import axios from "axios";
import { API_ROOT } from "@/config";
import { DEFINE_CANCEL_REQUEST_FUNCTION } from "../../store/support/constants";
const CancelToken = axios.CancelToken;

const progressBarConfig = (state) => {
  return {
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total > 2000000) {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        state.commit("SET_PROGRESS_BAR", percentCompleted);
      }
    },
  };
};

export default {
  getCount() {
    return axios.get(`${API_ROOT}api/support/Request/Count`);
  },
  getRequests(params, state) {
    const req = axios.create();

    if (state.getters.commonSettings.cancelRequest) {
      state.getters.commonSettings.cancelRequestExecuteFunction();
    }
    return req.get(`${API_ROOT}api/support/Request`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        state.commit(DEFINE_CANCEL_REQUEST_FUNCTION, () => c());
      }),
    });
  },
  getRequestsForDist(params, state) {
    const req = axios.create();
    if (state.getters.commonSettings.cancelRequest) {
      state.getters.commonSettings.cancelRequestExecuteFunction();
    }
    return req.get(`${API_ROOT}api/support/RequestDistributor`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        state.commit(DEFINE_CANCEL_REQUEST_FUNCTION, () => c());
      }),
    });
  },
  getRequestMessages(requestId) {
    const req = axios.create();
    req.defaults.timeout = 5000;
    return req.get(`${API_ROOT}api/support/Request/${requestId}`);
  },
  sendMessage(state, { requestId, message }) {
    return axios.post(
      `${API_ROOT}api/support/Message/By/${requestId}`,
      message,
      progressBarConfig(state)
    );
  },
  changeStatus({ requestId, status }) {
    return axios.patch(`${API_ROOT}api/support/Request/${requestId}`, {
      StatusId: status,
    });
  },
  updateRequest({ requestId, payload }) {
    return axios.patch(`${API_ROOT}api/support/Request/${requestId}`, payload);
  },
  addTime(timeData) {
    return axios.post(`${API_ROOT}api/support/Request/addTime`, timeData);
  },
  getManagersList() {
    return axios.get(`${API_ROOT}api/support/Request/getListManagers`);
  },
  changeManager(data) {
    return axios.post(`${API_ROOT}api/support/Request/changeManager`, data);
  },
  getTagsList() {
    return axios.get(`${API_ROOT}api/support/Tags`);
  },
  createTag(data) {
    return axios.post(`${API_ROOT}api/support/Tags`, data);
  },
  editTag(id, data) {
    return axios.put(`${API_ROOT}api/support/Tags/${id}`, data);
  },
  deleteTag(id) {
    return axios.delete(`${API_ROOT}api/support/Tags/${id}`);
  },
  createTagRelation(data) {
    return axios.post(`${API_ROOT}api/support/TagsToRequests`, data);
  },
  deleteTagRelation(id) {
    return axios.delete(`${API_ROOT}api/support/TagsToRequests/${id}`);
  },
  updateTagsOrder(data) {
    return axios.post(`${API_ROOT}api/support/Tags/updateOrder`, data);
  },
  createAnalyticsReport(data) {
    return axios.post(`${API_ROOT}api/support/tagsUsersSearchParams`, data);
  },
  getAnalyticsReports() {
    return axios.get(`${API_ROOT}api/support/tagsUsersSearchParams`);
  },
  deleteAnalyticsReport(id) {
    return axios.delete(`${API_ROOT}api/support/tagsUsersSearchParams/${id}`);
  },
  getAnalyticsCounter() {
    return axios.get(`${API_ROOT}api/support/tagsUsersSearchParams/countItems`);
  },
};
