<template>
  <div>
    <div class="header">
      <div class="header__col">
        <div
          class="tm-sidebar-open-btn"
          @click="commonModuleSettings.sidebarIsOpen = true"
          v-if="!commonModuleSettings.sidebarIsOpen"
        >
          <svg width="20" height="14">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#sidebar-menu-icon"
            ></use>
          </svg>
        </div>
        <p class="tm-header-title">
          <template v-if="!filterInGroup && selectedFilter">
            {{ filter && filter.name
            }}{{ filter && filter.count ? ": " + filter.count : "" }}
          </template>
          <template v-else-if="group">
            {{ group.GroupName }}{{ group.Count ? ": " + group.Count : "" }}
            <template v-if="filterInGroup"> + фильтр</template>
          </template>
        </p>
      </div>
      <div class="header__col">
        <div
          class="tasks-notifications-btn"
          @click.prevent="commonModuleSettings.notificationsPopupOpen = true"
        >
          <svg
            width="17"
            height="19"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 19C9.6 19 10.5 18.145 10.5 17.1H6.5C6.5 18.145 7.4 19 8.5 19ZM15 13.3V8.075C15 5.13 12.9 2.755 10 2.09V1.425C10 0.665 9.3 0 8.5 0C7.7 0 7 0.665 7 1.425V2.09C4.1 2.755 2 5.13 2 8.075V13.3L0 15.2V16.15H17V15.2L15 13.3Z"
              fill="#F9AE17"
            />
          </svg>
          <span
            class="aside-menu__counter aside-menu__counter--red"
            v-if="+taskCountNofitications"
            >{{ taskCountNofitications }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="header task-header cm-header"
      v-if="commonModuleSettings.selectedItems.length"
    >
      <div class="header__col cm-group-actions">
        <div class="button-group">
          <div
            class="button button-close button_grey button_uppercase"
            @click="unselectTasks()"
          >
            <img src="@/assets/images/sales/close.svg" alt="" />
          </div>
          <div class="button button_grey button_uppercase">
            {{ commonModuleSettings.selectedItems.length }}
          </div>
        </div>
        <div
          class="button button_grey button_uppercase"
          @click="attachAutomation"
        >
          Создать автоматизацию
        </div>
        <div
          class="button button_grey button_uppercase"
          v-if="selectedTasksNeedToApprove.length"
          @click="approveTasks"
        >
          Принять
        </div>
        <div>
          <div
            @click="personsBubbleOpen = true"
            class="button button_grey button_uppercase"
          >
            Ответственный
          </div>
          <transition name="fade">
            <PersonsListSearch
              v-if="personsBubbleOpen"
              :emptyPlaceholder="'Убрать ответственного'"
              :className="'task-bubble--checklist-items'"
              @choose="setResponsible($event)"
              @close="personsBubbleOpen = false"
              v-click-outside="closePersonBubble"
            />
          </transition>
        </div>
        <div>
          <div class="button button_grey button_uppercase">
            <date-input
              :format="'DD MMMM YYYY в HH:mm'"
              @setDate="(date, callback) => setDate(date)"
              :open="true"
              :afterConfirm="true"
              :placeholder="'Дата'"
            />
          </div>
        </div>
        <div ref="portal-wrapper-tags">
          <div
            @click="addTagBubbleOpen = true"
            class="button button_grey button_uppercase"
          >
            Добавить тег
          </div>
          <portal to="common">
            <transition name="fade">
              <TagsListSearch
                v-if="addTagBubbleOpen"
                :className="'task-bubble--tag'"
                :taskTags="[]"
                @choose="addTag"
                @close="addTagBubbleOpen = false"
                v-portal="{ parent: $refs['portal-wrapper-tags'] }"
              />
            </transition>
          </portal>
        </div>
        <div>
          <div
            @click="changeGroupBubbleOpen = true"
            class="button button_grey button_uppercase"
            ref="portal-wrapper-settings"
          >
            Переместить
          </div>
          <portal to="common">
            <transition name="fade">
              <TaskSettingsList
                v-if="changeGroupBubbleOpen"
                v-click-outside="closeGroupBubble"
                @closeSettings="closeGroupBubble"
                @unselectTasks="unselectTasks"
                :parentData="innerElementSettings"
                :selectedItems="commonModuleSettings.selectedItems"
                :sections="sections"
                :partIdToShow="3"
                v-portal="{ parent: $refs['portal-wrapper-settings'] }"
              />
            </transition>
          </portal>
        </div>
        <div>
          <div
            @click="copyTaskBubbleOpen = true"
            class="button button_grey button_uppercase"
            ref="portal-wrapper-settings-copy"
          >
            Дублировать
          </div>
          <portal to="common">
            <transition name="fade">
              <TaskSettingsList
                v-if="copyTaskBubbleOpen"
                v-click-outside="closeGroupBubble"
                @closeSettings="closeGroupBubble"
                @unselectTasks="unselectTasks"
                :parentData="innerElementSettings"
                :selectedItems="commonModuleSettings.selectedItems"
                :sections="sections"
                :partIdToShow="2"
                v-portal="{ parent: $refs['portal-wrapper-settings-copy'] }"
              />
            </transition>
          </portal>
        </div>
        <div v-if="statusesArray.length" ref="portal-wrapper">
          <div
            class="button button_grey button_uppercase"
            @click="
              checkUserTaskRight('change_status') && (statusBubbleOpen = true)
            "
          >
            Статус
          </div>
          <portal to="common">
            <transition name="fade">
              <StatusesListSearch
                v-if="statusBubbleOpen"
                :items="statusesArray"
                :selectedId="null"
                @choose="setProcessStatus"
                @close="() => (statusBubbleOpen = false)"
                v-portal="{ parent: $refs['portal-wrapper'] }"
              />
            </transition>
          </portal>
        </div>
        <div
          class="button button_grey button_uppercase"
          v-if="checkUserTaskRight('close_task')"
          style="margin-left: auto"
          @click="closeTasks()"
        >
          Закрыть
        </div>
        <div style="position: relative">
          <div
            class="button button_grey button_uppercase"
            style="position: absolute; top: 50px; right: 0"
            v-if="dependsTaskSelected"
            @click="deleteDependTasks()"
          >
            открепить
          </div>
          <div
            class="button button_grey button_uppercase"
            v-if="checkUserTaskRight('delete_task')"
            @click="deleteTasks()"
          >
            удалить
          </div>
        </div>
      </div>
    </div>
    <div class="actions-header" v-else>
      <div
        class="actions-header__col"
        ref="portal-wrapper"
        v-if="checkUserTaskRight('create_tasks_and_subtasks') && group"
      >
        <span
          class="button tm-btn tm-btn--add"
          @click="commonModuleSettings.taskCreatePopupOpen = true"
          >+</span
        >
        <a
          v-if="checkUserTaskRight('add_section')"
          href="#"
          class="icon-button task-checkbox-btn task-checkbox-btn--hide-completed"
          @click.prevent="createSection"
          style="margin-left: 12px"
        >
          + Секция
        </a>
      </div>
      <div
        class="actions-header__col"
        v-if="
          !group &&
          commonModuleSettings.sharedFilterInQuery &&
          showSaveSharedFilterBtn
        "
      >
        <div class="button tm-btn tm-btn--add" @click="saveSharedFilter">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H14L19 6V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 19V11H5V19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 1V6H13"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div class="actions-header__col actions-header__col_flexible">
        <!--        <FastTaskCreateForm-->
        <!--          style="margin-right: 10px;"-->
        <!--          -->
        <!--        />-->

        <div class="search-bar search-bar_white actions-header__search-bar">
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Введите название задачи"
            class="search-bar__input"
            @keyup="searchTasks($event)"
            v-model="commonModuleSettings.search"
          />

          <div class="filter-labels" v-if="filterInGroup">
            <div class="filter-labels__item">
              <span class="filter-labels__text">применен фильтр</span>
              <i @click.prevent="resetFilter">
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-close"
                  ></use>
                </svg>
              </i>
            </div>
          </div>

          <div
            class="form-filter__btn"
            :class="filterBtnClassName"
            @click="filterOpen = !filterOpen"
          >
            {{ selectedFilter ? "настройки фильтра" : "фильтр" }}
          </div>
        </div>
        <TasksFilter
          v-if="filterOpen"
          v-click-outside="closeFilterWindow"
          @closeFilterWindow="closeFilterWindow"
          ref="task-filter"
        />
      </div>
      <div class="actions-header__col">
        <a
          href="#"
          class="icon-button task-checkbox-btn task-checkbox-btn--hide-completed"
          @click.prevent="hideCompletedTasks"
          v-if="group"
        >
          <label
            class="checkbox checkbox_white table__checkbox js-index-checkbox"
          >
            <input
              class="checkbox__input main_checkbox_input"
              type="checkbox"
              :class="{
                'checkbox__box--active': group && settings.hideCompletedTasks,
              }"
            />
            <span class="checkbox__box main_checkbox_span">
              <svg class="checkbox__icon">
                <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
              </svg>
            </span>
          </label>
          Скрыть выполненные
        </a>
        <a
          href="#"
          class="icon-button task-checkbox-btn task-checkbox-btn--hide-completed"
          @click.prevent="filterGroupTasks"
          v-else
        >
          <label
            class="checkbox checkbox_white table__checkbox js-index-checkbox"
          >
            <input
              class="checkbox__input main_checkbox_input"
              type="checkbox"
              :class="{
                'checkbox__box--active': settings.filterGroupTaskByGroups,
              }"
            />
            <span class="checkbox__box main_checkbox_span">
              <svg class="checkbox__icon">
                <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
              </svg>
            </span>
          </label>
          Группировка по папкам
        </a>
      </div>
      <div class="actions-header__col" v-if="group">
        <span
          style="cursor: pointer"
          @click.prevent="commonModuleSettings.folderActivityPopupOpen = true"
        >
          <span class="icon-button__container">
            <svg width="20" height="18" stroke="#9b9b9b">
              <use
                xlink:href="/static/images/tasks/sprite.svg#pulse-icon"
              ></use>
            </svg>
          </span>
        </span>
      </div>
      <div class="actions-header__col">
        <span style="cursor: pointer" @click="projectViewToggle">
          <span class="icon-button__container">
            <svg
              width="19"
              height="19"
              :fill="
                commonModuleSettings.viewType === 'project'
                  ? '#F9AD1A'
                  : '#9B9B9B'
              "
            >
              <use xlink:href="/static/images/tasks/sprite.svg#rock"></use>
            </svg>
          </span>
        </span>
      </div>
      <div
        class="actions-header__col"
        v-if="
          (groupActiveProcess && !selectedFilter) || filterHaveProcessCondition
        "
      >
        <span
          class="tm-settings-btn tm-settings-btn-kanban"
          :class="{
            'tm-settings-btn-kanban--active':
              commonModuleSettings.viewType === 'kanban',
          }"
          @click="kanbanViewToggle"
        >
          <span class="icon-button__container">
            <svg width="16" height="16">
              <use
                xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
              ></use>
            </svg>
          </span>
        </span>
      </div>
      <div class="actions-header__col actions-header__col--favorite">
        <span
          class="tm-settings-btn"
          @click="addOrRemoveFromFavorite"
          :class="{ 'task-files-header__icon-1--disable': filterType === 2 }"
        >
          <span class="icon-button__container">
            <i
              class="task-files-header__icon-1"
              :class="{
                'task-files-header__icon-1--active':
                  isFavoriteItem || filterType === 2,
              }"
            ></i>
          </span>
        </span>
      </div>
      <div
        class="actions-header__col"
        @click="shareFilter"
        v-if="selectedFilter && !filterInGroup && filter && filter.Hash"
      >
        <div class="icon-button__container">
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4C13 5.65685 14.3431 7 16 7Z"
              stroke="#9b9b9a"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 14C5.65685 14 7 12.6569 7 11C7 9.34315 5.65685 8 4 8C2.34315 8 1 9.34315 1 11C1 12.6569 2.34315 14 4 14Z"
              stroke="#9b9b9a"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 21C17.6569 21 19 19.6569 19 18C19 16.3431 17.6569 15 16 15C14.3431 15 13 16.3431 13 18C13 19.6569 14.3431 21 16 21Z"
              stroke="#9b9b9a"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.58984 12.51L13.4198 16.49"
              stroke="#9b9b9a"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.4098 5.51001L6.58984 9.49001"
              stroke="#9b9b9a"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div
        class="actions-header__col"
        v-if="
          checkUserTaskRight('edit_groups') &&
          (!selectedFilter || filterInGroup)
        "
      >
        <span
          class="tm-settings-btn"
          @click.prevent="commonModuleSettings.groupSettingsPopupOpen = true"
        >
          <span class="icon-button__container">
            <svg class="icon-button__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-settings"
              ></use>
            </svg>
          </span>
        </span>
      </div>
    </div>
    <transition name="popupSlide">
      <TasksListToTransfer
        v-if="innerElementSettings.tasksListToTransferPopupOpen"
        :selectedItems="commonModuleSettings.selectedItems"
        @unselectTasks="unselectTasks"
        @onClosePopup="
          innerElementSettings.tasksListToTransferPopupOpen = false
        "
      />
    </transition>
  </div>
</template>
<script>
import * as c from "../../../../store/tasks/constants";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import TasksFilter from "./TasksFilter";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";
import { getStandartFilterNameInfo } from "../../../../mixins/tasks/func";
import common from "../../../../mixins/tasks/common";
import { queueFetch } from "../../../../utils/queneFetch";
import {
  GET_TASKS_BY_USER_FILTER,
  GET_TASKS_BY_STANDART_FILTER,
  REFRESH_SECTIONS,
  SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_SELECTED_ITEMS,
  SET_COMMON_MODULE_VIEW_TYPE,
  SET_LOCAL_STORAGE_SETTINGS,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_CACHED,
  SET_KEYDOWN_FUNCTION,
  DELETE_DEPENDENCIES_TASK,
  APPROVE_MANY_TASKS,
} from "../../../../store/tasks/constants";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";
import moment from "moment";
import TagsListSearch from "@/modules/tasks/components/CommonComponents/TagsListSearch/TagsListSearch";
import StatusesListSearch from "@/modules/tasks/components/CommonComponents/StatusesListSearch";
import TaskSettingsList from "@/modules/tasks/components/TaskDetail/TaskSettingsList/TaskSettingsList";
import TasksListToTransfer from "@/modules/tasks/components/TaskDetail/TaskSettingsList/TasksListToTransfer";
import { showAlert } from "@/utils";

export default {
  mixins: [Helpers, common],
  props: ["filterHaveProcessCondition"],
  data() {
    return {
      typeTime: null,
      search: "",
      filterOpen: false,
      filterType: 1,
      openTaskCreateSubmenu: false,
      personsBubbleOpen: false,
      addTagBubbleOpen: false,
      statusBubbleOpen: false,
      changeGroupBubbleOpen: false,
      innerElementSettings: {
        tasksListToTransferPopupOpen: false,
      },
      showSaveSharedFilterBtn: true,
      copyTaskBubbleOpen: false,
    };
  },
  mounted() {
    const keydownFunction = (e) => {
      if (e.key === "Escape") {
        this.unselectTasks();
      }
    };
    this[SET_KEYDOWN_FUNCTION](keydownFunction);

    document.onkeydown = keydownFunction;
  },
  computed: {
    filterBtnClassName() {
      if (this.filterOpen) {
        return " form-filter__btn--active";
      }
      return "";
    },
    filter() {
      if (
        this.commonModuleSettings.sharedFilterInQuery &&
        typeof this.commonModuleSettings.sharedFilterInQuery === "object"
      )
        return {
          ...this.commonModuleSettings.sharedFilterInQuery,
          name: this.commonModuleSettings.sharedFilterInQuery.FilterName,
        };
      if (this.selectedFilter) {
        if (this.selectedFilterIsUsersFilter) {
          const foundFilter = this.getUserFilterIfCurrentFilterIs;
          return foundFilter
            ? { ...foundFilter, name: foundFilter.FilterName }
            : null;
        } else {
          const foundFilter = this.standartFilters.find(
            (i) => i.index === this.selectedFilter
          );
          let filterName = getStandartFilterNameInfo(this.selectedFilter)[0];

          if (!foundFilter && this.countForFlashFilterCheck)
            filterName = this.selectedFilterName + "Свой фильтр";
          return {
            name: filterName,
            count: foundFilter ? foundFilter.TaskCount : null,
          };
        }
      }
      return null;
    },
    isFavoriteItem() {
      const isFavoriteGroup = this.group
        ? _.find(this.favorites, ["ItemId", this.group.Id])
        : false;
      let isFavoriteFilter = null;
      let foundFilter;
      if (this.selectedFilter) {
        if (this.selectedFilterIsUsersFilter) {
          foundFilter = this.getUserFilterIfCurrentFilterIs;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.filterType = 3;
        } else {
          foundFilter = this.standartFilters.find(
            (i) => i.index === this.selectedFilter
          );
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.filterType = 2;
        }
      }

      if (foundFilter) {
        const filterId = this.selectedFilterIsUsersFilter
          ? foundFilter.Id
          : foundFilter.index;
        isFavoriteFilter = _.find(this.favorites, ["ItemId", String(filterId)]);
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.filterType = 1;
      }
      return isFavoriteFilter || isFavoriteGroup;
    },
    filterInGroup() {
      return (
        this.group && this.selectedFilter < 0 && !this.countForFlashFilterCheck
      );
    },
    statusesArray() {
      if (this.group) {
        let processId = this.group.MainProcessId;
        const found = _.find(this.groupProcesses, ["Id", processId]);
        return found ? found.statuses : [];
      }
      return [];
    },
    dependsTaskSelected() {
      return this.commonModuleSettings.selectedItems.find((x) => x.isDepended);
    },
    selectedTasksNeedToApprove() {
      return this.commonModuleSettings.selectedItems.filter((i) => {
        return (
          i.ApproveRecordId &&
          Object.keys(i.ApproveRecordId).length &&
          i.ApproveRecordId.UserId === this.userData.Id
        );
      });
    },
    ...mapState("tasks", [
      "userFilters",
      "standartFilters",
      "settings",
      "favorites",
      "tasks",
      "sections",
      "tags",
      "groupProcesses",
      "allUserFilters",
      "commonModuleSettings",
      "userData",
    ]),
    ...mapGetters("tasks", [
      "group",
      "taskCountNofitications",
      "groupActiveProcess",
      "selectedFilter",
      "selectedFilterIsUsersFilter",
      "getUserFilterIfCurrentFilterIs",
      "countForFlashFilterCheck",
      "selectedFilterName",
    ]),
  },
  methods: {
    deleteTasks() {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        await this[c.DELETE_TASK]({ id: Id, batch: true });
      }).then(() => {
        this.unselectTasks();
        this[c.GET_USER_TASKS_LIST](true);
        this[REFRESH_SECTIONS]();
      });
    },
    deleteDependTasks() {
      queueFetch(this.commonModuleSettings.selectedItems, async (item) => {
        if (item.isDepended) {
          await this[DELETE_DEPENDENCIES_TASK](item.DependRecordId);
        }
      }).then(() => {
        this.unselectTasks();
        this[REFRESH_SECTIONS]();
      });
    },
    closeTasks() {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        const payload = {
          id: Id,
          data: {
            Status: "2",
          },
        };
        await this[c.EDIT_TASK](payload);
      }).then(() => {
        this.unselectTasks();
      });
    },
    unselectTasks() {
      this[SET_COMMON_MODULE_SELECTED_ITEMS]([]);
      document
        .querySelectorAll(
          ".project-view-table .checkbox__input, .table .checkbox__input"
        )
        .forEach((i) => {
          i.checked = false;
        });
      if (document.querySelector(".selection-entry")) {
        document
          .querySelector(".selection-entry")
          .classList.remove("selection-entry");
      }
      if (document.querySelector(".shift-selection-entry")) {
        document
          .querySelector(".shift-selection-entry")
          .classList.remove(".shift-selection-entry");
      }
      if (document.querySelector(".ctrl-selection-entry")) {
        document
          .querySelector(".ctrl-selection-entry")
          .classList.remove(".ctrl-selection-entry");
      }
    },
    searchTasks() {
      clearTimeout(this.typeTime);
      this.typeTime = setTimeout(() => {
        if (
          this.commonModuleSettings.search !==
          this.settings.taskFilterParams["params[TaskName]"]
        ) {
          this.settings.taskFilterParams[
            "params[TaskName]"
          ] = this.commonModuleSettings.search;
          this[SET_COMMON_MODULE_PAGE](0);
          if (!this.selectedFilter) {
            this[c.GET_TASKS]();
          } else {
            if (this.selectedFilter.length > 5) {
              this[GET_TASKS_BY_USER_FILTER]({
                id: this.selectedFilter,
                params: {
                  "params[TaskName]": this.commonModuleSettings.search,
                },
              });
            } else {
              this[GET_TASKS_BY_STANDART_FILTER]({
                type: this.selectedFilter,
                params: {
                  "params[TaskName]": this.commonModuleSettings.search,
                },
              });
            }
          }
        }
      }, 300);
    },
    closeFilterWindow() {
      this.filterOpen = false;
    },
    resetFilter() {
      this[SET_TABLE_FILTER](null);
      this[SET_TABLE_FILTER_CACHED](null);
      this[c.GET_TASKS]();
    },
    hideCompletedTasks() {
      this.settings.hideCompletedTasks = !this.settings.hideCompletedTasks;
      this.$localStorage.set(
        "hideCompletedTasks",
        this.settings.hideCompletedTasks
      );
      if (!this.selectedFilter) {
        this[c.GET_TASKS]();
      } else {
        if (this.selectedFilterIsUsersFilter) {
          this[c.GET_TASKS_BY_USER_FILTER]({
            id: this.selectedFilter,
          });
        } else {
          this[c.GET_TASKS_BY_STANDART_FILTER]({
            type: this.selectedFilter,
          });
        }
      }
    },
    addOrRemoveFromFavorite() {
      if (this.isFavoriteItem) {
        this[c.DELETE_FROM_FAVORITES](this.isFavoriteItem.Id);
      } else {
        const ItemId = this.selectedFilter
          ? this.selectedFilter
          : this.group.Id;
        this[c.ADD_TO_FAVORITES]({
          ItemType: this.filterType,
          ItemId,
        });
      }
    },
    kanbanViewToggle() {
      this[SET_COMMON_MODULE_PAGE](99);
      this[SET_COMMON_MODULE_VIEW_TYPE](
        this.commonModuleSettings.viewType === "kanban" ? false : "kanban"
      );
      if (this.commonModuleSettings.viewType === "kanban") {
        if (
          this.filterHaveProcessCondition &&
          this.getUserFilterIfCurrentFilterIs.Id
        ) {
          this[c.GET_TASKS_BY_USER_FILTER]({
            id: this.getUserFilterIfCurrentFilterIs.Id,
            params: { Limit: 500 },
          });
        } else {
          this[c.GET_TASKS]({ limit: 500 }).then(() => {
            const idsArray = _.map(this.tasks, (i) => i.Id);
            if (this.commonModuleSettings.viewType !== "kanban") {
              this[c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY]({
                idsArray,
                push: false,
              });
            }
          });
        }
      }
      if (this.group) {
        this[SET_LOCAL_STORAGE_SETTINGS]({
          data: {
            Id: this.group.Id,
            Type: "group",
            settings: {
              viewType: this.commonModuleSettings.viewType,
            },
          },
          init: false,
        });
      }
    },
    attachAutomation() {
      const arr = [];
      const recursiveFunc = (i) => {
        if (
          this.commonModuleSettings.selectedItems.find((x) => x.Id === i.Id)
        ) {
          arr.push(i);
        }
        if (i.childs && i.childs.length) {
          _.each(i.childs, (c) => {
            recursiveFunc(c);
          });
        }
      };
      this.tasks.forEach((i) => {
        recursiveFunc(i);
      });
      this[SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS](arr);
      this.unselectTasks();
    },
    closeTaskCreateSubmenu() {
      this.openTaskCreateSubmenu = false;
    },
    createSection() {
      this.openTaskCreateSubmenu = false;

      this[c.CREATE_SECTION]({
        Name: "Новая секция",
        GroupId: this.group.Id,
      }).then(() => {
        // setTimeout(() => {
        //   scrollTo(
        //     document.querySelector(".table__row-wrap--tasks"),
        //     document.querySelector(".table__row-wrap--tasks").scrollHeight,
        //     500
        //   );
        // }, 100);
      });
    },
    findTask(id) {
      let result = null;
      const recursiveFunc = (i) => {
        if (!result) {
          if (i.Id === id) return (result = i);
          else if (i.childs && i.childs.length) {
            _.each(i.childs, (i) => {
              recursiveFunc(i);
            });
          }
        }
      };
      this.tasks.forEach((i) => {
        recursiveFunc(i);
      });
      return result;
    },
    setResponsible(id) {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        const task = this.findTask(Id);
        const oldResponsiblId = task.ResponsibleId;
        const payload = {
          id: task.Id,
          data: {
            ResponsibleId: id,
          },
        };
        await this[c.EDIT_TASK](payload).then(() => {
          task.ResponsibleId = id;
          if (oldResponsiblId) {
            this[c.ADD_SUBSCRIBER]({
              UserId: oldResponsiblId,
              TaskId: task.Id,
            });
          }
        });
      }).then(() => {
        this.unselectTasks();
        this[REFRESH_SECTIONS]();
      });
    },
    closePersonBubble() {
      this.personsBubbleOpen = false;
    },
    setDate(date) {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        const task = this.findTask(Id);

        task.FinishDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
        task.FinishTime = moment(date).format("HH:mm");

        const payload = {
          id: Id,
          data: {
            FinishDate: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            FinishTime: moment(date).format("HH:mm"),
          },
        };
        await this[c.EDIT_TASK](payload);
      }).then(() => {
        this.unselectTasks();
        this[REFRESH_SECTIONS]();
      });
    },
    addTag(tagId) {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        const task = this.findTask(Id);

        await this[c.ADD_TAG_TO_TASK]({
          data: {
            TagId: tagId,
            TaskId: task.Id,
          },
        }).then((data) => {
          if (!_.find(task.tags, ["Id", data.TagId])) {
            const foundTag = _.find(this.tags, ["Id", tagId]);
            const tag = { ...data, TagName: foundTag.TagName };
            if (!task.tags) task.tags = [];
            task.tags.push(tag);
          }
        });
      }).then(() => {
        this.unselectTasks();
        this[REFRESH_SECTIONS]();
      });
    },
    setProcessStatus(id) {
      queueFetch(this.commonModuleSettings.selectedItems, async ({ Id }) => {
        const task = this.findTask(Id);
        task.StatusId = id;
        const payload = {
          id: task.Id,
          data: {
            StatusId: id,
          },
        };
        await this[c.EDIT_TASK](payload);
      }).then(() => {
        this.unselectTasks();
        this[REFRESH_SECTIONS]();
      });
    },
    closeGroupBubble() {
      this.changeGroupBubbleOpen = false;
      this.copyTaskBubbleOpen = false;
    },
    projectViewToggle() {
      this[SET_COMMON_MODULE_VIEW_TYPE](
        this.commonModuleSettings.viewType === "project" ? false : "project"
      );
      if (this.group) {
        this[SET_LOCAL_STORAGE_SETTINGS]({
          data: {
            Id: this.group.Id,
            Type: "group",
            settings: {
              viewType: this.commonModuleSettings.viewType,
            },
          },
          init: false,
        });
      }
      if (this.commonModuleSettings.viewType === "project") {
        this.makeSort("Activity", false);
      } else {
        this.resetSort();
      }
    },
    shareFilter() {
      const input = document.createElement("input");
      input.setAttribute(
        "value",
        window.location.origin + "/tasks?filter-hash=" + this.filter.Hash
      );
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      showAlert("success", "", "Ссылка скопирована");
    },
    saveSharedFilter() {
      this[c.COPY_SHARED_FILTER]({ FilterId: this.filter.Id });
      this.showSaveSharedFilterBtn = false;
    },
    filterGroupTasks() {
      this.settings.filterGroupTaskByGroups = !this.settings
        .filterGroupTaskByGroups;
      this[SET_COMMON_MODULE_PAGE](0);
      if (this.selectedFilterIsUsersFilter) {
        this[GET_TASKS_BY_USER_FILTER]({
          id: this.selectedFilter,
        });
      } else {
        this[c.GET_TASKS_BY_STANDART_FILTER]({
          type: this.selectedFilter,
        });
      }
    },
    approveTasks() {
      let tasksToApprove = this.commonModuleSettings.selectedItems.filter(
        (i) => {
          return (
            i.ApproveRecordId &&
            Object.keys(i.ApproveRecordId).length &&
            i.ApproveRecordId.UserId === this.userData.Id
          );
        }
      );
      this[APPROVE_MANY_TASKS]({
        ids: tasksToApprove.map((i) => i.ApproveRecordId.Id),
      })
        .then((res) => {
          if (res.data.status === "success") {
            tasksToApprove = tasksToApprove.map((i) => {
              i.ApproveRecordId = undefined;
              return i;
            });
          }
        })
        .finally(() => {
          this.unselectTasks();
        });
    },
    ...mapActions("tasks", [
      c.DELETE_TASK,
      c.EDIT_TASK,
      c.GET_TASKS,
      c.DELETE_FROM_FAVORITES,
      c.ADD_TO_FAVORITES,
      c.GET_TASKS_BY_STANDART_FILTER,
      c.GET_TASKS_BY_USER_FILTER,
      c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY,
      c.CREATE_SECTION,
      c.ADD_SUBSCRIBER,
      c.ADD_TAG_TO_TASK,
      c.GET_GROUPS,
      c.GET_USER_TASKS_LIST,
      c.COPY_SHARED_FILTER,
      DELETE_DEPENDENCIES_TASK,
      APPROVE_MANY_TASKS,
    ]),
    ...mapMutations("tasks", [
      SET_LOCAL_STORAGE_SETTINGS,
      REFRESH_SECTIONS,
      SET_TABLE_FILTER,
      SET_TABLE_FILTER_CACHED,
      SET_COMMON_MODULE_SELECTED_ITEMS,
      SET_COMMON_MODULE_PAGE,
      SET_COMMON_MODULE_VIEW_TYPE,
      SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS,
      SET_KEYDOWN_FUNCTION,
    ]),
  },
  destroyed() {
    document.onkeydown = null;
  },
  components: {
    TasksFilter,
    PersonsListSearch,
    TagsListSearch,
    StatusesListSearch,
    TaskSettingsList,
    TasksListToTransfer,
  },
};
</script>

<style scoped></style>
