<template>
  <div
    class="sup-list-wrapper"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="task"
    infinite-scroll-distance="0"
    infinite-scroll-immediate-check="false"
    infinite-scroll-throttle-delay="1000"
  >
    <div class="chat-spinner" v-if="preloaders.list">
      <Spinner
        :noFadeIn="true"
        name="wave"
        color="#c9eeff"
        width="60"
        height="60"
      />
    </div>
    <div v-if="filteredRequests.true" class="sup-list -urgently">
      <div class="sup-list_title" @click="toggleSection('attention')">
        <div
          class="sup-list_title-icon"
          :class="attentionIsShown ? '_switched' : ''"
        >
          <svg width="12" height="7">
            <use
              xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
            ></use>
          </svg>
        </div>
        <p>Требуют внимания: {{ filteredRequests.true.length }}</p>
      </div>
      <div v-if="attentionIsShown">
        <div
          class="sup-list_item"
          :key="index"
          v-for="(item, index) in filteredRequests.true"
          @click="selectRequest($event, item)"
          :class="selectedRequest.Id === item.Id ? 'sup-list_item--active' : ''"
        >
          <div
            class="sup-label"
            :class="[
              drawRpsStatusColor(item.StatusId),
              item.Unread ? 'font-600' : '',
            ]"
          >
            <template v-if="item.DistributorToAdmin === '1'">Д </template
            >{{ item.Id }}
            <span class="sup-label-circle" v-if="item.Unread">{{
              item.Unread
            }}</span>
          </div>
          <div class="sup-list_item__info">
            <span
              class="sup-list_item__info__title"
              :class="item.Unread ? 'font-600' : ''"
              >{{ item.Subject }}</span
            >
            <span class="sup-list_item__info__client">
              {{ item.Object }} /
              {{ requestObjectServiceType(item.ObjectId)[1] }}
              <template v-if="item.DistributorName || item.distributorName"
                >/ {{ item.DistributorName || item.distributorName }}
                <br /> </template
            ></span>
          </div>
          <div class="sup-list_item__tags" @click.stop>
            <RequestTags :request="item" />
          </div>
          <div class="sup-list_item__time" v-if="item.StatusId !== '5'">
            <div class="sup-list_item__time-top font-600">
              {{ item.LastMessageTime | lastActivity }}
            </div>
          </div>
          <div class="sup-list_item__lable" v-if="item.StatusId !== '5'">
            <img
              v-if="item.DeadLineMinutes <= 0"
              src="@/assets/images/fire.png"
              class="fire-icon"
              alt="fire icon"
            />
            <div
              class="sup-list_item__time-alert"
              v-if="item.DeadLineMinutes <= 0"
            >
              Пора ответить!
            </div>
            <div class="sup-list_item__time-alert" v-else>
              {{ item.DeadLineMinutes }} м. на ответ!
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sup-list" v-if="filteredRequests.false">
      <div class="sup-list_title curso" @click="toggleSection('other')">
        <div
          class="sup-list_title-icon"
          :class="otherAreShown ? '_switched' : ''"
        >
          <svg width="12" height="7">
            <use
              xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
            ></use>
          </svg>
        </div>
        <p>Остальные заявки: {{ filteredRequests.false.length }}</p>
      </div>
      <div v-if="otherAreShown">
        <div
          class="sup-list_item"
          :key="index"
          v-for="(item, index) in filteredRequests.false"
          @click="selectRequest($event, item)"
          :class="selectedRequest.Id === item.Id ? 'sup-list_item--active' : ''"
        >
          <div
            class="sup-label"
            :class="[
              drawRpsStatusColor(item.StatusId),
              item.Unread ? 'font-600' : '',
            ]"
          >
            <template v-if="item.DistributorToAdmin === '1'">Д </template
            >{{ item.Id }}
          </div>
          <div class="sup-list_item__info">
            <span
              class="sup-list_item__info__title"
              :class="item.Unread ? 'font-600' : ''"
              >{{ item.Subject }}</span
            >
            <span class="sup-list_item__info__client">
              {{ item.Object }} /
              {{ requestObjectServiceType(item.ObjectId)[1] }}
              <template v-if="item.DistributorName || item.distributorName"
                >/ {{ item.DistributorName || item.distributorName }} <br />
              </template>
            </span>
          </div>
          <div class="sup-list_item__tags" @click.stop>
            <RequestTags :request="item" />
          </div>

          <div class="sup-list_item__time">
            <span class="sup-list_item__time-top">{{
              item.LastMessageTime | lastActivity
            }}</span>
            <span class="sup-list_item__time-bottom" v-if="item.AnswerDeadline">
              <img src="@/assets/images/alarm-little.svg" alt="icon" />
              {{ item.AnswerDeadline | nextAnswer }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import ColorOfStatus from "@/mixins/colorOfStatus";
import * as c from "@/store/support/constants";
import Spinner from "vue-spinkit";
import * as moment from "moment";
import * as _ from "lodash";
import RequestTags from "./tasks-module/RequestTags";

export default {
  mixins: [ColorOfStatus],
  data() {
    return {
      attentionIsShown: true,
      otherAreShown: true,
    };
  },
  computed: {
    ...mapState("rpsSupport", [
      "preloaders",
      "sort",
      "selectedRequest",
      "commonSettings",
      "page",
    ]),
    ...mapGetters("rpsSupport", ["filteredRequests", "activeTab"]),
    ...mapState("clients", ["objects"]),
  },
  methods: {
    ...mapActions("rpsSupport", [
      c.SET_SELECTED_REQUEST,
      c.CHANGE_SORT,
      c.GET_REQUESTS,
    ]),
    ...mapMutations("rpsSupport", [c.SET_PAGE]),
    sortRequests(sort) {
      this[c.CHANGE_SORT](sort);
    },
    toggleSection(section) {
      if (section === "attention")
        this.attentionIsShown = !this.attentionIsShown;
      else if (section === "other") this.otherAreShown = !this.otherAreShown;
    },
    getRequestTags(tags) {
      let preparedTags = [];
      if (tags && tags.length) {
        findTag(this.commonSettings.tags);
        return preparedTags;
      } else {
        return [];
      }

      function findTag(array) {
        _.each(tags, (i) => {
          const foundTag = _.find(array, ["Id", i.TagId]);
          if (foundTag) {
            foundTag.relationId = i.Id;
            preparedTags.push(foundTag);
          }
        });
        _.each(array, (i) => {
          if (i.child && i.child.length) findTag(i.child);
        });
      }
    },
    selectRequest(e, request) {
      if (request.Id !== this.selectedRequest.Id) {
        this[c.SET_SELECTED_REQUEST]({ request, requestId: request.Id });
      }
      this.$emit("windowOpen");
    },
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.commonSettings.selectedItems.includes(id)) {
          this.commonSettings.selectedItems.push(id);
        }
      } else {
        this.commonSettings.selectedItems = _.filter(
          this.commonSettings.selectedItems,
          (i) => i !== id
        );
      }
    },
    requestObject(id) {
      return _.find(this.objects.objects, ["Id", id]);
    },
    requestObjectServiceType(id) {
      if (this.requestObject(id)) {
        switch (+this.requestObject(id).ServiceType) {
          case 0:
            return ["-", "Ничего"];
          case 1:
            return ["ТП", "Техническая поддержка"];
          case 2:
            return ["ТО", "Техническое обслуживание"];
          case 3:
            return ["УЭ", "Удалённая эксплуатация"];
          case 4:
            return ["Э", "Эксплуатация"];
          case 5:
            return ["ПНР", "ПостПНР"];
          case 6:
            return ["ПНР", "ПНР"];
        }
      }
      return false;
    },
    loadMore() {
      if (this.activeTab === "closed") {
        this[c.SET_PAGE](this.page + 1);
        this[c.GET_REQUESTS]({
          scope: "closed",
          start: false,
          force: true,
        });
      }
    },
  },

  filters: {
    time(date) {
      return moment.unix(date).format("HH:mm");
    },
    lastActivity(date) {
      date = moment(date);

      return date.locale("ru").calendar(null, {
        sameDay: "HH:mm",
        nextDay: "DD.MM.YYYY в HH:mm",
        lastDay: "Вчера в HH:mm",
        lastWeek: "DD.MM.YYYY в HH:mm",
        sameElse: "DD.MM.YYYY в HH:mm",
      });
    },
    nextAnswer(date) {
      const dateTime = moment.unix(date);
      if (dateTime.isSame(moment(), "day")) {
        return dateTime.format("HH:mm");
      } else {
        return dateTime.locale("ru").format("DD MMM HH:mm");
      }
    },
  },
  components: { RequestTags, Spinner },
};
</script>
