<template>
  <div>
    <div class="task-create-form__row task-create-form__row--small">
      <div class="task-create-form__row-left">
        <svg width="18" height="18">
          <use xlink:href="/static/images/tasks/sprite.svg#childs-icon"></use>
        </svg>
      </div>
      <div class="task-create-form__row-right">
        <SimplePreloader v-if="preloader" />
        <div class="task-create-form__blue-title task-bubble-wrap">
          <div class="task-create-form__name-wrap">
            <p class="task-create-form__label-text">
              Подзадачи:
              <template v-if="selectedItems.length"
                >выбрано: {{ selectedItems.length }}</template
              >
            </p>
            <div
              class="task-create-form__field-value"
              @click="addTask"
              v-if="!selectedItems.length"
            >
              Добавить
            </div>
            <ControlBarForSelectedTasks
              v-if="selectedItems.length"
              :selected="selectedItems"
              :tasks="subtasks"
              :templateModel="templateModel"
              @unselectTasks="selectedItems = []"
              @editMultipleTasks="editMultipleTasks"
              @deleteSelectedTasks="deleteSelectedTasks"
            />
            <!--            <div-->
            <!--              class="task-create-form__field-value subtasks-rows__select-control"-->
            <!--              v-else-->
            <!--            >-->
            <!--              <div class="subtasks-rows__select-control-item">-->
            <!--                <div @click="personsBubbleOpen = true">Ответственный</div>-->
            <!--                <transition name="fade">-->
            <!--                  <PersonsListSearch-->
            <!--                    v-if="personsBubbleOpen"-->
            <!--                    :className="'task-bubble&#45;&#45;checklist-items'"-->
            <!--                    @choose="setResponsibleSelected($event)"-->
            <!--                    @close="personsBubbleOpen = false"-->
            <!--                    v-click-outside="closePersonBubble"-->
            <!--                  />-->
            <!--                </transition>-->
            <!--              </div>-->
            <!--              <div class="subtasks-rows__select-control-item">-->
            <!--                <date-input-->
            <!--                  @setDate="(date, callback) => setDateSelected(date)"-->
            <!--                  :open="true"-->
            <!--                  :afterConfirm="true"-->
            <!--                  :placeholder="'Срок'"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div-->
            <!--                class="subtasks-rows__select-control-item"-->
            <!--                ref="portal-wrapper-tags"-->
            <!--                @click="tagsBubbleOpen = !tagsBubbleOpen"-->
            <!--              >-->
            <!--                <p>Тэги</p>-->
            <!--                <portal to="common">-->
            <!--                  <transition name="fade">-->
            <!--                    <TagsListSearch-->
            <!--                      v-if="tagsBubbleOpen"-->
            <!--                      :taskTags="[]"-->
            <!--                      @choose="addTagSelected"-->
            <!--                      @close="tagsBubbleOpen = false"-->
            <!--                      v-portal="{ parent: $refs['portal-wrapper-tags'] }"-->
            <!--                    />-->
            <!--                  </transition>-->
            <!--                </portal>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                class="subtasks-rows__select-control-item"-->
            <!--                ref="portal-wrapper-tags"-->
            <!--                @click="settingsBubbleOpen = !settingsBubbleOpen"-->
            <!--              >-->
            <!--                <p>Настройки</p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                class="subtasks-rows__select-control-item"-->
            <!--                style="margin-left: auto"-->
            <!--                @click="deleteSelected"-->
            <!--              >-->
            <!--                Удалить-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <draggable
      :list="subtasks"
      class="task-create-subtasks-rows"
      @end="changeOrder(subtasks)"
      v-if="subtasks.length"
    >
      <SubtaskRow
        v-for="(task, index) in subtasks"
        :level="0"
        :key="task.Id"
        :item="task"
        :task="task"
        :index="index"
        :selectedItems="selectedItems"
        :newSubtask="newSubtask"
        :templateModel="templateModel"
        :noSave="noSave"
        :editedTemplateTasks="editedTemplateTasks"
        @changeOrder="changeOrder"
        @editTask="editTask"
        @editTemplateTask="
          (data, formData, callback) =>
            $emit('editTemplateTask', data, formData, callback)
        "
        @removeFromList="removeFromList"
        @setTaskToDetailPopup="setTaskToDetailPopup"
      />
    </draggable>
    <portal to="common">
      <transition name="popupSlide">
        <SubtaskDetailPopup
          v-if="selectedTaskForDetail"
          :task="selectedTaskForDetail"
          :templateModel="templateModel"
          :noSave="noSave"
          :editedTemplateTasks="editedTemplateTasks"
          @pushTasksToDeletedArray="$emit('pushTasksToDeletedArray', $event)"
          @editTemplateTask="
            (data, formData, callback) =>
              $emit('editTemplateTask', data, formData, callback)
          "
          @removeFile="
            (payload, errorCallback) =>
              $emit('removeFile', payload, errorCallback)
          "
          @onClosePopup="selectedTaskForDetail = null"
          @shiftSelection="($event) => $emit('shiftSelection', $event)"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import common from "../../../../../../mixins/tasks/common";
import SubtaskRow from "./SubtaskRow";
import * as _ from "lodash";
import draggable from "vuedraggable";
import { Helpers } from "@/mixins/Helpers";
import { mapActions, mapState } from "vuex";
import SubtaskDetailPopup from "@/modules/tasks/components/TaskCreateForm/components/SubtaskDetailPopup";
import { taskCreateFormTemplate } from "@/modules/tasks/components/TaskCreateForm/templateData";
import {
  ADD_FILES_TO_TEMPLATE_TASK,
  CREATE_TEMPLATE_TASK,
  DELETE_MULTIPLE_TEMPLATE_TASKS,
  EDIT_MULTIPLE_TEMPLATE_TASKS,
  EDIT_TEMPLATE_TASK,
  GET_TEMPLATE_TASK_DETAIL,
} from "@/store/tasks/constants";
import saveMixin from "@/modules/tasks/components/TaskCreateForm/mixins/saveMixin";
import ControlBarForSelectedTasks from "@/modules/tasks/components/TaskCreateForm/components/ControlBarForSelectedTasks";

export default {
  name: "SubtasksInput",
  mixins: [common, Helpers, saveMixin],
  props: [
    "form",
    "subtasks",
    "taskId",
    "templateModel",
    "noSave",
    "editedTemplateTasks",
  ],
  data() {
    return {
      personsBubbleOpen: false,
      selectedItems: [],
      tagsBubbleOpen: false,
      settingsBubbleOpen: false,
      selectedTaskForDetail: null,
      preloader: false,
    };
  },
  computed: {
    ...mapState("tasks", ["tags"]),
  },
  methods: {
    newSubtask() {
      return {
        ...JSON.parse(JSON.stringify(taskCreateFormTemplate)),
        Id: Math.random().toString(36).substring(7),
        subtasks: [],
      };
    },
    removeFromList(Id) {
      function recursiveFunc(array) {
        const elementIndex = array.findIndex((x) => x.Id === Id);
        if (elementIndex !== -1) {
          array.splice(elementIndex, 1);
        } else {
          _.each(array, (i) => {
            if (i.subtasks?.length) {
              recursiveFunc(i.subtasks);
            }
          });
        }
      }
      recursiveFunc(this.subtasks);
    },
    deleteSelectedTasks() {
      var tasksCopy = [...this.subtasks];

      this.selectedItems.forEach(({ Id }) => {
        this.removeFromList(Id);
      });

      if (this.templateModel && !this.noSave) {
        this[DELETE_MULTIPLE_TEMPLATE_TASKS]({
          TasksIds: this.selectedItems.map((i) => i.Id),
        }).catch(() => {
          this.$emit("setSubtasks", tasksCopy);
        });
      }
      this.$emit(
        "pushTasksToDeletedArray",
        this.selectedItems.filter((i) => i.Id.length > 7)
      );
      this.selectedItems = [];
    },
    setResponsibleSelected(userId) {
      const self = this;
      this.multipleEditTasks(
        (i) => {
          i.TemplateTaskSettings.ResponsibleId = userId;
          i.TemplateTaskSettings.ResponsibleModel = self.getUser(userId);
        },
        {
          ResponsibleId: userId,
        }
      );
    },
    closePersonBubble() {
      this.personsBubbleOpen = false;
    },
    addTask() {
      const taskToCreate = this.newSubtask();
      this.subtasks.push(taskToCreate);
      if (this.templateModel && !this.noSave) {
        this.preloader = true;
        this[CREATE_TEMPLATE_TASK]({
          ...taskToCreate,
          TemplateId: this.templateModel.Id,
          TemplateParentTaskId: this.taskId,
        })
          .then((task) => {
            if (task) {
              this.$emit("setSubtasks", [
                ...this.subtasks.filter((i) => i.Id !== taskToCreate.Id),
                task,
              ]);
            }
          })
          .catch(() => {
            this.$emit(
              "setSubtasks",
              this.subtasks.filter((i) => i.Id !== taskToCreate.Id)
            );
          })
          .finally(() => (this.preloader = false));
      }
    },
    async loadTaskDetail(task, callback) {
      let result = null;
      let self = this;
      function recursiveSetting(i, item, child) {
        if (i.Id === task.Id) {
          return (result = i = self.noSave
            ? self.prepareTemplateTask(i, item, child)
            : {
                ...i,
                ...self.prepareTask({ ...i, ...item }, child),
              });
        } else if (i.subtasks) {
          i.subtasks = i.subtasks.map((x) => {
            x = recursiveSetting(x, item, child);
            return x;
          });
        }
        return i;
      }

      await this[GET_TEMPLATE_TASK_DETAIL](task.Id).then(({ item, child }) => {
        this.$emit(
          "setSubtasks",
          this.subtasks.map((i) => {
            i = recursiveSetting(i, item, child);
            return i;
          })
        );
        callback();
      });

      return result;
    },
    async setTaskToDetailPopup(task, callback) {
      // this.preloader = true;
      if (this.templateModel && task.Id.length > 7) {
        task = await this.loadTaskDetail(task, callback);
      } else {
        callback();
      }
      this.selectedTaskForDetail = task;
      // this.preloader = false;
    },

    editTask(task) {
      this.$emit(
        "setSubtasks",
        _.map(this.subtasks, (i) => {
          if (i.Id === task.Id) {
            i = task;
          }
          return i;
        })
      );
    },
    editMultipleTasks(options, tasksCopy) {
      this[EDIT_MULTIPLE_TEMPLATE_TASKS]({
        TasksIds: this.selectedItems.map((i) => i.Id),
        NewOptions: options,
      }).catch(() => {
        this.tasks = JSON.parse(JSON.stringify(tasksCopy));
      });
    },
    ...mapActions("tasks", [
      CREATE_TEMPLATE_TASK,
      ADD_FILES_TO_TEMPLATE_TASK,
      EDIT_TEMPLATE_TASK,
      GET_TEMPLATE_TASK_DETAIL,
      EDIT_MULTIPLE_TEMPLATE_TASKS,
      DELETE_MULTIPLE_TEMPLATE_TASKS,
    ]),
  },
  components: {
    SubtaskRow,
    SubtaskDetailPopup,
    draggable,
    ControlBarForSelectedTasks,
  },
};
</script>

<style scoped>
.task-create-subtasks-rows {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin: 0 40px 23px 64px;
}
.task-create-form__row:after {
  border-bottom: 0 !important;
}
</style>
