import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  getSettings() {
    return axios.get(`${API_ROOT}api/sales/BuilderSettings/GetSettings`);
  },
  saveSettings(settings, type) {
    if (type === "standart") {
      return axios.post(
        `${API_ROOT}api/sales/BuilderSettings/SaveSettings`,
        settings
      );
    } else if (type === "qr") {
      return axios.post(
        `${API_ROOT}api/sales/BuilderSettings/SaveQrSettings`,
        settings
      );
    } else {
      return axios.post(
        `${API_ROOT}api/sales/BuilderSettings/SaveEconomySettings`,
        settings
      );
    }
  },
  saveOffer(offer) {
    return axios.post(`${API_ROOT}api/sales/ComOffers`, offer);
  },
  getOffers(params) {
    return axios.get(`${API_ROOT}api/sales/ComOffers`, { params });
  },
  updateOffer(offer, id) {
    return axios.post(`${API_ROOT}api/sales/ComOffers/${id}`, offer);
  },
  removeOffer(id) {
    return axios.delete(`${API_ROOT}api/sales/ComOffers/${id}`);
  },
  getRateValue() {
    return axios.get(`${API_ROOT}api/sales/Courses/getCbrCourse?${new Date()}`);
  },
  getCommercialSiteUrl() {
    return axios.get(`${API_ROOT}api/sales/ComOffers/getDomain/`);
  },
  getExamplesObjects() {
    return axios.get(`${API_ROOT}api/sales/BuilderSettings/examplesWorksList`);
  },
  createOfferLink(offerId, newState) {
    return axios.get(
      `${API_ROOT}api/sales/ComOffers/ChangenAccessState?offerId=${offerId}&newState=${newState}`
    );
  },
  buildOfferPage(offerId, html, additionalData) {
    return axios({
      url: `${API_ROOT}api/sales/ComOffers/BuildLinkForOffer?offerId=${offerId}`,
      method: "POST",
      // responseType: 'blob', // important
      data: {
        html,
        ...additionalData,
      },
    });
  },
};
