<template>
  <div class="cm-popup__left">
    <template v-if="loadedTask">
      <div
        class="cm-popup-nav__item"
        v-if="loadedTask.counters.childTasks>0"
        :class="{ 'cm-popup-nav__item--active': tab === 4 }"
        @click="$emit('changeTab', 4)"
      >
        Активность
        <div
          class="num num--circle"
          v-if="
            loadedTask.activity &&
            loadedTask.activity.filter((i) => i.NewNotification).length
          "
        >
          {{ loadedTask.activity.filter((i) => i.NewNotification).length }}
        </div>
      </div>
      <div
        class="cm-popup-nav__item"
        :class="{ 'cm-popup-nav__item--active': tab === 1 }"
        @click="$emit('changeTab', 1)"
      >
        Задача
      </div>
      <div
        class="cm-popup-nav__item"
        :class="{ 'cm-popup-nav__item--active': tab === 2 }"
        @click="$emit('changeTab', 2)"
      >
        Подзадачи
        <span class="num" v-if="subtasksLength">{{ subtasksLength }}</span>
      </div>
      <div
        class="cm-popup-nav__item"
        :class="{ 'cm-popup-nav__item--active': tab === 3 }"
        v-if="
          checkUserTaskRight('view_task_files') &&
          checkUserTaskRight('view_docs_for_sign')
        "
        @click="$emit('changeTab', 3)"
      >
        Все файлы
        <span class="num" v-if="loadedTask.files.length">{{
          loadedTask.files.length
        }}</span>
      </div>

      <div
        class="cm-popup-nav__item"
        :class="{ 'cm-popup-nav__item--active': tab === 5 }"
        @click="$emit('changeTab', 5)"
      >
        Автоматизации
        <svg width="18" height="18" style="margin-left: 7px">
          <use xlink:href="/static/images/tasks/sprite.svg#eye-hide-icon"></use>
        </svg>
        <span class="num">{{ loadedTask.counters.countAutomatizations }}</span>
      </div>

      <div
        class="task-popup__nav-item task-popup__nav-item--datepicker"
        :class="{ 'finish-date--red': dateExpired }"
        style="margin-top: 17px"
      >
        <div class="task-popup__nav-name">Дата завершения:</div>
        <div class="task-popup__nav-info">
          <svg
            width="21"
            height="23"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="margin-bottom: -3px; margin-right: 5px"
          >
            <path
              d="M16.9139 2.99988H2.98924C1.89061 2.99988 1 3.89531 1 4.99988V18.9999C1 20.1045 1.89061 20.9999 2.98924 20.9999H16.9139C18.0125 20.9999 18.9032 20.1045 18.9032 18.9999V4.99988C18.9032 3.89531 18.0125 2.99988 16.9139 2.99988Z"
              stroke="#797B7C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.9302 0.999939V4.99995"
              stroke="#797B7C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.97363 0.999939V4.99995"
              stroke="#797B7C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 9.00012H18.9032"
              stroke="#797B7C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <template v-if="loadedTask.entityModel.FinishDate">
            <div class="task-popup__nav-info-date">
              <p>{{ getFinishDate }}</p>
              <span class="task-table-person__desc"
                >Создана
                {{
                  loadedTask.entityModel.TimeCreate | formatReplyDateWithoutTime
                }}</span
              >
            </div>
          </template>
          <template v-else-if="checkUserTaskRight('change_dates')">
            <div class="task-popup__nav-info-date">
              Указать срок
              <span class="task-table-person__desc"
                >Создана
                {{
                  loadedTask.entityModel.TimeCreate | formatReplyDateWithoutTime
                }}</span
              >
            </div>
          </template>
          <template v-else>Дата не назначена</template>
        </div>
        <date-input
          v-if="checkUserTaskRight('change_dates')"
          :date="loadedTask.entityModel.FinishDate"
          :timeValue="loadedTask.entityModel.FinishTime"
          :needReasonToChange="
            +loadedTask.entityModel.FinishDateOnlyWithComment
          "
          @setDate="setDate"
        />
      </div>

      <div class="task-popup__nav-item task-bubble-wrap">
        <div class="task-popup__nav-name">Ответственный:</div>
        <div class="task-popup__nav-person">
          <PersonsListSearch
            v-if="responsibleBubbleOpen"
            :emptyPlaceholder="'Убрать ответственного'"
            :selectedPersonId="loadedTask.entityModel.ResponsibleId"
            :outerUsers="loadedTask.taskGroupUsers"
            :className="'task-bubble--templates'"
            @choose="
              (id, user) => {
                if (loadedTask.entityModel.ResponsibleId) {
                  responsibleUserToChange = user;
                  changeResponsiblePersonDialog = true;
                } else {
                  setResponsibleId(user);
                }
              }
            "
            @close="responsibleBubbleOpen = false"
          />
          <UserAvatar :user="loadedTask.entityModel.ResponsibleModel" />
          <div>
            <div
              class="task-table-person__name"
              @click="
                checkUserTaskRight('change_responsible') &&
                  (responsibleBubbleOpen = true)
              "
            >
              <template v-if="!loadedTask.entityModel.ResponsibleId"
                >Назначить</template
              >
              <template v-else>{{
                loadedTask.entityModel.ResponsibleModel.Fio | shortName
              }}</template>
            </div>
            <span
              class="task-table-person__desc"
              v-if="
                loadedTask.entityModel.ResponsibleId &&
                Object.keys(loadedTask.assignerUser).length
              "
              >Назначил {{ loadedTask.assignerUser.User.Fio | shortName }}</span
            >
          </div>
        </div>
      </div>
      <div class="task-popup__nav-item">
        <div class="task-popup__nav-name">Наблюдатели:</div>
        <div class="task-popup__nav-persons-list">
          <div
            class="task-popup__nav-persons-list__item"
            v-for="item in loadedTask.watchers.filter(
              (item, index) => index <= 6
            )"
            :key="item.Id"
            :title="item.Fio | shortName"
          >
            <UserAvatar :user="item.User" />
            <div
              class="task-table-person__delete"
              @click="deleteWatcher(item.Id)"
              v-if="checkUserTaskRight('change_watchers')"
            >
              <svg focusable="false" viewBox="0 0 32 32">
                <path
                  d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="task-create-form-subtasks__person">
            <div
              class="task-create-form-subtasks__person--add"
              v-if="
                checkUserTaskRight('change_watchers') &&
                loadedTask.watchers.length <= 7
              "
              @click="addWatcherBubbleOpen = true"
            >
              +
            </div>
            <div
              class="task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count"
              v-else-if="loadedTask.watchers.length > 7"
              @click="
                () => {
                  if (checkUserTaskRight('change_watchers'))
                    addWatcherBubbleOpen = true;
                }
              "
            >
              +{{ loadedTask.watchers.length - 7 }}
            </div>
            <PersonsListSearch
              :arr="loadedTask.watchers"
              v-if="addWatcherBubbleOpen"
              :excludeArray="[loadedTask.entityModel.ResponsibleId]"
              :outerUsers="loadedTask.taskGroupUsers"
              :className="'task-bubble--subscriber'"
              @deleteItem="deleteWatcher"
              @choose="addWatcher"
              @close="addWatcherBubbleOpen = false"
            />
          </div>
        </div>
      </div>

      <div
        class="task-popup__nav-item"
        v-if="statusFieldShow && taskStatusesArray.length"
        style="z-index: 5"
      >
        <div class="task-popup__nav-name">Статус:</div>
        <div
          class="task-popup__nav-labels task-labels task-popup__nav-labels--status"
          ref="portal-wrapper"
        >
          <span
            class="task-label"
            v-if="loadedTask.entityModel.StatusId && taskStatus"
            @click="
              checkUserTaskRight('change_status') &&
                (setStatusBubbleOpen = true)
            "
            :style="{ backgroundColor: taskStatus.Color }"
            >{{ taskStatus.Name }}
          </span>
          <span
            class="task-label task-label--btn"
            v-else
            @click="
              checkUserTaskRight('change_status') &&
                (setStatusBubbleOpen = true)
            "
          >
            <template v-if="checkUserTaskRight('change_status')">
              Установить статус
            </template>
            <template v-else> Статус не установлен </template>
          </span>
          <portal to="common">
            <transition name="fade">
              <StatusesListSearch
                v-if="setStatusBubbleOpen"
                :items="taskStatusesArray"
                :selectedId="loadedTask.entityModel.StatusId"
                @choose="setProcessStatus"
                @close="() => (setStatusBubbleOpen = false)"
                v-portal="{ parent: $refs['portal-wrapper'] }"
              />
            </transition>
          </portal>
        </div>
      </div>

      <div class="task-popup__nav-item" style="z-index: 4">
        <div class="task-popup__nav-name">Метки:</div>
        <div
          class="task-popup__nav-labels task-labels"
          ref="portal-wrapper-tags"
        >
          <span
            class="task-label"
            v-for="item in loadedTask.tags"
            :style="{ backgroundColor: item.Color }"
            :key="item.Id"
            >{{ item.TagName }}
            <div
              class="task-table-person__delete"
              v-if="checkUserTaskRight('change_tags')"
              @click="deleteTag(item.Id)"
            >
              <svg focusable="false" viewBox="0 0 32 32">
                <path
                  d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                ></path>
              </svg>
            </div>
          </span>
          <!--        <span class="task-label task-label&#45;&#45;1">Проектирование</span>-->
          <div
            class="task-create-form-subtasks__person"
            v-if="checkUserTaskRight('change_tags')"
          >
            <div
              class="task-create-form-subtasks__person--add"
              @click="addTagBubbleOpen = true"
            >
              +
            </div>
            <portal to="common">
              <transition name="fade">
                <TagsListSearch
                  v-if="addTagBubbleOpen"
                  :className="'task-bubble--tag'"
                  :taskTags="loadedTask.tags || []"
                  :task="loadedTask"
                  @choose="addTag"
                  @close="addTagBubbleOpen = false"
                  v-portal="{ parent: $refs['portal-wrapper-tags'] }"
                />
              </transition>
            </portal>
          </div>
        </div>
      </div>

      <!--      <div class="task-popup__nav-item">-->
      <!--        <div class="task-popup__nav-name">Подписчики:</div>-->
      <!--        <div class="task-popup__nav-persons-list">-->
      <!--          <div-->
      <!--            class="task-popup__nav-persons-list__item"-->
      <!--            v-for="(item, index) in loadedTask.subscriptions.filter(-->
      <!--              (item, index) => index <= 6 && item.UserId !== this.userData.Id-->
      <!--            )"-->
      <!--            :key="index"-->
      <!--            :title="getPersonNameById(item.UserId) | shortName"-->
      <!--          >-->
      <!--            <UserAvatar :userId="item.UserId" />-->
      <!--            <div-->
      <!--              class="task-table-person__delete"-->
      <!--              v-if="checkUserTaskRight('change_subscribers')"-->
      <!--              @click="deleteSubscriber(item.Id)"-->
      <!--            >-->
      <!--              <svg focusable="false" viewBox="0 0 32 32">-->
      <!--                <path-->
      <!--                  d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"-->
      <!--                ></path>-->
      <!--              </svg>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="task-create-form-subtasks__person">-->
      <!--            <div-->
      <!--              class="task-create-form-subtasks__person&#45;&#45;add"-->
      <!--              v-if="-->
      <!--                checkUserTaskRight('change_subscribers') &&-->
      <!--                loadedTask.subscriptions.length <= 7-->
      <!--              "-->
      <!--              @click="addSubscriberBubbleOpen = true"-->
      <!--            >-->
      <!--              +-->
      <!--            </div>-->
      <!--            <div-->
      <!--              class="task-create-form-subtasks__person&#45;&#45;add task-create-form-subtasks__person&#45;&#45;last-count"-->
      <!--              v-else-if="loadedTask.subscriptions.length > 7"-->
      <!--              @click="-->
      <!--                () => {-->
      <!--                  if (checkUserTaskRight('change_subscribers'))-->
      <!--                    addSubscriberBubbleOpen = true;-->
      <!--                }-->
      <!--              "-->
      <!--            >-->
      <!--              +{{ loadedTask.subscriptions.length - 7 }}-->
      <!--            </div>-->
      <!--            <PersonsListSearch-->
      <!--              :arr="loadedTask.subscriptions"-->
      <!--              v-if="addSubscriberBubbleOpen"-->
      <!--              :className="'task-bubble&#45;&#45;subscriber'"-->
      <!--              :excludeArray="[loadedTask.entityModel.ResponsibleId]"-->
      <!--              @choose="addSubscriber"-->
      <!--              @deleteItem="deleteSubscriber"-->
      <!--              @close="addSubscriberBubbleOpen = false"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="task-popup__nav-item task-bubble-wrap">-->
      <!--        <div class="task-popup__nav-name">Процесс:</div>-->
      <!--        <div class="task-popup__nav-info" @click="processesBubbleOpen = true">-->
      <!--          <div class="task-popup__nav-title">-->
      <!--                    <span class="task-popup__nav-item-left">-->
      <!--                      <svg width="12" height="12">-->
      <!--                      <use xlink:href="/static/images/tasks/sprite.svg#process-icon"></use>-->
      <!--                    </svg>-->
      <!--                    </span>-->
      <!--            <template v-if="loadedTask.entityModel.ProcessId">{{getProcessName(loadedTask.entityModel.ProcessId)}}</template>-->
      <!--            <template v-else>Назначить процесс</template>-->
      <!--          </div>-->
      <!--          <transition name="fade">-->
      <!--            <ProcessesListSearch-->
      <!--              v-if="processesBubbleOpen"-->
      <!--              :selectedId="loadedTask.entityModel.ProcessId"-->
      <!--              :className="'task-bubble&#45;&#45;templates'"-->
      <!--              @choose="setGroupProcess"-->
      <!--              @close="() => processesBubbleOpen = false"-->
      <!--            />-->
      <!--          </transition>-->

      <!--        </div>-->
      <!--      </div>-->

      <div
        class="task-popup__bottom-btn--approve btn-full btn-full__green"
        v-if="userMustToApprove"
        @click="approveTask"
      >
        Принять задачу
      </div>
      <div
        class="task-popup__bottom-btn t-gray-btn"
        @click="checkTaskForClose"
        v-else-if="
          checkUserTaskRight('close_task') &&
          loadedTask.entityModel.Status !== '2'
        "
      >
        Завершить задачу
      </div>
      <div
        class="task-popup__bottom-btn t-gray-btn"
        @click="setTaskStatus('1')"
        v-else-if="loadedTask.entityModel.Status === '2'"
      >
        Открыть заново
      </div>
    </template>

    <portal to="task-root-portal">
      <v-dialog v-model="agreeToClose" max-width="290">
        <v-card>
          <v-card-title class="headline">Предупреждение</v-card-title>
          <v-card-text> Будут закрыты также все дочерние задачи </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              @click="agreeToClose = false"
            >
              Отменить
            </v-btn>
            <v-btn
              color="red darken-1"
              flat="flat"
              @click="
                () => {
                  agreeToClose = false;
                  setTaskStatus('2');
                }
              "
            >
              Продолжить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </portal>

    <portal to="task-root-portal">
      <v-dialog v-model="changeResponsiblePersonDialog" max-width="290">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            Оставить пользователя
            <span
              style="color: #f9ad1a"
              v-if="loadedTask.entityModel.ResponsibleModel"
              >{{
                loadedTask.entityModel.ResponsibleModel.Fio | shortName
              }}</span
            >
            в подписчиках ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              @click="
                () => {
                  setResponsibleUserWithSaveToSubscribers(
                    responsibleUserToChange
                  );
                  changeResponsiblePersonDialog = false;
                }
              "
            >
              Да
            </v-btn>
            <v-btn
              color="red darken-1"
              flat="flat"
              @click="
                () => {
                  setResponsibleId(responsibleUserToChange);
                  changeResponsiblePersonDialog = false;
                }
              "
            >
              Нет
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </portal>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import * as c from "../../../../store/tasks/constants";
import PersonsListSearch from "../CommonComponents/PersonsListSearch";
import TagsListSearch from "../CommonComponents/TagsListSearch/TagsListSearch";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import DateInput from "../CommonComponents/DateInput";
import UserAvatar from "../CommonComponents/UserAvatar";
import * as _ from "lodash";
import StatusesListSearch from "../CommonComponents/StatusesListSearch";
import { Helpers } from "@/mixins/Helpers";
import Api from "../../../../api/tasks";

export default {
  mixins: [common, Helpers],
  props: ["tab", "loadedTask", "taskGroup"],
  data() {
    return {
      responsibleBubbleOpen: false,
      addSubscriberBubbleOpen: false,
      addWatcherBubbleOpen: false,
      addTagBubbleOpen: false,
      processesBubbleOpen: false,
      setStatusBubbleOpen: false,
      agreeToClose: false,
      changeResponsiblePersonDialog: false,
      responsibleUserToChange: null,
    };
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   if (this.responsibleBubbleOpen && !e.target.closest(".task-bubble-wrap")) {
    //     this.responsibleBubbleOpen = false;
    //   }
    // });
  },
  computed: {
    getFinishDate() {
      const date = moment(this.loadedTask.entityModel.FinishDate);
      const time = this.loadedTask.entityModel.FinishTime
        ? moment(this.loadedTask.entityModel.FinishTime, "HH:mm:ss")
        : false;
      if (time) {
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
      }

      return date.locale("ru").format("DD MMM YYYY в HH:mm");
    },
    userMustToApprove() {
      if (
        this.loadedTask.approveRecordId &&
        this.loadedTask.approveRecordId.UserId === this.userData.Id
      ) {
        return true;
      }
      return false;
    },
    taskStatus() {
      let found = null;
      _.each(this.processes, (i) => {
        if (!found) {
          found = _.find(i.statuses, [
            "Id",
            this.loadedTask.entityModel.StatusId,
          ]);
        }
      });
      return found;
    },
    taskStatusesArray() {
      if (this.loadedTask) {
        const found = _.find(this.processes, [
          "Id",
          this.loadedTask.entityModel.ParentProcessId,
        ]);
        return found ? found.statuses : [];
      }
      return [];
    },
    statusFieldShow() {
      return this.loadedTask.entityModel.ParentProcessId;
    },
    subtasksLength() {
      return (
        this.loadedTask.counters.childTasks +
        (this.loadedTask.depends ? this.loadedTask.depends.length : 0)
      );
    },
    dateExpired() {
      const timeExpired = this.loadedTask.entityModel.FinishTime;
      const finishDate = moment(this.loadedTask.entityModel.FinishDate);

      if (timeExpired) {
        const hour = timeExpired.split(":")[0];
        const minute = timeExpired.split(":")[1];
        finishDate.set({ hour, minute });
      }

      const toDay = moment();
      return toDay > finishDate;
    },
    ...mapState("tasks", [
      "processes",
      "tags",
      "groups",
      "selectedTask",
      "allGroups",
      "userData",
    ]),
  },
  methods: {
    setDate(date, callback, reasonComment) {
      this.loadedTask.entityModel.FinishDate = date;
      this.loadedTask.entityModel.FinishTime = moment(date).format("HH:mm:ss");

      const data = {
        FinishDate: date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
        FinishTime: date ? moment(date).format("HH:mm") : "",
      };
      if (reasonComment) data.FinishDateComment = reasonComment;
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data,
      };
      this[c.EDIT_TASK](payload);
    },
    setResponsibleUserWithSaveToSubscribers(user) {
      this.setResponsibleId(user, true);
    },
    setResponsibleId(user, subscribe = false) {
      const oldResponsiblId = this.loadedTask.entityModel.ResponsibleId;

      this.loadedTask.entityModel.ResponsibleId = user?.Id;
      this.loadedTask.entityModel.ResponsibleModel = user;

      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          ResponsibleId: user ? user.Id : null,
        },
      };
      this[c.EDIT_TASK](payload).then(() => {
        if (subscribe) {
          this.addSubscriber(oldResponsiblId);
        }
      });
    },
    setTaskStatus(Status) {
      this.loadedTask.entityModel.Status = Status;
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          Status,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    setProcessStatus(id) {
      this.loadedTask.entityModel.StatusId = id;
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          StatusId: id,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    openParentTask() {
      this.$router.push("/tasks/");
      setTimeout(() => {
        this.$router.push("/tasks/" + this.loadedTask.entityModel.ParentId);
      }, 300);
    },
    addSubscriber(userId) {
      this[c.ADD_SUBSCRIBER]({
        UserId: userId,
        TaskId: this.loadedTask.entityModel.Id,
      }).then((data) => this.loadedTask.subscriptions.push(data));
    },
    addWatcher(userId) {
      const subscriber = this.loadedTask.subscriptions.find(
        (i) => i.UserId === userId
      );
      if (subscriber) {
        this.deleteSubscriber(subscriber.Id);
      }
      this[c.ADD_WATCHER]({
        UserId: userId,
        TaskId: this.loadedTask.entityModel.Id,
      }).then((userDataFromServer) => {
        this.loadedTask.watchers.push({
          ...this.getUser(
            userDataFromServer.UserId,
            this.loadedTask.taskGroupUsers
          ),
          Id: userDataFromServer.Id,
        });
      });
    },
    addTag(tagId) {
      this[c.ADD_TAG_TO_TASK]({
        data: {
          TagId: tagId,
          TaskId: this.loadedTask.entityModel.Id,
        },
      }).then((data) => {
        if (!_.find(this.loadedTask.tags, ["Id", data.TagId])) {
          const foundTag = _.find(this.tags, ["Id", tagId]);
          const tag = { ...data, TagName: foundTag.TagName };
          this.loadedTask.tags.push(tag);
        }
      });
    },
    deleteSubscriber(Id) {
      this[c.DELETE_SUBSCRIBER](Id).then(
        () =>
          (this.loadedTask.subscriptions = _.reject(
            this.loadedTask.subscriptions,
            { Id }
          ))
      );
    },
    deleteWatcher(Id) {
      this[c.DELETE_WATCHER](Id).then(
        () =>
          (this.loadedTask.watchers = _.reject(this.loadedTask.watchers, {
            Id,
          }))
      );
    },
    deleteTag(Id) {
      if (_.find(this.loadedTask.tags, ["Id", Id])) {
        this.loadedTask.tags = _.reject(this.loadedTask.tags, { Id });
      }
      this[c.REMOVE_TAG_FROM_TASK]({ Id });
    },
    approveTask() {
      this[c.APPROVE_TASK](this.loadedTask.approveRecordId.Id);
      this.loadedTask.approveRecordId = null;
      // if (!_.find(this.loadedTask.subscriptions, ['UserId', el.UserId])) {
      //   users.push(el)
      // }
    },
    // setGroupProcess (id) {
    //   this.loadedTask.entityModel.ProcessId = id
    //   const payload = {
    //     id: this.loadedTask.entityModel.Id,
    //     data: {
    //       ProcessId: id
    //     }
    //   }
    //   this[c.EDIT_TASK](payload)
    // },
    checkTaskForClose() {
      let count = 0;
      Api.checkTaskHaveChildTasks(this.loadedTask.entityModel.Id).then(
        (res) => {
          if (res.data.status === "success") {
            count = res.data.count;
            if (!count) {
              this.setTaskStatus("2");
            } else {
              this.agreeToClose = true;
            }
          }
        }
      );
    },
    ...mapActions("tasks", [
      c.EDIT_TASK,
      c.ADD_SUBSCRIBER,
      c.DELETE_SUBSCRIBER,
      c.ADD_TAG_TO_TASK,
      c.REMOVE_TAG_FROM_TASK,
      c.ADD_WATCHER,
      c.DELETE_WATCHER,
      c.APPROVE_TASK,
    ]),
  },
  components: {
    PersonsListSearch,
    DateInput,
    TagsListSearch,
    UserAvatar,
    StatusesListSearch,
  },
};
</script>

<style scoped></style>
