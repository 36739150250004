<template>
  <div class="settings__container">
    <div class="header header_shadow">
      <div class="header__col">
        <ul class="tabs header__tabs">
          <li class="tabs__tab" :class="{ active: tab === 1 }" @click="tab = 1">
            <span class="tabs__link">МЦ</span>
          </li>
          <li class="tabs__tab" :class="{ active: tab === 2 }" @click="tab = 2">
            <span class="tabs__link">Модуль задачи</span>
          </li>
          <li class="tabs__tab" :class="{ active: tab === 3 }" @click="tab = 3">
            <span class="tabs__link">Настройки пользователя</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="settings-users__container" v-show="tab === 1">
      <UsersBlock
        :orderedUsers="orderedUsers"
        :user_load_preloader="user_load_preloader"
        :active_user="active_user"
        :active_role="active_role"
        :user_add_preloader="user_add_preloader"
        @search="searchUsers"
        @makeUserActive="makeUserActive"
        @toggleShowUserConfig="toggleShowUserConfig"
        @addUser="addUser"
      />
      <UsersConfig
        :style="is_user_config_show ? 'flex: 1;opacity:1' : 'flex: 0;opacity:0'"
        :user_form_preloader="user_form_preloader"
        :roles="roles"
        @deleteUser="deleteUser"
        @updateUser="updateUser"
      />
      <RoleBlock
        :role_load_preloader="role_load_preloader"
        :orderedRoles="orderedRoles"
        :active_user="active_user"
        :active_role="active_role"
        :role_add_preloader="role_add_preloader"
        @search="searchRoles"
        @toggleShowRoleConfig="toggleShowRoleConfig"
        @makeRoleActive="makeRoleActive"
        @addRole="addRole"
      />
      <RoleConfig
        :style="is_role_config_show ? 'flex: 1;opacity:1' : 'flex: 0;opacity:0'"
        :role_form_preloader="role_form_preloader"
        :active_role="active_role"
        :old_role_name="old_role_name"
        @makeNewRoleName="makeNewRoleName"
        @refreshActiveRole="refreshActiveRole"
        @updateRole="updateRole"
        @deleteRole="deleteRole"
      />
    </div>
    <tasks-module-settings v-show="tab === 2" :users="orderedUsers" />
    <user-settings v-if="tab === 3" />
  </div>
</template>

<script>
import UsersBlock from "./components/UsersBlock";
import UsersConfig from "./components/UsersConfig";
import RoleBlock from "./components/RoleBlock";
import RoleConfig from "./components/RoleConfig";
import TasksModuleSettings from "./tasks-module-settings";
import UserSettings from "./user-settings";
import axios from "axios";
import { mapActions, mapState } from "vuex";
import { API_ROOT } from "../../config";
import * as c from "@/store/common/constants";
import { UserSettingsValidate } from "@/mixins/UserSettingsValidate";
import { UsersSettingsForm } from "@/mixins/UsersSettingsForm";
import * as _ from "lodash";

import { GET_SETTINGS_RIGHT } from "../../store/common/constants";
import { Helpers } from "../../mixins/Helpers";

export default {
  mixins: [UserSettingsValidate, UsersSettingsForm, Helpers],
  data() {
    return {
      users: [],
      user_load_preloader: false,
      active_user: {
        Id: null,
        LoginName: null,
        Email: null,
        FullName: null,
        RoleId: null,
        Password: null,
      },
      active_role: {
        Id: null,
      },
      searchUsersInput: "",
      searchRolesInput: "",
      is_role_config_show: false,
      is_user_config_show: false,
      user_form_preloader: false,
      user_add_preloader: false,
      roles: [],
      role_load_preloader: false,
      role_form_preloader: false,
      role_add_preloader: false,
      right_in_roles: {},
      old_role_name: "",
      tab: 1,
    };
  },

  computed: {
    orderedUsers() {
      if (this.users.length) {
        return _.filter(_.orderBy(this.users, ["FullName"], ["asc"]), (i) => {
          const re = new RegExp(this.searchUsersInput, "gi");
          return re.test(i.FullName);
        });
      }
      return false;
    },
    orderedRoles() {
      if (this.roles.length) {
        return _.filter(_.orderBy(this.roles, ["FullName"], ["asc"]), (i) => {
          const re = new RegExp(this.searchRolesInput, "gi");
          return re.test(i.Name);
        });
      }
      return [];
    },
    ...mapState("common", ["config_user_settings", "rightsLoaded"]),
  },
  methods: {
    searchUsers(search) {
      this.searchUsersInput = search;
    },
    searchRoles(search) {
      this.searchRolesInput = search;
    },
    toggleShowUserConfig(data) {
      if (!data) {
        this.active_user = {
          Id: null,
          LoginName: null,
          Email: null,
          FullName: null,
          RoleId: null,
          Password: null,
        };
      } else {
        if (this.is_role_config_show) {
          this.is_role_config_show = false;
        }
      }
      this.is_user_config_show = data;
    },
    makeUserActive(data) {
      this.changeUserForm(data);
      this.active_user = data;
      this.toggleShowUserConfig(true);
    },
    changeUserForm(data) {
      this[c.CHANGE_USER_FORM](data);
    },
    ...mapActions("common", [c.CHANGE_USER_FORM, c.CHANGE_USER_FORM_FIELD]),
    addUser() {
      this.user_add_preloader = true;
      const LoginName =
        Math.floor(Math.random() * (4959999999 - 4950000000 + 1)) + 4950000000;
      const data = {
        LoginName: LoginName,
        Email: LoginName + "@r-p-s.ru",
        Password: LoginName,
        FullName: "НОВЫЙ ПОЛЬЗОВАТЕЛЬ",
        ListName: "Фамилия И.О.",
        Name: "Фамилия Имя Отчество",
        active: 0,
        firstname: "Имя",
        lastname: "Фамилия",
        middlename: "Отчество",
        model: "users",
        _IsDeleted: 0,
      };
      axios
        .post(API_ROOT + "api/settings/UserModel/", data)
        .then(() => {
          this.loadUsers({ new_user: true, login_name: LoginName });
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.user_add_preloader = false;
        });
    },
    loadUsers(data = { new_user: false, login_name: null }) {
      this.user_load_preloader = true;

      axios
        .get(API_ROOT + "api/settings/UserModel")
        .then((res) => {
          this.users = res.data;
          if (data.new_user) {
            let user;
            this.users.forEach((item) => {
              if (item.LoginName === String(data.login_name)) {
                user = item;
              }
            });
            this.makeUserActive(user);
          }
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.user_load_preloader = false;
        });
    },
    deleteUser() {
      this.user_form_preloader = true;
      axios
        .delete(
          API_ROOT + `api/settings/UserModel/${this.config_user_settings.Id}`
        )
        .then(() => {
          this.loadUsers();
          this.is_user_config_show = false;
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.user_form_preloader = false;
        });
    },
    updateUser() {
      if (this.checkForm()) {
        this.user_form_preloader = true;
        const formFields = this.config_user_settings;
        const userId = formFields.Id;
        const numberPattern = /\d+/g;
        const LoginId = formFields.phone
          .match(numberPattern)
          .join("")
          .substring(1);
        const data = {
          Email: formFields.email,
          FullName: formFields.fullName,
          Id: userId,
          LoginName: LoginId,
          Name: formFields.fullName,
          RoleId: formFields.roleId ? formFields.roleId : null,
          activator: false,
          active: 1,
          firstname: formFields.firstname,
          id: userId,
          lastname: formFields.lastname,
          middlename: formFields.middlename,
          ObjectRequestNotification: formFields.ObjectRequestNotification,
          model: "users",
          _IsDeleted: "0",
          _id: userId,
        };

        if (formFields.password) {
          data.Password = formFields.password;
          data.PasswordConfirm = formFields.confirm_password;
        }

        axios
          .put(
            API_ROOT + "api/settings/UserModel/" + this.config_user_settings.Id,
            data
          )
          .then(() => {
            this.$notify({
              group: "common",
              title: "Успешно",
              text: "Пользователь сохранен",
              type: "success",
            });
            this.users = this.users.map((user) => {
              if (user.Id === data.Id) {
                user = data;
                this.active_user = user;
              }
              return user;
            });
            this.loadRoles();
          })
          .catch((err) => {
            this.$notify({
              group: "common",
              title: "Ошибка",
              text: err.response,
              type: "error",
            });
          })
          .finally(() => {
            this.user_form_preloader = false;
          });
      }
    },
    loadRoles() {
      this.role_load_preloader = true;
      axios
        .get(API_ROOT + "api/settings/RoleModel")
        .then((res) => {
          this.roles = res.data;
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.role_load_preloader = false;
        });
    },
    toggleShowRoleConfig(data) {
      if (!data) {
        this.active_role = { Id: null };
      } else {
        if (this.is_user_config_show) {
          this.is_user_config_show = false;
        }
      }
      this.is_role_config_show = data;
    },
    makeRoleActive(id) {
      this.role_form_preloader = true;
      this.toggleShowRoleConfig(true);

      axios
        .get(API_ROOT + "api/settings/RoleModel/" + id)
        .then((res) => {
          let roleCopy = JSON.parse(JSON.stringify(res.data));
          this.right_in_roles = roleCopy.Rights;
          this.active_role = res.data;
          this.old_role_name = res.data.Name;
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.role_form_preloader = false;
        });
    },
    addRole() {
      this.role_form_preloader = true;
      this.role_load_preloader = true;
      const data = {
        Active: "0",
        ChangedDateTime: "",
        Deleted: "0",
        FullAccess: "0",
        ListName: "НОВАЯ РОЛЬ",
        Name: "НОВАЯ РОЛЬ",
        ParkingId: "0",
        active: 0,
        add_btn: "НОВОЕ ПРАВО",
        header: "Права",
        model: "roles",
        search: "",
      };
      axios
        .post(API_ROOT + "api/settings/RoleModel/", data)
        .then((res) => {
          this.roles.push(res.data);
          this.makeRoleActive(res.data.Id);
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.role_form_preloader = false;
          this.role_load_preloader = false;
        });
    },
    makeNewRoleName(name) {
      this.active_role.Name = name;
    },
    refreshActiveRole(data) {
      this.active_role = data[0];
      this.old_role_name = data[0].Name;
      const rightId = data[1];
      this.active_role.Rights.forEach((item) => {
        if (item.Id === rightId) {
          if (item.Active === 0) {
            item.Children.forEach((subItem) => {
              subItem.Active = 0;
              subItem.Children.forEach((subSubItem) => {
                subSubItem.Active = 0;
                subSubItem.Children.forEach((subSubSubItem) => {
                  subSubSubItem.Active = 0;
                });
              });
            });
          }
        }
        item.Children.forEach((subItem) => {
          if (subItem.Id === rightId) {
            if (subItem.Active === 1) {
              item.Active = 1;
            } else {
              subItem.Children.forEach((subSubItem) => {
                subSubItem.Active = 0;
                subSubItem.Children.forEach((subSubSubItem) => {
                  subSubSubItem.Active = 0;
                });
              });
            }
          }
          subItem.Children.forEach((subSubItem) => {
            if (subSubItem.Id === rightId) {
              if (subSubItem.Active === 1) {
                item.Active = 1;
                subItem.Active = 1;
              } else {
                subSubItem.Children.forEach((subSubSubItem) => {
                  subSubSubItem.Active = 0;
                });
              }
            }
            subSubItem.Children.forEach((subSubSubItem) => {
              if (subSubSubItem.Active === 1) {
                item.Active = 1;
                subItem.Active = 1;
                subSubItem.Active = 1;
              }
            });
          });
        });
      });
    },
    updateRole() {
      this.role_form_preloader = true;
      const data = this.active_role;
      const rightInRoles = this.right_in_roles;
      data.Rights.forEach((item) => {
        rightInRoles.forEach((subItem) => {
          if (item.Id === subItem.Id && item.Active !== subItem.Active) {
            let searchRight;
            data.AllRights.forEach((allRightItem) => {
              if (
                data.Id === allRightItem.RoleId &&
                item.Id === allRightItem.RightId
              ) {
                searchRight = allRightItem;
                searchRight.Active = item.Active;
              }
            });
            if (searchRight) {
              axios.put(
                API_ROOT + `api/settings/RightsInRoleModel/${searchRight.Id}`,
                searchRight
              );
            }
          }
          item.Children.forEach((itemChild) => {
            subItem.Children.forEach((subItemChild) => {
              if (
                itemChild.Id === subItemChild.Id &&
                itemChild.Active !== subItemChild.Active
              ) {
                let searchRight;
                data.AllRights.forEach((allRightItem) => {
                  if (
                    data.Id === allRightItem.RoleId &&
                    itemChild.Id === allRightItem.RightId
                  ) {
                    searchRight = allRightItem;
                    searchRight.Active = itemChild.Active;
                  }
                });
                if (searchRight) {
                  axios.put(
                    API_ROOT +
                      `api/settings/RightsInRoleModel/${searchRight.Id}`,
                    searchRight
                  );
                }
              }
              itemChild.Children.forEach((itemChild2) => {
                subItemChild.Children.forEach((subItemChild2) => {
                  if (
                    itemChild2.Id === subItemChild2.Id &&
                    itemChild2.Active !== subItemChild2.Active
                  ) {
                    let searchRight;
                    data.AllRights.forEach((allRightItem) => {
                      if (
                        data.Id === allRightItem.RoleId &&
                        itemChild2.Id === allRightItem.RightId
                      ) {
                        searchRight = allRightItem;
                        searchRight.Active = itemChild2.Active;
                      }
                    });
                    if (searchRight) {
                      axios.put(
                        API_ROOT +
                          `api/settings/RightsInRoleModel/${searchRight.Id}`,
                        searchRight
                      );
                    }
                  }
                  itemChild2.Children.forEach((itemChild3) => {
                    subItemChild2.Children.forEach((subItemChild3) => {
                      if (
                        itemChild3.Id === subItemChild3.Id &&
                        itemChild3.Active !== subItemChild3.Active
                      ) {
                        let searchRight;
                        data.AllRights.forEach((allRightItem) => {
                          if (
                            data.Id === allRightItem.RoleId &&
                            itemChild3.Id === allRightItem.RightId
                          ) {
                            searchRight = allRightItem;
                            searchRight.Active = itemChild3.Active;
                          }
                        });
                        if (searchRight) {
                          axios.put(
                            API_ROOT +
                              `api/settings/RightsInRoleModel/${searchRight.Id}`,
                            searchRight
                          );
                        }
                      }
                    });
                  });
                });
              });
            });
          });
        });
      });
      axios
        .put(API_ROOT + `api/settings/RoleModel/${data.Id}`, data)
        .then((res) => {
          let roleCopy = JSON.parse(JSON.stringify(res.data));
          this.roles = this.roles.map((role) => {
            if (role.Id === data.Id) {
              role = data;
            }
            return role;
          });
          this.right_in_roles = roleCopy.Rights;
          this.$notify({
            group: "common",
            title: "Статус",
            text: "Роль успешно сохраненна",
            type: "success",
          });
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.role_form_preloader = false;
        });
    },
    deleteRole(id) {
      this.role_form_preloader = true;

      axios
        .delete(API_ROOT + `api/settings/RoleModel/${id}`)
        .then(() => {
          this.loadRoles();
          this.toggleShowRoleConfig(false);
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response,
            type: "error",
          });
        })
        .finally(() => {
          this.role_form_preloader = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const timerId = setInterval(() => {
        if (vm.rightsLoaded) {
          if (vm.checkUserRight(GET_SETTINGS_RIGHT)) {
            vm.loadUsers();
            vm.loadRoles();
          } else {
            vm.$router.push("/support");
          }
          clearInterval(timerId);
        }
      }, 200);
    });
  },
  components: {
    UsersBlock,
    UsersConfig,
    RoleBlock,
    RoleConfig,
    TasksModuleSettings,
    UserSettings,
  },
};
</script>

<style scoped lang="scss">
.settings__container {
  height: 100%;
  padding: 0;
}
.header {
  margin-bottom: 36px;
}
.settings-users__container {
  height: calc(100% - 150px);
}
.tabs__tab {
  cursor: pointer;
}

.settings-users_block__name {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
