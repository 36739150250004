<template>
  <div class="table-row-person">
    <transition name="fade">
      <PersonsListSearch
        v-if="portalOpen && users.length"
        :emptyPlaceholder="'Убрать ответственного'"
        :selectedPersonId="item.ResponsibleId"
        :className="'task-bubble--templates'"
        @choose="setResponsibleId"
        @close="open = false"
        v-click-outside="closePersonBubble"
      />
    </transition>
    <div
      class="task-table-person"
      v-if="checkUserTaskRight('change_responsible') || local"
      @click="open = true"
      :class="{ 'task-table-person--task-not-accepted': taskNotAccepted }"
    >
      <template v-if="item.ResponsibleModel">
        <div class="task-table-person__avatar">
          <UserAvatar :user="item.ResponsibleModel" />
          <div class="task-table-person__add-btn">+</div>
        </div>
        <template v-if="!local">
          <div class="task-table-person__name-wrap">
            <div class="task-table-person__name">
              {{ item.ResponsibleModel.Fio | shortName }}
            </div>
            <span
              class="task-table-person__desc"
              v-if="
                item.ResponsibleModel &&
                !hideDesc &&
                Object.keys(item.AssignerUser).length
              "
              >Назначил {{ item.AssignerUser.User.Fio | shortName }}</span
            >
          </div>
        </template>
      </template>
      <template v-else-if="users.length">
        <span class="table-c__name" style="font-size: 12px" v-if="!local"
          >Назначить</span
        >
        <svg width="24" height="24" class="task-table-person__circle" v-else>
          <use
            xlink:href="/static/images/tasks/sprite.svg#empty-user-icon"
          ></use>
        </svg>
      </template>
    </div>
    <div class="task-table-person" v-else>
      <template v-if="item.ResponsibleModel">
        <UserAvatar :user="item.ResponsibleModel" />
        <span class="table-c__name _fit-col">
          {{ item.ResponsibleModel.Fio | shortName }}
        </span>
      </template>
      <template v-else>
        <span class="table-c__name _fit-col">Ответственный не назначен</span>
      </template>
    </div>
  </div>
</template>

<script>
import PersonsListSearch from "../../CommonComponents/PersonsListSearch";
import common from "../../../../../mixins/tasks/common";
import moment from "moment";
import * as c from "../../../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import { Helpers } from "../../../../../mixins/Helpers";
import UserAvatar from "../../CommonComponents/UserAvatar";

export default {
  mixins: [common, Helpers],
  props: ["item", "local", "taskNotAccepted", "hideDesc"],
  data() {
    return {
      open: false,
      portalOpen: false,
    };
  },
  computed: {
    ...mapState("tasks", ["users"]),
  },
  methods: {
    setResponsibleId(id, user) {
      this.item.ResponsibleId = id;
      if (!this.local) {
        const payload = {
          id: this.item.Id,
          data: {
            ResponsibleId: id,
            FinishDate: this.item.FinishDate
              ? moment(this.item.FinishDate).format("YYYY-MM-DD HH:mm:ss")
              : this.item.FinishDate,
          },
        };
        this[c.EDIT_TASK](payload);
      } else {
        this.item.ResponsibleModel = user;
      }
    },
    closePersonBubble() {
      this.open = false;
    },
    ...mapActions("tasks", [c.EDIT_TASK]),
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.portalOpen = true;
        });
      } else {
        this.portalOpen = false;
      }
    },
  },
  components: {
    PersonsListSearch,
    UserAvatar,
  },
};
</script>

<style scoped></style>
