<template>
  <div class="task-comments-wrap">
    <div class="comments-group-wrap" ref="wrapper">
      <resize-observer @notify="handleResize" />
      <div
        class="task-comments__show-more"
        v-if="tasksWillLoadCount > 0"
        @click="loadMore"
      >
        <span>Показать еще комментариев: {{ tasksWillLoadCount }}</span>
      </div>
      <comment-item
        v-for="(item, index) in comments"
        :item="item"
        :key="index + 1"
        :replyComment="replyComment"
        :loadedTask="loadedTask"
        @goRoReplyMessage="goRoReplyMessage"
        @setReplyComment="setReplyComment"
      />
    </div>
    <div class="task-comments__preloader" v-if="preloader">
      <Spinner
        :noFadeIn="true"
        name="ball-beat"
        color="#65c8f0"
        width="60"
        height="60"
      />
    </div>
    <CommentInput
      v-if="loadedTask.entityModel.EnableComments !== '0'"
      ref="commentInput"
      :replyComment="replyComment"
      :loadedTask="loadedTask"
      @removeReply="replyComment = null"
      @hideDragZone="$emit('hideDragZone')"
    />
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { mapActions, mapState } from "vuex";
import CommentItem from "./CommentItem";
import textSubscriber from "../../../../../mixins/tasks/textSubscriber";
import CommentInput from "@/modules/tasks/components/TaskDetail/CommentsControl/CommentInput";
import { GET_COMMENTS_FOR_TASK } from "@/store/tasks/constants";

export default {
  mixins: [common, textSubscriber],
  props: ["loadedTask", "page", "totalCountComments"],
  data() {
    return {
      replyComment: null,
      preloader: false,
      firstLoad: false,
      limit: 50,
      initialHeight: 0,
      settingScroll: false,
    };
  },
  created() {
    if (!this.page) {
      this[GET_COMMENTS_FOR_TASK]({
        taskId: this.loadedTask.entityModel.Id,
        page: this.page,
        limit: this.limit,
      }).then(({ comments, totalCountComments }) => {
        this.loadedTask.comments = comments.reverse();
        this.$emit("setTotalCountComments", totalCountComments);
        setTimeout(() => {
          document.querySelector(".task-popup-detail__right").scrollTop =
            document.querySelector(".task-popup-detail__right").scrollHeight -
            400;
          setTimeout(() => {
            this.firstLoad = true;
          }, 200);
          this.initialHeight = this.$refs.wrapper.clientHeight;
          this.$emit("setPage", 1);
        });
      });
    }
  },
  mounted() {},
  computed: {
    notLoadedCommentsCount() {
      if (this.totalCountComments && this.loadedTask.comments) {
        return this.totalCountComments - this.loadedTask.comments.length;
      }
      return 0;
    },
    tasksWillLoadCount() {
      if (this.notLoadedCommentsCount) {
        if (this.notLoadedCommentsCount > this.limit) return this.limit;
        return this.notLoadedCommentsCount;
      }
      return 0;
    },
    comments() {
      return this.loadedTask.comments;
      // return this.loadedTask.comments.filter(
      //   (i) => !i.Comment.includes("В задачу добавлены новые файлы")
      // );
    },
    ...mapState("tasks", ["selectedTask", "userData"]),
  },
  methods: {
    setReplyComment(comment) {
      this.replyComment = comment;

      const quill = this.$refs.commentInput.$refs.editor.quill;
      const retain = this.settings.textBubbleChoords.retain;
      let subscriberName = this.replyComment.AuthorName;

      subscriberName =
        "@" + this.$options.filters.shortName_NICKNAME(subscriberName);
      const delta = {
        ops: [
          {
            insert: subscriberName,
            attributes: { link: "userId=" + this.replyComment.UserId },
          },
          { insert: "  " },
        ],
      };
      quill.updateContents(delta);
      quill.setSelection(retain + subscriberName.length + 1, 0);

      this.$refs.commentInput.$refs.editor.quill.focus();
    },
    goRoReplyMessage(replyMessage) {
      scroll();
      function scroll() {
        const replyEl = document.getElementById(
          "task-comments-item__" + replyMessage.Id
        );
        replyEl.classList.add("task-comments-item--select");
        setTimeout(() => {
          replyEl.classList.remove("task-comments-item--select");
        }, 1000);
        document.querySelector(".task-popup-detail__right").scrollTop =
          replyEl.offsetTop - 150;
      }
    },
    loadMore() {
      if (!this.preloader) {
        this.preloader = true;
        this.$emit("setPage", this.page + 1);
        this[GET_COMMENTS_FOR_TASK]({
          taskId: this.loadedTask.entityModel.Id,
          page: this.page,
          limit: this.limit,
        })
          .then(({ comments }) => {
            this.loadedTask.comments.unshift(...comments.reverse());
            // if (!comments.length) this.loadedAllComments = true;
            // this.comments.push(...comments);
          })
          .finally(() => (this.preloader = false));
      }
    },
    handleResize({ height }) {
      if (this.firstLoad) {
        this.settingScroll = true;
        console.log(
          this.initialHeight,
          height,
          document.querySelector(".task-popup-detail__right").scrollTop
        );
        const diff = height - this.initialHeight;
        console.log("diff", diff);
        document.querySelector(".task-popup-detail__right").scrollTop += diff;
        this.initialHeight = height;
        setTimeout(() => {
          this.settingScroll = false;
        }, 200);
      }
    },
    ...mapActions("tasks", [GET_COMMENTS_FOR_TASK]),
  },
  components: {
    CommentItem,
    CommentInput,
  },
};
</script>
