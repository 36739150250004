<template>
  <div class="main__body wiki active" id="main">
    <div class="ckeditor active">
      <!--      <div class="mb-20" v-if="currentCategory">Добавить статью для категории "{{currentCategory.Name}}"</div>-->
      <form @submit.prevent="edit">
        <div class="form-group field-wiki-name required">
          <label class="control-label"> Название</label>
          <input
            class="ui-input-field mb-20 ui-input-field__edit"
            v-model="form.Name"
          />
        </div>
        <div class="form-group field-wiki-sort required">
          <label class="control-label">letters_numbers_symbol</label>
          <input
            type="number"
            class="ui-input-field ui-input-field__edit"
            value="10"
            min="1"
            v-model="form.Sort"
          />
        </div>
        <div class="form-group field-wiki-sort_alph">
          <label class="ui-input"
            ><span class="ui-input-label">Сортировка по алфавиту?</span>
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div id="wiki-sort_alph">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.SortAlph"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.SortAlph"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <div class="form-group field-wiki-moderation required">
          <label class="ui-input"
            ><span class="ui-input-label">На модерации?</span>
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div id="wiki-moderation" aria-required="true">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <div class="form-group field-wiki-moderation required">
          <label class="ui-input"
            ><span class="ui-input-label">Расшарить дочерние статьи ?</span>
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div aria-required="true">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.ShareChildPosts"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.ShareChildPosts"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <div class="form-group field-wiki-moderation required">
          <label class="ui-input"
            ><span class="ui-input-label"
              >Вывод дочерних статей в виде нередактируемой секции</span
            >
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div aria-required="true">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.ShowChildPostSection"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.ShowChildPostSection"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <br />
        <div class="modal-buttons">
          <!--
          <div class="modal-button modal-button--accept">
            <a class="modal-show" href="#">Предпросмотр</a>
          </div>
        -->
          <div class="modal-button modal-button--accept">
            <a class="modal-save" href="#">
              <button type="submit">Сохранить</button>
            </a>
          </div>
          <div
            class="modal-button modal-button--refresh-link"
            @click.prevent="deleteArticle"
          >
            <a class="modal-save">
              <button type="button">Удалить</button>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DELETE_ARTICLE, EDIT_ARTICLE } from "../../../store/wiki/constants";
import * as _ from "lodash";

export default {
  props: ["article", "currentCategory"],
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.article));
  },
  methods: {
    edit() {
      const formData = new FormData();
      this.form.IsShare = 1;
      _.each(this.form, (val, key) => {
        if (val) formData.append(key, val);
      });
      formData.append("_method", "PATCH");

      this[EDIT_ARTICLE]({ data: formData, id: this.article.Id }).then(
        (data) => {
          this.$emit("setCurrentArticle", data);
          setTimeout(() => {
            Array.from(document.querySelectorAll(".sidebar__item-name")).find(
              (el) => {
                if (el.textContent.trim() === this.form.Name.trim()) {
                  el.closest(".sidebar__item").click();
                }
                return el.textContent === this.form.Name;
              }
            );
          }, 100);
        }
      );
    },
    deleteArticle() {
      this[DELETE_ARTICLE](this.article.Id).then(() => {
        this.$emit("setCurrentArticle", null);
      });
    },
    ...mapActions("wiki", [EDIT_ARTICLE, DELETE_ARTICLE]),
  },
};
</script>

<style scoped></style>
