<template>
  <div class="tags-controller">
    <div class="tasks-roles-settings">
      <div class="tasks-roles-settings__label">Группы задач:</div>
      <br />
      <ul>
        <li
          v-for="(item, index) in computedGroups"
          :key="index"
          style="margin-bottom: 8px"
        >
          <span @click="selectGroup(item)" :class="getGroupClass(item)">{{
            item.Name || item.GroupName
          }}</span>

          <div>
            <span
              class="addTo"
              v-if="checkTaskGroupRelation(item)"
              @click="removeGroupFromTagsGroup(item)"
              style="text-decoration: line-through"
              >отвязать</span
            >
            <span
              class="addTo"
              v-else-if="selectedGroupsTag"
              @click="addGroupToTagsGroup(item)"
              >привязать</span
            >
          </div>
          <div>
            <span
              class="addTo"
              v-if="checkTagInTaskGroupRelation(item)"
              @click="removeTagFromTaskGroup(item.Tags)"
              style="text-decoration: line-through"
              >отвязать тег</span
            >
            <span
              class="addTo"
              v-else-if="selectedTag"
              @click="addTagToTaskGroup(item)"
              >привязать тег</span
            >
          </div>
        </li>
      </ul>
    </div>
    <div class="tasks-roles-settings">
      <div class="tasks-roles-settings__label">Группы тегов:</div>
      <br />
      <ul>
        <li
          :key="item.Id"
          v-for="item in tagGroups"
          :class="getTagsGroupClass(item)"
          style="margin-top: 10px"
        >
          <div @click="selectGroupsTag(item)" style="display: inline">
            <input
              type="text"
              v-model="item.GroupName"
              @blur="editTagsGroup(item)"
            />
          </div>

          <!--          <template v-if="selectedTag">-->
          <span
            class="addTo"
            v-if="checkTagRelation(item)"
            @click="removeTagFromGroup(item.tags)"
            style="text-decoration: line-through"
            >отвязать</span
          >
          <span
            class="addTo"
            v-else-if="selectedTag"
            @click="addTagToGroup(item.Id)"
            >привязать</span
          >
          <!--          </template>-->
        </li>
      </ul>
      <button
        @click="createTagsGroup"
        style="margin-top: 30px; text-align: left; color: #46a546"
      >
        + Добавить
      </button>
    </div>
    <div class="tasks-roles-settings">
      <div class="tasks-roles-settings__label">Теги:</div>
      <br />
      <ul>
        <li
          :key="item.Id"
          :class="getTagClass(item)"
          v-for="item in tags"
          style="margin-top: 10px; display: flex"
        >
          <div @click="selectTag(item)">
            <span
              style="
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 6px;
              "
              :style="{ background: item.Color }"
            ></span>
            <input type="text" v-model="item.TagName" @blur="editTag(item)" />
          </div>

          <div
            class="vc-sketch-wrap"
            v-if="selectedTag && selectedTag.Id === item.Id"
          >
            <sketch
              :value="item.Color"
              @input="setColor(item, $event)"
              @blur="editTag(item)"
            />
            <button
              class="button button_green button_uppercase mt-15"
              @click="saveColor(item)"
            >
              Сохранить
            </button>
          </div>
        </li>
      </ul>
      <button
        @click="createTag"
        style="margin-top: 30px; text-align: left; color: #46a546"
      >
        + Добавить
      </button>
    </div>
  </div>
</template>

<script>
import { getRandomColor, showAlert } from "../../../../utils";

import { mapActions, mapState } from "vuex";
import * as c from "../../../../store/tasks/constants";
import * as _ from "lodash";
import { Sketch } from "vue-color";
import Api from "../../../../api/tasks";
import { Helpers } from "@/mixins/Helpers";

export default {
  mixins: [Helpers],
  data() {
    return {
      selectedTag: null,
      selectedGroupsTag: null,
      selectedGroup: null,
      groups: [],
    };
  },
  created() {
    this[c.GET_TAGS]();
    this[c.GET_TAG_GROUPS]();
    this.groups = this.allGroups;
  },
  computed: {
    computedGroups: {
      get() {
        return this.groups.length ? this.groups : this.allGroups;
      },
      set(val) {
        this.groups = val;
      },
    },
    ...mapState("tasks", ["tagGroups", "tags", "allGroups"]),
  },
  methods: {
    createTagsGroup() {
      this[c.CREATE_TAGS_GROUP]({
        GroupName: "Новая группа",
      });
    },
    editTagsGroup(item) {
      if (item.GroupName) {
        this[c.EDIT_TAGS_GROUP]({
          id: item.Id,
          data: item,
        });
      } else {
        this[c.DELETE_TAGS_GROUP](item.Id);
      }
    },
    createTag() {
      this[c.CREATE_TAG]({
        TagName: "Новый тег",
        Color: getRandomColor(),
      });
    },
    editTag(item) {
      if (item.TagName) {
        return this[c.EDIT_TAG]({
          id: item.Id,
          data: item,
        });
      } else {
        this[c.DELETE_TAG](item.Id);
      }
    },
    selectGroupsTag(item) {
      this.selectedTag = null;
      if (this.selectedGroupsTag && this.selectedGroupsTag.Id === item.Id) {
        this.selectedGroupsTag = null;
      } else {
        this.selectedGroupsTag = item;
      }
    },
    selectTag(item) {
      this.selectedGroupsTag = null;
      if (this.selectedTag && this.selectedTag.Id === item.Id) {
        this.selectedTag = null;
      } else {
        this.selectedTag = item;
      }
    },
    addTagToGroup(id) {
      this[c.ADD_TAG_TO_GROUP]({
        TagId: this.selectedTag.Id,
        GroupId: id,
      }).then(() => {
        this[c.GET_TAG_GROUPS]();
      });
    },
    removeTagFromGroup(tags) {
      const relateId = _.find(tags, ["TagId", this.selectedTag.Id]);
      this[c.REMOVE_TAG_FROM_GROUP]({
        Id: relateId.Id,
      }).then(() => {
        this[c.GET_TAG_GROUPS]();
      });
    },
    getTagClass(item) {
      let className = "";

      if (
        this.checkGroupsTagRelation(item) ||
        this.checkTaskGroupsTagRelation(item)
      ) {
        className += " related";
      }
      if (this.selectedTag && item.Id === this.selectedTag.Id) {
        className = "editing";
      }
      return className;
    },
    getGroupClass(item) {
      let className = "";
      if (this.checkTaskGroupRelation(item)) {
        className += " related";
      }
      if (this.selectedGroup && item.Id === this.selectedGroup.Id) {
        className = "editing";
      }
      return className;
    },
    getTagsGroupClass(item) {
      let className = "";
      if (this.checkTagRelation(item) || this.checkTagGroupRelation(item)) {
        className += " related";
      }
      if (this.selectedGroupsTag && item.Id === this.selectedGroupsTag.Id) {
        className = "editing";
      }
      return className;
    },
    checkTagRelation(item) {
      if (this.selectedTag && item.tags) {
        return _.find(item.tags, ["TagId", this.selectedTag.Id]);
      }
    },
    checkGroupsTagRelation(item) {
      if (this.selectedGroupsTag) {
        return _.find(this.selectedGroupsTag.tags, ["TagId", item.Id]);
      }
    },
    checkTaskGroupsTagRelation(item) {
      if (this.selectedGroup) {
        return _.find(this.selectedGroup.Tags, ["TagId", item.Id]);
      }
    },
    checkTagGroupRelation(item) {
      if (this.selectedGroup) {
        return _.find(this.selectedGroup.TagsGroups, ["TagGroupId", item.Id]);
      }
    },
    checkTaskGroupRelation(item) {
      if (this.selectedGroupsTag && item.TagsGroups) {
        return _.find(item.TagsGroups, [
          "TagGroupId",
          this.selectedGroupsTag.Id,
        ]);
      }
    },
    checkTagInTaskGroupRelation(item) {
      if (this.selectedTag) {
        return _.find(item.Tags, ["TagId", this.selectedTag.Id]);
      }
    },
    setColor(item, event) {
      item.Color = event.hex;
    },
    saveColor(item) {
      this.editTag(item).then(() => {
        showAlert("success", "", "Цвет сохранен");
      });
    },
    selectGroup(group) {
      if (this.selectedGroup && this.selectedGroup.Id === group.Id) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = group;
        this.loadGroupInfo(group);
      }
    },
    removeGroupFromTagsGroup(group) {
      const relateId = _.find(group.TagsGroups, [
        "TagGroupId",
        this.selectedGroupsTag.Id,
      ]).Id;
      Api.deleteTagGroupFromTaskGroup(relateId).then(
        this.loadGroupInfo(group, true)
      );
      //     .then(() => {
      //   this[c.GET_GROUPS]();
      // });
    },
    removeTagFromTaskGroup(tags) {
      const relateId = _.find(tags, ["TagId", this.selectedTag.Id]).Id;
      Api.deleteTagFromTaskGroup(relateId).then(
        this.loadGroupInfo(this.selectedGroup, true)
      );
      //     .then(() => {
      //   this[c.GET_GROUPS]();
      // });
    },
    addGroupToTagsGroup(group) {
      Api.addTagGroupToTaskGroup({
        TaskGroupId: group.Id,
        TagGroupId: this.selectedGroupsTag.Id,
      }).then(this.loadGroupInfo(group, true));
      //     .then(() => {
      //   this[c.GET_GROUPS]();
      // });
    },
    addTagToTaskGroup(group) {
      Api.addTagToTaskGroup({
        TagId: this.selectedTag.Id,
        GroupId: group.Id,
      }).then(this.loadGroupInfo(group, true));
      //     .then(() => {
      //   this[c.GET_GROUPS]();
      // });
    },
    loadGroupInfo(group, update = false) {
      if ((group && group.TagsGroups === void 0) || update) {
        Api.getGroupById(group.Id).then((res) => {
          if (res.data.status === "success") {
            this.computedGroups = this.computedGroups.map((i) => {
              if (i.Id === res.data.group.Id) {
                i = res.data.group;
              }
              return i;
            });
          }
        });
      }
    },
    ...mapActions("tasks", [
      c.GET_TAG_GROUPS,
      c.CREATE_TAGS_GROUP,
      c.EDIT_TAGS_GROUP,
      c.DELETE_TAGS_GROUP,
      c.GET_TAGS,
      c.CREATE_TAG,
      c.EDIT_TAG,
      c.DELETE_TAG,
      c.ADD_TAG_TO_GROUP,
      c.REMOVE_TAG_FROM_GROUP,
      c.GET_GROUPS,
    ]),
  },
  components: {
    Sketch,
  },
};
</script>

<style scoped></style>
