<template>
  <div
    class="kanban__col kanban__backlog"
    :class="{
      'kanban__col--collapse': collapsed,
      'kanban__col--scrollable': !overHeight,
    }"
  >
    <div class="kanban-list" ref="listWrapper">
      <div class="kanban-list__header" @click="collapsed = false">
        <h3 class="kanban-list__name">
          Завершенные<span class="kanban-list__name-count">{{
            tasks.length ? ": " + tasks.length : ""
          }}</span>
        </h3>
        <button
          class="kanban-list__settings-btn"
          aria-label="настройки"
          @click.stop="collapsed = true"
        >
          <svg width="4" height="16">
            <use xlink:href="/static/images/tasks/sprite.svg#dots-icon"></use>
          </svg>
        </button>
        <div class="kanban-list__color-indicator" aria-hidden="true">
          <div class="kanban-list__color-indicator-text">
            {{ tasks.length }}
          </div>
        </div>
      </div>
      <div class="kanban-list__body">
        <div
          class="kanban-list__scrollable"
          v-bar="{ preventParentScroll: true }"
        >
          <draggable
            class="kanban-list__list"
            ref="list"
            :group="{ name: 'kanban-' + process.Id, push: false }"
            :sort="false"
            @add="add"
            :disabled="!checkUserTaskRight('close_task')"
          >
            <KanbanItem
              v-for="task in tasks"
              :task="task"
              :key="task.Id"
              :parentData="parentData"
              :selectedTaskParentData="selectedTaskParentData"
            />
          </draggable>
          <div class="vb-dragger-wrap" v-if="overHeight"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KanbanItem from "./KanbanItem";
import draggable from "vuedraggable";
import { mapActions } from "vuex";
import { EDIT_TASK } from "../../../../store/tasks/constants";
import common from "../../../../mixins/tasks/common";

export default {
  name: "FinishedColumn",
  mixins: [common],
  props: ["tasks", "process", "parentData", "selectedTaskParentData"],
  data() {
    return {
      collapsed: false,
      overHeight: false,
    };
  },
  created() {
    if (!this.tasks.length) {
      this.collapsed = true;
    }
  },
  mounted() {
    this.matchHeight();
  },
  computed: {},
  methods: {
    add(e) {
      const payload = {
        id: e.item.getAttribute("data-item-id"),
        data: {
          Status: "2",
        },
      };
      this[EDIT_TASK](payload);
      this.matchHeight();
    },
    matchHeight() {
      const listHeight = this.$refs.list.$el.clientHeight;
      const listWrapperHeight = this.$refs.listWrapper.clientHeight - 50;
      this.overHeight = listHeight > listWrapperHeight;
    },
    ...mapActions("tasks", [EDIT_TASK]),
  },
  components: {
    KanbanItem,
    draggable,
  },
};
</script>

<style scoped></style>
