import * as c from "../constants";
import * as _ from "lodash";

export default {
  [c.SET_COUNT](state, data) {
    state.count = data;
  },
  [c.TOGGLE_COUNT_INTERVAL](state, data) {
    state.countInterval = data;
  },
  [c.SET_ACTIVE_TAB](state, data) {
    _.each(state.activeTabs, (value, tab) => {
      state.activeTabs[tab] = tab === data;
    });
  },
  [c.GET_REQUESTS](state, data) {
    state.requests[data.scope] = data.requests;
  },
  [c.SET_REQUESTS_WITH_CREATED](state, { scope, newRequest }) {
    state.requests[scope].unshift(newRequest);
  },
  [c.SET_SEARCH_INPUT](state, data) {
    state.searchInput = data;
  },
  [c.GET_SUPPORT_DATA](state, data) {
    state.supportData = data;
  },
  [c.TOGGLE_REQUEST_STATUS](state) {
    state.isRequestUploaded = true;
    setTimeout(() => {
      state.isRequestUploaded = false;
    });
  },
  [c.SET_SELECTED_REQUEST](state, data) {
    state.selectedRequest = data;
  },
  [c.SET_REQUEST_MESSAGES](state, data) {
    state.messages = data;
  },
  [c.REMOVE_UNREAD_MARK](state, { scope, id }) {
    _.each(state.requests[scope], (i) => {
      if (i.Id === id) i.Unread = null;
    });
  },
  [c.UPDATE_REQUEST_MESSAGES](state, message) {
    state.messages.push(message);
  },
  [c.CHANGE_REQUEST_FILTERS](state, { filter, value }) {
    state.requestFilters[filter] = value;
  },
  [c.SET_PROGRESS_BAR](state, data) {
    state.progressBar = data;
  },
  [c.SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
  [c.GET_PIN](state, pin) {
    state.pin = pin;
  },
  [c.SET_REQUEST_STATUS](state, data) {
    state.selectedRequest.RealStatusId = data.RealStatusId;
    state.selectedRequest.Status = data.Status;
  },
};
