<template>
  <div class="main__body wiki active">
    <div class="ckeditor active">
      <form @submit.prevent="edit">
        <div class="form-group field-wikicategory-name required">
          <label class="control-label">Название</label>
          <input
            type="text"
            class="ui-input-field mb-20 ui-input-field__edit"
            v-model="form.Name"
          />
        </div>
        <div class="form-group field-wikicategory-slug required">
          <label class="control-label">Слаг</label>
          <input
            type="text"
            class="ui-input-field mb-20 ui-input-field__edit"
            v-model="form.Slug"
          />
        </div>
        <div class="form-group field-wikicategory-sort required">
          <label class="control-label">Сортировка</label>
          <input
            type="number"
            class="ui-input-field ui-input-field__edit"
            min="1"
            v-model="form.Sort"
          />

          <p class="help-block help-block-error"></p>
        </div>
        <div class="form-group field-wikicategory-moderation required">
          <label class="ui-input"
            ><span class="ui-input-label">На модерации?</span>
            <div style="padding-top: 10px; padding-bottom: 10px">
              <div>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <br />
        <div class="modal-buttons">
          <div class="modal-button modal-button--accept">
            <a class="modal-save" href="#">
              <button type="submit">Сохранить</button>
            </a>
          </div>
          <div class="modal-button" @click.prevent="deleteCategory">
            <a class="modal-delete show-acept" href="#">Удалить</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { DELETE_CATEGORY, EDIT_CATEGORY } from "../../../store/wiki/constants";
import { mapActions } from "vuex";
import * as _ from "lodash";

export default {
  props: ["category"],
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.category));
  },
  methods: {
    edit() {
      if (!this.form.Language) this.form.Language = 1;
      const formData = new FormData();
      _.each(this.form, (val, key) => {
        formData.append(key, val);
      });
      formData.append("_method", "PATCH");

      this[EDIT_CATEGORY]({ data: formData, id: this.category.Id }).then(() => {
        this.$emit("goBack");
      });
    },
    deleteCategory() {
      this[DELETE_CATEGORY](this.category.Id).then(() => {
        this.$emit("changeCategory", this.category.Id - 1);
      });
    },
    ...mapActions("wiki", [EDIT_CATEGORY, DELETE_CATEGORY]),
  },
};
</script>

<style scoped></style>
