<template>
  <div class="cm-popup__right">
    <div class="cm-popup-search">
      <a
        href="#"
        class="button button_green button_uppercase settings-devices-zone__add"
        @click="createContact"
        >Добавить</a
      >
      <form
        class="search-bar search-bar_white actions-header__search-bar"
        method="post"
        action="#"
      >
        <button class="search-bar__button" type="submit">
          <svg>
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-search"
            ></use>
          </svg>
        </button>
        <input
          class="search-bar__input"
          type="search"
          placeholder="Введите ФИО"
          v-model="searchInput"
        />
      </form>
    </div>

    <div class="table cm-table-2">
      <div class="table__header">
        <div class="table__col table__col_width_360">
          <p class="table__header-text">Фамилия Имя Отчество</p>
          <div
            class="table__filter-button"
            @click="order = order === 'asc' ? 'desc' : 'asc'"
            style="margin-left: 10px"
          >
            {{ order === "asc" ? "А-Я" : "Я-А" }}
          </div>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">E-mail</p>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">Телефон</p>
        </div>
      </div>
      <div class="table__row-wrap" v-if="distributorContacts.length">
        <div
          class="tableInnerRow"
          v-for="(item, index) in localContacts"
          :key="index"
          :ref="'row' + index"
        >
          <div class="table__row table__row_main">
            <div
              class="table__col table__col_width_360"
              @click="openForm(index)"
            >
              <div class="cm-table-col-1">
                <div class="cm-table-col-1__arrow">
                  <svg class="select__caret">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#icon_icon-caret"
                    ></use>
                  </svg>
                </div>
                <div
                  class="cm-table-col-1__text"
                  style="justify-content: center"
                >
                  <span class="cm-table-col-1__name">{{
                    item.ContactName
                  }}</span>
                </div>
              </div>
            </div>
            <div class="table__col table__col_width_160">
              <div class="table__text-block">
                <p class="table__text">{{ item.Email }}</p>
              </div>
            </div>
            <div class="table__col table__col_width_160">
              <div class="table__text-block">
                <p class="table__text">{{ item.Phone }}</p>
              </div>
            </div>
          </div>
          <div class="cm-client-detail-form">
            <div class="cm-popup-form__wrapper">
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  ФИО:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.ContactName" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Пароль:
                </span>
                <span class="field__content">
                  <input type="password" class="input" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Телефон:
                </span>
                <span class="field__content">
                  <input
                    type="tel"
                    class="input"
                    v-model="item.Phone"
                    v-mask="'+#(###)###-##-##'"
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  E-mail:
                </span>
                <span class="field__content">
                  <input type="email" class="input" v-model="item.Email" />
                </span>
              </label>
              <label class="field" style="display: none">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Роль:
                </span>
                <span class="field__content">
                  <div class="select">
                    <select class="select__control" v-model="item.Role">
                      <option value="1">Роль 1</option>
                      <option value="2">Роль 2</option>
                      <option value="3">Роль 3</option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </span>
              </label>
            </div>
            <div class="cm-client-detail-form__btns">
              <span @click="deleteContact(item)">Удалить</span>
              <span @click="updateContact($event, item)">Сохранить</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as c from "@/store/clients/constants";
import { mask } from "vue-the-mask";
import * as _ from "lodash";

export default {
  data() {
    return {
      searchInput: "",
      order: "asc",
    };
  },
  computed: {
    ...mapGetters("clients", ["distributorContacts"]),
    localContacts() {
      return _.orderBy(
        this.distributorContacts.filter((i) => {
          const re = new RegExp(this.searchInput, "gi");
          return re.test(i.ContactName);
        }),
        ["Name"],
        [this.order]
      );
    },
  },
  methods: {
    ...mapActions("clients", [
      c.UPDATE_CONTACT_DISTB,
      c.CREATE_CONTACT_DISTB,
      c.DELETE_CONTACT_DISTB,
    ]),
    openForm(index) {
      const item = this.$refs["row" + index][0];
      document.querySelectorAll(".tableInnerRow").forEach((i) => {
        if (item !== i) i.classList.remove("tableInnerRow--open");
      });
      item.classList.toggle("tableInnerRow--open");
    },
    updateContact(e, payload) {
      payload.Password = e.target
        .closest(".tableInnerRow")
        .querySelector("input[type=password]").value;
      this[c.UPDATE_CONTACT_DISTB](payload);
    },
    deleteContact(payload) {
      this[c.DELETE_CONTACT_DISTB](payload.Id);
    },
    createContact() {
      this[c.CREATE_CONTACT_DISTB]();
    },
  },
  directives: { mask },
};
</script>
