<template>
  <div class="login">
    <a class="login__logo" href="#">
      <img
        class="login__logo-image"
        src="/static/images/logo.svg"
        alt="login"
        role="presentation"
      />
    </a>
    <div class="login__content">
      <form class="login-form" onsubmit="return false;">
        <span class="login-form__heading">Войти</span>
        <div class="login-form__fields">
          <div class="field field_vertical login-form__field">
            <div class="field__label">Телефон:</div>
            <div class="field__content">
              <input
                class="input"
                type="tel"
                v-mask="'+#(###)###-##-##'"
                v-model="phoneNumber"
                required
              />
            </div>
          </div>
          <div class="field field_vertical login-form__field">
            <div class="field__label">Пароль:</div>
            <div class="field__content">
              <input
                class="input"
                type="password"
                v-model="password"
                required
              />
            </div>
          </div>
          <button
            class="button button_orange button_size_small button_uppercase login-form__submit"
            type="button"
            @click="login()"
          >
            Войти
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import * as c from "@/store/common/constants";
import { GET_COUNT_NOTIFICATIONS_BY_INTERVAL } from "../../../store/tasks/constants";

export default {
  data() {
    return {
      phoneNumber: "",
      password: "",
    };
  },
  methods: {
    login() {
      if (this.password.length && this.phoneNumber.length) {
        let formData = new FormData();
        formData.append("LoginForm[password]", this.password);
        formData.append("LoginForm[username]", this.phoneNumber);
        this[c.SET_LOGIN](formData).then(() => {
          if (!window.location.href.includes("clientsupport")) {
            this[GET_COUNT_NOTIFICATIONS_BY_INTERVAL]();
          }
        });
      }
    },
    ...mapActions("common", [c.SET_LOGIN]),
    ...mapActions("tasks", [GET_COUNT_NOTIFICATIONS_BY_INTERVAL]),
  },
  directives: { mask },
};
</script>
