var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup__inner-cont"},[_c('div',{staticClass:"pdf-cont-scroll pdf-cont--ml-s scroll-style"},[_c('div',{staticClass:"pdf-cont",on:{"click":function($event){_vm.openModal()}}},[_c('Print_1')],1),_vm._v(" "),_c('magnific-popup-modal',{ref:"modal",attrs:{"config":{ closeOnBgClick: true }}},[_c('Print_1')],1)],1),_vm._v(" "),_c('div',{staticClass:"pdf-settings"},[_c('div',{staticClass:"pdf-settings__cont scroll-style"},[_c('div',{staticClass:"pdf-settings__inner"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-row__label"},[_vm._v("Коммерческое предложение для:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.offerObject.json.Name),expression:"offerObject.json.Name"}],staticClass:"form-row__input",attrs:{"type":"text"},domProps:{"value":(_vm.offerObject.json.Name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.offerObject.json, "Name", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"cover"},[_c('div',{staticClass:"cover__label"},[_vm._v("Обложки:")]),_vm._v(" "),_c('div',{staticClass:"cover__list"},[_c('label',{staticClass:"cover__item cover__item--add"},[_c('input',{attrs:{"type":"file","hidden":""},on:{"change":function($event){_vm.filesChange($event.target)}}}),_vm._v(" "),(_vm.offerObject.json.CoverManual)?_c('button',{staticClass:"cover__button",staticStyle:{"pointer-events":"none"},style:({
                  backgroundImage: ("url(" + (_vm.imageBlobCheck('builder', 'covers_manual', 1)
                      ? _vm.offerObject.json.CoverManual.blob
                      : _vm.getImageOfferSrc(_vm.offerObject.json.CoverManual.img)) + ")"),
                })}):_c('div',{staticClass:"cover__button"},[_c('span',[_vm._v("+")]),_vm._v(" "),_c('p',[_vm._v("Загрузить своё фото")])])]),_vm._v(" "),_vm._l((_vm.settingsObjectStandart.covers.filter(
                function (item) { return item.img; }
              )),function(item,index){return _c('div',{key:index,staticClass:"cover__item"},[_c('button',{staticClass:"cover__button",style:({ backgroundImage: ("url(" + (_vm.getImageSrc(item.img)) + ")") }),on:{"click":function () {
                    _vm.offerObject.json.CoverManualSet = false;
                    _vm.changeCover(item.img);
                  }}})])})],2)])])]),_vm._v(" "),_c('div',{staticClass:"pdf-settings__next"},[_c('button',{staticClass:"btn-full btn-full__green",on:{"click":function($event){_vm.changeStep(2)}}},[_vm._v("\n        Далее\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }