<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Сервисное обслуживание</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div class="cm-popup-form__wrapper cm-popup-form__wrapper--single">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Описание:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea textarea-big"
              v-model="settingsObject.json.service.desc"
            ></textarea>
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Картинка:
          </span>
          <span class="field__content input-file">
            <input
              type="file"
              accept="image/*"
              hidden
              class="input"
              @change="filesChange($event.target.files)"
            />
            <input
              type="text"
              class="input"
              :value="
                getVirtualImageName(
                  'service',
                  settingsObject.json.service.img,
                  1
                )
              "
              disabled
            />
            <div class="button button_grey button-file">
              <img src="/static/images/gps.svg" />
              Выбрать файл
            </div>
          </span>
        </label>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(15, false, false)">Превью</span>
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],

  methods: {
    filesChange(fileList) {
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        this.settingsObject.images.service = [];
        this.settingsObject.images.service.push({ id: 1, file: fileList[0] });
        this.settingsObject.blobImages.service.push({
          id: 1,
          file: fileList[0],
        });
        const blob = window.URL.createObjectURL(fileList[0]);
        this.settingsObject.json.service.blob = blob;
        this.settingsObject.json.service.img = fileList[0].name;
      }
    },
  },
};
</script>
