<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'cities'"
            ref="translated-field-Name"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Область:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.DistrictId">
              <option
                v-for="(item, index) in orderedDistricts"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  mixins: [CommercialSitePopupFields],
  computed: {
    orderedDistricts() {
      return _.orderBy(this.districts, ["Name"], ["asc"]);
    },
    ...mapState("site", ["districts"]),
  },
  methods: {},
};
</script>

<style scoped></style>
