export const randomId = () => {
  return Math.random().toString(36).substring(7);
};
export const offerJson = {
  System: "standart",
  Name: "",
  ClientName: "",
  CompanyName: "",
  ObjectName: "",
  Status: 1,
  SystemType: null,
  ManagerName: "",
  ManagerId: "",
  ManagerPhone: "",
  ManagerEmail: "",
  Text: "",
  Sum: "",
  UpdateDate: "",
  DoneWorks: "",
  Date: "",
  ClientEmail: "",
  ClientPhone: "",
  City: "",
  Address: "",
  ObjectType: "",
  ObjectTypeString: "",
  MMCount: "",
  ParkingTime: "",
  Tasks: "",
  ContentTasks: "",
  Cover: null,
  CoverManualSet: false,
  CoverManual: null,
  totalDevicesPriceDiscont: 0,
  totalDevicesPriceType: "percent",
  Schemes: [],
  Modules: {
    Entry: {},
    Exit: {},
    Payment: {},
    Additional: {},
  },
  Objects: null,
  OptionsText: "",
  ContentText: "",
  TasksText: "",
  TasksPageText: "",
  TasksPageTextVirtual: "",
  AdditionalPageText: "",
  AdditionalPageTextVirtual: {},
  AdvantagesOwner: [],
  AdvantagesTenant: {},
  AdvantagesGuest: {},
  Total: {
    Options: null,
    Guarantee: "18 месяцев",
    Date: "2 месяца",
    Delivery: "",
    Payment: "",
  },
  Service: {
    Img: "",
    Desc: "",
  },
  passPages: {
    schemes: false,
    ad_tenant: false,
    option_desc: false,
    service: false,
    entry_barrier: false,
    entry_rack: false,
    exit_rack: false,
    exit_barrier: false,
    tasks_page: true,
    payment: false,
    additional: false,
  },
  rateValue: null,
  serviceType: {
    type: 2,
    name: "Стандартное  Т.О.",
    cost: 0,
  },
  systemTypeImage: "",
  currency: "RUB",
  showCurrency: true,
};
export const settingsJson = {
  covers: [
    {
      id: 1,
      randomId: randomId(),
      img: "",
    },
    {
      id: 2,
      randomId: randomId(),
      img: "",
    },
    {
      id: 3,
      randomId: randomId(),
      img: "",
    },
    {
      id: 4,
      randomId: randomId(),
      img: "",
    },
  ],
  ticketsImg: {
    id: 1,
    randomId: randomId(),
    img: "",
  },
  tokensImg: {
    id: 1,
    randomId: randomId(),
    img: "",
  },
  tokensdblImg: {
    id: 1,
    randomId: randomId(),
    img: "",
  },
  cardsImg: {
    id: 1,
    randomId: randomId(),
    img: "",
  },
  content: "",
  doneWorks: "",
  options: "",
  total: {
    types: [
      {
        id: 1,
        randomId: randomId(),
        name: "Пуско-наладочные работы",
        cost: 50000,
        convertCost: "",
      },
      {
        id: 2,
        randomId: randomId(),
        name: "Проектные работы",
        cost: 0,
        convertCost: "",
      },
      {
        id: 3,
        randomId: randomId(),
        name: "Строительно-монтажные работы",
        cost: 150000,
        convertCost: "",
      },
    ],
    delivery: [
      {
        id: 1,
        randomId: randomId(),
        name: "Самовывоз со склада в москве",
      },
      {
        id: 2,
        randomId: randomId(),
        name: "DDP п Москве",
      },
      {
        id: 3,
        randomId: randomId(),
        name: "Доставка в регионы",
      },
    ],
    payment: [
      {
        id: 1,
        randomId: randomId(),
        name: "100% оплата",
      },
      {
        id: 2,
        randomId: randomId(),
        name: "Покупка в лизинг",
      },
      {
        id: 3,
        randomId: randomId(),
        name: "30% / 70%",
      },
      {
        id: 4,
        randomId: randomId(),
        name: "Произвольное поле",
      },
    ],
  },
  tasks: {
    tc: "",
    bc: "",
    zk: "",
    hotels: "",
    sport: "",
    air: "",
    others: "",
    parks: "",
  },
  advantages_owner: {
    tc: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    bc: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    zk: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    hotels: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    sport: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    air: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    others: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
    parks: [
      {
        id: 1,
        randomId: randomId(),
        img: "",
        name: "Комфорт",
        desc: "Описание преимущества",
      },
      {
        id: 2,
        randomId: randomId(),
        img: "",
        name: "Экономия",
        desc: "Описание преимущества",
      },
      {
        id: 3,
        randomId: randomId(),
        img: "",
        name: "Контроль",
        desc: "Описание преимущества",
      },
    ],
  },
  advantages_tenant: {
    tc: {
      desc: "",
      advantages: "",
    },
    bc: {
      desc: "",
      advantages: "",
    },
    zk: {
      desc: "",
      advantages: "",
    },
    hotels: {
      desc: "",
      advantages: "",
    },
    sport: {
      desc: "",
      advantages: "",
    },
    air: {
      desc: "",
      advantages: "",
    },
    others: {
      desc: "",
      advantages: "",
    },
    parks: {
      desc: "",
      advantages: "",
    },
  },
  advantages_guest: {
    tc: {
      desc: "",
      advantages: "",
    },
    bc: {
      desc: "",
      advantages: "",
    },
    zk: {
      desc: "",
      advantages: "",
    },
    hotels: {
      desc: "",
      advantages: "",
    },
    sport: {
      desc: "",
      advantages: "",
    },
    air: {
      desc: "",
      advantages: "",
    },
    others: {
      desc: "",
      advantages: "",
    },
    parks: {
      desc: "",
      advantages: "",
    },
  },
  service: {
    desc: "",
    img: "",
  },
  objects: {
    tc: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",
        address: "",
        equipment: "",
        works: "",
      },
    ],
    bc: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    zk: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    hotels: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    sport: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    air: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    others: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
    parks: [
      {
        id: 1,
        randomId: randomId(),
        name: "",
        img: "",

        address: "",
        equipment: "",
        works: "",
      },
    ],
  },
  devices: {
    cards: {
      entry: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      exit: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      payment: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        cash_1: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_2: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_3: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_4: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
      additional: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        options: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
    },
    tokens: {
      entry: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      exit: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      payment: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        cash_1: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_2: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_3: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_4: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
      additional: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        options: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
    },
    tickets: {
      entry: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      exit: {
        img_rack: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        img_barrier: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        rack: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
        barrier: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: true,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
            is_device: false,
          },
        ],
      },
      payment: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        cash_1: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_2: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_3: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
        cash_4: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
      additional: {
        img: [
          {
            id: 1,
            randomId: randomId(),
            img: "",
          },
        ],
        options: [
          {
            id: 1,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 2,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
          {
            id: 3,
            randomId: randomId(),
            name: "",
            cost: "",
            convertCost: "",
            desc: "",
            count: 1,
          },
        ],
      },
    },
  },
  fromServer: false,
  pdf_1: {
    img: "",
    file: "",
  },
  pdf_2: {
    img: "",
    file: "",
  },
  pdf_3: {
    img: "",
    file: "",
  },
  pdf_tc: {
    img: "",
    file: "",
  },
  pdf_bc: {
    img: "",
    file: "",
  },
  pdf_zk: {
    img: "",
    file: "",
  },
  pdf_hotels: {
    img: "",
    file: "",
  },
  pdf_sport: {
    img: "",
    file: "",
  },
  pdf_air: {
    img: "",
    file: "",
  },
  pdf_parks: {
    img: "",
    file: "",
  },
  pdf_others: {
    img: "",
    file: "",
  },
};

export const ObjectTypes = {
  tc: "Торгово-развлекательный центр",
  bc: "Бизнес-центр",
  zk: "Жилой комплекс",
  hotels: "Отель",
  sport: "Спортивный комплекс",
  air: "Аэропорт / Железнодорожный вокзал",
  parks: "Парк",
  others: "Паркинг",
};

export const optionsArray = [
  "rack",
  "barrier",
  "cash_1",
  "cash_2",
  "cash_3",
  "cash_4",
  "options",
];
export const modulesArray = ["Entry", "Exit", "Payment", "Additional"];
export const systemTypesDict = {
  Tokens: 1,
  Tokensdbl: 4,
  Tickets: 2,
  Cards: 3,
};

export const copy = (json) => {
  return JSON.parse(JSON.stringify(json));
};

// eslint-disable-next-line no-unused-vars
export const changeJSON = (state) => {
  // console.log(state);
  // sprint 21
  // ['settingsObjectEconomy', 'settingsObjectStandart', 'settingsObjectQr].forEach(system => {
  //   _.each(['cards', 'tickets', 'tokens', 'tokensdbl'], type => {
  //
  //     // state[system].devices[type].payment.cash_1 = _.map(state[system].devices[type].payment.cash_1, i => {
  //     //
  //     //   i.is_device = i.id === 1
  //     //   return i
  //     // })
  //     // state[system].devices[type].payment.cash_2 = _.map(state[system].devices[type].payment.cash_2, i => {
  //     //
  //     //   i.is_device = i.id === 1
  //     //   return i
  //     // })
  //     // state[system].devices[type].payment.cash_3 = _.map(state[system].devices[type].payment.cash_3, i => {
  //     //
  //     //   i.is_device = i.id === 1
  //     //   return i
  //     // })
  //     // state[system].devices[type].payment.cash_4 = _.map(state[system].devices[type].payment.cash_4, i => {
  //     //
  //     //   i.is_device = i.id === 1
  //     //   return i
  //     // })
  //
  //     // state[system].devices[type].payment.cash_4 = []
  //     // state[system].devices[type].entry.rack = _.map(state[system].devices[type].entry.rack, i => {
  //     //
  //     //   i.countAsOneKit = false
  //     //   return i
  //     // })
  //     // state[system].devices[type].entry.barrier = _.map(state[system].devices[type].entry.barrier, i => {
  //     //
  //     //   i.countAsOneKit = false
  //     //   return i
  //     // })
  //     // state[system].devices[type].exit.barrier = _.map(state[system].devices[type].exit.barrier, i => {
  //     //
  //     //   i.countAsOneKit = false
  //     //   return i
  //     // })
  //     // state[system].devices[type].exit.rack = _.map(state[system].devices[type].exit.rack, i => {
  //     //
  //     //   i.countAsOneKit = false
  //     //   return i
  //     // })
  //
  //     // state[system].devices[type].additional.options = _.map(state[system].devices[type].additional.options, i => {
  //     //
  //     //   i.countAsOneKit = false
  //     //   return i
  //     // })
  //     //
  //     // console.log(state.settingsObject.json.devices[type].entry.rack)
  //   })
  // })
  /////////
  // state.settingsObjectEconomy.devices.cards.additional.options = _.map(state.settingsObjectEconomy.devices.cards.additional.options, i => {
  // i.is_device = false
  //   return i
  // })
  // _.each(['cards', 'tickets', 'tokens'], type => {
  //   state.settingsObject.json.devices[type].entry.img_rack = [
  //     {
  //       id: 1,
  //       randomId: randomId(),
  //       img: '',
  //     }
  //   ]
  //   state.settingsObject.json.devices[type].entry.img_barrier = [
  //     {
  //       id: 1,
  //       randomId: randomId(),
  //       img: '',
  //     }
  //   ]
  //   state.settingsObject.json.devices[type].exit.img_rack = [
  //     {
  //       id: 1,
  //       randomId: randomId(),
  //       img: '',
  //     }
  //   ]
  //   state.settingsObject.json.devices[type].exit.img_barrier = [
  //     {
  //       id: 1,
  //       randomId: randomId(),
  //       img: '',
  //     }
  //   ]
  //
  // })
  // state.settingsObject.json.total.types = _.map(state.settingsObject.json.total.types, i => {
  //   i.convertCost = ''
  //   return i
  // })
  // state.settingsObject.json.total.delivery = _.map(state.settingsObject.json.total.delivery, i => {
  //   i.randomId = randomId()
  //   return i
  // })
  // state.settingsObject.json.total.payment = _.map(state.settingsObject.json.total.payment, i => {
  //   i.randomId = randomId()
  //   return i
  // })
  //
  // state.settingsObject.json.covers = _.map(state.settingsObject.json.covers, i => {
  //   i.randomId = randomId()
  //   return i
  // })
  // _.each(['tc', 'bc', 'zk','hotels','sport','air','parks','others'], objType => {
  //   state.settingsObject.json.advantages_owner[objType] = _.map(state.settingsObject.json.advantages_owner[objType], i => {
  //     i.randomId = randomId()
  //     return i
  //   })
  //   state.settingsObject.json.objects[objType] = _.map(state.settingsObject.json.objects[objType], i => {
  //     i.randomId = randomId()
  //     return i
  //   })
  // })
  // state.settingsObject.json.doneWorks = ''
  // state.settingsObject.json.ticketsImg = {
  //   id: 1,
  //     randomId: randomId(),
  //     img: '',
  // }
  // state.settingsObject.json.tokensImg = {
  //   id: 1,
  //     randomId: randomId(),
  //     img: '',
  // }
  // state.settingsObject.json.cardsImg = {
  //   id: 1,
  //     randomId: randomId(),
  //     img: '',
  // }
  // state.settingsObject.json.images.ticketsImg = []
  // state.settingsObject.json.images.tokensImg = []
  // state.settingsObject.json.images.cardsImg = []
  // state.settingsObject.json.blobImages.ticketsImg = []
  // state.settingsObject.json.blobImages.tokensImg = []
  // state.settingsObject.json.blobImages.cardsImg = []
  // state.settingsObject.json.advantages_owner.parks = [
  //   {
  //     id: 1,
  // randomId: randomId()
  //     img: '',
  //     name: 'Комфорт',
  //     desc: 'Описание преимущества',
  //   },
  //   {
  //     id: 2,
  // randomId: randomId()
  //     img: '',
  //     name: 'Экономия',
  //     desc: 'Описание преимущества',
  //   },
  //   {
  //     id: 3,
  // randomId: randomId()
  //     img: '',
  //     name: 'Контроль',
  //     desc: 'Описание преимущества',
  //   }
  // ]
  // state.settingsObject.json.advantages_tenant.parks = {
  //   desc: '',
  //   advantages: ''
  // }
  // state.settingsObject.json.advantages_guest.parks = {
  //   desc: '',
  //   advantages: ''
  // }
  // state.settingsObject.json.objects.parks = [
  //   {
  //     id: 1,
  // randomId: randomId()
  //     name: '',
  //     img: '',
  //
  //     address: '',
  //     equipment: '',
  //     works: '',
  //   }
  // ]
  // _.each(['cards', 'tickets', 'tokens'], type => {
  //   state.settingsObject.json.devices[type].entry.img = [
  //     {
  //           id: 1,
  //           randomId: randomId(),
  //           img: '',
  //         }
  //   ]
  //   state.settingsObject.json.devices[type].exit.img = [
  //     {
  //           id: 1,
  //           randomId: randomId(),
  //           img: '',
  //         }
  //   ]
  //   state.settingsObject.json.devices[type].payment.img = [
  //     {
  //           id: 1,
  //           randomId: randomId(),
  //           img: '',
  //         }
  //   ]
  //   state.settingsObject.json.devices[type].additional.img = [
  //     {
  //           id: 1,
  //           randomId: randomId(),
  //           img: '',
  //         }
  //   ]
  // })
  // _.each(['cards', 'tickets', 'tokens'], type => {
  // state.settingsObject.json.devices[type].entry.rack = _.map(state.settingsObject.json.devices[type].entry.rack, i => {
  // // i.convertCost = ''
  //   i.is_device = i.id === 1
  //   return i
  // })
  // state.settingsObject.json.devices[type].entry.barrier = _.map(state.settingsObject.json.devices[type].entry.barrier, i => {
  // // i.convertCost = ''
  //   i.is_device = i.id === 1
  //   return i
  // })
  // state.settingsObject.json.devices[type].exit.barrier = _.map(state.settingsObject.json.devices[type].exit.barrier, i => {
  // // i.convertCost = ''
  //   i.is_device = i.id === 1
  //   return i
  // })
  // state.settingsObject.json.devices[type].exit.rack = _.map(state.settingsObject.json.devices[type].exit.rack, i => {
  // // i.convertCost = ''
  //   i.is_device = i.id === 1
  //   return i
  // })
  //   state.settingsObject.json.devices[type].payment.cash_1 = _.map(state.settingsObject.json.devices[type].payment.cash_1, i => {
  //   i.convertCost = ''
  //
  //     return i
  //   })
  //   state.settingsObject.json.devices[type].payment.cash_2 = _.map(state.settingsObject.json.devices[type].payment.cash_2, i => {
  //   i.convertCost = ''
  //
  //     return i
  //   })
  //   state.settingsObject.json.devices[type].payment.cash_3 = _.map(state.settingsObject.json.devices[type].payment.cash_3, i => {
  //   i.convertCost = ''
  //
  //     return i
  //   })
  //   state.settingsObject.json.devices[type].additional.options = _.map(state.settingsObject.json.devices[type].additional.options, i => {
  //   i.convertCost = ''
  //
  //     return i
  //   })
  //   console.log(state.settingsObject.json.devices[type].entry.rack)
  // })
  // _.each(['cards', 'tickets', 'tokens'], type => {
  // state.settingsObject.json.devices[type].entry.rack = _.orderBy(state.settingsObject.json.devices[type].entry.rack, ['id'])
  // state.settingsObject.json.devices[type].entry.barrier = _.orderBy(state.settingsObject.json.devices[type].entry.barrier, ['id'])
  // state.settingsObject.json.devices[type].exit.barrier = _.orderBy(state.settingsObject.json.devices[type].exit.barrier, ['id'])
  // state.settingsObject.json.devices[type].exit.rack = _.orderBy(state.settingsObject.json.devices[type].exit.rack, ['id'])
  // state.settingsObject.json.devices[type].payment.cash_1 = _.orderBy(state.settingsObject.json.devices[type].payment.cash_1, ['id'])
  // state.settingsObject.json.devices[type].payment.cash_2 = _.orderBy(state.settingsObject.json.devices[type].payment.cash_2, ['id'])
  // state.settingsObject.json.devices[type].payment.cash_3 = _.orderBy(state.settingsObject.json.devices[type].payment.cash_3, ['id'])
  // state.settingsObject.json.devices[type].additional.options = _.orderBy(state.settingsObject.json.devices[type].additional.options, ['id'])
  //
  //   console.log(state.settingsObject.json.devices[type].entry.rack)
  // })
  // sprint 16
  // state.settingsObject.json.devices.tokensdbl = copy(state.settingsObject.json.devices.tokens)
  // state.settingsObject.json.tokensdblImg = {
  //   id: 1,
  //     randomId: randomId(),
  //     img: '',
  // }
  // state.settingsObject.json.pdf_1 = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_2 = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_3 = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_tc = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_bc = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_zk = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_hotels = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_sport = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_air = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_parks = {
  //   img: '',
  //     file: ''
  // }
  // state.settingsObject.json.pdf_others = {
  //   img: '',
  //     file: ''
  // }
};
