import * as _ from "lodash";

export function getStandartFilterNameInfo(index) {
  switch (+index) {
    case -1:
      return ["Фильтр", "this[c.GET_TASKS]()"];
    case 1:
      return ["Просроченные", "fire-icon.png"];
    case 2:
      return ["Я ответственный", "man-icon.svg"];
    case 3:
      return ["Принять", "check-icon.svg"];
    case 4:
      return ["Выполнить сегодня", "calendar-icon.svg"];
    case 5:
      return ["Я подписчик", "ding-icon.svg"];
    case 6:
      return ["Я наблюдатель", "eye-icon.svg"];
    default:
      return ["", ""];
  }
}

export const prepareFetchedFiles = (files, task) => {
  files.forEach((file) => {
    task.files.push(file);

    if (file.CommentId) {
      _.map(task.comments, (i) => {
        if (file.CommentId === i.Id) {
          i.files = i.files ? i.files : [];
          i.files.push(file);
        }
        return i;
      });
      task.comments = JSON.parse(JSON.stringify(task.comments));
    }
  });
};

export const deleteFetchedFile = (file, task) => {
  task.files = _.reject(task.files, ["Id", file.Id]);
  if (file.CommentId) {
    _.map(task.comments, (i) => {
      if (file.CommentId === i.Id) {
        i.files = _.reject(i.files, ["Id", file.Id]);
      }
      return i;
    });
  }
};
