<template>
  <div class="task-bubble__item-wrapper">
    <div
      class="task-bubble__item-parent-row"
      :class="{ 'task-bubble__item-parent-row--open': open }"
      @click="open = !open"
    >
      <div class="task-bubble__item-parent-row-arrow">
        <svg width="12" height="7">
          <use
            xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
          ></use>
        </svg>
      </div>
      <div class="task-bubble__item-parent-row-name">
        {{ item[fieldNames.parentFieldName] }}
      </div>
      <div class="task-bubble__item-parent-row-count">
        {{ item[fieldNames.childListFieldName].length }}
      </div>
    </div>
    <template v-if="open">
      <div
        class="task-bubble__item"
        :class="{ 'task-bubble__item--selected': isSelected(item) }"
        v-for="(item, index) in list"
        @click="$emit('choose', item)"
        :key="index"
      >
        <div
          class="task-table-person__circle"
          v-if="item[fieldNames.colorFieldName]"
          :style="{ background: item[fieldNames.colorFieldName] }"
        ></div>
        {{ item[fieldNames.childFieldName] }}
      </div>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "GroupedListSearch",
  props: ["parentItems", "item", "search", "fieldNames", "selectedItemsArray"],
  data() {
    return {
      open: false,
    };
  },
  created() {
    if (this.parentItems.length === 1) {
      this.open = true;
    }
  },
  computed: {
    list() {
      return _.filter(
        _.orderBy(
          this.item[this.fieldNames.childListFieldName],
          [this.fieldNames.childFieldName],
          ["asc"]
        ),
        (i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i[this.fieldNames.childFieldName]);
        }
      );
    },
  },
  methods: {
    isSelected(item) {
      return (
        this.selectedItemsArray.indexOf(item[this.fieldNames.idFieldName]) !==
        -1
      );
    },
  },
  watch: {
    search(val) {
      this.open = val ? this.list.length : false;
    },
  },
};
</script>

<style scoped></style>
