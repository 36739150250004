import * as _ from "lodash";
import { taskCreateFormTemplate } from "@/modules/tasks/components/TaskCreateForm/templateData";

export default {
  computed: {
    settings() {
      return _.map(
        this.form?.Settings ||
          taskCreateFormTemplate.TemplateTaskSettings.Settings,
        (value, key) => {
          let name = "";
          switch (key) {
            case "EnableComments":
              name = "Комментарии";
              break;
            case "FinishDateOnlyWithComment":
              name = "Запрет изменения срока без комментария";
              break;
            case "ResponsibleAutoAccept":
              name = "Не требует принятия";
              break;
            case "TaskAccess":
              name = "Видимость. Только исполнители";
              value = Number(value) === 2;
              break;
          }
          return {
            Name: name,
            Id: key,
            Selected: value,
          };
        }
      );
    },
  },
};
