import * as c from "./constants";
import { offerJson } from "@/mixins/sales/SalesJSONS";
import { changeJSON } from "@/mixins/sales/SalesJSONS";
import {
  copy,
  modulesArray,
  optionsArray,
} from "../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  [c.GET_SETTINGS](state, data) {
    state.settingsObject.json = data.settings;
    switch (state.config.system) {
      case "economy": {
        state.settingsObject.json = data.settingsEconomy;
        break;
      }
      case "qr":
        state.settingsObject.json = data.settingsQr;
        break;
    }

    state.settingsObjectStandart = data.settings;
    state.settingsObjectEconomy = data.settingsEconomy;
    state.settingsObjectQr = data.settingsQr;
    state.settingsObject.json.fromServer = true;
    changeJSON(state);
  },
  [c.GET_EXAMPLES_OBJECTS](state, data) {
    state.examplesObjects = data.examples;
    state.examplesObjects = _.orderBy(
      _.map(state.examplesObjects, (i) => {
        i.WorksTypesText = "";
        _.each(i.WorksTypes, (type) => {
          i.WorksTypesText += _.find(data.workTypes, ["Id", type]).Name + "; ";
        });
        i.CityName = _.find(data.cities, ["Id", i.CityId]).Name;
        return i;
      }),
      ["Name"],
      ["asc"]
    );
  },
  [c.GET_COMMERCIAL_SITE_URL](state, data) {
    state.commercialSiteUrl = data;
  },
  [c.TOGGLE_PRELOADER](state, { type, status }) {
    state.preloaders[type] = status;
  },
  [c.RESET_IMAGES](state) {
    state.settingsObject.images = {
      covers: [],
      objects: {
        tc: [],
        bc: [],
        zk: [],
        hotels: [],
        sport: [],
        air: [],
        others: [],
        parks: [],
      },
      service: [],
      icons: {
        tc: [],
        bc: [],
        zk: [],
        hotels: [],
        sport: [],
        air: [],
        others: [],
        parks: [],
      },
      devices: {
        cards_entry_rack: [],
        cards_entry_barrier: [],
        cards_exit_rack: [],
        cards_exit_barrier: [],
        cards_payment: [],
        cards_additional: [],

        tickets_entry_rack: [],
        tickets_entry_barrier: [],
        tickets_exit_rack: [],
        tickets_exit_barrier: [],
        tickets_payment: [],
        tickets_additional: [],

        tokens_entry_rack: [],
        tokens_entry_barrier: [],
        tokens_exit_rack: [],
        tokens_exit_barrier: [],
        tokens_payment: [],
        tokens_additional: [],

        tokensdbl_entry_rack: [],
        tokensdbl_entry_barrier: [],
        tokensdbl_exit_rack: [],
        tokensdbl_exit_barrier: [],
        tokensdbl_payment: [],
        tokensdbl_additional: [],
      },
      cardsImg: [],
      tokensImg: [],
      ticketsImg: [],
      tockensdblImg: [],
    };
    state.settingsObject.blobImages = {
      covers: [],
      objects: {
        tc: [],
        bc: [],
        zk: [],
        hotels: [],
        sport: [],
        air: [],
        others: [],
        parks: [],
      },
      service: [],
      icons: {
        tc: [],
        bc: [],
        zk: [],
        hotels: [],
        sport: [],
        air: [],
        others: [],
        parks: [],
      },
      devices: {
        cards_entry_rack: [],
        cards_entry_barrier: [],
        cards_exit_rack: [],
        cards_exit_barrier: [],
        cards_payment: [],
        cards_additional: [],

        tickets_entry_rack: [],
        tickets_entry_barrier: [],
        tickets_exit_rack: [],
        tickets_exit_barrier: [],
        tickets_payment: [],
        tickets_additional: [],

        tokens_entry_rack: [],
        tokens_entry_barrier: [],
        tokens_exit_rack: [],
        tokens_exit_barrier: [],
        tokens_payment: [],
        tokens_additional: [],

        tokensdbl_entry_rack: [],
        tokensdbl_entry_barrier: [],
        tokensdbl_exit_rack: [],
        tokensdbl_exit_barrier: [],
        tokensdbl_payment: [],
        tokensdbl_additional: [],
      },
    };
  },
  [c.RESET_OFFER](state) {
    state.offerObject.json = JSON.parse(JSON.stringify(offerJson));
    state.offerObject.images = {
      schemes: [],
    };
    state.offerObject.OpenAccessByLink = null;
    state.offerObject.LinkToOffer = null;

    state.offerObject.blobImages = {
      schemes: [],
    };
    state.pageNums = {
      Schemes: 4,
      Entry: 6,
      Entry_2: 6,
      Exit: 7,
      Exit_2: 7,
      Payment: 8,
      Additional: 9,
      Total: 10,
      Tasks: 11,
      Advantages: 14,
      Service: 15,
      Options: 17,
      Tenant: 18,
      OptionsLastPage: 19,
      EntryLastPage: 5,
      ExitLastPage: 5,
      PaymentLastPage: 5,
      Entry_2LastPage: 5,
      Exit_2LastPage: 5,
      Objects: 20,
      TasksAdditionalPage: false,
    };
    state.offerObject.openedPages = {
      Entry: 0,
      Exit: 0,
      Payment: 0,
      Additional: 0,
    };
    state.compareObject = {};
  },
  [c.GET_OFFERS](state, data) {
    state.offers = data;
    // state.offers = _.map(_.filter(data, i => {
    //   return i.OfferData
    // }), i => {
    //   i.OfferData = JSON.parse(i.OfferData)
    //   return i
    // })
  },
  [c.ADD_OFFERS_TO_LIST](state, data) {
    state.offers.push(...data);
  },
  [c.ADD_OFFER](state, data) {
    state.offerObject.json = JSON.parse(data.OfferData);
    state.offerObject.json.Id = data.Id;

    state.windowStates.is_open_builder = false;
    state.windowStates.is_open_view = true;
    state.offers.push(data);
  },
  [c.SAVE_OFFER](state, data) {
    state.offerObject.json = JSON.parse(data.OfferData);
    state.offerObject.json.Id = data.Id;

    state.windowStates.is_open_builder = false;
    state.windowStates.is_open_view = true;
    data.OfferData = JSON.parse(data.OfferData);
    state.offers.push(data);
  },
  [c.UPDATE_OFFER](state, data) {
    state.offerObject.json = JSON.parse(data.OfferData);
    state.offerObject.json.Id = data.Id;

    state.windowStates.is_edit_state = false;
    data.OfferData = JSON.parse(data.OfferData);
    state.offers = _.filter(state.offers, (i) => {
      // console.log(i.Id, data.Id)
      return i.Id !== data.Id;
    });
    state.offers.push(data);
  },
  [c.REMOVE_OFFER](state, id) {
    state.windowStates.is_open_view = false;
    state.offers = _.filter(state.offers, (i) => {
      return i.Id !== id;
    });
  },
  [c.CREATE_DUMP_OF_SETTINGS](state) {
    state.settingsJsonDump = copy(state.settingsObject.json);
  },
  [c.RESTORE_SETTINGS_FROM_DUMP](state) {
    // const data = state.config.system === 'standart' ? copy(state.settingsJsonDump) : copy(state.settingsJsonDump)
    state.settingsObject.json = copy(state.settingsJsonDump);
  },
  [c.CHANGE_COMPARE_OBJECT](state, data) {
    state.compareObject = data;
  },
  [c.RESET_COMPARE](state) {
    state.compareObject = {};
  },
  [c.CREATE_CLEAR_COPY_OFFER](state) {
    state.offerObject.json.rateValue = state.rateValue;
    state.offerObject.json.Name = state.offerObject.json.Name + " (КОПИЯ)";
  },
  [c.CREATE_COPY_OFFER](state) {
    state.offerObject.json.Name = state.offerObject.json.Name + " (НОВОЕ)";
    if (state.compareObject.ContentText !== void 0) {
      state.offerObject.json.ContentText = state.compareObject.ContentText;
    }
    if (state.compareObject.OptionsText !== void 0) {
      state.offerObject.json.OptionsText = state.compareObject.OptionsText;
    }
    if (state.compareObject.Tasks !== void 0) {
      state.offerObject.json.Tasks = state.compareObject.Tasks;
    }
    if (state.compareObject.AdvantagesTenant !== void 0) {
      state.offerObject.json.AdvantagesTenant =
        state.compareObject.AdvantagesTenant;
    }
    if (state.compareObject.AdvantagesGuest !== void 0) {
      state.offerObject.json.AdvantagesGuest =
        state.compareObject.AdvantagesGuest;
    }
    if (state.compareObject.Service !== void 0) {
      state.offerObject.json.Service = state.compareObject.Service;
    }
    if (state.compareObject.DoneWorks !== void 0) {
      state.offerObject.json.DoneWorks = state.compareObject.DoneWorks;
    }

    if (state.compareObject.Modules !== void 0) {
      _.each(modulesArray, (module) => {
        if (state.compareObject.Modules[module] !== void 0) {
          if (state.compareObject.Modules[module].img !== void 0) {
            state.offerObject.json.Modules[module].img =
              state.compareObject.Modules[module].img;
          }
          _.each(optionsArray, (option) => {
            const newOptions = state.compareObject.Modules[module][option];
            const oldOptions = state.offerObject.json.Modules[module][option];
            if (newOptions !== void 0) {
              _.each(newOptions, (i) => {
                const changeEl = _.find(oldOptions, ["randomId", i.randomId]);
                if (changeEl) {
                  changeEl.name = i.name;
                  // if (changeEl.initial_cost !== void 0) {
                  //   changeEl.initial_cost = i.cost
                  // } else {
                  //   changeEl.cost = i.cost
                  // }
                  changeEl.cost = i.cost;
                  changeEl.initial_cost = (state.rateValue * i.cost).toFixed();
                  changeEl.desc = i.desc;
                  changeEl.id = i.id;
                  // console.log(111, changeEl)
                }
              });
            }
          });
          if (
            state.compareObject.Modules[module].lengthDiffrent !== void 0 &&
            state.compareObject.Modules[module].lengthDiffrent.length
          ) {
            _.each(optionsArray, (option) => {
              if (
                state.compareObject.Modules[module].lengthDiffrent[0][
                  option
                ] !== void 0
              ) {
                const removedOptions =
                  state.compareObject.Modules[module].lengthDiffrent[0][option]
                    .removedOptions;
                const addedOptions =
                  state.compareObject.Modules[module].lengthDiffrent[0][option]
                    .addedOptions;
                if (removedOptions !== void 0 && removedOptions.length) {
                  _.each(removedOptions, (i) => {
                    state.offerObject.json.Modules[module][option] = _.filter(
                      state.offerObject.json.Modules[module][option],
                      (oldEl) => {
                        return i.randomId !== oldEl.randomId;
                      }
                    );
                    state.offerObject.json.Modules[module][option] = _.each(
                      state.offerObject.json.Modules[module][option],
                      (item, index) => {
                        item.id = index + 1;
                        return item;
                      }
                    );
                  });
                }
                if (addedOptions !== void 0 && addedOptions.length) {
                  state.offerObject.json.Modules[module][option].push(
                    ...addedOptions
                  );
                }
              }
            });
          }
        }
      });
    }
    if (state.compareObject.Total !== void 0) {
      _.each(["types", "delivery", "payment"], (option) => {
        let newOptions = state.compareObject.Total[option];
        let oldOptions = state.offerObject.json.Total.Options[option];
        if (newOptions !== void 0 && newOptions.Options !== void 0) {
          _.each(newOptions.Options, (i) => {
            const changeEl = _.find(oldOptions, ["randomId", i.randomId]);
            if (changeEl) {
              changeEl.name = i.name;
              if (changeEl.cost !== void 0) {
                changeEl.cost = i.cost;
              }
              changeEl.id = i.id;
            }
          });
        }
        if (
          newOptions !== void 0 &&
          newOptions.lengthDiffrent !== void 0 &&
          newOptions.lengthDiffrent.length &&
          newOptions.lengthDiffrent[0][option] !== void 0
        ) {
          const removedOptions =
            newOptions.lengthDiffrent[0][option].removedOptions;
          const addedOptions =
            newOptions.lengthDiffrent[0][option].addedOptions;
          if (removedOptions !== void 0 && removedOptions.length) {
            _.each(removedOptions, (i) => {
              state.offerObject.json.Total.Options[option] = _.filter(
                state.offerObject.json.Total.Options[option],
                (oldEl) => {
                  return i.randomId !== oldEl.randomId;
                }
              );
              state.offerObject.json.Total.Options[option] = _.each(
                state.offerObject.json.Total.Options[option],
                (item, index) => {
                  item.id = index + 1;
                  return item;
                }
              );
            });
          }
          if (addedOptions !== void 0 && addedOptions.length) {
            state.offerObject.json.Total.Options[option].push(...addedOptions);
          }
        }
      });
    }
    if (state.compareObject.AdvantagesOwner !== void 0) {
      let newOptions = state.compareObject.AdvantagesOwner;
      let oldOptions = state.offerObject.json.AdvantagesOwner;
      // console.log(111, newOptions)
      // console.log(222, oldOptions)
      _.each(newOptions.Options, (i) => {
        const changeEl = _.find(oldOptions, ["randomId", i.randomId]);
        if (changeEl) {
          // console.log('sss ')
          changeEl.name = i.name;
          changeEl.img = i.img;
          changeEl.desc = i.desc;
          changeEl.id = i.id;
        }
      });
      if (
        newOptions.lengthDiffrent !== void 0 &&
        newOptions.lengthDiffrent.length &&
        newOptions.lengthDiffrent[0].AdvantagesOwner !== void 0
      ) {
        const removedOptions =
          newOptions.lengthDiffrent[0].AdvantagesOwner.removedOptions;
        const addedOptions =
          newOptions.lengthDiffrent[0].AdvantagesOwner.addedOptions;
        if (removedOptions !== void 0 && removedOptions.length) {
          _.each(removedOptions, (i) => {
            state.offerObject.json.AdvantagesOwner = _.filter(
              state.offerObject.json.AdvantagesOwner,
              (oldEl) => {
                return i.randomId !== oldEl.randomId;
              }
            );
            state.offerObject.json.AdvantagesOwner = _.each(
              state.offerObject.json.AdvantagesOwner,
              (item, index) => {
                item.id = index + 1;
                return item;
              }
            );
          });
        }
        if (addedOptions !== void 0 && addedOptions.length) {
          state.offerObject.json.AdvantagesOwner.push(...addedOptions);
        }
      }
    }
    // new Object UPDATE
    // if (state.compareObject.Objects !== void 0) {
    //   let newOptions = state.compareObject.Objects
    //   let oldOptions = state.offerObject.json.Objects
    //
    //   _.each(newOptions.Options, i => {
    //     const changeEl = _.find(oldOptions, ['randomId', i.randomId])
    //     if (changeEl) {
    //       // console.log('sss ')
    //       changeEl.name = i.name
    //       changeEl.img = i.img
    //       changeEl.address = i.address
    //       changeEl.equipment = i.equipment
    //       changeEl.works = i.works
    //       changeEl.id = i.id
    //     }
    //   })
    //   if (newOptions.lengthDiffrent !== void 0
    //     && newOptions.lengthDiffrent.length
    //     && newOptions.lengthDiffrent[0].Objects !== void 0) {
    //
    //     const removedOptions = newOptions.lengthDiffrent[0].Objects.removedOptions
    //     const addedOptions = newOptions.lengthDiffrent[0].Objects.addedOptions
    //     if (removedOptions !== void 0 && removedOptions.length) {
    //       _.each(removedOptions, i => {
    //         state.offerObject.json.Objects = _.filter(state.offerObject.json.Objects, oldEl => {
    //           return i.randomId !== oldEl.randomId
    //         })
    //         state.offerObject.json.Objects = _.each(state.offerObject.json.Objects, (item, index) => {
    //           item.id = index + 1
    //           return item
    //         })
    //       })
    //     }
    //     if (addedOptions !== void 0 && addedOptions.length) {
    //       state.offerObject.json.Objects.push(...addedOptions)
    //     }
    //   }
    // }
    state.print.changeWindow = false;
  },
  [c.GET_RATE_VALUE](state, value) {
    state.rateValue = Number(parseFloat(value.replace(/,/g, ".")));
  },
  [c.REMOVE_OFFER_LINK](state) {
    state.offerObject.OpenAccessByLink = "0";
    state.offerObject.LinkToOffer = null;
  },
  [c.CREATE_OFFER_LINK](state, link) {
    state.offerObject.OpenAccessByLink = "1";
    state.offerObject.LinkToOffer = link;
  },
  [c.CONVERT_TO_RUB](state, data) {
    let rateValue,
      { newValue, oldValue } = data;

    if (state.offerObject.json.currency) {
      rateValue = state.offerObject.json.currency === "RUB" ? newValue : 1;
    }
    _.each(modulesArray, (module) => {
      _.each(optionsArray, (option) => {
        if (!(module === "Entry" && option === "barrier")) {
          if (state.offerObject.json.Modules[module][option] !== void 0) {
            _.each(state.offerObject.json.Modules[module][option], (i) => {
              if (i.custom) {
                if (state.offerObject.json.currency === "RUB") {
                  let value;
                  if (i.single_cost === void 0) i.single_cost = 0;
                  value = ((i.single_cost / oldValue) * rateValue).toFixed();

                  i.single_cost = value;
                  i.convertCost = value;
                } else {
                  i.convertCost = (+i.single_cost * rateValue).toFixed();
                }
              } else {
                const value = (+i.cost * rateValue).toFixed();
                i.convertCost = value;
                if (
                  i.single_cost &&
                  state.offerObject.json.currency === "RUB"
                ) {
                  i.single_cost = value;
                }
              }
            });
          }
        }
      });
    });
    _.each(state.offerObject.json.Total.Options.types, () => {
      // if (i.custom) {
      //   i.convertCost = (+i.single_cost * rateValue).toFixed()
      // } else {
      // if (i.name !== 'Проектные работы') {
      //   i.convertCost = (+i.cost * rateValue).toFixed()
      // }
      // }
    });
    // state.offerObject.json.Total.Options.types = _.map(state.offerObject.json.Total.Options.types, i => {
    //   if (i.name === 'Пуско-наладочные работы') {
    //     i.convertCost = getters.totalDevicesPrice * 7 / 100
    //   }
    //   return i
    // })
  },
  [c.CHANGE_COMMON_SETTINGS](state, data) {
    state.print[data.field] = data.value;
  },
  [c.CHANGE_SYSTEM](state, type) {
    state.config.system = type;
    if (type === "standart") {
      state.settingsObject.json = JSON.parse(
        JSON.stringify(state.settingsObjectStandart)
      );
    } else if (type === "qr") {
      state.settingsObject.json = JSON.parse(
        JSON.stringify(state.settingsObjectQr)
      );
    } else {
      state.settingsObject.json = JSON.parse(
        JSON.stringify(state.settingsObjectEconomy)
      );
    }
  },
};
