<template>
  <div class="main__body wiki active" id="main">
    <div class="ckeditor active">
      <div
        class="mb-20"
        v-if="currentArticle && currentArticle.ParentId !== void 0"
      >
        Добавить дочернюю статью для "{{ currentArticle.Name }}"
      </div>
      <div class="mb-20" v-else-if="currentCategory">
        Добавить статью для категории "{{ currentCategory.Name }}"
      </div>
      <form @submit.prevent="create">
        <div class="form-group field-wiki-name required">
          <label class="control-label"> Название</label>
          <input
            class="ui-input-field mb-20 ui-input-field__edit"
            v-model="form.Name"
            required
          />
        </div>
        <div class="form-group field-wiki-sort required">
          <label class="control-label">letters_numbers_symbol</label>
          <input
            type="number"
            class="ui-input-field ui-input-field__edit"
            value="10"
            min="1"
            v-model="form.Sort"
          />
        </div>
        <div class="form-group field-wiki-sort_alph">
          <label class="ui-input"
            ><span class="ui-input-label">Сортировка по алфавиту?</span>
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div id="wiki-sort_alph">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.SortAlph"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.SortAlph"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <div class="form-group field-wiki-moderation required">
          <label class="ui-input"
            ><span class="ui-input-label">На модерации?</span>
            <div
              class="form-group"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <div id="wiki-moderation" aria-required="true">
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <br />
        <div class="modal-buttons">
          <!--
          <div class="modal-button modal-button--accept">
            <a class="modal-show" href="#">Предпросмотр</a>
          </div>
        -->
          <div class="modal-button modal-button--accept">
            <a class="modal-save" href="#">
              <button type="submit">Сохранить</button>
            </a>
          </div>
          <div
            class="modal-button modal-button--refresh-link"
            @click.prevent="$emit('goBack')"
          >
            <a class="modal-save">
              <button type="button">Отменить</button>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_ARTICLE } from "../../../store/wiki/constants";
import * as _ from "lodash";
import { showAlert } from "../../../utils";

export default {
  props: ["article", "currentCategory", "currentArticle"],
  data() {
    return {
      form: {
        Name: "",
        Sort: "",
        Moderation: 0,
        Language: 1,
        SortAlph: 0,
        CategoryId: null,
        ParentId: null,
        IsShare: 1,
      },
    };
  },
  methods: {
    create() {
      this.form.CategoryId = this.currentCategory.Id;
      if (this.currentArticle && this.currentArticle.ParentId !== void 0)
        this.form.ParentId = this.currentArticle.Id;
      if (this.form.Name) {
        const formData = new FormData();
        _.each(this.form, (val, key) => {
          if (val) formData.append(key, val);
        });

        this[CREATE_ARTICLE](formData).then((data) => {
          this.$emit("setCurrentArticle", data);
        });
      } else {
        showAlert("", "", "Введите название");
      }
    },
    ...mapActions("wiki", [CREATE_ARTICLE]),
  },
};
</script>

<style scoped></style>
