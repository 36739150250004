<template>
  <div>
    <div>
      <div class="popup__body">
        <div class="cm-popup task-support-popup">
          <div class="task-popup-header">
            <div class="actions-header__col">
              <a
                class="button tm-btn tm-btn--add"
                href="#"
                @click="setAssignType('create')"
              >
                &plus;
              </a>
              <a
                href="#"
                class="icon-button task-checkbox-btn task-checkbox-btn--hide-completed"
                style="margin-left: 12px"
                @click="setAssignType('select')"
              >
                + Прикрепить
              </a>
            </div>
            <div class="actions-header__col actions-header__col_flexible">
              <div
                class="search-bar search-bar_white actions-header__search-bar"
              >
                <button type="submit" class="search-bar__button">
                  <svg>
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#icon_icon-search"
                    ></use>
                  </svg>
                </button>
                <input
                  class="search-bar__input"
                  type="search"
                  v-model="search"
                  placeholder="Введите название задачи"
                />
              </div>
            </div>
          </div>

          <div class="subtasks-list">
            <request-subtasks-list :subtasks="filteredTasks" />
          </div>

          <div class="row-spinner _full-height" v-if="preloader">
            <Spinner
              :noFadeIn="true"
              name="line-scale-pulse-out"
              color="#c9eeff"
              width="50"
              height="35"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="popupSlide">
      <task-group-selector
        v-if="taskGroupPopupOpen"
        @onClosePopup="taskGroupPopupOpen = false"
        :groups="allGroups"
        @onGroupSelect="handleGroupSelect"
        :moveLeft="
          taskCreatePopupOpen ? '-210px' : taskParentPopupOpen ? '-105px' : '0'
        "
      />
    </transition>

    <transition name="popupSlide">
      <task-parent-selector
        v-if="taskParentPopupOpen"
        @onClosePopup="taskParentPopupOpen = false"
        :groupId="selectedGroup.Id"
        :groupName="selectedGroup.GroupName"
        :assignType="taskAssignType"
        @onParentTaskSelect="handleParentSelect"
        @onTaskToRequestAssign="createTaskToRequestRelation"
        :moveLeft="taskCreatePopupOpen ? '-105px' : '0'"
      />
    </transition>

    <transition name="popupSlide">
      <TaskCreateForm
        v-if="taskCreatePopupOpen"
        :open="taskCreatePopupOpen"
        :parentTask="selectedParent"
        :section="selectedSection"
        :selectedGroup="selectedGroup"
        :requestForRelate="selectedRequest"
        :localUsersArray="
          selectedGroup
            ? selectedGroup.Users.map((i) => {
                return { ...i, ...i.User, Id: i.UserId, FullName: i.User.Fio };
              })
            : []
        "
        @taskCreated="closeAllInnerPopups"
        @onClosePopup="taskCreatePopupOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import popup from "../../../../../mixins/tasks/popup";
import TaskCreateForm from "@/modules/tasks/components/TaskCreateForm";
import TaskGroupSelector from "./TaskGroupSelector";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import RequestSubtasksList from "./RequestSubtasksList";
import * as c from "../../../../../store/tasks/constants";
import * as _ from "lodash";
import TaskParentSelector from "./TaskParentSelector";
import { SET_COMMON_LOCAL_STORAGE_SETTINGS } from "../../../../../store/tasks/constants";
import Api from "../../../../../api/tasks";
import { showAlert } from "../../../../../utils";

export default {
  mixins: [popup, common],
  data() {
    return {
      taskAssignType: "",
      taskCreatePopupOpen: false,
      taskGroupPopupOpen: false,
      taskParentPopupOpen: false,
      selectedItems: [],
      selectedGroup: null,
      selectedParent: null,
      selectedSection: null,
      parentTasks: [],
      search: "",
      preloader: true,
      templates: [],
    };
  },
  created() {},
  mounted() {
    let loadTasks = setInterval(() => {
      if (this.tasks) {
        this.preloader = false;
        clearInterval(loadTasks);
      }
    }, 200);
  },
  watch: {
    taskGroupPopupOpen(newVal) {
      if (newVal) this.$emit("openTaskPopup");
      else this.$emit("closeTaskPopup");
    },
  },
  computed: {
    tasks() {
      return this.selectedRequest.tasks;
    },
    filteredTasks() {
      const tasks = this.selectedRequest.tasks;
      if (tasks && tasks.length) {
        tasks.sort(function (a, b) {
          if (a.TaskName < b.TaskName) {
            return -1;
          }
          if (a.TaskName > b.TaskName) {
            return 1;
          }
          return 0;
        });
        return tasks.filter((item) => {
          const name = item.TaskName || "";
          return name.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return tasks;
    },
    ...mapState("tasks", ["allGroups"]),
    ...mapGetters("tasks", ["groupTemplates"]),
    ...mapState("rpsSupport", ["selectedRequest"]),
  },
  methods: {
    ...mapMutations("tasks", [SET_COMMON_LOCAL_STORAGE_SETTINGS]),
    setAssignType(type) {
      this.taskAssignType = type;
      this.taskGroupPopupOpen = true;
    },
    closeAllInnerPopups() {
      this.taskCreatePopupOpen = false;
      this.taskParentPopupOpen = false;
      this.taskGroupPopupOpen = false;
    },
    openSubTask(id) {
      this.$emit("onClosePopup");
      setTimeout(() => {
        this.$router.push("/support/" + id);
      }, 300);
    },
    handleParentSelect(item) {
      if (item !== null) {
        if (item.TaskName) {
          this.selectedParent = item;
        } else if (item.Name !== "virtualSection") this.selectedSection = item;
      }
      this.taskCreatePopupOpen = true;
    },
    createTaskToRequestRelation(taskId) {
      this.closeAllInnerPopups();
      this.preloader = true;
      Api.createTaskRequestRelation({
        RequestId: this.selectedRequest.Id,
        TaskId: taskId,
        SkipNotifications: true,
      }).then((res) => {
        if (res.status !== 200) {
          showAlert(
            "error",
            "Ошибка",
            "Произошла ошибка при прикреплении задачи"
          );
        }
        this.preloader = false;
      });
    },
    handleGroupSelect(group) {
      Api.getGroupById(group.Id).then((res) => {
        this.selectedGroup = res.data.group;
        this.taskParentPopupOpen = true;
      });
      Api.getTemplates(group.Id).then((res) => {
        this.templates = res.data;
      });
    },
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.selectedItems.includes(id)) {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    deleteItems() {
      this.selectedItems.forEach((id) => {
        this[c.DELETE_TASK]({ id });
        if (id === this.selectedItems[this.selectedItems.length - 1]) {
          this.unselectItems();
        }
      });
    },
    unselectItems() {
      this.selectedItems = [];
      document
        .querySelectorAll(".task-popup-list .checkbox__input")
        .forEach((i) => {
          i.checked = false;
        });
    },
    ...mapActions("tasks", [c.DELETE_TASK]),
  },
  components: {
    RequestSubtasksList,
    TaskParentSelector,
    TaskGroupSelector,
    TaskCreateForm,
  },
};
</script>

<style scoped></style>
