<template>
  <div class="settings-users_block">
    <div class="settings-users_block__name">
      <h3>Пользователи</h3>
    </div>
    <div class="settings-users_block__inner">
      <div
        class="settings-users_block__inner-header settings-users_block__inner-item"
      >
        <input
          type="text"
          @keyup="$emit('search', $event.target.value)"
          placeholder="Поиск"
        />

        <div class="search-bar__button">
          <svg>
            <use xlink:href="/static/images/icon.svg#icon_icon-search"></use>
          </svg>
        </div>
      </div>
      <div class="settings-users_block__preloader" v-if="user_load_preloader">
        <i class="circle-preloader"></i>
      </div>
      <div class="settings-users_block__inner-item-wrap" v-else>
        <div
          :class="isActive(user.Id)"
          :key="user.Id"
          v-for="user in orderedUsers"
          @click="
            active_user.Id === user.Id
              ? $emit('toggleShowUserConfig', false)
              : $emit('makeUserActive', user)
          "
        >
          <p>{{ user.FullName | short_name }}</p>
        </div>
      </div>
    </div>

    <span
      class="button button_green button_uppercase button_align_center button--settings"
      @click="$emit('addUser')"
      v-disabled="{
        allow: checkUserRight(rightUpdateUsers),
        title: 'Запрещено настройками системы',
      }"
    >
      <span v-if="user_add_preloader" class="reload-icon"></span>
      <span v-else>НОВЫЙ ПОЛЬЗОВАТЕЛЬ</span>
    </span>
  </div>
</template>

<script>
import { Helpers } from "../../../mixins/Helpers";

export default {
  props: [
    "orderedUsers",
    "user_load_preloader",
    "active_user",
    "active_role",
    "user_add_preloader",
  ],
  mixins: [Helpers],
  data() {
    return {
      rightUpdateUsers: "02BAACBA-8B68-4E2F-B302-23D1C1448219",
    };
  },
  methods: {
    isActive(id) {
      let className = "settings-users_block__inner-item";
      if (this.active_user.Id === id)
        className = className + " settings-users_block__inner-item--active";
      if (this.active_role.Users) {
        this.active_role.Users.forEach((item) => {
          if (item.Id === id) {
            className =
              className + " settings-users_block__inner-item--role-active";
          }
        });
      }
      return className;
    },
  },
  filters: {
    short_name: function (value) {
      if (!value) return "";
      value = value.split(" ");
      let shortName = "";
      value.forEach((item, i) => {
        shortName = i === 0 ? `${item} ` : shortName + `${item[0]}.`;
      });
      return shortName;
    },
  },
};
</script>

<style lang="scss">
.settings-users_block__inner-item-wrap {
  height: 100%;
  /*max-height: 639px;*/
}
.settings-users_block__name {
  flex-shrink: 0;
}
.settings-users_block__inner {
  overflow: hidden;
}
.settings-users_block {
  overflow: hidden;
}
</style>
