export const GET_SETTINGS = "GET_SETTINGS";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER";
export const RESET_IMAGES = "RESET_IMAGES";
export const RESET_OFFER = "RESET_OFFER";
export const SAVE_OFFER = "SAVE_OFFER";
export const GET_OFFERS = "GET_OFFERS";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const ADD_OFFER = "ADD_OFFER";
export const REMOVE_OFFER = "REMOVE_OFFER";
export const CREATE_DUMP_OF_SETTINGS = "CREATE_DUMP_OF_SETTINGS";
export const RESTORE_SETTINGS_FROM_DUMP = "RESTORE_SETTINGS_FROM_DUMP";
export const CHANGE_COMPARE_OBJECT = "CHANGE_COMPARE_OBJECT";
export const CREATE_COPY_OFFER = "CREATE_COPY_OFFER";
export const RESET_COMPARE = "RESET_COMPARE";
export const GET_RATE_VALUE = "GET_RATE_VALUE";
export const CONVERT_TO_RUB = "CONVERT_TO_RUB";
export const GET_EXAMPLES_OBJECTS = "GET_EXAMPLES_OBJECTS";
export const GET_COMMERCIAL_SITE_URL = "GET_COMMERCIAL_SITE_URL";
export const CREATE_OFFER_LINK = "CREATE_OFFER_LINK";
export const REMOVE_OFFER_LINK = "REMOVE_OFFER_LINK";
export const CREATE_CLEAR_COPY_OFFER = "CREATE_CLEAR_COPY_OFFER";
export const CHANGE_COMMON_SETTINGS = "CHANGE_COMMON_SETTINGS";
export const CHANGE_SYSTEM = "CHANGE_SYSTEM";
export const ADD_OFFERS_TO_LIST = "ADD_OFFERS_TO_LIST";

export const SALE_MODULE_RIGHT = "2A028788-59B1-40E7-81A2-2B820C6CCEBB";
export const SHOW_OTHER_OFFERS_RIGHT = "DBE53D4E-6CDE-43EC-B4E6-8C783EE40C30";
export const CREATE_OFFER_RIGHT = "A1E64A99-C104-4C85-A258-666107E299FA";
export const EDIT_OFFER_RIGHT = "CD3573CC-7385-40A1-92FE-7742F8343F6F";
export const CHANGE_OFFER_STATUS_RIGHT = "231D9560-FE47-4450-A3DE-C7FE1835D084";
export const EDIT_OFFER_TEMPLATES_RIGHT =
  "A5A422F0-2B3E-4E2A-AC15-422F527AB654";
export const TASKS_MODULE_RIGHT = "374A7336-D855-4FB9-9914-C4EAC471618F";
