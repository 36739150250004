import * as c from "../../../../store/tasks/constants";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  SET_COMMON_LOCAL_STORAGE_SETTINGS,
  SET_CURRENT_GROUP,
  SET_SEARCH_TASKS_INPUT_VALUE,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_CACHED,
  SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK,
} from "../../../../store/tasks/constants";

export default {
  computed: {
    ...mapState("tasks", ["localStorageSettings", "settings", "allGroups"]),
    ...mapGetters("tasks", ["group", "selectedFilter"]),
  },
  methods: {
    isActive(id) {
      if (
        this.settings.currentGroup &&
        this.settings.currentGroup.Id === id &&
        (!this.selectedFilter || this.selectedFilter < 0) &&
        !this.countForFlashFilterCheck
      ) {
        return true;
      }
    },
    changeGroup(group) {
      this[SET_SEARCH_TASKS_INPUT_VALUE]("");
      this[SET_CURRENT_GROUP]({ group });
      this.headerControlElement().unselectTasks();
    },
    ...mapActions("tasks", [
      c.GET_PROCESSES,
      c.GET_TASKS,
      c.GET_SECTIONS,
      SET_CURRENT_GROUP,
    ]),
    ...mapMutations("tasks", [
      SET_COMMON_LOCAL_STORAGE_SETTINGS,
      SET_TABLE_FILTER,
      SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK,
      SET_TABLE_FILTER_CACHED,
      SET_SEARCH_TASKS_INPUT_VALUE,
    ]),
  },
};
