<template>
  <div class="tm-sidebar-filters">
    <div class="sidebar-draggable-zone">
      <div class="task-form-filter__title">Заявки</div>
      <div
        v-for="key in tabsSection1"
        :key="key"
        class="task-form-filter__item"
        :class="tabClassName(key)"
        @click="setActiveTab(key)"
      >
        <div class="task-form-filter__item-row">
          <img
            :src="`static/images/tasks/icons/${tabIcon(key)}`"
            alt="icon"
            class="task-form-filter__item-icon"
          />
          <p>{{ tabName(key) }}</p>
          <div class="task-form-filter__item-count">{{ count[key] }}</div>
        </div>
      </div>
    </div>
    <div class="sidebar-draggable-zone" style="margin-top: 20px">
      <div class="task-form-filter__title">Заявки партнёрам</div>
      <div
        v-for="key in tabsSection2"
        :key="key"
        class="task-form-filter__item"
        :class="tabClassName(key)"
        @click="setActiveTab(key)"
      >
        <div class="task-form-filter__item-row">
          <img
            :src="`static/images/tasks/icons/${tabIcon(key)}`"
            alt="icon"
            class="task-form-filter__item-icon"
          />
          <p>{{ tabName(key) }}</p>
          <div class="task-form-filter__item-count">{{ count[key] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import * as c from "@/store/support/constants";
import store from "../../../../store/store";
export default {
  name: "RequestsSidebarBody",
  computed: {
    ...mapState("rpsSupport", ["count", "activeTabs", "commonSettings"]),
    ...mapGetters("rpsSupport", ["activeTab"]),
  },
  data() {
    return {
      tabsSection1: [
        "open",
        "closed",
        "responsible",
        "without_responsible",
        "dist_to_admin_open",
      ],
      tabsSection2: ["customer_to_dist_open", "customer_to_dist_closed"],
    };
  },
  methods: {
    ...mapActions("rpsSupport", [
      c.SET_ACTIVE_TAB,
      c.GET_REQUESTS,
      c.SET_SEARCH_INPUT,
      c.CHANGE_MANAGER,
    ]),
    ...mapMutations("rpsSupport", [c.SET_PAGE]),
    tabName(tab) {
      switch (tab) {
        case "open":
          return "Активные";
        case "closed":
          return "Закрытые";
        case "responsible":
          return "Я ответственный";
        case "without_responsible":
          return "Без ответственного";
        case "dist_to_admin_open":
          return "От партнёров";
        case "dist_to_admin_closed":
          return "Закрытые";
        case "customer_to_dist_open":
          return "Активные";
        case "customer_to_dist_closed":
          return "Закрытые";
      }
      return "Заявки";
    },
    tabIcon(tab) {
      switch (tab) {
        case "open":
          return "fire-icon.png";
        case "closed":
          return "check-icon.svg";
        case "responsible":
          return "man-icon.svg";
        case "without_responsible":
          return "man-icon.svg";
        case "dist_to_admin_open":
          return "case-icon.svg";
        case "dist_to_admin_closed":
          return "check-icon.svg";
        case "customer_to_dist_open":
          return "case-icon.svg";
        case "customer_to_dist_closed":
          return "check-icon.svg";
      }
      return "case-icon.svg";
    },
    tabClassName(tab) {
      return this.activeTab === tab ? "task-form-filter__item--active" : "";
    },
    setActiveTab(tab) {
      this.commonSettings.cancelRequest = true;
      this[c.SET_PAGE](0);
      if (tab === "without_responsible") {
        this.commonSettings.managerForFilter = " ";
      } else if (tab === "responsible") {
        this.commonSettings.managerForFilter =
          store.modules.common.state.userData.Id;
      } else {
        this.commonSettings.managerForFilter = null;
      }

      this[c.GET_REQUESTS]({
        scope: tab,
        start: false,
        force: true,
      });
      this[c.SET_ACTIVE_TAB](tab);
    },
  },
};
</script>

<style scoped></style>
