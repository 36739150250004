<template>
  <div
    class="automation-template__row automation-template__row--item"
    :class="{ 'automation-template__row--no-edit': !edit }"
    v-click-outside="offEdit"
  >
    <div class="automation-template__col">
      <div class="automation-template__index">{{ index + 1 }}</div>
    </div>
    <AutomationBuilder
      class="automation-template__col"
      :form="item"
      :parentData="parentData"
      :saveOnBlur="true"
      :edit="edit"
      @save="save"
    />
    <div class="automation-template__col automation-template__col--btns">
      <button class="automation-template__delete-btn" @click="deleteTemplate">
        <svg width="18" height="18">
          <use xlink:href="/static/images/tasks/sprite.svg#delete-icon"></use>
        </svg>
      </button>
      <button class="automation-template__settings-btn" @click="edit = !edit">
        <svg width="18" height="18">
          <use xlink:href="/static/images/tasks/sprite.svg#settings-icon"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { Helpers } from "../../../../../mixins/Helpers";
import AutomationBuilder from "../AutomationBuilder";
import {
  DELETE_AUTOMATION_TEMPLATE,
  UPDATE_AUTOMATION_TEMPLATE,
} from "../../../../../store/tasks/constants";
import { mapActions } from "vuex";

export default {
  name: "AutomationCreateControlItem",
  mixins: [Helpers],
  props: ["index", "item", "parentData"],
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    offEdit() {
      this.edit = false;
    },
    deleteTemplate() {
      this.parentData.preloader = true;
      this[DELETE_AUTOMATION_TEMPLATE](this.item.Id).finally(
        () => (this.parentData.preloader = false)
      );
    },
    save(data) {
      this[UPDATE_AUTOMATION_TEMPLATE](data);
    },
    ...mapActions("tasks", [
      DELETE_AUTOMATION_TEMPLATE,
      UPDATE_AUTOMATION_TEMPLATE,
    ]),
  },
  components: {
    AutomationBuilder,
  },
};
</script>

<style scoped></style>
