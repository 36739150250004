<template>
  <div class="knowledge-base-module__wrap">
    <form id="w0" class="knowledge-base-module__form" @submit.prevent="create">
      <h3 class="form__title">Категория</h3>
      <div class="form__body">
        <div class="form__field field-wikicategory-name required">
          <label class="ui-input">
            <span class="ui-input-label">Название:</span>
            <input type="text" class="ui-input-field" v-model="form.Name" />
          </label>
        </div>
        <div class="form__field field-wikicategory-slug required">
          <label class="ui-input"
            ><span class="ui-input-label">Слаг:</span>
            <input type="text" class="ui-input-field" v-model="form.Slug" />
          </label>
        </div>
        <div class="form__field field-wikicategory-sort required">
          <label class="ui-input">
            <span class="ui-input-label">Сортировка:</span>
            <input
              type="number"
              class="ui-input-field ui-input-field__edit"
              min="1"
              v-model="form.Sort"
            />
          </label>
        </div>
        <div class="form__field field-wikicategory-moderation required">
          <label class="ui-input">
            <span class="ui-input-label">На модерации?</span>
            <div style="padding-top: 10px; padding-bottom: 10px">
              <div>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="1"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Да</span>
                </label>
                <label class="ui-radio">
                  <input
                    class="ui-radio__field"
                    type="radio"
                    value="0"
                    v-model="form.Moderation"
                  />
                  <span class="ui-radio__icon"></span>
                  <span class="ui-radio__lable">Нет</span>
                </label>
              </div>
            </div>
          </label>
        </div>
        <div style="padding-bottom: 20px">
          <button type="submit" class="ui-button form__submit">
            Добавить категорию
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_CATEGORY } from "../../../store/wiki/constants";
import * as _ from "lodash";
import { showAlert } from "../../../utils";

export default {
  data() {
    return {
      form: {
        Name: "",
        Slug: "",
        Sort: "",
        Moderation: 0,
        Language: 1,
      },
    };
  },
  methods: {
    create() {
      if (!this.form.Name) return showAlert("", "", "Напишите название");
      if (!this.form.Slug) return showAlert("", "", "Напишите Slug");
      const formData = new FormData();
      _.each(this.form, (val, key) => {
        if (val) formData.append(key, val);
      });
      this[CREATE_CATEGORY](formData).then(() => {
        this.$emit("goBack");
      });
    },
    ...mapActions("wiki", [CREATE_CATEGORY]),
  },
};
</script>

<style scoped></style>
