<template>
  <div class="task-bubble" :class="className" v-click-outside="close">
    <div class="task-bubble__search">
      <svg>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="/static/images/icon.svg#icon_icon-search"
        ></use>
      </svg>
      <input
        type="text"
        class="input"
        placeholder="Поиск тега"
        v-model="search"
        ref="input"
      />
      <div class="task-bubble__close" @click="close()"><i></i></div>
    </div>
    <div class="task-bubble__list" ref="list">
      <TagsListSearchItem
        v-for="(item, index) in outsideTags || currentGroupGroupsTags"
        :key="index"
        :item="item"
        :search="outerSearch ? outerSearch : search"
        @choose="choose"
        :currentGroupGroupsTags="outsideTags || currentGroupGroupsTags"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import common from "../../../../../mixins/tasks/common";
import { Helpers } from "../../../../../mixins/Helpers";
import * as _ from "lodash";
import TagsListSearchItem from "@/modules/tasks/components/CommonComponents/TagsListSearch/TagsListSearchItem";

export default {
  mixins: [common, Helpers],
  props: {
    className: {
      default: "",
    },
    outerSearch: {
      default: "",
    },
    outsideTags: {
      default: null,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  destroyed() {
    document.onkeydown = this.settings.keydownFunction;
  },
  mounted() {
    this.$refs["input"].focus();
  },
  computed: {
    currentGroupGroupsTags() {
      if (this.group && this.group.TagsGroups.length) {
        let arr = [];
        _.each(this.tagGroups, (i) => {
          if (_.find(this.group.TagsGroups, ["TagGroupId", i.Id])) {
            arr.push(i);
          }
        });
        const search = this.outerSearch ? this.outerSearch : this.search;

        if (search) {
          arr = _.filter(arr, (i) => {
            let can = false;
            i.tags &&
              i.tags.forEach((i) => {
                const re = new RegExp(search, "gi");
                if (re.test(i.TagName)) can = true;
              });
            return can;
          });
        }
        return _.orderBy(arr, ["GroupName"], ["asc"]);
      }
      return [];
    },
    ...mapState("tasks", ["tags", "tagGroups", "settings"]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    choose(item) {
      this.$emit("choose", item.TagId ? item.TagId : item.Id);
      this.$emit("chooseTag", {
        ...item,
        Id: item.TagId ? item.TagId : item.Id,
      });
      this.$emit("chooseSupportTag", item);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    TagsListSearchItem,
  },
};
</script>

<style scoped></style>
