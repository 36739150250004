import axios from "axios";
import { API_ROOT } from "@/config";
import {
  newCompany,
  newObject,
  newContact,
  newDistb,
  newContactDist,
} from "@/utils/formPayloads";

export default {
  getObjects() {
    return axios.get(`${API_ROOT}api/clients/Object`);
  },
  getCompanies() {
    return axios.get(`${API_ROOT}api/clients/Company`);
  },
  editMultipleObjects(data) {
    return axios.post(`${API_ROOT}api/clients/Object/MultiEdit`, data);
  },
  // ...
  updateCompany(id, payload) {
    return axios.put(`${API_ROOT}api/clients/Company/${id}`, payload);
  },
  createCompany() {
    return axios.post(`${API_ROOT}api/clients/Company`, newCompany);
  },
  deleteCompany(companyId) {
    return axios.delete(`${API_ROOT}api/clients/Company/` + companyId);
  },
  // ...
  updateObject(id, payload) {
    return axios.put(`${API_ROOT}api/clients/Object/${id}`, payload);
  },
  patchObject(id, payload) {
    return axios.patch(`${API_ROOT}api/clients/Object/${id}`, payload);
  },
  createObject() {
    return axios.post(`${API_ROOT}api/clients/Object`, newObject);
  },
  deleteObject(objectId) {
    return axios.delete(`${API_ROOT}api/clients/Object/` + objectId);
  },
  generateLicense(id) {
    return axios.post(`${API_ROOT}api/clients/object/generate/${id}`);
  },
  updateLicense(id, ExpireSet) {
    return axios.post(
      `${API_ROOT}api/clients/object/generate/${id}`,
      ExpireSet
    );
  },
  // ...
  updateContact(id, payload) {
    return axios.put(`${API_ROOT}api/clients/ObjectContacts/${id}`, payload);
  },
  createContact(objectId) {
    return axios.post(
      `${API_ROOT}api/clients/ObjectContacts`,
      newContact(objectId)
    );
  },
  deleteContact(companyId) {
    return axios.delete(`${API_ROOT}api/clients/ObjectContacts/` + companyId);
  },
  // ...
  updateDistributerContact(id, payload) {
    return axios.put(
      `${API_ROOT}api/clients/DistributorContacts/${id}`,
      payload
    );
  },
  createDistributerContact(distbId) {
    return axios.post(
      `${API_ROOT}api/clients/DistributorContacts`,
      newContactDist(distbId)
    );
  },
  deleteDistributerContact(Id) {
    return axios.delete(`${API_ROOT}api/clients/DistributorContacts/` + Id);
  },
  // ...
  getDevices(ObjectId) {
    return axios.get(`${API_ROOT}api/clients/DeviceInfo?ObjectId=${ObjectId}`);
  },
  updateDevice(deviceId, payload) {
    return axios.put(`${API_ROOT}api/clients/DeviceInfo/${deviceId}`, payload);
  },
  // ...
  getDeviceActions(ObjectId) {
    return axios.get(`${API_ROOT}api/clients/DeviceAction?object=${ObjectId}`);
  },
  updateDeviceActions(ObjectId, ActionId, payload) {
    return axios.put(
      `${API_ROOT}api/clients/DeviceAction/${ActionId}?object=${ObjectId}&action_id=${ActionId}`,
      payload
    );
  },
  // ...
  getDistb() {
    return axios.get(`${API_ROOT}api/clients/Distributor`);
  },
  getSingleDistb(id) {
    return axios.get(`${API_ROOT}api/clients/Distributor/${id}`);
  },
  createDistb() {
    return axios.post(`${API_ROOT}api/clients/Distributor`, newDistb);
  },
  updateDistb(distbId, payload) {
    return axios.patch(
      `${API_ROOT}api/clients/Distributor/${distbId}`,
      payload
    );
  },
  deleteDistb(id) {
    return axios.delete(`${API_ROOT}api/clients/Distributor/` + id);
  },
  // ...
  // attach
  attachCompanyToDistb(payload) {
    return axios.post(`${API_ROOT}api/clients/DistributorClients`, payload);
  },
  attachObjectToDistb(payload) {
    return axios.post(`${API_ROOT}api/clients/DistributorObjects`, payload);
  },
  getDistbCompanies() {
    return axios.get(`${API_ROOT}api/clients/DistributorClients`);
  },
  getDistbObjects() {
    return axios.get(`${API_ROOT}api/clients/DistributorObjects`);
  },
  getDistbContacts() {
    return axios.get(`${API_ROOT}api/clients/DistributorContacts`);
  },
  createDistbContact(payload) {
    return axios.post(`${API_ROOT}api/clients/DistributorContacts`, payload);
  },
  patchDistbContact(payload) {
    return axios.patch(
      `${API_ROOT}api/clients/DistributorContacts/${payload.Id}`,
      payload
    );
  },
  removeCompanyAttach(id) {
    return axios.delete(`${API_ROOT}api/clients/DistributorClients/${id}`);
  },
  removeObjectAttach(id) {
    return axios.delete(`${API_ROOT}api/clients/DistributorObjects/${id}`);
  },
};
