<template>
  <div
    class="table-row table-row_draggable table__row--col"
    :class="{
      'task-table-row--open': this.rowIsOpen && this.childsCount,
      'table-row--task-closed': this.item.Status === '2',
      'task-table-row--loading': this.item.VirtualId,
    }"
  >
    <div
      class="table__row table__row_main"
      :class="{
        'table__row--selected':
          parentData.selectedItems &&
          parentData.selectedItems.find((x) => x.Id === item.Id),
        'table__row--resizeble':
          !inDetailTask && !listState && settings.mainTableColsWidth.name,
      }"
      @click.shift.capture.stop.prevent="$emit('shiftSelection', $event)"
      @click.meta.capture.stop.prevent="$emit('ctrlSelection', $event)"
      @click.ctrl.capture.stop.prevent="$emit('ctrlSelection', $event)"
      :data-task-id="item.Id"
      @contextmenu.prevent="$refs.menu.open($event)"
    >
      <ContextPopupMenu
        v-if="!listState"
        ref="menu"
        :item="item"
        :boundariesElement="boundariesElement"
        @openDetailTask="openDetailTask"
        @createSubTask="$emit('createSubTask', item)"
        @copyLink="copyLink"
        @copyTask="copyTask"
        @closeTask="closeTask"
        @openTask="openTask"
        @deleteTask="deleteTask"
      />
      <div
        class="task-table-row-inner-indicator"
        v-for="c in new Array(level)"
        :key="c"
      ></div>
      <div class="table__col_label">
        <label
          class="checkbox checkbox_white table__checkbox js-index-checkbox task-row-count-checkbox"
        >
          <input
            class="checkbox__input main_checkbox_input"
            type="checkbox"
            :task-id="item.Id"
            name="row_checkbox_clients"
            @click.stop.capture="$emit('selectItem', $event, item)"
            :class="{
              'checkbox__box--active':
                listState &&
                parentData.selectedItems &&
                parentData.selectedItems.find((x) => x.Id === item.Id),
            }"
          />
          <span class="checkbox__box main_checkbox_span table__checkbox--tasks">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
          <div class="task-row-count">{{ index + 1 }}</div>
        </label>
      </div>
      <div
        class="table__col table__col_name table__col_flexible"
        :style="{ width: getColWidth('name') }"
      >
        <div class="task-row-first">
          <div
            class="task-row-link"
            @mousedown.middle="openDetailTask(true)"
            @click.exact="
              !listState
                ? openDetailTask(false)
                : $emit('selectItem', $event, item)
            "
            @click.alt="
              () => {
                if (!listState) {
                  editMode = true;
                  $nextTick(() => $refs.nameInput.focus());
                }
              }
            "
            @mouseover.alt="editMode = true"
            @mouseleave="editMode = false"
          >
            <div
              class="table-c__arrow"
              v-if="childsCount"
              @click.stop="toggleTableRowOpenState"
            >
              <svg width="12" height="7">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
                ></use>
              </svg>
            </div>
            <div class="table-c__arrow" style="opacity: 0.2" v-else>
              <svg width="12" height="7">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
                ></use>
              </svg>
            </div>
            <label
              class="table-c__name-label"
              :class="{
                'table-c__name-label--hover-with-key': editMode || isFocused,
              }"
            >
              <svg
                width="11"
                height="11"
                class="table-row__depend-icon"
                v-if="item.isDepended"
                stroke="#000"
              >
                <use
                  xlink:href="/static/images/tasks/sprite.svg#attach-icon"
                ></use>
              </svg>
              <input
                class="table-c__name"
                @click.prevent.stop
                @blur="
                  changeName($event);
                  isFocused = false;
                "
                @focus="isFocused = true"
                @keydown="validateChangeName"
                v-model="newName"
                ref="nameInput"
                @keypress.13.prevent="createTaskByEnter"
              />
              <p class="table-c__name table-c__name_shadow">
                {{ newName }}
              </p>
              <span class="table-row__lock-icon" v-if="item.TaskAccess === '2'">
                <svg width="10" height="12">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/tasks/sprite.svg#lock-pink-icon"
                  ></use>
                </svg>
              </span>
            </label>
            <div class="table-c__name-info">
              <div class="task-deep-info" v-if="childsCount">
                <i class="deep-icon"></i>{{ childsCount }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!listState">
        <div
          class="table__col"
          :class="
            inDetailTask ? 'table__col_width_140' : 'table__col_width_160'
          "
          v-if="
            inDetailTask
              ? inDetailTask.ProcessId
              : (group && group.MainProcessId) || filterHaveProcessCondition
          "
          :style="{ width: getColWidth('status') }"
          ref="portal-wrapper"
        >
          <template v-if="statusFieldShow">
            <p
              class="table__status-label status-label"
              :style="{ backgroundColor: item.StatusModel.Color }"
              v-if="item.StatusModel"
              @click="
                checkUserTaskRight('change_status') &&
                  (setStatusBubbleOpen = true)
              "
            >
              {{ item.StatusModel.Name }}
            </p>
            <p
              class="table__status-label--not-set task-create-form-subtasks__person--add"
              v-else-if="checkUserTaskRight('change_status')"
              @click="setStatusBubbleOpen = true"
            >
              +
            </p>
            <portal to="common">
              <transition name="fade">
                <StatusesListSearch
                  v-if="setStatusBubbleOpen"
                  :items="taskStatusesArray"
                  :selectedId="item.StatusId"
                  @choose="setProcessStatus"
                  @close="setStatusBubbleOpen = false"
                  v-portal="{ parent: $refs['portal-wrapper'] }"
                />
              </transition>
            </portal>
          </template>
        </div>
        <div
          class="table__col table__col_width_160 table__col--tags-wrap"
          v-if="!inDetailTask"
          ref="portal-wrapper-tags"
          :style="{ width: getColWidth('tags') }"
        >
          <div class="task-labels">
            <span
              class="task-label"
              v-for="item in sortTags(item.tags)"
              :style="{ backgroundColor: item.Color }"
              :key="item.Id"
              @click="showTagsListBubble = true"
              >{{ item.TagName }}
            </span>

            <div
              class="add-tag-btn"
              v-if="!item.tags || !item.tags.length"
              @click="addTagBubbleOpen2 = true"
            >
              +
            </div>
          </div>
          <div
            class="task-labels task-labels--bubble"
            v-if="showTagsListBubble"
            v-click-outside="closeTagListBubble"
            ref="portal-wrapper-tags-2"
          >
            <span
              class="task-label"
              v-for="item in sortTags(item.tags)"
              :style="{ backgroundColor: item.Color }"
              :key="item.Id"
              >{{ item.TagName }}
              <div
                class="task-table-person__delete"
                @click="deleteTag(item.Id)"
              >
                <svg focusable="false" viewBox="0 0 32 32">
                  <path
                    d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                  ></path>
                </svg>
              </div>
            </span>
            <div class="add-tag-btn" @click="addTagBubbleOpen = true">+</div>
            <transition name="fade">
              <TagsListSearch
                v-if="addTagBubbleOpen"
                :className="'task-bubble--tag'"
                :taskTags="item.tags || []"
                :task="item"
                @choose="addTag"
                @close="addTagBubbleOpen = false"
                v-portal="{ parent: $refs['portal-wrapper-tags-2'] }"
              />
            </transition>
          </div>
          <portal to="common">
            <transition name="fade">
              <TagsListSearch
                v-if="addTagBubbleOpen2"
                :className="'task-bubble--tag'"
                :taskTags="item.tags || []"
                :task="item"
                @choose="addTag"
                @close="addTagBubbleOpen2 = false"
                v-portal="{ parent: $refs['portal-wrapper-tags'] }"
              />
            </transition>
          </portal>
        </div>
        <div
          class="table__col table__col_width_160"
          :style="{ width: getColWidth('resp') }"
        >
          <person
            :item="item"
            :taskNotAccepted="
              item.ApproveRecordId && Object.keys(item.ApproveRecordId).length
            "
            :hideDesc="true"
          />
        </div>
        <div
          class="table__col table__col_width_160"
          :style="{ width: getColWidth('date') }"
        >
          <finish-date
            :class="{ 'finish-date--red': dateExpired }"
            :item="item"
          />
        </div>
        <div
          class="table__col table__col--relative table__col_width_50"
          :style="{
            width: getColWidth('view'),
          }"
          v-if="!inDetailTask && !listState"
        >
          <div
            class="task-row-view"
            @click="item.ProcessId && setShowViewTab(viewTypeTab === 1 ? 2 : 1)"
            :class="{
              'task-row-view--no-process': !item.ProcessId,
            }"
            :title="!item.ProcessId && 'Нет активного процесса'"
          >
            <button
              class="task-row-view__btn"
              :class="{ 'task-row-view__btn--active': viewTypeTab === 1 }"
              title="Стандартный"
            >
              <svg width="10" height="10" style="transform: rotate(-90deg)">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                ></use>
              </svg>
            </button>
            <button
              class="task-row-view__btn"
              :class="{ 'task-row-view__btn--active': viewTypeTab === 2 }"
              title="Канбан"
            >
              <svg width="10" height="10">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                ></use>
              </svg>
            </button>
          </div>
        </div>
      </template>
    </div>
    <Kanban
      v-if="
        item.childs &&
        item.childs.length &&
        taskActiveProcess &&
        viewTypeTab === 2 &&
        rowIsOpen
      "
      :process="taskActiveProcess"
      :tasks="item.childs"
      :createTaskFunction="() => $emit('createSubTask', item)"
    />
    <!--    <TableRow-->
    <!--      v-else-if="viewTypeTab === 1"-->
    <!--      v-for="(item, index) in item.childs"-->
    <!--      :key="index"-->
    <!--      -->
    <!--      :item="item"-->
    <!--      :index="index"-->
    <!--      :inDetailTask="inDetailTask"-->
    <!--      :level="level + 1"-->
    <!--      :className="'task-table-row-inner'"-->
    <!--      :selectedTaskParentData="selectedTaskParentData"-->
    <!--      :parentData="parentData"-->
    <!--      @selectItem="($event, id) => $emit('selectItem', $event, id)"-->
    <!--      @createSubTask="(item) => $emit('createSubTask', item)"-->
    <!--      @shiftSelection="($event) => $emit('shiftSelection', $event)"-->
    <!--      @ctrlSelection="($event) => $emit('ctrlSelection', $event)"-->
    <!--    />-->
    <div class="inner-section-wrapper" v-else-if="viewTypeTab === 1">
      <TableSection
        :className="'task-table-row-inner'"
        v-for="(section, index) in computedSections"
        :key="'section' + section.Id"
        :section="section"
        :sectionTasks="section.tasks"
        :sections="computedSections"
        :selectedTaskParentData="selectedTaskParentData"
        :haveNextSection="index + 1 < computedSections.length"
        :parentData="parentData"
        :parentTask="item"
        :currentElement="currentElement"
        :level="level + 1"
        :draggableId="item.Id"
        :inDetailTask="inDetailTask"
        :boundariesElement="boundariesElement"
        :listState="listState"
        @changeOrder="changeOrder"
        @setTasksByDraggable="
          (parentTask, tasks, section) =>
            $emit('setTasksByDraggable', parentTask, tasks, section)
        "
        @selectItem="($event, item) => $emit('selectItem', $event, item)"
        @createSubTask="(item) => $emit('createSubTask', item)"
        @shiftSelection="($event) => $emit('shiftSelection', $event)"
        @ctrlSelection="($event) => $emit('ctrlSelection', $event)"
      />
    </div>
    <div class="row-spinner" v-if="preloader">
      <Spinner
        :noFadeIn="true"
        name="line-scale-pulse-out"
        color="#c9eeff"
        width="50"
        height="35"
      />
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import * as _ from "lodash";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Person from "../TableComponents/Person";
import FinishDate from "../TableComponents/FinishDate";
import * as c from "../../../../../store/tasks/constants";
import TagsListSearch from "../../CommonComponents/TagsListSearch/TagsListSearch";
import { Helpers } from "../../../../../mixins/Helpers";
import TableRow from "./TableRow";
import Kanban from "../../Kanban/Kanban";
import StatusesListSearch from "../../CommonComponents/StatusesListSearch";
import {
  CHANGE_ORDER,
  COPY_TASK,
  CREATE_TASK,
  GET_PARENT_TASK_SECTIONS,
  GET_TASKS,
  GET_USER_TASKS_LIST,
  REFRESH_SECTIONS,
  REMOVE_VIRTUAL_TASK,
  SET_LOCAL_STORAGE_SETTINGS,
  SET_SECTIONS,
} from "../../../../../store/tasks/constants";
import moment from "moment";
import Spinner from "vue-spinkit";
import { taskCreateFormTemplate } from "@/modules/tasks/components/TaskCreateForm/templateData";
import { randomId } from "@/mixins/sales/SalesJSONS";
import ContextPopupMenu from "@/modules/tasks/components/Tables/MainTable/ContextPopupMenu";

export default {
  name: "TableRow",
  mixins: [common, Helpers],
  props: [
    "item",
    "className",
    "level",
    "index",
    "inDetailTask",
    "selectedTaskParentData",
    "parentData",
    "section",
    "filterHaveProcessCondition",
    "boundariesElement",
    "listState",
  ],
  data() {
    return {
      addTagBubbleOpen: false,
      addTagBubbleOpen2: false,
      showTagsListBubble: false,
      viewTypeTab: 1,
      showViewTypeTab: false,
      rowIsOpen: false,
      setStatusBubbleOpen: false,
      sections: [],
      preloader: false,
      currentElement: this,
      newName: this.item.TaskName,
      loading: false,
      tasksQueue: [],
      editMode: false,
      contextMenuPopupOpen: false,
      isFocused: false,
    };
  },
  created() {
    if (this.group) {
      const foundLocalSettings = _.find(this.localStorageSettings, {
        Id: this.item.Id,
        Type: "tableTask",
      });
      if (foundLocalSettings) {
        const settings = JSON.parse(foundLocalSettings.settings);
        this.rowIsOpen =
          settings.rowIsOpen !== void 0 ? settings.rowIsOpen : this.rowIsOpen;
        this.viewTypeTab =
          settings.viewTypeTab !== void 0
            ? settings.viewTypeTab
            : this.viewTypeTab;
        this.showViewTypeTab =
          settings.showViewTypeTab !== void 0
            ? settings.showViewTypeTab
            : this.showViewTypeTab;
        if (this.rowIsOpen) {
          this.getInnerRowInfo();
        }
      }
    }
  },
  mounted() {
    if (this.item.VirtualId) {
      this.$refs.nameInput.focus();
    }
  },
  computed: {
    taskActiveProcess() {
      return _.find(this.processes, { Id: this.item.ProcessId });
    },
    taskStatusesArray() {
      let processId = this.item.ParentProcessId;
      // if (this.filterHaveProcessCondition) {
      //   processId = this.filterHaveProcessCondition;
      // }

      const found = _.find(this.processes, ["Id", processId]);
      return found ? found.statuses : [];
    },
    parentItem() {
      let found = null;
      let ParentId = null;

      function findChild(i) {
        if (!found) {
          if (i.Id === ParentId) {
            found = i;
          } else if (i.childs && i.childs.length) {
            _.each(i.childs, (c) => {
              findChild(c);
            });
          }
        }
      }

      if (this.item && this.item.ParentId !== void 0) {
        ParentId = this.item.ParentId;

        _.each(this.tasks, (i) => {
          findChild(i);
        });
        return found;
      }
      return false;
    },
    dateExpired() {
      const timeExpired = this.item.FinishTime;
      const finishDate = moment(this.item.FinishDate);

      if (timeExpired) {
        const hour = timeExpired.split(":")[0];
        const minute = timeExpired.split(":")[1];
        finishDate.set({ hour, minute });
      }

      const toDay = moment();
      return toDay > finishDate;
    },
    computedSections: {
      get() {
        return this.sections;
      },
      set(data) {
        this[SET_SECTIONS](data);
      },
    },
    childsCount() {
      return (
        (this.item.childs
          ? this.item.childs.filter((i) => !i.isDepended).length
          : 0) + (this.item.CountDependsTasks ? this.item.CountDependsTasks : 0)
      );
    },
    statusFieldShow() {
      return this.item.ParentProcessId;
    },
    ...mapState("tasks", [
      "tasks",
      "processes",
      "allGroups",
      "localStorageSettings",
      "tags",
      "settings",
    ]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    toggleListBubble(event) {
      if (event.target.classList.contains("task-plus-label")) {
        document.querySelectorAll(".task-plus-label").forEach((i) => {
          if (event.target !== i) {
            i.classList.remove("task-plus-label--close");
          }
        });
        event.target.classList.toggle("task-plus-label--close");
      }
    },
    getInnerRowInfo() {
      const self = this;
      let needReload = false;

      this.preloader = true;

      function getSection() {
        self.preloader = true;
        self[c.GET_PARENT_TASK_SECTIONS](self.item.Id)
          .then((res) => {
            needReload = res.needReload;
            if (res.needReload) {
              getSection();
            } else {
              self.sections = res;
              self.prepareTasksToSections();
            }
          })
          .finally(() => {
            if (!needReload) {
              self.preloader = false;
            }
          });
      }

      this[c.GET_CHILD_TASKS_DETAIL]({ task: this.item }).then(() => {
        getSection();
      });
    },
    toggleTableRowOpenState() {
      this.rowIsOpen = !this.rowIsOpen;
      if (this.rowIsOpen) {
        this.getInnerRowInfo();
      }

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.item.Id,
          Type: "tableTask",
          settings: {
            rowIsOpen: this.rowIsOpen,
          },
        },
        init: false,
      });

      // if (!this.rowIsOpen && this.item.childs && this.item.childs.length) {
      //   this.item.childs.forEach((i) => {
      //     const child = this.$el.querySelector(
      //       '.task-table-row--open[data-task-id="' + i.Id + '"]'
      //     );
      //     if (child && child.querySelector(".table__toggle")) {
      //       child.querySelector(".table__toggle").click();
      //     }
      //   });
      // }
    },
    openDetailTask(newPage) {
      const globalTask =
        this.item.GlobalTask === void 0 ? true : this.item.GlobalTask;
      let id = globalTask ? this.item.Id : this.item.TaskId;
      if (this.selectedTaskParentData) {
        this.selectedTaskParentData.subtaskIdForOpen = id;
        if (!newPage) {
          window.history.pushState({}, document.title, "/tasks/" + id);
        } else {
          window.open("/tasks/" + id, "_blank");
        }
      } else {
        if (!newPage) {
          this.$router.push("/tasks/" + id);
        } else {
          window.open("/tasks/" + id, "_blank");
        }
      }
    },
    deleteTag(Id) {
      console.log(this.item.tags);
      this.item.tags = _.reject(this.item.tags, (i) => i.Id === Id);
      this[c.REMOVE_TAG_FROM_TASK]({ Id });
    },
    addTag(tagId) {
      if (!this.item.tags) this.item.tags = [];
      const foundTag = _.find(this.tags, ["Id", tagId]);
      if (!_.find(this.item.tags, ["TagId", tagId])) {
        this.item.tags.push(foundTag);

        this[c.ADD_TAG_TO_TASK]({
          data: {
            TagId: tagId,
            TaskId: this.item.Id,
          },
        }).then((data) => {
          const tag = { ...data, TagName: foundTag.TagName };
          this.item.tags.splice(this.item.tags.length - 1, 1, tag);
          // this.item.tags.push(tag);
        });
      }

      document.querySelectorAll(".task-plus-label").forEach((i) => {
        i.classList.remove("task-plus-label--close");
      });
    },
    closeTagListBubble() {
      this.showTagsListBubble = false;
    },
    closeViewTab() {
      if (this.showViewTypeTab !== this.showViewTypeTab) {
        this.showViewTypeTab = false;
        this[SET_LOCAL_STORAGE_SETTINGS]({
          data: {
            Id: this.item.Id,
            Type: "tableTask",
            settings: {
              showViewTypeTab: false,
            },
          },
          init: false,
        });
      }
    },
    setProcessStatus(id) {
      this.item.StatusId = id;
      const payload = {
        id: this.item.Id,
        data: {
          StatusId: id,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    setShowViewTab(type) {
      this.viewTypeTab = type;

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.item.Id,
          Type: "tableTask",
          settings: {
            showViewTypeTab: this.viewTypeTab,
          },
        },
        init: false,
      });
    },
    setViewTypeTab(tab) {
      this.viewTypeTab = tab;

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.item.Id,
          Type: "tableTask",
          settings: {
            viewTypeTab: this.viewTypeTab,
          },
        },
        init: false,
      });
    },
    adjustLevelColor() {
      // let color = '#F9F9F9', amount = -((this.level - 1) * 10)
      // return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
      const percentage = 100 - this.level * 2;
      return `hsl(0 0% ${percentage}% / 1)`;
    },
    changeOrder(items) {
      this[CHANGE_ORDER]({
        items,
        apiMethod: "api/tasks/v2/tasks/updateOrder",
      });
    },
    changeName(e) {
      if (!this.item.VirtualId && e.target.value !== this.item.TaskName) {
        this.addTaskToQueuneOrRun(() => {
          this.item.TaskName = e.target.value;
          const payload = {
            id: this.item.Id,
            data: {
              TaskName: this.item.TaskName,
            },
          };
          this[c.EDIT_TASK](payload);
        });
      }
    },
    createTaskByEnter() {
      if (this.checkUserTaskRight("create_tasks_and_subtasks")) {
        this.loading = true;
        const SectionId =
          this.section && this.section.Name !== "virtualSection"
            ? this.section.Id
            : null;
        const virtualId = randomId();
        const payload = {
          ...JSON.parse(
            JSON.stringify(taskCreateFormTemplate.TemplateTaskSettings)
          ),
          TaskName: "Новая задача",
          GroupId: this.item.GroupId,
          SectionId: SectionId,
          VirtualId: virtualId,
          ParentId: this.item.ParentId,
          Position: this.section ? this.section.tasks.length + 1 : 1,
          // Position: +this.item.Position + 1,
        };
        this.localCreateTask(payload);
        this[REFRESH_SECTIONS]();

        if (this.inDetailTask) {
          this.inDetailTask.childs.push(payload);
        }

        this[CREATE_TASK]({
          form: payload,
          addCreatedTaskToChildArray: true,
        })
          .then((task) => {
            if (!this.inDetailTask) {
              this[REMOVE_VIRTUAL_TASK]({ virtualId: payload.VirtualId });
            } else {
              this[REMOVE_VIRTUAL_TASK]({
                virtualId: payload.VirtualId,
                array: this.inDetailTask.childs,
              });
              this.inDetailTask.childs.push(task);
            }
            this[REFRESH_SECTIONS]();
            setTimeout(() => {
              const parentClass = this.inDetailTask
                ? `[task-detail-wrapper="${this.inDetailTask.Id}"]`
                : "";
              const input = document.querySelector(
                `${parentClass} [data-id="${task.Id}"] input.table-c__name`
              );
              if (input) {
                input.focus();
              }
            });
          })
          .finally(() => {
            this.loading = false;
            this.tasksQueue.forEach((func) => {
              func();
            });
            this.tasksQueue = [];
          });
      }
    },
    addTaskToQueuneOrRun(func) {
      if (this.loading) {
        this.tasksQueue.push(func);
      } else {
        func();
      }
    },
    validateChangeName(e) {
      if (this.item.VirtualId) {
        e.preventDefault();
      }
    },
    getColWidth(colName) {
      if (!this.inDetailTask && this.settings.mainTableColsWidth[colName]) {
        let width = this.settings.mainTableColsWidth[colName].replace("px", "");
        if (colName === "name") {
          width -= 23;
          if (this.level) {
            width -= 33 * this.level;
          }
        }
        return width ? width + "px" : null;
      }
    },
    copyLink() {
      const input = document.createElement("input");
      const globalTask =
        this.item.GlobalTask === void 0 ? true : this.item.GlobalTask;
      let id = globalTask ? this.item.Id : this.item.TaskId;
      input.setAttribute("value", window.location.host + "/tasks/" + id);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    closeTask() {
      const payload = {
        id: this.item.Id,
        data: {
          Status: "2",
        },
      };
      this[c.EDIT_TASK](payload);
    },
    openTask() {
      const payload = {
        id: this.item.Id,
        data: {
          Status: "1",
        },
      };
      this[c.EDIT_TASK](payload);
    },
    deleteTask() {
      this[c.DELETE_TASK]({ id: this.item.Id }).then(() => {
        this[c.GET_USER_TASKS_LIST](true);
      });
    },
    sortTags(arr) {
      return _.orderBy(arr, ["TagName"], ["asc"]);
    },
    copyTask() {
      this[COPY_TASK]({
        Pk: [this.item.Id],
        CopyChildTasks: 1,
      }).then(() => {
        this.getTasksByFilterOrGroup();
      });
    },
    prepareTasksToSections() {
      const copySections = JSON.parse(JSON.stringify(this.sections));
      const depends = this.item.depends
        ? this.item.depends.map((i) => {
            i.isDepended = true;
            i.SectionId = i.DependSectionModel ? i.DependSectionModel.Id : null;
            return i;
          })
        : [];
      const allTasks = [...this.item.childs, ...depends];

      this.sections = _.map(copySections, (i) => {
        if (i.Name === "virtualSection") {
          i.tasks = _.filter(allTasks, ["SectionId", null]);
        } else {
          i.tasks = _.filter(allTasks, ["SectionId", i.Id]);
        }
        return i;
      });
    },
    ...mapActions("tasks", [
      c.REMOVE_TAG_FROM_TASK,
      c.ADD_TAG_TO_TASK,
      c.GET_CHILD_TASKS_DETAIL,
      c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY,
      c.EDIT_TASK,
      c.SET_SECTIONS,
      CHANGE_ORDER,
      GET_PARENT_TASK_SECTIONS,
      CREATE_TASK,
      c.DELETE_TASK,
      GET_USER_TASKS_LIST,
      COPY_TASK,
      GET_TASKS,
    ]),
    ...mapMutations("tasks", [
      SET_LOCAL_STORAGE_SETTINGS,
      REFRESH_SECTIONS,
      REMOVE_VIRTUAL_TASK,
    ]),
    ...mapMutations("tasks", { localCreateTask: CREATE_TASK }),
  },
  watch: {
    "item.TaskName"(val) {
      this.newName = val;
    },
    "item.childs.length"() {
      this.prepareTasksToSections();
    },
    "item.depends"() {
      this.prepareTasksToSections();
    },
  },
  components: {
    Person,
    FinishDate,
    TagsListSearch,
    // eslint-disable-next-line vue/no-unused-components
    TableRow,
    Kanban,
    StatusesListSearch,
    TableSection: () =>
      import("@/modules/tasks/components/Tables/MainTable/TableSection"),
    Spinner,
    ContextPopupMenu,
  },
};
</script>
