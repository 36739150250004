<template>
  <div
    class="task-create-form__row task-create-form__row--labels task-create-form__row--small"
  >
    <div class="task-create-form__row-left">
      <svg width="17" height="18" v-if="forSubscribers">
        <use xlink:href="/static/images/tasks/sprite.svg#bell-icon-2"></use>
      </svg>
      <svg width="18" height="14" v-else>
        <use xlink:href="/static/images/tasks/sprite.svg#eye-icon"></use>
      </svg>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">
            {{ forSubscribers ? "Подписчики" : "Наблюдатели" }}:
          </p>
          <div class="task-create-form__field-value">
            <p @click="open = true" v-if="!watchers.length">Выбрать</p>
            <div
              class="task-popup__nav-persons-list task-popup__nav-persons-list--old"
            >
              <div
                class="task-popup__nav-persons-list__item"
                v-for="userId in watchers"
                :key="userId"
                :title="getUser(userId).Fio | shortName"
              >
                <UserAvatar :user="getUser(userId)" />
                <div
                  class="task-table-person__delete"
                  @click.prevent="deleteWatcher(userId)"
                >
                  <svg focusable="false" viewBox="0 0 32 32">
                    <path
                      d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="task-create-form-subtasks__person">
                <div
                  class="task-create-form-subtasks__person--add"
                  @click="open = true"
                  v-if="watchers.length"
                >
                  +
                </div>

                <transition name="fade">
                  <PersonsListSearch
                    :arr="
                      watchers.map((id) => {
                        return { UserId: id };
                      })
                    "
                    v-if="portalOpen"
                    :className="'task-bubble--subscriber'"
                    :getItemIdField="'UserId'"
                    :outerUsers="localUsersArray"
                    @choose="addWatcher"
                    @deleteItem="deleteWatcher"
                    @close="open = false"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SwitcherForHideField
      v-if="!noSave"
      :form="form"
      :field="forSubscribers ? 'Subscribers' : 'Watchers'"
      @saveAfterEdit="
        (formData, callback, field) =>
          $emit('saveAfterEdit', formData, callback, field)
      "
    />
  </div>
</template>

<script>
import PersonsListSearch from "../../CommonComponents/PersonsListSearch";
import UserAvatar from "../../CommonComponents/UserAvatar";
import common from "../../../../../mixins/tasks/common";
import * as _ from "lodash";
import SwitcherForHideField from "./SwitcherForHideField";

export default {
  mixins: [common],
  props: ["watchers", "forSubscribers", "form", "localUsersArray", "noSave"],
  data() {
    return {
      open: false,
      portalOpen: false,
    };
  },
  methods: {
    addWatcher(id) {
      if (!_.find(this.watchers, id)) {
        this.watchers.push(id);
      }
      this.$emit(
        "saveAfterEdit",
        null,
        () => {},
        this.forSubscribers ? "Subscribers" : "Watchers"
      );
    },
    deleteWatcher(id) {
      this.watchers.splice(this.watchers.indexOf(id), 1);
      this.$emit(
        "saveAfterEdit",
        null,
        () => {},
        this.forSubscribers ? "Subscribers" : "Watchers"
      );
    },
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.portalOpen = true;
        });
      } else {
        this.portalOpen = false;
      }
    },
  },
  components: {
    PersonsListSearch,
    UserAvatar,
    SwitcherForHideField,
  },
};
</script>

<style scoped></style>
