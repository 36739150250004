import { modulesArray, optionsArray } from "../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  settings: (state) => state.settingsObject.json,
  images: (state) => state.settingsObject.images,
  offer: (state) => state.offerObject.json,
  offerImages: (state) => state.offerObject.images,
  totalDevicesForXlsExport: (state) => {
    const data = [];

    _.each(modulesArray, (module) => {
      _.each(optionsArray, (option) => {
        if (!(module === "Entry" && option === "barrier")) {
          if (state.offerObject.json.Modules[module][option] !== void 0) {
            const arr = _.orderBy(
              state.offerObject.json.Modules[module][option],
              ["is_device"],
              ["desc"]
            );
            _.each(arr, (i) => {
              if (["barrier", "rack"].includes(option)) {
                if (
                  state.offerObject.json.SystemType === "Tokensdbl" &&
                  option === "rack" &&
                  module === "Exit"
                ) {
                  return false;
                }
                if (
                  state.offerObject.json.passPages[
                    module.toLowerCase() + "_" + option
                  ]
                ) {
                  // console.log(module.toLowerCase() + '_' + option)
                  return false;
                }
              }
              if (i.count) {
                data.push({
                  name: i.name,
                  count: i.count,
                  one_count: i.convertCost,
                  sum: i.convertCost * i.count,
                });
              }
            });
          }
        }
      });
    });
    return data;
  },
  totalDevicesPrice(state, getters) {
    let amount = 0;
    _.each(modulesArray, (module) => {
      _.each(optionsArray, (option) => {
        if (!(module === "Entry" && option === "barrier")) {
          if (state.offerObject.json.Modules[module][option] !== void 0) {
            _.each(state.offerObject.json.Modules[module][option], (i) => {
              if (["barrier", "rack"].includes(option)) {
                if (
                  state.offerObject.json.SystemType === "Tokensdbl" &&
                  option === "rack" &&
                  module === "Exit"
                ) {
                  return false;
                }
                if (
                  state.offerObject.json.passPages[
                    module.toLowerCase() + "_" + option
                  ]
                ) {
                  // console.log(module.toLowerCase() + '_' + option)
                  return false;
                }
              }
              amount += +(i.convertCost * i.count);
            });
          }
        }
      });
    });
    if (
      getters.offer.totalDevicesPriceDiscont !== void 0 &&
      getters.offer.totalDevicesPriceDiscont
    ) {
      if (getters.offer.totalDevicesPriceType === "rub") {
        amount -= getters.offer.totalDevicesPriceDiscont;
      } else {
        amount -= (amount * getters.offer.totalDevicesPriceDiscont) / 100;
      }
    }
    state.offerObject.json.Total.Options.types = _.map(
      state.offerObject.json.Total.Options.types,
      (i) => {
        // if (i.name === 'Пуско-наладочные работы') {
        //   if (i.single_cost === void 0) {
        //     i.convertCost = amount * 7 / 100
        //   }
        // }
        // i.convertCost = Math.round(i.convertCost / 1000) * 1000
        return i;
      }
    );
    return amount;
  },
  totalDevicesPriceWithoutDiscount(state) {
    let amount = 0;
    _.each(modulesArray, (module) => {
      _.each(optionsArray, (option) => {
        if (!(module === "Entry" && option === "barrier")) {
          if (state.offerObject.json.Modules[module][option] !== void 0) {
            _.each(state.offerObject.json.Modules[module][option], (i) => {
              if (["barrier", "rack"].includes(option)) {
                if (
                  state.offerObject.json.SystemType === "Tokensdbl" &&
                  option === "rack" &&
                  module === "Exit"
                ) {
                  return false;
                }
                if (
                  state.offerObject.json.passPages[
                    module.toLowerCase() + "_" + option
                  ]
                ) {
                  // console.log(module.toLowerCase() + '_' + option)
                  return false;
                }
              }
              amount += +(i.convertCost * i.count);
            });
          }
        }
      });
    });
    return amount;
  },
  totalSumPrice(state, getters) {
    let amount = 0;
    if (state.offerObject.json.Total.Options) {
      _.each(state.offerObject.json.Total.Options.types, (i) => {
        if (i.disabled === void 0) {
          amount += +i.convertCost;
        } else {
          if (!i.disabled) {
            amount += +i.convertCost;
          }
        }
      });
      return amount + getters.totalDevicesPrice;
    }
  },
  getDiscountValue(state, getters) {
    let amount = 0;
    function findDiscount(item) {
      let price =
        item.single_cost !== void 0
          ? !item.single_cost
            ? item.initial_cost
            : item.single_cost
          : item.initial_cost;
      let value = 0;
      if (item.discont_number !== void 0 && item.discont_number !== "") {
        if (item.discont_type === "percent") {
          value = (+price * +item.discont_number) / 100;
          const count = item.count !== void 0 ? item.count : 1;
          value = value * count;
        } else {
          if (+item.discont_number <= +price) {
            value = item.discont_number;
          } else {
            value = price;
          }
        }
      }
      return +value;
    }
    _.each(modulesArray, (module) => {
      _.each(optionsArray, (option) => {
        if (!(module === "Entry" && option === "barrier")) {
          if (state.offerObject.json.Modules[module][option] !== void 0) {
            _.each(state.offerObject.json.Modules[module][option], (i) => {
              if (["barrier", "rack"].includes(option)) {
                if (
                  state.offerObject.json.SystemType === "Tokensdbl" &&
                  option === "rack" &&
                  module === "Exit"
                ) {
                  return false;
                }
                if (
                  state.offerObject.json.passPages[
                    module.toLowerCase() + "_" + option
                  ]
                ) {
                  return false;
                }
              }
              amount += findDiscount(i);
            });
          }
        }
      });
    });
    if (
      getters.offer.totalDevicesPriceDiscont !== void 0 &&
      getters.offer.totalDevicesPriceDiscont
    ) {
      if (getters.offer.totalDevicesPriceType === "rub") {
        amount += +getters.offer.totalDevicesPriceDiscont;
      } else {
        amount += +(
          (getters.totalDevicesPriceWithoutDiscount *
            getters.offer.totalDevicesPriceDiscont) /
          100
        );
      }
    }
    _.each(state.offerObject.json.Total.Options.types, (i) => {
      amount += findDiscount({ ...i, initialCost: i.convertCost });
    });
    return amount;
  },
  rateValue: (state) => state.rateValue,
  getCurrencyAbbreviation(state) {
    let value = "руб";
    if (
      state.offerObject.json &&
      state.offerObject.json.currency &&
      state.offerObject.json.currency === "USD"
    ) {
      value = "$";
    }
    return value;
  },
  config: (state) => state.config,
};
