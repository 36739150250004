var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-create-form__row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"task-create-form__row-right"},[_c('div',{staticClass:"task-create-form__label"},[_vm._v("Описание")]),_vm._v(" "),_c('vue-editor',{ref:"editor",staticClass:"task-create-form__text task-editor task-editor-toolbar-dynamic",attrs:{"id":"task-editor_task-detail","editor-options":{ bounds: '#task-editor_task-detail' },"editorToolbar":_vm.checkUserTaskRight('edit_task_description')
          ? _vm.customToolbarWithLink
          : [[]],"placeholder":_vm.checkUserTaskRight('edit_task_description')
          ? 'Введите сюда описание задачи...'
          : '',"disabled":!_vm.checkUserTaskRight('edit_task_description')},on:{"blur":function () {
          _vm.checkUserTaskRight('edit_task_description') && _vm.$emit('editTask');
          _vm.blur();
        },"focus":_vm.focus,"text-change":_vm.watchForSubscribe,"selection-change":_vm.watchEditorSelection},nativeOn:{"paste":function($event){return _vm.onPaste($event)}},model:{value:(_vm.loadedTask.entityModel.TaskDescription),callback:function ($$v) {_vm.$set(_vm.loadedTask.entityModel, "TaskDescription", $$v)},expression:"loadedTask.entityModel.TaskDescription"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-create-form__row-left"},[_c('i',{staticClass:"task-form-icon task-form-icon--2"})])}]

export { render, staticRenderFns }