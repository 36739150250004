<template>
  <div class="search__wrapp">
    <h2>Результаты поиска: {{ searchResults.length }}</h2>
    <div class="search" style="visibility: hidden"></div>
    <div v-for="(item, index) in searchResults" :key="item.Id">
      <div class="search__result active">
        <div class="search__number">{{ index + 1 }}.</div>
        <div class="search__text">
          <SearchResultItem
            :item="item"
            :articles="articles"
            :categories="categories"
          />
          <br />
          <span
            >{{ getCategoryNameById(item.CategoryId) }}
            <BreadCrumbsItem
              :isParent="true"
              :item="item.parent"
              :articles="articles"
          /></span>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import BreadCrumbsItem from "../components/BreadCrumbsItem";
import SearchResultItem from "./SearchResultItem";

export default {
  props: ["searchResults", "articles", "categories"],
  methods: {
    getCategoryNameById(id) {
      const found = _.find(this.categories, ["Id", id]);
      if (found) return found.Name;
    },
  },
  components: {
    BreadCrumbsItem,
    SearchResultItem,
  },
};
</script>

<style scoped></style>
