<template>
  <div
    :class="getClassName"
    v-if="user"
    :style="{
      backgroundColor: user.ProfileColor ? '#' + user.ProfileColor : '#e1e1e1',
    }"
    :title="user.Fio || user.FullName"
  >
    <img
      :src="API_ROOT + user.LinkToAvatar"
      alt="user avatar"
      v-if="user.LinkToAvatar"
    />
    <template v-else>{{ user.Fio || user.FullName | initials }}</template>
  </div>
</template>

<script>
// import * as _ from "lodash";
import { mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import { API_ROOT } from "../../../../config";
export default {
  mixins: [common],
  props: ["userId", "user", "className"],
  data() {
    return {
      API_ROOT,
    };
  },
  computed: {
    // user() {
    //   if (typeof this.userId === "object") return this.userId;
    //   const foundPerson = _.find(this.users, ["Id", this.userId]);
    //   if (foundPerson) {
    //     return foundPerson;
    //   } else {
    //     return {};
    //   }
    // },
    getClassName() {
      return this.className ? this.className : "task-table-person__circle";
    },
    ...mapState("tasks", ["users"]),
  },
};
</script>

<style scoped></style>
