import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export const Helpers = {
  computed: {
    ...mapState("common", ["rights", "rightsLoaded"]),
  },
  methods: {
    checkUserRight(rightId) {
      // if (rightId === '2A028788-59B1-40E7-81A2-2B820C6CCEBB') {
      //   console.log('rightsLoaded', this.rightsLoaded)
      // }
      if (this.rightsLoaded) {
        // if (rightId === '2A028788-59B1-40E7-81A2-2B820C6CCEBB') {
        //   console.log(111)
        // }
        for (let accessRight of this.rights) {
          // if (rightId === '2A028788-59B1-40E7-81A2-2B820C6CCEBB') {
          //   console.log(accessRight.RightId.toUpperCase(), rightId.toUpperCase())
          // }

          if (accessRight.RightId.toUpperCase() === rightId.toUpperCase()) {
            return true;
          }
        }
        // return true
        return false;
      }
    },
    declOfNum(number, words) {
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
      ];
    },
  },
  directives: {
    "click-outside-v-2": ClickOutside,
    disabled: {
      bind: (el, binding) => {
        if (!binding.value.allow) {
          const disabledFill = document.createElement("span");
          el.classList.add("disabled-btn__wrap");
          disabledFill.classList.add("disabled-btn");
          disabledFill.setAttribute("title", binding.value.title);
          el.appendChild(disabledFill);
          disabledFill.addEventListener("click", function (e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            return false;
          });
        }
      },
      update: (el, binding, vnode) => {
        if (vnode.context.$store.state.common.rightsLoaded) {
          if (!binding.value.allow) {
            const disabledFill = document.createElement("span");
            el.classList.add("disabled-btn__wrap");
            disabledFill.classList.add("disabled-btn");
            disabledFill.setAttribute("title", binding.value.title);
            el.appendChild(disabledFill);
            disabledFill.addEventListener("click", function (e) {
              e.stopImmediatePropagation();
              e.stopPropagation();
              e.preventDefault();
              return false;
            });
          } else {
            if (el.querySelector(".disabled-btn")) {
              el.classList.remove("disabled-btn__wrap");
              el.querySelector(".disabled-btn").remove();
            }
          }
        }
      },
    },
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            if (
              !event.target.closest(".pac-container") &&
              !event.target.closest(".mx-datepicker-popup") &&
              !event.target.closest(".fixed-bubble") &&
              !event.target.closest(".task-bubble") &&
              !event.target.closest(".simple-popup-list") &&
              event.target.tagName !== "BODY"
            ) {
              vnode.context[binding.expression](event);
            }
          }
        };
        setTimeout(() => {
          document.body.addEventListener("click", el.clickOutsideEvent);
        }, 200);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
    "click-outside-in-datepicker": {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
    "popup-inner-windows": {
      update(el, binding) {
        let { state, level, sumLevel, forceOffset, forceLevel } = binding.value;
        if (state) {
          el.classList.add("has-open-inner-popup");
          let value = forceOffset ? 1 : sumLevel - level;
          if (forceLevel) value = forceLevel;
          el.style.transform = `translateX(-${value}0%)`;
        } else {
          el.classList.remove("has-open-inner-popup");
          el.style.transform = "";
        }
      },
    },
    portal: {
      bind(el, binding) {
        const { parent, offsetY, offsetX } = binding.value;
        const wrapComponent = parent;
        const portedComponent = el;
        if (portedComponent) {
          const bodyRect = document.body.getBoundingClientRect(),
            elemRect = wrapComponent.getBoundingClientRect();

          let left = elemRect.left - bodyRect.left;
          let top = elemRect.top - bodyRect.top;

          if (window.innerWidth - left < portedComponent.clientWidth) {
            // left = elemRect.right - portedComponent.clientWidth;
          }
          if (window.innerHeight - top < portedComponent.clientHeight) {
            top = elemRect.top - portedComponent.clientHeight;
          } else if (
            window.innerHeight - top - portedComponent.clientHeight <
            150
          ) {
            top = elemRect.top - portedComponent.clientHeight;
          } else {
            const innerList = portedComponent.querySelector(
              ".task-bubble__list"
            );
            if (innerList) {
              const innerHeight =
                innerList.querySelectorAll(".task-bubble__item").length * 40 +
                10;
              const maxHeight = window.innerHeight - top - 100;
              const height = maxHeight > innerHeight ? innerHeight : maxHeight;
              innerList.style.minHeight = height + "px";
            }
          }

          if (offsetX) left += offsetX;
          if (offsetY) top += offsetY;

          portedComponent.style.top = top + "px";
          portedComponent.style.left = left + "px";
          portedComponent.style.position = "fixed";
        }
      },
    },
  },
};
