<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <svg width="15" height="18">
        <use xlink:href="/static/images/tasks/sprite.svg#location-icon"></use>
      </svg>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Адрес</p>
          <div class="task-create-form__field-value">
            <p @click="gmapWindow = true">
              <template v-if="form.TaskAddress">{{
                form.TaskAddress
              }}</template>
              <template v-else>Указать на карте</template>
            </p>
          </div>
        </div>
      </div>
      <!--      <div class="task-create-form__field">-->
      <!--        <div class="custom-select">-->
      <!--          <div class="task-popup__nav-person__bubble task-bubble" v-click-outside="close">-->
      <!--            <div class="task-bubble__search">-->
      <!--              <svg>-->
      <!--                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/images/icon.svg#icon_icon-search"></use>-->
      <!--              </svg>-->
      <!--              <input type="text" class="input"-->
      <!--                     @click="open = true"-->
      <!--                     :class="form.ObjectId ? 'input&#45;&#45;filled' : ''"-->
      <!--                     :placeholder="form.ObjectId ? getObjectById(form.ObjectId).Name : 'Введите название объекта'"-->
      <!--                     v-model="search"-->
      <!--              >-->
      <!--              <div class="task-bubble__close" @click="unselectObject()"><i></i></div>-->
      <!--            </div>-->
      <!--            <div class="task-bubble__list" v-if="open">-->
      <!--              <div class="task-bubble__item"-->
      <!--                   v-for="(item, index) in filteredObjects"-->
      <!--                   @click="setAddress(item)"-->
      <!--                   :key="index"-->
      <!--              >-->
      <!--                {{item.Name}}-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="task-create-form__btn" @click="gmapWindow = true">-->
      <!--          <i class="task-form-icon task-form-icon&#45;&#45;12"></i>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <input type="text" class="task-create-form__field-text" style="width: 100%;"-->
      <!--             v-model="form.TaskAddress" >-->
      <!--      @keyup="parentData.objectId = '0'"-->
    </div>
    <v-dialog
      class="offer-gmap-dialog"
      v-model="gmapWindow"
      width="600px"
      v-if="gmapWindow"
    >
      <google-map
        @close="gmapWindow = false"
        @setAddressByMap="setAddressByMap"
        @setGeoByMap="setGeoByMap"
        :setPosition="geo"
      />
    </v-dialog>
    <SwitcherForHideField
      v-if="!noSave"
      :form="form"
      :field="'Address'"
      @saveAfterEdit="
        (formData, callback, field) =>
          $emit('saveAfterEdit', formData, callback, field)
      "
    />
  </div>
</template>

<script>
import GoogleMap from "../../CommonComponents/GoogleMap";
import common from "../../../../../mixins/tasks/common";
import { Helpers } from "../../../../../mixins/Helpers";
import SwitcherForHideField from "./SwitcherForHideField";
import * as _ from "lodash";

export default {
  mixins: [common, Helpers],
  props: ["form", "noSave"],
  data() {
    return {
      gmapWindow: false,
      open: false,
      search: "",
    };
  },
  computed: {
    filteredObjects() {
      return _.orderBy(
        _.filter(this.objects, (i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i.Name);
        }),
        ["Name"],
        ["asc"]
      );
    },
    geo() {
      if (this.form.TaskLatitude && this.form.TaskLongitude) {
        return {
          lat: +this.form.TaskLatitude,
          lng: +this.form.TaskLongitude,
        };
      }
      return null;
    },
  },
  methods: {
    setAddressByMap(val) {
      if (val) {
        // this.parentData.objectId = null
        this.form.ObjectId = null;
        this.form.TaskAddress = val;
        setTimeout(() =>
          this.$emit("saveAfterEdit", null, () => {}, "TaskAddress")
        );
      }
    },
    setAddress(item) {
      // this.parentData.objectId = item.Id
      this.form.ObjectId = item.Id;
      this.close();
      // this.form.TaskAddress = this.getObjectAddressById(this.parentData.objectId)
      this.form.TaskLatitude = item.Latitude;
      this.form.TaskLongitude = item.Longitude;
    },
    close() {
      this.search = "";
      this.open = false;
    },
    unselectObject() {
      this.search = "";
      this.open = false;
      // this.parentData.objectId = null
      this.form.ObjectId = null;
      this.form.TaskAddress = "";
    },
    setGeoByMap(position) {
      this.form.TaskLatitude = +position.lat.toFixed(8);
      this.form.TaskLongitude = +position.lng.toFixed(8);
    },
  },
  components: {
    GoogleMap,
    SwitcherForHideField,
  },
};
</script>

<style scoped></style>
