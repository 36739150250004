var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"print print--11"},[_c('div',{staticClass:"print__cont pb-0"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
        backgroundImage: ("url(" + (_vm.getimgStaticSrc('sales/print-badge.svg')) + ")"),
      })},[_vm._v("\n      Функциональные "),_c('br'),_vm._v("задачи системы\n    ")]),_vm._v(" "),(_vm.offerObject.json.SystemImg === void 0)?_c('img',{staticClass:"print__system-tasks-img",attrs:{"src":_vm.getimgStaticSrc('sales/system-tasks.jpg')}}):(
        _vm.imageBlobCheck(
          'system',
          _vm.offerObject.json.SystemType.toLowerCase() + 'Img',
          1
        )
      )?_c('img',{staticClass:"print__system-tasks-img",attrs:{"src":_vm.settingsObject.json[_vm.offerObject.json.SystemType.toLowerCase() + 'Img']
          .blob}}):_c('img',{staticClass:"print__system-tasks-img",attrs:{"src":_vm.getImageSrc(_vm.offerObject.json.SystemImg.img, _vm.offerObject.json.System)}})]),_vm._v(" "),_c('div',{staticClass:"flex-wrap"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"print__s-benefits"},_vm._l((_vm.tasks),function(item,index){return _c('div',{key:index,staticClass:"print__s-benefits-item"},[_vm._v("\n        "+_vm._s(item)+"\n      ")])}))]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v(_vm._s(this.pageNums.Total + 1))])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"print__s-list"},[_c('div',{staticClass:"print__s-list-item"},[_c('div',{staticClass:"print__s-list-inner"},[_c('span',{staticClass:"print__s-list-num"},[_vm._v("1")]),_c('span',{staticClass:"print__s-list-text"},[_vm._v("получение максимальной "),_c('br'),_vm._v("прибыли с парковки")])])]),_vm._v(" "),_c('div',{staticClass:"print__s-list-item"},[_c('div',{staticClass:"print__s-list-inner"},[_c('span',{staticClass:"print__s-list-num"},[_vm._v("2")]),_c('span',{staticClass:"print__s-list-text"},[_vm._v("контроль доступа на "),_c('br'),_vm._v("территорию паркинга")])])]),_vm._v(" "),_c('div',{staticClass:"print__s-list-item"},[_c('div',{staticClass:"print__s-list-inner"},[_c('span',{staticClass:"print__s-list-num"},[_vm._v("3")]),_c('span',{staticClass:"print__s-list-text"},[_vm._v("комфортное использование "),_c('br'),_vm._v("парковочного простарнства")])])])])}]

export { render, staticRenderFns }