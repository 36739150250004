import * as c from "./constants";
import Api from "../../api/reservation";
import { showAlert } from "../../utils";
export default {
  [c.GET_REQUESTS_OBJECTS](state, params = null) {
    Api.getRequestsObjects(params)
      .then((res) => {
        if (res.data.status === "success") {
          if (params && params.page > 0) {
            state.commit(c.ADD_REQUESTS_OBJECTS_TO_LIST, res.data);
          } else {
            state.commit(c.GET_REQUESTS_OBJECTS, res.data);
          }
        } else {
          showAlert("error", "Ошибка", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.EDIT_REQUEST_OBJECT](state, { id, data, onlyStatus }) {
    return Api.editRequestObject(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          if (onlyStatus) {
            state.commit(c.DELETE_REQUEST_OBJECT, id);
          }
        } else {
          showAlert("error", "", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.response.data);
      });
  },
  [c.GET_RESERVED_OBJECTS](state, params = null) {
    Api.getReservedObjects(params)
      .then((res) => {
        if (res.data.status === "success") {
          if (params && params.page > 0) {
            state.commit(c.ADD_RESERVED_OBJECTS_TO_LIST, res.data.items);
          } else {
            state.commit(c.GET_RESERVED_OBJECTS, res.data.items);
          }
        } else {
          showAlert("error", "Ошибка", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.EDIT_RESERVED_OBJECT](state, { id, data }) {
    return new Promise((resolve, reject) => {
      Api.editReservedObject(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.EDIT_RESERVED_OBJECT, res.data.model);
            resolve(res.data.model);
            showAlert("success", "", "Успешно");
          } else {
            showAlert("error", "Ошибка", res.data.message);
          }
        })
        .catch((err) => {
          reject();
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.DELETE_RESERVED_OBJECT](state, id) {
    return Api.deleteReserveObject(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DELETE_RESERVED_OBJECT, id);
          showAlert("success", "", "Объект удален");
        } else {
          showAlert("error", "Ошибка", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.response.data);
      });
  },
  [c.CREATE_RESERVED_OBJECT](state, data) {
    return Api.createReserveObject(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_RESERVED_OBJECT, res.data.model);
          showAlert("success", "", "Объект успешно создан");
        } else {
          showAlert("error", "Ошибка", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.response.data);
      });
  },
};
