export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const ADD_ROLE_FOR_USER = "ADD_ROLE_FOR_USER";
export const DELETE_ROLE_FOR_USER = "DELETE_ROLE_FOR_USER";
export const GET_RIGHTS = "GET_RIGHTS";
export const CREATE_RIGHT = "CREATE_RIGHT";
export const EDIT_RIGHT = "EDIT_RIGHT";
export const DELETE_RIGHT = "DELETE_RIGHT";
export const ADD_RIGHT_FOR_ROLE = "ADD_RIGHT_FOR_ROLE";
export const DELETE_RIGHT_FOR_ROLE = "DELETE_RIGHT_FOR_ROLE";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATE_TASKS = "GET_TEMPLATE_TASKS";
export const GET_TEMPLATE_TASK_DETAIL = "GET_TEMPLATE_TASK_DETAIL";
export const EDIT_TEMPLATE_TASK = "EDIT_TEMPLATE_TASK";
export const ADD_FILES_TO_TEMPLATE_TASK = "ADD_FILES_TO_TEMPLATE_TASK";
export const DELETE_FILES_FROM_TEMPLATE_TASK =
  "DELETE_FILES_FROM_TEMPLATE_TASK";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_TASK = "CREATE_TEMPLATE_TASK";
export const DELETE_TEMPLATE_TASK = "DELETE_TEMPLATE_TASK";
export const GET_TEMPLATE_TASK_CHILD = "GET_TEMPLATE_TASK_CHILD";
export const DELETE_MULTIPLE_TEMPLATE_TASKS = "DELETE_MULTIPLE_TEMPLATE_TASKS";
export const EDIT_MULTIPLE_TEMPLATE_TASKS = "EDIT_MULTIPLE_TEMPLATE_TASKS";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const CREATE_TASKS_FROM_TEMPLATE = "CREATE_TASKS_FROM_TEMPLATE";
export const CREATE_MANY_ROOT_TASKS = "CREATE_MANY_ROOT_TASKS";
export const GET_GROUPS = "GET_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const ADD_USER_TO_GROUP = "ADD_USER_TO_GROUP";
export const DELETE_USER_FROM_GROUP = "DELETE_USER_FROM_GROUP";
export const ADD_TEMPLATE_TO_GROUP = "ADD_TEMPLATE_TO_GROUP";
export const DELETE_TEMPLATE_FROM_GROUP = "DELETE_TEMPLATE_FROM_GROUP";
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const GET_GROUP_USERS = "GET_GROUP_USERS";
export const GET_OBJECTS = "GET_OBJECTS";
export const GET_TASKS = "GET_TASKS";
export const SET_TASKS_TO_NULL = "SET_TASKS_TO_NULL";
export const SHOW_TASK = "SHOW_TASK";
export const CREATE_TASK = "CREATE_TASK";
export const REMOVE_VIRTUAL_TASK = "REMOVE_VIRTUAL_TASK";
export const CREATE_TASK_NO_MUTATION = "CREATE_TASK_NO_MUTATION";
export const EDIT_TASK = "EDIT_TASK";
export const SILENT_EDIT_TASK = "SILENT_EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const CHANGE_TASK_GROUP = "CHANGE_TASK_GROUP";
export const UNSELECT_TASK = "UNSELECT_TASK";
export const SET_TASK = "SET_TASK";
export const SET_TASK_CHILDS = "SET_TASK_CHILDS";
export const CREATE_CHECKLIST = "CREATE_CHECKLIST";
export const CREATE_CHECKLIST_NO_MUTATION = "CREATE_CHECKLIST_NO_MUTATION";
export const EDIT_CHECKLIST = "EDIT_CHECKLIST";
export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const CREATE_CHECKLIST_ITEM = "CREATE_CHECKLIST_ITEM";
export const CREATE_CHECKLIST_ITEM_NO_MUTATION =
  "CREATE_CHECKLIST_ITEM_NO_MUTATION";
export const EDIT_CHECKLIST_ITEM = "EDIT_CHECKLIST_ITEM";
export const CREATE_CHECKLIST_ITEM_LOCAL = "CREATE_CHECKLIST_ITEM_LOCAL";
export const DELETE_CHECKLIST_ITEM = "DELETE_CHECKLIST_ITEM";
export const ORDER_CHECKLIST_ITEMS = "ORDER_CHECKLIST_ITEMS";
export const ADD_FILES_TO_TASK = "ADD_FILES_TO_TASK";
export const ADD_DOC_FILES_TO_TASK = "ADD_DOC_FILES_TO_TASK";
export const ADD_DOC_FILES_TO_TASK_NO_MUTATION =
  "ADD_DOC_FILES_TO_TASK_NO_MUTATION";
export const ADD_FILES_TO_TASK_NO_MUTATION = "ADD_FILES_TO_TASK_NO_MUTATION";
export const ADD_LOCAL_FILES_TO_TASK = "ADD_LOCAL_FILES_TO_TASK";
export const DELETE_FILES_FROM_TASK = "DELETE_FILES_FROM_TASK";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_FILE_TO_FAVORITE = "ADD_FILE_TO_FAVORITE";
export const REMOVE_FILE_FROM_FAVORITE = "REMOVE_FILE_FROM_FAVORITE";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const CREATE_CONTACT_NO_MUTATION = "CREATE_CONTACT_NO_MUTATION";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const EDIT_CONTACT_QUIET = "EDIT_CONTACT_QUIET";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER";
export const ADD_SUBSCRIBER_NO_MUTATION = "ADD_SUBSCRIBER_NO_MUTATION";
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";
export const RESET_SUBSCRIBERS_BUBBLE = "RESET_SUBSCRIBERS_BUBBLE";
export const SET_PROGRESS_BAR_BY_FILES_UPLOAD =
  "SET_PROGRESS_BAR_BY_FILES_UPLOAD";
export const GET_TAGS = "GET_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const EDIT_TAG = "EDIT_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const GET_TAG_GROUPS = "GET_TAG_GROUPS";
export const CREATE_TAGS_GROUP = "CREATE_TAGS_GROUP";
export const EDIT_TAGS_GROUP = "EDIT_TAGS_GROUP";
export const DELETE_TAGS_GROUP = "DELETE_TAGS_GROUP";
export const ADD_TAG_TO_TASK = "ADD_TAG_TO_TASK";
export const REMOVE_TAG_FROM_TASK = "REMOVE_TAG_FROM_TASK";
export const ADD_TAG_TO_GROUP = "ADD_TAG_TO_GROUP";
export const REMOVE_TAG_FROM_GROUP = "REMOVE_TAG_FROM_GROUP";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_GROUPED_NOTIFICATIONS = "GET_GROUPED_NOTIFICATIONS";
export const GET_NOTIFICATIONS_FROM_ONE_TASK =
  "GET_NOTIFICATIONS_FROM_ONE_TASK";
export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const GET_SPECIFIC_NOTIFICATIONS = "GET_SPECIFIC_NOTIFICATIONS";
export const APPROVE_CHECKLIST_ITEM = "APPROVE_CHECKLIST_ITEM";
export const GET_CHILD_TASKS_DETAIL = "GET_CHILD_TASKS_DETAIL";
export const GET_USERS_SETTINGS = "GET_USERS_SETTINGS";
export const CREATE_CHECKLIST_ITEM_NOTIFICATION =
  "CREATE_CHECKLIST_ITEM_NOTIFICATION";
export const UPDATE_CHECKLIST_ITEM_NOTIFICATION =
  "UPDATE_CHECKLIST_ITEM_NOTIFICATION";
export const DELETE_CHECKLIST_ITEM_NOTIFICATION =
  "DELETE_CHECKLIST_ITEM_NOTIFICATION";
export const ADD_WATCHER = "ADD_WATCHER";
export const DELETE_WATCHER = "DELETE_WATCHER";
export const ADD_WATCHER_NO_MUTATION = "ADD_WATCHER_NO_MUTATION";
export const GET_STANDART_FILTERS = "GET_STANDART_FILTERS";
export const GET_TASKS_BY_STANDART_FILTER = "GET_TASKS_BY_STANDART_FILTER";
export const ADD_TAG_TO_TASK_NO_MUTATION = "ADD_TAG_TO_TASK_NO_MUTATION";
export const CREATE_USER_FILTER = "CREATE_USER_FILTER";
export const EDIT_USER_FILTER = "EDIT_USER_FILTER";
export const DELETE_USER_FILTER = "DELETE_USER_FILTER";
export const GET_USER_FILTERS = "GET_USER_FILTERS";
export const GET_TASKS_BY_USER_FILTER = "GET_TASKS_BY_USER_FILTER";
export const GET_COUNT_NOTIFICATIONS = "GET_COUNT_NOTIFICATIONS";
export const GET_COUNT_NOTIFICATIONS_BY_INTERVAL =
  "GET_COUNT_NOTIFICATIONS_BY_INTERVAL";
export const MARK_ALL_NOTIFICATIONS_READ = "MARK_ALL_NOTIFICATIONS_READ";
export const GET_TASK_BY_FLASH_FILTER = "GET_TASK_BY_FLASH_FILTER";
export const APPROVE_TASK = "APPROVE_TASK";
export const ADD_TASKS_TO_LIST = "ADD_TASKS_TO_LIST";
export const CREATE_CHECKLIST_LOCAL = "CREATE_CHECKLIST_LOCAL";
export const GET_PROCESSES = "GET_PROCESSES";
export const SET_GROUP_PROCESSES = "SET_GROUP_PROCESSES";
export const CREATE_PROCESS = "CREATE_PROCESS";
export const UPDATE_PROCESS = "UPDATE_PROCESS";
export const DELETE_PROCESS = "DELETE_PROCESS";
export const ATTACH_PROCESS_TO_GROUP = "ATTACH_PROCESS_TO_GROUP";
export const DETACH_PROCESS_FROM_GROUP = "DETACH_PROCESS_FROM_GROUP";
export const CREATE_STATUS = "CREATE_STATUS";
export const DELETE_STATUS = "DELETE_STATUS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const CHANGE_STATUSES_ORDER = "CHANGE_STATUSES_ORDER";
export const GET_USER_FAVORITES = "GET_USER_FAVORITES";
export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const DELETE_FROM_FAVORITES = "DELETE_FROM_FAVORITES";
export const CHANGE_FAVORITES_ORDER = "CHANGE_FAVORITES_ORDER";
export const CHANGE_ORDER = "CHANGE_ORDER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const SET_TASK_PROCESS_STATUS = "SET_TASK_PROCESS_STATUS";
export const SAVE_USER_AVATAR = "SAVE_USER_AVATAR";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const TRANSFER_TASK_TO_OTHER_GROUP = "TRANSFER_TASK_TO_OTHER_GROUP";
export const TRANSFER_TASK_TO_OTHER_TASK = "TRANSFER_TASK_TO_OTHER_TASK";
export const RESET_TASK_NOTIFICATION = "RESET_TASK_NOTIFICATION";
export const COPY_TASK = "COPY_TASK";
export const GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY =
  "GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY";
export const SET_TASKS_NOTIFICATIONS_IDS_ARRAY =
  "SET_TASKS_NOTIFICATIONS_IDS_ARRAY";
export const GET_USER_TASKS_MODULE_RIGHTS = "GET_USER_TASKS_MODULE_RIGHTS";
export const GET_CUSTOM_FILTER_PARAMS = "GET_CUSTOM_FILTER_PARAMS";
export const CREATE_AUTOMATION_TEMPLATE = "CREATE_AUTOMATION_TEMPLATE";
export const DELETE_AUTOMATION_TEMPLATE = "DELETE_AUTOMATION_TEMPLATE";
export const UPDATE_AUTOMATION_TEMPLATE = "UPDATE_AUTOMATION_TEMPLATE";
export const GET_AUTOMATION_TEMPLATES = "GET_AUTOMATION_TEMPLATES";
export const GET_AUTOMATION_OBJECTS_TYPES = "GET_AUTOMATION_OBJECTS_TYPES";
export const GET_AUTOMATION_ACTIONS = "GET_AUTOMATION_ACTIONS";
export const GET_AUTOMATION_ACTIONS_OPTIONS = "GET_AUTOMATION_ACTIONS_OPTIONS";
export const GET_AUTOMATION_ACTIONS_OBJECTS_TYPES =
  "GET_AUTOMATION_ACTIONS_OBJECTS_TYPES";
export const GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS =
  "GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS";
export const GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_ACTIONS =
  "GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_ACTIONS";
export const GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES =
  "GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES";
export const GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS =
  "GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS";
export const GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES =
  "GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES";
export const GET_AUTOMATION_ACTIONS_OBJECTS = "GET_AUTOMATION_ACTIONS_OBJECTS";
export const SET_AUTOMATION_SETTINGS_STORE = "SET_AUTOMATION_SETTINGS_STORE";
export const ATTACH_AUTOMATION_TO_TASK = "ATTACH_AUTOMATION_TO_TASK";
export const GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS =
  "GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS";
export const GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS =
  "GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS";
export const LOAD_TASK_AUTOMATIONS = "LOAD_TASK_AUTOMATIONS";
export const UPDATE_TASK_AUTOMATION = "UPDATE_TASK_AUTOMATION";
export const SET_AUTOMATION_LOADING = "SET_AUTOMATION_LOADING";
export const DELETE_TASK_AUTOMATION = "DELETE_TASK_AUTOMATION";
export const SET_LOCAL_STORAGE_SETTINGS = "SET_LOCAL_STORAGE_SETTINGS";
export const SET_COMMON_LOCAL_STORAGE_SETTINGS =
  "SET_COMMON_LOCAL_STORAGE_SETTINGS";
export const DROP_CHILD_TASKS_STATUSES = "DROP_CHILD_TASKS_STATUSES";
export const CREATE_SECTION = "CREATE_SECTION";
export const CREATE_SUBTASK_SECTION = "CREATE_SUBTASK_SECTION";
export const GET_SECTIONS = "GET_SECTIONS";
export const GET_SECTIONS_BY_ID = "GET_SECTIONS_BY_ID";
export const EDIT_SECTION = "EDIT_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const ADD_TASKS_TO_SECTION = "ADD_TASKS_TO_SECTION";
export const REFRESH_SECTIONS = "REFRESH_SECTIONS";
export const SET_SECTIONS = "SET_SECTIONS";
export const GET_PARENT_TASK_SECTIONS = "GET_PARENT_TASK_SECTIONS";
export const SET_SECTIONS_TO_NULL = "SET_SECTIONS_TO_NULL";
export const GET_GLOBAL_GROUPS = "GET_GLOBAL_GROUPS";
export const CREATE_GLOBAL_GROUP = "CREATE_GLOBAL_GROUP";
export const UPDATE_GLOBAL_GROUP = "UPDATE_GLOBAL_GROUP";
export const DELETE_GLOBAL_GROUP = "DELETE_GLOBAL_GROUP";
export const GET_GLOBAL_GROUP_ELEMENTS = "GET_GLOBAL_GROUP_ELEMENTS";
export const ADD_ELEMENT_TO_GLOBAL_GROUP = "ADD_ELEMENT_TO_GLOBAL_GROUP";
export const DELETE_ELEMENT_FROM_GLOBAL_GROUP =
  "DELETE_ELEMENT_FROM_GLOBAL_GROUP";
export const CHANGE_GLOBAL_GROUP_ORDER = "CHANGE_GLOBAL_GROUP_ORDER";
export const CHANGE_GLOBAL_GROUP_FILTERS_ORDER =
  "CHANGE_GLOBAL_GROUP_FILTERS_ORDER";
export const GET_USER_TASKS_LIST = "GET_USER_TASKS_LIST";
export const GET_COMMENTS_FOR_PARENT_COMMENT =
  "GET_COMMENTS_FOR_PARENT_COMMENT";
export const CHANGE_TASKS_SECTIONS = "CHANGE_TASKS_SECTIONS";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const PIN_COMMENT = "PIN_COMMENT";
export const UNPIN_COMMENT = "UNPIN_COMMENT";
export const MARK_COMMENT_AS_IMPORTANT = "MARK_COMMENT_AS_IMPORTANT";
export const UNMARK_COMMENT_AS_IMPORTANT = "UNMARK_COMMENT_AS_IMPORTANT";
export const SET_COMMENT_VISIBLE = "SET_COMMENT_VISIBLE";
export const SET_COMMENT_UNVISIBLE = "SET_COMMENT_UNVISIBLE";
export const GET_COMMENTS_FOR_TASK = "GET_COMMENTS_FOR_TASK";
export const COPY_SHARED_FILTER = "COPY_SHARED_FILTER";
export const SET_GROUPED_TASKS_BY_FILTER = "SET_GROUPED_TASKS_BY_FILTER";
export const LOAD_MORE_STANDART_TASKS_IN_GROUP =
  "LOAD_MORE_STANDART_TASKS_IN_GROUP";
export const LOAD_MORE_CUSTOM_TASKS_IN_GROUP =
  "LOAD_MORE_CUSTOM_TASKS_IN_GROUP";
export const LOAD_MORE_FILTER_TASKS_IN_GROUP =
  "LOAD_MORE_FILTER_TASKS_IN_GROUP";
export const DISABLE_FILTER_GROUP_TASKS_BY_GROUP =
  "DISABLE_FILTER_GROUP_TASKS_BY_GROUP";
export const SET_TABLE_FILTER = "SET_TABLE_FILTER";
export const SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK =
  "SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK";
export const SET_TABLE_FILTER_NAME = "SET_TABLE_FILTER_NAME";
export const SET_TABLE_FILTER_CACHED = "SET_TABLE_FILTER_CACHED";
export const SET_TABLE_FILTER_FLASH_DATA = "SET_TABLE_FILTER_FLASH_DATA";
export const SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY =
  "SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY";
export const SET_COMMON_MODULE_PAGE = "SET_COMMON_MODULE_PAGE";
export const SET_COMMON_MODULE_SORTS = "SET_COMMON_MODULE_SORTS";
export const SET_COMMON_MODULE_POPUP = "SET_COMMON_MODULE_POPUP";
export const SET_COMMON_MODULE_SUBTASK = "SET_COMMON_MODULE_SUBTASK";
export const SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE =
  "SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE";
export const SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT =
  "SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT";
export const SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT =
  "SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT";
export const SET_COMMON_MODULE_SELECTED_ITEMS =
  "SET_COMMON_MODULE_SELECTED_ITEMS";
export const SET_COMMON_MODULE_VIEW_TYPE = "SET_COMMON_MODULE_VIEW_TYPE";
export const SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS =
  "SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS";
export const SET_CURRENT_GROUP = "SET_CURRENT_GROUP";
export const GET_FILES_FOR_TASK = "GET_FILES_FOR_TASK";
export const GET_CURRENT_USER_SETTINGS = "GET_CURRENT_USER_SETTINGS";
export const UPDATE_CURRENT_GROUP = "UPDATE_CURRENT_GROUP";
export const LOAD_FULL_INFO_OF_GROUP = "LOAD_FULL_INFO_OF_GROUP";
export const TASKS_LOADING_PRELOADER_ENABLE = "TASKS_LOADING_PRELOADER_ENABLE";
export const TASKS_LOADING_PRELOADER_DISABLE =
  "TASKS_LOADING_PRELOADER_DISABLE";
export const SET_KEYDOWN_FUNCTION = "SET_KEYDOWN_FUNCTION";
export const SET_SEARCH_TASKS_INPUT_VALUE = "SET_SEARCH_TASKS_INPUT_VALUE";
export const GET_DEPENDENCIES_TASKS = "GET_DEPENDENCIES_TASKS";
export const DELETE_DEPENDENCIES_TASK = "DELETE_DEPENDENCIES_TASK";
export const EDIT_TASK_DEPENDENCY = "EDIT_TASK_DEPENDENCY";
export const APPROVE_MANY_TASKS = "APPROVE_MANY_TASKS";
export const GET_GROUP_BY_ID = "GET_GROUP_BY_ID";
export const UPLOAD_TMP_FILES = "UPLOAD_TMP_FILES";
export const GET_NOTIFICATIONS_FOR_TASK = "GET_NOTIFICATIONS_FOR_TASK";
