// import * as c from "../store/tasks/constants";

async function delay(item, action) {
  await action(item);
}

async function loop(items, action) {
  for (const item of items) {
    await delay(item, action);
  }
}
export async function queueFetch(items, action) {
  await loop(items, action);
}

// example
// queueFetch(this.parentData.selectedItems, async (id) => {
//   const payload = {
//     id,
//     data: {
//       Status: '2'
//     }
//   }
//   await this[c.EDIT_TASK](payload)
//   if (id === this.parentData.selectedItems[this.parentData.selectedItems.length - 1]) {
//     this.unselectTasks()
//   }
// })
