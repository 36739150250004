<template>
  <div>
    <div class="task-popup-header">
      <div class="actions-header__col">
        <div
          class="attach-tasks__selected-group"
          @click="$emit('unselectGroup')"
        >
          <svg width="16" height="15" :style="{ fill: group.Color }">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#folder-icon"
            ></use>
          </svg>
          <div class="attach-tasks__selected-group-name">
            {{ group.Name }}
          </div>
        </div>
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <div class="search-bar search-bar_white actions-header__search-bar">
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            class="search-bar__input"
            type="search"
            v-model="search"
            @keyup="getTasks"
            placeholder="Введите название задачи"
          />
        </div>
      </div>
    </div>
    <div class="popup__body">
      <div class="group-list">
        <TableSection
          v-for="(item, index) in computedSections"
          :key="'section' + item.Id"
          :section="item"
          :sections="computedSections"
          :parentData="parentData"
          :sectionTasks="item.tasks"
          :haveNextSection="index + 1 < computedSections.length"
          :parentTask="null"
          :listState="true"
          :level="0"
          @selectItem="selectItem"
          @createSubTask="() => {}"
          @shiftSelection="() => {}"
          @ctrlSelection="() => {}"
        />
        <div class="row-spinner _full-height" v-if="preloader">
          <Spinner
            :noFadeIn="true"
            name="line-scale-pulse-out"
            color="#c9eeff"
            width="50"
            height="35"
          />
        </div>
      </div>
      <div
        class="task-create-form__button"
        v-if="parentData.selectedItems.length"
        @click="
          $emit('onSubmitHandler', parentData.selectedItems);
          parentData.selectedItems = [];
        "
      >
        Прикрепить {{ parentData.selectedItems.length }}
        {{
          declOfNum(parentData.selectedItems.length, [
            "задачу",
            "задачи",
            "задач",
          ])
        }}
      </div>
    </div>
  </div>
</template>

<script>
import TableSection from "@/modules/tasks/components/Tables/MainTable/TableSection";
import Api from "@/api/tasks";
import * as _ from "lodash";
import { Helpers } from "@/mixins/Helpers";

export default {
  name: "AttachTasks",
  props: ["group"],
  mixins: [Helpers],
  data() {
    return {
      search: "",
      sections: [],
      tasks: [],
      preloader: false,
      parentData: {
        selectedItems: [],
      },
    };
  },
  created() {
    Api.getSections(this.group.Id).then((res) => {
      this.sections = res.data.items;
    });
    this.getTasks();
  },
  computed: {
    computedSections() {
      let computedSections = [];
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];
        const tasks =
          section.Name === "virtualSection"
            ? this.tasks.filter((item) => item.SectionId === null)
            : this.tasks.filter((item) => item.SectionId === section.Id);
        computedSections[i] = {
          ...section,
          tasks: tasks,
          isVirtualSection: section.Name === "virtualSection",
        };
      }
      return computedSections;
    },
  },
  methods: {
    getTasks() {
      this.parentData.selectedItems = [];
      this.preloader = true;
      this.tasks = [];
      let data = {
        "params[GroupId]": this.group.Id,
      };
      if (this.search.length) {
        data = {
          ...data,
          "params[TaskName]": this.search,
        };
      }
      Api.getTasks(data)
        .then((res) => {
          this.tasks = res.data;
        })
        .finally(() => (this.preloader = false));
    },
    selectItem(el, id) {
      if (!this.parentData.selectedItems.includes(id)) {
        this.parentData.selectedItems.push(id);
        el.target
          .closest(".table__row")
          .querySelector(".checkbox__input").checked = true;
      } else {
        this.parentData.selectedItems = _.filter(
          this.parentData.selectedItems,
          (i) => i !== id
        );
        el.target
          .closest(".table__row")
          .querySelector(".checkbox__input").checked = false;
      }
    },
  },
  components: {
    TableSection,
  },
};
</script>

<style scoped></style>
