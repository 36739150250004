<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            ref="translated-field-Name"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            ref="translated-field-Uri"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Категория:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.CategoryId">
              <option
                v-for="(item, index) in selectListOptions"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение размеров:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="changeAction($event)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
              <span class="remove-icon" @click.prevent="files = []"></span>
            </div>
          </template>
          <span
            class="commercial-site__images"
            v-else-if="fields.ProductSizeImage"
          >
            <img :src="getImgSrc(fields.ProductSizeImage)" />
            <span class="remove-icon" @click.prevent="clickAction"></span>
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Основное изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files, 'additionalImages')"
          />

          <template v-if="additionalImages.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in additionalImages"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.ImageLink">
            <img :src="getImgSrc(fields.ImageLink)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение для других страниц:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files, 'additionalImages2')"
          />

          <template v-if="additionalImages2.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in additionalImages2"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span
            class="commercial-site__images"
            v-else-if="fields.AnotherImageLink"
          >
            <img :src="getImgSrc(fields.AnotherImageLink)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field cm-calendar-input">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Высокая карточка:
        </span>
        <span class="field__content">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              v-model="fields.DoubleSize"
              v-bind:true-value="'1'"
              v-bind:false-value="'0'"
              id="UpdatePinDist"
            />
            <label class="onoffswitch-label" for="UpdatePinDist">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Верхний текст:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.FunctionalOptions"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'FunctionalOptions'"
            ref="translated-field-FunctionalOptions"
            :OriginalValue="fields.FunctionalOptions"
            :inputType="'textarea'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Call to action:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.CallToActionText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'CallToActionText'"
            ref="translated-field-CallToActionText"
            :OriginalValue="fields.SiteProduction"
            :inputType="'textarea'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Список похожих устройств:
        </span>
        <span class="field__content">
          <multiselect
            v-model="fields.DevicesIds"
            :options="devicesIdsArray"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выбрать"
            :searchable="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ getMultiselectOptionName(props.option) }}
                </span>
              </div>
            </template>
            <template slot="tag" slot-scope="{ option }"
              ><span class="multiselect__tag"
                ><span>{{ getMultiselectOptionName(option) }}</span></span
              ></template
            >
          </multiselect>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 3">
      <div class="field">
        <label class="">
          <input
            type="file"
            class="sup-chat__file"
            hidden
            @change="
              filesSliderChange($event.target.files, 'productionGallery')
            "
          />
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            >добавить изображение
          </span>
        </label>
        <label
          style="margin-left: 20px"
          @click="addVideo"
          v-if="!productSliderVideo"
        >
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            >добавить видео
          </span>
        </label>
      </div>
      <div class="field field-product-video" v-if="productSliderVideo">
        <label>
          <span class="field__label"> Ссылка на видео youtube: </span>
          <input
            type="text"
            class="input"
            :value="productSliderVideo.VideoLink"
            :disabled="loadingState"
            @blur="
              editFieldValue(
                $event,
                'productionGallery',
                productSliderVideo,
                'VideoLink'
              )
            "
          />
          <div
            class="sup-chat-files__item-remove"
            @click="
              deleteAdditionalData('productionGallery', productSliderVideo)
            "
          >
            <img src="/static/images/remove.svg" alt="icon" />
          </div>
        </label>
      </div>
      <div
        class="site-gallery"
        v-if="additionalItems.productionGallery.length"
        @end="changeSliderOrder()"
      >
        <div
          class="site-gallery__item"
          v-for="(item, index) in orderedGallery.filter(
            (i) => FileType !== '1'
          )"
          :key="index"
        >
          <div
            class="sup-chat-files__item-remove"
            @click="deleteAdditionalData('productionGallery', item)"
          >
            <img src="/static/images/remove.svg" alt="icon" />
          </div>
          <img :src="getImgSrc(item.Link)" alt="file image preview" />
        </div>
      </div>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 8">
      <div class="vue-editor-row">
        <label class="field">
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название лейбла:
            </span>
            <span class="field__content">
              <input
                type="text"
                class="input"
                v-model="fields.BaseFunctionalName"
                placeholder="Базовый функционал"
                v-if="parentData.language === 'ru'"
              />
              <translate-field
                :EntityColumnName="'BaseFunctionalName'"
                ref="translated-field-BaseFunctionalName"
                :OriginalValue="fields.BaseFunctionalName"
                :inputType="'input'"
                :module="'SiteProduction'"
                @getTranslation="getTranslation"
                :translation="translation"
                :id="id"
              />
            </span>
          </div>
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Базовый функционал:
          </span>
          <span class="field__content">
            <div class="skedit" v-if="parentData.language === 'ru'">
              <textarea
                v-tinymce
                field="BaseFunctional"
                v-model="fields.BaseFunctional"
              />
            </div>
            <translate-field
              :EntityColumnName="'BaseFunctional'"
              :OriginalValue="fields.BaseFunctional"
              :inputType="'editor'"
              :module="'SiteProduction'"
              ref="translated-field-BaseFunctional"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>
        <label class="field">
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название лейбла:
            </span>
            <span class="field__content">
              <input
                type="text"
                class="input"
                v-model="fields.OptionFunctionalName"
                placeholder="Опции"
                v-if="parentData.language === 'ru'"
              />
              <translate-field
                :EntityColumnName="'OptionFunctionalName'"
                ref="translated-field-OptionFunctionalName"
                :OriginalValue="fields.OptionFunctionalName"
                :inputType="'input'"
                :module="'SiteProduction'"
                @getTranslation="getTranslation"
                :translation="translation"
                :id="id"
              />
            </span>
          </div>
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Опции:
          </span>
          <span class="field__content">
            <div class="skedit" v-if="parentData.language === 'ru'">
              <textarea
                v-tinymce
                field="OptionFunctional"
                v-model="fields.OptionFunctional"
              />
            </div>
            <translate-field
              :EntityColumnName="'OptionFunctional'"
              :OriginalValue="fields.OptionFunctional"
              :inputType="'editor'"
              :module="'SiteProduction'"
              ref="translated-field-OptionFunctional"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>
      </div>
      <div class="field" style="margin-top: 30px">
        <label class="">
          <input
            type="file"
            class="sup-chat__file"
            hidden
            @change="
              filesSliderChange(
                $event.target.files,
                'productionFunctionalImages'
              )
            "
          />
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            >добавить изображение
          </span>
        </label>
      </div>
      <div
        class="site-gallery"
        v-if="additionalItems.productionFunctionalImages.length"
        @end="changeSliderOrder()"
      >
        <div
          class="site-gallery__item"
          v-for="(item, index) in additionalItems.productionFunctionalImages"
          :key="index"
        >
          <img :src="getImgSrc(item.ImageLink)" alt="file image preview" />
          <div
            class="sup-chat-files__item-remove"
            @click="deleteAdditionalData('productionFunctionalImages', item)"
          >
            <img src="/static/images/remove.svg" alt="icon" />
          </div>
        </div>
      </div>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save(false)"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 9">
      <div class="table cm-table-1 cm-table--device">
        <div class="table__row-wrap сmp__row-wrap">
          <div class="table__row table__row_main table__row-head">
            <div class="table__col table__col_width_260">
              <div class="table__text-block">
                <p class="table__text">Название характеристики</p>
              </div>
            </div>
            <div class="table__col table__col_width_260">
              <div class="table__text-block">
                <p class="table__text">Значение</p>
              </div>
            </div>
            <span
              class="little-down-text little-down-text--devices little-down-text--hidden"
            ></span>
          </div>

          <div class="flex-column">
            <div
              class="table__row table__row_main"
              v-for="(item, index) in additionalItems.productionCharacteristics"
              :key="index"
            >
              <div class="table__col table__col_width_260">
                <div class="table__text-block table__text-block--order">
                  <input
                    type="text"
                    class="input"
                    :value="item.CharacteristicsName"
                    :disabled="loadingState"
                    @blur="
                      editFieldValue(
                        $event,
                        'productionCharacteristics',
                        item,
                        'CharacteristicsName'
                      )
                    "
                    v-if="parentData.language === 'ru'"
                  />
                  <translate-field
                    :EntityColumnName="'CharacteristicsName'"
                    ref="translated-field-CharacteristicsName"
                    :OriginalValue="item.CharacteristicsName"
                    :inputType="'input'"
                    :module="'SiteProductionCharacteristics'"
                    @getTranslation="
                      getInnerObjectTranslation(
                        'productionCharacteristics',
                        item
                      )
                    "
                    :translation="item.translation"
                    :id="item.Id"
                  />
                </div>
              </div>
              <div class="table__col table__col_flexible">
                <div class="table__text-block">
                  <input
                    type="text"
                    class="input"
                    :value="item.CharacteristicsValue"
                    @blur="
                      editFieldValue(
                        $event,
                        'productionCharacteristics',
                        item,
                        'CharacteristicsValue'
                      )
                    "
                    v-if="parentData.language === 'ru'"
                  />
                  <translate-field
                    :EntityColumnName="'CharacteristicsValue'"
                    ref="translated-field-CharacteristicsValue"
                    :OriginalValue="item.CharacteristicsValue"
                    :inputType="'input'"
                    :module="'SiteProductionCharacteristics'"
                    @getTranslation="
                      getInnerObjectTranslation(
                        'productionCharacteristics',
                        item
                      )
                    "
                    :translation="item.translation"
                    :id="item.Id"
                  />
                </div>
              </div>
              <div class="table__col table__col-btn">
                <div
                  class="table__text-block"
                  @click="
                    deleteAdditionalData('productionCharacteristics', item)
                  "
                >
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          class="little-down-text little-down-text--devices"
          @click="createAdditionalData('productionCharacteristics')"
          >+ Добавить еще</span
        >
      </div>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 10">
      <div class="field">
        <label class="">
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            @click="createAdditionalData('productionDocuments')"
            >Добавить
          </span>
        </label>
      </div>
      <div class="inner-objects">
        <div
          class="inner-objects__item"
          v-for="(item, index) in additionalItems.productionDocuments"
          :key="index"
        >
          <span
            class="remove-icon"
            @click="deleteAdditionalData('productionDocuments', item)"
          ></span>
          <label
            class="field inner-objects__item-img"
            v-if="additionalProductsFiles.productionDocuments[item.Id]"
          >
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
              <span
                class="add-file-btn"
                v-if="
                  !item.Link &&
                  !additionalProductsFiles.productionDocuments[item.Id].length
                "
                >+</span
              >
            </span>
            <input
              type="file"
              @change="
                additionalProductsFilesChange(
                  'productionDocuments',
                  item,
                  $event.target.files
                )
              "
              hidden
            />
            <span class="field__content">
              <template
                v-if="
                  additionalProductsFiles.productionDocuments[item.Id].length
                "
              >
                <!--<p v-for="file additionalProductsFiles.productionDocuments[item.Id]">{{file}}</p>-->
              </template>
              <p>{{ item.Link }}</p>
            </span>
          </label>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название:
            </span>
            <span class="field__content">
              <input
                type="text"
                class="input"
                :value="item.Name"
                @blur="
                  editFieldValue($event, 'productionDocuments', item, 'Name')
                "
                v-if="parentData.language === 'ru'"
              />
              <translate-field
                :EntityColumnName="'Name'"
                ref="translated-field-productionDocumentsName"
                :OriginalValue="item.Name"
                :inputType="'input'"
                :module="'SiteProductionDocuments'"
                @getTranslation="
                  getInnerObjectTranslation('productionDocuments', item)
                "
                :translation="item.translation"
                :id="item.Id"
              />
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            ref="translated-field-SeoTitle"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            ref="translated-field-SeoDescription"
            :OriginalValue="fields.SeoDescription"
            :inputType="'input'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            ref="translated-field-SeoKeywords"
            :OriginalValue="fields.SeoKeywords"
            :inputType="'input'"
            :module="'SiteProduction'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteProduction'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import Api from "../../../api/commercial_site";
import { showAlert } from "../../../utils";
import * as _ from "lodash";

export default {
  mixins: [CommercialSitePopupFields],
  props: ["items", "selectListOptions"],
  data() {
    return {
      innerTab: 1,
      additionalItems: {
        productionGallery: [],
        productionDocuments: [],
        productionFunctionalImages: [],
        productionCharacteristics: [],
      },
      additionalProductsFiles: {
        productionGallery: {},
        productionDocuments: {},
        productionFunctionalImages: {},
        productionCharacteristics: {},
      },
      images: [],
      loadingState: false,
    };
  },
  watch: {
    id() {
      [
        "productionGallery",
        "productionDocuments",
        "productionFunctionalImages",
        "productionCharacteristics",
      ].forEach((objectName) => {
        this.getAdditionalData(objectName);
      });
    },
  },
  created() {
    window.b = this.additionalItems.productionCharacteristics;
    if (this.id !== "create") {
      [
        "productionGallery",
        "productionDocuments",
        "productionFunctionalImages",
        "productionCharacteristics",
      ].forEach((objectName) => {
        this.getAdditionalData(objectName);
      });
    }
  },
  computed: {
    initialData() {
      return {
        productionGallery: {
          Link: "",
          MainImage: 0,
          ProductId: this.id,
          initial: true,
        },
        productionDocuments: {
          Name: "",
          Link: "",
          ProductId: this.id,
          initial: true,
        },
        productionFunctionalImages: {
          ImageLink: "",
          ProductionId: this.id,
          initial: true,
        },
        productionCharacteristics: {
          CharacteristicsName: "",
          CharacteristicsValue: "",
          ProductId: this.id,
          initial: true,
        },
      };
    },
    devicesIdsArray() {
      const arr = [];
      _.each(this.items, (i) => {
        if (i.Id !== this.id) arr.push(i.Id);
      });
      return arr;
    },
    productSliderVideo() {
      return _.find(this.additionalItems.productionGallery, ["FileType", "1"]);
    },
    orderedGallery() {
      return _.orderBy(
        this.additionalItems.productionGallery,
        ["DateCreate"],
        ["asc"]
      );
    },
  },
  methods: {
    createAdditionalData(objectName) {
      const data = JSON.parse(JSON.stringify(this.initialData[objectName]));
      this.setAdditionalData(objectName, data, "create");
    },
    setAdditionalData(objectName, data, method = "set") {
      this.loadingState = true;
      this.$emit("setPreloader", true);
      const formData = new FormData();
      _.each(data, (value, key) => {
        if (key !== "ImageLink") {
          formData.append(key, value);
        }
      });

      if (!data.initial) {
        formData.append("_method", "PATCH");
        const files = this.additionalProductsFiles[objectName][data.Id];

        if (files && files.length) {
          _.each(files, (i) => {
            formData.append("ImageLink", i.file, i.name);
          });
        }
      }
      if (this.images) {
        _.each(this.images, (i) => {
          formData.append("Link", i.file, i.name);
          formData.append("ImageLink", i.file, i.name);
        });
      }

      const Id = method === "set" ? data.Id : this.id;
      Api[method + "AdditionalDataForProductions"](formData, Id, objectName)
        .then((res) => {
          if (res.data.status === "success") {
            if (method === "create") {
              this.getAdditionalData(objectName);
            }
          } else {
            showAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
          this.loadingState = false;
        });
      this.images = [];
    },
    getAdditionalData(objectName) {
      this.$emit("setPreloader", true);
      Api.getAdditionalDataForProductions(objectName, this.id)
        .then((res) => {
          const data = _.map(res.data, (i) => {
            i.translation = {};
            return i;
          });
          this.additionalItems[objectName] = data;
          _.each(res.data, (i) => {
            if (this.additionalProductsFiles[objectName][i.Id] === void 0) {
              this.additionalProductsFiles[objectName][i.Id] = [];
            } else {
              this.additionalProductsFiles[objectName][i.Id] = [];
            }
          });
          this.additionalProductsFiles = JSON.parse(
            JSON.stringify(this.additionalProductsFiles)
          );
          this.additionalItems = JSON.parse(
            JSON.stringify(this.additionalItems)
          );
          _.each(this.additionalItems[objectName], (o, index) => {
            Api.getTranslation(
              "Site" +
                objectName.charAt(0).toUpperCase() +
                objectName.substring(1),
              o.Id
            )
              .then((res) => {
                this.additionalItems[objectName][index].translation = res.data;
                // console.log(this.additionalItems[objectName])
                // this.additionalItems[objectName] = copy(this.additionalItems[objectName])
              })
              .catch(() => {
                showAlert("error", "Ошибка получения переводов");
              });
          });
          this.additionalItems = JSON.parse(
            JSON.stringify(this.additionalItems)
          );
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
    },
    deleteAdditionalData(objectName, data) {
      this.$emit("setPreloader", true);

      Api.deleteAdditionalDataForProductions(objectName, data.Id)
        .then((res) => {
          if (res.data.status === "success") {
            this.getAdditionalData(objectName);
          } else {
            showAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
    },
    additionalProductsFilesChange(objectName, object, fileList) {
      if (!fileList.length) return;
      this.additionalProductsFiles[objectName][object.Id] = [];
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.additionalProductsFiles[objectName][object.Id].push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
      this.setAdditionalData(objectName, object);
    },
    editFieldValue(event, objectName, item, fieldName) {
      const newFieldValue = event.target.value;
      if (newFieldValue !== item[fieldName]) {
        item[fieldName] = newFieldValue;
        this.setAdditionalData(objectName, item);
      }
    },
    filesSliderChange(fileList, objectName) {
      if (!fileList.length) return;
      // this.images = []
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.images.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          Id: Math.random().toString(36).substring(7),
        });
      });
      this.setAdditionalData(
        objectName,
        {
          ProductId: this.id,
          ProductionId: this.id,
          initial: true,
        },
        "create"
      );
    },
    addVideo() {
      this.setAdditionalData(
        "productionGallery",
        {
          FileType: "1",
          ProductId: this.id,
          ProductionId: this.id,
          Link: "",
          initial: true,
        },
        "create"
      );
    },
    getMultiselectOptionName(option) {
      return option.Name !== void 0
        ? option.Name
        : _.find(this.items, ["Id", option]).Name;
    },
    getInnerObjectTranslation(objectName, item) {
      this.getTranslation(
        "Site" + objectName.charAt(0).toUpperCase() + objectName.substring(1),
        item.Id
      ).then((res) => {
        this.additionalItems[objectName] = _.map(
          this.additionalItems[objectName],
          (o) => {
            if (o.Id === res.id) {
              o.translation = res.data;
            }
            return o;
          }
        );
      });
    },
    changeAction(e) {
      this.filesChange(e.target.files);
      this.fields.DropProductSizeImage = false;
    },
    clickAction() {
      this.fields.ProductSizeImage = null;
      this.DropProductSizeImage = true;
    },
  },
};
</script>

<style scoped></style>
