<template>
  <div class="dropzone dropzone3" ref="dropzone">
    <div class="dropzone__text">
      {{ text || "Загрузить файлы" }}
      <svg class="icon icon--file-download">
        <use xlink:href="/static/images/icon.svg#file-download"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
  mounted() {
    const dropzoneWrapper = this.$refs["dropzone"];
    const self = this;
    let isDragged = false;

    dropzoneWrapper.ondrop = function (event) {
      event.preventDefault();
      dropzoneWrapper.classList.remove("dropzone-hover");
      dropzoneWrapper.style.display = "none";
      self.$emit("filesChange", event.dataTransfer.files);
      self.$emit("hideDragZone");
      isDragged = false;
    };
    dropzoneWrapper.ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      isDragged = true;
      setTimeout(() => (isDragged = false));
      return false;
    };

    dropzoneWrapper.ondragleave = function () {
      setTimeout(() => {
        if (!isDragged) {
          dropzoneWrapper.classList.remove("dropzone-hover");
          dropzoneWrapper.style.display = "none";
          self.$emit("hideDragZone");
        }
      });
      return false;
    };
  },
};
</script>

<style scoped></style>
