export const GET_COUNT = "GET_COUNT";
export const SET_COUNT = "SET_COUNT";
export const TOGGLE_COUNT_INTERVAL = "TOGGLE_COUNT_INTERVAL";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const GET_REQUESTS = "GET_REQUESTS";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const GET_SUPPORT_DATA = "GET_SUPPORT_DATA";
export const CREATE_NEW_CLIENT_REQUEST = "CREATE_NEW_CLIENT_REQUEST";
export const TOGGLE_REQUEST_STATUS = "TOGGLE_REQUEST_STATUS";
export const SET_REQUESTS_WITH_CREATED = "SET_REQUESTS_WITH_CREATED";
export const SET_SELECTED_REQUEST = "SET_SELECTED_REQUEST";
export const GET_REQUEST_MESSAGES = "GET_REQUEST_MESSAGES";
export const SET_REQUEST_MESSAGES = "SET_REQUEST_MESSAGES";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_REQUEST_MESSAGES = "UPDATE_REQUEST_MESSAGES";
export const REMOVE_UNREAD_MARK = "REMOVE_UNREAD_MARK";
export const CLOSE_REQUEST = "CLOSE_REQUEST";
export const OPEN_REQUEST = "OPEN_REQUEST";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CHANGE_REQUEST_FILTERS = "CHANGE_REQUEST_FILTERS";
export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER";
export const TOGGLE_MESSAGE_UPDATE_STATE = "TOGGLE_MESSAGE_UPDATE_STATE";
export const TOGGLE_REQUEST_UPDATE_STATE = "TOGGLE_REQUEST_UPDATE_STATE";
export const TOGGLE_REQUESTS_LIST_LOAD_STATE =
  "TOGGLE_REQUESTS_LIST_LOAD_STATE";
export const CHANGE_REQUEST_STATUS = "CHANGE_REQUEST_STATUS";
export const ADD_TIME = "ADD_TIME";
export const CHANGE_SORT = "CHANGE_SORT";
export const SET_PROGRESS_BAR = "SET_PROGRESS_BAR";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const SET_USER_INFO = "SET_USER_INFO";
export const GET_PIN = "GET_PIN";
export const GET_MANAGERS_PEOPLE_LIST = "GET_MANAGERS_PEOPLE_LIST";
export const CHANGE_MANAGER = "CHANGE_MANAGER";
export const DEFINE_CANCEL_REQUEST_FUNCTION = "DEFINE_CANCEL_REQUEST_FUNCTION";
export const RESET_CANCEL_REQUEST_FUNCTION = "RESET_CANCEL_REQUEST_FUNCTION";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";
export const SET_PAGE = "SET_PAGE";

// rps rights
export const RESPONSE_CHANGE_RIGHT = "AC3CC28B-3A62-40D9-9CBB-DDE8AB625DF2";
export const ANSWER_TO_GUEST_RIGHT = "7641CEA7-CF84-423F-AD26-5615B3D3389F";
export const COMMENT_RIGHT = "CDAAA7EE-DA4E-4410-8A92-A9699756D354";
export const CREATE_REQUEST_RIGHT = "FDA88E65-1234-4A2B-93E3-3A6E034A712C";
export const CHANGE_STATUS_RIGHT = "C6011EF9-9A36-4E21-B328-53E54B9EE979";
export const EDIT_TAGS_RIGHT = "2A252D15-B317-456A-81C0-93CB2F416D95";
