<template>
  <div class="cm-popup__right promo-landing-settings">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Заголовок:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="json.Title"
            v-if="parentData.language === 'ru'"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Описание:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="json.Description"
            v-if="id !== 'partner_landing'"
          >
          </textarea>
          <vue-editor
            v-model="json.Description"
            v-else
            style="margin-bottom: 60px"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="singleFileChange($event.target.files, 'imageFile2')"
          />

          <div class="commercial-site__images" v-if="imageFile2">
            <img v-if="checkFileExt(imageFile2.name)" :src="imageFile2.img" />
          </div>

          <span class="commercial-site__images" v-else-if="json.Image">
            <img :src="getImgSrc(json.Image, null, imgPath)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>

      <template v-if="id !== 'soft_landing'">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Устройства Standart:
          </span>
          <span class="field__content">
            <multiselect
              v-model="json.DevicesIdsStandart"
              :options="devicesIdsArray"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Выбрать"
              :searchable="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ getMultiselectOptionDeviceName(props.option) }}
                  </span>
                </div>
              </template>
              <template slot="tag" slot-scope="{ option }"
                ><span class="multiselect__tag"
                  ><span>{{
                    getMultiselectOptionDeviceName(option)
                  }}</span></span
                ></template
              >
            </multiselect>
          </span>
        </label>

        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Устройства Ecomon:
          </span>
          <span class="field__content">
            <multiselect
              v-model="json.DevicesIdsEconom"
              :options="devicesIdsArray"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Выбрать"
              :searchable="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ getMultiselectOptionDeviceName(props.option) }}
                  </span>
                </div>
              </template>
              <template slot="tag" slot-scope="{ option }"
                ><span class="multiselect__tag"
                  ><span>{{
                    getMultiselectOptionDeviceName(option)
                  }}</span></span
                ></template
              >
            </multiselect>
          </span>
        </label>
      </template>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div
      class="cm-popup-form__wrapper"
      v-if="tab === 'promo_landing_devices_page_settings'"
    >
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Заголовок на странице оборудования:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="landingSettings.PageTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'PageTitle'"
            ref="translated-field-PageTitle"
            :OriginalValue="landingSettings.PageTitle"
            :inputType="'input'"
            :module="'PromoSettings'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Описание на странице оборудования:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="landingSettings.PageDescription"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название в меню:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="landingSettings.MenuTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'MenuTitle '"
            ref="translated-field-MenuTitle"
            :OriginalValue="landingSettings.MenuTitle"
            :inputType="'input'"
            :module="'PromoSettings'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="singleFileChange($event.target.files, 'imageFile1')"
          />

          <div class="commercial-site__images" v-if="imageFile1">
            <img v-if="checkFileExt(imageFile1.name)" :src="imageFile1.img" />
          </div>

          <span
            class="commercial-site__images"
            v-else-if="landingSettings.Image"
          >
            <img :src="getImgSrc(landingSettings.Image)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="landingSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 'promo_landing'">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Заголовок:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="json.Functionality.Title"
            v-if="parentData.language === 'ru'"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Описание:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="json.Functionality.Desc"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
        </span>
      </label>
      <!--      <label class="field">-->
      <!--        <span class="field__label field__label_align_right clients-common-info__label">-->
      <!--            Видеоинструкция ссылка:-->
      <!--        </span>-->
      <!--        <span class="field__content">-->
      <!--            <input type="text" class="input" v-model="json.Functionality.LinkForVideoInstruction"-->
      <!--                   v-if="parentData.language==='ru'">-->
      <!--        </span>-->
      <!--      </label>-->

      <div class="promo-landing-settings__functionality-wrap">
        <ul class="promo-landing-functionality-categories-list">
          <li
            class="promo-landing-functionality-categories-list__item"
            :class="{
              'promo-landing-functionality-categories-list__item--selected':
                selectedFunctionalityCategory === item.Id,
            }"
            @click="selectedFunctionalityCategory = item.Id"
            v-for="item in json.Functionality.Categories"
            :key="item.Id"
          >
            {{ item.Name }}
          </li>
        </ul>

        <draggable
          class="tags-section__list"
          v-model="json.Functionality.Items"
        >
          <li
            class="tags-section__list-item"
            :class="{
              'tags-section__list-item--selected': item.Categories.includes(
                selectedFunctionalityCategory
              ),
            }"
            v-for="(item, index) in json.Functionality.Items"
            :key="index"
            @click="selectedFunctionalityItem = item"
          >
            <p class="tags-section__list-name">{{ item.Name }}</p>
          </li>
        </draggable>

        <div
          class="btn"
          style="margin-top: 20px"
          @click="
            () => {
              json.Functionality.Items.push({
                Name: 'Название',
                Desc: '',
                VideoLink: '',
                img: '',
                Categories: [],
                Id: Math.random().toString(36).substring(7),
              });
            }
          "
        >
          Добавить
        </div>
      </div>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div
      class="cm-popup-form__wrapper"
      v-if="['TabSlider1', 'TabSlider2'].includes(tab)"
    >
      <div class="field">
        <label class="">
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            @click="appendTabToTabSlider(tab)"
            >Добавить
          </span>
        </label>
      </div>

      <div class="tabslider-editor__list">
        <div
          class="tabslider-editor__item"
          v-for="(item, index) in json[tab]"
          :key="index"
          @click="selectedTabSliderTab = item"
        >
          <p>{{ item.Name }}</p>
        </div>
      </div>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 4">
      <div class="field">
        <label class="">
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            @click="appendToTasksArray"
            >Добавить
          </span>
        </label>
      </div>
      <div class="inner-objects">
        <div
          class="inner-objects__item"
          v-for="(item, index) in json.Tasks"
          :key="index"
        >
          <span class="remove-icon" @click="deleteTask(item)"></span>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название:
            </span>
            <span class="field__content">
              <input type="text" class="input" v-model="item.Name" />
            </span>
          </label>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Текст:
            </span>
            <span class="field__content">
              <textarea class="textarea" v-model="item.TasksText"></textarea>
            </span>
          </label>
        </div>
      </div>
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 6">
      <div class="header" v-if="id !== 'partner_landing'">
        <div class="header__col">
          <ul class="tabs header__tabs">
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 1 }"
              @click="innerTab = 1"
            >
              <a href="#" class="tabs__link">Для собственника</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 2 }"
              @click="innerTab = 2"
            >
              <a href="#" class="tabs__link">Для арендатора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 3 }"
              @click="innerTab = 3"
            >
              <a href="#" class="tabs__link">Для гостей:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 4 }"
              @click="innerTab = 4"
            >
              <a href="#" class="tabs__link">Названия табов:</a>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="innerTab === 1">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits', 1)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType(1)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label class="field inner-objects__item-img">
              <div
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <div class="add-file-btn" v-if="!item.img">+</div>
              </div>
              <input
                type="file"
                @change="item.Image = singleFileChange($event.target.files)"
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof item.Image === 'object' &&
                    checkFileExt(item.Image.name)
                  "
                  :src="item.Image.img"
                />
                <img
                  :src="getImgSrc(item.img, null, imgPath)"
                  v-else-if="item.img"
                />
              </div>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsBenefitsName"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  v-model="item.BenefitText"
                  v-if="parentData.language === 'ru'"
                ></textarea>
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 2">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits', 2)"
              >Добавить
            </span>
          </label>
        </div>
        <label class="field field-inside">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Текст:
          </span>
          <span class="field__content">
            <textarea
              class="textarea"
              v-model="json.RentPromoText"
              v-if="parentData.language === 'ru'"
            ></textarea>
          </span>
        </label>

        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType(2)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsBenefitsName2"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 3">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits')"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType(3)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label class="field inner-objects__item-img">
              <div
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <div class="add-file-btn" v-if="!item.img">+</div>
              </div>
              <input
                type="file"
                @change="item.Image = singleFileChange($event.target.files)"
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof item.Image === 'object' &&
                    checkFileExt(item.Image.name)
                  "
                  :src="item.Image.img"
                />
                <img
                  :src="getImgSrc(item.img, null, '/uploads/landing/')"
                  v-else-if="item.img"
                />
              </div>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  v-model="item.BenefitText"
                  v-if="parentData.language === 'ru'"
                ></textarea>
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 4">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для арендодателей:
          </span>
          <span class="field__content">
            <input type="text" class="input" v-model="json.RentTabTitle" />
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для собственников:
          </span>
          <span class="field__content">
            <input type="text" class="input" v-model="json.OwnerTabTitle" />
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для гостей:
          </span>
          <span class="field__content">
            <input type="text" class="input" v-model="json.GuestTabTitle" />
          </span>
        </label>
      </template>
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 7">
      <div class="header">
        <div class="header__col">
          <ul class="tabs header__tabs">
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 1 }"
              @click="innerTab2 = 1"
            >
              <a href="#" class="tabs__link">Рабочая станция оператора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 2 }"
              @click="innerTab2 = 2"
            >
              <a href="#" class="tabs__link">Личный кабинет арендатора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 3 }"
              @click="innerTab2 = 3"
            >
              <a href="#" class="tabs__link">Мобильные приложения:</a>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="innerTab2 === 1">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 1)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(1)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label class="field inner-objects__item-img">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span class="add-file-btn" v-if="!item.img">+</span>
              </span>
              <input
                type="file"
                @change="item.Image = singleFileChange($event.target.files)"
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof item.Image === 'object' &&
                    checkFileExt(item.Image.name)
                  "
                  :src="item.Image.img"
                />
                <img
                  :src="getImgSrc(item.img, null, '/uploads/landing/')"
                  v-else-if="item.img"
                />
              </div>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  v-model="item.SoftText"
                  v-if="parentData.language === 'ru'"
                ></textarea>
                <translate-field
                  :EntityColumnName="'SoftText'"
                  ref="translated-field-SoftText"
                  :OriginalValue="item.SoftText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab2 === 2">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 2)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(2)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label class="field inner-objects__item-img">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span class="add-file-btn" v-if="!item.img">+</span>
              </span>
              <input
                type="file"
                @change="item.Image = singleFileChange($event.target.files)"
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof item.Image === 'object' &&
                    checkFileExt(item.Image.name)
                  "
                  :src="item.Image.img"
                />
                <img
                  :src="getImgSrc(item.img, null, '/uploads/landing/')"
                  v-else-if="item.img"
                />
              </div>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  v-model="item.SoftText"
                  v-if="parentData.language === 'ru'"
                ></textarea>
                <translate-field
                  :EntityColumnName="'SoftText'"
                  ref="translated-field-SoftText"
                  :OriginalValue="item.SoftText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab2 === 3">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 3)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(3)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label class="field inner-objects__item-img">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span class="add-file-btn" v-if="!item.img">+</span>
              </span>
              <input
                type="file"
                @change="item.Image = singleFileChange($event.target.files)"
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof item.Image === 'object' &&
                    checkFileExt(item.Image.name)
                  "
                  :src="item.Image.img"
                />
                <img
                  :src="getImgSrc(item.img, null, '/uploads/landing/')"
                  v-else-if="item.img"
                />
              </div>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="item.Name"
                  v-if="parentData.language === 'ru'"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  v-model="item.SoftText"
                  v-if="parentData.language === 'ru'"
                ></textarea>
              </span>
            </label>
          </div>
        </div>
      </template>
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 'standart_vs_ecomon'">
      <div class="header">
        <div class="header__col">
          <ul class="tabs header__tabs">
            <li
              class="tabs__tab"
              :class="{ active: innerTab3 === 1 && selectedTable === 'Rack' }"
              @click="clickAction"
            >
              <a href="#" class="tabs__link">Стойки Въезда/Выезда</a>
            </li>
            <li
              class="tabs__tab"
              :class="{
                active: innerTab3 === 1 && selectedTable === 'Payment',
              }"
              @click="clickAction2"
            >
              <a href="#" class="tabs__link">Терминалы оплаты</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab3 === 1 && selectedTable === 'Soft' }"
              @click="clickAction3"
            >
              <a href="#" class="tabs__link">Программное обеспечение</a>
            </li>
            <!--            <li class="tabs__tab" :class="{'active': innerTab3 === 2}" @click="innerTab3 = 2">-->
            <!--              <a href="#" class="tabs__link">Изображения в таблице</a>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>

      <div class="promo-landing-settings-table" v-if="innerTab3 === 1">
        <div class="promo-landing-settings-table__list">
          <div class="promo-landing-settings-table__img-field-wrap">
            <label
              class="promo-landing-settings-table__img-field field"
              v-for="(type, index) in [
                { name: 'Стандарт', prefix: '' },
                { name: 'Эконом', prefix: 'Econom' },
              ]"
              :key="index"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение {{ type.name }}:
                <span
                  class="add-file-btn"
                  v-if="!json.Table[selectedTable + 'Image' + type.prefix]"
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  json.Table[
                    selectedTable + 'Image' + type.prefix
                  ] = singleFileChange($event.target.files)
                "
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof json.Table[selectedTable + 'Image' + type.prefix] ===
                      'object' &&
                    checkFileExt(
                      json.Table[selectedTable + 'Image' + type.prefix].name
                    )
                  "
                  :src="json.Table[selectedTable + 'Image' + type.prefix].img"
                />
                <img
                  :src="
                    getImgSrc(
                      json.Table[selectedTable + 'Image' + type.prefix],
                      null,
                      '/uploads/landing/'
                    )
                  "
                  v-else-if="json.Table[selectedTable + 'Image' + type.prefix]"
                />
              </div>
            </label>
          </div>
          <div
            class="promo-landing-settings-table__item promo-landing-settings-table__item-head"
          >
            <p>Название</p>
            <p>Описание</p>
            <p>Стандарт</p>
            <p>Эконом</p>
          </div>
          <div
            class="promo-landing-settings-table__item"
            v-for="item in json.Table[selectedTable]"
            @click="selectedTableItem = item"
            :key="item.Id"
          >
            <p>{{ item.Name }}</p>
            <p>{{ item.Desc }}</p>
            <p>{{ item.Standart }}</p>
            <p>{{ item.Econom }}</p>
          </div>
        </div>

        <div
          class="btn"
          style="margin-top: 20px"
          @click="
            () => {
              json.Table[selectedTable].push({
                Name: 'Название',
                Desc: '',
                VideoLink: '',
                img: '',
                Standart: 1,
                Econom: 1,
                Id: Math.random().toString(36).substring(7),
              });
            }
          "
        >
          Добавить
        </div>
      </div>
      <template v-if="innerTab3 === 2">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Изображение 1:
          </span>
          <span class="field__content">
            <input
              type="file"
              accept="image/*"
              hidden
              class="input"
              @change="singleFileChange($event.target.files, 'tableImageFile1')"
            />

            <div class="commercial-site__images" v-if="tableImageFile1">
              <img
                v-if="checkFileExt(tableImageFile1.name)"
                :src="tableImageFile1.img"
              />
            </div>

            <span
              class="commercial-site__images"
              v-else-if="json.TableColImage1"
            >
              <img
                :src="getImgSrc(json.TableColImage1, null, '/uploads/landing/')"
              />
            </span>
            <span class="add-file-btn" v-else>+</span>
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Изображение 2:
          </span>
          <span class="field__content">
            <input
              type="file"
              accept="image/*"
              hidden
              class="input"
              @change="singleFileChange($event.target.files, 'tableImageFile2')"
            />

            <div class="commercial-site__images" v-if="tableImageFile2">
              <img
                v-if="checkFileExt(tableImageFile2.name)"
                :src="tableImageFile2.img"
              />
            </div>

            <span
              class="commercial-site__images"
              v-else-if="json.TableColImage2"
            >
              <img
                :src="getImgSrc(json.TableColImage2, null, '/uploads/landing/')"
              />
            </span>
            <span class="add-file-btn" v-else>+</span>
          </span>
        </label>
      </template>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="json.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            ref="translated-field-SeoTitle"
            :OriginalValue="json.SeoTitle"
            :inputType="'input'"
            :module="'PromoSettings'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="json.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            ref="translated-field-SeoDescription"
            :OriginalValue="json.SeoDescription"
            :inputType="'input'"
            :module="'PromoSettings'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="json.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            ref="translated-field-SeoKeywords"
            :OriginalValue="json.SeoKeywords"
            :inputType="'input'"
            :module="'PromoSettings'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="json.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'PromoSettings'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="jsonSave"
      >
        Сохранить
      </button>
    </div>

    <v-dialog
      v-model="selectedFunctionalityItem"
      v-if="selectedFunctionalityItem"
      width="600px"
      style="min-height: 800px"
    >
      <v-card style="padding-bottom: 70px">
        <v-card-title>
          <span class="headline">Редактировать</span>
        </v-card-title>
        <v-card-text>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                v-model="selectedFunctionalityItem.Name"
              />
            </div>
          </label>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                placeholder="Описание"
                v-model="selectedFunctionalityItem.Desc"
              />
            </div>
          </label>
          <label class="field dialog__img-field">
            <div
              class="field__label field__label_align_right clients-common-info__label"
            >
              Изображение:
            </div>
            <div class="field__content">
              <div class="add-file-btn" v-if="!selectedFunctionalityItem.img">
                +
              </div>
              <input
                type="file"
                @change="
                  selectedFunctionalityItem.img = singleFileChange(
                    $event.target.files
                  )
                "
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof selectedFunctionalityItem.img === 'object' &&
                    checkFileExt(selectedFunctionalityItem.img.name)
                  "
                  :src="selectedFunctionalityItem.img.img"
                />
                <img
                  :src="getImgSrc(selectedFunctionalityItem.img, null, imgPath)"
                  v-else-if="selectedFunctionalityItem.img"
                />
              </div>
            </div>
          </label>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                placeholder="Ссылка на видео"
                v-model="selectedFunctionalityItem.VideoLink"
              />
            </div>
          </label>
          <label class="field" v-if="id !== 'soft_landing'">
            <multiselect
              v-model="selectedFunctionalityItem.Categories"
              :options="funcCategoriesIds"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Выбрать"
              :searchable="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ getMultiselectOptionName(props.option) }}
                  </span>
                </div>
              </template>
              <template slot="tag" slot-scope="{ option }"
                ><span class="multiselect__tag"
                  ><span>{{ getMultiselectOptionName(option) }}</span></span
                >
              </template>
            </multiselect>
          </label>
          <div
            class="btn"
            style="position: absolute; right: 20px; bottom: 20px"
            @click="removeFunctionalityItem(selectedFunctionalityItem.Id)"
          >
            Удалить
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectedTableItem"
      v-if="selectedTableItem"
      width="600px"
      style="min-height: 800px"
    >
      <v-card style="padding-bottom: 70px">
        <v-card-title>
          <span class="headline">Редактировать</span>
        </v-card-title>
        <v-card-text>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                v-model="selectedTableItem.Name"
              />
            </div>
          </label>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                placeholder="Описание"
                v-model="selectedTableItem.Desc"
              />
            </div>
          </label>
          <label class="field dialog__img-field">
            <div
              class="field__label field__label_align_right clients-common-info__label"
            >
              Изображение:
            </div>
            <div class="field__content">
              <div class="add-file-btn" v-if="!selectedTableItem.img">+</div>
              <input
                type="file"
                @change="
                  selectedTableItem.img = singleFileChange($event.target.files)
                "
                hidden
              />
              <div class="field__content">
                <img
                  v-if="
                    typeof selectedTableItem.img === 'object' &&
                    checkFileExt(selectedTableItem.img.name)
                  "
                  :src="selectedTableItem.img.img"
                />
                <img
                  :src="
                    getImgSrc(selectedTableItem.img, null, '/uploads/landing/')
                  "
                  v-else-if="selectedTableItem.img"
                />
              </div>
            </div>
          </label>
          <label class="field">
            <div class="field__content">
              <input
                type="text"
                class="input"
                placeholder="Ссылка на видео"
                v-model="selectedTableItem.VideoLink"
              />
            </div>
          </label>
          <label class="field">
            <p style="margin-right: 20px">Стандарт</p>
            <div class="field__content">
              <select
                class="select__control input"
                v-model="selectedTableItem.Standart"
              >
                <option :value="0">Нет</option>
                <option :value="1">Да</option>
                <option :value="2">Опционально</option>
              </select>
            </div>
          </label>
          <label class="field">
            <p style="margin-right: 20px">Эконом</p>
            <div class="field__content">
              <select
                class="select__control input"
                v-model="selectedTableItem.Econom"
              >
                <option :value="0">Нет</option>
                <option :value="1">Да</option>
                <option :value="2">Опционально</option>
              </select>
            </div>
          </label>
          <div
            class="btn"
            style="position: absolute; right: 20px; bottom: 20px"
            @click="removeTableItem(selectedTableItem.Id)"
          >
            Удалить
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectedTabSliderTab"
      v-if="selectedTabSliderTab"
      width="1000px"
      style="min-height: 800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Редактировать</span>
        </v-card-title>
        <div style="display: flex; margin-bottom: 70px">
          <v-card-text>
            <label class="field">
              <div class="field__content">
                <input
                  type="text"
                  class="input"
                  v-model="selectedTabSliderTab.Name"
                />
              </div>
            </label>
            <label class="field">
              <div class="field__content">
                <textarea-autosize
                  type="text"
                  class="textarea"
                  placeholder="Описание"
                  v-model="selectedTabSliderTab.Desc"
                  :min-height="20"
                  :max-height="350"
                />
              </div>
            </label>
            <label class="field dialog__img-field">
              <div class="field__content">
                <template v-if="!selectedTabSliderTab.img">
                  <span style="margin-right: 10px; cursor: pointer">
                    Изображение:
                  </span>
                  <div class="add-file-btn">+</div>
                </template>
                <input
                  type="file"
                  @change="
                    selectedTabSliderTab.img = singleFileChange(
                      $event.target.files
                    )
                  "
                  hidden
                />
                <div class="field__content" style="cursor: pointer">
                  <img
                    v-if="
                      typeof selectedTabSliderTab.img === 'object' &&
                      checkFileExt(selectedTabSliderTab.img.name)
                    "
                    :src="selectedTabSliderTab.img.img"
                  />
                  <img
                    :src="getImgSrc(selectedTabSliderTab.img, null, imgPath)"
                    v-else-if="selectedTabSliderTab.img"
                  />
                </div>
              </div>
            </label>
            <div
              class="btn"
              style="position: absolute; right: 20px; bottom: 20px"
              @click="removeTabSliderTab(selectedTabSliderTab.Id)"
            >
              Удалить
            </div>
          </v-card-text>
          <v-card-text>
            <div class="field">
              <div
                style="display: flex; align-items: center"
                @click="addOptionToSelectedTabSliderTab"
              >
                <span style="margin-right: 10px; cursor: pointer">
                  Опции:
                </span>
                <div class="add-file-btn">+</div>
              </div>
            </div>

            <div
              class="tabslider-editor__tab-options-list"
              ref="tab-options-list"
            >
              <div
                class="tabslider-editor__tab-options-list-item"
                v-for="(item, index) in selectedTabSliderTab.Options"
                :key="index"
              >
                <input
                  type="text"
                  v-model="item.Name"
                  @keypress.13.prevent="
                    () => {
                      addOptionToSelectedTabSliderTab();
                      $nextTick(() =>
                        $refs['tab-options-list']
                          .querySelector(
                            '.tabslider-editor__tab-options-list-item:last-of-type input'
                          )
                          .focus()
                      );
                    }
                  "
                />
                <div
                  class="tabslider-editor__tab-options-list-item-remove-btn"
                  @click="removeOptionFromSelectedTabSliderTab(item.Id)"
                >
                  <svg width="16" height="18" stroke="#444444">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#trash-icon"
                    ></use>
                  </svg>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import Api from "../../../api/commercial_site";
import { showAlert } from "../../../utils";
import * as _ from "lodash";
import axios from "axios";
import { API_ROOT } from "../../../config";
import draggable from "vuedraggable";
import { randomId } from "../../../mixins/sales/SalesJSONS";

export default {
  data() {
    return {
      selectedFunctionalityItem: null,
      selectedFunctionalityCategory: null,
      selectedTable: "Rack",
      selectedTableItem: null,
      selectedTabSliderTab: null,
      devices: [],
      innerTab: 1,
      innerTab2: 1,
      innerTab3: 1,
      landingSettings: {
        PageTitle: "",
        PageDescription: "",
        MenuTitle: "",
      },
      json: {
        Tasks: [],
        Functionality: {
          Title: "",
          Desc: "",
          LinkForVideoInstruction: "",
          Categories: [
            {
              Name: "ТОП  10",
              Id: "Top",
            },
            {
              Name: "ТЦ",
              Id: "Tc",
            },
            {
              Name: "БЦ",
              Id: "Bc",
            },
            {
              Name: "МФЦ",
              Id: "Mfc",
            },
            {
              Name: "ЖК",
              Id: "Zk",
            },
            {
              Name: "Отель",
              Id: "Hotel",
            },
            {
              Name: "Гипермаркет",
              Id: "Hypermarket",
            },
            {
              Name: "Аэропорт/Вокзал",
              Id: "Air",
            },
            {
              Name: "Спорт-комплекс",
              Id: "Sport",
            },
            {
              Name: "Складской комплекс",
              Id: "Stock",
            },
            {
              Name: "Парковка",
              Id: "Parking",
            },
          ],
          Items: [],
        },
        RentTabTitle: "",
        OwnerTabTitle: "",
        GuestTabTitle: "",
        RentPromoText: "",
        DevicesIdsStandart: [],
        DevicesIdsEconom: [],
        Table: {
          RackImage: "",
          PaymentImage: "",
          SoftImage: "",
          RackImageEconom: "",
          PaymentImageEconom: "",
          SoftImageEconom: "",
          Rack: [],
          Payment: [],
          Soft: [],
        },
        Title: "",
        Description: "",
        Image: "",
        solutionsOpportunities: [],
        solutionsSoft: [],
        solutionsBenefits: [],
        SeoTitle: "",
        SeoDescription: "",
        SeoKeywords: "",
        SeoText: "",
        TableColImage1: "",
        TableColImage2: "",
        TabSlider1: [],
        TabSlider2: [],
      },
      imageFile1: null,
      imageFile2: null,
      tableImageFile1: null,
      tableImageFile2: null,

      // Настройка всех ручек перенесена в этот объект. В идеале перенести во внешнее хранилище.
      // Название вложенного объекта соответствует ident ресурса, передаваемого с роутера
      endpoints: {
        remote_landing: {
          get_promo_setting: `api/site/RemoteLanding/GetPromoSettings`,
          get_setting: `api/site/RemoteLanding/GetSettings`,
          save_promo_setting: `api/site/RemoteLanding/SavePromoSettings`,
          save_setting: `api/site/RemoteLanding/SaveSettings`,
        },
        partner_landing: {
          get_promo_setting: `api/site/PartnerLanding/GetPromoSettings`,
          get_setting: `api/site/PartnerLanding/GetSettings`,
          save_promo_setting: `api/site/PartnerLanding/SavePromoSettings`,
          save_setting: `api/site/PartnerLanding/SaveSettings`,
        },
        landing: {
          get_promo_setting: `api/site/Landing/GetPromoSettings`,
          get_setting: `api/site/Landing/GetSettings`,
          save_promo_setting: `api/site/Landing/SavePromoSettings`,
          save_setting: `api/site/Landing/SaveSettings`,
        },
        soft_landing: {
          get_setting: `api/site/Soft/GetSettings`,
          save_setting: `api/site/Soft/SaveSettings`,
        },
      },
    };
  },
  created() {
    axios
      .get(API_ROOT + this.endpoints[this.id].get_setting)
      .then((res) => {
        this.landingSettings = res.data;
        if (res.data.settings && res.data.settings !== "undefined") {
          const json = res.data.settings;
          if (json) {
            _.each(this.json, (val, key) => {
              if (!Object.keys(json).includes(key)) {
                json[key] = val;
              }
            });
            json.Functionality.Items = _.map(json.Functionality.Items, (i) => {
              _.each(["img"], (k) => {
                if (i[k] === void 0) i[k] = "";
              });
              return i;
            });
            _.each(
              [
                "SoftImage",
                "PaymentImage",
                "RackImage",
                "SoftImageEconom",
                "PaymentImageEconom",
                "RackImageEconom",
              ],
              (k) => {
                if (json.Table[k] === void 0) json.Table[k] = "";
              }
            );

            _.each(["Rack", "Payment", "Soft"], (k) => {
              _.each(json.Table[k], (i) => {
                if (i.img === void 0) i.img = "";
              });
            });

            this.json = { ...json };
          }
        }
      })
      .catch((err) => showAlert("error", "Ошибка", err.message));

    Api.get("production")
      .then((res) => (this.devices = res.data))
      .catch((err) => showAlert("error", "Ошибка", err.message));
  },

  computed: {
    devicesIdsArray() {
      const arr = [];
      _.each(this.devices, (i) => {
        if (i.Id !== this.id) arr.push(i.Id);
      });
      return arr;
    },
    funcCategoriesIds() {
      return this.json.Functionality.Categories.map((i) => i.Id);
    },
    initialData() {
      return {
        solutionsOpportunities: {
          Name: "",
          Image: "",
          OpportunitiesText: "",
          SolutionId: this.id,
          initial: true,
        },
        solutionsSoft: {
          Name: "",
          Image: "",
          SoftText: "",
          SolutionId: this.id,
          initial: true,
        },
        solutionsBenefits: {
          Name: "",
          Image: "",
          BenefitText: "",
          SolutionId: this.id,
          initial: true,
          BenefitType: 2,
        },
      };
    },
    imgPath() {
      return this.id === "partner_landing"
        ? "/uploads/partner_landing/"
        : this.id === "soft_landing"
        ? "/uploads/soft_landing/"
        : "/uploads/landing/";
    },
  },

  methods: {
    landingSave() {
      const formData = new FormData();
      _.each(this.landingSettings, (value, key) => {
        formData.append(key, value);
      });
      if (this.imageFile1) {
        formData.append("image", this.imageFile1.file, this.imageFile1.name);
      }
      axios
        .post(API_ROOT + this.endpoints[this.id].save_promo_setting, formData)
        .then(() => showAlert("success", "Данные успешно сохранены"))
        .catch((err) => showAlert("error", "Ошибка", err.message));
    },

    jsonSave() {
      const formData = new FormData();

      const arrayWithImages = [
        "solutionsBenefits",
        "solutionsSoft",
        "TabSlider1",
        "TabSlider2",
      ];
      arrayWithImages.forEach((arrayName) => {
        let count = 0;
        _.each(this.json[arrayName], (i) => {
          const imgField = arrayName.includes("TabSlider") ? "img" : "Image";

          if (i[imgField] && typeof i[imgField] === "object") {
            formData.append(
              `${arrayName}[${arrayName}_${count}]`,
              i[imgField].file,
              i[imgField].name
            );
          }
          count++;
        });
      });

      let count = 0;
      this.json.Functionality.Items.forEach((i) => {
        if (i.img && typeof i.img === "object") {
          formData.append(`Functionality[Functionality_${count}]`, i.img.file);
        }
        count++;
      });

      _.each(["Rack", "Payment", "Soft"], (k) => {
        let count = 0;
        _.each(this.json.Table[k], (i) => {
          if (i.img && typeof i.img === "object") {
            formData.append(`Table${k}[Table${k}_${count}]`, i.img.file);
          }
          count++;
        });
      });

      const jsonCopy = JSON.parse(JSON.stringify(this.json));
      arrayWithImages.forEach((arrayName) => {
        _.each(jsonCopy[arrayName], (i) => {
          if (i.Image && typeof i.Image === "object") {
            i.Image = "";
          }
        });
      });

      formData.append("settings", JSON.stringify(jsonCopy));

      if (this.imageFile2) {
        formData.append("image", this.imageFile2.file, this.imageFile2.name);
      }
      if (this.tableImageFile1) {
        formData.append(
          "TableColImage1",
          this.tableImageFile1.file,
          this.tableImageFile1.name
        );
      }
      if (this.tableImageFile2) {
        formData.append(
          "TableColImage2",
          this.tableImageFile2.file,
          this.tableImageFile2.name
        );
      }

      _.each(
        [
          "SoftImage",
          "PaymentImage",
          "RackImage",
          "SoftImageEconom",
          "PaymentImageEconom",
          "RackImageEconom",
        ],
        (k) => {
          if (this.json.Table[k] && typeof this.json.Table[k] === "object") {
            formData.append(
              k,
              this.json.Table[k].file,
              this.json.Table[k].name
            );
          }
        }
      );

      // icons_sport[icons_sport_1]: (binary)
      if (jsonCopy) {
        axios
          .post(API_ROOT + this.endpoints[this.id].save_setting, formData)
          .then((res) => {
            if (res.data.status === "success") {
              showAlert("success", "Данные успешно сохранены");
            } else {
              showAlert("alert", res.data.message);
            }
          });
      }
    },
    appendToTasksArray() {
      if (!this.json.Tasks) this.json.Tasks = [];
      this.json.Tasks.push({ Name: "", TasksText: "" });
      this.json = JSON.parse(JSON.stringify(this.json));
    },
    deleteTask(item) {
      this.json.Tasks = _.reject(this.json.Tasks, ["Name", item.Name]);
      this.json = JSON.parse(JSON.stringify(this.json));
    },
    getMultiselectOptionName(option) {
      return option.Name !== void 0
        ? option.Name
        : _.find(this.json.Functionality.Categories, ["Id", option]).Name;
    },
    getMultiselectOptionDeviceName(option) {
      // console.log(this.devices)
      return option.Name !== void 0
        ? option.Name
        : _.find(this.devices, ["Id", option])
        ? _.find(this.devices, ["Id", option]).Name
        : "...";
    },
    createAdditionalData(objectName, type = 3) {
      const data = JSON.parse(JSON.stringify(this.initialData[objectName]));
      if (objectName === "solutionsBenefits") {
        data.BenefitType = type;
      }
      if (objectName === "solutionsSoft") {
        data.SoftType = type;
      }
      data.Id = randomId();
      this.setAdditionalData(objectName, data, "create");
    },
    setAdditionalData(objectName, data) {
      if (!this.json[objectName]) this.json[objectName] = [];
      this.json[objectName].push(data);
    },
    getBenefitsByType(BenefitType) {
      return _.filter(this.json.solutionsBenefits, { BenefitType });
    },
    deleteAdditionalData(objectName, data) {
      this.json[objectName] = _.reject(this.json[objectName], ["Id", data.Id]);
    },
    removeFunctionalityItem(id) {
      this.selectedFunctionalityItem = null;
      this.json.Functionality.Items = _.reject(this.json.Functionality.Items, [
        "Id",
        id,
      ]);
    },
    removeTableItem(id) {
      this.selectedTableItem = null;
      this.json.Table[this.selectedTable] = _.reject(
        this.json.Table[this.selectedTable],
        ["Id", id]
      );
    },
    getSoftsByType(SoftType) {
      return _.filter(this.json.solutionsSoft, { SoftType });
    },
    clickAction() {
      this.selectedTable = "Rack";
      this.innerTab3 = 1;
    },
    clickAction2() {
      this.selectedTable = "Payment";
      this.innerTab3 = 1;
    },
    clickAction3() {
      this.selectedTable = "Soft";
      this.innerTab3 = 1;
    },
    appendTabToTabSlider(field) {
      this.json[field].push({
        Id: Math.random().toString(36).substring(7),
        Name: "Название таба",
        Desc: "",
        img: "",
        Options: [],
      });
    },
    removeTabSliderTab(id) {
      this.selectedTabSliderTab = null;
      const tabSliderFields = ["TabSlider1", "TabSlider2"];
      tabSliderFields.forEach((field) => {
        this.json[field] = _.reject(this.json[field], ["Id", id]);
      });
    },
    addOptionToSelectedTabSliderTab() {
      this.selectedTabSliderTab.Options.push({
        Id: Math.random().toString(36).substring(7),
        Name: "",
      });
    },
    removeOptionFromSelectedTabSliderTab(Id) {
      this.selectedTabSliderTab.Options = _.reject(
        this.selectedTabSliderTab.Options,
        { Id }
      );
    },
  },

  mixins: [CommercialSitePopupFields],
  components: { draggable },
};
</script>

<style scoped>
.field__content img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
}
.tabslider-editor__list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}
.tabslider-editor__item {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.tabslider-editor__tab-options-list {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.tabslider-editor__tab-options-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
}
.tabslider-editor__tab-options-list-item input {
  padding: 5px 18px;
  flex: 1;
}
.tabslider-editor__tab-options-list-item-remove-btn {
  padding: 4px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}
</style>
