<template>
  <div class="popup__inner-cont">
    <div
      class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
      @click="openModal()"
    >
      <Print_6_1 />
    </div>
    <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
      <Print_6_1 />
    </magnific-popup-modal>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('Entry_2')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div
          class="new-changes-block"
          v-if="
            compareObject.Modules.Entry_2 !== void 0 &&
            compareObject.Modules.Entry_2.img_barrier
          "
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <img
              :src="getImageSrc(offerObject.json.Modules.Entry.img_barrier)"
              alt=""
            />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <img
              :src="getImageSrc(compareObject.Modules.Entry_2.img_barrier)"
              alt=""
            />
          </div>
        </div>
        <div
          class="new-changes-block"
          :key="index"
          v-for="(item, index) in getOptionsChangesArray('Entry_2')"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <span style="display: none">{{
              (old = findOldOption("Entry", item.randomId))
            }}</span>
            <p>
              {{ old.name }}<span>, {{ old.cost | moneyFormat }}$</span>
            </p>
            <br />
            <p>{{ old.desc }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>
            {{ item.name }}, <span>{{ item.cost | moneyFormat }}$</span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Entry_2', 'removed')"
          :key="'1' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>
              {{ item.name }}, <span>{{ item.cost | moneyFormat }}$</span>
            </p>
            <br />
            <p>{{ item.desc }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>Удалено</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Entry_2', 'added')"
          :key="'k' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>-</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>
            {{ item.name }}, <span>{{ item.cost | moneyFormat }}$</span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('Entry'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__cont--total pb-0 scroll-style"
      >
        <div class="p-settings">
          <div class="cover devices-cover">
            <div class="cover__label p-settings__title">
              Выбрать изображение:
            </div>
            <div class="cover__list">
              <div
                class="cover__item"
                v-for="(item, index) in settingsObject.json.devices[
                  systemTypeForPreview
                ].entry.img_barrier.filter((item) => item.img)"
                :key="index"
              >
                <button
                  class="cover__button"
                  @click="
                    changeDeviceImageForOffer(item.img, 'Entry', 'img_barrier')
                  "
                  :style="{
                    backgroundImage: `url(${getImageSrc(
                      item.img,
                      offerObject.json.System
                    )})`,
                  }"
                ></button>
              </div>
            </div>
          </div>

          <div class="p-settings__title">Устройства</div>
          <div
            class="p-settings__item"
            v-for="(item, index) in mainDevices('Entry', 'barrier')"
            :key="index + 'xss'"
          >
            <div
              class="p-settings__cont"
              @click="openOptionSettings($event.target)"
            >
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">{{ item.name }}</div>
                <div class="p-settings__inner">
                  <div class="p-settings__price">
                    {{ getOptionPrice("Entry", "barrier", item.randomId) }}
                  </div>
                  <span class="badge badge--warning" v-if="item.discont_number"
                    >Скидка {{ item.discont_number
                    }}{{ item.discont_type === "percent" ? "%" : "р." }}</span
                  >
                </div>
              </div>
              <div class="col-auto ml-auto">
                <div class="spinner">
                  <div
                    class="spinner__btn"
                    @click="changeCount(item, 'minus', 'Entry_2')"
                  >
                    -
                  </div>
                  <input
                    class="spinner__num"
                    type="number"
                    :value="item.count"
                    @keyup="changeCount(item, 'input', 'Entry_2', $event)"
                  />
                  <div
                    class="spinner__btn"
                    @click="changeCount(item, 'plus', 'Entry_2')"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Скидка</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.discont_number"
                      @keyup="validateDiscont($event, item)"
                    />
                  </div>
                  <div class="p-settings__discount">
                    <span
                      class="p-settings__discount-item p-settings__discount--percent"
                      :class="
                        item.discont_type === void 0
                          ? 'p-settings__discount-item--active'
                          : item.discont_type === 'percent'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="changeDiscontType(item, 'percent')"
                      >%</span
                    >
                    <span
                      class="p-settings__discount-item p-settings__discount--rub"
                      :class="
                        item.discont_type === 'rub'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="changeDiscontType(item, 'rub')"
                      >{{ getCurrencyAbbreviation }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Цена за единицу</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.single_cost"
                      @keyup="setSingleCost(item)"
                      :placeholder="
                        item.single_cost === void 0 || !item.single_cost
                          ? item.discont_type !== void 0 && item.discont_type
                            ? item.initial_cost
                            : item.convertCost
                          : ''
                      "
                    />
                  </div>
                  <div class="p-settings__currency">
                    {{ getCurrencyAbbreviation }}
                  </div>
                </div>
              </div>
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">
                    Сумма за {{ item.count }} шт.
                  </div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.custom_sum"
                      @keyup="setCustomSum(item)"
                      :placeholder="
                        item.custom_sum === void 0 || !item.custom_sum
                          ? item.discont_type !== void 0 && item.discont_type
                            ? item.single_cost !== void 0 && item.single_cost
                              ? item.single_cost * item.count
                              : item.initial_cost * item.count
                            : item.convertCost * item.count
                          : ''
                      "
                    />
                  </div>
                  <div class="p-settings__currency">
                    {{ getCurrencyAbbreviation }}
                  </div>
                </div>
              </div>
              <div class="p-settings__btn-group">
                <button
                  class="p-settings__btn p-settings__btn--red"
                  @click="cancelSetOptions(item)"
                >
                  Отмена
                </button>
                <button
                  class="p-settings__btn p-settings__btn--green"
                  @click="closeSettingsOption($event.target)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
          <div class="p-settings__title">Опции</div>

          <template
            v-for="(
              item, index
            ) in offerObject.json.Modules.Entry.barrier.filter(
              (item, index) =>
                index !== 0 && !item.is_device && !item.falseOption
            )"
          >
            <div
              class="p-settings__item"
              :class="!item.count ? 'p-settings__item--disabled' : ''"
              :key="index + 'c'"
            >
              <div
                class="p-settings__cont"
                @click="openOptionSettings($event.target)"
              >
                <div class="p-settings__check">
                  <label
                    class="checkbox checkbox_white table__checkbox js-index-checkbox"
                  >
                    <input
                      class="checkbox__input main_checkbox_input"
                      type="checkbox"
                      name="row_checkbox_clients"
                      @click.prevent="item.count = item.count ? 0 : 1"
                      :class="item.count ? 'checkbox__box--active' : ''"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </label>
                </div>
                <div class="col-auto p-settings__name-wrap">
                  <div class="p-settings__name-s">{{ item.name }}</div>
                  <div class="p-settings__inner">
                    <div class="p-settings__price">
                      {{ getOptionPrice("Entry", "barrier", item.randomId) }}
                    </div>
                    <span
                      class="badge badge--warning"
                      v-if="item.discont_number"
                      >Скидка {{ item.discont_number
                      }}{{ item.discont_type === "percent" ? "%" : "р." }}</span
                    >
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <div class="spinner">
                    <div
                      class="spinner__btn"
                      @click="changeCount(item, 'minus', false)"
                    >
                      -
                    </div>
                    <input
                      class="spinner__num"
                      type="number"
                      :value="item.count"
                      @keyup="changeCount(item, 'input', 'Entry_2', $event)"
                    />
                    <div
                      class="spinner__btn"
                      @click="changeCount(item, 'plus', false)"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-settings__drop">
                <div class="p-settings__row">
                  <div class="col">
                    <div class="p-settings__label">Скидка</div>
                  </div>
                  <div class="p-settings__price-cont">
                    <div class="form-row">
                      <input
                        class="form-row__input"
                        type="number"
                        min="0"
                        v-model="item.discont_number"
                        @keyup="validateDiscont($event, item)"
                      />
                    </div>
                    <div class="p-settings__discount">
                      <span
                        class="p-settings__discount-item p-settings__discount--percent"
                        :class="
                          item.discont_type === void 0
                            ? 'p-settings__discount-item--active'
                            : item.discont_type === 'percent'
                            ? 'p-settings__discount-item--active'
                            : ''
                        "
                        @click="changeDiscontType(item, 'percent')"
                        >%</span
                      >
                      <span
                        class="p-settings__discount-item p-settings__discount--rub"
                        :class="
                          item.discont_type === 'rub'
                            ? 'p-settings__discount-item--active'
                            : ''
                        "
                        @click="changeDiscontType(item, 'rub')"
                        >{{ getCurrencyAbbreviation }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="p-settings__row">
                  <div class="col">
                    <div class="p-settings__label">Цена за единицу</div>
                  </div>
                  <div class="p-settings__price-cont">
                    <div class="form-row">
                      <input
                        class="form-row__input"
                        type="number"
                        min="0"
                        v-model="item.single_cost"
                        @keyup="setSingleCost(item)"
                        :placeholder="
                          item.single_cost === void 0 || !item.single_cost
                            ? item.discont_type !== void 0 && item.discont_type
                              ? item.initial_cost
                              : item.convertCost
                            : ''
                        "
                      />
                    </div>
                    <div class="p-settings__currency">
                      {{ getCurrencyAbbreviation }}
                    </div>
                  </div>
                </div>
                <div class="p-settings__row">
                  <div class="col">
                    <div class="p-settings__label">
                      Сумма за {{ item.count }} шт.
                    </div>
                  </div>
                  <div class="p-settings__price-cont">
                    <div class="form-row">
                      <input
                        class="form-row__input"
                        type="number"
                        min="0"
                        v-model="item.custom_sum"
                        @keyup="setCustomSum(item)"
                        :placeholder="
                          item.custom_sum === void 0 || !item.custom_sum
                            ? item.discont_type !== void 0 && item.discont_type
                              ? item.single_cost !== void 0 && item.single_cost
                                ? item.single_cost * item.count
                                : item.initial_cost * item.count
                              : item.convertCost * item.count
                            : ''
                        "
                      />
                    </div>
                    <div class="p-settings__currency">
                      {{ getCurrencyAbbreviation }}
                    </div>
                  </div>
                </div>
                <div class="p-settings__btn-group">
                  <button
                    class="p-settings__btn p-settings__btn--red"
                    @click="cancelSetOptions(item)"
                  >
                    Отмена
                  </button>
                  <button
                    class="p-settings__btn p-settings__btn--green"
                    @click="closeSettingsOption($event.target)"
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
            <div
              class="p-settings__title"
              v-if="item.is_device"
              :key="index + 'c'"
            >
              Опции для {{ item.name }}
            </div>
          </template>
        </div>
      </div>
      <div class="pdf-settings__next">
        <div class="p-settings__total">
          <div class="col-auto">Итого за модуль</div>
          <div class="col-auto ml-auto">
            {{ getAmount("Entry", "cost", "barrier") }}
            {{ getCurrencyAbbreviation }}
          </div>
        </div>
        <div class="pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(6)">
            Назад
          </button>
          <button
            class="btn-full btn-full__green"
            @click="
              changeStep(offerObject.json.SystemType !== 'Tokensdbl' ? 7 : 7.1)
            "
          >
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_6_1 from "../prints/print_6-1";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
    ...mapGetters("sales", ["getCurrencyAbbreviation"]),
  },
  components: { Print_6_1 },
};
</script>
