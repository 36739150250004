<template>
  <div class="task-form-filter">
    <div class="task-form-filter__control">
      <div class="task-form-filter__control-inner">
        <div>
          <div class="task-form-filter__field">
            <span class="task-form-filter__field-title">Ответственный:</span>
            <div class="select">
              <select
                class="select__control"
                v-model="commonSettings.managerForFilter"
                :disabled="
                  activeTab === 'without_responsible' || activeTab === 'responsible'
                "
                :style="
                  activeTab === 'without_responsible' || activeTab === 'responsible'
                    ? 'cursor: not-allowed'
                    : ''
                "
              >
                <option :value="null" selected>Все операторы</option>
                <option :value="' '">Без ответственного</option>
                <option
                  :value="item.Id"
                  v-for="(item, index) in personsForManage"
                  :key="index"
                >
                  {{ item.FullName }}
                </option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>
          <div class="task-form-filter__field">
            <span class="task-form-filter__field-title">Объект:</span>
            <div class="select">
              <select
                class="select__control"
                v-model="commonSettings.objectForFilter"
              >
                <option :value="null">Все объекты</option>
                <option
                  :value="item.Id"
                  v-for="(item, index) in objects.objects"
                  :key="index"
                >
                  {{ item.Name }}
                </option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>
          <div class="task-form-filter__field">
            <span class="task-form-filter__field-title">Статус:</span>
            <div class="select">
              <select
                class="select__control"
                v-model="commonSettings.statusForFilter"
              >
                <option :value="null">Все статусы</option>
                <option :value="1">Ожидает ответа клиента</option>
                <option :value="2">В работе</option>
                <option :value="4">Ожидает ответа оператора</option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>
          <div class="task-form-filter__field">
            <span class="task-form-filter__field-title inline">С задачами</span>
            <!--              TODO если это выглядит нормально, можно вынести inline в отдельный стиль-->
            <label
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
              style="vertical-align: sub; margin: 0 0.5rem"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                @click.prevent="
                  commonSettings.showWithTasks = !commonSettings.showWithTasks
                "
                :class="{
                  'checkbox__box--active': commonSettings.showWithTasks,
                }"
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </label>
          </div>
        </div>
        <div>
          <span class="task-form-filter__field-title">Тэги:</span>
          <div class="task-form-filter__field">
            <tags-select
              :initialTags="commonSettings.tagsForFilter"
              :tagsList="tagsFilterArray"
              :asFilter="true"
              @deleteTagFromList="setTagFromList"
              @setTagFromList="setTagFromList"
            />
          </div>
        </div>
      </div>
      <div class="task-form-filter__bottom">
        <template>
          <div
            class="task-form-filter__bottom-btn"
            @click="getFilteredRequests"
          >
            Применить
          </div>
          <div class="task-form-filter__bottom-btn-gray" @click="resetAll">
            Сбросить
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import * as supportTypes from "@/store/support/constants";
import * as _ from "lodash";
import { Helpers } from "@/mixins/Helpers";
import TagsSelect from "./TagsSelect";
export default {
  mixins: [Helpers],
  name: "RpsSupportFilter",
  components: { TagsSelect },
  computed: {
    ...mapState("rpsSupport", [
      "count",
      "activeTabs",
      "searchInput",
      "commonSettings",
      "personsForManage",
    ]),
    ...mapState("clients", ["objects"]),
    ...mapGetters("rpsSupport", ["activeTab"]),
    tagsFilterArray() {
      const tags = this.commonSettings.tagsForFilter;
      let preparedTags = [];
      if (tags && tags.length) {
        _.each(tags, (i) => {
          const foundTag = this.getTagById(i.Id);
          if (foundTag) {
            foundTag.requestId = i.Id;
            preparedTags.push(foundTag);
          }
        });
        return preparedTags;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions("rpsSupport", [
      supportTypes.SET_ACTIVE_TAB,
      supportTypes.GET_REQUESTS,
      supportTypes.SET_SEARCH_INPUT,
      supportTypes.CHANGE_MANAGER,
    ]),
    getTagById(id) {
      return findTag(this.commonSettings.tags);

      function findTag(array) {
        let searchTag = undefined;
        _.each(array, (i) => {
          if (i.Id === id) {
            searchTag = i;
            return false;
          } else if (i.child && i.child.length) {
            let tag = findTag(i.child);
            if (tag) {
              searchTag = tag;
              return false;
            }
          }
        });

        return searchTag;
      }
    },
    getFilteredRequests() {
      this.commonSettings.cancelRequest = true;
      this[supportTypes.GET_REQUESTS]({
        scope: this.activeTab,
        start: false,
        force: true,
      });
      this.$emit("closeFilterWindow");
      this.commonSettings.cancelRequest = false;
    },
    setTagFromList(tag) {
      const foundTag = _.find(this.commonSettings.tagsForFilter, [
        "Id",
        tag.Id,
      ]);
      if (foundTag) {
        this.commonSettings.tagsForFilter = _.reject(
          this.commonSettings.tagsForFilter,
          ["Id", foundTag.Id]
        );
      } else {
        this.commonSettings.tagsForFilter.push(tag);
      }
    },
    resetAll() {
      this.commonSettings.managerForFilter = null;
      this.commonSettings.objectForFilter = null;
      this.commonSettings.statusForFilter = null;
      this.commonSettings.showWithTasks = false;
      this.commonSettings.tagsForFilter = [];
    },
  },
};
</script>

<style scoped></style>
