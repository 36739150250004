<template>
  <div
    class="section-tasks-draggable-zone"
    :class="{
      'section-tasks-draggable-zone--open': open,
      'section-tasks-draggable-zone--empty': !sectionTasks.length,
      'section-tasks-draggable-zone_draggable': section.Id,
      'section-tasks-draggable-zone_have-next-section': haveNextSection,
    }"
    @mouseleave="mouseleave"
  >
    <div
      class="table-section-row table__row--col"
      v-click-outside="disableEditState"
      v-if="sections.length > 1"
    >
      <div class="table__row table__row_main">
        <div
          class="task-table-row-inner-indicator"
          v-for="c in new Array(level)"
          :key="c"
        ></div>
        <div
          class="table-section-row__arrow table__col table__col_width_35"
          @click="setOpenState(!open)"
        >
          <svg width="12" height="7">
            <use
              xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
            ></use>
          </svg>
        </div>
        <div class="table__col table__col_flexible table-section-row__name">
          <input
            type="text"
            v-if="edit && !listState"
            :disabled="!checkUserTaskRight('edit_section_name')"
            :value="section.Name"
            ref="input"
          />
          <div class="table-section-row__name" v-else @click="setEditState">
            <p>{{ isVirtualSection ? "Без секции" : section.Name }}</p>
            <div
              class="task-deep-info"
              v-if="sectionTasks && sectionTasks.length"
            >
              <i class="deep-icon"></i>{{ sectionTasks.length }}
            </div>
            <div
              class="table-section-row__submenu-btn"
              ref="portal-wrapper"
              v-if="!isVirtualSection && !listState"
              @click.stop="submenuOpen = true"
            >
              <svg focusable="false" viewBox="0 0 32 32">
                <path
                  d="M16,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S14.3,13,16,13z M3,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S1.3,13,3,13z M29,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S27.3,13,29,13z"
                ></path>
              </svg>
              <portal to="no-multiple-portal" v-if="submenuOpen">
                <transition name="fade">
                  <div
                    class="simple-select-list"
                    style="max-width: 200px"
                    v-if="submenuOpen"
                    v-click-outside="closeSubmenu"
                    v-portal="{ parent: $refs['portal-wrapper'] }"
                  >
                    <div class="simple-select-list__item" @click="createTask">
                      Создать задачу
                    </div>
                    <div class="simple-select-list__item">
                      <div
                        class="task-create-form__field-value"
                        @click="processesBubbleOpen = true"
                      >
                        <template v-if="section.ProcessId">{{
                          getProcessName(section.ProcessId)
                        }}</template>
                        <template v-else>Прикрепить процесс</template>
                        <transition name="fade">
                          <ProcessesListSearch
                            v-if="processesBubbleOpen"
                            :selectedId="section.ProcessId"
                            :className="'task-bubble--templates'"
                            @choose="(id) => setSection(id)"
                            @close="() => (processesBubbleOpen = false)"
                          />
                        </transition>
                      </div>
                    </div>
                    <div
                      v-if="checkUserTaskRight('delete_section')"
                      class="simple-select-list__item"
                      @click="deleteSection"
                    >
                      Удалить секцию
                    </div>
                  </div>
                </transition>
              </portal>
            </div>
          </div>
        </div>
        <div
          class="table__col table__col--relative table__col_width_50"
          v-if="!inDetailTask && !listState"
        >
          <div
            class="task-row-view"
            @click="
              section.ProcessId && setViewTypeTab(viewTypeTab === 1 ? 2 : 1)
            "
            :class="{
              'task-row-view--no-process': !section.ProcessId,
            }"
            :title="!section.ProcessId && 'Нет активного процесса'"
          >
            <button
              class="task-row-view__btn"
              :class="{ 'task-row-view__btn--active': viewTypeTab === 1 }"
              title="Стандартный"
            >
              <svg width="10" height="10" style="transform: rotate(-90deg)">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                ></use>
              </svg>
            </button>
            <button
              class="task-row-view__btn"
              :class="{ 'task-row-view__btn--active': viewTypeTab === 2 }"
              title="Канбан"
            >
              <svg width="10" height="10">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                ></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Kanban
      v-if="
        sectionTasks &&
        sectionTasks.length &&
        taskActiveProcess &&
        viewTypeTab === 2 &&
        open
      "
      :process="taskActiveProcess"
      :tasks="sectionTasks"
      :createTaskFunction="() => createTask()"
    />
    <!--    @end="$emit('changeOrder', sectionTasks)"-->
    <!--    @add="addToSection($event, section)"-->

    <draggable
      v-model="computedTasks"
      @start="dragging = true"
      class="section-tasks-draggable-zone__list"
      :group="{ name: 'table-tasks' }"
      v-else-if="viewTypeTab === 1"
      :ghostClass="'sortable-ghost'"
      :chosenClass="'sortable-chosen'"
      :dragClass="'sortable-drag'"
      draggable=".table-row_draggable"
      :disabled="listState"
    >
      <template v-if="open">
        <TableRow
          v-for="(item, index) in computedTasks"
          :key="item.Id"
          :item="item"
          :index="index"
          :level="level"
          :data-id="item.Id"
          :inDetailTask="inDetailTask"
          :selectedTaskParentData="selectedTaskParentData"
          :parentData="parentData"
          :section="section"
          :boundariesElement="boundariesElement"
          :listState="listState"
          @setTasksByDraggable="
            (parentTask, tasks, section) =>
              $emit('setTasksByDraggable', parentTask, tasks, section)
          "
          @selectItem="($event, item) => $emit('selectItem', $event, item)"
          @createSubTask="(item) => $emit('createSubTask', item)"
          @shiftSelection="($event) => $emit('shiftSelection', $event)"
          @ctrlSelection="($event) => $emit('ctrlSelection', $event)"
        />
        <div
          class="table-row_draggable table-row_draggable-fake"
          v-if="!computedTasks.length"
        ></div>
        <div class="table-row_add-task" slot="footer" v-if="!listState">
          <div
            class="task-table-row-inner-indicator"
            v-for="c in new Array(level)"
            :key="c"
          ></div>
          <div
            @mouseover.alt="showTaskNameInput = true"
            @mouseleave="showTaskNameInput = false"
          >
            <button
              @click.exact="createTask"
              @click.alt="
                showTaskNameInput = true;
                $nextTick(() => $refs.taskNameInput.focus());
              "
              v-if="!showTaskNameInput && !taskNameInputInFocus"
            >
              <span>+</span> Добавить задачу или {{ getCtrlName }} + клик
            </button>
            <input
              type="text"
              ref="taskNameInput"
              @blur="
                fastCreateTask();
                taskNameInputInFocus = false;
              "
              @focus="taskNameInputInFocus = true"
              @keypress.13.prevent="fastCreateTask"
              v-model="taskNameForCreate"
              v-else
              placeholder="Добавить задачу"
            />
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  CREATE_TASK,
  DELETE_SECTION,
  EDIT_SECTION,
  EDIT_TASK,
  GET_SECTIONS,
  GET_TASKS,
  REFRESH_SECTIONS,
  SET_LOCAL_STORAGE_SETTINGS,
} from "@/store/tasks/constants";
import { Helpers } from "@/mixins/Helpers";
import draggable from "vuedraggable";
import TableRow from "@/modules/tasks/components/Tables/MainTable/TableRow";
import * as _ from "lodash";
import ProcessesListSearch from "@/modules/tasks/components/CommonComponents/ProcessesListSearch";
import common from "@/mixins/tasks/common";
import Kanban from "@/modules/tasks/components/Kanban/Kanban";
import { taskCreateFormTemplate } from "@/modules/tasks/components/TaskCreateForm/templateData";
import { iOS } from "@/utils";

export default {
  name: "TableSection",
  props: [
    "level",
    "section",
    "sections",
    "parentData",
    "selectedTaskParentData",
    "inDetailTask",
    "parentTask",
    "draggableId",
    "currentElement",
    "sectionTasks",
    "haveNextSection",
    "boundariesElement",
    "listState",
  ],
  mixins: [Helpers, common],
  data() {
    return {
      edit: false,
      dragging: false,
      open: true,
      submenuOpen: false,
      processesBubbleOpen: false,
      showViewTypeTab: false,
      viewTypeTab: 1,
      showTaskNameInput: false,
      taskNameInputInFocus: false,
      taskNameForCreate: "",
      creatingTaskLoader: false,
    };
  },
  created() {
    const foundLocalSettings = _.find(this.localStorageSettings, {
      Id: this.section.Id,
      Type: "tableSection",
    });
    if (foundLocalSettings) {
      const settings = JSON.parse(foundLocalSettings.settings);
      this.open = settings.open !== void 0 ? settings.open : this.open;
      this.showViewTypeTab =
        settings.showViewTypeTab !== void 0
          ? settings.showViewTypeTab
          : this.showViewTypeTab;
      this.viewTypeTab =
        settings.viewTypeTab !== void 0
          ? settings.viewTypeTab
          : this.viewTypeTab;
    }
  },
  mounted() {
    if (!this.sectionTasks.length) {
      // if (this.section.Name === "Новая секция") {
      //   if (this.selectedTask) {
      //     if (!this.$el.closest(".task-container")) {
      //       this.setEditState();
      //     }
      //   } else {
      //     this.setEditState();
      //   }
      // }
      this.open = false;
    }
    if (this.section.Name === "virtualSection") {
      this.open = true;
    }
  },
  computed: {
    computedTasks: {
      get() {
        return this.section.tasks;
      },
      set(tasks) {
        // let otherParentChilds = [];
        // if (this.parentTask) {
        //   otherParentChilds = _.filter(
        //     this.parentTask.childs,
        //     (i) => !tasks.find((t) => t.Id === i.Id)
        //   );
        // }

        // if (this.inDetailTask) {
        this.section.tasks = tasks;
        // }
        this.$emit("setTasksByDraggable", this.parentTask, tasks, this.section);
      },
    },
    taskActiveProcess() {
      return _.find(this.groupProcesses, { Id: this.section.ProcessId });
    },
    isVirtualSection() {
      return this.section.Name === "virtualSection";
    },
    getCtrlName() {
      return iOS ? "option" : "ctrl";
    },
    ...mapState("tasks", [
      "localStorageSettings",
      "selectedTask",
      "groupProcesses",
      "tasks",
    ]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    update() {
      if (this.$refs.input.value !== this.section.Name) {
        this.section.Name = this.$refs.input.value;
        this[EDIT_SECTION]({
          id: this.section.Id,
          data: this.section,
        }).then(() => {
          if (this.selectedTask) {
            this[GET_TASKS]();
            this[GET_SECTIONS](this.group.Id);
          }
        });
      }
    },
    setEditState() {
      if (!this.isVirtualSection) {
        this.edit = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 100);
      }
    },
    disableEditState() {
      this.edit = false;
    },
    findTask(id) {
      let result = null;
      const recursiveFunc = (i) => {
        if (!result) {
          if (i.Id === id) return (result = i);
          else if (i.childs && i.childs.length) {
            _.each(i.childs, (i) => {
              recursiveFunc(i);
            });
          }
        }
      };
      this.tasks.forEach((i) => {
        recursiveFunc(i);
      });
      return result;
    },
    addToSection(e, section) {
      this.open = true;
      const id = e.item.getAttribute("data-id");
      const payload = {
        id: id,
        data: {
          SectionId: section.Name === "virtualSection" ? null : section.Id,
          SectionName: section.Name,
        },
      };

      const task = this.findTask(id);
      task.SectionId = payload.data.SectionId;
      task.sectionName = payload.data.SectionName;

      this[EDIT_TASK]({ id, data: task });
      // this[EDIT_TASK](payload);

      this.dragging = false;
    },
    setOpenState(val) {
      this.open = val;
      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.section.Id,
          Type: "tableSection",
          settings: {
            open: this.open,
          },
        },
        init: false,
      });
    },
    closeSubmenu() {
      this.submenuOpen = false;
      this.processesBubbleOpen = false;
    },
    createTask() {
      const SectionId = !this.isVirtualSection ? this.section.Id : null;
      const SectionName = !this.isVirtualSection
        ? this.section.Name
        : "Без секции";
      this.submenuOpen = false;
      this.parentData.sectionForTaskCreate = {
        Name: SectionName,
        Id: SectionId,
        currentElement: this.currentElement,
        inDetailTask: this.inDetailTask,
      };
      if (this.parentTask) {
        this.parentData.subTask = this.parentTask;
      }
      this.parentData.taskCreatePopupOpen = true;
      this.setOpenState(true);
    },
    deleteSection() {
      this.submenuOpen = false;
      if (this.inDetailTask) {
        this[DELETE_SECTION]({ id: this.section.Id, local: true }).then(() => {
          this.$emit("loadSectionTasks");
          this[GET_TASKS]();
          this[GET_SECTIONS](this.group.Id);
        });
      } else {
        this.parentData.page = 0;
        this[DELETE_SECTION]({ id: this.section.Id });
      }
    },
    setSection(id) {
      this.section.ProcessId = id;
      this[EDIT_SECTION]({
        id: this.section.Id,
        data: this.section,
      });
    },
    adjustLevelColor() {
      // let color = '#F9F9F9', amount = -((this.level - 1) * 10)
      // return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
      const percentage = 100 - this.level * 2;
      return `hsl(0 0% ${percentage}% / 1)`;
    },
    closeViewTab() {
      if (this.showViewTypeTab !== this.showViewTypeTab) {
        this.showViewTypeTab = false;
        this[SET_LOCAL_STORAGE_SETTINGS]({
          data: {
            Id: this.section.Id,
            Type: "tableSection",
            settings: {
              showViewTypeTab: false,
            },
          },
          init: false,
        });
      }
    },
    setShowViewTab() {
      this.showViewTypeTab = !this.showViewTypeTab;

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.section.Id,
          Type: "tableSection",
          settings: {
            showViewTypeTab: this.showViewTypeTab,
          },
        },
        init: false,
      });
    },
    setViewTypeTab(tab) {
      this.viewTypeTab = tab;

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.section.Id,
          Type: "tableSection",
          settings: {
            viewTypeTab: this.viewTypeTab,
          },
        },
        init: false,
      });
    },
    mouseleave() {
      this.edit = false;
      if (this.$refs.input) {
        this.update();
      }
    },
    fastCreateTask() {
      if (this.taskNameForCreate && !this.creatingTaskLoader) {
        this.creatingTaskLoader = true;
        const SectionId = this.section
          ? !this.isVirtualSection
            ? this.section.Id
            : null
          : null;
        const Position = this.section
          ? this.section.tasks.length + 1
          : this.item
          ? this.items.childs.length + 1
          : 1;
        const ParentId = this.parentTask
          ? this.parentTask.Id
          : this.inDetailTask
          ? this.inDetailTask.Id
          : this.item
          ? this.item.Id
          : null;
        const GroupId = this.section
          ? this.section.GroupId
          : this.item
          ? this.item.GroupId
          : null;
        const payload = {
          ...JSON.parse(
            JSON.stringify(taskCreateFormTemplate.TemplateTaskSettings)
          ),
          TaskName: this.taskNameForCreate,
          GroupId,
          SectionId,
          ParentId,
          Position,
        };
        this[CREATE_TASK]({
          form: payload,
          addCreatedTaskToChildArray: true,
        })
          .then((task) => {
            if (this.inDetailTask) {
              if (this.parentTask) {
                this.parentTask.childs.push(task);
              } else {
                this.inDetailTask.childs.push(task);
              }
              this[REFRESH_SECTIONS]();
            }
            this.showTaskNameInput = false;
            this.taskNameInputInFocus = false;
            this.taskNameForCreate = "";
          })
          .finally(() => (this.creatingTaskLoader = false));
      }
    },
    ...mapActions("tasks", [
      EDIT_SECTION,
      // EDIT_TASK,
      DELETE_SECTION,
      GET_TASKS,
      GET_SECTIONS,
      CREATE_TASK,
    ]),
    ...mapMutations("tasks", [
      EDIT_TASK,
      SET_LOCAL_STORAGE_SETTINGS,
      REFRESH_SECTIONS,
    ]),
  },
  components: {
    draggable,
    TableRow,
    ProcessesListSearch,
    Kanban,
  },
};
</script>

<style scoped></style>
