import * as _ from "lodash";

export default {
  group: (state) => {
    return state.settings.currentGroup;
  },
  users: (state) => state.users,
  serviceTemplate: (state) => {
    return _.filter(state.templates, (i) => {
      return i.TemplateName === "Сервисный шаблон";
    });
  },
  taskFilterParams(state, getters) {
    return getters.group
      ? {
          "params[GroupId]": getters.group.Id,
          ...state.settings.taskFilterParams,
        }
      : false;
  },
  selectedTask: (state) => state.selectedTask,
  taskCountNofitications: (state) =>
    state.countNotifications.countUnreadNotifications,
  settings: (state) => state.settings,
  currentUser: (state, getters, rootState) => {
    // if (rootState.common.userData && state.users.length) {
    //   return _.find(state.users, ["Id", rootState.common.userData.Id]);
    // }
    // return null;
    return rootState.common.userData;
  },
  groupActiveProcess(state, getters) {
    if (getters.group) {
      return _.find(state.groupProcesses, { Id: getters.group.MainProcessId });
    } else if (state.settings.processFromFilter) {
      return _.find(state.processes, { Id: state.settings.processFromFilter });
    }
  },
  objects: (state) => state.objects,
  selectedFilterIsUsersFilter: (state) =>
    state.tableFilterControl.filter &&
    state.tableFilterControl.filter.length > 5,
  getUserFilterIfCurrentFilterIs(state, getters) {
    if (getters.selectedFilterIsUsersFilter) {
      const commonLocalStorageSettings = JSON.parse(
        state.commonLocalStorageSettings
      );
      const filter = commonLocalStorageSettings.model;
      return filter;
    }
    return null;
  },
  commonModuleSettings: (state) => state.commonModuleSettings,
  selectedFilter: (state) => state.tableFilterControl?.filter,
  flashFilterData: (state) => state.tableFilterControl.flashFilterData,
  countForFlashFilterCheck: (state) =>
    state.tableFilterControl.countForFlashFilterCheck,
  selectedFilterName: (state) => state.tableFilterControl.filterName,
  cachedFilter: (state) => state.tableFilterControl.cachedFilter,
  allGroups: (state) => state.allGroups,
  localStorageSettings: (state) => state.localStorageSettings,
};
