import moment from "moment";
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  data() {
    return {
      staticElements: [
        // {
        //   Name: 'О компании',
        //   UniqueName: 'about_page',
        //   Value: '',
        // }
      ],
    };
  },

  methods: {},
  computed: {
    filteredItems() {
      let items = this.items;
      if (this.module === "commonTranslations" && items) {
        items = items[this.parentData.translateTableLanguage];
        if (items !== void 0) {
          return items;
        }
      } else {
        if (this.module === "blocks") {
          items = _.filter(items, (i) => {
            return i.UniqueName.substr(0, 4) === "page";
            // return i.GroupId === 'company'
          });
        }
        let dateSort = { name: false, active: false },
          columnName;
        _.each(this.sorts, (object, name) => {
          if (object.active) {
            dateSort.active = true;
            dateSort.name = name;
          }
        });
        // if (dateSort.active) {
        if (items && items.length) {
          columnName =
            dateSort.name === "Name"
              ? items[0].Name === void 0
                ? "Title"
                : "Name"
              : dateSort.name;
          return _.orderBy(
            items,
            [columnName],
            [this.sorts[dateSort.name].asc ? "asc" : "desc"]
          );
        }

        // } else {
        //   return _.orderBy(items, [this.column], [this.order])
        // }
      }
    },
    ...mapState("site", ["parentData"]),
  },
  filters: {
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(date) {
      return moment(date).format("HH:mm:ss");
    },
  },
};
