var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"html",staticClass:"scroll-style",attrs:{"id":"entry-wrap"}},[_c('div',{staticClass:"print print--1",style:({
      backgroundImage: ("url(" + (_vm.getImageSrc(
        _vm.settingsObject.json.covers[0].img
      )) + ")"),
    })},[_c('div',{staticClass:"print__small-logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo-small.svg')}})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_vm._l((_vm.pageLength),function(page,index){return _c('div',{key:index + 'page',staticClass:"pdf-cont"},[_c('div',{staticClass:"print print--19"},[_c('div',{staticClass:"print__cont pb-0"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })},[_vm._v("\n          примеры "),_c('br'),_vm._v("объектов\n        ")])]),_vm._v(" "),_c('div',{staticClass:"print__object"},_vm._l((_vm.objects.filter(function (item, index) { return _vm.limitObject(item, index, page); }
          )),function(item,index){return _c('div',{key:index,staticClass:"print__object-item"},[_c('div',{staticClass:"print__object-img"},[_c('img',{attrs:{"src":_vm.commercialSiteUrl + item.ImageLink}})]),_vm._v(" "),_c('div',{staticClass:"print__object-cont"},[_c('div',{staticClass:"print__object-title"},[_vm._v(_vm._s(item.Name))]),_vm._v(" "),_c('div',{staticClass:"print__object-address"},[_vm._v("\n              "+_vm._s(item.CityName)+". "+_vm._s(item.Address)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"print__object-list"},[_c('div',{staticClass:"print__object-l-item"},[_c('img',{staticClass:"icon icon--badge",attrs:{"src":_vm.getimgStaticSrc('sales/arrow-2.svg')}}),_vm._v("\n                "+_vm._s(item.WorksTypesText)+"\n              ")])])])])})),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v(_vm._s(index + 1))])])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"print__welcome-title"},[_vm._v("\n      Выполненные работы "),_c('br'),_vm._v("\n      по автоматизации парковочного пространства\n    ")])}]

export { render, staticRenderFns }