<template>
  <form class="profile-settings-form" @submit.prevent="save">
    <div class="profile-settings-form__input">
      <input
        type="password"
        placeholder="Пароль"
        v-model="password"
        class="input"
      />
    </div>
    <div class="profile-settings-form__input">
      <input
        type="password"
        placeholder="Пароль еще раз"
        v-model="confirm_password"
        class="input"
      />
    </div>
    <br />
    <br />
    <button class="profile-settings-form__btn btn">Сохранить</button>
  </form>
</template>

<script>
import { UPDATE_USER_PASSWORD } from "../../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import { GET_USER_DATA } from "../../../../store/common/constants";
import { mask } from "vue-the-mask";
import { showAlert } from "../../../../utils";

export default {
  name: "ProfileSettings",
  data() {
    return {
      password: "",
      confirm_password: "",
    };
  },
  created() {},
  computed: {
    ...mapState("tasks", ["userData"]),
  },
  methods: {
    save() {
      if (!this.password || !this.confirm_password)
        return showAlert("", "", "Введите пароль и подтверждение");
      if (this.password !== this.confirm_password)
        return showAlert("", "", "Пароли должны совпадать");
      if (this.password.length < 4)
        return showAlert("", "", "В пароле должно быть минимум 4 символа");

      this[UPDATE_USER_PASSWORD]({
        Password: this.password,
        PasswordConfirm: this.confirm_password,
      });
    },
    ...mapActions("tasks", [UPDATE_USER_PASSWORD]),
    ...mapActions("common", [GET_USER_DATA]),
  },
  directives: { mask },
};
</script>

<style scoped></style>
