import * as _ from "lodash";
export default {
  filteredObjects: (state) => {
    if (state.objects) {
      let filteredObjects;
      filteredObjects = _.map(state.objects.objects, (i) => {
        i.type = "object";
        return i;
      });
      filteredObjects.push(
        ..._.map(state.objects.companies, (i) => {
          i.type = "company";
          i.CompanyName = i.Name;
          return i;
        })
      );
      filteredObjects.push(
        ..._.map(state.objects.distributors, (i) => {
          i.type = "distributor";
          i.DistributorName = i.Name;
          return i;
        })
      );
      if (state.sort.name) {
        filteredObjects = _.orderBy(
          filteredObjects,
          [state.sort.name],
          [state.sort.type]
        );
      }
      filteredObjects = filteredObjects.filter((i) => {
        const re = new RegExp(state.searchInput, "gi");
        return (
          re.test(i.Name) ||
          re.test(i.CompanyName) ||
          re.test(i.DistributorName)
        );
      });
      return filteredObjects;
    }
  },
  companyId: (state) => state.selectedCompany,
  selectedCompany: (state) =>
    _.find(state.companies, ["Id", state.selectedCompany]),
  companyObjects: (state) => {
    if (state.objects && state.companies && state.selectedCompany) {
      return _.filter(state.objects.objects, [
        "CompanyID",
        state.selectedCompany,
      ]);
    }
  },
  selectedObject: (state) => state.selectedObject,
  contacts: (state) => {
    if (state.selectedObject) {
      if (!state.selectedObject.contacts) return [];
      return _.map(state.selectedObject.contacts, (i) => {
        i.Phone = typeof i.Phone === "object" ? i.Phone[0] : i.Phone;
        i.Email = typeof i.Email === "object" ? i.Email[0] : i.Email;
        return i;
      });
    } else {
      return [];
    }
  },
  distributorContacts: (state) =>
    state.selectedDistb ? state.selectedDistb.contacts || [] : [],
  distbsObjects: (state) => state.distbsObjects,
  distbsCompanies: (state) => state.distbsCompanies,
  selectedDistb: (state) => state.selectedDistb,
  getCompanyOfObject: (state) => {
    if (state.selectedObject && state.selectedObject.CompanyID) {
      return _.find(state.companies, ["Id", state.selectedObject.CompanyID]);
    } else return null;
  },
  getDistbOfObject: (state) => {
    if (state.distbsObjects && state.selectedObject) {
      const distb = _.find(state.distbsObjects, [
        "ObjectId",
        state.selectedObject.Id,
      ]);
      return distb ? _.find(state.distbs, ["Id", distb.DistributorId]) : null;
    } else return null;
  },
  getDistbOfCompany: (state) => {
    if (state.distbsCompanies && state.selectedCompany) {
      const distb = _.find(state.distbsCompanies, [
        "ClientId",
        state.selectedCompany,
      ]);
      return distb ? _.find(state.distbs, ["Id", distb.DistributorId]) : null;
    } else return null;
  },
};
