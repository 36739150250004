<template>
  <div
    class="tm-sidebar"
    :class="{ 'tm-sidebar--hidden': !commonModuleSettings.sidebarIsOpen }"
  >
    <div class="tm-sidebar__header">
      <div class="tm-sidebar-user" v-if="userData">
        <div class="tm-sidebar-user__avatar">
          <img :src="avatar" />
        </div>
        <div class="tm-sidebar-user__name">
          {{ userData.FullName | shortName }}
          <span @click="commonModuleSettings.profileSettingsPopupOpen = true"
            >Настройки</span
          >
        </div>
      </div>
      <div
        class="tm-sidebar__btn"
        @click="commonModuleSettings.sidebarIsOpen = false"
      >
        <svg width="20" height="14">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/tasks/sprite.svg#sidebar-menu-icon"
          ></use>
        </svg>
      </div>
    </div>
    <div
      class="tm-sidebar__content"
      @scroll="$refs['sidebar-filters'].showCreateFilterPopup = false"
    >
      <SidebarFilters
        ref="sidebar-filters"
        :sidebarData="sidebarData"
        :headerControlElement="headerControlElement"
        :selectedArray="selectedFiltersArray"
        :selectedGroupsAndFiltersArray="selectedGroupsAndFiltersArray"
        @selectItem="selectItem"
      >
        <SidebarGroups
          :sidebarData="sidebarData"
          :headerControlElement="headerControlElement"
          :selectedArray="selectedGroupsArray"
          @selectItem="selectItem"
        />
      </SidebarFilters>
      <button
        class="add-to-folder-btn"
        v-if="
          selectedGroupsArray.length ||
          selectedFiltersArray.length ||
          selectedGroupsAndFiltersArray.length
        "
        @click="createFolderFromSelected"
      >
        <SimplePreloader v-if="creatingFolderPreloader" />
        <div class="create-folder-btn__row">
          <div class="create-folder-btn__icon">
            <svg width="20" height="20" style="fill: #202325">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#group-folder-icon"
              ></use>
            </svg>
          </div>
          <div class="create-folder-btn__text">
            <div class="create-folder-btn__title">Объединить в группу</div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import SidebarFilters from "./SidebarFilters";
import SidebarGroups from "./SidebarGroups";
import { mapActions, mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import { API_ROOT } from "../../../../config";
import * as _ from "lodash";
import {
  ADD_ELEMENT_TO_GLOBAL_GROUP,
  ADD_TO_FAVORITES,
  CREATE_GLOBAL_GROUP,
} from "@/store/tasks/constants";

export default {
  name: "Sidebar",
  mixins: [common],
  props: ["headerControlElement"],
  data() {
    return {
      API_ROOT,
      sidebarData: {
        preparedFavoritesArray: [],
      },
      selectedGroupsArray: [],
      selectedFiltersArray: [],
      selectedGroupsAndFiltersArray: [],
      creatingFolderPreloader: false,
    };
  },
  created() {},
  computed: {
    avatar() {
      if (this.userData && this.userData.LinkToAvatar) {
        return API_ROOT + this.userData.LinkToAvatar;
      }
      return "https://tanzolymp.com/images/default-non-user-no-photo-1.jpg";
    },
    ...mapState("tasks", ["commonModuleSettings", "userData"]),
  },
  methods: {
    selectItem(item, array) {
      const arrays = [
        "selectedGroupsArray",
        "selectedFiltersArray",
        "selectedGroupsAndFiltersArray",
      ];
      arrays.forEach((arr) => {
        if (this[array] !== this[arr]) {
          this[arr] = [];
        }
      });
      if (
        !this[array].find((i) => {
          return i.Id === item.Id;
        })
      ) {
        this[array].push(item);
      } else {
        this[array] = _.reject(this[array], ["Id", item.Id]);
      }
    },
    checkItemSelect(item, array) {
      return this[array].find((i) => {
        return i.Id === item.Id;
      });
    },
    async addChildsToFolder(groupId, array, GroupType) {
      // if (GroupType === 3) {
      //   array = array.map(i => {
      //     return i
      //   })
      // }
      if (GroupType === 3) {
        const groups = array.filter((i) => +i.ItemType === 1);
        const filters = array.filter((i) => +i.ItemType === 3);
        if (groups) {
          await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
            GroupId: groupId,
            ItemType: 1,
            ItemsIds: groups.map((i) => i.ItemId),
          });
        }
        if (filters) {
          await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
            GroupId: groupId,
            ItemType: 2,
            ItemsIds: filters.map((i) => i.ItemId),
          });
        }
      } else {
        await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
          GroupId: groupId,
          ItemType: GroupType,
          ItemsIds: array.map((i) => i.Id),
        });
      }

      return true;
    },
    createFolderFromSelected() {
      this.creatingFolderPreloader = true;
      const array = this.selectedGroupsArray.length
        ? this.selectedGroupsArray
        : this.selectedFiltersArray.length
        ? this.selectedFiltersArray
        : this.selectedGroupsAndFiltersArray.length
        ? this.selectedGroupsAndFiltersArray
        : [];

      const GroupType = this.selectedGroupsArray.length
        ? 1
        : this.selectedFiltersArray.length
        ? 2
        : 3;
      this[CREATE_GLOBAL_GROUP]({
        GroupName: "Глобальная группа",
        GroupType,
      }).then((data) => {
        if (GroupType === 3) {
          this[ADD_TO_FAVORITES]({
            ItemType: 4,
            ItemId: data.Id,
          });
        }
        this.addChildsToFolder(data.Id, array, GroupType).finally(() => {
          this.selectedGroupsArray = [];
          this.selectedFiltersArray = [];
          this.selectedGroupsAndFiltersArray = [];
          this.creatingFolderPreloader = false;
        });
      });
    },
    ...mapActions("tasks", [
      CREATE_GLOBAL_GROUP,
      ADD_ELEMENT_TO_GLOBAL_GROUP,
      ADD_TO_FAVORITES,
    ]),
  },
  components: {
    SidebarFilters,
    SidebarGroups,
  },
};
</script>

<style scoped></style>
