<template>
  <div
    class="task-events__item"
    :class="{
      'task-events__item--comment':
        item.SystemName === 'tasks_add_comment' ||
        item.SystemName === 'tasks_user_mark_in_comment',
      'task-events__item--task-is-deleted':
        item.taskInfo && item.taskInfo.IsDeleted,
    }"
  >
    <div class="task-events__item-left">
      <div class="task-events__circle">
        <div class="task-events__circle-big">
          <img
            class="task-table-person__circle"
            src="/static/images/tasks/kitty.gif"
            alt=""
            v-if="item.SystemName === 'tasks_task_deadline'"
            style="object-position: left"
          />
          <img
            src="/static/images/tasks/icons/system-message-picture.png"
            alt="user avatar"
            class="task-table-person__circle"
            v-else-if="item.SystemSender"
          />
          <UserAvatar v-else :user="item.Sender" />
        </div>
        <div class="task-events__circle-small">
          <div
            class="task-events__icon task-events__icon-1"
            :style="{
              backgroundImage: `url(/img/tasks_icons/${item.SystemName}.svg)`,
            }"
          />
        </div>
      </div>
      <div class="task-events__item-time">{{ date }}</div>
    </div>
    <div class="task-events__info">
      <span class="task-events__info-label" v-if="item.localNewNotification"
        >Непрочитанное</span
      >
      <span
        class="task-events__info-label"
        v-else-if="item.localNewNotification"
        >Новое</span
      >
      <div class="task-events__info-top">
        <div class="task-events__breadcrumbs" v-if="item.taskInfo">
          <div class="task-events__breadcrumbs-shadow"></div>
          <div class="task-events__breadcrumbs-inner">
            <a v-if="item.taskInfo.GroupModel">
              {{ item.taskInfo.GroupModel.GroupName }}
            </a>
            /
            <a
              target="_blank"
              :href="'/tasks/' + task.Id"
              v-for="(task, index) in item.taskInfo.Breadcrumbs"
              :key="index"
            >
              {{ task.TaskName }} /
            </a>
          </div>
        </div>
        <template v-if="item.TaskId">
          <span class="task-events__name" @click="openTask(item)"
            ><svg
              class="task-events__deleted-icon"
              width="15"
              height="15"
              stroke="#000"
              v-if="item.taskInfo && item.taskInfo.IsDeleted"
            >
              <use
                xlink:href="/static/images/tasks/sprite.svg#trash-icon"
              ></use>
            </svg>
            {{ name }}</span
          >
        </template>

        <div class="task-events__name" v-else>{{ name }}</div>
      </div>
      <!--      <div-->
      <!--        class="task-events__labels"-->
      <!--        v-if="item.SystemName === 'tasks_add_new_tag'"-->
      <!--      >-->
      <!--        <span-->
      <!--          class="task-label"-->
      <!--          v-for="item in task.tags"-->
      <!--          :style="{ backgroundColor: item.Color }"-->
      <!--          :key="item.Id"-->
      <!--          >{{ item.TagName }}-->
      <!--        </span>-->
      <!--      </div>-->

      <div class="task-events__files" v-if="files.length">
        <div class="task-events__text">В задачу добавлены новые файлы:</div>

        <div class="task-popup-files" v-if="files.length">
          <div
            class="sup-chat-files__item"
            v-for="(item, index) in files"
            :key="index"
          >
            <span
              class="sup-chat-files__item-inner"
              v-if="fileIsImage(item.original)"
              @click="showImagePopup(item.original)"
            >
              <img :src="item.small" />
              <p>{{ item.filename }}</p>
            </span>

            <a
              class="sup-chat-files__item-inner"
              download
              target="_blank"
              :href="item.original"
              @click.prevent="
                downloadURI(getFileSrc(item.item), item.original, item.Id)
              "
              v-else
            >
              <span class="sup-chat-files__item-link">
                <img :src="getNonImageTypeFileIcon(item.original)" />
              </span>
              <p>{{ item.filename }}</p>
            </a>
          </div>
        </div>
      </div>

      <div
        class="task-events__text"
        :class="item.SystemName"
        v-else
        v-html="message"
      ></div>
    </div>
    <!--    <span class="task-events__separator-for-news">-->
    <!--      <span>Новые</span>-->
    <!--    </span>-->
  </div>
</template>

<script>
import moment from "moment";
import common from "../../../../mixins/tasks/common";
import UserAvatar from "../CommonComponents/UserAvatar";
import Api from "../../../../api/tasks";
import { GET_COUNT_NOTIFICATIONS } from "../../../../store/tasks/constants";
import { mapActions } from "vuex";

export default {
  props: ["item", "countNotifications"],
  mixins: [common],
  computed: {
    message() {
      const el = document.createElement("div");
      el.innerHTML = this.item.Message;
      const allLinks = el.querySelectorAll("a");
      allLinks.forEach((i) => {
        if (i.getAttribute("href").includes("userId=")) {
          i.classList.add("user-link");
        }
      });

      if (this.item.SystemName === "tasks_task_priority") {
        const oldPriority = el.querySelector(".old_priority").innerText;
        const newPriority = el.querySelector(".new_priority").innerText;
        el.querySelector(".old_priority").classList.add(
          this.getPriorityColor(oldPriority)
        );
        el.querySelector(".old_priority").classList.add("task-priority");
        el.querySelector(".new_priority").classList.add(
          this.getPriorityColor(newPriority)
        );
        el.querySelector(".new_priority").classList.add("task-priority");
      }

      return el.innerHTML;
    },
    name() {
      if (this.item.TaskId) {
        return this.item.taskInfo.TaskName;
      } else {
        return this.item.NotificationName;
      }
    },
    date() {
      // return this.$options.filters.formatReplyDate(this.item.DateCreate)
      return moment(this.item.DateCreate).format("HH:mm");
    },
    task() {
      return this.item.taskInfo;
    },
    files() {
      const files = this.item.Message.split("|||");
      if (files.length > 1) {
        files.shift();
        files.pop();
        try {
          const json = JSON.parse(files);
          return json;
        } catch (e) {
          return files;
        }
      }
      return [];
    },
  },
  methods: {
    openTask(item) {
      if (item.Status === "1" && item.TemplatePriority === "5") {
        Api.markOneNotificationRead(item.Id).then(() => {
          this[GET_COUNT_NOTIFICATIONS]();
          this.countNotifications.countUnreadNotifications -= 1;
          item.Status = "3";
          item.localNewNotification = false;
          !item.taskInfo.IsDeleted &&
            this.$router.push("/tasks/" + item.TaskId);
        });
      } else {
        !item.taskInfo.IsDeleted && this.$router.push("/tasks/" + item.TaskId);
      }
    },
    goToGroup() {
      // не работает
      const sidebar = this.$parent.$children[0].$children[0].$children[1];
      sidebar.changeGroup(this.item.taskInfo.GroupModel);
      this.closePopup();
    },
    ...mapActions("tasks", [GET_COUNT_NOTIFICATIONS]),
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UserAvatar,
  },
};
</script>

<style scoped></style>
