<template>
  <div class="sidebar">
    <router-link class="sidebar__logo" to="/">
      <svg>
        <use xlink:href="/static/images/icon.svg#icon_logo"></use>
      </svg>
    </router-link>
    <div class="aside-menu" v-if="rightsLoaded">
      <div class="aside-menu__bottom-block">
        <div class="aside-menu__item" @click="logout">
          <span class="aside-menu__link">
            <span
              class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--6"
            ></span>
          </span>
        </div>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(GET_OBJECTS_RIGHT) && showModule('objects')"
      >
        <router-link class="aside-menu__link" to="/" exact>
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--1"
          ></span>
          <span class="aside-menu__text">Объекты</span>
        </router-link>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(GET_SETTINGS_RIGHT) && showModule('settings')"
      >
        <router-link class="aside-menu__link" to="/settings/">
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--4"
          ></span>
          <span class="aside-menu__text">Настройки</span>
        </router-link>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(GET_SUPPORT_RIGHT) && showModule('support')"
      >
        <router-link class="aside-menu__link" to="/support">
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--5"
          ></span>
          <span class="aside-menu__text">Поддержка</span>
          <span class="aside-menu__counter" v-if="notification">{{
            notification
          }}</span>
        </router-link>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(SALE_MODULE_RIGHT) && showModule('sale')"
      >
        <router-link class="aside-menu__link" to="/sales">
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--7"
          ></span>
          <span class="aside-menu__text">Продажи</span>
        </router-link>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(WIKI_MODULE_RIGHT) && showModule('wiki')"
      >
        <router-link class="aside-menu__link" to="/wiki">
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--9"
          ></span>
          <span class="aside-menu__text">База знаний</span>
        </router-link>
      </div>
      <div
        class="aside-menu__item"
        v-if="checkUserRight(TASKS_MODULE_RIGHT) && showModule('tasks')"
      >
        <router-link class="aside-menu__link" to="/tasks">
          <span
            class="aside-menu__icon aside-menu__icon-bg aside-menu__icon--8"
          ></span>
          <span class="aside-menu__text">Задачи</span>
          <span
            class="aside-menu__counter aside-menu__counter--red"
            v-if="+taskCountNofitications"
            >{{ taskCountNofitications }}</span
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { API_ROOT } from "@/config";
import { Helpers } from "@/mixins/Helpers";
import {
  SALE_MODULE_RIGHT,
  TASKS_MODULE_RIGHT,
} from "../../../../store/sales/constants";
import {
  GET_OBJECTS_RIGHT,
  GET_SETTINGS_RIGHT,
  GET_SUPERLOG_RIGHT,
  GET_SUPPORT_RIGHT,
  GET_UPDATE_RIGHT,
} from "../../../../store/common/constants";
import { WIKI_MODULE_RIGHT } from "../../../../store/wiki/constants";
import axios from "axios";

export default {
  mixins: [Helpers],
  data() {
    return {
      SALE_MODULE_RIGHT,
      TASKS_MODULE_RIGHT,
      GET_SETTINGS_RIGHT,
      GET_SUPPORT_RIGHT,
      GET_UPDATE_RIGHT,
      GET_SUPERLOG_RIGHT,
      GET_OBJECTS_RIGHT,
      API_ROOT,
      WIKI_MODULE_RIGHT,
    };
  },
  computed: {
    ...mapState("common", ["currentRoute", "notification", "rightsLoaded"]),
    ...mapGetters("tasks", ["taskCountNofitications"]),
  },
  methods: {
    logout() {
      axios.get(API_ROOT + "api/logout").then(() => {
        (function () {
          var cookies = document.cookie.split("; ");
          for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
              var cookieBase =
                encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                d.join(".") +
                " ;path=";
              var p = location.pathname.split("/");
              document.cookie = cookieBase + "/";
              while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
              }
              d.shift();
            }
          }
        })();
        window.location.href = "/";
      });
    },
    // eslint-disable-next-line no-unused-vars
    showModule(module) {
      const host = window.location.hostname;
      if (host === "surerp.ru") {
        return !["objects", "settings", "wiki", "support"].includes(module);
      } else if (host === "sur.r-p-s.ru") {
        return !["tasks", "sale"].includes(module);
      }
      return true;
    },
  },
};
</script>
