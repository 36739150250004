<template>
  <div class="print print--12">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        Преимущества <br />Для гостя
      </div>
      <div class="print__text print__text--m-small">
        <p>{{ this.offerObject.json.AdvantagesGuest.desc }}</p>
      </div>
    </div>
    <div class="print__adv-c">
      <div class="print__adv-c--inner">
        <div class="print__adv-c--l-title">Ключевые особенности</div>
        <div
          class="print__adv-item"
          v-for="(item, index) in advantages"
          :key="index"
        >
          <div class="print__adv-icon">
            <img
              class="icon icon--badge"
              :src="getimgStaticSrc('sales/arrow-1.svg')"
            />
          </div>
          {{ item }}
        </div>
      </div>
      <div class="print__adv-c--cont">
        <div class="print__adv-c--c-title">Способы въезда гостя</div>
        <div class="print__adv-c-item">
          <div class="print__adv-c-icon">
            <img :src="getimgStaticSrc('sales/number-recognition.png')" />
          </div>
          <div class="print__adv-c-cont">Распознование номера</div>
        </div>
        <div
          class="print__adv-c-item"
          v-if="offerObject.json.System !== 'economy'"
        >
          <div class="print__adv-c-icon">
            <img :src="getimgStaticSrc('sales/invitation-qr.png')" />
          </div>
          <div class="print__adv-c-cont">Приглашение с QR-кодом</div>
        </div>
        <div class="print__adv-c-item">
          <div class="print__adv-c-icon">
            <img :src="getimgStaticSrc('sales/operator.png')" />
          </div>
          <div class="print__adv-c-cont">С помощью оператора</div>
        </div>
        <div class="print__adv-c-hint">
          При отсутствии приглашения гость может <br />
          въехать, получив карту на въезде.
        </div>
      </div>
    </div>
    <div class="print__footer">{{ this.pageNums.Total + 4 }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {},
  computed: {
    advantages() {
      return this.offerObject.json.AdvantagesGuest.advantages.split(";");
    },
    ...mapState("sales", ["offerObject", "settingsObject"]),
  },
};
</script>
