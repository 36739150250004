import { render, staticRenderFns } from "./FrontTable.vue?vue&type=template&id=d206d4d6&scoped=true&"
import script from "./FrontTable.vue?vue&type=script&lang=js&"
export * from "./FrontTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d206d4d6",
  null
  
)

export default component.exports