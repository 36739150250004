<template>
  <div class="popup event-popup popup-additional-items event-popup_active">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content" style="width: 600px">
        <div class="popup__header">
          <h2 class="popup__heading">Добавление {{ name }}</h2>
          <div class="popup__close" @click="onClose">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>

        <div class="table-spinner" v-show="preloader">
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>

        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__right">
              <div class="cm-popup-search">
                <form
                  class="search-bar search-bar_white actions-header__search-bar"
                  method="post"
                  action="#"
                  style="height: 35px"
                >
                  <button class="search-bar__button" type="submit">
                    <svg>
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/static/images/icon.svg#icon_icon-search"
                      ></use>
                    </svg>
                  </button>
                  <input
                    class="search-bar__input"
                    type="search"
                    placeholder="Поиск"
                    v-model="searchInput"
                  />
                </form>
              </div>

              <div class="table cm-table-1">
                <div class="table__header">
                  <div class="table__col table__col_width_260">
                    <p
                      class="table__header-text"
                      v-if="name === 'пользователей'"
                    >
                      Пользователь
                    </p>
                    <p class="table__header-text" v-else>Наименование</p>
                  </div>
                </div>
                <div class="table__row-wrap" v-if="items">
                  <div
                    class="table__row table__row_main"
                    :key="index"
                    v-for="(item, index) in filteredItems"
                  >
                    <div class="table__col table__col_width_360">
                      <div class="table__text-block">
                        <p class="table__text">
                          {{ item.FullName ? item.FullName : item.Name }}
                        </p>
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text"></p>
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <a
                          href="#"
                          style="margin-left: auto"
                          @click="onAttach(item.Id)"
                          class="button button_green cm-table-col-1__btn"
                          >Привязать</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-spinkit";
import * as _ from "lodash";

export default {
  props: {
    preloader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchInput: "",
    };
  },
  computed: {
    filteredItems() {
      let filteredItems = this.items
        ? _.filter(this.items, (i) =>
            new RegExp(this.searchInput, "gi").test(
              this.type === "users" ? i.FullName : i.Name
            )
          )
        : [];
      if (this.type === "Object" && filteredItems.length) {
        filteredItems = _.uniqBy(filteredItems, "Name");
      }
      return filteredItems;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onAttach(itemId) {
      this.$emit("onAttach", { itemId, type: this.type });
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onClose();
      }
    },
  },
  components: { Spinner },
};
</script>
