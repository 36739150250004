<template>
  <div :class="preloader ? 'app--load' : ''" id="app">
    <div class="layout" v-if="(is_client || login) && !staticPage">
      <nav-bar />
      <requests-sidebar
        v-if="currentRoute === '/support/' || currentRoute === '/support'"
        :parentData="parentData"
      />
      <div class="layout__right layout__right_scrollless">
        <app-header :parentData="parentData" />
        <router-view v-bind="routeProps" />
      </div>
      <popup-image :is_open="popupImage" />
    </div>
    <div class="static-page" v-else-if="staticPage">
      <router-view />
    </div>
    <notifications group="common" position="top right" />
    <Login v-if="!is_client && !login && !userId && !staticPage" />
    <portal-target name="users" style="z-index: 1001"></portal-target>
    <portal-target name="process-status-control"></portal-target>

    <portal-target name="common" multiple></portal-target>
    <portal-target name="no-multiple-portal"></portal-target>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as commonTypes from "@/store/common/constants";
import NavBar from "@/modules/common/navbar";
import Header from "@/modules/common/header";
import PopupImage from "@/modules/common/popupImage";
import Login from "@/modules/common/login";
import { getCookie } from "@/utils";
import { GET_COUNT_NOTIFICATIONS_BY_INTERVAL } from "./store/tasks/constants";
import { CHECK_GUEST } from "@/store/common/constants";
import RequestsSidebar from "./modules/support/components/rps/RequestsSidebar";

export default {
  data() {
    return {
      userId: getCookie("UserId"),
      staticPage: false,
      parentData: {
        sidebarIsOpen: true,
        profileSettingsPopupOpen: false,
      },
    };
  },
  created() {
    if (this.$route.name === "wikiShare") this.staticPage = true;
    // <--Check auth |____
    // ||-------------------------------------------------
    // client

    if (!this.staticPage) {
      if (getCookie("RoleName")) {
        if (getCookie("UserAccess")) {
          this[commonTypes.SET_CLIENT_AUTH](false);
        } else {
          this[commonTypes.SET_CLIENT_AUTH](true);
        }
        // if (getCookie('RoleName') === 'Оператор+поддержки') {
        //   this[commonTypes.SET_CLIENT_AUTH](false)
        // } else {
        //   this[commonTypes.SET_CLIENT_AUTH](true)
        // }
      }
      // rpc
      if (getCookie("UserId")) {
        this[commonTypes.GET_USER](getCookie("UserId"));
        this[commonTypes.GET_USER_DATA](getCookie("UserId")).then(() => {
          if (!window.location.href.includes("clientsupport")) {
            this[GET_COUNT_NOTIFICATIONS_BY_INTERVAL]();
            setTimeout(() => {
              this[CHECK_GUEST]().then((res) => {
                if (res.data.isGuest) {
                  this.logout();
                }
              });
            }, 1000);
          }
        });
        // if (!this.is_client) {
        // }
      }
    }

    this[commonTypes.SET_CURRENT_ROUTE](window.location.pathname);

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    // ||-----------------------------------------------/>
  },
  computed: {
    ...mapState("common", [
      "preloader",
      "popupImage",
      "is_client",
      "login",
      "currentRoute",
    ]),
    ...mapGetters([]),
    routeProps() {
      if (this.$route.name === "rps-support") {
        return { parentData: this.parentData };
      }
      return null;
    },
  },
  methods: {
    logout() {
      setTimeout(() => {
        document.cookie.split(";").forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });
        window.location.href = "/";
      }, 1000);
    },
    ...mapActions("clientSupport", []),
    ...mapActions("common", [
      commonTypes.SET_CURRENT_ROUTE,
      commonTypes.TOGGLE_PRELOADER,
      commonTypes.SET_CLIENT_AUTH,
      commonTypes.GET_USER,
      commonTypes.GET_USER_DATA,
      CHECK_GUEST,
    ]),
    ...mapActions("tasks", [GET_COUNT_NOTIFICATIONS_BY_INTERVAL]),
  },
  watch: {
    $route() {
      this[commonTypes.SET_CURRENT_ROUTE](window.location.pathname);
    },
  },
  components: {
    NavBar,
    "app-header": Header,
    PopupImage,
    Login,
    RequestsSidebar,
  },
};
</script>
<style src="./assets/styles/vuetify.min.css"></style>
<style src="./assets/styles/main.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!--<style src="/public/static/js/vue2-datepicker/index.css"></style>-->
