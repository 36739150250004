<template>
  <div class="create-folder-control" v-click-outside="close">
    <div class="create-folder-inner-wrapper">
      <div class="create-folder-btn">
        <div
          class="create-folder-btn__row"
          @click="
            () => {
              if (groupSelectPopupOpen) {
                closeGroupSelectPopup();
              } else {
                openSelectGroupPopup();
              }
            }
          "
        >
          <div class="create-folder-btn__icon">
            <svg width="20" height="20" :style="{ fill: '#202325' }">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#group-folder-icon"
              ></use>
            </svg>
          </div>
          <div class="create-folder-btn__text">
            <div class="create-folder-btn__title">Группу папок</div>
            <div class="create-folder-btn__subtitle">
              Содержит несколько папок
            </div>
          </div>
        </div>
        <div
          class="create-folder-btn__row"
          @click="
            $emit('createGroup');
            close();
          "
        >
          <div class="create-folder-btn__icon">
            <svg width="17" height="17" :style="{ fill: '#202325' }">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#folder-icon"
              ></use>
            </svg>
          </div>
          <div class="create-folder-btn__text">
            <div class="create-folder-btn__title">Папку</div>
            <div class="create-folder-btn__subtitle">Содержит списки задач</div>
          </div>
        </div>
      </div>
      <FolderGroupsList
        :preloader="preloader"
        :selected="selected"
        @select="select"
        @close="closeGroupSelectPopup"
        @createFolder="createFolder"
        v-if="groupSelectPopupOpen"
        :parentData="parentData"
      />
    </div>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import { mapActions } from "vuex";
import {
  ADD_ELEMENT_TO_GLOBAL_GROUP,
  CREATE_GLOBAL_GROUP,
} from "@/store/tasks/constants";
import FolderGroupsList from "@/modules/tasks/components/Sidebar/FolderGroupsList";

export default {
  name: "CreateFolderAndGroupControl",
  mixins: [Helpers],
  data() {
    return {
      input: "",
      selected: [],
      groupSelectPopupOpen: false,
      preloader: false,
      parentData: {
        name: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    select(id) {
      if (this.selected.includes(id)) {
        this.selected.splice(this.selected.indexOf(id), 1);
      } else {
        this.selected.push(id);
      }
    },
    closeGroupSelectPopup() {
      this.input = "";
      this.selected = [];
      this.groupSelectPopupOpen = false;
    },
    openSelectGroupPopup() {
      this.groupSelectPopupOpen = true;
    },
    async addChildsToFolder(groupId, array) {
      await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
        GroupId: groupId,
        ItemType: 1,
        ItemsIds: array.map((i) => i.Id),
      });
      return true;
    },
    createFolder() {
      this.preloader = true;
      this[CREATE_GLOBAL_GROUP]({
        GroupName: this.parentData.name
          ? this.parentData.name
          : "Глобальная группа",
        GroupType: 1,
      })
        .then((data) => {
          this.addChildsToFolder(data.Id, this.selected).finally(() => {
            this.close();
          });
        })
        .finally(() => this.close());
    },
    ...mapActions("tasks", [CREATE_GLOBAL_GROUP, ADD_ELEMENT_TO_GLOBAL_GROUP]),
  },
  components: {
    FolderGroupsList,
  },
};
</script>

<style scoped></style>
