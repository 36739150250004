import { API_ROOT } from "../../config";
import draggable from "vuedraggable";
import Api from "@/api/commercial_site";
import { VueEditor } from "vue2-editor";
import moment from "moment";
import { showAlert } from "../../utils";
// import DatePicker from 'vue2-datepicker'
import translationTabs from "@/modules/commercial_site/components/translation-tabs";
import translateField from "@/modules/commercial_site/components/translate-field";
import { mapState, mapMutations } from "vuex";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { translatedFieldsRefs } from "@/mixins/site/CommercialSiteSettings";
import Multiselect from "vue-multiselect";
import * as _ from "lodash";
import { editorToolBar, getTranslation } from "./CommercialSiteSettings";
import * as c from "../../store/commercial_site/constants";
import axios from "axios";

const saveElement = (state, method) => {
  const formData = new FormData();
  state.$emit("setPreloader", true);
  document.querySelector("#app").classList.add("app--load");
  let DateCreate, ObjectDate;
  if (!state.fields.DateCreate) {
    DateCreate = moment().format("YYYYMMDD HH:mm:ss");
  } else {
    DateCreate = moment(state.fields.DateCreate).format("YYYYMMDD HH:mm:ss");
  }
  if (!state.fields.ObjectDate) {
    ObjectDate = moment().format("YYYYMMDD HH:mm:ss");
  } else {
    ObjectDate = moment(state.fields.ObjectDate).format("YYYYMMDD HH:mm:ss");
  }
  formData.append("ObjectDate", ObjectDate);
  formData.append("DateCreate", DateCreate);

  if (method === "edit") {
    formData.append("_method", "PATCH");
  }
  // if (state.module === 'blocks') {
  //   if (!fields.Name)
  // }
  if (state.files.length) {
    if (state.files.length > 1) {
      _.each(state.files, (i, index) =>
        formData.append("files[" + index + "]", i.file, i.file.name)
      );
    } else {
      formData.append("image", state.files[0].file, state.files[0].file.name);
    }
  }
  if (state.additionalFiles.length) {
    formData.append(
      "imageMain",
      state.additionalFiles[0].file,
      state.additionalFiles[0].file.name
    );
  }
  if (state.additionalImages.length) {
    formData.append(
      "ImageLink",
      state.additionalImages[0].file,
      state.additionalImages[0].file.name
    );
  }
  if (state.additionalImages2.length) {
    formData.append(
      "AnotherImageLink",
      state.additionalImages2[0].file,
      state.additionalImages2[0].file.name
    );
  }
  if (state.additionalSolutionsFiles.length) {
    formData.append(
      "imageForCategory",
      state.additionalSolutionsFiles[0].file,
      state.additionalSolutionsFiles[0].file.name
    );
  }
  if (state.pdfFiles.length) {
    formData.append(
      "PdfLink",
      state.pdfFiles[0].file,
      state.pdfFiles[0].file.name
    );
  }
  if (state.sliderFiles.length) {
    if (state.sliderFiles.length > 0) {
      const slides = _.filter(state.sliderFiles, (i) => i.file !== void 0);
      if (slides.length) {
        // _.each(slides, (i, index) => formData.append('sliderImages[' + index + ']', i.file, i.file.name))
        _.each(slides, (i) =>
          formData.append("sliderImages[]", i.file, i.file.name)
        );
      }
    }
  }
  if (state.fields.Characteristics !== void 0) {
    formData.append(
      "Characteristics",
      JSON.stringify(state.fields.Characteristics)
    );
  }
  if (state.fields.WorksNumbers !== void 0) {
    _.each(state.fields.WorksNumbers, (i) => {
      formData.append("WorksNumbers[]", JSON.stringify(i));
    });
  }
  // if (state.fields.FunctionalOptions !== void 0) {
  //   formData.append('FunctionalOptions', JSON.stringify(state.fields.FunctionalOptions))
  // }
  if (state.fields.Tasks !== void 0 && state.fields.Tasks.length) {
    _.each(state.fields.Tasks, (i) =>
      formData.append("Tasks[]", JSON.stringify(i))
    );
  }
  if (state.fields.WorksTypes !== void 0 && state.fields.WorksTypes.length) {
    _.each(state.fields.WorksTypes, (i) => formData.append("WorksTypes[]", i));
  }
  if (state.fields.DevicesIds !== void 0 && state.fields.DevicesIds.length) {
    _.each(state.fields.DevicesIds, (i) => formData.append("DevicesIds[]", i));
  }
  if (
    state.fields.WorksDevices !== void 0 &&
    state.fields.WorksDevices.length
  ) {
    _.each(state.fields.WorksDevices, (i) =>
      formData.append("WorksDevices[]", i)
    );
  }
  _.each(state.fields, (value, key) => {
    if (value === null) value = "";
    if (
      ![
        "WorksTypes",
        "Tasks",
        "Characteristics",
        "DevicesIds",
        "WorksDevices",
        "WorksNumbers",
        "sliderImages",
        "FunctionalImages",
        "DateCreate",
        "ObjectDate",
        "ImageLink",
        "AnotherImageLink",
        "ImageLinkMainPage",
      ].includes(key)
    ) {
      formData.append(key, value);
    }
  });
  const moduleName =
    state.module.substr(0, 9) === "blocks_of" ? "blocks" : state.module;
  const params =
    method === "create"
      ? [moduleName, formData]
      : [moduleName, state.id, formData];
  Api[method](...params)
    .then((res) => {
      if (res.data.status === "success") {
        showAlert("success", "Данные успешно сохранены");

        let additionalDataForGetItems = null;

        if (
          ["solutions", "production"].includes(state.module) &&
          method === "create"
        ) {
          additionalDataForGetItems = {
            action: "reopen",
            name: state.fields.Name,
          };
        }
        // if (!additionalDataForGetItems && closePopup) {
        //   state.$emit('closePopup')
        // }
        if (method === "create") {
          state.$emit("closePopup");
        }
        if (state.module === "regions" && method === "create") {
          if (state.fields.districts && state.fields.districts.length) {
            const arr = [];
            const formData = new FormData();
            _.each(state.fields.districts, (i) => {
              arr.push(i.Id);
              formData.append("DistrictsIds[]", i.Id);
            });
            if (arr.length) {
              formData.append("RegionId", res.data.entityModel.Id);
              axios
                .post(
                  API_ROOT + "api/site/regions/addDistrictsToRegion",
                  formData
                )
                .then(() => {
                  if (res.data.status !== "success") {
                    showAlert(res.data.status, res.data.message);
                  }
                  console.log("get items");
                  state.$emit("getItems", additionalDataForGetItems);
                });
            }
          } else {
            state.$emit("getItems", additionalDataForGetItems);
          }
        } else {
          if (
            state.module !== "blocks" ||
            (state.module === "blocks" &&
              state.fields.UniqueName !== "page_contacts")
          ) {
            state.$emit("getItems", additionalDataForGetItems);
          }
        }

        // if (state.module === 'blocks') {
        //   if (state.fields.UniqueName === 'page_contacts') {
        //     _.each(['contactsPageAddress', 'contactsPagePhone1',
        //       'contactsPagePhone2', 'contactsPageEmail1',
        //       'contactsPageEmail2', 'contactsPageDesc1',
        //       'contactsPageDesc2'
        //     ], i => {
        //         state.getTranslation('SiteBlocks', state[i].Id)
        //     })
        //   }
        // }
      } else {
        showAlert("error", res.data.message);
      }
    })
    .catch((err) => {
      showAlert("error", "Ошибка", err.message);
    })
    .finally(() => {
      state.$emit("setPreloader", false);
      document.querySelector("#app").classList.remove("app--load");
    });
};

const slugify = require("@sindresorhus/slugify");

export default {
  props: ["data", "tab", "id", "module", "preloader", "items"],
  data() {
    return {
      fields: {},
      files: [],
      additionalImages: [],
      additionalImages2: [],
      additionalFiles: [],
      additionalSolutionsFiles: [],
      pdfFiles: [],
      sliderFiles: [],
      customToolbar: editorToolBar,
      translation: {},
      editor: DecoupledEditor,
      editorData: "<p>Rich-text editor content.</p>",
      editorConfig: {
        // The configuration of the rich-text editor.
      },
    };
  },
  created() {
    this.initFields();
  },
  watch: {
    data() {
      this.initFields();
    },
  },
  computed: {
    ...mapState("site", ["workTypeItems", "cities", "parentData", "baseUrl"]),
  },
  methods: {
    save(closePopup = true) {
      if (this.module === "production") {
        if (this.fields.CategoryId === void 0 || !this.fields.CategoryId) {
          showAlert("error", "Ошибка", "Нужно выбрать категорию");
          return false;
        }
      }
      if (this.module === "BlogPosts") {
        if (this.fields.CategoryId === void 0 || !this.fields.CategoryId) {
          showAlert("error", "Ошибка", "Нужно выбрать категорию");
          return false;
        }
      }
      if (this.module === "examples") {
        if (
          (this.fields.CityId === void 0 || !this.fields.CityId) &&
          (this.fields.CityName === void 0 || !this.fields.CityName)
        ) {
          showAlert("error", "Ошибка", "Нужно выбрать город");
          return false;
        }
        if (+this.fields.ShowMainPage) {
          if (!this.additionalFiles.length && !this.fields.ImageLinkMainPage) {
            showAlert(
              "error",
              "Ошибка",
              "Нужно загрузить изображение для главной страницы"
            );
            return false;
          }
          if (!this.fields.SolutionId) {
            showAlert(
              "error",
              "Ошибка",
              "Нужно установить ссылку на страницу решения"
            );
            return false;
          }
          let can = true;
          _.each(this.fields.WorksNumbers, (i) => {
            if (!i.NumberValue && !i.NumberName) {
              can = false;
            }
          });
          if (!can) {
            showAlert(
              "error",
              "Ошибка",
              'Пожалуйста заполните блок "числа для главной"'
            );
            return false;
          }
        }
      }
      switch (this.id) {
        case "create":
          saveElement(this, "create");
          break;
        default:
          saveElement(this, "edit", closePopup);
          break;
      }
      translatedFieldsRefs.forEach((i) => {
        if (this.$refs["translated-field-" + i]) {
          if (["TaskText", "TaskName"].includes(i)) {
            this.$refs["translated-field-" + i].forEach((o) => {
              o.save();
            });
          } else {
            this.$refs["translated-field-" + i].save();
          }
        }
      });
    },
    makeSlug() {
      this.fields.Uri = slugify(
        this.fields.Name ? this.fields.Name : this.fields.Title
      );
    },
    checkFileExt(fileName) {
      const imagesExts = ["jpeg", "jpg", "png", "bmp", "gif"];
      const ext = fileName.split(".").pop();
      if (imagesExts.includes(ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    filesChange(fileList, fileArray = "files") {
      if (!fileList.length) return;
      this[fileArray] = [];
      Array.from(Array(fileList.length).keys()).map((x) => {
        this[fileArray].push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    singleFileChange(fileList, fieldNameToChange) {
      const file = {
        file: fileList[0],
        name: fileList[0].name,
        img: window.URL.createObjectURL(fileList[0]),
        id: Math.random().toString(36).substring(7),
      };
      if (fieldNameToChange) {
        this[fieldNameToChange] = file;
      }
      return file;
    },
    filesChangePdf(e) {
      const fileList = e.target.files;
      if (!fileList.length) return;
      const ext = fileList[0].name.split(".").pop();
      if (ext !== "pdf") {
        showAlert("error", "ошибка", "это поле под pdf файл");
        e.target.value = null;
        return false;
      }
      this.pdfFiles = [];
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.pdfFiles.push({
          file: fileList[x],
          name: fileList[x].name,
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    filesSliderChange(fileList) {
      if (!fileList.length) return;
      // this.sliderFiles = []
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.sliderFiles.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          Id: Math.random().toString(36).substring(7),
        });
      });
      // console.log('!!!!!!', this.sliderFiles.length)
      // this.save(false)
    },
    removeSliderFile(Id) {
      const slide = _.find(this.sliderFiles, { Id });
      if (slide && slide.img !== void 0) {
        this.sliderFiles = _.reject(this.sliderFiles, { Id });
      } else {
        // console.log(slide, slide.img)
        this.$emit("setPreloader", true);

        Api.removeSlide(this.module, Id)
          .then((res) => {
            if (res.data.status === "success") {
              this.sliderFiles = _.reject(this.sliderFiles, { Id });
              // const newItems = _.map(this.items, i => {
              //   console.log(2222, i.Id === this.fields.Id, i.Id ,this.fields.Id)
              //   if (i.Id === this.fields.Id) {
              //     console.log(this.sliderFiles)
              //     i.sliderFiles = JSON.parse(JSON.stringify(this.sliderFiles))
              //   }
              //   return i
              // })
              // console.log(111111, newItems)
              // this.$emit('changeItems', newItems)
              this.$emit("getItems", { manualPreloader: true });
            } else {
              showAlert("error", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            // this.$emit('setPreloader', false)
          });
      }
    },
    changeSliderOrder() {
      const blobSlide = _.find(this.sliderFiles, (i) => i.file !== void 0);
      if (!blobSlide) {
        this.$emit("setPreloader", true);
        Api.updateSlideOrder(this.module, this.sliderFiles)
          .then((res) => {
            if (res.data.status === "success") {
              this.$emit("getItems");
            } else {
              showAlert("error", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            this.$emit("setPreloader", false);
          });
      }
    },
    initFields() {
      if (this.data) {
        this.fields = this.data;
      } else {
        // slug
        if (
          [
            "news",
            "solutions",
            "services",
            "examples",
            "production",
            "staticPages",
          ].includes(this.module)
        ) {
          this.fields = { ...this.fields, Name: "", Uri: "", Status: "1" };
        }
        if (["news"].includes(this.module)) {
          this.fields = { ...this.fields, NewsType: "1" };
        }
        // if (this.module === 'production') {
        //   this.fields = {
        //     ...this.fields,
        //     FunctionalOptions: { base: '', options: '' }
        //   }
        // }
        if (this.module === "blocks_of_company") {
          this.fields = {
            ...this.fields,
            GroupId: "company",
          };
        }
        if (["examples"].includes(this.module)) {
          this.fields = {
            ...this.fields,
            WorksNumbers: [
              { NumberName: "", NumberValue: "" },
              { NumberName: "", NumberValue: "" },
              { NumberName: "", NumberValue: "" },
            ],
          };
        }
      }
      // for sliders and maps
      if (this.data && ["examples"].includes(this.module)) {
        if (this.fields.sliderImages.length) {
          // console.log('INIT sliderFiles',this.sliderFiles.length, this.fields.sliderImages.length)
          this.sliderFiles = this.fields.sliderImages;
          this.$emit("setPreloader", false);

          // this.sliderFiles = _.orderBy(this.fields.sliderImages, ['Position'], ['asc'])
        }
      }

      //
      if (this.data && ["production"].includes(this.module)) {
        if (this.fields.Characteristics) {
          this.fields.Characteristics = JSON.parse(this.fields.Characteristics);
        }
        // if (this.fields.FunctionalOptions) {
        //   this.fields.FunctionalOptions = JSON.parse(this.fields.FunctionalOptions)
        // }
      }
      if (this.data && ["examples"].includes(this.module)) {
        if (!this.fields.WorksNumbers.length) {
          this.fields = {
            ...this.fields,
            WorksNumbers: [
              { NumberName: "", NumberValue: "" },
              { NumberName: "", NumberValue: "" },
              { NumberName: "", NumberValue: "" },
            ],
          };
        }
      }
      // get translations
      if (this.data) {
        this.getTranslation();
      }
    },
    getTranslation(moduleName = false, id = false, lastFetch = false) {
      return getTranslation(this, moduleName, id, lastFetch);
    },
    getImgSrc(src, blob, path = "") {
      if (blob) {
        return blob;
      } else {
        return this.baseUrl + path + src;
      }
    },
    onReady(editor) {
      if (!document.querySelector(".ck-toolbar")) {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
      }
    },
    validateUrl() {
      this.fields.Uri = this.fields.Uri.trim().replace(" ", "");
    },
    ...mapMutations("site", [c.CHANGE_BLOCKS_ARRAY, c.CHANGE_CITIES_ARRAY]),
  },
  directives: {
    tinymce: {
      inserted: function (el, binding, vnode) {
        // eslint-disable-next-line no-undef
        tinymce.init({
          target: el,
          plugins:
            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons responsivefilemanager ",
          menubar: "file edit view insert format tools table help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          toolbar_sticky: true,
          height: 400,
          image_advtab: true,
          external_filemanager_path: "/filemanager/",
          filemanager_title: "Responsive Filemanager",
          filemanager_access_key: "1vhvHG8ZSitKn3tK",
          relative_urls: true,
          external_plugins: {
            filemanager: "/static/js/filemanager/plugin.min.js",
          },
          init_instance_callback: function (editor) {
            editor.on("blur", function () {
              vnode.context.fields[
                el.getAttribute("field")
              ] = editor.getContent();
            });
          },
        });
      },
      unbind() {
        // eslint-disable-next-line no-undef
        tinymce.remove();
      },
    },
  },
  components: {
    VueEditor,
    // DatePicker,
    Multiselect,
    draggable,
    translationTabs,
    translateField,
  },
};
