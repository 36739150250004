import * as _ from "lodash";

export default {
  countInterval: (state) => state.countInterval,
  activeTab: (state) => _.findKey(state.activeTabs, (i) => i === true),
  count: (state) => state.count,
  canLoadRequestList: (state) => state.canLoadRequestList,
  requests: (state) => state.requests,
  filteredRequests: (state, getters) => {
    let requests = state.requests[getters.activeTab].filter((i) => {
      const re = new RegExp(state.searchInput, "gi");
      return (
        re.test(i.Subject) ||
        re.test(i.Id) ||
        re.test(i.Object) ||
        re.test(i.distributorName)
      );
    });

    if (state.commonSettings.tagsForSearch.length) {
      _.each(state.commonSettings.tagsForSearch, (tag) => {
        requests = _.filter(requests, (req) => {
          return _.find(req.tags, ["TagId", tag.Id]);
        });
      });
    }

    if (state.commonSettings.showWithTasks) {
      requests = _.filter(requests, (req) => req.tasks && req.tasks.length);
    }

    if (getters.activeTab === "closed") {
      requests = { false: requests };
    } else {
      requests = _.groupBy(requests, (i) => {
        return i.DeadLineMinutes !== null && i.DeadLineMinutes <= 15;
      });
    }
    if (requests.true && requests.true.length) {
      requests.true = _.orderBy(
        requests.true,
        ["LastMessageTime", "AnswerDeadline"],
        ["desc", "desc"]
      );
    }
    if (requests.false && requests.false.length) {
      // requests.false = _.orderBy(
      //   requests.false,
      //   [state.sort],
      //   [state.sort === "AnswerDeadline" ? "asc" : "desc"]
      // );
    }

    return requests;
  },
  selectedRequest: (state) => state.selectedRequest,
  messages: (state) => {
    return state.messages;
    // return _.orderBy(state.messages, ['Id'], ['desc'])
  },
  canRequestUpdate: (state) => state.canRequestUpdate,
  requestFiles: (state) => {
    if (state.messages && state.messages.length) {
      const messages = state.messages.filter((i) => i.Files.length);
      let files = [];
      messages.forEach((item) =>
        item.Files.forEach((file) => files.push(file))
      );
      return files;
    } else {
      return [];
    }
  },
  requestDescriptionFiles: (state) => {
    if (state.messages && state.messages.length) {
      let files = _.find(state.messages, (i) => i.CreatedBy === "Описание");
      files = files === void 0 ? [] : files.Files;
      return files;
    } else {
      return [];
    }
  },
  commonSettings: (state) => state.commonSettings,
  page: (state) => state.page,
};
