import common from "./common";
import { mapMutations, mapState } from "vuex";
import * as c from "../../store/tasks/constants";

export default {
  mixins: [common],
  data() {
    return {
      watchKeyUp: false,
    };
  },
  computed: {
    ...mapState("tasks", ["settings"]),
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    watchEditorSelection(range) {
      // const quill = this.$refs.editor.quill;
      // if (range) {
      //   let [leaf, offset] = quill.getLeaf(range.index);
      //   if (leaf && leaf.parent.domNode.tagName === "A") {
      //     if (leaf.parent.domNode.getAttribute("href").includes("userId=")) {
      //       quill.setSelection(range.index + leaf.text.length - offset, 0);
      //     }
      //   }
      // }
    },
    watchForSubscribe(delta) {
      // console.log(delta)
      if (!this.watchKeyUp) {
        if (delta.ops.length >= 2 && delta.ops[0].retain === void 0)
          return false;
        const quill = this.$refs.editor.quill;
        const char =
          delta.ops.length >= 2 ? delta.ops[1].insert : delta.ops[0].insert;
        const retain = delta.ops.length >= 2 ? delta.ops[0].retain : 0;
        const [leaf] = quill.getLeaf(retain);
        if (leaf && !leaf.text) {
          this[c.RESET_SUBSCRIBERS_BUBBLE]();
        }

        if (leaf && leaf.parent.domNode.tagName === "SPAN") {
          if (leaf.text.includes("@")) {
            this.settings.textBubbleChoords.search = leaf.text.split("@").pop();
          }
          if ([" ", "@"].includes(char)) {
            this[c.RESET_SUBSCRIBERS_BUBBLE]();
          }
        }
        if (char === "@") {
          this.watchKeyUp = true;
          var deltaOps = [
            { retain: retain + 1 },
            { insert: "@", attributes: { color: "inherit" } },
          ];
          quill.updateContents(deltaOps);
          quill.setSelection(retain + 2, 0);
          quill.deleteText(retain, 1);
          const bodyRect = document.body.getBoundingClientRect(),
            elemRect = leaf.parent.domNode.getBoundingClientRect(),
            offsetTop = elemRect.top - bodyRect.top,
            offsetLeft = elemRect.left - bodyRect.left;
          let preparedOffsetTop = offsetTop + 20;
          let className = null;
          if (quill.container.closest(".control-comment-state")) {
            className = "control-comment-state";
            preparedOffsetTop -= 20;
          }
          if (quill.container.closest(".create-form-state")) {
            className = "create-form-state";
          }
          this.settings.textBubbleChoords = {
            left: offsetLeft + "px",
            top: preparedOffsetTop + "px",
            isOpen: true,
            el: this.$refs.editor.quill,
            retain,
            className,
            search: "",
          };
          setTimeout(() => {
            this.watchKeyUp = false;
          }, 200);
        }
      }
      this.watchLinkType(delta);
    },
    watchLinkType(delta) {
      function isWhitespace(ch) {
        var whiteSpace = false;
        if (ch == " " || ch == "\t" || ch == "\n") {
          whiteSpace = true;
        }
        return whiteSpace;
      }
      var regex = /https?:\/\/[^\s]+$/;
      if (
        delta.ops.length === 2 &&
        delta.ops[0].retain &&
        isWhitespace(delta.ops[1].insert)
      ) {
        var endRetain = delta.ops[0].retain;
        var text = this.$refs["editor"].quill.getText().substr(0, endRetain);
        var match = text.match(regex);

        if (match !== null) {
          var url = match[0];

          var ops = [];
          if (endRetain > url.length) {
            ops.push({ retain: endRetain - url.length });
          }

          ops = ops.concat([
            { delete: url.length },
            { insert: url, attributes: { link: url } },
          ]);

          this.$refs["editor"].quill.updateContents({
            ops: ops,
          });
        }
      }
    },
    ...mapMutations("tasks", [c.RESET_SUBSCRIBERS_BUBBLE]),
  },
};
