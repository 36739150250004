import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as _ from "lodash";
import moment from "moment";
import { VueEditor } from "vue2-editor";
import { API_ROOT } from "../../config";
import * as commonTypes from "../../store/common/constants";
import { mask } from "vue-the-mask";
import Multiselect from "vue-multiselect";

import {
  GET_TASK_BY_FLASH_FILTER,
  GET_TASKS,
  GET_TASKS_BY_STANDART_FILTER,
  GET_TASKS_BY_USER_FILTER,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_SORTS,
} from "@/store/tasks/constants";
import { sortsTemplate } from "@/modules/tasks/components/utils";

export default {
  data() {
    return {
      customToolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { list: "ordered" },
          { list: "bullet" },
        ],
      ],
      customToolbarWithLink: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "link",
          { list: "ordered" },
          { list: "bullet" },
        ],
      ],
    };
  },
  computed: {
    ...mapState("tasks", [
      "users",
      "objects",
      "gallery",
      "groupProcesses",
      "activeRights",
      "isAdmin",
      "settings",
      "commonModuleSettings",
    ]),
    ...mapGetters("tasks", ["selectedFilter", "flashFilterData"]),
  },
  methods: {
    getUser(Id, arr) {
      return _.find(arr || this.users, { Id });
    },
    getPersonNameById(Id) {
      const foundPerson = _.find(this.users, { Id });
      if (foundPerson) {
        return foundPerson.FullName;
      } else {
        return "";
      }
    },
    getObjectAddressById(Id) {
      const foundObject = _.find(this.objects, { Id });
      if (foundObject) {
        return foundObject.Address
          ? foundObject.Address
          : "Адрес объекта не указан";
      }
    },
    getObjectGeoById(Id) {
      const foundObject = _.find(this.objects, { Id });
      console.log(foundObject);
      if (foundObject) {
        return [foundObject.Latitude, foundObject.Longitude];
      }
    },
    getObjectById(Id) {
      const foundObject = _.find(this.objects, { Id });
      return foundObject || {};
    },
    getFileSrc(link) {
      return API_ROOT.substring(0, API_ROOT.length - 1) + link;
    },
    getThumbnailSrc(item) {
      const link = item.linkToSmallFile
        ? item.linkToSmallFile
        : item.linkToFile;
      return this.getFileSrc(link);
    },
    showImagePopup(link) {
      link = link.includes("http") ? link : API_ROOT + link;
      this[commonTypes.TOGGLE_POPUP_IMAGE](link);
    },
    getNonImageTypeFileIcon(filename, client = false) {
      const ext = filename.split(".").pop().toLowerCase();
      return client && process.env.NODE_ENV !== "development"
        ? "/clientsupport/static/images/tasks/types/" + ext + ".svg"
        : "/static/images/tasks/types/" + ext + ".svg";
    },
    fileIsImage(fileName) {
      const imagesExts = ["jpeg", "jpg", "png", "bmp", "gif"];
      const ext = fileName.split(".").pop();
      if (imagesExts.includes(ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    getPriorityColor(num) {
      return "task-priority--" + num;
    },
    showGalleryImage(name, files) {
      let arr = _.filter(files, (i) => {
        return this.fileIsImage(i.Filename);
      });
      arr = _.map(arr, (i) => {
        return this.getFileSrc(i.linkToFile);
      });
      this.gallery.images = _.map(arr, (i) => {
        return i;
        // return {
        //   imageUrl: i,
        //   caption: ''
        // }
      });
      this.gallery.index = this.gallery.images.indexOf(this.getFileSrc(name));
    },
    getTimeByDate(date) {
      return moment(date).format("HH:mm");
    },
    getProcessName(Id) {
      const found = _.find(this.groupProcesses, { Id });
      return found ? found.Name : Id;
    },
    checkUserTaskRight(name) {
      return this.isAdmin || this.activeRights.includes(name);
    },
    downloadURI(uri, name, id, forceDownload) {
      const ext = uri.split(".").pop();
      if (
        !forceDownload &&
        ["pdf", "doc", "docx", "xml", "xls", "excel", "xlsx"].includes(ext)
      ) {
        this.settings.docFileToView = uri;
        this.settings.docFileIdToView = id;
      } else {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    getTasksByFilterOrGroup() {
      this[SET_COMMON_MODULE_PAGE](0);
      if (!this.selectedFilter) {
        this[GET_TASKS](this.buildQueryString());
      } else {
        if (this.selectedFilter === -1) {
          this[GET_TASK_BY_FLASH_FILTER]({
            data: { draftFilter: this.flashFilterData },
            pagination: this.commonModuleSettings.page,
            params: this.buildQueryString(),
          });
        } else if (this.selectedFilter.length > 5) {
          this[GET_TASKS_BY_USER_FILTER]({
            params: this.buildQueryString(),
            id: this.selectedFilter,
            reset: true,
          });
        } else {
          this[GET_TASKS_BY_STANDART_FILTER]({
            params: this.buildQueryString(),
            type: this.selectedFilter,
            reset: true,
          });
        }
      }
    },
    resetSort() {
      this[SET_COMMON_MODULE_SORTS](JSON.parse(JSON.stringify(sortsTemplate)));
      this.getTasksByFilterOrGroup();
    },
    makeSort(type, asc) {
      const sorts = this.commonModuleSettings.sorts;
      _.each(sorts, (object, name) => {
        if (name !== type) {
          object.asc = false;
          object.active = false;
        }
      });

      let active = true;
      asc = asc !== void 0 ? asc : !sorts[type].asc;
      if (!sorts[type].asc && sorts[type].active) {
        active = false;
        asc = false;
      }
      sorts[type].active = active;
      sorts[type].asc = asc;

      this[SET_COMMON_MODULE_SORTS](sorts);

      this.getTasksByFilterOrGroup();
    },
    buildQueryString() {
      let order = null,
        sort,
        orderBy;
      _.each(this.commonModuleSettings.sorts, (object, name) => {
        if (object.active) {
          order = name;
          sort = object.asc;
        }
      });
      if (order) {
        orderBy = order + (sort ? "" : " DESC");
      }
      return {
        page: this.commonModuleSettings.page,
        orderBy,
      };
    },
    ...mapActions("common", [commonTypes.TOGGLE_POPUP_IMAGE]),
    ...mapActions("tasks", [
      GET_TASK_BY_FLASH_FILTER,
      GET_TASKS_BY_STANDART_FILTER,
      GET_TASKS_BY_USER_FILTER,
    ]),
    ...mapMutations("tasks", [SET_COMMON_MODULE_PAGE, SET_COMMON_MODULE_SORTS]),
  },
  filters: {
    shortName: (value) => {
      if (!value) return "";
      // value = value.split(" ");
      // let shortName = "";
      // value.forEach((item, i) => {
      //   shortName = i === 0 ? `${item} ` : shortName + `${item[0]}.`;
      // });
      var fioArray = value.split(" ");
      return `${fioArray[0]} ${fioArray[1] ? fioArray[1][0] + "." : ""}`;
    },
    shortName_NICKNAME: (value) => {
      if (!value) return "";
      value = value.split(" ");
      let shortName = "";
      value.forEach((item, i) => {
        shortName = i === 0 ? `${item} ` : shortName + `${item[0]}.`;
      });
      return value[0];
    },
    initials: function (name) {
      if (!name) return "";

      const nameArr = name.split(" ");

      if (nameArr.length === 3) {
        return `${nameArr[1][0]}${nameArr[0][0]}`;
      } else if (nameArr.length === 2) {
        return `${nameArr[1][0]}${nameArr[0][0]}`;
      }
    },
    formatLocalDate(date) {
      return moment(date).locale("ru").format("DD MMM YYYY в HH:mm");
    },
    formatLocalDateWithoutTime(date) {
      return moment(date).locale("ru").format("DD MMM YYYY");
    },
    formatDateSimple(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    formatDateSimpleWithoutTime(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatReplyDate(date) {
      return moment(date).locale("ru").calendar(null, {
        sameDay: "HH:mm",
        lastDay: "Вчера в HH:mm",
        lastWeek: "DD MMM в HH:mm",
        sameElse: "DD MMM YYYY в HH:mm",
      });
    },
    formatReplyDateWithoutTime(date) {
      return moment(date).locale("ru").calendar(null, {
        sameDay: "HH:mm",
        lastDay: "Вчера в HH:mm",
        sameElse: "DD MMMM",
        lastYear: "DD MMM YYYY",
      });
    },
    formatShortLocaleDate(date) {
      return moment(date).locale("ru").calendar(null, {
        sameDay: "Сегодня",
        nextWeek: "MMMM",
        nextYear: "YYYY",
        sameElse: "MMMM",
      });
    },
  },
  directives: { mask },
  components: {
    VueEditor,
    Multiselect,
  },
};
