<template>
  <div class="popup event-popup" :class="is_open ? 'event-popup_active' : ''">
    <div
      class="popup__wrapper"
      @click="hidePopupByClickWrap($event, 'client', 'requestWindow')"
    >
      <div class="popup__content" style="width: 950px">
        <div class="popup__header">
          <h2 class="popup__heading">
            #{{ selectedRequest.Id }} | {{ selectedRequest.Subject }}
          </h2>
          <div
            class="popup__close"
            @click="hideThisPopup('client', 'requestWindow')"
          >
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="sup-client-chat">
            <div class="sup-client-chat__left">
              <div class="sup-client-info">
                <span class="sup-client-info__title">Статус</span>
                <div
                  class="sup-client-info__status sup-label-status"
                  :class="drawStatusColor(selectedRequest.RealStatusId)"
                >
                  {{ getStatusMessage(selectedRequest) }}
                </div>
              </div>
              <div class="sup-client-info">
                <span class="sup-client-info__title">Создал заявку</span>
                <span class="sup-client-info__text">{{ sender }}</span>
              </div>
              <div class="sup-client-info">
                <span class="sup-client-info__title">Категория</span>
                <span class="sup-client-info__text">{{
                  selectedRequest.Category
                }}</span>
              </div>
              <div class="sup-client-info">
                <span class="sup-client-info__title">Дата создания</span>
                <span class="sup-client-info__text">{{
                  selectedRequest.CreatedDate
                }}</span>
              </div>
              <div
                class="sup-client-info__footer"
                v-if="+selectedRequest.RealStatusId !== 5"
              >
                <div class="sup-client-info__footer-text">
                  <p>
                    Если вы считаете, что проблема решена, то можете
                    самостоятельно закрыть заявку
                  </p>
                </div>
                <div
                  class="sup-client-info__footer-btn sup-client-btn sup-client-btn-1"
                  @click="closeRequest(selectedRequest.Id)"
                >
                  Закрыть заявку
                </div>
              </div>
              <div class="sup-client-info__footer" v-else>
                <div
                  class="sup-client-info__footer-btn sup-client-btn sup-client-btn-1"
                  @click="openRequest(selectedRequest.Id)"
                >
                  Открыть заявку
                </div>
              </div>
            </div>
            <div class="sup-client-chat__right">
              <div class="sup-chat" style="position: relative">
                <div class="dropzone dropzone2"></div>
                <div
                  class="sup-chat__window"
                  ref="windowScrollable"
                  @scroll="handleScroll"
                >
                  <div class="sup-chat__window-top" ref="upperPart">
                    <div class="sup-chat__window-top__navbar">
                      <div
                        class="task-popup-nav__full-btn"
                        :class="fixedContentIsOpen ? '_active' : ''"
                      >
                        <i></i>
                      </div>
                      <div
                        class="task-popup-nav__item-icon btn-pointer"
                        @click="onFixedContentToggle"
                      >
                        <svg width="20" height="14">
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/static/images/sprite.svg#request-header-hamburger"
                          ></use>
                        </svg>
                      </div>
                      <p
                        class="sup-chat__window-top__title btn-pointer"
                        @click="onFixedContentToggle"
                      >
                        Описание
                      </p>
                    </div>
                    <div
                      class="sup-chat__window-top__content"
                      :class="
                        fixedContentIsOpen
                          ? 'sup-chat__window-top__content-active'
                          : ''
                      "
                    >
                      <div class="sup-chat__window-top__item">
                        <p class="sup-chat__window-top__desc">
                          {{ selectedRequest.Description }}
                        </p>
                      </div>
                      <div
                        class="sup-chat__window-top__item sup-chat__window-top__item-files"
                        v-if="requestFiles && requestFiles.length"
                      >
                        <template v-for="(item, index) in requestFiles">
                          <span
                            :key="index"
                            v-if="item.file && checkFileExt(item.name)"
                            @click="
                              showImagePopup(
                                apiUrl + item.file.replace('api2', 'api')
                              )
                            "
                            style="
                              cursor: pointer;
                              margin-left: 0;
                              margin-right: 10px;
                            "
                            class="sup-message__file"
                          >
                            <img
                              :src="apiUrl + item.file.replace('api2', 'api')"
                              alt="file"
                            />
                          </span>
                          <a
                            class="task-files-list__img task-files-list__img--file"
                            :key="index"
                            v-else
                            :href="getFileSrc(item.file.replace('api2', 'api'))"
                            download
                            @click.prevent="
                              downloadURI(
                                getFileSrc(item.file.replace('api2', 'api')),
                                item.name
                              )
                            "
                            style="
                              cursor: pointer;
                              margin-left: 0;
                              margin-right: 10px;
                            "
                          >
                            <img
                              :src="getNonImageTypeFileIcon(item.name, true)"
                            />
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sup-chat__window-top _floating"
                    :class="floatingHeaderIsShown ? '_floating-active' : ''"
                    style="top: 47px"
                  >
                    <div class="sup-chat__window-top__navbar">
                      <div
                        class="task-popup-nav__full-btn"
                        :class="contentIsOpen ? '_active' : ''"
                        @click="scrollToTop"
                      >
                        <i></i>
                      </div>
                      <div
                        class="task-popup-nav__item-icon btn-pointer"
                        @click="onContentToggle"
                      >
                        <svg width="20" height="14">
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/static/images/sprite.svg#request-header-hamburger"
                          ></use>
                        </svg>
                      </div>
                      <p
                        class="sup-chat__window-top__title btn-pointer"
                        @click="onContentToggle"
                      >
                        Описание
                      </p>
                    </div>
                    <div
                      class="sup-chat__window-top__content"
                      :class="
                        contentIsOpen
                          ? 'sup-chat__window-top__content-active'
                          : ''
                      "
                    >
                      <div class="sup-chat__window-top__item">
                        <p class="sup-chat__window-top__desc">
                          {{ selectedRequest.Description }}
                        </p>
                      </div>
                      <div
                        class="sup-chat__window-top__item sup-chat__window-top__item-files"
                        v-if="requestFiles && requestFiles.length"
                      >
                        <template v-for="(item, index) in requestFiles">
                          <span
                            :key="index"
                            v-if="item.file && checkFileExt(item.name)"
                            @click="
                              showImagePopup(
                                apiUrl + item.file.replace('api2', 'api')
                              )
                            "
                            style="
                              cursor: pointer;
                              margin-left: 0;
                              margin-right: 10px;
                            "
                            class="sup-message__file"
                          >
                            <img
                              :src="apiUrl + item.file.replace('api2', 'api')"
                              alt="file"
                            />
                          </span>
                          <a
                            class="task-files-list__img task-files-list__img--file"
                            :key="index"
                            v-else
                            :href="getFileSrc(item.file.replace('api2', 'api'))"
                            download
                            @click.prevent="
                              downloadURI(
                                getFileSrc(item.file.replace('api2', 'api')),
                                item.name
                              )
                            "
                            style="
                              cursor: pointer;
                              margin-left: 0;
                              margin-right: 10px;
                            "
                          >
                            <img
                              :src="getNonImageTypeFileIcon(item.name, true)"
                            />
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="sup-chat__window-bottom">
                    <template v-for="(item, index) in messages">
                      <!--:class="currentUser===item.SenderName ? 'sup-message&#45;&#45;left':'sup-message&#45;&#45;right'"-->
                      <div
                        class="sup-message"
                        :id="`sup-message__${item.Id}`"
                        :key="index"
                        v-if="
                          !item.OperatorId &&
                          item.SenderName !== 'Описание' &&
                          !item.DistributorContactId
                        "
                      >
                        <div class="sup-message__avatar">
                          {{ item.SenderName | initials }}
                        </div>
                        <div
                          class="sup-message__text-block sup-message--client"
                        >
                          <p class="sup-message__name">
                            {{ item.SenderName }}
                            <span class="sup-message__date">{{
                              item.Created
                            }}</span>
                            <span
                              v-if="item.SenderName !== 'Система'"
                              class="sup-message__reply"
                              @click="setReplyComment(item)"
                            >
                              <svg class="sup-message__reply-icon">
                                <use
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xlink:href="/clientsupport/static/images/sprite.svg#sup-reply-icon"
                                ></use>
                              </svg>
                            </span>
                          </p>
                          <div
                            class="task-parent-comment-preview"
                            v-if="item.Parent.Message || item.Parent.Files"
                            @click="goToReplyMessage(item.Parent)"
                            :style="
                              item.Parent.OperatorId
                                ? 'border-color: #F9AE17'
                                : ''
                            "
                          >
                            <div class="task-parent-comment-preview__name">
                              <div class="task-comments-item__name">
                                {{ item.Parent.SenderName }}
                              </div>
                            </div>
                            <div class="task-comments-item__date">
                              {{ item.Parent.Created }}
                            </div>
                            <div
                              class="task-parent-comment-preview__comment"
                              v-text="item.Parent.Message"
                            ></div>
                            <template v-if="item.Parent.Files.length">
                              <div
                                v-for="file in item.Parent.Files"
                                class="task-parent-comment-preview__comment"
                                :key="file.name"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                  style="height: 14px"
                                />
                                {{ file.name }}
                              </div>
                            </template>
                          </div>
                          <p class="sup-message__text" v-if="item.Message">
                            {{ item.Message.trim() }}
                          </p>
                          <template v-if="item.Files && item.Files.length">
                            <template v-for="(file, index) in item.Files">
                              <a
                                :key="index"
                                :href="apiUrl + file.file"
                                v-if="!checkFileExt(file.name)"
                                download
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </a>
                              <span
                                :key="index"
                                v-if="checkFileExt(file.name)"
                                @click="showImagePopup(apiUrl + file.file)"
                                style="cursor: pointer"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </span>
                              <br :key="index" />
                            </template>
                          </template>
                        </div>
                      </div>

                      <div
                        class="sup-message sup-message--right"
                        :id="`sup-message__${item.Id}`"
                        :key="index"
                        v-if="
                          (item.OperatorId || item.DistributorContactId) &&
                          item.SenderName !== 'Описание'
                        "
                      >
                        <div class="sup-message__avatar">
                          <img src="@/assets/images/avatar.png" alt="" />
                        </div>
                        <div
                          class="sup-message__text-block sup-message--support"
                        >
                          <p class="sup-message__name">
                            {{ item.SenderName }}
                            {{ item.CreatedBy
                            }}<template v-if="item.DistributorContactId"
                              >, Оператор
                              {{
                                selectedRequest.distributorName ||
                                selectedRequest.DistributorName
                              }}</template
                            >
                            <span class="sup-message__date">{{
                              item.Created
                            }}</span>
                            <span
                              v-if="item.SenderName !== 'Система'"
                              class="sup-message__reply"
                              @click="setReplyComment(item)"
                            >
                              <svg class="sup-message__reply-icon">
                                <use
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xlink:href="/clientsupport/static/images/sprite.svg#sup-reply-icon"
                                ></use>
                              </svg>
                            </span>
                          </p>
                          <div
                            class="task-parent-comment-preview"
                            v-if="item.Parent.Message || item.Parent.Files"
                            @click="goToReplyMessage(item.Parent)"
                            :style="
                              item.Parent.OperatorId
                                ? 'border-color: #F9AE17'
                                : ''
                            "
                          >
                            <div class="task-parent-comment-preview__name">
                              <div class="task-comments-item__name">
                                {{ item.Parent.SenderName }}
                              </div>
                            </div>
                            <div class="task-comments-item__date">
                              {{ item.Parent.Created }}
                            </div>
                            <div
                              class="task-parent-comment-preview__comment"
                              v-text="item.Parent.Message"
                            ></div>
                            <template v-if="item.Parent.Files.length">
                              <div
                                v-for="file in item.Parent.Files"
                                class="task-parent-comment-preview__comment"
                                :key="file.name"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                  style="height: 14px"
                                />
                                {{ file.name }}
                              </div>
                            </template>
                          </div>
                          <p class="sup-message__text" v-if="item.Message">
                            {{ item.Message.trim() }}
                          </p>
                          <template v-if="item.Files && item.Files.length">
                            <template v-for="(file, index) in item.Files">
                              <a
                                :key="index"
                                :href="apiUrl + file.file"
                                v-if="!checkFileExt(file.name)"
                                download
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </a>
                              <span
                                :key="index"
                                v-if="checkFileExt(file.name)"
                                @click="showImagePopup(apiUrl + file.file)"
                                style="cursor: pointer"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </span>
                              <br :key="index" />
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  class="sup-chat__footer"
                  v-if="+selectedRequest.RealStatusId !== 5"
                >
                  <div
                    v-if="replyComment"
                    class="sup-chat__reply task-comment-reply"
                  >
                    <div class="task-comment-reply__person">
                      {{ replyComment.SenderName }}
                    </div>
                    <p
                      v-if="replyComment.Message"
                      class="task-comment-reply__text"
                    >
                      {{ replyComment.Message }}
                    </p>
                    <div class="sup-chat__footer-close" @click="clearReply">
                      <svg class="popup__close-icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-close"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="sup-chat__input">
                    <div class="loadfile-indicator" v-if="progressBar">
                      <span :style="`width:${progressBar}%`"></span>
                    </div>

                    <textarea
                      placeholder="Напишите соообщение..."
                      v-model="message"
                      @keyup.ctrl.enter="sendMessage"
                      @paste="onPaste"
                    ></textarea>
                    <label class="sup-chat__input-att">
                      <input
                        type="file"
                        class="sup-chat__file"
                        multiple
                        hidden
                        @change="filesChange($event.target.files)"
                      />
                      <img src="@/assets/images/attachment.svg" alt="icon" />
                    </label>
                  </div>

                  <template v-if="files && files.length">
                    <div class="sup-chat-files">
                      <div
                        class="sup-chat-files__item"
                        v-for="(item, index) in files"
                        :key="index"
                      >
                        <img
                          v-if="checkFileExt(item.name)"
                          :src="item.img"
                          alt="file image preview"
                        />
                        <img
                          class="sup-chat-files__file-icon"
                          src="@/assets/images/file.svg"
                          v-else
                          alt="icon"
                        />
                        <div
                          class="sup-chat-files__item-remove"
                          @click="removeFile(item.id)"
                        >
                          <img src="@/assets/images/remove.svg" alt="icon" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="sup-chat__footer-btn-wrap _client">
                    <div
                      class="sup-client-info__footer-btn sup-client-btn sup-client-btn-2 flex-1"
                      @click="sendMessage"
                    >
                      Отправить сообщение
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/mixins/popup";
import { mapState, mapActions, mapGetters } from "vuex";
import * as c from "@/store/support/constants";
import * as commonTypes from "@/store/common/constants";
import ColorOfStatus from "@/mixins/colorOfStatus";
import { scrollTo } from "@/utils/scrollTo";
import { API_ROOT } from "@/config";
import * as _ from "lodash";
import common from "../../../../mixins/tasks/common";

export default {
  mixins: [Popup, ColorOfStatus, common],
  data() {
    return {
      message: "",
      files: [],
      apiUrl: API_ROOT,
      typeTime: null,
      contentIsOpen: false,
      floatingHeaderIsShown: false,
      fixedContentIsOpen: true,
      replyComment: null,
    };
  },
  mounted() {
    const self = this;

    const chatWindow = document.querySelector(".sup-chat__window");
    chatWindow.scrollTop = chatWindow.scrollHeight;

    const dropzoneWrapper = document.querySelector(".dropzone2");
    let isDragged = false;

    document.querySelector(".sup-chat").ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      return false;
    };

    dropzoneWrapper.ondrop = function (event) {
      event.preventDefault();
      dropzoneWrapper.classList.remove("dropzone-hover");
      dropzoneWrapper.style.display = "none";
      self.filesChange(event.dataTransfer.files);
      isDragged = false;
    };
    dropzoneWrapper.ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      isDragged = true;
      setTimeout(() => (isDragged = false), 400);
      return false;
    };

    dropzoneWrapper.ondragleave = function () {
      setTimeout(() => {
        if (!isDragged) {
          dropzoneWrapper.classList.remove("dropzone-hover");
          dropzoneWrapper.style.display = "none";
        }
      }, 400);
      return false;
    };
  },
  computed: {
    ...mapState("clientSupport", [
      "client",
      "selectedRequest",
      "progressBar",
      "parentData",
    ]),
    ...mapGetters("clientSupport", [
      "sender",
      "messages",
      "currentUser",
      "requestFiles",
    ]),
  },
  methods: {
    ...mapActions("common", { showImagePopup: commonTypes.TOGGLE_POPUP_IMAGE }),
    ...mapActions("clientSupport", {
      closeRequest: c.CLOSE_REQUEST,
      openRequest: c.OPEN_REQUEST,
    }),
    ...mapActions("clientSupport", [c.SEND_MESSAGE]),
    goToReplyMessage(replyMessage) {
      const replyEl = document.getElementById(
        "sup-message__" + replyMessage.Id
      );
      replyEl.classList.add("sup-message--select");
      setTimeout(() => {
        replyEl.classList.remove("sup-message--select");
      }, 1000);
      this.$refs.windowScrollable.scrollTop = replyEl.offsetTop - 150;
    },
    scrollToTop() {
      this.floatingHeaderIsShown = false;
      this.$refs.windowScrollable.scrollTop = 0;
    },
    onContentToggle() {
      this.contentIsOpen = !this.contentIsOpen;
    },
    setReplyComment(comment) {
      this.replyComment = comment;
    },
    clearReply() {
      this.replyComment = null;
    },
    onFixedContentToggle() {
      this.fixedContentIsOpen = !this.fixedContentIsOpen;
    },
    handleScroll() {
      let window = this.$refs.windowScrollable;
      let description = this.$refs.upperPart;

      let docViewTop = window.scrollTop;
      let elemHeight = description.clientHeight;

      this.floatingHeaderIsShown = docViewTop > elemHeight;
    },
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    sendMessage() {
      if (this.message.trim().length || this.files.length) {
        this.parentData.sendingMessage = true;
        const formData = new FormData();
        formData.append("Message", this.message);
        _.each(this.files, (i, index) =>
          formData.append("files[" + index + "]", i.file, i.file.name)
        );
        if (this.replyComment) {
          formData.append("AnswerToCommentId", this.replyComment.Id);
        }
        this[c.SEND_MESSAGE]({
          requestId: this.selectedRequest.Id,
          message: formData,
        });
        this.message = "";
        this.file = { name: "", file: "" };
        this.files = [];
        this.replyComment = null;
        document.querySelector(".sup-chat__file").value = "";

        const self = this;
        this.typeTime = setTimeout(function () {
          clearTimeout(this.typeTime);

          self.parentData.sendingMessage = false;
        }, 5000);
      }
    },
    checkFileExt(fileName) {
      const imagesExts = ["jpeg", "jpg", "png", "bmp", "gif"];
      const ext = fileName.split(".").pop();
      if (imagesExts.includes(ext.toLowerCase())) {
        return true;
      } else return false;
    },
    removeFile(id) {
      this.files = _.reject(this.files, { id });
    },
    getStatusMessage(item) {
      let message =
        item.RealStatusId === 5 ? "Закрыта " + item.Closed : item.Status;
      return message;
    },
    onPaste(e) {
      const files = e.clipboardData.files;
      console.log(files);
      if (files.length) {
        this.filesChange(files);
        e.preventDefault();
      }
    },
  },
  filters: {
    initials: function (name) {
      if (!name) return "";
      if (name === "Автоматическое уведомление RPS") return "RPS";

      const nameArr = name.split(" ");

      if (nameArr.length === 3) {
        return `${nameArr[1][0]} ${nameArr[2][0]}`;
      } else if (nameArr.length === 2) {
        return `${nameArr[0][0]} ${nameArr[1][0]}`;
      }
    },
    truncateFileName(name) {
      if (name.length > 10) name = name.substr(0, 10) + "...";
      return name;
    },
  },
  watch: {
    messages(newMessages, oldMessages) {
      if (newMessages.length !== oldMessages.length) {
        const chatWindow = document.querySelector(".sup-chat__window");
        setTimeout(() => {
          scrollTo(chatWindow, chatWindow.scrollHeight, 500);
        }, 100);
      }
    },
  },
};
</script>
