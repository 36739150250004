<template>
  <div v-if="request !== null" @click.stop>
    <div
      v-click-outside="closeTagList"
      class="task-labels"
      :class="{ 'prevent-click': false }"
    >
      <span
        class="task-label"
        v-for="item in computedTags"
        :style="{ backgroundColor: '#' + item.Color }"
        :key="item.Id"
        @click="openTagList"
        >{{ item.TagName }}
      </span>
      <div
        class="add-tag-btn"
        v-if="true && (!computedTags || !computedTags.length)"
        @click="openTagList"
      >
        +
      </div>
    </div>
    <rps-tags-selector
      v-if="showTagsList"
      @close="closeTagList"
      :request-tags="computedTags"
      :request-id="request.Id"
      @pushTag="pushTag"
      @removeTag="removeTag"
      @updateTags="updateTags"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Helpers } from "@/mixins/Helpers";
import * as _ from "lodash";
import RpsTagsSelector from "./RpsTagsSelector";

export default {
  components: { RpsTagsSelector },
  mixins: [Helpers],
  name: "RequestTags",
  props: ["request"],
  data() {
    return {
      showTagsList: false,
    };
  },
  computed: {
    computedTags: {
      get() {
        const self = this;
        function findTag(array, tag) {
          let found = null;
          array.forEach((i) => {
            if (!found) {
              if (i.Id === tag.TagId || i.Id === tag.Id) found = i;
              else if (i.child && i.child.length) {
                found = findTag(i.child, tag);
              }
            }
          });
          return found;
        }

        let result = [];
        _.each(this.request.tags, (i) => {
          const foundTag = findTag(self.commonSettings.tags, i);
          if (foundTag !== null && foundTag !== undefined) {
            result.push({
              ...foundTag,
              TagId: foundTag.Id,
              relationId: i.relationId ? i.relationId : i.Id,
            });
          }
        });
        return result;
      },
      set(val) {
        this.request.tags = val;
      },
    },
    ...mapState("rpsSupport", ["preloaders", "commonSettings"]),
  },
  methods: {
    closeTagList() {
      this.showTagsList = false;
      this.$emit("closeInnerPopup");
    },
    openTagList() {
      this.showTagsList = true;
      this.$emit("openInnerPopup");
    },
    pushTag(data) {
      this.request.tags.push(data);
    },
    removeTag(id) {
      this.request.tags = _.reject(this.request.tags, ["TagId", id]);
    },
    updateTags(newTags) {
      this.request.tags = newTags.slice();
    },
    addRequestIdToTag(tag) {
      this.request.tags = _.map(this.request.tags, (i) => {
        if (i.TagId === tag.TagId) {
          i.relationId = tag.Id;
        }
        return i;
      });
    },
  },
};
</script>

<style scoped></style>
