<template>
  <div class="commercial-site">
    <div class="header">
      <div class="header__col">
        <ul class="tabs header__tabs">
          <li
            class="tabs__tab"
            @click="
              () => {
                tab = 1;
                $router.push('/commercial_site/sliders');
              }
            "
            :class="tab === 1 ? 'active' : ''"
          >
            <a class="tabs__link" href="#">Страницы и разделы:</a>
          </li>
          <li
            class="tabs__tab"
            @click="
              () => {
                tab = 2;
                $router.push('/commercial_site/commonTranslations');
              }
            "
            :class="tab === 2 ? 'active' : ''"
          >
            <a class="tabs__link" href="#">Настройки сайта:</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="cs-container">
      <div class="inner-sidebar">
        <div class="inner-sidebar__group" style="height: 938px">
          <div class="inner-sidebar__item">
            <ul
              class="vertical-menu vertical-menu_left_border statistics__vertical-menu"
              v-if="tab === 1"
            >
              <li
                class="vertical-menu__item vertical-menu__item--inner-list"
                :class="getParentItemClassName('mainPage')"
              >
                <router-link
                  to="/commercial_site/sliders"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Главная</span>
                </router-link>
                <ul class="inner-sidebar__inner-list">
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/sliders"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Слайдер</span>
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/benefits"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Преимущества</span>
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/blocks_of_main_products"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Продукция</span>
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/facts"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Факты</span>
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/mainpageFooterFacts"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Иконки в футере</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/staticPages"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Компания</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/news"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Новости</span>
                </router-link>
              </li>

              <li
                class="vertical-menu__item vertical-menu__item--inner-list"
                :class="getParentItemClassName('blog')"
              >
                <router-link
                  to="/commercial_site/BlogPosts"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Блог</span>
                </router-link>
                <ul class="inner-sidebar__inner-list">
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/BlogPosts"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Посты</span>
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/BlogCategories"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Категории</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/services"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Услуги</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/examples"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Выполненные работы</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/solutions"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Решения</span>
                </router-link>
              </li>
              <li
                class="vertical-menu__item vertical-menu__item--inner-list"
                :class="getParentItemClassName('catalog')"
              >
                <router-link
                  to="/commercial_site/productionCategories"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Каталог</span>
                </router-link>
                <ul class="inner-sidebar__inner-list">
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/productionCategories"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text"
                        >Категории оборудования</span
                      >
                    </router-link>
                  </li>
                  <li class="vertical-menu__item">
                    <router-link
                      to="/commercial_site/production"
                      class="vertical-menu__link"
                    >
                      <span class="vertical-menu__text">Товары</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/blocks"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Статические страницы</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/promo_landing"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Лендинг</span>
                </router-link>
              </li>
            </ul>
            <ul
              class="vertical-menu vertical-menu_left_border statistics__vertical-menu"
              v-if="tab === 2"
            >
              <li
                class="vertical-menu__item vertical-menu__item--inner-list"
                :class="getParentItemClassName('commonTranslations')"
              >
                <router-link
                  to="/commercial_site/commonTranslations"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Общие переводы сайта</span>
                </router-link>
                <ul class="inner-sidebar__inner-list">
                  <li
                    class="vertical-menu__item"
                    @click="parentData.translateTableLanguage = 'en'"
                  >
                    <a
                      href="#"
                      class="vertical-menu__link"
                      :class="{
                        'router-link-active vertical-menu__item--open-list router-link-active':
                          parentData.translateTableLanguage === 'en',
                      }"
                    >
                      <span class="vertical-menu__text">Английский</span>
                    </a>
                  </li>
                  <li
                    class="vertical-menu__item"
                    @click="parentData.translateTableLanguage = 'ch'"
                  >
                    <a
                      href="#"
                      class="vertical-menu__link"
                      :class="{
                        'router-link-active vertical-menu__item--open-list router-link-active':
                          parentData.translateTableLanguage === 'ch',
                      }"
                    >
                      <span class="vertical-menu__text">Китайский</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/exportTranslations"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Экспорт\Импорт</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/regions"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Регионы</span>
                </router-link>
              </li>
              <li class="vertical-menu__item">
                <router-link
                  to="/commercial_site/cities"
                  class="vertical-menu__link"
                >
                  <span class="vertical-menu__text">Города</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cs-container__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import * as c from "../../store/commercial_site/constants";
import { mapActions, mapState } from "vuex";
import { GET_SITE_RIGHT } from "../../store/common/constants";
import { Helpers } from "@/mixins/Helpers";
export default {
  mixins: [Helpers],
  data() {
    return {
      settings: null,
      tab: 1,
      GET_SITE_RIGHT,
    };
  },
  created() {},
  mounted() {
    document
      .querySelectorAll(
        ".vertical-menu__item--inner-list > .vertical-menu__link"
      )
      .forEach((el) => {
        el.onclick = (e) => {
          e.currentTarget
            .closest(".vertical-menu__item--inner-list")
            .classList.toggle("vertical-menu__item--open-list");
        };
      });
  },
  computed: {
    ...mapState("site", ["parentData"]),
    ...mapState("common", ["rightsLoaded"]),
  },
  methods: {
    getParentItemClassName(menuName) {
      switch (menuName) {
        case "mainPage":
          if (
            [
              "benefits",
              "facts",
              "sliders",
              "blocks_of_main_products",
              "mainpageFooterFacts",
            ].includes(this.$route.params.module)
          ) {
            return " router-link-active vertical-menu__item--open-list";
          }
          break;
        case "catalog":
          if (
            ["productionCategories", "production"].includes(
              this.$route.params.module
            )
          ) {
            return " router-link-active vertical-menu__item--open-list";
          }
          break;
        case "blog":
          if (
            ["BlogPosts", "BlogCategories"].includes(this.$route.params.module)
          ) {
            return " router-link-active vertical-menu__item--open-list";
          }
          break;
        case "commonTranslations":
          if (this.$route.params.module === "commonTranslations") {
            return " router-link-active vertical-menu__item--open-list";
          }
      }
    },
    ...mapActions("site", [c.GET_SETTINGS, c.GET_BASE_URL, c.GET_DISTRICTS]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const timerId = setInterval(() => {
        if (vm.rightsLoaded) {
          if (vm.checkUserRight(GET_SITE_RIGHT)) {
            vm[c.GET_SETTINGS]();
            vm[c.GET_BASE_URL]();
            vm[c.GET_DISTRICTS]();
            if (
              ["commonTranslations", "exportTranslations"].includes(
                vm.$route.params.module
              )
            ) {
              vm.tab = 2;
            }
          } else {
            vm.$router.push("/");
          }
          clearInterval(timerId);
        }
      }, 200);
    });
  },
};
</script>
