<template>
  <div>
    <div class="header">
      <div class="header__col">
        <ul class="tabs header__tabs">
          <li class="tabs__tab" :class="activeClass('open')">
            <a class="tabs__link" @click="setActiveTab('open')" href="#"
              >Активные: {{ count.open }}</a
            >
          </li>
          <li class="tabs__tab" :class="activeClass('closed')">
            <a class="tabs__link" @click="setActiveTab('closed')" href="#"
              >Закрытые: {{ count.closed }}</a
            >
          </li>
        </ul>
      </div>
      <div class="header-pin" v-if="pin">
        <span>Ваш пинкод: {{ pin }}</span>
      </div>
      <portal-target
        class="client-support-header__portal"
        name="client-support-header"
      />
    </div>
    <div class="actions-header">
      <div class="actions-header__col">
        <a
          class="button button_green button_uppercase settings-devices-zone__add"
          href="#"
          @click="
            togglePopup({ module: 'client', popup: 'newRequest', value: true })
          "
          >Создать заявку</a
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          class="search-bar search-bar_white actions-header__search-bar"
          method="post"
          action="#"
        >
          <button class="search-bar__button" type="submit">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            class="search-bar__input"
            type="search"
            v-model="search"
            @keyup="setSearchInput"
            placeholder="№ заявки, название заявки"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as supportTypes from "@/store/support/constants.js";
import * as commonTypes from "@/store/common/constants";
export default {
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState("clientSupport", ["count", "searchInput", "activeTabs", "pin"]),
  },
  methods: {
    ...mapActions("clientSupport", [
      supportTypes.SET_ACTIVE_TAB,
      supportTypes.GET_REQUESTS,
      supportTypes.SET_SEARCH_INPUT,
      supportTypes.CHANGE_REQUEST_FILTERS,
    ]),
    ...mapActions("common", { togglePopup: commonTypes.TOGGLE_POPUP_LOCAL }),
    setActiveTab(tab) {
      this[supportTypes.GET_REQUESTS]({ scope: tab, start: false });
      this[supportTypes.CHANGE_REQUEST_FILTERS]({
        filter: "status",
        value: { name: "Все", id: 0 },
      });
      this[supportTypes.SET_ACTIVE_TAB](tab);
    },
    activeClass(tab) {
      return this.activeTabs[tab] ? "active" : "";
    },
    setSearchInput() {
      this[supportTypes.SET_SEARCH_INPUT](this.search);
    },
  },
};
</script>

<style scoped>
.header-pin {
  margin-left: auto;
  margin-right: 10px;
  align-self: center;
  font-size: 19px;
  background: #fff;
  padding: 4px 8px 7px;
  border-radius: 4px;
}
</style>
