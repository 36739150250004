var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"create-folder-control"},[_c('div',{staticClass:"create-folder-inner-wrapper"},[_c('div',{staticClass:"create-folder-btn"},[_c('div',{staticClass:"create-folder-btn__row",on:{"click":function () {
            if (_vm.groupSelectPopupOpen) {
              _vm.closeGroupSelectPopup();
            } else {
              _vm.openSelectGroupPopup();
            }
          }}},[_c('div',{staticClass:"create-folder-btn__icon"},[_c('svg',{style:({ fill: '#202325' }),attrs:{"width":"20","height":"20"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"/static/images/tasks/sprite.svg#group-folder-icon"}})])]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"create-folder-btn__row",on:{"click":function($event){_vm.$emit('createGroup');
          _vm.close();}}},[_c('div',{staticClass:"create-folder-btn__icon"},[_c('svg',{style:({ fill: '#202325' }),attrs:{"width":"17","height":"17"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"/static/images/tasks/sprite.svg#folder-icon"}})])]),_vm._v(" "),_vm._m(1)])]),_vm._v(" "),(_vm.groupSelectPopupOpen)?_c('FolderGroupsList',{attrs:{"preloader":_vm.preloader,"selected":_vm.selected,"parentData":_vm.parentData},on:{"select":_vm.select,"close":_vm.closeGroupSelectPopup,"createFolder":_vm.createFolder}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-folder-btn__text"},[_c('div',{staticClass:"create-folder-btn__title"},[_vm._v("Группу папок")]),_vm._v(" "),_c('div',{staticClass:"create-folder-btn__subtitle"},[_vm._v("\n            Содержит несколько папок\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-folder-btn__text"},[_c('div',{staticClass:"create-folder-btn__title"},[_vm._v("Папку")]),_vm._v(" "),_c('div',{staticClass:"create-folder-btn__subtitle"},[_vm._v("Содержит списки задач")])])}]

export { render, staticRenderFns }