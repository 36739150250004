<template>
  <div class="task-bubble__item-wrapper">
    <div
      class="task-bubble__item-parent-row"
      :class="{ 'task-bubble__item-parent-row--open': open }"
      @click="open = !open"
    >
      <div class="task-bubble__item-parent-row-arrow">
        <svg width="12" height="7">
          <use
            xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
          ></use>
        </svg>
      </div>
      <div class="task-bubble__item-parent-row-name">{{ item.GroupName }}</div>
      <div class="task-bubble__item-parent-row-count">
        {{ item.tags ? item.tags.length : 0 }}
      </div>
    </div>
    <template v-if="open">
      <div
        class="task-bubble__item"
        v-for="(item, index) in filteredTags"
        @click="$emit('choose', item)"
        :key="index"
        :item-id="item.Id"
      >
        <div
          class="task-table-person__circle"
          :style="{ background: item.Color || item.TagColor || '#F9AE17' }"
        ></div>
        {{ item.TagName }}
      </div>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "TagsListSearchItem",
  props: ["item", "search", "currentGroupGroupsTags"],
  data() {
    return {
      open: false,
    };
  },
  created() {
    if (this.currentGroupGroupsTags.length === 1) {
      this.open = true;
    }
  },
  computed: {
    filteredTags() {
      let tags = _.filter(
        _.orderBy(this.item.tags, ["TagName"], ["asc"]),
        (i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i.TagName);
        }
      );
      return tags;
    },
  },
  watch: {
    search(val) {
      this.open = val ? this.filteredTags.length : false;
    },
  },
};
</script>

<style scoped></style>
