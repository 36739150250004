<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_20 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_20 />
      </magnific-popup-modal>
    </div>
    <div class="pdf-settings">
      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Менеджер</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.ManagerName"
            />
          </div>
          <div class="form-row">
            <div class="form-row__label">Телефон:</div>
            <input
              class="form-row__input"
              type="tel"
              v-mask="'+7 ### ### ## ##'"
              v-model="offerObject.json.ManagerPhone"
            />
          </div>
          <div class="form-row">
            <div class="form-row__label">E-mail:</div>
            <input
              class="form-row__input"
              type="email"
              v-model="offerObject.json.ManagerEmail"
            />
          </div>
        </div>
      </div>
      <div
        class="pdf-settings__next pdf-settings__btns"
        v-if="!windowStates.is_edit_state"
      >
        <button class="btn-full btn-full__gray" @click="changeStep(19)">
          Назад
        </button>
        <button
          class="btn-full btn-full__green"
          :class="allowSave ? '' : 'disabled-btn__wrap'"
          @click="downloadAfterSave"
        >
          Скачать PDF
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_20 from "../prints/print_20";
import { mask } from "vue-the-mask";
import * as c from "../../../../../store/sales/constants";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      allowSave: true,
    };
  },
  computed: {
    ...mapState("sales", ["offerObject", "windowStates"]),
  },
  methods: {
    downloadAfterSave() {
      this.allowSave = false;
      this[c.SAVE_OFFER]({})
        .then(() => {
          setTimeout(() => {
            this.downloadPdf(true);
          }, 200);
        })
        .finally(() => (this.allowSave = true));
    },
    ...mapActions("sales", [c.SAVE_OFFER]),
  },
  components: { Print_20 },
  directives: { mask },
};
</script>
