<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <svg width="14" height="18" stroke="#BBBBBB">
        <use xlink:href="/static/images/tasks/sprite.svg#process-icon-2"></use>
      </svg>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Процесс:</p>
          <div class="task-create-form__field-value" @click="bubbleOpen = true">
            <template v-if="form.ProcessId">{{
              getProcessName(form.ProcessId)
            }}</template>
            <template v-else>Прикрепить</template>
            <transition name="fade">
              <ProcessesListSearch
                v-if="bubbleOpen"
                :selectedId="form.ProcessId"
                :className="'task-bubble--templates'"
                @choose="
                  (id) => {
                    form.ProcessId = id;
                    $emit('saveAfterEdit', null, () => {}, 'ProcessId');
                  }
                "
                @close="() => (bubbleOpen = false)"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <SwitcherForHideField
      v-if="!noSave"
      @saveAfterEdit="
        (formData, callback, field) =>
          $emit('saveAfterEdit', formData, callback, field)
      "
      :form="form"
      :field="'Process'"
    />
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import ProcessesListSearch from "../../CommonComponents/ProcessesListSearch";
import SwitcherForHideField from "./SwitcherForHideField";

export default {
  mixins: [common],
  props: ["form", "noSave"],
  data() {
    return {
      bubbleOpen: false,
    };
  },
  mounted() {},
  methods: {},
  components: {
    ProcessesListSearch,
    SwitcherForHideField,
  },
};
</script>

<style scoped></style>
