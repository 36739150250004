<template>
  <div class="settings-users_block">
    <div class="settings-users_block__name">
      <h3>Роли</h3>
    </div>
    <div class="settings-users_block__inner">
      <div
        class="settings-users_block__inner-header settings-users_block__inner-item"
      >
        <input
          type="text"
          @keyup="$emit('search', $event.target.value)"
          placeholder="Поиск"
        />
        <div class="search-bar__button">
          <svg>
            <use xlink:href="static/images/icon.svg#icon_icon-search"></use>
          </svg>
        </div>
      </div>
      <div class="settings-users_block__preloader" v-if="role_load_preloader">
        <i class="circle-preloader"></i>
      </div>
      <div class="settings-users_block__inner-item-wrap" v-else>
        <div
          :class="isActive(role.Id)"
          :key="role.Id"
          v-for="role in orderedRoles"
          @click="
            active_role.Id === role.Id
              ? $emit('toggleShowRoleConfig', false)
              : $emit('makeRoleActive', role.Id)
          "
        >
          <p>{{ role.Name }}</p>
        </div>
      </div>
    </div>

    <span
      @click="$emit('addRole')"
      class="button button_green button_uppercase button_align_center button--settings"
      v-disabled="{
        allow: checkUserRight(rightUpdateRoles),
        title: 'Запрещено настройками системы',
      }"
    >
      <span v-if="role_add_preloader" class="reload-icon"></span>
      <span v-else>НОВАЯ РОЛЬ</span>
    </span>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";

export default {
  mixins: [Helpers],
  props: [
    "role_load_preloader",
    "roles",
    "active_role",
    "active_user",
    "orderedRoles",
    "role_add_preloader",
  ],
  data() {
    return {
      rightUpdateRoles: "5F7B3086-0362-4AF4-975F-DBB7CC7A773A",
    };
  },
  methods: {
    isActive(id) {
      let className = "settings-users_block__inner-item";
      if (this.active_role.Id === id)
        className = className + " settings-users_block__inner-item--active";
      if (this.active_user.RoleId === id) {
        className =
          className + " settings-users_block__inner-item--role-active";
      }
      return className;
    },
  },
};
</script>

<style scoped>
.settings-users_block__inner-item-wrap {
  height: 100%;
}
</style>
