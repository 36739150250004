<template>
  <div
    class="popup task-popup event-popup event-popup_active"
    id="select-parent-popup"
    ref="taskFilesList"
    :style="`left: ${moveLeft}`"
  >
    <div class="popup__wrapper" @click.stop="$emit('onClosePopup')">
      <div
        class="popup__content"
        :class="assignType === 'create' ? 'parent-selector-list' : ''"
        @click.stop
      >
        <div class="popup__header">
          <h2 class="popup__heading">Задачи {{ groupName }}</h2>
          <div class="popup__close" @click.stop="$emit('onClosePopup')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="task-popup-header">
          <div class="actions-header__col actions-header__col_flexible">
            <div class="search-bar search-bar_white actions-header__search-bar">
              <button type="submit" class="search-bar__button">
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-search"
                  ></use>
                </svg>
              </button>
              <input
                class="search-bar__input"
                type="search"
                v-model="search"
                placeholder="Введите название задачи"
                @change="getTasks"
                @keyup="clearTasks"
              />
            </div>
          </div>
        </div>
        <!--        todo uncomment this to add sorting button-->
        <!--        <div class="table__header">-->
        <!--          <div class="table__col table__col_flexible">-->
        <!--            <p class="table__header-text">Задачи</p>-->
        <!--            <div-->
        <!--              class="table__filter-button"-->
        <!--              :class="sort.active ? 'table-c__filter-btn&#45;&#45;active' : ''"-->
        <!--              @click="toggleSort"-->
        <!--            >-->
        <!--              {{ sort.type === "asc" ? "А-Я" : "Я-А" }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="popup__body group-list">
          <template v-if="tasks.length">
            <template v-if="sections.length && !search.length">
              <TasksSection
                v-for="(item, index) in computedSections"
                :key="'section' + item.Id"
                :level="0"
                :sectionTasks="item.tasks"
                :section="item"
                :sections="sections"
                :assignType="assignType"
                :haveNextSection="index + 1 < sections.length"
                @onParentTaskSelect="handleParentSelect"
                @taskToRequestAssign="createTaskToRequestRelation"
              />
            </template>
            <template v-if="tasks.length && search.length">
              <TasksRow
                v-for="(item, index) in tasks"
                :key="item.Id"
                :item="item"
                :index="index"
                :level="0"
                :data-id="item.Id"
                :assignType="assignType"
                @onParentTaskSelect="handleParentSelect"
                @taskToRequestAssign="createTaskToRequestRelation"
              />
            </template>
          </template>
          <div class="row-spinner _full-height" v-if="preloader">
            <Spinner
              :noFadeIn="true"
              name="line-scale-pulse-out"
              color="#c9eeff"
              width="50"
              height="35"
            />
          </div>
        </div>
        <div
          v-if="assignType === 'create'"
          class="task-create-form__button"
          @click="handleParentSelect(null)"
        >
          Пропустить
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../../../api/tasks";
import TasksSection from "./TasksSection";
import TasksRow from "./TasksRow";
export default {
  name: "TaskParentSelector",
  components: { TasksRow, TasksSection },
  props: ["groupId", "groupName", "moveLeft", "assignType"],
  data() {
    return {
      tasks: [],
      search: "",
      sections: [],
      preloader: true,
      sort: {
        active: false,
        type: "asc",
      },
    };
  },
  computed: {
    computedSections() {
      let computedSections = [];
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];
        const tasks =
          section.Name === "virtualSection"
            ? this.tasks.filter((item) => item.SectionId === null)
            : this.tasks.filter((item) => item.SectionId === section.Id);
        computedSections[i] = {
          ...section,
          tasks: tasks,
          isVirtualSection: section.Name === "virtualSection",
        };
      }
      return computedSections;
    },
  },
  watch: {
    sort: function () {
      // todo sort here
    },
  },
  mounted() {
    this.getTasks();
    Api.getSections(this.groupId).then((res) => {
      this.sections = res.data.items;
    });
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup();
      }
    },
    createTaskToRequestRelation(taskId) {
      this.$emit("onTaskToRequestAssign", taskId);
    },
    handleParentSelect(data) {
      this.$emit("onParentTaskSelect", data);
    },
    toggleSort() {
      if (!this.sort.active) {
        this.sort.active = true;
      } else if (this.sort.type === "asc") {
        this.sort.type = "desc";
      } else {
        this.sort.type = "asc";
        this.sort.active = false;
      }
    },
    getTasks() {
      this.preloader = true;
      this.clearTasks();
      let data = {
        "params[GroupId]": this.groupId,
      };
      if (this.search.length) {
        data = {
          ...data,
          "params[TaskName]": this.search,
        };
      }
      Api.getTasks(data).then((res) => {
        this.tasks = res.data;
        this.preloader = false;
      });
    },
    clearTasks() {
      this.tasks = [];
    },
  },
};
</script>

<style scoped></style>
