export function getObjectTypeName(value) {
  switch (+value) {
    case 0:
      return "Торговый центр";
    case 1:
      return "Торгово-развлекательнцый центр";
    case 2:
      return "Бизнес-центр";
    case 3:
      return "Жилой комплекс";
    case 4:
      return "Парк/Зона отдыха";
    case 5:
      return "Спортивный комплекс";
    case 6:
      return "Склад/Промышленное предприятие";
    case 7:
      return "Больница";
    case 8:
      return "Административное здание";
    case 9:
      return "Другое";
  }
}

export function getStatusName(value) {
  switch (+value) {
    case 0:
      return "Ожидает";
    case 1:
      return "Отклонено";
    case 3:
      return "Одобрено";
  }
}
