var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-item",class:{
    'activity-item--system-message': _vm.isSystemMessage,
    'activity-item--additional-margin':
      _vm.isSystemMessage && _vm.nextItem && !+_vm.nextItem.NotifyComment,
    'activity-item--have-files': _vm.files.length,
  }},[_c('div',{staticClass:"activity-item__left"},[_c('UserAvatar',{attrs:{"user":_vm.item.SenderModel,"className":'activity-item__avatar'}})],1),_vm._v(" "),_c('div',{staticClass:"activity-item__right"},[_c('div',{staticClass:"activity-item__content__empty-place"}),_vm._v(" "),_c('div',{staticClass:"activity-item__content"},[_c('div',{staticClass:"activity-item__time"},[_vm._v(_vm._s(_vm.date))]),_vm._v(" "),_c('div',{staticClass:"activity-item__name",class:{
          'activity-item__name--clickable':
            _vm.item.TaskId && _vm.selectedTask.entityModel.Id !== _vm.item.TaskId,
        },on:{"click":function($event){_vm.item.TaskId &&
            _vm.selectedTask.entityModel.Id !== _vm.item.TaskId &&
            _vm.openSubTask(_vm.item.TaskId)}}},[_c('div',{staticClass:"activity-item__name-inner"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.files.length)?[_c('div',{staticClass:"task-events__files"},[_c('div',{staticClass:"activity-item__name-additional-text-info"},[_vm._v("\n              В задачу добавлены новые файлы:\n            ")]),_vm._v(" "),_c('div',{staticClass:"activity-item__files"},_vm._l((_vm.files),function(item,index){return _c('div',{key:index,staticClass:"activity-item__file-item"},[_c('a',{attrs:{"href":item,"target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(_vm.getFileName(item)))])])}))])]:(_vm.isSystemMessage)?_c('div',{staticClass:"activity-item__name-additional-text-info",domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()],2),_vm._v(" "),(!_vm.isSystemMessage)?_c('div',{staticClass:"activity-item__icon",style:({
          backgroundImage: "url(/img/tasks_icons/AAFBDB81-2604-421D-814B-32EAEB855D92.svg)",
        })}):_vm._e(),_vm._v(" "),(!_vm.isSystemMessage)?_c('div',{staticClass:"activity-item__text",domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()]),_vm._v(" "),(_vm.item.NewNotification)?_c('div',{staticClass:"activity-item__notification-text"},[_vm._v("\n      новое\n    ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }