import axios from "axios";
import { API_ROOT } from "@/config";
import * as _ from "lodash";

export default {
  get(page) {
    return axios.get(`${API_ROOT}api/site/${page}`);
  },
  create(page, data) {
    return axios.post(`${API_ROOT}api/site/${page}`, data);
  },
  edit(page, id, data) {
    return axios.post(`${API_ROOT}api/site/${page}/${id}`, data);
  },
  delete(page, id) {
    return axios.delete(`${API_ROOT}api/site/${page}/${id}`);
  },
  getSettings() {
    return axios.get(`${API_ROOT}api/site/settings/GetSettings`);
  },
  saveSettings(data) {
    return axios.post(`${API_ROOT}api/site/settings/saveSettings`, data);
  },
  getBlocks() {
    return axios.get(`${API_ROOT}api/site/blocks`);
  },
  createBlock(data) {
    return axios.post(`${API_ROOT}api/site/blocks`, data);
  },
  getCities() {
    return axios.get(`${API_ROOT}api/site/cities`);
  },
  getWorkTypes() {
    return axios.get(`${API_ROOT}api/site/examples/ListWorkTypes`);
  },
  createWorkType(data) {
    const formData = new FormData();
    formData.append("Name", data.Name);
    return axios.post(`${API_ROOT}api/site/examples/CreateWorkType`, formData);
  },
  editWorkTypes(data) {
    const formData = new FormData();
    formData.append("Name", data.Name);
    return axios.post(
      `${API_ROOT}api/site/examples/CreateWorkType?id=${data.Id}`,
      formData
    );
  },
  deleteWorkTypes(data) {
    return axios.delete(
      `${API_ROOT}api/site/examples/CreateWorkType?id=${data.Id}`
    );
  },
  getBlogCategories() {
    return axios.get(`${API_ROOT}api/site/BlogCategories`);
  },
  createBlogCategory(data) {
    const formData = new FormData();
    formData.append("CategoryName", data.CategoryName);
    return axios.post(`${API_ROOT}api/site/BlogCategories`, formData);
  },
  editBlogCategories(data) {
    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("CategoryName", data.CategoryName);
    return axios.post(
      `${API_ROOT}api/site/blogCategories/${data.Id}`,
      formData
    );
  },
  deleteBlogCategories(data) {
    return axios.delete(`${API_ROOT}api/site/BlogCategories/${data.Id}`);
  },
  removeSlide(module, slideId) {
    return axios.get(
      `${API_ROOT}api/site/${module}/DeleteSlider?Id=${slideId}`
    );
  },
  updateSlideOrder(module, data) {
    const formData = new FormData();
    _.each(data, (i) => {
      formData.append("SlidersIds[]", i.Id);
    });
    return axios.post(`${API_ROOT}api/site/${module}/UpdateOrder`, formData);
  },
  getAdditionalDataForSolutions(objectName, id) {
    return axios.get(`${API_ROOT}api/site/${objectName}/?solutionId=${id}`);
  },
  deleteAdditionalDataForSolutions(objectName, id) {
    return axios.delete(`${API_ROOT}api/site/${objectName}/${id}`);
  },
  createAdditionalDataForSolutions(data, id, objectName) {
    return axios.post(`${API_ROOT}api/site/${objectName}/`, data);
  },
  setAdditionalDataForSolutions(data, id, objectName) {
    return axios.post(`${API_ROOT}api/site/${objectName}/${id}`, data);
  },
  getAdditionalDataForProductions(objectName, id) {
    return axios.get(`${API_ROOT}api/site/${objectName}/?productId=${id}`);
  },
  deleteAdditionalDataForProductions(objectName, id) {
    return axios.delete(`${API_ROOT}api/site/${objectName}/${id}`);
  },
  createAdditionalDataForProductions(data, id, objectName) {
    return axios.post(`${API_ROOT}api/site/${objectName}/`, data);
  },
  setAdditionalDataForProductions(data, id, objectName) {
    return axios.post(`${API_ROOT}api/site/${objectName}/${id}`, data);
  },
  getProductionCategories() {
    return axios.get(`${API_ROOT}api/site/productionCategories`);
  },
  getTranslation(objectName, objectId) {
    return axios.get(
      `${API_ROOT}api/site/Translations/?EntityName=${objectName}&EntityId=${objectId}`
    );
  },
  setTranslation(id, data) {
    return axios.post(`${API_ROOT}api/site/Translations/${id}`, data);
  },
  setCommonTranslation(id, data) {
    return axios.post(`${API_ROOT}api/site/commonTranslations/${id}`, data);
  },
  updateEntityOrder(module, data) {
    const formData = new FormData();
    _.each(data, (i) => {
      formData.append("Ids[]", i.Id);
    });
    return axios.post(`${API_ROOT}api/site/${module}/UpdateOrder`, formData);
  },
  getBaseUrl() {
    return axios.get(`${API_ROOT}api/site/baseSite/getDomain/`);
  },
  importLang(data) {
    return axios.post(
      API_ROOT + "api/site/commonTranslations/importCommonTranslations",
      data
    );
  },
  getDistricts() {
    return axios.get(`${API_ROOT}api/site/districts`);
  },
};
