<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Итоговая смета и условия</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs">
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 1"
          >
            Виды работ
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 2"
          >
            Доставки
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 3"
          >
            Условия оплаты
          </div>
        </div>

        <template v-if="tab === 1">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <p class="table__text">Наименование</p>
                  </div>
                </div>
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <p class="table__text">Минимальная стоимость</p>
                  </div>
                </div>
              </div>

              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.total.types"
                :key="index"
              >
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.cost" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  @click="actionTotal('remove', 'types', item.id)"
                  v-if="index > 2"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionTotal('add', 'types')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 2">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <p class="table__text">Наименование</p>
                  </div>
                </div>
              </div>

              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.total.delivery"
                :key="index"
              >
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionTotal('remove', 'delivery', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionTotal('add', 'delivery')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 3">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <p class="table__text">Наименование</p>
                  </div>
                </div>
              </div>

              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.total.payment"
                :key="index"
              >
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 3"
                  @click="actionTotal('remove', 'payment', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionTotal('add', 'payment')"
            >+ Добавить ещё</span
          >
        </template>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(10, false, false)">Превью</span>
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import { randomId } from "../../../../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      tab: 1,
    };
  },

  methods: {
    actionTotal(action, type, id) {
      if (action === "add") {
        if (type === "types") {
          this.settingsObject.json.total.types.push({
            id: this.settingsObject.json.total.types.length + 1,
            name: "",
            cost: 0,
            convertCost: "",
            randomId: randomId(),
          });
        } else {
          this.settingsObject.json.total[type].push({
            id: this.settingsObject.json.total[type].length + 1,
            name: "",
            randomId: randomId(),
          });
        }
      } else if (action === "remove") {
        if (type === "types") {
          this.settingsObject.json.total.types = _.reject(
            this.settingsObject.json.total.types,
            { id }
          );
          this.settingsObject.json.total.types = _.each(
            this.settingsObject.json.total.types,
            (item, index) => {
              item.id = index + 1;
              return item;
            }
          );
        } else {
          this.settingsObject.json.total[type] = _.reject(
            this.settingsObject.json.total[type],
            { id }
          );
          this.settingsObject.json.total[type] = _.each(
            this.settingsObject.json.total[type],
            (item, index) => {
              item.id = index + 1;
              return item;
            }
          );
        }
      }
    },
  },
};
</script>
