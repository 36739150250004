<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'regions'"
            ref="translated-field-Name"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Адрес:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Address"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Address'"
            :OriginalValue="fields.Address"
            :inputType="'input'"
            :module="'regions'"
            ref="translated-field-Address"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Номер поддержки:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SupportPhone"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SupportPhone'"
            :OriginalValue="fields.SupportPhone"
            :inputType="'input'"
            :module="'regions'"
            ref="translated-field-SupportPhone"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Номер отдела продаж:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SalesPhone"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SalesPhone'"
            :OriginalValue="fields.SalesPhone"
            :inputType="'input'"
            :module="'regions'"
            ref="translated-field-SalesPhone"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Области:
        </span>
        <span class="field__content">
          <multiselect
            v-model="fields.districts"
            :options="orderedDistricts"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выбрать"
            :searchable="false"
            track-by="Id"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.Name }} </span>
              </div>
            </template>
            <template slot="tag" slot-scope="{ option }"
              ><span class="multiselect__tag"
                ><span>{{ getOptionName(option) }}</span></span
              ></template
            >
          </multiselect>
        </span>
      </label>

      <label class="field cm-calendar-input">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          отключить Calltouch:
        </span>
        <span class="field__content">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              v-model="fields.HideCallTouch"
              v-bind:true-value="'1'"
              v-bind:false-value="'0'"
              id="UpdatePinDist"
            />
            <label class="onoffswitch-label" for="UpdatePinDist">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Calltouch classname:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.AdditionalClass" />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="saveRegions"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import { mapState } from "vuex";
import axios from "axios";
import { API_ROOT } from "../../../config";
import { showAlert } from "../../../utils";
import * as _ from "lodash";

export default {
  mixins: [CommercialSitePopupFields],
  computed: {
    orderedDistricts() {
      return _.orderBy(this.districts, ["Name"], ["asc"]);
    },
    ...mapState("site", ["districts"]),
  },
  methods: {
    saveRegions() {
      if (this.id !== "create") {
        const arr = [];
        if (this.fields.districts && this.fields.districts.length) {
          const formData = new FormData();

          _.each(this.fields.districts, (i) => {
            if (!i.DistrictId) {
              arr.push(i.Id);
              formData.append("DistrictsIds[]", i.Id);
            }
          });
          if (arr.length) {
            formData.append("RegionId", this.id);
            axios
              .post(
                API_ROOT + "api/site/regions/addDistrictsToRegion",
                formData
              )
              .then((res) => {
                if (res.data.status !== "success") {
                  showAlert(res.data.status, res.data.message);
                }
              });
          }
        }
      }
      this.save();
    },
    getOptionName(option) {
      if (option.Name) return option.Name;
      const found = _.find(this.districts, ["Id", option.DistrictId]);
      if (found) return found.Name;
    },
  },
};
</script>

<style scoped></style>
