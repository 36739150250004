<template>
  <div class="task-files-list">
    <div v-if="requestFiles && requestFiles.length">
      <div
        class="task-files-list__item"
        v-for="(item, index) in requestFiles"
        :key="index"
      >
        <div
          class="task-files-list__img"
          v-if="item.file && checkFile(item.name)"
          @click="showImagePopup(apiUrl + item.file.replace('api2', 'api'))"
        >
          <img :src="apiUrl + item.file.replace('api2', 'api')" alt="file" />
        </div>
        <div class="task-files-list__img task-files-list__img--file" v-else>
          <img :src="getNonImageTypeFileIcon(item.name)" />
        </div>

        <div class="task-files-list__info">
          <div class="task-files-list__left">
            <div class="task-files-list__name" v-if="fileIsImage(item.name)">
              {{ item.name }}
            </div>
            <a
              class="task-files-list__name"
              :href="getFileSrc(item.file.replace('api2', 'api'))"
              download
              @click.prevent="
                downloadURI(
                  getFileSrc(item.file.replace('api2', 'api')),
                  item.name
                )
              "
              v-else
              >{{ item.name }}</a
            >
            <div class="task-files-list__date">
              {{ item.created | formatLocalDate }}
            </div>
          </div>
          <div class="task-files-list__right">
            <div class="task-files-list__person task-table-person">
              <UserAvatar :userId="item.sender" />
              {{ getPersonNameById(item.sender) | shortName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import UserAvatar from "../../../../tasks/components/CommonComponents/UserAvatar";
import { mapGetters } from "vuex";
export default {
  components: { UserAvatar },
  mixins: [common],
  name: "RequestFilesTab",
  props: ["checkFile", "apiUrl", "download"],
  computed: {
    ...mapGetters("rpsSupport", ["messages", "requestFiles"]),
  },
};
</script>

<style scoped></style>
