<template>
  <div class="cm-popup__right">
    <div class="header task-header cm-header" v-if="selectedItems.length">
      <div class="header__col cm-group-actions">
        <div class="button-group">
          <div
            class="button button-close button_grey button_uppercase"
            @click="unselectItems()"
          >
            <img src="/static/images/sales/close.svg" alt="" />
          </div>
          <div class="button button_grey button_uppercase">
            {{ selectedItems.length }}
          </div>
        </div>
        <div
          class="button button_grey button_uppercase"
          style="margin-left: auto"
          @click="deleteItems()"
        >
          удалить
        </div>
      </div>
    </div>

    <div class="task-popup-header" v-else>
      <div class="actions-header__col">
        <a
          href="#"
          class="button button_green button_uppercase"
          @click="$emit('selectItemsInnerPopupOpen')"
          >Добавить</a
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          method="post"
          action="#"
          class="search-bar search-bar_white actions-header__search-bar"
        >
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Поиск"
            class="search-bar__input"
            v-model="search"
          />
        </form>
      </div>
    </div>

    <div class="task-popup-list">
      <div
        class="task-popup-list__row"
        v-for="(item, index) in filteredGroupUsers"
        :key="index"
      >
        <label
          class="checkbox checkbox_white table__checkbox js-index-checkbox"
        >
          <input
            class="checkbox__input main_checkbox_input"
            type="checkbox"
            name="row_checkbox_clients"
            @click="selectItem($event, item.Id)"
          /><span class="checkbox__box main_checkbox_span">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
        </label>
        <span class="task-popup-list__row-name">{{ item.User.Fio }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import * as c from "../../../../store/tasks/constants";
import * as _ from "lodash";

export default {
  data() {
    return {
      selectedItems: [],
      search: "",
    };
  },
  computed: {
    filteredGroupUsers() {
      return _.filter(this.group.Users, (i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.Name);
      });
    },
    ...mapState("tasks", ["users"]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.selectedItems.includes(id)) {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    deleteItems() {
      this.selectedItems.forEach((id) => {
        this[c.DELETE_USER_FROM_GROUP](id);
        if (id === this.selectedItems[this.selectedItems.length - 1]) {
          this.unselectItems();
        }
      });
    },
    unselectItems() {
      this.selectedItems = [];
      document
        .querySelectorAll(".task-popup-list .checkbox__input")
        .forEach((i) => {
          i.checked = false;
        });
    },
    ...mapActions("tasks", [c.DELETE_USER_FROM_GROUP]),
  },
};
</script>

<style scoped></style>
