<template>
  <div class="task-detail-wrap">
    <div
      class="popup task-popup event-popup event-popup_active task-popup-parent popup task-popup-detail"
      :class="{
        'task-popup--loading': preloader,
        'task-popup-parent--closed':
          loadedTask && loadedTask.entityModel.Status === '2',
      }"
      :task-detail-wrapper="loadedTask ? loadedTask.entityModel.Id : ''"
      v-popup-inner-windows="{
        state:
          parentData.subtaskIdForOpen ||
          parentData.taskCreatePopupOpen ||
          parentData.automationCreatePopupOpen,
        level: getPopupLevel(),
        forceOffset:
          parentData.automationCreatePopupOpen ||
          parentData.taskCreatePopupOpen,
        sumLevel: commonModuleSettings.taskDetailPopupsCount,
      }"
      ref="popup"
    >
      <div
        class="popup__wrapper"
        @mousedown="closePopupByClickWrap($event)"
        ref="popup__wrapper"
      >
        <div
          class="popup__content"
          :class="{
            'popup__content--wide':
              loadedTask &&
              loadedTask.entityModel.ProcessId &&
              loadedTask.entityModel.childs.length,
          }"
          ref="taskFilesList"
          @dragover="showDragZone()"
          @dragleave="hideDragZone"
        >
          <div
            class="task-detail-spinner"
            :style="{ opacity: preloader ? 1 : 0 }"
          >
            <Spinner
              :noFadeIn="true"
              name="ball-scale-ripple"
              color="rgba(3, 83, 164, 0.44)"
              width="60"
              height="60"
            />
          </div>
          <div class="popup__header">
            <div class="task-settings">
              <button
                class="task-settings__btn"
                aria-label="настройки"
                @click="showSettings = true"
              >
                <svg width="4" height="16">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#dots-icon"
                  ></use>
                </svg>
              </button>
              <TaskSettingsList
                v-if="showSettings"
                v-click-outside="closeSettings"
                @closeSettings="closeSettings"
                :loadedTask="loadedTask"
                :parentData="parentData"
              />
            </div>
            <!--            <priority-select :item="selectedTask.entityModel" v-if="selectedTask"/>-->

            <div class="popup__heading-wrap" v-if="loadedTask">
              <div class="task-breadcrumbs" :title="breadcrumbsText">
                <a @click="goToGroup" v-if="taskGroup">
                  {{ taskGroup.GroupName }}
                </a>
                \
                <a
                  @click="goToParentTask(item.Id)"
                  v-for="(item, index) in loadedTask.entityModel.Breadcrumbs"
                  :key="index"
                >
                  {{ item.TaskName }} \
                </a>
              </div>
              <div class="popup__heading">
                <svg
                  class="popup__heading__lock-icon"
                  width="14"
                  height="17"
                  v-if="loadedTask.entityModel.TaskAccess === '2'"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/tasks/sprite.svg#lock-pink-icon"
                  ></use>
                </svg>
                <input
                  type="text"
                  v-if="loadedTask && !openInner"
                  v-model="loadedTask.entityModel.TaskName"
                  @blur="editTask"
                />
                <input
                  type="text"
                  v-else-if="openInner"
                  :value="loadedTask.entityModel.TaskName"
                />
                <!--<span class="task-label task-label&#45;&#45;3" v-if="selectedTask && selectedTask.entityModel.Status === '2'">Задача закрыта</span>-->
              </div>
            </div>

            <span
              class="task-label task-label--2"
              v-if="loadedTask && loadedTask.approveRecordId"
              >Внимание! Задача не принята</span
            >

            <div class="popup__close" @click="closePopup">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body" v-if="loadedTask">
            <div class="cm-popup">
              <left-side-control
                :tab="tab"
                :loadedTask="loadedTask"
                :taskGroup="taskGroup"
                @changeTab="(value) => (tab = value)"
              />
              <div
                class="cm-popup__right task-popup-detail__right"
                :class="{
                  'task-popup-detail__right--no-input':
                    !checkUserTaskRight('write_comments_to_task') ||
                    (loadedTask &&
                      loadedTask.entityModel.EnableComments === '0'),
                }"
                v-if="tab === 1"
                @scroll="captureScrollEvent"
              >
                <div class="tasks-progress-bar" v-if="progressFilesUpload">
                  <span :style="{ width: progressFilesUpload + '%' }"></span>
                </div>
                <short-view-tab
                  :shortViewTypeByScroll="shortViewTypeByScroll"
                  :parentData="parentData"
                  :needToShowAdditionalControl="needToShowAdditionalControl"
                  :docFiles="docFiles"
                  :commonFiles="commonFiles"
                  :loadedTask="loadedTask"
                  @filesChange="filesChange"
                  @editTask="editTask"
                  :commentsControl="$refs['comments-control']"
                />

                <template>
                  <description-input
                    v-if="checkUserTaskRight('view_task_description')"
                    :loadedTask="loadedTask"
                    @filesChange="filesChange"
                    @editTask="editTask"
                  />
                  <portal-target name="address-map-portal"></portal-target>

                  <address-input
                    v-if="
                      parentData.showAddressField &&
                      checkUserTaskRight('view_task_files')
                    "
                    :loadedTask="loadedTask"
                    ref="addressField"
                    @editTask="editTask"
                  />

                  <checklist
                    v-if="
                      parentData.showChecklistField &&
                      checkUserTaskRight('access_to_microtasks')
                    "
                    :loadedTask="loadedTask"
                    ref="checklistField"
                    :parentData="parentData"
                    @setCheckListDragging="
                      (value) => (parentData.checkListDragging = value)
                    "
                  />
                  <contacts-input
                    v-if="
                      parentData.showContactsField &&
                      checkUserTaskRight('view_task_contacts')
                    "
                    :parentData="parentData"
                    :loadedTask="selectedTask"
                    ref="contactsField"
                  />
                  <files-input
                    v-if="
                      commonFiles.length &&
                      checkUserTaskRight('view_task_files')
                    "
                    :files="commonFiles"
                    :loadedTask="loadedTask"
                    @filesChange="filesChange"
                  />
                  <doc-files-input
                    v-if="
                      docFiles.length &&
                      checkUserTaskRight('view_docs_for_sign')
                    "
                    :files="docFiles"
                    :loadedTask="loadedTask"
                    @filesChange="filesChange"
                  />

                  <div
                    class="task-create-form__row task-create-form__row--info"
                    v-click-outside="closeAdditionalData"
                    v-if="
                      checkUserTaskRight('add_edit_task_contacts') &&
                      needToShowAdditionalControl
                    "
                  >
                    <div class="task-create-form__row-left">
                      <i
                        class="task-form-icon task-popup-info__icon"
                        :class="{
                          'task-popup-info__icon--active':
                            parentData.addAdditionalData,
                        }"
                        @click="
                          parentData.addAdditionalData = !parentData.addAdditionalData
                        "
                      ></i>
                    </div>
                    <div class="task-create-form__row-right">
                      <div style="display: flex; width: 100%">
                        <div
                          class="task-create-form__blue-title"
                          @click="parentData.addAdditionalData = true"
                          v-if="!parentData.addAdditionalData"
                        >
                          Добавить данные
                        </div>
                        <template v-else>
                          <div
                            class="task-popup-info__field"
                            v-if="
                              !parentData.showAddressField &&
                              checkUserTaskRight('add_edit_task_address')
                            "
                            @click="makeAddressFieldShow"
                          >
                            <i class="task-popup-info-icon-1"></i>
                            Адрес
                          </div>
                          <div
                            class="task-popup-info__field"
                            v-if="!parentData.showChecklistField"
                            @click="makeChecklistShow"
                          >
                            <i class="task-form-icon task-form-icon--7">
                              <svg width="14" height="14" stroke="#C7C7C7">
                                <use
                                  xlink:href="/static/images/tasks/sprite.svg#check-icon"
                                ></use>
                              </svg>
                            </i>
                            Чек лист
                          </div>
                          <div
                            class="task-popup-info__field"
                            v-if="!parentData.showContactsField"
                            @click="makeContactsShow"
                          >
                            <i class="task-popup-info-icon-2"></i>
                            Контактные данные
                          </div>
                          <label
                            class="task-popup-info__field"
                            v-if="!docFiles.length"
                          >
                            <input
                              type="file"
                              hidden
                              multiple
                              @change="filesChange($event.target.files, true)"
                              v-if="
                                checkUserTaskRight('add_edit_docs_for_sign')
                              "
                            />
                            <i class="task-form-icon--9"></i>
                            Документы на подпись
                          </label>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>

                <comments-control
                  v-if="checkUserTaskRight('view_task_comments') && loadedTask"
                  ref="comments-control"
                  @hideDragZone="hideDragZone"
                  :loadedTask="loadedTask"
                  :page="page"
                  :totalCountComments="totalCountComments"
                  @setTotalCountComments="(val) => (totalCountComments = val)"
                  @setPage="(value) => (page = value)"
                />
              </div>
              <SubTasksTab
                v-if="tab === 2"
                :loadedTask="loadedTask"
                :openInner="openInner"
                :parentData="parentData"
                ref="subtasksTab"
                @closePopup="closePopup"
              />
              <files-tab v-if="tab === 3" />
              <Activity v-if="tab === 4" :loadedTask="loadedTask" />
              <Automation v-if="tab === 5" :parentData="parentData" />
              <div class="dropzone-wrapper" v-if="filesDragZoneShow">
                <dropzone
                  ref="dropzone"
                  :text="'Файлы'"
                  :class="'dropzone--tab-' + tab"
                  v-if="
                    checkUserTaskRight('add_edit_tasks_files') &&
                    !parentData.checkListDragging
                  "
                  @filesChange="filesChange"
                />
                <dropzone
                  ref="dropzone"
                  :text="'Документы на подпись'"
                  :class="'dropzone--tab-' + tab"
                  v-if="
                    checkUserTaskRight('add_edit_docs_for_sign') &&
                    !parentData.checkListDragging &&
                    docFiles.length
                  "
                  @filesChange="filesChange($event, true)"
                />
              </div>
              <portal-target name="checklist-grouped-actions"></portal-target>
              <div class="inner-task-preloader" v-if="parentData.preloader">
                <Spinner
                  :noFadeIn="true"
                  name="pacman"
                  color="#F9AD1A"
                  width="60"
                  height="60"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="popupSlide" v-if="loadedTask">
      <TaskCreateForm
        v-if="parentData.taskCreatePopupOpen"
        :parentTask="parentData.subTask"
        :loadedTask="loadedTask"
        :section="parentData.sectionForTaskCreate"
        :inTaskDetail="true"
        :groupFromParams="taskGroup"
        @loadCreatedTasks="loadCreatedTasks"
        @onClosePopup="
          () => {
            parentData.taskCreatePopupOpen = false;
            parentData.sectionForTaskCreate = null;
            parentData.subTask = null;
          }
        "
      />
    </transition>

    <transition name="popupSlide">
      <AutomationCreateControl
        v-if="parentData.automationCreatePopupOpen"
        @onClosePopup="parentData.automationCreatePopupOpen = false"
      />
    </transition>
    <transition name="popupSlide">
      <AutomationAttachControl
        :tasks="parentData.automationAttachItems"
        v-if="parentData.automationAttachItems.length"
        @onClosePopup="parentData.automationAttachItems = []"
      />
    </transition>

    <transition name="popupSlide">
      <TaskDetail
        :idFromParent="parentData.subtaskIdForOpen"
        v-if="parentData.subtaskIdForOpen"
        :level="getPopupLevel()"
        @close="setParentToSelectedTask"
      />
    </transition>

    <transition name="popupSlide">
      <TasksListToTransfer
        v-if="parentData.tasksListToTransferPopupOpen"
        @onClosePopup="parentData.tasksListToTransferPopupOpen = false"
        :loadedTask="loadedTask"
      />
    </transition>

    <transition name="popupSlide">
      <CommentAnswersPopup
        v-if="settings.commentIdForAnswers"
        @onClosePopup="settings.commentIdForAnswers = false"
        :replyComment="settings.commentIdForAnswers"
        :loadedTask="loadedTask"
      />
    </transition>

    <portal-target multiple name="task-root-portal" />
  </div>
</template>

<script>
import * as c from "../../../../store/tasks/constants";
import { mapActions, mapState, mapMutations } from "vuex";
import common from "../../../../mixins/tasks/common";
// import moment from "moment";
import FilesTab from "./FilesTab/";
import Checklist from "./Checklist";
import SubTasksTab from "./SubTasksTab/";
import TaskCreateForm from "../TaskCreateForm/";
import CommentsControl from "./CommentsControl";
import Dropzone from "../CommonComponents/Dropzone";
import { Helpers } from "../../../../mixins/Helpers";
import LeftSideControl from "./LeftSideControl";
import DescriptionInput from "./Inputs/DescriptionInput";
import ShortViewTab from "./ShortViewTab";
import AddressInput from "./Inputs/AddressInput";
import FilesInput from "./Inputs/FilesInput";
import ContactsInput from "./Inputs/ContactsInput";
import Spinner from "vue-spinkit";
import DocFilesInput from "./Inputs/DocFilesInput";
import * as _ from "lodash";
import TaskSettingsList from "./TaskSettingsList/TaskSettingsList";
import Activity from "./Activity/Activity";
import TaskDetail from "./index";
import Automation from "../Automation/Automation";
import AutomationCreateControl from "../Automation/AutomationCreateControl/AutomationCreateControl";
import { prepareFetchedFiles } from "../../../../mixins/tasks/func";
import AutomationAttachControl from "../Automation/AutomationAttachControl/AutomationAttachControl";
import TasksListToTransfer from "./TaskSettingsList/TasksListToTransfer";
import CommentAnswersPopup from "@/modules/tasks/components/TaskDetail/CommentsControl/CommentAnswersPopup";
import {
  GET_COMMENTS_FOR_TASK,
  GET_GROUP_BY_ID,
  SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT,
} from "../../../../store/tasks/constants";
import Api from "@/api/tasks";

export default {
  name: "TaskDetail",
  props: ["idFromParent", "level"],
  mixins: [common, Helpers],
  data() {
    return {
      tab: 1,
      shortViewTypeByScroll: false,
      files: [],
      parentData: {
        checkListDragging: false,
        showAddressField: false,
        showContactsField: false,
        showChecklistField: true,
        addAdditionalData: false,
        subtaskIdForOpen: false,
        automationCreatePopupOpen: false,
        tasksListToTransferPopupOpen: false,
        automationAttachItems: [],
        preloader: false,
        gettingTasks: false,
        sections: [],
        sectionForTaskCreate: null,
        taskCreatePopupOpen: false,
        hideCompletedTasks: false,
        subTask: null,
      },
      loadedTask: null,
      preloader: false,
      openInner: false,
      taskId: null,
      routePrefix: "",
      filesDragZoneShow: false,
      showSettings: false,
      page: 0,
      totalCountComments: 0,
      taskGroup: null,
    };
  },
  created() {
    this[c.SET_PROGRESS_BAR_BY_FILES_UPLOAD](0);
    this[SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT](
      this.commonModuleSettings.taskDetailPopupsCount + 1
    );
    // this.taskId = this.$route.name === this.routePrefix+'task2' ? this.$route.params.id2 : this.$route.name ===  this.routePrefix+'task3' ? this.$route.params.id3 : this.$route.params.id0
    this.taskId = this.idFromParent
      ? this.idFromParent
      : window.location.pathname.split("/").pop();
    this.routePrefix = window.location.pathname.includes("support")
      ? "support-"
      : "";
    this.preloader = true;
    // setTimeout(() => {
    this.getTask(this.taskId);
    // }, 300);
    this[c.RESET_TASK_NOTIFICATION](this.taskId);
    this[c.GET_OBJECTS]();
  },
  computed: {
    needToShowAdditionalControl() {
      return (
        !this.parentData.showAddressField ||
        !this.parentData.showChecklistField ||
        !this.parentData.showContactsField ||
        !this.docFiles.length
      );
    },
    filteredFiles() {
      return this.loadedTask
        ? _.filter(this.loadedTask.files, ["CommentId", null])
        : [];
    },
    commonFiles() {
      return _.filter(this.filteredFiles, (i) => !+i.SignDocs);
    },
    docFiles() {
      return _.filter(this.filteredFiles, (i) => +i.SignDocs);
    },
    breadcrumbsText() {
      try {
        return this.loadedTask.entityModel.Breadcrumbs.map(
          (i) => `${i.TaskName}`
        )
          .join(",")
          .replaceAll(",", " \\ ");
      } catch (e) {
        return "";
      }
    },
    ...mapState("tasks", [
      "selectedTask",
      "progressFilesUpload",
      "localStorageSettings",
      "groups",
      "allGroups",
      "commonModuleSettings",
      "users",
    ]),
  },
  methods: {
    getTask(taskId) {
      const foundLocalSettings = _.find(this.localStorageSettings, {
        Id: taskId,
        Type: "task",
      });
      if (foundLocalSettings) {
        const settings = JSON.parse(foundLocalSettings.settings);
        this.parentData.hideCompletedTasks =
          settings.hideCompletedTasks !== void 0
            ? settings.hideCompletedTasks
            : false;
      }

      this[c.SHOW_TASK](taskId)
        .then(() => {
          this[GET_GROUP_BY_ID](this.selectedTask.entityModel.GroupId).then(
            (group) => (this.taskGroup = group)
          );
          if (this.selectedTask.entityModel.TaskAddress) {
            this.parentData.showAddressField = true;
          }
          if (
            !this.selectedTask.checklists.length ||
            (this.selectedTask.checklists.length === 1 &&
              (!this.selectedTask.checklists[0].tasks ||
                !this.selectedTask.checklists[0].tasks.length))
          ) {
            this.parentData.showChecklistField = false;
          }

          if (this.selectedTask.contacts.length) {
            this.parentData.showContactsField = true;
          }

          this.loadedTask = JSON.parse(JSON.stringify(this.selectedTask));

          if (this.loadedTask.entityModel.childs.length) {
            const idsArray = _.map(
              this.loadedTask.entityModel.childs,
              (i) => i.Id
            );
            this[c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY]({
              idsArray,
              push: false,
            });
          }

          this[c.GET_FILES_FOR_TASK](taskId).then((files) => {
            if (files) {
              this.loadedTask.files = files;
            }
          });

          // if (!this.users.length) {
          Api.getGroupUsers(this.loadedTask.entityModel.GroupId).then((res) => {
            this.selectedTask.taskGroupUsers = this.loadedTask.taskGroupUsers = res.data.map(
              (i) => {
                return {
                  ...i,
                  ...i.User,
                  Id: i.UserId,
                  FullName: i.User.Fio,
                };
              }
            );
          });
          // }
        })
        .catch(() => {
          this.closePopup();
        })
        .finally(() => (this.preloader = false));
    },
    closePopup() {
      if (this.idFromParent) {
        this.$emit("close");
        this[SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT](
          this.commonModuleSettings.taskDetailPopupsCount - 1
        );
      } else {
        if (this.routePrefix) {
          this.$router.push("/support");
        } else {
          this.$router.push("/tasks");
        }
        this[SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT](0);
        this[c.UNSELECT_TASK]();
      }
    },
    closePopupByClickWrap(event) {
      if (event.target === this.$refs.popup__wrapper) {
        this.closePopup();
      }
    },
    editTask() {
      const v = document.createElement("span");
      v.innerHTML = this.loadedTask.entityModel.TaskDescription;
      const users = [];
      _.each(this.findSubscribersFromText(v), (el) => {
        if (!_.find(this.loadedTask.subscriptions, ["UserId", el.UserId])) {
          users.push(el);
        }
      });
      // console.log(users)
      v.remove();
      users.forEach((el) => {
        this[c.ADD_SUBSCRIBER]({
          UserId: el.UserId,
          TaskId: this.loadedTask.entityModel.Id,
        });
      });

      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          TaskName: this.loadedTask.entityModel.TaskName,
          TaskDescription: this.loadedTask.entityModel.TaskDescription,
          TaskAddress: this.loadedTask.entityModel.TaskAddress,
          TaskLatitude: this.loadedTask.entityModel.TaskLatitude,
          TaskLongitude: this.loadedTask.entityModel.TaskLongitude,
          ObjectId: this.loadedTask.entityModel.ObjectId,
          // FinishDate: this.loadedTask.entityModel.FinishDate
          //   ? moment(this.loadedTask.entityModel.FinishDate).format(
          //       "YYYY-MM-DD HH:mm:ss"
          //     )
          //   : this.loadedTask.entityModel.FinishDate,
          Users: users,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    findSubscribersFromText(text) {
      let links = [];
      _.each(text.querySelectorAll("a"), (i) => {
        // console.log(text, i)
        const userId = i.getAttribute("href");
        if (userId.includes("userId=")) {
          links.push({
            UserId: userId.split("=")[1],
            commentPart: i.closest("p").innerHTML,
          });
        }
      });
      return links;
    },
    filesChange(fileList, forDocFiles = false) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
      this.setTaskFiles(forDocFiles);
      this.filesDragZoneShow = false;
    },
    setTaskFiles(forDocFiles) {
      if (this.files.length) {
        const formData = new FormData();
        _.each(this.files, (i) => {
          formData.append("taskFiles[]", i.file);
        });
        formData.append("TaskId", this.loadedTask.entityModel.Id);

        this.files = [];

        if (forDocFiles) {
          this[c.ADD_DOC_FILES_TO_TASK](formData).then((files) =>
            prepareFetchedFiles(files, this.loadedTask)
          );
        } else {
          this[c.ADD_FILES_TO_TASK](formData).then((files) =>
            prepareFetchedFiles(files, this.loadedTask)
          );
        }
      }
    },
    closeAdditionalData() {
      this.parentData.addAdditionalData = false;
    },
    makeAddressFieldShow() {
      this.parentData.showAddressField = true;
      this.parentData.addAdditionalData = false;
      setTimeout(() => {
        const el = this.$refs["addressField"].$el;
        el.querySelector(".task-create-form__field-text").focus();
        document.querySelector(".task-popup-detail__right").scrollTop =
          el.offsetTop - 45;
      }, 200);
    },
    makeChecklistShow() {
      this.parentData.showChecklistField = true;
      this.parentData.addAdditionalData = false;
      setTimeout(() => {
        const el = this.$refs["checklistField"].$el;
        document.querySelector(".task-popup-detail__right").scrollTop =
          el.offsetTop - 45;
      }, 200);
    },
    makeContactsShow() {
      this.parentData.showContactsField = true;
      this.parentData.addAdditionalData = false;
      // this.$children
      setTimeout(() => {
        this.$refs["contactsField"].addContact();
        const el = this.$refs["contactsField"].$el;
        document.querySelector(".task-popup-detail__right").scrollTop =
          el.offsetTop - 45;
      }, 200);
    },
    showDragZone() {
      if (this.tab !== 2 && !this.parentData.checkListDragging) {
        this.filesDragZoneShow = true;
      }
    },
    hideDragZone() {
      this.filesDragZoneShow = false;
    },
    captureScrollEvent(e) {
      function isScrolledIntoView(parent, elem) {
        var docViewTop = parent.scrollTop;
        var docViewBottom = docViewTop + parent.clientHeight;

        var elemTop = elem.offsetTop;
        var elemBottom = elemTop + elem.clientHeight;

        return elemTop <= docViewTop && docViewBottom < elemBottom;
      }

      if (
        this.$refs["comments-control"] &&
        !this.$refs["comments-control"].settingScroll
      ) {
        this.shortViewTypeByScroll = isScrolledIntoView(
          e.target,
          this.$refs["comments-control"].$el
        );
      }
      // console.log(isScrolledIntoView(e.target, this.$refs['comments-control'].$el))
    },
    closeSettings() {
      this.showSettings = false;
    },
    setParentToSelectedTask() {
      this.parentData.subtaskIdForOpen = false;
      this[c.SET_TASK](this.loadedTask);
    },
    getPopupLevel() {
      return this.level === void 0 ? 1 : this.level + 1;
    },
    goToGroup() {
      const sidebar = this.$parent.$children[0].$children[0].$children[1];
      sidebar.changeGroup(this.taskGroup);
      this.closePopup();
    },
    goToParentTask(id) {
      if (this.idFromParent) {
        // this.$emit("close");
        this.$router.push("/tasks");
        this.$nextTick(() => {
          this.$router.push("/tasks/" + id);
        });
      } else {
        this.parentData.subtaskIdForOpen = id;
        window.history.pushState({}, document.title, "/tasks/" + id);
      }
    },
    loadCreatedTasks() {
      console.log("123123");
      console.log(this.$refs.subtasksTab);
      this.$refs.subtasksTab.loadSectionTasks();
    },
    ...mapActions("tasks", [
      c.SHOW_TASK,
      c.EDIT_TASK,
      c.ADD_FILES_TO_TASK,
      c.ADD_DOC_FILES_TO_TASK,
      c.ADD_SUBSCRIBER,
      c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY,
      c.GET_CHILD_TASKS_DETAIL,
      c.GET_PARENT_TASK_SECTIONS,
      c.GET_OBJECTS,
      c.GET_FILES_FOR_TASK,
      GET_COMMENTS_FOR_TASK,
      GET_GROUP_BY_ID,
    ]),
    ...mapMutations("tasks", [
      c.UNSELECT_TASK,
      c.SET_TASK,
      c.RESET_TASK_NOTIFICATION,
      c.SET_PROGRESS_BAR_BY_FILES_UPLOAD,
      SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT,
    ]),
  },
  components: {
    FilesTab,
    LeftSideControl,
    Checklist,
    SubTasksTab,
    TaskCreateForm,
    CommentsControl,
    Dropzone,
    DescriptionInput,
    ShortViewTab,
    AddressInput,
    FilesInput,
    ContactsInput,
    Spinner,
    DocFilesInput,
    TaskSettingsList,
    Activity,
    TaskDetail,
    Automation,
    AutomationCreateControl,
    AutomationAttachControl,
    TasksListToTransfer,
    CommentAnswersPopup,
  },
};
</script>

<style scoped></style>
