import * as c from "./constants";
import Api from "@/api/common";
import { togglePreloader, showAlert } from "@/utils";

export default {
  [c.SET_CURRENT_ROUTE](state, data) {
    state.commit(c.SET_CURRENT_ROUTE, data);
  },
  [c.TOGGLE_PRELOADER]: {
    root: true,
    handler(state, data) {
      state.commit(c.TOGGLE_PRELOADER, data);
    },
  },
  [c.TOGGLE_POPUP_LOCAL](state, data) {
    state.commit(c.TOGGLE_POPUP, data);
  },
  [c.TOGGLE_POPUP_IMAGE](state, image) {
    state.commit(c.TOGGLE_POPUP_IMAGE, image);
  },
  [c.SET_CLIENT_AUTH](state, isClient) {
    state.commit(c.SET_CLIENT_AUTH, isClient);
  },
  [c.TOGGLE_POPUP]: {
    root: true,
    handler(state, data) {
      state.commit(c.TOGGLE_POPUP, data);
    },
  },
  [c.SET_NOTIFICATIONS]: {
    root: true,
    handler(state, data) {
      state.commit(c.SET_NOTIFICATIONS, data);
    },
  },
  [c.SET_GETTING_NOTIFICATIONS_STATUS](state, data) {
    state.commit(c.SET_GETTING_NOTIFICATIONS_STATUS, data);
  },
  // eslint-disable-next-line no-unused-vars
  [c.GET_NOTIFICATIONS](state) {
    // const timerId = setInterval(() => {
    //   if (state.getters.canGetNotification) {
    //     Api.getNotifications()
    //       .then((res) => {
    //         state.dispatch(c.SET_NOTIFICATIONS, res.data.wait_client, {
    //           root: true,
    //         });
    //       })
    //       .catch((err) => {
    //         console.log("Ошибка получения данных с сервера", err);
    //       });
    //   } else {
    //     clearInterval(timerId);
    //   }
    // }, 10000);
    // Api.getNotifications().then((res) => {
    //   state.dispatch(c.SET_NOTIFICATIONS, res.data.wait_client, { root: true });
    // });
  },
  [c.SET_LOGIN](state, authData) {
    togglePreloader(state, true);
    return Api.login(authData)
      .then((res) => {
        state.commit(c.SET_LOGIN, true);
        showAlert("success", "Успешно", "Вход выполнен");
        state.commit(c.SET_CLIENT_AUTH, false);
        if (res.data.id !== void 0) {
          state.dispatch(c.GET_USER_DATA, res.data.id);
          Promise.resolve();
        }
        togglePreloader(state, false);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.response.data);
        togglePreloader(state, false);
        state.commit(c.SET_LOGIN, false);
      });
  },
  [c.GET_USER](state, userId) {
    Api.login(userId)
      .then((res) => {
        state.commit(c.GET_USER, res.data);
        state.commit(c.SET_CLIENT_AUTH, false);
        state.commit(c.SET_LOGIN, true);
      })
      .catch((err) => {
        console.log(err);
        state.commit(c.SET_LOGIN, false);
      });
  },
  [c.GET_USER_DATA](state, userId) {
    console.log("права");
    return Api.getUserData(userId)
      .then((res) => {
        state.commit(c.GET_USER_DATA, res.data);
        Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [c.CHANGE_USER_FORM](state, data) {
    state.commit(c.CHANGE_USER_FORM, data);
  },
  [c.CHANGE_USER_FORM_FIELD](state, data) {
    state.commit(c.CHANGE_USER_FORM_FIELD, data);
  },
  [c.GET_USERS_BY_RIGHT](state, payload) {
    return Api.getUsersByRight(payload);
  },
  [c.CHECK_GUEST]() {
    return Api.checkGuest();
  },
};
