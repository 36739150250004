import { render, staticRenderFns } from "./AttachTasksTasksList.vue?vue&type=template&id=7b1847ba&scoped=true&"
import script from "./AttachTasksTasksList.vue?vue&type=script&lang=js&"
export * from "./AttachTasksTasksList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1847ba",
  null
  
)

export default component.exports