import * as c from "../commercial_site/constants";
import { copy } from "../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  [c.GET_SETTINGS](state, data) {
    // if (!data.SiteBlocks) data.SiteBlocks = {
    //   full: {
    //     width: 1920
    //   },
    //   medium: {
    //     width: 768
    //   },
    //   low: {
    //     width: 500
    //   }
    // }
    if (data) state.settings = copy(data);
    else {
      state.settings = {
        SiteBenefits: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteExamplesWorks: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteFacts: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteNews: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteProduction: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteProductionCategories: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteProductionGallery: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteSliders: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteSolutions: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteServices: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },

        SiteSolutionsBenefits: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteSolutionsOpportunities: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteSolutionsSoft: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteProductionFunctionalImages: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteMainPageFooterFacts: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
        SiteBlogPosts: {
          full: {
            width: 1920,
          },
          medium: {
            width: 768,
          },
          low: {
            width: 500,
          },
        },
      };
    }

    if (state.settings.SiteBlogPosts === void 0) {
      state.settings.SiteBlogPosts = {
        full: {
          width: 1920,
        },
        medium: {
          width: 768,
        },
        low: {
          width: 500,
        },
      };
    }
  },
  [c.SET_BLOCKS](state, data) {
    state.blocks = data;
  },
  [c.GET_CITIES](state, data) {
    state.cities = data;
  },
  [c.WORK_TYPES_ACTION](state, data) {
    state.workTypeItems = data;
  },
  [c.CHANGE_WORK_TYPES_ARRAY](state, { id, data }) {
    state.workTypeItems = _.map(state.workTypeItems, (i) => {
      if (i.Id === id) {
        i.translation = data;
      }
      return i;
    });
  },
  [c.BLOG_CAT_ACTION](state, data) {
    state.blogCategoriesItems = data;
  },
  [c.CHANGE_BLOG_CAT_ARRAY](state, { id, data }) {
    state.blogCategoriesItems = _.map(state.blogCategoriesItems, (i) => {
      if (i.Id === id) {
        i.translation = data;
      }
      return i;
    });
  },
  [c.CHANGE_BLOCKS_ARRAY](state, { id, data }) {
    state.blocks = _.map(state.blocks, (i) => {
      if (i.Id === id) {
        i.translation = data;
      }
      return i;
    });
  },
  [c.EDIT_ELEMENT_IN_BLOCKS_ARRAY](state, { id, data, field }) {
    state.blocks = _.map(state.blocks, (i) => {
      if (i.Id === id) {
        i[field] = data;
      }
      return i;
    });
  },
  [c.CHANGE_CITIES_ARRAY](state, { id, data }) {
    state.cities = _.map(state.cities, (i) => {
      if (i.Id === id) {
        i.translation = data;
      }
      return i;
    });
  },
  [c.GET_BASE_URL](state, data) {
    state.baseUrl = data;
  },
  [c.GET_DISTRICTS](state, data) {
    state.districts = data;
  },
};
