<template>
  <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style print--17">
    <span style="display: none">{{ (i = 0) }}</span>
    <template
      v-for="(module, moduleIndex) in [
        'Entry',
        'Exit',
        'Additional',
        'Payment',
      ]"
    >
      <div
        class="pdf-cont"
        v-for="(page, index) in optionPages[module].filter(
          (page) => page.length && optionPages[module]
        )"
        :key="'page' + index + moduleIndex"
      >
        <span style="display: none">{{ i++ }}</span>
        <div class="print">
          <div class="print__cont">
            <div class="print__logo">
              <img :src="getimgStaticSrc('sales/print-logo.svg')" />
            </div>
            <div
              class="print__title"
              :style="{
                backgroundImage: `url(${getimgStaticSrc(
                  'sales/print-badge.svg'
                )})`,
              }"
            >
              Описание опций <br />{{ getTitle(module) }}
            </div>

            <div
              class="print__info-s"
              v-for="(option, index) in page"
              :key="index"
              :id="'id' + option.randomId"
            >
              <div class="print__info-s-title" :id="'id' + option.anotherId">
                {{ option.name }}
              </div>
              <p>{{ option.desc }}</p>
            </div>
          </div>
          <div class="print__footer">{{ countPage("Options", i) }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {
    getTitle(module) {
      if (module === "Entry" || module === "Exit") {
        return "модуля въезда/выезда";
      } else if (module === "Payment") {
        return "модуля оплаты";
      } else {
        return "дополнительного оборудования";
      }
    },
  },
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
};
</script>
