import moment from "moment";
import { getObjectTypeName, getStatusName } from "./reservationHelpers";
import {
  GET_REQUESTS_OBJECTS,
  GET_RESERVED_OBJECTS,
} from "../../../store/sales_reservation/constants";
import { mapActions } from "vuex";

export const listMixin = {
  data() {
    return {
      queryParams: {
        page: 0,
        "params[Name]": "",
        orderBy: "Created DESC",
      },
    };
  },
  created() {
    this[this.getList](this.buildQueryString());
  },
  methods: {
    getObjectTypeName(value) {
      return getObjectTypeName(value);
    },
    getStatusName(value) {
      return getStatusName(value);
    },
    loadMore: function () {
      this.queryParams.page += 20;
      this[this.getList](this.buildQueryString());
    },
    buildQueryString() {
      return {
        ...this.queryParams,
      };
    },
    search() {
      this.queryParams.page = 0;
      this[this.getList](this.buildQueryString());
    },
    changeOrder() {
      this.queryParams.page = 0;
      this.queryParams.orderBy =
        this.queryParams.orderBy === "Created DESC"
          ? "Created ASC"
          : "Created DESC";
      this[this.getList](this.buildQueryString());
      document.querySelector(".table__row-wrap--tasks").scrollTop = 0;
    },
    ...mapActions("reserve", [GET_RESERVED_OBJECTS, GET_REQUESTS_OBJECTS]),
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(date) {
      return moment(date).format("HH:mm:ss");
    },
  },
};
