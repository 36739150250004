import * as c from "../constants";
import Api from "@/api/support/rps";
import * as _ from "lodash";
import { showAlert } from "@/utils";
import axios from "axios";
export default {
  [c.GET_COUNT](state, start = false) {
    const timerId = setInterval(() => {
      if (state.getters.countInterval) {
        Api.getCount()
          .then((res) => {
            // !!!
            // console.log(res.data[state.getters.activeTab] !== state.getters.count[state.getters.activeTab], res.data[state.getters.activeTab] , state.getters.count[state.getters.activeTab])
            // if (res.data[state.getters.activeTab] !== state.getters.count[state.getters.activeTab]) {
            if (state.getters.activeTab !== "closed") {
              state.dispatch(c.GET_REQUESTS, {
                scope: state.getters.activeTab,
                start: false,
              });
            }
            // }
            state.commit(c.SET_COUNT, res.data);
            state.dispatch("SET_NOTIFICATIONS", res.data.wait_client, {
              root: true,
            });
          })
          .catch((err) => {
            console.log("Ошибка получения данных с сервера", err);
          });
      } else {
        clearInterval(timerId);
      }
    }, 30000);
    if (start) {
      Api.getCount().then((res) => {
        state.commit(c.SET_COUNT, res.data);
      });
    }
  },
  [c.TOGGLE_COUNT_INTERVAL](state, data) {
    state.commit(c.TOGGLE_COUNT_INTERVAL, data);
  },
  [c.SET_ACTIVE_TAB](state, data) {
    state.commit(c.SET_ACTIVE_TAB, data);
  },
  [c.CHANGE_SORT](state, sort) {
    state.commit(c.CHANGE_SORT, sort);
  },
  [c.GET_REQUESTS](state, { scope, start, force = false }) {
    if (
      force ||
      (state.getters.canLoadRequestList &&
        !state.getters.commonSettings.cancelRequest)
    ) {
      state.commit(c.TOGGLE_REQUESTS_LIST_LOAD_STATE, false);
      const requestCount =
        state.getters.activeTab === "closed"
          ? +state.getters.count.closed + +state.getters.count.wait_comment
          : +state.getters.count[scope];
      if (state.getters.requests[scope].length !== requestCount || start) {
        togglePreloader(state, { preloader: "list", status: false });
      }
      let isForDist = false;
      let requestType = "getRequests";
      let responsible = false;
      let without_responsible = false;
      let isFromDist = false;
      if (
        ["customer_to_dist_open", "customer_to_dist_closed"].includes(scope)
      ) {
        scope = scope === "customer_to_dist_open" ? "open" : "closed";
        requestType = "getRequestsForDist";
        isForDist = true;
      }
      if (["responsible"].includes(scope)) {
        scope = "open";
        responsible = true;
      }
      if (["without_responsible"].includes(scope)) {
        scope = "open";
        without_responsible = true;
      }
      if (["dist_to_admin_open"].includes(scope)) {
        scope = "open";
        isFromDist = true;
      }
      if (["dist_to_admin_closed"].includes(scope)) {
        scope = "closed";
        isFromDist = true;
      }
      //TODO other scopes
      let params = { scopes: scope };
      if (responsible) {
        params = { ...params, manager: state.rootState.common.userData.Id };
      }
      if (without_responsible) {
        params = { ...params, manager: "" };
      }
      if (isFromDist) {
        params = { ...params, DistributorToAdmin: 1 };
      }
      if (
        state.getters.commonSettings.managerForFilter ||
        state.getters.commonSettings.managerForFilter === " "
      ) {
        params = {
          ...params,
          manager:
            state.getters.commonSettings.managerForFilter === " "
              ? ""
              : state.getters.commonSettings.managerForFilter,
        };
      }
      if (state.getters.commonSettings.objectForFilter) {
        params = {
          ...params,
          ObjectId: state.getters.commonSettings.objectForFilter,
        };
      }
      if (state.getters.commonSettings.statusForFilter) {
        params = {
          ...params,
          StatusId: state.getters.commonSettings.statusForFilter,
        };
      }
      if (
        state.getters.commonSettings.tagsForFilter &&
        state.getters.commonSettings.tagsForFilter.length
      ) {
        const ids = _.map(
          state.getters.commonSettings.tagsForFilter,
          (item) => {
            return item.Id;
          }
        );
        params = {
          ...params,
          tagsIds: ids,
        };
      }
      if (state.getters.page) params.page = state.getters.page;
      // let cancel = false
      // if (state.getters.commonSettings.cancelRequest) {
      //   cancel = true
      // }
      Api[requestType](params, state)
        .then((res) => {
          state.commit(c.GET_REQUESTS, {
            scope,
            requests: res.data,
            isForDist,
            responsible,
            without_responsible,
            isFromDist,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            state.commit(c.RESET_CANCEL_REQUEST_FUNCTION);
            console.log("Request canceled");
          } else {
            if (err.message !== "timeout of 5000ms exceeded") {
              showAlert(
                "error",
                "Ошибка",
                "Не удалось получить данные заявок с сервера"
              );
            }
            console.log(err);
          }
        })
        .finally(() => {
          togglePreloader(state, { preloader: "list", status: false });
          state.commit(c.TOGGLE_REQUESTS_LIST_LOAD_STATE, true);
        });
    }
  },
  [c.SET_SEARCH_INPUT](state, data) {
    state.commit(c.SET_SEARCH_INPUT, data);
  },
  [c.SET_SELECTED_REQUEST](state, { request, requestId }) {
    state.commit(c.SET_SELECTED_REQUEST, false);
    state.dispatch(c.SET_REQUEST_MESSAGES, []);
    state.commit(c.TOGGLE_MESSAGE_UPDATE_STATE, false);
    if (requestId) {
      togglePreloader(state, { preloader: "request", status: true });
      Api.getRequestMessages(requestId)
        .then((res) => {
          state.commit(c.SET_SELECTED_REQUEST, request);
          state.dispatch(c.SET_REQUEST_MESSAGES, res.data.messages);
          state.dispatch(c.GET_REQUEST_MESSAGES);
          state.commit(c.REMOVE_UNREAD_MARK, {
            scope: state.getters.activeTab,
            id: requestId,
          });
        })
        .catch((err) => {
          if (err.message !== "timeout of 5000ms exceeded") {
            showAlert("error", "Ошибка", "Не удалось получить данные заявки");
          }
          console.log(err);
        })
        .finally(() => {
          state.commit(c.TOGGLE_MESSAGE_UPDATE_STATE, true);
          togglePreloader(state, { preloader: "request", status: false });
        });
    } else {
      state.commit(c.SET_SELECTED_REQUEST, false);
    }
  },
  [c.SET_REQUEST_MESSAGES](state, data) {
    state.commit(c.SET_REQUEST_MESSAGES, data);
  },
  [c.UPDATE_REQUEST_MESSAGES](state, data) {
    state.commit(c.UPDATE_REQUEST_MESSAGES, data);
  },
  [c.GET_REQUEST_MESSAGES](state) {
    state.commit(c.GET_REQUEST_MESSAGES);
  },
  [c.SEND_MESSAGE](state, { requestId, message }) {
    Api.sendMessage(state, { requestId, message })
      .then((res) => {
        state.commit(c.UPDATE_REQUEST_MESSAGES, res.data);
        state.commit(c.SET_PROGRESS_BAR, null);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось отправить сообщение");
        state.commit(c.SET_PROGRESS_BAR, null);
        console.log(err);
      });
  },
  [c.CHANGE_REQUEST_STATUS](state, { requestId, status }) {
    state.commit(c.TOGGLE_REQUEST_UPDATE_STATE, false);
    state.commit(c.CHANGE_REQUEST_STATUS, {
      scope: state.getters.activeTab,
      id: requestId,
      status,
    });
    return new Promise((resolve, reject) => {
      Api.changeStatus({ requestId, status })
        .then((res) => {
          state.commit(c.SET_SELECTED_REQUEST, res.data);
          setTimeout(() => {
            state.commit(c.TOGGLE_REQUEST_UPDATE_STATE, true);
          }, 400);
          resolve("completed");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось изменить статус");
          console.log(err);
          setTimeout(() => {
            state.commit(c.TOGGLE_REQUEST_UPDATE_STATE, true);
          }, 400);
          reject("error occurred");
        });
    });
  },
  [c.ADD_TIME](state, timeData) {
    togglePreloader(state, { preloader: "request", status: true });
    Api.addTime(timeData)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Успешно", "Время добавлено");
        } else {
          showAlert("warning", "Предупреждение", res.data.message);
        }
        togglePreloader(state, { preloader: "request", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось добавить время");
        console.log(err);
        togglePreloader(state, { preloader: "request", status: false });
      });
  },
  [c.UPDATE_REQUEST](state, payload) {
    togglePreloader(state, { preloader: "request", status: true });
    Api.updateRequest(payload)
      .then(() => {
        showAlert("success", "Успешно");
        togglePreloader(state, { preloader: "request", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        togglePreloader(state, { preloader: "request", status: false });
      });
  },
  [c.GET_MANAGERS_PEOPLE_LIST](state) {
    Api.getManagersList().then((res) => {
      if (res.data.status === "success") {
        state.commit(c.GET_MANAGERS_PEOPLE_LIST, res.data.users);
      }
    });
  },
  [c.CHANGE_MANAGER](state, formData) {
    togglePreloader(state, { preloader: "request", status: true });
    Api.changeManager(formData)
      .then((res) => {
        showAlert(res.data.status, res.data.message);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      })
      .finally(() => {
        togglePreloader(state, { preloader: "request", status: false });
      });
  },
};
const togglePreloader = (state, data) => {
  state.commit(c.TOGGLE_PRELOADER, data);
};
