import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  getCategories() {
    return axios.get(`${API_ROOT}api/wiki/wikiCategories`);
  },
  createCategory(data) {
    return axios.post(`${API_ROOT}api/wiki/wikiCategories`, data);
  },
  editCategory(data, id) {
    return axios.post(`${API_ROOT}api/wiki/wikiCategories/${id}`, data);
  },
  deleteCategory(id) {
    return axios.delete(`${API_ROOT}api/wiki/wikiCategories/${id}`);
  },
  getArticles(params) {
    return axios.get(`${API_ROOT}api/wiki/wikiPosts`, { params });
  },
  searchArticles(params) {
    return axios.get(`${API_ROOT}api/wiki/wikiPosts/Search`, { params });
  },
  getChildArticles(params) {
    return axios.get(`${API_ROOT}api/wiki/wikiPosts/getChildPosts`, { params });
  },
  editArticle(data, id) {
    return axios.post(`${API_ROOT}api/wiki/wikiPosts/${id}`, data);
  },
  createArticle(data) {
    return axios.post(`${API_ROOT}api/wiki/wikiPosts`, data);
  },
  deleteArticle(id) {
    return axios.delete(`${API_ROOT}api/wiki/wikiPosts/${id}`);
  },
  createShareLink(params) {
    return axios.get(`${API_ROOT}api/wiki/wikiPosts/ToggleShareOption`, {
      params,
    });
  },
  readSharedArticle(slug) {
    return axios.get(
      `${API_ROOT}api/distributors/wikiRead/readPost?Slug=${slug}`
    );
  },
};
