export const sortsTemplate = {
  TaskName: {
    asc: false,
    active: false,
  },
  FinishDate: {
    asc: false,
    active: false,
  },
  Priority: {
    asc: false,
    active: false,
  },
  Activity: {
    asc: false,
    active: false,
  },
};
