<template>
  <div class="grouped-action-header" v-if="selectedItems.length > 1">
    <div
      class="grouped-action-header__item grouped-action-header__item--first-col"
      @click="$emit('resetSelectedItems')"
    >
      <div class="grouped-action-header__icon">
        <svg width="11" height="11">
          <use xlink:href="/static/images/sprite.svg#cross"></use>
        </svg>
      </div>
      <p>Выбрано: {{ selectedItems.length }}</p>
    </div>
    <div
      class="grouped-action-header__item"
      v-if="checkUserTaskRight('add_edit_checklists_tasks')"
      @click="personsBubbleOpen = true"
    >
      <div class="grouped-action-header__icon">
        <svg width="16" height="19">
          <use xlink:href="/static/images/sprite.svg#man"></use>
        </svg>
      </div>
      <p>Ответственный</p>
      <transition name="fade">
        <PersonsListSearch
          v-if="personsBubbleOpen"
          :emptyPlaceholder="'Убрать ответственного'"
          :className="'task-bubble--checklist-items'"
          @choose="setResponsible($event)"
          @close="personsBubbleOpen = false"
          v-click-outside="closePersonBubble"
        />
      </transition>
    </div>
    <div
      class="grouped-action-header__item"
      v-if="checkUserTaskRight('change_checklists_tasks_dates')"
    >
      <div class="grouped-action-header__icon">
        <svg width="19" height="19">
          <use xlink:href="/static/images/sprite.svg#date"></use>
        </svg>
      </div>
      <p>Дата</p>
      <date-input
        :format="'DD MMMM YYYY в HH:mm'"
        @setDate="(date, callback) => setDate(date)"
        :open="true"
        :afterConfirm="true"
        :placeholder="'Дата'"
      />
    </div>
    <div
      class="grouped-action-header__item"
      @click="doneItems"
      v-if="checkUserTaskRight('close_checklist_task')"
    >
      <div class="grouped-action-header__icon">
        <svg width="17" height="17" class="sprite-icon__check">
          <use xlink:href="/static/images/sprite.svg#check"></use>
        </svg>
      </div>
      <p>Завершить</p>
    </div>
    <div
      class="grouped-action-header__item"
      @click="removeItems"
      v-if="checkUserTaskRight('close_checklist_task')"
    >
      <div class="grouped-action-header__icon">
        <svg width="17" height="19">
          <use xlink:href="/static/images/sprite.svg#trash"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import PersonsListSearch from "../CommonComponents/PersonsListSearch";
import * as _ from "lodash";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import {
  DELETE_CHECKLIST_ITEM,
  EDIT_CHECKLIST_ITEM,
} from "../../../../store/tasks/constants";
import common from "../../../../mixins/tasks/common";

export default {
  mixins: [common],
  props: ["selectedItems", "parentData", "loadedTask"],
  data() {
    return {
      personsBubbleOpen: false,
    };
  },
  computed: {
    ...mapState("tasks", ["userData"]),
  },
  methods: {
    saveItem(item) {
      return new Promise((resolve, reject) => {
        const payload = {
          id: item.Id,
          data: item,
        };
        if (payload.data.Deadline)
          payload.data.Deadline = moment(payload.data.Deadline).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        if (payload.data.RealTimeFinish)
          payload.data.RealTimeFinish = moment(
            payload.data.RealTimeFinish
          ).format("YYYY-MM-DD HH:mm:ss");

        this[EDIT_CHECKLIST_ITEM](payload)
          .then(() => {
            resolve();
          })
          .catch(() => reject({}));
      });
    },
    setResponsible(id) {
      const arr = [];
      _.each(this.selectedItems, (i) => {
        if (
          i.ApproveRequestId &&
          i.ApproveRequestId.UserId === this.userData.Id
        ) {
          1;
        } else if (i.ApproveRequestId) {
          i.ApproveRequestId.UserId = id;
          arr.push(i);
        } else {
          i.ResponsibleId = id;
          arr.push(i);
        }
      });
      this.asyncFetchAction(arr);
    },
    closePersonBubble() {
      this.personsBubbleOpen = false;
    },
    setDate(date) {
      const arr = [];
      _.each(this.selectedItems, (i) => {
        if (i.Status !== "2") {
          i.Deadline = date;
          i.DeadlineTime = moment(date).format("HH:mm:ss");
          arr.push(i);
        }
      });
      this.asyncFetchAction(arr);
    },
    doneItems() {
      const arr = [];
      _.each(this.selectedItems, (i) => {
        i.Status = "2";
        arr.push(i);
      });
      this.asyncFetchAction(arr);
    },
    removeItem(item) {
      return new Promise((resolve, reject) => {
        this[DELETE_CHECKLIST_ITEM](item.Id)
          .then(() => {
            resolve();
          })
          .catch(() => reject({}));
      });
    },
    removeItems() {
      this.asyncFetchAction(this.selectedItems, true);
    },
    asyncFetchAction(arr, remove = false) {
      this.parentData.preloader = true;

      const self = this;

      function delay(item) {
        if (remove) return self.removeItem(item);
        return self.saveItem(item);
      }

      async function delayedLog(item) {
        await delay(item);
      }

      async function processArray(array) {
        for (const item of array) {
          await delayedLog(item);
        }
        // console.log('Done!');
        self.parentData.preloader = false;
        self.$emit("resetSelectedItems");
      }

      processArray(arr);
    },
    ...mapActions("tasks", [EDIT_CHECKLIST_ITEM, DELETE_CHECKLIST_ITEM]),
  },
  components: {
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
