<template>
  <div
    class="popup task-popup task-popup-inner event-popup event-popup_active"
    v-popup-inner-windows="{
      state: commonModuleSettings.taskCreateDetailPopupsCount,
      level: 0,
      forceOffset: true,
      sumLevel: 0,
    }"
  >
    <div
      class="popup__wrapper"
      @mousedown="closePopupByClickWrap($event)"
      ref="popup__wrapper"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">{{ TemplateName }}</h2>
          <div class="popup__close" @click="$emit('onClosePopup')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="task-create-form">
              <div class="task-create-form__row">
                <div class="task-create-form__row-left">
                  <i class="task-form-icon task-form-icon--1"></i>
                </div>
                <div class="task-create-form__row-right">
                  <div class="task-create-form__label" style="margin-top: 8px">
                    Название шаблона
                  </div>
                  <div class="task-create-form__field">
                    <input
                      type="text"
                      class="input"
                      placeholder="Например, выезд RPS Service"
                      v-model="TemplateName"
                      @change="save"
                    />
                  </div>
                </div>
              </div>
              <div class="task-create-form__row head-row">
                <div class="task-create-form__row-left">
                  <svg width="18" height="18">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#check-icon"
                    ></use>
                  </svg>
                </div>
                <div class="task-create-form__row-right">
                  <SimplePreloader v-if="preloader" />
                  <div class="task-create-form__label">
                    Задачи
                    <ControlBarForSelectedTasks
                      v-if="selected.length"
                      :selected="selected"
                      :tasks="tasks"
                      :templateModel="templateForEdit"
                      @unselectTasks="selected = []"
                      @editMultipleTasks="editMultipleTasks"
                      @deleteSelectedTasks="deleteSelectedTasks"
                    />
                  </div>
                </div>
              </div>

              <draggable
                v-model="tasks"
                @end="changeOrder(tasks)"
                draggable=".task-to-create:not(.task-to-create--open)"
                class="task-to-create__wrapper"
              >
                <TaskToCreate
                  v-for="(item, index) in tasks"
                  :key="index"
                  :index="index"
                  :task="item"
                  :isSelected="selected.find((x) => x.Id === item.Id)"
                  :templateModel="templateForEdit"
                  ref="task-to-create"
                  @select="selected.push(item)"
                  @loadTaskDetail="(callback) => loadTaskDetail(item, callback)"
                  @editTemplateTask="editTemplateTask"
                  @removeFile="removeFile"
                  @unselect="
                    selected = selected.filter((x) => x.Id !== item.Id)
                  "
                  @shiftSelection="shiftSelection"
                />
              </draggable>

              <AddTaskToListButton
                :index="tasks.length"
                :preloader="creatingTaskPreloader"
                @click.native="addTaskToList"
              />

              <!--              <div class="task-create-form__button" @click="save">-->
              <!--                сохранить-->
              <!--              </div>-->
              <!--              <div class="task-create-form__button-fake"></div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as c from "../../../../store/tasks/constants";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import { taskCreateFormTemplate } from "../TaskCreateForm/templateData";
import ControlBarForSelectedTasks from "@/modules/tasks/components/TaskCreateForm/components/ControlBarForSelectedTasks";
import TaskToCreate from "@/modules/tasks/components/TaskCreateForm/TaskToCreate";
import AddTaskToListButton from "@/modules/tasks/components/TaskCreateForm/components/AddTaskToListButton";
import {
  ADD_FILES_TO_TEMPLATE_TASK,
  CHANGE_ORDER,
  CREATE_TEMPLATE_TASK,
  DELETE_FILES_FROM_TEMPLATE_TASK,
  DELETE_MULTIPLE_TEMPLATE_TASKS,
  EDIT_MULTIPLE_TEMPLATE_TASKS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_TASK,
  GET_TEMPLATE_TASK_DETAIL,
  GET_TEMPLATE_TASKS,
  SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT,
} from "../../../../store/tasks/constants";
import draggable from "vuedraggable";
import selectionMixin from "@/modules/tasks/components/TaskCreateForm/mixins/selectionMixin";

export default {
  mixins: [common, Helpers, selectionMixin],
  props: {
    templateForEdit: null,
  },
  data() {
    return {
      tasks: [],
      TemplateName: "",
      selected: [],
      preloader: false,
      creatingTaskPreloader: false,
    };
  },
  computed: {
    ...mapGetters("tasks", ["group"]),
    ...mapState("tasks", ["objects", "users", "commonModuleSettings"]),
  },
  created() {
    this.TemplateName = this.templateForEdit.TemplateName;
    if (this.templateForEdit.taskModel) {
      this.tasks.push({ ...this.templateForEdit.taskModel, subtasks: [] });
    } else {
      this.preloader = true;
      this[GET_TEMPLATE_TASKS](this.templateForEdit.Id)
        .then(({ tasks }) => {
          this.tasks = tasks;
        })
        .finally(() => (this.preloader = false));
    }
  },
  mounted() {},
  methods: {
    prepareTask(item, subtasks = []) {
      return Array.isArray(item.TemplateTaskSettings)
        ? {
            ...item,
            TemplateTaskSettings: JSON.parse(
              JSON.stringify(taskCreateFormTemplate.TemplateTaskSettings)
            ),
            subtasks,
          }
        : { ...item, subtasks };
    },
    closePopupByClickWrap(event) {
      if (event.target === this.$refs.popup__wrapper) {
        this.closePopup();
      }
    },
    closePopup() {
      this.$emit("onClosePopup");
      this[SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT](0);
    },
    save() {
      this[EDIT_TEMPLATE]({
        id: this.templateForEdit.Id,
        data: {
          TemplateName: this.TemplateName,
          TemplateSettings: null,
        },
      });
    },
    deleteSelectedTasks() {
      if (this.tasks.length > 1) {
        // this.preloader = true;
        var tasksCopy = [...this.tasks];
        this.tasks = this.tasks.filter(
          (x) => !this.selected.find((s) => s.Id === x.Id)
        );
        this[DELETE_MULTIPLE_TEMPLATE_TASKS]({
          TasksIds: this.selected.map((s) => s.Id),
        }).catch(() => {
          this.tasks = tasksCopy;
        });
        // .finally(() => {
        //   this.preloader = false;
        // });
      }
      this.selected = [];
    },
    addTaskToList() {
      this.creatingTaskPreloader = true;
      this[CREATE_TEMPLATE_TASK]({
        ...taskCreateFormTemplate,
        TemplateId: this.templateForEdit.Id,
      })
        .then((task) => {
          if (task) {
            this.tasks.push(task);
            setTimeout(() => {
              const lastInput = this.$refs["task-to-create"][
                this.$refs["task-to-create"].length - 1
              ].$el.querySelector(".task-to-create__name input");
              lastInput.focus();
            });
          }
        })
        .finally(() => (this.creatingTaskPreloader = false));
    },
    loadTaskDetail(task, callback) {
      this[GET_TEMPLATE_TASK_DETAIL](task.Id).then(({ item, child }) => {
        this.tasks = this.tasks.map((i) => {
          if (i.Id === task.Id) {
            i = { ...i, ...this.prepareTask(item, child) };
          }
          return i;
        });
        callback();
      });
    },
    editTemplateTask(data, formData, callback) {
      if (formData && formData instanceof FormData) {
        this[ADD_FILES_TO_TEMPLATE_TASK](formData).then((files) => {
          callback(files);
        });
      } else {
        this[EDIT_TEMPLATE_TASK]({ id: data.Id, data });
      }
    },
    editMultipleTasks(options, tasksCopy) {
      this[EDIT_MULTIPLE_TEMPLATE_TASKS]({
        TasksIds: this.selected.map((i) => i.Id),
        NewOptions: options,
      }).catch(() => {
        this.tasks = JSON.parse(JSON.stringify(tasksCopy));
      });
    },
    removeFile(payload, errorCallback) {
      this[DELETE_FILES_FROM_TEMPLATE_TASK](payload).catch(() => {
        errorCallback();
      });
    },
    changeOrder(items) {
      this[CHANGE_ORDER]({
        items,
        apiMethod: "api/tasks/v2/tasksGroupsTemplatesItems/updateOrder",
      });
    },
    ...mapActions("tasks", [
      c.CREATE_TEMPLATE,
      EDIT_TEMPLATE,
      GET_TEMPLATE_TASKS,
      GET_TEMPLATE_TASK_DETAIL,
      EDIT_TEMPLATE_TASK,
      ADD_FILES_TO_TEMPLATE_TASK,
      CREATE_TEMPLATE_TASK,
      DELETE_MULTIPLE_TEMPLATE_TASKS,
      EDIT_MULTIPLE_TEMPLATE_TASKS,
      DELETE_FILES_FROM_TEMPLATE_TASK,
      CHANGE_ORDER,
    ]),
    ...mapMutations("tasks", [
      SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT,
    ]),
  },
  components: {
    ControlBarForSelectedTasks,
    TaskToCreate,
    AddTaskToListButton,
    draggable,
  },
};
</script>

<style scoped></style>
