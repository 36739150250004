<template>
  <div
    class="section-tasks-draggable-zone"
    :class="{
      'section-tasks-draggable-zone--open': isOpen,
      'section-tasks-draggable-zone--empty': !sectionTasks.length,
      'section-tasks-draggable-zone_have-next-section': haveNextSection,
    }"
  >
    <div class="table-section-row table__row--col" v-if="sections.length > 1">
      <div class="table__row table__row_main">
        <div class="table__col table__col_name table__col_flexible">
          <div
            class="task-table-row-inner-indicator"
            v-for="c in new Array(level)"
            :key="c"
          ></div>
          <div
            class="table-section-row__arrow table__col table__col_width_35"
            @click="toggleOpen"
          >
            <svg width="12" height="7">
              <use
                xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
              ></use>
            </svg>
          </div>
          <div class="table__col table__col_flexible table-section-row__name">
            <div class="table-section-row__name">
              <p>
                {{ section.isVirtualSection ? "Без секции" : section.Name }}
              </p>
              <div
                class="task-deep-info"
                v-if="sectionTasks && sectionTasks.length"
              >
                <i class="deep-icon"></i>{{ sectionTasks.length }}
              </div>
            </div>
            <div
              v-if="assignType === 'create'"
              class="table-c__name-select"
              @click.stop="$emit('onParentTaskSelect', section)"
            >
              Выбрать
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isOpen">
      <TasksRow
        v-for="(item, index) in section.tasks"
        :key="item.Id"
        :item="item"
        :index="index"
        :level="level"
        :data-id="item.Id"
        :section="section"
        :assignType="assignType"
        @onParentTaskSelect="handleParentSelect"
        @taskToRequestAssign="handleTaskToRequestAssign"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "TasksSection",
  components: {
    TasksRow: () =>
      import("@/modules/support/components/rps/tasks-module/TasksRow"),
  },
  props: [
    "sections",
    "sectionTasks",
    "haveNextSection",
    "level",
    "section",
    "assignType",
  ],
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    handleParentSelect(data) {
      this.$emit("onParentTaskSelect", data);
    },
    handleTaskToRequestAssign(taskId) {
      this.$emit("taskToRequestAssign", taskId);
    },
  },
};
</script>

<style scoped></style>
