<template>
  <div class="popup event-popup event-popup_active" id="request-window-popup">
    <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
      <div
        class="popup__content _allow-overflow popup__content--dynamic"
        :class="newTaskPopup ? 'overflow-hidden' : ''"
        :style="`width: ${windowWidth}`"
      >
        <div
          class="chat-spinner chat-spinner--request"
          :class="preloaders.request ? 'chat-spinner--show' : ''"
        >
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
        <div class="sup-chat-fill" v-show="!selectedRequest">
          <img src="@/assets/images/mail.svg" alt="icon" />
        </div>
        <div class="sup-chat">
          <div class="dropzone dropzone3"></div>
          <template v-if="selectedRequest">
            <div class="popup__header request-window__header">
              <div class="sup-request__header">
                <input
                  class="sup-request__header-title"
                  v-model="requestName"
                  @change="updateRequest"
                />
                <p
                  v-if="selectedRequest.distributorName"
                  class="sup-request__header-info"
                >
                  {{ selectedRequest.CreatedDate }} /
                  {{ selectedRequest.Object }} /
                  {{ requestObjectServiceType[1] }} /
                  {{ selectedRequest.distributorName }}
                </p>
                <p v-else class="sup-request__header-info">
                  {{ selectedRequest.CreatedDate }} /
                  {{ selectedRequest.Object }} /
                  {{ requestObjectServiceType[1] }}
                </p>
              </div>
              <div class="popup__close" @click="closePopup">
                <svg class="popup__close-icon">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-close"
                  ></use>
                </svg>
              </div>
            </div>
            <div class="sup-chat__body">
              <div
                v-if="currentTab === 'Request'"
                class="sup-chat__window-wrapper"
                :style="innerPopup ? 'z-index: -1' : ''"
              >
                <div
                  class="sup-chat__window"
                  @scroll="handleScroll"
                  ref="windowScrollable"
                >
                  <RequestWindowHeader
                    ref="requestDescription"
                    :selected-request="selectedRequest"
                    :check-file-ext="checkFileExt"
                    :api-url="apiUrl"
                    :show-image-popup="showImagePopup"
                    :show-content="contentIsOpen"
                    :floating-header-is-shown="floatingHeaderIsShown"
                    @scrollToTop="scrollToTop"
                    @onContentToggle="onContentToggle"
                  />
                  <div class="sup-chat__window-bottom">
                    <template v-for="(item, index) in messages">
                      <div
                        class="sup-message sup-message--left"
                        :id="`sup-message__${item.Id}`"
                        :key="'d' + index"
                        v-if="
                          isDistsObjectList
                            ? !item.OperatorId &&
                              !['Описание', 'Система'].includes(
                                item.SenderName
                              ) &&
                              !item.DistributorContactId
                            : !item.OperatorId &&
                              !['Описание', 'Система'].includes(item.SenderName)
                        "
                      >
                        <div class="sup-message__avatar">
                          {{ item.SenderName | initials }}
                        </div>
                        <div
                          class="sup-message__text-block sup-message--client"
                        >
                          <p class="sup-message__name">
                            <span class="sup-message__sender">
                              {{ item.SenderName }}
                              <template v-if="item.DistributorContactId"
                                >, Оператор
                                {{ selectedRequest.distributorName }}</template
                              >
                            </span>
                            <span class="sup-message__date">{{
                              item.Created
                            }}</span>
                            <span
                              v-if="item.SenderName !== 'Система'"
                              class="sup-message__reply"
                              @click="setReplyComment(item)"
                            >
                              <svg class="sup-message__reply-icon">
                                <use
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xlink:href="/static/images/sprite.svg#sup-reply-icon"
                                ></use>
                              </svg>
                            </span>
                          </p>
                          <div
                            class="task-parent-comment-preview"
                            v-if="item.Parent.Message || item.Parent.Files"
                            @click="goToReplyMessage(item.Parent)"
                            :style="
                              item.Parent.OperatorId
                                ? 'border-color: #F9AE17'
                                : ''
                            "
                          >
                            <div class="task-parent-comment-preview__name">
                              <div class="task-comments-item__name">
                                {{ item.Parent.SenderName }}
                              </div>
                            </div>
                            <div class="task-comments-item__date">
                              {{ item.Parent.Created }}
                            </div>
                            <div
                              class="task-parent-comment-preview__comment"
                              v-text="item.Parent.Message"
                            ></div>
                            <template v-if="item.Parent.Files.length">
                              <div
                                v-for="file in item.Parent.Files"
                                class="task-parent-comment-preview__comment"
                                :key="file.name"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                  style="height: 14px"
                                />
                                {{ file.name }}
                              </div>
                            </template>
                          </div>
                          <p class="sup-message__text" v-if="item.Message">
                            {{ item.Message.trim() }}
                          </p>
                          <template v-if="item.Files.length">
                            <template v-for="(file, index) in item.Files">
                              <a
                                :key="'e' + index"
                                :href="
                                  apiUrl + file.file.replace('api2', 'api')
                                "
                                v-if="!checkFileExt(file.name)"
                                download
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </a>
                              <span
                                :key="'f' + index"
                                v-if="checkFileExt(file.name)"
                                @click.right="
                                  download(
                                    apiUrl + file.file.replace('api2', 'api')
                                  )
                                "
                                @click="
                                  showImagePopup(
                                    apiUrl + file.file.replace('api2', 'api')
                                  )
                                "
                                style="cursor: pointer"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </span>
                              <br :key="'g' + index" />
                            </template>
                          </template>
                        </div>
                      </div>

                      <div
                        class="task-comments-item task-comments-item--notification task-comments-item--support"
                        v-if="
                          item.TaskId || ['Система'].includes(item.SenderName)
                        "
                        :key="'h' + index"
                        :class="{
                          'task-comments-item--notification_add-comment': isAddCommentTemplate(
                            item
                          ),
                        }"
                      >
                        <div class="sup-message__avatar">
                          <img
                            src="@/assets/images/avatar.png"
                            v-if="!isAddCommentTemplate(item)"
                          />
                          <template v-else>sur</template>
                        </div>
                        <div class="task-comments-for-notification">
                          <div class="task-comments-for-notification__date">
                            {{ item.Created }}
                          </div>
                          <div v-if="getTaskFiles(item).length">
                            <template
                              v-for="(file, index) in getTaskFiles(item)"
                            >
                              <span
                                class="task-comments-for-notification__message"
                                :key="'i' + index"
                              >
                                В задачу добавлен новый файл:
                              </span>
                              <a
                                v-if="!checkFileExt(file.original)"
                                :key="'j' + index"
                                :href="file.original"
                                download
                                target="_blank"
                              >
                                {{ file.filename }}
                              </a>
                              <span
                                :key="'k' + index"
                                v-if="checkFileExt(file.original)"
                                @click.right="download(file.original)"
                                @click="showImagePopup(file.original)"
                                style="cursor: pointer"
                              >
                                {{ file.filename }}
                              </span>
                              <br :key="'l' + index" />
                            </template>
                          </div>
                          <div
                            class="task-comments-for-notification__message"
                            v-else
                          >
                            {{ item.Message }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="sup-message sup-message--right"
                        :id="`sup-message__${item.Id}`"
                        :key="'n' + index"
                        v-else-if="
                          isDistsObjectList
                            ? (item.OperatorId ||
                                item.DistributorContactId ||
                                item.SenderName === 'Система') &&
                              item.SenderName !== 'Описание'
                            : item.OperatorId && item.SenderName !== 'Описание'
                        "
                      >
                        <div
                          class="sup-message__avatar sup-message__avatar-blue"
                        >
                          {{ item.SenderName | initials }}
                        </div>
                        <div
                          class="sup-message__text-block"
                          :class="
                            item.ActionStatus === '14'
                              ? 'sup-message--hide'
                              : 'sup-message--support'
                          "
                        >
                          <p class="sup-message__name" v-if="item.TaskId">
                            <span class="sup-message__sender">
                              {{ taskName(item.TaskId, item) }}
                            </span>
                            <span class="sup-message__date">{{
                              item.Created
                            }}</span>
                            <span
                              v-if="item.SenderName !== 'Система'"
                              class="sup-message__reply"
                              @click="setReplyComment(item)"
                            >
                              <svg class="sup-message__reply-icon">
                                <use
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xlink:href="/static/images/sprite.svg#sup-reply-icon"
                                ></use>
                              </svg>
                            </span>
                          </p>
                          <p class="sup-message__name" v-else>
                            <span class="sup-message__sender">
                              {{ item.CreatedBy }}
                              <template v-if="item.DistributorContactId"
                                >, Оператор {{ getDistbOfObject }}</template
                              >
                            </span>
                            <span class="sup-message__date">{{
                              item.Created
                            }}</span>
                            <span
                              v-if="item.SenderName !== 'Система'"
                              class="sup-message__reply"
                              @click="setReplyComment(item)"
                            >
                              <svg class="sup-message__reply-icon">
                                <use
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xlink:href="/static/images/sprite.svg#sup-reply-icon"
                                ></use>
                              </svg>
                            </span>
                          </p>

                          <template v-if="getTaskFiles(item).length">
                            <template
                              v-for="(file, index) in getTaskFiles(item)"
                            >
                              <p class="sup-message__text" :key="'m' + index">
                                В задачу добавлены новые файлы
                              </p>
                              <a
                                :key="'o' + index"
                                :href="item"
                                v-if="!checkFileExt(file.original)"
                                download
                                target="_blank"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.original.split("/").pop() }}
                              </a>
                              <span
                                :key="'p' + index"
                                v-if="checkFileExt(file.original)"
                                @click.right="download(file.original)"
                                @click="showImagePopup(file.original)"
                                style="cursor: pointer"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.split("/").pop() }}
                              </span>
                              <br :key="'q' + index" />
                            </template>
                          </template>

                          <div
                            class="task-parent-comment-preview"
                            v-if="item.Parent.Message || item.Parent.Files"
                            @click="goToReplyMessage(item.Parent)"
                            :style="
                              item.Parent.OperatorId
                                ? 'border-color: #F9AE17'
                                : ''
                            "
                          >
                            <div class="task-parent-comment-preview__name">
                              <div class="task-comments-item__name">
                                {{ item.Parent.SenderName }}
                              </div>
                            </div>
                            <div class="task-comments-item__date">
                              {{ item.Parent.Created }}
                            </div>
                            <div
                              class="task-parent-comment-preview__comment"
                              v-text="item.Parent.Message"
                            ></div>
                            <template v-if="item.Parent.Files.length">
                              <div
                                v-for="file in item.Parent.Files"
                                class="task-parent-comment-preview__comment"
                                :key="file.name"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                  style="height: 14px"
                                />
                                {{ file.name }}
                              </div>
                            </template>
                          </div>

                          <template v-if="item.Message">
                            <p class="sup-message__text" v-if="!item.TaskId">
                              {{ item.Message.trim() }}
                            </p>
                            <p
                              class="sup-message__text"
                              v-else
                              v-html="item.Message.trim()"
                            ></p>
                          </template>

                          <template v-if="item.Files.length">
                            <template v-for="(file, index) in item.Files">
                              <a
                                :key="'r' + index"
                                :href="
                                  apiUrl + file.file.replace('api2', 'api')
                                "
                                v-if="!checkFileExt(file.name)"
                                download
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </a>
                              <span
                                :key="'s' + index"
                                v-if="checkFileExt(file.name)"
                                @click.right="
                                  download(
                                    apiUrl + file.file.replace('api2', 'api')
                                  )
                                "
                                @click="
                                  showImagePopup(
                                    apiUrl + file.file.replace('api2', 'api')
                                  )
                                "
                                style="cursor: pointer"
                                class="sup-message__file"
                              >
                                <img
                                  src="@/assets/images/attachment.svg"
                                  alt="icon"
                                />
                                {{ file.name }}
                              </span>
                              <br :key="'t' + index" />
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="sup-chat__footer">
                  <div
                    v-if="replyComment"
                    class="sup-chat__reply task-comment-reply"
                  >
                    <div class="task-comment-reply__person">
                      {{ replyComment.SenderName }}
                    </div>
                    <p
                      v-if="replyComment.Message"
                      class="task-comment-reply__text"
                    >
                      {{ replyComment.Message }}
                    </p>
                    <div class="sup-chat__footer-close" @click="clearReply">
                      <svg class="popup__close-icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-close"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <template v-if="selectedRequest.StatusId !== '5'">
                    <div class="sup-chat__input" ref="message-area">
                      <div class="loadfile-indicator" v-if="progressBar">
                        <span :style="`width:${progressBar}%`"></span>
                      </div>
                      <textarea
                        placeholder="Напишите соообщение..."
                        v-model="message"
                        @keyup.ctrl.enter="sendMessage(false)"
                      ></textarea>
                      <label class="sup-chat__input-att">
                        <input
                          type="file"
                          class="sup-chat__file"
                          multiple
                          hidden
                          @change="filesChange($event.target.files)"
                        />
                        <img src="@/assets/images/attachment.svg" alt="icon" />
                      </label>
                      <div class="sup-chat-resize" ref="resize">
                        <img src="@/assets/images/line.svg" alt="icon" />
                      </div>
                    </div>

                    <template v-if="files.length">
                      <div class="sup-chat-files">
                        <div
                          class="sup-chat-files__item"
                          v-for="(item, index) in files"
                          :key="'u' + index"
                        >
                          <img
                            v-if="checkFileExt(item.name)"
                            :src="item.img"
                            alt="file image preview"
                          />
                          <img
                            class="sup-chat-files__file-icon"
                            src="@/assets/images/file.svg"
                            v-else
                            alt="icon"
                          />
                          <div
                            class="sup-chat-files__item-remove"
                            @click="removeFile(item.id)"
                          >
                            <img src="@/assets/images/remove.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <div
                    class="sup-chat__footer-btn-wrap"
                    v-if="selectedRequest.StatusId !== '5'"
                  >
                    <template v-if="!isDistsObjectList">
                      <div
                        class="sup-btn sup-chat__footer-btn sup-color-3"
                        v-if="checkUserRight(COMMENT_RIGHT)"
                        @click="sendMessage(true)"
                      >
                        Отправить как комментарий
                      </div>
                      <div
                        class="sup-btn sup-chat__footer-btn sup-color-2"
                        v-if="checkUserRight(ANSWER_TO_GUEST_RIGHT)"
                        @click="sendMessage(false)"
                      >
                        Отправить сообщение клиенту
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="sup-btn sup-chat__footer-btn sup-color-3"
                        @click="sendMessage(true)"
                      >
                        Написать комментарий дистрибьютору
                      </div>
                    </template>
                  </div>
                  <div
                    class="sup-chat__footer-btn-wrap"
                    v-else
                    style="margin: 10px"
                  >
                    <div
                      class="sup-btn sup-chat__footer-btn sup-color-2"
                      style="margin: 0"
                      v-if="checkUserRight(ANSWER_TO_GUEST_RIGHT)"
                      @click="renewRequest()"
                    >
                      Возобновить заявку
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="currentTab === 'Files'"
                class="sup-chat__window-wrapper"
                :style="innerPopup ? 'z-index: -1' : ''"
              >
                <RequestFilesTab
                  :request="selectedRequest"
                  :check-file="checkFileExt"
                  :api-url="apiUrl"
                  :download="download"
                />
              </div>
              <div
                v-if="currentTab === 'Tasks'"
                class="sup-chat__window-wrapper"
                :style="innerPopup ? 'z-index: -1' : ''"
              >
                <TasksList
                  @openTaskPopup="newTaskPopup = true"
                  @closeTaskPopup="newTaskPopup = false"
                />
              </div>
              <request-window-sidebar
                :request="selectedRequest"
                :activeTab="currentTab"
                :files="requestFiles.length"
                @changeTab="changeActiveTab"
                @openInnerPopup="innerPopup = true"
                @closeInnerPopup="innerPopup = false"
              />
            </div>
          </template>
        </div>
        <transition name="popupSlide">
          <router-view />
        </transition>
        <transition name="popupSlideWithInner">
          <tasks-list
            v-if="taskPopup"
            :isOpen="taskPopup"
            @onClosePopup="taskPopup = false"
          />
        </transition>
        <div
          v-if="selectedWidth === '65%'"
          class="width-bar"
          @click.stop="toggleWidth"
        >
          <span class="width-bar__sign width-bar__sign-back">
            <svg width="32" height="32">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/sprite.svg#window-bar-2"
              ></use>
            </svg>
          </span>
          <span class="width-bar__sign width-bar__sign-front">
            <svg width="32" height="32">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/sprite.svg#window-bar-1"
              ></use>
            </svg>
          </span>
        </div>
        <div
          v-if="selectedWidth === '85%'"
          class="width-bar"
          @click.stop="toggleWidth"
        >
          <span class="width-bar__sign width-bar__sign-back">
            <svg width="32" height="32">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/sprite.svg#window-bar-4"
              ></use>
            </svg>
          </span>
          <span class="width-bar__sign width-bar__sign-front">
            <svg width="32" height="32">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/sprite.svg#window-bar-3"
              ></use>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as c from "@/store/support/constants";
import * as commonTypes from "@/store/common/constants";
import Spinner from "vue-spinkit";
import { scrollTo } from "@/utils/scrollTo";
import { API_ROOT } from "@/config";
import * as moment from "moment";
import * as _ from "lodash";
// import DatePicker from 'vue2-datepicker'
import { Helpers } from "@/mixins/Helpers";
import Api from "../../../../api/support/rps";
import TasksList from "./tasks-module/TasksList";
import { EDIT_TAGS_RIGHT } from "../../../../store/support/constants";
import RequestWindowSidebar from "./tasks-module/RequestWindowSidebar";
import RequestWindowHeader from "./tasks-module/RequestWindowHeader";
import RequestFilesTab from "./tasks-module/RequestFilesTab";

export default {
  mixins: [Helpers],
  data() {
    return {
      isRequestInfoOpen: false,
      message: "",
      files: [],
      apiUrl: API_ROOT,
      requestName: "",
      RESPONSE_CHANGE_RIGHT: c.RESPONSE_CHANGE_RIGHT,
      ANSWER_TO_GUEST_RIGHT: c.ANSWER_TO_GUEST_RIGHT,
      COMMENT_RIGHT: c.COMMENT_RIGHT,
      CHANGE_STATUS_RIGHT: c.CHANGE_STATUS_RIGHT,
      isShowMultiSelectLabels: true,
      settingTagNameState: false,
      newTagName: "",
      tags: [],
      taskPopup: false,
      initialTags: { array: [] },
      showTagsListBubble: false,
      addTagBubbleOpen: false,
      addTagBubbleOpen2: false,
      EDIT_TAGS_RIGHT,
      dateSettingReason: {
        open: false,
        text: "",
        value: "",
        type: "",
      },
      scroll: null,
      contentIsOpen: false,
      floatingHeaderIsShown: false,
      currentTab: "Request",
      innerPopup: false,
      selectedWidth: "65%",
      newTaskPopup: false,
      replyComment: null,
    };
  },
  computed: {
    windowWidth() {
      if (this.innerPopup) return "100%";
      return this.selectedWidth;
    },
    getManagerName() {
      if (!this.personsForManage.length) {
        return "...";
      } else {
        const foundManager = _.find(this.personsForManage, [
          "Id",
          this.selectedRequest.Manager,
        ]);
        return foundManager ? foundManager.FullName : "Не установлен";
      }
    },
    filteredTags() {
      if (this.tags.length) {
        let filteredTags = this.tags.filter((i) => {
          const re = new RegExp(this.newTagName, "gi");
          return re.test(i.TagName);
        });
        filteredTags = _.map(filteredTags, (i) => {
          const foundTag = _.find(this.initialTags, ["TagId", i.Id]);
          i.checked = !!foundTag;
          return i;
        });
        return filteredTags;
      } else {
        return [];
      }
    },
    canCreateNewTag() {
      if (!this.newTagName) return false;
      return !_.find(this.filteredTags, ["TagName", this.newTagName]);
    },
    requestTags() {
      const tags = this.initialTags.array;
      let preparedTags = [];
      if (tags && tags.length) {
        _.each(tags, (i) => {
          const foundTag = _.find(this.commonSettings.tags, ["Id", i.TagId]);
          if (foundTag) {
            foundTag.requestId = i.Id;
            preparedTags.push(foundTag);
          }
        });
        return preparedTags;
      } else {
        return [];
      }
    },
    requestObject() {
      if (
        this.selectedRequest &&
        this.selectedRequest.ObjectId &&
        this.objects
      ) {
        return _.find(this.objects.objects, [
          "Id",
          this.selectedRequest.ObjectId,
        ]);
      }
      return false;
    },
    requestObjectServiceType() {
      if (this.requestObject) {
        switch (+this.requestObject.ServiceType) {
          case 0:
            return ["-", "Ничего"];
          case 1:
            return ["ТП", "Техническая поддержка"];
          case 2:
            return ["ТО", "Техническое обслуживание"];
          case 3:
            return ["УЭ", "Удалённая эксплуатация"];
          case 4:
            return ["Э", "Эксплуатация"];
          case 5:
            return ["ПНР", "ПостПНР"];
          case 6:
            return ["ПНР", "ПНР"];
        }
      }
      return false;
    },
    ...mapState("rpsSupport", [
      "selectedRequest",
      "requests",
      "preloaders",
      "progressBar",
      "isDistsObjectList",
      "personsForManage",
      "commonSettings",
    ]),
    ...mapGetters("rpsSupport", ["messages", "requestFiles", "activeTab"]),
    ...mapState("clients", ["objects"]),
    ...mapState("common", ["userData"]),
  },
  mounted() {
    const self = this;
    document.onclick = (e) => {
      if (
        self.isRequestInfoOpen &&
        !e.target.closest(".sup-chat-info") &&
        !e.target.closest(".sup-chat-info__icon")
      ) {
        self.isRequestInfoOpen = false;
      }
      if (
        this.settingTagNameState &&
        !e.target.closest(".multi_select_with_checkboxes--tags")
      ) {
        this.cancelSettingTag();
      }
    };

    const dropzoneWrapper = document.querySelector(".dropzone3");
    let isDragged = false;

    document.querySelector(".sup-chat").ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      return false;
    };

    dropzoneWrapper.ondrop = function (event) {
      event.preventDefault();
      dropzoneWrapper.classList.remove("dropzone-hover");
      dropzoneWrapper.style.display = "none";
      self.filesChange(event.dataTransfer.files);
      isDragged = false;
    };
    dropzoneWrapper.ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      isDragged = true;
      setTimeout(() => (isDragged = false), 400);
      return false;
    };

    dropzoneWrapper.ondragleave = function () {
      setTimeout(() => {
        if (!isDragged) {
          dropzoneWrapper.classList.remove("dropzone-hover");
          dropzoneWrapper.style.display = "none";
        }
      }, 400);
      return false;
    };
  },
  methods: {
    goToReplyMessage(replyMessage) {
      const replyEl = document.getElementById(
        "sup-message__" + replyMessage.Id
      );
      replyEl.classList.add("sup-message--select");
      setTimeout(() => {
        replyEl.classList.remove("sup-message--select");
      }, 1000);
      this.$refs.windowScrollable.scrollTop = replyEl.offsetTop - 150;
    },
    setReplyComment(comment) {
      this.replyComment = comment;
    },
    clearReply() {
      this.replyComment = null;
    },
    getTaskFiles(item) {
      if (item.TaskId && item.Message && item.Message.length) {
        if (
          item.Message.includes("В задачу добавлены новые файлы") ||
          (item.Message.includes("|||") &&
            item.Message.includes("original") &&
            item.Message.includes("small"))
        ) {
          const files = item.Message.split("|||");
          files.shift();
          files.pop();
          try {
            const json = JSON.parse(files);
            return json;
          } catch (e) {
            return files;
          }
        }
      }
      return [];
    },
    handleScroll() {
      let window = this.$refs.windowScrollable;
      let description = this.$refs.requestDescription.$refs.upperPart;

      let docViewTop = window.scrollTop;
      let elemHeight = description.clientHeight;
      let totalHeight = window.scrollHeight;

      this.floatingHeaderIsShown =
        docViewTop > elemHeight &&
        totalHeight > window.clientHeight + elemHeight;
    },
    scrollToTop() {
      this.floatingHeaderIsShown = false;
      this.$refs.windowScrollable.scrollTop = 0;
    },
    changeActiveTab(tab) {
      this.currentTab = tab;
    },
    onContentToggle() {
      this.contentIsOpen = !this.contentIsOpen;
    },
    ...mapActions("common", { showImagePopup: commonTypes.TOGGLE_POPUP_IMAGE }),
    ...mapActions("rpsSupport", [
      c.SET_SELECTED_REQUEST,
      c.SEND_MESSAGE,
      c.CHANGE_REQUEST_STATUS,
      c.ADD_TIME,
      c.UPDATE_REQUEST,
      c.CHANGE_MANAGER,
    ]),
    updateRequest() {
      const payload = {
        requestId: this.selectedRequest.Id,
        payload: {
          Subject: this.requestName,
        },
      };
      this[c.UPDATE_REQUEST](payload);

      // update name in a requests list
      this.requests[this.activeTab].find(
        (item) => item.Id === this.selectedRequest.Id
      ).Subject = this.requestName;
    },
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    closeRequestWindow() {
      this[c.SET_SELECTED_REQUEST](false);
    },
    checkFileExt(fileName) {
      const imagesExts = ["jpeg", "jpg", "png", "bmp", "gif"];
      if (fileName && fileName.length) {
        const ext = fileName.split(".").pop();
        if (imagesExts.includes(ext.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    sendMessage(comment) {
      if (this.message.trim().length || this.files.length) {
        const formData = new FormData();
        formData.append("Message", this.message);
        if (comment) {
          formData.append("ActionStatus", 14);
          formData.append("hidden", 1);
        }
        _.each(this.files, (i, index) =>
          formData.append("files[" + index + "]", i.file, i.file.name)
        );
        if (this.replyComment) {
          formData.append("AnswerToCommentId", this.replyComment.Id);
        }
        this[c.SEND_MESSAGE]({
          requestId: this.selectedRequest.Id,
          message: formData,
        });
        this.message = "";
        this.file = { name: "", file: "" };
        this.files = [];
        this.replyComment = null;
        document.querySelector(".sup-chat__file").value = "";
      }
    },
    renewRequest() {
      // const formData = new FormData()
      // formData.append('Message', 'Возобновление заявки')
      this.changeRequestStatus(2);
      // this[c.SEND_MESSAGE]({ requestId: this.selectedRequest.Id, message: formData })
    },
    removeFile(id) {
      this.files = _.reject(this.files, { id });
    },
    detectStatusIndicator(statusIdArray) {
      if (statusIdArray.includes(+this.selectedRequest.StatusId)) {
        return "sup-indicator--active";
      }
    },
    changeRequestStatus(status) {
      this[c.CHANGE_REQUEST_STATUS]({
        requestId: this.selectedRequest.Id,
        status,
      });
    },
    resetDateReasonObject() {
      this.dateSettingReason = {
        open: false,
        type: "",
        text: "",
        value: "",
      };
    },
    download(item) {
      const link = document.createElement("a");
      link.href = item;
      link.click();
    },
    changeManager(managerId) {
      const formData = new FormData();
      formData.append("managerId", managerId);
      formData.append("requestId", this.selectedRequest.Id);

      this[c.CHANGE_MANAGER](formData);
    },
    cancelSettingTag() {
      this.settingTagNameState = false;
      this.newTagName = "";
    },
    addTag() {
      this.settingTagNameState = true;
      setTimeout(() => {
        // this.$refs['newTagName'].focus()
      });
    },
    setTagFromList(tag) {
      // console.log(tag)

      // this.$refs['newTagName'].focus()
      this.preloaders.request = true;
      this.newTagName = "";
      if (tag.checked) {
        Api.createTagRelation({
          RequestId: this.selectedRequest.Id,
          TagId: tag.Id,
        })
          .then((res) => {
            if (res.data && res.data.TagId) {
              this.initialTags.array.push(res.data);
            }
          })
          .finally(() => {
            this.preloaders.request = false;
          });
      } else {
        const foundTag = _.find(this.initialTags.array, ["TagId", tag.Id]);
        Api.deleteTagRelation(foundTag.Id)
          .then((res) => {
            if (res.data.status === "success") {
              this.initialTags.array = _.reject(this.initialTags.array, [
                "TagId",
                foundTag.TagId,
              ]);
            }
          })
          .finally(() => {
            this.preloaders.request = false;
          });
      }
      // this.settingTagNameState = false
    },
    createTag() {
      Api.createTag({
        TagName: this.newTagName,
      }).then((res) => {
        // console.log(res)
        if (res.data.Id !== void 0) {
          Api.createTagRelation({
            RequestId: this.selectedRequest.Id,
            TagId: res.data.Id,
          }).then((res) => {
            if (res.data && res.data.TagId) {
              Api.getTagsList().then((res) => {
                if (res.data && res.data.length) {
                  this.tags = res.data;
                }
              });
              this.initialTags.push(res.data);
            }
          });
        }
      });
      this.tags.push({
        TagName: this.newTagName,
        checked: true,
      });
      this.newTagName = "";
      this.settingTagNameState = false;
    },
    taskName(taskId, item) {
      const task = _.find(this.selectedRequest.tasks, ["Id", taskId]);
      if (task) {
        return task.TaskName;
      }
      return "Удаленная задача " + item.TaskId;
    },
    closeTagListBubble() {
      this.showTagsListBubble = false;
    },
    closePopup() {
      this.saveMessageToStorage(this.message);
      this.$emit("windowClose");
    },
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.saveMessageToStorage(this.message);
        this.$emit("windowClose");
      }
    },
    saveMessageToStorage(message) {
      if (message.length)
        localStorage.setItem(
          `request${this.selectedRequest.Id}savedMessage`,
          message
        );
      else if (
        localStorage.getItem(`request${this.selectedRequest.Id}savedMessage`)
      )
        localStorage.removeItem(
          `request${this.selectedRequest.Id}savedMessage`
        );
      this.message = "";
    },
    toggleWidth() {
      if (this.selectedWidth === "65%") this.selectedWidth = "85%";
      else this.selectedWidth = "65%";
    },
    isAddCommentTemplate(item) {
      return (
        item.NotificationTemplate &&
        item.NotificationTemplate.SystemName === "tasks_add_comment"
      );
    },
  },

  filters: {
    initials: function (name) {
      if (!name) return "";
      const nameArr = name.split(" ").filter((item) => item.length);
      if (nameArr.length === 3) {
        return `${nameArr[1][0]}${nameArr[2][0]}`;
      } else if (nameArr.length === 2) {
        return `${nameArr[0][0]}${nameArr[1][0]}`;
      } else {
        return nameArr[0][0];
      }
    },
    truncateFileName(name) {
      if (name.length > 10) name = name.substr(0, 10) + "...";
      return name;
    },
    time(date) {
      return `${date[0]} мин. ${date[1]} ceк.`;
    },
    deadlineTime(minutes) {
      let output;
      if (minutes <= 0) {
        output = 0;
      } else {
        if (minutes >= 60) {
          if (minutes <= 1440) {
            output = moment("2010-10-20")
              .minutes(minutes)
              .format("H ч. m мин.");
          } else {
            const now = moment("2010-10-20");
            const expiration = moment("2010-10-20").minutes(minutes);
            const diff = expiration.diff(now);
            const diffDuration = moment.duration(diff);
            output = `${diffDuration.days()} д. ${diffDuration.hours()} ч. ${diffDuration.minutes()} мин.`;
          }
        } else {
          output = minutes + " мин.";
        }
      }
      return output;
    },
  },
  watch: {
    messages(newMessages, oldMessages) {
      if (newMessages.length && newMessages.length !== oldMessages.length) {
        const chatWindow = document.querySelector(".sup-chat__window");
        if (chatWindow) {
          setTimeout(() => {
            scrollTo(chatWindow, chatWindow.scrollHeight, 500);
          }, 100);
        }
      }
    },
    selectedRequest(newRequest, oldRequest) {
      if (newRequest.Id !== oldRequest.Id) {
        const savedMessage = localStorage.getItem(
          `request${this.selectedRequest.Id}savedMessage`
        );
        if (savedMessage && savedMessage.length) this.message = savedMessage;
        this.replyComment = null;

        this.currentTab = "Request";
        this.requestName = newRequest.Subject;
        setTimeout(() => {
          const chatWindow = document.querySelector(".sup-chat__window");
          if (chatWindow) {
            // console.log(this.selectedRequest.tags)
            this.isRequestInfoOpen = false;
            this.initialTags.array = newRequest.tags;
            chatWindow.scrollTop = chatWindow.scrollHeight;

            const element = this.$refs["message-area"];
            const resizer = this.$refs["resize"];
            window.addEventListener("onclick", stopResize);

            let original_height = 0;
            let original_mouse_y = 0;
            resizer.addEventListener("mousedown", function (e) {
              e.preventDefault();
              original_mouse_y = e.pageY;
              original_height = parseFloat(
                getComputedStyle(element, null)
                  .getPropertyValue("height")
                  .replace("px", "")
              );
              window.addEventListener("mousemove", resize);
              window.addEventListener("mouseup", stopResize);
            });

            // eslint-disable-next-line no-inner-declarations
            function resize(e) {
              const height = original_height - (e.pageY - original_mouse_y);
              if (height > 50) {
                element.style.height = height + "px";
              }
            }

            // eslint-disable-next-line no-inner-declarations
            function stopResize() {
              window.removeEventListener("mousemove", resize);
            }
          } else {
            // setTimeout(() => {
            //   document.querySelector('.sup-chat__window').scrollTop = document.querySelector('.sup-chat__window').scrollHeight
            // }, 400)
          }
        }, 100);
      }
    },
  },
  components: {
    RequestFilesTab,
    RequestWindowHeader,
    RequestWindowSidebar,
    Spinner,
    // DatePicker,
    TasksList,
  },
};
</script>
