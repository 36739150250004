var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],ref:"popper",staticClass:"task-settings__list context-popup-menu",attrs:{"tabindex":"-1"},on:{"!contextmenu":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.close();
      _vm.$emit('openDetailTask', true);}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"18","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#new-page-icon"}})])]),_vm._v("\n    Открыть в новом окне\n  ")]),_vm._v(" "),_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('createSubTask');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"18","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#child-icon"}})])]),_vm._v("\n    Добавить подзадачу\n  ")]),_vm._v(" "),_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('copyTask');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"18","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#circle-angle-icon"}})])]),_vm._v("\n    Дублировать\n  ")]),_vm._v(" "),_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('copyLink');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"18","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#copy-link-icon"}})])]),_vm._v("\n    Копировать ссылку\n  ")]),_vm._v(" "),(_vm.item.Status === '2')?_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('openTask');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"18","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#check-icon"}})])]),_vm._v("\n    Открыть\n  ")]):_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('closeTask');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"16","height":"16","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#check-icon"}})])]),_vm._v("\n    Закрыть\n  ")]),_vm._v(" "),(_vm.checkUserTaskRight('delete_task'))?_c('div',{staticClass:"task-settings__list-item",on:{"click":function($event){_vm.$emit('deleteTask');
      _vm.close();}}},[_c('div',{staticClass:"task-settings__list-icon"},[_c('svg',{attrs:{"width":"16","height":"18","stroke":"#444444"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#trash-icon"}})])]),_vm._v("\n    Удалить\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }