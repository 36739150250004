import * as c from "./constants";
import { makeSound } from "@/utils";

export default {
  [c.SET_CURRENT_ROUTE](state, data) {
    state.currentRoute = data;
  },
  [c.TOGGLE_PRELOADER](state, data) {
    state.preloader = data;
  },
  [c.TOGGLE_POPUP](state, data) {
    state.popups[data.module][data.popup] = data.value;
  },
  [c.TOGGLE_POPUP_IMAGE](state, image) {
    state.popupImage = image;
  },
  [c.SET_LOGIN](state, login) {
    state.login = login;
  },
  [c.SET_CLIENT_AUTH](state, isClient) {
    state.is_client = isClient;
  },
  [c.GET_USER](state, userData) {
    state.user = userData;
  },
  [c.SET_NOTIFICATIONS](state, data) {
    // console.log('data > state.notification', data, state.notification)
    if (state.notification && data > state.notification) {
      makeSound();
    }
    state.notification = data;
  },
  [c.SET_GETTING_NOTIFICATIONS_STATUS](state, data) {
    state.canGetNotification = data;
  },
  [c.GET_USER_DATA](state, data) {
    state.userData = data;
    // console.log(data.role)
    if (
      data.role &&
      data.role.ActiveRights &&
      data.role.ActiveRights.length > 0
    ) {
      state.rights = data.role.ActiveRights;
      state.rightsLoaded = true;
    }
  },
  [c.CHANGE_USER_FORM](state, data) {
    const fullName = data.FullName.split(" ");
    state.config_user_settings.errors = {};
    state.config_user_settings.firstname = fullName[1];
    state.config_user_settings.lastname = fullName[0];
    state.config_user_settings.middlename = fullName[2] ? fullName[2] : "";
    state.config_user_settings.Id = data.Id;
    state.config_user_settings.loginName = data.LoginName;
    state.config_user_settings.fullName = data.FullName;
    state.config_user_settings.email = data.Email;
    state.config_user_settings.roleId = data.RoleId;
    state.config_user_settings.password = data.Password;
    state.config_user_settings.confirm_password = data.Password;
    state.config_user_settings.phone = data.LoginName;
    state.config_user_settings.ObjectRequestNotification =
      data.ObjectRequestNotification;
  },
  [c.CHANGE_USER_FORM_FIELD](state, data) {
    if (data.field_type === "errors") {
      const key = Object.keys(data.value)[0];
      if (key) {
        state.config_user_settings.errors[key] = data.value[key];
      } else {
        state.config_user_settings.errors = {};
      }
    } else {
      state.config_user_settings[data.field_type] = data.value;
      state.config_user_settings["fullName"] = `${
        state.config_user_settings.lastname
      } ${state.config_user_settings.firstname} ${
        state.config_user_settings.middlename
          ? state.config_user_settings.middlename
          : ""
      }`;
    }
  },
};
