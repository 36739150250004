<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Title"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Title'"
            ref="translated-field-Title"
            :OriginalValue="fields.Title"
            :inputType="'input'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            ref="translated-field-Uri"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          GroupId:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.GroupId" />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Верхний текст:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.WelcomeText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'WelcomeText'"
            ref="translated-field-WelcomeText"
            :OriginalValue="fields.WelcomeText"
            :inputType="'textarea'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Call to action:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.CallToActionText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'CallToActionText'"
            ref="translated-field-CallToActionText"
            :OriginalValue="fields.CallToActionText"
            :inputType="'textarea'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__text-editor" v-if="tab === 2">
      <div class="skedit" v-if="parentData.language === 'ru'">
        <textarea v-tinymce field="FullText" v-model="fields.FullText" />
      </div>
      <translate-field
        :EntityColumnName="'FullText'"
        ref="translated-field-FullText"
        :OriginalValue="fields.FullText"
        :inputType="'editor'"
        :module="'SiteStaticPages'"
        @getTranslation="getTranslation"
        :translation="translation"
        :id="id"
      />
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            ref="translated-field-SeoTitle"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            ref="translated-field-SeoDescription"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            ref="translated-field-SeoKeywords"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteStaticPages'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteStaticPages'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";

export default {
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
