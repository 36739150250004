<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_1 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <Print_1 />
      </magnific-popup-modal>
    </div>

    <div class="pdf-settings">
      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Коммерческое предложение для:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.Name"
            />
          </div>
          <div class="cover">
            <div class="cover__label">Обложки:</div>
            <div class="cover__list">
              <label class="cover__item cover__item--add">
                <input
                  type="file"
                  hidden
                  @change="filesChange($event.target)"
                />
                <button
                  class="cover__button"
                  v-if="offerObject.json.CoverManual"
                  style="pointer-events: none"
                  :style="{
                    backgroundImage: `url(${
                      imageBlobCheck('builder', 'covers_manual', 1)
                        ? offerObject.json.CoverManual.blob
                        : getImageOfferSrc(offerObject.json.CoverManual.img)
                    })`,
                  }"
                ></button>
                <div class="cover__button" v-else>
                  <span>+</span>
                  <p>Загрузить своё фото</p>
                </div>
              </label>

              <div
                class="cover__item"
                v-for="(item, index) in settingsObjectStandart.covers.filter(
                  (item) => item.img
                )"
                :key="index"
              >
                <button
                  class="cover__button"
                  @click="
                    () => {
                      offerObject.json.CoverManualSet = false;
                      changeCover(item.img);
                    }
                  "
                  :style="{ backgroundImage: `url(${getImageSrc(item.img)})` }"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button class="btn-full btn-full__green" @click="changeStep(2)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_1 from "../prints/print_1";
import { copy } from "../../../../../mixins/sales/SalesJSONS";
export default {
  mixins: [SalesHelpers],
  created() {
    if (!this.offerObject.json.Cover) {
      this.offerObject.json.Cover = copy(
        this.settingsObjectStandart.covers[0].img
      );
    }
  },
  methods: {
    changeCover(img) {
      this.offerObject.json.Cover = img;
    },
    filesChange(input) {
      const fileList = input.files;
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        const blob = window.URL.createObjectURL(fileList[0]);

        this.offerObject.json.CoverManual = {
          id: 1,
          img: fileList[0].name,
          blob,
        };
        this.offerObject.images.covers_manual = [{ id: 1, file: fileList[0] }];
        this.offerObject.blobImages.covers_manual = [
          { id: 1, file: fileList[0] },
        ];

        input.value = null;
      }
      this.offerObject.json.CoverManualSet = true;
      this.changeCover(this.offerObject.json.CoverManual.img);
    },
  },
  computed: {
    ...mapState("sales", ["offerObject", "settingsObjectStandart"]),
  },
  components: { Print_1 },
};
</script>
