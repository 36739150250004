<template>
  <div class="task-popup-header task-popup-header--files">
    <div class="actions-header__col actions-header__col_flexible">
      <div class="search-bar search-bar_white actions-header__search-bar">
        <button type="submit" class="search-bar__button">
          <svg>
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-search"
            ></use>
          </svg>
        </button>
        <input
          type="search"
          placeholder="Название документа"
          class="search-bar__input"
          v-model="search"
        />
        <div class="filter-active-label" v-if="filterEnabled">
          Применён фильтр
          <span
            class="filter-active-label__close"
            @click="$refs['files-filter'].cancel()"
          >
            <svg>
              <use xlink:href="/static/images/icon.svg#icon_icon-close"></use>
            </svg>
          </span>
        </div>
        <div
          class="form-filter__btn"
          :class="{ 'form-filter__btn--active': filterEnabled }"
          @click="filterControlOpen = !filterControlOpen"
        >
          <span></span>
        </div>
        <transition name="fade">
          <files-filter
            ref="files-filter"
            v-show="filterControlOpen"
            @close="filterControlOpen = false"
          />
        </transition>
      </div>
    </div>
    <div class="actions-header__col">
      <span
        href="#"
        class="icon-button task-files-header__btn"
        @click="$emit('makeFavoriteFilter', !favoritesOnly)"
      >
        <i
          class="task-files-header__icon-1"
          :class="{ 'task-files-header__icon-1--active': favoritesOnly }"
        ></i>
      </span>
      <span
        class="icon-button task-files-header__btn"
        @click="$emit('setGridView', !gridView)"
      >
        <i class="task-files-header__icon-2"></i>
      </span>
    </div>
  </div>
</template>

<script>
import FilesFilter from "./FilesFilter";

export default {
  props: ["gridView", "favoritesOnly"],
  data() {
    return {
      search: "",
      filterControlOpen: false,
      isMounted: false,
    };
  },
  computed: {
    filterEnabled() {
      if (this.isMounted) {
        const filterTypes = this.$refs["files-filter"].filterTypes;
        const filterPersons = this.$refs["files-filter"].filterPersons;
        if (filterTypes.length || filterPersons.length) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.isMounted = true;
  },
  components: {
    FilesFilter,
  },
};
</script>

<style scoped></style>
