<template>
  <div class="cm-popup__right">
    <div class="task-popup-header">
      <div class="actions-header__col">
        <span
          class="button tm-btn tm-btn--add"
          @click="
            settings.processesCreatePopupOpen = !settings.processesCreatePopupOpen
          "
          >+</span
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <div class="search-bar search-bar_white actions-header__search-bar">
          <button class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Поиск"
            class="search-bar__input"
            v-model="search"
          />
        </div>
      </div>
    </div>
    <div class="task-popup-list">
      <div
        class="process-list-item"
        v-for="item in filteredProcesses"
        :key="item.Id"
      >
        <p class="process-list-item__name">
          {{ item.Name }}
          <button
            class="link"
            style="margin-left: 10px; font-weight: normal; color: red"
            @click="detach(item.Id)"
          >
            открепить
          </button>
        </p>
        <div class="process-list-item__labels">
          <ul class="process-list-item__labels-list">
            <li
              class="process-list-item__labels-item task-label"
              v-for="status in item.statuses"
              :key="status.Id"
              :style="{ background: status.Color }"
            >
              {{ status.Name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  DETACH_PROCESS_FROM_GROUP,
  GET_PROCESSES,
} from "../../../../store/tasks/constants";

export default {
  name: "Processes",
  props: ["settings"],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredProcesses() {
      return this.groupProcesses.filter((i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.Name);
      });
    },
    ...mapState("tasks", ["groupProcesses"]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    detach(id) {
      this[DETACH_PROCESS_FROM_GROUP]({
        GroupId: this.group.Id,
        ProcessId: id,
      }).then(() => {
        this[GET_PROCESSES](this.group.Id);
      });
    },
    ...mapActions("tasks", [GET_PROCESSES, DETACH_PROCESS_FROM_GROUP]),
  },
};
</script>
