import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    settings: [],
    itemsWithImages: ["news"],
    itemsWithContent: [
      "news",
      "services",
      "examples",
      "productionCategories",
      "staticPages",
      "solutions",
      "BlogPosts",
    ],
    itemsWithSliders: ["examples"],
    itemsWithSeo: [
      "blocks",
      "productionCategories",
      "production",
      "solutions",
      "examplesWorks",
      "news",
      "benefits",
      "services",
      "blocks_of_company",
      "staticPages",
      "examples",
      "BlogPosts",
      "BlogCategories",
      "promo_landing",
    ],
    blocks: [],
    workTypeItems: [],
    blogCategoriesItems: [],
    cities: [],
    parentData: {
      language: "ru",
      translateTableLanguage: "en",
    },
    baseUrl: "",
    districts: [],
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
