<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name"
              >Функциональные задачи системы</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div
        class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single"
      >
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 1"
          >
            Тц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 2"
          >
            Бц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 3"
          >
            ЖК
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 4"
          >
            Отели
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 5 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 5"
          >
            Спорт комплексы
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 6 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 6"
          >
            Аэропорты и ЖД
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 7 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 7"
          >
            Парки
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 8 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 8"
          >
            Паркинг
          </div>
        </div>

        <label class="field" v-if="tab === 1">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.tc"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 2">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.bc"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 3">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.zk"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 4">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.hotels"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 5">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.sport"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 6">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.air"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 7">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.parks"
            ></textarea>
          </span>
        </label>
        <label class="field" v-if="tab === 8">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Список задач:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.tasks.others"
            ></textarea>
          </span>
        </label>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(11, false, getObjectsTypeByTab(tab))"
          >Превью</span
        >
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
export default {
  mixins: [SalesHelpers],
  data() {
    return {
      tab: 1,
    };
  },

  methods: {},
};
</script>
