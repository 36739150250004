<template>
  <div class="subtask-warpper">
    <SimplePreloader v-if="preloader || creatingTaskPreloader" />
    <div
      class="subtask-row"
      :class="{
        'subtask-row--open': open,
        'subtask-row--selected': selectedItems.find((x) => x.Id === task.Id),
        'subtask-row--focused': isFocused || edit,
      }"
      @mouseover.alt="edit = true"
      @mouseleave="edit = false"
      @click="openSubtask"
    >
      <div
        class="subtask-row__level-padding"
        v-for="c in new Array(level)"
        :key="c"
      ></div>
      <div class="subtask-row__counter-label" @click.stop>
        <label class="subtask-row__counter-checkbox checkbox">
          <input
            class="checkbox__input main_checkbox_input"
            type="checkbox"
            @click.prevent="
              () => {
                if (!selectedItems.find((x) => x.Id === task.Id)) {
                  selectedItems.push(task);
                } else {
                  selectedItems.splice(
                    selectedItems.indexOf(
                      selectedItems.find((x) => x.Id === task.Id)
                    ),
                    1
                  );
                }
              }
            "
            :class="{
              'checkbox__box--active': selectedItems.find(
                (x) => x.Id === task.Id
              ),
            }"
          />
          <span class="checkbox__box">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
        </label>
        <div class="subtask-row__counter-value">{{ index + 1 }}</div>
      </div>
      <div class="subtask-row__bth-child" @click.stop="disclose">
        <svg width="12" height="7">
          <use
            xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
          ></use>
        </svg>
      </div>
      <div class="subtask-row__name" @click.stop @mouseover="edit = true">
        <input
          class="subtask-row__name-input"
          @blur="isFocused = false"
          @focus="isFocused = true"
          @change="saveAfterEdit(null, () => {}, 'TemplateTaskName')"
          v-model="task.TemplateTaskName"
        />
        <p class="subtask-row__name-clear-value subtask-row__name-shadow">
          {{ task.TemplateTaskName }}
        </p>
        <div
          class="subtask-row__task-deep-info task-deep-info"
          v-if="childCount"
        >
          <i class="deep-icon"></i>{{ childCount }}
        </div>
      </div>
      <!--      <div class="subtask-row__labels">-->
      <!--        <div class="task-labels">-->
      <!--          <span-->
      <!--            class="task-label"-->
      <!--            v-for="tag in taskForm.TagsIds"-->
      <!--            :style="{ backgroundColor: tag.Color }"-->
      <!--            :key="tag.Id"-->
      <!--            @click="showTagsListBubble = true"-->
      <!--            >{{ tag.TagName }}-->
      <!--            <div class="task-table-person__delete" @click="deleteTag(tag.Id)">-->
      <!--              <svg focusable="false" viewBox="0 0 32 32">-->
      <!--                <path-->
      <!--                  d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"-->
      <!--                ></path>-->
      <!--              </svg>-->
      <!--            </div>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="subtask-row__finish-date" @click.stop>
        <p v-if="taskForm.FinishDate">{{ getFinishDate }}</p>
        <p v-else>Указать срок</p>
        <date-input
          :date="taskForm.FinishDate"
          :timeValue="taskForm.FinishTime"
          :format="'DD MMMM YYYY в HH:mm'"
          @setDate="setDate"
        />
      </div>
      <div class="subtask-row__responsible" @click.stop>
        <div
          class="subtask-row__responsible-no-person"
          @click="responsibleBubbleOpen = !responsibleBubbleOpen"
        >
          <template v-if="!taskForm.ResponsibleId">
            <svg width="24" height="24">
              <use
                xlink:href="/static/images/tasks/sprite.svg#no-person-icon"
              ></use>
            </svg>
          </template>
          <UserAvatar :user="getUser(taskForm.ResponsibleId)" />
        </div>
        <transition name="fade">
          <PersonsListSearch
            :emptyPlaceholder="'Убрать ответственного'"
            v-if="responsibleBubbleOpen"
            :selectedPersonId="taskForm.ResponsibleId"
            @choose="setResponsible"
            @close="responsibleBubbleOpen = false"
          />
        </transition>
      </div>
    </div>
    <draggable
      v-model="subtasks"
      draggable=".subtask-warpper"
      class="subtask-row__childs"
      v-if="open"
    >
      <SubtaskRow
        v-for="(task, index) in subtasks"
        :level="level + 1"
        :key="task.Id"
        :task="task"
        :index="index"
        :selectedItems="selectedItems"
        :templateModel="templateModel"
        :newSubtask="newSubtask"
        :noSave="noSave"
        :editedTemplateTasks="editedTemplateTasks"
        @editTask="editTask"
        @editTemplateTask="
          (data, formData, callback) =>
            $emit('editTemplateTask', data, formData, callback)
        "
        @removeFromList="$emit('removeFromList', $event)"
        @setTaskToDetailPopup="
          (task, callback) => $emit('setTaskToDetailPopup', task, callback)
        "
        @changeOrder="(data) => $emit('changeOrder', data)"
      />
      <div class="subtask-row__add-task-btn" @click="addTask" v-if="!preloader">
        <div
          class="subtask-row__level-padding"
          v-for="c in new Array(level)"
          :key="c"
        ></div>
        <span>+</span>Добавить подзадачу
      </div>
    </draggable>
  </div>
</template>

<script>
import SubtaskRow from "./SubtaskRow";
import { Helpers } from "../../../../../../mixins/Helpers";
import * as _ from "lodash";
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
import common from "../../../../../../mixins/tasks/common";
import moment from "moment";
import {
  CREATE_TEMPLATE_TASK,
  GET_TEMPLATE_TASK_CHILD,
} from "@/store/tasks/constants";
import UserAvatar from "@/modules/tasks/components/CommonComponents/UserAvatar";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";
import saveMixin from "@/modules/tasks/components/TaskCreateForm/mixins/saveMixin";

export default {
  mixins: [Helpers, common, saveMixin],
  props: [
    "task",
    "newSubtask",
    "level",
    "selectedItems",
    "index",
    "templateModel",
    "noSave",
    "editedTemplateTasks",
  ],
  name: "SubtaskRow",
  data() {
    return {
      menuOpen: false,
      open: false,
      addTagBubbleOpen: false,
      showTagsListBubble: false,
      processesBubbleOpen: false,
      responsibleBubbleOpen: false,
      edit: false,
      isFocused: false,
      preloader: false,
      creatingTaskPreloader: false,
    };
  },
  computed: {
    getFinishDate() {
      const date = moment(this.taskForm.FinishDate);
      const time = this.taskForm.FinishTime
        ? moment(this.taskForm.FinishTime, "HH:mm:ss")
        : false;
      if (time) {
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
      }
      return date.locale("ru").format("DD.MM.YYYY HH:mm");
    },
    taskForm() {
      return this.task?.TemplateTaskSettings;
    },
    subtasks: {
      get() {
        return this.task.subtasks;
      },
      set(val) {
        this.task.subtasks = val;
        this.$emit("changeOrder", val);
        this.$emit("editTask", JSON.parse(JSON.stringify(this.task)));
      },
    },
    childCount() {
      if (this.task.subtasks && this.task.subtasks.length) {
        return this.task.subtasks.length;
      }
      return this.task.ChildCount;
    },
    ...mapState("tasks", ["tags"]),
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
    closeTagListBubble() {
      this.showTagsListBubble = false;
    },
    deleteTag(id) {
      this.taskForm.TagsIds.splice(this.taskForm.TagsIds.indexOf(id), 1);
    },
    addTag(tagId) {
      this.task.TagsIds.push(tagId);
    },
    getTag(id) {
      return _.find(this.tags, ["Id", id]);
    },
    adjustLevelColor() {
      let color = "#F9F9F9",
        amount = -((this.level - 1) * 10);
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
    setEditState() {
      this.edit = true;
      setTimeout(() => {
        this.$refs.input.$el.focus();
      }, 100);
    },
    setDate(date) {
      this.taskForm.FinishDate = date;
      this.taskForm.FinishTime = date ? moment(date).format("HH:mm:ss") : null;
      this.saveAfterEdit(null, () => {}, "FinishDate");
    },
    addTask() {
      if (!this.task.subtasks) {
        this.task.subtasks = [];
      }
      const taskToCreate = this.newSubtask();
      this.task.subtasks.push(taskToCreate);
      if (this.templateModel && !this.noSave) {
        this.creatingTaskPreloader = true;
        this[CREATE_TEMPLATE_TASK]({
          ...taskToCreate,
          TemplateId: this.templateModel.Id,
          TemplateParentTaskId: this.task.Id,
        })
          .then((task) => {
            if (task) {
              this.task.subtasks = [
                ...this.task.subtasks.filter((i) => i.Id !== taskToCreate.Id),
                task,
              ];
            }
          })
          .catch(() => {
            this.task.subtasks = this.task.subtasks.filter(
              (i) => i.Id !== taskToCreate.Id
            );
          })
          .finally(() => (this.creatingTaskPreloader = false));
      }
    },
    getChild() {
      if (this.templateModel) {
        this.preloader = true;
        this[GET_TEMPLATE_TASK_CHILD](this.task.Id)
          .then((tasks) => {
            if (!this.task.subtasks) {
              this.task.subtasks = [];
            }
            if (tasks) {
              this.task.subtasks = this.task.subtasks.length
                ? this.task.subtasks
                : tasks;
            }
            this.$emit("editTask", JSON.parse(JSON.stringify(this.task)));
          })
          .finally(() => {
            this.preloader = false;
          });
      }
    },
    disclose() {
      this.open = !this.open;
      if (this.open) {
        this.getChild();
      }
    },
    openSubtask() {
      this.preloader = true;
      this.$emit(
        "setTaskToDetailPopup",
        this.task,
        () => (this.preloader = false)
      );
    },
    setResponsible(id) {
      console.log(this.task);
      this.taskForm.ResponsibleId = id;
      this.saveAfterEdit(null, () => {}, "ResponsibleId");
    },
    editTask(task) {
      this.task.subtasks = _.map(this.task.subtasks, (i) => {
        if (i.Id === task.Id) {
          i = task;
        }
        return i;
      });
    },
    ...mapActions("tasks", [CREATE_TEMPLATE_TASK, GET_TEMPLATE_TASK_CHILD]),
  },
  components: {
    SubtaskRow,
    UserAvatar,
    PersonsListSearch,
    draggable,
  },
};
</script>

<style scoped lang="scss">
.subtask-warpper {
  position: relative;
}
.simple-preloader {
  display: block;
  top: 20px;
  bottom: auto;
  width: 30px;
  z-index: 1;
  background: none;
}
.subtask-row {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  padding: 8px 24px;
  padding-right: 8px;
  position: relative;
  &:hover {
    background: #fafafa;
  }
}
.subtask-row__select {
  display: flex;
  margin-left: 0;
}
.subtask-row__bth-child {
  display: flex;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    display: block;
  }
  svg {
    transform: rotate(-90deg);
  }
}
.subtask-row__name {
  margin-left: 10px;
  flex: 1;
  padding-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  input {
    font-size: 14px;
    color: #202325;
    width: 100%;
    padding-right: 30px;
    &::placeholder {
      color: #929294;
    }
  }
}
.subtask-row__finish-date {
  margin-left: auto;
  font-size: 11px;
  color: #007fff;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}
.subtask-row__responsible {
  margin-left: 16px;
  cursor: pointer;
}
.subtask-row__responsible-no-person {
  display: flex;
}
.subtask-row__childs {
}
.subtask-row__add-task-btn {
  font-size: 14px;
  color: #929294;
  border-bottom: 1px solid #e6e6e6;
  height: 41px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 79px;
  position: relative;
  span {
    font-weight: bold;
    font-size: 16px;
    margin-right: 17px;
  }
}
.subtask-row--open {
  background: #fafafa;
  .subtask-row__bth-child svg {
    transform: rotate(0);
  }
  .subtask-row__name {
    font-weight: bold;
  }
}
.subtask-row__level-padding {
  width: 27px;
}
.subtask-row__labels {
  max-width: 320px;
  margin-top: -3px;
  margin-right: 20px;
  overflow: visible;
  .task-label {
    white-space: nowrap;
    margin-top: 5px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.subtask-row__counter-label {
  width: 16px;
  text-align: center;
  flex-shrink: 0;
}
.subtask-row__counter-value {
  font-size: 13px;
  color: #b2b2b2;
}
.subtask-row--selected {
  .subtask-row__counter-checkbox {
    display: block;
  }
  .subtask-row__counter-value {
    display: none;
  }
}
.subtask-row__counter-checkbox {
  display: none;
}
.subtask-row:hover {
  > .subtask-row__counter-label .subtask-row__counter-checkbox {
    display: block;
  }
  > .subtask-row__counter-label .subtask-row__counter-value {
    display: none;
  }
}
.subtask-row__name {
  font-size: 14px;
  color: #202325;
  flex: 0 1 auto;
  min-width: 1px;
  z-index: 1;
  position: relative;
  white-space: nowrap;
  min-height: 18px;
}
.subtask-row__name-shadow {
  opacity: 0;
  border: 1px solid transparent;
  pointer-events: none;
  margin-right: 30px;
}
.subtask-row__name-input {
  position: absolute;
  width: 100%;
  top: 0;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 8px;
  transform: translate(-8px, -5px);
  height: auto;
  transition: none;
  pointer-events: none;
}
.subtask-row__name-input:focus {
  border-color: #b2b2b2;
  color: #202325 !important;
}
.subtask-row--focused {
  .subtask-row__name-input {
    border-color: #b2b2b2;
    color: #202325 !important;
    pointer-events: all;
  }
  .subtask-row__task-deep-info {
    display: none;
  }
}
.subtask-row__task-deep-info {
  position: absolute;
  right: 0px;
  margin: 0;
}
</style>

<style>
.subtask-row__finish-date .mx-datepicker {
  position: absolute;
  top: -10px;
}
.subtask-row__finish-date .mx-input-wrapper {
  opacity: 0;
}
.subtask-row__responsible .task-table-person__circle {
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
</style>
