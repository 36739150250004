<template>
  <div
    class="popup event-popup event-popup-ob"
    :class="is_open ? 'popup--active' : ''"
  >
    <div
      class="popup__wrapper popup__wrapper_co-admin"
      @click="hidePopupByClickWrap($event)"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">{{ offerName }}</h2>
          <div class="popup__close" @click="onCloseSettings">
            <svg class="popup__close-icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-close"></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="popup__setting flex-wrap">
            <div class="col-auto">
              <button
                v-if="offerObject.json.SystemType"
                :class="allowSave ? '' : 'disabled-btn__wrap'"
                class="btn btn__green w-160"
                @click="saveOffer"
              >
                СОХРАНИТЬ
              </button>
            </div>
            <div class="col-auto" v-if="offerObject.json.SystemType">
              <div class="table__text-block rate-text">
                Курс доллара:
                <input
                  type="number"
                  class="input"
                  :placeholder="rateValue"
                  :value="offerObject.json.rateValue"
                  @blur="changeRateValue($event)"
                />
              </div>
            </div>
            <!--            <div class="col-auto ml-auto">-->
            <!--              <button class="btn w-145" @click="resetOffer">ОТМЕНА</button>-->
            <!--            </div>-->
          </div>
          <step-0--system v-if="!offerObject.json.SystemType" />
          <template v-else>
            <div class="popup__cont">
              <step-nav :step="step" @changeStep="changeStep" />
              <step-1--covers v-if="step === 1" @changeStep="changeStep" />
              <step-2--content v-if="step === 2" @changeStep="changeStep" />
              <step-3--tasks v-if="step === 3" @changeStep="changeStep" />
              <step-3-1--tasks v-if="step === 3.1" @changeStep="changeStep" />
              <step-4--schemes v-if="step === 4" @changeStep="changeStep" />
              <step-5--options v-if="step === 5" @changeStep="changeStep" />
              <step-6--entry v-if="step === 6" @changeStep="changeStep" />
              <!--              <step-6-1&#45;&#45;entry v-if="step===6.1" @changeStep="changeStep"/>-->
              <step-7--exit v-if="step === 7" @changeStep="changeStep" />
              <step-7-1--exit v-if="step === 7.1" @changeStep="changeStep" />
              <step-8--payment v-if="step === 8" @changeStep="changeStep" />
              <step-9--additional v-if="step === 9" @changeStep="changeStep" />
              <step-9-1--additional-desc-page
                v-if="step === 9.1"
                @changeStep="changeStep"
              />
              <step-10--total v-if="step === 10" @changeStep="changeStep" />
              <step-11--tasks v-if="step === 11" @changeStep="changeStep" />
              <step-12--ad_owner v-if="step === 12" @changeStep="changeStep" />
              <step-13--ad_tenant v-if="step === 13" @changeStep="changeStep" />
              <step-14--ad_guest v-if="step === 14" @changeStep="changeStep" />
              <step-15--service v-if="step === 15" @changeStep="changeStep" />
              <step-16--service2 v-if="step === 16" @changeStep="changeStep" />
              <!--              <step-17&#45;&#45;option_desc v-if="step===17" @changeStep="changeStep"/>-->
              <step-18--done_works
                v-if="step === 18"
                @changeStep="changeStep"
              />
              <step-19--objects v-if="step === 19" @changeStep="changeStep" />
              <step-20--final v-if="step === 20" @changeStep="changeStep" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Nav from "./Nav";
import * as c from "@/store/sales/constants";
import Step_0 from "./steps/step-0-system";
import Step_1 from "./steps/step-1-covers";
import Step_2 from "./steps/step-2-content";
import Step_3 from "./steps/step-3-tasks";
import Step_3_1 from "./steps/step-3-1-tasks";
import Step_4 from "./steps/step-4-schemes";
import Step_5 from "./steps/step-5-options";
import Step_6 from "./steps/step-6-entry";
import Step_6_1 from "./steps/step-6-1-entry";
import Step_7 from "./steps/step-7-exit";
import Step_7_1 from "./steps/step-7-1-exit";
import Step_8 from "./steps/step-8-payment";
import Step_9 from "./steps/step-9-additional";
import Step_10 from "./steps/step-10-total";
import Step_11 from "./steps/step-11-tasks";
import Step_12 from "./steps/step-12-ad_owner";
import Step_13 from "./steps/step-13-ad_tenant";
import Step_14 from "./steps/step-14-ad_guest";
import Step_15 from "./steps/step-15-service";
import Step_16 from "./steps/step-16-service2";
import Step_17 from "./steps/step-17-option_desc";
import Step_18 from "./steps/step-18-done_works";
import Step_19 from "./steps/step-19-objects";
import Step_9_1 from "./steps/step-9-1-additional-desc-page";
import Step_20 from "./steps/step-20-final";
import * as _ from "lodash";

import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import moment from "moment";
import { showAlert } from "../../../../utils";
export default {
  data() {
    return {
      step: 1,
      allowSave: true,
    };
  },
  mixins: [SalesHelpers],
  props: {
    is_open: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    step(val) {
      if (val === 2) {
        if (!this.offerObject.json.CompanyName) {
          this.offerObject.json.CompanyName = this.offerObject.json.Name;
        }
      }
      if (val === 3) {
        if (!this.offerObject.json.ObjectName) {
          this.offerObject.json.ObjectName = this.offerObject.json.Name;
        }
      }
    },
  },
  created() {
    window.s = this.offerObject;
    window.offerModuleTitles = {
      Entry: 0,
    };
  },
  computed: {
    ...mapState("sales", ["offerObject", "settingsObject", "rateValue"]),

    offerName() {
      const city = this.offerObject.json.City
        ? ", " + this.offerObject.json.City
        : "";
      return this.offerObject.json.Name
        ? this.offerObject.json.Name + city
        : "Новое коммерческое предложение";
    },
  },
  methods: {
    onCloseSettings() {
      this.$emit("onCloseSettings");
      this.step = 1;
      this[c.RESET_OFFER]();
      this[c.CHANGE_SYSTEM]("standart");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onCloseSettings();
      }
    },
    changeStep(step) {
      this.step = step;
    },

    saveOffer() {
      this.allowSave = false;
      this[c.SAVE_OFFER]({})
        .catch(() => {
          showAlert(
            "",
            "Ошибка",
            "Соединение с интернетом прервано, дамп КП сохранен локально."
          );
          this.downloadDump();
        })
        .finally(() => {
          this.allowSave = true;
        });
    },
    downloadDump() {
      const element = document.createElement("a");
      const fileName = this.offerObject.json.Name
        ? this.offerObject.json.Name + "_"
        : "";
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(JSON.stringify(this.offerObject.json))
      );
      element.setAttribute(
        "download",
        fileName + "dump_at_" + moment().format("YYYY-MM-DD HH:mm:ss") + ".txt"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    changeRateValue(e) {
      let newRateValue = e.target.value;
      let oldValue = this.offerObject.json.rateValue;
      // if (!newRateValue) e.target.value = newRateValue = this.rateValue
      if (this.rateValue !== newRateValue) {
        this.offerObject.json.rateValue = newRateValue;
        this[c.CONVERT_TO_RUB]({ newValue: newRateValue, oldValue });
        this.offerObject.json.Total.Options.types = _.map(
          this.offerObject.json.Total.Options.types,
          (i) => {
            // if (i.name === 'Пуско-наладочные работы') {
            //   i.convertCost = this.totalDevicesPrice * 7 / 100
            // }
            i.convertCost = Math.round(i.convertCost / 1000) * 1000;
            return i;
          }
        );
      }
    },
    ...mapActions("sales", [
      c.RESET_OFFER,
      c.SAVE_OFFER,
      c.CONVERT_TO_RUB,
      c.CHANGE_SYSTEM,
    ]),
  },
  components: {
    "step-nav": Nav,
    "step-0--system": Step_0,
    "step-1--covers": Step_1,
    "step-2--content": Step_2,
    "step-3--tasks": Step_3,
    "step-3-1--tasks": Step_3_1,
    "step-4--schemes": Step_4,
    "step-5--options": Step_5,
    "step-6--entry": Step_6,
    // eslint-disable-next-line vue/no-unused-components
    "step-6-1--entry": Step_6_1,
    "step-7--exit": Step_7,
    "step-7-1--exit": Step_7_1,
    "step-8--payment": Step_8,
    "step-9--additional": Step_9,
    "step-10--total": Step_10,
    "step-11--tasks": Step_11,
    "step-12--ad_owner": Step_12,
    "step-13--ad_tenant": Step_13,
    "step-14--ad_guest": Step_14,
    "step-15--service": Step_15,
    "step-16--service2": Step_16,
    // eslint-disable-next-line vue/no-unused-components
    "step-17--option_desc": Step_17,
    "step-18--done_works": Step_18,
    "step-19--objects": Step_19,
    "step-9-1--additional-desc-page": Step_9_1,
    "step-20--final": Step_20,
  },
};
</script>
