<template>
  <div class="cm-popup__right">
    <div class="header task-header cm-header" v-if="selectedItems.length">
      <div class="header__col cm-group-actions">
        <div class="button-group">
          <div
            class="button button-close button_grey button_uppercase"
            @click="unselectTemplates()"
          >
            <img src="/static/images/sales/close.svg" alt="" />
          </div>
          <div class="button button_grey button_uppercase">
            {{ selectedItems.length }}
          </div>
        </div>
        <div
          class="button button_grey button_uppercase"
          style="margin-left: auto"
          @click="deleteTemplates()"
        >
          удалить
        </div>
      </div>
    </div>

    <div class="task-popup-header" v-else>
      <div class="actions-header__col">
        <span
          class="button button_green button_uppercase"
          @click="createTemplate(null)"
          >Создать</span
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          method="post"
          action="#"
          class="search-bar search-bar_white actions-header__search-bar"
        >
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Введите название шаблона"
            class="search-bar__input"
            v-model="search"
          />
        </form>
      </div>
    </div>

    <div class="task-popup-list">
      <div
        class="task-popup-list__row"
        v-for="(item, index) in filteredTemplates"
        :key="index"
      >
        <label
          class="checkbox checkbox_white table__checkbox js-index-checkbox"
        >
          <input
            class="checkbox__input main_checkbox_input"
            type="checkbox"
            name="row_checkbox_clients"
            @click="selectItem($event, item.Id)"
          /><span class="checkbox__box main_checkbox_span">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
        </label>
        <span @click="createTemplate(item)" class="task-popup-list__row-name"
          >{{ item.TemplateName }}
        </span>
        <span
          class="task-popup-list__label label-button"
          v-if="isDefaultTemplate(item.Id)"
          >Основной</span
        >
      </div>
      <SimplePreloader v-if="preloader" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import * as c from "../../../../store/tasks/constants";
import * as _ from "lodash";
import { CREATE_TEMPLATE } from "../../../../store/tasks/constants";

export default {
  data() {
    return {
      selectedItems: [],
      search: "",
      preloader: false,
    };
  },
  computed: {
    filteredTemplates() {
      return _.filter(this.templates, (i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.TemplateName);
      });
    },
    ...mapGetters("tasks", ["group"]),
    ...mapState("tasks", ["templates"]),
  },
  methods: {
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.selectedItems.includes(id)) {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    deleteTemplates() {
      this.selectedItems.forEach((id) => {
        this[c.DELETE_TEMPLATE](id);
        if (id === this.selectedItems[this.selectedItems.length - 1]) {
          this.unselectTemplates();
        }
      });
    },
    unselectTemplates() {
      this.selectedItems = [];
      document
        .querySelectorAll(".task-popup-list .checkbox__input")
        .forEach((i) => {
          i.checked = false;
        });
    },
    isDefaultTemplate(id) {
      return this.group.DefaultTemplateId === id;
    },
    async createTemplate(template = null) {
      this.preloader = true;
      if (!template) {
        await this[CREATE_TEMPLATE]({
          GroupId: this.group.Id,
          payload: {
            TemplateName: "Новый шаблон",
          },
        }).then(({ taskModel, entityModel }) => {
          template = { ...entityModel, taskModel };
        });
      } else {
        template = JSON.parse(JSON.stringify(template));
      }
      this.$emit("setTemplateForEdit", template);
      this.preloader = false;
    },
    ...mapActions("tasks", [c.DELETE_TEMPLATE, CREATE_TEMPLATE]),
  },
  components: {},
};
</script>

<style scoped></style>
