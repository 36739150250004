<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_13 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_13 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('AdvantagesTenant')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div class="new-changes-block">
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>{{ offerObject.json.AdvantagesTenant.desc }}</p>
            <br />
            <p>{{ offerObject.json.AdvantagesTenant.advantages }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <p>{{ compareObject.AdvantagesTenant.desc }}</p>
            <br />
            <p>{{ compareObject.AdvantagesTenant.advantages }}</p>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('AdvantagesTenant'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__center pdf-settings__cont--total pb-0 scroll-style"
      >
        <div class="pdf-settings__info-text">
          <div class="pdf-settings__info-text">
            Данные для этой страницы <br />
            редактировать нельзя.
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <div class="p-settings__total">
          <div class="p-settings__check">
            <label
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                name="row_checkbox_clients"
                @click.prevent="
                  offerObject.json.passPages.ad_tenant = !offerObject.json
                    .passPages.ad_tenant
                "
                :class="
                  offerObject.json.passPages.ad_tenant
                    ? 'checkbox__box--active'
                    : ''
                "
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </label>
          </div>
          <div class="col-auto">Не добавлять страницу в КП</div>
        </div>
        <div class="pdf-settings__next pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(12)">
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(14)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_13 from "../prints/print_13";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  components: { Print_13 },
};
</script>
