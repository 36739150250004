<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <svg width="16" height="24">
        <use
          xlink:href="/static/images/tasks/sprite.svg#responsible-icon"
        ></use>
      </svg>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Ответственный:</p>
          <div
            class="task-create-form__field-value"
            @click="responsibleBubbleOpen = !responsibleBubbleOpen"
          >
            <template v-if="!form.ResponsibleId">Выбрать</template>
            <template v-else>{{
              getPersonNameById(form.ResponsibleId)
            }}</template>
            <transition name="fade">
              <PersonsListSearch
                :emptyPlaceholder="'Убрать ответственного'"
                v-if="responsibleBubbleOpen"
                :selectedPersonId="form.ResponsibleId"
                :className="'task-bubble--templates'"
                :outerUsers="localUsersArray"
                @choose="setResponsibleId"
                @close="responsibleBubbleOpen = false"
              />
            </transition>
          </div>
        </div>
      </div>
      <!--      <label class="task-create-form__task-checkbox-btn task-checkbox-btn">-->
      <!--        <div class="checkbox checkbox_white table__checkbox js-index-checkbox">-->
      <!--          <input-->
      <!--            class="checkbox__input main_checkbox_input"-->
      <!--            type="checkbox"-->
      <!--            v-model="form.Settings.ResponsibleAutoAccept"-->
      <!--            @change="$emit('saveAfterEdit')"-->
      <!--          />-->
      <!--          <span class="checkbox__box main_checkbox_span">-->
      <!--            <svg class="checkbox__icon">-->
      <!--              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>-->
      <!--            </svg>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        Необходимо принять-->
      <!--      </label>-->
    </div>
  </div>
</template>

<script>
import PersonsListSearch from "../../CommonComponents/PersonsListSearch";
import common from "../../../../../mixins/tasks/common";

export default {
  mixins: [common],
  props: ["form", "localUsersArray"],
  data() {
    return {
      responsibleBubbleOpen: false,
    };
  },
  methods: {
    setResponsibleId(id) {
      this.form.ResponsibleId = id;
      this.$emit("saveAfterEdit", null, () => {}, "ResponsibleId");
    },
  },
  components: {
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
