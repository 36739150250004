<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Ключевые проекты</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form cm-client-detail-form--objects">
      <div
        class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single cm-popup-form__wrapper--objects"
      >
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 1"
          >
            Тц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 2"
          >
            Бц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 3"
          >
            ЖК
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 4"
          >
            Отели
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 5 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 5"
          >
            Спорт комплексы
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 6 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 6"
          >
            Аэропорты и ЖД
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 7 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 7"
          >
            Парки
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 8 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 8"
          >
            Паркинг
          </div>
        </div>

        <template v-if="tab === 1">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.tc"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'tc', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_tc', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'tc', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'tc')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 2">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.bc"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'bc', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_bc', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'bc', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'bc')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 3">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.zk"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'zk', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_zk', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'zk', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'zk')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 4">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.hotels"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'hotels', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_hotels', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'hotels', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'hotels')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 5">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.sport"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'sport', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_sport', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'sport', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'sport')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 6">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.air"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'air', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_air', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'air', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'air')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 7">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.parks"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'parks', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_parks', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'parks', item.id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'parks')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
        <template v-if="tab === 8">
          <div class="co-object-wrap">
            <div
              class="co-object"
              v-for="(item, index) in settingsObject.json.objects.others"
              :key="index"
            >
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Картинка:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    class="input"
                    @change="filesChange($event.target, 'others', item.id)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="
                      getVirtualImageName('objects_others', item.img, item.id)
                    "
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.address" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Оборудование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.equipment" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Выполненные работы:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.works" />
                </span>
              </label>
              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click="actionObject('remove', 'others', item.Id)"
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span
              class="field__content input-file"
              @click="actionObject('add', 'others')"
            >
              <span class="little-down-text">+ Добавить ещё объект</span>
            </span>
          </div>
        </template>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(19, false, getObjectsTypeByTab(tab))"
          >Превью</span
        >
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import { randomId } from "../../../../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      tab: 1,
    };
  },

  methods: {
    filesChange(input, type, id) {
      const fileList = input.files;
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        this.settingsObject.json.objects[
          type
        ] = this.settingsObject.json.objects[type].map((i) => {
          if (i.id === id) {
            this.settingsObject.images.objects[type].push({
              id,
              file: fileList[0],
            });
            this.settingsObject.blobImages.objects[type].push({
              id,
              file: fileList[0],
            });
            const blob = window.URL.createObjectURL(fileList[0]);
            i.blob = blob;
            i.img = fileList[0].name;
          }
          return i;
        });
        input.value = null;
      }
    },
    actionObject(action, type, id) {
      if (action === "add") {
        this.settingsObject.json.objects[type].push({
          id: this.settingsObject.json.objects[type].length + 1,
          name: "",
          img: "",
          year: "",
          address: "",
          equipment: "",
          works: "",
          randomId: randomId(),
        });
      } else if (action === "remove") {
        this.settingsObject.json.objects[type] = _.reject(
          this.settingsObject.json.objects[type],
          { id }
        );
        this.settingsObject.images.objects[type] = _.reject(
          this.settingsObject.images.objects[type],
          { id }
        );
        this.settingsObject.json.objects[type] = _.each(
          this.settingsObject.json.objects[type],
          (item, index) => {
            item.id = index + 1;
            return item;
          }
        );
      }
    },
  },
};
</script>
