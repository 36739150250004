<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--4"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-create-form__datepicker">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Дата окончания:</p>
          <div class="task-create-form__field-value">
            <date-input
              :date="form.FinishDate"
              :timeValue="form.FinishTime"
              :format="'DD MMMM YYYY в HH:mm'"
              @setDate="setDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import DateInput from "../../CommonComponents/DateInput";
import moment from "moment";

export default {
  mixins: [common],
  props: ["form"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setDate(date) {
      this.form.FinishDate = date;
      this.form.FinishTime = date ? moment(date).format("HH:mm:ss") : null;
      this.$emit("saveAfterEdit", null, () => {}, "FinishDate");
    },
  },
  components: {
    DateInput,
  },
};
</script>

<style scoped></style>
