<template>
  <li>
    <a :href="linkToShare" class="caret">{{ item.Name }}</a>
    <ul class="nested" v-if="item.childs">
      <ChildWikiRow
        v-for="(item, index) in item.childs"
        :item="item"
        :key="index"
      />
    </ul>
  </li>
</template>

<script>
import ChildWikiRow from "./ChildWikiRow";
export default {
  name: "ChildWikiRow",
  props: ["item"],
  computed: {
    linkToShare() {
      if (this.item.ShareLink) {
        // const host =  process.env.NODE_ENV === 'development' ? window.location.host :  'dev-dist.r-p-s.ru/#'
        // return window.location.protocol + '//' + host + '/wiki-shared/' + this.item.ShareLink.split('/').pop()
        return this.item.ShareLink;
      }
      return "";
    },
  },
  components: {
    ChildWikiRow,
  },
};
</script>

<style scoped></style>
