<template>
  <div class="popup event-popup">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading" v-if="selectedCompany">
            {{ selectedCompany.Name }}
          </h2>
          <div class="popup__close" @click="close">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>

        <div
          class="table-spinner"
          v-show="preloaders.company || localPreloader"
        >
          <Spinner
            :noFadeIn="true"
            style="margin-left: 190px"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>

        <div class="popup__body" v-if="selectedCompany">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(1)"
                @click="tab = 1"
              >
                Общая информация
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(2)"
                @click="tab = 2"
              >
                Рекивизиты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(3)"
                @click="tab = 3"
              >
                Объекты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(4)"
                @click="tab = 4"
              >
                Тех. поддержка
              </div>

              <div class="cm-popup-nav__client" v-if="getDistbOfCompany">
                ДИСТРИБЬЮТОР: <br />
                <a
                  target="_blank"
                  :href="'/distb/' + getDistbOfCompany.Id"
                  class="link"
                  >{{ getDistbOfCompany.Name }}</a
                >
                <div
                  class="button button_grey button_uppercase"
                  @click="unattachDistb"
                >
                  Отвязать
                </div>
              </div>

              <div class="cm-popup__down-btn">
                <div
                  class="button button_grey button_uppercase"
                  @click="agreeToRemove = true"
                >
                  Удалить компанию
                </div>
              </div>
            </div>
            <div v-show="tab === 1" class="cm-popup__right">
              <div class="cm-popup__top-title">Данные о клиенте</div>
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Наименование:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_name"
                      :value="selectedCompany.Name"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Контактное лицо:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_ContactPerson"
                      :value="selectedCompany.ContactPerson"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Телефон:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_ContactPhone"
                      :value="selectedCompany.ContactPhone"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    E-mail:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_email"
                      :value="selectedCompany.Email"
                    />
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateCompany"
                >
                  Сохранить
                </button>
              </div>
            </div>
            <div v-show="tab === 2" class="cm-popup__right">
              <div class="cm-popup__top-title">Данные о клиенте</div>
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Юридический адрес:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_AddressJuridical"
                      :value="selectedCompany.AddressJuridical"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Физический адрес:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_AddressPhysical"
                      :value="selectedCompany.AddressPhysical"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    ИНН:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_inn"
                      :value="selectedCompany.INN"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    ОКПО:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      ref="c_Okpo"
                      :value="selectedCompany.Okpo"
                    />
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateCompany"
                >
                  Сохранить
                </button>
              </div>
            </div>
            <div v-show="tab === 3" class="cm-popup__right">
              <div class="table cm-table-1">
                <div class="table__header">
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Объект</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Адрес</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Срок действия лицензии</p>
                  </div>
                </div>
                <div class="table__row-wrap">
                  <div
                    class="table__row table__row_main"
                    :key="index"
                    v-for="(item, index) in companyObjects"
                  >
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <a
                          :href="'/object/' + item.Id"
                          target="_blank"
                          class="table__text cm-link"
                          >{{ item.Name }}</a
                        >
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.Address }}</p>
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.ExpireGet }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="table__row table__row_main">
                    <div class="table__col table__col_width_260">
                      <div
                        class="table__text-block"
                        @click="innerPopupOpen = true"
                        style="cursor: pointer"
                      >
                        <p class="table__text green-font">+ ПРИВЯЗАТЬ</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="tab === 4" class="cm-popup__right">
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Включить уникальный пин:
                  </span>
                  <span class="field__content">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        v-model="createPin"
                        v-bind:true-value="'1'"
                        v-bind:false-value="'0'"
                        id="createPin"
                      />
                      <label class="onoffswitch-label" for="createPin">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </span>
                </label>
                <div
                  class="field"
                  v-if="createPin === '1' && selectedCompany.Pin"
                >
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Уникальный пин:
                  </span>
                  <span class="field__content">
                    <span class="pin-info">{{ selectedCompany.Pin }}</span>
                  </span>
                </div>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Менять пин-код раз в месяц:
                  </span>
                  <span class="field__content">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        v-model="selectedCompany.UpdatePin"
                        v-bind:true-value="'1'"
                        v-bind:false-value="'0'"
                        id="UpdatePin"
                      />
                      <label class="onoffswitch-label" for="UpdatePin">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </span>
                </label>

                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updatePin"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="popupSlide">
      <addition-items
        v-if="innerPopupOpen"
        :items="objects.objects"
        :name="'объектов'"
        :preloader="innerPopupPreloader"
        @onClose="onClose"
        @onAttach="onAttach"
      />
    </transition>

    <v-dialog v-model="agreeToRemove" max-width="290">
      <v-card>
        <v-card-title class="headline">Предупреждение</v-card-title>
        <v-card-text> Уверены, что хотите удалить компанию ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            flat="flat"
            @click="agreeToRemove = false"
          >
            Нет
          </v-btn>
          <v-btn color="red darken-1" flat="flat" @click="clickAction">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PopupsPage from "@/mixins/popupsPage";
import { mapState, mapActions, mapGetters } from "vuex";
import { API_ROOT } from "@/config";
import axios from "axios";
import { showAlert } from "@/utils";
import * as c from "@/store/clients/constants";
import Spinner from "vue-spinkit";
import AdditionItems from "./AdditionItems";

export default {
  mixins: [PopupsPage],
  data() {
    return {
      createPin: "0",
    };
  },
  created() {
    const companyId = this.$route.params.id;
    this.localPreloader = true;
    axios
      .get(`${API_ROOT}api/clients/Company/` + companyId)
      .then(() => {
        this[c.SELECT_COMPANY](companyId);
        this.localPreloader = false;
        this.createPin = this.selectedCompany.Pin ? "1" : "0";
      })
      .catch(() => {
        showAlert("error", "Ошибка", "Нет такой компании");
        this.localPreloader = false;
        this.$router.push("/");
      });
  },
  computed: {
    ...mapState("clients", ["companies", "preloaders", "objects"]),
    ...mapGetters("clients", [
      "selectedCompany",
      "companyObjects",
      "getDistbOfCompany",
    ]),
  },
  methods: {
    clickAction() {
      this.agreeToRemove = false;
      this.deleteCompany();
    },
    ...mapActions("clients", [
      c.SELECT_COMPANY,
      c.UPDATE_COMPANY,
      c.CREATE_COMPANY,
      c.DELETE_COMPANY,
      c.PATCH_OBJECT,
      c.REMOVE_ATTACH_COMPANY_TO_DISTB,
    ]),
    onClose() {
      this.innerPopupOpen = false;
    },
    onAttach(data) {
      this.innerPopupPreloader = true;
      const payload = {
        id: data.itemId,
        payload: { CompanyID: this.selectedCompany.Id },
      };
      this[c.PATCH_OBJECT](payload).then(() => {
        this.innerPopupPreloader = false;
        this.innerPopupOpen = false;
      });
    },
    updateCompany() {
      const payload = {
        Id: this.selectedCompany.Id,
        Name: this.$refs.c_name.value,
        INN: this.$refs.c_inn.value,
        Email: this.$refs.c_email.value,
        ContactPerson: this.$refs.c_ContactPerson.value,
        ContactPhone: this.$refs.c_ContactPhone.value,
        AddressJuridical: this.$refs.c_AddressJuridical.value,
        AddressPhysical: this.$refs.c_AddressPhysical.value,
        Okpo: this.$refs.c_Okpo.value,
        id: this.selectedCompany.Id,
        ListName: this.selectedCompany.Name,
      };
      this[c.UPDATE_COMPANY](payload);
    },
    deleteCompany() {
      this[c.DELETE_COMPANY]().then(() => {
        this.close();
      });
    },
    unattachDistb() {
      this[c.REMOVE_ATTACH_COMPANY_TO_DISTB](this.selectedCompany.Id);
    },
    updatePin() {
      this.localPreloader = true;
      const UpdatePin =
        this.selectedCompany.UpdatePin === void 0
          ? "0"
          : this.selectedCompany.UpdatePin;
      axios
        .get(API_ROOT + "api/clients/Company/savePin", {
          params: {
            CompanyId: this.selectedCompany.Id,
            createPin: this.createPin,
            UpdatePin,
          },
        })
        .then((res) => {
          this.selectedCompany.Pin = res.data.Pin;
          this.$notify({
            group: "common",
            title: res.data.status,
            text: res.data.message,
            type: res.data.status,
          });
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response.data,
            type: "error",
          });
        })
        .finally(() => (this.localPreloader = false));
    },
  },
  components: { Spinner, AdditionItems },
};
</script>

<style lang="scss" scoped>
.clients-common-info__label {
  width: 145px;
}
.field__content {
  position: relative;
}
.pin-info {
  color: #202325;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}
</style>
