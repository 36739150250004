<template>
  <div class="task-form-filter" :class="className">
    <SimplePreloader v-if="loader" />
    <div class="task-form-filter__control">
      <div class="task-form-filter__control-inner">
        <div>
          <div class="task-form-filter__field">
            <span class="task-form-filter__field-title">Параметры:</span>
            <div
              class="select"
              :class="{ 'select--selected': filters.dateOption }"
            >
              <select
                class="select__control"
                v-model="filters.dateOption"
                @change="setDateFilter"
              >
                <option :value="null">Срок задачи</option>
                <option value="1">Сегодня</option>
                <option value="2">Вчера</option>
                <option value="3">За неделю</option>
                <option value="4">За месяц</option>
                <option value="5">Свой диапазон</option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>
          <div
            class="task-form-filter__field"
            v-if="filters.dateOption === '5'"
          >
            <date-picker
              v-model="filters.dateInterval"
              range
              range-separator=" - "
            ></date-picker>
          </div>
          <div class="task-form-filter__field" ref="portal-wrapper-users">
            <div
              class="fake-select"
              @click.stop.prevent="usersBubbleOpen = true"
            >
              <multiselect
                v-model="filters.responsibleUsers"
                :options="filteredUsers.map((i) => i.Id)"
                class="multi-select"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Ответственный"
                :searchable="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{
                      getMultiselectOptionName(
                        props.option,
                        filteredUsers,
                        "FullName"
                      ) | shortName
                    }}</span>
                  </div>
                </template>
                <template slot="tag" slot-scope="{ option }">
                  <span class="multiselect__tag"
                    ><span>{{
                      getMultiselectOptionName(
                        option,
                        filteredUsers,
                        "FullName"
                      )
                    }}</span></span
                  >
                </template>
              </multiselect>
            </div>
            <portal to="common">
              <transition name="fade">
                <SingleListSearch
                  v-if="usersBubbleOpen"
                  @choose="chooseUsers"
                  @close="usersBubbleOpen = false"
                  :items="filteredUsers"
                  :selectedItemsArray="filters.responsibleUsers.map((i) => i)"
                  :fieldNames="{
                    idFieldName: 'Id',
                    fieldName: 'FullName',
                  }"
                  v-portal="{ parent: $refs['portal-wrapper-users'] }"
                />
              </transition>
            </portal>
          </div>
          <div class="task-form-filter__field" ref="portal-wrapper-groups">
            <div
              class="fake-select"
              @click.stop.prevent="groupsBubbleOpen = true"
            >
              <multiselect
                v-model="filters.groupIds"
                :options="allGroups.map((i) => i.Id)"
                :disabled="!fromSidebar && filterInGroup"
                class="multi-select"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Группа"
                :searchable="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{
                      getMultiselectOptionName(props.option, allGroups, "Name")
                    }}</span>
                  </div>
                </template>
                <template slot="tag" slot-scope="{ option }">
                  <span class="multiselect__tag"
                    ><span>{{
                      getMultiselectOptionName(option, allGroups, "Name")
                    }}</span></span
                  >
                </template>
              </multiselect>
            </div>
            <portal to="common">
              <transition name="fade">
                <SingleListSearch
                  v-if="groupsBubbleOpen"
                  @choose="chooseGroups"
                  @close="groupsBubbleOpen = false"
                  :items="allGroups"
                  :selectedItemsArray="filters.groupIds.map((i) => i)"
                  :fieldNames="{
                    idFieldName: 'Id',
                    fieldName: 'Name',
                    colorFieldName: 'Color',
                  }"
                  v-portal="{ parent: $refs['portal-wrapper-groups'] }"
                />
              </transition>
            </portal>
          </div>
          <div class="task-form-filter__field">
            <div
              class="select"
              :class="{ 'select--selected': filters.taskProperty }"
            >
              <select class="select__control" v-model="filters.taskProperty">
                <option :value="null">Свойства задачи</option>
                <option
                  :value="item.id"
                  v-for="(item, index) in taskProperties"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>

          <div class="task-form-filter__field">
            <div
              class="select"
              :class="{ 'select--selected': filters.taskFiles }"
            >
              <select class="select__control" v-model="filters.taskFiles">
                <option :value="null">Файлы</option>
                <option
                  :value="item.id"
                  v-for="(item, index) in taskFiles"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
              <div class="select__arrow multiselect__select"></div>
            </div>
          </div>

          <div class="task-form-filter__field" ref="portal-wrapper-statuses">
            <div
              class="fake-select"
              @click.stop.prevent="statusesBubbleOpen = true"
            >
              <multiselect
                v-model="filters.taskStatus"
                :options="statuses.map((i) => i.Id)"
                class="multi-select"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Статусы"
                :searchable="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{
                      getMultiselectOptionName(props.option, statuses, "Name")
                    }}</span>
                  </div>
                </template>
                <template slot="tag" slot-scope="{ option }">
                  <span class="multiselect__tag"
                    ><span>{{
                      getMultiselectOptionName(option, statuses, "Name")
                    }}</span></span
                  >
                </template>
              </multiselect>
            </div>
            <portal to="common">
              <transition name="fade">
                <GroupedListSearch
                  v-if="statusesBubbleOpen"
                  @choose="chooseStatuses"
                  @close="statusesBubbleOpen = false"
                  :selectedItemsArray="filters.taskStatus.map((i) => i)"
                  :items="filteredProcesses"
                  :fieldNames="{
                    searchInputName: 'Поиск статуса',
                    idFieldName: 'Id',
                    parentFieldName: 'Name',
                    childListFieldName: 'statuses',
                    childFieldName: 'Name',
                    colorFieldName: 'Color',
                  }"
                  v-portal="{ parent: $refs['portal-wrapper-statuses'] }"
                />
              </transition>
            </portal>
          </div>

          <div class="task-form-filter__field" ref="portal-wrapper-processes">
            <div
              class="fake-select"
              @click.stop.prevent="processesBubbleOpen = true"
            >
              <div
                class="select"
                :class="{ 'select--selected': filters.taskProcess }"
              >
                <select class="select__control" v-model="filters.taskProcess">
                  <option :value="null">Процесс</option>
                  <option
                    :value="item.id"
                    v-for="(item, index) in processesFromFilterApi"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="select__arrow multiselect__select"></div>
              </div>
            </div>
            <portal to="common">
              <transition name="fade">
                <SingleListSearch
                  v-if="processesBubbleOpen"
                  @choose="(item) => (filters.taskProcess = item.Id)"
                  @close="processesBubbleOpen = false"
                  :items="processes"
                  :fieldNames="{
                    idFieldName: 'Id',
                    fieldName: 'Name',
                    colorFieldName: 'Color',
                  }"
                  v-portal="{ parent: $refs['portal-wrapper-processes'] }"
                />
              </transition>
            </portal>
          </div>

          <!--          <div class="task-form-filter__field">-->
          <!--            <div class="select" :class="{'select&#45;&#45;selected': filters.priority}">-->
          <!--              <select class="select__control" v-model="filters.priority">-->
          <!--                <option :value="null">Приоритет</option>-->
          <!--                <option v-for="(item, index) in new Array(10)"-->
          <!--                        :value="index + 1"-->
          <!--                        :key="index">{{index + 1}}-->
          <!--                </option>-->
          <!--              </select>-->
          <!--              <div class="select__button">-->
          <!--                <svg class="select__caret">-->
          <!--                  <use xmlns:xlink="http://www.w3.org/1999/xlink"-->
          <!--                       xlink:href="/static/images/icon.svg#icon_icon-caret"></use>-->
          <!--                </svg>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div>
          <span class="task-form-filter__field-title">Тэги:</span>

          <div class="task-form-filter__field">
            <input
              type="text"
              class="input"
              placeholder="Поиск тега"
              @click="addTagBubbleOpen = true"
            />
            <transition name="fade">
              <TagsListSearch
                style="position: absolute; left: 0; top: 0"
                v-if="addTagBubbleOpen"
                @chooseTag="chooseTag"
                :className="'task-bubble--tag'"
                :taskTags="[]"
                :outsideTags="filteredTags"
                @close="addTagBubbleOpen = false"
              />
            </transition>
          </div>

          <div class="task-form-filter__labels">
            <span
              class="task-label"
              v-for="(item, index) in filters.tags"
              :key="index"
              :style="{ 'border-color': item.Color, color: item.Color }"
            >
              {{ item.TagName }}
              <div
                class="task-table-person__delete"
                @click="deleteTag(item.Id)"
              >
                <svg focusable="false" viewBox="0 0 32 32">
                  <path
                    d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                  ></path>
                </svg>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div
        class="task-form-filter__bottom"
        v-if="getUserFilterIfCurrentFilterIs && !fromSidebar"
      >
        <div class="task-form-filter__bottom-input">
          <input
            type="text"
            class="input"
            v-model="userFilter.FilterName"
            placeholder="Введите название фильтра и выберите цвет"
          />
          <div class="task-form-filter__bottom-color-control">
            <span
              class="task-form-filter__bottom-color"
              :style="{ background: userFilter.FilterColor }"
              @click="showColorSwitch = true"
            ></span>
            <transition name="fade">
              <sketch
                v-if="showColorSwitch"
                :value="userFilter.FilterColor"
                @input="setColor(userFilter, $event)"
                v-click-outside="closeColorSwitch"
              />
            </transition>
          </div>
        </div>
        <div class="task-form-filter__bottom-btn" @click="editFilter">
          Обновить
        </div>
        <div
          class="task-form-filter__bottom-btn-gray ml-auto"
          @click="deleteUserFilter"
        >
          Удалить фильтр
        </div>
      </div>
      <div class="task-form-filter__bottom" v-else>
        <template v-if="filters.state === 1">
          <div class="task-form-filter__bottom-btn" @click="makeFlashFilter">
            Применить
          </div>
          <div
            class="task-form-filter__bottom-btn-gray"
            @click="resetFlashFilter"
            v-if="selectedFilter === -1"
          >
            Сбросить
          </div>
          <div
            class="task-form-filter__bottom-btn-gray ml-auto"
            @click="filters.state = 2"
          >
            Сохранить фильтр
          </div>
        </template>
        <template v-else-if="filters.state === 2">
          <div class="task-form-filter__bottom-input">
            <input
              type="text"
              class="input"
              v-model="userFilter.FilterName"
              placeholder="Введите название фильтра и выберите цвет"
            />
            <div class="task-form-filter__bottom-color-control">
              <span
                class="task-form-filter__bottom-color"
                :style="{ background: userFilter.FilterColor }"
                @click="showColorSwitch = true"
              ></span>
              <transition name="fade">
                <sketch
                  v-if="showColorSwitch"
                  :value="userFilter.FilterColor"
                  @input="setColor(userFilter, $event)"
                  v-click-outside="closeColorSwitch"
                />
              </transition>
            </div>
          </div>
          <div class="task-form-filter__bottom-btn" @click="createUserFilter">
            Сохранить
          </div>
          <div
            class="task-form-filter__bottom-btn-gray"
            @click="filters.state = 1"
          >
            Отмена
          </div>
        </template>
      </div>
      <div class="task-form-filter__triangle">
        <svg width="12" height="33">
          <use
            xlink:href="/static/images/tasks/sprite.svg#bubble-triangle"
          ></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as c from "../../../../store/tasks/constants";
import common from "../../../../mixins/tasks/common";
import * as _ from "lodash";
import TagsListSearch from "../CommonComponents/TagsListSearch/TagsListSearch";
import moment from "moment";
import { Sketch } from "vue-color";
import { getRandomColor } from "../../../../utils";
import { Helpers } from "../../../../mixins/Helpers";
import { getStandartFilterNameInfo } from "../../../../mixins/tasks/func";
import { sortsTemplate } from "@/modules/tasks/components/utils";
import GroupedListSearch from "@/modules/tasks/components/CommonComponents/GroupedListSearch/GroupedListSearch";
import SingleListSearch from "@/modules/tasks/components/CommonComponents/SingleListSearch/SingleListSearch";
import {
  GET_CUSTOM_FILTER_PARAMS,
  LOAD_FULL_INFO_OF_GROUP,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_SORTS,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_CACHED,
  SET_TABLE_FILTER_FLASH_DATA,
} from "../../../../store/tasks/constants";
import Api from "@/api/tasks";

const initialFiltersState = {
  dateOption: null,
  dateInterval: null,
  responsibleUsers: [],
  taskProperty: null,
  taskStatus: [],
  taskProcess: null,
  taskFiles: null,
  priority: null,
  groupIds: [],
  tags: [],
  state: 1,
};

export default {
  mixins: [common, Helpers],
  props: ["className", "fromSidebar"],
  data() {
    return {
      userFilter: {
        FilterName: "",
        FilterColor: getRandomColor(),
      },
      filters: JSON.parse(JSON.stringify(initialFiltersState)),
      searchTagString: "",
      addTagBubbleOpen: false,
      showColorSwitch: false,
      statusesBubbleOpen: false,
      processesBubbleOpen: false,
      usersBubbleOpen: false,
      groupsBubbleOpen: false,
      loader: true,
      allUsers: [],
    };
  },
  created() {
    this[GET_CUSTOM_FILTER_PARAMS]().finally(() => (this.loader = false));
    if (this.fromSidebar) {
      this.filters.state = 2;
    } else {
      this.prepareFilters();
    }
    if (this.fromSidebar) {
      Api.getUsers().then((res) => {
        this.allUsers = res.data.users;
      });
    }
    this.filters.groupIds.forEach((id) => this[LOAD_FULL_INFO_OF_GROUP](id));
  },
  computed: {
    filterParams() {
      let params = {};

      if (this.filters.dateOption) {
        let interval = "";
        if (this.filters.dateInterval) {
          interval = `${moment(this.filters.dateInterval[0]).format(
            "DD.MM.YYYY"
          )} - ${moment(this.filters.dateInterval[1]).format("DD.MM.YYYY")}`;
        }
        const date = this.filters.dateOption === "5" ? interval : null;
        params["1"] = { [this.filters.dateOption]: date };
      }
      if (
        this.filters.responsibleUsers &&
        this.filters.responsibleUsers.length
      ) {
        params["2"] = this.filters.responsibleUsers;
      }
      if (this.filters.groupIds && this.filters.groupIds.length) {
        params["3"] = this.filters.groupIds;
      }
      if (this.filters.taskProperty) {
        params["4"] = [this.filters.taskProperty];
      }
      if (this.filters.taskFiles) {
        params["6"] = [this.filters.taskFiles];
      }
      if (this.filters.tags && this.filters.tags.length) {
        params["7"] = [...this.filters.tags.map((i) => i.Id)];
      }
      if (this.filters.priority) {
        params["8"] = [this.filters.priority];
      }
      if (this.filters.taskStatus && this.filters.taskStatus.length) {
        params["9"] = this.filters.taskStatus;
      }
      if (this.filters.taskProcess) {
        params["10"] = this.filters.taskProcess;
      }

      return params;
    },
    filteredUsers() {
      const arr = [];
      if (this.fromSidebar) {
        return _.orderBy(this.allUsers, ["FullName"], ["asc"]);
      }
      if (this.filters.groupIds.length) {
        const selectedGroups = this.allGroups.filter(
          (i) => this.filters.groupIds.indexOf(i.Id) !== -1
        );
        selectedGroups.forEach((i) => {
          if (i.Users) {
            i.Users.forEach((u) => {
              if (!arr.find((a) => a.UserId === u.UserId))
                arr.push({
                  ...u,
                  ...u.User,
                  FullName: u.User.Fio,
                  Id: u.UserId,
                });
            });
          }
        });
        return _.orderBy(arr, ["FullName"], ["asc"]);
      } else return this.users;
    },
    filterInGroup() {
      if (!this.selectedFilter && this.group) {
        return true;
      }
      return false;
    },
    statuses() {
      if (
        !this.fromSidebar &&
        this.group &&
        (!this.selectedFilter || this.selectedFilter < 0)
      ) {
        const arr = [];
        _.each(this.groupProcesses, (i) => {
          arr.push(...i.statuses);
        });
        return arr;
      } else {
        const arr = this.customFilterParams
          ? this.customFilterParams[7].params
          : [];
        return arr.map((i) => {
          i.Id = i.id;
          i.Name = i.name;
          return i;
        });
      }
    },
    processesFromFilterApi() {
      return this.customFilterParams ? this.customFilterParams[8].params : [];
    },
    taskProperties() {
      return this.customFilterParams ? this.customFilterParams[3].params : [];
    },
    taskFiles() {
      return this.customFilterParams ? this.customFilterParams[6].params : [];
    },
    filteredProcesses() {
      return this.processes;
    },
    filteredTags() {
      return _.filter(this.tagGroups, (i) => {
        if (this.filters.groupIds.length) {
          let can = false;
          const selectedGroups = this.allGroups.filter(
            (i) => this.filters.groupIds.indexOf(i.Id) !== -1
          );
          selectedGroups.forEach((g) => {
            if (_.find(g.TagsGroups, ["TagGroupId", i.Id])) {
              return (can = true);
            }
          });
          return can;
        }
        return true;
        // if (_.find(this.group.TagsGroups, ["TagGroupId", i.Id])) {
        //   arr.push(i);
        // }
      });
    },
    ...mapState("tasks", [
      "standartFilters",
      "userFilters",
      "users",
      "tags",
      "groups",
      "customFilterParams",
      "groupProcesses",
      "allUserFilters",
      "allGroups",
      "processes",
      "tagGroups",
      "userData",
    ]),
    ...mapGetters("tasks", [
      "group",
      "selectedFilter",
      "getUserFilterIfCurrentFilterIs",
      "countForFlashFilterCheck",
      "cachedFilter",
      "selectedFilterName",
      "selectedFilterIsUsersFilter",
    ]),
  },
  methods: {
    getTasksByUserFilter(id) {
      this.resetFilters();
      if (this.selectedFilter === id) {
        this[SET_TABLE_FILTER](null);
        this[c.GET_TASKS]();
      } else {
        this.prepareFilters();
        this[c.GET_TASKS_BY_USER_FILTER]({ id, reset: true });
      }
      // setTimeout(() => {
      //   this.$emit('closeFilterWindow')
      // }, 300)
    },
    createUserFilter() {
      if (Object.keys(this.filterParams).length && this.userFilter.FilterName) {
        this[c.CREATE_USER_FILTER]({
          ...this.userFilter,
          FilterParams: this.filterParams,
        }).then((res) => {
          this.getTasksByUserFilter(res.Id);
        });
      }
    },
    setDateFilter() {
      if (this.filters.dateOption !== "5") {
        this.filters.dateInterval = null;
      }
    },
    chooseTag(tag) {
      if (!_.find(this.filters.tags, ["Id", tag.Id])) {
        this.filters.tags.push(tag);
      }
    },
    deleteTag(Id) {
      this.filters.tags = _.reject(this.filters.tags, { Id });
    },
    deleteUserFilter() {
      this[c.DELETE_USER_FILTER](this.selectedFilter).then(() => {
        this.resetFilters();
        this[c.GET_TASKS]();
      });
    },
    resetFilters() {
      this.userFilter = {
        FilterName: "",
        FilterColor: getRandomColor(),
      };
      this.filters = JSON.parse(JSON.stringify(initialFiltersState));
    },
    editFilter() {
      this[c.EDIT_USER_FILTER]({
        ...this.userFilter,
        FilterParams: this.filterParams,
        Id: this.selectedFilter,
      }).then(() => {
        this[c.GET_TASKS_BY_USER_FILTER]({ id: this.selectedFilter });
        this[SET_COMMON_MODULE_PAGE](0);
        this[SET_COMMON_MODULE_SORTS](
          JSON.parse(JSON.stringify(sortsTemplate))
        );
        this.$emit("closeFilterWindow");
      });
    },
    setColor(item, event) {
      item.FilterColor = event.hex;
    },
    closeColorSwitch() {
      this.showColorSwitch = false;
    },
    prepareFilters() {
      if (this.countForFlashFilterCheck && this.cachedFilter) {
        this.userFilter.FilterName =
          this.selectedFilterName + " + " + "Свой фильтр";
        this.filters = this.cachedFilter;
      } else {
        if (this.selectedFilterIsUsersFilter) {
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["1"]) {
            this.filters.dateOption = Object.keys(
              this.getUserFilterIfCurrentFilterIs.FilterParams["1"]
            )[0].toString();
            if (
              Object.keys(
                this.getUserFilterIfCurrentFilterIs.FilterParams["1"]
              )[0] === "5"
            ) {
              this.filters.dateInterval = [];
              const interval = this.getUserFilterIfCurrentFilterIs.FilterParams[
                "1"
              ][5].split("-");
              this.filters.dateInterval[0] = moment(
                interval[0].trim(),
                "DD.MM.YYYY"
              ).toDate();
              this.filters.dateInterval[1] = moment(
                interval[1].trim(),
                "DD.MM.YYYY"
              ).toDate();
            }
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["2"]) {
            this.filters.responsibleUsers = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "2"
            ];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["3"]) {
            this.filters.groupIds = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "3"
            ];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["4"]) {
            this.filters.taskProperty = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "4"
            ][0];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["6"]) {
            this.filters.taskFiles = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "6"
            ][0];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["7"]) {
            this.filters.tags = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "7"
            ].map((i) => _.find(this.tags, ["Id", i]));
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["8"]) {
            this.filters.priority = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "8"
            ][0];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["9"]) {
            this.filters.taskStatus = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "9"
            ];
          }
          if (this.getUserFilterIfCurrentFilterIs.FilterParams["10"]) {
            this.filters.taskProcess = this.getUserFilterIfCurrentFilterIs.FilterParams[
              "10"
            ];
          }

          this.userFilter = {
            FilterName: this.getUserFilterIfCurrentFilterIs.FilterName,
            FilterColor: this.getUserFilterIfCurrentFilterIs.FilterColor
              ? this.getUserFilterIfCurrentFilterIs.FilterColor
              : getRandomColor(),
          };
        } else if (
          typeof this.selectedFilter === "number" &&
          this.selectedFilter > 0
        ) {
          switch (this.selectedFilter) {
            case 1: {
              this.filters.taskProperty = 1;
              break;
            }
            case 2: {
              this.filters.responsibleUsers = [this.userData.Id];
              break;
            }
          }
        } else {
          if (
            (!this.selectedFilter || this.selectedFilter === -1) &&
            this.group
          ) {
            if (this.cachedFilter) {
              this.filters = this.cachedFilter;
            }
            this.filters.groupIds = [this.group.Id];
          }
        }
      }
    },
    getStandartFilterNameInfo(index) {
      return getStandartFilterNameInfo(index);
    },
    checkFilterBelongGroup(params) {
      if (params[3] !== void 0) {
        if (!params[3].includes(this.group.Id)) {
          return false;
        }
      }
      return true;
    },
    getGroupName(id) {
      const foundGroup = _.find(this.allGroups, ["Id", id]);
      if (foundGroup) return foundGroup.Name;
    },
    makeFlashFilter() {
      if (Object.keys(this.filterParams)) {
        this[c.GET_TASK_BY_FLASH_FILTER]({
          data: { draftFilter: this.filterParams },
        });
        this[SET_TABLE_FILTER_FLASH_DATA](this.filterParams);
      }
      this[SET_TABLE_FILTER](-1);
      this[SET_TABLE_FILTER_CACHED](JSON.parse(JSON.stringify(this.filters)));

      if (this.countForFlashFilterCheck)
        this.userFilter.FilterName =
          this.selectedFilterName + " + " + "Свой фильтр";
    },
    resetFlashFilter() {
      this[SET_TABLE_FILTER](null);
      this.resetFilters();
      this[c.GET_TASKS]();
    },

    getMultiselectOptionName(Id, array, fieldname) {
      const found = _.find(array, { Id });
      if (found) return found[fieldname];
      return "...";
    },
    chooseStatuses(item) {
      if (!this.filters.taskStatus[item.Id]) {
        this.filters.taskStatus.push(item.Id);
      } else {
        this.filters.taskStatus.splice(
          this.filters.taskStatus.indexOf(item.Id),
          1
        );
      }
    },
    chooseUsers(item) {
      if (this.filters.responsibleUsers.indexOf(item.Id) === -1) {
        this.filters.responsibleUsers.push(item.Id);
      } else {
        this.filters.responsibleUsers.splice(
          this.filters.responsibleUsers.indexOf(item.Id),
          1
        );
      }
    },
    chooseGroups(item) {
      this[LOAD_FULL_INFO_OF_GROUP](item.Id);
      if (this.filters.groupIds.indexOf(item.Id) === -1) {
        this.filters.groupIds.push(item.Id);
      } else {
        this.filters.groupIds.splice(this.filters.groupIds.indexOf(item.Id), 1);
      }
    },
    ...mapActions("tasks", [
      c.GET_TASKS_BY_STANDART_FILTER,
      c.GET_TASKS,
      c.CREATE_USER_FILTER,
      c.EDIT_USER_FILTER,
      c.DELETE_USER_FILTER,
      c.GET_TASKS_BY_USER_FILTER,
      c.GET_TASK_BY_FLASH_FILTER,
      GET_CUSTOM_FILTER_PARAMS,
      LOAD_FULL_INFO_OF_GROUP,
    ]),
    ...mapMutations("tasks", [
      SET_TABLE_FILTER_FLASH_DATA,
      SET_TABLE_FILTER_CACHED,
      SET_COMMON_MODULE_SORTS,
      SET_COMMON_MODULE_PAGE,
      SET_TABLE_FILTER,
    ]),
  },
  components: {
    TagsListSearch,
    Sketch,
    GroupedListSearch,
    SingleListSearch,
  },
};
</script>

<style scoped></style>
