<template>
  <div class="tm-sidebar-filters">
    <div class="sidebar-draggable-zone">
      <div class="task-form-filter__title">Избранное</div>
      <!--      v-model="sidebarData.preparedFavoritesArray"-->
      <div
        v-for="item in standartFilters"
        :key="item.index"
        class="task-form-filter__item"
        :class="{
          'task-form-filter__item--active': selectedFilter === item.index,
        }"
        @click="getTasksByStandartFilter(item.index)"
        :data-item-type="2"
        :data-item-id="item.index"
      >
        <div class="task-form-filter__item-row">
          <img
            :src="
              '/static/images/tasks/icons/' +
              getStandartFilterNameInfo(item.index)[1]
            "
            alt="icon"
            class="task-form-filter__item-icon"
          />
          <p>{{ getStandartFilterNameInfo(item.index)[0] }}</p>
          <div class="task-form-filter__item-count">{{ item.TaskCount }}</div>
        </div>
      </div>
      <draggable
        group="favorites"
        class="task-form-filter__list task-form-filter__list--favorites"
        :list="favorites"
        @add="addToFavorite"
        :move="checkMove"
        @end="changeFavoritesOrder(favorites)"
      >
        <template v-for="item in favorites">
          <SidebarGroupsItem
            class="task-form-filter__item draggable"
            v-if="item.ItemInfo"
            :class="{
              'task-form-filter__item--active':
                selectedFilter === item.ItemInfo.Id ||
                isActive(item.ItemInfo.Id),
              'task-form-filter__item--selected': checkItemFavoriteSelect(item),
            }"
            :key="item.Id"
            :data-item-type="
              item.ItemInfo && item.ItemInfo.FilterParams !== void 0 ? 2 : 1
            "
            :data-item-id="item.ItemInfo ? item.ItemInfo.Id : item.Id"
            :item="item"
            @getTasksByUserFilter="getTasksByUserFilter"
            :headerControlElement="headerControlElement"
            :outerList="favorites"
            :outerListIsFavorites="true"
            @selectItem="
              (item) =>
                $emit('selectItem', item, 'selectedGroupsAndFiltersArray')
            "
          />
        </template>
      </draggable>
    </div>
    <slot />

    <div class="sidebar-draggable-zone">
      <div class="task-form-filter__title">
        Мои фильтры
        <div
          class="tm-sidebar-control"
          :class="{ 'tm-sidebar-control--show-icons': showCreateFilterPopup }"
        >
          <div
            class="tm-sidebar-control__btn tm-sidebar-control__btn--add"
            @click="openFilter"
          >
            <svg width="24" height="16" v-if="!showCreateFilterPopup">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#add-dashed-icon"
              ></use>
            </svg>
            <svg width="24" height="16" v-if="showCreateFilterPopup">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#add-dashed-icon-close"
              ></use>
            </svg>
          </div>
          <portal to="common">
            <PortalComponent
              v-if="showCreateFilterPopup"
              :clickCoordinates="clickCoordinates"
              :arrowElement="'task-form-filter__triangle'"
              offset="-5, 20"
              @close="closeFilter"
            >
              <TasksFilter
                :className="'sidebar-filter-create'"
                :fromSidebar="true"
              />
            </PortalComponent>
          </portal>
          <!--          <div class="tm-sidebar-control__btn">-->
          <!--            <svg width="17" height="17">-->
          <!--              <use xmlns:xlink="http://www.w3.org/1999/xlink"-->
          <!--                   xlink:href="/static/images/tasks/sprite.svg#edit-icon"></use>-->
          <!--            </svg>-->
          <!--          </div>-->
        </div>
      </div>
      <draggable
        class="task-form-filter__list"
        :group="{ name: 'favorites', put: false }"
        :list="userFilters"
        :move="checkMove"
        @end="changeOrder(userFilters)"
      >
        <SidebarGroupsItem
          v-for="item in userFilters"
          class="task-form-filter__item draggable"
          :class="{
            'task-form-filter__item--active': selectedFilter === item.FilterId,
            'task-form-filter__item--selected': checkItemSelect(item),
          }"
          :key="item.Id"
          :data-item-type="2"
          :data-item-id="item.ItemInfo ? item.ItemInfo.Id : item.FilterId"
          :data-global-group="item.GlobalGroupName"
          :item="item"
          :isFilter="item.FilterName !== void 0"
          :outerList="userFilters"
          :headerControlElement="headerControlElement"
          @selectItem="
            (item) => $emit('selectItem', item, 'selectedFiltersArray')
          "
          @getTasksByUserFilter="getTasksByUserFilter"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as c from "../../../../store/tasks/constants";
import * as _ from "lodash";
import draggable from "vuedraggable";
import TasksFilter from "../Header/TasksFilter";
import PortalComponent from "../CommonComponents/PortalComponent";
import {
  SET_COMMON_LOCAL_STORAGE_SETTINGS,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY,
  SET_COMMON_MODULE_SORTS,
  SET_COMMON_MODULE_VIEW_TYPE,
  SET_SEARCH_TASKS_INPUT_VALUE,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_CACHED,
  SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK,
  SET_TABLE_FILTER_NAME,
} from "../../../../store/tasks/constants";

import SidebarGroupsItem from "@/modules/tasks/components/Sidebar/SidebarGroupsItem";
import sidebarMixins from "@/modules/tasks/components/Sidebar/sidebarMixins";
import { getStandartFilterNameInfo } from "@/mixins/tasks/func";

const initialFiltersState = {
  dateOption: null,
  dateInterval: null,
  responsibleUser: null,
  taskProperty: null,
  priority: null,
  tags: [],
  state: 1,
};
export default {
  name: "SidebarFilters",
  mixins: [common, Helpers, sidebarMixins],
  props: [
    "sidebarData",
    "headerControlElement",
    "selectedArray",
    "selectedGroupsAndFiltersArray",
  ],
  data() {
    return {
      filters: JSON.parse(JSON.stringify(initialFiltersState)),
      showCreateFilterPopup: false,
      filterBottomPosition: 0,
      clickCoordinates: null,
    };
  },
  created() {},
  computed: {
    ...mapState("tasks", [
      "standartFilters",
      "userFilters",
      "favorites",
      "settings",
      "globalGroups",
      "allUserFilters",
      "allGroups",
      "allFavorites",
    ]),
    ...mapGetters("tasks", [
      "group",
      "selectedFilter",
      "selectedFilterIsUsersFilter",
    ]),
  },
  methods: {
    getTasksByStandartFilter(type) {
      this[SET_SEARCH_TASKS_INPUT_VALUE]("");
      this[c.GET_TASKS_BY_STANDART_FILTER]({ type, reset: true });
      this.headerControlElement().unselectTasks();
    },
    getStandartFilterNameInfo(index) {
      return getStandartFilterNameInfo(index);
    },
    checkFilterBelongGroup(params) {
      if (params[3] !== void 0) {
        if (!params[3].includes(this.group.Id)) {
          return false;
        }
      }
      return true;
    },
    getTasksByUserFilter(id, filter) {
      this[SET_SEARCH_TASKS_INPUT_VALUE]("");
      this[c.GET_TASKS_BY_USER_FILTER]({
        id,
        reset: true,
        filter,
      });
      this.headerControlElement().unselectTasks();
    },
    getGroupName(params) {
      const foundGroup = _.find(this.allGroups, ["Id", params[3][0]]);
      if (foundGroup) return foundGroup.Name;
    },
    addToFavorite(e) {
      if (e.item.getAttribute("data-global-group")) {
        this[c.UPDATE_GLOBAL_GROUP]({
          Id: e.item.getAttribute("data-item-id"),
          GroupType: 3,
        }).then(() => {
          this[c.ADD_TO_FAVORITES]({
            ItemType: 4,
            ItemId: e.item.getAttribute("data-item-id"),
          });
        });
      } else {
        this[c.ADD_TO_FAVORITES]({
          ItemType: e.item.getAttribute("data-item-type"),
          ItemId: e.item.getAttribute("data-item-id"),
        });
      }
    },
    changeFavoritesOrder(items) {
      this[c.CHANGE_FAVORITES_ORDER](items);
    },
    changeOrder(items) {
      // const groups = [];
      // const globalGroups = [];
      const arr = [];
      items.forEach((i) => {
        if (!i.GlobalGroupName) {
          // groups.push(i);
          arr.push({ FilterType: 1, FilterId: i.FilterId });
        } else {
          arr.push({ FilterType: 2, FilterId: i.FilterId });
          // globalGroups.push(i);
        }
      });

      const formData = new FormData();
      _.each(arr, (i, index) => {
        formData.append(`Ids[${index}][FilterId]`, i.FilterId);
        formData.append(`Ids[${index}][FilterType]`, i.FilterType);
      });
      this[c.CHANGE_GLOBAL_GROUP_FILTERS_ORDER](formData);
    },
    openFilter(e) {
      this.clickCoordinates = e;
      this.showCreateFilterPopup = !this.showCreateFilterPopup;
    },
    closeFilter() {
      this.showCreateFilterPopup = false;
    },
    ...mapActions("tasks", [
      c.GET_TASKS,
      c.GET_TASKS_BY_STANDART_FILTER,
      c.GET_TASKS_BY_USER_FILTER,
      c.ADD_TO_FAVORITES,
      c.CHANGE_FAVORITES_ORDER,
      c.CHANGE_FAVORITES_ORDER,
      c.CHANGE_ORDER,
      c.CHANGE_GLOBAL_GROUP_FILTERS_ORDER,
      c.UPDATE_GLOBAL_GROUP,
    ]),
    checkItemSelect(item) {
      return !!this.selectedArray.find((i) => {
        return i.Id === item.Id;
      });
    },
    checkItemFavoriteSelect(item) {
      return !!this.selectedGroupsAndFiltersArray.find((i) => {
        return i.Id === item.Id;
      });
    },
    checkMove: function (evt) {
      if (evt.draggedContext.element.GlobalGroupName) {
        return !evt.to.classList.contains("task-form-filter__item-childs");
      }
      return true;
    },
    ...mapMutations("tasks", [
      c.GET_USER_FAVORITES,
      SET_COMMON_LOCAL_STORAGE_SETTINGS,
      SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK,
      SET_TABLE_FILTER,
      SET_TABLE_FILTER_NAME,
      SET_TABLE_FILTER_CACHED,
      SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY,
      SET_COMMON_MODULE_SORTS,
      SET_COMMON_MODULE_PAGE,
      SET_COMMON_MODULE_VIEW_TYPE,
      SET_SEARCH_TASKS_INPUT_VALUE,
    ]),
  },
  watch: {},
  components: {
    draggable,
    TasksFilter,
    PortalComponent,
    SidebarGroupsItem,
  },
};
</script>

<style scoped></style>
