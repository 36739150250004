<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название слайда:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SlideTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SlideTitle'"
            :OriginalValue="fields.SlideTitle"
            ref="translated-field-SlideTitle"
            :inputType="'input'"
            :module="'SiteSliders'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Ссылка:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.Link" />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слайд:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.Image">
            <img :src="getImgSrc(fields.Image)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Видео:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.Video" />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";

export default {
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
