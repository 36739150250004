<template>
  <div class="header cm-header">
    <div
      class="header__col cm-search"
      v-show="!selectedItems.length"
      @mouseleave="isDropdownOpen = false"
    >
      <span
        class="button cm__add-btn button_green button_uppercase settings-devices-zone__add"
        @click="isDropdownOpen = true"
        >Создать</span
      >
      <div
        class="dropdown add-client-dropdown dropdown_active"
        v-show="isDropdownOpen"
      >
        <ul class="icon-menu">
          <li class="icon-menu__item">
            <a class="icon-menu__link" @click="createCompany"
              ><span class="icon-menu__icon-wrap"
                ><svg class="icon-menu__icon">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-company"
                  ></use></svg
              ></span>
              <span class="icon-menu__text">Компанию</span></a
            >
          </li>
          <li class="icon-menu__item">
            <a class="icon-menu__link" @click="createObject"
              ><span class="icon-menu__icon-wrap"
                ><svg class="icon-menu__icon">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-person"
                  ></use></svg
              ></span>
              <span class="icon-menu__text">Объект</span></a
            >
          </li>
          <li class="icon-menu__item">
            <a class="icon-menu__link" @click="createDistb"
              ><span class="icon-menu__icon-wrap"
                ><svg class="icon-menu__icon">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-person"
                  ></use></svg
              ></span>
              <span class="icon-menu__text">Дистрибьютора</span></a
            >
          </li>
        </ul>
      </div>
      <div class="search-bar search-bar_white actions-header__search-bar">
        <button class="search-bar__button" type="button">
          <svg>
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-search"
            ></use>
          </svg>
        </button>
        <input
          class="search-bar__input"
          type="search"
          @keyup="setSearchInput($event.target.value)"
          :value="searchInput"
          placeholder="Наименование клиента, объекта"
        />
      </div>
      <div class="actions-header__col export-wrapper">
        <div class="icon-button icon-button_uppercase icon-button--export">
          <span class="icon-button__container">
            <svg class="icon-button__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-export"
              ></use>
            </svg>
            <span class="icon-button__text" @click="doExport()">Экспорт</span>
          </span>
        </div>
      </div>
    </div>
    <div class="header__col cm-group-actions" v-if="selectedItems.length">
      <div class="button-group">
        <div
          class="button button-close button_grey button_uppercase"
          @click="removeAllSelectedItems"
        >
          <img src="@/assets/images/close.svg" alt="" />
        </div>
        <div class="button button_grey button_uppercase">
          {{ selectedItems.length }}
        </div>
      </div>
      <div
        class="button button_grey button_uppercase"
        @click="changeObjectsPopupOpen = true"
      >
        Изменения данных объектов : {{ selectedItems.length }}
      </div>
      <!--<div class="button button_grey button_uppercase" >-->
      <!--ПРОДЛИТЬ ЛИЦЕНЗИЮ ?-->
      <!--</div>-->
    </div>
    <div
      class="dropdown-overlay dropdown-overlay_active"
      v-show="isDropdownOpen"
    ></div>
    <change-objects-popup
      :is_open="changeObjectsPopupOpen"
      @onCloseChangePopup="changeObjectsPopupOpen = false"
    />
  </div>
</template>

<script>
import * as c from "@/store/clients/constants";
import { mapActions, mapState } from "vuex";
import { API_ROOT } from "../../../../config";
import ChangeObjectsPopup from "../../../clients/components/ChangeObjectsPopup";

export default {
  data() {
    return {
      isDropdownOpen: false,
      localSearchInput: "",
      changeObjectsPopupOpen: false,
    };
  },
  computed: {
    ...mapState("clients", ["searchInput", "selectedItems"]),
  },
  methods: {
    ...mapActions("clients", [
      c.CREATE_COMPANY,
      c.SET_SEARCH_INPUT,
      c.CREATE_OBJECT,
      c.SET_SELECTED_ITEMS,
      c.CREATE_DISTB,
    ]),
    createCompany() {
      this.isDropdownOpen = false;
      this[c.CREATE_COMPANY]()
        .then((companyId) => {
          this.$router.push("/company/" + companyId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createDistb() {
      this.isDropdownOpen = false;
      this[c.CREATE_DISTB]()
        .then((distbId) => {
          this.$router.push("/distb/" + distbId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeAllSelectedItems() {
      this[c.SET_SELECTED_ITEMS]({ removeAll: true });
      document
        .querySelectorAll(".checkbox__input")
        .forEach((i) => (i.checked = false));
    },
    createObject() {
      this.isDropdownOpen = false;
      this[c.CREATE_OBJECT]()
        .then((objectId) => {
          this.$router.push("/object/" + objectId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSearchInput(value) {
      this[c.SET_SEARCH_INPUT](value);
    },
    doExport() {
      const url = `${API_ROOT}api/clients/Object/Export`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
  components: { ChangeObjectsPopup },
};
</script>
