import * as c from "../constants";
import * as _ from "lodash";
import Api from "../../../api/support/rps";
import * as moment from "moment";

export default {
  [c.SET_COUNT](state, data) {
    state.count = data;
  },
  [c.TOGGLE_COUNT_INTERVAL](state, data) {
    state.countInterval = data;
  },
  [c.SET_ACTIVE_TAB](state, data) {
    state.selectedRequest = false;
    state.messages = [];
    _.each(state.activeTabs, (value, tab) => {
      state.activeTabs[tab] = tab === data;
    });
    state.isDistsObjectList = ["open_dist_obj", "closed_dist_obj"].includes(
      data
    );
  },
  [c.GET_REQUESTS](state, data) {
    if (state.canRequestUpdate) {
      let scope = data.scope;
      if (data.isForDist) {
        scope =
          data.scope === "open"
            ? "customer_to_dist_open"
            : "customer_to_dist_closed";
      } else if (data.isFromDist) {
        scope =
          data.scope === "open" ? "dist_to_admin_open" : "dist_to_admin_closed";
      } else if (data.responsible) {
        scope = "responsible";
      } else if (data.without_responsible) {
        scope = "without_responsible";
      }
      const items = _.map(data.requests, (i) => {
        if (i.AnswerDeadline) {
          const requestDeadline = i.AnswerDeadline;
          const now = moment();
          const deadline = moment.unix(requestDeadline);
          const diff = deadline.diff(now, "minutes");
          i.DeadLineMinutes = diff;
        } else {
          i.DeadLineMinutes = null;
        }
        return i;
      });
      if (state.page) {
        state.requests[scope].push(...items);
      } else {
        state.requests[scope] = items;
      }
    }
  },
  [c.SET_SEARCH_INPUT](state, data) {
    state.searchInput = data;
  },
  [c.SET_SELECTED_REQUEST](state, data) {
    state.selectedRequest = data;
  },
  [c.SET_REQUEST_MESSAGES](state, data) {
    state.messages = data;
  },
  [c.TOGGLE_REQUESTS_LIST_LOAD_STATE](state, status) {
    state.canLoadRequestList = status;
  },
  [c.CHANGE_SORT](state, sort) {
    state.sort = sort;
  },
  [c.UPDATE_REQUEST_MESSAGES](state, message) {
    // console.log('UPDATE_REQUEST_MESSAGES', state.messages)
    state.messages.push(message);
    // console.log(state.messages)
  },
  [c.TOGGLE_PRELOADER](state, { preloader, status }) {
    state.preloaders[preloader] = status;
  },
  [c.GET_REQUEST_MESSAGES](state) {
    clearInterval(state.messagesInterval);
    const oldRequest = state.selectedRequest.Id;
    state.messagesInterval = setInterval(() => {
      if (state.selectedRequest.Id && state.countInterval) {
        if (state.canLoadRequest) {
          state.canLoadRequest = false;
          Api.getRequestMessages(state.selectedRequest.Id)
            .then((res) => {
              if (oldRequest === state.selectedRequest.Id) {
                if (
                  state.canMessageListUpdate &&
                  state.messages.length !== res.data.messages.length
                ) {
                  state.messages = res.data.messages;
                }
                if (state.canRequestUpdate) {
                  state.selectedRequest = res.data;
                  state.selectedRequest.DeadLineMinutes = state.selectedRequest
                    .AnswerDeadline
                    ? moment
                        .unix(state.selectedRequest.AnswerDeadline)
                        .diff(moment(), "minutes")
                    : null;
                }
              }
              state.canLoadRequest = true;
            })
            .catch((err) => {
              console.log("Ошибка принятия сообщений с сервера", err);
              state.canLoadRequest = true;
            });
        }
      } else {
        clearInterval(state.messagesInterval);
      }
    }, 2000);
  },
  [c.TOGGLE_MESSAGE_UPDATE_STATE](state, data) {
    state.canMessageListUpdate = data;
  },
  [c.TOGGLE_REQUEST_UPDATE_STATE](state, data) {
    state.canRequestUpdate = data;
  },
  [c.CHANGE_REQUEST_STATUS](state, { scope, id, status }) {
    _.each(state.requests[scope], (i) => {
      if (i.Id === id) {
        i.StatusId = String(status);
      }
    });
    state.selectedRequest.StatusId = String(status);
  },
  [c.SET_PROGRESS_BAR](state, data) {
    state.progressBar = data;
  },
  [c.GET_MANAGERS_PEOPLE_LIST](state, data) {
    state.personsForManage = data;
  },
  [c.DEFINE_CANCEL_REQUEST_FUNCTION](state, func) {
    state.commonSettings.cancelRequestExecuteFunction = func;
  },
  [c.RESET_CANCEL_REQUEST_FUNCTION](state) {
    state.commonSettings.cancelRequest = false;
  },
  [c.REMOVE_UNREAD_MARK](state, { scope, id }) {
    _.each(state.requests[scope], (i) => {
      if (i.Id === id) i.Unread = null;
    });
  },
  [c.SET_PAGE](state, value) {
    state.page = value;
  },
};
