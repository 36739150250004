<template>
  <div
    class="task-bubble__item"
    :class="{ 'task-bubble__item--selected': isSelected(item) }"
    @click="$emit('choose', item)"
  >
    <div
      class="task-table-person__circle"
      v-if="item[fieldNames.colorFieldName]"
      :style="{ background: item[fieldNames.colorFieldName] }"
    ></div>
    {{ item[fieldNames.fieldName] }}
  </div>
</template>

<script>
export default {
  name: "SingleListSearch",
  props: ["item", "search", "fieldNames", "selectedItemsArray"],
  methods: {
    isSelected(item) {
      return (
        this.selectedItemsArray &&
        this.selectedItemsArray.indexOf(item[this.fieldNames.idFieldName]) !==
          -1
      );
    },
  },
};
</script>

<style scoped></style>
