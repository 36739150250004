<template>
  <div class="task-create-form__row">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--6"></i>
    </div>
    <div class="task-create-form__row-right" v-if="!editState">
      <div class="task-create-form__label">Контакты</div>
      <div
        class="task-create-form-contact__wrapper"
        v-for="(item, index) in loadedTask.contacts"
        :key="index"
      >
        <div
          class="task-create-form__blue-title"
          @click="
            checkUserTaskRight('add_edit_task_contacts') && (editState = true)
          "
        >
          {{ getContactName(item) }} {{ item.ManualContactEmail }}
          <span
            class="task-popup-list__label label-button"
            :class="{ 'label-button--disabled': item.MainContact !== '1' }"
            @click="
              checkUserTaskRight('add_edit_task_contacts') &&
                setMainContact(item)
            "
            >Основной</span
          >
        </div>
      </div>
    </div>

    <div class="task-create-form__row-right" v-else>
      <div
        class="task-create-form__label"
        @click="editState = false"
        style="cursor: pointer"
      >
        Контакты
      </div>
      <div class="task-create-form-contact__wrapper">
        <div
          class="task-create-form-contact"
          v-for="(item, index) in loadedTask.contacts"
          :key="index"
        >
          <div class="task-create-form__blue-title">
            <span
              class="task-popup-list__label label-button"
              :class="{ 'label-button--disabled': item.MainContact !== '1' }"
              @click="setMainContact(item)"
              >Основной</span
            >
          </div>
          <div class="task-create-form__field">
            <input
              type="text"
              class="input"
              placeholder="ФИО"
              v-model="item.ManualContactFIO"
              @blur="editContact(item)"
            />
            <input
              type="text"
              class="input"
              placeholder="Должность"
              v-model="item.ManualContactPosition"
              @blur="editContact(item)"
            />
            <div class="task-create-form__btn" @click="deleteContact(item.Id)">
              <i class="task-form-icon task-form-icon--13"></i>
            </div>
          </div>
          <div class="task-create-form__field">
            <input
              type="text"
              class="input"
              placeholder="Телефон"
              v-mask="'+7 ### ### ## ##'"
              v-model="item.ManualContactPhone"
              @blur="editContact(item)"
            />
            <input
              type="text"
              class="input"
              placeholder="Email"
              v-model="item.ManualContactEmail"
              @blur="editContact(item)"
            />
            <div
              class="task-create-form__btn task-create-form__btn--hidden"
            ></div>
          </div>
          <!--TODO решить с комментарием-->
          <!--<div class="task-create-form__field">-->
          <!--<input type="text" class="input" placeholder="Комментарий">-->
          <!--<div class="task-create-form__btn task-create-form__btn&#45;&#45;hidden">-->
          <!--</div>-->
          <!--</div>-->
        </div>
      </div>
      <div class="task-create-form__field-desc">
        <!--<span class="blue-color">Добавить из объекта</span>-->
        <!--или-->
        <span
          class="blue-color"
          @click="addContact"
          v-if="checkUserTaskRight('add_edit_task_contacts')"
          >Добавить новый</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { mapActions } from "vuex";
import * as c from "../../../../../store/tasks/constants";
import { emptyContact } from "../../TaskCreateForm/templateData";
import * as _ from "lodash";

export default {
  mixins: [common],
  props: ["parentData", "loadedTask"],
  data() {
    return {
      editState: false,
    };
  },
  created() {
    if (
      !this.loadedTask.contacts.length &&
      this.checkUserTaskRight("add_edit_task_contacts")
    ) {
      this.editState = true;
    }
  },
  computed: {},
  methods: {
    editContact(item) {
      this[c.EDIT_CONTACT_QUIET]({ id: item.Id, data: item });
    },
    addContact() {
      this[c.CREATE_CONTACT]({
        TaskId: this.loadedTask.entityModel.Id,
        ...emptyContact,
      });
    },
    deleteContact(id) {
      this[c.DELETE_CONTACT](id).then(() => {
        if (!this.loadedTask.contacts.length) {
          this.parentData.showContactsField = false;
        }
      });
    },
    getContactName(item) {
      if (!item.ManualContactFIO) return "Не указано имя";
      let name = item.ManualContactFIO;
      if (item.ManualContactPhone) {
        name += ", " + item.ManualContactPhone;
      }
      if (item.ManualContactPosition) {
        name += ", " + item.ManualContactPosition;
      }
      return name;
    },
    setMainContact(item) {
      item.MainContact = item.MainContact === "1" ? "0" : "1";
      _.map(this.loadedTask.contacts, (i) => {
        if (item.Id !== i.Id) i.MainContact = "0";
        this.editContact(i);
        return i;
      });
    },
    ...mapActions("tasks", [
      c.DELETE_CONTACT,
      c.EDIT_CONTACT_QUIET,
      c.CREATE_CONTACT,
    ]),
  },
};
</script>

<style scoped>
.task-create-form__blue-title {
  padding-left: 0;
}
</style>
