<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_15 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_15 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('ServiceDesc')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div class="new-changes-block">
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>{{ offerObject.json.Service.desc }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <p>{{ compareObject.Service.desc }}</p>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('ServiceDesc'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__center pdf-settings__cont--pass-page"
      >
        <div class="pdf-settings__info-text">
          <div class="pdf-settings__info-text">
            Данные для этой страницы <br />
            редактировать нельзя.
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <label class="p-settings__total">
          <div class="p-settings__check">
            <div
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                @click.prevent="
                  offerObject.json.passPages.service = !offerObject.json
                    .passPages.service
                "
                :class="
                  offerObject.json.passPages.service
                    ? 'checkbox__box--active'
                    : ''
                "
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </div>
          </div>
          <div class="col-auto">
            Не добавлять страницы Сервисное обслуживание
          </div>
        </label>
        <div class="pdf-settings__next pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(14)">
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(16)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_15 from "../prints/print_15";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  components: { Print_15 },
};
</script>
