<template>
  <div class="popup event-popup" :class="is_open ? 'popup--active' : ''">
    <div
      class="popup__wrapper popup__wrapper_co-admin"
      @click="hidePopupByClickWrap($event)"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading popup__heading--offer">{{ offerName }}</h2>
          <a
            :href="commercialSiteUrl + offerObject.LinkToOffer + '.html'"
            class="btn-offer-link"
            v-if="
              offerObject.LinkToOffer && offerObject.OpenAccessByLink === '1'
            "
            target="_blank"
            >{{ commercialSiteUrl + offerObject.LinkToOffer }}.html</a
          >
          <button
            class="btn btn-create-offer-link"
            @click="deleteOfferLink()"
            v-if="
              checkUserRight(EDIT_OFFER_RIGHT) &&
              offerObject.OpenAccessByLink === '1'
            "
          >
            Удалить ссылку
          </button>
          <button
            class="btn btn-create-offer-link"
            @click="createOfferLink()"
            v-else-if="
              checkUserRight(EDIT_OFFER_RIGHT) &&
              (offerObject.OpenAccessByLink === '0' ||
                offerObject.OpenAccessByLink === null)
            "
          >
            Создать ссылку
          </button>
          <div class="popup__close" @click="onCloseSettings">
            <svg class="popup__close-icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-close"></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div
            class="popup__setting flex-wrap"
            v-if="!windowStates.is_edit_state"
          >
            <div
              class="col-auto"
              v-if="checkUserRight(CHANGE_OFFER_STATUS_RIGHT)"
            >
              <div class="popup__setting-name">
                <span>Менеджер: </span
                >{{ offerObject.json.ManagerName | short_name }}
              </div>
              <!--<div class="popup__status"><span>Статус:</span>-->
              <!--<div class="select-d">-->
              <!--<div class="select-d__inner">{{getStatus(offerObject.json.Status)}}-->
              <!--<div class="select-d__arrow">-->
              <!--<svg class="icon icon&#45;&#45;arrow-down">-->
              <!--<use xlink:href="/static/images/icon.svg#arrow-down"></use>-->
              <!--</svg>-->
              <!--</div>-->
              <!--</div>-->
              <!--<div class="select-d__dropdown">-->
              <!--<div class="select-d__dropdown-list">-->
              <!--<div class="select-d__dropdown-item"-->
              <!--@click="offerObject.json.Status=1"-->
              <!--:class="offerObject.json.Status === 1 ? 'select-d__dropdown-item&#45;&#45;active': ''">На проверку-->
              <!--</div>-->
              <!--<div class="select-d__dropdown-item"-->
              <!--@click="offerObject.json.Status=2"-->
              <!--:class="offerObject.json.Status === 2 ? 'select-d__dropdown-item&#45;&#45;active': ''">Одобрено-->
              <!--</div>-->
              <!--<div class="select-d__dropdown-item"-->
              <!--@click="offerObject.json.Status=3"-->
              <!--:class="offerObject.json.Status === 3 ? 'select-d__dropdown-item&#45;&#45;active': ''">Доработать-->
              <!--</div>-->
              <!--<div class="select-d__dropdown-item"-->
              <!--@click="offerObject.json.Status=4"-->
              <!--:class="offerObject.json.Status === 4 ? 'select-d__dropdown-item&#45;&#45;active': ''">В процессе-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
            </div>
            <div class="col-auto" v-else>
              <!--<div class="popup__setting-name"><span>Статус: </span>На проверку</div>-->
            </div>
            <div class="col-auto ml-auto">
              <button
                class="btn ml-12"
                @click="windowStates.is_edit_state = true"
                v-if="checkUserRight(EDIT_OFFER_RIGHT)"
              >
                РЕДАКТИРОВАТЬ
              </button>
              <button class="btn ml-12" @click="copyDialog = true">
                Дублировать
              </button>

              <!--<button class="btn ml-12"-->
              <!--v-if="checkUserRight(EDIT_OFFER_RIGHT)"-->
              <!--@click="removeOffer"-->
              <!--&gt;Удалить-->
              <!--</button>-->
              <button
                class="btn ml-12 btn-pdf-download"
                @click="downloadBtnsShow = true"
              >
                <svg class="icon icon--file-download">
                  <use
                    xlink:href="/static/images/icon.svg#file-download "
                  ></use>
                </svg>
                СКАЧАТЬ
              </button>
              <label class="btn ml-12"
                >Восстановить
                <input
                  type="file"
                  hidden
                  @change="restoreDump($event.target.files)"
                />
              </label>
            </div>
            <button
              class="btn btn__green w-160 ml-12"
              @click="saveOffer"
              v-if="
                checkUserRight(EDIT_OFFER_RIGHT) && windowStates.is_edit_state
              "
            >
              СОХРАНИТЬ
            </button>
            <div
              class="pdf-view"
              v-if="downloadBtnsShow"
              @mouseleave="downloadBtnsShow = false"
            >
              <button
                class="pdf-view__btn"
                @click="downloadPdf(true, 'downloadBtnsShow')"
              >
                Полную версию
              </button>
              <button
                class="pdf-view__btn"
                @click="downloadPdf(false, 'downloadBtnsShow')"
              >
                Уменьшенную версию
              </button>
              <download-excel
                class="pdf-view__btn"
                :data="offerDevicesOptionForExpost.json_data"
                :fields="offerDevicesOptionForExpost.json_fields"
                worksheet="Rps Worksheet"
                :name="offerName + '.xls'"
              >
                Таблицу xls
              </download-excel>
              <!--<button class="pdf-view__btn" @click="downloadDump">JSON дамп</button>-->
            </div>
          </div>

          <div class="popup__setting flex-wrap" v-else>
            <div class="col-auto">
              <button
                v-if="offerObject.json.SystemType && !print.changeWindow"
                class="btn btn__green w-160"
                @click="saveOffer"
              >
                СОХРАНИТЬ
              </button>
              <button
                v-if="print.changeWindow"
                class="btn btn__green"
                @click="createCopyOffer('compare_copy')"
              >
                Создать КП с новыми данными
              </button>
            </div>
            <div class="col-auto" v-if="offerObject.json.SystemType">
              <div class="table__text-block rate-text">
                Курс доллара:
                <input
                  type="number"
                  class="input"
                  :placeholder="rateValue"
                  :value="offerObject.json.rateValue"
                  @blur="changeRateValue($event)"
                />
              </div>
            </div>
            <div class="col-auto" v-if="offerObject.json.ManagerId">
              <div class="table__text-block cp-responsible-user">
                Ответственный:
                <div class="select">
                  <select
                    class="select__control"
                    v-model="offerObject.json.ManagerId"
                    @change="changeResponsibleUser"
                  >
                    <option
                      :value="item.Id"
                      v-for="item in allowedToModuleUsers"
                      :key="item.Id"
                    >
                      {{ item.FullName }}
                    </option>
                  </select>
                  <div class="select__button">
                    <svg class="select__caret">
                      <use
                        xlink:href="/static/images/icon.svg#icon_icon-caret"
                      ></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto ml-auto">
              <!--              <div-->
              <!--                class="have-new-changes"-->
              <!--                v-if="-->
              <!--                  offerObject.json.System == 'standart' &&-->
              <!--                  newTemplateChanges.length &&-->
              <!--                  !print.changeWindow-->
              <!--                "-->
              <!--              >-->
              <!--                Внимание! Были изменения в шаблоне.-->
              <!--                <span @click="showChangeLog">Посмотрите, что изменилось.</span>-->
              <!--              </div>-->
            </div>
            <div
              class="col-auto"
              :class="!newTemplateChanges.length ? 'ml-auto' : ''"
            >
              <button
                class="btn w-145"
                @click="
                  print.changeWindow
                    ? (print.changeWindow = false)
                    : (windowStates.is_edit_state = false)
                "
              >
                ОТМЕНА
              </button>
            </div>
          </div>
          <div class="popup__cont">
            <step-nav
              :step="step"
              :class="print.changeWindow ? 'changeLogState' : ''"
              @changeStep="changeStep"
            />

            <div
              class="pdf-cont pdf-cont--ml-view pdf-view-state"
              v-if="!windowStates.is_edit_state"
            >
              <Print_1 v-if="step === 1" />
              <Print_2 v-if="step === 2" />
              <Print_3 v-if="step === 3" />
              <Print_3_1 v-if="step === 3.1" />
              <div
                v-if="step === 4"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_4 />
              </div>
              <Print_5 v-if="step === 5" />
              <div
                v-if="step === 6"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_6 />
              </div>
              <!--              <div v-if="step===6.1" class="pdf-cont-scroll pdf-cont&#45;&#45;ml-s scroll-style">-->
              <!--                <Print_6_1/>-->
              <!--              </div>-->
              <div
                v-if="step === 7"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_7 />
              </div>
              <div
                v-if="step === 7.1"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_7_1 />
              </div>
              <div
                v-if="step === 8"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_8 />
              </div>
              <div
                v-if="step === 9"
                class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
              >
                <Print_9 />
              </div>
              <Print_9_1 v-if="step === 9.1" />
              <Print_10 v-if="step === 10" />
              <Print_11 v-if="step === 11" />
              <Print_12 v-if="step === 12" />
              <Print_13 v-if="step === 13" />
              <Print_14 v-if="step === 14" />
              <Print_15 v-if="step === 15" />
              <Print_16 v-if="step === 16" />
              <!--              <Print_17 v-if="step===17"/>-->
              <Print_18 v-if="step === 18" />
              <Print_19 v-if="step === 19" />
              <Print_20 v-if="step === 20" />
            </div>

            <template v-else>
              <step-1--covers v-if="step === 1" @changeStep="changeStep" />
              <step-2--content v-if="step === 2" @changeStep="changeStep" />
              <step-3--tasks v-if="step === 3" @changeStep="changeStep" />
              <step-3-1--tasks v-if="step === 3.1" @changeStep="changeStep" />
              <step-4--schemes v-if="step === 4" @changeStep="changeStep" />
              <step-5--options v-if="step === 5" @changeStep="changeStep" />
              <step-6--entry v-if="step === 6" @changeStep="changeStep" />
              <!--              <step-6-1&#45;&#45;entry v-if="step===6.1" @changeStep="changeStep"/>-->
              <step-7--exit v-if="step === 7" @changeStep="changeStep" />
              <step-7-1--exit v-if="step === 7.1" @changeStep="changeStep" />
              <step-8--payment v-if="step === 8" @changeStep="changeStep" />
              <step-9--additional v-if="step === 9" @changeStep="changeStep" />
              <step-9-1--additional-desc-page
                v-if="step === 9.1"
                @changeStep="changeStep"
              />
              <step-10--total v-if="step === 10" @changeStep="changeStep" />
              <step-11--tasks v-if="step === 11" @changeStep="changeStep" />
              <step-12--ad_owner v-if="step === 12" @changeStep="changeStep" />
              <step-13--ad_tenant v-if="step === 13" @changeStep="changeStep" />
              <step-14--ad_guest v-if="step === 14" @changeStep="changeStep" />
              <step-15--service v-if="step === 15" @changeStep="changeStep" />
              <step-16--service2 v-if="step === 16" @changeStep="changeStep" />
              <!--              <step-17&#45;&#45;option_desc v-if="step===17" @changeStep="changeStep"/>-->
              <step-18--done_works
                v-if="step === 18"
                @changeStep="changeStep"
              />
              <step-19--objects v-if="step === 19" @changeStep="changeStep" />
              <step-20--final v-if="step === 20" @changeStep="changeStep" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="copyDialog"
      class="ob-dialog"
      max-width="550"
      v-if="copyDialog"
    >
      <v-card>
        <v-card-title class="headline"
          >Какой курс использовать для нового КП?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            flat="flat"
            @click="
              () => {
                copyDialog = false;
                createCopyOffer('clear_copy', offerObject.json.rateValue);
              }
            "
          >
            Старый курс: {{ offerObject.json.rateValue }}
          </v-btn>
          <v-btn
            color="red darken-1"
            flat="flat"
            @click="
              () => {
                copyDialog = false;
                createCopyOffer('clear_copy', rateValue);
              }
            "
          >
            Новый курс: {{ rateValue }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Nav from "./Nav";
import * as c from "@/store/sales/constants";
import Step_0 from "./steps/step-0-system";
import Step_1 from "./steps/step-1-covers";
import Step_2 from "./steps/step-2-content";
import Step_3 from "./steps/step-3-tasks";
import Step_3_1 from "./steps/step-3-1-tasks";
import Step_4 from "./steps/step-4-schemes";
import Step_5 from "./steps/step-5-options";
import Step_6 from "./steps/step-6-entry";
import Step_6_1 from "./steps/step-6-1-entry";
import Step_7 from "./steps/step-7-exit";
import Step_7_1 from "./steps/step-7-1-exit";
import Step_8 from "./steps/step-8-payment";
import Step_9 from "./steps/step-9-additional";
import Step_10 from "./steps/step-10-total";
import Step_11 from "./steps/step-11-tasks";
import Step_12 from "./steps/step-12-ad_owner";
import Step_13 from "./steps/step-13-ad_tenant";
import Step_14 from "./steps/step-14-ad_guest";
import Step_15 from "./steps/step-15-service";
import Step_16 from "./steps/step-16-service2";
import Step_17 from "./steps/step-17-option_desc";
import Step_18 from "./steps/step-18-done_works";
import Step_19 from "./steps/step-19-objects";
import Step_9_1 from "./steps/step-9-1-additional-desc-page";
import Step_20 from "./steps/step-20-final";

import Print_1 from "./prints/print_1";
import Print_2 from "./prints/print_2";
import Print_3 from "./prints/print_3";
import Print_3_1 from "./prints/print_3-1";
import Print_4 from "./prints/print_4";
import Print_5 from "./prints/print_5";
import Print_6 from "./prints/print_6";
import Print_6_1 from "./prints/print_6-1";
import Print_7 from "./prints/print_7";
import Print_7_1 from "./prints/print_7-1";
import Print_8 from "./prints/print_8";
import Print_9 from "./prints/print_9";
import Print_10 from "./prints/print_10";
import Print_11 from "./prints/print_11";
import Print_12 from "./prints/print_12";
import Print_13 from "./prints/print_13";
import Print_14 from "./prints/print_14";
import Print_15 from "./prints/print_15";
import Print_16 from "./prints/print_16";
import Print_17 from "./prints/print_17";
import Print_18 from "./prints/print_18";
import Print_19 from "./prints/print_19";
import Print_9_1 from "./prints/print_9-1";
import Print_20 from "./prints/print_20";

import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as _ from "lodash";

import {
  CHANGE_OFFER_STATUS_RIGHT,
  EDIT_OFFER_RIGHT,
  SALE_MODULE_RIGHT,
} from "../../../../store/sales/constants";
import { Helpers } from "../../../../mixins/Helpers";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { showAlert } from "../../../../utils";
import { GET_USERS_BY_RIGHT } from "../../../../store/common/constants";

export default {
  data() {
    return {
      step: 1,
      downloadBtnsShow: false,
      savingOffer: false,
      EDIT_OFFER_RIGHT,
      CHANGE_OFFER_STATUS_RIGHT,
      allowedToSaleModuleUsersArray: [],
      copyDialog: false,
    };
  },
  mixins: [SalesHelpers, Helpers],
  watch: {
    is_open(val) {
      if (val) {
        this.makeOptionsQuery("Entry", "rack");
        this.makeOptionsQuery("Entry_2", "barrier");
        this.makeOptionsQuery("Exit", "rack");
        this.makeOptionsQuery("Exit_2", "barrier");
        this.makeOptionsQuery("Payment", "cash_1");
        this.getServiceTypePrice();

        this.getLastTasksText;
        this.optionPages;
        this.objectPageLength;
      }
    },
  },
  props: {
    is_open: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this[GET_USERS_BY_RIGHT]({ RightId: SALE_MODULE_RIGHT }).then((res) => {
      if (res.data.status === "success") {
        this.allowedToSaleModuleUsersArray = res.data.usersIds;
      }
    });
  },
  computed: {
    offerDevicesOptionForExpost() {
      return {
        json_fields: {
          Название: "name",
          Количество: "count",
          "Стоимость за ед.": "one_count",
          Сумма: "sum",
        },
        json_data: this.totalDevicesForXlsExport,
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      };
    },
    offerName() {
      const city = this.offerObject.json.City
        ? ", " + this.offerObject.json.City
        : "";
      return this.offerObject.json.Name
        ? this.offerObject.json.Name + city
        : "Новое коммерческое предложение";
    },
    allowedToModuleUsers() {
      if (this.allowedToSaleModuleUsersArray.length) {
        return _.filter(this.users, (i) =>
          this.allowedToSaleModuleUsersArray.includes(i.Id)
        );
      }
      return [];
    },
    ...mapState("sales", [
      "offerObject",
      "settingsObject",
      "print",
      "windowStates",
      "commercialSiteUrl",
    ]),
    ...mapGetters("sales", ["totalDevicesForXlsExport"]),
    ...mapState("tasks", ["users"]),
  },
  methods: {
    onCloseSettings() {
      this.$emit("onCloseSettings");
      this.print.preview = false;
      this.print.changeWindow = false;
      this.windowStates.is_edit_state = false;
      this.step = 1;
      this[c.RESET_OFFER]();
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onCloseSettings();
      }
    },
    changeStep(step) {
      this.step = step;
    },
    resetOffer() {
      this.onCloseSettings();
      this.step = 1;
      this[c.RESET_OFFER]();
    },
    saveOffer() {
      if (this.offerObject.OpenAccessByLink === "1") {
        this.print.preview = true;
        this.print.rendering = true;
      }
      this[c.UPDATE_OFFER]()
        .then(() => {
          if (this.offerObject.OpenAccessByLink === "1") {
            setTimeout(() => {
              this.createOfferLink();
            }, 200);
          }
        })
        .catch(() => {
          showAlert(
            "",
            "Ошибка",
            "Соединение с интернетом прервано, дамп КП сохранен локально."
          );
          this.downloadDump();
        });
    },
    createCopyOffer(type, rateValue = false) {
      if (!this.savingOffer) {
        this.savingOffer = true;
        this[c.SAVE_OFFER]({ type, rateValue }).finally(() => {
          this.savingOffer = false;
        });
      }
    },
    removeOffer(e) {
      if (!confirm("Вы действительно хотите удалить предложение ?")) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        this[c.REMOVE_OFFER]();
      }
    },
    showChangeLog() {
      this.print.changeWindow = true;
      this.compareOfferWithNewTemplate(this.offerObject.json);
      // console.log(this.newTemplateChanges)
      this.changeStep(
        this.searchNextChangePage(this.newTemplateChanges[0], true)
      );
      this.makeOptionsQuery("Entry", "rack");
      this.makeOptionsQuery("Entry_2", "barrier");
      this.makeOptionsQuery("Exit", "rack");
      this.makeOptionsQuery("Exit_2", "barrier");
      this.makeOptionsQuery("Payment", "cash_1");
      this.getServiceTypePrice();

      this.getLastTasksText;
      this.optionPages;
      this.objectPageLength;
    },
    deleteOfferLink() {
      this[c.CREATE_OFFER_LINK](0);
    },
    createOfferLink() {
      this[c.TOGGLE_PRELOADER]({ type: "main", status: true });

      this.print.preview = true;
      this.print.rendering = true;

      this[c.CREATE_OFFER_LINK](1).finally(() => {
        this[c.TOGGLE_PRELOADER]({ type: "main", status: false });
        this.print.rendering = false;
        this.print.preview = false;
      });
    },
    changeRateValue(e) {
      let newRateValue = e.target.value;
      let oldValue = this.offerObject.json.rateValue;
      // if (!newRateValue) e.target.value = newRateValue = this.rateValue
      if (this.rateValue !== newRateValue) {
        this.offerObject.json.rateValue = newRateValue;
        // console.log(oldValue, newRateValue);
        this[c.CONVERT_TO_RUB]({ newValue: newRateValue, oldValue });
        this.offerObject.json.Total.Options.types = _.map(
          this.offerObject.json.Total.Options.types,
          (i) => {
            // if (i.name === 'Пуско-наладочные работы') {
            //   i.convertCost = this.totalDevicesPrice * 7 / 100
            // }
            // i.convertCost = Math.round(i.convertCost / 1000) * 1000
            return i;
          }
        );
      }
    },
    downloadDump() {
      const element = document.createElement("a");
      const fileName = this.offerObject.json.Name
        ? this.offerObject.json.Name + "_"
        : "";
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(JSON.stringify(this.offerObject.json))
      );
      element.setAttribute(
        "download",
        fileName + "dump_at_" + moment().format("YYYY-MM-DD HH:mm:ss") + ".txt"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    restoreDump(dump) {
      const self = this;
      if (dump) {
        if (dump[0].name.split(".").pop() === "txt") {
          try {
            var reader = new FileReader();
            reader.readAsText(dump[0], "UTF-8");
            reader.onload = function (evt) {
              const json = JSON.parse(evt.target.result);
              self.offerObject.json = {
                ...json,
                Id: json.Id ? json.Id : self.offerObject.json.Id,
              };
            };
          } catch (e) {
            showAlert("error", "", "Не удалось восстановить данные из дампа");
            console.log(e);
          }
        } else {
          showAlert("error", "", "Неверный формат дампа");
        }
      }
    },
    changeResponsibleUser() {
      this.offerObject.json.ManagerName = _.find(this.users, [
        "Id",
        this.offerObject.json.ManagerId,
      ]).FullName;
    },
    ...mapActions("sales", [
      c.RESET_OFFER,
      c.UPDATE_OFFER,
      c.TOGGLE_PRELOADER,
      c.REMOVE_OFFER,
      c.SAVE_OFFER,
      c.CREATE_OFFER_LINK,
    ]),
    ...mapActions("common", [GET_USERS_BY_RIGHT]),
  },
  components: {
    "step-nav": Nav,
    // eslint-disable-next-line vue/no-unused-components
    "step-0--system": Step_0,
    "step-1--covers": Step_1,
    "step-2--content": Step_2,
    "step-3--tasks": Step_3,
    "step-3-1--tasks": Step_3_1,
    "step-4--schemes": Step_4,
    "step-5--options": Step_5,
    "step-6--entry": Step_6,
    // eslint-disable-next-line vue/no-unused-components
    "step-6-1--entry": Step_6_1,
    "step-7--exit": Step_7,
    "step-7-1--exit": Step_7_1,
    "step-8--payment": Step_8,
    "step-9--additional": Step_9,
    "step-10--total": Step_10,
    "step-11--tasks": Step_11,
    "step-12--ad_owner": Step_12,
    "step-13--ad_tenant": Step_13,
    "step-14--ad_guest": Step_14,
    "step-15--service": Step_15,
    "step-16--service2": Step_16,
    // eslint-disable-next-line vue/no-unused-components
    "step-17--option_desc": Step_17,
    "step-18--done_works": Step_18,
    "step-19--objects": Step_19,
    "step-9-1--additional-desc-page": Step_9_1,
    "step-20--final": Step_20,
    Print_1,
    Print_2,
    Print_3,
    Print_3_1,
    Print_4,
    Print_5,
    Print_6,
    // eslint-disable-next-line vue/no-unused-components
    Print_6_1,
    Print_7,
    Print_7_1,
    Print_8,
    Print_9,
    Print_10,
    Print_11,
    Print_12,
    Print_13,
    Print_14,
    Print_15,
    Print_16,
    // eslint-disable-next-line vue/no-unused-components
    Print_17,
    Print_18,
    Print_19,
    Print_9_1,
    Print_20,

    downloadExcel: JsonExcel,
  },
};
</script>
