<template>
  <div style="position: relative">
    <div class="cm-popup-search">
      <div
        class="search-bar search-bar_white сmp__search actions-header__search-bar"
      >
        <button class="search-bar__button" type="supmit">
          <svg>
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-search"
            ></use>
          </svg>
        </button>
        <input
          class="search-bar__input"
          type="search"
          placeholder="Введите наименование"
          v-model="searchInput"
        />
      </div>
    </div>

    <div class="table-spinner" v-show="preloader">
      <Spinner
        :noFadeIn="true"
        name="wave"
        color="#c9eeff"
        width="60"
        height="60"
      />
    </div>

    <div class="table cm-table-2 сmp-table" v-if="filteredDevices">
      <div class="table__row-wrap">
        <div
          class="tableInnerRow"
          :ref="'row' + index"
          :key="index"
          v-for="(item, index) in filteredDevices"
        >
          <div class="table__row table__row_main" @click="openForm(index)">
            <div class="table__col table__col_flexible">
              <div class="cm-table-col-1">
                <div class="cm-table-col-1__arrow">
                  <svg class="select__caret">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#icon_icon-caret"
                    ></use>
                  </svg>
                </div>
                <div class="cm-table-col-1__text">
                  <span class="cm-table-col-1__name">{{ item.Name }}</span>
                  <span class="cm-table-col-1__detail">
                    <p>
                      Версия софта: - {{ item.SoftVer }}| Версия БД: -
                      {{ item.DbVer }}| Внешний IP: {{ item.IP }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="cm-client-detail-form">
            <div class="cpb3__thead">
              <div class="cpb3__thead-row cpb3__col-1"></div>
              <div class="cpb3__thead-row cpb3__col-1">БД доступ</div>
              <div class="cpb3__thead-row cpb3__col-2">TeamViewer</div>
              <div class="cpb3__thead-row cpb3__col-2">Windows</div>
            </div>
            <div class="cpb3__tbody">
              <div class="cpb3__tbody-item">
                <div class="cpb3__tbody-item__col cpb3__col-1">Логин:</div>
                <div class="cpb3__tbody-item__col cpb3__col-1 cpb3__col-text">
                  {{ item.DBLogin }}
                </div>
                <div class="cpb3__tbody-item__col cpb3__col-2">
                  <input type="text" class="input" v-model="item.TWId" />
                </div>
                <div class="cpb3__tbody-item__col cpb3__col-2">
                  <input type="text" class="input" v-model="item.RDPLogin" />
                </div>
              </div>
              <div class="cpb3__tbody-item">
                <div class="cpb3__tbody-item__col cpb3__col-1">Пароль:</div>
                <div class="cpb3__tbody-item__col cpb3__col-1 cpb3__col-text">
                  {{ item.DBPassword }}
                </div>
                <div class="cpb3__tbody-item__col cpb3__col-2">
                  <input type="text" class="input" v-model="item.TWPassword" />
                </div>
                <div class="cpb3__tbody-item__col cpb3__col-2">
                  <input type="text" class="input" v-model="item.RDPPassword" />
                </div>
              </div>
            </div>
            <div
              class="cm-client-detail-form__btns cm-client-detail-form__btns-2 cpb3__btns"
            >
              <span @click="resetInputValue(item.DeviceId)">Отменить</span>
              <span @click="saveDevide(item)">Сохранить</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-spinkit";
import { showAlert } from "@/utils";
import Api from "@/api/clients";
import * as _ from "lodash";
export default {
  props: {
    objectId: null,
  },
  data() {
    return {
      searchInput: "",
      devices: null,
      initialDevicesData: null,
      preloader: true,
    };
  },
  created() {
    Api.getDevices(this.objectId)
      .then((res) => {
        this.devices = [];
        this.initialDevicesData = [];
        _.each(res.data, (i) => {
          this.devices.push({ ...i });
          this.initialDevicesData.push({ ...i });
        });
        this.preloader = false;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        this.preloader = false;
        console.log(err);
      });
  },
  computed: {
    filteredDevices() {
      return this.devices
        ? this.devices.filter((i) => {
            const re = new RegExp(this.searchInput, "gi");
            return re.test(i.Name);
          })
        : [];
    },
  },
  methods: {
    openForm(index) {
      const item = this.$refs["row" + index][0];
      document.querySelectorAll(".tableInnerRow").forEach((i) => {
        if (item !== i) i.classList.remove("tableInnerRow--open");
      });
      item.classList.toggle("tableInnerRow--open");
    },
    resetInputValue(deviceId) {
      const initialData = _.find(this.initialDevicesData, [
        "DeviceId",
        deviceId,
      ]);
      this.devices = _.map(this.devices, (i) => {
        if (i.DeviceId === initialData.DeviceId) i = { ...initialData };
        return i;
      });
    },
    saveDevide(device) {
      this.preloader = true;
      Api.updateDevice(device.DeviceId, device)
        .then(() => {
          this.preloader = false;
          this.initialDevicesData = _.map(this.initialDevicesData, (i) => {
            if (i.DeviceId === device.DeviceId) i = { ...device };
            return i;
          });
          showAlert("success", "Успешно", "Устройство успешно изменено");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          this.preloader = false;
        });
    },
  },
  components: { Spinner },
};
</script>
