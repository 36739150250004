import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { sortsTemplate } from "@/modules/tasks/components/utils";

export default {
  namespaced: true,
  state: {
    userData: null,
    roles: [],
    rights: [],
    templates: [],
    groups: [],
    allGroups: [],
    allUserFilters: [],
    allFavorites: [],
    globalGroups: [],
    users: [],
    objects: [],
    tasks: [],
    selectedTask: null,
    imagesForGallery: [],
    tags: [],
    tagGroups: [],
    notifications: [],
    standartFilters: [],
    userFilters: [],
    gallery: {
      images: [],
      index: null,
    },
    countNotifications: {
      countNewNotifications: 0,
      countUnreadNotifications: 0,
    },
    settings: {
      currentGroup: null,
      processFromFilter: null,
      taskFilterParams: {
        "params[TaskName]": "",
      },
      textBubbleChoords: {
        left: 0,
        top: 0,
        isOpen: false,
        el: null,
        retain: 0,
        className: null,
        search: "",
      },
      hideCompletedTasks: false,
      docFileToView: null,
      docFileIdToView: null,
      commentIdForAnswers: null,
      mainTableColsWidth: {
        name: 0,
        status: 0,
        tags: 0,
        resp: 0,
        date: 0,
        view: 0,
      },
      filterGroupTaskByGroups: false,
      filterGroupTaskByGroupsLoading: false,
      keydownFunction: () => {},
    },
    progressFilesUpload: 0,
    processes: [],
    groupProcesses: [],
    favorites: [],
    tasksIdsArrayForNotificationsGet: [],
    activeRights: [],
    isAdmin: false,
    customFilterParams: null,
    automatizationsSettingsStore: {},
    automatizations: [],
    automatizationsObjectsTypes: [],
    automatizationsActions: [],
    automatizationsActionsOptions: [],
    automatizationsActionsObjects: [],
    automatizationsActionsObjectsTypes: [],
    automatizationsActionsObjectsOptions: [],
    automatizationsLoadingSettings: false,
    commonLocalStorageSettings: "",
    localStorageSettings: [
      { Id: "test-id", Type: "group", settings: "some-settings-json" },
    ],
    sections: [],
    groupedTaskByFilter: [],
    tableFilterControl: {
      filter: null,
      filterName: "",
      countForFlashFilterCheck: 0,
      cachedFilter: null,
      flashFilterData: null,
    },
    commonModuleSettings: {
      sidebarIsOpen: true,
      selectedItems: [],
      groupSettingsPopupOpen: false,
      taskCreatePopupOpen: false,
      notificationsPopupOpen: false,
      folderActivityPopupOpen: false,
      subTask: null,
      sectionForTaskCreate: null,
      hideCompletedTasks: false,
      profileSettingsPopupOpen: false,
      page: 0,
      viewType: false,
      sorts: JSON.parse(JSON.stringify(sortsTemplate)),
      taskDetailPopupsCount: 0,
      taskCreateDetailPopupsCount: 0,
      automationAttachItems: [],
      sharedFilterInQuery: null,
      tasksLoading: false,
      search: "",
    },
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
