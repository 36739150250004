<template>
  <div class="activity cm-popup__right">
    <SimplePreloader v-if="preloader" />
    <div class="activity__header">
      <!--      <p class="activity__header-title activity__title">Сегодня</p>-->
      <div class="activity__header-calendar">
        <svg width="17" height="19">
          <use xlink:href="/static/images/tasks/sprite.svg#calendar-icon"></use>
        </svg>
      </div>
    </div>
    <div class="activity__content">
      <div
        class="activity__block"
        v-for="(notifications, index) in groupedNotifications"
        :key="index"
      >
        <div class="activity__block-title activity__title">
          {{ getGroupDateName(index) }}
        </div>
        <ActivityItem
          v-for="(item, index) in notifications"
          :key="item.Id"
          :item="item"
          :nextItem="notifications[index + 1]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import moment from "moment";
import ActivityItem from "./ActivityItem";
import { mapActions } from "vuex";
import { GET_NOTIFICATIONS_FOR_TASK } from "@/store/tasks/constants";

export default {
  name: "Activity",
  props: ["loadedTask"],
  data() {
    return {
      activity: [],
      preloader: true,
    };
  },
  created() {
    this[GET_NOTIFICATIONS_FOR_TASK](this.loadedTask.entityModel.Id)
      .then(({ activity }) => {
        this.activity = activity;
        this.loadedTask.activity = activity;
      })
      .finally(() => (this.preloader = false));
  },
  computed: {
    groupedNotifications() {
      return _.groupBy(this.activity, (i) => {
        return moment(i.DateCreate).startOf("day").format();
      });
    },
  },
  methods: {
    getGroupDateName(date) {
      return moment(date).locale("ru").format("DD MMM YYYY");
    },
    ...mapActions("tasks", [GET_NOTIFICATIONS_FOR_TASK]),
  },
  components: {
    ActivityItem,
  },
};
</script>

<style scoped></style>
