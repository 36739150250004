<template>
  <div>
    <template v-if="!group">
      <div class="task-popup-header">
        <div class="actions-header__col actions-header__col_flexible">
          <div class="search-bar search-bar_white actions-header__search-bar">
            <button type="submit" class="search-bar__button">
              <svg>
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-search"
                ></use>
              </svg>
            </button>
            <input
              class="search-bar__input"
              type="search"
              v-model="search"
              placeholder="Введите название группы"
            />
          </div>
        </div>
      </div>
      <div class="popup__body">
        <ul class="group-list">
          <li
            class="group-list__item"
            v-for="item in filteredGroups"
            :key="item.Id"
            @click.stop="group = item"
          >
            <svg
              width="16"
              height="15"
              :style="{ fill: item.Color ? item.Color : '#FFB72B' }"
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/tasks/sprite.svg#folder-icon"
              ></use>
            </svg>
            <p>{{ item.Name }}</p>
          </li>
        </ul>
      </div>
    </template>
    <AttachTasksTasksList
      v-else
      :group="group"
      @unselectGroup="group = null"
      @onSubmitHandler="
        (items) => {
          $emit('onSubmitHandler', items);
          group = null;
        }
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AttachTasksTasksList from "@/modules/tasks/components/AttachTasks/AttachTasksTasksList";
import { Helpers } from "@/mixins/Helpers";

export default {
  name: "AttachTasksGroupsList",
  mixins: [Helpers],
  data() {
    return {
      search: "",
      group: null,
    };
  },
  computed: {
    filteredGroups() {
      return this.allGroups.filter((item) =>
        item.Name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    ...mapState("tasks", ["allGroups"]),
  },
  methods: {},
  components: {
    AttachTasksTasksList,
  },
};
</script>

<style scoped></style>
