<template>
  <div class="table__row-wrap table__row-wrap--tasks">
    <draggable
      v-if="loaded"
      v-model="computedSections"
      @end="changeSectionOrder(computedSections)"
      draggable=".section-tasks-draggable-zone_draggable"
      :group="{ name: 'table-sections' }"
      class="section-tasks-draggable-zone-wrapper"
      :disabled="!checkUserTaskRight('change_section_order')"
    >
      <TableSection
        v-for="(item, index) in computedSections"
        :key="'section' + item.Id"
        :section="item"
        :sections="computedSections"
        :parentData="parentData"
        :sectionTasks="item.tasks"
        :inDetailTask="loadedTask ? loadedTask.entityModel : true"
        :haveNextSection="index + 1 < computedSections.length"
        :selectedTaskParentData="parentData"
        :parentTask="null"
        :level="0"
        :draggableId="'subtasks'"
        :boundariesElement="el"
        @setTasksByDraggable="setTasksByDraggable"
        @loadSectionTasks="$emit('loadSectionTasks')"
        @changeOrder="changeOrder"
        @selectItem="(event, item) => $emit('selectItem', event, item)"
        @createSubTask="() => {}"
        @shiftSelection="() => {}"
        @ctrlSelection="() => {}"
      />
    </draggable>
    <!--    <TableRow-->
    <!--      v-for="(item, index) in subtasks"-->
    <!--      :key="index"-->
    <!--      :item="item"-->
    <!--      :index="index"-->
    <!--      :level="0"-->
    <!--      :inDetailTask="loadedTask ? loadedTask.entityModel : true"-->
    <!--      :selectedTaskParentData="parentData"-->
    <!--      @selectItem="(event, id) => $emit('selectItem', event, id)"-->
    <!--      @createSubTask="() => {}"-->
    <!--      @shiftSelection="() => {}"-->
    <!--      @ctrlSelection="() => {}"-->
    <!--    />-->
    <transition name="fade">
      <div
        class="table-spinner table-spinner--table"
        v-if="parentData.gettingTasks"
      >
        <Spinner
          :noFadeIn="true"
          name="ball-spin-fade-loader"
          color="#c7c7c7"
          width="60"
          height="60"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import moment from "moment";
import {
  CHANGE_ORDER,
  EDIT_TASK_DEPENDENCY,
  GET_SECTIONS,
  GET_TASKS,
  SET_TASK_CHILDS,
  SILENT_EDIT_TASK,
} from "@/store/tasks/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import draggable from "vuedraggable";
import TableSection from "@/modules/tasks/components/Tables/MainTable/TableSection";
import * as _ from "lodash";
import Spinner from "vue-spinkit";

export default {
  mixins: [common],
  props: ["parentData", "loadedTask"],
  data() {
    return {
      loaded: false,
      el: this.$el,
    };
  },
  created() {
    // console.log(this.$route.name)
  },
  mounted() {
    this.el = this.$el;
    this.loaded = true;
  },
  computed: {
    computedSections: {
      get() {
        return this.parentData.sections;
      },
      set(data) {
        data;
        this.parentData.sections = data;
      },
    },
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    getRoute(item) {
      return {
        path: window.location.pathname + "/" + item.Id,
      };
    },
    getFinishDate(item) {
      const date = moment(item.FinishDate);
      const time = moment(item.FinishTime, "HH:mm:ss");
      date.set({ hour: time.format("HH"), minute: time.format("mm") });
      return date.locale("ru").format("DD MMM YYYY в HH:mm");
    },
    openSubTask(id) {
      this.$router.push("/tasks/");
      setTimeout(() => {
        this.$router.push("/tasks/" + id);
      }, 300);
    },
    changeOrder(items) {
      this[CHANGE_ORDER]({
        items,
        apiMethod: "api/tasks/v2/tasks/updateOrder",
      }).then(() => {
        // this[GET_TASKS]();
        // this[GET_SECTIONS](this.group.Id);
      });
    },
    changeSectionOrder(items) {
      this[CHANGE_ORDER]({
        items: items.filter((i) => i.Id),
        apiMethod: "api/tasks/v2/TasksSections/updateOrder",
      }).then(() => {
        this[GET_TASKS]();
        this[GET_SECTIONS](this.group.Id);
      });
    },
    setTasksByDraggable(parentTask, tasks, section) {
      section = JSON.parse(JSON.stringify(section));
      section.Id = section.Name === "virtualSection" ? null : section.Id;
      const cleanTasksArray = [];
      tasks = _.each(tasks, (i) => {
        const SectionId = section ? section.Id : null;
        const SectionName = section ? section.Name : null;
        const ParentId = parentTask
          ? parentTask.Id
          : this.loadedTask.entityModel.Id;
        let needSave = false;

        if (i.isDepended) {
          if (i.SectionId !== SectionId) {
            i.SectionId = SectionId;
            this[EDIT_TASK_DEPENDENCY]({
              Id: i.DependRecordId,
              SectionId,
            });
          }
        } else {
          if (i.SectionId !== SectionId || i.ParentId !== ParentId) {
            needSave = true;
          }
          i.SectionId = SectionId;
          i.SectionName = SectionName;
          i.ParentId = ParentId;
          if (needSave) {
            this[SILENT_EDIT_TASK]({
              id: i.Id,
              data: {
                SectionId: i.SectionId,
                SectionName: i.SectionName,
                ParentId: i.ParentId,
              },
            });
          }
        }
        cleanTasksArray.push(i);
        // return i;
      });

      const subtasks = cleanTasksArray.length
        ? cleanTasksArray.filter((i) => !i.isDepended)
        : [];
      if (subtasks.length) {
        this.changeOrder(subtasks);
      }
      // const data = {
      //   parentTask,
      //   tasks: subtasks,
      //   section,
      //   arrayToMutate: subtasks,
      // };
      // this[SET_TASK_CHILDS](data);
      //
      // this.loadedTask.entityModel.childs = data.arrayToMutate;
      // this[REFRESH_SECTIONS]();
    },
    ...mapActions("tasks", [
      CHANGE_ORDER,
      GET_TASKS,
      GET_SECTIONS,
      SILENT_EDIT_TASK,
      EDIT_TASK_DEPENDENCY,
    ]),
    ...mapMutations("tasks", [SET_TASK_CHILDS]),
  },
  components: {
    draggable,
    TableSection,
    Spinner,
  },
};
</script>

<style scoped></style>
