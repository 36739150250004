<template>
  <div class="automation-statuses" ref="portal-wrapper">
    <div
      class="automation-statuses__name-wrap"
      @click="!disabled && (open = !open)"
    >
      <div class="automation-select-text--no-selectable" v-if="preplaceholder">
        {{ preplaceholder }}
      </div>

      <div
        class="automation-select-text"
        :class="{
          'automation-select-text--have-value': value,
          'automation-select-text--disabled': disabled,
        }"
      >
        {{ name }}
      </div>
    </div>
    <PersonsListSearch
      v-if="portalOpen"
      :selectedPersonId="selected"
      :className="'task-bubble--templates'"
      @choose="(id) => $emit('choose', id)"
      @close="open = false"
      v-portal="{ parent: $refs['portal-wrapper'] }"
    />
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapState } from "vuex";
import { Helpers } from "../../../../../mixins/Helpers";
import PersonsListSearch from "../../CommonComponents/PersonsListSearch";
import { capitalize } from "../../../../../utils";

export default {
  name: "AutomationPersonInput",
  props: ["value", "placeholder", "preplaceholder", "disabled"],
  mixins: [Helpers],
  data() {
    return {
      open: false,
      portalOpen: false,
    };
  },
  computed: {
    name() {
      if (this.selected) {
        return capitalize(this.selected.FullName.toLowerCase());
      } else {
        const val = this.placeholder ? this.placeholder : "выбрать";
        return capitalize(val.toLowerCase());
      }
    },
    selected() {
      return _.find(this.users, ["Id", this.value]);
    },
    ...mapState("tasks", ["users"]),
  },
  methods: {},
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.portalOpen = true;
        });
      } else {
        this.portalOpen = false;
      }
    },
  },
  components: {
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
