import * as c from "./constants";
import * as _ from "lodash";
import Vue from "vue";

export default {
  [c.SET_OBJECTS](state, data) {
    state.objects = data;
  },
  [c.SET_OBJECTS_FROM_REMOVE](state, data) {
    state.objects.objects = data;
  },
  [c.SET_COMPANIES](state, data) {
    state.companies = data;
  },
  [c.TOGGLE_PRELOADER](state, { preloader, status }) {
    state.preloaders[preloader] = status;
  },
  [c.SELECT_COMPANY](state, id) {
    state.selectedCompany = id;
  },
  [c.SET_SEARCH_INPUT](state, value) {
    state.searchInput = value;
  },
  [c.SET_SORT](state, value) {
    state.sort = value;
  },
  [c.REMOVE_SELECTED_ITEMS](state) {
    state.selectedItems = [];
  },
  [c.SET_SELECTED_ITEMS](state, item) {
    if (state.selectedItems.includes(item)) {
      state.selectedItems = _.reject(state.selectedItems, (i) => i === item);
    } else {
      state.selectedItems.push(item);
    }
  },
  [c.UPDATE_COMPANY](state, company) {
    state.companies = _.map(state.companies, (i) => {
      if (i.Id === company.Id) i = company;
      return i;
    });
  },
  [c.CREATE_COMPANY](state, company) {
    state.selectedCompany = company.Id;
    state.companies.push(company);
  },
  [c.SELECT_OBJECT](state, object) {
    state.selectedObject = object;
  },
  [c.UPDATE_OBJECT](state, object) {
    state.objects.objects = _.map(state.objects.objects, (i) => {
      if (i.Id === object.Id) i = object;
      return i;
    });
    state.selectedObject = object;
    Vue.localStorage.set("objects", state.objects);
  },
  [c.CREATE_OBJECT](state, object) {
    state.selectedObject = object;
    state.objects.objects.push(object);
    Vue.localStorage.set("objects", state.objects);
  },
  // <--CONTACTS |____
  // ||-------------------------------------------------
  [c.UPDATE_CONTACT](state, contact) {
    const object = state.selectedObject;
    object.contacts = _.map(object.contacts, (i) => {
      if (i.Id === contact.Id) i = contact;
      return i;
    });
    state.objects.objects = _.map(state.objects.objects, (i) => {
      if (i.Id === object.Id) i = object;
      return i;
    });
    Vue.localStorage.set("objects", state.objects);
  },
  [c.CREATE_CONTACT](state, contact) {
    const object = state.selectedObject;
    object.contacts.push(contact);
    state.objects.objects = _.map(state.objects.objects, (i) => {
      if (i.Id === object.Id) i = object;
      return i;
    });
    Vue.localStorage.set("objects", state.objects);
  },
  [c.DELETE_CONTACT](state, contactId) {
    const object = state.selectedObject;
    object.contacts = _.reject(object.contacts, ["Id", contactId]);
    state.objects.objects = _.map(state.objects.objects, (i) => {
      if (i.Id === object.Id) i = object;
      return i;
    });
    Vue.localStorage.set("objects", state.objects);
  },

  [c.UPDATE_CONTACT_DISTB](state, contact) {
    const distb = state.selectedDistb;
    distb.contacts = _.map(distb.contacts, (i) => {
      if (i.Id === contact.Id) i = contact;
      return i;
    });
  },
  [c.CREATE_CONTACT_DISTB](state, contact) {
    const distb = state.selectedDistb;
    distb.contacts.push(contact);
  },
  [c.DELETE_CONTACT_DISTB](state, contactId) {
    const distb = state.selectedDistb;
    distb.contacts = _.reject(distb.contacts, ["Id", contactId]);
  },
  // ||-----------------------------------------------/>

  // <--DISTB |____
  // ||-------------------------------------------------
  [c.SET_DISTB](state, data) {
    state.distbs = data;
  },
  [c.CREATE_DISTB](state, distb) {
    state.selectedDistb = distb;
    state.distbs.push(distb);
  },
  [c.UPDATE_DISTB](state, distb) {
    state.distbs = _.map(state.distbs, (i) => {
      if (i.Id === distb.Id) i = distb;
      return i;
    });
  },
  [c.SELECT_DISTB](state, distb) {
    state.selectedDistb = distb;
  },
  [c.GET_DISTB_COMPANIES](state, data) {
    state.distbsCompanies = data;
  },
  [c.GET_DISTB_OBJECTS](state, data) {
    state.distbsObjects = data;
  },
  [c.GET_DISTB_CONTACTS](state, data) {
    state.distbsContacts = data;
  },
  [c.PUSH_DISTB_CONTACTS](state, data) {
    state.distbsContacts = [...state.distbsContacts, data];
  },
  [c.UPDATE_DISTB_CONTACT](state, data) {
    state.distbsContacts = _.map(state.distbsContacts, (i) => {
      if (i.Id === data.Id) i = data;
      return i;
    });
  },
  // ||-----------------------------------------------/>
};
