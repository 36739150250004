<template>
  <div class="task-settings__list">
    <SimplePreloader v-if="preloader" />
    <template v-if="state === 1">
      <div
        class="task-settings__list-item"
        @click="state = 1.1"
        v-if="!selectedItems"
      >
        <div class="task-settings__list-icon">
          <svg width="18" height="18" stroke="black">
            <use
              xlink:href="/static/images/tasks/sprite.svg#settings-icon"
            ></use>
          </svg>
        </div>
        Настройки
      </div>
      <div
        class="task-settings__list-item"
        v-if="loadedTask"
        @click="state = 1.2"
      >
        <div class="task-settings__list-icon">
          <svg width="16" height="18">
            <use xlink:href="/static/images/tasks/sprite.svg#lock-icon"></use>
          </svg>
        </div>
        Видимость
      </div>
      <div
        class="task-settings__list-item"
        @click="state = 2"
        v-if="checkUserTaskRight('clone_task') && !selectedItems"
      >
        <div class="task-settings__list-icon">
          <svg width="18" height="18">
            <use xlink:href="/static/images/tasks/sprite.svg#double-icon"></use>
          </svg>
        </div>
        Дублировать
      </div>
      <div
        class="task-settings__list-item"
        style="cursor: not-allowed"
        v-if="!selectedItems"
      >
        <div class="task-settings__list-icon">
          <svg width="18" height="18">
            <use
              xlink:href="/static/images/tasks/sprite.svg#create-template-icon"
            ></use>
          </svg>
        </div>
        Создать шаблон
      </div>
      <div
        class="task-settings__list-item"
        @click="state = 3"
        v-if="checkUserTaskRight('move_task')"
      >
        <div class="task-settings__list-icon">
          <svg width="18" height="18">
            <use
              xlink:href="/static/images/tasks/sprite.svg#circle-angle-icon"
            ></use>
          </svg>
        </div>
        Перенести
      </div>
      <div
        class="task-settings__list-item"
        @click="checkTaskForDelete"
        v-if="checkUserTaskRight('delete_task') && !selectedItems"
      >
        <div class="task-settings__list-icon">
          <svg width="16" height="18" stroke="black">
            <use xlink:href="/static/images/tasks/sprite.svg#delete-icon"></use>
          </svg>
        </div>
        Удалить
      </div>
    </template>
    <template v-if="state === 1.1">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="state = 1"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Настройки
      </div>
      <label
        class="task-settings__list-item task-settings__list-item_switcher task-settings__list-inner-item"
      >
        Комментарии
        <div class="onoffswitch onoffswitch-normal">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            v-bind:true-value="'1'"
            v-bind:false-value="'0'"
            v-model="loadedTask.entityModel.EnableComments"
            @change="setTaskConfig('EnableComments')"
          />
          <div class="onoffswitch-label">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </div>
        </div>
      </label>
      <label
        class="task-settings__list-item task-settings__list-item_switcher task-settings__list-inner-item"
      >
        Запрет изменения срока без комментария
        <div class="onoffswitch onoffswitch-normal">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            v-bind:true-value="'1'"
            v-bind:false-value="'0'"
            v-model="loadedTask.entityModel.FinishDateOnlyWithComment"
            @change="setTaskConfig('FinishDateOnlyWithComment')"
          />
          <div class="onoffswitch-label">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </div>
        </div>
      </label>
      <label
        class="task-settings__list-item task-settings__list-item_switcher task-settings__list-inner-item"
      >
        Не требует принятия
        <div class="onoffswitch onoffswitch-normal">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            v-bind:true-value="'1'"
            v-bind:false-value="'0'"
            v-model="loadedTask.entityModel.ResponsibleAutoAccept"
            @change="setTaskConfig('ResponsibleAutoAccept')"
          />
          <div class="onoffswitch-label">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </div>
        </div>
      </label>
    </template>
    <template v-if="state === 1.2">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="state = 1"
        style="width: 240px"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Видимость
      </div>
      <label
        class="task-settings__list-item task-settings__list-item_checkbox task-settings__list-inner-item"
      >
        Все

        <div class="ui-radio">
          <input
            class="ui-radio__field"
            type="radio"
            value="1"
            v-model="loadedTask.entityModel.TaskAccess"
            @change="setAccess"
          />
          <span class="ui-radio__icon"></span>
        </div>
      </label>
      <label
        class="task-settings__list-item task-settings__list-item_checkbox task-settings__list-inner-item"
      >
        Исполнитель, подписчики, наблюдатели
        <div class="ui-radio">
          <input
            class="ui-radio__field"
            type="radio"
            value="2"
            v-model="loadedTask.entityModel.TaskAccess"
            @change="setAccess"
          />
          <span class="ui-radio__icon"></span>
        </div>
      </label>
    </template>
    <template v-if="state === 2">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="!partIdToShow ? (state = 1) : $emit('closeSettings')"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Дублировать
      </div>
      <div
        class="task-settings__list-item task-settings__list-inner-item"
        @click="copyTask(0)"
      >
        Только задачу
      </div>
      <div
        class="task-settings__list-item task-settings__list-inner-item"
        @click="copyTask(1)"
      >
        Задачу и её подзадачи
      </div>
    </template>
    <template v-if="state === 3">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="!partIdToShow ? (state = 1) : $emit('closeSettings')"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Перенести
      </div>
      <div
        class="task-settings__list-item task-settings__list-inner-item"
        @click="state = 3.1"
      >
        Перенести в группу
      </div>
      <div
        class="task-settings__list-item task-settings__list-inner-item"
        @click="openTasksPopup"
      >
        Перенести в задачу
      </div>
      <div
        @click="state = 3.2"
        v-if="sections"
        class="task-settings__list-item task-settings__list-inner-item"
      >
        Перенести в секцию
      </div>
    </template>

    <template v-if="state === 3.1">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="state = 1"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Перенести в группу
      </div>
      <div
        class="task-settings__list-item task-settings__list-gray-item"
        v-for="item in allGroups"
        :key="item.Id"
        @click="setTaskGroup(item.Id)"
      >
        <div class="task-settings__list-icon">
          <svg
            width="16"
            height="15"
            :style="{
              fill: item.Color ? item.Color : '#FFB72B',
            }"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#folder-icon"
            ></use>
          </svg>
        </div>
        <p>{{ item.Name }}</p>
      </div>
    </template>

    <template v-if="state === 3.2">
      <div
        class="task-settings__list-item task-settings__list-inner-item task-settings__list-head"
        @click="state = 1"
      >
        <div class="task-settings__list-back-icon">
          <svg width="8" height="14">
            <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
          </svg>
        </div>
        Перенести в секцию
      </div>
      <div
        class="task-settings__list-item task-settings__list-gray-item"
        v-for="item in sections.filter((i) => i.Name !== 'virtualSection')"
        :key="item.Id"
        @click="setTaskSection(item.Id)"
      >
        <p style="margin-left: 15px">{{ item.Name }}</p>
      </div>
    </template>

    <portal to="task-root-portal">
      <v-dialog v-model="agreeToRemove" max-width="290">
        <v-card>
          <v-card-title class="headline">Предупреждение</v-card-title>
          <v-card-text> Будут удалены также все дочерние задачи </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              @click="agreeToRemove = false"
            >
              Отменить
            </v-btn>
            <v-btn
              color="red darken-1"
              flat="flat"
              @click="
                () => {
                  agreeToRemove = false;
                  deleteTask();
                }
              "
            >
              Продолжить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as c from "../../../../../store/tasks/constants";
import {
  CHANGE_TASK_GROUP,
  CHANGE_TASKS_SECTIONS,
  GET_GROUPS,
  GET_TASKS,
  GET_USER_TASKS_LIST,
  TRANSFER_TASK_TO_OTHER_GROUP,
} from "../../../../../store/tasks/constants";
import { DELETE_TASK } from "../../../../../store/tasks/constants";
import { COPY_TASK } from "../../../../../store/tasks/constants";
import common from "../../../../../mixins/tasks/common";
import { EDIT_TASK } from "../../../../../store/tasks/constants";
import Api from "../../../../../api/tasks";
import { queueFetch } from "@/utils/queneFetch";

export default {
  name: "TaskSettingsList",
  props: [
    "loadedTask",
    "parentData",
    "selectedItems",
    "sections",
    "partIdToShow",
  ],
  mixins: [common],
  data() {
    return {
      state: this.partIdToShow ? this.partIdToShow : 1,
      item: {},
      agreeToRemove: false,
      preloader: false,
    };
  },
  computed: {
    ...mapState("tasks", ["allGroups", "selectedTask", "userData"]),
  },
  methods: {
    setTaskGroup(id) {
      if (this.selectedItems) {
        this.$emit("unselectTasks");
        this.$emit("closeSettings");
        queueFetch(this.selectedItems, async (taskId) => {
          await this.asyncTransferTaskToOtherGroup(id, taskId, true);
        }).then(() => {
          this[GET_TASKS]();
          this[GET_USER_TASKS_LIST](true);
        });
      } else {
        this.selectedTask.entityModel.GroupId = id;
        this.asyncTransferTaskToOtherGroup(
          id,
          this.selectedTask.entityModel.Id,
          false
        );
        this.$emit("closeSettings");
      }
    },
    setTaskSection(id) {
      if (this.selectedItems) {
        this.$emit("unselectTasks");
        this.$emit("closeSettings");
        this.$emit("closeSettings");
        this[CHANGE_TASKS_SECTIONS]({
          TaskIds: this.selectedItems,
          SectionId: id,
        });
      }
    },
    async asyncTransferTaskToOtherGroup(groupId, taskId, batch) {
      await this[CHANGE_TASK_GROUP]({
        GroupId: groupId,
        TaskId: taskId,
      });
      const payload = {
        id: taskId,
        data: {
          GroupId: groupId,
        },
      };
      await this[TRANSFER_TASK_TO_OTHER_GROUP]({ data: payload, batch });
      return true;
    },
    checkTaskForDelete() {
      let count = 0;
      Api.checkTaskHaveChildTasks(this.selectedTask.entityModel.Id).then(
        (res) => {
          if (res.data.status === "success") {
            count = res.data.count;
            if (!count) {
              this.deleteTask();
            } else {
              this.agreeToRemove = true;
            }
          }
        }
      );
    },
    deleteTask() {
      this.$emit("closeSettings");
      this[DELETE_TASK]({ id: this.selectedTask.entityModel.Id }).then(() =>
        this.$router.push("/tasks")
      );
    },
    copyTask(type) {
      const ids = [];
      this.preloader = true;
      if (this.selectedItems) {
        ids.push(...this.selectedItems);
      } else {
        ids.push(this.selectedTask.entityModel.Id);
      }
      this[COPY_TASK]({
        Pk: ids,
        CopyChildTasks: type,
      })
        .then(() => {
          if (this.selectedItems) {
            this.getTasksByFilterOrGroup();
            this.$emit("unselectTasks");
          }
          this.$emit("closeSettings");
        })
        .finally(() => (this.preloader = false));
    },
    setTaskConfig(field) {
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          [field]: this.loadedTask.entityModel[field],
        },
      };
      this[c.EDIT_TASK](payload);
    },
    setAccess() {
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          TaskAccess: this.loadedTask.entityModel.TaskAccess,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    openTasksPopup() {
      this.parentData.tasksListToTransferPopupOpen = true;

      if (this.selectedItems) {
        this.$emit("closeSettings");
      }
    },
    ...mapActions("tasks", [
      TRANSFER_TASK_TO_OTHER_GROUP,
      DELETE_TASK,
      COPY_TASK,
      EDIT_TASK,
      CHANGE_TASK_GROUP,
      GET_TASKS,
      GET_GROUPS,
      GET_USER_TASKS_LIST,
      CHANGE_TASKS_SECTIONS,
    ]),
  },
};
</script>

<style scoped>
.ui-radio {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
}

.ui-radio__icon {
  width: 20px;
  height: 20px;
  border: 2px solid #e5e5e5;
  border-radius: 20px;
  position: relative;
  -webkit-transition: border-color 0.12s;
  transition: border-color 0.12s;
}

.ui-radio__icon,
.ui-radio__lable {
  display: inline-block;
  vertical-align: top;
}

.ui-radio__icon,
.ui-radio__lable {
  display: inline-block;
  vertical-align: top;
}

.ui-radio__lable {
  line-height: 20px;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-radio__icon:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 6px;
  background-color: #f9ad1a;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: opacity 60ms, -webkit-transform 0.22s;
  transition: opacity 60ms, -webkit-transform 0.22s;
  transition: opacity 60ms, transform 0.22s;
  transition: opacity 60ms, transform 0.22s, -webkit-transform 0.22s;
}

.ui-radio__field:checked + .ui-radio__icon,
.ui-radio__field:focus + .ui-radio__icon,
.ui-radio__field:hover + .ui-radio__icon {
  border-color: #f9ad1a;
}

.ui-radio__field:checked + .ui-radio__icon:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ui-radio__field {
  visibility: hidden;
}

.ui-button {
  padding-top: 0px !important;
  margin-top: 30px !important;
}

.ui-button:not(:disabled) {
  display: inline-block;
  line-height: 40px;
  width: 100%;
  max-width: 390px;
  background-color: #f9ae17;
  border: 2px solid #bf8511;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  -webkit-transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.12s;
  transition-duration: 0.12s;
}
</style>
