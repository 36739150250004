export const GET_SETTINGS = "GET_SETTINGS";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const GET_BLOCKS = "GET_BLOCKS";
export const WORK_TYPES_ACTION = "WORK_TYPES_ACTION";
export const CHANGE_WORK_TYPES_ARRAY = "CHANGE_WORK_TYPES_ARRAY";
export const CHANGE_BLOCKS_ARRAY = "CHANGE_BLOCKS_ARRAY";
export const EDIT_ELEMENT_IN_BLOCKS_ARRAY = "EDIT_ELEMENT_IN_BLOCKS_ARRAY";
export const CHANGE_CITIES_ARRAY = "CHANGE_CITIES_ARRAY";
export const GET_CITIES = "GET_CITIES";
export const SET_BLOCKS = "SET_BLOCKS";
export const GET_BASE_URL = "GET_BASE_URL";
export const IMPORT_LANG = "IMPORT_LANG";
export const CHANGE_BLOG_CAT_ARRAY = "CHANGE_BLOG_CAT_ARRAY";
export const BLOG_CAT_ACTION = "BLOG_CAT_ACTION";
export const GET_DISTRICTS = "GET_DISTRICTS";
