<template>
  <div class="table">
    <table class="table-header-resizeble" id="main-tasks-table">
      <tr class="table__header">
        <!--      <div class="table__col table__col_width_35" style="border-right: none">-->
        <!--        <p class="table__header-text">№</p>-->
        <!--      </div>-->
        <th
          data-col-name="name"
          class="table__col table__col_resizeble table__col_flexible"
        >
          <p class="table__header-text">Задачи</p>
          <div
            class="table__filter-button"
            v-if="!filterOrSearchActive"
            :class="
              commonModuleSettings.sorts.TaskName.active
                ? 'table-c__filter-btn--active'
                : ''
            "
            @click="makeSort('TaskName')"
          >
            {{ commonModuleSettings.sorts.TaskName.asc ? "А-Я" : "Я-А" }}
          </div>
        </th>
        <th
          data-col-name="status"
          class="table__col table__col_resizeble table__col_width_160"
          v-if="(group && group.MainProcessId) || filterHaveProcessCondition"
        >
          <p class="table__header-text">Статус</p>
        </th>
        <th
          data-col-name="tags"
          class="table__col table__col_resizeble table__col_width_160"
        >
          <p class="table__header-text">Теги</p>
        </th>
        <th
          data-col-name="resp"
          class="table__col table__col_resizeble table__col_width_160"
        >
          <p class="table__header-text">Ответственный</p>
        </th>
        <th
          data-col-name="date"
          class="table__col table__col_resizeble table__col_width_160"
        >
          <p class="table__header-text">Срок</p>
          <div
            class="table__filter-button"
            v-if="!filterOrSearchActive"
            :class="
              commonModuleSettings.sorts.FinishDate.active
                ? 'table-c__filter-btn--active'
                : ''
            "
            @click="makeSort('FinishDate')"
          >
            {{ commonModuleSettings.sorts.FinishDate.asc ? "1-9" : "9-1" }}
          </div>
        </th>
        <th
          data-col-name="view"
          class="table__col table__col_resizeble table__col_width_50"
        >
          <p class="table__header-text">Вид</p>
        </th>
      </tr>
    </table>

    <div
      class="table__row-wrap table__row-wrap--tasks"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="0"
      infinite-scroll-immediate-check="false"
      infinite-scroll-throttle-delay="1000"
      v-if="!settings.filterGroupTaskByGroups"
    >
      <template>
        <template v-if="!filterOrSearchActive">
          <draggable
            v-model="computedSections"
            @end="changeSectionOrder(computedSections)"
            draggable=".section-tasks-draggable-zone_draggable"
            :group="{ name: 'table-sections' }"
            class="section-tasks-draggable-zone-wrapper"
            :disabled="!checkUserTaskRight('change_section_order')"
          >
            <TableSection
              v-for="(item, index) in computedSections"
              :key="'section' + item.Id"
              :level="0"
              :section="item"
              :sectionTasks="item.tasks"
              :sections="computedSections"
              :haveNextSection="index + 1 < computedSections.length"
              :parentData="commonModuleSettings"
              :parentTask="null"
              :draggableId="_uid"
              @setTasksByDraggable="setTasksByDraggable"
              @changeOrder="changeOrder"
              @selectItem="selectItem"
              @createSubTask="createSubTask"
              @shiftSelection="shiftSelection"
              @ctrlSelection="ctrlSelection"
            />
          </draggable>
        </template>
        <template v-else>
          <TableRow
            v-for="(item, index) in tasks"
            :key="item.Id"
            :item="item"
            :index="index"
            :level="0"
            :data-id="item.Id"
            :parentData="commonModuleSettings"
            :filterHaveProcessCondition="filterHaveProcessCondition"
            @selectItem="selectItem"
            @createSubTask="createSubTask"
            @shiftSelection="shiftSelection"
            @ctrlSelection="ctrlSelection"
          />
        </template>
      </template>
    </div>
    <GroupedTasksByFilterQuery v-else />
    <transition name="fade">
      <div
        class="table-spinner table-spinner--table"
        v-if="commonModuleSettings.tasksLoading"
      >
        <Spinner
          :noFadeIn="true"
          name="ball-spin-fade-loader"
          color="#c7c7c7"
          width="60"
          height="60"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import TableRow from "./TableRow";
import tasksTable from "../mixins/tasksTable";
import {
  CHANGE_ORDER,
  EDIT_TASK,
  GET_TASKS,
  REFRESH_SECTIONS,
  SET_COMMON_MODULE_POPUP,
  SET_COMMON_MODULE_SELECTED_ITEMS,
  SET_COMMON_MODULE_SUBTASK,
  SET_SECTIONS,
  SET_TASK_CHILDS,
  SILENT_EDIT_TASK,
} from "@/store/tasks/constants";
import { mapActions, mapMutations, mapState } from "vuex";
import TableSection from "@/modules/tasks/components/Tables/MainTable/TableSection";
import draggable from "vuedraggable";
import ColumnResizer from "column-resizer";
import GroupedTasksByFilterQuery from "@/modules/tasks/components/Tables/MainTable/GroupedTasksByGroup/GroupedTasksByGroupList";

export default {
  mixins: [common, tasksTable],
  props: ["filterHaveProcessCondition"],
  data() {
    return {
      preparedTasks: [],
      dragging: false,
      resizer: null,
    };
  },
  mounted() {
    this.enableResize();
    // var thElm;
    // var startOffset;
    // Array.prototype.forEach.call(
    //   document.querySelectorAll("#main-tasks-table .table__col_resizeble"),
    //   function (th) {
    //     th.style.position = "relative";
    //     console.log(th);
    //     var grip = document.createElement("div");
    //     grip.innerHTML = "&nbsp;";
    //     grip.style.top = 0;
    //     grip.style.right = 0;
    //     grip.style.bottom = 0;
    //     grip.style.width = "5px";
    //     grip.style.position = "absolute";
    //     grip.style.cursor = "col-resize";
    //     grip.addEventListener("mousedown", function (e) {
    //       thElm = th;
    //       startOffset = th.offsetWidth - e.pageX;
    //     });
    //
    //     th.appendChild(grip);
    //   }
    // );
    //
    // document.addEventListener("mousemove", function (e) {
    //   if (thElm) {
    //     thElm.style.width = startOffset + e.pageX + "px";
    //   }
    // });
    //
    // document.addEventListener("mouseup", function () {
    //   thElm = undefined;
    // });
  },
  computed: {
    computedSections: {
      get() {
        return this.sections;
      },
      set(data) {
        this[SET_SECTIONS](data);
      },
    },
    filterOrSearchActive() {
      return (
        this.settings.taskFilterParams["params[TaskName]"] ||
        this.selectedFilter
      );
    },
    ...mapState("tasks", ["sections", "tasks", "settings"]),
  },
  methods: {
    enableResize() {
      const remoteTable = document.querySelector("#main-tasks-table");
      var self = this;
      function setRowsWidth() {
        document.querySelectorAll("#main-tasks-table th").forEach((i) => {
          const colName = i.getAttribute("data-col-name");
          let colWidth = i.style.width;
          if (colWidth === "0px") colWidth = i.clientWidth + "px";
          // console.log("ресайзим", colWidth);
          self.settings.mainTableColsWidth[colName] = colWidth;
        });
      }
      const options = {
        liveDrag: true,
        draggingClass: "rangeDrag",
        gripInnerHtml: "<div class='rangeGrip'></div>",
        minWidth: 50,
        disabledColumns: [
          document.querySelectorAll("#main-tasks-table th").length - 1,
        ],
        resizeMode: "flex",
        onResize: function () {
          // var table = $(e.currentTarget); //reference to the resized table
          // alert('Table grip index: ' + $(e.target).parent().index());
          // const nodes = Array.prototype.slice.call(
          //   e.target.parentNode.parentNode.children
          // );
          // const index = nodes.indexOf(e.target.parentNode)
          setRowsWidth();
          // self.settings.asdas =
        },
      };
      if (!this.resizer) {
        this.resizer = new ColumnResizer(remoteTable, options);
        this.$nextTick(() => {
          // if (!self.settings.mainTableColsWidth.name) {
          setRowsWidth();
          // }
        });
      } else {
        this.resizer.reset(options);
      }
    },
    disableResize() {
      if (this.resizer) {
        this.resizer.reset({ disable: true });
        this.resizer = null;
      }
    },
    changeSectionOrder(items) {
      this[CHANGE_ORDER]({
        items: items.filter((i) => i.Id),
        apiMethod: "api/tasks/v2/TasksSections/updateOrder",
      });
    },
    ...mapActions("tasks", [
      CHANGE_ORDER,
      GET_TASKS,
      EDIT_TASK,
      SILENT_EDIT_TASK,
    ]),
    ...mapMutations("tasks", [
      SET_SECTIONS,
      SET_TASK_CHILDS,
      REFRESH_SECTIONS,
      SET_COMMON_MODULE_SELECTED_ITEMS,
      SET_COMMON_MODULE_SUBTASK,
      SET_COMMON_MODULE_POPUP,
    ]),
  },
  watch: {
    tasks(val, oldVal) {
      if (val.length !== oldVal.length) {
        // this.preparedTasks = this.tasks;
      }
    },
    group() {
      this.disableResize();
      // console.log("изменился group");
      this.$nextTick(function () {
        this.enableResize();
      });
    },
  },
  components: {
    TableRow,
    TableSection,
    draggable,
    GroupedTasksByFilterQuery,
  },
};
</script>

<style scoped></style>
