<template>
  <div class="notification-settings-table">
    <template v-if="settings">
      <div
        class="notification-settings-table__head notification-settings-table__row"
      >
        <div />
        <div class="notification-settings-table__title">Тип события</div>
        <div class="notification-settings-table__control-col">
          <svg width="17" height="16">
            <use
              xlink:href="/static/images/tasks/notification-settings-table-icons/sprite.svg#web-icon"
            ></use>
          </svg>
        </div>
        <div class="notification-settings-table__control-col">
          <svg width="12" height="16">
            <use
              xlink:href="/static/images/tasks/notification-settings-table-icons/sprite.svg#mobile-app-icon"
            ></use>
          </svg>
        </div>
        <div class="notification-settings-table__control-col">
          <img
            width="16"
            height="16"
            src="/static/images/tasks/notification-settings-table-icons/telegram-icon.png"
            alt="telegram-icon"
          />
        </div>
        <div class="notification-settings-table__control-col">@</div>
      </div>
      <div class="notification-settings-table__list">
        <NotificationSettingsTableRowParent
          v-for="(item, index) in settings"
          :key="index"
          :item="item"
        />
      </div>
    </template>
    <SimplePreloader v-else />
  </div>
</template>

<script>
import NotificationSettingsTableRowParent from "@/modules/tasks/components/ProfileSettingsPopup/NotificationSettingsTable/NotificationSettingsTableRowParent";
import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  name: "NotificationSettingsTable",
  data() {
    return {
      settings: null,
    };
  },
  created() {
    axios
      .get(`${API_ROOT}api/tasks/v2/TasksNotificationsGroupsSettings`)
      .then((res) => {
        this.settings = res.data.settings;
      });
  },
  components: {
    NotificationSettingsTableRowParent,
  },
};
</script>

<style scoped></style>
