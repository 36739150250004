<template>
  <div class="popup-image" :class="is_open ? 'popup-image--open' : ''">
    <div class="popup-image__close-zone" @click="closePopup(false)"></div>
    <div class="popup-image__image">
      <div class="popup__close" @click="closePopup(false)">
        <svg class="popup__close-icon">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/icon.svg#icon_icon-close"
          ></use>
        </svg>
      </div>

      <img
        v-if="popupImage"
        :src="popupImage"
        alt="popup image from user file"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as c from "@/store/common/constants";
export default {
  props: {
    is_open: {
      default: false,
    },
  },
  computed: {
    ...mapState("common", ["popupImage"]),
  },
  methods: {
    ...mapActions("common", { closePopup: c.TOGGLE_POPUP_IMAGE }),
  },
};
</script>

<style scoped lang="scss">
.popup-image {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.46);
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  /*transition: .3s;*/
  &__close-zone {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
  }
  &--open {
    z-index: 100;
    opacity: 1;
    pointer-events: all;
  }
  .popup__close-icon {
    fill: #fff;
  }
  .popup__close {
    border: 2px solid #fff;
    position: absolute;
    right: 50px;
    top: 50px;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: flex;
  }
  &__image {
    height: 80%;
    max-width: 80%;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      z-index: 1;
    }
  }
}
</style>
