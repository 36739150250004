<template>
  <div>
    <div class="project-view-item__wrapper">
      <div class="project-view-item__counter">
        <label
          class="checkbox checkbox_white table__checkbox js-index-checkbox task-row-count-checkbox"
        >
          <input
            class="checkbox__input main_checkbox_input"
            type="checkbox"
            :task-id="item.Id"
            name="row_checkbox_clients"
            @click.stop.capture="
              $emit('selectItem', $event, item, 'projectTable')
            "
            :class="{
              'checkbox__box--active':
                parentData.selectedItems &&
                parentData.selectedItems.find((x) => x.Id === item.Id),
            }"
          />
          <span class="checkbox__box main_checkbox_span table__checkbox--tasks">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
          <div class="task-row-count">{{ index + 1 }}</div>
        </label>
      </div>
      <div
        class="project-view-item"
        :style="{ backgroundColor: adjustLevelColor() }"
      >
        <div class="project-view-item__row">
          <div
            class="project-view-item__toggle-btn icon-button icon-button_shadow table__toggle"
            :class="{
              'project-view-item__toggle-btn--hidden': !(
                item.childs && item.childs.length
              ),
              'table__toggle--revert': rowIsOpen,
            }"
            @click="toggleTableRowOpenState"
          >
            <svg width="12" height="7">
              <use
                xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
              ></use>
            </svg>
          </div>
          <div class="project-view-item__name-wrapper">
            <div class="project-view-item__name-inner">
              <div class="project-view-item__name" @click="openDetailTask">
                {{ item.TaskName }}
                <span
                  class="project-view-item__lock-icon"
                  v-if="item.TaskAccess === '2'"
                >
                  <svg width="10" height="12">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/tasks/sprite.svg#lock-pink-icon"
                    ></use>
                  </svg>
                </span>
              </div>
              <div class="project-view-item__right-top-part">
                <div
                  class="project-view-item__finish-date-label"
                  :class="getLabelActivityClass()"
                  v-if="item.LastActivityModel"
                  :title="item.LastActivityModel.TimeCreate"
                >
                  <svg width="14" height="13">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#pulse-icon"
                    ></use>
                  </svg>
                  {{
                    item.LastActivityModel.TimeCreate | formatShortLocaleDate
                  }}
                </div>
                <div class="project-view-item__date-created">
                  Создан {{ item.TimeCreate | formatReplyDateWithoutTime }}
                </div>
              </div>
            </div>
            <div class="project-view-item__name-info">
              <div class="project-view-item__statuses" ref="portal-wrapper">
                <div class="project-view-item__statuses-label">Статус:</div>
                <div
                  class="project-view-item__status-btn"
                  @click="
                    checkUserTaskRight('change_status') &&
                      (setStatusBubbleOpen = true)
                  "
                >
                  <div
                    class="table__status-label status-label"
                    :style="{ backgroundColor: itemStatus.Color }"
                    v-if="itemStatus"
                  >
                    {{ itemStatus.Name }}
                  </div>
                  <div class="status-label--not-set" v-else>Указать</div>
                </div>

                <portal to="common">
                  <transition name="fade">
                    <StatusesListSearch
                      v-if="setStatusBubbleOpen"
                      :items="taskStatusesArray"
                      :selectedId="item.StatusId"
                      @choose="setProcessStatus"
                      @close="() => (setStatusBubbleOpen = false)"
                      v-portal="{ parent: $refs['portal-wrapper'] }"
                    />
                  </transition>
                </portal>

                <div class="project-view-item__tags">
                  <div class="project-view-item__statuses-label">Теги:</div>
                  <div class="task-labels" ref="portal-wrapper-tags-2">
                    <span
                      class="task-label"
                      v-for="item in sortTags(item.tags)"
                      :style="{ backgroundColor: item.Color }"
                      :key="item.Id"
                      >{{ item.TagName }}
                      <div
                        class="task-table-person__delete"
                        @click="deleteTag(item.Id)"
                      >
                        <svg focusable="false" viewBox="0 0 32 32">
                          <path
                            d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                          ></path>
                        </svg>
                      </div>
                    </span>
                    <div class="add-tag-btn" @click="addTagBubbleOpen = true">
                      +
                    </div>
                    <transition name="fade">
                      <TagsListSearch
                        v-if="addTagBubbleOpen"
                        :className="'task-bubble--tag'"
                        :taskTags="item.tags || []"
                        :task="item"
                        @choose="addTag"
                        @close="addTagBubbleOpen = false"
                        v-portal="{ parent: $refs['portal-wrapper-tags-2'] }"
                      />
                    </transition>
                  </div>
                </div>

                <!--            <div-->
                <!--              class="project-view-item__process-label"-->
                <!--              v-if="item.ProcessId"-->
                <!--              @click="-->
                <!--                checkUserTaskRight('attach_proccess_to_task') &&-->
                <!--                  (processesBubbleOpen = true)-->
                <!--              "-->
                <!--            >-->
                <!--              {{ getProcessName(item.ProcessId) }}-->
                <!--            </div>-->
                <!--            <transition name="fade">-->
                <!--              <ProcessesListSearch-->
                <!--                :taskId="item.Id"-->
                <!--                v-if="processesBubbleOpen"-->
                <!--                :selectedId="item.ProcessId"-->
                <!--                :className="'task-bubble&#45;&#45;templates'"-->
                <!--                @choose="setGroupProcess"-->
                <!--                @close="() => (processesBubbleOpen = false)"-->
                <!--              />-->
                <!--            </transition>-->
              </div>
            </div>
          </div>
        </div>
        <div class="project-view-item__row">
          <div class="project-view-item__view-toggle">
            <div
              class="task-row-view"
              @click="
                item.ProcessId && (viewTypeTab = viewTypeTab === 1 ? 2 : 1)
              "
              :class="{
                'task-row-view--no-process': !item.ProcessId,
              }"
              :title="!item.ProcessId && 'Нет активного процесса'"
            >
              <button
                class="task-row-view__btn"
                :class="{ 'task-row-view__btn--active': viewTypeTab === 1 }"
                title="Стандартный"
              >
                <svg width="16" height="16" style="transform: rotate(-90deg)">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                  ></use>
                </svg>
              </button>
              <button
                class="task-row-view__btn"
                :class="{ 'task-row-view__btn--active': viewTypeTab === 2 }"
                title="Канбан"
              >
                <svg width="16" height="16">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
          <div class="project-view-item__info">
            <div class="project-view-item__person project-view-item__info-item">
              <person :item="item" />
            </div>

            <div class="project-view-item__date project-view-item__info-item">
              <svg class="project-view-item__info-icon" width="16" height="17">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#calendar-icon"
                ></use>
              </svg>
              <finish-date
                :item="item"
                :rangeValue="item.TimeCreate | formatDateSimpleWithoutTime"
              />
            </div>
            <div
              class="project-view-item__progress project-view-item__info-item"
              v-if="allInnerTasksCount"
            >
              <svg class="project-view-item__info-icon" width="16" height="16">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#check-icon"
                ></use>
              </svg>
              <div class="task-progress-indicator">
                <div class="task-progress-indicator__count">
                  Выполнено: {{ allClosedInnerTasksCount }} из
                  {{ allInnerTasksCount }}
                </div>
                <div class="task-progress-indicator__bar">
                  <div
                    class="task-progress-indicator__line"
                    :style="{ width: allClosedInnerTasksCount + '%' }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="project-view-item__expired-childs" v-if="expiredChilds">
              <img
                class="project-view-item__expired-childs-icon"
                src="/static/images/tasks/icons/fire-icon.png"
                alt="#"
              />
              Просрочено: {{ expiredChilds }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-view-item__childs" v-if="rowIsOpen">
      <Kanban
        v-if="
          item.childs && taskActiveProcess && viewTypeTab === 2 && rowIsOpen
        "
        :process="taskActiveProcess"
        :tasks="item.childs"
        :createTaskFunction="() => $emit('createSubTask', item)"
      />
      <div class="inner-section-wrapper" v-else-if="viewTypeTab === 1">
        <TableSection
          :className="'task-table-row-inner'"
          v-for="(section, index) in computedSections"
          :key="'section' + section.Id"
          :section="section"
          :sectionTasks="section.tasks"
          :sections="computedSections"
          :selectedTaskParentData="selectedTaskParentData"
          :haveNextSection="index + 1 < computedSections.length"
          :parentData="parentData"
          :parentTask="item"
          :currentElement="currentElement"
          :level="level + 1"
          :draggableId="item.Id"
          :boundariesElement="null"
          @changeOrder="changeOrder"
          @setTasksByDraggable="
            (parentTask, tasks, section) =>
              $emit('setTasksByDraggable', parentTask, tasks, section)
          "
          @selectItem="($event, item) => $emit('selectItem', $event, item)"
          @createSubTask="(item) => $emit('createSubTask', item)"
          @shiftSelection="($event) => $emit('shiftSelection', $event)"
          @ctrlSelection="($event) => $emit('ctrlSelection', $event)"
        />
      </div>
    </div>
    <div class="row-spinner" v-if="preloader">
      <Spinner
        :noFadeIn="true"
        name="line-scale-pulse-out"
        color="#c9eeff"
        width="50"
        height="35"
      />
    </div>
  </div>
</template>
<script>
import common from "../../../../../mixins/tasks/common";
import * as _ from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import Person from "../TableComponents/Person";
import FinishDate from "../TableComponents/FinishDate";
import * as c from "../../../../../store/tasks/constants";
import { Helpers } from "../../../../../mixins/Helpers";
import TableProjectRow from "./TableProjectRow";
import Kanban from "../../Kanban/Kanban";
import TableRow from "@/modules/tasks/components/Tables/MainTable/TableRow";
import StatusesListSearch from "@/modules/tasks/components/CommonComponents/StatusesListSearch";
import ProcessesListSearch from "@/modules/tasks/components/CommonComponents/ProcessesListSearch";
import moment from "moment";
import TagsListSearch from "@/modules/tasks/components/CommonComponents/TagsListSearch/TagsListSearch";
import draggable from "vuedraggable";
import TableSection from "@/modules/tasks/components/Tables/MainTable/TableSection";
import {
  CHANGE_ORDER,
  SET_SECTIONS,
} from "../../../../../store/tasks/constants";

export default {
  name: "TableProjectRow",
  mixins: [common, Helpers],
  props: [
    "item",
    "className",
    "level",
    "index",
    "inDetailTask",
    "selectedTaskParentData",
    "parentData",
  ],
  data() {
    return {
      addTagBubbleOpen: false,
      addTagBubbleOpen2: false,
      showTagsListBubble: false,
      viewTypeTab: 1,
      showViewTypeTab: false,
      rowIsOpen: false,
      setStatusBubbleOpen: false,
      processesBubbleOpen: false,
      preloader: false,
      sections: [],
      currentElement: this,
    };
  },
  computed: {
    taskActiveProcess() {
      return _.find(this.processes, { Id: this.item.ProcessId });
    },
    getClosedChildTasks() {
      if (this.item.childs) {
        return _.filter(this.item.childs, ["Status", "2"]);
      }
      return false;
    },
    getClosedChildTasksPercentage() {
      return (this.getClosedChildTasks.length * 100) / this.item.childs.length;
    },
    taskStatusesArray() {
      const found = _.find(this.processes, ["Id", this.item.ParentProcessId]);
      return found ? found.statuses : [];
    },
    taskGroup() {
      return _.find(this.allGroups, ["Id", this.item.GroupId]);
    },
    itemStatus() {
      let found = null;
      _.each(this.processes, (i) => {
        if (!found) {
          found = _.find(i.statuses, ["Id", this.item.StatusId]);
        }
      });
      return found;
    },
    expiredChilds() {
      let count = 0;
      if (this.item.childs && this.item.childs.length) {
        this.item.childs.forEach((i) => {
          if (i.FinishDate && this.dateIsExpired(i.FinishTime, i.FinishDate)) {
            count++;
          }
        });
      }
      return count;
    },
    computedSections: {
      get() {
        return _.map(this.sections, (i) => {
          if (i.Name === "virtualSection") {
            i.tasks = _.filter(this.item.childs, ["SectionId", null]);
          } else {
            i.tasks = _.filter(this.item.childs, ["SectionId", i.Id]);
          }
          return i;
        });
      },
      set(data) {
        this[SET_SECTIONS](data);
      },
    },
    allInnerTasksCount() {
      return this.item.Counters.find((x) => x.CounterType === 2)?.CounterValue;
    },
    allClosedInnerTasksCount() {
      return this.item.Counters.find((x) => x.CounterType === 1)?.CounterValue;
    },
    ...mapState("tasks", [
      "tasks",
      "processes",
      "allGroups",
      "tags",
      // "sections",
    ]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    toggleTableRowOpenState() {
      this.rowIsOpen = !this.rowIsOpen;
      if (this.rowIsOpen) {
        this.getInnerRowInfo();
      }
    },
    openDetailTask() {
      let id = this.item.Id;
      if (this.selectedTaskParentData) {
        this.selectedTaskParentData.subtaskIdForOpen = id;
        window.history.pushState({}, document.title, "/tasks/" + id);
      } else {
        this.$router.push("/tasks/" + id);
      }
    },
    closeViewTab() {
      this.showViewTypeTab = false;
    },
    adjustLevelColor() {
      let color = "#F9F9F9",
        amount = -((this.level - 1) * 10);
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },
    setProcessStatus(id) {
      this.item.StatusId = id;
      const payload = {
        id: this.item.Id,
        data: {
          StatusId: id,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    setGroupProcess(id) {
      this.item.ProcessId = id;
      const payload = {
        id: this.item.Id,
        data: {
          ProcessId: id,
        },
      };
      this[c.EDIT_TASK](payload);
    },
    dateIsExpired(finishTime, finishDate) {
      finishDate = moment(finishDate);

      if (finishTime) {
        const hour = finishTime.split(":")[0];
        const minute = finishTime.split(":")[1];
        finishDate.set({ hour, minute });
      }

      const toDay = moment();
      return toDay > finishDate;
    },
    closeTagListBubble() {
      this.showTagsListBubble = false;
    },
    deleteTag(Id) {
      console.log(this.item.tags);
      this.item.tags = _.reject(this.item.tags, (i) => i.Id === Id);
      this[c.REMOVE_TAG_FROM_TASK]({ Id });
    },
    addTag(tagId) {
      if (!this.item.tags) this.item.tags = [];
      const foundTag = _.find(this.tags, ["Id", tagId]);
      if (!_.find(this.item.tags, ["TagId", tagId])) {
        this.item.tags.push(foundTag);

        this[c.ADD_TAG_TO_TASK]({
          data: {
            TagId: tagId,
            TaskId: this.item.Id,
          },
        }).then((data) => {
          const tag = { ...data, TagName: foundTag.TagName };
          this.item.tags.splice(this.item.tags.length - 1, 1, tag);
          // this.item.tags.push(tag);
        });
      }
    },
    sortTags(arr) {
      return _.orderBy(arr, ["TagName"], ["asc"]);
    },
    createTask() {
      this.parentData.subTask = this.item;
      this.parentData.taskCreatePopupOpen = true;
    },
    getLabelActivityClass() {
      if (
        this.item.childs &&
        this.item.childs.length &&
        !this.getClosedChildTasks.length
      ) {
        const daysPast = moment().diff(
          moment(this.item.LastActivityModel.TimeCreate),
          "days"
        );
        if (daysPast <= 7) {
          return "project-view-item__finish-date-label--green";
        }
        if (daysPast > 7 && daysPast < 30) {
          return "project-view-item__finish-date-label--yellow";
        }
        return "project-view-item__finish-date-label--red";
      }
    },
    getInnerRowInfo() {
      const self = this;
      let needReload = false;

      this.preloader = true;

      function getSection() {
        self.preloader = true;
        self[c.GET_PARENT_TASK_SECTIONS](self.item.Id)
          .then((res) => {
            needReload = res.needReload;
            if (res.needReload) {
              getSection();
            } else {
              self.sections = res;
            }
          })
          .finally(() => {
            if (!needReload) {
              self.preloader = false;
            }
          });
      }

      this[c.GET_CHILD_TASKS_DETAIL]({ task: this.item }).then(() => {
        getSection();
      });
    },
    changeOrder(items) {
      this[CHANGE_ORDER]({
        items,
        apiMethod: "api/tasks/v2/tasks/updateOrder",
      });
    },
    ...mapActions("tasks", [
      c.REMOVE_TAG_FROM_TASK,
      c.ADD_TAG_TO_TASK,
      c.GET_CHILD_TASKS_DETAIL,
      c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY,
      c.EDIT_TASK,
      c.GET_PARENT_TASK_SECTIONS,
      SET_SECTIONS,
      CHANGE_ORDER,
    ]),
  },
  components: {
    Person,
    FinishDate,
    // eslint-disable-next-line vue/no-unused-components
    TableProjectRow,
    // eslint-disable-next-line vue/no-unused-components
    TableRow,
    Kanban,
    StatusesListSearch,
    // eslint-disable-next-line vue/no-unused-components
    ProcessesListSearch,
    TagsListSearch,
    // eslint-disable-next-line vue/no-unused-components
    draggable,
    TableSection,
  },
};
</script>

<style scoped></style>
