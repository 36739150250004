import { mapActions } from "vuex";
import * as c from "@/store/common/constants";
import * as supportTypes from "@/store/support/constants";

export default {
  props: {
    is_open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("common", [c.TOGGLE_POPUP_LOCAL]),
    ...mapActions("clientSupport", [
      supportTypes.SET_SELECTED_REQUEST,
      supportTypes.SET_REQUEST_MESSAGES,
    ]),
    hideThisPopup(module, popup) {
      this[c.TOGGLE_POPUP_LOCAL]({ module, popup, value: false });
      if (module === "client" && popup === "newRequest") {
        this.name = "";
        this.category = { value: "", id: "" };
        this.desc = "";
        this.files = [];
        this.isDropdownOpen = false;
      }
      if (module === "client" && popup === "requestWindow") {
        this[supportTypes.SET_SELECTED_REQUEST]({
          request: false,
          requestId: false,
        });
        this[supportTypes.SET_REQUEST_MESSAGES]([]);
      }
    },
    hidePopupByClickWrap(event, module, popup) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.hideThisPopup(module, popup);
      }
    },
  },
};
