<template>
  <div
    class="tasks-module-wrapper module-wrapper"
    @click="controlClick($event)"
  >
    <Sidebar :headerControlElement="() => $refs['task-control-header']" />
    <div class="tasks-module-wrapper__inner">
      <task-control-header
        :filterHaveProcessCondition="filterHaveProcessCondition"
        ref="task-control-header"
      />

      <div class="task-container">
        <Kanban
          v-if="
            tasks.length &&
            groupActiveProcess &&
            commonModuleSettings.viewType === 'kanban'
          "
          :createTaskFunction="
            () => (commonModuleSettings.taskCreatePopupOpen = true)
          "
          :process="groupActiveProcess"
          :tasks="tasks"
        />
        <MainTableProjectView
          v-else-if="commonModuleSettings.viewType === 'project'"
        />
        <MainTable
          v-else
          ref="mainTable"
          :filterHaveProcessCondition="filterHaveProcessCondition"
        />
      </div>
      <transition name="popupSlide">
        <group-settings
          v-if="commonModuleSettings.groupSettingsPopupOpen"
          @onClosePopup="onClosePopup('groupSettingsPopupOpen')"
        />
      </transition>
      <transition name="popupSlide">
        <TaskCreateForm
          v-if="commonModuleSettings.taskCreatePopupOpen"
          :parentTask="commonModuleSettings.subTask"
          :section="commonModuleSettings.sectionForTaskCreate"
          :open="commonModuleSettings.taskCreatePopupOpen"
          @onClosePopup="onClosePopup('taskCreatePopupOpen')"
        />
      </transition>
      <transition name="popupSlide">
        <router-view />
      </transition>
      <transition name="popupSlide">
        <notifications-popup
          v-if="commonModuleSettings.notificationsPopupOpen"
          @onClosePopup="onClosePopup('notificationsPopupOpen')"
        />
      </transition>
      <transition name="popupSlide">
        <FolderActivity
          v-if="commonModuleSettings.folderActivityPopupOpen"
          @onClosePopup="onClosePopup('folderActivityPopupOpen')"
        />
      </transition>
      <transition name="popupSlide">
        <ProfileSettingsPopup
          v-if="commonModuleSettings.profileSettingsPopupOpen"
          :isOpen="commonModuleSettings.profileSettingsPopupOpen"
          @onClosePopup="onClosePopup('profileSettingsPopupOpen')"
        />
      </transition>

      <transition name="popupSlide">
        <AutomationAttachControl
          :tasks="commonModuleSettings.automationAttachItems"
          v-if="commonModuleSettings.automationAttachItems.length"
          @onClosePopup="commonModuleSettings.automationAttachItems = []"
        />
      </transition>

      <vue-gallery-slideshow
        :images="gallery.images"
        :index="gallery.index"
        @close="gallery.index = null"
      ></vue-gallery-slideshow>
      <text-subscribe-bubble-select />
      <PdfAndDocsViewer
        v-if="settings.docFileToView"
        :src="settings.docFileToView"
        :id="settings.docFileIdToView"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import GroupSettings from "./components/GroupSettings";
import TaskCreateForm from "./components/TaskCreateForm";
import Notifications from "./components/Notifications";
import ProfileSettingsPopup from "./components/ProfileSettingsPopup";
import MainTable from "./components/Tables/MainTable";
import * as c from "../../store/tasks/constants";
import common from "../../mixins/tasks/common";
import TaskControlHeader from "./components/Header/TaskControlHeader";
import TextSubscribeBubbleSelect from "./components/CommonComponents/TextSubscribeBubbleSelect";
import VueGallerySlideshow from "vue-gallery-slideshow";
import { TASKS_MODULE_RIGHT } from "../../store/sales/constants";
import { Helpers } from "../../mixins/Helpers";
import Sidebar from "./components/Sidebar/Sidebar";
import Kanban from "./components/Kanban/Kanban";
import MainTableProjectView from "./components/Tables/MainTableProjectView/MainTableProjectView";
import AutomationAttachControl from "./components/Automation/AutomationAttachControl/AutomationAttachControl";
import {
  GET_CURRENT_USER_SETTINGS,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_POPUP,
  SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE,
  SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY,
  SET_COMMON_MODULE_SORTS,
  SET_COMMON_MODULE_SUBTASK,
  SET_LOCAL_STORAGE_SETTINGS,
  SET_TABLE_FILTER,
} from "../../store/tasks/constants";
import { SET_COMMON_LOCAL_STORAGE_SETTINGS } from "../../store/tasks/constants";
import PdfAndDocsViewer from "@/modules/tasks/components/CommonComponents/PdfAndDocsViewer";
import FolderActivity from "@/modules/tasks/components/FolderActivity/FolderActivity";

export default {
  mixins: [Helpers, common],
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      if (!this.checkUserRight(TASKS_MODULE_RIGHT)) {
        window.location.href = "/";
      }
    }, 2000);
    this[SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY](
      this.$route.query["filter-hash"]
    );
    const localStorageSettings = this.$localStorage.get("localStorageSettings");
    if (localStorageSettings)
      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: JSON.parse(localStorageSettings),
        init: true,
      });
    const commonLocalStorageSettings = this.$localStorage.get(
      "commonLocalStorageSettings"
    );
    if (commonLocalStorageSettings)
      this[SET_COMMON_LOCAL_STORAGE_SETTINGS](
        JSON.parse(commonLocalStorageSettings)
      );

    const storedCompletedGroupIdsArr = this.$localStorage.get(
      "hideCompletedTasks"
    );
    if (storedCompletedGroupIdsArr) {
      this.settings.hideCompletedTasks = storedCompletedGroupIdsArr === "true";
    }
    let localSettings = commonLocalStorageSettings
      ? JSON.parse(commonLocalStorageSettings)
      : false;
    this[GET_CURRENT_USER_SETTINGS]();
    this[c.GET_USER_TASKS_LIST](true);
    this[c.GET_GROUPS]();

    if (this.commonModuleSettings.sharedFilterInQuery) {
      this[c.GET_TASKS_BY_USER_FILTER]({
        id: this.commonModuleSettings.sharedFilterInQuery,
      }).then((res) => {
        this[SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY](res.data.filter);
      });
      this[SET_TABLE_FILTER](this.commonModuleSettings.sharedFilterInQuery);
    } else if (localSettings) {
      if (localSettings.queryType === "group") {
        if (localSettings.model) {
          this[c.SET_CURRENT_GROUP]({
            group: localSettings.model,
            forceUpdateCurrentGroup: true,
          });
        }
      } else if (localSettings.queryType === "userFilter") {
        this[c.GET_TASKS_BY_USER_FILTER]({ id: localSettings.queryId });
      }
    }

    this[c.GET_TAG_GROUPS]();
    this[c.GET_TAGS]();
    this[c.GET_STANDART_FILTERS]().then(() => {
      if (localSettings.queryType === "standartFilter") {
        this[c.GET_TASKS_BY_STANDART_FILTER]({ type: localSettings.queryId });
      }
    });
    this[c.GET_PROCESSES]();
    this[c.GET_USER_TASKS_MODULE_RIGHTS](this.$router);
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".task-plus-label")) {
        document.querySelectorAll(".task-plus-label").forEach((i) => {
          i.classList.remove("task-plus-label--close");
        });
      }
    });
    this.$refs["task-control-header"].unselectTasks();
  },
  computed: {
    filterHaveProcessCondition() {
      if (this.selectedFilter) {
        try {
          if (this.getUserFilterIfCurrentFilterIs) {
            // TODO разобраться с этим непотребством
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.settings.processFromFilter = this.getUserFilterIfCurrentFilterIs.FilterParams[10];
            return this.getUserFilterIfCurrentFilterIs.FilterParams[10];
          } else if (this.flashFilterData) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.settings.processFromFilter = this.flashFilterData[10];
            return this.flashFilterData[10];
          }
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    ...mapState("tasks", [
      "gallery",
      "settings",
      "tasks",
      "localStorageSettings",
      "commonLocalStorageSettings",
      "allUserFilters",
      "commonModuleSettings",
    ]),
    ...mapGetters("tasks", [
      "group",
      "groupActiveProcess",
      "getUserFilterIfCurrentFilterIs",
      "selectedFilter",
      "flashFilterData",
    ]),
  },
  methods: {
    onClosePopup(popup) {
      this[SET_COMMON_MODULE_POPUP]({ popup, value: false });
      this[SET_COMMON_MODULE_SUBTASK](null);
      this[SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE](null);
    },
    controlClick(e) {
      if (
        e.target.tagName === "A" &&
        e.target.getAttribute("href").includes("userId=")
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    ...mapActions("tasks", [
      c.GET_GROUPS,
      c.GET_TASKS,
      c.DELETE_TASK,
      c.GET_NOTIFICATIONS,
      c.GET_TAGS,
      c.GET_TAG_GROUPS,
      c.GET_STANDART_FILTERS,
      c.GET_USER_FILTERS,
      c.GET_PROCESSES,
      c.GET_USER_FAVORITES,
      c.GET_USER_TASKS_MODULE_RIGHTS,
      c.GET_CUSTOM_FILTER_PARAMS,
      c.GET_TASKS_BY_STANDART_FILTER,
      c.GET_TASKS_BY_USER_FILTER,
      c.GET_SECTIONS,
      c.GET_USER_TASKS_LIST,
      GET_CURRENT_USER_SETTINGS,
      c.SET_CURRENT_GROUP,
    ]),
    ...mapMutations("tasks", [
      SET_LOCAL_STORAGE_SETTINGS,
      SET_COMMON_LOCAL_STORAGE_SETTINGS,
      SET_TABLE_FILTER,
      SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY,
      SET_COMMON_MODULE_PAGE,
      SET_COMMON_MODULE_SORTS,
      SET_COMMON_MODULE_SUBTASK,
      SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE,
      SET_COMMON_MODULE_POPUP,
    ]),
  },
  watch: {
    "commonModuleSettings.viewType"(val) {
      if (!val) {
        this.$nextTick(function () {
          this.$refs.mainTable.disableResize();
          this.$nextTick(function () {
            this.$refs.mainTable.enableResize();
          });
        });
      }
    },
  },
  components: {
    TaskControlHeader,
    GroupSettings,
    TaskCreateForm,
    MainTable,
    "notifications-popup": Notifications,
    TextSubscribeBubbleSelect,
    VueGallerySlideshow,
    Sidebar,
    ProfileSettingsPopup,
    Kanban,
    MainTableProjectView,
    AutomationAttachControl,
    PdfAndDocsViewer,
    FolderActivity,
  },
};
</script>
