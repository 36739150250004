<template>
  <div class="popup__inner-cont">
    <div
      class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
      @click="openModal()"
    >
      <Print_19 />
    </div>
    <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
      <print_19 />
    </magnific-popup-modal>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('Objects')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div
          class="new-changes-block"
          :key="index"
          v-for="(item, index) in getOptionsChangesArray('Objects')"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <span style="display: none">{{
              (old = findOldOption("Objects", item.randomId))
            }}</span>
            <p class="icons">
              {{ old.name }},
              <span><img :src="getImageSrc(old.img)" alt="" /></span>
            </p>
            <br />
            <p>{{ old.desc }}</p>
            <br />
            <p>{{ old.address }}</p>
            <br />
            <p>{{ old.equipment }}</p>
            <br />
            <p>{{ old.works }}</p>
            <br />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p class="icons">
            {{ item.name }}
            <span><img :src="getImageSrc(item.img)" alt="" /></span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
          <p>{{ item.address }}</p>
          <br />
          <p>{{ item.equipment }}</p>
          <br />
          <p>{{ item.works }}</p>
          <br />
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Objects', 'removed')"
          :key="'1' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p class="icons">
              {{ item.name }}
              <span><img :src="getImageSrc(item.img)" alt="" /></span>
            </p>
            <br />
            <p>{{ item.desc }}</p>
            <p>{{ item.address }}</p>
            <br />
            <p>{{ item.equipment }}</p>
            <br />
            <p>{{ item.works }}</p>
            <br />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>Удалено</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Objects', 'added')"
          :key="'k' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>-</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p class="icons">
            {{ item.name }}
            <span><img :src="getImageSrc(item.img)" alt="" /></span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
          <p>{{ item.address }}</p>
          <br />
          <p>{{ item.equipment }}</p>
          <br />
          <p>{{ item.works }}</p>
          <br />
        </div>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div class="pdf-settings__cont scroll-style">
        <div class="key-objects">
          <div class="actions-header">
            <div class="actions-header__col actions-header__col_flexible">
              <div
                class="search-bar search-bar_white actions-header__search-bar"
              >
                <input
                  class="search-bar__input"
                  type="search"
                  placeholder="Название объекта"
                  v-model="searchInput"
                />
                <div
                  class="form-filter__btn"
                  :class="filterBtnClassName"
                  @click="filterOpen = !filterOpen"
                >
                  <!--            <span></span>-->
                  фильтр
                </div>

                <div
                  class="actions-header__filter-wrap"
                  v-if="filterOpen"
                  v-click-outside="closeFilter"
                >
                  <div
                    class="cp__page-filter page-filter portfolio__page-filter--visible"
                  >
                    <div
                      class="page-filter__list"
                      :class="{
                        'page-filter__list--all-selected': !selectedCities.length,
                      }"
                    >
                      <div
                        class="page-filter__item page-filter__item--all"
                        :class="{
                          'page-filter__item--selected': !selectedCities.length,
                        }"
                        @click="selectedCities = []"
                      >
                        <div
                          class="checkbox checkbox_white table__checkbox js-index-checkbox"
                        >
                          <input
                            class="checkbox__input main_checkbox_input"
                            type="checkbox"
                            name="row_checkbox_clients"
                          />
                          <div class="checkbox__box main_checkbox_span">
                            <svg class="checkbox__icon">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-check"
                              ></use>
                            </svg>
                          </div>
                        </div>
                        <div class="page-filter__item-name">Все города</div>
                      </div>
                      <div
                        class="page-filter__item"
                        v-for="item in cities"
                        :key="item.Id"
                        @click="addToArray(item, 'selectedCities')"
                        :class="{
                          'page-filter__item--selected': selectedCities.includes(
                            item.Id
                          ),
                        }"
                      >
                        <div
                          class="checkbox checkbox_white table__checkbox js-index-checkbox"
                        >
                          <input
                            class="checkbox__input main_checkbox_input"
                            type="checkbox"
                            name="row_checkbox_clients"
                          />
                          <div class="checkbox__box main_checkbox_span">
                            <svg class="checkbox__icon">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-check"
                              ></use>
                            </svg>
                          </div>
                        </div>
                        <div class="page-filter__item-name">
                          {{ item.Name }}
                        </div>
                      </div>
                    </div>
                    <div class="page-filter__control">
                      <div
                        class="page-filter__btn page-filter__btn--no-wrap page-filter__btn--cancel"
                        @click="selectedCities = []"
                      >
                        Сбросить
                      </div>
                    </div>
                  </div>

                  <div
                    class="cp__page-filter page-filter portfolio__page-filter--visible"
                  >
                    <div
                      class="page-filter__list"
                      :class="{
                        'page-filter__list--all-selected': !selectedTypes.length,
                      }"
                    >
                      <div
                        class="page-filter__item page-filter__item--all"
                        :class="{
                          'page-filter__item--selected': !selectedTypes.length,
                        }"
                        @click="selectedTypes = []"
                      >
                        <div
                          class="checkbox checkbox_white table__checkbox js-index-checkbox"
                        >
                          <input
                            class="checkbox__input main_checkbox_input"
                            type="checkbox"
                            name="row_checkbox_clients"
                          />
                          <div class="checkbox__box main_checkbox_span">
                            <svg class="checkbox__icon">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-check"
                              ></use>
                            </svg>
                          </div>
                        </div>
                        <div class="page-filter__item-name">Все типы</div>
                      </div>
                      <div
                        class="page-filter__item"
                        v-for="item in OBJECT_TYPES"
                        :key="item.Id"
                        @click="addToArray(item, 'selectedTypes')"
                        :class="{
                          'page-filter__item--selected': selectedTypes.includes(
                            item.Id
                          ),
                        }"
                      >
                        <div
                          class="checkbox checkbox_white table__checkbox js-index-checkbox"
                        >
                          <input
                            class="checkbox__input main_checkbox_input"
                            type="checkbox"
                            name="row_checkbox_clients"
                          />
                          <div class="checkbox__box main_checkbox_span">
                            <svg class="checkbox__icon">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-check"
                              ></use>
                            </svg>
                          </div>
                        </div>
                        <div class="page-filter__item-name">
                          {{ item.Name }}
                        </div>
                      </div>
                    </div>
                    <div class="page-filter__control">
                      <div
                        class="page-filter__btn page-filter__btn--no-wrap page-filter__btn--cancel"
                        @click="selectedTypes = []"
                      >
                        Сбросить
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="key-objects__item"
            v-for="(item, index) in filteredObjects"
            :key="index"
          >
            <label
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                name="row_checkbox_clients"
                @click.prevent="selectObject(item)"
                :class="setObjectClass(item.Id)"
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </label>
            <div class="key-objects__img">
              <img :src="commercialSiteUrl + item.ImageLink" />
            </div>
            <div class="key-objects__cont">
              <div class="key-objects__name">{{ item.Name }}</div>
              <div class="key-objects__object-address">
                {{ item.CityName }}. {{ item.Address }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(18)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(20)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_19 from "../prints/print_19";
import * as _ from "lodash";
import { OBJECT_TYPES } from "../../../../../mixins/site/CommercialSiteSettings";
import { Helpers } from "../../../../../mixins/Helpers";

export default {
  mixins: [SalesHelpers, Helpers],
  data() {
    return {
      searchInput: "",
      OBJECT_TYPES,
      filterOpen: false,
      selectedCities: [],
      selectedTypes: [],
    };
  },
  created() {
    if (!this.offerObject.json.Objects) {
      this.offerObject.json.Objects = [];
      // this.offerObject.json.Objects = copy(this.settingsObject.json.objects[this.offerObject.json.ObjectType])
    }
  },
  computed: {
    filteredObjects() {
      return this.examplesObjects.filter((i) => {
        const re = new RegExp(this.searchInput, "gi");
        let filterByFieldsAllow = true;
        if (this.selectedCities.length) {
          filterByFieldsAllow = this.selectedCities.includes(i.CityId);
        }
        if (this.selectedTypes.length && filterByFieldsAllow) {
          filterByFieldsAllow = this.selectedTypes.includes(+i.ObjectType);
        }
        return re.test(i.Name) && filterByFieldsAllow;
      });
    },
    filterBtnClassName() {
      if (
        this.filterOpen ||
        this.selectedCities.length ||
        this.selectedTypes.length
      ) {
        return "form-filter__btn--active";
      }
      return "";
    },
    ...mapState("sales", [
      "offerObject",
      "examplesObjects",
      "commercialSiteUrl",
    ]),
    ...mapState("site", ["cities"]),
  },
  methods: {
    disableOption(item) {
      item.disabled = item.disabled === void 0 ? true : !item.disabled;
      this.offerObject.json.Objects = _.map(
        this.offerObject.json.Objects,
        (i) => i
      );
    },
    selectObject(item) {
      if (!_.find(this.offerObject.json.Objects, ["Id", item.Id])) {
        this.offerObject.json.Objects.push(item);
      } else {
        this.offerObject.json.Objects = _.reject(
          this.offerObject.json.Objects,
          ["Id", item.Id]
        );
      }
    },
    setObjectClass(Id) {
      if (_.find(this.offerObject.json.Objects, { Id })) {
        return "checkbox__box--active";
      }
    },
    addToArray(item, array) {
      if (this[array].includes(item.Id)) {
        this[array].splice(this[array].indexOf(item.Id), 1);
      } else {
        this[array].push(item.Id);
      }
    },
    closeFilter() {
      this.filterOpen = false;
    },
  },
  components: { Print_19 },
};
</script>
