<template>
  <div
    class="task-create-form__row task-create-form__row--files task-create-form__row--show-all"
  >
    <label class="task-create-form__row-left">
      <input
        type="file"
        hidden
        @change="filesChange($event.target.files, $event)"
        multiple
      />
      <i class="task-form-icon task-form-icon--9"></i>
    </label>
    <div class="task-create-form__row-right">
      <label
        class="files-dragzone"
        :class="{ 'files-dragzone--dragover': filesDragZoneShow }"
        @dragover.prevent="showDragZone()"
        @dragleave="hideDragZone"
        @drop.prevent="
          (e) => {
            filesChange(e.dataTransfer.files, e);
            hideDragZone();
          }
        "
      >
        <input
          type="file"
          hidden
          @change="filesChange($event.target.files, $event)"
          multiple
        />
        <svg
          width="39"
          height="32"
          class="files-dragzone__icon"
          v-if="!files.length"
        >
          <use xlink:href="/static/images/tasks/sprite.svg#upload-icon"></use>
        </svg>
        <div class="task-popup-files">
          <div
            class="sup-chat-files__item"
            v-for="(item, index) in files"
            :key="index"
          >
            <img
              :src="item.file ? item.Link : getFileSrc(item.Link)"
              v-if="fileIsImage(item.file ? item.Name : item.Link)"
              @click.prevent="showImagePopup(item.Link)"
            />
            <a
              class="sup-chat-files__item-link"
              download
              v-else
              @click.prevent="
                downloadURI(getFileSrc(item.Link), item.Name, item.Id)
              "
              :href="getFileSrc(item.Link)"
            >
              <img
                :src="
                  getNonImageTypeFileIcon(item.file ? item.Name : item.Link)
                "
              />
              <p>{{ item.Name }}</p>
            </a>

            <div
              class="sup-chat-files__item-remove"
              @click.prevent="deleteFileFromTask(item.Id, index)"
            >
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
        </div>
      </label>
    </div>
    <!--    <SwitcherForHideField-->
    <!--      @saveAfterEdit="$emit('saveAfterEdit')"-->
    <!--      :form="form"-->
    <!--      :field="'Files'"-->
    <!--    />-->
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { API_ROOT } from "../../../../../config";
import * as _ from "lodash";
import SwitcherForHideField from "@/modules/tasks/components/TaskCreateForm/Inputs/SwitcherForHideField";
import { showAlert } from "@/utils";

export default {
  mixins: [common],
  props: ["form", "taskFilesListElement", "template", "taskId"],
  data() {
    return {
      filesDragZoneShow: false,
      preloader: false,
    };
  },
  created() {},
  computed: {
    files() {
      return _.map(
        _.filter(this.form.Files, (i) => !i.SignDocs),
        (i) => {
          if (typeof i === "string") {
            i = { Name: "some-image.jpg", Link: API_ROOT + i, SignDocs: 0 };
          }
          return i;
        }
      );
    },
  },
  methods: {
    filesChange(fileList, e) {
      if (this.preloader) {
        e.target.value = "";
        showAlert("error", "", "Идет загрузка файлов, пожалуйста подождите");
        return;
      }
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.form.Files.push({
          file: fileList[x],
          Name: fileList[x].name,
          Link: window.URL.createObjectURL(fileList[x]),
          Id: Math.random().toString(36).substring(7),
          SignDocs: 0,
        });
      });

      const formData = new FormData();
      formData.append("SignDocs", 0);
      formData.append("TaskId", this.taskId);
      if (this.form.Files.length) {
        _.each(this.form.Files, (i) => {
          if (i.file) {
            formData.append("Files[]", i.file, i.file.name);
          }
        });
      }
      this.preloader = true;
      this.$emit(
        "saveAfterEdit",
        formData,
        (files, tmp, callback = () => {}) => {
          this.form.Files = _.reject(this.form.Files, (i) => i.file);
          if (files) {
            if (tmp) {
              this.form.Files.push(...files);
            } else {
              this.form.Files = files;
            }
            callback();
          }
          this.preloader = false;
        },
        "Files"
      );
    },
    deleteFileFromTask(Id, index) {
      if (Id) {
        const filesCopy = [...this.form.Files];
        this.form.Files = _.reject(this.form.Files, { Id });
        this.$emit("removeFile", Id, () => (this.form.Files = filesCopy));
      } else {
        this.form.Files.splice(index, 1);
      }
    },
    showDragZone() {
      this.filesDragZoneShow = true;
    },
    hideDragZone() {
      this.filesDragZoneShow = false;
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SwitcherForHideField,
  },
};
</script>

<style scoped>
.task-create-form__row-left {
  cursor: pointer;
}
</style>
