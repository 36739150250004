<template>
  <div class="popup__inner-cont">
    <div
      class="pdf-cont-scroll pdf-cont-scheme pdf-cont--ml-s scroll-style"
      @click="openModal()"
    >
      <Print_4 />
    </div>
    <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
      <Print_4 />
    </magnific-popup-modal>
    <div class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__cont--scroll pdf-settings__cont--total scroll-style"
      >
        <div class="pdf-settings__inner scheme">
          <div class="scheme__label" v-if="offerObject.json.Schemes.length">
            Файлы
          </div>

          <div
            class="scheme__img"
            v-for="(item, index) in offerObject.json.Schemes"
            :key="index"
          >
            <img
              v-if="imageBlobCheck('builder', 'schemes', item.id)"
              :src="item.blob"
              alt=""
            />
            <img v-else :src="getImageOfferSrc(item.img)" alt="" />
            <div class="table__col table__col-btn">
              <div class="table__text-block" @click="remove(item.id)">
                <span class="remove-icon"></span>
              </div>
            </div>
          </div>
          <label class="btn btn__white">
            <input type="file" @change="filesChange($event.target)" hidden />
            <template v-if="offerObject.json.Schemes.length"
              >Добавить еще</template
            >
            <template v-else>Добавить схему</template>
          </label>
        </div>
      </div>
      <div class="pdf-settings__next">
        <label class="p-settings__total">
          <div class="p-settings__check">
            <div
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                @click.prevent="
                  offerObject.json.passPages.schemes = !offerObject.json
                    .passPages.schemes
                "
                :class="
                  offerObject.json.passPages.schemes
                    ? 'checkbox__box--active'
                    : ''
                "
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </div>
          </div>
          <div class="col-auto">Не добавлять страницу в КП</div>
        </label>
        <div class="pdf-settings__next pdf-settings__btns">
          <button
            class="btn-full btn-full__gray"
            @click="changeStep(offerObject.json.passPages.tasks_page ? 3 : 3.1)"
          >
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(5)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_4 from "../prints/print_4";
import { scrollTo } from "@/utils/scrollTo";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    filesChange(input) {
      const fileList = input.files;
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        const id = this.offerObject.json.Schemes.length + 1;
        const blob = window.URL.createObjectURL(fileList[0]);

        this.offerObject.json.Schemes.push({
          id: id,
          img: fileList[0].name,
          blob,
        });
        this.offerObject.images.schemes.push({ id, file: fileList[0] });
        this.offerObject.blobImages.schemes.push({ id, file: fileList[0] });

        input.value = null;

        const printWindow = document.querySelector("#schemes-wrap");
        setTimeout(() => {
          // console.log(document.querySelector('.pdf-cont-scroll'), printWindow)
          scrollTo(
            document.querySelector(".pdf-cont-scheme"),
            printWindow.scrollHeight,
            1000
          );
          scrollTo(
            document.querySelector(".pdf-settings__cont--scroll"),
            document.querySelector(".pdf-settings__inner").scrollHeight,
            1000
          );
        }, 100);
      }
    },
    remove(id) {
      // console.log(id)
      this.offerObject.json.Schemes = _.reject(this.offerObject.json.Schemes, {
        id,
      });
      this.offerObject.images.schemes = _.reject(
        this.offerObject.images.schemes,
        { id }
      );
      this.offerObject.blobImages.schemes = _.reject(
        this.offerObject.blobImages.schemes,
        { id }
      );

      this.offerObject.images.schemes = _.each(
        this.offerObject.images.schemes,
        (item, index) => {
          item.id = index + 1;
          return item;
        }
      );
      this.offerObject.blobImages.schemes = _.each(
        this.offerObject.blobImages.schemes,
        (item, index) => {
          item.id = index + 1;
          return item;
        }
      );
      this.offerObject.json.Schemes = _.each(
        this.offerObject.json.Schemes,
        (item, index) => {
          item.id = index + 1;
          return item;
        }
      );
    },
  },
  components: { Print_4 },
};
</script>
