<template>
  <div class="automation-statuses" ref="portal-wrapper">
    <div class="automation-statuses__name-wrap">
      <div class="automation-select-text--no-selectable" v-if="preplaceholder">
        {{ preplaceholder }}
      </div>
      <div
        class="automation-select-text"
        :class="{
          'automation-select-text--have-value': value,
          'automation-select-text--disabled': disabled,
        }"
        @click="!disabled && (open = !open)"
      >
        {{ name }}
      </div>
    </div>
    <portal to="no-multiple-portal" v-if="open">
      <transition name="fade">
        <SimpleSelectList
          v-if="portalOpen"
          :items="items"
          @choose="(id) => $emit('choose', id)"
          @close="open = false"
          v-portal="{ parent: $refs['portal-wrapper'] }"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import { Helpers } from "../../../../../mixins/Helpers";
import { capitalize } from "../../../../../utils";
import * as _ from "lodash";
import SimpleSelectList from "@/modules/tasks/components/CommonComponents/SimpleSelectList";

export default {
  name: "AutomationInput",
  mixins: [Helpers],
  props: [
    "value",
    "items",
    "placeholder",
    "preplaceholder",
    "disabled",
    "capitalizeWord",
  ],
  data() {
    return {
      open: false,
      portalOpen: false,
    };
  },
  computed: {
    name() {
      if (this.selected) {
        if (this.capitalizeWord)
          return capitalize(this.selected.Name.toLowerCase());
        return this.selected.Name.toLowerCase();
      } else {
        const val = this.placeholder ? this.placeholder : "выбрать";
        if (this.capitalizeWord) {
          return capitalize(val.toLowerCase());
        }
        return val;
      }
    },
    selected() {
      return _.find(this.items, ["Id", this.value]);
    },
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.portalOpen = true;
        });
      } else {
        this.portalOpen = false;
      }
    },
  },
  components: {
    SimpleSelectList,
  },
};
</script>

<style scoped></style>
