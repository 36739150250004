<template>
  <div class="print print--16">
    <div class="print__cont">
      <div class="flex-wrap">
        <div class="col-auto">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
        </div>
        <div class="col-auto ml-auto">
          <div class="print__header-title">
            Сервисное<br />
            обслуживание
          </div>
        </div>
      </div>
      <div
        class="print-table print-table--service"
        :class="getServiceTypeTableClass"
      >
        <div class="print-table__header">
          <div class="print-table__th col">Вид работ</div>
          <div class="print-table__th col-cell">
            Тех <br />
            поддержка
          </div>
          <div class="print-table__th col-cell">
            Стандартное <br />
            т.о.
          </div>
          <div class="print-table__th col-cell">
            Эксплуатация <br />
            12 часов
          </div>
          <div class="print-table__th col-cell">
            Эксплуатация <br />
            24 часа
          </div>
        </div>
        <div class="print-table__inner">
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Ежемесячная техническая поддержка
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Комплект ЗИП под объект на складе RPS
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">Комплект ЗИП на объекте</div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Онлайн поддержка на рабочей станции
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Поддержание работоспособности системы
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Отслеживание состояния оборудования
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Прием голосовых вызовов с устройств
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Удаленная работа с клиентами
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Формирование и предоставление статистической отчетности
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Учет и регистрация причин нарушения эксплуатационного процесса
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Консультирование посетителей парнинга по вопросам его эксплуатации
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Принятие и рассмотрение претензий, связанных с оказанием услуг на
              паркинге
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Администрирование парковочных билетов
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Обслуживание кассовых терминалов (Инкассация, работа с ККМ и пр.)
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <span class="print-table--service-text"
                >Совместно&nbsp;с&nbsp;вашим бухгалтером</span
              >
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross">
                <img :src="getimgStaticSrc('sales/cross.svg')" />
              </div>
            </div>
          </div>
          <div class="print-table__row">
            <div class="print-table__row-td col">
              Работа с внештатными и аварийными ситуациями
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <div class="print__cross-2">
                <img :src="getimgStaticSrc('sales/cross-2.svg')" />
              </div>
            </div>
            <div class="print-table__row-td col-cell">
              <span
                >Совместно <br />
                с Ч.О.П</span
              >
            </div>
            <div class="print-table__row-td col-cell">
              <span
                >Совместно <br />
                с Ч.О.П</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="print__list-row print__list-row--service">
        <div class="col print__list-name">
          {{ offerObject.json.serviceType.name }}
        </div>
        <div class="co-auto ml-auto print__list-page">
          {{ offerObject.json.serviceType.cost | moneyFormat }}
          {{ getCurrencyAbbreviation }}/месяц
        </div>
      </div>
    </div>
    <div class="print__footer">{{ this.pageNums.Advantages + 2 }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {},
  computed: {
    getServiceTypeTableClass() {
      switch (this.offerObject.json.serviceType.type) {
        case 1:
          return "select-2";
        case 2:
          return "select-3";
        case 3:
          return "select-4";
        case 4:
          return "select-5";
      }
      return false;
    },
    ...mapState("sales", ["offerObject", "settingsObject"]),
    ...mapGetters("sales", ["getCurrencyAbbreviation"]),
  },
};
</script>
