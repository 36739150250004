var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-style",attrs:{"id":"entry-wrap"}},_vm._l((_vm.offerObject.json.AdditionalPageTextVirtual),function(page,index){return _c('div',{key:index,staticClass:"pdf-cont"},[_c('div',{staticClass:"print"},[_c('div',{staticClass:"print__cont"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })},[_vm._v("\n          Дополнительная "),_c('br'),_vm._v("информация\n        ")]),_vm._v(" "),_c('div',{staticClass:"print__info",staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.getRealText(page))}})]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v("\n        "+_vm._s(_vm.pageNum +
          Object.keys(_vm.offerObject.json.AdditionalPageTextVirtual).indexOf(
            index
          ) +
          1)+"\n      ")])])])}))}
var staticRenderFns = []

export { render, staticRenderFns }