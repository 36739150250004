import { mapState, mapActions } from "vuex";
import * as c from "../store/common/constants";

export const UsersSettingsForm = {
  computed: {
    ...mapState("common", ["config_user_settings"]),
    ...mapState("common", {
      errors: (state) => state.config_user_settings.errors,
    }),
    firstname: {
      get() {
        return this.config_user_settings.firstname;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "firstname",
          value: value,
        });
      },
    },
    lastname: {
      get() {
        return this.config_user_settings.lastname;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "lastname",
          value: value,
        });
      },
    },
    middlename: {
      get() {
        return this.config_user_settings.middlename;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "middlename",
          value: value,
        });
      },
    },
    email: {
      get() {
        return this.config_user_settings.email;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "email",
          value: value,
        });
      },
    },
    phone: {
      get() {
        return this.config_user_settings.phone;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "phone",
          value: value,
        });
      },
    },
    password: {
      get() {
        return this.config_user_settings.password;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "password",
          value: value,
        });
      },
    },
    confirm_password: {
      get() {
        return this.config_user_settings.confirm_password;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "confirm_password",
          value: value,
        });
      },
    },
    roleId: {
      get() {
        return this.config_user_settings.roleId;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "roleId",
          value: value,
        });
      },
    },
    ObjectRequestNotification: {
      get() {
        return this.config_user_settings.ObjectRequestNotification;
      },
      set(value) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "ObjectRequestNotification",
          value: value,
        });
      },
    },
  },
  methods: {
    ...mapActions("common", [c.CHANGE_USER_FORM_FIELD]),
  },
};
