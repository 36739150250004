<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Оборудование и опции</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div class="cm-popup-form__wrapper">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Описание:
          </span>
          <span class="field__content input-file">
            <textarea
              class="textarea"
              v-model="settingsObject.json.options"
              maxlength="400"
            ></textarea>
          </span>
        </label>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(5, false, false)">Превью</span>
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
export default {
  mixins: [SalesHelpers],

  methods: {},
};
</script>
