<template>
  <div class="automation-builder">
    <template v-if="!hideFirstPart">
      <div class="automation-builder__separate-word" v-if="!noSeparateWord1">
        Если
      </div>
      <AutomationInput
        :items="automatizationsActions"
        :value="form.ActionId"
        :placeholder="'событие'"
        @choose="chooseActionId"
        :disabled="!create"
        ref="ActionId"
      />
      <AutomationInput
        :items="
          filteredItems(
            automatizationsObjectsTypes,
            filteredAutomatizationsObjectsTypes
          )
        "
        :value="form.ObjectTypeId"
        :placeholder="'свойство задачи'"
        ref="ObjectTypeId"
        @choose="chooseObjectTypeId"
        :disabled="!create"
        @click.native="checkField('ActionId')"
      />

      <div
        class="automation-builder-task-input"
        v-if="!hideTaskName && form.EventTaskId"
      >
        задачи
        <span
          class="automation-select-text automation-builder-task-input__name"
          @click="selectedTaskParentData.subtaskIdForOpen = form.EventTaskId"
        >
          {{ form.TaskEventName }}</span
        >
      </div>
      <!--                    !!-->
      <AutomationInput
        v-if="showConditionField('conditionTemplate')"
        :items="
          preparedAutomatizationsActionsOptions(
            filteredAutomatizationsActionsOptions
          )
        "
        :value="form.ActionOptionId"
        :placeholder="'условие'"
        :capitalizeWord="true"
        @click.native="checkField('ObjectTypeId'), checkField('ActionId')"
        :disabled="!create"
        @choose="
          setCondition(
            $event,
            'ActionOptionId',
            'conditionTemplate',
            filteredAutomatizationsActionsOptions
          ),
            save()
        "
      />

      <AutomationBuilderParams
        :template="conditionTemplate"
        :params="params"
        :type="'condition'"
        :create="create"
        :attach="attach"
        :edit="edit"
        :filteredAutomatizationsObjectsTypes="
          filteredAutomatizationsObjectsTypes
        "
        :form="form"
        @save="save"
      />
    </template>
    <template v-if="!hideFirstPart && !hideLastPart">,</template>
    <template v-if="!hideLastPart">
      <div
        class="automation-builder__separate-word automation-builder__separate-word--2"
        v-if="!noSeparateWord2"
      >
        то
      </div>
      <AutomationInput
        :items="automatizationsActionsObjectsTypes"
        :value="form.ActionObjectType"
        :placeholder="'действие'"
        ref="ActionObjectType"
        :disabled="!create"
        @choose="chooseActionType"
      />

      <AutomationInput
        :items="
          filteredItems(
            automatizationsActionsObjects,
            filteredAutomatizationsActionsObjects
          )
        "
        :value="form.ActionObjectTypeId"
        :placeholder="'объект действия'"
        ref="ActionObjectTypeId"
        :disabled="!create"
        @choose="chooseActionObjectTypeId"
        @click.native="checkField('ActionObjectType')"
      />

      <!--                    !!-->
      <AutomationInput
        v-if="showConditionField('actionTemplate')"
        :items="
          preparedAutomatizationsActionsOptions(
            filteredAutomatizationsActionsTemplates
          )
        "
        :value="form.ActionObjectOptionId"
        :capitalizeWord="true"
        :placeholder="'параметр действия'"
        :disabled="!create"
        @click.native="
          checkField('ActionObjectTypeId'), checkField('ActionObjectType')
        "
        @choose="
          setCondition(
            $event,
            'ActionObjectOptionId',
            'actionTemplate',
            filteredAutomatizationsActionsTemplates
          ),
            save()
        "
      />
      <AutomationBuilderParams
        :template="actionTemplate"
        :params="params"
        :type="'action'"
        @save="save"
        :edit="edit"
        :attach="attach"
        :create="create"
        :filteredAutomatizationsObjectsTypes="
          filteredAutomatizationsObjectsTypes
        "
        :form="form"
      />
    </template>
  </div>
</template>

<script>
import AutomationInput from "./components/AutomationInput";
import {
  GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS,
  GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS,
  GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES,
  GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_ACTIONS,
  GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS,
  GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES,
} from "../../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import { automationParamsTemplate } from "./template";
import AutomationBuilderParams from "./AutomationBuilderParams";
import * as _ from "lodash";

export default {
  name: "AutomationBuilder",
  props: [
    "form",
    "parentData",
    "saveOnBlur",
    "hideLastPart",
    "hideFirstPart",
    "create",
    "edit",
    "noSeparateWord1",
    "noSeparateWord2",
    "selectedTaskParentData",
    "hideTaskName",
    "attach",
  ],
  data() {
    return {
      filteredAutomatizationsActions: [],
      filteredAutomatizationsActionsOptions: [],
      filteredAutomatizationsActionsObjectsTypes: [],
      filteredAutomatizationsActionsTemplates: [],
      filteredAutomatizationsObjectsTypes: [],
      filteredAutomatizationsActionsObjects: [],
      conditionTemplate: null,
      actionTemplate: null,
      params: {
        condition: JSON.parse(JSON.stringify(automationParamsTemplate)),
        action: JSON.parse(JSON.stringify(automationParamsTemplate)),
      },
    };
  },
  created() {
    _.each(
      [
        "ObjectTypeId",
        "ActionOptionId",
        "ActionObjectTypeId",
        "ActionObjectType",
        "ActionObjectOptionId",
      ],
      (i) => {
        this.loadSettingsParams(i, true);
      }
    );
    const dict = {
      ActionOptionParams: "condition",
      ActionObjectOptionParams: "action",
      TaskActionOptionParams: "condition",
      TaskActionObjectOptionParams: "action",
    };
    _.each(
      [
        "ActionOptionParams",
        "ActionObjectOptionParams",
        "TaskActionOptionParams",
        "TaskActionObjectOptionParams",
      ],
      (field) => {
        if (this.form[field]) {
          let json;
          try {
            json = JSON.parse(this.form[field]);
          } catch (e) {
            json = this.form[field];
          }
          _.each(json, (val, key) => {
            this.params[dict[field]][key] = val;
          });
        }
      }
    );
  },
  computed: {
    ...mapState("tasks", [
      "automatizationsActions",
      "automatizationsObjectsTypes",
      "automatizationsActionsObjectsTypes",
      "automatizationsActionsObjects",
      "automatizationsSettingsStore",
      "automatizationsLoadingSettings",
    ]),
  },
  methods: {
    showConditionField(field) {
      if (
        this[field] &&
        this[field].Params &&
        Object.keys(this[field].Params).length
      ) {
        return false;
      }
      return true;
    },
    checkField(field) {
      if (!this.form[field]) {
        const el = this.$refs[field].$el.querySelector(
          ".automation-select-text"
        );
        el.classList.add("automation-select-text--red");
        setTimeout(() => {
          el.classList.remove("automation-select-text--red");
        }, 500);
      }
    },
    loadSettingsParams(field, init = false) {
      if (this.automatizationsLoadingSettings) {
        setTimeout(() => {
          return this.loadSettingsParams(field, true);
        }, 50);
      } else {
        if (field === "ObjectTypeId" && this.form.ActionId) {
          this.filteredAutomatizationsObjectsTypes = [];
          const foundSettings = this.findSettingsInStore(
            "filteredAutomatizationsObjectsTypes",
            this.form.ActionId
          );
          if (!foundSettings || !Object.keys(foundSettings).length) {
            this.parentData.preloader = true;
            this[GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS]({
              ActionId: this.form.ActionId,
            })
              .then((res) => {
                if (res.data.status === "success") {
                  this.filteredAutomatizationsObjectsTypes = res.data.objects;
                  return Promise.resolve(res);
                }
              })
              .finally(() => (this.parentData.preloader = false));
          } else {
            this.filteredAutomatizationsObjectsTypes = foundSettings;
          }
        }

        if (
          field === "ActionOptionId" &&
          this.form.ActionId &&
          this.form.ObjectTypeId
        ) {
          this.filteredAutomatizationsActionsOptions = [];
          const foundSettings = this.findSettingsInStore(
            "filteredAutomatizationsActionsOptions",
            [this.form.ObjectTypeId, this.form.ActionId].join()
          );

          if (!foundSettings || !foundSettings.length) {
            this.parentData.preloader = true;

            this[GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES]({
              ObjectTypeId: this.form.ObjectTypeId,
              ActionId: this.form.ActionId,
            })
              .then((res) => {
                if (res.data.status === "success") {
                  this.filteredAutomatizationsActionsOptions =
                    res.data.templates;
                  if (this.form.ActionOptionId) {
                    this.setCondition(
                      this.form.ActionOptionId,
                      "ActionOptionId",
                      "conditionTemplate",
                      this.filteredAutomatizationsActionsOptions
                    );
                  }
                  return Promise.resolve(res);
                }
              })
              .finally(() => (this.parentData.preloader = false));
          } else {
            this.filteredAutomatizationsActionsOptions = foundSettings;
            if (this.form.ActionOptionId) {
              if (!init) {
                console.log("удаляем все кондицииц");
                this.conditionTemplate = [];
                this.form.ActionOptionId = null;
                this.params.condition = JSON.parse(
                  JSON.stringify(automationParamsTemplate)
                );
              }
              this.setCondition(
                this.form.ActionOptionId,
                "ActionOptionId",
                "conditionTemplate",
                this.filteredAutomatizationsActionsOptions
              );
            }
          }
        }

        if (field === "ActionObjectType" && this.form.ActionObjectTypeId) {
          this.filteredAutomatizationsActionsObjectsTypes = [];

          const foundSettings = this.findSettingsInStore(
            "filteredAutomatizationsActionsObjectsTypes",
            this.form.ActionObjectTypeId
          );

          if (!foundSettings || !Object.keys(foundSettings).length) {
            this.parentData.preloader = true;
            this[GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS]({
              ObjectTypeId: this.form.ActionObjectTypeId,
            })
              .then((res) => {
                if (res.data.status === "success") {
                  this.filteredAutomatizationsActionsObjectsTypes =
                    res.data.actions;
                  return Promise.resolve(res);
                }
              })
              .finally(() => (this.parentData.preloader = false));
          } else {
            this.filteredAutomatizationsActionsObjectsTypes = foundSettings;
          }
        }

        if (field === "ActionObjectTypeId" && this.form.ActionObjectType) {
          this.filteredAutomatizationsActionsObjects = [];

          const foundSettings = this.findSettingsInStore(
            "filteredAutomatizationsActionsObjects",
            this.form.ActionObjectType
          );

          if (!foundSettings || !Object.keys(foundSettings).length) {
            this.parentData.preloader = true;
            this[GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS]({
              ActionId: this.form.ActionObjectType,
            })
              .then((res) => {
                if (res.data.status === "success") {
                  this.filteredAutomatizationsActionsObjects = res.data.objects;
                  return Promise.resolve(res);
                }
              })
              .finally(() => (this.parentData.preloader = false));
          } else {
            this.filteredAutomatizationsActionsObjects = foundSettings;
          }
        }

        if (
          field === "ActionObjectOptionId" &&
          this.form.ActionObjectTypeId &&
          this.form.ActionObjectType
        ) {
          this.filteredAutomatizationsActionsTemplates = [];

          const foundSettings = this.findSettingsInStore(
            "filteredAutomatizationsActionsTemplates",
            [this.form.ActionObjectTypeId, this.form.ActionObjectType].join()
          );

          if (!foundSettings || !foundSettings.length) {
            this.parentData.preloader = true;
            this[GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES]({
              ObjectTypeId: this.form.ActionObjectTypeId,
              ActionId: this.form.ActionObjectType,
            })
              .then((res) => {
                if (res.data && res.data.status === "success") {
                  this.filteredAutomatizationsActionsTemplates =
                    res.data.templates;
                  if (this.form.ActionObjectOptionId) {
                    this.setCondition(
                      this.form.ActionObjectOptionId,
                      "ActionObjectOptionId",
                      "actionTemplate",
                      this.filteredAutomatizationsActionsTemplates
                    );
                  }
                  return Promise.resolve(res);
                }
              })
              .finally(() => (this.parentData.preloader = false));
          } else {
            this.filteredAutomatizationsActionsTemplates = foundSettings;
            if (this.form.ActionObjectOptionId) {
              if (!init) {
                this.actionTemplate = [];
                this.form.ActionObjectOptionId = null;
                this.params.action = JSON.parse(
                  JSON.stringify(automationParamsTemplate)
                );
              }
              this.setCondition(
                this.form.ActionObjectOptionId,
                "ActionObjectOptionId",
                "actionTemplate",
                this.filteredAutomatizationsActionsTemplates
              );
            }
          }
        }
      }
    },
    setCondition(id, field1, field2, arr) {
      this.form[field1] = id;
      const foundConditionParam = _.find(
        this.preparedAutomatizationsActionsOptions(arr),
        ["Id", id]
      );
      if (foundConditionParam) {
        this[field2] = JSON.parse(foundConditionParam.Template);
      }
    },
    chooseObjectTypeId(id) {
      this.form.ObjectTypeId = id;
      this.loadSettingsParams("ActionOptionId");
      this.save();
    },
    filteredItems(allItems, filteredItems) {
      if (filteredItems && Object.keys(filteredItems).length) {
        return _.filter(allItems, (i) =>
          Object.keys(filteredItems).includes(i.Id)
        );
      }
      return [];
    },
    chooseActionId(id) {
      this.form.ActionId = id;
      this.loadSettingsParams("ObjectTypeId");
      this.save();
    },
    chooseActionObjectTypeId(id) {
      this.form.ActionObjectTypeId = id;
      this.loadSettingsParams("ActionObjectOptionId");
      this.save();
    },
    chooseActionType(id) {
      this.form.ActionObjectType = id;
      this.loadSettingsParams("ActionObjectTypeId");
      this.save();
    },
    preparedAutomatizationsActionsOptions(arr) {
      return arr.map((i) => {
        const jsonTemplate = JSON.parse(i.Template);
        let Name = i.Template;
        if (jsonTemplate.Params) {
          let FROM = jsonTemplate.Params.FROM;
          let TO = jsonTemplate.Params.TO;
          let COUNT_DAYS = jsonTemplate.Params.COUNT_DAYS;
          let COUNT_MONTHS = jsonTemplate.Params.COUNT_MONTHS;
          let COUNT_YEARS = jsonTemplate.Params.COUNT_YEARS;
          if (FROM) FROM = FROM === "@@FIXED@@" ? "(фикс)" : "(парам)";
          if (TO) TO = TO === "@@FIXED@@" ? "(фикс)" : "(парам)";
          if (COUNT_DAYS)
            COUNT_DAYS = COUNT_DAYS === "@@FIXED@@" ? "(фикс)" : "(парам)";
          if (COUNT_MONTHS)
            COUNT_MONTHS = COUNT_MONTHS === "@@FIXED@@" ? "(фикс)" : "(парам)";
          if (COUNT_YEARS)
            COUNT_YEARS = COUNT_YEARS === "@@FIXED@@" ? "(фикс)" : "(парам)";

          Name = jsonTemplate.Template.replace(
            new RegExp("%%FROM%%", "g"),
            FROM
          )
            .replace(new RegExp("%%TO%%", "g"), TO)
            .replace(new RegExp("%%COUNT_DAYS%%", "g"), COUNT_DAYS)
            .replace(new RegExp("%%COUNT_MONTHS%%", "g"), COUNT_MONTHS)
            .replace(new RegExp("%%COUNT_YEARS%%", "g"), COUNT_YEARS)
            .replace(new RegExp("%%DATE%%", "g"), "")
            .replace(new RegExp("%%USER%%", "g"), "");
        }

        return {
          Name,
          ...i,
        };
      });
    },

    paramsToSave() {
      const ActionOptionParams = {};
      const ActionObjectOptionParams = {};
      const TaskActionOptionParams = {};
      const TaskActionObjectOptionParams = {};
      _.each(["condition", "action"], (field) => {
        _.each(
          [
            "FROM",
            "TO",
            "COUNT_DAYS",
            "COUNT_MONTHS",
            "COUNT_YEARS",
            "USER",
            "DATE",
          ],
          (val) => {
            if (
              this[field + "Template"] &&
              this[field + "Template"].Params[val]
            ) {
              if (field === "condition") {
                ActionOptionParams[val] = this.params[field][val];
                TaskActionOptionParams[val] = this.params[field][val];
              } else {
                ActionObjectOptionParams[val] = this.params[field][val];
                TaskActionObjectOptionParams[val] = this.params[field][val];
              }
            }
          }
        );
      });
      return {
        ...this.form,
        ActionOptionParams,
        ActionObjectOptionParams,
        TaskActionOptionParams,
        TaskActionObjectOptionParams,
      };
    },

    save() {
      if (this.saveOnBlur) {
        this.$emit("save", this.paramsToSave());
      }
    },

    findSettingsInStore(field, id) {
      try {
        return this.automatizationsSettingsStore[field][id];
      } catch (e) {
        return [];
      }
    },

    ...mapActions("tasks", [
      GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES,
      GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_ACTIONS,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES,
      GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS,
    ]),
  },
  components: {
    AutomationInput,
    AutomationBuilderParams,
  },
};
</script>

<style scoped></style>
