<template>
  <div>
    <client-support-header
      v-if="
        currentRoute === '/clientsupport/' || currentRoute === '/clientsupport'
      "
    />
    <rps-support-header
      v-if="
        window.location.pathname.includes('support') &&
        !window.location.pathname.includes('clientsupport')
      "
      :parentData="parentData"
    />
  </div>
</template>

<script>
import ClientSupportHeader from "./components/ClientSupportHeader";
import RpsSupportHeader from "./components/RpsSupportHeader";
import { mapState } from "vuex";
export default {
  data() {
    return {
      window: {},
    };
  },
  computed: {
    ...mapState("common", ["currentRoute"]),
  },
  created() {
    this.window = window;
  },
  components: { ClientSupportHeader, RpsSupportHeader },
  props: ["parentData"],
};
</script>
