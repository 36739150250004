<template>
  <div
    class="popup task-popup event-popup event-popup_active"
    id="select-group-popup"
    ref="taskFilesList"
    :style="`left: ${moveLeft}`"
  >
    <div class="popup__wrapper" @click.stop="$emit('onClosePopup')">
      <div class="popup__content" @click.stop>
        <div class="popup__header">
          <h2 class="popup__heading">Выбор группы</h2>
          <div class="popup__close" @click.stop="$emit('onClosePopup')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="task-popup-header">
          <div class="actions-header__col actions-header__col_flexible">
            <div class="search-bar search-bar_white actions-header__search-bar">
              <button type="submit" class="search-bar__button">
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/static/images/icon.svg#icon_icon-search"
                  ></use>
                </svg>
              </button>
              <input
                class="search-bar__input"
                type="search"
                v-model="search"
                placeholder="Введите название группы"
              />
            </div>
          </div>
        </div>
        <div class="popup__body">
          <ul class="group-list">
            <li
              class="group-list__item"
              v-for="group in filteredGroups"
              :key="group.Id"
              @click.stop="$emit('onGroupSelect', group)"
            >
              <svg
                width="16"
                height="15"
                :style="{ fill: group.Color ? group.Color : '#FFB72B' }"
              >
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/tasks/sprite.svg#folder-icon"
                ></use>
              </svg>
              <p>{{ group.Name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskGroupSelector",
  props: ["groups", "moveLeft"],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((item) =>
        item.Name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup();
      }
    },
  },
};
</script>

<style scoped></style>
