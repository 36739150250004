<template>
  <div class="popup-wrapper">
    <div
      class="popup task-popup task-popup-parent task-popup-events event-popup event-popup_active"
    >
      <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
        <div class="popup__content">
          <div class="popup__header">
            <h2 class="popup__heading">Ответы на комментарий</h2>
            <div class="popup__close" @click="$emit('onClosePopup')">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body">
            <div class="cm-popup comment-answer-popup">
              <div class="task-comments-wrap">
                <div class="comments-group-wrap" ref="comments-group-wrap">
                  <transition name="fade">
                    <div class="spinner" v-if="preloader">
                      <Spinner
                        :noFadeIn="true"
                        name="ball-grid-pulse"
                        color="#F9AD1A"
                        width="60"
                        height="60"
                      />
                    </div>
                  </transition>
                  <CommentItem
                    :item="replyComment"
                    :loadedTask="loadedTask"
                    :isPinnedInAnswerPopup="true"
                  />
                  <CommentItem
                    v-for="(item, index) in comments"
                    :item="item"
                    :key="index + 1"
                    :replyComment="replyComment"
                    :loadedTask="loadedTask"
                    @setReplyComment="setReplyComment"
                  />
                </div>
                <CommentInput
                  v-if="loadedTask.entityModel.EnableComments !== '0'"
                  :replyComment="computedReplyComment"
                  :popupReplyComment="popupReplyComment"
                  :loadedTask="loadedTask"
                  :fromPopup="true"
                  ref="commentInput"
                  @loadComments="loadComments"
                  @removeReply="popupReplyComment = null"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import CommentInput from "@/modules/tasks/components/TaskDetail/CommentsControl/CommentInput";
import CommentItem from "@/modules/tasks/components/TaskDetail/CommentsControl/CommentItem";
import { mapActions, mapState } from "vuex";
import { GET_COMMENTS_FOR_PARENT_COMMENT } from "@/store/tasks/constants";
import common from "@/mixins/tasks/common";

export default {
  name: "CommentAnswersPopup",
  mixins: [Helpers, common],
  props: ["loadedTask", "replyComment"],
  data() {
    return {
      comments: [],
      preloader: false,
      popupReplyComment: null,
    };
  },
  created() {
    this.preloader = true;
    this.loadComments();
  },
  mounted() {},
  computed: {
    computedReplyComment() {
      return this.popupReplyComment || this.replyComment;
    },
    ...mapState("tasks", ["settings"]),
  },
  methods: {
    loadComments() {
      this[GET_COMMENTS_FOR_PARENT_COMMENT](this.replyComment.Id).then(
        (res) => {
          this.comments = res.data.comments;
          this.preloader = false;
          setTimeout(() => {
            this.$refs["comments-group-wrap"].scrollTop = this.$refs[
              "comments-group-wrap"
            ].scrollHeight;
          }, 50);
        }
      );
    },
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.$emit("onClosePopup");
      }
    },
    setReplyComment(comment) {
      this.popupReplyComment = comment;

      const quill = this.$refs.commentInput.$refs.editor.quill;
      const retain = this.settings.textBubbleChoords.retain;
      let subscriberName = this.popupReplyComment.AuthorName;

      subscriberName =
        "@" + this.$options.filters.shortName_NICKNAME(subscriberName);
      const delta = {
        ops: [
          {
            insert: subscriberName,
            attributes: { link: "userId=" + this.popupReplyComment.UserId },
          },
          { insert: "  " },
        ],
      };
      quill.updateContents(delta);
      quill.setSelection(retain + subscriberName.length + 1, 0);

      this.$refs.commentInput.$refs.editor.quill.focus();
    },
    ...mapActions("tasks", [GET_COMMENTS_FOR_PARENT_COMMENT]),
  },
  components: {
    CommentInput,
    CommentItem,
  },
};
</script>
