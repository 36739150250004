import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    login: false,
    is_client: false,
    user: null,
    userData: null,
    preloader: false,
    currentRoute: "",
    popupImage: null,
    notification: null,
    canGetNotification: true,
    popups: {
      client: {
        newRequest: false,
        requestWindow: false,
      },
      rps: {
        newRequest: false,
      },
      objects: {
        company: false,
      },
    },
    rightsLoaded: false,
    rights: [],
    config_user_settings: {
      id: "",
      firstname: null,
      lastname: null,
      middlename: null,
      email: null,
      phone: null,
      password: null,
      confirm_password: null,
      loginName: null,
      fullName: null,
      roleId: null,
      ObjectRequestNotification: "0",
      errors: {},
    },
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
