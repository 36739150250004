<template>
  <div class="settings-users__container user-settings">
    <label
      class="user-settings__field user-settings__field--avatar"
      @change="setAvatar($event.target.files)"
    >
      <p>Аватар:</p>
      <input type="file" hidden />
      <img :src="avatar" alt="user avatar" />
    </label>
    <button
      class="button button_green button_uppercase button_align_center button--settings"
      @click="saveAvatar"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import { filesChange, showAlert } from "../../../utils";
import { API_ROOT } from "../../../config";
import axios from "axios";
import { GET_USERS } from "../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
export default {
  data() {
    return {
      avatarBlob: null,
    };
  },
  created() {
    this[GET_USERS]();
  },
  computed: {
    avatar() {
      if (this.avatarBlob) return this.avatarBlob.img;
      if (this.currentUser && this.currentUser.LinkToAvatar) {
        return API_ROOT + this.currentUser.LinkToAvatar;
      }
      return "https://tanzolymp.com/images/default-non-user-no-photo-1.jpg";
    },
    currentUser() {
      if (this.users && this.userData) {
        const foundUser = _.find(this.users, ["Id", this.userData.Id]);
        return foundUser;
      }
      return false;
    },
    ...mapState("tasks", ["users"]),
    ...mapState("common", ["userData"]),
  },
  methods: {
    setAvatar(files) {
      const images = filesChange(files);
      if (images.length) {
        this.avatarBlob = images[0];
      }
    },
    saveAvatar() {
      if (this.avatarBlob) {
        const formData = new FormData();
        formData.append("avatar", this.avatarBlob.file);
        axios
          .post(
            API_ROOT + "api/tasks/v2/TasksUsersSettings/uploadAvatar",
            formData
          )
          .then(() => {
            this[GET_USERS]();
            showAlert("success", "", "Профиль обновлен");
          });
      }
    },
    ...mapActions("tasks", [GET_USERS]),
  },
};
</script>

<style scoped></style>
