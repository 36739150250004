<template>
  <div class="print print--3">
    <div class="print__cont">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        общая информация <br />об объекте
      </div>
      <div class="print__info">
        <div class="print__category-title print__category-title--1">
          Информация об объекте
        </div>
        <div class="print__info-item">
          <b>Название:</b> {{ offerObject.json.ObjectName }}
        </div>
        <!--<div class="print__info-item"><b>Город:</b> {{offerObject.json.City}}</div>-->
        <div class="print__info-item">
          <b>Адрес:</b> {{ offerObject.json.Address }}
        </div>
        <!--<div class="print__info-item"><b>Шаблон:</b> {{getObjectTypeName(offerObject.json.ObjectType)}}</div>-->
        <div class="print__info-item">
          <b>Тип объекта:</b> {{ offerObject.json.ObjectTypeString }}
        </div>
        <div class="print__info-item">
          <b>Количество машиномест:</b> {{ offerObject.json.MMCount }}
        </div>
        <div class="print__info-item">
          <b>Время работы парковки:</b> {{ offerObject.json.ParkingTime }}
        </div>
      </div>
      <div class="print__info" v-if="offerObject.json.passPages.tasks_page">
        <div class="print__category-title">Задачи</div>
        <p style="white-space: pre-line">{{ offerObject.json.ContentTasks }}</p>
      </div>
    </div>
    <div class="print__footer">3</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
};
</script>
