<template>
  <div
    class="project-view-table"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="0"
    infinite-scroll-immediate-check="false"
    infinite-scroll-throttle-delay="1000"
  >
    <div class="project-view-table__header">
      <p>Проекты и задачи</p>
      <div
        class="project-view-table__header-sort"
        @click="
          commonModuleSettings.sorts.Activity.asc = !commonModuleSettings.sorts
            .Activity.asc;
          getTasksByFilterOrGroup();
        "
      >
        <template v-if="!commonModuleSettings.sorts.Activity.asc">
          Сверху актуальнуые
        </template>
        <template v-else> Снизу актуальные </template>
      </div>
    </div>
    <TableProjectRow
      v-for="(item, index) in tasks"
      :key="index"
      :item="item"
      :index="index"
      :level="0"
      :inDetailTask="false"
      :parentData="commonModuleSettings"
      @setTasksByDraggable="setTasksByDraggable"
      @selectItem="selectItem"
      @createSubTask="createSubTask"
      @shiftSelection="shiftSelection"
      @ctrlSelection="ctrlSelection"
    />
    <transition name="fade">
      <div
        class="table-spinner table-spinner--table"
        v-if="commonModuleSettings.tasksLoading"
      >
        <Spinner
          :noFadeIn="true"
          name="ball-spin-fade-loader"
          color="#c7c7c7"
          width="60"
          height="60"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import tasksTable from "../mixins/tasksTable";
import TableProjectRow from "./TableProjectRow";
import {
  CHANGE_ORDER,
  REFRESH_SECTIONS,
  SET_TASK_CHILDS,
  SILENT_EDIT_TASK,
} from "@/store/tasks/constants";
import { mapActions, mapMutations } from "vuex";

export default {
  mixins: [common, tasksTable],
  name: "MainTableProjectView",
  methods: {
    ...mapActions("tasks", [SILENT_EDIT_TASK, CHANGE_ORDER]),
    ...mapMutations("tasks", [SET_TASK_CHILDS, REFRESH_SECTIONS]),
  },
  components: {
    TableProjectRow,
  },
};
</script>

<style scoped></style>
