<template>
  <div
    :class="{
      'process-list-item__labels-item--select': showControl,
      'process-list-item__labels-item-wrap': !create,
    }"
    v-click-outside="closeControl"
  >
    <div
      class="process-list-item__labels-item task-label task-label--anchor"
      @click="openControl"
      v-if="!create"
      :style="{ backgroundColor: item.Color }"
    >
      {{ item.Name }}
      <button class="task-label__delete-btn" @click.stop="deleteStatus">
        <svg width="16" height="16">
          <use
            xlink:href="/static/images/tasks/sprite.svg#label-close-icon"
          ></use>
        </svg>
      </button>
    </div>
    <div
      class="task-label-add-btn task-label--anchor"
      @click="openControl"
      v-else
    >
      Добавить
    </div>
    <portal to="process-status-control" v-if="showControl">
      <div class="task-labels-edit tm-table__task-labels-edit" ref="ported">
        <div class="task-labels-edit__input">
          <input
            type="text"
            class="input"
            v-model="data.Name"
            placeholder="Название"
          />
          <div
            class="task-form-filter__bottom-color-control task-form-filter__bottom-color-control--left-position"
          >
            <span
              class="task-form-filter__bottom-color"
              :style="{ background: data.Color }"
              @click="($event) => (showColorSwitch = $event)"
            ></span>
            <portal to="common">
              <PortalComponent
                v-if="showColorSwitch"
                @close="closeColorSwitch"
                placement="left-start"
                :clickCoordinates="showColorSwitch"
              >
                <sketch :value="data.Color" @input="setColor($event)" />
              </PortalComponent>
            </portal>
          </div>
        </div>
        <div class="task-labels-edit__btns">
          <div
            class="task-labels-edit__btn task-labels-edit__btn--red"
            @click="closeControl()"
          >
            Отменить
          </div>
          <div
            class="task-labels-edit__btn task-labels-edit__btn--blue"
            @click="createOrEdit"
          >
            Сохранить
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { Sketch } from "vue-color";
import common from "../../../../../mixins/tasks/common";
import { Helpers } from "../../../../../mixins/Helpers";
import {
  CREATE_STATUS,
  DELETE_STATUS,
  GET_PROCESSES,
  UPDATE_STATUS,
} from "../../../../../store/tasks/constants";
import { mapActions, mapGetters } from "vuex";
import { getRandomColor, showAlert } from "../../../../../utils";
import PortalComponent from "@/modules/tasks/components/CommonComponents/PortalComponent";
const emptyData = {
  Name: "",
  Color: "",
  ProcessId: "",
};
export default {
  mixins: [common, Helpers],
  name: "ProcessLabelElement",
  props: ["item", "create", "process", "parentData"],
  data() {
    return {
      showColorSwitch: false,
      data: { ...emptyData },
      showControl: false,
    };
  },
  created() {
    if (!this.create) {
      this.data = { ...this.item };
    }
  },
  computed: {
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    closeColorSwitch() {
      this.showColorSwitch = false;
    },
    setColor(event) {
      this.data.Color = event.hex;
    },
    setPosition() {
      return new Promise((resolve) => {
        const el = this.$refs["ported"];

        const parent = this.$el,
          bodyRect = document.body.getBoundingClientRect(),
          parentRect = parent.getBoundingClientRect();
        let left = parentRect.left - bodyRect.left;
        let top = parentRect.top - bodyRect.top;

        const componentWidth = el.clientWidth;
        const componentHeight = el.clientHeight;

        if (window.innerWidth - left < componentWidth) {
          left = parentRect.right - componentWidth;
        }

        if (window.innerHeight - top < componentHeight) {
          top = parentRect.top - componentHeight;
        } else {
          if (window.innerHeight - top - componentHeight < 150) {
            top = parentRect.top - componentHeight - 60;
          }
        }
        el.style.top = top + 45 + "px";
        el.style.left =
          left - parent.querySelector(".task-label--anchor").clientWidth + "px";

        resolve();
      });
    },
    openControl() {
      setTimeout(() => {
        this.showControl = !this.showControl;
        if (this.showControl) {
          if (this.create) {
            this.data.Color = getRandomColor();
            this.data.ProcessId = this.process.Id;
            this.data.Name = "";
          }
          this.$nextTick().then(
            this.$nextTick(() => {
              const el = this.$refs["ported"];
              this.setPosition().then(() => {
                el.querySelector("input").focus();
              });
            })
          );
        }
      });
    },
    closeControl(e) {
      if (
        !e ||
        (!e.target.closest(".task-labels-edit") &&
          !e.target.closest(".task-label__delete-btn"))
      ) {
        this.showControl = false;
        this.showColorSwitch = false;
        if (!this.create) {
          this.data = { ...this.item };
        }
        // if (this.create) {
        //   this.data = {...emptyData}
        // }
      }
    },
    deleteStatus() {
      this.closeControl();
      this.parentData.preloader = true;
      this[DELETE_STATUS](this.data.Id)
        .then(() => {
          this[GET_PROCESSES](this.group.Id);
        })
        .finally(() => {
          this.parentData.preloader = false;
        });
    },
    createOrEdit() {
      if (this.data.Name) {
        this.parentData.preloader = true;
        const method = this.create ? CREATE_STATUS : UPDATE_STATUS;
        this[method]({
          ...this.data,
          ProcessId: this.process.Id,
        })
          .then(() => {
            this[GET_PROCESSES](this.group.Id);
          })
          .finally(() => {
            this.parentData.preloader = false;
            this.closeControl();
          });
      } else {
        showAlert("", "", "Напишите название");
      }
    },
    ...mapActions("tasks", [
      CREATE_STATUS,
      UPDATE_STATUS,
      DELETE_STATUS,
      GET_PROCESSES,
    ]),
  },
  components: {
    Sketch,
    PortalComponent,
  },
};
</script>

<style scoped></style>
