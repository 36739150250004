<template>
  <div class="print print--15">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        Сервисное <br />обслуживание
      </div>
      <div class="print__text print__text--m-small">
        <p>{{ offerObject.json.Service.desc }}</p>
      </div>
    </div>
    <div class="print__footer">{{ this.pageNums.Advantages + 1 }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject", "settingsObject"]),
  },
};
</script>
