<template>
  <div style="width: 100%; height: 100%">
    <div class="profile-settings-form profile-settings-form_2">
      <div>
        <label
          class="profile-settings-form__input-2"
          @click.prevent="openTelegramLink"
        >
          Интеграция с телеграмм
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              :class="{
                'onoffswitch-checkbox--checked': userData.TelegramId,
              }"
            />
            <div class="onoffswitch-label">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </div>
          </div>
        </label>
        <label class="profile-settings-form__input-2">
          Группировать события
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              v-bind:true-value="'2'"
              v-bind:false-value="'1'"
              v-model="userData.NotificationsViewType"
              @change="
                updateUserProfile(
                  'NotificationsViewType',
                  userData.NotificationsViewType
                )
              "
            />
            <div class="onoffswitch-label">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </div>
          </div>
        </label>
      </div>
    </div>
    <NotificationSettingsTable />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UPDATE_USER_PROFILE } from "@/store/tasks/constants";
import NotificationSettingsTable from "@/modules/tasks/components/ProfileSettingsPopup/NotificationSettingsTable/NotificationSettingsTable";

export default {
  name: "ProfileSettings",
  computed: {
    ...mapState("tasks", ["users", "userData"]),
  },
  methods: {
    openTelegramLink() {
      if (!this.userData.TelegramId) {
        window.open(
            "https://t.me/surerp_bot?start=" + this.userData.Id,
          "_blank"
        );
        this.userData.TelegramId = "some-data";
      } else {
        this.userData.TelegramId = null;
        this[UPDATE_USER_PROFILE]({
          id: this.userData.Id,
          data: {
            TelegramId: null,
          },
        });
      }
    },
    updateUserProfile(field, value) {
      this[UPDATE_USER_PROFILE]({
        id: this.userData.Id,
        data: {
          [field]: value,
        },
      });
    },
    ...mapActions("tasks", [UPDATE_USER_PROFILE]),
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NotificationSettingsTable,
  },
};
</script>

<style scoped></style>
