<template>
  <div
    :class="
      role_form_preloader
        ? 'settings-users_block settings-users_config disable'
        : 'settings-users_block settings-users_config'
    "
  >
    <div class="settings-users_block__name">
      <h4>Настройка</h4>
    </div>

    <div class="settings-users_block__inner">
      <div class="settings-users_block__preloader" v-if="role_form_preloader">
        <i class="circle-preloader"></i>
      </div>
      <div
        class="settings-users_config__inner settings-users_config__inner--role"
        v-else-if="active_role.Id"
        :class="checkUserRight(rightUpdateRoles) ? '' : 'disable_inputs'"
      >
        <div
          class="settings-users_config__row settings-users_config__row--edit-role-name"
        >
          <input
            class="edit-name-input"
            @focus="editNameOpen"
            @blur="editNameClose"
            @keyup.enter="editNameClose"
            type="text"
            v-model="active_role.Name"
          />
          <div
            :class="
              editNameIsOpen
                ? 'settings-users_config__row-icon'
                : 'settings-users_config__row-icon settings-users_config__row-icon--disable'
            "
            @click="editNameCancel"
          >
            <p>Отмена</p>
            <img src="@/assets/images/translate.svg" alt="" />
          </div>
        </div>

        <div class="settings-users_config__list-wrap">
          <p class="settings-users_config__list-title">Уровни доступа</p>

          <ul
            class="settings-users_config__list s-tree"
            v-if="active_role.Rights"
          >
            <li
              class="s-tree__item s-tree__item-1 s-tree--hide"
              :key="settings.Id"
              v-for="settings in active_role.Rights"
            >
              <div class="s-tree__inner" v-hideClick>
                <p class="s-tree__title">
                  <span
                    class="s-tree__arrow"
                    v-if="settings.Children.length"
                  ></span>
                  {{ settings.Name }}
                </p>
                <div class="control-group">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      v-model="settings.Active"
                      @click="toggleCheckRight"
                      :data-id="settings.Id"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                    />
                    <span class="control_indicator"></span>
                  </label>
                </div>
              </div>

              <ul
                class="s-tree__list s-tree__list-2"
                v-if="settings.Children.length"
              >
                <li
                  class="s-tree__item s-tree__item-2 s-tree--hide"
                  :key="settings_child.Id"
                  v-for="settings_child in settings.Children"
                >
                  <div class="s-tree__inner" v-hideClick>
                    <p class="s-tree__title">
                      <span
                        class="s-tree__arrow"
                        v-if="settings_child.Children.length"
                      ></span>
                      {{ settings_child.Name }}
                    </p>
                    <div class="control-group">
                      <label class="control control-checkbox">
                        <input
                          type="checkbox"
                          v-model="settings_child.Active"
                          @click="toggleCheckRight"
                          :data-id="settings_child.Id"
                          v-bind:true-value="1"
                          v-bind:false-value="0"
                        />
                        <span class="control_indicator"></span>
                      </label>
                    </div>
                  </div>
                  <ul
                    class="s-tree__list s-tree__list-3"
                    v-if="settings_child.Children.length"
                  >
                    <li
                      class="s-tree__item s-tree__item-3 s-tree--hide"
                      :key="sub_settings_child.Id"
                      v-for="sub_settings_child in settings_child.Children"
                    >
                      <div class="s-tree__inner" v-hideClick>
                        <p class="s-tree__title">
                          <span
                            class="s-tree__arrow"
                            v-if="sub_settings_child.Children.length"
                          ></span>
                          {{ sub_settings_child.Name }}
                        </p>
                        <div class="control-group">
                          <label class="control control-checkbox">
                            <input
                              type="checkbox"
                              v-model="sub_settings_child.Active"
                              @click="toggleCheckRight"
                              :data-id="sub_settings_child.Id"
                              v-bind:true-value="1"
                              v-bind:false-value="0"
                            />
                            <span class="control_indicator"></span>
                          </label>
                        </div>
                      </div>

                      <ul
                        class="s-tree__list s-tree__list-3"
                        v-if="sub_settings_child.Children.length"
                      >
                        <li
                          class="s-tree__item s-tree__item-3 s-tree__item-4 s-tree--hide"
                          :key="sub_sub_settings_child.Id"
                          v-for="sub_sub_settings_child in sub_settings_child.Children"
                        >
                          <div class="s-tree__inner" v-hideClick>
                            <p class="s-tree__title">
                              {{ sub_sub_settings_child.Name }}
                            </p>
                            <div class="control-group">
                              <label class="control control-checkbox">
                                <input
                                  type="checkbox"
                                  v-model="sub_sub_settings_child.Active"
                                  @click="toggleCheckRight"
                                  :data-id="sub_sub_settings_child.Id"
                                  v-bind:true-value="1"
                                  v-bind:false-value="0"
                                />
                                <span class="control_indicator"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="settings-users_block__btn-wrap">
      <a
        @click="$emit('updateRole')"
        class="button button_green button_uppercase button_align_center"
        v-disabled="{
          allow: checkUserRight(rightUpdateRoles),
          title: 'Запрещено настройками системы',
        }"
      >
        СОХРАНИТЬ
      </a>
      <a
        @click="$emit('deleteRole', active_role.Id)"
        v-disabled="{
          allow: checkUserRight(rightDeleteRoles),
          title: 'Запрещено настройками системы',
        }"
        class="button button_green button_uppercase button_align_center"
      >
        Удалить
      </a>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";

export default {
  props: ["role_form_preloader", "active_role", "old_role_name"],
  mixins: [Helpers],
  data() {
    return {
      open: false,
      editNameIsOpen: false,
      rightUpdateRoles: "5F7B3086-0362-4AF4-975F-DBB7CC7A773A",
      rightDeleteRoles: "36752240-CFCB-4034-8419-D48B8E9BF1EC",
    };
  },
  methods: {
    editNameOpen() {
      setTimeout(() => {
        this.editNameIsOpen = true;
      }, 200);
    },
    editNameClose() {
      setTimeout(() => {
        this.editNameIsOpen = false;
        document.querySelector(".edit-name-input").blur();
      }, 200);
    },
    editNameCancel() {
      if (this.old_role_name !== this.active_role.Name) {
        this.$emit("makeNewRoleName", this.old_role_name);
      }
    },
    toggleCheckRight(e) {
      setTimeout(() => {
        const rightId = e.target.getAttribute("data-id");
        this.$emit("refreshActiveRole", [this.active_role, rightId]);
      }, 100);
    },
  },
  directives: {
    hideClick: {
      inserted: function (el) {
        el.onclick = () => {
          el.parentElement.classList.toggle("s-tree--hide");
        };
      },
    },
  },
};
</script>

<style scoped>
.settings-users_block__btn-wrap .button {
  height: 45px;
  line-height: 26px;
}
</style>
