<template>
  <div
    class="kanban-item__label"
    @click="openPortal"
    :style="{ backgroundColor: tag.Color }"
  >
    <PortalComponent
      v-if="open"
      v-click-outside="close"
      :portedId="randomId"
      :offsetY="3"
    >
      <div
        class="kanban-item__label-inner"
        :style="{ backgroundColor: tag.Color }"
        @click="open = false"
        :data-ported-id="randomId"
      >
        {{ tag.TagName }}
      </div>
    </PortalComponent>
  </div>
</template>

<script>
import { randomId } from "../../../../mixins/sales/SalesJSONS";
import PortalComponent from "../CommonComponents/PortalComponent";
import { Helpers } from "../../../../mixins/Helpers";

export default {
  name: "KanbanItemTag",
  props: ["tag"],
  mixins: [Helpers],
  data() {
    return {
      randomId: randomId(),
      open: false,
    };
  },
  methods: {
    close(e) {
      if (!e.target.closest(".ported-element")) {
        this.open = false;
      }
    },
    openPortal() {
      const interval = setInterval(() => {
        if (!document.querySelector(".ported-element")) {
          clearInterval(interval);
          this.open = true;
        }
      }, 50);
    },
  },
  components: {
    PortalComponent,
  },
};
</script>

<style scoped></style>
