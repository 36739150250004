<template>
  <div id="entry-wrap" class="scroll-style">
    <div
      class="pdf-cont"
      v-for="(page, index) in offerObject.json.AdditionalPageTextVirtual"
      :key="index"
    >
      <div class="print">
        <div class="print__cont">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            Дополнительная <br />информация
          </div>
          <div
            class="print__info"
            style="white-space: pre-wrap"
            v-html="getRealText(page)"
          ></div>
        </div>
        <div class="print__footer">
          {{
            pageNum +
            Object.keys(offerObject.json.AdditionalPageTextVirtual).indexOf(
              index
            ) +
            1
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    pageNum() {
      let pageCount = this.pageNums.Additional;
      if (this.offerObject.json.passPages.additional) {
        pageCount = this.pageNums.Payment;
      }
      return pageCount;
    },
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    getRealText(textArr) {
      return textArr.join("");
    },
  },
};
</script>
