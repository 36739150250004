<template>
  <div
    class="activity-item"
    :class="{
      'activity-item--system-message': isSystemMessage,
      'activity-item--additional-margin':
        isSystemMessage && nextItem && !+nextItem.NotifyComment,
      'activity-item--have-files': files.length,
    }"
  >
    <div class="activity-item__left">
      <UserAvatar
        :user="item.SenderModel"
        :className="'activity-item__avatar'"
      />
    </div>
    <div class="activity-item__right">
      <div class="activity-item__content__empty-place"></div>
      <div class="activity-item__content">
        <div class="activity-item__time">{{ date }}</div>
        <div
          class="activity-item__name"
          :class="{
            'activity-item__name--clickable':
              item.TaskId && selectedTask.entityModel.Id !== item.TaskId,
          }"
          @click="
            item.TaskId &&
              selectedTask.entityModel.Id !== item.TaskId &&
              openSubTask(item.TaskId)
          "
        >
          <div class="activity-item__name-inner">{{ name }}</div>

          <template v-if="files.length">
            <div class="task-events__files">
              <div class="activity-item__name-additional-text-info">
                В задачу добавлены новые файлы:
              </div>
              <div class="activity-item__files">
                <div
                  class="activity-item__file-item"
                  v-for="(item, index) in files"
                  :key="index"
                >
                  <a :href="item" target="_blank" rel="nofollow">{{
                    getFileName(item)
                  }}</a>
                  <!--                <img :src="item"-->
                  <!--                     v-if="fileIsImage(item)"-->
                  <!--                     @click="showImagePopup(item)"-->
                  <!--                >-->
                  <!--                <a class="sup-chat-files__item-link" download v-else :href="item">-->
                  <!--                  <img :src="getNonImageTypeFileIcon(item)">-->
                  <!--                  <p>{{item}}</p>-->
                  <!--                </a>-->
                </div>
              </div>
            </div>
          </template>

          <div
            class="activity-item__name-additional-text-info"
            v-else-if="isSystemMessage"
            v-html="message"
          ></div>
        </div>
        <div
          class="activity-item__icon"
          v-if="!isSystemMessage"
          :style="{
            backgroundImage: `url(/img/tasks_icons/AAFBDB81-2604-421D-814B-32EAEB855D92.svg)`,
          }"
        ></div>
        <div
          class="activity-item__text"
          v-if="!isSystemMessage"
          v-html="message"
        ></div>
      </div>

      <div class="activity-item__notification-text" v-if="item.NewNotification">
        новое
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import common from "../../../../../mixins/tasks/common";
import UserAvatar from "../../CommonComponents/UserAvatar";
import { mapState } from "vuex";

export default {
  name: "ActivityItem",
  mixins: [common],
  props: ["item", "nextItem"],
  computed: {
    date() {
      return moment(this.item.DateCreate).format("HH:mm");
    },
    name() {
      if (this.item.TaskId) {
        return this.item.TaskName;
      } else {
        return this.item.NotificationName;
      }
    },
    message() {
      const el = document.createElement("div");
      el.innerHTML = this.item.Message;
      const allLinks = el.querySelectorAll("a");
      allLinks.forEach((i) => {
        if (i.getAttribute("href").includes("userId=")) {
          const userId = i.getAttribute("href").split("=")[1];
          const userName = this.getPersonNameById(userId);
          const userNameToArray = userName.split(" ");
          try {
            i.innerText = `${userNameToArray[1]} ${userNameToArray[0]}`;
          } catch (e) {
            i.innerText = userName;
          }
          i.classList.add("user-link");
        }
      });

      if (this.item.SystemName === "tasks_task_priority") {
        const oldPriority = el.querySelector(".old_priority").innerText;
        const newPriority = el.querySelector(".new_priority").innerText;
        el.querySelector(".old_priority").classList.add(
          this.getPriorityColor(oldPriority)
        );
        el.querySelector(".old_priority").classList.add("task-priority");
        el.querySelector(".new_priority").classList.add(
          this.getPriorityColor(newPriority)
        );
        el.querySelector(".new_priority").classList.add("task-priority");
      }

      const str = el.innerHTML.replace(/<[^/>][^>]*><\/[^>]+>/gim, "");
      return str;
    },
    isSystemMessage() {
      return +this.item.NotifyComment;
    },
    files() {
      if (this.isSystemMessage) {
        const files = this.item.Message.split("|||");
        files.shift();
        files.pop();
        return files;
      }
      return [];
    },
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    openSubTask(id) {
      this.$router.push("/tasks/");
      setTimeout(() => {
        this.$router.push("/tasks/" + id);
      }, 300);
    },
    getFileName(file) {
      return file.split("/").pop();
    },
  },
  components: {
    UserAvatar,
  },
};
</script>

<style scoped></style>
