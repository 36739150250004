<template>
  <div class="print print--11">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        Функциональные <br />задачи системы
      </div>
      <img
        class="print__system-tasks-img"
        :src="getimgStaticSrc('sales/system-tasks.jpg')"
        v-if="offerObject.json.SystemImg === void 0"
      />
      <img
        class="print__system-tasks-img"
        :src="
          settingsObject.json[offerObject.json.SystemType.toLowerCase() + 'Img']
            .blob
        "
        v-else-if="
          imageBlobCheck(
            'system',
            offerObject.json.SystemType.toLowerCase() + 'Img',
            1
          )
        "
      />
      <img
        class="print__system-tasks-img"
        :src="
          getImageSrc(offerObject.json.SystemImg.img, offerObject.json.System)
        "
        v-else
      />
    </div>
    <div class="flex-wrap">
      <div class="print__s-list">
        <div class="print__s-list-item">
          <div class="print__s-list-inner">
            <span class="print__s-list-num">1</span
            ><span class="print__s-list-text"
              >получение максимальной <br />прибыли с парковки</span
            >
          </div>
        </div>
        <div class="print__s-list-item">
          <div class="print__s-list-inner">
            <span class="print__s-list-num">2</span
            ><span class="print__s-list-text"
              >контроль доступа на <br />территорию паркинга</span
            >
          </div>
        </div>
        <div class="print__s-list-item">
          <div class="print__s-list-inner">
            <span class="print__s-list-num">3</span
            ><span class="print__s-list-text"
              >комфортное использование <br />парковочного простарнства</span
            >
          </div>
        </div>
      </div>
      <div class="print__s-benefits">
        <div
          class="print__s-benefits-item"
          v-for="(item, index) in tasks"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="print__footer">{{ this.pageNums.Total + 1 }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {},
  computed: {
    tasks() {
      return this.offerObject.json.Tasks.split(";");
    },
    ...mapState("sales", ["offerObject", "settingsObject"]),
  },
};
</script>
