import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  getRequestsObjects(params) {
    return axios.get(`${API_ROOT}api/sales/RequestsObjects`, { params });
  },
  editRequestObject(id, data) {
    return axios.post(`${API_ROOT}api/sales/RequestsObjects/` + id, data);
  },
  getReservedObjects(params) {
    return axios.get(`${API_ROOT}api/sales/ReservedObjects`, { params });
  },
  editReservedObject(id, data) {
    return axios.post(`${API_ROOT}api/sales/ReservedObjects/` + id, data);
  },
  deleteReserveObject(id) {
    return axios.delete(`${API_ROOT}api/sales/ReservedObjects/` + id);
  },
  createReserveObject(data) {
    return axios.post(`${API_ROOT}api/sales/ReservedObjects`, data);
  },
};
