<template>
  <div class="popup-wrapper" style="z-index: 99">
    <div
      class="popup task-popup task-popup-parent task-popup-events event-popup event-popup_active"
    >
      <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
        <div class="popup__content">
          <div class="popup__header">
            <h2 class="popup__heading">Активности папки:</h2>
            <div class="popup__close" @click="$emit('onClosePopup')">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body">
            <div class="cm-popup">
              <div
                class="task-events"
                v-infinite-scroll="loadMore"
                infinite-scroll-disabled="task"
                infinite-scroll-distance="0"
                infinite-scroll-immediate-check="false"
                infinite-scroll-throttle-delay="1000"
              >
                <transition name="fade">
                  <div class="table-spinner" v-if="preloader">
                    <Spinner
                      :noFadeIn="true"
                      name="ball-grid-pulse"
                      color="#F9AD1A"
                      width="60"
                      height="60"
                    />
                  </div>
                </transition>
                <transition name="fade">
                  <div>
                    <div
                      class="task-events__wrap"
                      :key="index"
                      v-for="(items, index) in groupedItems"
                    >
                      <div class="task-events__time-name" :key="index">
                        <span>{{ getGroupDateName(index) }}</span>
                      </div>
                      <div class="task-events__item-wrap">
                        <FolderActivityItem
                          v-for="item in items"
                          :key="item.Id"
                          :item="item"
                        />
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "vue-spinkit";
import { Helpers } from "@/mixins/Helpers";
import FolderActivityItem from "@/modules/tasks/components/FolderActivity/FolderActivityItem";
import moment from "moment";
import * as _ from "lodash";
import Api from "@/api/tasks";

export default {
  name: "FolderActivity",
  mixins: [Helpers],
  data() {
    return {
      preloader: false,
      items: [],
      page: 0,
    };
  },
  created() {
    this.getItems();
  },
  mounted() {},
  computed: {
    groupedItems() {
      return _.groupBy(this.items, (i) => {
        return moment(i.DateCreate).startOf("day").format();
      });
    },
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.$emit("onClosePopup");
      }
    },
    getGroupDateName(date) {
      return moment(date).locale("ru").format("DD MMM YYYY");
    },
    getItems() {
      this.preloader = true;
      const params = {
        Page: this.page,
        Limit: 20,
        GroupId: this.group.Id,
      };
      Api.getActivityForGroup(params)
        .then((res) => {
          if (this.page) {
            this.items.push(...res.data.activities);
          } else {
            this.items = res.data.activities;
          }
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    loadMore() {
      this.page += 1;
      this.getItems();
    },
  },
  components: {
    Spinner,
    FolderActivityItem,
  },
};
</script>

<style scoped></style>
