<template>
  <div class="simple-popup-list__wrapper">
    <div class="simple-popup-list__wrapper__overflow" @click="close"></div>
    <div class="simple-popup-list">
      <!--      <div class="simple-popup-list__item simple-popup-list__item_not-active">-->
      <!--        Редактировать группу-->
      <!--      </div>-->
      <div class="simple-popup-list__item" @click="$emit('openChildList')">
        Добавить/удалить папки
      </div>
      <div class="simple-popup-list__item" @click="removeGroup">
        Разгруппировать
      </div>
      <div
        class="simple-popup-list__item"
        @click="
          () => {
            $emit('setEditNameState');
            close();
          }
        "
      >
        Переименовать
      </div>
    </div>
    <FolderGroupsList
      :edit="true"
      :preloader="preloader"
      :selected="selected"
      :initialGroups="childs"
      :outerList="outerList"
      :outerListIsFavorites="outerListIsFavorites"
      :placeholder="globalGroup.GlobalGroupName"
      @select="select"
      @close="$emit('closeGroupSelectPopup')"
      @save="save2"
      v-if="groupSelectPopupOpen"
    />
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import FolderGroupsList from "@/modules/tasks/components/Sidebar/FolderGroupsList";
import {
  ADD_ELEMENT_TO_GLOBAL_GROUP,
  DELETE_ELEMENT_FROM_GLOBAL_GROUP,
  DELETE_GLOBAL_GROUP,
  GET_GLOBAL_GROUP_ELEMENTS,
  GET_USER_TASKS_LIST,
} from "@/store/tasks/constants";
import { mapActions } from "vuex";
import * as _ from "lodash";

export default {
  name: "SidebarFolderEditOptionList",
  mixins: [Helpers],
  props: [
    "globalGroup",
    "childs",
    "outerList",
    "outerListIsFavorites",
    "item",
    "itemType",
    "groupSelectPopupOpen",
  ],
  data() {
    return {
      preloader: false,
      selected: [],
    };
  },
  created() {
    this.childs.map((i) => i.ItemInfo).forEach((i) => this.selected.push(i));
  },
  methods: {
    select(i) {
      if (this.selected.find((x) => x.Id === i.Id)) {
        this.selected = _.reject(this.selected, (x) => x.Id === i.Id);
      } else {
        this.selected.push(i);
      }
    },
    close() {
      this.$emit("close");
    },
    async save() {
      this.preloader = true;
      const itemsToRemove = [];
      const itemsToAdd = [];

      this.childs.forEach((i) => {
        if (!this.selected.find((x) => x.Id === i.ItemInfo.Id)) {
          itemsToRemove.push(i.Id);
        }
      });
      this.selected.forEach((item) => {
        if (!this.childs.find((i) => i.ItemInfo.Id === item.Id)) {
          itemsToAdd.push({
            Id: item.Id,
            ItemType: item.FilterParams !== void 0 ? 2 : 1,
          });
        }
      });
      await this.addChildsToFolder(
        this.globalGroup.ItemInfo
          ? this.globalGroup.ItemInfo.Id
          : this.globalGroup.Id,
        itemsToAdd
      )
        .then(async () => {
          await this.removeChildsFromFolder(itemsToRemove).finally(async () => {
            this.preloader = false;
            await this[GET_USER_TASKS_LIST]();
            this.$emit("closeGroupSelectPopup");
          });
        })
        .finally(() => {});
    },
    save2() {
      this.save().then(() => {
        this[GET_GLOBAL_GROUP_ELEMENTS](this.item.Id);
        this.preloader = false;
        this.$emit("closeGroupSelectPopup");
        this.close();
      });
    },
    async addChildsToFolder(groupId, array) {
      if (array.length) {
        if (!this.outerListIsFavorites) {
          await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
            GroupId: groupId,
            ItemType: this.itemType,
            ItemsIds: array.map((i) => i.Id),
          });
        } else {
          const groups = array
            .filter((i) => +i.ItemType === 1)
            .map((i) => i.Id);
          const filters = array
            .filter((i) => +i.ItemType === 2)
            .map((i) => i.Id);
          if (groups.length) {
            await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
              GroupId: groupId,
              ItemType: 1,
              ItemsIds: groups,
            });
          }
          if (filters.length) {
            await this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
              GroupId: groupId,
              ItemType: 2,
              ItemsIds: filters,
            });
          }
        }
      }
      return true;
    },
    async removeChildsFromFolder(array) {
      for (const itemId of array) {
        await this[DELETE_ELEMENT_FROM_GLOBAL_GROUP](itemId);
      }
      return true;
    },
    removeGroup() {
      this[DELETE_GLOBAL_GROUP](
        this.globalGroup.ItemInfo.Id
          ? this.globalGroup.ItemInfo.Id
          : this.globalGroup.Id
      );
      this.close();
    },
    ...mapActions("tasks", [
      ADD_ELEMENT_TO_GLOBAL_GROUP,
      DELETE_ELEMENT_FROM_GLOBAL_GROUP,
      DELETE_GLOBAL_GROUP,
      GET_GLOBAL_GROUP_ELEMENTS,
      GET_USER_TASKS_LIST,
    ]),
  },
  components: {
    FolderGroupsList,
  },
};
</script>

<style scoped></style>
