<template>
  <div class="table-row-date">
    <div class="task-table-date" v-if="!rangeValue">
      <date-input
        :date="item.FinishDate"
        :timeValue="item.FinishTime"
        :format="'DD MMMM YYYY в HH:mm'"
        :placeholder="'Указать срок'"
        :needReasonToChange="+item.FinishDateOnlyWithComment"
        @setDate="setDate"
        v-if="checkUserTaskRight('change_dates')"
      />
      <template v-else-if="item.FinishDate">
        {{ getFinishDate }}
      </template>
      <template v-else> Дата не назначена </template>
    </div>
    <div class="task-table-date task-table-range-date" v-else>
      <date-input
        :date="item.FinishDate"
        :timeValue="item.FinishTime"
        :placeholder="'Указать срок'"
        :rangeValue="rangeValue"
        :needReasonToChange="+item.FinishDateOnlyWithComment"
        @setDate="
          (...args) => checkUserTaskRight('change_dates') && setDate(...args)
        "
      />
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import * as c from "../../../../../store/tasks/constants";
import moment from "moment";
import { mapActions } from "vuex";
import { Helpers } from "../../../../../mixins/Helpers";
import DateInput from "../../CommonComponents/DateInput";

export default {
  mixins: [common, Helpers],
  props: ["item", "local", "rangeValue"],
  data() {
    return {};
  },
  methods: {
    setDate(date, callback, reasonComment) {
      this.item.FinishDate = date;
      this.item.FinishTime = moment(date).format("HH:mm:ss");

      if (!this.local) {
        const data = {
          FinishDate: date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
          FinishTime: date ? moment(date).format("HH:mm") : "",
        };

        if (reasonComment) data.FinishDateComment = reasonComment;

        const payload = {
          id: this.item.Id,
          data,
        };
        this[c.EDIT_TASK](payload);
      }
    },
    closeBubble() {},
    ...mapActions("tasks", [c.EDIT_TASK]),
  },
  computed: {
    getFinishDate() {
      const date = moment(this.item.FinishDate);
      const time = this.item.FinishTime
        ? moment(this.item.FinishTime, "HH:mm:ss")
        : false;
      if (time) {
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
      }

      return date.locale("ru").format("DD MMM YYYY в HH:mm");
    },
  },
  components: {
    DateInput,
  },
};
</script>

<style scoped></style>
