<template>
  <div
    class="task-create-form__row task-create-form__row--small task-create-form__row--labels"
    style="z-index: 3"
  >
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--3-1"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__blue-title task-bubble-wrap">
          <div class="task-create-form__name-wrap">
            <p class="task-create-form__label-text">Теги:</p>
            <div
              class="task-create-form__field-value"
              ref="portal-wrapper-tags"
            >
              <p @click="addTagsTagsOpen = true" v-if="!tags.length">
                Добавить
              </p>
              <div v-else class="task-popup__nav-labels task-labels">
                <span
                  class="task-label"
                  v-for="item in tags"
                  :style="{ backgroundColor: item.Color }"
                  :key="item.Id"
                  >{{ item.TagName }}
                  <div
                    class="task-table-person__delete"
                    @click="deleteTag(item.Id)"
                  >
                    <svg focusable="false" viewBox="0 0 32 32">
                      <path
                        d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                      ></path>
                    </svg>
                  </div>
                </span>
                <!--        <span class="task-label task-label&#45;&#45;1">Проектирование</span>-->
                <div class="task-create-form-subtasks__person">
                  <div
                    class="task-create-form-subtasks__person--add"
                    @click="addTagsTagsOpen = true"
                    v-if="tags.length"
                  >
                    +
                  </div>
                </div>
              </div>
              <portal to="common">
                <transition name="fade">
                  <TagsListSearch
                    v-if="addTagsTagsOpen"
                    :className="'task-bubble--tag'"
                    :taskTags="tags || []"
                    @choose="addTag"
                    @close="addTagsTagsOpen = false"
                    v-portal="{ parent: $refs['portal-wrapper-tags'] }"
                  />
                </transition>
              </portal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SwitcherForHideField
      v-if="!noSave"
      @saveAfterEdit="
        (formData, callback, field) =>
          $emit('saveAfterEdit', formData, callback, field)
      "
      :form="form"
      :field="'Tags'"
    />
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import TagsListSearch from "../../CommonComponents/TagsListSearch/TagsListSearch";
import { mapState } from "vuex";
import SwitcherForHideField from "./SwitcherForHideField";
import { Helpers } from "@/mixins/Helpers";

export default {
  mixins: [common, Helpers],
  props: ["form", "noSave"],
  data() {
    return {
      addTagsTagsOpen: false,
    };
  },
  computed: {
    tags() {
      return this.form.TagsIds.map((id) =>
        this.tagsFromStore.find((x) => x.Id === id)
      );
    },
    ...mapState("tasks", { tagsFromStore: "tags" }),
  },
  methods: {
    addTag(id) {
      if (!this.form.TagsIds.includes(id)) {
        this.form.TagsIds.push(id);
        this.$emit("saveAfterEdit", null, () => {}, "TagsIds");
      }
    },
    deleteTag(id) {
      this.form.TagsIds.splice(this.form.TagsIds.indexOf(id), 1);
      this.$emit("saveAfterEdit", null, () => {}, "TagsIds");
    },
  },
  components: {
    TagsListSearch,
    SwitcherForHideField,
  },
};
</script>

<style scoped></style>
