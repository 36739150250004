<template>
  <div class="popup-wrapper">
    <div
      class="popup task-popup task-popup-inner event-popup event-popup_active"
      :style="{ transform: `translateX(-${padding}0%)` }"
    >
      <div
        class="popup__wrapper"
        @mousedown="closePopupByClickWrap($event)"
        ref="popup__wrapper"
      >
        <div class="popup__content">
          <div class="popup__header">
            <h2 class="popup__heading">
              Настройка задачи: {{ task.TemplateTaskName }}
            </h2>
            <div class="popup__close" @click="closePopup">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body">
            <div class="cm-popup">
              <div class="task-create-form">
                <div class="task-to-create__wrapper">
                  <TaskToCreate
                    :task="task"
                    :templateModel="templateModel"
                    :noSave="noSave"
                    :editedTemplateTasks="editedTemplateTasks"
                    @pushTasksToDeletedArray="
                      $emit('pushTasksToDeletedArray', $event)
                    "
                    @editTemplateTask="
                      (data, formData, callback) =>
                        $emit('editTemplateTask', data, formData, callback)
                    "
                    @removeFile="
                      (payload, errorCallback) =>
                        $emit('removeFile', payload, errorCallback)
                    "
                    ref="task-to-create"
                    @shiftSelection="
                      ($event) => $emit('shiftSelection', $event)
                    "
                  />
                </div>

                <!--                <div class="task-create-form__button">сохранить</div>-->
                <!--                <div class="task-create-form__button-fake"></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT } from "@/store/tasks/constants";
import { mapMutations, mapState } from "vuex";
import { Helpers } from "@/mixins/Helpers";

export default {
  name: "SubtaskDetailPopup",
  mixins: [Helpers],
  props: ["task", "templateModel", "noSave", "editedTemplateTasks"],
  data() {
    return {
      popupOrderNumber: 0,
    };
  },
  computed: {
    padding() {
      if (
        this.commonModuleSettings.taskCreateDetailPopupsCount > 1 &&
        this.popupOrderNumber !==
          this.commonModuleSettings.taskCreateDetailPopupsCount
      ) {
        return (
          this.commonModuleSettings.taskCreateDetailPopupsCount -
          this.popupOrderNumber
        );
      }
      return 0;
    },
    ...mapState("tasks", ["commonModuleSettings"]),
  },
  created() {
    const orderNumber =
      this.commonModuleSettings.taskCreateDetailPopupsCount + 1;
    this[SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT](orderNumber);
    this.popupOrderNumber = orderNumber;
  },
  mounted() {},
  methods: {
    closePopupByClickWrap(event) {
      if (event.target === this.$refs.popup__wrapper) {
        this.closePopup();
      }
    },
    closePopup() {
      this[SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT](
        this.commonModuleSettings.taskCreateDetailPopupsCount - 1
      );
      this.$emit("onClosePopup");
    },
    ...mapMutations("tasks", [
      SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT,
    ]),
  },
  components: {
    TaskToCreate: () =>
      import("@/modules/tasks/components/TaskCreateForm/TaskToCreate"),
  },
};
</script>

<style scoped>
.popup {
  background-color: rgb(0 0 0 / 20%);
}
</style>
