<template>
  <div v-click-outside="closeTabs" class="task-popup-nav__wrap">
    <div
      class="task-popup-nav"
      :class="{
        'task-popup-nav__item--empty': !selectedTask.entityModel
          .TaskDescription,
        'task-popup-nav--show': shortViewTypeByScroll,
      }"
    >
      <div class="task-popup-nav__inner">
        <div
          class="task-popup-nav__full-btn task-popup-nav__full-btn--reverse"
          @click="
            (e) => {
              e.target.closest('.task-popup-detail__right').scrollTop = 0;
            }
          "
        >
          <i></i>
        </div>
        <div
          class="task-popup-nav__item"
          :class="{ 'task-popup-nav__item--active': shortViewTab === 1 }"
          v-if="checkUserTaskRight('view_task_description')"
        >
          <span
            class="task-popup-nav__item-icon"
            @click="shortViewTab = shortViewTab === 1 ? 0 : 1"
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7347 1H0.820801"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M16.7347 9H0.820801"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M13.1982 5H0.820801"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M13.1982 13H0.820801"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>

          <transition name="slide-down">
            <div class="task-popup-nav__block" v-if="shortViewTab === 1">
              <description-input
                :loadedTask="loadedTask"
                @editTask="$emit('editTask')"
              />
            </div>
          </transition>
        </div>
        <div
          class="task-popup-nav__item"
          :class="{
            'task-popup-nav__item--active': shortViewTab === 2,
            'task-popup-nav__item--empty': !parentData.showChecklistField,
          }"
          v-if="checkUserTaskRight('access_to_microtasks')"
        >
          <div
            class="task-popup-nav__item-icon"
            @click="
              () => {
                if (!parentData.showContactsField) {
                  addData('checklist');
                  shortViewTab = 2;
                } else shortViewTab = shortViewTab === 2 ? 0 : 2;
              }
            "
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 10.0857V11.0057C20.9988 13.1621 20.3005 15.2604 19.0093 16.9875C17.7182 18.7147 15.9033 19.9782 13.8354 20.5896C11.7674 21.201 9.55726 21.1276 7.53447 20.3803C5.51168 19.633 3.78465 18.2518 2.61096 16.4428C1.43727 14.6338 0.879791 12.4938 1.02168 10.342C1.16356 8.19029 1.99721 6.14205 3.39828 4.5028C4.79935 2.86354 6.69279 1.72111 8.79619 1.24587C10.8996 0.770634 13.1003 0.988061 15.07 1.86572"
                stroke-linecap="round"
                fill="none"
                stroke-linejoin="round"
              ></path>
              <path
                d="M21 3.00586L11 13.0159L8 10.0159"
                stroke-linecap="round"
                fill="none"
                stroke-linejoin="round"
              ></path>
            </svg>
            <span
              class="task-popup-nav__text"
              v-if="getChecklistSuccessTasksNumber"
              >{{ getChecklistSuccessTasksNumber }}</span
            >
          </div>

          <transition name="slide-down">
            <div
              class="task-popup-nav__block short-checklist-view"
              v-if="shortViewTab === 2"
            >
              <checklist
                :loadedTask="loadedTask"
                :parentData="parentData"
                ref="checklistField"
                @setCheckListDragging="
                  (value) => (parentData.checkListDragging = value)
                "
              />
            </div>
          </transition>
        </div>

        <div
          class="task-popup-nav__item"
          v-if="
            filteredFiles.length &&
            checkUserTaskRight('view_task_files') &&
            checkUserTaskRight('view_docs_for_sign')
          "
          :class="{ 'task-popup-nav__item--active': shortViewTab === 3 }"
        >
          <div
            class="task-popup-nav__item-icon"
            @click="shortViewTab = shortViewTab === 3 ? 0 : 3"
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0382 9.43839L10.0554 17.4644C9.07746 18.4476 7.75107 19 6.36804 19C4.98501 19 3.65863 18.4476 2.68068 17.4644C1.70273 16.4811 1.15332 15.1476 1.15332 13.7571C1.15332 12.3665 1.70273 11.033 2.68068 10.0497L10.6635 2.02375C11.3154 1.36825 12.1997 1 13.1217 1C14.0437 1 14.928 1.36825 15.5799 2.02375C16.2319 2.67924 16.5982 3.56829 16.5982 4.4953C16.5982 5.42231 16.2319 6.31135 15.5799 6.96685L7.58848 14.9928C7.26249 15.3206 6.82036 15.5047 6.35935 15.5047C5.89834 15.5047 5.45622 15.3206 5.13023 14.9928C4.80425 14.6651 4.62111 14.2206 4.62111 13.7571C4.62111 13.2935 4.80425 12.849 5.13023 12.5213L12.505 5.11537"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <span class="task-popup-nav__text" v-if="filteredFiles.length">{{
              filteredFiles.length
            }}</span>
          </div>
          <transition name="slide-down">
            <div class="task-popup-nav__block" v-if="shortViewTab === 3">
              <files-input
                :loadedTask="loadedTask"
                v-if="commonFiles.length"
                :files="commonFiles"
                @filesChange="filesChange"
              />
              <doc-files-input
                :loadedTask="loadedTask"
                v-if="docFiles.length"
                :files="docFiles"
                @filesChange="filesChange"
              />
            </div>
          </transition>
        </div>

        <div
          class="task-popup-nav__item"
          v-if="checkUserTaskRight('view_task_address')"
          :class="{
            'task-popup-nav__item--active': shortViewTab === 4,
            'task-popup-nav__item--empty': !parentData.showAddressField,
          }"
        >
          <div
            class="task-popup-nav__item-icon"
            @click="
              () => {
                if (!parentData.showAddressField) {
                  addData('address');
                  shortViewTab = 4;
                } else shortViewTab = shortViewTab === 4 ? 0 : 4;
              }
            "
          >
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 10C19 17 10 23 10 23C10 23 1 17 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>

          <transition name="slide-down">
            <div
              class="task-popup-nav__block"
              v-if="shortViewTab === 4"
              style="overflow: visible"
            >
              <address-input
                v-if="parentData.showAddressField"
                :loadedTask="loadedTask"
                ref="addressField"
                @editTask="$emit('editTask')"
              />
            </div>
          </transition>
        </div>
        <div
          class="task-popup-nav__item"
          v-if="checkUserTaskRight('view_task_address')"
          :class="{
            'task-popup-nav__item--active': shortViewTab === 5,
            'task-popup-nav__item--empty': !parentData.showContactsField,
          }"
        >
          <div
            class="task-popup-nav__item-icon"
            @click="
              () => {
                if (!parentData.showContactsField) {
                  addData('contacts');
                  shortViewTab = 5;
                } else shortViewTab = shortViewTab === 5 ? 0 : 5;
              }
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9638 14.4765V17.1862C18.9648 17.4377 18.9133 17.6867 18.8125 17.9172C18.7117 18.1477 18.5639 18.3546 18.3786 18.5247C18.1932 18.6947 17.9743 18.8242 17.736 18.9048C17.4978 18.9854 17.2452 19.0153 16.9947 18.9927C14.2153 18.6907 11.5455 17.7409 9.19974 16.2197C7.01735 14.8329 5.16706 12.9827 3.78028 10.8003C2.25379 8.44389 1.30381 5.76107 1.00733 2.96915C0.98476 2.71938 1.01444 2.46764 1.09449 2.22996C1.17454 1.99229 1.30321 1.77389 1.47229 1.58866C1.64137 1.40343 1.84717 1.25544 2.07658 1.15411C2.30599 1.05278 2.55399 1.00032 2.80478 1.00009H5.51451C5.95286 0.995773 6.37782 1.151 6.71019 1.43683C7.04255 1.72267 7.25964 2.11961 7.32099 2.55366C7.43536 3.42084 7.64747 4.27229 7.95326 5.09177C8.07479 5.41507 8.10109 5.76642 8.02905 6.1042C7.95701 6.44198 7.78965 6.75203 7.5468 6.99761L6.39969 8.14473C7.6855 10.406 9.55783 12.2784 11.8191 13.5642L12.9663 12.4171C13.2118 12.1742 13.5219 12.0069 13.8597 11.9348C14.1975 11.8628 14.5488 11.8891 14.8721 12.0106C15.6916 12.3164 16.543 12.5285 17.4102 12.6429C17.849 12.7048 18.2497 12.9258 18.5361 13.2639C18.8226 13.6019 18.9748 14.0335 18.9638 14.4765Z"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <span
              class="task-popup-nav__text"
              v-if="selectedTask.contacts.length"
              >{{ selectedTask.contacts.length }}</span
            >
          </div>

          <transition name="slide-down">
            <div class="task-popup-nav__block" v-if="shortViewTab === 5">
              <contacts-input
                :loadedTask="loadedTask"
                v-if="parentData.showContactsField"
                :parentData="parentData"
                ref="contactsField"
              />
            </div>
          </transition>
        </div>

        <div
          class="task-popup-nav__item task-popup-nav__special-btn"
          :class="{
            'task-popup-nav__item--active': parentData.addAdditionalData,
          }"
          v-if="
            checkUserTaskRight('add_edit_task_contacts') &&
            needToShowAdditionalControl
          "
          style="display: none"
        >
          <div
            class="task-popup-nav__special-btn-wrapper"
            @click="
              parentData.addAdditionalData = !parentData.addAdditionalData
            "
          >
            <div class="task-popup-nav__item-icon">
              <svg
                class="task-popup-nav__special-btn-icon-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.3708 12C23.3708 18.3538 18.2485 23.5 11.9354 23.5C5.62234 23.5 0.5 18.3538 0.5 12C0.5 5.64618 5.62234 0.5 11.9354 0.5C18.2485 0.5 23.3708 5.64618 23.3708 12Z"
                  fill="none"
                ></path>
                <path
                  d="M8.75203 10.915H11.587V7.825H12.787V10.915H15.622V12.1H12.787V15.205H11.587V12.1H8.75203V10.915Z"
                  stroke="none"
                ></path>
              </svg>
            </div>
            <div class="task-popup-nav__item-icon">
              <svg
                class="task-popup-nav__special-btn-icon-2"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17.0005"
                  cy="16.9706"
                  r="11.5"
                  transform="rotate(45 17.0005 16.9706)"
                  fill="none"
                ></circle>
                <path
                  d="M13.861 13.7649L16.647 16.5509L19.7159 13.482L20.5361 14.3023L17.4673 17.3711L20.2533 20.1571L19.4613 20.9491L16.6753 18.1631L13.5923 21.2461L12.7721 20.4258L15.8551 17.3428L13.0691 14.5568L13.861 13.7649Z"
                  stroke="none"
                ></path>
              </svg>
            </div>
          </div>

          <div
            class="task-popup-nav__bubble"
            v-if="parentData.addAdditionalData"
            v-click-outside="closeAdditionalData"
          >
            <!--<div><i class="task-popup-nav__bubble-icon-1"></i>Контакт</div>-->
            <!--          <div v-if="!parentData.showAddressField"-->
            <!--               @click="addData('address');shortViewTab=4"><i class="task-popup-nav__bubble-icon-2"></i>Адрес-->
            <!--          </div>-->
            <!--          <div v-if="!parentData.showChecklistField"-->
            <!--               @click="addData('checklist');shortViewTab=2"><i class="task-popup-nav__bubble-icon-3"></i>Чек-лист-->
            <!--          </div>-->
            <!--          <div v-if="!parentData.showContactsField"-->
            <!--               @click="addData('contacts');shortViewTab=5"><i class="task-popup-nav__bubble-icon-1"></i>Контакты-->
            <!--          </div>-->
            <div
              v-if="docFiles && !docFiles.length"
              class="task-popup-nav__bubble_files-field"
            >
              <label>
                <input
                  type="file"
                  hidden
                  multiple
                  @change="filesChange($event.target.files, true)"
                  v-if="checkUserTaskRight('add_edit_docs_for_sign')"
                />
                <i></i>Документы на подпись
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pinned-comment"
        v-if="loadedTask.pinnedComments.length"
        @click="goToPinnedComment"
      >
        <div class="pinned-comment__icon">
          <svg width="27" height="26">
            <use xlink:href="/static/images/tasks/sprite.svg#pin-icon"></use>
          </svg>
        </div>
        <div class="pinned-comment__info">
          <div class="pinned-comment__title">Закреплённый комментарий:</div>
          <div
            class="pinned-comment__text"
            v-html="loadedTask.pinnedComments[0].Comment"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import DescriptionInput from "./Inputs/DescriptionInput";
import Checklist from "./Checklist";
import FilesInput from "./Inputs/FilesInput";
import AddressInput from "./Inputs/AddressInput";
import { Helpers } from "../../../../mixins/Helpers";
import ContactsInput from "./Inputs/ContactsInput";
import DocFilesInput from "./Inputs/DocFilesInput";
import * as _ from "lodash";

export default {
  mixins: [common, Helpers],
  props: [
    "parentData",
    "needToShowAdditionalControl",
    "docFiles",
    "commonFiles",
    "shortViewTypeByScroll",
    "loadedTask",
    "commentsControl",
  ],
  data() {
    return {
      shortViewTab: 0,
      addingPopup: true,
      addingPopupAddress: false,
      addingPopupChecklist: false,
      addingPopupContacts: false,
    };
  },
  computed: {
    getChecklistSuccessTasksNumber() {
      const checkLists = this.selectedTask.checklists;
      if (checkLists.length) {
        if (checkLists.length === 1) {
          if (checkLists[0].tasks && checkLists[0].tasks.length) {
            let doneTasks = _.filter(checkLists[0].tasks, ["Status", "2"]);
            doneTasks = doneTasks ? doneTasks.length : 0;
            return doneTasks + "/" + checkLists[0].tasks.length;
          } else {
            return 0;
          }
        } else {
          let countTasks = 0;
          let doneTasks = 0;

          _.each(checkLists, (c) => {
            if (c.tasks && c.tasks.length) {
              let cDoneTasks = _.filter(c.tasks, ["Status", "2"]);
              doneTasks = cDoneTasks
                ? doneTasks + cDoneTasks.length
                : doneTasks;
              countTasks += c.tasks.length;
            }
          });
          return doneTasks + "/" + countTasks;
        }
      } else {
        return 0;
      }
    },
    filteredFiles() {
      return _.filter(this.selectedTask.files, ["CommentId", null]);
    },
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    filesChange(files, forDocFiles) {
      this.$emit("filesChange", files, forDocFiles);
    },
    closeAdditionalData() {
      this.parentData.addAdditionalData = false;
    },
    addData(field) {
      switch (field) {
        case "address":
          this.addingPopupAddress = true;
          this.parentData.showAddressField = true;
          break;
        case "checklist":
          this.addingPopupChecklist = true;
          this.parentData.showChecklistField = true;
          break;
        case "contacts":
          this.addingPopupContacts = true;
          this.parentData.showContactsField = true;
          break;
      }
      this.parentData.addAdditionalData = false;
    },
    closeTabs(e) {
      if (
        !e.target.closest(".mx-datepicker-popup") &&
        e.target.tagName !== "BODY" &&
        !e.target.closest(".grouped-action-header")
      ) {
        this.shortViewTab = 0;
      }
    },
    goToPinnedComment() {
      this.commentsControl.goRoReplyMessage(this.loadedTask.pinnedComments[0]);
    },
  },
  watch: {
    shortViewTypeByScroll(oldVal, newVal) {
      if (oldVal !== newVal && !oldVal) {
        this.shortViewTab = 0;
        this.addingPopup = true;
        this.addingPopupAddress = false;
        this.addingPopupChecklist = false;
        this.addingPopupContacts = false;
      }
    },
  },
  components: {
    DescriptionInput,
    Checklist,
    FilesInput,
    DocFilesInput,
    AddressInput,
    ContactsInput,
  },
};
</script>

<style scoped></style>
