<template>
  <portal to="users">
    <div
      class="task-popup__nav-person__bubble task-bubble fixed-bubble"
      ref="ported"
      :class="className"
      v-click-outside="close"
    >
      <!--        :style="{left, top}"-->
      <div class="task-bubble__search">
        <svg>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/icon.svg#icon_icon-search"
          ></use>
        </svg>
        <input
          type="text"
          class="input"
          placeholder="Поиск"
          v-model="search"
          ref="input"
        />
        <div class="task-bubble__close" @click="close()"><i></i></div>
      </div>
      <div class="task-bubble__list" ref="list" v-if="filteredProcesses.length">
        <div
          class="task-bubble__item task-bubble__item--delete"
          v-if="selectedId"
          @click="choose(null)"
        >
          Открепить
        </div>
        <div
          class="task-bubble__item"
          v-for="(item, index) in filteredProcesses"
          @click="choose(item.Id)"
          :key="index"
          :class="{ 'task-bubble__item--selected': item.selected }"
          :item-id="item.Id"
        >
          {{ item.Name }}
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";
import { DROP_CHILD_TASKS_STATUSES } from "../../../../store/tasks/constants";

export default {
  mixins: [common, Helpers],
  props: {
    className: {
      default: "",
    },
    selectedId: null,
    outerSearch: {
      default: "",
    },
    taskId: {
      default: "",
    },
  },
  data() {
    return {
      search: "",
      items: [],
      top: "",
      left: "",
    };
  },
  created() {
    this.$nextTick().then(
      this.$nextTick(() => {
        const el = this.$refs["ported"];
        this.setPosition().then(() => {
          setTimeout(() => {
            el.style.transition = ".3s";
            el.classList.add("fixed-bubble--show");
            setTimeout(() => {
              el.style.transition = "0s";
            }, 300);
          });
        });
      })
    );
  },
  mounted() {
    setTimeout(() => {
      this.$refs["input"].focus();
    });
  },

  destroyed() {
    document.onkeydown = this.settings.keydownFunction;
  },
  computed: {
    filteredProcesses() {
      return _.filter(this.groupProcesses, (i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.Name);
      });
    },
    ...mapState("tasks", ["groupProcesses", "settings"]),
  },
  methods: {
    choose(id) {
      if (!id && this.taskId) {
        this[DROP_CHILD_TASKS_STATUSES](this.taskId);
      }
      this.$emit("choose", id);
      this.close();
    },
    close() {
      this.$emit("close");
    },
    setPosition() {
      return new Promise((resolve) => {
        const el = this.$refs["ported"];

        const parent = this.$el.parentElement,
          bodyRect = document.body.getBoundingClientRect(),
          parentRect = parent.getBoundingClientRect();

        let left = parentRect.left - bodyRect.left;
        let top = parentRect.top - bodyRect.top;

        const componentWidth = el.clientWidth;
        const componentHeight = el.clientHeight;

        if (window.innerWidth - left < componentWidth) {
          left = parentRect.right - componentWidth;
        }

        if (window.innerHeight - top < componentHeight) {
          top = parentRect.top - componentHeight;
        } else {
          if (window.innerHeight - top - componentHeight < 150) {
            top = parentRect.top - componentHeight;
          }
        }
        el.style.top = top + "px";
        el.style.left = left + "px";

        resolve();
      });
    },
    ...mapActions("tasks", [DROP_CHILD_TASKS_STATUSES]),
  },
};
</script>

<style scoped></style>
