<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Устройства оплаты</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form" v-if="cat">
      <div
        class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single"
      >
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            @click="tab = 1"
            :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
          >
            Фото
          </div>
          <div
            class="cm-popup__tabs-item"
            @click="tab = 2"
            :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
          >
            <template v-if="config.system == 'economy'">Касса Econom</template>
            <template v-else>Касса Light</template>
          </div>
          <div
            class="cm-popup__tabs-item"
            v-if="config.system !== 'economy'"
            @click="tab = 3"
            :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
          >
            Касса Standart
          </div>
          <div
            class="cm-popup__tabs-item"
            @click="tab = 4"
            :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
          >
            Мобильная ручная касса
          </div>
          <div
            class="cm-popup__tabs-item"
            v-if="config.system !== 'economy'"
            @click="tab = 5"
            :class="tab === 5 ? 'cm-popup__tabs-item--active' : ''"
          >
            Касса RPS BM
          </div>
        </div>

        <div class="field field--input-file device-images" v-if="tab === 1">
          <label
            class="field field-cover"
            v-for="(img, index) in settingsObject.json.devices[cat].payment.img"
            :key="index"
          >
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Картинка {{ img.id }}:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="saveDeviceImage($event.target, cat, 'payment', img.id)"
              />
              <input type="text" class="input" :value="img.img" disabled />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>

            <div
              class="table__col table__col-btn"
              v-if="index > 0"
              @click.prevent="
                actionDeviceImages(
                  'remove',
                  img.id,
                  settingsObject.json.devices[cat].payment.img,
                  cat,
                  'payment'
                )
              "
            >
              <div class="table__text-block">
                <span class="remove-icon"></span>
              </div>
            </div>
            <div class="device-img-preview">
              <img
                class="input-file__img"
                v-if="imageBlobCheck('devices', cat + '_payment', img.id)"
                :src="img.blob"
                alt=""
              />
              <img
                class="input-file__img"
                v-else
                :src="getImageSrc(img.img, config.system)"
                alt=""
              />
            </div>
          </label>

          <div class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            ></span>
            <span class="field__content input-file">
              <span
                class="little-down-text"
                @click="
                  actionDeviceImages(
                    'add',
                    null,
                    settingsObject.json.devices[cat].payment.img
                  )
                "
                >+ Добавить ещё</span
              >
            </span>
          </div>
        </div>

        <div v-if="tab === 2" class="table cm-table-1 cm-table--device">
          <div class="table__row-wrap сmp__row-wrap">
            <div class="table__row table__row_main table__row-head">
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <p class="table__text">Наименование</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Цена за ед. в $
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_120">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">Кол-во</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Один комплект
                  </p>
                </div>
              </div>
              <!--              <div class="table__col table__col_width_260">-->
              <!--                <div class="table__text-block">-->
              <!--                  <p class="table__text">Описание опции</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <span
                class="little-down-text little-down-text--devices little-down-text--hidden"
              ></span>
            </div>
            <draggable
              v-model="settingsObject.json.devices[cat].payment.cash_1"
              class="flex-column"
              @end="changeOrder(cat, 'payment', 'cash_1')"
            >
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.devices[cat].payment
                  .cash_1"
                :key="index"
              >
                <div class="table__col table__col_width_200">
                  <div class="table__text-block table__text-block--order">
                    <span
                      ><img
                        class="g-item__drag"
                        src="@/assets/images/drag.svg"
                        alt=""
                    /></span>
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="number" class="input" v-model="item.cost" />
                  </div>
                </div>
                <div class="table__col table__col_width_120">
                  <div class="table__text-block">
                    <input
                      type="number"
                      class="input"
                      v-model.number="item.count"
                    />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        :id="'myonoffswitch5' + index"
                        v-model="item.countAsOneKit"
                      />
                      <label
                        class="onoffswitch-label"
                        :for="'myonoffswitch5' + index"
                      >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!--                <div class="table__col table__col_width_260">-->
                <!--                  <div class="table__text-block">-->
                <!--                    <input type="text" class="input" v-model="item.desc" v-if="index > 0">-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="table__col table__col-btn" v-if="index > 0">
                  <div
                    class="table__text-block"
                    @click="
                      actionDevice('remove', cat, 'payment', 'cash_1', item.id)
                    "
                  >
                    <span class="remove-icon"></span>
                  </div>
                </div>
                <span
                  class="little-down-text little-down-text--devices little-down-text--hidden"
                  v-else
                ></span>
              </div>
            </draggable>
          </div>
          <span
            class="little-down-text little-down-text--devices"
            @click="actionDevice('add', cat, 'payment', 'cash_1')"
            >+ Добавить ещё</span
          >
        </div>
        <div v-if="tab === 3" class="table cm-table-1 cm-table--device">
          <div class="table__row-wrap сmp__row-wrap">
            <div class="table__row table__row_main table__row-head">
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <p class="table__text">Наименование</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Цена за ед. в $
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_120">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">Кол-во</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Один комплект
                  </p>
                </div>
              </div>
              <!--              <div class="table__col table__col_width_260">-->
              <!--                <div class="table__text-block">-->
              <!--                  <p class="table__text">Описание опции</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <span
                class="little-down-text little-down-text--devices little-down-text--hidden"
              ></span>
            </div>

            <draggable
              v-model="settingsObject.json.devices[cat].payment.cash_2"
              class="flex-column"
              @end="changeOrder(cat, 'payment', 'cash_2')"
            >
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.devices[cat].payment
                  .cash_2"
                :key="index"
              >
                <div class="table__col table__col_width_200">
                  <div class="table__text-block table__text-block--order">
                    <span
                      ><img
                        class="g-item__drag"
                        src="@/assets/images/drag.svg"
                        alt=""
                    /></span>
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="number" class="input" v-model="item.cost" />
                  </div>
                </div>
                <div class="table__col table__col_width_120">
                  <div class="table__text-block">
                    <input
                      type="number"
                      class="input"
                      v-model.number="item.count"
                    />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        :id="'myonoffswitch5' + index"
                        v-model="item.countAsOneKit"
                      />
                      <label
                        class="onoffswitch-label"
                        :for="'myonoffswitch5' + index"
                      >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!--                <div class="table__col table__col_width_260">-->
                <!--                  <div class="table__text-block">-->
                <!--                    <input type="text" class="input" v-model="item.desc" v-if="index > 0">-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="table__col table__col-btn" v-if="index > 0">
                  <div
                    class="table__text-block"
                    @click="
                      actionDevice('remove', cat, 'payment', 'cash_2', item.id)
                    "
                  >
                    <span class="remove-icon"></span>
                  </div>
                </div>
                <span
                  class="little-down-text little-down-text--devices little-down-text--hidden"
                  v-else
                ></span>
              </div>
            </draggable>
          </div>
          <span
            class="little-down-text little-down-text--devices"
            @click="actionDevice('add', cat, 'payment', 'cash_2')"
            >+ Добавить ещё</span
          >
        </div>
        <div v-if="tab === 4" class="table cm-table-1 cm-table--device">
          <div class="table__row-wrap сmp__row-wrap">
            <div class="table__row table__row_main table__row-head">
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <p class="table__text">Наименование</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Цена за ед. в $
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_120">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">Кол-во</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Один комплект
                  </p>
                </div>
              </div>
              <!--              <div class="table__col table__col_width_260">-->
              <!--                <div class="table__text-block">-->
              <!--                  <p class="table__text">Описание опции</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <span
                class="little-down-text little-down-text--devices little-down-text--hidden"
              ></span>
            </div>

            <draggable
              v-model="settingsObject.json.devices[cat].payment.cash_3"
              class="flex-column"
              @end="changeOrder(cat, 'payment', 'cash_3')"
            >
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.devices[cat].payment
                  .cash_3"
                :key="index"
              >
                <div class="table__col table__col_width_200">
                  <div class="table__text-block table__text-block--order">
                    <span
                      ><img
                        class="g-item__drag"
                        src="@/assets/images/drag.svg"
                        alt=""
                    /></span>
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="number" class="input" v-model="item.cost" />
                  </div>
                </div>
                <div class="table__col table__col_width_120">
                  <div class="table__text-block">
                    <input
                      type="number"
                      class="input"
                      v-model.number="item.count"
                    />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        :id="'myonoffswitch4' + index"
                        v-model="item.countAsOneKit"
                      />
                      <label
                        class="onoffswitch-label"
                        :for="'myonoffswitch4' + index"
                      >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!--                <div class="table__col table__col_width_260">-->
                <!--                  <div class="table__text-block">-->
                <!--                    <input type="text" class="input" v-model="item.desc" v-if="index > 0">-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="table__col table__col-btn" v-if="index > 0">
                  <div
                    class="table__text-block"
                    @click="
                      actionDevice('remove', cat, 'payment', 'cash_3', item.id)
                    "
                  >
                    <span class="remove-icon"></span>
                  </div>
                </div>
                <span
                  class="little-down-text little-down-text--devices little-down-text--hidden"
                  v-else
                ></span>
              </div>
            </draggable>
          </div>
          <span
            class="little-down-text little-down-text--devices"
            @click="actionDevice('add', cat, 'payment', 'cash_3')"
            >+ Добавить ещё</span
          >
        </div>
        <div
          v-if="tab === 5 && config.system !== 'economy'"
          class="table cm-table-1 cm-table--device"
        >
          <div class="table__row-wrap сmp__row-wrap">
            <div class="table__row table__row_main table__row-head">
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <p class="table__text">Наименование</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Цена за ед. в $
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_120">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">Кол-во</p>
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <div class="table__text-block">
                  <p class="table__text" style="padding-left: 0">
                    Один комплект
                  </p>
                </div>
              </div>
              <!--              <div class="table__col table__col_width_260">-->
              <!--                <div class="table__text-block">-->
              <!--                  <p class="table__text">Описание опции</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <span
                class="little-down-text little-down-text--devices little-down-text--hidden"
              ></span>
            </div>

            <draggable
              v-model="settingsObject.json.devices[cat].payment.cash_4"
              class="flex-column"
              @end="changeOrder(cat, 'payment', 'cash_4')"
            >
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.devices[cat].payment
                  .cash_4"
                :key="index"
              >
                <div class="table__col table__col_width_200">
                  <div class="table__text-block table__text-block--order">
                    <span
                      ><img
                        class="g-item__drag"
                        src="@/assets/images/drag.svg"
                        alt=""
                    /></span>
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="number" class="input" v-model="item.cost" />
                  </div>
                </div>
                <div class="table__col table__col_width_120">
                  <div class="table__text-block">
                    <input
                      type="number"
                      class="input"
                      v-model.number="item.count"
                    />
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        :id="'myonoffswitch5' + index"
                        v-model="item.countAsOneKit"
                      />
                      <label
                        class="onoffswitch-label"
                        :for="'myonoffswitch5' + index"
                      >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!--                <div class="table__col table__col_width_260">-->
                <!--                  <div class="table__text-block">-->
                <!--                    <input type="text" class="input" v-model="item.desc" v-if="index > 0">-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="table__col table__col-btn" v-if="index > 0">
                  <div
                    class="table__text-block"
                    @click="
                      actionDevice('remove', cat, 'payment', 'cash_4', item.id)
                    "
                  >
                    <span class="remove-icon"></span>
                  </div>
                </div>
                <span
                  class="little-down-text little-down-text--devices little-down-text--hidden"
                  v-else
                ></span>
              </div>
            </draggable>
          </div>
          <span
            class="little-down-text little-down-text--devices"
            @click="actionDevice('add', cat, 'payment', 'cash_4')"
            >+ Добавить ещё</span
          >
        </div>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(8, cat, false)">Превью</span>
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import SalesDraggableMixin from "@/mixins/sales/SalesDraggableMixin";

export default {
  mixins: [SalesHelpers, SalesDraggableMixin],
  props: {
    cat: null,
  },
  data() {
    return {
      tab: 1,
    };
  },

  methods: {},
};
</script>
