<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-Name"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-Uri"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Вступление:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.ShortText"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'ShortText'"
            :OriginalValue="fields.ShortText"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-ShortText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.ImageLink">
            <img :src="getImgSrc(fields.ImageLink)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Видео:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.VideoLink" />
        </span>
      </label>

      <label class="field cm-calendar-input">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Тип новости:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.NewsType">
              <option value="1">Стандарт</option>
              <option value="2">Промо</option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Дата:
        </span>
        <span class="field__content">
          <date-picker
            valueType="format"
            v-model="fields.DateCreate"
            :format="'YYYY-MM-DD'"
            lang="ru"
          ></date-picker>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__text-editor" v-if="tab === 2">
      <div class="skedit" v-if="parentData.language === 'ru'">
        <textarea v-tinymce field="FullNews" v-model="fields.FullNews" />
      </div>
      <translate-field
        :EntityColumnName="'FullNews'"
        :OriginalValue="fields.FullNews"
        :inputType="'editor'"
        :module="'SiteNews'"
        ref="translated-field-FullNews"
        @getTranslation="getTranslation"
        :translation="translation"
        :id="id"
      />
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            :OriginalValue="fields.SeoTitle"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-SeoTitle"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            :OriginalValue="fields.SeoDescription"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-SeoDescription"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            :OriginalValue="fields.SeoKeywords"
            :inputType="'input'"
            :module="'SiteNews'"
            ref="translated-field-SeoKeywords"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteNews'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
export default {
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
