<template>
  <div>
    <svg width="22" height="17" v-if="allIsSelected">
      <use
        xlink:href="/static/images/tasks/notification-settings-table-icons/sprite.svg#folder-checked-icon"
      ></use>
    </svg>
    <svg width="24" height="19" v-else-if="allIsNotSelected">
      <use
        xlink:href="/static/images/tasks/notification-settings-table-icons/sprite.svg#folder-empty-icon"
      ></use>
    </svg>
    <template v-else>
      <svg width="22" height="17">
        <use
          xlink:href="/static/images/tasks/notification-settings-table-icons/sprite.svg#folder-bordered-icon"
        ></use>
      </svg>
      <div class="notification-settings-table__control-count">
        {{ selectedItems.length }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NotificationSettingsTableTemolateState",
  props: ["items", "field"],
  computed: {
    allIsSelected() {
      const allCount = this.items.length;
      return allCount === this.selectedItems.length;
    },
    allIsNotSelected() {
      return !this.selectedItems.length;
    },
    selectedItems() {
      return this.items.filter((i) =>
        Number(i.GroupNotificationSetting[this.field])
      );
    },
  },
};
</script>

<style scoped></style>
