import Vue from "vue";
import Router from "vue-router";
import ClientSupport from "@/modules/support/views/ClientSupport";
import RpsSupport from "@/modules/support/views/RpsSupport";
import Clients from "@/modules/clients";
import Companies from "@/modules/clients/components/Company";
import Objects from "@/modules/clients/components/Object";
import Distb from "@/modules/clients/components/Distb";
import Settings from "@/modules/settings";
import CommercialSite from "@/modules/commercial_site";
import CommercialSiteTable from "@/modules/commercial_site/FrontTable";
import CommercialSiteEditPopup from "@/modules/commercial_site/EditPopup";
import Sales from "@/modules/sales";
import Tasks from "@/modules/tasks";
import TaskDetail from "@/modules/tasks/components/TaskDetail";
import Reservation from "@/modules/sales/reservation/";
import RequestDetail from "@/modules/sales/reservation/RequestDetail";
import ObjectsList from "@/modules/sales/reservation/lists/ObjectsList";
import RequestsList from "@/modules/sales/reservation/lists/RequestsList";
import ObjectDetail from "@/modules/sales/reservation/ObjectDetail";
import CreateObjectForm from "@/modules/sales/reservation/CreateObjectForm";
import Wiki from "@/modules/wiki";
import WikiSharedPost from "./modules/static_pages/WikiSharedPost/WikiSharedPost";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "home",
      component: Clients,
      children: [
        {
          path: "company/:id",
          name: "company",
          component: Companies,
        },
        {
          path: "object/:id",
          name: "object",
          component: Objects,
        },
        {
          path: "distb/:id",
          name: "distb",
          component: Distb,
        },
      ],
    },
    {
      path: "/clientsupport/",
      name: "client-support",
      component: ClientSupport,
    },
    {
      path: "/settings/",
      name: "settings",
      component: Settings,
    },
    {
      path: "/support",
      name: "rps-support",
      component: RpsSupport,
      children: [
        {
          path: ":id0",
          name: "support-task",
          component: TaskDetail,
          children: [
            {
              path: ":id2",
              name: "support-task2",
              component: TaskDetail,
              children: [
                {
                  path: ":id3",
                  name: "support-task3",
                  component: TaskDetail,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/sales",
      name: "sales",
      component: Sales,
      children: [
        {
          path: "reservation",
          name: "reservation",
          component: Reservation,
          children: [
            {
              path: "objects",
              name: "reserved-objects",
              component: ObjectsList,
              children: [
                {
                  path: "create",
                  name: "CreateObject",
                  component: CreateObjectForm,
                },
                {
                  path: ":id",
                  name: "ObjectDetail",
                  component: ObjectDetail,
                },
              ],
            },
            {
              path: "requests",
              name: "requests-objects",
              component: RequestsList,
              children: [
                {
                  path: ":id",
                  name: "reserveDetail",
                  component: RequestDetail,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/commercial_site/",
      name: "commercial_site",
      component: CommercialSite,
      children: [
        {
          path: ":module/",
          name: "module",
          component: CommercialSiteTable,
          props: true,
          children: [
            {
              path: ":id",
              name: "module_item",
              component: CommercialSiteEditPopup,
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "/tasks",
      name: "tasks",
      component: Tasks,
      children: [
        {
          path: ":id",
          name: "task",
          component: TaskDetail,
        },
      ],
    },
    {
      path: "/reservation",
      name: "reservation",
      component: Reservation,
      children: [
        {
          path: "objects",
          name: "reserved-objects",
          component: ObjectsList,
          children: [
            {
              path: "create",
              name: "CreateObject",
              component: CreateObjectForm,
            },
            {
              path: ":id",
              name: "ObjectDetail",
              component: ObjectDetail,
            },
          ],
        },
        {
          path: "requests",
          name: "requests-objects",
          component: RequestsList,
          children: [
            {
              path: ":id",
              name: "reserveDetail",
              component: RequestDetail,
            },
          ],
        },
      ],
    },
    {
      path:
        "/wiki/:category?/:post1?/:post2?/:post3?/:post4?/:post5?/:post6?/:post7?",
      name: "wiki",
      component: Wiki,
    },
    {
      path: "/wiki-shared/:slug",
      name: "wikiShare",
      component: WikiSharedPost,
    },
    // other urls
    // ----------------------
    // {
    //   path: '*',
    //   redirect: { name: 'home' }
    // }
    // ----------------------
  ],
});
