<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_11 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_11 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('Tasks')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div class="new-changes-block">
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>{{ offerObject.json.Tasks }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <p>{{ compareObject.Tasks }}</p>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('Tasks'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div class="pdf-settings__cont pdf-settings__center">
        <div class="pdf-settings__info-text">
          <div class="pdf-settings__info-text">
            Данные для этой страницы <br />
            редактировать нельзя.
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(10)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(12)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_11 from "../prints/print_11";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  components: { Print_11 },
};
</script>
