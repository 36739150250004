<template>
  <div>
    <date-picker
      v-model="localDate"
      confirm
      type="date"
      :format="format ? format : 'YYYY-MM-DD HH:mm'"
      :clearable="false"
      :disabled="disabled ? disabled : false"
      @panel-change="close"
      :disabled-date="
        (date) => date < new Date(new Date().setDate(new Date().getDate() - 1))
      "
      :append-to-body="appendToBody"
      ref="datepicker"
      @confirm="confirm"
      v-click-outside="closeDatepicker"
    >
      <template slot="input">
        <template v-if="date">
          <template v-if="!rangeValue">
            <input
              :value="getDate"
              type="text"
              autocomplete="off"
              name="date"
              placeholder="Выберите дату"
              class="mx-input"
              style="pointer-events: none"
            />
          </template>
          <template v-else>
            <div class="task-table-range-date__inner-wrapper">
              {{ rangeValue }}
              <span class="task-table-range-date__arrow">
                <svg
                  width="18"
                  height="7"
                  viewBox="0 0 18 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.297 6.6C13.793 5.576 14.257 4.808 14.689 4.296H0.696977V3.288H14.689C14.257 2.776 13.793 2.008 13.297 0.983999H14.137C15.145 2.152 16.201 3.016 17.305 3.576V4.008C16.201 4.552 15.145 5.416 14.137 6.6H13.297Z"
                    fill="black"
                  />
                </svg>
              </span>
              {{ getDate }}
            </div>
          </template>
        </template>
        <p v-else>
          <template v-if="placeholder">{{ placeholder }}</template>
          <template v-else>Выберите дату</template>
        </p>
      </template>

      <div
        slot="footer"
        slot-scope="{}"
        :class="{
          'mx-datepicker--disabled-btn': needReasonToChange && !reasonComment,
        }"
      >
        <div class="mx-datepicker-footer mx-datepicker-footer--time">
          <date-picker
            v-model="time"
            class="date-time-picker"
            :time-picker-options="{
              start: '08:00',
              step: '00:30',
              end: '23:30',
            }"
            format="HH:mm"
            :clearable="true"
            type="time"
            placeholder="HH:mm"
            :popup-style="{ visibility: 'hidden' }"
          >
            // часы
            <template slot="input">
              <img
                src="/static/images/tasks/icons/clock-icon.svg"
                class="time-icon"
                alt=""
              />
              <input
                type="text"
                class="mx-input"
                v-mask="'##:##'"
                :value="time | hours"
                :clearable="false"
                @change="setLocalTime($event.target.value)"
                @click="timePickerOpen = !timePickerOpen"
                placeholder="Добавить время"
              />

              <transition name="fade">
                <div
                  class="mx-datepicker-main mx-datepicker-popup"
                  v-if="timePickerOpen"
                  v-click-outside="closeTimePicker"
                >
                  <!---->
                  <div class="mx-datepicker-content">
                    <!---->
                    <div class="mx-datepicker-body">
                      <div class="mx-time">
                        <!---->
                        <div class="mx-time-content">
                          <div
                            class="mx-scrollbar"
                            style="position: relative; overflow: hidden"
                          >
                            <div class="mx-scrollbar-wrap">
                              <div
                                class="mx-time-option"
                                v-for="(time, index) in timePickerOptions"
                                :key="index"
                                @click="
                                  () => {
                                    setLocalTime(time);
                                    timePickerOpen = false;
                                  }
                                "
                              >
                                {{ time }}
                              </div>
                            </div>
                            <div class="mx-scrollbar-track">
                              <div
                                class="mx-scrollbar-thumb"
                                style="height: 19.4444%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!---->
                  </div>
                </div>
              </transition>
            </template>
          </date-picker>
        </div>
        <div class="mx-input--reason" v-if="needReasonToChange">
          <input
            type="text"
            class="mx-input"
            v-model="reasonComment"
            placeholder="Укажите обязательно причину"
          />
        </div>
        <div
          class="mx-datepicker-footer mx-datepicker-footer--notification"
          v-if="checklistItem"
        >
          <ChecklistNotificationControl
            :item="checklistItem"
            :form="form"
            ref="notification-control"
          />
        </div>
        <!--<div class="mx-datepicker-footer">-->
        <!--<button type="button" class="mx-datepicker-btn mx-datepicker-btn-confirm" @click="confirm">OK</button>-->
        <!--</div>-->
        <div class="mx-datepicker-footer__reset-btn mx-btn" @click="reset">
          Сброс
        </div>
      </div>
    </date-picker>
  </div>
</template>

<script>
import moment from "moment";
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import ChecklistNotificationControl from "./ChecklistNotificationControl";
import * as _ from "lodash";
import { showAlert } from "@/utils";

export default {
  mixins: [common, Helpers],
  props: [
    "date",
    "format",
    "disabled",
    "placeholder",
    "timeValue",
    "formatOutput",
    "checklistItem",
    "appendToBody",
    "open",
    "form",
    "afterConfirm",
    "rangeValue",
    "bottomText",
    "needReasonToChange",
  ],
  data() {
    return {
      time: "",
      localDate: "",
      customToolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { list: "ordered" },
          { list: "bullet" },
        ],
      ],
      timePickerOpen: false,
      timePickerOptions: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      reasonComment: "",
    };
  },
  created() {
    this.localDate = moment(this.date).toDate();
    this.time = this.date
      ? moment(this.date).toDate()
      : moment("09:00", "HH:mm").toDate();
    if (this.timeValue && this.date) {
      this.time = moment(this.timeValue, "HH:mm:ss").toDate();
    }
  },
  computed: {
    getDate() {
      if (!this.timeValue && !this.rangeValue) {
        return this.$options.filters.formatLocalDate(this.localDate);
      } else {
        const date = moment(this.localDate);
        const time = moment(this.timeValue, "HH:mm:ss");
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
        if (this.rangeValue) {
          return date.format("DD.MM.YYYY");
        }
        return date.locale("ru").format("DD MMM YYYY в HH:mm");
      }
    },
  },
  methods: {
    close() {
      this.localDate = this.date;
      this.time = this.date;
      if (this.timeValue && this.date) {
        this.time = moment(this.timeValue, "HH:mm:ss").toDate();
      }
      this.timePickerOpen = false;
    },
    closeTimePicker() {
      this.timePickerOpen = false;
    },
    clearTime() {
      const time = moment(this.time);
      time.set({ hour: 0, minute: 0 });
      time.toISOString();
      time.format();
      this.time = time.toDate();
    },
    setLocalTime(value) {
      // const oldTime = this.time;

      const hour = value.split(":")[0];
      const minute = value.split(":")[1];
      const time = this.time ? moment(this.time) : moment();
      time.set({ hour, minute });
      time.toISOString();
      time.format();
      this.time = time.toDate();

      // this.setTime(this.localDate, "", oldTime);
    },
    setTime(e, t, oldTime = false, force = false) {
      if (this.needReasonToChange && !this.reasonComment) {
        showAlert("error", "", "Укажите причину смены даты");
        return;
      } else {
        const m = e ? moment(e) : moment();
        let time;
        if (this.time) {
          time = moment(this.time);
        } else {
          time = moment();
          time.set({ hour: "09", minute: "00" });
        }
        const hour = time.format("HH");
        const minute = time.format("mm");
        m.set({ hour, minute });
        m.toISOString();
        m.format();
        e = m.toDate();

        const context = this;
        const oldDate = this.localDate;
        const setN = function () {
          if (context.checklistItem) {
            context.setNotificationDate(e, oldTime, oldDate, context);
          }
        };

        if (force) {
          if (this.formatOutput) {
            this.$emit(
              "setDate",
              m.format(this.formatOutput),
              setN,
              this.reasonComment
            );
          } else {
            this.$emit("setDate", e, setN, this.reasonComment);
          }
        }
      }
    },
    closeDatepicker() {
      // console.log(this.$refs.datepicker)
      this.$refs.datepicker.closePopup();
    },
    setNotificationDate(date, oldTime, oldDate, context) {
      let notificationDate = _.find(context.checklistItem.Notifications, [
        "NotificationType",
        "1",
      ]);
      // console.log(moment(this.time).format('HH:mm'))

      if (
        notificationDate &&
        notificationDate.NotificationDate &&
        notificationDate.NotificationTime
      ) {
        let dateOfNotification = moment(notificationDate.NotificationDate);
        let timeOfNotification = moment(
          notificationDate.NotificationTime,
          "HH:mm:ss"
        );
        dateOfNotification.set({
          hour: timeOfNotification.format("HH"),
          minute: timeOfNotification.format("mm"),
        });

        // console.log('old ', this.localDate, 'new', e)
        const admission = moment(oldDate);
        const time = oldTime || context.time;
        admission.set({
          hour: moment(time).format("HH"),
          minute: moment(time).format("mm"),
        });
        const discharge = dateOfNotification;
        const minutes = admission.diff(discharge, "minutes");
        //
        //
        date = moment(date);
        date.set({
          hour: moment(context.time).format("HH"),
          minute: moment(context.time).format("mm"),
        });

        // console.log(moment(date).format('HH:mm:ss'), minutes)

        date.subtract(minutes, "minutes");

        // console.log(moment(date).format('HH:mm:ss'), minutes)
        // console.log(admission.format('DD.MM.YYYY HH:mm'), discharge.format('DD.MM.YYYY HH:mm'))
        // console.log(dateOfNotification.format('DD.MM.YYYY HH:mm'), minutes)
        notificationDate = JSON.parse(JSON.stringify(notificationDate));
        notificationDate.NotificationDate = date;
        notificationDate.NotificationTime = moment(date).format("HH:mm:ss");

        console.log(moment(date).format("HH:mm:ss"));

        setTimeout(() =>
          context.$refs["notification-control"].saveOrCreate(notificationDate)
        );
      }
    },
    confirm(e) {
      if (this.needReasonToChange && !this.reasonComment) return;
      // if (this.afterConfirm) {
      this.setTime(e, "", false, true);
      // }
    },
    reset() {
      // this.localDate = "";
      // this.time = "";
      if (this.needReasonToChange && !this.reasonComment) return;
      this.$emit("setDate", null);
      this.closeDatepicker();
      // this.date = "";
    },
  },
  watch: {
    date() {
      this.localDate = moment(this.date).toDate();
      this.time = this.date
        ? moment(this.date).toDate()
        : moment("09:00", "HH:mm").toDate();
      if (this.timeValue && this.date) {
        this.time = moment(this.timeValue, "HH:mm:ss").toDate();
      }
    },
  },
  filters: {
    hours(date) {
      return date ? moment(date).format("HH:mm") : "";
    },
  },
  components: {
    ChecklistNotificationControl,
  },
};
</script>

<style scoped></style>
