<template>
  <router-link :to="routerParams">{{ item.Name }}</router-link>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "SearchResultItem",
  props: ["item", "categories", "articles"],
  computed: {
    routerParams() {
      const categorySlug = _.find(this.categories, ["Id", this.item.CategoryId])
        .Slug;
      let parents = [];
      function addToArr(arr, item) {
        if (item && item.Id) {
          arr.push(item);
          addToArr(parents, item.parent);
        }
      }
      addToArr(parents, this.item.parent);
      parents = parents.reverse();
      //
      let params = { name: "wiki", params: { category: categorySlug } };
      //
      // parents = parents.reverse()
      //
      _.each(parents, (parent, index) => {
        const postIndex = index + 1;
        params.params["post" + postIndex] = parent.Name;
      });
      const postIndex = parents.length + 1;
      params.params["post" + postIndex] = this.item.Name;

      console.log(params);
      return params;
    },
  },
};
</script>

<style scoped></style>
