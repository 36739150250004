<template>
  <div class="doc-viewer">
    <div class="doc-viewer__inner" v-click-outside="close">
      <div class="table-spinner" style="height: 100%" v-show="preloader">
        <Spinner
          :noFadeIn="true"
          name="line-scale-party"
          color="#c9eeff"
          width="60"
          height="60"
        />
      </div>
      <object v-if="fileType === 'pdf'" :data="link" type="application/pdf">
        <embed :src="link" type="application/pdf" />
      </object>
      <iframe
        v-else
        :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + src"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Helpers } from "@/mixins/Helpers";
import { API_ROOT } from "@/config";

export default {
  name: "PdfAndDocsViewer",
  mixins: [Helpers],
  props: ["src", "id"],
  data() {
    return {
      fileType: null,
      numPages: undefined,
      preloader: true,
      link: "",
    };
  },
  created() {
    this.link =
      API_ROOT +
      "api/tasks/v2/tasksFiles/DownloadFile?FileId=" +
      this.id +
      "&ToBrowser=1";
    // this.link = this.src;

    if (this.src) {
      const ext = this.src.split(".").pop();
      this.fileType = ext;
      setTimeout(() => {
        this.preloader = false;
      }, 1000);
    }
  },
  computed: {
    ...mapState("tasks", ["settings"]),
  },
  methods: {
    close() {
      this.settings.docFileToView = null;
    },
  },
  components: {},
};
</script>

<style scoped></style>
