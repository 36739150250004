<template>
  <div id="entry-wrap" class="scroll-style print--10">
    <div class="pdf-cont" v-for="(num, index) in totalPages" :key="index">
      <div class="print">
        <div class="print__cont pb-0">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            Итоговая смета <br />и условия
          </div>
          <div class="print-table">
            <div class="print-table__header mb-0" v-if="!endPage(num)">
              <div class="col print-table__th">наименование</div>
              <div class="print-table__th">
                стоимость в
                {{ getCurrencyAbbreviation === "$" ? "долларах" : "рублях" }}.
              </div>
            </div>
            <div class="print-table__inner">
              <div class="print-table__row" v-if="num === 1">
                <div class="col print-table__row-td">Оборудование</div>
                <div
                  class="print-table__row-td"
                  :class="{
                    'red-color': offerObject.json.totalDevicesPriceDiscont,
                  }"
                >
                  {{ totalDevicesPrice | moneyFormat }}
                </div>
              </div>

              <div
                class="print-table__row"
                v-for="(item, index) in filteredTypes.filter(
                  (i, index) => checkTotalRowsLimit(num, index) && !endPage(num)
                )"
                :key="index"
                :class="getRowClassName(item)"
              >
                <div class="col print-table__row-td">{{ item.name }}</div>
                <div class="print-table__row-td print-table__price-col">
                  {{ item.convertCost | moneyFormat }}
                </div>
              </div>
            </div>
            <template v-if="!endPage(num)">
              <div
                class="print-table__total print-table__total--grouped print-table__total--ml"
              >
                <div class="print-table__total-row" v-if="getDiscountValue">
                  <div class="col print-table__total-td">Ваша скидка:</div>
                  <div class="print-table__total-td">
                    {{ getDiscountValue | moneyFormat }}
                  </div>
                </div>
                <div class="print-table__total-row">
                  <div class="col print-table__total-td">
                    общая стоимость
                    <template v-if="getDiscountValue">
                      c учётом скидки</template
                    >
                    :
                  </div>
                  <div class="print-table__total-td print-table__total-td--big">
                    {{ totalSumPrice | moneyFormat }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            class="print__text print__text--m-large"
            v-if="offerObject.json.System === 'economy'"
            style="text-align: center"
          >
            <p>
              Нашли дешевле? Пришлите нам КП конкурентов и мы сделаем ещё
              дешевле.
            </p>
          </div>
          <br /><br /><br />
          <div class="print-benefits" v-if="endPage(num, true)">
            <div class="print-benefits__item">
              <div class="print-benefits__img">
                <img :src="getimgStaticSrc('sales/delivery.png')" />
              </div>
              <div class="print-benefits__cont">
                <div class="print-benefits__label">Доставка:</div>
                <div class="print-benefits__name">
                  {{ offerObject.json.Total.Delivery }}
                </div>
              </div>
            </div>
            <div class="print-benefits__item">
              <div class="print-benefits__img">
                <img :src="getimgStaticSrc('sales/term.png')" />
              </div>
              <div class="print-benefits__cont">
                <div class="print-benefits__label">Срок автоматизации:</div>
                <div class="print-benefits__name">
                  {{ offerObject.json.Total.Date }}
                </div>
              </div>
            </div>
            <div class="print-benefits__item">
              <div class="print-benefits__img">
                <img :src="getimgStaticSrc('sales/warranty.png')" />
              </div>
              <div class="print-benefits__cont">
                <div class="print-benefits__label">Гарантия:</div>
                <div class="print-benefits__name">
                  {{ offerObject.json.Total.Guarantee }}
                </div>
              </div>
            </div>
            <div class="print-benefits__item">
              <div class="print-benefits__img">
                <img :src="getimgStaticSrc('sales/terms-of-payment.png')" />
              </div>
              <div class="print-benefits__cont">
                <div class="print-benefits__label">Условия оплаты:</div>
                <div class="print-benefits__name">
                  {{ offerObject.json.Total.Payment }}
                </div>
              </div>
            </div>
          </div>
          <p
            class="print-currency-text"
            v-if="showCurrency && getCurrencyAbbreviation !== '$'"
          >
            Цены указаны с привязкой курса доллара США по ЦБ РФ <br />
            на дату выставления КП
          </p>
        </div>

        <div class="print__footer">{{ countPage("Total", num) }}</div>
        <!--<div class="print__footer">{{pageNums.Additional + 1}}</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {
    endPage(num, iconsBlock = false) {
      if (iconsBlock && num === 1 && this.totalPages <= 1) return true;
      if (num === 1) return false;
      return this.totalPages === num;
    },
  },
  computed: {
    getCurrencyNumber() {
      // return parseFloat(this.offerObject.json.rateValue).toFixed(2)
      return parseFloat(this.rateValue).toFixed(2);
    },
    showCurrency() {
      return this.offerObject.json.showCurrency === void 0
        ? true
        : this.offerObject.json.showCurrency;
    },
    ...mapState("sales", ["offerObject", "pageNums", "rateValue"]),
    ...mapGetters("sales", ["getCurrencyAbbreviation", "getDiscountValue"]),
  },
};
</script>
