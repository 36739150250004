<template>
  <div class="checklist-notification-control">
    <span
      class="task-create-form-check-list__input task-popup__nav-item--datepicker"
      v-click-outside-in-datepicker="closeSelectListDate"
    >
      <i class="checklist-notification-control__icon">
        <img src="/static/images/tasks/icons/alarm-icon.svg" alt="icon" />
      </i>
      <span
        class="task-create-form-check-list__input__close"
        @click="destroyDate"
      >
        <svg class="popup__close-icon">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/icon.svg#icon_icon-close"
          ></use>
        </svg>
      </span>

      <!--           <date-input-->
      <!--             :timeValue="notificationDate.NotificationTime"-->
      <!--             :date="notificationDate.NotificationDate"-->
      <!--             :format="'DD MMMM YYYY в HH:mm'"-->
      <!--             :formatOutput="'YYYY-MM-DD HH:mm:ss'"-->
      <!--             @setDate="setNotificationDate($event)"-->
      <!--             :appendToBody="false"-->
      <!--           />-->
      <input
        type="text"
        class="input"
        @click="
          (e) => {
            showSelectListDate = true;
            e.target.blur();
          }
        "
        :value="getNotificationDateForInput"
        :class="{
          'input--filled':
            notificationDate.NotificationDate &&
            notificationDate.NotificationTime,
        }"
        style="pointer-events: all"
      />
      <transition name="fade">
        <div v-if="showSelectListDate">
          <div class="select-d__dropdown">
            <div class="select-d__dropdown-list">
              <div
                class="select-d__dropdown-item select-d__dropdown-item--disabled"
                v-if="!item.Deadline"
              >
                Установите дату задания чек-листа
              </div>
              <template v-else>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(1)"
                >
                  за 30 мин
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(2)"
                >
                  за 1 час
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(3)"
                >
                  за 2 часа
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(4)"
                >
                  за 3 часа
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(5)"
                >
                  за 6 часов
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(6)"
                >
                  за 12 часов
                </div>
                <div
                  class="select-d__dropdown-item"
                  @click="setNotificationDate(7)"
                >
                  За 1 день
                </div>
              </template>
            </div>
          </div>
        </div>
      </transition>
    </span>
    <div
      class="task-create-form-check-list__input"
      v-click-outside-in-datepicker="closeSelectListGeo"
    >
      <i class="checklist-notification-control__icon">
        <img src="/static/images/tasks/icons/geo-icon.svg" alt="icon" />
      </i>
      <span
        class="task-create-form-check-list__input__close"
        @click="destroyGeo"
      >
        <svg class="popup__close-icon">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/icon.svg#icon_icon-close"
          ></use>
        </svg>
      </span>
      <input
        type="text"
        class="input"
        @click="
          (e) => {
            showSelectListGeo = true;
            e.target.blur();
          }
        "
        :value="getValueForInput"
        :class="{ 'input--filled': geo }"
      />
      <transition name="fade">
        <div v-if="showSelectListGeo">
          <div class="select-d__dropdown">
            <div class="select-d__dropdown-list">
              <div
                class="select-d__dropdown-item"
                :class="{
                  'select-d__dropdown-item--disabled':
                    !taskGeo || taskGeo.lat === 0,
                }"
                @click="setGeoFromTask"
              >
                Использовать адрес в задаче
              </div>
              <div
                class="select-d__dropdown-item"
                @click="
                  () => {
                    gmapWindow = true;
                    showSelectListGeo = false;
                  }
                "
              >
                Указать другой адрес
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <v-dialog
      class="offer-gmap-dialog"
      v-model="gmapWindow"
      width="600px"
      v-if="gmapWindow"
    >
      <google-map
        @close="gmapWindow = false"
        @setGeoByMap="setGeoByMap"
        :setPosition="geo"
      />
    </v-dialog>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import GoogleMap from "./GoogleMap";
import { mapActions, mapState } from "vuex";
import * as c from "../../../../store/tasks/constants";
import moment from "moment";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";

export default {
  mixins: [common, Helpers],
  props: ["item", "form"],
  data() {
    return {
      gmapWindow: false,
      showSelectListGeo: false,
      showSelectListDate: false,
    };
  },
  computed: {
    notificationDate() {
      return (
        _.find(this.item.Notifications, ["NotificationType", "1"]) || {
          NotificationType: "1",
          NotificationDate: "",
          NotificationTime: "",
          NotificationLatitude: "",
          NotificationLongitude: "",
        }
      );
    },
    notificationGeo() {
      return (
        _.find(this.item.Notifications, ["NotificationType", "2"]) || {
          NotificationType: "2",
          NotificationDate: "",
          NotificationTime: "",
          NotificationLatitude: "",
          NotificationLongitude: "",
        }
      );
    },
    geo() {
      if (
        this.notificationGeo.NotificationLatitude &&
        this.notificationGeo.NotificationLongitude
      ) {
        return {
          lat: +this.notificationGeo.NotificationLatitude,
          lng: +this.notificationGeo.NotificationLongitude,
        };
      }
      return null;
    },
    getValueForInput() {
      if (this.geo) {
        return `${this.geo.lat} ${this.geo.lng}`;
      }
      return "Напомнить по geo";
    },
    getNotificationDateForInput() {
      if (
        this.notificationDate.NotificationDate &&
        this.notificationDate.NotificationTime
      ) {
        const date = moment(this.notificationDate.NotificationDate);
        const time = moment(this.notificationDate.NotificationTime, "HH:mm:ss");
        date.set({ hour: time.format("HH"), minute: time.format("mm") });

        return date.locale("ru").format("DD MMM YYYY в HH:mm");
      } else {
        return "Напомнить по времени";
      }
    },
    taskGeo() {
      if (
        this.selectedTask &&
        this.selectedTask.entityModel &&
        this.selectedTask.entityModel.TaskLongitude &&
        this.selectedTask.entityModel.TaskLatitude
      ) {
        return {
          lat: +this.selectedTask.entityModel.TaskLatitude,
          lng: +this.selectedTask.entityModel.TaskLongitude,
        };
      } else if (
        this.form &&
        this.form.TaskLongitude &&
        this.form.TaskLatitude
      ) {
        return {
          lat: +this.form.TaskLatitude,
          lng: +this.form.TaskLongitude,
        };
      }
      return false;
    },
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    setNotificationDate(type) {
      // this.notificationDate.NotificationDate = date
      // this.notificationDate.NotificationTime = moment(date).format('HH:mm:ss')
      // this.notificationDate.NotificationLatitude = ''
      // this.notificationDate.NotificationLongitude = ''
      // this.notificationDate.NotificationType = '1'
      let date = moment(this.item.Deadline);
      let time = false;
      if (this.item.DeadlineTime) {
        time = moment(this.item.DeadlineTime, "HH:mm:ss");
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
      }

      // date.locale('ru').format('DD MMM YYYY в HH:mm')

      switch (type) {
        case 1:
          date.subtract("30", "minutes");
          break;
        case 2:
          date.subtract("1", "hours");
          break;
        case 3:
          date.subtract("2", "hours");
          break;
        case 4:
          date.subtract("3", "hours");
          break;
        case 5:
          date.subtract("6", "hours");
          break;
        case 6:
          date.subtract("12", "hours");
          break;
        case 7:
          date.subtract("1", "days");
          break;
      }

      this.notificationDate.NotificationDate = date;
      this.notificationDate.NotificationTime = moment(date).format("HH:mm:ss");

      this.showSelectListDate = false;
      this.saveOrCreate(this.notificationDate);
    },
    setGeoByMap(position) {
      this.notificationGeo.NotificationDate = "";
      this.notificationGeo.NotificationTime = "";
      this.notificationGeo.NotificationLatitude = +position.lat.toFixed(8);
      this.notificationGeo.NotificationLongitude = +position.lng.toFixed(8);
      this.notificationGeo.NotificationType = "2";
      this.saveOrCreate(this.notificationGeo);
    },
    destroyDate() {
      // this.notificationDate.NotificationDate = ''
      // if (!this.geo) {
      //   this.notificationDate.NotificationType = null
      this.delete(this.notificationDate);
      // }
    },
    destroyGeo() {
      // this.notificationGeo.NotificationLatitude = ''
      // this.notificationGeo.NotificationLongitude = ''
      // if (!this.notificationDate.NotificationDate) {
      // this.notificationDate.NotificationType = null
      this.delete(this.notificationGeo);
      // }
    },
    openGoogleMap(e) {
      if (!e.target.closest(".task-create-form-check-list__input__close")) {
        this.gmapWindow = true;
      }
    },
    saveOrCreate(data) {
      if (this.item.Id) {
        if (!data.Id) {
          this[c.CREATE_CHECKLIST_ITEM_NOTIFICATION]({
            ...data,
            ChecklistItemId: this.item.Id,
          });
        } else {
          this[c.UPDATE_CHECKLIST_ITEM_NOTIFICATION](data);
        }
      } else {
        let found = _.find(this.item.Notifications, [
          "NotificationType",
          data.NotificationType,
        ]);
        if (found) {
          // found = data
          this.item.Notifications = _.map(this.item.Notifications, (i) => {
            if (i.NotificationType === found.NotificationType) i = data;
            return i;
          });
          this.item.Notifications = JSON.parse(
            JSON.stringify(this.item.Notifications)
          );
        } else {
          this.item.Notifications.push(data);
        }
      }
    },
    delete(data) {
      if (data.Id && this.item.Id && this.item.Notifications.length) {
        // console.log(this.notificationDate)
        this[c.DELETE_CHECKLIST_ITEM_NOTIFICATION](data.Id);
      } else if (!this.item.Id) {
        this.item.Notifications = _.reject(this.item.Notifications, [
          "NotificationType",
          data.NotificationType,
        ]);
      }
    },
    closeSelectListGeo() {
      this.showSelectListGeo = false;
    },
    closeSelectListDate() {
      this.showSelectListDate = false;
    },
    setGeoFromTask() {
      this.notificationGeo.NotificationLatitude = this.taskGeo.lat.toFixed(8);
      this.notificationGeo.NotificationLongitude = this.taskGeo.lng.toFixed(8);
      this.saveOrCreate(this.notificationGeo);
      this.showSelectListGeo = false;
    },
    ...mapActions("tasks", [
      c.UPDATE_CHECKLIST_ITEM_NOTIFICATION,
      c.DELETE_CHECKLIST_ITEM_NOTIFICATION,
      c.CREATE_CHECKLIST_ITEM_NOTIFICATION,
    ]),
  },
  components: {
    GoogleMap,
  },
};
</script>

<style scoped></style>
