import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    companies: null,
    objects: null,
    devices: null,
    distbs: null,
    distbsCompanies: null,
    distbsObjects: null,
    distbsContacts: null,
    preloaders: {
      main: false,
      company: false,
      object: false,
      devices: false,
      distb: false,
    },
    selectedCompany: null,
    selectedObject: null,
    selectedDistb: null,
    searchInput: "",
    sort: { name: null, type: null },
    selectedItems: [],
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
