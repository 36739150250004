export const GET_OBJECTS = "GET_OBJECTS";
export const SILENT_LOAD_OBJECTS = "SILENT_LOAD_OBJECTS";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const SET_SORT = "SET_SORT";
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
export const REMOVE_SELECTED_ITEMS = "REMOVE_SELECTED_ITEMS";
export const SET_OBJECTS = "SET_OBJECTS";
export const SET_OBJECTS_FROM_REMOVE = "SET_OBJECTS_FROM_REMOVE";
export const GET_COMPANIES = "GET_COMPANIES";
export const SET_COMPANIES = "SET_COMPANIES";
export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const SELECT_OBJECT = "SELECT_OBJECT";
export const UPDATE_OBJECT = "UPDATE_OBJECT";
export const CREATE_OBJECT = "CREATE_OBJECT";
export const DELETE_OBJECT = "DELETE_OBJECT";
export const PATCH_OBJECT = "PATCH_OBJECT";
export const GENERATE_LICENSE = "GENERATE_LICENSE";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const CREATE_DISTB = "CREATE_DISTB";
export const GET_DISTB = "GET_DISTB";
export const GET_SINGLE_DISTB = "GET_SINGLE_DISTB";
export const SET_DISTB = "SET_DISTB";
export const SELECT_DISTB = "SELECT_DISTB";
export const UPDATE_DISTB = "UPDATE_DISTB";
export const DELETE_DISTB = "DELETE_DISTB";
export const ATTACH_COMPANY_TO_DISTB = "ATTACH_COMPANY_TO_DISTB";
export const ATTACH_OBJECT_TO_DISTB = "ATTACH_OBJECT_TO_DISTB";
export const REMOVE_ATTACH_COMPANY_TO_DISTB = "REMOVE_ATTACH_COMPANY_TO_DISTB";
export const REMOVE_ATTACH_OBJECT_TO_DISTB = "REMOVE_ATTACH_OBJECT_TO_DISTB";
export const GET_DISTB_COMPANIES = "GET_DISTB_COMPANIES";
export const GET_DISTB_OBJECTS = "GET_DISTB_OBJECTS";
export const GET_DISTB_CONTACTS = "GET_DISTB_CONTACTS";
export const CREATE_DISTB_CONTACT = "CREATE_DISTB_CONTACT";
export const UPDATE_DISTB_CONTACT = "UPDATE_DISTB_CONTACT";
export const PUSH_DISTB_CONTACTS = "PUSH_DISTB_CONTACTS";
export const UPDATE_CONTACT_DISTB = "UPDATE_CONTACT_DISTB";
export const CREATE_CONTACT_DISTB = "CREATE_CONTACT_DISTB";
export const DELETE_CONTACT_DISTB = "DELETE_CONTACT_DISTB";
