var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkUserTaskRight('write_comments_to_task'))?_c('div',{ref:"triggerZone",staticClass:"task-comment-textarea",class:{ 'task-comment-textarea--reply': _vm.replyComment },style:({ width: _vm.width })},[_c('div',{staticClass:"task-comments-item__left-col"},[_c('div',{staticClass:"task-comments-item__avatar"},[(_vm.userData)?_c('UserAvatar',{attrs:{"user":_vm.userData}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"task-comments-item__lock",on:{"click":function($event){_vm.commentWillBeHide = !_vm.commentWillBeHide;
        _vm.usersWhoWillSeeHiddenComment = [];}}},[(!_vm.commentWillBeHide)?_c('svg',{attrs:{"width":"16","height":"18"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#lock-open-icon"}})]):_c('svg',{attrs:{"width":"16","height":"18"}},[_c('use',{attrs:{"xlink:href":"/static/images/tasks/sprite.svg#lock-close-icon"}})])])]),_vm._v(" "),_c('div',{staticClass:"task-comment-textarea__text"},[(_vm.showReplyBar)?_c('div',{staticClass:"task-comment-reply"},[_c('div',{staticClass:"task-comment-reply__person"},[_vm._v("\n        "+_vm._s(_vm._f("shortName")(_vm.replyComment.AuthorName))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"task-comment-reply__text",domProps:{"innerHTML":_vm._s(_vm.replyComment.Comment)}},[_vm._v("}")]),_vm._v(" "),_c('div',{staticClass:"task-comment-reply__close",on:{"click":function () {
            _vm.$emit('removeReply');
            _vm.message = '';
          }}},[_c('svg',{staticClass:"popup__close-icon"},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"/static/images/icon.svg#icon_icon-close"}})])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"task-editor-wrapper"},[_c('vue-editor',{ref:"editor",staticClass:"task-create-form__text task-editor control-comment-state",class:{
          'control-comment-state--focus': _vm.isFocus || _vm.message || _vm.files.length,
        },attrs:{"editorToolbar":_vm.customToolbar,"placeholder":"Введите ваш комментарий здесь...","editor-options":{ bounds: '#task-editor_task-comment' },"id":"task-editor_task-comment"},on:{"text-change":_vm.watchForSubscribe,"selection-change":_vm.watchEditorSelection,"blur":function($event){_vm.saveUnsendedMessageToLocalStorage(_vm.message);
          _vm.setFocusFalse();},"focus":function($event){_vm.isFocus = true}},nativeOn:{"paste":function($event){return _vm.onPaste($event)},"keydown":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.metaKey){ return null; }return _vm.sendComment($event)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_vm._v(" "),_c('div',{staticClass:"task-create-form__additional-toolbar"},[_c('label',{staticClass:"task-create-form__additional-toolbar-item",on:{"click":function($event){_vm.isFocus = true}}},[_c('input',{attrs:{"type":"file","hidden":"","multiple":""},on:{"change":function($event){_vm.filesChange($event.target.files)}}}),_vm._v(" "),_c('svg',{attrs:{"focusable":"false","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M19,32c-3.9,0-7-3.1-7-7V10c0-2.2,1.8-4,4-4s4,1.8,4,4v9c0,0.6-0.4,1-1,1s-1-0.4-1-1v-9c0-1.1-0.9-2-2-2s-2,0.9-2,2v15c0,2.8,2.2,5,5,5s5-2.2,5-5V10c0-4.4-3.6-8-8-8s-8,3.6-8,8v5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5C6,4.5,10.5,0,16,0s10,4.5,10,10v15C26,28.9,22.9,32,19,32z"}})])]),_vm._v(" "),_c('label',{staticClass:"task-create-form__additional-toolbar-item",on:{"click":function($event){_vm.markUserByChar();
            _vm.isFocus = true;}}},[_c('svg',{attrs:{"focusable":"false","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16c3.7,0,7.3-1.3,10.1-3.6c0.4-0.3,0.5-1,0.1-1.4c-0.3-0.4-1-0.5-1.4-0.1c-2.5,2-5.6,3.1-8.8,3.1C8.3,30,2,23.7,2,16S8.3,2,16,2s14,6.3,14,14v1.5c0,1.9-1.6,3.5-3.5,3.5S23,19.4,23,17.5V16c0-3.9-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7c2.3,0,4.4-1.1,5.7-2.9c0.9,1.7,2.8,2.9,4.8,2.9c3,0,5.5-2.5,5.5-5.5V16C32,7.2,24.8,0,16,0z M16,21c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S18.8,21,16,21z"}})])])])],1),_vm._v(" "),(_vm.files.length)?_c('div',{staticClass:"task-popup-files"},_vm._l((_vm.files),function(item,index){return _c('div',{key:index,staticClass:"sup-chat-files__item"},[(_vm.fileIsImage(item.name))?_c('img',{attrs:{"src":item.img},on:{"click":function($event){_vm.showImagePopup(item.img)}}}):_c('a',{staticClass:"sup-chat-files__item-link",attrs:{"download":"","href":_vm.getFileSrc(item.linkToFile)},on:{"click":function($event){$event.preventDefault();_vm.downloadURI(_vm.getFileSrc(item.linkToFile), item.name, item.Id)}}},[_c('img',{attrs:{"src":_vm.getNonImageTypeFileIcon(item.name)}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('div',{staticClass:"sup-chat-files__item-remove",on:{"click":function($event){_vm.deleteFileFromTask(item.id)}}},[_c('svg',{staticClass:"popup__close-icon"},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"/static/images/icon.svg#icon_icon-close"}})])])])})):_vm._e(),_vm._v(" "),_c('div',{staticClass:"task-comment-textarea__control"},[(!_vm.commentWillBeHide)?_c('div',{staticClass:"task-comment-subscribers"},[_c('div',{staticClass:"task-comment-subscribers__name"},[_vm._v("Подписчики:")]),_vm._v(" "),_c('div',{staticClass:"task-popup__nav-persons-list"},[_vm._l((_vm.loadedTask.subscriptions.filter(
              function (item, index) { return item &&
                index <= 6 &&
                item.UserId !== _vm.loadedTask.entityModel.ResponsibleId; }
            )),function(item,index){return _c('div',{key:index,staticClass:"task-popup__nav-persons-list__item",attrs:{"title":_vm._f("shortName")(item.Fio)}},[_c('UserAvatar',{attrs:{"user":item.User}}),_vm._v(" "),(_vm.checkUserTaskRight('change_subscribers'))?_c('div',{staticClass:"task-table-person__delete",on:{"click":function($event){_vm.deleteSubscriber(item.Id)}}},[_c('svg',{attrs:{"focusable":"false","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"}})])]):_vm._e()],1)}),_vm._v(" "),_c('div',{staticClass:"task-create-form-subtasks__person"},[(
                _vm.checkUserTaskRight('change_subscribers') &&
                _vm.loadedTask.subscriptions.length <= 7
              )?_c('div',{staticClass:"task-create-form-subtasks__person--add",on:{"click":function($event){_vm.addSubscriberBubbleOpen = true}}},[_vm._v("\n              +\n            ")]):(_vm.loadedTask.subscriptions.length > 7)?_c('div',{staticClass:"task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count",on:{"click":function () {
                  if (_vm.checkUserTaskRight('change_subscribers'))
                    { _vm.addSubscriberBubbleOpen = true; }
                }}},[_vm._v("\n              +"+_vm._s(_vm.loadedTask.subscriptions.length - 7)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.addSubscriberBubbleOpen)?_c('PersonsListSearch',{attrs:{"arr":_vm.loadedTask.subscriptions,"className":'task-bubble--subscriber',"excludeArray":[_vm.loadedTask.entityModel.ResponsibleId],"outerUsers":_vm.loadedTask.taskGroupUsers},on:{"choose":_vm.addSubscriber,"deleteItem":_vm.deleteSubscriber,"close":function($event){_vm.addSubscriberBubbleOpen = false}}}):_vm._e()],1)],2)]):_c('div',{staticClass:"task-comment-subscribers"},[_c('div',{staticClass:"task-comment-subscribers__name"},[_vm._v("Увидят только:")]),_vm._v(" "),_c('div',{staticClass:"task-popup__nav-persons-list"},[_vm._l((_vm.usersWhoWillSeeHiddenComment.filter(
              function (item, index) { return item &&
                index <= 6 &&
                item.UserId !== _vm.loadedTask.entityModel.ResponsibleId; }
            )),function(item,index){return _c('div',{key:index,staticClass:"task-popup__nav-persons-list__item",attrs:{"title":_vm._f("shortName")(_vm.getPersonNameById(item.UserId))}},[_c('UserAvatar',{attrs:{"user":item.User}}),_vm._v(" "),_c('div',{staticClass:"task-table-person__delete",on:{"click":function($event){_vm.deleteHiddenCommentUser(item.Id)}}},[_c('svg',{attrs:{"focusable":"false","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"}})])])],1)}),_vm._v(" "),_c('div',{staticClass:"task-create-form-subtasks__person"},[(_vm.usersWhoWillSeeHiddenComment.length <= 7)?_c('div',{staticClass:"task-create-form-subtasks__person--add",on:{"click":function($event){_vm.addHiddenCommentUserBubbleOpen = true}}},[_vm._v("\n              +\n            ")]):(_vm.usersWhoWillSeeHiddenComment.length > 7)?_c('div',{staticClass:"task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count",on:{"click":function($event){_vm.addHiddenCommentUserBubbleOpen = true}}},[_vm._v("\n              +"+_vm._s(_vm.usersWhoWillSeeHiddenComment.length - 7)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.addHiddenCommentUserBubbleOpen)?_c('PersonsListSearch',{attrs:{"arr":_vm.usersWhoWillSeeHiddenComment,"className":'task-bubble--subscriber',"excludeArray":[_vm.loadedTask.entityModel.ResponsibleId],"outerUsers":_vm.loadedTask.taskGroupUsers},on:{"choose":_vm.addHiddenCommentUser,"deleteItem":_vm.deleteHiddenCommentUser,"close":function($event){_vm.addHiddenCommentUserBubbleOpen = false}}}):_vm._e()],1)],2)]),_vm._v(" "),_c('div',{staticClass:"btn-yellow task-comment-send-btn",class:{
          'task-comment-send-btn--disabled':
            !_vm.isFocus && !_vm.files.length && !_vm.message,
        },on:{"click":_vm.sendComment}},[_vm._v("\n        Отправить\n      ")])])]),_vm._v(" "),_c('dropzone',{ref:"dropzone",on:{"filesChange":_vm.filesChange}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }