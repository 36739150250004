<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            :OriginalValue="fields.Name"
            ref="translated-field-Name"
            :inputType="'input'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            ref="translated-field-Uri"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.Image">
            <img :src="getImgSrc(fields.Image)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение на главной:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files, 'additionalFiles')"
          />

          <template v-if="additionalFiles.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in additionalFiles"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span
            class="commercial-site__images"
            v-else-if="fields.ImageMainPage"
          >
            <img :src="getImgSrc(fields.ImageMainPage)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Что это значит текст:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.WhatMean"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'WhatMean'"
            :OriginalValue="fields.WhatMean"
            ref="translated-field-WhatMean"
            :inputType="'textarea'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Верхний текст:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.WelcomeText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'WelcomeText'"
            :OriginalValue="fields.WelcomeText"
            ref="translated-field-WelcomeText"
            :inputType="'textarea'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Call to action:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.CallToActionText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'CallToActionText'"
            :OriginalValue="fields.CallToActionText"
            ref="translated-field-CallToActionText"
            :inputType="'textarea'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <!--<label class="field cm-calendar-input">-->
      <!--<span class="field__label field__label_align_right clients-common-info__label">-->
      <!--Показывать только на главной:-->
      <!--</span>-->
      <!--<span class="field__content">-->
      <!--<div class="onoffswitch">-->
      <!--<input type="checkbox"-->
      <!--name="onoffswitch"-->
      <!--class="onoffswitch-checkbox"-->
      <!--v-model="fields.OnlyForMainPage"-->
      <!--v-bind:true-value="'1'"-->
      <!--v-bind:false-value="'0'"-->
      <!--id="UpdatePinDist"-->
      <!--&gt;-->
      <!--<label class="onoffswitch-label" for="UpdatePinDist">-->
      <!--<span class="onoffswitch-inner"></span>-->
      <!--<span class="onoffswitch-switch"></span>-->
      <!--</label>-->
      <!--</div>-->
      <!--</span>-->
      <!--</label>-->

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__text-editor" v-if="tab === 2">
      <div class="skedit" v-if="parentData.language === 'ru'">
        <textarea v-tinymce field="Description" v-model="fields.Description" />
      </div>
      <translate-field
        :EntityColumnName="'Description'"
        :OriginalValue="fields.Description"
        ref="translated-field-Description"
        :inputType="'editor'"
        :module="'SiteProductionCategories'"
        @getTranslation="getTranslation"
        :translation="translation"
        :id="id"
      />
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            :OriginalValue="fields.SeoTitle"
            ref="translated-field-SeoTitle"
            :inputType="'input'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            :OriginalValue="fields.SeoDescription"
            ref="translated-field-SeoDescription"
            :inputType="'input'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            :OriginalValue="fields.SeoKeywords"
            ref="translated-field-SeoKeywords"
            :inputType="'input'"
            :module="'SiteProductionCategories'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteProductionCategories'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";

export default {
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
