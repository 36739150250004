<template>
  <span v-if="isParent && item"
    ><template v-if="item.parent && item.parent.Id">
      <BreadCrumbsItem
        :isParent="true"
        :item="item.parent"
        :articles="articles"
    /></template>
    / {{ item.Name }}
  </span>
  <span v-else-if="item && item.ParentId !== void 0">
    <template v-if="parentItem"
      ><BreadCrumbsItem :item="parentItem" :articles="articles"
    /></template>
    / {{ item.Name }}</span
  >
</template>

<script>
import BreadCrumbsItem from "./BreadCrumbsItem";
import * as _ from "lodash";
export default {
  name: "BreadCrumbsItem",
  props: ["item", "articles", "isParent"],
  computed: {
    parentItem() {
      let found = null;
      let ParentId = null;

      function findChild(i) {
        if (!found) {
          if (i.Id === ParentId) {
            found = i;
          } else if (i.child && i.child.length) {
            _.each(i.child, (c) => {
              findChild(c);
            });
          }
        }
      }
      if (this.item && this.item.ParentId !== void 0) {
        ParentId = this.item.ParentId;
        _.each(this.articles, (i) => {
          findChild(i);
        });
        return found;
      }
      return false;
    },
  },
  components: {
    BreadCrumbsItem,
  },
};
</script>

<style scoped></style>
