<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_9_1 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <Print_9_1 />
      </magnific-popup-modal>
    </div>
    <div class="pdf-settings">
      <div
        class="virtual-tasks-text virtual-tasks-text--open"
        id="virtual-additional-text"
        ref="virtual-additional-text"
      ></div>

      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Текст:</div>
            <vue-editor
              class=""
              v-model="offerObject.json.AdditionalPageText"
              :editorToolbar="[
                [
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  { list: 'ordered' },
                  { list: 'bullet' },
                ],
              ]"
              @blur="textTasksChecked5"
            ></vue-editor>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(9)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(10)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_9_1 from "../prints/print_9-1";
import { VueEditor } from "vue2-editor";
function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export default {
  mixins: [SalesHelpers],
  mounted() {},
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    textTasksChecked5() {
      const body = document.querySelector("body");
      // const virtualTextBlock = this.$refs['virtual-additional-text']
      let el = document.createElement("div");
      el.innerHTML = this.offerObject.json.AdditionalPageText;

      body.append(el);

      this.offerObject.json.AdditionalPageTextVirtual = {};
      const AdditionalPageTextVirtualLocal = {};
      // eslint-disable-next-line no-undef
      $(el).columnize({ width: 500, height: 500 });
      el.querySelectorAll(".column").forEach((i, index) => {
        if (i.innerHTML) {
          AdditionalPageTextVirtualLocal[index] = [];
          AdditionalPageTextVirtualLocal[index].push(i.innerHTML);
        }
      });
      this.offerObject.json.AdditionalPageTextVirtual = AdditionalPageTextVirtualLocal;
      el.parentNode.removeChild(el);
    },
    textTasksChecked4() {
      const body = document.querySelector("body");
      // const virtualTextBlock = this.$refs['virtual-additional-text']
      let el = document.createElement("div");
      el.innerHTML = this.offerObject.json.AdditionalPageText;
      el.querySelectorAll("ul").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      el.querySelectorAll("ol").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      const textToCut = document.createElement("div");
      textToCut.className = "virtual-tasks-text-2";
      body.append(textToCut);

      this.offerObject.json.AdditionalPageTextVirtual = {};
      function loop(node) {
        console.log(node);
        var nodes = node.childNodes;
        for (var i = 0; i < nodes.length; i++) {
          if (!nodes[i]) {
            continue;
          }

          if (nodes[i].childNodes.length > 0) {
            loop(nodes[i]);
          }
        }
      }
      loop(el);
    },

    textTasksChecked2() {
      const body = document.querySelector("body");
      // const virtualTextBlock = this.$refs['virtual-additional-text']
      let el = document.createElement("div");
      el.innerHTML = this.offerObject.json.AdditionalPageText;
      el.querySelectorAll("ul").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      el.querySelectorAll("ol").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      const textToCut = document.createElement("div");
      textToCut.className = "virtual-tasks-text-2";
      body.append(textToCut);

      this.offerObject.json.AdditionalPageTextVirtual = {};
      const AdditionalPageTextVirtualLocal = {};
      // let getInStrong = false
      // let getInA = false
      // let getInSup = false

      // const myStr = el.innerHTML;
      // const container = document.createElement('div'); // a temporary orphan div
      // container.innerHTML = myStr;
      // const spanToUnwrap = container.querySelectorAll('span');
      // spanToUnwrap.forEach(s => {
      //   s.outerHTML = s.innerHTML;
      //   // console.log(s)
      // })
      //
      // const spanToDelete = container.querySelectorAll('span');
      // spanToDelete.forEach(s => {
      //   if (s.parentNode)
      //     s.parentNode.removeChild(s)
      // })

      let words = replaceAll(el.innerHTML, "</p>", " ");
      // words = replaceAll(words, '</strong>', ' </strong>')
      // words = replaceAll(words, '</a>', ' </a>')
      // words = replaceAll(words, '</sup>', '')
      // words = replaceAll(words, '<sup>', '')

      // console.log(words)

      words = words.split(" ");
      const textArray = [];
      words.forEach((word, index) => {
        // console.log(word)
        // word = word.replace('<br>', '\n')

        if (word.includes("<p>")) {
          const p = document.createElement("p");
          textToCut.append(p);
          word = word.replace("<p>", "");
        }
        if (!textToCut.querySelector("p:last-child")) {
          const p = document.createElement("p");
          textToCut.append(p);
        }

        // if (word.includes('<strong>')) {
        //   const element = document.createElement('strong')
        //   textToCut.querySelector('p:last-child').appendChild(element)
        //   // console.log(textToCut.querySelector('p:last-child'), element)
        //   word = word.replace('<strong>', '')
        //   getInStrong = true
        // }
        // if (word.includes('</strong>')) {
        //   word = word.replace('</strong>', '')
        //   getInStrong = false
        // }
        // if (getInStrong) {
        //   textToCut.querySelector('p:last-child').querySelector('strong:last-child').innerText += ' ' + word
        //   // console.log('eeeegetInStrong', textToCut.querySelector('p:last-child').querySelector('strong:last-child'))
        // }
        // if (word.includes('<a>')) {
        //   const element = document.createElement('a')
        //   textToCut.querySelector('p:last-child').appendChild(element)
        //   // console.log(textToCut.querySelector('p:last-child'), element)
        //   word = word.replace('<a>', '')
        //   getInA = true
        // }
        // if (word.includes('</a>')) {
        //   word = word.replace('</a>', '')
        //   getInA = false
        // }
        // if (getInA) {
        //   textToCut.querySelector('p:last-child').querySelector('a:last-child').innerText += ' ' + word
        //   // console.log('eeeegetInStrong', textToCut.querySelector('p:last-child').querySelector('strong:last-child'))
        // }

        // else {
        //   textToCut.querySelector('p:last-child').innerHTML += ' ' + word
        // }
        textToCut.querySelector("p:last-child").innerHTML += " " + word;

        // textToCut.innerText += word
        // prepareText(word, textToCut)
        if (textToCut.clientHeight <= 416) {
          if (index === words.length - 1) {
            // console.log(textToCut.innerHTML)
            textArray.push(textToCut.innerHTML);
          }
        } else {
          textArray.push(textToCut.innerHTML);
          textToCut.innerHTML = "";
        }
      });

      // console.log(textArray)
      textArray.forEach((text, index) => {
        AdditionalPageTextVirtualLocal[index] = [];
        const wrapper = document.createElement("p");
        const wrapper2 = document.createElement("div");
        wrapper.innerText = text;
        wrapper2.appendChild(wrapper);
        AdditionalPageTextVirtualLocal[index].push(text);
      });
      this.offerObject.json.AdditionalPageTextVirtual = AdditionalPageTextVirtualLocal;
      // console.log(textArray)
      // textToCut.parentNode.removeChild(textToCut)
    },

    textTasksChecked() {
      const body = document.querySelector("body");
      const virtualTextBlock = this.$refs["virtual-additional-text"];
      let el = document.createElement("div");
      el.innerHTML = this.offerObject.json.AdditionalPageText;
      el.querySelectorAll("ul").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      el.querySelectorAll("ol").forEach((i) => {
        const wrapper = document.createElement("p");
        i.parentNode.insertBefore(wrapper, i);
        wrapper.appendChild(i);
      });
      console.log(el);
      let lines = this.offerObject.json.AdditionalPageText.split("</p>");
      let maxHeight = 0;
      lines = lines.map((i) => i + "</p>");
      if (lines.length > 1) {
        lines.pop();
      }
      // console.log(lines)
      this.offerObject.json.AdditionalPageTextVirtual = {};
      const AdditionalPageTextVirtualLocal = {};
      virtualTextBlock.innerHTML = "";
      let count = 16;
      let index = 0;
      const renderText = () => {
        for (let line of lines) {
          virtualTextBlock.innerHTML = virtualTextBlock.innerHTML + line;
          if (!AdditionalPageTextVirtualLocal[count])
            AdditionalPageTextVirtualLocal[count] = [];
          const fakeElementNext = document.createElement("div");
          fakeElementNext.className = "virtual-tasks-text-2";
          fakeElementNext.innerHTML = lines[index + 1];
          body.append(fakeElementNext);
          const fakeElementCurrent = document.createElement("div");
          fakeElementCurrent.className = "virtual-tasks-text-2";
          fakeElementCurrent.innerHTML = line;
          body.append(fakeElementCurrent);
          // console.log(fakeElementCurrent.clientHeight, fakeElementNext.clientHeight)
          if (fakeElementCurrent.clientHeight >= 316) {
            const textToCut = document.createElement("div");
            textToCut.className = "virtual-tasks-text-2";
            textToCut.innerHTML = line;
            body.append(textToCut);
            const textArray = [];
            const lineSplit = textToCut.innerText.split(" ");
            textToCut.innerHTML = "";
            // console.log(lineSplit)
            let wordIndex = 0;
            for (let word of lineSplit) {
              // console.log(textToCut.clientHeight)
              textToCut.innerHTML += " " + word;

              if (textToCut.clientHeight <= 200) {
                if (wordIndex === lineSplit.length - 1) {
                  textArray.push(textToCut.innerHTML);
                }
              } else {
                textArray.push(textToCut.innerHTML);
                textToCut.innerHTML = "";
              }
              wordIndex++;
              // console.log(word)
            }
            console.log(textArray);
            textArray.forEach((text, i) => {
              const deleteCount = i === 0 ? 1 : 0;
              const wrapper = document.createElement("p");
              const wrapper2 = document.createElement("div");
              wrapper.innerText = text;
              wrapper2.appendChild(wrapper);
              lines.splice(index + i, deleteCount, wrapper2.innerHTML);
            });

            // textToCut.parentNode.removeChild(textToCut)
            renderText();
            // textToCut.innerHTML = line
            // console.log(textToCut.innerHTML)
            // var str = textToCut.innerText;
            // console.log(1)
            // var lastIndex = str.lastIndexOf(" ");
            // var mySplitResult = str.split(" ");
            // var lastWord =  mySplitResult[mySplitResult.length-1]
            // str = str.substring(0, lastIndex);
            // const wrapper = document.createElement('p')
            // wrapper.innerText = str
            // textToCut.innerHTML = ''
            // textToCut.appendChild(wrapper)
            // console.log(lastWord)
            // console.log(textToCut.innerHTML)

            // lines.splice(index, 1, textToCut.innerHTML)
            // renderText()
            // console.log(line, index)
            break;
          }
          // console.log('virtualTextBlock.offsetHeight', virtualTextBlock.offsetHeight ,'maxHeight' , maxHeight,'virtualTextBlock.offsetHeight - maxHeight', virtualTextBlock.offsetHeight - maxHeight,  'fakeElement.clientHeight', fakeElement.clientHeight,)
          else if (
            virtualTextBlock.offsetHeight -
              maxHeight +
              fakeElementNext.clientHeight >=
            416
          ) {
            // console.log('\n\n\n')
            maxHeight = virtualTextBlock.offsetHeight;
            count = virtualTextBlock.offsetHeight;
            if (!AdditionalPageTextVirtualLocal[count]) {
              AdditionalPageTextVirtualLocal[count] = [];
              if (
                AdditionalPageTextVirtualLocal["16"] &&
                !AdditionalPageTextVirtualLocal["16"].length
              )
                delete AdditionalPageTextVirtualLocal["16"];
            }
          }
          fakeElementNext.parentNode.removeChild(fakeElementNext);
          fakeElementCurrent.parentNode.removeChild(fakeElementCurrent);
          AdditionalPageTextVirtualLocal[count].push(line);
          index++;
        }
        // console.log(lines)
      };
      renderText();
      this.offerObject.json.AdditionalPageTextVirtual = AdditionalPageTextVirtualLocal;
      console.log("done");
    },
  },
  components: { Print_9_1, VueEditor },
};
</script>

<style scoped>
.virtual-tasks-text {
  width: 600px;
  line-height: 1;
  border: none;
  border-left: 2px red;
  border-right: 2px red;
  opacity: 0;
}
</style>
