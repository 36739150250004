<template>
  <div class="table__row-wrap table__row-wrap--tasks">
    <GroupedTasksByGroupItem
      v-for="(item, index) in groupedTaskByFilter"
      :group="item"
      :key="index"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupedTasksByGroupItem from "@/modules/tasks/components/Tables/MainTable/GroupedTasksByGroup/GroupedTasksByGroupItem";

export default {
  name: "GroupedTasksByFilterQuery",
  computed: {
    ...mapState("tasks", ["groupedTaskByFilter"]),
  },
  components: {
    GroupedTasksByGroupItem,
  },
};
</script>

<style scoped></style>
