<template>
  <div class="wiki-article-child-list">
    <p>Содержание</p>
    <ul>
      <ContentLinksItem
        :item="item"
        v-for="item in currentArticle.child"
        :categorySlug="categorySlug"
        :articles="articles"
        :key="item.Id"
      />
    </ul>
  </div>
</template>

<script>
import ContentLinksItem from "./ContentLinksItem";

export default {
  props: ["currentArticle", "categorySlug", "articles"],
  name: "ContentLinks",
  components: {
    ContentLinksItem,
  },
};
</script>

<style scoped></style>
