<template>
  <div class="tasks-roles-settings__wrap">
    <div class="tasks-roles-settings__tabs">
      <button :class="{ active: tab === 1 }" @click="tab = 1">
        Пользователи / группы
      </button>
      <button :class="{ active: tab === 2 }" @click="tab = 2">Теги</button>
    </div>
    <tags-controller v-if="tab === 2" />
    <template v-if="tab === 1">
      <div class="tasks-roles-settings">
        <div class="tasks-roles-settings__users" style="max-height: 400px">
          <div class="tasks-roles-settings__label">Пользователи:</div>
          <br />
          <ul>
            <li
              :key="user.Id"
              @click="selectUser(user)"
              :class="getUserClass(user)"
              v-for="user in users"
            >
              <p>{{ user.FullName }}</p>
            </li>
          </ul>
        </div>
        <div class="tasks-roles-settings__users">
          <div class="tasks-roles-settings__label">Роли:</div>
          <br />
          <ul>
            <li
              v-for="(item, index) in roles.filter(
                (item) => item.SystemName !== 'superadmin'
              )"
              :key="index"
              :class="{ 'not-available': isUserSuperAdmin }"
            >
              <span @click="selectRole(item)" :class="getRoleClass(item)"
                >{{ item.Name }}
                <span class="unic">({{ item.SystemName }})</span></span
              >
              <span class="delete" @click="deleteRole(item.Id)">удалить</span>
              <span
                class="addTo"
                v-if="checkUsersRelation(item)"
                @click="deleteRoleToUser(item)"
                style="text-decoration: line-through"
                >отвязать</span
              >
              <span
                class="addTo"
                v-else-if="selectedUser"
                @click="addRoleToUser(item.Id)"
                >привязать</span
              >
            </li>
          </ul>

          <span
            v-if="selectedUser"
            @click="addSuperAdminRoleToUser()"
            style="cursor: pointer"
          >
            <span>Суперадмин</span>
            <input
              type="checkbox"
              style="margin-top: 50px; margin-left: 10px; pointer-events: none"
              v-model="isUserSuperAdmin"
            />
          </span>
          <br />
          <label v-if="selectedUser" style="cursor: pointer">
            <span>Геолокация</span>
            <input
              type="checkbox"
              style="margin-top: 50px; margin-left: 10px"
              v-bind:true-value="'1'"
              v-bind:false-value="'0'"
              v-model="selectedUser.ObjectRequestNotification"
              @change="setUserGeolocation()"
            />
          </label>
        </div>
        <div class="tasks-roles-settings__users">
          <div class="tasks-roles-settings__label">Права:</div>
          <br />
          <ul>
            <li v-for="(item, index) in preparedRights" :key="index">
              <span @click="selectRight(item)" :class="getRightClass(item)"
                >{{ item.Name }}
                <span class="unic">({{ item.SystemName }})</span></span
              >
              <span class="delete" @click="deleteRight(item.Id)">удалить</span>
              <span class="child" @click="createChildRight(item)">➕</span>

              <span
                class="addTo"
                v-if="checkRoleRelation(item.Id)"
                @click="deleteRightToRole(item)"
                style="text-decoration: line-through"
                >отвязать</span
              >
              <span
                class="addTo"
                v-else-if="editingRole"
                @click="addRightToRole(item.Id)"
                >привязать</span
              >
              <ul>
                <li
                  v-for="(item, index) in findChildRights(item.Id)"
                  :key="index"
                  style="margin-left: 10px; margin-top: 5px"
                >
                  -
                  <span @click="selectRight(item)" :class="getRightClass(item)"
                    >{{ item.Name }}
                    <span class="unic">({{ item.SystemName }})</span></span
                  >
                  <span class="delete" @click="deleteRight(item.Id)"
                    >удалить</span
                  >
                  <span class="child" @click="createChildRight(item)">➕</span>
                  <span
                    class="addTo"
                    v-if="checkRoleRelation(item.Id)"
                    @click="deleteRightToRole(item)"
                    style="text-decoration: line-through"
                    >отвязать</span
                  >
                  <span
                    class="addTo"
                    v-else-if="editingRole"
                    @click="addRightToRole(item.Id)"
                    >привязать</span
                  >
                  <ul>
                    <li
                      v-for="(item, index) in findChildRights(item.Id)"
                      :key="index"
                      style="margin-left: 10px; margin-top: 5px"
                    >
                      --
                      <span
                        @click="selectRight(item)"
                        :class="getRightClass(item)"
                        >{{ item.Name }}
                        <span class="unic">({{ item.SystemName }})</span></span
                      >
                      <span class="delete" @click="deleteRight(item.Id)"
                        >удалить</span
                      >
                      <span class="child" @click="createChildRight(item)"
                        >➕</span
                      >
                      <span
                        class="addTo"
                        v-if="checkRoleRelation(item.Id)"
                        @click="deleteRightToRole(item)"
                        style="text-decoration: line-through"
                        >отвязать</span
                      >
                      <span
                        class="addTo"
                        v-else-if="editingRole"
                        @click="addRightToRole(item.Id)"
                        >привязать</span
                      >
                      <ul>
                        <li
                          v-for="(item, index) in findChildRights(item.Id)"
                          :key="index"
                          style="margin-left: 10px; margin-top: 5px"
                        >
                          ---
                          <span
                            @click="selectRight(item)"
                            :class="getRightClass(item)"
                            >{{ item.Name }}
                            <span class="unic"
                              >({{ item.SystemName }})</span
                            ></span
                          >
                          <span class="delete" @click="deleteRight(item.Id)"
                            >удалить</span
                          >
                          <!--<span class="child" @click="createChildRight(item)">➕</span>-->
                          <span
                            class="addTo"
                            v-if="checkRoleRelation(item.Id)"
                            @click="deleteRightToRole(item)"
                            style="text-decoration: line-through"
                            >отвязать</span
                          >
                          <span
                            class="addTo"
                            v-else-if="editingRole"
                            @click="addRightToRole(item.Id)"
                            >привязать</span
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="tasks-roles-settings__forms">
          <div>
            <template v-if="editingRole">
              <div
                class="tasks-roles-settings__method tasks-roles-settings__method--edit"
              >
                РЕДАКТИРОВАТЬ РОЛЬ:
                {{ editingRole.Name }}
              </div>
              <input class="input" type="text" v-model="editingRole.Name" />
              <label style="color: red; margin-top: 10px; display: block"
                >уникальное название</label
              >
              <input
                class="input"
                type="text"
                v-model="editingRole.SystemName"
              />
              <button class="btn button_text_green" @click="editRole()">
                редактировать роль
              </button>
            </template>
            <template v-else>
              <div class="tasks-roles-settings__method">создать новую роль</div>
              <br />
              <input class="input" type="text" v-model="roleName" />
              <label style="color: red; margin-top: 10px; display: block"
                >уникальное название</label
              >
              <input class="input" type="text" v-model="roleSlug" />
              <button class="btn button_text_green" @click="createRole()">
                создать роль
              </button>
            </template>
          </div>

          <div>
            <template v-if="editingRight">
              <div
                class="tasks-roles-settings__method tasks-roles-settings__method--edit"
              >
                РЕДАКТИРОВАТЬ ПРАВО:
                {{ editingRight.Name }}
              </div>
              <input class="input" type="text" v-model="editingRight.Name" />
              <label style="color: red; margin-top: 10px; display: block"
                >уникальное название</label
              >
              <input
                class="input"
                type="text"
                v-model="editingRight.SystemName"
              />
              <button class="btn button_text_green" @click="editRight()">
                редактировать право
              </button>
            </template>
            <template v-else-if="parentRightForCreate">
              <div class="tasks-roles-settings__method">
                создать
                <span style="text-decoration: underline">подправило</span> для:
                {{ parentRightForCreate.Name }}
              </div>
              <br />
              <input class="input" type="text" v-model="rightName" />
              <label style="color: red; margin-top: 10px; display: block"
                >уникальное название</label
              >
              <input class="input" type="text" v-model="rightSlug" />
              <button class="btn button_text_green" @click="createRight()">
                создать право
              </button>
            </template>
            <template v-else>
              <div class="tasks-roles-settings__method">
                создать новое право
              </div>
              <br />
              <input class="input" type="text" v-model="rightName" />
              <label style="color: red; margin-top: 10px; display: block"
                >уникальное название</label
              >
              <input class="input" type="text" v-model="rightSlug" />
              <button class="btn button_text_green" @click="createRight()">
                создать право
              </button>
            </template>
          </div>
        </div>
      </div>
      <div
        class="tasks-roles-settings"
        style="padding-bottom: 0; margin-bottom: 200px"
      >
        <div class="tasks-roles-settings__users">
          <div class="tasks-roles-settings__label">Группы:</div>
          <br />
          <ul>
            <li v-for="(item, index) in groups" :key="index">
              <span
                style="
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  margin-right: 6px;
                "
                :style="{ background: item.Color }"
              ></span>

              <span @click="selectGroup(item)" :class="getGroupClass(item)">{{
                item.GroupName
              }}</span>

              <span class="delete" @click="deleteGroup(item.Id)">удалить</span>
            </li>
          </ul>
        </div>
        <div class="tasks-roles-settings__forms">
          <div>
            <template v-if="editingGroup">
              <div
                class="tasks-roles-settings__method tasks-roles-settings__method--edit"
              >
                Редактировать группу:
                {{ editingGroup.GroupName }}
              </div>
              <input
                class="input"
                type="text"
                v-model="editingGroup.GroupName"
              />
              <div
                class="vc-sketch-wrap"
                style="position: static; margin-top: 20px"
              >
                <sketch
                  :value="editingGroup.Color ? editingGroup.Color : ''"
                  @input="(event) => (editingGroup.Color = event.hex)"
                />
              </div>
              <button class="btn btn__green" @click="editGroup()">
                редактировать группу
              </button>
            </template>
            <template v-else>
              <div class="tasks-roles-settings__method">
                создать новую группу
              </div>
              <br />
              <input class="input" type="text" v-model="groupName" />
              <button class="btn button_text_green" @click="createGroup()">
                создать группу
              </button>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as c from "../../../store/tasks/constants";
import * as _ from "lodash";
import TagsController from "../../tasks/components/TagsController";
import { Sketch } from "vue-color";
import { getRandomColor } from "../../../utils";
import axios from "axios";
import { API_ROOT } from "@/config";
import { GET_USERS } from "../../../store/tasks/constants";

export default {
  props: ["users"],
  data() {
    return {
      roleName: "",
      roleSlug: "",
      rightName: "",
      rightSlug: "",
      groupName: "",
      editingRole: false,
      selectedUser: false,
      editingRight: false,
      parentRightForCreate: false,
      editingGroup: false,
      tab: 1,
    };
  },
  created() {
    this[c.GET_ROLES]();
    this[c.GET_RIGHTS]();
    this[c.GET_GROUPS]();
    this[GET_USERS]();
  },
  computed: {
    preparedRights() {
      return _.filter(this.rights, ["ParentId", null]);
    },
    superAdminRole() {
      return _.find(this.roles, ["SystemName", "superadmin"]);
    },
    isUserSuperAdmin() {
      if (this.superAdminRole) {
        return !!_.find(this.superAdminRole.activeUsers, [
          "UserId",
          this.selectedUser.Id,
        ]);
      } else {
        return false;
      }
    },
    ...mapState("tasks", ["roles", "rights", "groups"]),
    ...mapState("tasks", { tasksUsers: "users" }),
    ...mapState("common", ["userData"]),
  },
  methods: {
    createRole() {
      const payload = {
        Name: this.roleName,
        SystemName: this.roleSlug,
      };
      this[c.CREATE_ROLE](payload);
      this.roleName = "";
      this.roleSlug = "";
    },
    deleteRole(id) {
      this[c.DELETE_ROLE](id);
    },
    selectRole(role) {
      if (this.editingRole && this.editingRole.Id === role.Id) {
        this.editingRole = false;
      } else {
        this.editingRole = JSON.parse(JSON.stringify(role));
      }
    },
    editRole() {
      const payload = {
        id: this.editingRole.Id,
        data: this.editingRole,
      };
      this[c.EDIT_ROLE](payload);
    },
    getRoleClass(role) {
      let className = "";

      if (this.editingRole && role.Id === this.editingRole.Id) {
        className = "editing";
      }
      if (this.checkUsersRelation(role)) {
        className += " related";
      }
      return className;
    },
    checkUsersRelation(role) {
      if (this.selectedUser) {
        return _.find(role.activeUsers, ["UserId", this.selectedUser.Id]);
      }
    },
    selectUser(user) {
      if (this.selectedUser && this.selectedUser.Id === user.Id) {
        this.selectedUser = false;
      } else {
        this.selectedUser = user;
      }
    },
    getUserClass(user) {
      let className = "";
      if (this.selectedUser && user.Id === this.selectedUser.Id) {
        className = "editing";
      }
      if (this.editingRole) {
        const relateUser = _.find(this.editingRole.activeUsers, [
          "UserId",
          user.Id,
        ]);
        if (relateUser) {
          className += " related";
        }
      }
      return className;
    },
    addRoleToUser(RoleId) {
      const payload = {
        UserId: this.selectedUser.Id,
        RoleId,
      };
      this[c.ADD_ROLE_FOR_USER](payload);
    },
    deleteRoleToUser(role) {
      const relateUser = _.find(role.activeUsers, [
        "UserId",
        this.selectedUser.Id,
      ]);
      this[c.DELETE_ROLE_FOR_USER](relateUser.Id);
    },
    createRight() {
      const payload = {
        Name: this.rightName,
        SystemName: this.rightSlug,
      };
      if (this.parentRightForCreate) {
        payload.ParentId = this.parentRightForCreate.Id;
      }
      this[c.CREATE_RIGHT](payload);
      this.rightName = "";
      this.rightSlug = "";
    },
    createChildRight(right) {
      this.editingRight = false;
      if (
        this.parentRightForCreate &&
        this.parentRightForCreate.Id === right.Id
      ) {
        this.parentRightForCreate = false;
      } else {
        this.parentRightForCreate = right;
      }
    },
    editRight() {
      const payload = {
        id: this.editingRight.Id,
        data: this.editingRight,
      };
      this[c.EDIT_RIGHT](payload);
    },
    deleteRight(id) {
      this[c.DELETE_RIGHT](id);
    },
    selectRight(right) {
      this.parentRightForCreate = false;

      if (this.editingRight && this.editingRight.Id === right.Id) {
        this.editingRight = false;
      } else {
        this.editingRight = JSON.parse(JSON.stringify(right));
      }
    },
    addRightToRole(RightId) {
      const payload = {
        RoleId: this.editingRole.Id,
        RightId,
      };
      this[c.ADD_RIGHT_FOR_ROLE](payload);
    },
    deleteRightToRole(right) {
      const relateRole = _.find(this.editingRole.activeRights, [
        "RightId",
        right.Id,
      ]);
      this[c.DELETE_RIGHT_FOR_ROLE](relateRole.Id);
    },
    getRightClass(right) {
      let className = "";

      if (this.editingRight && right.Id === this.editingRight.Id) {
        className = "editing";
      }
      if (this.checkRoleRelation(right.Id)) {
        className += " related";
      }
      return className;
    },
    checkRoleRelation(rightId) {
      if (this.editingRole) {
        return _.find(this.editingRole.activeRights, ["RightId", rightId]);
      }
    },
    findChildRights(rightId) {
      return _.filter(this.rights, ["ParentId", rightId]);
    },
    createGroup() {
      const payload = { GroupName: this.groupName, Color: getRandomColor() };
      const UserId = this.userData.Id;
      this[c.CREATE_GROUP]({ payload, UserId });
      this.groupName = "";
    },
    deleteGroup(id) {
      this[c.DELETE_GROUP](id);
    },
    selectGroup(group) {
      if (this.editingGroup && this.editingGroup.Id === group.Id) {
        this.editingGroup = false;
      } else {
        this.editingGroup = JSON.parse(JSON.stringify(group));
      }
    },
    editGroup() {
      const payload = {
        id: this.editingGroup.Id,
        data: {
          GroupName: this.editingGroup.GroupName,
          Color: this.editingGroup.Color,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    getGroupClass(group) {
      let className = "";
      if (this.editingGroup && group.Id === this.editingGroup.Id) {
        className = "editing";
      }
      return className;
    },
    addSuperAdminRoleToUser() {
      if (this.isUserSuperAdmin) {
        this.deleteRoleToUser(this.superAdminRole);
      } else {
        this.addRoleToUser(this.superAdminRole.Id);
      }
    },
    setUserGeolocation() {
      const user = _.find(this.tasksUsers, ["Id", this.selectedUser.Id]);
      axios.put(API_ROOT + "api/settings/UserModel/" + user.UserId, {
        ObjectRequestNotification: this.selectedUser.ObjectRequestNotification,
      });
    },
    ...mapActions("tasks", [
      c.GET_ROLES,
      c.GET_RIGHTS,
      c.CREATE_ROLE,
      c.DELETE_ROLE,
      c.EDIT_ROLE,
      c.ADD_ROLE_FOR_USER,
      c.DELETE_ROLE_FOR_USER,
      c.CREATE_RIGHT,
      c.EDIT_RIGHT,
      c.DELETE_RIGHT,
      c.ADD_RIGHT_FOR_ROLE,
      c.DELETE_RIGHT_FOR_ROLE,
      c.GET_GROUPS,
      c.CREATE_GROUP,
      c.EDIT_GROUP,
      c.DELETE_GROUP,
      c.GET_USERS,
    ]),
  },
  watch: {
    roles() {
      if (this.editingRole) {
        this.editingRole = _.find(this.roles, ["Id", this.editingRole.Id]);
      }
    },
  },
  components: {
    TagsController,
    Sketch,
  },
};
</script>

<style scoped></style>
