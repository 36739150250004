<template>
  <div>
    <div
      class="table-vertical__cell"
      :class="isOpen ? 'table-vertical__cell-active' : ''"
      @click="isOpen = !isOpen"
      :style="`padding-left: ${depth * 15}px`"
    >
      <span v-if="item.child && item.child.length">
        <svg
          class="table-vertical__cell__icon"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.73205 9C6.96225 10.3333 5.03775 10.3333 4.26795 9L0.803847 3C0.0340471 1.66666 0.996299 -1.13682e-06 2.5359 -1.00222e-06L9.4641 -3.96541e-07C11.0037 -2.61945e-07 11.966 1.66667 11.1962 3L7.73205 9Z"
            :fill="color"
          />
        </svg>
      </span>
      <span v-else>
        <svg
          class="table-vertical__cell__icon"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" :fill="color" />
        </svg>
      </span>
      <span class="tag-name">
        {{ item.TagName }}
      </span>
      <span
        class="tags-settings__buttons tags-settings__buttons-right _checkbox"
        @click.stop
      >
        <span
          v-if="childrenSelected > 0"
          class="tags-settings__counter"
          :style="`background: ${color}`"
        >
          {{ childrenSelected }}
        </span>
        <input
          type="checkbox"
          :id="item.Id"
          name="request-tags"
          :value="item.Id"
          :checked="isSelected"
          @click.prevent
          @click="onSelectedChange"
        />
      </span>
    </div>
    <div v-if="item.child && item.child.length">
      <div v-show="isOpen" v-for="child in item.child" :key="child.id">
        <rps-tags-selector-item
          v-if="search.length < 1 || searchList.includes(child.Id)"
          :item="child"
          :color="color"
          :depth="depth + 1"
          :request-tags="requestTags"
          :search="search"
          :search-list="searchList"
          @createTagRelation="(i) => $emit('createTagRelation', i)"
          @deleteTagRelation="(i) => $emit('deleteTagRelation', i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  name: "RpsTagsSelectorItem",
  props: ["item", "color", "depth", "requestTags", "search", "searchList"],
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    search(newVal) {
      if (newVal.length > 0) {
        this.isOpen = true;
      }
    },
  },
  computed: {
    ...mapState("rpsSupport", ["preloaders", "commonSettings"]),
    isSelected() {
      if (this.requestTags) {
        return this.requestTags.find((i) => i.Id === this.item.Id);
      } else return false;
    },
    childrenSelected() {
      let result = 0;
      const requestTags = this.requestTags;
      checkChildren(this.item.child);

      function checkChildren(array) {
        _.each(array, (child) => {
          if (requestTags.find((i) => i.Id === child.Id)) result++;
          if (child.child && child.child.length) checkChildren(child.child);
        });
      }

      return result;
    },
  },
  methods: {
    onSelectedChange(event) {
      const tag = {
        ...this.item,
        TagId: this.item.Id,
        relationId: this.requestTags.find((i) => i.TagId === this.item.Id)
          ?.relationId,
      };
      if (event.target.checked) {
        this.$emit("createTagRelation", tag);
      } else {
        this.$emit("deleteTagRelation", tag);
      }
    },
  },
};
</script>

<style scoped></style>
