var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup__inner-cont"},[_c('div',{staticClass:"pdf-cont-scroll pdf-cont--ml-s scroll-style"},[_c('div',{staticClass:"pdf-cont",on:{"click":function($event){_vm.openModal()}}},[_c('Print_9_1')],1),_vm._v(" "),_c('magnific-popup-modal',{ref:"modal",attrs:{"config":{ closeOnBgClick: true }}},[_c('Print_9_1')],1)],1),_vm._v(" "),_c('div',{staticClass:"pdf-settings"},[_c('div',{ref:"virtual-additional-text",staticClass:"virtual-tasks-text virtual-tasks-text--open",attrs:{"id":"virtual-additional-text"}}),_vm._v(" "),_c('div',{staticClass:"pdf-settings__cont scroll-style"},[_c('div',{staticClass:"pdf-settings__inner"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-row__label"},[_vm._v("Текст:")]),_vm._v(" "),_c('vue-editor',{attrs:{"editorToolbar":[
              [
                'bold',
                'italic',
                'underline',
                'strike',
                { list: 'ordered' },
                { list: 'bullet' } ] ]},on:{"blur":_vm.textTasksChecked5},model:{value:(_vm.offerObject.json.AdditionalPageText),callback:function ($$v) {_vm.$set(_vm.offerObject.json, "AdditionalPageText", $$v)},expression:"offerObject.json.AdditionalPageText"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"pdf-settings__next pdf-settings__btns"},[_c('button',{staticClass:"btn-full btn-full__gray",on:{"click":function($event){_vm.changeStep(9)}}},[_vm._v("\n        Назад\n      ")]),_vm._v(" "),_c('button',{staticClass:"btn-full btn-full__green",on:{"click":function($event){_vm.changeStep(10)}}},[_vm._v("\n        Далее\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }