<template>
  <div :class="preloaders.main ? 'app--load' : ''">
    <client-header />
    <router-view />
    <div class="cm-container">
      <div class="table">
        <div class="table__header">
          <div class="table__col table__col_width_260">
            <p class="table__header-text">Объект</p>
            <div
              class="table__filter-button"
              @click="makeSort(sorts.object)"
              :class="sort.name === 'Name' ? 'table__filter-button_orange' : ''"
              style="margin-left: 10px"
            >
              {{ sorts.object.type === "asc" ? "А-Я" : "Я-A" }}
            </div>
          </div>
          <div class="table__col table__col_width_260">
            <p class="table__header-text">Клиент</p>
            <div
              class="table__filter-button"
              @click="makeSort(sorts.company)"
              :class="
                sort.name === 'CompanyName' ? 'table__filter-button_orange' : ''
              "
              style="margin-left: 10px"
            >
              {{ sorts.company.type === "asc" ? "А-Я" : "Я-A" }}
            </div>
          </div>
          <div class="table__col table__col_width_260">
            <p class="table__header-text">Дистрибьютор</p>
            <div
              class="table__filter-button"
              @click="makeSort(sorts.distb)"
              :class="
                sort.name === 'DistributorName'
                  ? 'table__filter-button_orange'
                  : ''
              "
              style="margin-left: 10px"
            >
              {{ sorts.distb.type === "asc" ? "А-Я" : "Я-A" }}
            </div>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Контакты</p>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Дата лицензии</p>
            <svg class="datepicker__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-calendar"
              ></use>
            </svg>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Договор на ТО/ТС</p>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Окончание договора на ТО</p>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Комментарий</p>
          </div>
        </div>
        <div class="table__row-wrap" style="height: calc(100vh - 98px)">
          <div
            class="table__row"
            v-for="(item, index) in filteredObjects"
            :key="index"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_260">
                <div class="table__text-block" style="display: flex">
                  <label
                    class="checkbox checkbox_white table__checkbox js-index-checkbox"
                  >
                    <input
                      type="checkbox"
                      name="row_checkbox_clients"
                      class="checkbox__input main_checkbox_input"
                      @click="selectItem(item.Id)"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </label>
                  <router-link
                    :to="'/object/' + item.Id"
                    v-if="item.type === 'object'"
                    class="table__text cm-link"
                  >
                    {{ item.Name }}
                  </router-link>
                </div>
              </div>
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <router-link
                    :to="'/company/' + item.CompanyID"
                    class="table__text cm-link"
                    v-if="item.type === 'object' && item.CompanyID"
                    >{{ item.CompanyName }}
                  </router-link>
                  <router-link
                    :to="'/company/' + item.Id"
                    class="table__text cm-link"
                    v-if="item.type === 'company'"
                  >
                    {{ item.CompanyName }}
                  </router-link>
                </div>
              </div>
              <div class="table__col table__col_width_260">
                <div class="table__text-block">
                  <router-link
                    :to="'distb/' + item.DistributorId"
                    v-if="item.type === 'object' && item.DistributorId"
                    class="table__text cm-link"
                    >{{ item.DistributorName }}
                  </router-link>
                  <router-link
                    :to="'distb/' + item.Id"
                    v-if="item.type === 'distributor'"
                    class="table__text cm-link"
                  >
                    {{ item.DistributorName }}
                  </router-link>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <div class="table__text-block">
                  <p
                    class="table__text"
                    v-if="item.contacts !== void 0 && item.contacts.length"
                  >
                    {{ getMainContact(item.contacts) }}
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <div class="table__text-block">
                  <p class="table__text" v-if="item.ExpireSet">
                    {{ item.ExpireSet }}
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <div class="table__text-block">
                  <p class="table__text" v-if="item.ServiceNumber">
                    {{ item.ServiceNumber }}
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <div class="table__text-block">
                  <p class="table__text" v-if="item.ServiceDateValidTo">
                    {{ item.ServiceDateValidTo }}
                  </p>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <div class="table__text-block">
                  <p class="table__text" v-if="item.Comment">
                    {{ item.Comment }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="table-spinner"
          style="display: flex; flex-direction: column"
          v-show="preloaders.main"
        >
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientHeader from "@/modules/common/header/components/ClientHeader";
import { mapActions, mapState, mapGetters } from "vuex";
import * as _ from "lodash";
import Spinner from "vue-spinkit";
import * as c from "@/store/clients/constants";
import * as commonTypes from "@/store/common/constants";
import { GET_OBJECTS_RIGHT } from "../../store/common/constants";
import { Helpers } from "../../mixins/Helpers";
import { TASKS_MODULE_RIGHT } from "@/store/sales/constants";

export default {
  mixins: [Helpers],
  data() {
    return {
      sorts: {
        object: {
          active: 0,
          type: "asc",
          name: "Name",
        },
        company: {
          active: 0,
          type: "asc",
          name: "CompanyName",
        },
        distb: {
          active: 0,
          type: "asc",
          name: "DistributorName",
        },
      },
    };
  },
  computed: {
    ...mapState("clients", ["preloaders", "objects", "sort"]),
    ...mapState("common", ["popups", "rightsLoaded"]),
    ...mapGetters("clients", ["filteredObjects"]),
  },
  methods: {
    ...mapActions("clients", [
      c.GET_OBJECTS,
      c.GET_COMPANIES,
      c.SET_OBJECTS,
      c.SILENT_LOAD_OBJECTS,
      c.SET_SORT,
      c.SET_SELECTED_ITEMS,
      c.GET_DISTB,
      c.GET_DISTB_COMPANIES,
      c.GET_DISTB_OBJECTS,
      c.GET_DISTB_CONTACTS,
    ]),
    ...mapActions("common", [
      commonTypes.GET_NOTIFICATIONS,
      commonTypes.SET_GETTING_NOTIFICATIONS_STATUS,
    ]),
    makeSort(action) {
      this.sorts = _.each(this.sorts, (i) => {
        if (i !== action) i.type = "asc";
        i.active = 0;
      });
      action.active = 1;
      action.type = action.type === "asc" ? "desc" : "asc";
      const sort = { name: action.name, type: action.type };
      this[c.SET_SORT](sort);
    },
    selectItem(item) {
      this[c.SET_SELECTED_ITEMS]({ item });
    },
    getMainContact(contacts) {
      const contact = _.find(contacts, ["MainContact", "1"]);
      return contact ? contact.Phone : contacts[0].Phone;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const timerId = setInterval(() => {
        if (vm.rightsLoaded) {
          if (window.location.hostname === "surerp.ru") {
            // if (window.location.hostname === "localhost") {
            clearInterval(timerId);
            vm.$router.push(
              vm.checkUserRight(TASKS_MODULE_RIGHT) ? "/tasks" : "/sales"
            );
          } else {
            if (vm.checkUserRight(GET_OBJECTS_RIGHT)) {
              vm[c.GET_COMPANIES]();
              vm[c.GET_DISTB]();
              vm[c.GET_DISTB_COMPANIES]();
              vm[c.GET_DISTB_OBJECTS]();
              vm[c.GET_DISTB_CONTACTS]();
              vm[c.GET_OBJECTS]();
              vm[commonTypes.SET_GETTING_NOTIFICATIONS_STATUS](true);
              vm[commonTypes.GET_NOTIFICATIONS]();
            } else {
              vm.$router.push("/settings");
            }
            clearInterval(timerId);
          }
        }
      }, 200);
    });
  },
  beforeRouteLeave(to, from, next) {
    this[c.SET_SORT]({ name: null, type: null });
    this[c.SET_SELECTED_ITEMS]({ removeAll: true });
    next();
  },
  components: { ClientHeader, Spinner },
};
</script>
