import * as c from "@/store/clients/constants";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      tab: 1,
      agreeToRemove: false,
      innerPopupOpen: false,
      innerPopupPreloader: false,
      localPreloader: false,
    };
  },
  mounted() {
    setTimeout(() => {
      document
        .querySelector(".event-popup:not(.event-popup-multichange)")
        .classList.add("event-popup_active");
    }, 300);
  },

  methods: {
    ...mapActions("clients", [
      c.SELECT_COMPANY,
      c.SELECT_OBJECT,
      c.SELECT_DISTB,
    ]),
    close() {
      const self = this;
      document
        .querySelector(".event-popup:not(.event-popup-multichange)")
        .classList.remove("event-popup_active");
      setTimeout(() => self.$router.push("/"), 500);
      this[c.SELECT_OBJECT](null);
      this[c.SELECT_COMPANY](null);
      this[c.SELECT_DISTB](null);
      this.localPreloader = false;
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.close();
      }
    },
    activeTabClass(tab) {
      return tab === this.tab ? "cm-popup-nav__item--active" : "";
    },
  },
};
