<template>
  <div>
    <div class="sup-chat__window-top" ref="upperPart">
      <div class="sup-chat__window-top__navbar">
        <div
          class="task-popup-nav__full-btn"
          :class="showFixedContent ? '_active' : ''"
        >
          <i></i>
        </div>
        <div
          class="task-popup-nav__item-icon btn-pointer"
          @click="toggleFixedContent"
        >
          <svg width="20" height="14">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/sprite.svg#request-header-hamburger"
            ></use>
          </svg>
        </div>
        <p
          class="sup-chat__window-top__title btn-pointer"
          @click="toggleFixedContent"
        >
          Описание
        </p>
      </div>
      <div
        class="sup-chat__window-top__content"
        :class="showFixedContent ? 'sup-chat__window-top__content-active' : ''"
      >
        <div class="sup-chat__window-top__item">
          <p class="sup-chat__window-top__desc">
            {{ selectedRequest.Description }}
          </p>
        </div>
        <div
          class="sup-chat__window-top__item sup-chat__window-top__item-files"
          v-if="requestDescriptionFiles && requestDescriptionFiles.length"
        >
          <template v-for="(item, index) in requestDescriptionFiles">
            <span
              :key="'fixed' + index"
              v-if="item.file && checkFileExt(item.name)"
              @click="showImagePopup(apiUrl + item.file.replace('api2', 'api'))"
              style="cursor: pointer; margin-left: 0; margin-right: 10px"
              class="sup-message__file"
            >
              <img
                :src="apiUrl + item.file.replace('api2', 'api')"
                alt="file"
              />
            </span>
            <a
              class="task-files-list__img task-files-list__img--file"
              :key="'fixed' + index"
              v-else
              :href="getFileSrc(item.file.replace('api2', 'api'))"
              download
              @click.prevent="
                downloadURI(
                  getFileSrc(item.file.replace('api2', 'api')),
                  item.name
                )
              "
              style="cursor: pointer; margin-left: 0; margin-right: 10px"
            >
              <img :src="getNonImageTypeFileIcon(item.name)" />
            </a>
          </template>
        </div>
      </div>
    </div>
    <div
      class="sup-chat__window-top _floating"
      :class="floatingHeaderIsShown ? '_floating-active' : ''"
    >
      <div class="sup-chat__window-top__navbar">
        <div
          class="task-popup-nav__full-btn"
          :class="showContent ? '_active' : ''"
          @click="$emit('scrollToTop')"
        >
          <i></i>
        </div>
        <div
          class="task-popup-nav__item-icon btn-pointer"
          @click="$emit('onContentToggle')"
        >
          <svg width="20" height="14">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/sprite.svg#request-header-hamburger"
            ></use>
          </svg>
        </div>
        <p
          class="sup-chat__window-top__title btn-pointer"
          @click="$emit('onContentToggle')"
        >
          Описание
        </p>
      </div>
      <div
        class="sup-chat__window-top__content"
        :class="showContent ? 'sup-chat__window-top__content-active' : ''"
      >
        <div class="sup-chat__window-top__item">
          <p class="sup-chat__window-top__desc">
            {{ selectedRequest.Description }}
          </p>
        </div>
        <div
          class="sup-chat__window-top__item sup-chat__window-top__item-files"
          v-if="requestDescriptionFiles && requestDescriptionFiles.length"
        >
          <template v-for="(item, index) in requestDescriptionFiles">
            <span
              :key="'floating' + index"
              v-if="item.file && checkFileExt(item.name)"
              @click="showImagePopup(apiUrl + item.file.replace('api2', 'api'))"
              style="cursor: pointer; margin-left: 0; margin-right: 10px"
              class="sup-message__file"
            >
              <img
                :src="apiUrl + item.file.replace('api2', 'api')"
                alt="file"
              />
            </span>
            <a
              class="task-files-list__img task-files-list__img--file"
              :key="'floating' + index"
              v-else
              :href="getFileSrc(item.file.replace('api2', 'api'))"
              download
              @click.prevent="
                downloadURI(
                  getFileSrc(item.file.replace('api2', 'api')),
                  item.name
                )
              "
              style="cursor: pointer; margin-left: 0; margin-right: 10px"
            >
              <img :src="getNonImageTypeFileIcon(item.name)" />
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { mapGetters } from "vuex";
export default {
  mixins: [common],
  name: "RequestWindowHeader",
  data() {
    return {
      showFixedContent: true,
    };
  },
  props: [
    "selectedRequest",
    "checkFileExt",
    "apiUrl",
    "showContent",
    "floatingHeaderIsShown",
  ],
  computed: {
    ...mapGetters("rpsSupport", ["messages", "requestDescriptionFiles"]),
  },
  methods: {
    toggleFixedContent() {
      this.showFixedContent = !this.showFixedContent;
    },
  },
};
</script>

<style scoped></style>
