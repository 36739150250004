export default {
  props: {
    isOpen: false,
    fieldName: null,
  },
  // mounted () {
  //   setTimeout(() => {
  //     document.querySelector('.event-popup:not(.event-popup-multichange)').classList.add('event-popup_active')
  //   }, )
  // },
  methods: {
    closePopup() {
      // document.querySelector('.event-popup:not(.event-popup-multichange)').classList.remove('event-popup_active')
      // setTimeout(() => {
      this.$emit("onClosePopup", this.fieldName);
      // }, 200)
    },
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup();
      }
    },
  },
};
