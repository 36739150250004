import * as _ from "lodash";
import { getCookie } from "@/utils";
export default {
  countInterval: (state) => state.countInterval,
  activeTab: (state) => _.findKey(state.activeTabs, (i) => i === true),
  count: (state) => state.count,
  requests: (state) => state.requests,
  filteredRequests: (state, getters) => {
    let requests = state.requests[getters.activeTab].filter((i) => {
      const re = new RegExp(state.searchInput, "gi");
      return re.test(i.Subject) || re.test(i.Id);
    });
    requests = _.orderBy(
      requests,
      ["LastMessageTime"],
      [state.requestFilters.activity]
    );
    if (state.requestFilters.status.name !== "Все") {
      requests = requests.filter(
        (i) => +i.RealStatusId === +state.requestFilters.status.Id
      );
    }
    if (state.requestFilters.messages !== "Все") {
      requests = requests.filter((i) => i.Unread.length);
    }
    return requests;
  },
  sender: (state) => {
    if (state.supportData) {
      return _.find(
        state.supportData.Sender[Object.keys(state.supportData.Sender)[0]],
        (value, key) => key === state.selectedRequest.Sender
      );
    }
  },
  currentUser: (state) => {
    if (state.supportData) {
      const clientId = getCookie("UserId");
      return _.find(
        state.supportData.UserId[Object.keys(state.supportData.UserId)[0]],
        (value, key) => key === clientId
      );
    }
  },
  messages: (state) => {
    return _.orderBy(state.messages, ["Id"], ["asc"]);
  },
  requestFiles: (state) => {
    if (state.messages && state.messages.length) {
      let files = _.find(state.messages, (i) => i.SenderName === "Описание");
      files = files === void 0 ? [] : files.Files;
      return files;
    } else {
      return [];
    }
  },
  selectedRequest: (state) => state.selectedRequest,
  parentData: (state) => state.parentData,
};
