<template>
  <div
    class="task-to-create"
    :class="{
      'task-to-create--open': open || hideHeadRow,
      'task-to-create--selected': isSelected,
      'task-to-create--hide-head-row': hideHeadRow,
    }"
  >
    <div class="task-to-create__row" v-if="!hideHeadRow">
      <div
        class="task-to-create__counter-label"
        @click.shift.prevent="$emit('shiftSelection', task)"
      >
        <label class="task-to-create__counter-checkbox checkbox">
          <input
            class="checkbox__input task-to-create__checkbox main_checkbox_input"
            type="checkbox"
            @click.exact.prevent="
              isSelected ? $emit('unselect') : $emit('select')
            "
            :class="{
              'checkbox__box--active': isSelected,
            }"
          />
          <span class="checkbox__box">
            <svg class="checkbox__icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
            </svg>
          </span>
        </label>
        <div class="task-to-create__counter-value">{{ index + 1 }}</div>
      </div>
      <div class="task-to-create__open-btn" @click="disclose">
        <svg width="12" height="7">
          <use
            xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
          ></use>
        </svg>
      </div>
      <div class="task-to-create__name">
        <input
          type="text"
          placeholder="Введите название задачи"
          v-model="task.TemplateTaskName"
          @change="saveAfterEdit(null, () => {}, 'TemplateTaskName')"
        />
      </div>
      <div class="task-to-create__finish-date">
        <p v-if="taskForm.FinishDate">{{ getFinishDate }}</p>
        <p v-else>Указать срок</p>
        <date-input
          :date="taskForm.FinishDate"
          :timeValue="taskForm.FinishTime"
          :format="'DD MMMM YYYY в HH:mm'"
          @setDate="setDate"
        />
      </div>
      <div class="task-to-create__responsible">
        <div
          class="task-to-create__responsible-no-person"
          @click="responsibleBubbleOpen = !responsibleBubbleOpen"
        >
          <template v-if="!taskForm.ResponsibleId">
            <svg width="32" height="32">
              <use
                xlink:href="/static/images/tasks/sprite.svg#no-person-icon"
              ></use>
            </svg>
          </template>
          <UserAvatar :user="getUser(taskForm.ResponsibleId)" />
        </div>
        <transition name="fade">
          <PersonsListSearch
            :emptyPlaceholder="'Убрать ответственного'"
            v-if="responsibleBubbleOpen"
            :selectedPersonId="taskForm.ResponsibleId"
            :outerUsers="localUsersArray"
            @choose="setResponsible"
            @close="responsibleBubbleOpen = false"
          />
        </transition>
      </div>
    </div>
    <div
      class="task-to-create__form"
      v-if="
        (open || hideHeadRow) &&
        taskForm &&
        Object.keys(taskForm).length > 4 &&
        !preloader
      "
    >
      <DescriptionInput
        ref="description-input"
        :form="taskForm"
        :task="task"
        :showNameField="hideHeadRow"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <FilesInput
        ref="filesInput"
        @saveAfterEdit="saveAfterEdit"
        @removeFile="removeFile"
        :form="taskForm"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
      />

      <ResponsibleInput
        :form="taskForm"
        :localUsersArray="localUsersArray"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <TaskSettingsInput
        :form="taskForm"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <FinishDateInput
        :form="taskForm"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <AddressInput
        v-if="isShowedInput('Address')"
        :form="taskForm"
        :watchers="taskForm.Watchers"
        :localUsersArray="localUsersArray"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />
      <ContactsInput
        v-if="isShowedInput('Contacts')"
        :form="taskForm"
        :watchers="taskForm.Watchers"
        :localUsersArray="localUsersArray"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />
      <WatchersInput
        v-if="isShowedInput('Watchers')"
        :form="taskForm"
        :watchers="taskForm.Watchers"
        :localUsersArray="localUsersArray"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <!-- подписчики-->
      <WatchersInput
        v-if="isShowedInput('Subscribers')"
        @saveAfterEdit="saveAfterEdit"
        :forSubscribers="true"
        :form="taskForm"
        :noSave="noSave"
        :watchers="taskForm.Subscribers"
        :localUsersArray="localUsersArray"
      />
      <ProcessInput
        v-if="isShowedInput('Process')"
        :form="taskForm"
        :watchers="taskForm.Watchers"
        :localUsersArray="localUsersArray"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />
      <TagsInput
        v-if="isShowedInput('Tags')"
        :form="taskForm"
        :watchers="taskForm.Watchers"
        :localUsersArray="localUsersArray"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        @saveAfterEdit="saveAfterEdit"
      />

      <DocFilesInput
        v-if="isShowedInput('DocFiles')"
        @saveAfterEdit="saveAfterEdit"
        @removeFile="removeFile"
        :noSave="noSave"
        :form="taskForm"
        :taskId="task.Id"
        :templateModel="templateModel"
      />
      <SubtasksInput
        :form="taskForm"
        :subtasks="task.subtasks"
        :taskId="task.Id"
        :templateModel="templateModel"
        :noSave="noSave"
        :editedTemplateTasks="editedTemplateTasks"
        @setSubtasks="setSubtasks"
        @saveAfterEdit="saveAfterEdit"
        @pushTasksToDeletedArray="$emit('pushTasksToDeletedArray', $event)"
        @editTemplateTask="
          (data, formData, callback) =>
            $emit('editTemplateTask', data, formData, callback)
        "
        @removeFile="
          (payload, errorCallback) =>
            $emit('removeFile', payload, errorCallback)
        "
        @shiftSelection="($event) => $emit('shiftSelection', $event)"
      />
    </div>
    <SimplePreloader v-if="preloader" />
  </div>
</template>

<script>
import moment from "moment";
import DescriptionInput from "@/modules/tasks/components/TaskCreateForm/Inputs/DescriptionInput";
import FilesInput from "@/modules/tasks/components/TaskCreateForm/Inputs/FilesInput";
import ResponsibleInput from "@/modules/tasks/components/TaskCreateForm/Inputs/ResponsibleInput";
import TaskSettingsInput from "@/modules/tasks/components/TaskCreateForm/Inputs/TaskSettingsInput";
import FinishDateInput from "@/modules/tasks/components/TaskCreateForm/Inputs/FinishDateInput";
import WatchersInput from "@/modules/tasks/components/TaskCreateForm/Inputs/WatchersInput";
import DocFilesInput from "@/modules/tasks/components/TaskCreateForm/Inputs/DocFilesInput";
import AddressInput from "@/modules/tasks/components/TaskCreateForm/Inputs/AddressInput";
import ContactsInput from "@/modules/tasks/components/TaskCreateForm/Inputs/ContactsInput";
import TagsInput from "@/modules/tasks/components/TaskCreateForm/Inputs/TagsInput";
import ProcessInput from "@/modules/tasks/components/TaskCreateForm/Inputs/ProcessInput";
import SubtasksInput from "@/modules/tasks/components/TaskCreateForm/Inputs/SubtasksInput/SubtasksInput";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";
import common from "@/mixins/tasks/common";
import UserAvatar from "@/modules/tasks/components/CommonComponents/UserAvatar";
import saveMixin from "@/modules/tasks/components/TaskCreateForm/mixins/saveMixin";

export default {
  name: "TaskToCreate",
  mixins: [common, saveMixin],
  props: [
    "localUsersArray",
    "task",
    "index",
    "isSelected",
    "templateModel",
    "noSave",
    "editedTemplateTasks",
  ],
  data() {
    return {
      open: false,
      responsibleBubbleOpen: false,
      preloader: false,
    };
  },
  created() {
    if (this.index === 0) this.open = true;
    if (this.templateModel && this.open) {
      this.loadTaskInfo();
    }
  },
  computed: {
    taskForm() {
      return this.task?.TemplateTaskSettings;
    },
    hideHeadRow() {
      return this.index === void 0;
    },
    getFinishDate() {
      const date = moment(this.taskForm.FinishDate);
      const time = this.taskForm.FinishTime
        ? moment(this.taskForm.FinishTime, "HH:mm:ss")
        : false;
      if (time) {
        date.set({ hour: time.format("HH"), minute: time.format("mm") });
      }
      return date.locale("ru").format("DD.MM.YYYY HH:mm");
    },
  },
  methods: {
    setDate(date) {
      this.taskForm.FinishDate = date;
      this.taskForm.FinishTime = date ? moment(date).format("HH:mm:ss") : null;
      this.saveAfterEdit(null, () => {}, "FinishDate");
    },
    disclose() {
      this.open = !this.open;
      if (this.open) {
        this.loadTaskInfo();
      }
    },
    loadTaskInfo() {
      if (this.templateModel && this.task.Id.length > 7) {
        this.preloader = true;
        this.$emit("loadTaskDetail", () => {
          this.preloader = false;
        });
      }
    },
    setResponsible(id) {
      this.taskForm.ResponsibleId = id;
      this.saveAfterEdit(null, () => {}, "ResponsibleId");
    },
    removeFile(id, errorCallback) {
      this.$emit(
        "removeFile",
        {
          TaskId: this.task.Id,
          FileId: id,
        },
        errorCallback
      );
      this.saveAfterEdit(null, () => {}, "Files");
    },
    setSubtasks(data) {
      this.task.subtasks = data;
    },
    isShowedInput(inputName) {
      if (this.noSave && this.taskForm) {
        return !this.taskForm.HiddenFields[inputName];
      }
      return true;
    },
  },
  components: {
    DescriptionInput,
    ResponsibleInput,
    FinishDateInput,
    AddressInput,
    ContactsInput,
    FilesInput,
    DocFilesInput,
    WatchersInput,
    TagsInput,
    ProcessInput,
    SubtasksInput,
    TaskSettingsInput,
    PersonsListSearch,
    UserAvatar,
  },
};
</script>

<style scoped lang="scss">
.task-to-create {
  min-height: 64px;
  position: relative;
  background: #fff;
}
.task-to-create:hover {
  .task-to-create__counter-checkbox {
    display: block;
  }
  .task-to-create__counter-value {
    display: none;
  }
}
.task-to-create--open .task-to-create__open-btn svg {
  transform: rotate(0);
}
.task-to-create--selected {
  .task-to-create__row {
    background: rgba(255, 187, 51, 0.1);
  }
  .task-to-create__counter-checkbox {
    display: block;
  }
  .task-to-create__counter-value {
    display: none;
  }
}
.task-to-create--hide-head-row {
  .task-to-create__form {
    padding-left: 0;
  }
}
.task-to-create__row {
  border-bottom: 1px solid #efefef;
  height: 64px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 40px 0px 24px;
}
.task-to-create__form {
  background: #f9f9f9;
  padding-left: 47px;
}
.task-to-create__open-btn {
  background: #f2f2f2;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  cursor: pointer;
}
.task-to-create__open-btn svg {
  transform: rotate(-90deg);
}
.task-to-create__name {
  flex: 1;
}
.task-to-create__name input {
  font-size: 16px;
  color: #000000;
  width: 100%;
}
.task-to-create__name input::placeholder {
  color: #929294;
}
.task-to-create__counter-label {
  margin-right: 27px;
  width: 16px;
  text-align: center;
}
.task-to-create__counter-value {
  font-size: 13px;
  color: #b2b2b2;
}
.task-to-create__counter-checkbox {
  display: none;
}
.task-to-create__finish-date {
  margin-left: auto;
  font-size: 11px;
  color: #007fff;
  cursor: pointer;
  position: relative;
}
.task-to-create__responsible {
  margin-left: 26px;
  cursor: pointer;
}
.task-to-create__responsible-no-person {
  display: flex;
}
</style>

<style>
.task-to-create {
  user-select: none;
}
.task-to-create__finish-date .mx-datepicker {
  position: absolute;
  top: -10px;
}
.task-to-create__finish-date .mx-input-wrapper {
  opacity: 0;
}
.task-to-create__responsible .task-table-person__circle {
  margin: 0;
  width: 31px;
  height: 31px;
  line-height: 31px;
}
.task-to-create__form .task-create-form__row {
  border-bottom: 0;
  position: relative;
}
.task-to-create__form .task-create-form__row:after {
  content: "";
  position: absolute;
  left: -47px;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #efefef;
}
</style>
