import * as c from "./constants";
import * as _ from "lodash";
import {
  deleteFetchedFile,
  prepareFetchedFiles,
} from "../../mixins/tasks/func";
import Vue from "vue";

function preparedTasksData(state, data) {
  return _.map(data, (i) => {
    i.tags = i.Tags !== void 0 ? i.Tags : i.tags || [];
    i.childs = i.childs || [];
    i.Notifications = i.Notifications || {
      CountNewNotifications: 0,
      CountUnreadNotifications: 0,
    };
    if (state.settings.hideCompletedTasks) {
      i.childs = _.filter(i.childs, (i) => i.Status !== "2");
    }
    return i;
  });
}

export default {
  [c.SET_USERS](state, data) {
    state.users = data
      ? data.map((i) => {
          return { ...i, ...i.User, Id: i.UserId, FullName: i.User.Fio };
        })
      : [];
    // state.users = [];
  },
  [c.GET_OBJECTS](state, data) {
    state.objects = data.objects;
  },
  // roles
  [c.GET_ROLES](state, data) {
    state.roles = data;
  },
  [c.CREATE_ROLE](state, data) {
    state.roles.push(data);
  },
  [c.EDIT_ROLE](state, { id, data }) {
    state.roles = _.map(state.roles, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_ROLE](state, Id) {
    state.roles = _.reject(state.roles, { Id });
  },

  // rights
  [c.GET_RIGHTS](state, data) {
    state.rights = data;
  },
  [c.CREATE_RIGHT](state, data) {
    state.rights.push(data);
  },
  [c.EDIT_RIGHT](state, { id, data }) {
    state.rights = _.map(state.rights, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_RIGHT](state, Id) {
    state.rights = _.reject(state.rights, { Id });
  },

  // templates
  [c.GET_TEMPLATES](state, data) {
    _.map(data, (i) => {
      // i.TemplateTaskSettings = JSON.parse(i.TemplateTaskSettings);
      // i.TemplateTaskSettings = i.TemplateTaskSettings;
      return i;
    });
    state.templates = data;
  },
  [c.CREATE_TEMPLATE](state, data) {
    state.templates.push(data);
  },
  [c.EDIT_TEMPLATE](state, { id, data }) {
    // data.TemplateTaskSettings = JSON.parse(data.TemplateTaskSettings);
    state.templates = _.map(state.templates, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_TEMPLATE](state, Id) {
    state.templates = _.reject(state.templates, { Id });
  },

  // groups
  [c.GET_GROUPS](state, data) {
    state.allGroups = data;
  },
  // eslint-disable-next-line no-unused-vars
  [c.CREATE_GROUP](state, data) {
    state.allGroups.push(data);
  },
  // eslint-disable-next-line no-unused-vars
  [c.EDIT_GROUP](state, data) {
    state.settings.currentGroup = data;
  },
  // eslint-disable-next-line no-unused-vars
  [c.DELETE_GROUP](state, Id) {
    // state.groups = _.reject(state.groups, ["GroupId", Id]);
    // if (state.groups.filter((i) => !i.GlobalGroupName)[0]) {
    //   state.settings.currentGroup = JSON.parse(
    //     JSON.stringify(
    //       state.groups.filter((i) => !i.GlobalGroupName)[0]?.ItemInfo
    //     )
    //   );
    // }
  },

  [c.CHANGE_ORDER](state, items) {
    state.tasks = items;
  },
  // tasks
  [c.SET_TASKS_TO_NULL](state) {
    state.tasks = [];
  },
  [c.GET_TASKS](state, data) {
    state.tasks = preparedTasksData(state, data);
    // state.tasks = [];
    // setTimeout(() => {
    //   state.tasks = data;
    // });
  },
  [c.ADD_TASKS_TO_LIST](state, data) {
    state.tasks.push(...preparedTasksData(state, data));
  },
  [c.SHOW_TASK](state, data) {
    if (data.checklists.length) {
      _.each(data.checklists, (i) => {
        if (i.tasks && i.tasks.length) {
          _.map(i.tasks, (i) => {
            // i.Notification = {
            //   NotificationType: null,
            //   NotificationDate: '',
            //   NotificationTime: '',
            //   NotificationLatitude: '',
            //   NotificationLongitude: '',
            // }
            // if (i.Notifications.length) {
            //   i.Notification = i.Notifications[0]
            // }
            i.Position = +i.Position;
            i.personsBubbleOpen = false;
            return i;
          });
          i.tasks = _.orderBy(i.tasks, ["Position"], ["asc"]);
          let count = 1;
          i.tasks = _.map(i.tasks, (i) => {
            i.Position = count;
            count++;
            return i;
          });
        } else {
          i.tasks = [];
        }
      });
    }
    if (data.entityModel.childs.length) {
      data.entityModel.childs = _.map(data.entityModel.childs, (i) => {
        i.Notifications = i.Notifications || {
          CountNewNotifications: 0,
          CountUnreadNotifications: 0,
        };
        i.childs = i.childs || [];
        return i;
      });
    }
    state.selectedTask = data;
    state.selectedTask.files = [];
    state.selectedTask.comments = [];
  },
  [c.SET_TASK](state, data) {
    if (data.entityModel && data.entityModel.childs.length) {
      data.entityModel.childs = _.map(data.entityModel.childs, (i) => {
        i.Notifications = i.Notifications || {
          CountNewNotifications: 0,
          CountUnreadNotifications: 0,
        };
        return i;
      });
    }
    state.selectedTask = data;
  },
  [c.SET_TASK_CHILDS](state, { parentTask, tasks, section, arrayToMutate }) {
    if (!arrayToMutate) arrayToMutate = state.tasks;

    function recursiveDelete(array) {
      _.each(tasks, (taskForRemove) => {
        const indexForRemove = array.findIndex(
          (x) => x.Id === taskForRemove.Id
        );
        if (indexForRemove !== -1) {
          // console.log(
          //   "indexForRemove",
          //   array.map((i) => i.TaskName),
          //   indexForRemove,
          //   taskForRemove.TaskName
          // );
          array.splice(indexForRemove, 1);
        } else {
          _.each(array, (i) => {
            if (i.childs && i.childs.length) {
              recursiveDelete(i.childs);
            }
          });
        }
      });
    }
    function recursiveFunc(item) {
      if (item.Id === parentTask.Id) {
        if (!item.childs) item.childs = [];
        item.childs = _.reject(item.childs, (i) => i.SectionId === section.Id);
        item.childs.push(...tasks);
      } else if (item.childs && item.childs.length) {
        item.childs = _.map(item.childs, (i) => {
          recursiveFunc(i);
          return i;
        });
      }
    }
    if (tasks.length) {
      recursiveDelete(arrayToMutate);
    }

    if (!parentTask) {
      if (tasks.length) {
        const arrayToPush = _.filter(
          tasks,
          (i) => !arrayToMutate.find((t) => t.Id === i.Id)
        );
        const arrayToDelete = _.filter(
          arrayToMutate.filter((i) => i.SectionId === section.Id),
          (i) => !tasks.find((t) => t.Id === i.Id)
        );
        if (arrayToPush) {
          console.log(
            "arrayToPush",
            arrayToPush.map((i) => i.TaskName)
          );
          arrayToMutate.push(...arrayToPush);
        }
        if (arrayToDelete) {
          console.log("arrayToDelete", arrayToDelete);
          arrayToMutate = _.reject(arrayToMutate, (i) =>
            arrayToDelete.find((a) => a.Id === i.Id)
          );
        }
      } else {
        console.log("no tasks.length");
      }
    } else {
      console.log(parentTask.TaskName, "state.tasks", arrayToMutate);
      arrayToMutate = _.map(arrayToMutate, (i) => {
        recursiveFunc(i);
        return i;
      });
    }
    return arrayToMutate;
  },
  [c.UNSELECT_TASK](state) {
    state.selectedTask = null;
  },
  [c.CREATE_TASK](state, data) {
    data.childs = [];
    function recursiveFunc(i) {
      if (i.Id === data.ParentId) {
        if (!i.childs) i.childs = [];
        i.childs.push(data);
        return i;
      } else if (i.childs && i.childs.length) {
        i.childs = _.map(i.childs, (c) => {
          c = recursiveFunc(c);
          return c;
        });
      }
      return i;
    }
    if (data.ParentId) {
      state.tasks = _.map(state.tasks, (i) => {
        i = recursiveFunc(i);
        return i;
      });
    } else {
      state.tasks.push(data);
    }
  },
  [c.REMOVE_VIRTUAL_TASK](state, { virtualId, array = [] }) {
    function recursiveDelete(array) {
      const indexForRemove = array.findIndex((x) => x.VirtualId === virtualId);
      if (indexForRemove !== -1) {
        array.splice(indexForRemove, 1);
      } else {
        _.each(array, (i) => {
          if (i.childs && i.childs.length) {
            recursiveDelete(i.childs);
          }
        });
      }
    }
    recursiveDelete(array);
    recursiveDelete(state.tasks);
  },
  [c.EDIT_TASK](state, { id, data }) {
    // if (state.settings.hideCompletedTasks) {
    //   if (data.childs && data.childs.length) {
    //     data.childs = _.filter(data.childs, i => i.Status !== '2')
    //   }
    // }
    function recursiveFunc(i) {
      if (i.Id === id) {
        return { ...i, ...data, childs: i.childs };
      } else if (i.childs && i.childs.length) {
        i.childs = _.map(i.childs, (c) => {
          c = recursiveFunc(c);
          return c;
        });
      }
      return i;
    }

    state.tasks = _.map(state.tasks, (i) => {
      i = recursiveFunc(i);
      return i;
    });
    state.tasks = JSON.parse(JSON.stringify(state.tasks));

    // state.sections = _.map(state.sections, (i) => {
    //   i.tasks = _.map(i.tasks, (i) => {
    //     i = recursiveFunc(i);
    //     return i;
    //   });
    //   return i;
    // });
  },
  [c.DELETE_TASK](state, Id) {
    const recursiveDelete = (task) => {
      task.childs = _.reject(task.childs, { Id });
      task.depends = _.reject(task.depends, { Id });
      task.childs.map((i) => {
        recursiveDelete(i);
        return i;
      });
    };
    state.tasks = _.reject(state.tasks, { Id });
    state.tasks = state.tasks.map((i) => {
      recursiveDelete(i);
      return i;
    });
  },
  [c.DELETE_DEPENDENCIES_TASK](state, Id) {
    const recursiveDelete = (task) => {
      task.depends = _.reject(task.depends, ["DependRecordId", Id]);
      if (task.childs) {
        task.childs.map((i) => {
          recursiveDelete(i);
          return i;
        });
      }
    };
    state.tasks = state.tasks.map((i) => {
      recursiveDelete(i);
      return i;
    });
    state.tasks = JSON.parse(JSON.stringify(state.tasks));
  },
  [c.GET_FILES_FOR_TASK](state, files) {
    state.selectedTask.files = files;
  },
  // other
  [c.CREATE_CHECKLIST](state, { checklistEl, localId }) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.localId === localId) i = checklistEl;
          return i;
        }
      );
    }
  },
  [c.DELETE_CHECKLIST](state, Id) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.reject(state.selectedTask.checklists, {
        Id,
      });
    }
  },
  [c.CREATE_CHECKLIST_ITEM_LOCAL](state, taskEl) {
    taskEl.Position = +taskEl.Position;
    taskEl.personsBubbleOpen = false;
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.Id === taskEl.ChecklistId) {
            i.tasks = i.tasks || [];
            i.tasks.push(taskEl);
          }
          return i;
        }
      );
    }
  },
  [c.CREATE_CHECKLIST_LOCAL](state, checklistEl) {
    if (state.selectedTask) {
      state.selectedTask.checklists.push({ ...checklistEl, tasks: [] });
    }
  },
  [c.ORDER_CHECKLIST_ITEMS](state) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          i.tasks = i.tasks || [];
          i.tasks = _.orderBy(i.tasks, ["Position"], ["asc"]);
          let count = 1;
          i.tasks = _.map(i.tasks, (i) => {
            i.Position = count;
            count++;
            return i;
          });
          return i;
        }
      );
    }
  },
  [c.EDIT_CHECKLIST_ITEM](state, data) {
    data.Position = +data.Position;
    data.personsBubbleOpen = false;
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.Id === data.ChecklistId) {
            i.tasks = i.tasks || [];
            i.tasks = _.map(i.tasks, (i) => {
              if (i.Id === data.Id) {
                i = data;
                // i.Notification = {
                //   NotificationType: null,
                //   NotificationDate: '',
                //   NotificationTime: '',
                //   NotificationLatitude: '',
                //   NotificationLongitude: '',
                // }
                // if (i.Notifications.length) {
                //   i.Notification = i.Notifications[0]
                // }
              }
              return i;
            });
          }
          return i;
        }
      );
    }
  },
  [c.CREATE_CHECKLIST_ITEM](state, { taskEl, localId }) {
    taskEl.Position = +taskEl.Position;
    taskEl.personsBubbleOpen = false;
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.Id === taskEl.ChecklistId) {
            i.tasks = i.tasks || [];
            i.tasks = _.map(i.tasks, (i) => {
              if (i.localId === localId) {
                i = taskEl;
                // i.Notification = {
                //   NotificationType: null,
                //   NotificationDate: '',
                //   NotificationTime: '',
                //   NotificationLatitude: '',
                //   NotificationLongitude: '',
                // }
                // if (i.Notifications.length) {
                //   i.Notification = i.Notifications[0]
                // }
              }
              return i;
            });
          }
          return i;
        }
      );
    }
  },
  [c.DELETE_CHECKLIST_ITEM](state, data) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.Id === data.ChecklistId) {
            i.tasks = _.reject(i.tasks, ["Id", data.Id]);
          }
          return i;
        }
      );
    }
  },
  [c.ADD_FILES_TO_TASK](state, files) {
    if (state.selectedTask) {
      prepareFetchedFiles(files, state.selectedTask);
    }
  },
  [c.DELETE_FILES_FROM_TASK](state, file) {
    if (state.selectedTask) {
      deleteFetchedFile(file, state.selectedTask);
    }
  },
  [c.CREATE_COMMENT](state, data) {
    if (state.selectedTask) {
      state.selectedTask.comments.push(data);
    }
  },
  [c.EDIT_COMMENT](state, { id, data }) {
    state.selectedTask.comments = _.map(state.selectedTask.comments, (i) => {
      if (i.Id === id) i = { ...data, VisibleUsersIds: i.VisibleUsersIds };
      return i;
    });
  },
  [c.CREATE_CONTACT](state, data) {
    if (state.selectedTask) {
      state.selectedTask.contacts.push(data);
    }
  },
  [c.EDIT_CONTACT](state, { id, data }) {
    state.selectedTask.contacts = _.map(state.selectedTask.contacts, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_CONTACT](state, Id) {
    if (state.selectedTask) {
      state.selectedTask.contacts = _.reject(state.selectedTask.contacts, {
        Id,
      });
    }
  },
  [c.ADD_FILE_TO_FAVORITE](state, data) {
    state.selectedTask.files = _.map(state.selectedTask.files, (i) => {
      if (i.Id === data.FileId) i.fileInFavoriteId = data.Id;
      return i;
    });
  },
  [c.REMOVE_FILE_FROM_FAVORITE](state, data) {
    state.selectedTask.files = _.map(state.selectedTask.files, (i) => {
      if (i.Id === data.FileId) i.fileInFavoriteId = null;
      return i;
    });
  },
  [c.ADD_SUBSCRIBER](state, data) {
    if (state.selectedTask) {
      state.selectedTask.subscriptions.push(data);
    }
  },
  [c.DELETE_SUBSCRIBER](state, Id) {
    if (state.selectedTask) {
      state.selectedTask.subscriptions = _.reject(
        state.selectedTask.subscriptions,
        { Id }
      );
    }
  },
  [c.RESET_SUBSCRIBERS_BUBBLE](state) {
    state.settings.textBubbleChoords.isOpen = false;
    state.settings.textBubbleChoords.el = null;
    state.settings.textBubbleChoords.retain = null;
    state.settings.textBubbleChoords.className = null;
    state.settings.textBubbleChoords.search = "";
  },
  // others
  [c.SET_PROGRESS_BAR_BY_FILES_UPLOAD](state, val) {
    state.progressFilesUpload = val;
  },
  [c.GET_TAGS](state, data) {
    state.tags = data;
  },
  [c.CREATE_TAG](state, data) {
    state.tags.push(data);
  },
  [c.EDIT_TAG](state, { id, data }) {
    state.tags = _.map(state.tags, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_TAG](state, Id) {
    state.tags = _.reject(state.tags, { Id });
  },
  [c.GET_TAG_GROUPS](state, data) {
    state.tagGroups = data;
  },
  [c.CREATE_TAGS_GROUP](state, data) {
    state.tagGroups.push(data);
  },
  [c.EDIT_TAGS_GROUP](state, { id, data }) {
    state.tagGroups = _.map(state.tagGroups, (i) => {
      if (i.Id === id) i = data;
      return i;
    });
  },
  [c.DELETE_TAGS_GROUP](state, Id) {
    state.tagGroups = _.reject(state.tagGroups, { Id });
  },
  [c.ADD_TAG_TO_TASK](state, { data }) {
    const foundTag = _.find(state.tags, ["Id", data.TagId]);
    const tag = { ...data, TagName: foundTag.TagName };
    if (state.selectedTask) {
      state.selectedTask.tags.push(tag);
    }
  },
  [c.REMOVE_TAG_FROM_TASK](state, data) {
    if (state.selectedTask) {
      state.selectedTask.tags = _.reject(state.selectedTask.tags, {
        Id: data.Id,
      });
    }
    let reloadTasks = false;

    state.tasks = _.map(state.tasks, (i) => {
      if (i.Id === data.TaskId) {
        i.tags = _.reject(i.tags, { Id: data.Id });
      } else if (i.childs) {
        i.childs = _.map(i.childs, (i) => {
          if (i.Id === data.TaskId) {
            i.tags = _.reject(i.tags, { Id: data.Id });
            reloadTasks = true;
          } else if (i.childs) {
            i.childs = _.map(i.childs, (i) => {
              if (i.Id === data.TaskId) {
                i.tags = _.reject(i.tags, { Id: data.Id });
                reloadTasks = true;
              }
              return i;
            });
          }
          return i;
        });
      }
      return i;
    });
    if (reloadTasks) {
      state.tasks = JSON.parse(JSON.stringify(state.tasks));
    }
  },
  [c.ADD_TAG_TO_GROUP]() {},
  [c.REMOVE_TAG_FROM_GROUP]() {},
  [c.GET_NOTIFICATIONS](state, data) {
    state.notifications = data;
  },
  [c.APPROVE_CHECKLIST_ITEM](state, data) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          if (i.Id === data.ChecklistId) {
            i.tasks = i.tasks || [];
            i.tasks = _.map(i.tasks, (i) => {
              if (i.Id === data.Id) {
                // i.Notification = {
                //   NotificationType: null,
                //   NotificationDate: '',
                //   NotificationTime: '',
                //   NotificationLatitude: '',
                //   NotificationLongitude: '',
                // }
                // if (i.Notifications.length) {
                //   i.Notification = i.Notifications[0]
                // }
                i.ResponsibleId = data.ApproveRequestId.UserId;
                i.ApproveRequestId = undefined;
              }
              return i;
            });
          }
          return i;
        }
      );
    }
  },
  [c.GET_CHILD_TASKS_DETAIL](state, { childs, depends, item }) {
    item.childs = _.map(childs, (i) => {
      i.tags = i.tags || [];
      i.childs = i.childs || [];
      i.Notifications = i.Notifications || {
        CountNewNotifications: 0,
        CountUnreadNotifications: 0,
      };
      return i;
    });
    item.depends = depends;
  },
  [c.CREATE_CHECKLIST_ITEM_NOTIFICATION](state, data) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          i.tasks = i.tasks || [];
          i.tasks = _.map(i.tasks, (i) => {
            if (i.Id === data.ChecklistItemId) {
              // i.Notification = data
              i.Notifications.push(data);
            }
            return i;
          });
          return i;
        }
      );
    }
  },
  [c.UPDATE_CHECKLIST_ITEM_NOTIFICATION](state, data) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          i.tasks = i.tasks || [];
          i.tasks = _.map(i.tasks, (i) => {
            if (i.Id === data.ChecklistItemId) {
              i.Notifications = _.map(i.Notifications, (i) => {
                if (i.Id === data.Id) i = data;
                return i;
              });
            }
            return i;
          });
          return i;
        }
      );
    }
  },
  [c.DELETE_CHECKLIST_ITEM_NOTIFICATION](state, data) {
    if (state.selectedTask) {
      state.selectedTask.checklists = _.map(
        state.selectedTask.checklists,
        (i) => {
          i.tasks = i.tasks || [];
          i.tasks = _.map(i.tasks, (i) => {
            if (i.Id === data.ChecklistItemId) {
              // i.Notification = {
              //   NotificationType: null,
              //   NotificationDate: '',
              //   NotificationTime: '',
              //   NotificationLatitude: '',
              //   NotificationLongitude: '',
              // }
              i.Notifications = _.reject(i.Notifications, ["Id", data.Id]);
            }
            return i;
          });
          return i;
        }
      );
    }
  },
  [c.ADD_WATCHER](state, data) {
    if (state.selectedTask) {
      state.selectedTask.watchers.push(data);
    }
  },
  [c.DELETE_WATCHER](state, Id) {
    if (state.selectedTask) {
      state.selectedTask.watchers = _.reject(state.selectedTask.watchers, {
        Id,
      });
    }
  },
  [c.GET_STANDART_FILTERS](state, data) {
    state.standartFilters = _.map(data, (i, index) => {
      return { ...i, index: +index };
    });
  },
  [c.GET_USER_FILTERS](state, data) {
    state.allUserFilters = data;
  },
  [c.CREATE_USER_FILTER](state, data) {
    state.userFilters.push(data);
  },
  [c.EDIT_USER_FILTER](state, data) {
    state.userFilters = _.map(state.userFilters, (i) => {
      if (i.FilterId === data.Id) i.ItemInfo = data;
      return i;
    });
  },
  [c.DELETE_USER_FILTER](state, data) {
    state.userFilters = _.reject(state.userFilters, { FilterId: data.Id });
  },
  [c.GET_COUNT_NOTIFICATIONS](state, data) {
    state.countNotifications = {
      countNewNotifications: data.countNewNotifications,
      countUnreadNotifications: data.countUnreadNotifications,
    };
  },
  [c.APPROVE_TASK](state) {
    if (state.selectedTask) {
      state.selectedTask.approveRecordId = null;
    }
  },
  [c.GET_PROCESSES](state, data) {
    state.processes = data;
  },
  [c.SET_GROUP_PROCESSES](state, data) {
    state.groupProcesses = data;
  },
  [c.CREATE_PROCESS](state, data) {
    state.processes.push(data);
  },
  [c.UPDATE_PROCESS](state, data) {
    state.processes = _.map(state.processes, (i) => {
      if (i.Id === data.Id) i = data;
      return i;
    });
  },
  [c.DELETE_PROCESS](state, Id) {
    state.processes = _.reject(state.processes, { Id });
  },
  [c.CREATE_STATUS](state, data) {
    state.processes = _.map(state.processes, (i) => {
      if (i.Id === data.ProcessId) i.statuses.push(data);
      return i;
    });
  },
  [c.UPDATE_STATUS](state, data) {
    state.processes = _.map(state.processes, (p) => {
      p.statuses = _.map(p.statuses, (s) => {
        if (s.Id === data.Id) s = data;
        return s;
      });
      return p;
    });
  },
  [c.DELETE_STATUS](state, Id) {
    state.processes = _.map(state.processes, (p) => {
      p.statuses = _.reject(p.statuses, { Id });
      return p;
    });
  },
  [c.GET_USER_FAVORITES](state, data) {
    state.allFavorites = data;
  },
  [c.ADD_TO_FAVORITES](state, data) {
    state.favorites.push(data);
  },
  [c.DELETE_FROM_FAVORITES](state, Id) {
    state.favorites = _.reject(state.favorites, { Id });
  },
  [c.SET_TASK_PROCESS_STATUS](state, { taskId, statusId }) {
    function recursiveFunc(i) {
      if (i.Id === taskId) {
        i.StatusId = statusId;
        i.Status = "1";
      } else if (i.childs && i.childs.length) {
        _.each(i.childs, (c) => {
          recursiveFunc(c);
        });
      }
    }

    state.tasks = _.map(state.tasks, (i) => {
      recursiveFunc(i);
      return i;
    });
  },
  [c.UPDATE_USER_PROFILE](state, { data, id }) {
    state.users = _.map(state.users, (i) => {
      if (i.UserId === id) i = { ...i, ...data };
      return i;
    });
  },
  [c.TRANSFER_TASK_TO_OTHER_GROUP](state, data) {
    function recursiveFunc(i) {
      if (i.Id === data.Id) {
        i.GroupId = data.GroupId;
      } else if (i.childs && i.childs.length) {
        _.each(i.childs, (c) => {
          recursiveFunc(c);
        });
      }
    }

    state.tasks = _.map(state.tasks, (i) => {
      recursiveFunc(i);
      return i;
    });
  },

  [c.TRANSFER_TASK_TO_OTHER_TASK](state, data) {
    let indexToRemove = null;
    const elsToDelete = [];
    function recursiveFunc(i, index, arr, child = false) {
      console.log("qqq", i, data);
      if (i.Id === data.Id) {
        if (!child) {
          indexToRemove = index;
        } else {
          elsToDelete.push({ arr, index });
        }
        // i.ParentId = data.ParentId;
      } else if (i.Id === data.ParentId) {
        if (!i.childs) i.childs = [];
        i.childs.push(data);
      } else if (i.childs && i.childs.length) {
        _.each(i.childs, (c, index) => {
          console.log("eee2");
          recursiveFunc(c, index, i.childs, true);
        });
      }
    }
    state.tasks = _.map(state.tasks, (i, index) => {
      console.log("eee");
      recursiveFunc(i, index, state.tasks);
      // state.tasks.splice(i, index);
      return i;
    });

    _.each(elsToDelete, (item) => {
      item.arr.splice(item.index, 1);
    });

    setTimeout(() => {
      if (indexToRemove) {
        state.tasks.splice(indexToRemove, 1);
      }
    }, 200);
  },

  [c.RESET_TASK_NOTIFICATION](state, id) {
    function recursiveFunc(i) {
      if (i.Id === id) {
        i.Notifications = {
          CountNewNotifications: 0,
          CountUnreadNotifications: 0,
        };
      } else if (i.childs && i.childs.length) {
        _.each(i.childs, (c) => {
          recursiveFunc(c);
        });
      }
    }

    state.tasks = _.map(state.tasks, (i) => {
      recursiveFunc(i);
      return i;
    });
    if (state.selectedTask && state.selectedTask.entityModel.childs.length) {
      state.selectedTask.entityModel.childs = _.map(
        state.selectedTask.entityModel.childs,
        (i) => {
          recursiveFunc(i);
          return i;
        }
      );
    }
  },
  [c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY](state, array) {
    const keys = Object.keys(array);

    function recursiveFunc(i) {
      if (keys.includes(i.Id)) {
        i.Notifications = array[i.Id];
      } else if (i.childs && i.childs.length) {
        _.each(i.childs, (c) => {
          recursiveFunc(c);
        });
      }
    }

    state.tasks = _.map(state.tasks, (i) => {
      recursiveFunc(i);
      return i;
    });
    if (state.selectedTask && state.selectedTask.entityModel.childs.length) {
      state.selectedTask.entityModel.childs = _.map(
        state.selectedTask.entityModel.childs,
        (i) => {
          recursiveFunc(i);
          return i;
        }
      );
    }
  },
  [c.SET_TASKS_NOTIFICATIONS_IDS_ARRAY](state, { idsArray, push }) {
    if (push) {
      state.tasksIdsArrayForNotificationsGet.push(idsArray);
    } else {
      state.tasksIdsArrayForNotificationsGet = idsArray;
    }
  },
  [c.GET_USER_TASKS_MODULE_RIGHTS](state, data) {
    state.activeRights = data.activeRights;
    state.isAdmin = data.isAdmin;
  },
  [c.GET_CUSTOM_FILTER_PARAMS](state, data) {
    state.customFilterParams = data;
  },
  // automations
  [c.GET_AUTOMATION_OBJECTS_TYPES](state, data) {
    state.automatizationsObjectsTypes = data;
  },
  [c.GET_AUTOMATION_ACTIONS](state, data) {
    state.automatizationsActions = data;
  },
  [c.GET_AUTOMATION_ACTIONS_OPTIONS](state, data) {
    state.automatizationsActionsOptions = data;
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS](state, data) {
    state.automatizationsActionsObjects = data;
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_TYPES](state, data) {
    state.automatizationsActionsObjectsTypes = data;
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS](state, data) {
    state.automatizationsActionsObjectsOptions = data;
  },
  [c.CREATE_AUTOMATION_TEMPLATE](state, data) {
    state.automatizations.push(data);
  },
  [c.GET_AUTOMATION_TEMPLATES](state, data) {
    state.automatizations = data;
  },
  [c.DELETE_AUTOMATION_TEMPLATE](state, Id) {
    state.automatizations = _.reject(state.automatizations, { Id });
  },
  [c.UPDATE_AUTOMATION_TEMPLATE](state, data) {
    state.automatizations = _.map(state.automatizations, (i) => {
      if (i.Id === data.Id) i = data;
      return i;
    });
  },
  [c.SET_AUTOMATION_SETTINGS_STORE](state, { ids, field, data }) {
    if (!state.automatizationsSettingsStore[field])
      state.automatizationsSettingsStore[field] = {};
    state.automatizationsSettingsStore[field][ids.join()] = data;
    state.automatizationsSettingsStore = JSON.parse(
      JSON.stringify(state.automatizationsSettingsStore)
    );
    state.automatizationsLoadingSettings = false;
  },
  [c.SET_AUTOMATION_LOADING](state, data) {
    state.automatizationsLoadingSettings = data;
  },
  [c.SET_LOCAL_STORAGE_SETTINGS](state, { data, init }) {
    if (init) {
      state.localStorageSettings = data;
    } else {
      const found = _.find(state.localStorageSettings, {
        Id: data.Id,
        Type: data.Type,
      });
      if (found) {
        const settings = { ...JSON.parse(found.settings), ...data.settings };
        state.localStorageSettings = _.map(state.localStorageSettings, (i) => {
          if (i.Id === found.Id && i.Type === found.Type) {
            i.settings = JSON.stringify(settings);
          }
          return i;
        });
      } else {
        state.localStorageSettings.push({
          ...data,
          settings: JSON.stringify(data.settings),
        });
      }
      Vue.localStorage.set(
        "localStorageSettings",
        JSON.stringify(state.localStorageSettings)
      );
    }
  },
  [c.SET_COMMON_LOCAL_STORAGE_SETTINGS](state, data) {
    const val = state.commonLocalStorageSettings
      ? JSON.parse(state.commonLocalStorageSettings)
      : {};
    const settings = JSON.stringify({ ...val, ...data });
    state.commonLocalStorageSettings = settings;
    Vue.localStorage.set("commonLocalStorageSettings", settings);
  },
  [c.DROP_CHILD_TASKS_STATUSES](state, id) {
    function recursiveFunc2(i) {
      if (i.childs && i.childs.length) {
        i.childs = _.map(i.childs, (i) => {
          i.StatusId = null;
          recursiveFunc2(i);
          return i;
        });
      }
    }
    function recursiveFunc(i) {
      if (i.Id === id) {
        recursiveFunc2(i);
      } else if (i.childs && i.childs.length) {
        i.childs = _.map(i.childs, (c) => {
          c = recursiveFunc(c);
          return c;
        });
      }
      return i;
    }

    state.tasks = _.map(state.tasks, (i) => {
      i = recursiveFunc(i);
      return i;
    });
  },
  [c.CREATE_SECTION](state, data) {
    if (data) {
      data.tasks = [];
      state.sections.push(data);
    }
  },
  [c.EDIT_SECTION](state, data) {
    state.sections = _.map(state.sections, (i) => {
      if (i.Id === data.Id) {
        i = { ...i, ...data };
      }
      return i;
    });
  },
  [c.DELETE_SECTION](state, Id) {
    state.sections = _.reject(state.sections, { Id });
  },
  [c.SET_SECTIONS_TO_NULL](state) {
    state.sections = [];
  },
  [c.GET_SECTIONS](state, data) {
    const sections = data.length
      ? _.map(data, (i) => {
          if (i.Name === "virtualSection") {
            i.tasks = _.filter(state.tasks, ["SectionId", null]);
          } else {
            i.tasks = _.filter(state.tasks, ["SectionId", i.Id]);
          }
          return i;
        })
      : [];
    // sections.push({
    //   Name: "Без секции",
    //   Id: null,
    //   tasks: _.filter(state.tasks, ["SectionId", null]),
    // });
    state.sections = sections;
  },
  [c.ADD_TASKS_TO_SECTION](state, data) {
    data = preparedTasksData(state, data);
    state.sections = _.map(state.sections, (i) => {
      if (i.Name !== "virtualSection") {
        i.tasks.push(..._.filter(data, ["SectionId", i.Id]));
      } else {
        i.tasks.push(..._.filter(data, ["SectionId", null]));
      }
      return i;
    });
  },
  [c.REFRESH_SECTIONS](state) {
    state.sections = _.map(state.sections, (i) => {
      if (i.Name !== "virtualSection") {
        i.tasks = _.filter(state.tasks, ["SectionId", i.Id]);
      } else {
        i.tasks = _.filter(state.tasks, ["SectionId", null]);
      }
      return i;
    });
  },
  [c.SET_SECTIONS](state, data) {
    state.sections = data;
  },
  [c.GET_GLOBAL_GROUPS](state, data) {
    state.globalGroups = data.map((i) => {
      i.isGlobal = true;
      i.childs = [];
      return i;
    });
  },
  [c.GET_GLOBAL_GROUP_ELEMENTS](state, data) {
    state.groups = state.groups.map((i) => {
      if (data.Id === i.GroupId) {
        i.ItemInfo = data;
      }
      return i;
    });
    state.userFilters = state.userFilters.map((i) => {
      if (data.Id === i.Id) {
        i.ItemInfo = data;
      }
      return i;
    });
    state.favorites = state.favorites.map((i) => {
      if (data.Id === i.ItemId) {
        i.ItemInfo = data;
      }
      return i;
    });
  },
  [c.CREATE_GLOBAL_GROUP](state, data) {
    state.globalGroups.push({ ...data, childs: [], onlyCreated: true });
  },
  [c.UPDATE_GLOBAL_GROUP](state, data) {
    state.globalGroups = _.map(state.globalGroups, (i) => {
      if (i.Id === data.Id) {
        i = { ...i, ...data };
      }
      return i;
    });
  },
  [c.DELETE_GLOBAL_GROUP](state, id) {
    state.groups = _.reject(state.groups, (i) => i.Id === id);
    state.userFilters = _.reject(state.userFilters, (i) => i.Id === id);
  },
  [c.ADD_ELEMENT_TO_GLOBAL_GROUP](state, childData) {
    state.globalGroups = _.map(state.globalGroups, (i) => {
      if (i.Id === childData.entityModel.GroupId) {
        i.childs.push({
          ...childData.entityModel,
          ItemInfo: childData.ItemInfo,
        });
      }
      return i;
    });
  },
  [c.DELETE_ELEMENT_FROM_GLOBAL_GROUP](state, childData) {
    state.groups = _.map(state.groups, (i) => {
      if (i.Id === childData.GroupId) {
        i.ItemInfo.childs = _.reject(i.ItemInfo.childs, ["Id", childData.Id]);
      }
      return i;
    });
    state.filters = _.map(state.filters, (i) => {
      if (i.Id === childData.GroupId) {
        i.ItemInfo.childs = _.reject(i.ItemInfo.childs, ["Id", childData.Id]);
      }
      return i;
    });
  },
  [c.GET_USER_TASKS_LIST](state, data) {
    let { groups, filters, favorites } = data;

    groups = _.map(groups, (i) => {
      i.Id = i.GroupId || i.FilterId || i.Id;
      if (i.GlobalGroupName && i.ItemInfo) {
        // if (!i.ItemInfo) i.ItemInfo = {};
        const found = state.groups.find((x) => x.GroupId === i.GroupId);
        let childs = [];
        if (found && found.ItemInfo) {
          childs = found.ItemInfo.childs;
        }
        i.ItemInfo.childs = childs;
      }
      return i;
    });
    filters = _.map(filters, (i) => {
      i.Id = i.GroupId || i.FilterId || i.Id;
      if (i.GlobalGroupName && i.ItemInfo) {
        const found = state.userFilters.find((x) => x.GroupId === i.GroupId);
        let childs = [];
        if (found && found.ItemInfo) {
          childs = found.ItemInfo.childs;
        }
        i.ItemInfo.childs = childs;
      }
      return i;
    });
    favorites = _.map(favorites, (i) => {
      i.Id = i.GroupId || i.FilterId || i.Id;
      if (i.GlobalGroupName) {
        const found = state.favorites.find((x) => x.Id === i.Id);
        let childs = [];
        if (found && found.ItemInfo) {
          childs = found.ItemInfo.childs;
        }
        i.ItemInfo.childs = childs;
      }
      return i;
    });

    state.groups = groups;
    state.userFilters = filters;
    state.favorites = favorites.filter((i) => {
      return i.ItemType !== "2";
    });

    // const notGlobalGroups = groups.filter((i) => !i.GlobalGroupName);

    // if (!state.settings.currentGroup) {
    //   const localSettings = state.commonLocalStorageSettings
    //     ? JSON.parse(state.commonLocalStorageSettings)
    //     : false;
    //   if (localSettings) {
    //     if (localSettings.queryType === "group") {
    //       state.settings.currentGroup = _.find(state.groups, [
    //         "GroupId",
    //         localSettings.queryId,
    //       ])?.ItemInfo;
    //     }
    //   } else {
    //     state.settings.currentGroup = notGlobalGroups[0]?.ItemInfo;
    //   }
    // }
    // if (updateCurrentGroup && state.settings.currentGroup) {
    //   state.settings.currentGroup = _.find(notGlobalGroups, [
    //     "GroupId",
    //     state.settings.currentGroup.Id,
    //   ])?.ItemInfo;
    // }
  },
  [c.SET_GROUPED_TASKS_BY_FILTER](state, data) {
    // state.groupedTaskByFilter.activeGroups = data.ActiveGroups;
    // state.groupedTaskByFilter.inactiveGroups = data.InactiveGroups;
    if (!data) {
      state.groupedTaskByFilter = [];
    } else {
      state.groupedTaskByFilter = [
        ...data.ActiveGroups,
        ...data.InactiveGroups.map((i) => {
          return { Tasks: [], GroupInfo: i };
        }),
      ];
    }
  },
  [c.LOAD_MORE_FILTER_TASKS_IN_GROUP](state, { tasks, groupId }) {
    state.groupedTaskByFilter = state.groupedTaskByFilter.map((i) => {
      if (i.GroupInfo.Id === groupId) {
        i.Tasks.push(...tasks);
        if (!tasks.length) {
          i.filled = true;
        }
      }
      return i;
    });
    state.settings.filterGroupTaskByGroupsLoading = false;
  },
  [c.DISABLE_FILTER_GROUP_TASKS_BY_GROUP](state) {
    state.settings.filterGroupTaskByGroups = false;
  },

  // filter header control
  [c.SET_TABLE_FILTER](state, value) {
    state.tableFilterControl.filter = value;
  },
  [c.SET_TABLE_FILTER_FLASH_DATA](state, value) {
    state.tableFilterControl.flashFilterData = value;
  },
  [c.SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK](state, value) {
    state.tableFilterControl.countForFlashFilterCheck = value;
  },
  [c.SET_TABLE_FILTER_NAME](state, value) {
    state.tableFilterControl.filterName = value;
  },
  [c.SET_TABLE_FILTER_CACHED](state, value) {
    state.tableFilterControl.cachedFilter = value;
  },
  // common module settings
  [c.SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY](state, value) {
    state.commonModuleSettings.sharedFilterInQuery = value;
  },
  [c.SET_COMMON_MODULE_PAGE](state, value) {
    state.commonModuleSettings.page = value;
  },
  [c.SET_COMMON_MODULE_SORTS](state, value) {
    state.commonModuleSettings.sorts = value;
  },
  [c.SET_COMMON_MODULE_POPUP](state, { popup, value }) {
    state.commonModuleSettings[popup] = value;
  },
  [c.SET_COMMON_MODULE_SECTION_FOR_TASK_CREATE](state, value) {
    state.commonModuleSettings.sectionForTaskCreate = value;
  },
  [c.SET_COMMON_MODULE_SUBTASK](state, value) {
    state.commonModuleSettings.subTask = value;
  },
  [c.SET_COMMON_MODULE_TASK_DETAIL_POPUPS_COUNT](state, value) {
    state.commonModuleSettings.taskDetailPopupsCount = value;
  },
  [c.SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT](state, value) {
    state.commonModuleSettings.taskCreateDetailPopupsCount = value;
  },
  [c.SET_COMMON_MODULE_SELECTED_ITEMS](state, value) {
    state.commonModuleSettings.selectedItems = value;
  },
  [c.SET_COMMON_MODULE_VIEW_TYPE](state, value) {
    state.commonModuleSettings.viewType = value;
  },
  [c.SET_COMMON_MODULE_AUTOMATION_ATTACH_ITEMS](state, value) {
    state.commonModuleSettings.automationAttachItems = value;
  },
  [c.SET_CURRENT_GROUP](state, value) {
    state.settings.currentGroup = value;
  },
  [c.LOAD_FULL_INFO_OF_GROUP](state, data) {
    state.allGroups = state.allGroups.map((i) => {
      if (i.Id === data.Id) i = { ...i, ...data };
      return i;
    });
  },
  [c.GET_CURRENT_USER_SETTINGS](state, data) {
    state.userData = data;
  },
  [c.TASKS_LOADING_PRELOADER_ENABLE](state) {
    state.commonModuleSettings.tasksLoading = true;
  },
  [c.TASKS_LOADING_PRELOADER_DISABLE](state) {
    state.commonModuleSettings.tasksLoading = false;
  },
  [c.SET_KEYDOWN_FUNCTION](state, func) {
    state.settings.keydownFunction = func;
  },
  [c.SET_SEARCH_TASKS_INPUT_VALUE](state, value) {
    state.settings.taskFilterParams["params[TaskName]"] = value;
    state.commonModuleSettings.search = value;
  },
};
