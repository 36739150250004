<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Обложки</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div class="cm-popup-form__wrapper">
        <label
          class="field field-cover"
          v-for="(cover, index) in settingsObject.json.covers"
          :key="index"
        >
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Обложка {{ cover.id }}:
          </span>
          <span class="field__content input-file">
            <input
              type="file"
              accept="image/*"
              hidden
              class="input"
              @change="filesChange($event.target.files, cover.id)"
            />
            <input
              type="text"
              class="input"
              :value="getVirtualImageName('covers', cover.img, cover.id)"
              disabled
            />
            <div class="button button_grey button-file">
              <img src="/static/images/gps.svg" />
              Выбрать файл
            </div>
          </span>

          <div
            class="table__col table__col-btn"
            v-if="index > 3"
            @click.prevent="actionCover('remove', cover.id)"
          >
            <div class="table__text-block">
              <span class="remove-icon"></span>
            </div>
          </div>
        </label>

        <div class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          ></span>
          <span class="field__content input-file">
            <span class="little-down-text" @click="actionCover('add')"
              >+ Добавить ещё</span
            >
          </span>
        </div>
      </div>
      <div class="cm-client-detail-form__btns">
        <span class="cm-client-detail-form__btns__save" @click="saveSettings"
          >Сохранить</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],

  methods: {
    filesChange(fileList, id) {
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        this.settingsObject.json.covers = this.settingsObject.json.covers.map(
          (i) => {
            if (i.id === id) {
              this.settingsObject.images.covers.push({ id, file: fileList[0] });
              i.img = fileList[0].name;
            }
            return i;
          }
        );
      }
    },
    actionCover(action, id) {
      if (action === "add") {
        const lastElementId = _.maxBy(this.settingsObject.json.covers, "id").id;

        this.settingsObject.json.covers.push({
          id: lastElementId + 1,
          img: "",
        });
      } else if (action === "remove") {
        this.settingsObject.json.covers = _.reject(
          this.settingsObject.json.covers,
          { id }
        );
        this.settingsObject.images.covers = _.reject(
          this.settingsObject.images.covers,
          { id }
        );
        this.settingsObject.json.covers = _.each(
          this.settingsObject.json.covers,
          (item, index) => {
            item.id = index + 1;
            return item;
          }
        );
      }
    },
  },
};
</script>
