<template>
  <div id="entry-wrap" class="scroll-style">
    <div class="pdf-cont">
      <div class="print">
        <div class="print__cont">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            Описание <br />
            задачи
          </div>
          <div class="print__info">
            <p style="white-space: pre-wrap" id="textTasks">
              {{ getFirstTasksText }}
            </p>
          </div>
        </div>
        <div class="print__footer">4</div>
      </div>
    </div>

    <div class="pdf-cont" v-if="pageNums.TasksAdditionalPage">
      <div class="print">
        <div class="print__cont">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            Описание <br />
            задачи
          </div>
          <div class="print__info">
            <p style="white-space: pre-wrap" id="tasksAdditionalPageText">
              {{ getLastTasksText }}
            </p>
          </div>
        </div>
        <div class="print__footer">5</div>
        <div class="pdf-non-print-text pdf-non-print-text--tasks hidden">
          <span
            >Сократите текст задачи, он слишком большой. Задача должна
            помещаться на 2 страницы КП.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
};
</script>
