var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-style",attrs:{"id":"entry-wrap"}},[_c('div',{staticClass:"pdf-cont"},[_c('div',{staticClass:"print"},[_c('div',{staticClass:"print__cont"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })},[_vm._v("\n          Описание "),_c('br'),_vm._v("\n          задачи\n        ")]),_vm._v(" "),_c('div',{staticClass:"print__info"},[_c('p',{staticStyle:{"white-space":"pre-wrap"},attrs:{"id":"textTasks"}},[_vm._v("\n            "+_vm._s(_vm.getFirstTasksText)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v("4")])])]),_vm._v(" "),(_vm.pageNums.TasksAdditionalPage)?_c('div',{staticClass:"pdf-cont"},[_c('div',{staticClass:"print"},[_c('div',{staticClass:"print__cont"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })},[_vm._v("\n          Описание "),_c('br'),_vm._v("\n          задачи\n        ")]),_vm._v(" "),_c('div',{staticClass:"print__info"},[_c('p',{staticStyle:{"white-space":"pre-wrap"},attrs:{"id":"tasksAdditionalPageText"}},[_vm._v("\n            "+_vm._s(_vm.getLastTasksText)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v("5")]),_vm._v(" "),_vm._m(0)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-non-print-text pdf-non-print-text--tasks hidden"},[_c('span',[_vm._v("Сократите текст задачи, он слишком большой. Задача должна\n          помещаться на 2 страницы КП.\n        ")])])}]

export { render, staticRenderFns }