<template>
  <div>
    <div class="actions-header">
      <div class="actions-header__col actions-header__col_flexible">
        <div class="search-bar search-bar_white actions-header__search-bar">
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Название объекта"
            class="search-bar__input"
            v-model="queryParams['params[Name]']"
            @keyup="search"
          />
        </div>
      </div>
    </div>
    <div class="task-container">
      <div class="table">
        <div class="table__header">
          <div class="table__col table__col_flexible">
            <p class="table__header-text">Название объекта</p>
          </div>
          <div class="table__col table__col_width_180">
            <p class="table__header-text">Тип объекта</p>
          </div>
          <div class="table__col table__col_width_160">
            <p class="table__header-text">Город</p>
          </div>
          <div class="table__col table__col_width_220">
            <p class="table__header-text">Адрес</p>
          </div>
          <div class="table__col table__col_width_200">
            <p class="table__header-text">Партнёр</p>
          </div>
          <div class="table__col table__col_width_160">
            <p class="table__header-text">Дата заявки</p>
            <div
              class="table__filter-button"
              :class="{
                'table-c__filter-btn--active':
                  queryParams.orderBy === 'Created DESC',
              }"
              @click="changeOrder"
            >
              {{ queryParams.orderBy === "Created DESC" ? "9-1" : "1-9" }}
            </div>
          </div>
          <div class="table__col table__col_width_120">
            <p class="table__header-text">Статус</p>
          </div>
        </div>
        <div
          class="table__row-wrap table__row-wrap--tasks"
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="0"
          infinite-scroll-immediate-check="false"
          infinite-scroll-throttle-delay="1000"
        >
          <div
            class="table__row--col"
            v-for="(item, index) in requestsObjects"
            :key="index"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_flexible">
                <div class="task-row-first">
                  <!--                  <label-->
                  <!--                    class="checkbox checkbox_white table__checkbox js-index-checkbox"-->
                  <!--                    ><input-->
                  <!--                      type="checkbox"-->
                  <!--                      class="checkbox__input main_checkbox_input"-->
                  <!--                    /><span-->
                  <!--                      class="checkbox__box main_checkbox_span table__checkbox&#45;&#45;tasks"-->
                  <!--                      ><svg class="checkbox__icon">-->
                  <!--                        <use-->
                  <!--                          xlink:href="/static/images/icon.svg#icon_icon-check"-->
                  <!--                        ></use></svg-->
                  <!--                    ></span>-->
                  <!--                    <div class="task-row-count">{{ index + 1 }}</div>-->
                  <!--                  </label>-->
                  <router-link
                    :to="'/sales/reservation/requests/' + item.Id"
                    class="table-c__name"
                    >{{ item.Name }}</router-link
                  >
                </div>
              </div>
              <div class="table__col table__col_width_180">
                <p class="table__text">{{ getObjectTypeName(item.Type) }}</p>
              </div>
              <div class="table__col table__col_width_160">
                <p class="table__text">{{ item.City }}</p>
              </div>
              <div class="table__col table__col_width_220">
                <p class="table__text">{{ item.Address }}</p>
              </div>
              <div class="table__col table__col_width_200">
                <p class="table__text">{{ item.DistributorName }}</p>
              </div>
              <div class="table__col table__col_width_160">
                <div class="kb-table-date">
                  <div class="table-c__date">
                    {{ item.Created | formatDate }}
                  </div>
                  <div class="table-c__time">{{ item.Created | getTime }}</div>
                </div>
              </div>
              <div class="table__col table__col_width_120">
                <div class="kb-label" :class="'kb-label--' + item.Status">
                  {{ getStatusName(item.Status) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="popupSlide">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { GET_REQUESTS_OBJECTS } from "../../../../../store/sales_reservation/constants";
import { mapState } from "vuex";
import { listMixin } from "../../../../../mixins/sales/reservation/listMixin";

export default {
  mixins: [listMixin],
  data() {
    return {
      getList: GET_REQUESTS_OBJECTS,
    };
  },
  computed: {
    ...mapState("reserve", ["requestsObjects"]),
  },
};
</script>

<style scoped></style>
