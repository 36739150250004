export const GET_REQUESTS_OBJECTS = "GET_REQUESTS_OBJECTS";
export const ADD_REQUESTS_OBJECTS_TO_LIST = "ADD_REQUESTS_OBJECTS_TO_LIST";
export const EDIT_REQUEST_OBJECT = "EDIT_REQUEST_OBJECT";
export const GET_RESERVED_OBJECTS = "GET_RESERVED_OBJECTS";
export const ADD_RESERVED_OBJECTS_TO_LIST = "ADD_RESERVED_OBJECTS_TO_LIST";
export const EDIT_RESERVED_OBJECT = "EDIT_RESERVED_OBJECT";
export const DELETE_RESERVED_OBJECT = "DELETE_RESERVED_OBJECT";
export const CREATE_RESERVED_OBJECT = "CREATE_RESERVED_OBJECT";
export const DELETE_REQUEST_OBJECT = "DELETE_REQUEST_OBJECT";

export const RESERVE_MODULE_RIGHT = "9337294F-5BD5-469A-8D2F-3E606D3A35A0";
