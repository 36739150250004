var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-style",attrs:{"id":"entry-wrap"}},[_vm._l((_vm.makeOptionsQuery('Exit_2', 'barrier')),function(page,index){return _c('div',{key:'page-' + index,staticClass:"pdf-cont"},[_c('div',{staticClass:"print"},[_c('div',{staticClass:"print__cont"},[_c('div',{staticClass:"flex-wrap"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})])]),_vm._v(" "),_vm._m(0,true)]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })},[_vm._v("\n          Шлагбаумы\n          "),(
              _vm.imageBlobCheck(
                'devices',
                _vm.systemTypeForPreview + '_exit_barrier',
                1
              )
            )?_c('i',{staticClass:"print__icon--entry-modules",style:({
              backgroundImage: ("url(" + (_vm.settingsObject.json.devices[_vm.systemTypeForPreview].exit_barrier.blob) + ")"),
            })}):_c('i',{staticClass:"print__icon--entry-modules",style:({
              backgroundImage: ("url(" + (_vm.getImageSrc(
                _vm.offerObject.json.Modules.Exit.img_barrier,
                _vm.offerObject.json.System
              )) + ")"),
            })})]),_vm._v(" "),_c('div',{staticClass:"print-table"},[_c('div',{staticClass:"print-table__header"},[_c('div',{staticClass:"col print-table__th"},[_vm._v("наименование")]),_vm._v(" "),_c('div',{staticClass:"print-table__th print-table__num"},[_vm._v("кол-во")]),_vm._v(" "),_c('div',{staticClass:"print-table__th print-table__price"},[_vm._v("\n              цена/ед. "+_vm._s(_vm.getCurrencyAbbreviation)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"print-table__th print-table__sum"},[_vm._v("\n              сумма, "+_vm._s(_vm.getCurrencyAbbreviation)+"\n            ")])]),_vm._v(" "),_vm._l((page),function(item,index){return [(item.is_device)?_c('div',{key:index + 'xf',staticClass:"print-table__row",class:_vm.getSubRowClass(item)},[_c('div',{staticClass:"col print-table__row-td print-table__row--device-name"},[_c('div',{staticClass:"print__checkbox print__checkbox--active"},[_c('div',{staticClass:"icon icon--checkbox",style:({
                      backgroundImage: ("url(" + (_vm.getimgStaticSrc(
                        'sales/check_icon.png'
                      )) + ")"),
                    })})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('div',{staticClass:"print-table__row-td print-table__num"},[_vm._v("\n                "+_vm._s(item.count)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"print-table__row-td print-table__price"},[_vm._v("\n                "+_vm._s(_vm._f("moneyFormat")(item.convertCost))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"print-table__row-td print-table__sum"},[_vm._v("\n                "+_vm._s(_vm.getOptionSumPrice(item))+"\n              ")])]):_c('div',{key:index,staticClass:"print-table__sub-row",class:_vm.getSubRowClass(item)},[_c('div',{staticClass:"col print-table__sub-row-td print-table__name"},[_c('div',{staticClass:"print__checkbox",class:!item.count ? '' : 'print__checkbox--active'},[_c('div',{staticClass:"icon icon--checkbox",style:({
                      backgroundImage: ("url(" + (_vm.getimgStaticSrc(
                        'sales/check_icon.png'
                      )) + ")"),
                    })})]),_vm._v(" "),_c('a',{attrs:{"href":'#id' + item.randomId}},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('div',{staticClass:"print-table__sub-row-td print-table__num"},[_vm._v("\n                "+_vm._s(item.count)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"print-table__sub-row-td print-table__price"},[_vm._v("\n                "+_vm._s(_vm._f("moneyFormat")(item.convertCost))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"print-table__sub-row-td print-table__sum"},[_vm._v("\n                "+_vm._s(_vm.getOptionSumPrice(item))+"\n              ")])])]}),_vm._v(" "),(_vm.pageNums.Exit_2LastPage === +index)?_c('div',{staticClass:"print-table__total"},[_c('div',{staticClass:"col print-table__total-td"},[_vm._v("Итого:")]),_vm._v(" "),_c('div',{staticClass:"print-table__total-td print-table__num"},[_vm._v("\n              "+_vm._s(_vm.getAmount("Exit", "count", "barrier"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"print-table__total-td print-table__price"}),_vm._v(" "),_c('div',{staticClass:"print-table__total-td print-table__sum"},[_vm._v("\n              "+_vm._s(_vm.getAmount("Exit", "cost", "barrier"))+"\n            ")])]):_vm._e()],2)]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v(_vm._s(_vm.countPage("Exit_2", +index)))])])])}),_vm._v(" "),(_vm.offerObject.json.passPages.exit_barrier)?_c('div',{staticClass:"pdf-non-print-text"},[_c('span',[_vm._v("Страница не будет добавлена в PDF")])]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto ml-auto"},[_c('div',{staticClass:"print__header-title"},[_vm._v("Оборудование"),_c('br'),_vm._v("и опции")])])}]

export { render, staticRenderFns }