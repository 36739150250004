<template>
  <div
    class="popup task-popup event-popup event-popup_active"
    id="create-task-popup"
    v-popup-inner-windows="{
      state: commonModuleSettings.taskCreateDetailPopupsCount,
      level: 0,
      forceOffset: true,
      sumLevel: 0,
    }"
  >
    <div
      class="popup__wrapper"
      @mousedown="closePopupByClickWrap($event)"
      ref="popup__wrapper"
    >
      <div class="popup__content">
        <div class="popup__header" v-if="parentTask || loadedTask">
          <div class="popup__heading_tabs">
            <div
              class="popup__heading_tabs-item"
              @click="tab = 1"
              :class="{ 'popup__heading_tabs-item--active': tab === 1 }"
            >
              Создать
            </div>
            <div
              class="popup__heading_tabs-item"
              @click="tab = 2"
              :class="{ 'popup__heading_tabs-item--active': tab === 2 }"
            >
              Привязать
            </div>
          </div>
          <div class="popup__close" @click="$emit('onClosePopup')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__header" v-else>
          <h2 class="popup__heading" v-if="section">
            Создать ({{ section.Name }})
          </h2>
          <h2 class="popup__heading" v-else>Создать</h2>

          <div class="popup__close" @click="$emit('onClosePopup')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body" v-if="tab === 1">
          <div class="cm-popup">
            <div class="task-create-form" ref="form">
              <TemplateInput
                :templateModel="templateModel"
                @settingTaskByTemplate="settingTaskByTemplate"
                v-if="templates && templates.length"
              />

              <div class="task-create-form__row head-row">
                <div class="task-create-form__row-left">
                  <svg width="18" height="18">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#check-icon"
                    ></use>
                  </svg>
                </div>
                <div class="task-create-form__row-right">
                  <SimplePreloader v-if="preloader" />
                  <div class="task-create-form__label">
                    Задачи
                    <ControlBarForSelectedTasks
                      v-if="selected.length"
                      :localUsersArray="localUsersArray"
                      :selected="selected"
                      :tasks="tasks"
                      :editedTemplateTasks="editedTemplateTasks"
                      @unselectTasks="selected = []"
                      @deleteSelectedTasks="deleteSelectedTasks"
                    />
                  </div>
                </div>
              </div>

              <draggable
                v-model="tasks"
                @end="changeOrder(tasks)"
                draggable=".task-to-create:not(.task-to-create--open)"
                class="task-to-create__wrapper"
              >
                <TaskToCreate
                  v-for="(item, index) in tasks"
                  :key="index"
                  :index="index"
                  :task="item"
                  :isSelected="selected.find((x) => x.Id === item.Id)"
                  :templateModel="templateModel"
                  :noSave="true"
                  :editedTemplateTasks="editedTemplateTasks"
                  @pushTasksToDeletedArray="deletedItems.push(...$event)"
                  @loadTaskDetail="(callback) => loadTaskDetail(item, callback)"
                  ref="task-to-create"
                  @select="selected.push(item)"
                  @unselect="
                    selected = selected.filter((x) => x.Id !== item.Id)
                  "
                  @shiftSelection="shiftSelection"
                />
              </draggable>

              <AddTaskToListButton
                :index="tasks.length"
                @click.native="addTaskToList"
              />

              <div
                class="task-create-form__button"
                @click="createTasks"
                v-if="!preloader"
              >
                <template v-if="selected.length && templateModel"
                  >Создать выбранные задачи: {{ selected.length }} шт.</template
                >
                <template v-else>Создать</template>
              </div>
              <div class="task-create-form__button-fake"></div>
            </div>
          </div>
        </div>
        <AttachTasksGroupsList
          class="task-create-form__attach-tab"
          @onSubmitHandler="setTasksDependencies"
          v-else-if="tab === 2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as _ from "lodash";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import Api from "../../../../api/tasks";
import TemplateInput from "./Inputs/TemplateInput";
import { taskCreateFormTemplate } from "./templateData";
import AttachTasksGroupsList from "@/modules/tasks/components/AttachTasks/AttachTasksGroupsList";
import {
  CREATE_MANY_ROOT_TASKS,
  CREATE_TASKS_FROM_TEMPLATE,
  GET_DEPENDENCIES_TASKS,
  GET_TEMPLATE_TASK_DETAIL,
  GET_TEMPLATE_TASKS,
  GET_TEMPLATES,
  SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT,
} from "../../../../store/tasks/constants";
import { showAlert } from "@/utils";
import TaskToCreate from "@/modules/tasks/components/TaskCreateForm/TaskToCreate";
import AddTaskToListButton from "@/modules/tasks/components/TaskCreateForm/components/AddTaskToListButton";
import ControlBarForSelectedTasks from "@/modules/tasks/components/TaskCreateForm/components/ControlBarForSelectedTasks";
import saveMixin from "@/modules/tasks/components/TaskCreateForm/mixins/saveMixin";
import { Helpers } from "@/mixins/Helpers";
import selectionMixin from "@/modules/tasks/components/TaskCreateForm/mixins/selectionMixin";

export default {
  props: [
    "parentTask",
    "requestForRelate",
    "open",
    "section",
    "selectedGroup",
    "inTaskDetail",
    "localUsersArray",
    "loadedTask",
    "groupFromParams",
  ],
  mixins: [Helpers, common, saveMixin, selectionMixin],
  data() {
    return {
      tasks: [
        {
          ...JSON.parse(JSON.stringify(taskCreateFormTemplate)),
          Id: Math.random().toString(36).substring(7),
        },
      ],
      selected: [],
      tab: 1,
      templateModel: null,
      preloader: false,
      editedTemplateTasks: [],
      deletedItems: [],
    };
  },
  created() {
    this[GET_TEMPLATES]();
  },
  computed: {
    computedGroup() {
      return this.groupFromParams || this.group;
    },
    parent() {
      return this.parentTask || this.loadedTask?.entityModel;
    },
    ...mapState("tasks", ["templates", "commonModuleSettings"]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target === this.$refs.popup__wrapper) {
        this.closePopup();
      }
    },
    closePopup() {
      this.$emit("onClosePopup");
      this[SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT](0);
    },
    findSubscribersFromText() {
      let links = [];
      _.each(
        this.$refs["description-input"].$el.querySelectorAll(".ql-editor a"),
        (i) => {
          const userId = i.getAttribute("href");
          if (userId.includes("userId=")) {
            links.push(userId.split("=")[1]);
          }
        }
      );
      return links;
    },
    resetState() {
      this.tasks = [
        {
          ...JSON.parse(
            JSON.stringify(taskCreateFormTemplate.TemplateTaskSettings)
          ),
          Id: Math.random().toString(36).substring(7),
        },
      ];
    },
    setTasksDependencies(items) {
      this.preload = true;
      Api.setTasksDependencies({
        TaskId: this.parent.Id,
        DependOnTaskIds: items.map((i) => i.Id),
        SectionId: this.section?.Id,
      })
        .then((res) => {
          if (res.data.status === "success") {
            this.getTasksDependencies();
            if (this.section && this.section.currentElement) {
              this.section.currentElement.getInnerRowInfo();
              this.section.currentElement = null;
            }
          } else {
            showAlert("error", "", res.data.message);
            this.preload = false;
          }
        })
        .catch(() => {
          this.preload = false;
        });
    },
    async getTasksDependencies() {
      await this[GET_DEPENDENCIES_TASKS](this.parent.Id)
        .then((tasks) => {
          if (this.parentTask) {
            this.parentTask.depends = tasks;
          } else this.loadedTask.depends = tasks;
        })
        .finally(() => {
          this.$emit("onClosePopup");
          this.tab = 1;
          this.preload = false;
        });
      return true;
    },
    addTaskToList() {
      this.tasks.push({
        ...JSON.parse(JSON.stringify(taskCreateFormTemplate)),
        Id: Math.random().toString(36).substring(7),
      });
      setTimeout(() => {
        const lastInput = this.$refs["task-to-create"][
          this.$refs["task-to-create"].length - 1
        ].$el.querySelector(".task-to-create__name input");
        lastInput.focus();
      });
    },
    deleteSelectedTasks() {
      if (this.tasks.length > 1) {
        this.tasks = this.tasks.filter(
          (x) => !this.selected.find((s) => s.Id === x.Id)
        );
      }
      this.deletedItems.push(
        ...this.selected.filter((i) => i.Id.length > 7).map((i) => i.Id)
      );
      this.selected = [];
    },
    settingTaskByTemplate(template) {
      this.templateModel = template;
      this.deletedItems = [];
      this.editedTemplateTasks = [];
      this.selected = [];
      this.preloader = true;
      this[GET_TEMPLATE_TASKS](this.templateModel.Id)
        .then(({ tasks }) => {
          this.tasks = [];
          this.$nextTick(() => (this.tasks = tasks));
        })
        .finally(() => (this.preloader = false));
    },
    loadTaskDetail(task, callback) {
      this[GET_TEMPLATE_TASK_DETAIL](task.Id).then(({ item, child }) => {
        this.tasks = this.tasks.map((i) => {
          if (i.Id === task.Id) {
            i = this.prepareTemplateTask(i, item, child);
          }
          return i;
        });
        callback();
      });
    },
    prepareTaskForSave(task, parentId) {
      const excludeFields = [
        "TagsIds",
        "Subscribers",
        "Watchers",
        "TaskContacts",
        "Settings",
        "Files",
      ];
      const options = {};
      _.each(task.TemplateTaskSettings, (value, key) => {
        if (!excludeFields.includes(key) && key !== "HiddenFields") {
          options[key] = value;
        }
      });
      const subtasks = [];
      if (task.subtasks && task.subtasks.length) {
        task.subtasks.forEach((i) => {
          const subtask = this.prepareTaskForSave(i, null);
          subtasks.push(subtask);
        });
      }
      const additionalParams = {};
      excludeFields.forEach((field) => {
        additionalParams[field] = task.TemplateTaskSettings[field];
      });
      return {
        ParentId: parentId,
        TaskOptions: {
          ParentId: parentId,
          GroupId: this.computedGroup.Id,
          TaskName: task.TemplateTaskName,
          ...options,
          AdditionalParams: additionalParams,
        },
        Childs: subtasks,
      };
    },
    getAddedItems() {
      const addedItems = [];
      const recursiveFindAddedItems = (item, parentId) => {
        if (item.Id.length < 7) {
          addedItems.push(this.prepareTaskForSave(item, parentId));
        } else if (item.subtasks && item.subtasks.length) {
          item.subtasks.forEach((s) => recursiveFindAddedItems(s, item.Id));
        }
      };
      if (this.tasks.length) {
        this.tasks.forEach((i) => {
          recursiveFindAddedItems(i, null);
        });
      }
      return addedItems;
    },
    createTasks() {
      this.$refs.form.scrollTop = 0;
      if (this.templateModel) {
        this.createTasksFromTemplate();
      } else {
        this.createCleanTasks();
      }
    },
    createCleanTasks() {
      this.preloader = true;
      const addedItems = this.getAddedItems();
      const payload = {
        GroupId: this.computedGroup.Id,
        SectionId: this.section?.Id,
        ParentTaskId: this.parent?.Id,
        Tasks: addedItems,
      };
      this[CREATE_MANY_ROOT_TASKS](payload)
        .then(() => {
          this.$emit("loadCreatedTasks");
          this.closePopup();
        })
        .catch(() => (this.preloader = false));
    },
    createTasksFromTemplate() {
      this.preloader = true;
      const addedItems = this.getAddedItems();
      const deletedItems = this.deletedItems;
      if (this.selected.length) {
        deletedItems.push(
          ...this.tasks
            .filter((t) => !this.selected.find((x) => x.Id === t.Id))
            .map((i) => i.Id)
        );
      }
      const payload = {
        GroupId: this.computedGroup.Id,
        SectionId: this.section?.Id,
        ParentTaskId: this.parent?.Id,
        TemplateId: this.templateModel.Id,
        NewOptions: this.editedTemplateTasks,
        DeletedItems: deletedItems,
        AddedItems: addedItems,
      };
      this[CREATE_TASKS_FROM_TEMPLATE](payload)
        .then(() => {
          this.$emit("loadCreatedTasks");
          this.closePopup();
        })
        .catch(() => (this.preloader = false));
    },
    // eslint-disable-next-line no-unused-vars
    ...mapActions("tasks", [
      GET_TEMPLATES,
      GET_DEPENDENCIES_TASKS,
      GET_TEMPLATE_TASKS,
      GET_TEMPLATE_TASK_DETAIL,
      CREATE_TASKS_FROM_TEMPLATE,
      CREATE_MANY_ROOT_TASKS,
    ]),
    ...mapMutations("tasks", [
      SET_COMMON_MODULE_TASK_CREATE_DETAIL_POPUPS_COUNT,
    ]),
  },
  components: {
    TemplateInput,
    AttachTasksGroupsList,
    TaskToCreate,
    AddTaskToListButton,
    ControlBarForSelectedTasks,
    draggable,
  },
};
</script>

<style>
.task-popup.event-popup_active .popup__content,
.task-create-form__button {
  width: 960px;
}
.head-row {
  padding: 0;
  align-items: center;
}
.head-row .task-create-form__row-left {
  padding-top: 0;
}
.head-row .task-create-form__label {
  margin: 0;
}
</style>
