<template>
  <div class="table__row--col">
    <div
      class="table__row automation-table-row"
      :class="{
        'task-table-row--open': open,
        'task-table-row--complete': automationComplete,
      }"
    >
      <div class="table__col table__col_width_30" style="border-right: none">
        <div class="task-row-count" v-if="typeof index === 'number'">
          {{ index + 1 }}
        </div>
      </div>
      <div class="table__col table__col_flexible">
        <div class="automation-table-row__info">
          <div v-if="actionType">
            <AutomationBuilder
              class="automation-item__name"
              :form="item"
              :hideFirstPart="true"
              :noSeparateWord1="true"
              :noSeparateWord2="true"
              :parentData="parentData"
              :selectedTaskParentData="selectedTaskParentData"
              :saveOnBlur="true"
              :edit="!automationComplete"
              @save="save"
            />
            <AutomationBuilder
              class="automation-item__name"
              :form="item"
              :hideLastPart="true"
              :noSeparateWord2="true"
              :parentData="parentData"
              :saveOnBlur="true"
              :selectedTaskParentData="selectedTaskParentData"
              :edit="!automationComplete"
              @save="save"
            />
          </div>
          <div class="automation-table-row__info-inner" v-else>
            <AutomationBuilder
              class="automation-item__name"
              :form="eventItem"
              :hide-last-part="true"
              :hideTaskName="true"
              :parentData="parentData"
              :selectedTaskParentData="selectedTaskParentData"
              :saveOnBlur="true"
              :edit="true"
              @save="save"
            />
            , то...
          </div>
          <div class="automation-template__col automation-template__col--btns">
            <button
              class="automation-template__icon-btn"
              v-if="automationComplete"
            >
              <svg width="20" height="20" stroke="#00CC66">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#check-icon"
                ></use>
              </svg>
            </button>
            <button
              class="automation-template__delete-btn"
              @click="deleteAutomation(item)"
              v-else-if="actionType"
            >
              <svg width="18" height="18">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#delete-icon"
                ></use>
              </svg>
            </button>
            <div
              class="icon-button icon-button_shadow table__toggle"
              @click="open = !open"
              v-if="!actionType"
            >
              <div class="icon-button__container">
                <svg class="icon-button__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-caret"
                  ></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!actionType">
      <div class="automation-table-row__inner" v-if="open">
        <template v-for="(arr, hash) in item">
          <div :key="hash">
            <div
              class="automation-action-row"
              v-for="(actionItem, actionItemHash) in getActionItemsArrayByHash(
                hash
              )"
              :key="actionItemHash"
            >
              <div class="automation-action-row__count">
                {{ actionItemHash + 1 }}
                <!--                {{ Object.keys(item).indexOf(actionItemHash) + 1 }}-->
              </div>
              <div class="automation-table-row__info">
                <div class="automation-action-row__name">
                  <!--          {{getActionItemByHash(hash)}}-->
                  <div class="automation-table-row__info-inner">
                    <AutomationBuilder
                      class="automation-item__name"
                      :form="actionItem"
                      :hide-first-part="true"
                      :noSeparateWord2="true"
                      :parentData="parentData"
                      :saveOnBlur="true"
                      :selectedTaskParentData="selectedTaskParentData"
                      :edit="true"
                      @save="save"
                    />
                    <!--              <div class="automation-builder-task-input">-->
                    <!--                для-->
                    <!--                <span class="automation-select-text automation-builder-task-input__name"-->
                    <!--                      @click="selectedTaskParentData.subtaskIdForOpen = form.EventTaskId"-->
                    <!--                >{{getActionItemsArrayByHash(hash).length}} {{declOfNum(getActionItemsArrayByHash(hash).length, ['задача', 'задачи',-->
                    <!--            'задач'])}}</span>-->
                    <!--              </div>-->
                    <div class="automation-builder-task-input">
                      <AutomationInput
                        :items="[
                          {
                            ...actionItem,
                            Id: actionItem.ActionTaskId,
                            Name: actionItem.TaskActionName,
                          },
                        ]"
                        :placeholder="'задачи ' + actionItem.TaskActionName"
                        :preplaceholder="'для'"
                        @choose="
                          (id) =>
                            (selectedTaskParentData.subtaskIdForOpen =
                              actionItem.ActionTaskId)
                        "
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="automation-template__icon-btn"
                  v-if="actionItem.DateFinish && actionItem.Status === '2'"
                >
                  <svg width="20" height="20" stroke="#00CC66">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#check-icon"
                    ></use>
                  </svg>
                </button>
                <button
                  class="automation-template__delete-btn"
                  v-else
                  @click="deleteAutomation(actionItem)"
                >
                  <svg width="18" height="18">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#delete-icon"
                    ></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <!--      <div class="automation-action-row automation-action-row--add">-->
          <!--        <div class="automation-action-row__count">+</div>-->
          <!--        <div class="automation-action-row__name">Добавить действие</div>-->
          <!--      </div>-->
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapActions } from "vuex";
import { Helpers } from "../../../../mixins/Helpers";
import AutomationInput from "./components/AutomationInput";
import AutomationBuilder from "./AutomationBuilder";
import {
  DELETE_TASK_AUTOMATION,
  UPDATE_TASK_AUTOMATION,
} from "../../../../store/tasks/constants";

export default {
  mixins: [Helpers],
  name: "AutomationItem",
  props: [
    "item",
    "index",
    "parentData",
    "actionType",
    "selectedTaskParentData",
  ],
  data() {
    return {
      open: true,
    };
  },
  computed: {
    automationComplete() {
      return (
        this.actionType && this.item.DateFinish && this.item.Status === "2"
      );
    },
    eventItem() {
      return this.item[Object.keys(this.item)[0]][0];
    },
  },
  methods: {
    save(form) {
      this[UPDATE_TASK_AUTOMATION](form);
    },
    deleteAutomation(item) {
      this.parentData.preloader = true;
      this[DELETE_TASK_AUTOMATION](item.Id)
        .then(() => {
          this.$emit("deleteAutomation", item.Id);
        })
        .finally(() => {
          this.parentData.preloader = false;
        });
    },
    getActionItemByHash(hash) {
      return this.item[hash][0];
    },
    getActionItemsArrayByHash(hash) {
      const items = JSON.parse(JSON.stringify(this.item[hash]));
      return _.map(items, (i) => {
        i.Id = i.ActionTaskId;
        i.Name = i.TaskActionName;
        return i;
      });
    },
    ...mapActions("tasks", [UPDATE_TASK_AUTOMATION, DELETE_TASK_AUTOMATION]),
  },
  components: {
    AutomationInput,
    AutomationBuilder,
  },
};
</script>

<style scoped></style>
