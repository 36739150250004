<template>
  <div class="popup__inner-cont">
    <div
      class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
      @click="openModal()"
    >
      <Print_10 />
    </div>
    <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
      <print_10 />
    </magnific-popup-modal>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('Total')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div
          class="new-changes-block"
          :key="index"
          v-for="(item, index) in getOptionsChangesArray('Total')"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <span style="display: none">{{
              (old = findOldOption("Total", item.randomId))
            }}</span>
            <p>
              {{ old.name
              }}<span v-if="old.cost">, {{ old.cost | moneyFormat }}$</span>
            </p>
            <br />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>
            {{ item.name
            }}<span v-if="item.cost">, {{ item.cost | moneyFormat }}</span>
          </p>
          <br />
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Total', 'removed')"
          :key="'1' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>
              {{ item.name
              }}<span v-if="item.cost">, {{ item.cost | moneyFormat }}</span>
            </p>
            <br />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>Удалено</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('Total', 'added')"
          :key="'k' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>-</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>
            {{ item.name
            }}<span v-if="item.cost">, {{ item.cost | moneyFormat }}</span>
          </p>
          <br />
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('Total'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__cont--total pb-0 scroll-style"
        style="height: calc(100% - 168px)"
      >
        <div class="p-settings">
          <div class="p-settings__item">
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="col-auto">
                <div class="p-settings__name">Оборудование</div>
                <div class="p-settings__inner">
                  <span
                    class="badge badge--warning"
                    v-if="offerObject.json.totalDevicesPriceDiscont"
                    >Скидка {{ offerObject.json.totalDevicesPriceDiscont
                    }}{{
                      offerObject.json.totalDevicesPriceType === "rub"
                        ? "р."
                        : "%"
                    }}</span
                  >
                </div>
              </div>
              <div class="col-auto ml-auto">
                <div class="p-settings__text">
                  {{ totalDevicesPrice | moneyFormat }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Скидка</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="offerObject.json.totalDevicesPriceDiscont"
                      @keyup="validateAllDevicePriceDiscont($event)"
                    />
                  </div>
                  <div class="p-settings__discount">
                    <span
                      class="p-settings__discount-item p-settings__discount--percent"
                      :class="
                        offerObject.json.totalDevicesPriceType === void 0
                          ? 'p-settings__discount-item--active'
                          : offerObject.json.totalDevicesPriceType === 'percent'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="
                        offerObject.json.totalDevicesPriceType = 'percent'
                      "
                      >%</span
                    >
                    <span
                      class="p-settings__discount-item p-settings__discount--rub"
                      :class="
                        offerObject.json.totalDevicesPriceType === 'rub'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="offerObject.json.totalDevicesPriceType = 'rub'"
                      >{{ getCurrencyAbbreviation }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="p-settings__btn-group">
                <button
                  class="p-settings__btn p-settings__btn--red"
                  @click="cancelSetOptions(item)"
                >
                  Отмена
                </button>
                <button
                  class="p-settings__btn p-settings__btn--green"
                  @click="closeOption($event.target)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
          <div
            class="p-settings__item p-settings__item--total"
            :class="setClass(item)"
            v-for="(item, index) in offerObject.json.Total.Options.types"
            :key="index"
          >
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="p-settings__check">
                <label
                  class="checkbox checkbox_white table__checkbox js-index-checkbox"
                >
                  <input
                    class="checkbox__input main_checkbox_input"
                    type="checkbox"
                    name="row_checkbox_clients"
                    @click.prevent="disableOption(item)"
                    :class="
                      item.disabled === void 0
                        ? 'checkbox__box--active'
                        : item.disabled
                        ? ''
                        : 'checkbox__box--active'
                    "
                  />
                  <span class="checkbox__box main_checkbox_span">
                    <svg class="checkbox__icon">
                      <use
                        xlink:href="/static/images/icon.svg#icon_icon-check"
                      ></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">{{ item.name }}</div>
                <div class="p-settings__inner">
                  <span class="badge badge--warning" v-if="item.discont_number"
                    >Скидка {{ item.discont_number
                    }}{{ item.discont_type === "percent" ? "%" : "р." }}</span
                  >
                </div>
              </div>
              <div class="col-auto ml-auto">
                <div class="p-settings__text">
                  {{ item.convertCost | moneyFormat }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Название</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="text"
                      v-model="item.name"
                      :placeholder="item.name"
                    />
                  </div>
                </div>
              </div>

              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Цена</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.single_cost"
                      @keyup="setSingleCost(item)"
                      :placeholder="
                        item.single_cost === void 0 || !item.single_cost
                          ? item.discont_type !== void 0 && item.discont_type
                            ? item.initial_cost
                            : item.convertCost
                          : ''
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Скидка</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.discont_number"
                      @keyup="validateDiscont($event, item)"
                    />
                  </div>
                  <div class="p-settings__discount">
                    <span
                      class="p-settings__discount-item p-settings__discount--percent"
                      :class="
                        item.discont_type === void 0
                          ? 'p-settings__discount-item--active'
                          : item.discont_type === 'percent'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="changeDiscontType(item, 'percent')"
                      >%</span
                    >
                    <span
                      class="p-settings__discount-item p-settings__discount--rub"
                      :class="
                        item.discont_type === 'rub'
                          ? 'p-settings__discount-item--active'
                          : ''
                      "
                      @click="changeDiscontType(item, 'rub')"
                      >{{ getCurrencyAbbreviation }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="p-settings__btn-group">
                <button
                  class="p-settings__btn p-settings__btn--red"
                  v-if="item.custom"
                  @click="actionOption('remove', item.id)"
                >
                  Удалить
                </button>
                <button
                  class="p-settings__btn p-settings__btn--red"
                  v-else
                  @click="cancelSetOptions(item)"
                >
                  Отмена
                </button>
                <button
                  class="p-settings__btn p-settings__btn--green"
                  @click="closeOption($event.target)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>

          <div
            class="p-settings__item p-settings__item--add"
            @click="actionOption('add')"
          >
            <div class="p-settings__cont">
              <div class="p-settings__pluse">+</div>
              <div class="p-settings__name">Добавить еще</div>
            </div>
          </div>

          <div class="p-settings__title">Условия</div>
          <div class="p-settings__item p-settings__item--cond">
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">Доставка</div>
              </div>
              <div class="col-auto ml-auto p-settings__name-wrap">
                <div class="p-settings__text">
                  {{ offerObject.json.Total.Delivery }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="select-d__dropdown-list">
                    <div
                      class="select-d__dropdown-item"
                      v-for="(item, index) in settingsObjectStandart.total
                        .delivery"
                      :key="index"
                      @click="offerObject.json.Total.Delivery = item.name"
                      :class="
                        offerObject.json.Total.Delivery === item.name
                          ? 'select-d__dropdown-item--active'
                          : ''
                      "
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-settings__row">
                <div class="col">
                  <input
                    type="text"
                    class="form-row__input form-row__input--payment"
                    placeholder="Проивольное поле"
                    @keyup="changeDelivery($event.target)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-settings__item p-settings__item--cond">
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">Срок автоматизации</div>
              </div>
              <div class="col-auto ml-auto p-settings__name-wrap">
                <div class="p-settings__text">
                  {{ offerObject.json.Total.Date }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <input
                    type="text"
                    class="form-row__input"
                    v-model="offerObject.json.Total.Date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-settings__item p-settings__item--cond">
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">Гарантия</div>
              </div>
              <div class="col-auto ml-auto p-settings__name-wrap">
                <div class="p-settings__text">
                  {{ offerObject.json.Total.Guarantee }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <input
                    type="text"
                    class="form-row__input"
                    v-model="offerObject.json.Total.Guarantee"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-settings__item p-settings__item--cond">
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">Условия оплаты</div>
              </div>
              <div class="col-auto ml-auto p-settings__name-wrap">
                <div class="p-settings__text">
                  {{ offerObject.json.Total.Payment }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="select-d__dropdown-list">
                    <div
                      class="select-d__dropdown-item"
                      v-for="(item, index) in settingsObjectStandart.total
                        .payment"
                      :key="index"
                      @click="offerObject.json.Total.Payment = item.name"
                      :class="
                        offerObject.json.Total.Payment === item.name
                          ? 'select-d__dropdown-item--active'
                          : ''
                      "
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-settings__row">
                <div class="col">
                  <input
                    type="text"
                    class="form-row__input form-row__input--payment"
                    placeholder="Проивольное поле"
                    @keyup="changePayment($event.target)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <div class="p-settings__total">
          <div class="p-settings__check">
            <label
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                name="row_checkbox_clients"
                @click.prevent="setShowCurrencyTextState"
                :class="showCurrency ? 'checkbox__box--active' : ''"
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </label>
          </div>
          <div class="col-auto">Примечание с курсом доллара</div>
        </div>
        <div class="p-settings__total">
          <div class="col-auto">
            Общая стоимость
            {{ getCurrencyAbbreviation === "$" ? "долларов" : "рублей" }}, с НДС
          </div>
          <div class="col-auto ml-auto">
            {{ totalSumPrice | moneyFormat }} {{ getCurrencyAbbreviation }}
          </div>
        </div>
        <div class="pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(9)">
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(11)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_10 from "../prints/print_10";
import { copy, randomId } from "../../../../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  computed: {
    showCurrency() {
      return this.offerObject.json.showCurrency === void 0
        ? true
        : this.offerObject.json.showCurrency;
    },
    ...mapState("sales", [
      "offerObject",
      "settingsObject",
      "settingsObjectStandart",
    ]),
    ...mapGetters("sales", ["getCurrencyAbbreviation"]),
  },
  methods: {
    disableOption(item) {
      item.disabled = item.disabled === void 0 ? true : !item.disabled;
      this.offerObject.json.Total.Options.types = _.map(
        this.offerObject.json.Total.Options.types,
        (i) => i
      );
    },
    setClass(item) {
      let className = item.disabled ? "p-settings__item--disabled" : "";
      className += item.custom ? " p-settings__item--custom" : "";
      return className;
    },

    actionOption(action, id) {
      if (action === "add") {
        this.offerObject.json.Total.Options.types.push({
          id: this.offerObject.json.Total.Options.types.length + 1,
          name: "Новая услуга ",
          cost: 0,
          convertCost: "",
          custom: true,
          randomId: randomId(),
        });
      } else if (action === "remove") {
        this.offerObject.json.Total.Options.types = _.reject(
          this.offerObject.json.Total.Options.types,
          { id }
        );
        this.offerObject.json.Total.Options.types = _.each(
          this.offerObject.json.Total.Options.types,
          (item, index) => {
            item.id = index + 1;
            return item;
          }
        );
      }
    },
    closeOption(el) {
      el.closest(".p-settings__item")
        .querySelector(".p-settings__drop")
        .classList.remove("p-settings__drop--open");
    },
    openOption(el) {
      const drop = el
        .closest(".p-settings__item")
        .querySelector(".p-settings__drop");
      document.querySelectorAll(".p-settings__drop").forEach((i) => {
        if (i !== drop) {
          i.classList.remove("p-settings__drop--open");
        }
      });
      if (
        el.classList[0] === "p-settings__cont" ||
        el.closest(".p-settings__name-wrap")
      ) {
        drop.classList.toggle("p-settings__drop--open");
        if (
          drop.querySelector(".form-row__input:not(.form-row__input--payment)")
        ) {
          drop
            .querySelector(".form-row__input:not(.form-row__input--payment)")
            .focus();
        }
      }
    },
    changePayment(el) {
      this.offerObject.json.Total.Payment = el.value;
    },
    changeDelivery(el) {
      this.offerObject.json.Total.Delivery = el.value;
    },
    setShowCurrencyTextState() {
      if (this.offerObject.json.showCurrency === void 0) {
        this.offerObject.json.showCurrency = false;
        this.offerObject.json = JSON.parse(
          JSON.stringify(this.offerObject.json)
        );
      } else {
        this.offerObject.json.showCurrency = !this.offerObject.json
          .showCurrency;
      }
    },
  },
  created() {
    if (!this.offerObject.json.Total.Options) {
      this.offerObject.json.Total.Options = copy(
        this.settingsObjectStandart.total
      );
      this.offerObject.json.Total.Delivery = copy(
        this.settingsObjectStandart.total.delivery[0].name
      );
      this.offerObject.json.Total.Payment = copy(
        this.settingsObjectStandart.total.payment[0].name
      );
    }
  },
  components: { Print_10 },
};
</script>
