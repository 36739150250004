<template>
  <li
    class="kanban-item"
    :data-item-id="task.Id"
    @mouseover="parentData.canDrag = false"
    @mouseleave="parentData.canDrag = true"
  >
    <div
      class="kanban-item__notification"
      aria-hidden="true"
      v-if="notification"
    >
      {{ notification }}
    </div>
    <div class="kanban-item__row">
      <person class="kanban-item__author" :item="task" />
      <p
        class="kanban-item__date"
        :class="{ 'kanban-item__date--red': dateExpired }"
      >
        {{ task.FinishDate }}
      </p>
    </div>
    <div class="kanban-item__row kanban-item__name-wrap" @click="openTask">
      <p class="kanban-item__name">{{ task.TaskName }}</p>
    </div>
    <div class="kanban-item__row">
      <div
        class="kanban-item__labels"
        ref="portal-wrapper-tags"
        @scroll="closeAll"
      >
        <template v-if="task.tags && task.tags.length">
          <KanbanItemTag v-for="tag in task.tags" :key="tag.Id" :tag="tag" />
        </template>
        <template v-else>
          <div
            class="kanban-item__labels-add task-create-form-subtasks__person--add"
            v-if="checkUserTaskRight('change_tags')"
            @click="addTagBubbleOpen = true"
          >
            +
          </div>
          <portal to="common">
            <transition name="fade">
              <TagsListSearch
                v-if="addTagBubbleOpen"
                :className="'task-bubble--tag'"
                :taskTags="task.tags || []"
                :task="item"
                @choose="addTag"
                @close="addTagBubbleOpen = false"
                v-portal="{ parent: $refs['portal-wrapper-tags'] }"
              />
            </transition>
          </portal>
        </template>
      </div>

      <div
        class="kanban-item__task-deep-info task-deep-info"
        v-if="task.childs && task.childs.length"
      >
        <i class="deep-icon"></i>{{ task.childs.length }}
      </div>
      <div class="task-deep-info task-deep-info--hidden" v-else>
        <i class="deep-icon"></i>
      </div>
    </div>
  </li>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import KanbanItemTag from "./KanbanItemTag";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Person from "../Tables/TableComponents/Person";
import TagsListSearch from "../CommonComponents/TagsListSearch/TagsListSearch";
import { ADD_TAG_TO_TASK } from "../../../../store/tasks/constants";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";

export default {
  name: "KanbanItem",
  mixins: [common, Helpers],
  props: ["task", "parentData", "selectedTaskParentData"],
  data() {
    return {
      addTagBubbleOpen: false,
    };
  },
  computed: {
    notification() {
      if (this.task.Notifications) {
        return (
          this.task.Notifications.CountUnreadNotifications +
          this.task.Notifications.CountNewNotifications
        );
      }
      return false;
    },
    dateExpired() {
      const finishDate = moment(this.task.FinishDate);
      const toDay = moment();
      return toDay > finishDate;
    },
    ...mapState("tasks", ["selectedTask", "tags"]),
  },
  methods: {
    closeAll() {
      this.$children.forEach((c) => {
        c.open = false;
      });
    },
    openTask() {
      let id = this.task.Id;
      if (this.selectedTaskParentData) {
        this.selectedTaskParentData.subtaskIdForOpen = id;
        window.history.pushState({}, document.title, "/tasks/" + id);
      } else {
        this.$router.push("/tasks/" + id);
      }
    },
    addTag(tagId) {
      const foundTag = _.find(this.tags, ["Id", tagId]);
      const tag = { ...foundTag, TagName: foundTag.TagName };

      this.task.tags.push(tag);
      this[ADD_TAG_TO_TASK]({
        data: {
          TagId: tagId,
          TaskId: this.task.Id,
        },
      });
    },
    ...mapActions("tasks", [ADD_TAG_TO_TASK]),
  },
  components: {
    KanbanItemTag,
    Person,
    TagsListSearch,
  },
};
</script>

<style scoped></style>
