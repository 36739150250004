export default {
  methods: {
    drawStatusColor(status) {
      let colorNum = 3;
      if (status === "1") colorNum = 3; // новая===ожидает
      if (status === "2") colorNum = 1; // в работе
      if (+status === 4) colorNum = 2; // ожидает вашего ответа
      if (status === "5") colorNum = 3; // закрыт
      return "sup-color-" + colorNum;
    },
    drawRpsStatusColor(status) {
      let colorNum = 3;
      if (status === "1") colorNum = 2; // новая===ожидает
      if (status === "2") colorNum = 1; // в работе
      if (+status === 4) colorNum = 3; // ожидает вашего ответа
      if (status === "5") colorNum = 3; // закрыт
      return "sup-color-" + colorNum;
    },
  },
};
