<template>
  <div class="automation-statuses" ref="portal-wrapper-statuses">
    <div
      class="automation-statuses__name-wrap"
      @click="!disabled && (open = !open)"
    >
      <div class="automation-select-text--no-selectable" v-if="preplaceholder">
        {{ preplaceholder }}
      </div>

      <div
        class="automation-select-text"
        :class="{
          'automation-select-text--have-value': value,
          'automation-select-text--disabled': disabled,
        }"
      >
        {{ name }}
      </div>
    </div>
    <portal to="common">
      <transition name="fade">
        <GroupedListSearch
          v-if="portalOpen"
          @choose="choose"
          @close="open = false"
          :selectedItemsArray="selected ? [selected.Id] : []"
          :items="processes"
          :fieldNames="{
            searchInputName: 'Поиск статуса',
            idFieldName: 'Id',
            parentFieldName: 'Name',
            childListFieldName: 'statuses',
            childFieldName: 'Name',
            colorFieldName: 'Color',
          }"
          v-portal="{ parent: $refs['portal-wrapper-statuses'] }"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapState } from "vuex";
import { Helpers } from "../../../../../mixins/Helpers";
import { capitalize } from "../../../../../utils";
import GroupedListSearch from "@/modules/tasks/components/CommonComponents/GroupedListSearch/GroupedListSearch";

export default {
  name: "AutomationProcessInput",
  props: ["value", "placeholder", "preplaceholder", "disabled"],
  mixins: [Helpers],
  data() {
    return {
      open: false,
      portalOpen: false,
    };
  },
  computed: {
    name() {
      if (this.selected) {
        return capitalize(this.selected.Name.toLowerCase());
      } else {
        const val = this.placeholder ? this.placeholder : "выбрать";
        return capitalize(val.toLowerCase());
      }
    },
    selected() {
      let result = null;
      this.processes.forEach((i) => {
        const found = _.find(i.statuses, ["Id", this.value]);
        if (found) {
          return (result = found);
        }
      });
      return result;
    },
    ...mapState("tasks", ["processes"]),
  },
  methods: {
    choose({ Id }) {
      this.$emit("choose", Id);
      this.open = false;
    },
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.portalOpen = true;
        });
      } else {
        this.portalOpen = false;
      }
    },
  },
  components: {
    GroupedListSearch,
  },
};
</script>

<style scoped></style>
