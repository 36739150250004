<template>
  <div class="kanban" v-dragscroll.x="parentData.canDrag">
    <BacklogColumn
      :tasks="backlogTasks"
      :process="process"
      :parentData="parentData"
      :selectedTaskParentData="selectedTaskParentData"
    />
    <KanbanStatusColumn
      v-for="status in process.statuses"
      :key="status.Id"
      :status="status"
      :process="process"
      :tasks="getStatusesTasks(status.Id)"
      :parentData="parentData"
      :selectedTaskParentData="selectedTaskParentData"
    />
    <FinishedColumn
      :tasks="finishedTasks"
      :process="process"
      :parentData="parentData"
      :selectedTaskParentData="selectedTaskParentData"
    />
    <div class="kanban__col-fake"></div>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";
import BacklogColumn from "./BacklogColumn";
import KanbanStatusColumn from "./KanbanStatusColumn";
import FinishedColumn from "./FinishedColumn";
import { dragscroll } from "vue-dragscroll";

export default {
  name: "index",
  props: ["process", "tasks", "createTaskFunction", "selectedTaskParentData"],
  mixins: [common, Helpers],
  data() {
    return {
      parentData: {
        canDrag: true,
        createTask: () => {},
      },
    };
  },
  created() {
    this.parentData.createTask = this.createTaskFunction;
  },
  computed: {
    backlogTasks() {
      return _.filter(this.tasks, (i) => !i.StatusId && i.Status !== "2");
    },
    finishedTasks() {
      return _.filter(this.tasks, (i) => i.Status === "2");
    },
  },
  methods: {
    getStatusesTasks(statusId) {
      return _.filter(
        this.tasks,
        (i) => i.StatusId === statusId && i.Status !== "2"
      );
    },
  },
  components: {
    BacklogColumn,
    KanbanStatusColumn,
    FinishedColumn,
  },
  directives: {
    dragscroll: dragscroll,
  },
};
</script>

<style scoped></style>
