<template>
  <div class="popup task-popup event-popup event-popup_active">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">
            Автоматизировать {{ tasks.length }}
            {{ declOfNum(tasks.length, ["задачу", "задачи", "задач"]) }}
          </h2>
          <div class="popup__close" @click="onClose">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <SimplePreloader v-if="preloader" />
            <div class="cm-popup__right">
              <div class="automation-attach">
                <div
                  class="automation-attach__step"
                  :class="{ 'automation-attach__step--active': step === 1 }"
                  @click="step = 1"
                >
                  <div class="automation-attach__head">
                    <div class="automation-attach__name">
                      Шаг 1. Выбрать задачу для события
                    </div>
                    <div class="automation-attach__icon" v-if="selectedTaskId">
                      <svg width="20" height="20">
                        <use
                          xlink:href="/static/images/tasks/sprite.svg#check-icon"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="automation-attach__inner" v-if="step === 1">
                    <div
                      class="automation-attach__row"
                      v-for="(item, index) in tasks"
                      :key="index"
                    >
                      <div
                        class="automation-attach__row-name automation-attach__row-link"
                        @click="selectedTaskId = item.Id"
                      >
                        {{ item.TaskName }}
                      </div>
                      <div
                        class="automation-attach__row__label-button label-button"
                        :class="{
                          'label-button--disabled': selectedTaskId !== item.Id,
                        }"
                        @click="selectedTaskId = item.Id"
                      >
                        <template v-if="selectedTaskId === item.Id"
                          >Выбрана
                        </template>
                        <template v-else>Выбрать</template>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="automation-attach__step"
                  :class="{ 'automation-attach__step--active': step === 2 }"
                  @click="selectedTaskId && (step = 2)"
                >
                  <div class="automation-attach__head">
                    <div class="automation-attach__name">
                      Шаг 2. Выбор автоматизации
                    </div>
                    <div
                      class="automation-attach__icon"
                      v-if="Object.keys(selectedAutomation).length"
                    >
                      <svg width="20" height="20">
                        <use
                          xlink:href="/static/images/tasks/sprite.svg#check-icon"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="automation-attach__inner" v-if="step === 2">
                    <div class="automation-attach__search task-popup-header">
                      <div
                        class="actions-header__col actions-header__col_flexible"
                      >
                        <div
                          class="search-bar search-bar_white actions-header__search-bar"
                        >
                          <button class="search-bar__button">
                            <svg>
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="/static/images/icon.svg#icon_icon-search"
                              ></use>
                            </svg>
                          </button>
                          <input
                            type="search"
                            placeholder="Свойство, событие, условие, действие, объект действия, параметр действия"
                            v-model="search"
                            class="search-bar__input"
                          />
                        </div>
                      </div>
                    </div>
                    <AutomationAttachControlItem
                      v-for="(item, index) in copiedAutomatizations"
                      :key="index"
                      :item="item"
                      @save="(form) => updateAutomation(form, index)"
                      :selectedAutomation="selectedAutomation"
                      @selectAutomation="selectAutomation"
                    />
                  </div>
                </div>
                <div
                  class="automation-attach__step automation-attach__step--finish"
                  :class="{ 'automation-attach__step--active': step === 3 }"
                  @click="
                    selectedTaskId &&
                      Object.keys(selectedAutomation).length &&
                      (step = 3)
                  "
                >
                  <div class="automation-attach__head">
                    <div class="automation-attach__name">Шаг 3. Завершение</div>
                    <div class="automation-attach__icon" v-if="step === 3">
                      <svg width="20" height="20">
                        <use
                          xlink:href="/static/images/tasks/sprite.svg#check-icon"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="automation-attach__inner" v-if="step === 3">
                    <div
                      class="automation-attach__row automation-attach__row--finish"
                    >
                      <div class="automation-attach__row-title">Событие</div>
                      <AutomationBuilder
                        class="automation-attach__row-name"
                        :form="selectedAutomation"
                        @save="editSelectedAutomation"
                        :hideLastPart="true"
                        :saveOnBlur="true"
                        :attach="true"
                        :edit="true"
                        :parentData="{}"
                      />
                    </div>
                    <div class="automation-attach__row-wrapper">
                      <div
                        class="automation-attach__row automation-attach__row--finish"
                      >
                        <div class="automation-attach__row-title">Действие</div>
                        <AutomationBuilder
                          class="automation-attach__row-name"
                          :form="selectedAutomation"
                          @save="(form) => (selectedAutomation = form)"
                          :hideFirstPart="true"
                          :saveOnBlur="true"
                          :attach="true"
                          :edit="true"
                          :parentData="{}"
                        />
                      </div>
                      <div class="automation-attach__repeat-btn">
                        <label class="icon-button task-checkbox-btn"
                          ><span
                            class="checkbox checkbox_white table__checkbox js-index-checkbox"
                            ><input
                              type="checkbox"
                              v-model="repeatAutomatization"
                              v-bind:true-value="1"
                              v-bind:false-value="0"
                              class="checkbox__input main_checkbox_input" />
                            <span class="checkbox__box main_checkbox_span"
                              ><svg class="checkbox__icon">
                                <use
                                  xlink:href="/static/images/icon.svg#icon_icon-check"
                                ></use></svg></span
                          ></span>
                          Повторять
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="automation-attach__btn task-create-form__button"
                  v-if="step === 1"
                  @click="selectedTaskId && (step = 2)"
                >
                  Следующий шаг
                </div>
                <div
                  class="automation-attach__btn task-create-form__button"
                  v-if="step === 2"
                  @click="
                    selectedTaskId &&
                      Object.keys(selectedAutomation).length &&
                      (step = 3)
                  "
                >
                  Настройте выбранные автоматизации
                </div>
                <div
                  class="automation-attach__btn task-create-form__button"
                  v-if="step === 3 && !preloader"
                  @click="attach"
                >
                  Завершить
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers } from "../../../../../mixins/Helpers";
import AutomationAttachControlItem from "./AutomationAttachControlItem";
import { mapActions, mapState } from "vuex";
import AutomationBuilder from "../AutomationBuilder";
import {
  ATTACH_AUTOMATION_TO_TASK,
  GET_AUTOMATION_ACTIONS,
  GET_AUTOMATION_ACTIONS_OBJECTS,
  GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS,
  GET_AUTOMATION_ACTIONS_OBJECTS_TYPES,
  GET_AUTOMATION_ACTIONS_OPTIONS,
  GET_AUTOMATION_OBJECTS_TYPES,
  GET_AUTOMATION_TEMPLATES,
} from "../../../../../store/tasks/constants";
import * as _ from "lodash";

export default {
  name: "AutomationAttachControl",
  mixins: [Helpers],
  props: ["tasks"],
  data() {
    return {
      search: "",
      step: 1,
      selectedTaskId: null,
      selectedAutomation: {},
      copiedAutomatizations: [],
      repeatAutomatization: 0,
      preloader: false,
    };
  },
  created() {
    this[GET_AUTOMATION_OBJECTS_TYPES]();
    this[GET_AUTOMATION_ACTIONS]();
    this[GET_AUTOMATION_ACTIONS_OPTIONS]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS_TYPES]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS]();

    const setAutomatizations = () => {
      this.copiedAutomatizations = JSON.parse(
        JSON.stringify(this.automatizations)
      );
    };
    if (this.automatizations.length) {
      setAutomatizations();
    } else {
      this[GET_AUTOMATION_TEMPLATES]().then(() => {
        setAutomatizations();
      });
    }
  },
  computed: {
    ...mapState("tasks", ["automatizations"]),
  },
  methods: {
    selectAutomation(item) {
      this.selectedAutomation = item;
    },
    onClose() {
      this.$emit("onClosePopup");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onClose();
      }
    },
    attach() {
      _.each(this.tasks, (task) => {
        if (task.Id !== this.selectedTaskId || this.tasks.length === 1) {
          this.preloader = true;
          this[ATTACH_AUTOMATION_TO_TASK]({
            EventTaskId: this.selectedTaskId,
            ActionTaskId: task.Id,
            ...this.selectedAutomation,
            TaskActionObjectOptionParams: this.selectedAutomation
              .ActionObjectOptionParams,
            TaskActionOptionParams: this.selectedAutomation.ActionOptionParams,
            RepeatAutomatization: this.repeatAutomatization,
          })
            .then(() => {
              this.onClose();
            })
            .finally(() => (this.preloader = false));
        }
      });
    },
    updateAutomation(form, index) {
      this.copiedAutomatizations[index] = form;
      this.copiedAutomatizations = JSON.parse(
        JSON.stringify(this.copiedAutomatizations)
      );
      this.selectedAutomation = this.copiedAutomatizations[index];
    },
    editSelectedAutomation(form) {
      this.selectedAutomation = JSON.parse(JSON.stringify(form));
    },
    ...mapActions("tasks", [
      ATTACH_AUTOMATION_TO_TASK,
      GET_AUTOMATION_TEMPLATES,
      GET_AUTOMATION_OBJECTS_TYPES,
      GET_AUTOMATION_ACTIONS,
      GET_AUTOMATION_ACTIONS_OPTIONS,
      GET_AUTOMATION_ACTIONS_OBJECTS,
      GET_AUTOMATION_ACTIONS_OBJECTS_TYPES,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS,
    ]),
  },
  components: {
    AutomationAttachControlItem,
    AutomationBuilder,
  },
};
</script>

<style scoped>
.automation-attach__row-wrapper {
  display: flex;
}

.automation-attach__row-wrapper .automation-attach__row {
  flex: 1;
}

.automation-attach__repeat-btn {
  margin-left: auto;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  padding-right: 16px;
}
</style>
