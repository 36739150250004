export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const TOGGLE_PRELOADER = "TOGGLE_PRELOADER";
export const TOGGLE_POPUP = "TOGGLE_POPUP";
export const TOGGLE_POPUP_LOCAL = "TOGGLE_POPUP_LOCAL";
export const TOGGLE_POPUP_IMAGE = "TOGGLE_POPUP_IMAGE";
export const SET_LOGIN = "SET_LOGIN";
export const SET_CLIENT_AUTH = "SET_CLIENT_AUTH";
export const GET_USER = "GET_USER";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_GETTING_NOTIFICATIONS_STATUS =
  "SET_GETTING_NOTIFICATIONS_STATUS";
export const GET_USER_DATA = "GET_USER_DATA";
export const CHANGE_USER_FORM = "CHANGE_USER_FORM";
export const GET_USERS_BY_RIGHT = "GET_USERS_BY_RIGHT";
export const CHANGE_USER_FORM_FIELD = "CHANGE_USER_FORM_FIELD";
export const CHECK_GUEST = "CHECK_GUEST";

// rights
export const GET_SETTINGS_RIGHT = "72B22856-4DA3-4DA3-9FA8-8700CF4049C8";
export const GET_SUPPORT_RIGHT = "EA08881E-150A-4BA0-B329-0F3142EA4807";
export const GET_SUPERLOG_RIGHT = "23EF71F2-E804-41DA-8B18-D66F927410D4";
export const GET_UPDATE_RIGHT = "0B0E2997-D6F3-4956-96DB-A990BBFAE56D";
export const GET_OBJECTS_RIGHT = "7B19B8AA-9600-4544-AF5C-A73D73D4AE2F";
export const GET_SITE_RIGHT = "d92be135-f255-46ef-8c2b-a067da7359c3";
