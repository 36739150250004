<template>
  <div id="entry-wrap" class="scroll-style">
    <div
      class="pdf-cont"
      v-for="(page, index) in makeOptionsQuery('Payment', 'cash_1')"
      :key="'page-' + index"
    >
      <div class="print">
        <div class="print__cont">
          <div class="flex-wrap">
            <div class="col-auto">
              <div class="print__logo">
                <img :src="getimgStaticSrc('sales/print-logo.svg')" />
              </div>
            </div>
            <div class="col-auto ml-auto">
              <div class="print__header-title">Оборудование<br />и опции</div>
            </div>
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            Устройства <br />
            оплаты
            <i
              v-if="
                imageBlobCheck('devices', systemTypeForPreview + '_payment', 1)
              "
              :style="{
                backgroundImage: `url(${settingsObject.json.devices[systemTypeForPreview].payment.blob})`,
              }"
              class="print__icon--entry-modules"
            ></i>
            <i
              v-else
              :style="{
                backgroundImage: `url(${getImageSrc(
                  offerObject.json.Modules.Payment.img,
                  offerObject.json.System
                )})`,
              }"
              class="print__icon--entry-modules"
            ></i>
          </div>
          <div class="print-table">
            <div class="print-table__header">
              <div class="col print-table__th">наименование</div>
              <div class="print-table__th print-table__num">кол-во</div>
              <div class="print-table__th print-table__price">
                цена/ед. {{ getCurrencyAbbreviation }}
              </div>
              <div class="print-table__th print-table__sum">
                сумма, {{ getCurrencyAbbreviation }}.
              </div>
            </div>

            <template v-for="(item, index) in page">
              <div
                class="print-table__row"
                v-if="item.id === 1"
                :key="index + 'xf'"
                :class="getSubRowClass(item)"
                style="display: flex !important"
              >
                <div
                  class="col print-table__row-td print-table__row--device-name"
                >
                  <div class="print__checkbox print__checkbox--active">
                    <div
                      class="icon icon--checkbox"
                      :style="{
                        backgroundImage: `url(${getimgStaticSrc(
                          'sales/check_icon.png'
                        )})`,
                      }"
                    ></div>
                  </div>
                  <span>{{ item.name }}</span>
                </div>
                <div class="print-table__row-td print-table__num">
                  {{ item.count }}
                </div>
                <div class="print-table__row-td print-table__price">
                  {{ item.convertCost | moneyFormat }}
                </div>
                <div class="print-table__row-td print-table__sum">
                  {{ getOptionSumPrice(item) }}
                </div>
              </div>
              <div
                class="print-table__sub-row"
                v-else
                :class="getSubRowClass(item)"
                :key="index"
              >
                <div class="col print-table__sub-row-td print-table__name">
                  <div
                    class="print__checkbox"
                    :class="!item.count ? '' : 'print__checkbox--active'"
                  >
                    <div
                      class="icon icon--checkbox"
                      :style="{
                        backgroundImage: `url(${getimgStaticSrc(
                          'sales/check_icon.png'
                        )})`,
                      }"
                    ></div>
                  </div>
                  <a :href="'#id' + item.randomId">{{ item.name }}</a>
                </div>
                <div class="print-table__sub-row-td print-table__num">
                  {{ item.count }}
                </div>
                <div class="print-table__sub-row-td print-table__price">
                  {{ item.convertCost | moneyFormat }}
                </div>
                <div class="print-table__sub-row-td print-table__sum">
                  {{ getOptionSumPrice(item) }}
                </div>
              </div>
            </template>

            <div
              class="print-table__total"
              v-if="pageNums.PaymentLastPage === +index"
            >
              <div class="col print-table__total-td">Итого:</div>
              <div class="print-table__total-td print-table__num">
                {{ getAmount("Payment", "count") }}
              </div>
              <div class="print-table__total-td print-table__price"></div>
              <div class="print-table__total-td print-table__sum">
                {{ getAmount("Payment", "cost") }}
              </div>
            </div>
          </div>
        </div>
        <div class="print__footer">{{ countPage("Payment", +index) }}</div>
      </div>
    </div>

    <div
      class="pdf-non-print-text"
      v-if="offerObject.json.passPages.entry_rack"
    >
      <span>Страница не будет добавлена в PDF</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  data() {
    return {
      col_1_is_full: false,
      col_2_is_full: false,
    };
  },
  created() {
    window.offerModuleTitles.Payment = 0;
    window.offerModuleTitles.Payment3 = 0;
    this.offerObject.openedPages.Payment = 1;
  },
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
    ...mapGetters("sales", ["getCurrencyAbbreviation"]),
  },
};
</script>
