<template>
  <div id="entry-wrap" class="scroll-style" ref="html">
    <div
      class="print print--1"
      :style="{
        backgroundImage: `url(${getImageSrc(
          settingsObject.json.covers[0].img
        )})`,
      }"
    >
      <div class="print__small-logo">
        <img :src="getimgStaticSrc('sales/print-logo-small.svg')" />
      </div>
      <div class="print__welcome-title">
        Выполненные работы <br />
        по автоматизации парковочного пространства
      </div>
    </div>
    <div
      class="pdf-cont"
      v-for="(page, index) in pageLength"
      :key="index + 'page'"
    >
      <div class="print print--19">
        <div class="print__cont pb-0">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            примеры <br />объектов
          </div>
        </div>
        <div class="print__object">
          <div
            class="print__object-item"
            v-for="(item, index) in objects.filter((item, index) =>
              limitObject(item, index, page)
            )"
            :key="index"
          >
            <div class="print__object-img">
              <img :src="commercialSiteUrl + item.ImageLink" />
            </div>
            <div class="print__object-cont">
              <div class="print__object-title">{{ item.Name }}</div>
              <div class="print__object-address">
                {{ item.CityName }}. {{ item.Address }}
              </div>
              <div class="print__object-list">
                <div class="print__object-l-item">
                  <img
                    class="icon icon--badge"
                    :src="getimgStaticSrc('sales/arrow-2.svg')"
                  />
                  {{ item.WorksTypesText }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="print__footer">{{ index + 1 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as c from "../../../../store/sales/constants";
import { showAlert } from "../../../../utils";
import { API_ROOT } from "../../../../config";
import moment from "moment";
import axios from "axios";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  mounted() {
    this.downloadPdf();
  },
  methods: {
    downloadPdf() {
      this[c.TOGGLE_PRELOADER]({ type: "main", status: true });
      setTimeout(() => {
        this.print.rendering = true;
        const html = `<!DOCTYPE html>
  <html>
  <head>
    <title>RPS — Найстройка / Устройства / Устройство</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1">
    <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&amp;display=swap&amp;subset=cyrillic-ext"
          rel="stylesheet">
    <link
      href="https://fonts.googleapis.com/css?family=Exo+2:100,200,300,400,500,600,700,800&amp;display=swap&amp;subset=cyrillic"
      rel="stylesheet">
    <link rel="stylesheet" href="${API_ROOT}/static/styles/print-pdf.css">
  </head>
  <body>
${this.$refs.html.innerHTML}
  </body>
  </html>`;
        // console.log(html)

        axios({
          url: `${API_ROOT}api/sales/ComOffers/RenderPdf`,
          method: "POST",
          responseType: "blob", // important
          data: {
            html,
          },
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Портфолио RPS.pdf");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            this[c.TOGGLE_PRELOADER]({ type: "main", status: false });
            this.print.rendering = false;
            this.$emit("close");
          });
      }, 500);
    },
    limitObject(item, index, page) {
      return index + 1 > (page - 1) * 3 && index + 1 <= (page - 1) * 3 + 3;
    },
  },
  computed: {
    pageLength() {
      const objectsLength = this.objects.length;
      // console.log(objectsLength)
      return objectsLength <= 3 ? 1 : Math.ceil(this.objects.length / 3);
    },
    objects() {
      // return this.examplesObjects
      return _.orderBy(
        this.examplesObjects,
        (i) => {
          return moment(i.ObjectDate).format("YYYYMMDD");
        },
        ["desc"]
      );
    },
    ...mapState("sales", [
      "offerObject",
      "commercialSiteUrl",
      "examplesObjects",
      "print",
      "settingsObject",
    ]),
  },
};
</script>
