<template>
  <div
    class="popup task-popup event-popup profile-popup"
    :class="{
      'event-popup_active': isOpen,
    }"
  >
    <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Профиль и настройки</h2>
          <div class="popup__close" @click="closePopup()">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                @click="tab = 1"
                :class="{ 'cm-popup-nav__item--active': tab === 1 }"
              >
                Ваш профиль
              </div>
              <div
                class="cm-popup-nav__item"
                @click="tab = 2"
                :class="{ 'cm-popup-nav__item--active': tab === 2 }"
              >
                Уведомления<span class="num"></span>
              </div>
              <div
                class="cm-popup-nav__item"
                @click="tab = 3"
                :class="{ 'cm-popup-nav__item--active': tab === 3 }"
              >
                Пароль
              </div>
              <div class="cm-popup__down-btn">
                <div
                  class="button button_grey button_uppercase"
                  @click="logout"
                >
                  Выйти
                </div>
              </div>
            </div>
            <ProfileSettings v-if="tab === 1" />
            <ProfileNotificationSettings v-if="tab === 2" />
            <ProfilePasswordSettings v-if="tab === 3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import popup from "../../../../mixins/tasks/popup";
import common from "../../../../mixins/tasks/common";
import ProfileSettings from "./ProfileSettings";
import ProfilePasswordSettings from "./ProfilePasswordSettings";
import ProfileNotificationSettings from "@/modules/tasks/components/ProfileSettingsPopup/ProfileNotificationSettings";

export default {
  mixins: [popup, common],
  data() {
    return {
      tab: 1,
    };
  },
  methods: {
    logout() {
      setTimeout(() => {
        document.cookie.split(";").forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });
        window.location.href = "/";
      }, 1000);
    },
  },
  components: {
    ProfileSettings,
    ProfilePasswordSettings,
    ProfileNotificationSettings,
  },
};
</script>

<style scoped></style>
