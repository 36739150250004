<template>
  <div class="print__object-item">
    <div class="print__object-img">
      <img :src="commercialSiteUrl + item.ImageLink" />
      <a
        class="print__object-video"
        @click.prevent="openVideo = true"
        v-if="item.VideoLink"
        :href="'https://www.youtube.com/watch?v=' + link"
      >
        <img :src="getimgStaticSrc('redbutton.png')" />
      </a>
      <portal to="modal">
        <div
          class="print__object-video-modal"
          v-if="!print.rendering && openVideo"
          @click="closeModal($event)"
        >
          <div class="print__object-video-modal__block">
            <iframe
              width="560"
              height="315"
              :src="'https://www.youtube.com/embed/' + link"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </portal>
    </div>
    <div class="print__object-cont">
      <div class="print__object-title">{{ item.Name }}</div>
      <div class="print__object-address">
        {{ item.CityName }}. {{ item.Address }}
      </div>
      <div class="print__object-list">
        <div class="print__object-l-item">
          <img
            class="icon icon--badge"
            :src="getimgStaticSrc('sales/arrow-2.svg')"
          />
          {{ item.WorksTypesText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import { Helpers } from "../../../../../../mixins/Helpers";

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export default {
  props: ["item", "commercialSiteUrl"],
  mixins: [SalesHelpers, Helpers],
  data() {
    return {
      openVideo: false,
    };
  },
  computed: {
    link() {
      if (this.item.VideoLink) {
        return youtube_parser(this.item.VideoLink);
      }
      return "";
    },
  },
  methods: {
    closeModal(e) {
      if (!e.target.closest(".print__object-video-modal__block")) {
        this.openVideo = false;
      }
    },
  },
};
</script>

<style scoped></style>
