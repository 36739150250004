import moment from "moment";
import { taskCreateFormTemplate } from "@/modules/tasks/components/TaskCreateForm/templateData";
import { mapActions } from "vuex";
import { CHANGE_ORDER, UPLOAD_TMP_FILES } from "@/store/tasks/constants";

export default {
  methods: {
    prepareTask(item, subtasks = []) {
      return Array.isArray(item.TemplateTaskSettings)
        ? {
            ...item,
            TemplateTaskSettings: JSON.parse(
              JSON.stringify(taskCreateFormTemplate.TemplateTaskSettings)
            ),
            subtasks: item.subtasks || subtasks,
          }
        : { ...item, subtasks: item.subtasks || subtasks };
    },
    prepareTemplateTask(currentTaskSettings, newTaskSettings, subtasks) {
      const updatedTaskSettings = this.editedTemplateTasks.find(
        (x) => x.TaskId === currentTaskSettings.Id
      );
      if (updatedTaskSettings) {
        const taskName =
          updatedTaskSettings.Options.TemplateTaskName ||
          newTaskSettings.TemplateTaskName;
        let templateTaskSettings = {
          ...newTaskSettings.TemplateTaskSettings,
          ...updatedTaskSettings.Options,
        };
        if (Object.keys(updatedTaskSettings.Options).includes("Settings")) {
          templateTaskSettings = {
            ...newTaskSettings.TemplateTaskSettings,
            ...updatedTaskSettings.Options,
            Settings: {
              ...newTaskSettings.TemplateTaskSettings.Settings,
              ...updatedTaskSettings.Options.Settings,
            },
          };
        }
        return {
          ...newTaskSettings,
          TemplateTaskName: taskName,
          TemplateTaskSettings: templateTaskSettings,
          subtasks: currentTaskSettings.subtasks || subtasks,
        };
      } else {
        return {
          ...newTaskSettings,
          subtasks: currentTaskSettings.subtasks || subtasks,
        };
      }
    },
    saveAfterEdit(formData, callback, field) {
      if (this.templateModel && !this.noSave) {
        this.$emit("editTemplateTask", this.task, formData, callback);
      }
      if (this.editedTemplateTasks) {
        if (formData && formData instanceof FormData) {
          this[UPLOAD_TMP_FILES](formData).then((files) => {
            callback(files, true, () => this.setNewOptions(field)); // tmp
          });
        }
        this.setNewOptions(field);
      }
    },
    setNewOptions(field, taskFromParam) {
      const task = this.task || taskFromParam;
      if (field && task.Id.length > 7) {
        const found = this.editedTemplateTasks.find(
          (x) => x.TaskId === task.Id
        );
        let fieldValue = task.TemplateTaskSettings[field];
        if (field === "TemplateTaskName") fieldValue = task[field];
        let options = {
          [field]: fieldValue,
        };
        if (field === "FinishDate") {
          options = {
            FinishDate: task.TemplateTaskSettings.FinishDate
              ? moment(task.TemplateTaskSettings.FinishDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : this.form.FinishDate,
            FinishTime: task.TemplateTaskSettings.FinishTime,
          };
        }

        if (!found) {
          const taskOptions = {
            TaskId: task.Id,
            Options: options,
          };
          this.editedTemplateTasks.push(taskOptions);
        } else {
          found.Options = {
            ...found.Options,
            ...options,
          };
        }
      }
    },
    setMultipleTasksNewOptions(tasks, options) {
      tasks.forEach((task) => {
        if (task.Id.length > 7) {
          const found = this.editedTemplateTasks.find(
            (x) => x.TaskId === task.Id
          );

          if (options.FinishDate) {
            options.FinishDate = options.FinishDate
              ? moment(options.FinishDate).format("YYYY-MM-DD HH:mm:ss")
              : options.FinishDate;
          }

          if (!found) {
            const taskOptions = {
              TaskId: task.Id,
              Options: options,
            };
            this.editedTemplateTasks.push(taskOptions);
          } else {
            found.Options = {
              ...found.Options,
              ...options,
            };
          }
        }
      });
    },
    changeOrder(items) {
      if (this.editedTemplateTasks) {
        let index = 1;
        items = items.map((i) => {
          i.TemplateTaskSettings.Position = index;
          index++;
          return i;
        });
        items.forEach((i) => this.setNewOptions("Position", i));
      } else {
        this[CHANGE_ORDER]({
          items,
          apiMethod: "api/tasks/v2/tasksGroupsTemplatesItems/updateOrder",
        });
      }
    },
    ...mapActions("tasks", [UPLOAD_TMP_FILES, CHANGE_ORDER]),
  },
};
