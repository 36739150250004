<template>
  <div v-click-outside="close" class="popup-component" ref="popper">
    <resize-observer @notify="handleResize" />
    <slot></slot>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import Popper from "popper.js";
import { Helpers } from "@/mixins/Helpers";

export default {
  mixins: [common, Helpers],
  props: ["clickCoordinates", "arrowElement", "offset", "placement"],
  data() {
    return {};
  },
  computed: {
    isVisible() {
      return this.opened;
    },
  },
  mounted() {
    this.open();
  },
  methods: {
    open() {
      this.opened = true;
      console.log(this.clickCoordinates, this.$refs.popper);
      if (this.popper) {
        this.popper.destroy();
      }
      this.popper = new Popper(
        this.referenceObject(this.clickCoordinates),
        this.$refs.popper,
        {
          placement: this.placement || "right-start",
          modifiers: {
            arrow: {
              element: this.arrowElement
                ? document.querySelector(`.${this.arrowElement}`)
                : null,
            },
            offset: {
              offset: this.offset || "",
            },
          },
        }
      );

      this.popper;

      // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate();
      });
    },
    close() {
      this.opened = false;
      this.$emit("close");
    },
    referenceObject(evt) {
      const left = evt.clientX;
      const top = evt.clientY;
      const right = left + 1;
      const bottom = top + 1;
      const clientWidth = 1;
      const clientHeight = 1;

      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom,
        };
      }

      const obj = {
        getBoundingClientRect,
        clientWidth,
        clientHeight,
      };
      return obj;
    },
    handleResize() {
      this.popper.scheduleUpdate();
    },
  },

  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Popper,
  },
};
</script>

<style scoped>
.popup-component {
  position: fixed !important;
  z-index: 999;
}
</style>
