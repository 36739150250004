<template>
  <div
    class="popup task-popup event-popup event-popup_active task-popup-parent popup task-popup-detail"
  >
    <div class="popup__wrapper" @click="$emit('close')">
      <div class="popup__content popup__content-wide" @click.stop>
        <div class="popup__header">
          <h2 class="popup__heading">Тэги</h2>
          <div class="popup__close" @click="$emit('close')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="request-tags__list">
          <span
            class="task-label"
            v-for="item in requestTags"
            :key="item.Id"
            :style="`background-color: #${item.Color}; font-size: 12px`"
            >{{ item.TagName }}</span
          >
          <input
            class="request-tags__list-search"
            v-model="search"
            type="text"
            placeholder="Введите название тега"
          />
          <span class="tags-settings__buttons tags-settings__buttons-right">
            <div
              class="sup-btn sup-color-3"
              style="padding: 0 20px"
              @click="handleRemoveAll"
            >
              Очистить
            </div>
          </span>
        </div>
        <div class="table-vertical">
          <div
            v-for="item in commonSettings.tags"
            :key="item.Id"
            :class="
              search.length < 1 || searchList.includes(item.Id)
                ? 'table-vertical__col'
                : ''
            "
          >
            <div v-if="search.length < 1 || searchList.includes(item.Id)">
              <div class="table-vertical__cell">
                <span
                  class="task-label"
                  :style="`background-color: #${item.Color}`"
                  >{{ item.TagName }}
                </span>
                <span
                  class="tags-settings__buttons tags-settings__buttons-right _checkbox"
                >
                  <span
                    v-if="countChildrenSelected(item) > 0"
                    class="tags-settings__counter"
                    :style="`background: #${item.Color}`"
                  >
                    {{ countChildrenSelected(item) }}
                  </span>
                  <input
                    type="checkbox"
                    :id="item.Id"
                    name="request-tags"
                    :value="item.Id"
                    :checked="requestTags.find((i) => i.Id === item.Id)"
                    @click.prevent
                    @click="onSelectedChange(item, $event)"
                  />
                </span>
              </div>
              <div v-for="child in item.child" :key="child.id">
                <rps-tags-selector-item
                  v-if="search.length < 1 || searchList.includes(child.Id)"
                  :item="child"
                  :color="`#${item.Color}`"
                  :request-tags="requestTags"
                  :depth="1"
                  :search="search"
                  :search-list="searchList"
                  @createTagRelation="createTagRelation"
                  @deleteTagRelation="deleteTagRelation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RpsTagsSelectorItem from "./RpsTagsSelectorItem";
import Api from "../../../../../api/support/rps";
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  name: "RpsTagsSelector",
  components: { RpsTagsSelectorItem },
  props: ["requestTags", "requestId"],
  data() {
    return {
      search: "",
      searchList: [],
    };
  },
  watch: {
    search(newVal) {
      if (newVal.length > 0) {
        this.searchList = [...new Set(this.getCheckList())];
      } else this.searchList = [];
    },
  },
  computed: {
    ...mapState("rpsSupport", ["preloaders", "commonSettings"]),
    filteredTags() {
      if (this.search.length < 1) return this.commonSettings.tags;
      else {
        return [];
      }
    },
  },
  methods: {
    getCheckList() {
      const tags = this.commonSettings.tags;
      const self = this;
      let checkList = [];
      _.each(tags, (tag) => {
        if (tag.TagName.toLowerCase().includes(this.search.toLowerCase()))
          checkList.push(tag.Id);
        updateCheckList(tag, this.search.toLowerCase());
      });

      return checkList;

      function updateCheckList(item, searchName) {
        if (item.child && item.child.length) {
          return _.each(item.child, (child) => {
            updateCheckList(child, searchName);
            if (child.TagName.toLowerCase().includes(searchName)) {
              pushWithParents(child);
              return true;
            }
          });
        } else return false;
      }

      function pushWithParents(tag) {
        checkList.push(tag.Id);
        if (tag.ParentTagId) pushWithParents(self.getTagById(tag.ParentTagId));
      }
    },
    getTagById(id) {
      const self = this;
      return findTag(self.commonSettings.tags);

      function findTag(array) {
        let parentTag = undefined;
        _.each(array, (i) => {
          if (i.Id === id) {
            parentTag = i;
            return false;
          } else if (i.child && i.child.length) {
            let tag = findTag(i.child);
            if (tag) {
              parentTag = tag;
              return false;
            }
          }
        });

        return parentTag;
      }
    },
    countChildrenSelected(item) {
      let result = 0;
      const requestTags = this.requestTags;
      checkChildren(item.child);

      function checkChildren(array) {
        _.each(array, (child) => {
          if (requestTags.find((i) => i.Id === child.Id)) result++;
          if (child.child && child.child.length) checkChildren(child.child);
        });
      }

      return result;
    },
    onSelectedChange(tag, event) {
      tag = {
        ...tag,
        TagId: tag.Id,
        relationId: this.requestTags.find((i) => i.TagId === tag.Id)
          ?.relationId,
      };
      if (event.target.checked) {
        this.createTagRelation(tag);
      } else {
        this.deleteTagRelation(tag);
      }
    },
    createTagRelation(tag) {
      this.search = "";
      const self = this;
      if (!this.requestTags.find((i) => i.TagId === tag.Id)) {
        this.preloaders.request = true;
        this.$emit("pushTag", tag);
        if (tag.ParentTagId) {
          const parentFound = self.getTagById(tag.ParentTagId);
          const parent = {
            ...parentFound,
            TagId: parentFound.Id,
            relationId: self.requestTags.find((i) => i.TagId === parentFound.Id)
              ?.relationId,
          };
          this.createTagRelation(parent);
        }
        // Api;
        Api.createTagRelation({
          RequestId: this.requestId,
          TagId: tag.Id,
        })
          .then((res) => {
            if (res.status === 200) {
              if (this.requestTags.length === res.data.tags.length) {
                this.$emit("updateTags", res.data.tags);
              } else {
                this.$emit("addRequestIdToTag", res.data);
              }
            }
          })
          .catch(() => {
            this.$emit("removeTag", tag.Id);
          })
          .finally(() => {
            this.preloaders.request = false;
          });
      }
    },
    isSelected(tag) {
      if (this.requestTags) {
        return this.requestTags.find((i) => i.Id === tag.Id);
      } else return false;
    },
    deleteTagRelation(tag) {
      this.$emit("removeTag", tag.TagId);
      _.each(tag.child, (child) => {
        if (this.isSelected(child)) {
          const childTag = {
            ...child,
            TagId: child.Id,
            relationId: this.requestTags.find((i) => i.TagId === child.Id)
              ?.relationId,
          };
          this.deleteTagRelation(childTag);
        }
      });
      Api.deleteTagRelation(tag.relationId).catch(() => {
        this.$emit("pushTag", tag);
      });
    },
    handleRemoveAll() {
      _.each(this.requestTags, (i) => {
        this.deleteTagRelation(i);
      });
    },
  },
};
</script>

<style scoped></style>
