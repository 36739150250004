<template>
  <div class="cm-popup__right">
    <div class="cm-popup-search">
      <a
        href="#"
        class="button button_green button_uppercase settings-devices-zone__add"
        @click="createContact"
        >Добавить</a
      >
      <form
        class="search-bar search-bar_white actions-header__search-bar"
        method="post"
        action="#"
      >
        <button class="search-bar__button" type="submit">
          <svg>
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-search"
            ></use>
          </svg>
        </button>
        <input
          class="search-bar__input"
          type="search"
          placeholder="Введите ФИО"
          v-model="searchInput"
        />
      </form>
    </div>

    <div class="table cm-table-2">
      <div class="table__header">
        <div class="table__col table__col_width_360">
          <p class="table__header-text">Фамилия Имя Отчество</p>
          <div
            class="table__filter-button"
            @click="order = order === 'asc' ? 'desc' : 'asc'"
            style="margin-left: 10px"
          >
            {{ order === "asc" ? "А-Я" : "Я-А" }}
          </div>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">E-mail</p>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">Телефон</p>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">Не звонить?</p>
        </div>
      </div>
      <div class="table__row-wrap" v-if="contacts.length">
        <div
          class="tableInnerRow"
          v-for="(item, index) in localContacts"
          :key="index"
          :ref="'row' + index"
        >
          <div class="table__row table__row_main">
            <div
              class="table__col table__col_width_360"
              @click="openForm(index)"
            >
              <div class="cm-table-col-1">
                <div class="cm-table-col-1__arrow">
                  <svg class="select__caret">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#icon_icon-caret"
                    ></use>
                  </svg>
                </div>
                <div class="cm-table-col-1__text">
                  <span class="cm-table-col-1__name">{{ item.Name }}</span>
                  <span class="cm-table-col-1__detail">
                    <p>{{ item.Position }}</p>
                    <br />
                    <span v-if="item.MainContact && item.MainContact == 1"
                      >Основной контакт</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <div class="table__col table__col_width_160">
              <div class="table__text-block">
                <p class="table__text">{{ item.Email }}</p>
              </div>
            </div>
            <div class="table__col table__col_width_160">
              <div class="table__text-block">
                <p class="table__text">{{ item.Phone }}</p>
              </div>
            </div>
            <div class="table__col table__col_wid th_160">
              <a
                href="#"
                class="button cm-table-col-1__btn"
                :class="item.DND ? 'button_red' : 'button_green'"
                >{{ item.DND ? "Нe Звонить" : "Звонить" }}</a
              >
            </div>
          </div>
          <div class="cm-client-detail-form">
            <div class="cm-popup-form__wrapper">
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Наименование:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.Name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Должность:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.Position" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Телефон:
                </span>
                <span class="field__content">
                  <input type="tel" class="input" v-model="item.Phone" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  E-mail:
                </span>
                <span class="field__content">
                  <input type="email" class="input" v-model="item.Email" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Описание:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="item.Description" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Очередь:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    style="width: 90px"
                    v-model="item.SortOrder"
                  />
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Основной контакт :
                </span>
                <span class="field__content">
                  <div
                    class="checkbox checkbox_white table__checkbox js-index-checkbox"
                  >
                    <input
                      type="checkbox"
                      v-model="item.MainContact"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      name="row_checkbox_clients"
                      class="checkbox__input main_checkbox_input"
                      @click="changeMainContact(item)"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </div>
                </span>
              </label>

              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Не звонить:
                </span>
                <span class="field__content">
                  <div
                    class="checkbox checkbox_white table__checkbox js-index-checkbox"
                  >
                    <input
                      type="checkbox"
                      name="row_checkbox_clients"
                      v-model="item.DND"
                      class="checkbox__input main_checkbox_input"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </div>
                </span>
              </label>
            </div>
            <div class="cm-client-detail-form__btns">
              <span @click="deleteContact(item)">Удалить</span>
              <span @click="updateContact(item)">Сохранить</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import * as c from "@/store/clients/constants";
import * as _ from "lodash";

export default {
  data() {
    return {
      searchInput: "",
      order: "asc",
    };
  },
  computed: {
    ...mapGetters("clients", ["contacts"]),
    ...mapState("clients", ["selectedObject"]),

    localContacts() {
      return _.orderBy(
        this.contacts.filter((i) => {
          const re = new RegExp(this.searchInput, "gi");
          return re.test(i.Name);
        }),
        ["Name"],
        [this.order]
      );
    },
  },
  methods: {
    ...mapActions("clients", [
      c.UPDATE_CONTACT,
      c.CREATE_CONTACT,
      c.DELETE_CONTACT,
    ]),
    changeMainContact(contact) {
      _.each(this.selectedObject.contacts, (i) => {
        if (i.Id !== contact.Id) {
          i.MainContact = 0;
        } else {
          i.MainContact = 1;
        }
        this.updateContact(i, false);
      });
    },
    openForm(index) {
      const item = this.$refs["row" + index][0];
      document.querySelectorAll(".tableInnerRow").forEach((i) => {
        if (item !== i) i.classList.remove("tableInnerRow--open");
      });
      item.classList.toggle("tableInnerRow--open");
    },
    updateContact(payload, preloader = true) {
      this[c.UPDATE_CONTACT]({ payload, preloader });
    },
    deleteContact(payload) {
      this[c.DELETE_CONTACT](payload.Id);
    },
    createContact() {
      this[c.CREATE_CONTACT]();
    },
  },
};
</script>
