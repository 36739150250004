<template>
  <div class="popup event-popup">
    <div
      class="popup__wrapper popup__wrapper_co-admin"
      @click="hidePopupByClickWrap($event)"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">{{ getPopupTitle }}</h2>
          <div class="popup__close" @click="closePopup()">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(1)"
                @click="tab = 1"
              >
                Общие данные
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(2)"
                @click="tab = 2"
                v-if="itemsWithContent.includes(module)"
              >
                Контент
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(3)"
                @click="tab = 3"
                v-if="
                  itemsWithSliders.includes(module) ||
                  (module === 'production' && id !== 'create')
                "
              >
                Слайдер
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(4)"
                @click="tab = 4"
                v-if="
                  (module === 'solutions' || module === 'promo_landing') &&
                  id !== 'create'
                "
              >
                Задачи
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(5)"
                @click="tab = 5"
                v-if="module === 'solutions' && id !== 'create'"
              >
                Возможности
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(6)"
                @click="tab = 6"
                v-if="
                  (module === 'solutions' || module === 'promo_landing') &&
                  id !== 'create' &&
                  id !== 'soft_landing'
                "
              >
                Преимущества
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(7)"
                @click="tab = 7"
                v-if="
                  (module === 'solutions' || module === 'promo_landing') &&
                  id !== 'create' &&
                  !['partner_landing', 'soft_landing'].includes(id)
                "
              >
                Программное обеспечение
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(8)"
                @click="tab = 8"
                v-if="module === 'production' && id !== 'create'"
              >
                Функциональные возможности
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(9)"
                @click="tab = 9"
                v-if="module === 'production' && id !== 'create'"
              >
                Характеристики
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(10)"
                @click="tab = 10"
                v-if="module === 'production' && id !== 'create'"
              >
                Документация
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName('TabSlider1')"
                @click="tab = 'TabSlider1'"
                v-if="module === 'promo_landing' && id === 'soft_landing'"
              >
                Таб слайдер
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName('TabSlider2')"
                @click="tab = 'TabSlider2'"
                v-if="module === 'promo_landing' && id === 'soft_landing'"
              >
                Таб слайдер 2
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName('promo_landing')"
                @click="tab = 'promo_landing'"
                v-if="
                  module === 'promo_landing' &&
                  !['partner_landing'].includes(id)
                "
              >
                <template v-if="id === 'soft_landing'"> REST блок </template>
                <template v-else>
                  Ключевые функциональные возможности
                </template>
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName('standart_vs_ecomon')"
                @click="tab = 'standart_vs_ecomon'"
                v-if="
                  module === 'promo_landing' &&
                  !['partner_landing', 'soft_landing'].includes(id)
                "
              >
                Standart Vs Econom
              </div>

              <div
                class="cm-popup-nav__item"
                :class="getTabClassName('promo_landing_devices_page_settings')"
                @click="tab = 'promo_landing_devices_page_settings'"
                v-if="
                  module === 'promo_landing' &&
                  !['partner_landing', 'soft_landing'].includes(id)
                "
              >
                Настройки на странице оборудования
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(11)"
                @click="tab = 11"
                v-if="itemsWithSeo.includes(module)"
              >
                Сео
              </div>
            </div>
            <news-fields
              v-if="module === 'news'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <benefits-fields
              v-if="module === 'benefits'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <examples-fields
              v-if="module === 'examples'"
              :module="module"
              :tab="tab"
              :data="data"
              :items="items"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
              @changeItems="changeItems"
            />
            <facts-fields
              v-if="module === 'facts'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <solutions-fields
              v-if="module === 'solutions'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <sliders-fields
              v-if="module === 'sliders'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <services-fields
              v-if="module === 'services'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <production-categories-fields
              v-if="module === 'productionCategories'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <production-fields
              v-if="module === 'production'"
              :module="module"
              :tab="tab"
              :data="data"
              :items="items"
              :id="id"
              :selectListOptions="selectListOptions"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <static-pages-fields
              v-if="module === 'staticPages'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <static-fields
              v-if="module === 'blocks' || thisIsBlockTime"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <main-page-footer-facts-fields
              v-if="module === 'mainpageFooterFacts'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <blog-fields
              v-if="module === 'BlogPosts'"
              :module="module"
              :tab="tab"
              :data="data"
              :items="items"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
              @changeItems="changeItems"
            />

            <blog-categories-fields
              v-if="module === 'BlogCategories'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <regions-fields
              v-if="module === 'regions'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />
            <CitiesFields
              v-if="module === 'cities'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <PromoLandingFields
              v-if="module === 'promo_landing'"
              :module="module"
              :tab="tab"
              :data="data"
              :id="id"
              @closePopup="closePopup"
              @setPreloader="setPreloader"
              @getItems="getItems"
            />

            <div class="fields-spinner" v-show="preloader">
              <Spinner
                :noFadeIn="true"
                name="ball-grid-pulse"
                color="#c9eeff"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsFields from "./fields/NewsFields";
import BenefitsFields from "./fields/BenefitsFields";
import ExamplesFields from "./fields/ExamplesFields";
import FactsFields from "./fields/FactsFields";
import SolutionsFields from "./fields/SolutionsFields";
import SlidersFields from "./fields/SlidersFields";
import StaticFields from "./fields/StaticFields";
import ServicesFields from "./fields/ServicesFields";
import ProductionCategoriesFields from "./fields/ProductionCategoriesFields";
import ProductionFields from "./fields/ProductionFields";
import StaticPagesFields from "./fields/StaticPagesFields";
import MainPageFooterFactsFields from "./fields/MainPageFooterFactsFields";
import BlogFields from "./fields/BlogFields";
import BlogCategoriesFields from "./fields/BlogCategoriesFields";

import { mapState } from "vuex";
import * as _ from "lodash";
import CommercialSitePopup from "@/mixins/site/CommercialSitePopup";
import Spinner from "vue-spinkit";
import RegionsFields from "./fields/RegionsFields";
import PromoLandingFields from "./fields/PromoLandingFields";
import CitiesFields from "@/modules/commercial_site/fields/CitiesFields";

export default {
  props: ["id", "module", "items", "selectListOptions"],
  mixins: [CommercialSitePopup],
  data() {
    return {};
  },
  created() {},
  // watch: {
  //   data(val, old) {
  //     if (this.id !== 'create' && !this.data) {
  //       this.$router.push('/commercial_site/'+this.module)
  //     }
  //   }
  // },
  computed: {
    getPopupTitle() {
      switch (this.id) {
        case "create":
          return "Добавить";
        default:
          return "Редактировать: " + this.getItemName(this.data);
      }
    },
    data() {
      let foundItem = _.find(this.items, ["Id", this.id]);
      if (foundItem) {
        foundItem = JSON.parse(JSON.stringify(foundItem));
      }
      return foundItem;
    },
    thisIsBlockTime() {
      return this.module.substr(0, 9) === "blocks_of";
    },
    ...mapState("site", [
      "itemsWithContent",
      "itemsWithSliders",
      "itemsWithSeo",
    ]),
  },
  methods: {
    getItemName(item) {
      if (this.module === "promo_landing") return "Лендинг";
      if (item) {
        return item.Name
          ? item.Name
          : item.Title
          ? item.Title
          : item.SlideTitle
          ? item.SlideTitle
          : item.CategoryName;
      }
    },
    getItems(data) {
      this.$emit("getItems", data);
    },
    changeItems(items) {
      this.$emit("changeItems", items);
    },
  },
  components: {
    RegionsFields,
    NewsFields,
    BenefitsFields,
    ExamplesFields,
    FactsFields,
    SolutionsFields,
    Spinner,
    SlidersFields,
    StaticFields,
    ServicesFields,
    ProductionCategoriesFields,
    ProductionFields,
    StaticPagesFields,
    MainPageFooterFactsFields,
    BlogFields,
    BlogCategoriesFields,
    PromoLandingFields,
    CitiesFields,
  },
};
</script>
