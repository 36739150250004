<template>
  <div
    v-if="id !== 'create' && parentData.language !== 'ru'"
    class="transaction-field"
  >
    <input
      type="text"
      v-if="inputType === 'input'"
      class="input"
      ref="input"
      @blur="saveField"
      @keyup="validateString($event)"
      :value="translatedValue"
    />

    <div class="skeditor skeditor-lang" v-if="inputType === 'editor'">
      <textarea
        v-tinymce
        class=""
        :id="'editor' + EntityColumnName"
        :value="translatedValue"
        ref="editor"
      ></textarea>
    </div>
    <textarea
      class="textarea"
      :value="translatedValue"
      ref="textarea"
      @blur="saveField"
      v-if="inputType === 'textarea'"
    ></textarea>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Api from "../../../api/commercial_site";
import { showAlert } from "../../../utils";
import * as c from "../../../store/commercial_site/constants";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  props: [
    "module",
    "id",
    "EntityColumnName",
    "OriginalValue",
    "inputType",
    "translation",
  ],
  data() {
    return {
      editor: DecoupledEditor,
    };
  },
  computed: {
    ...mapState("site", ["parentData"]),
  },
  methods: {
    saveField() {
      if (
        [
          "CharacteristicsName",
          "CharacteristicsValue",
          "BenefitText",
          "RentPromoText",
        ].includes(this.EntityColumnName) ||
        [
          "SiteProductionDocuments",
          "SiteSolutionsOpportunities",
          "SiteSolutionsBenefits",
          "SiteSolutionsSoft",
        ].includes(this.module)
      ) {
        this.save();
      }
    },
    save() {
      if (this.parentData.language !== "ru") {
        let inputValue;
        let id = "";
        let OriginalValue = this.OriginalValue ? this.OriginalValue : "1";
        if (this.inputType === "editor") {
          // eslint-disable-next-line no-undef
          inputValue = tinymce
            .get("editor" + this.EntityColumnName)
            .getContent();
        } else {
          inputValue = this.$refs.input
            ? this.$refs.input.value
            : this.$refs.textarea.value;
        }
        if (this.translatedValue !== inputValue) {
          // console.log(inputValue, 1111)

          document.querySelector("#app").classList.add("app--load");
          const formData = new FormData();
          if (
            this.translation &&
            this.translation[this.parentData.language] !== void 0 &&
            this.translation[this.parentData.language][this.EntityColumnName]
          ) {
            formData.append("_method", "PATCH");
            id = this.translation[this.parentData.language][
              this.EntityColumnName
            ].id;
          }
          formData.append("EntityName", this.module);
          formData.append("EntityId", this.id);
          formData.append("Language", this.parentData.language);
          formData.append("OriginalValue", OriginalValue);
          formData.append("TranslatedValue", inputValue);
          formData.append("EntityColumnName", this.EntityColumnName);

          Api.setTranslation(id, formData)
            .then((res) => {
              if (res.data.status === "success") {
                // showAlert('success', 'Данные успешно сохранены')
              }
              this.$emit("getTranslation");
            })
            .catch((err) => {
              showAlert("error", "Ошибка сохранения переводов", err.message);
            })
            .finally(() => {
              document.querySelector("#app").classList.remove("app--load");
            });
        }
      }
    },
    validateString(e) {
      if (this.EntityColumnName === "Uri") {
        e.target.value = e.target.value.trim().replace(" ", "");
      }
    },
    ...mapActions("site", [c.WORK_TYPES_ACTION]),
  },
  directives: {
    tinymce: {
      inserted: function (el, binding, vnode) {
        // eslint-disable-next-line no-undef
        tinymce.init({
          target: document.querySelector(
            "#editor" + vnode.context.EntityColumnName
          ),
          plugins:
            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons responsivefilemanager ",
          menubar: "file edit view insert format tools table help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          toolbar_sticky: true,
          height: 400,
          image_advtab: true,
          external_filemanager_path: "/filemanager/",
          filemanager_title: "Responsive Filemanager",
          filemanager_access_key: "1vhvHG8ZSitKn3tK",
          relative_urls: true,
          external_plugins: {
            filemanager: "/static/js/filemanager/plugin.min.js",
          },
          // init_instance_callback: function (editor) {
          //   editor.on('blur', function (e) {
          //     vnode.context.save(editor)
          //   });
          // }
        });
      },
    },
  },
};
</script>

<style scoped></style>
