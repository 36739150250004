<template>
  <div>
    <div
      class="table-vertical__cell _draggable"
      :class="isOpen ? 'table-vertical__cell-active' : ''"
      @click="setFocus(item.Id)"
      :style="`padding-left: ${depth * 15}px`"
    >
      <span v-if="item.child && item.child.length" @click="isOpen = !isOpen">
        <svg
          class="table-vertical__cell__icon"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.73205 9C6.96225 10.3333 5.03775 10.3333 4.26795 9L0.803847 3C0.0340471 1.66666 0.996299 -1.13682e-06 2.5359 -1.00222e-06L9.4641 -3.96541e-07C11.0037 -2.61945e-07 11.966 1.66667 11.1962 3L7.73205 9Z"
            :fill="color"
          />
        </svg>
      </span>
      <span v-else>
        <svg
          class="table-vertical__cell__icon"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" :fill="color" />
        </svg>
      </span>
      <input
        v-if="item.Id !== null"
        class="tag-name"
        v-model="item.TagName"
        @change="handleNameChange(item)"
        @keydown.enter.prevent
        @keydown.enter="handleAddSibling()"
        :data-id="item.Id"
      />
      <span v-else>
        {{ item.TagName }}
      </span>
      <span
        v-if="item.Id !== null"
        class="tags-settings__buttons tags-settings__buttons-right tags-settings__buttons-hidden"
      >
        <span
          class="tags-settings__buttons-add"
          @click.stop
          @click="handleAddItem()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
              :fill="color"
            />
          </svg>
        </span>
        <span class="tags-settings__dropdown" @click.stop>
          <svg width="5px" height="16px">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#three-dots"
            ></use>
          </svg>
          <span class="tags-settings__dropdown-content">
            <ul>
              <li @click="handleDeleteItem">
                <span>Удалить</span>
              </li>
            </ul>
          </span>
        </span>
      </span>
    </div>
    <div>
      <draggable
        :list="item.child"
        @end="$emit('tagMove', item.child)"
        @change="handleParentChange"
        :group="{ name: 'g1' }"
      >
        <div v-show="isOpen" v-for="child in item.child" :key="child.id">
          <rps-tag-item
            :item="child"
            :color="color"
            :depth="depth + 1"
            :tags="tags"
            @removeTemp="handleRemoveTemp"
            @deleteItem="emitDeleteItem"
            @handleParentChange="handleParentChange"
          />
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import Api from "../../../../../api/support/rps";
import draggable from "vuedraggable";
import * as _ from "lodash";
import { showAlert } from "../../../../../utils";

export default {
  name: "RpsTagItem",
  props: { item: Object, color: String, depth: Number, tags: Array },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleNameChange(item) {
      Api.editTag(item.Id, {
        TagName: item.TagName.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, ""),
      }).then((res) => {
        if (!res.data)
          showAlert("error", "Произошла ошибка при обновлении имени тега");
      });
    },
    handleParentChange(evt) {
      this.$emit("handleParentChange", evt);
    },
    handleDeleteItem() {
      this.emitDeleteItem(this.item.Id);
    },
    emitDeleteItem(id) {
      this.$emit("deleteItem", id);
    },
    handleRemoveTemp(id) {
      this.$emit("removeTemp", id);
    },
    handleAddItem() {
      const virtualId = Math.random().toString(36).substring(7);
      this.item.child.push({
        TagName: "Новый тэг",
        VirtualId: virtualId,
        ParentTagId: this.item.Id,
        Position: this.item.child.length + 1,
        Id: null,
      });
      this.isOpen = true;
      Api.createTag({
        TagName: "Новый тэг",
        ParentTagId: this.item.Id,
      })
        .then((res) => {
          if (res.data.Id !== void 0) {
            this.handleRemoveTemp(virtualId);
            this.item.child.push(res.data);
          }
        })
        .catch(() => {
          this.handleRemoveTemp(virtualId);
          this.isOpen = false;
        });
    },
    setFocus(id) {
      const input = document.querySelector(
              `input[data-id="${id}"]`
      );
      if (input) {
        input.focus();
        document.execCommand("selectAll", false, null);
        document.getSelection().collapseToEnd();
      }
    },
    handleAddSibling() {
      const parent = this.getTagById(this.item.ParentTagId);
      if (parent) {
        const virtualId = Math.random().toString(36).substring(7);
        parent.child.push({
          TagName: "Новый тэг",
          VirtualId: virtualId,
          ParentTagId: parent.Id,
          Id: null,
        });
        Api.createTag({
          TagName: "Новый тэг",
          ParentTagId: parent.Id,
        })
          .then((res) => {
            if (res.data.Id !== void 0) {
              this.handleRemoveTemp(virtualId);
              parent.child.push(res.data);

              setTimeout(() => {
                this.setFocus(res.data.Id);
              });
            }
          })
          .catch(() => {
            this.handleRemoveTemp(virtualId);
          });
      }
    },
    getTagById(id) {
      return findTag(this.tags);

      function findTag(array) {
        let searchTag = undefined;
        _.each(array, (i) => {
          if (i.Id === id) {
            searchTag = i;
            return false;
          } else if (i.child && i.child.length) {
            let tag = findTag(i.child);
            if (tag) {
              searchTag = tag;
              return false;
            }
          }
        });
        return searchTag;
      }
    },
  },
  components: { draggable },
};
</script>

<style scoped></style>
