<template>
  <li
    @click.exact.prevent.stop="click"
    @click.meta.prevent.stop="metaClick"
    @click.alt.prevent.stop="metaClick"
    :class="{
      'task-form-filter__item--edit-name': editNameState,
      'task-form-filter__item--open': open,
    }"
    v-click-outside="disableEditNameState"
  >
    <div
      class="task-form-filter__item-row task-form-filter__item-row-main"
      v-if="isFilter"
    >
      <div
        class="task-form-filter__item-color"
        :style="{
          background: getColor,
        }"
      ></div>
      <p>{{ item.ItemInfo.FilterName }}</p>
    </div>
    <div
      class="task-form-filter__item-row task-form-filter__item-row-main"
      v-else
    >
      <template v-if="itemIsNotFolder">
        <svg
          width="16"
          height="15"
          :style="{
            fill:
              item.ItemInfo && item.ItemInfo.Color
                ? '#' + item.ItemInfo.Color.replace('#', '')
                : '#FFB72B',
          }"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/tasks/sprite.svg#folder-icon"
          ></use>
        </svg>
        <p>
          <!--        <span style="font-size: 8px">{{ item.Id }}</span>-->
          <!--        {{ item.Position }}-->
          {{
            item.ItemInfo && item.ItemInfo.GroupName
              ? item.ItemInfo.GroupName
              : ""
          }}
        </p>
      </template>
      <template v-else>
        <SimplePreloader v-if="preloader" />
        <div class="task-form-filter__item-color-switch">
          <svg
            class="task-form-filter__item-arrow-icon"
            v-if="!preloader"
            width="9"
            height="5"
          >
            <use
              xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
            ></use>
          </svg>
          <svg
            @click.stop.prevent="showColorSwitch = !showColorSwitch"
            width="19"
            height="19"
            :style="{
              fill: item.GlobalGroupColor ? item.GlobalGroupColor : '#FFB72B',
              marginRight: '-1px',
            }"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#group-folder-icon"
            ></use>
          </svg>
          <transition name="fade">
            <sketch
              :value="item.GlobalGroupColor ? item.GlobalGroupColor : ''"
              @input="(event) => (item.GlobalGroupColor = event.hex)"
              v-if="showColorSwitch"
              v-click-outside="closeColorSwitch"
            />
          </transition>
        </div>
        <p v-if="!editNameState">
          <!--        <span style="font-size: 8px">{{ item.Id }}</span>-->
          <!--        {{ item.Position }}-->
          {{ item.GlobalGroupName }}
        </p>
        <input
          class="task-form-filter__item-name-input"
          type="text"
          v-model="item.GlobalGroupName"
          ref="input"
          @blur="editName"
          v-else
        />
      </template>

      <div class="task-form-filter__item-count" v-if="itemIsNotFolder">
        <template v-if="item.ItemInfo">
          {{
            item.ItemInfo.CountChildItems
              ? item.ItemInfo.CountChildItems
              : item.ItemInfo.TaskCount
          }}
        </template>
      </div>
      <div class="task-form-filter__item-count" ref="portal-wrapper" v-else>
        <portal to="common">
          <!--          <transition name="fade">-->
          <SidebarFolderEditOptionList
            @close="closePopup"
            @setEditNameState="setEditNameState"
            v-if="editPopupOpen"
            :childs="item.ItemInfo.childs"
            :globalGroup="item"
            :outerList="outerList"
            :outerListIsFavorites="outerListIsFavorites"
            :item="item"
            :itemType="item.ItemInfo.GroupType"
            :groupSelectPopupOpen="groupSelectPopupOpen"
            @closeGroupSelectPopup="groupSelectPopupOpen = false"
            @openChildList="openChildList"
            v-portal="{ parent: $refs['portal-wrapper'] }"
          />
          <!--          </transition>-->
        </portal>
        <div
          class="task-form-filter__item-edit"
          @click.prevent.stop="openPopup"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" rx="4" fill="#F0F0F0" />
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {{ item.ItemInfo.CountChildItems }}
      </div>
    </div>
    <div class="task-form-filter__item-row" v-if="open">
      <draggable
        :group="{ name: 'favorites', pull: false }"
        class="task-form-filter__item-childs"
        :list="item.ItemInfo.childs"
        @add="addToGlobalGroup"
        @end="changeOrder(item.ItemInfo.childs)"
        v-if="
          !itemIsNotFolder &&
          item.ItemInfo.childs &&
          item.ItemInfo.childs.length
        "
      >
        <SidebarGroupsItem
          v-for="i in item.ItemInfo.childs"
          class="task-form-filter__item"
          :class="{
            'task-form-filter__item--active':
              i.ItemInfo.FilterName !== void 0
                ? filterIsActive(i.ItemInfo.Id)
                : isActive(i.ItemInfo.Id),
          }"
          :key="i.Id"
          :data-item-type="isFilter ? 3 : 1"
          :data-item-id="i.Id"
          :item="i"
          :headerControlElement="headerControlElement"
          :isFilter="i.ItemInfo.FilterName !== void 0"
          @getTasksByUserFilter="
            (id, item) => $emit('getTasksByUserFilter', id, item)
          "
        />
      </draggable>
    </div>
  </li>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import SidebarFolderEditOptionList from "@/modules/tasks/components/Sidebar/SidebarFolderEditOptionList";
import { mapActions, mapMutations } from "vuex";
import {
  ADD_ELEMENT_TO_GLOBAL_GROUP,
  GET_GLOBAL_GROUP_ELEMENTS,
  SET_LOCAL_STORAGE_SETTINGS,
  UPDATE_GLOBAL_GROUP,
} from "@/store/tasks/constants";
import SidebarGroupsItem from "./SidebarGroupsItem";
import sidebarMixins from "@/modules/tasks/components/Sidebar/sidebarMixins";
import draggable from "vuedraggable";
import Api from "@/api/tasks";
import * as _ from "lodash";
import { Sketch } from "vue-color";

export default {
  name: "SidebarGroupsItem",
  mixins: [Helpers, sidebarMixins],
  props: ["item", "headerControlElement", "outerList", "outerListIsFavorites"],
  data() {
    return {
      editPopupOpen: false,
      editNameState: false,
      open: false,
      preloader: false,
      showColorSwitch: false,
      groupSelectPopupOpen: false,
    };
  },
  created() {
    if (!this.itemIsNotFolder) {
      const foundLocalSettings = _.find(this.localStorageSettings, {
        Id: this.item.Id,
        Type: "globalGroup",
      });
      if (foundLocalSettings) {
        const settings = JSON.parse(foundLocalSettings.settings);
        if (settings.open) {
          this.toggleOpen();
        }
      }
    }
  },
  mounted() {
    if (this.item.onlyCreated) {
      this.editNameState = true;
      setTimeout(() => {
        this.$refs.input.focus();
      });
      this.item.onlyCreated = false;
    }
  },
  computed: {
    itemIsNotFolder() {
      return !this.item.GlobalGroupName;
      // if (this.isFilter) return true;
      // else {
      //   return item.DefaultTemplateId !== void 0 || item.ItemInfo !== void 0;
      // }
    },
    itemId() {
      return this.item.ItemId ? this.item.ItemId : this.item.Id;
    },
    isFilter() {
      return this.item.ItemInfo && this.item.ItemInfo.FilterParams !== void 0;
    },
    getColor() {
      return "#" + this.item.ItemInfo.FilterColor?.replace("#", "");
    },
  },
  methods: {
    setEditNameState() {
      this.editNameState = true;
      setTimeout(() => {
        this.$refs.input.focus();
      });
    },
    disableEditNameState() {
      this.editNameState = false;
    },
    editName() {
      this[UPDATE_GLOBAL_GROUP]({
        Id: this.item.ItemId ? this.item.ItemId : this.item.Id,
        GroupName: this.item.GlobalGroupName,
      });
    },
    toggleOpen() {
      this.open = !this.open;
      if (this.open) {
        this.preloader = true;
        this[GET_GLOBAL_GROUP_ELEMENTS](this.itemId).finally(() => {
          this.preloader = false;
        });
      }
      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.item.Id,
          Type: "globalGroup",
          settings: {
            open: this.open,
          },
        },
        init: false,
      });
    },
    closePopup() {
      this.editPopupOpen = false;
      this.groupSelectPopupOpen = false;
    },
    openPopup() {
      this.editPopupOpen = !this.editPopupOpen;
    },
    openChildList() {
      if (!this.groupSelectPopupOpen) {
        this.preloader = true;
        this[GET_GLOBAL_GROUP_ELEMENTS](this.itemId).finally(() => {
          this.preloader = false;
          this.groupSelectPopupOpen = true;
        });
      } else {
        this.groupSelectPopupOpen = false;
      }
    },
    click(e) {
      if (!e.target.closest(".task-form-filter__item-color-switch")) {
        if (this.isFilter) {
          this.$emit("getTasksByUserFilter", this.itemId, this.item.ItemInfo);
        } else {
          this.itemIsNotFolder
            ? this.changeGroup(this.item.ItemInfo)
            : this.toggleOpen();
        }
      }
    },
    metaClick() {
      if (this.isFilter) {
        this.$emit("selectItem", this.item, "selectedFiltersArray");
      } else {
        this.itemIsNotFolder &&
          this.$emit("selectItem", this.item, "selectedGroupsArray");
      }
    },
    filterIsActive(id) {
      return this.selectedFilter === id;
    },
    changeOrder(items) {
      const formData = new FormData();
      _.each(items, (i) => {
        formData.append("Ids[]", i.Id);
      });
      Api.changeGlobalGroupElementsOrder(formData);
    },
    closeColorSwitch() {
      this.showColorSwitch = false;
      this[UPDATE_GLOBAL_GROUP]({
        Id: this.item.ItemId ? this.item.ItemId : this.item.Id,
        Color: this.item.GlobalGroupColor,
      });
    },
    checkMove: function (evt) {
      if (evt.draggedContext.element.GlobalGroupName) {
        return !evt.to.classList.contains("task-form-filter__list--favorites");
      }
      return true;
    },
    addToGlobalGroup(e) {
      this[ADD_ELEMENT_TO_GLOBAL_GROUP]({
        GroupId: this.item.ItemInfo.Id,
        ItemType: e.item.getAttribute("data-item-type"),
        ItemsIds: [e.item.getAttribute("data-item-id")],
      });
    },
    ...mapActions("tasks", [
      UPDATE_GLOBAL_GROUP,
      GET_GLOBAL_GROUP_ELEMENTS,
      ADD_ELEMENT_TO_GLOBAL_GROUP,
    ]),
    ...mapMutations("tasks", [SET_LOCAL_STORAGE_SETTINGS]),
  },
  components: {
    SidebarFolderEditOptionList,
    SidebarGroupsItem,
    draggable,
    Sketch,
  },
};
</script>

<style scoped></style>
