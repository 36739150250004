<template>
  <div id="schemes-wrap">
    <template v-if="offerObject.json.Schemes.length">
      <div
        class="pdf-cont"
        v-for="(item, index) in offerObject.json.Schemes"
        :key="index"
      >
        <div class="print">
          <div class="print__cont">
            <div class="print__logo">
              <img :src="getimgStaticSrc('sales/print-logo.svg')" />
            </div>
            <div
              class="print__title"
              :style="{
                backgroundImage: `url(${getimgStaticSrc(
                  'sales/print-badge.svg'
                )})`,
              }"
            >
              Схема проездов <br />парковки
            </div>
            <!--<p style="font-size: 10px;color:black;font-weight: bold">{{item.img}}</p>-->
            <div class="print__img-scheme">
              <template v-if="!print.rendering">
                <img
                  v-if="imageBlobCheck('builder', 'schemes', item.id)"
                  :src="item.blob"
                  alt=""
                />
                <img v-else :src="getImageOfferSrc(item.img)" alt="" />
              </template>
              <template v-else>
                <img :src="getImageOfferSrc(item.img)" alt="" />
              </template>
            </div>
          </div>
          <div class="print__footer">{{ countPage("Schemes", index + 1) }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pdf-cont">
        <div class="print">
          <div class="print__cont">
            <div class="print__logo">
              <img :src="getimgStaticSrc('sales/print-logo.svg')" />
            </div>
            <div
              class="print__title"
              :style="{
                backgroundImage: `url(${getimgStaticSrc(
                  'sales/print-badge.svg'
                )})`,
              }"
            >
              Схема проездов <br />парковки
            </div>
            <div class="print__img-scheme">
              <div class="print__info-item print__info-item--scheme">
                <b>Отсутствует</b>
              </div>
            </div>
          </div>
          <div class="print__footer">{{ countPage("Schemes", 1) }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject", "print"]),
  },
};
</script>
