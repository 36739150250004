<template>
  <div
    class="popup event-popup event-popup_active kb-popup kb-popup--object kb-popup--edit"
  >
    <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Создание объекта</h2>
          <div class="popup__close" @click="closePopup">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="kb-popup-form">
            <div class="kb-popup-form__inputs">
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Дата подачи заявки:
                </span>
                <span class="field__content">
                  <date-picker
                    :format="'YYYY-MM-DD'"
                    valueType="format"
                    input-class="input"
                    lang="ru"
                    v-model="form.Created"
                  ></date-picker>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Партнер:
                </span>
                <span class="field__content">
                  <div class="select">
                    <select
                      class="select__control"
                      v-model="form.DistributorId"
                    >
                      <option :value="d.Id" v-for="d in distbs" :key="d.Id">
                        {{ d.Name }}
                      </option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название объекта:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="form.Name" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Тип объекта:
                </span>
                <span class="field__content">
                  <div class="select">
                    <select class="select__control" v-model="form.Type">
                      <option
                        :value="t.value"
                        v-for="t in objectTypeList"
                        :key="t.value"
                      >
                        {{ t.name }}
                      </option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Файл КП:
                </span>
                <span class="field__content input-file">
                  <input
                    type="file"
                    hidden
                    class="input"
                    @change="filesChange($event.target.files)"
                  />
                  <input
                    type="text"
                    class="input"
                    :value="file && file.name"
                    disabled
                  />
                  <div class="button button_grey button-file">
                    <img src="/static/images/gps.svg" />
                    Выбрать файл
                  </div>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Тип системы:
                </span>
                <span class="field__content">
                  <div class="select">
                    <select class="select__control" v-model="form.SystemType">
                      <option :value="0">АСПП</option>
                      <option :value="1">СПН</option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Город:
                </span>
                <span class="field__content">
                  <input type="text" class="input" v-model="form.City" />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <gmap-autocomplete
                    @place_changed="
                      (place) => {
                        setPlace(place);
                        usePlace();
                      }
                    "
                    :value="form.Address"
                    class="input"
                  >
                  </gmap-autocomplete>
                </span>
              </label>
            </div>
            <GmapMap
              :zoom="16"
              :center="{ lat: +form.Lat, lng: +form.Lng }"
              class="field__map kb-field__map"
              @click="
                (location) => {
                  form.Lat = location.latLng.lat();
                  form.Lng = location.latLng.lng();
                  setAddressByGeo({ lat: +form.Lat, lng: +form.Lng });
                }
              "
            >
              <GmapMarker
                ref="myMarker"
                :position="{ lat: +form.Lat, lng: +form.Lng }"
                :clickable="true"
                :draggable="true"
                @dragend="
                  (location) => {
                    form.Lat = location.latLng.lat();
                    form.Lng = location.latLng.lng();
                    setAddressByGeo({ lat: +form.Lat, lng: +form.Lng });
                  }
                "
              />
            </GmapMap>
            <div class="sup-form-btn__wrap">
              <div
                class="sup-form-btn__btn sup-client-btn sup-client-btn-2"
                @click="save"
              >
                Сохранить
              </div>
            </div>
          </div>
        </div>
        <div class="kb-popup-preloader" v-if="preloader">
          <Spinner
            :noFadeIn="true"
            name="ball-scale-ripple"
            color="#F9AD1A"
            width="60"
            height="60"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CREATE_RESERVED_OBJECT } from "../../../../store/sales_reservation/constants";
import Spinner from "vue-spinkit";
import * as _ from "lodash";

export default {
  data() {
    return {
      form: {
        DistributorId: "",
        // DistributorContactId: '',
        Name: "",
        Type: "",
        City: "",
        Address: "",
        Lat: "55.754461",
        Lng: "37.633781",
        SystemType: 0,
        // Status: '',
      },
      preloader: false,
      objectTypeList: [
        { value: 0, name: "Торговый центр" },
        { value: 1, name: "Торгово-развлекательнцый центр" },
        { value: 2, name: "Бизнес-центр" },
        { value: 3, name: "Жилой комплекс" },
        { value: 4, name: "Парк/Зона отдыха" },
        { value: 5, name: "Спортивный комплекс" },
        { value: 6, name: "Склад/Промышленное предприятие" },
        { value: 7, name: "Больница" },
        { value: 8, name: "Административное здание" },
        { value: 9, name: "Другое" },
      ],
      file: null,
    };
  },
  computed: {
    ...mapState("clients", ["distbs"]),
    ...mapState("reserve", ["reservedObjects"]),
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup();
      }
    },
    closePopup() {
      this.$router.push("/sales/reservation/objects");
    },
    save() {
      this.preloader = true;
      const formData = new FormData();
      _.each(this.form, (val, key) => {
        if (val) formData.append(key, val);
      });
      if (this.file) formData.append("file", this.file.file, this.file.name);
      this[CREATE_RESERVED_OBJECT](formData)
        .then(() => {
          this.closePopup();
        })
        .finally(() => (this.preloader = false));
    },
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.file = {
          file: fileList[x],
          name: fileList[x].name,
        };
      });
    },
    setAddressByGeo(position) {
      this.$geocoder.send(position, (response) => {
        try {
          this.form.City = _.find(response.results, (i) => {
            return (
              i.types.includes("administrative_area_level_2") &&
              i.types.includes("political")
            );
          }).address_components[0].long_name;
          this.form.Address = _.find(response.results, (i) => {
            return i.types.includes("street_address");
          }).formatted_address;
        } catch (e) {
          e;
        }
        // console.log(response)
      });
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.form.Lat = this.place.geometry.location.lat();
        this.form.Lng = this.place.geometry.location.lng();
        this.place = null;
        this.setAddressByGeo({ lat: +this.form.Lat, lng: +this.form.Lng });
      }
    },
    ...mapActions("reserve", [CREATE_RESERVED_OBJECT]),
  },
  components: { Spinner },
};
</script>

<style scoped></style>
