<template>
  <div class="print print--2">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div class="print__text">
        <p>
          Дата: {{ offerObject.json.Date | formatDate }} <br />
          Кому: {{ offerObject.json.ClientName }}<br />
          Компания: {{ offerObject.json.CompanyName }}
          <!--E-mail: {{offerObject.json.ClientEmail}}<br>-->
          <!--Телефон: {{offerObject.json.ClientPhone}}-->
        </p>
        <p>{{ offerObject.json.ContentText }}</p>
      </div>
    </div>
    <div class="print__list">
      <div class="print__list-row">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">информация об объекте и задачи</div>
        <div class="co-auto ml-auto print__list-page">3</div>
      </div>
      <div
        class="print__list-row"
        v-if="!this.offerObject.json.passPages.schemes"
      >
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Схема проездов</div>
        <div class="co-auto ml-auto print__list-page">
          {{ navCountPage("Schemes") | shortNum }}
        </div>
      </div>
      <div class="print__list-row">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Оборудование и опции</div>
        <div
          class="co-auto ml-auto print__list-page"
          v-if="!offerObject.json.passPages.schemes"
        >
          {{ pageNums.Schemes + 1 }}
        </div>
        <div class="co-auto ml-auto print__list-page" v-else>4</div>
      </div>
      <div class="print__list-row">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Итоговая смета и условия</div>
        <div class="co-auto ml-auto print__list-page">
          {{ navCountPage("Total") | shortNum }}
        </div>
      </div>
      <div class="print__list-row" v-if="print.full">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Функциональные задачи системы</div>
        <div class="co-auto ml-auto print__list-page">
          {{ pageNums.Total + 1 }}
        </div>
      </div>
      <div class="print__list-row" v-if="print.full">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Преимущества</div>
        <div class="co-auto ml-auto print__list-page">
          {{ pageNums.Total + 2 }}
        </div>
      </div>
      <div
        class="print__list-row"
        v-if="print.full && !this.offerObject.json.passPages.service"
      >
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Сервисное обслуживание</div>
        <div class="co-auto ml-auto print__list-page">
          {{ pageNums.Total + 5 }}
        </div>
      </div>
      <!--      <div class="print__list-row" v-if="print.full && !offerObject.json.passPages.option_desc">-->
      <!--        <div class="col-auto print__list-badge">-->
      <!--          <div class="print__content-badge" :style="{backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`}" ></div>-->
      <!--        </div>-->
      <!--        <div class="col print__list-name">описание Опций</div>-->
      <!--        <div class="co-auto ml-auto print__list-page">{{navCountPage('Options') | shortNum}}</div>-->
      <!--      </div>-->
      <div class="print__list-row" v-if="print.full">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Выполненные работы</div>
        <div class="co-auto ml-auto print__list-page">
          {{ pageNums.OptionsLastPage + 1 }}
        </div>
      </div>
      <div class="print__list-row">
        <div class="col-auto print__list-badge">
          <div
            class="print__content-badge"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          ></div>
        </div>
        <div class="col print__list-name">Контакты вашего менеджера</div>
        <div class="co-auto ml-auto print__list-page" v-if="print.full">
          {{ pageNums.Objects + 1 }}
        </div>
        <div class="co-auto ml-auto print__list-page" v-else>
          {{ pageNums.Tenant + 3 }}
        </div>
      </div>
    </div>
    <div class="print__footer">2</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import moment from "moment";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject", "print", "pageNums"]),
  },
  filters: {
    formatDate(date) {
      if (date) {
        if (moment(date, "DD.MM.YYYY").isValid()) {
          return moment(date, "DD.MM.YYYY").format("DD.MM.YYYY");
        } else {
          return moment(date).format("DD.MM.YYYY");
        }
      }
    },
    shortNum(val) {
      if (val) {
        return String(val).split("-")[0];
      }
    },
  },
};
</script>
