<template>
  <div class="popup event-popup">
    <div
      class="popup__wrapper popup__wrapper_co-admin"
      @click="hidePopupByClickWrap($event, true)"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Настройки</h2>
          <div class="popup__close" @click="closePopup(true)">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(1)"
                @click="tab = 1"
                v-if="settingsFieldName"
              >
                Изображения
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(2)"
                @click="tab = 2"
                v-if="module === 'examples'"
              >
                Типы выполенных работ
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(3)"
                @click="tab = 3"
                v-if="seoSectionFields"
              >
                Сео
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(4)"
                @click="tab = 4"
                v-if="welcomeSectionFields"
              >
                Верхний текст
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(5)"
                @click="tab = 5"
                v-if="callSectionFields"
              >
                Call to action
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(6)"
                @click="tab = 6"
                v-if="promoSectionBlock"
              >
                Промотекст
              </div>
              <div
                class="cm-popup-nav__item"
                :class="getTabClassName(7)"
                @click="tab = 7"
                v-if="promoSectionBlock"
              >
                Информация в футере
              </div>
              <!--<div class="cm-popup-nav__item" :class="getTabClassName(8)" @click="tab=8" v-if="module === 'BlogPosts'">-->
              <!--Категории-->
              <!--</div>-->
            </div>
            <div class="cm-popup__right">
              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 1 && settingsFieldName"
              >
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    full:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      min="0"
                      class="input"
                      v-model="settings[settingsFieldName].full.width"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    medium:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      min="0"
                      class="input"
                      v-model="settings[settingsFieldName].medium.width"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    low:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      min="0"
                      class="input"
                      v-model="settings[settingsFieldName].low.width"
                    />
                  </span>
                </label>

                <div v-if="module === 'production'">
                  <span class="separate-text"
                    >Настройки для изображений функциональных возможностей</span
                  >
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      full:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="
                          settings.SiteProductionFunctionalImages.full.width
                        "
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      medium:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="
                          settings.SiteProductionFunctionalImages.medium.width
                        "
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      low:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="
                          settings.SiteProductionFunctionalImages.low.width
                        "
                      />
                    </span>
                  </label>
                </div>
                <div v-if="module === 'solutions'">
                  <span class="separate-text"
                    >Настройки для изображений возможностей</span
                  >
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      full:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsOpportunities.full.width"
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      medium:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="
                          settings.SiteSolutionsOpportunities.medium.width
                        "
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      low:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsOpportunities.low.width"
                      />
                    </span>
                  </label>

                  <span class="separate-text"
                    >Настройки для изображений преимуществ
                  </span>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      full:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsBenefits.full.width"
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      medium:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsBenefits.medium.width"
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      low:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsBenefits.low.width"
                      />
                    </span>
                  </label>

                  <span class="separate-text"
                    >Настройки для изображений оборудования
                  </span>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      full:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsSoft.full.width"
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      medium:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsSoft.medium.width"
                      />
                    </span>
                  </label>
                  <label class="field">
                    <span
                      class="field__label field__label_align_right clients-common-info__label"
                    >
                      low:
                    </span>
                    <span class="field__content">
                      <input
                        type="number"
                        min="0"
                        class="input"
                        v-model="settings.SiteSolutionsSoft.low.width"
                      />
                    </span>
                  </label>
                </div>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="save"
                >
                  Сохранить
                </button>
              </div>
              <div class="cm-popup-form__wrapper" v-if="tab === 2">
                <translation-tabs :module="module" :id="'edit'" />
                <label class="field" v-if="workTypeItems.length">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Типы:
                  </span>
                  <span class="field__content">
                    <ul class="item-popup-list">
                      <li v-for="(item, index) in workTypeItems" :key="index">
                        <input
                          type="text"
                          class="input"
                          :value="item.Name"
                          @blur="editWorkType($event, item)"
                          v-if="parentData.language === 'ru'"
                        />
                        <translate-field
                          :EntityColumnName="'Name'"
                          :OriginalValue="item.Name"
                          :inputType="'input'"
                          :module="'SiteWorkTypes'"
                          ref="translated-field-WorkType"
                          @getTranslation="
                            getTranslation('SiteWorkTypes', item.Id)
                          "
                          :translation="item.translation"
                          :id="item.Id"
                        />
                        <!--<span @click="removeWorkTypeItem(item.Id)">✖</span>-->
                      </li>
                    </ul>
                  </span>
                </label>
                <label class="field" v-if="parentData.language === 'ru'">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Название:
                  </span>
                  <span class="field__content">
                    <input type="text" class="input" v-model="workTypeName" />
                  </span>
                </label>
                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  v-if="parentData.language === 'ru'"
                  @click="addWorkType"
                >
                  Добавить новый тип
                </button>
                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  v-else
                  @click="saveTranslatedTypes"
                >
                  Сохранить переводы
                </button>
              </div>

              <!--<div class="cm-popup-form__wrapper" v-if="tab===8">-->
              <!--<translation-tabs :module="module" :id="'edit'"/>-->
              <!--<label class="field" v-if="blogCategoriesItems.length">-->
              <!--<span class="field__label field__label_align_right clients-common-info__label">-->
              <!--Категории:-->
              <!--</span>-->
              <!--<span class="field__content">-->
              <!--<ul class="item-popup-list">-->
              <!--<li v-for="(item, index) in blogCategoriesItems" :key="index">-->
              <!--<input type="text" class="input" :value="item.CategoryName" @blur="editBlogCategory($event, item)" v-if="parentData.language==='ru'">-->
              <!--<translate-field :EntityColumnName="'CategoryName'"-->
              <!--:OriginalValue="item.CategoryName"-->
              <!--:inputType="'input'"-->
              <!--:module="'SiteBlogCategories'"-->
              <!--ref="translated-field-BlogCategory"-->
              <!--@getTranslation="getTranslation('SiteBlogCategories', item.Id)"-->
              <!--:translation="item.translation"-->
              <!--:id="item.Id"-->
              <!--/>-->
              <!--<span @click="removeBlogCategoryItem(item.Id)">✖</span>-->
              <!--</li>-->
              <!--</ul>-->
              <!--</span>-->
              <!--</label>-->
              <!--<label class="field" v-if="parentData.language==='ru'">-->
              <!--<span class="field__label field__label_align_right clients-common-info__label">-->
              <!--Название:-->
              <!--</span>-->
              <!--<span class="field__content">-->
              <!--<input type="text" class="input" v-model="blogCategoryName">-->
              <!--</span>-->
              <!--</label>-->
              <!--<button class="button button_green button_uppercase cm-popup-form__btn-submit"-->
              <!--v-if="parentData.language==='ru'"-->
              <!--@click="addBlogCategory"-->
              <!--&gt;-->
              <!--Добавить новую категорию-->
              <!--</button>-->
              <!--<button class="button button_green button_uppercase cm-popup-form__btn-submit"-->
              <!--v-else-->
              <!--@click="saveTranslatedBlogCategories"-->
              <!--&gt;-->
              <!--Сохранить переводы-->
              <!--</button>-->
              <!--</div>-->

              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 3 && seoSectionBlock"
              >
                <translation-tabs :module="module" :id="seoSectionBlock.Id" />

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Заголовок в шапке:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="seoSectionBlock.PageTitle"
                      v-if="parentData.language === 'ru'"
                    />
                    <translate-field
                      :EntityColumnName="'PageTitle'"
                      :OriginalValue="seoSectionBlock.PageTitle"
                      :inputType="'input'"
                      :module="'SiteBlocks'"
                      ref="translated-field-PageTitle"
                      @getTranslation="
                        getTranslation('SiteBlocks', seoSectionBlock.Id)
                      "
                      :translation="seoSectionBlock.translation"
                      :id="seoSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Title:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="seoSectionBlock.SeoTitle"
                      v-if="parentData.language === 'ru'"
                    />
                    <translate-field
                      :EntityColumnName="'SeoTitle'"
                      :OriginalValue="seoSectionBlock.SeoTitle"
                      :inputType="'input'"
                      :module="'SiteBlocks'"
                      ref="translated-field-SeoTitle"
                      @getTranslation="
                        getTranslation('SiteBlocks', seoSectionBlock.Id)
                      "
                      :translation="seoSectionBlock.translation"
                      :id="seoSectionBlock.Id"
                    />
                  </span>
                </label>

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Description:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="seoSectionBlock.SeoDescription"
                      v-if="parentData.language === 'ru'"
                    />
                    <translate-field
                      :EntityColumnName="'SeoDescription'"
                      :OriginalValue="seoSectionBlock.SeoDescription"
                      :inputType="'input'"
                      :module="'SiteBlocks'"
                      ref="translated-field-SeoDescription"
                      @getTranslation="
                        getTranslation('SiteBlocks', seoSectionBlock.Id)
                      "
                      :translation="seoSectionBlock.translation"
                      :id="seoSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Keywords:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="seoSectionBlock.SeoKeywords"
                      v-if="parentData.language === 'ru'"
                    />
                    <translate-field
                      :EntityColumnName="'SeoKeywords'"
                      :OriginalValue="seoSectionBlock.SeoKeywords"
                      :inputType="'input'"
                      :module="'SiteBlocks'"
                      ref="translated-field-SeoKeywords"
                      @getTranslation="
                        getTranslation('SiteBlocks', seoSectionBlock.Id)
                      "
                      :translation="seoSectionBlock.translation"
                      :id="seoSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Text:
                  </span>
                  <span class="field__content">
                    <textarea
                      class="textarea"
                      v-model="seoSectionBlock.SeoText"
                      v-if="parentData.language === 'ru'"
                    >
                    </textarea>
                    <translate-field
                      :EntityColumnName="'SeoText'"
                      :OriginalValue="seoSectionBlock.SeoText"
                      :inputType="'textarea'"
                      :module="'SiteBlocks'"
                      ref="translated-field-SeoText"
                      @getTranslation="
                        getTranslation('SiteBlocks', seoSectionBlock.Id)
                      "
                      :translation="seoSectionBlock.translation"
                      :id="seoSectionBlock.Id"
                    />
                  </span>
                </label>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="saveBlockSection(seoSectionBlock)"
                >
                  Сохранить
                </button>
              </div>
              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 4 && welcomeSectionBlock"
              >
                <translation-tabs
                  :module="module"
                  :id="welcomeSectionBlock.Id"
                />

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Текст:
                  </span>
                  <span class="field__content">
                    <textarea
                      class="textarea"
                      v-model="welcomeSectionBlock.Value"
                      v-if="parentData.language === 'ru'"
                    >
                    </textarea>
                    <translate-field
                      :EntityColumnName="'Value'"
                      :OriginalValue="welcomeSectionBlock.Value"
                      :inputType="'textarea'"
                      :module="'SiteBlocks'"
                      ref="translated-field-Value"
                      @getTranslation="
                        getTranslation('SiteBlocks', welcomeSectionBlock.Id)
                      "
                      :translation="welcomeSectionBlock.translation"
                      :id="welcomeSectionBlock.Id"
                    />
                  </span>
                </label>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="saveBlockSection(welcomeSectionBlock)"
                >
                  Сохранить
                </button>
              </div>
              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 5 && callSectionBlock"
              >
                <translation-tabs :module="module" :id="callSectionBlock.Id" />

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Текст:
                  </span>
                  <span class="field__content">
                    <textarea
                      class="textarea"
                      v-model="callSectionBlock.Value"
                      v-if="parentData.language === 'ru'"
                    >
                    </textarea>
                    <translate-field
                      :EntityColumnName="'Value'"
                      :OriginalValue="callSectionBlock.Value"
                      :inputType="'textarea'"
                      :module="'SiteBlocks'"
                      ref="translated-field-Value"
                      @getTranslation="
                        getTranslation('SiteBlocks', callSectionBlock.Id)
                      "
                      :translation="callSectionBlock.translation"
                      :id="callSectionBlock.Id"
                    />
                  </span>
                </label>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="saveBlockSection(callSectionBlock)"
                >
                  Сохранить
                </button>
              </div>
              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 6 && promoSectionBlock"
              >
                <translation-tabs :module="module" :id="promoSectionBlock.Id" />

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Текст:
                  </span>
                  <span class="field__content">
                    <textarea
                      class="textarea"
                      v-model="promoSectionBlock.Value"
                      v-if="parentData.language === 'ru'"
                    >
                    </textarea>
                    <translate-field
                      :EntityColumnName="'Value'"
                      :OriginalValue="promoSectionBlock.Value"
                      :inputType="'textarea'"
                      :module="'SiteBlocks'"
                      ref="translated-field-Value"
                      @getTranslation="
                        getTranslation('SiteBlocks', promoSectionBlock.Id)
                      "
                      :translation="promoSectionBlock.translation"
                      :id="promoSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Ссылка:
                  </span>
                  <span class="field__content">
                    <input
                      class="input"
                      type="text"
                      v-model="promoSectionBlock.SeoText"
                    />
                  </span>
                </label>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="saveBlockSection(promoSectionBlock)"
                >
                  Сохранить
                </button>
              </div>
              <div
                class="cm-popup-form__wrapper"
                v-if="tab === 7 && footerSectionBlock"
              >
                <translation-tabs
                  :module="module"
                  :id="footerSectionBlock.Id"
                />

                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Заголовок:
                  </span>
                  <span class="field__content">
                    <input
                      class="input"
                      v-model="footerSectionBlock.Name"
                      v-if="parentData.language === 'ru'"
                    />
                    <translate-field
                      :EntityColumnName="'Name'"
                      :OriginalValue="footerSectionBlock.Name"
                      :inputType="'input'"
                      :module="'SiteBlocks'"
                      ref="translated-field-Name"
                      @getTranslation="
                        getTranslation('SiteBlocks', footerSectionBlock.Id)
                      "
                      :translation="footerSectionBlock.translation"
                      :id="footerSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Описание:
                  </span>
                  <span class="field__content">
                    <textarea
                      class="textarea"
                      v-model="footerSectionBlock.Value"
                      v-if="parentData.language === 'ru'"
                    >
                    </textarea>
                    <translate-field
                      :EntityColumnName="'Value'"
                      :OriginalValue="footerSectionBlock.Value"
                      :inputType="'textarea'"
                      :module="'SiteBlocks'"
                      ref="translated-field-Value"
                      @getTranslation="
                        getTranslation('SiteBlocks', footerSectionBlock.Id)
                      "
                      :translation="footerSectionBlock.translation"
                      :id="footerSectionBlock.Id"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Ссылка:
                  </span>
                  <span class="field__content">
                    <input
                      class="input"
                      type="text"
                      v-model="footerSectionBlock.SeoText"
                    />
                  </span>
                </label>

                <button
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="saveBlockSection(footerSectionBlock)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommercialSitePopup from "@/mixins/site/CommercialSitePopup";
import { mapState, mapActions, mapMutations } from "vuex";
import * as c from "../../store/commercial_site/constants";
import * as _ from "lodash";
import Api from "../../api/commercial_site";
import { showAlert } from "../../utils";
import translationTabs from "@/modules/commercial_site/components/translation-tabs";
import translateField from "@/modules/commercial_site/components/translate-field";
import {
  editorToolBar,
  getTranslation,
  translatedFieldsRefs,
} from "../../mixins/site/CommercialSiteSettings";

export default {
  props: ["module"],
  mixins: [CommercialSitePopup],
  data() {
    return {
      data: null,
      workTypeName: "",
      blogCategoryName: "",
      customToolbar: editorToolBar,
      translation: {},
    };
  },
  created() {
    [
      "seoSectionBlock",
      "welcomeSectionBlock",
      "callSectionBlock",
      "promoSectionBlock",
      "footerSectionBlock",
    ].forEach((i) => {
      if (this[i]) {
        this.getTranslation("SiteBlocks", this[i].Id);
      }
    });
    switch (this.module) {
      case "staticPages":
        this.tab = 5;
        break;
    }
  },
  computed: {
    settingsFieldName() {
      const obj = {
        news: "SiteNews",
        examples: "SiteExamplesWorks",
        facts: "SiteFacts",
        solutions: "SiteSolutions",
        sliders: "SiteSliders",
        services: "SiteServices",
        productionCategories: "SiteProductionCategories",
        production: "SiteProductionGallery",
        mainpageFooterFacts: "SiteMainPageFooterFacts",
        BlogPosts: "SiteBlogPosts",
        blocks: "SiteBlocks",
      };
      if (obj[this.module] !== void 0) {
        return obj[this.module];
      }
      return false;
    },
    seoSectionFields() {
      switch (this.module) {
        case "sliders":
        case "benefits":
        case "mainpageFooterFacts":
        case "blocks_of_main_products":
        case "facts":
          return "seo_section_main";
        case "news":
          return "seo_section_news";
        case "services":
          return "seo_section_services";
        case "examples":
          return "seo_section_examples";
        case "solutions":
          return "seo_section_solutions";
        case "productionCategories":
          return "seo_section_production_categories";
        case "production":
          return "seo_section_production";
        case "BlogPosts":
          return "seo_section_blog_posts";
      }
      return false;
    },
    welcomeSectionFields() {
      switch (this.module) {
        case "solutions":
          return "welcome_section_solutions";
        case "productionCategories":
          return "welcome_section_production";
        case "services":
          return "welcome_section_services";
        case "BlogPosts":
          return "welcome_section_blog_posts";
      }
      return false;
    },
    callSectionFields() {
      switch (this.module) {
        case "sliders":
        case "benefits":
        case "mainpageFooterFacts":
        case "blocks_of_main_products":
        case "facts":
          return "call_section_main";
        case "news":
          return "call_section_news";
        case "services":
          return "call_section_services";
        case "examples":
          return "call_section_examples";
        case "solutions":
          return "call_section_solutions";
        case "productionCategories":
          return "call_section_production_categories";
        case "production":
          return "call_section_production";
        case "staticPages":
          return "call_section_static_pages";
      }
      return false;
    },
    promoSectionFields() {
      switch (this.module) {
        case "sliders":
        case "benefits":
        case "mainpageFooterFacts":
        case "blocks_of_main_products":
        case "facts":
          return "promo_section_main";
      }
      return false;
    },
    footerSectionFields() {
      switch (this.module) {
        case "sliders":
        case "benefits":
        case "mainpageFooterFacts":
        case "blocks_of_main_products":
        case "facts":
          return "footer_section_main";
      }
      return false;
    },
    seoSectionBlock() {
      if (this.blocks.length && this.seoSectionFields) {
        return _.find(this.blocks, ["UniqueName", this.seoSectionFields]);
      }
      return false;
    },
    welcomeSectionBlock() {
      if (this.blocks.length && this.welcomeSectionFields) {
        return _.find(this.blocks, ["UniqueName", this.welcomeSectionFields]);
      }
      return false;
    },
    callSectionBlock() {
      if (this.blocks.length && this.callSectionFields) {
        return _.find(this.blocks, ["UniqueName", this.callSectionFields]);
      }
      return false;
    },
    promoSectionBlock() {
      if (this.blocks.length && this.promoSectionFields) {
        return _.find(this.blocks, ["UniqueName", this.promoSectionFields]);
      }
      return false;
    },
    footerSectionBlock() {
      if (this.blocks.length && this.footerSectionFields) {
        return _.find(this.blocks, ["UniqueName", this.footerSectionFields]);
      }
      return false;
    },
    ...mapState("site", [
      "settings",
      "workTypeItems",
      "blocks",
      "blogCategoriesItems",
    ]),
  },
  methods: {
    save() {
      this[c.SAVE_SETTINGS](this.settings);
    },
    addWorkType() {
      this[c.WORK_TYPES_ACTION]({
        action: "create",
        data: {
          Name: this.workTypeName,
        },
      });
      this.workTypeName = "";
    },
    addBlogCategory() {
      this[c.BLOG_CAT_ACTION]({
        action: "create",
        data: {
          CategoryName: this.blogCategoryName,
        },
      });
      this.blogCategoryName = "";
    },
    removeWorkTypeItem(id) {
      this[c.WORK_TYPES_ACTION]({ action: "delete", data: { Id: id } });
    },
    removeBlogCategoryItem(id) {
      this[c.BLOG_CAT_ACTION]({ action: "delete", data: { Id: id } });
    },
    editWorkType(event, item) {
      const newName = event.target.value;
      if (newName !== item.Name) {
        this[c.WORK_TYPES_ACTION]({
          action: "edit",
          data: {
            Name: newName,
            Id: item.Id,
          },
        });
      }
    },
    editBlogCategory(event, item) {
      const newName = event.target.value;
      if (item.CategoryName === null) item.CategoryName = "";
      console.log(newName != item.CategoryName);
      console.log(newName);
      console.log(item.CategoryName);
      if (newName != item.CategoryName) {
        this[c.BLOG_CAT_ACTION]({
          action: "edit",
          data: {
            CategoryName: newName,
            Id: item.Id,
          },
        });
      }
    },
    saveBlockSection(block) {
      this.$emit("setPreloader", true);

      const formData = new FormData();

      formData.append("_method", "PATCH");

      _.each(block, (value, key) => {
        if (value === null) value = "";
        formData.append(key, value);
      });
      Api.edit("blocks", block.Id, formData)
        .then(() => {
          showAlert("success", "Успешно");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
      translatedFieldsRefs.forEach((i) => {
        if (this.$refs["translated-field-" + i]) {
          this.$refs["translated-field-" + i].save();
        }
      });
    },
    saveTranslatedTypes() {
      this.$refs["translated-field-WorkType"].forEach((i) => {
        i.save();
      });
    },
    saveTranslatedBlogCategories() {
      this.$refs["translated-field-BlogCategory"].forEach((i) => {
        i.save();
      });
    },
    getTranslation(moduleName, id) {
      getTranslation(this, moduleName, id);
    },
    ...mapActions("site", [
      c.SAVE_SETTINGS,
      c.WORK_TYPES_ACTION,
      c.BLOG_CAT_ACTION,
    ]),
    ...mapMutations("site", [
      c.CHANGE_WORK_TYPES_ARRAY,
      c.CHANGE_BLOCKS_ARRAY,
      c.CHANGE_BLOG_CAT_ARRAY,
    ]),
  },
  components: {
    translationTabs,
    translateField,
  },
};
</script>

<style scoped></style>
