<template>
  <div>
    <div class="header">
      <div class="header__col">
        <div
          class="tm-sidebar-open-btn inline"
          @click="parentData.sidebarIsOpen = true"
          v-if="!parentData.sidebarIsOpen"
        >
          <svg width="20" height="14">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#sidebar-menu-icon"
            ></use>
          </svg>
        </div>
        <p class="tm-header-title">
          <template> {{ activeTabName }}: {{ count[activeTab] }} </template>
        </p>
      </div>
    </div>
    <div class="actions-header">
      <div class="actions-header__col">
        <span
          v-if="
            commonSettings.selectedItems.length &&
            checkUserRight(RESPONSE_CHANGE_RIGHT)
          "
          style="margin-top: 9px; margin-left: 5px"
        >
          Смена ответственного:
        </span>
        <a
          class="button tm-btn tm-btn--add"
          href="#"
          v-if="
            !commonSettings.selectedItems.length &&
            checkUserRight(CREATE_REQUEST_RIGHT)
          "
          @click="
            togglePopup({ module: 'rps', popup: 'newRequest', value: true })
          "
        >
          &plus;
        </a>
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <div class="search-bar search-bar_white actions-header__search-bar">
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            class="search-bar__input"
            type="search"
            v-model="search"
            @keyup="setSearchInput"
            placeholder="Введите название заявки"
          />

          <div
            class="form-filter__btn"
            :class="filterBtnClassName"
            @click="filterOpen = !filterOpen"
          >
            фильтр
          </div>
          <rps-support-filter
            v-if="filterOpen"
            v-click-outside="closeFilterWindow"
            @closeFilterWindow="closeFilterWindow"
            ref="support-filter"
          />
        </div>
      </div>
      <div class="actions-header__col" @click="toggleAnalyticsPopup">
        <span class="btn-pointer">
          <span class="icon-button__container">
            <img src="@/assets/images/bar-chart.svg" alt="analytics" />
          </span>
        </span>
      </div>
      <div class="actions-header__col" @click="toggleTagsPopup">
        <span class="btn-pointer">
          <span class="icon-button__container">
            <img src="@/assets/images/label.svg" alt="tags" />
          </span>
        </span>
      </div>

      <!--      TODO это селектор тэгов, отсюда можно взять логику позже для RpsSupportFilter-->
      <!--      <div class="actions-header__col">-->
      <!--        <tags-select-->
      <!--          :initialTags="initialTags"-->
      <!--          :tagsList="tagsFilterArray"-->
      <!--          :asFilter="true"-->
      <!--          @deleteTagFromList="setTagFromList"-->
      <!--          @setTagFromList="setTagFromList"-->
      <!--        />-->
      <!--      </div>-->

      <!--      TODO это прошлый селектор ответственного, отсюда можно взять логику позже для RpsSupportFilter-->
      <!--      <div-->
      <!--        class="actions-header__col"-->
      <!--        v-if="!commonSettings.selectedItems.length"-->
      <!--      >-->
      <!--        <div class="select">-->
      <!--          <select-->
      <!--            class="select__control"-->
      <!--            @change="filterRequestsByManager($event)"-->
      <!--            v-model="commonSettings.managerForFilter"-->
      <!--          >-->
      <!--            <option :value="null" selected>Все операторы</option>-->
      <!--            <option :value="' '">Без ответственного</option>-->
      <!--            <option-->
      <!--              :value="item.Id"-->
      <!--              v-for="(item, index) in personsForManage"-->
      <!--              :key="index"-->
      <!--            >-->
      <!--              {{ item.FullName }}-->
      <!--            </option>-->
      <!--          </select>-->
      <!--          <div class="select__button">-->
      <!--            <svg class="select__caret">-->
      <!--              <use-->
      <!--                xmlns:xlink="http://www.w3.org/1999/xlink"-->
      <!--                xlink:href="/static/images/icon.svg#icon_icon-caret"-->
      <!--              ></use>-->
      <!--            </svg>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      TODO это чекбокс "с задачами", отсюда можно взять логику позже для RpsSupportFilter-->
      <!--      <div class="actions-header__col">-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="icon-button task-checkbox-btn"-->
      <!--          @click.prevent="-->
      <!--            commonSettings.showWithTasks = !commonSettings.showWithTasks-->
      <!--          "-->
      <!--        >-->
      <!--          <label-->
      <!--            class="checkbox checkbox_white table__checkbox js-index-checkbox"-->
      <!--          >-->
      <!--            <input-->
      <!--              class="checkbox__input main_checkbox_input"-->
      <!--              type="checkbox"-->
      <!--              :class="{ 'checkbox__box&#45;&#45;active': commonSettings.showWithTasks }"-->
      <!--            />-->
      <!--            <span class="checkbox__box main_checkbox_span">-->
      <!--              <svg class="checkbox__icon">-->
      <!--                <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>-->
      <!--              </svg>-->
      <!--            </span>-->
      <!--          </label>-->
      <!--          С задачами-->
      <!--        </a>-->
      <!--      </div>-->
    </div>

    <rps-analytics-window
      v-if="!preloaders.list && analyticsOpen"
      @close="toggleAnalyticsPopup"
    />
    <rps-tags-settings
      v-show="tagsOpen"
      :initial-tags="commonSettings.tags"
      @close="handleTagsSettinsClose"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import * as supportTypes from "@/store/support/constants";
import * as commonTypes from "@/store/common/constants";
import { showAlert } from "../../../../utils";
import { Helpers } from "@/mixins/Helpers";
import * as _ from "lodash";
import RpsSupportFilter from "../../../support/components/rps/RpsSupportFilter";
import RpsTagsSettings from "../../../support/components/rps/tasks-module/RpsTagsSettings";
import RpsAnalyticsWindow from "../../../support/components/rps/RpsAnalyticsWindow";
import Api from "../../../../api/support/rps";

// checkUserRight
export default {
  mixins: [Helpers],
  data() {
    return {
      search: "",
      initialTags: { array: [] },
      RESPONSE_CHANGE_RIGHT: supportTypes.RESPONSE_CHANGE_RIGHT,
      CREATE_REQUEST_RIGHT: supportTypes.CREATE_REQUEST_RIGHT,
      filterOpen: false,
      tagsOpen: false,
      analyticsOpen: false,
    };
  },
  computed: {
    ...mapState("rpsSupport", [
      "preloaders",
    ]),
    tagsFilterArray() {
      const tags = this.initialTags.array;
      let preparedTags = [];
      if (tags && tags.length) {
        _.each(tags, (i) => {
          const foundTag = _.find(this.commonSettings.tags, ["Id", i.Id]);
          if (foundTag) {
            foundTag.requestId = i.Id;
            preparedTags.push(foundTag);
          }
        });
        return preparedTags;
      } else {
        return [];
      }
    },
    filterBtnClassName() {
      if (this.filterOpen) {
        return " form-filter__btn--active";
      }
      return "";
    },
    activeTabName() {
      switch (this.activeTab) {
        case "open":
          return "Активные";
        case "closed":
          return "Закрытые";
      }
      return "Заявки";
    },
    ...mapState("rpsSupport", [
      "count",
      "activeTabs",
      "searchInput",
      "commonSettings",
      "personsForManage",
    ]),
    ...mapGetters("rpsSupport", ["activeTab"]),
  },
  methods: {
    deleteTagFromList(tag) {
      this.tagsFilterArray = _.reject(this.tagsFilterArray, ["Id", tag.Id]);
    },
    closeFilterWindow() {
      this.filterOpen = false;
    },
    ...mapActions("rpsSupport", [
      supportTypes.SET_ACTIVE_TAB,
      supportTypes.GET_REQUESTS,
      supportTypes.SET_SEARCH_INPUT,
      supportTypes.CHANGE_MANAGER,
    ]),
    ...mapActions("common", { togglePopup: commonTypes.TOGGLE_POPUP_LOCAL }),

    setActiveTab(tab) {
      this.commonSettings.cancelRequest = true;
      this[supportTypes.GET_REQUESTS]({
        scope: tab,
        start: false,
        force: true,
      });
      this[supportTypes.SET_ACTIVE_TAB](tab);
    },
    activeClass(tab) {
      return this.activeTabs[tab] ? "active" : "";
    },
    setSearchInput() {
      this[supportTypes.SET_SEARCH_INPUT](this.search);
    },
    changeRequestsManager() {
      const managerId = this.$refs.changeManagerSelect.value;
      if (managerId === "none") {
        showAlert("error", "Выберите менеджера");
      } else {
        const formData = new FormData();
        formData.append("managerId", managerId);
        this.commonSettings.selectedItems.forEach((id) => {
          formData.append("requestId[]", id);
        });
        this[supportTypes.CHANGE_MANAGER](formData);
        this.$refs.changeManagerSelect.value = "none";
      }
    },
    toggleTagsPopup() {
      this.tagsOpen = !this.tagsOpen;
    },
    toggleAnalyticsPopup() {
      this.analyticsOpen = !this.analyticsOpen;
    },
    handleTagsSettinsClose() {
      this.toggleTagsPopup();

      // update tags in common settings
      Api.getTagsList().then((res) => {
        if (res.data && res.data.length) {
          this.commonSettings.tags = res.data;
        }
      });
    },
  },
  watch: {
    tagsFilterArray(arr) {
      this.commonSettings.tagsForSearch = arr;
    },
  },
  components: { RpsAnalyticsWindow, RpsTagsSettings, RpsSupportFilter },
  props: ["parentData"],
};
</script>
