<template>
  <div
    id="edit-card-data-popup"
    class="popup event-popup event-popup-multichange"
    :class="is_open ? 'popup--active' : ''"
  >
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Изменение данных объектов</h2>
          <div class="popup__close" @click="onCloseChangePopup">
            <svg class="popup__close-icon">
              <use xlink:href="/static/images/icon.svg#icon_icon-close"></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="edit-card-data-popup">
            <div class="actions-header">
              <div class="actions-header__col">
                <a
                  class="button button_green button_uppercase"
                  @click="changeAllOptions()"
                  >Выполнить изменения</a
                >
              </div>
            </div>
            <div class="edit-card-data-popup__content">
              <table class="default-table">
                <thead
                  class="default-table__header edit-card-data-popup__header"
                >
                  <tr class="default-table__row">
                    <td
                      class="default-table__cell default-table__cell_border_bottom edit-card-data-popup__cell"
                    >
                      Параметр
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom edit-card-data-popup__cell"
                      colspan="2"
                    >
                      До изменения
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom edit-card-data-popup__cell"
                      colspan="2"
                    >
                      После изменения
                    </td>
                  </tr>
                </thead>
                <tbody class="default-table__body">
                  <tr
                    class="default-table__row edit-card-data-popup__row"
                    v-for="(oneRow, index) in addRowsArray"
                    :key="index"
                  >
                    <td
                      class="default-table__cell default-table__cell_border_bottom edit-card-data-popup__cell"
                    >
                      <div
                        class="select select_size_small js-edit-card-data-popup__parent-select"
                      >
                        <select
                          class="select__control"
                          v-model="oneRow.selected"
                        >
                          <option value="">Выберите параметр</option>
                          <option
                            :value="oneParam.id"
                            v-for="(oneParam, index) in getFilteredParamForRow(
                              oneRow
                            )"
                            :key="index"
                          >
                            {{ oneParam.name }}
                          </option>
                        </select>
                        <div class="select__button">
                          <svg class="select__caret">
                            <use
                              xlink:href="/static/images/icon.svg#icon_icon-caret"
                            ></use>
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom"
                    >
                      <div
                        v-for="(oneParam, index) in filterArray(oneRow.options)"
                        :key="index"
                      >
                        <div class="select select_size_small">
                          <select
                            class="select__control"
                            v-model="oneRow.value"
                            v-if="oneParam.id === 'ServiceType'"
                          >
                            <option value="">Любой</option>
                            <option value="0">Ничего</option>
                            <option value="1">Техническая поддержка</option>
                            <option value="2">Техническое обслуживание</option>
                            <option value="3">Удалённая эксплуатация</option>
                            <option value="4">Эксплуатация</option>
                            <option value="5">ПостПНР</option>
                            <option value="6">ПНР</option>
                          </select>
                          <div class="select__button">
                            <svg class="select__caret">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-caret"
                              ></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom"
                    >
                      <svg class="edit-card-data-popup__arrow">
                        <use
                          xlink:href="/static/images/icon.svg#icon_icon-arrow"
                        ></use>
                      </svg>
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom"
                    >
                      <div
                        v-for="(oneParam, index) in filterArray(oneRow.options)"
                        :key="index"
                      >
                        <div class="select select_size_small">
                          <select
                            class="select__control"
                            v-model="oneRow.newValue"
                            v-if="oneParam.id === 'ServiceType'"
                          >
                            <option value="0">Ничего</option>
                            <option value="1">Техническая поддержка</option>
                            <option value="2">Техническое обслуживание</option>
                            <option value="3">Удалённая эксплуатация</option>
                            <option value="4">Эксплуатация</option>
                            <option value="5">ПостПНР</option>
                            <option value="6">ПНР</option>
                          </select>

                          <div class="select__button">
                            <svg class="select__caret">
                              <use
                                xlink:href="/static/images/icon.svg#icon_icon-caret"
                              ></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(oneParam, index) in filterArray(oneRow.options)"
                        :key="index"
                      >
                        <div class="field" style="display: inherit">
                          <span
                            class="field__content mifare-card-info__field"
                            style="width: 160px"
                          >
                            <input
                              class="input"
                              v-model="oneRow.newValue"
                              type="text"
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        v-for="(oneParam, index) in filterArray(oneRow.options)"
                        :key="index"
                        style="display: flex"
                      >
                        <date-picker
                          valueType="format"
                          input-class="input"
                          :format="'YYYY-MM-DD'"
                          lang="ru"
                          v-model="oneRow.newValue"
                        ></date-picker>
                      </div>
                    </td>
                    <td
                      class="default-table__cell default-table__cell_border_bottom edit-card-data-popup__cell"
                    >
                      <a
                        class="icon-button icon-button_shadow icon-button_size_small js-edit-card-data-popup-remove"
                        href="#"
                      >
                        <span
                          class="icon-button__container"
                          @click.prevent="removeRowByIndex(index)"
                        >
                          <svg class="icon-button__icon">
                            <use
                              xlink:href="/static/images/icon.svg#icon_icon-trash"
                            ></use>
                          </svg>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <a
                class="edit-card-data-popup__add js-edit-card-data-popup__add-row"
                @click.prevent="addNewRow()"
              >
                + Добавить изменение
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import moment from "moment";
import Api from "../../../api/clients";
import { mapState } from "vuex";
import { showAlert } from "../../../utils";
import * as _ from "lodash";

export default {
  props: {
    is_open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addRowsArray: [],
      defaultOptions: [
        { id: "ServiceDateStart", name: "Дата заключения", value: null },
        { id: "ServiceDateValidTo", name: "Действителен до", value: null },
        { id: "ServiceType", name: "Тип", value: null, value2: null },
        {
          id: "FreeServiceDate",
          name: "Бесплатное обслуживание до",
          value: null,
          value2: null,
        },
        { id: "ExpireSet", name: "Лицензия активна до", value: null },
      ],
      enabledOldOptions: ["ServiceType"],
      enabledInputOptions: [],
      enabledDateOptions: [
        "ExpireSet",
        "ServiceDateValidTo",
        "ServiceDateStart",
        "FreeServiceDate",
      ],
      optionsDataLoaded: false,
      BlockedOptions: [
        { id: 0, value: "Активна" },
        { id: 1, value: "Заблокирована" },
      ],
    };
  },
  methods: {
    onCloseChangePopup() {
      this.$emit("onCloseChangePopup");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onCloseChangePopup();
      }
    },
    addNewRow() {
      this.addRowsArray.push({
        options: this.defaultOptions,
        selected: null,
        value: "",
        newValue: null,
        newValue2: null,
        showModel: false,
        showModel2: false,
      });
      this.loadOptionsData();
    },
    validateDate(input, field, row) {
      const date = input.target.value;
      if (moment(date, "DD-MM-YYYY").isValid()) {
        row[field] = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        alert("Дата указана неверно");
      }
    },
    validateTime(input, field, row) {
      const time = input.target.value;
      if (moment("2014-12-13 " + time).isValid()) {
        row[field] = time;
      } else {
        alert("Время указана неверно");
      }
    },
    changeAllOptions() {
      let vm = this;
      Api.editMultipleObjects({
        objects: vm.selectedItems,
        options: vm.addRowsArray,
      })
        .then((res) => {
          if (res.data.status === "success") {
            showAlert("success", "Обновлено строк: " + res.data.count);
          } else {
            showAlert("error", "Ошибка", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        });
    },
    loadOptionsData() {
      if (!this.optionsDataLoaded) {
        // ...
        this.optionsDataLoaded = true;
      }
    },
    getFilteredParamForRow(row) {
      let vm = this;
      let filteredParamsData = [];
      let selectedIds = [];
      vm.addRowsArray.forEach(function (oneRow) {
        if (oneRow.selected) {
          selectedIds.push(oneRow.selected);
        }
      });
      vm.defaultOptions.forEach(function (oneOption) {
        if (
          !selectedIds.includes(oneOption.id) ||
          oneOption.id === row.selected
        ) {
          filteredParamsData.push(oneOption);
        }
      });
      return filteredParamsData;
    },
    checkIfRowSelect(oneRow, oneParam) {
      return (
        oneRow.selected === oneParam.id &&
        this.enabledOldOptions.includes(oneParam.id)
      );
    },
    checkIfRowInput(oneRow, oneParam) {
      return (
        oneRow.selected === oneParam.id &&
        this.enabledInputOptions.includes(oneParam.id)
      );
    },
    checkIfRowDateTime(oneRow, oneParam) {
      if (
        oneRow.selected === oneParam.id &&
        this.enabledDateOptions.includes(oneParam.id)
      ) {
        return true;
      }
      return false;
    },
    removeRowByIndex(indexRow) {
      this.addRowsArray.splice(indexRow, 1);
    },
    filterArray(arr) {
      return _.filter(arr, (oneRow, oneParam) => {
        return this.checkIfRowSelect(oneRow, oneParam);
      });
    },
  },
  computed: {
    ...mapState("clients", ["selectedItems"]),
  },
  directives: { mask },
  filters: {
    formatDateOutput: function (date) {
      if (!moment(date).isValid()) {
        return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
      }
      return moment(date).format("DD-MM-YYYY");
    },
  },
  // components: { DatePicker }
};
</script>

<style scoped></style>
