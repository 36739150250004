<template>
  <div
    class="table-row table-row_draggable table__row--col"
    :class="{
      'task-table-row--open': isOpen && item.childs.length,
      'table-row--task-closed': item.Status === '2',
      'task-table-row--loading': item.VirtualId,
    }"
  >
    <div class="table__row table__row_main" :data-task-id="item.Id">
      <div
        class="task-table-row-inner-indicator"
        v-for="c in new Array(level)"
        :key="c"
      ></div>
      <div class="table__col_label">
        <label
          class="checkbox checkbox_white table__checkbox js-index-checkbox task-row-count-checkbox"
        >
          <div class="task-row-count">{{ index + 1 }}</div>
        </label>
      </div>
      <div class="table__col table__col_name table__col_flexible">
        <div class="task-row-first">
          <div class="task-row-link">
            <div
              class="table-c__arrow"
              v-if="item.childs && item.childs.length"
              @click.stop="toggleTableRowOpen"
            >
              <svg width="12" height="7">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
                ></use>
              </svg>
            </div>
            <div class="table-c__arrow" style="opacity: 0.2" v-else>
              <svg width="12" height="7">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
                ></use>
              </svg>
            </div>
            <label class="table-c__name-label">
              <p class="table-c__name">
                {{ item.TaskName }}
              </p>
            </label>
            <div class="table-c__name-info">
              <div
                class="task-deep-info"
                v-if="item.childs && item.childs.length"
              >
                <i class="deep-icon"></i>{{ item.childs.length }}
              </div>
            </div>
            <div
              v-if="assignType === 'create'"
              class="table-c__name-select"
              @click.stop="$emit('onParentTaskSelect', item)"
            >
              Выбрать
            </div>
            <div
              v-else-if="assignType === 'select'"
              class="table-c__name-select"
              @click="$emit('taskToRequestAssign', item.Id)"
            >
              Прикрепить
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-section-wrapper" v-if="isOpen">
      <TasksSection
        :className="'task-table-row-inner'"
        v-for="(section, index) in computedSections"
        :key="'section' + section.Id"
        :section="section"
        :sectionTasks="section.tasks"
        :sections="computedSections"
        :haveNextSection="index + 1 < computedSections.length"
        :parentTask="item"
        :assignType="assignType"
        :level="level + 1"
        @taskToRequestAssign="handleTaskToRequestAssign"
        @onParentTaskSelect="handleParentSelect"
      />
    </div>
    <div class="row-spinner" v-if="preloader">
      <Spinner
        :noFadeIn="true"
        name="line-scale-pulse-out"
        color="#c9eeff"
        width="50"
        height="35"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../../../../api/tasks";
import * as _ from "lodash";
export default {
  name: "TasksRow",
  components: {
    TasksSection: () =>
      import("@/modules/support/components/rps/tasks-module/TasksSection"),
  },
  props: ["item", "level", "index", "assignType"],
  data() {
    return {
      isOpen: false,
      preloader: false,
      sections: [],
    };
  },
  created() {
    // if (this.isOpen) {
    //   Api.getChildTasksDetail(this.item.Id).then((res) => {
    //     this.item.childs = res.data.childs;
    //   });
    // }
  },
  computed: {
    computedSections() {
      return _.map(this.sections, (i) => {
        if (i.Name === "virtualSection") {
          i.isVirtualSection = true;
          i.tasks = _.filter(this.item.childs, ["SectionId", null]);
        } else {
          i.tasks = _.filter(this.item.childs, ["SectionId", i.Id]);
        }
        return i;
      });
    },
  },
  methods: {
    toggleTableRowOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.getSections();
        Api.getChildTasksDetail(this.item.Id).then((res) => {
          this.item.childs = res.data.childs;
        });
      }
    },
    getSections() {
      this.preloader = true;
      Api.getParentTaskSections(this.item.Id).then((res) => {
        this.sections = res.data.sections;
        this.preloader = false;
      });
    },
    handleParentSelect(data) {
      this.$emit("onParentTaskSelect", data);
    },
    handleTaskToRequestAssign(taskId) {
      this.$emit("taskToRequestAssign", taskId);
    },
  },
};
</script>

<style scoped></style>
