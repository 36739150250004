import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import Vuetify from "vuetify";
import Notifications from "vue-notification";
import VueLocalStorage from "vue-localstorage";
import * as VueGoogleMaps from "vue2-google-maps";
import Geocoder from "@pderas/vue2-geocoder";
import CKEditor from "@ckeditor/ckeditor5-vue";
import TextareaAutosize from "vue-textarea-autosize";
import PortalVue from "portal-vue";
import DateInput from "./modules/tasks/components/CommonComponents/DateInput";
import infiniteScroll from "vue-infinite-scroll";
import Spinner from "vue-spinkit";
import Vuebar from "vuebar";
import SimplePreloader from "@/modules/tasks/components/CommonComponents/SimplePreloader";
import VueResize from "vue-resize";
import "vue-resize/dist/vue-resize.css";

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

Vue.use(Vuebar);
Vue.use(VueResize);
// eslint-disable-next-line no-undef
Vue.component("date-picker", DatePicker);
Vue.component("date-input", DateInput);
Vue.component("Spinner", Spinner);
Vue.component("SimplePreloader", SimplePreloader);
Vue.use(TextareaAutosize);
Vue.use(CKEditor);
Vue.use(PortalVue);
Vue.use(infiniteScroll);

Vue.use(Geocoder, {
  defaultMode: "lat-lng", // or 'lat-lng',
  defaultLanguage: "ru", // e.g. 'en'
  googleMapsApiKey: "AIzaSyDVkOF0RfQfdBub9SPGQUciEErw0BAqoDs",
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDVkOF0RfQfdBub9SPGQUciEErw0BAqoDs",
    libraries: "places",
  },
});
Vue.use(VueLocalStorage);
Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(Notifications);

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Vue.config.productionTip = false;

new Vue({
  router,
  store: new Vuex.Store(store),
  localStorage: {
    objects: {
      type: Object,
      default: null,
    },
  },
  render: (h) => h(App),
}).$mount("#app");
