<template>
  <div class="pdf-list scroll-style">
    <div
      class="pdf-list__item"
      :class="step === 1 ? 'pdf-list__item--active' : ''"
      @click="changeStep(1)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/1.jpg" alt="" />
      </div>
      <div class="pdf-list__num">1</div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(2, 'ContentText')"
      @click="changeStep(2)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/2.jpg" alt="" />
      </div>
      <div class="pdf-list__num">2</div>
      <div v-html="getChangesNumericLabel('ContentText')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="step === 3 ? 'pdf-list__item--active' : ''"
      @click="changeStep(3)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/3.jpg" alt="" />
      </div>
      <div class="pdf-list__num">3</div>
    </div>
    <div
      class="pdf-list__item"
      :class="step === 3.1 ? 'pdf-list__item--active' : ''"
      @click="changeStep(3.1)"
      v-if="!offerObject.json.passPages.tasks_page"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/3.jpg" alt="" />
      </div>
      <div class="pdf-list__num">
        {{ pageNums.TasksAdditionalPage ? "4 - 5" : 4 }}
      </div>
    </div>
    <div
      class="pdf-list__item"
      :class="step === 4 ? 'pdf-list__item--active' : ''"
      @click="changeStep(4)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/4.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Schemes") }}</div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(5, 'OptionsText')"
      @click="changeStep(5)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/5.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ pageNums.Schemes + 1 }}</div>
      <div v-html="getChangesNumericLabel('OptionsText')"></div>
    </div>
    <!--////////////////////////////////////////////-->
    <div
      class="pdf-list__item"
      :class="getNavItemClass(6, 'Entry')"
      @click="changeStep(6)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/6.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Entry") }}</div>
      <div v-html="getChangesNumericLabel('Entry')"></div>
    </div>
    <!--    <div class="pdf-list__item" :class="getNavItemClass(6.1, 'Entry_2')" @click="changeStep(6.1)">-->
    <!--      <div class="pdf-list__img"><img src="@/assets/images/sales/pdf/6.jpg" alt=""></div>-->
    <!--      <div class="pdf-list__num">{{navCountPage('Entry_2')}}</div>-->
    <!--      <div v-html="getChangesNumericLabel('Entry_2')"></div>-->
    <!--    </div>-->

    <!-- for new System Type-->
    <template v-if="offerObject.json.SystemType !== 'Tokensdbl'">
      <div
        class="pdf-list__item"
        :class="getNavItemClass(7, 'Exit')"
        @click="changeStep(7)"
      >
        <div class="pdf-list__img">
          <img src="@/assets/images/sales/pdf/6.jpg" alt="" />
        </div>
        <div class="pdf-list__num">{{ navCountPage("Exit") }}</div>
        <div v-html="getChangesNumericLabel('Exit')"></div>
      </div> </template
    ><template v-else-if="navCountPage('Exit')"></template>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(7.1, 'Exit_2')"
      @click="changeStep(7.1)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/6.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Exit_2") }}</div>
      <div v-html="getChangesNumericLabel('Exit_2')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(8, 'Payment')"
      @click="changeStep(8)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/6.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Payment") }}</div>
      <div v-html="getChangesNumericLabel('Payment')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(9, 'Additional')"
      @click="changeStep(9)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/6.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Additional") }}</div>
      <div v-html="getChangesNumericLabel('Additional')"></div>
    </div>
    <!--    <div class="pdf-list__item" :class="step===9.1 ? 'pdf-list__item&#45;&#45;active' : ''" @click="changeStep(9.1)">-->
    <!--      <div class="pdf-list__img"><img src="@/assets/images/sales/pdf/3.jpg" alt=""></div>-->
    <!--      <div class="pdf-list__num">{{navCountPage('AdditionalTextPage')}}</div>-->
    <!--    </div>-->
    <div
      class="pdf-list__item"
      :class="getNavItemClass(10, 'Total')"
      @click="changeStep(10)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/7.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Total") }}</div>
      <div v-html="getChangesNumericLabel('Total')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(11, 'Tasks')"
      @click="changeStep(11)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/8.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ pageNums.Total + 1 }}</div>
      <div v-html="getChangesNumericLabel('Tasks')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(12, 'AdvantagesOwner')"
      @click="changeStep(12)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/9.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ pageNums.Total + 2 }}</div>
      <div v-html="getChangesNumericLabel('AdvantagesOwner')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(13, 'AdvantagesTenant')"
      @click="changeStep(13)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/10.jpg" alt="" />
      </div>
      <div class="pdf-list__num" v-if="!offerObject.json.passPages.ad_tenant">
        {{ pageNums.Total + 3 }}
      </div>
      <div class="pdf-list__num" v-else>---</div>
      <div v-html="getChangesNumericLabel('AdvantagesTenant')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(14, 'AdvantagesGuest')"
      @click="changeStep(14)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/11.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Advantages") }}</div>
      <div v-html="getChangesNumericLabel('AdvantagesGuest')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(15, 'ServiceDesc')"
      @click="changeStep(15)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/12.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("ServiceDesc") }}</div>
      <div v-html="getChangesNumericLabel('ServiceDesc')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(16, 'ServiceImg')"
      @click="changeStep(16)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/13.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("ServiceImg") }}</div>
      <div v-html="getChangesNumericLabel('ServiceImg')"></div>
    </div>
    <!--    <div class="pdf-list__item" :class="step===17 ? 'pdf-list__item&#45;&#45;active' : ''" @click="changeStep(17)">-->
    <!--      <div class="pdf-list__img"><img src="@/assets/images/sales/pdf/14.jpg" alt=""></div>-->
    <!--      <div class="pdf-list__num">{{navCountPage('Options')}}</div>-->
    <!--    </div>-->
    <div
      class="pdf-list__item"
      :class="getNavItemClass(18, 'DoneWorks')"
      @click="changeStep(18)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/15.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ pageNums.OptionsLastPage + 1 }}</div>
      <div v-html="getChangesNumericLabel('DoneWorks')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="getNavItemClass(19, 'Objects')"
      @click="changeStep(19)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/16.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ navCountPage("Objects") }}</div>
      <div v-html="getChangesNumericLabel('Objects')"></div>
    </div>
    <div
      class="pdf-list__item"
      :class="step === 20 ? 'pdf-list__item--active' : ''"
      @click="changeStep(20)"
    >
      <div class="pdf-list__img">
        <img src="@/assets/images/sales/pdf/17.jpg" alt="" />
      </div>
      <div class="pdf-list__num">{{ pageNums.Objects + 1 }}</div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  props: ["step"],
  methods: {
    getNavItemClass(step, page) {
      let className = this.step === step ? "pdf-list__item--active" : "";
      className = this.getChangesNumericLabel(page)
        ? className + " pdf-list__item--changed"
        : className;
      return className;
    },
  },
  mixins: [SalesHelpers],
};
</script>
