<template>
  <div class="create-folder-list">
    <SimplePreloader v-if="preloader" />
    <div class="create-folder-list__header" @click="$emit('close')">
      <div class="task-settings__list-back-icon">
        <svg width="8" height="14">
          <use xlink:href="/static/images/tasks/sprite.svg#back-icon"></use>
        </svg>
      </div>
      <span>Группа папок</span>
    </div>
    <div class="create-folder-list__search">
      <input
        v-if="!parentData"
        type="text"
        class="input"
        :placeholder="placeholder ? placeholder : 'Поиск'"
        v-model="input"
        @keyup="selected = []"
        ref="input"
      />
      <input
        v-else
        type="text"
        class="input"
        placeholder="Название"
        v-model="parentData.name"
        ref="input"
      />
    </div>
    <div class="create-folder-list__list">
      <div
        class="create-folder-list__item"
        :class="{
          'create-folder-list__item--selected': selected.find(
            (x) => x.Id === item.Id
          ),
        }"
        v-for="item in filteredGroups"
        :key="item.Id"
        @click="$emit('select', item)"
      >
        <div class="create-folder-list__icon">
          <div
            class="task-form-filter__item-color"
            :style="{ background: item.FilterColor }"
            v-if="item.FilterColor"
          ></div>
          <svg
            width="16"
            height="15"
            :style="{
              fill: item.Color ? item.Color : '#FFB72B',
            }"
            v-else
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#folder-icon"
            ></use>
          </svg>
        </div>
        <div class="create-folder-list__name">
          {{ item.GroupName ? item.GroupName : item.FilterName }}
        </div>
      </div>
    </div>
    <div
      class="create-folder-list__btn"
      @click="$emit('createFolder')"
      v-if="!edit"
    >
      Создать
    </div>
    <div class="create-folder-list__btn" @click="$emit('save')" v-else>
      Готово
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FolderGroupsList",
  props: [
    "selected",
    "preloader",
    "edit",
    "initialGroups",
    "outerList",
    "outerListIsFavorites",
    "placeholder",
    "parentData",
  ],
  data() {
    return {
      input: "",
    };
  },
  created() {
    setTimeout(() => {
      this.$refs.input.focus();
    }, 100);
  },
  computed: {
    filteredGroups() {
      const groups = this.groups
        .filter((i) => !i.GlobalGroupName)
        .map((i) => i.ItemInfo);
      let arr = this.outerList
        ? this.outerList
            .filter((i) => !i.GlobalGroupName)
            .map((i) => i.ItemInfo)
        : groups;

      if (this.outerListIsFavorites) {
        arr = [];
        // this.allGroups.forEach((i) => {
        //   if (this.outerList.find((x) => x.ItemId === i.Id)) {
        //     arr.push(i);
        //   }
        // });
        this.favorites.forEach((i) => {
          if (["3", "1"].includes(i.ItemType) && i.ItemInfo)
            arr.push(i.ItemInfo);
        });
        // this.allUserFilters.forEach((i) => {
        //   if (this.outerList.find((x) => x.ItemId === i.Id)) {
        //     arr.push(i);
        //   }
        // });
      }

      // const clearGroups = arr.filter((i) => {
      //   let can = true;
      //   if (
      //     this.initialGroups &&
      //     this.initialGroups.find((ii) => ii.ItemId === i.Id)
      //   ) {
      //     can = true;
      //   }
      //   return can;
      // });
      const childs = this.initialGroups
        ? this.initialGroups.map((i) => i.ItemInfo)
        : [];
      const clearGroups = [...arr, ...childs];

      return clearGroups.filter((i) => {
        const re = new RegExp(this.input, "gi");
        return re.test(i.GroupName || i.FilterName);
      });
    },
    ...mapState("tasks", [
      "groups",
      "globalGroups",
      "standartFilters",
      "userFilters",
      "allGroups",
      "allUserFilters",
      "favorites",
    ]),
  },
  methods: {
    prepare() {
      return null;
    },
  },
};
</script>

<style scoped></style>
