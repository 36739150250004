import axios from "axios";
import { API_ROOT } from "@/config";
import * as _ from "lodash";

export default {
  getRoles() {
    return axios.get(`${API_ROOT}api/tasks/v2/usersRoles`);
  },
  createRole(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/usersRoles`, data);
  },
  editRole(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/usersRoles/${id}`, data);
  },
  deleteRole(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/usersRoles/${id}`);
  },
  addRoleForUser(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksRolesForUser`, data);
  },
  deleteRoleForUser(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksRolesForUser/${id}`);
  },
  getRights() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksRights`);
  },
  createRight(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksRights`, data);
  },
  editRight(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksRights/${id}`, data);
  },
  deleteRight(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksRights/${id}`);
  },
  addRightForRole(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksRightsInRole`, data);
  },
  deleteRightForRole(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksRightsInRole/${id}`);
  },
  getTemplates(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksGroupsTemplates?GroupId=${id}`
    );
  },
  getTemplateTasks(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksGroupsTemplates/${id}`);
  },
  getTemplateTaskDetail(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksGroupsTemplatesItems/${id}`);
  },
  getTemplateTaskChild(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/getChildTasks?ParentTaskId=${id}`
    );
  },
  createTemplateTask(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksGroupsTemplatesItems`,
      data
    );
  },
  deleteTemplateTask(id) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/${id}`
    );
  },
  deleteMultipleTemplateTasks(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/multiDelete`,
      data
    );
  },
  editMultipleTemplateTasks(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/multiEdit`,
      data
    );
  },
  editTemplateTask(id, data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/tasksGroupsTemplatesItems/${id}`,
      data
    );
  },
  createTemplate(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksGroupsTemplates`, data);
  },
  addFilesToTemplateTask(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/addFiles`,
      data
    );
  },
  deleteFilesFromTemplateTask(params) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/TasksGroupsTemplatesItems/DeleteFile?`,
      { params }
    );
  },
  editTemplate(id, data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/tasksGroupsTemplates/${id}`,
      data
    );
  },
  deleteTemplate(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksGroupsTemplates/${id}`);
  },
  createTasksFromTemplate(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasks/createTasksFromTemplate`,
      data
    );
  },
  createManyRootTasks(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasks/createManyRootTasks`,
      data
    );
  },
  getGroups() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksGroups/shortList`);
  },
  getGroupById(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksGroups/${id}`);
  },
  createGroup(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksGroups`, data);
  },
  editGroup(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksGroups/${id}`, data);
  },
  deleteGroup(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksGroups/${id}`);
  },
  getGroupUsers(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksGroupsAccess?GroupId=${id}`);
  },
  addUserToGroup(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksGroupsAccess`, data);
  },
  deleteUserToGroup(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksGroupsAccess/${id}`);
  },
  addTemplateToGroup(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/TasksTemplatesToGroups`, data);
  },
  deleteTemplateToGroup(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/TasksTemplatesToGroups/${id}`);
  },
  getUsers() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasks/listUsers`);
  },
  getCurrentUserSettigs() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksUsersSettings/me`);
  },
  getObjects() {
    return axios.get(`${API_ROOT}api/clients/Object`);
  },
  getTasks(params) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasks`, {
      params,
    });
  },
  createTask(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasks/CreateTasks`, data);
  },
  changeTaskGroup(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasks/ChangeTaskGroup`, data);
  },
  editTask(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasks/${id}`, data);
  },
  deleteTask(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasks/${id}`);
  },
  restoreTask(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasks/restore?id=${id}`);
  },
  showTask(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasks/${id}`);
  },
  createChecklist(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksChecklists`, data);
  },
  editChecklist(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksChecklists/${id}`, data);
  },
  deleteChecklist(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksChecklists/${id}`);
  },
  createChecklistItem(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksChecklistsItems`, data);
  },
  editChecklistItem(id, data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/tasksChecklistsItems/${id}`,
      data
    );
  },
  deleteChecklistItem(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksChecklistsItems/${id}`);
  },
  addFilesToTask(state, data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksFiles`, data, {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted !== 100) {
          state.commit("SET_PROGRESS_BAR_BY_FILES_UPLOAD", percentCompleted);
        }
      },
    });
  },
  addLocalFilesToTask(state, data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksFiles/UploadLocalFile`,
      data
    );
  },
  addDocFilesToTask(state, data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksFiles/uploadSignDocs`,
      data,
      {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted !== 100) {
            state.commit("SET_PROGRESS_BAR_BY_FILES_UPLOAD", percentCompleted);
          }
        },
      }
    );
  },
  deleteFilesFromTask(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksFiles/${id}`);
  },
  createComment(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksComments/`, data);
  },
  editComment(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksComments/${id}`, data);
  },
  deleteComment(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksComments/${id}`);
  },
  createTaskRequestRelation(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksRequestsToTasks`, data);
  },
  addFileToFavorite(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksFilesFavorites`, data);
  },
  removeFileFromFavorite(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksFilesFavorites/${id}`);
  },
  createContact(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksContacts/`, data);
  },
  editContact(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksContacts/${id}`, data);
  },
  deleteContact(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksContacts/${id}`);
  },
  addSubscriber(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksNotifications`, data);
  },
  deleteSubscriber(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksNotifications/${id}`);
  },
  getTagGroups() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksTagsGroups/`);
  },
  createTagGroup(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksTagsGroups`, data);
  },
  patchTagGroup(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksTagsGroups/${id}`, data);
  },
  deleteTagGroup(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksTagsGroups/${id}`);
  },
  getTags() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksTags/`);
  },
  createTag(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksTags/`, data);
  },
  editTag(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksTags/${id}`, data);
  },
  deleteTag(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksTags/${id}`);
  },
  addTagToTask(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksTags/addTagToTask`, data);
  },
  removeTagFromTask(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksTags/removeTagFromTask`,
      data
    );
  },
  addTagToGroup(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksTagsGroups/addTagToGroup`,
      data
    );
  },
  removeTagFromGroup(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksTagsGroups/removeTagFromGroup`,
      data
    );
  },
  getNotifications(params = {}) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications?NotificationType=1&Limit=50`,
      { params }
    );
  },
  getGroupedNotifications(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/groupedNotifications?NotificationType=1&Limit=50`,
      { params }
    );
  },
  getNotificationsFromOneTask(taskId) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/detailsByOneTask?TaskId=${taskId}&NotificationType=1`
    );
  },
  getUnreadNotifications() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/getUnreadNotifications`
    );
  },
  approveChecklistTask(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksChecklistsItems/approveChecklistItem?id=${id}`
    );
  },
  getChildTasksDetail(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasks/ParentDetails`, data);
  },
  getUsersSettings() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksUsersSettings`);
  },
  createChecklistItemNotification(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksChecklistsItemsNotifications`,
      data
    );
  },
  updateChecklistItemNotification(data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/tasksChecklistsItemsNotifications/${data.Id}`,
      data
    );
  },
  deleteChecklistItemNotification(id) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/tasksChecklistsItemsNotifications/${id}`
    );
  },
  addWatcher(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksWatchers`, data);
  },
  deleteWatcher(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksWatchers/${id}`);
  },
  getStandartFilters() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksFilters/getFilterTypes`);
  },
  getTasksByStandartFilter(type, params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksFilters/filterTasks?FilterType=${type}&Limit=50`,
      { params }
    );
  },
  addTagGroupToTaskGroup(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksGroups/addGroupTagsToGroup`,
      data
    );
  },
  addTagToTaskGroup(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksGroups/addTagToGroup`,
      data
    );
  },
  deleteTagFromTaskGroup(id) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/tasksGroups/deleteTagFromGroup?id=${id}`
    );
  },
  deleteTagGroupFromTaskGroup(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksGroups/deleteGroupTagsFromGroup?id=${id}`
    );
  },
  createUserFilter(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksFilters`, data);
  },
  editUserFilter(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksFilters/${id}`, data);
  },
  deleteUserFilter(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksFilters/${id}`);
  },
  getUserFilters() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksFilters`);
  },
  getTasksByUserFilter(filterId, params) {
    params[filterId.length === 32 ? "FilterHash" : "FilterId"] = filterId;
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksFilters/customFilterTasks?Limit=50`,
      { params }
    );
  },
  getTasksByFlashFilter(data, page) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksFilters/customFilterTasks?Limit=50&Page=${page}`,
      data
    );
  },
  getCountUnreadNotifications() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/getCountUnreadNotifications`
    );
  },
  markAllNotificationsRead(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/markAllNotificationsRead`,
      { params }
    );
  },
  markOneNotificationRead(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksNotifications/markOneNotificationRead?NotificationId=` +
        id
    );
  },
  approveTask(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasks/approveRequest?id=` + id);
  },
  getProcesses(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksProcesses?GroupId=` + id);
  },
  createProcess(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksProcesses`, data);
  },
  updateProcess(id, data) {
    return axios.patch(`${API_ROOT}api/tasks/v2/tasksProcesses/${id}`, data);
  },
  deleteProcess(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksProcesses/${id}`);
  },
  attachProcess(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksProcesses/attachProcessToGroup`,
      data
    );
  },
  detachProcess(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksProcesses/detachProcessFromGroup`,
      data
    );
  },
  createStatus(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksProcessesStatuses`, data);
  },
  updateStatus(id, data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/tasksProcessesStatuses/${id}`,
      data
    );
  },
  deleteStatus(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksProcessesStatuses/${id}`);
  },
  changeStatusesOrder(data) {
    const formData = new FormData();
    _.each(data, (i) => {
      formData.append("Ids[]", i.Id);
    });
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksProcessesStatuses/updateOrder`,
      formData
    );
  },
  getUserFavorites() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksFavorites`);
  },
  addToFavorites(data) {
    return axios.post(`${API_ROOT}api/tasks/v2/tasksFavorites`, data);
  },
  removeFromFavorites(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/tasksFavorites/${id}`);
  },
  changeFavoritesOrder(data) {
    const formData = new FormData();
    console.log(data);

    _.each(data, (i) => {
      formData.append("Ids[]", i.Id);
    });
    return axios.post(
      `${API_ROOT}api/tasks/v2/tasksFavorites/updateOrder`,
      formData
    );
  },
  changeOrder(items, apiMethod) {
    const formData = new FormData();
    _.each(items, (i) => {
      formData.append("Ids[]", i.Id);
    });
    return axios.post(`${API_ROOT}${apiMethod}`, formData);
  },
  updateUserProfile(data) {
    return axios.put(
      API_ROOT + "api/tasks/v2/tasksUsersSettings/updateUserProfile",
      data
    );
  },
  saveUserAvatar(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/TasksUsersSettings/uploadAvatar",
      data
    );
  },
  copyTask(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasks/CopyTask", data);
  },
  getTasksNewNotificationsByArray(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasks/GetTasksNewNotifications",
      data
    );
  },
  getUserRights() {
    return axios.get(`${API_ROOT}api/tasks/v2/UsersRoles/GetUserRightsInRoles`);
  },
  getCustomFilterParams() {
    return axios.get(`${API_ROOT}api/tasks/v2/tasksFilters/CustomFilterParams`);
  },
  createAutomationTemplate(data) {
    return axios.post(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsTemplates`,
      data
    );
  },
  getAutomationTemplates() {
    return axios.get(`${API_ROOT}api/tasks/v2/TasksAutomatizationsTemplates`);
  },
  deleteAutomationTemplate(id) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsTemplates/${id}`
    );
  },
  updateAutomationTemplate(data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsTemplates/${data.Id}`,
      data
    );
  },
  getAutomationObjectsTypes() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsObjectsTypes`
    );
  },
  getAutomationActions() {
    return axios.get(`${API_ROOT}api/tasks/v2/TasksAutomatizationsActions`);
  },
  getAutomationActionsOptions() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsOptions`
    );
  },
  getAutomationActionsObjects() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjects`
    );
  },
  getAutomationActionsObjectsTypes() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjectsTypes`
    );
  },
  getAutomationActionsObjectsOptions() {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjectsOptions`
    );
  },
  getAutomationActionsOptionsFilterActions(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsOptions/FilterActions`,
      { params }
    );
  },
  getAutomationActionsOptionsFilterTemplates(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsOptions/FilterTemplates`,
      { params }
    );
  },
  getAutomationActionsObjectsOptionsFilterActions(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjectsOptions/FilterActions`,
      { params }
    );
  },
  getAutomationActionsObjectsOptionsFilterTemplates(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjectsOptions/FilterTemplates`,
      { params }
    );
  },
  getAutomationActionsOptionsFilterObjects(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsOptions/FilterObjects`,
      { params }
    );
  },
  getAutomationActionsObjectsOptionsFilterObjects(params) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsActionsObjectsOptions/FilterObjects`,
      { params }
    );
  },
  attachAutomationToTask(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/TasksAutomatizationsToTasks",
      data
    );
  },
  getTaskAutomation(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsToTasks/AutomatizationsForTask?TaskId=${id}`
    );
  },
  updateTaskAutomation(data) {
    return axios.patch(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsToTasks/${data.Id}`,
      data
    );
  },
  deleteTaskAutomation(id) {
    return axios.delete(
      `${API_ROOT}api/tasks/v2/TasksAutomatizationsToTasks/${id}`
    );
  },
  dropChildTasksStatuses(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksProcessesStatuses/DropChildTasksStatuses?TaskId=${id}`
    );
  },
  dropStatusesForGroup(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/TasksProcessesStatuses/DropStatusesForGroup?GroupId=${id}`
    );
  },
  checkTaskHaveChildTasks(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/Tasks/CheckTaskHaveChildTasks?TaskId=${id}`
    );
  },
  createSection(data) {
    return axios.post(API_ROOT + "api/tasks/v2/TasksSections", data);
  },
  editSection(id, data) {
    return axios.patch(API_ROOT + "api/tasks/v2/TasksSections/" + id, data);
  },
  deleteSection(id) {
    return axios.delete(`${API_ROOT}api/tasks/v2/TasksSections/${id}`);
  },
  getSections(id) {
    return axios.get(`${API_ROOT}api/tasks/v2/TasksSections?GroupId=${id}`);
  },
  getParentTaskSections(id) {
    return axios.get(
      `${API_ROOT}api/tasks/v2/tasksSections/GetSectionForParentTask?ParentTaskId=${id}`
    );
  },
  getGlobalGroups() {
    return axios.get(API_ROOT + "api/tasks/v2/tasksGlobalGroups");
  },
  createGlobalGroup(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasksGlobalGroups", data);
  },
  updateGlobalGroup(id, data) {
    return axios.patch(API_ROOT + "api/tasks/v2/tasksGlobalGroups/" + id, data);
  },
  deleteGlobalGroup(id) {
    return axios.delete(API_ROOT + "api/tasks/v2/tasksGlobalGroups/" + id);
  },
  getGlobalGroupElements(id) {
    return axios.get(API_ROOT + "api/tasks/v2/tasksGlobalGroups/" + id);
  },
  addElementToGlobalGroup(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasksGlobalGroupsItems", data);
  },
  changeGlobalGroupElementsOrder(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasksGlobalGroupsItems/updateOrder",
      data
    );
  },
  removeElementFromGlobalGroup(id) {
    return axios.delete(API_ROOT + "api/tasks/v2/tasksGlobalGroupsItems/" + id);
  },
  changeGlobalGroupOrders(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/userTasksList/updateGroupsOrder",
      data
    );
  },
  changeGlobalGroupFiltersOrders(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/userTasksList/updateFiltersOrder",
      data
    );
  },
  getUserTasksList() {
    return axios.get(API_ROOT + "api/tasks/v2/userTasksList/");
  },
  getCommentsForParentComment(id) {
    return axios.get(
      API_ROOT +
        "api/tasks/tasksComments/getCommentsForParentComment?CommentId=" +
        id +
        "&Page=0&Limit=300/"
    );
  },
  changeTaskSections(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasks/ChangeTasksSections",
      data
    );
  },
  downloadFile(id) {
    return axios.get(
      API_ROOT + "api/tasks/v2/tasksFiles/DownloadFile?FileId=" + id
    );
  },
  pinComment(data) {
    return axios.post(API_ROOT + "api/tasks/v2/TasksCommentsPinned", data);
  },
  unpinComment(id) {
    return axios.delete(API_ROOT + "api/tasks/v2/TasksCommentsPinned/" + id);
  },
  markCommentAsImportant(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasksCommentsUsersImportant",
      data
    );
  },
  unmarkCommentAsImportant(id) {
    return axios.delete(
      API_ROOT + "api/tasks/v2/tasksCommentsUsersImportant/" + id
    );
  },
  setCommentVisible(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasksCommentsUserVisible", data);
  },
  setCommentUnvisible(id) {
    return axios.delete(
      API_ROOT + "api/tasks/v2/tasksCommentsUserVisible/" + id
    );
  },
  getCommentsForTask({ taskId, page, limit }) {
    return axios.get(
      API_ROOT +
        `api/tasks/v2/tasksComments/getCommentsForTask?TaskId=${taskId}&Page=${page}&Limit=${limit}`
    );
    // &Page=0&Limit=1
  },
  copySharedFilter(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasksFilters/copySharedFilter",
      data
    );
  },
  loadMoreStandardTasksInGroup(params) {
    return axios.get(
      API_ROOT + "api/tasks/v2/tasksFilters/loadMoreStandardTasksInGroup",
      { params }
    );
  },
  loadMoreCustomTasksInGroup(params) {
    return axios.get(
      API_ROOT + "api/tasks/v2/tasksFilters/loadMoreCustomTasksInGroup",
      { params }
    );
  },
  getFilesForTask(id) {
    return axios.get(
      API_ROOT + "api/tasks/v2/tasksFiles/getFilesForTask?TaskId=" + id
    );
  },
  setTasksDependencies(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasksDependencies", data);
  },
  editTaskDependency(data) {
    return axios.patch(
      API_ROOT + "api/tasks/v2/tasksDependencies/" + data.Id,
      data
    );
  },
  getDependenciesTasks(id) {
    return axios.get(
      API_ROOT +
        "api/tasks/v2/tasksDependencies/getDependenciesTasks?TaskId=" +
        id
    );
  },
  deleteDependenciesTask(id) {
    return axios.delete(API_ROOT + "api/tasks/v2/tasksDependencies/" + id);
  },
  approveManyRequests(data) {
    return axios.post(
      API_ROOT + "api/tasks/v2/tasks/approveManyRequests",
      data
    );
  },
  getActivityForGroup(params) {
    return axios.get(
      API_ROOT + "api/tasks/v2/tasksGroups/getActivityForGroup",
      {
        params,
      }
    );
  },
  uploadTmpFile(data) {
    return axios.post(API_ROOT + "api/tasks/v2/tasksFiles/uploadTmpFile", data);
  },
  getNotificationsForTask(id) {
    return axios.get(
      API_ROOT +
        "api/tasks/v2/tasksNotifications/getNotificationsForTask?TaskId=" +
        id
    );
  },
};
