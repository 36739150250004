<template>
  <div
    class="task-comments-item"
    :id="'task-comments-item__' + item.Id"
    :class="{
      // 'task-comments-item--answer': parentComment,
      'task-comments-item--notification': item.CommentType !== '1',
      'task-comments-item--pinned-in-answer-popup': isPinnedInAnswerPopup,
      'task-comments-item--hidden': item.Visible === '2',
      'task-comments-item--important': item.Important,
      'task-comments-item--menu-opened': menuOpen || hovered,
    }"
  >
    <template v-if="item.CommentType === '1'">
      <div class="task-comments-item__avatar">
        <UserAvatar :user="item.Author" />
      </div>
      <div class="task-comments-item__content-wrapper">
        <div class="task-comments-item__content">
          <div
            class="activity-item__content__empty-place task-comments-item__empty-place"
          ></div>
          <div
            class="task-comments-item__simple-popup-list simple-popup-list"
            v-if="menuOpen && !item.IsDeleted"
            v-click-outside="closeMenu"
          >
            <div class="simple-popup-list__item" @click="setEditState">
              Изменить
            </div>
            <div
              class="simple-popup-list__item"
              @click="pin(item.Id)"
              v-if="!pinnedRecord"
            >
              Закрепить
            </div>
            <div class="simple-popup-list__item" @click="unpin(item.Id)" v-else>
              Открепить
            </div>
            <div class="simple-popup-list__item" @click="deleteComment">
              Удалить
            </div>
          </div>
          <div class="task-comments-item__top">
            <div class="task-comments-item__left">
              <div class="task-comments-item__name">
                {{
                  item.UserId === userData.Id
                    ? "Вы"
                    : item.AuthorName | shortName
                }}
                <!--              <div-->
                <!--                class="task-comments-item__reply"-->
                <!--                v-if="parentComment"-->
                <!--                @click="$emit('goRoReplyMessage', parentComment)"-->
                <!--                :title="parentComment.TimeCreate | formatReplyDate"-->
                <!--              >-->
                <!--                ответил {{ parentComment.AuthorName | shortName }}-->
                <!--              </div>-->
              </div>
              <div class="task-comments-item__date">
                {{ item.TimeCreate | formatLocalDate }}
              </div>
              <div
                class="task-comments-hidden-info"
                v-if="item.Visible === '2'"
                @mouseenter="hovered = true"
                @mouseleave="hovered = false"
              >
                <div class="task-comments-hidden-info__icon">
                  <svg width="10" height="12">
                    <use
                      xlink:href="/static/images/tasks/sprite.svg#lock-close-icon"
                    ></use>
                  </svg>
                </div>
                <div
                  class="task-comments-hidden-info__tooltip"
                  v-if="allowedToVisibleUsers.length"
                >
                  <div class="task-comments-hidden-info__tooltip-name">
                    Видят только
                  </div>
                  <div
                    class="task-comments-hidden-info__tooltip-row"
                    v-for="item in allowedToVisibleUsers"
                    :key="item.Id"
                  >
                    {{ item.FullName | shortName }}
                  </div>
                  <svg
                    class="task-comments-hidden-info__tooltip-tail"
                    width="65"
                    height="49"
                    viewBox="0 0 65 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_4142:748)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.3123 29.0001H44.9485C42.7473 29.0001 40.6436 28.0934 39.1323 26.4933L34.3755 21.4562C33.6162 20.6524 32.3486 20.6173 31.5459 21.3775L25.8142 26.8077C24.3281 28.2154 22.3591 29.0001 20.3123 29.0001Z"
                        fill="black"
                        fill-opacity="0.75"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_4142:748"
                        x="0.312256"
                        y="0.82959"
                        width="64.6362"
                        height="48.1704"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="10" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_4142:748"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_4142:748"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                class="task-comments-inportant-icon"
                @click="markCommentAsImportant"
              >
                <svg width="15" height="14">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#star-icon"
                  ></use>
                </svg>
              </div>
            </div>
            <!--          <div-->
            <!--            class="task-comments-item__anchor"-->
            <!--            v-if="parentComment"-->
            <!--                      @click="$emit('goRoReplyMessage', parentComment)"-->
            <!--                      :title="parentComment.TimeCreate | formatReplyDate"-->
            <!--          >-->
            <!--            <i class="task-comments-item__anchor-icon"></i>-->
            <!--          </div>-->
            <div
              class="task-comments-item__right"
              v-if="!item.IsDeleted && !isPinnedInAnswerPopup"
            >
              <div
                class="task-comments-item__btn--reply"
                @click="$emit('setReplyComment', item)"
              >
                Ответить
              </div>
              <div
                class="task-comments-item__ellipses"
                v-if="userData && item.UserId === userData.Id"
                @click="menuOpen = !menuOpen"
              ></div>
            </div>
          </div>
          <div
            v-if="edit && !item.IsDeleted"
            class="task-editor-comment task-editor-comment--single"
          >
            <div class="task-editor-wrapper">
              <vue-editor
                class="task-create-form__text task-editor"
                :editor-options="{ bounds: '#task-editor_task-comment-item' }"
                id="task-editor_task-comment-item"
                v-model="item.Comment"
                :editorToolbar="customToolbar"
                ref="editor"
                @text-change="watchForSubscribe"
                @selection-change="watchEditorSelection"
              ></vue-editor>

              <div class="task-create-form__additional-toolbar">
                <label class="task-create-form__additional-toolbar-item">
                  <input
                    type="file"
                    hidden
                    @change="filesChange($event.target.files)"
                    multiple
                  />
                  <svg focusable="false" viewBox="0 0 32 32">
                    <path
                      d="M19,32c-3.9,0-7-3.1-7-7V10c0-2.2,1.8-4,4-4s4,1.8,4,4v9c0,0.6-0.4,1-1,1s-1-0.4-1-1v-9c0-1.1-0.9-2-2-2s-2,0.9-2,2v15c0,2.8,2.2,5,5,5s5-2.2,5-5V10c0-4.4-3.6-8-8-8s-8,3.6-8,8v5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5C6,4.5,10.5,0,16,0s10,4.5,10,10v15C26,28.9,22.9,32,19,32z"
                    ></path>
                  </svg>
                </label>
                <label
                  class="task-create-form__additional-toolbar-item"
                  @click="
                    markUserByChar();
                    isFocus = true;
                  "
                >
                  <svg focusable="false" viewBox="0 0 32 32">
                    <path
                      d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16c3.7,0,7.3-1.3,10.1-3.6c0.4-0.3,0.5-1,0.1-1.4c-0.3-0.4-1-0.5-1.4-0.1c-2.5,2-5.6,3.1-8.8,3.1C8.3,30,2,23.7,2,16S8.3,2,16,2s14,6.3,14,14v1.5c0,1.9-1.6,3.5-3.5,3.5S23,19.4,23,17.5V16c0-3.9-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7c2.3,0,4.4-1.1,5.7-2.9c0.9,1.7,2.8,2.9,4.8,2.9c3,0,5.5-2.5,5.5-5.5V16C32,7.2,24.8,0,16,0z M16,21c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S18.8,21,16,21z"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>

            <div
              class="task-popup-files"
              v-if="files.length && !item.IsDeleted"
            >
              <div
                class="sup-chat-files__item"
                v-for="(item, index) in files"
                :key="index"
              >
                <img
                  :src="item.img"
                  v-if="fileIsImage(item.name)"
                  @click="showImagePopup(item.img)"
                />
                <a
                  class="sup-chat-files__item-link"
                  download
                  v-else
                  :href="getFileSrc(item.linkToFile)"
                  @click.prevent="
                    downloadURI(getFileSrc(item.linkToFile), item.name, item.Id)
                  "
                >
                  <img :src="getNonImageTypeFileIcon(item.name)" />
                  <p>{{ item.name }}</p>
                </a>

                <div
                  class="sup-chat-files__item-remove"
                  @click="deleteLocalFileFromTask(item.id)"
                >
                  <svg class="popup__close-icon">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#icon_icon-close"
                    ></use>
                  </svg>
                </div>
              </div>
            </div>

            <div class="task-comment-textarea__btn-wrap" v-if="!item.IsDeleted">
              <div class="task-comment-textarea__btn" @click="setEditState">
                Отменить
              </div>
              <div class="task-comment-textarea__btn" @click="editComment">
                Сохранить
              </div>
            </div>
            <div>
              <div
                class="task-comment-subscribers task-comment-subscribers_comment-item"
                v-if="item.Visible === '2'"
              >
                <div class="task-comment-subscribers__name">Увидят только:</div>
                <div class="task-popup__nav-persons-list">
                  <div
                    class="task-popup__nav-persons-list__item"
                    v-for="(item, index) in usersWhoWillSeeHiddenComment.filter(
                      (item, index) =>
                        item &&
                        index <= 6 &&
                        item.UserId !== loadedTask.entityModel.ResponsibleId
                    )"
                    :key="index"
                    :title="getPersonNameById(item.UserId) | shortName"
                  >
                    <UserAvatar :user="item.User" />
                    <div
                      class="task-table-person__delete"
                      @click="deleteHiddenCommentUser(item.Id)"
                    >
                      <svg focusable="false" viewBox="0 0 32 32">
                        <path
                          d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div class="task-create-form-subtasks__person">
                    <div
                      class="task-create-form-subtasks__person--add"
                      v-if="usersWhoWillSeeHiddenComment.length <= 7"
                      @click="addHiddenCommentUserBubbleOpen = true"
                    >
                      +
                    </div>
                    <div
                      class="task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count"
                      v-else-if="usersWhoWillSeeHiddenComment.length > 7"
                      @click="addHiddenCommentUserBubbleOpen = true"
                    >
                      +{{ usersWhoWillSeeHiddenComment.length - 7 }}
                    </div>
                    <PersonsListSearch
                      :arr="usersWhoWillSeeHiddenComment"
                      v-if="addHiddenCommentUserBubbleOpen"
                      :className="'task-bubble--subscriber'"
                      :excludeArray="[loadedTask.entityModel.ResponsibleId]"
                      :outerUsers="loadedTask.taskGroupUsers"
                      @choose="addHiddenCommentUser"
                      @deleteItem="deleteHiddenCommentUser"
                      @close="addHiddenCommentUserBubbleOpen = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <template v-if="!item.IsDeleted">
              <div
                class="task-parent-comment-preview"
                @click="$emit('goRoReplyMessage', item.ParentComment)"
                :title="item.ParentComment.TimeCreate | formatReplyDate"
                v-if="item.ParentComment && !isPinnedInAnswerPopup"
                :style="{
                  'border-color': '#' + item.ParentComment.Author.ProfileColor,
                }"
              >
                <div class="task-parent-comment-preview__name">
                  <div class="task-comments-item__name">
                    {{ item.ParentComment.AuthorName | shortName }}
                  </div>
                  <div class="task-comments-item__date">
                    {{ item.ParentComment.TimeCreate | formatLocalDate }}
                  </div>
                </div>
                <div
                  class="task-parent-comment-preview__comment"
                  v-text="
                    item.ParentComment.Comment.replace(/<\/?[^>]+(>|$)/g, '')
                  "
                ></div>
              </div>
              <div
                class="task-comments-item__text ql-text-block"
                v-html="message"
              ></div>
              <div
                class="task-popup-files task-popup-files-in-comment"
                v-if="item.files && item.files.length"
              >
                <div
                  class="sup-chat-files__item"
                  v-for="(file, index) in item.files"
                  @click="
                    showGalleryImageLocal(
                      file.linkToFile,
                      item.files,
                      file.Filename
                    )
                  "
                  :key="index"
                >
                  <template v-if="fileIsImage(file.Filename)">
                    <img :src="getThumbnailSrc(file)" />
                    <p>{{ file.Filename }}</p>
                  </template>

                  <a
                    class="sup-chat-files__item-link"
                    download
                    @click.prevent="
                      downloadURI(
                        getFileSrc(file.linkToFile),
                        file.Filename,
                        file.Id
                      )
                    "
                    v-else
                    :href="getFileSrc(file.linkToFile)"
                  >
                    <img :src="getNonImageTypeFileIcon(file.Filename)" />
                    <p>{{ file.Filename }}</p>
                  </a>
                  <a
                    class="sup-chat-files__item-link-2"
                    download
                    @click.prevent.stop.capture="
                      downloadURI(
                        getFileSrc(file.linkToFile),
                        file.Filename,
                        file.Id,
                        true
                      )
                    "
                  >
                    Скачать
                  </a>
                  <!--            <div class="sup-chat-files__item-remove" @click="deleteFileFromTask(file)">-->
                  <!--              <svg class="popup__close-icon">-->
                  <!--                <use xmlns:xlink="http://www.w3.org/1999/xlink"-->
                  <!--                     xlink:href="/static/images/icon.svg#icon_icon-close"></use>-->
                  <!--              </svg>-->
                  <!--            </div>-->
                </div>
              </div>
            </template>
            <div class="task-comments-item__text" v-else>
              Комментарий удален
            </div>
          </template>
        </div>
        <div
          class="task-comments-show-answers-btn"
          v-if="item.ChildCommentsCounter && !isPinnedInAnswerPopup"
          @click="openTree"
        >
          <svg width="16" height="16" fill="#BFBFBF">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#message-icon"
            ></use>
          </svg>
          Показать ответы ({{ item.ChildCommentsCounter }})
        </div>
      </div>
    </template>

    <div class="task-comments-for-notification" v-else>
      <div class="task-comments-for-notification__icon">
        <i
          class="task-events__icon task-events__icon-1"
          style="width: 32px; height: 32px"
          :style="{
            backgroundImage: `url(/img/tasks_icons/${item.SystemName}.svg)`,
          }"
        />
      </div>
      <div class="task-comments-for-notification__content">
        <div class="task-comments-for-notification__top">
          <div class="task-comments-for-notification__name">
            {{ item.AuthorName | shortName }}
          </div>
          <div class="task-comments-for-notification__date">
            {{ item.TimeCreate | formatDateSimple }}
          </div>
        </div>

        <div
          class="task-comments-for-notification__message"
          v-if="item.IsDeleted"
        >
          Комментарий удален
        </div>
        <div
          class="task-comments-for-notification__message"
          v-else-if="!filesFromSystemComment.length"
          v-html="item.Comment"
        />
        <div class="task-comments-for-notification__message" v-else>
          В задачу добавлены новые файлы:
        </div>
        <div
          class="task-popup-files task-popup-files-in-comment"
          style="display: flex; flex-direction: column"
          v-if="filesFromSystemComment.length"
        >
          <div
            class="sup-chat-files__item"
            v-for="(file, index) in filesFromSystemComment"
            @click="showImagePopup(file.original)"
            :key="index"
          >
            <template v-if="fileIsImage(file.original)">
              <img :src="file.small" />
              <p>{{ file.filename }}</p>
            </template>

            <a
              class="sup-chat-files__item-link"
              download
              @click.prevent="
                downloadURI(getFileSrc(file.original), file.filename, file.Id)
              "
              v-else
              :href="getFileSrc(file.original)"
            >
              <img :src="getNonImageTypeFileIcon(file.original)" />
              <p>{{ file.filename }}</p>
            </a>
            <a
              class="sup-chat-files__item-link-2"
              download
              @click.prevent.stop.capture="
                downloadURI(
                  getFileSrc(file.original),
                  file.filename,
                  file.Id,
                  true
                )
              "
            >
              Скачать
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import common from "../../../../../mixins/tasks/common";
import * as c from "../../../../../store/tasks/constants";
import * as _ from "lodash";
import textSubscriber from "../../../../../mixins/tasks/textSubscriber";
import UserAvatar from "../../CommonComponents/UserAvatar";
import {
  deleteFetchedFile,
  prepareFetchedFiles,
} from "../../../../../mixins/tasks/func";
import { Helpers } from "@/mixins/Helpers";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";

export default {
  mixins: [common, textSubscriber, Helpers],
  props: ["item", "replyComment", "loadedTask", "isPinnedInAnswerPopup"],
  data() {
    return {
      edit: false,
      oldComment: "",
      files: [],
      menuOpen: false,
      hovered: false,
      usersWhoWillSeeHiddenComment: [],
      addHiddenCommentUserBubbleOpen: false,
    };
  },
  created() {
    if (this.item.VisibleUsersIds.length) {
      this.usersWhoWillSeeHiddenComment = this.getUsers.filter((i) =>
        this.item.VisibleUsersIds.find((u) => u.UserId === i.Id)
      );
    }
  },
  computed: {
    getUsers() {
      return this.users.length
        ? this.users
        : this.loadedTask.taskGroupUsers
        ? this.loadedTask.taskGroupUsers
        : [];
    },
    pinnedRecord() {
      if (this.loadedTask.pinnedComments.length) {
        return this.loadedTask.pinnedComments.find(
          (i) => i.Id === this.item.Id
        );
      }
      return false;
    },
    message() {
      return this.item.Comment === " " ? "" : this.item.Comment;
    },
    filesFromSystemComment() {
      const files = this.item.Comment.split("|||");
      if (files.length > 1) {
        files.shift();
        files.pop();
        try {
          const json = JSON.parse(files);
          return json;
        } catch (e) {
          return files;
        }
      }
      return [];
    },
    allowedToVisibleUsers() {
      if (this.item.VisibleUsersIds.length) {
        return this.getUsers.filter((i) =>
          this.item.VisibleUsersIds.find((u) => u.UserId === i.Id)
        );
      }
      return [];
    },
    ...mapState("tasks", ["selectedTask", "settings", "users", "userData"]),
  },
  methods: {
    setEditState() {
      if (!this.edit) {
        this.edit = true;
        setTimeout(() => {
          this.$refs.editor.quill.focus();
        }, 200);
        this.oldComment = this.item.Comment;
      } else {
        this.edit = false;
        this.item.Comment = this.oldComment;
      }
      this.menuOpen = false;
    },
    editComment() {
      this[c.EDIT_COMMENT]({
        id: this.item.Id,
        data: {
          Comment: this.item.Comment,
          TaskId: this.selectedTask.entityModel.Id,
          // CommentType: 1,
          // Status: 1,
          // UserId: this.userData.id
        },
      }).then(() => {
        this.setCommentsFiles(this.item.Id);
      });
      // this.setEditState()
      this.edit = false;
    },
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    deleteLocalFileFromTask(id) {
      this.files = _.reject(this.files, { id });
    },
    deleteFileFromTask(file) {
      this[c.DELETE_FILES_FROM_TASK](file).then(() =>
        deleteFetchedFile(file, this.loadedTask)
      );
    },
    setCommentsFiles(CommentId) {
      if (this.files.length) {
        const formData = new FormData();
        _.each(this.files, (i) => {
          formData.append("taskFiles[]", i.file);
        });
        formData.append("TaskId", this.selectedTask.entityModel.Id);
        formData.append("CommentId", CommentId);

        this.files = [];
        this[c.ADD_FILES_TO_TASK](formData).then((files) =>
          prepareFetchedFiles(files, this.loadedTask)
        );
      }
    },
    showGalleryImageLocal(linkToFile, files, fileName) {
      if (this.fileIsImage(fileName)) {
        this.showGalleryImage(linkToFile, files);
      }
    },
    deleteComment() {
      this.item.IsDeleted = true;
      this[c.DELETE_COMMENT](this.item.Id);
      this.edit = false;
      this.menuOpen = false;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    openTree() {
      this.settings.commentIdForAnswers = null;
      setTimeout(() => {
        this.settings.commentIdForAnswers = this.item;
      }, 100);
    },
    markUserByChar() {
      const quill = this.$refs.editor.quill;
      const { index } = quill.getSelection(true) || {
        index: this.message.length,
      };
      quill.insertText(index, "@");
    },
    async unpinLastComment() {
      if (this.loadedTask.pinnedComments.length) {
        await this[c.UNPIN_COMMENT](
          this.loadedTask.pinnedComments[0].PinnedRecordId
        ).then(() => (this.loadedTask.pinnedComments = []));
      }
      return true;
    },
    pin(id) {
      this.unpinLastComment().then(() => {
        this[c.PIN_COMMENT]({ CommentId: id }).then((res) =>
          this.loadedTask.pinnedComments.push({
            ...this.item,
            PinnedRecordId: res.data.entityModel.Id,
          })
        );
      });
      this.menuOpen = false;
    },
    unpin() {
      if (this.pinnedRecord) {
        this[c.UNPIN_COMMENT](this.pinnedRecord.PinnedRecordId).then(
          () => (this.loadedTask.pinnedComments = [])
        );
      }
      this.menuOpen = false;
    },
    markCommentAsImportant() {
      if (!this.item.Important) {
        this.item.Important = true;
        this[c.MARK_COMMENT_AS_IMPORTANT]({
          CommentId: this.item.Id,
        });
      } else {
        this.item.Important = false;
        this[c.UNMARK_COMMENT_AS_IMPORTANT](this.item.ImportantId);
      }
    },
    deleteHiddenCommentUser(Id) {
      this.usersWhoWillSeeHiddenComment = _.reject(
        this.usersWhoWillSeeHiddenComment,
        { Id }
      );
      const foundRelateObject = this.item.VisibleUsersIds.find(
        (i) => i.UserId === Id
      );
      if (foundRelateObject) {
        this[c.SET_COMMENT_UNVISIBLE](
          foundRelateObject.Id
            ? foundRelateObject.Id
            : foundRelateObject.RecordId
        );
      }

      this.item.VisibleUsersIds = _.reject(this.item.VisibleUsersIds, [
        "UserId",
        Id,
      ]);
    },
    addHiddenCommentUser(userId, user) {
      this.usersWhoWillSeeHiddenComment.push(user);
      const foundRelateObject = this.item.VisibleUsersIds.find(
        (i) => i.UserId === user.Id
      );
      if (!foundRelateObject) {
        this[c.SET_COMMENT_VISIBLE]({
          CommentId: this.item.Id,
          UsersIds: [user.Id],
        }).then((res) => {
          this.item.VisibleUsersIds.push(res.data.rowsIds[0]);
        });
      }
    },
    ...mapActions("tasks", [
      c.EDIT_COMMENT,
      c.DELETE_FILES_FROM_TASK,
      c.ADD_FILES_TO_TASK,
      c.DELETE_COMMENT,
      c.PIN_COMMENT,
      c.UNPIN_COMMENT,
      c.MARK_COMMENT_AS_IMPORTANT,
      c.UNMARK_COMMENT_AS_IMPORTANT,
      c.SET_COMMENT_UNVISIBLE,
      c.SET_COMMENT_VISIBLE,
    ]),
  },
  watch: {
    edit(val) {
      if (val) {
        setTimeout(() => {
          this.$refs["editor"].$el
            .querySelectorAll(".ql-toolbar button")
            .forEach((i) => i.setAttribute("tabindex", "-1"));
          this.$refs["editor"].quill.clipboard.addMatcher(
            Node.TEXT_NODE,
            function (node, delta) {
              var regex = /https?:\/\/[^\s]+/g;
              if (regex.exec(node.data) != null) {
                delta.ops = [
                  { insert: node.data, attributes: { link: node.data } },
                ];
              }
              return delta;
            }
          );
          // this.$refs["editor"].quill.clipboard.addMatcher(
          //   Node.ELEMENT_NODE,
          //   (node, delta) => {
          //     delta.ops = delta.ops.map((op) => {
          //       return {
          //         insert: op.insert,
          //       };
          //     });
          //     return delta;
          //   }
          // );
        }, 100);
      }
    },
    "item.VisibleUsersIds"() {
      if (this.item.VisibleUsersIds.length) {
        this.usersWhoWillSeeHiddenComment = this.getUsers.filter((i) =>
          this.item.VisibleUsersIds.find((u) => u.UserId === i.Id)
        );
      }
    },
  },
  components: {
    UserAvatar,
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
