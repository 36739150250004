export const newCompany = {
  Name: "Новая компания",
  header: "Клиенты",
  add_btn: "НОВЫЙ КЛИЕНТ",
  model: "client",
  search: "",
  INN: "",
  active: 0,
  ListName: "Новая компания",
};
export const newObject = {
  Name: "НОВЫЙ ОБЬЕКТ",
  model: "object",
  header: "Обьекты",
  add_btn: "НОВЫЙ ОБЬЕКТ",
  search: "",
  changed: 0,
  NowDate: "25.10.2018",
  active: 0,
  ListName: "НОВЫЙ ОБЬЕКТ",
};

export const newContact = (objectId) => {
  return {
    ObjectId: objectId,
    Phone: [""],
    Name: "ФИО ",
    Position: "Должность",
    Description: "Описание",
    DND: false,
    SortOrder: 99,
    Email: [""],
    Skype: "",
    Others: "",
    active: 0,
    ListName: "ФИО ",
  };
};

export const newContactDist = (distbId) => {
  return {
    ContactName: "Новый контакт",
    DistributorId: distbId,
    Email: "",
    Password: "",
    Phone: "",
  };
};

export const newDistb = {
  Name: "Новый дистрибьютор",
  Inn: "",
  Okpo: "",
  Phone: "",
  Address_juridical_text: "",
  Address_juridical_coordinates: "",
  Address_physical_text: "",
  Address_physical_coordinates: "",
};
