import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    countInterval: true,
    count: {
      active: 0,
      all: 0,
      closed: 0,
      new: 0,
      new_messages: 0,
      open: 0,
      wait_client: 0,
    },
    activeTabs: {
      open: true,
      new_messages: false,
      wait_client: false,
      closed: false,
    },
    requests: {
      active: [],
      all: [],
      closed: [],
      new: [],
      new_messages: [],
      open: [],
      wait_client: [],
    },
    supportData: false,
    isRequestUploaded: false,
    selectedRequest: false,
    messages: [],
    requestFilters: {
      messages: "Все",
      status: { name: "Все", id: 0 },
      activity: "desc",
    },
    searchInput: "",
    progressBar: null,
    userInfo: false,
    pin: false,
    parentData: {
      sendingMessage: false,
    },
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
