var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"portal-wrapper-statuses",staticClass:"automation-statuses"},[_c('div',{staticClass:"automation-statuses__name-wrap",on:{"click":function($event){!_vm.disabled && (_vm.open = !_vm.open)}}},[(_vm.preplaceholder)?_c('div',{staticClass:"automation-select-text--no-selectable"},[_vm._v("\n      "+_vm._s(_vm.preplaceholder)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"automation-select-text",class:{
        'automation-select-text--have-value': _vm.value,
        'automation-select-text--disabled': _vm.disabled,
      }},[_vm._v("\n      "+_vm._s(_vm.name)+"\n    ")])]),_vm._v(" "),_c('portal',{attrs:{"to":"common"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.portalOpen)?_c('GroupedListSearch',{directives:[{name:"portal",rawName:"v-portal",value:({ parent: _vm.$refs['portal-wrapper-statuses'] }),expression:"{ parent: $refs['portal-wrapper-statuses'] }"}],attrs:{"selectedItemsArray":_vm.selected ? [_vm.selected.Id] : [],"items":_vm.processes,"fieldNames":{
          searchInputName: 'Поиск статуса',
          idFieldName: 'Id',
          parentFieldName: 'Name',
          childListFieldName: 'statuses',
          childFieldName: 'Name',
          colorFieldName: 'Color',
        }},on:{"choose":_vm.choose,"close":function($event){_vm.open = false}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }