<template>
  <div class="module-wrapper">
    <div class="header">
      <div class="header__col">
        <div class="tabs header__tabs">
          <router-link class="tabs__tab" to="/sales" exact>
            <span class="tabs__link">Ком. Предложения</span>
          </router-link>
          <router-link class="tabs__tab" to="/sales/reservation/objects">
            <span class="tabs__link">Забронированные объекты</span>
          </router-link>
          <router-link class="tabs__tab" to="/sales/reservation/requests">
            <span class="tabs__link"
              >Заявки<template v-if="requestsCount"
                >: {{ requestsCount }}</template
              ></span
            >
          </router-link>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  GET_REQUESTS_OBJECTS,
  GET_RESERVED_OBJECTS,
  RESERVE_MODULE_RIGHT,
} from "../../../store/sales_reservation/constants";
import { mapActions, mapState } from "vuex";
import { GET_DISTB } from "../../../store/clients/constants";
import { Helpers } from "../../../mixins/Helpers";

export default {
  mixins: [Helpers],
  created() {
    this[GET_DISTB]();
    setTimeout(() => {
      if (!this.checkUserRight(RESERVE_MODULE_RIGHT)) {
        window.location.href = "/";
      }
    }, 2000);
  },
  computed: {
    ...mapState("reserve", ["requestsCount"]),
  },
  methods: {
    ...mapActions("clients", [GET_DISTB]),
    ...mapActions("reserve", [GET_REQUESTS_OBJECTS, GET_RESERVED_OBJECTS]),
  },
};
</script>

<style scoped></style>
