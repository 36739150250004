<template>
  <div>
    <div class="tableInnerRow">
      <div class="table__row table__row_main">
        <div
          class="table__col table__col_width_360"
          @click="openForm($event.target)"
        >
          <div class="cm-table-col-1">
            <div class="cm-table-col-1__arrow">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
            <div class="cm-table-col-1__text">
              <span class="cm-table-col-1__name">Изображение типа системы</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cm-client-detail-form" v-if="cat">
        <div
          class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single"
          style="padding-top: 40px"
        >
          <div class="field field--input-file device-images">
            <label class="field field-cover">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
              </span>
              <span class="field__content input-file">
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  class="input"
                  @change="
                    filesChange(
                      $event.target.files,
                      cat,
                      settingsObject.json[cat + 'Img'].id
                    )
                  "
                />
                <input
                  type="text"
                  class="input"
                  :value="settingsObject.json[cat + 'Img'].img"
                  disabled
                />
                <div class="button button_grey button-file">
                  <img src="/static/images/gps.svg" />
                  Выбрать файл
                </div>
              </span>
            </label>
          </div>
        </div>
        <div class="cm-client-detail-form__btns">
          <span @click="saveSettings">Сохранить</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  props: {
    cat: null,
  },
  methods: {
    filesChange(fileList, cat, id) {
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        const blob = window.URL.createObjectURL(fileList[0]);
        this.settingsObject.json[cat + "Img"].img = fileList[0].name;
        this.settingsObject.json[cat + "Img"].blob = blob;
        this.settingsObject.images[cat + "Img"] = [{ id, file: fileList[0] }];
        this.settingsObject.blobImages[cat + "Img"] = [
          { id, file: fileList[0] },
        ];
      }
    },
  },
};
</script>
