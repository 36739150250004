import { render, staticRenderFns } from "./ProcessLabelElement.vue?vue&type=template&id=6ba5cab4&scoped=true&"
import script from "./ProcessLabelElement.vue?vue&type=script&lang=js&"
export * from "./ProcessLabelElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba5cab4",
  null
  
)

export default component.exports