<template>
  <div
    class="text-bubble"
    :style="{
      left: settings.textBubbleChoords.left,
      top: settings.textBubbleChoords.top,
    }"
    :class="settings.textBubbleChoords.className"
  >
    <transition name="fade">
      <PersonsListSearch
        v-if="settings.textBubbleChoords.isOpen"
        :outerSearch="settings.textBubbleChoords.search"
        :outerUsers="selectedTask ? selectedTask.taskGroupUsers : null"
        :className="'task-bubble--subscriber-in-textarea'"
        @choose="addSubscriber"
        @close="close"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import PersonsListSearch from "./PersonsListSearch";
import common from "../../../../mixins/tasks/common";
import * as c from "../../../../store/tasks/constants";

export default {
  mixins: [common],
  computed: {
    ...mapState("tasks", ["settings", "selectedTask"]),
  },
  methods: {
    close() {
      this[c.RESET_SUBSCRIBERS_BUBBLE]();
    },
    addSubscriber(id, user) {
      console.log("id, user", id, user);
      const quill = this.settings.textBubbleChoords.el;
      const retain = this.settings.textBubbleChoords.retain;
      let subscriberName = user.FullName;

      const searchNameLength = this.settings.textBubbleChoords.search.length;

      subscriberName =
        "@" + this.$options.filters.shortName_NICKNAME(subscriberName);
      const delta = {
        ops: [
          { retain: retain + 1 },
          { insert: "" },
          { insert: subscriberName, attributes: { link: "userId=" + user.Id } },
        ],
      };
      quill.updateContents(delta);
      quill.setSelection(retain + subscriberName.length, 0);
      quill.deleteText(retain, 1);

      // const searchTextRetain = retain + subscriberName.length + 2;
      // const [leaf] = quill.getLeaf(searchTextRetain); // удалить впереди идущего
      // if (leaf && leaf.text) {
      //   quill.deleteText(searchTextRetain - 1, leaf.text.length);
      // }
      // quill.updateContents({
      //   ops: [{ retain: retain + subscriberName.length }, { insert: "" }],
      // });
      quill.deleteText(retain + subscriberName.length, 1);

      quill.deleteText(retain + subscriberName.length, searchNameLength);

      quill.updateContents({
        ops: [{ retain: retain + subscriberName.length }, { insert: " " }],
      });

      quill.setSelection(retain + subscriberName.length + 1, 0);
    },
    ...mapActions("tasks", [c.ADD_SUBSCRIBER]),
    ...mapMutations("tasks", [c.RESET_SUBSCRIBERS_BUBBLE]),
  },
  components: {
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
