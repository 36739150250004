<template>
  <div class="print print--12 print--12-1">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        Преимущества <br />Для собственика
      </div>
    </div>
    <div class="print__advantages">
      <div
        class="print__advantages-item"
        v-for="(item, index) in this.offerObject.json.AdvantagesOwner"
        :key="index"
      >
        <div class="print__advantages-img">
          <img
            v-if="imageBlobCheck('icons', objectType, item.id)"
            :src="item.blob"
          />
          <img
            v-else-if="item.img"
            :src="getImageSrc(item.img, offerObject.json.System)"
          />
        </div>
        <div class="print__advantages-cont">
          <div class="print__advantages-name">{{ item.name }}</div>
          <div class="print__advantages-text">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <div class="print__footer">{{ this.pageNums.Total + 2 }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  methods: {},

  computed: {
    ...mapState("sales", ["offerObject", "settingsObject"]),
  },
};
</script>
