<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_2 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <Print_2 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('ContentText')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div class="new-changes-block">
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>{{ offerObject.json.ContentText }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <p>{{ compareObject.ContentText }}</p>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('ContentText'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Дата КП:</div>
            <div class="form-row__datepicker">
              <date-picker
                input-class="input"
                valueType="format"
                :format="'DD.MM.YYYY'"
                lang="ru"
                v-model="offerObject.json.Date"
              ></date-picker>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__label">ФИО представителя Заказчика:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.ClientName"
            />
          </div>
          <!--<div class="form-row">-->
          <!--<div class="form-row__label">E-mail:</div>-->
          <!--<input class="form-row__input" type="email" v-model="offerObject.json.ClientEmail">-->
          <!--</div>-->
          <!--<div class="form-row">-->
          <!--<div class="form-row__label">Телефон:</div>-->
          <!--<input class="form-row__input" type="tel" v-model="offerObject.json.ClientPhone">-->
          <!--</div>-->
          <div class="form-row">
            <div class="form-row__label">Название компании:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.CompanyName"
            />
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(1)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(3)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
// import DatePicker from 'vue2-datepicker'
import Print_2 from "../prints/print_2";
export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },

  components: {
    // DatePicker,
    Print_2,
  },
};
</script>
