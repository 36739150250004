import draggable from "vuedraggable";
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  methods: {
    changeOrder(cat, module, device) {
      let count = 1;
      this.settingsObject.json.devices[cat][module][device] = _.map(
        this.settingsObject.json.devices[cat][module][device],
        (i) => {
          i.id = count;
          count++;
          return i;
        }
      );
    },
  },
  computed: {
    ...mapState("sales", ["settingsObject"]),
  },
  components: {
    draggable,
  },
};
