<template>
  <div class="task-create-form__row">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--2"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__field" v-if="showNameField">
        <input
          type="text"
          class="input"
          placeholder="Название задачи"
          v-model="task.TemplateTaskName"
          @blur="$emit('saveAfterEdit', null, () => {}, 'TemplateTaskName')"
        />
      </div>
      <div class="task-create-form__field">
        <vue-editor
          tab="disabled"
          class="task-editor textarea task-editor-template create-form-state"
          placeholder="Что необходимо сделать?"
          v-model="form.TaskDescription"
          :editorToolbar="customToolbarWithLink"
          ref="editor"
          @text-change="watchForSubscribe"
          @selection-change="watchEditorSelection"
          @paste.native="onPaste"
        ></vue-editor>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import textSubscriber from "../../../../../mixins/tasks/textSubscriber";

export default {
  mixins: [common, textSubscriber],
  props: ["form", "filesInput", "showNameField", "task"],
  mounted() {
    this.$refs["editor"].$el
      .querySelectorAll(".ql-toolbar button")
      .forEach((i) => i.setAttribute("tabindex", "-1"));
    this.$refs["editor"].quill.clipboard.addMatcher(
      Node.TEXT_NODE,
      function (node, delta) {
        var regex = /https?:\/\/[^\s]+/g;
        if (regex.exec(node.data) != null) {
          delta.ops = [{ insert: node.data, attributes: { link: node.data } }];
        }
        return delta;
      }
    );
    const self = this;
    this.$refs["editor"].quill.root.addEventListener("blur", function () {
      self.$emit("saveAfterEdit", null, () => {}, "TaskDescription");
    });
    // this.$refs["editor"].quill.clipboard.addMatcher(
    //   Node.ELEMENT_NODE,
    //   (node, delta) => {
    //     delta.ops = delta.ops.map((op) => {
    //       return {
    //         insert: op.insert,
    //       };
    //     });
    //     return delta;
    //   }
    // );
  },
  methods: {
    onPaste(e) {
      const files = e.clipboardData.files;
      if (files.length) {
        this.$parent.$refs.filesInput.filesChange(files);
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>
