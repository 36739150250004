<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <svg width="18" height="18">
        <use xlink:href="/static/images/tasks/sprite.svg#eye-hide-icon"></use>
      </svg>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Настройки:</p>
          <div
            class="task-create-form__field-value"
            ref="portal-wrapper"
            @click="selectBubbleOpen = true"
          >
            <p v-if="!selectedSettings.length">Выбрать</p>
            <p v-else>
              Выбрано {{ selectedSettings.length }} из {{ settings.length }}
            </p>
            <portal to="common">
              <transition name="fade">
                <SingleListSearch
                  v-if="selectBubbleOpen"
                  @choose="setSettings"
                  @close="selectBubbleOpen = false"
                  :items="settings"
                  :selectedItemsArray="selectedSettings.map((i) => i.Id)"
                  :fieldNames="{
                    idFieldName: 'Id',
                    fieldName: 'Name',
                  }"
                  v-portal="{ parent: $refs['portal-wrapper'] }"
                />
              </transition>
            </portal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import SingleListSearch from "@/modules/tasks/components/CommonComponents/SingleListSearch/SingleListSearch";
import { Helpers } from "@/mixins/Helpers";
import taskSettingsMixin from "@/modules/tasks/components/TaskCreateForm/mixins/taskSettingsMixin";
export default {
  mixins: [common, Helpers, taskSettingsMixin],
  props: ["form"],
  data() {
    return { selectBubbleOpen: false };
  },
  computed: {
    selectedSettings() {
      return this.settings.filter((i) => i.Selected) || [];
    },
  },
  methods: {
    setSettings(setting) {
      if (setting.Id === "TaskAccess") {
        this.form.Settings.TaskAccess =
          Number(this.form.Settings.TaskAccess) === 1 ? 2 : 1;
      } else {
        this.form.Settings[setting.Id] = !this.form.Settings[setting.Id];
      }
      this.$emit("saveAfterEdit", null, () => {}, "Settings");
    },
  },
  components: { SingleListSearch },
};
</script>

<style scoped></style>
