<template>
  <div class="cm-popup__right">
    <div class="table cm-table-1">
      <div class="table__header">
        <div class="table__col table__col_width_90" style="width: 60px">
          <p class="table__header-text">Id</p>
        </div>
        <div class="table__col table__col_flexible">
          <p class="table__header-text">Событие</p>
        </div>
        <div class="table__col table__col_width_120 tac">
          <p class="table__header-text">Время</p>
        </div>
        <div class="table__col table__col_width_120 tac">
          <p class="table__header-text">Вкл/Выкл</p>
        </div>
      </div>

      <div class="table-spinner" v-show="preloader">
        <Spinner
          :noFadeIn="true"
          name="wave"
          color="#c9eeff"
          width="60"
          height="60"
        />
      </div>

      <div
        class="table__row-wrap сmp__row-wrap"
        v-if="items"
        style="height: calc(100vh - 100px)"
      >
        <div
          class="table__row table__row_main"
          :key="index"
          v-for="(item, index) in items"
        >
          <div class="table__col table__col_width_90" style="width: 60px">
            <div class="table__text-block">
              <p class="table__text">{{ item.Id }}</p>
            </div>
          </div>
          <div class="table__col table__col_flexible">
            <div class="table__text-block">
              <p class="table__text">{{ item.Description }}</p>
            </div>
          </div>
          <div class="table__col table__col_width_120">
            <div class="table__text-block">
              <input
                type="number"
                class="input"
                :class="item.IsWrite === '0' ? 'input-disabled' : ''"
                v-model="item.WriteTime"
                @change="updateAction(item.Id, 'WriteTime', item.WriteTime)"
              />
            </div>
          </div>
          <div class="table__col table__col_width_120">
            <div class="table__text-block">
              <div class="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  :id="'myonoffswitch' + index"
                  v-model="item.IsWrite"
                  v-bind:true-value="'1'"
                  v-bind:false-value="'0'"
                  @change="updateAction(item.Id, 'IsWrite', item.IsWrite)"
                />
                <label class="onoffswitch-label" :for="'myonoffswitch' + index">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-spinkit";
import { showAlert } from "@/utils";
import Api from "@/api/clients";

export default {
  props: {
    objectId: null,
  },
  data() {
    return {
      items: null,
      preloader: true,
    };
  },
  created() {
    Api.getDeviceActions(this.objectId)
      .then((res) => {
        this.items = res.data;
        this.preloader = false;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.response.data.message);
        this.preloader = false;
        console.log(err);
      });
  },
  computed: {
    // filteredDevices () {
    //   return this.devices ? this.devices.filter(i => {
    //     const re = new RegExp(this.searchInput, 'gi')
    //     return re.test(i.Name)
    //   }) : []
    // }
  },
  methods: {
    updateAction(actionId, actionName, value) {
      this.preloader = true;
      let payload = {};
      payload[actionName] = value;
      Api.updateDeviceActions(this.objectId, actionId, payload)
        .then(() => {
          this.preloader = false;
          showAlert("success", "Успешно");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          this.preloader = false;
        });
    },
  },
  components: { Spinner },
};
</script>
