<template>
  <div class="task-bubble" v-click-outside="close">
    <div class="task-bubble__search">
      <svg>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="/static/images/icon.svg#icon_icon-search"
        ></use>
      </svg>
      <input
        type="text"
        class="input"
        :placeholder="fieldNames.searchInputName || 'Поиск'"
        v-model="search"
        ref="input"
      />
      <div class="task-bubble__close" @click="close()"><i></i></div>
    </div>
    <div class="task-bubble__list" ref="list">
      <SingleListSearchItem
        v-for="(item, index) in filteredItems"
        @choose="choose"
        :key="index"
        :item="item"
        :search="search"
        :fieldNames="fieldNames"
        :selectedItemsArray="selectedItemsArray"
      />
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { Helpers } from "../../../../../mixins/Helpers";
import SingleListSearchItem from "@/modules/tasks/components/CommonComponents/SingleListSearch/SingleListSearchItem";
import * as _ from "lodash";

export default {
  mixins: [common, Helpers],
  props: ["items", "fieldNames", "selectedItemsArray"],
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$refs["input"].focus();
  },
  computed: {
    filteredItems() {
      return _.orderBy(
        this.items.filter((i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i[this.fieldNames.fieldName]);
        }),
        [this.fieldNames.fieldName],
        ["asc"]
      );
    },
  },
  methods: {
    choose(item) {
      this.$emit("choose", item);
      if (this.selectedItemsArray === void 0) this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    SingleListSearchItem,
  },
};
</script>

<style scoped></style>
