import * as c from "./constants";
import * as _ from "lodash";

function prepareArticles(items) {
  return _.map(items, (i) => {
    if (!i.child) {
      i.child = [];
    } else if (i.child && i.child.length) {
      i.child = prepareArticles(i.child);
    }
    return i;
  });
}

export default {
  [c.GET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [c.CREATE_CATEGORY](state, data) {
    state.categories.push(data);
  },
  [c.EDIT_CATEGORY](state, data) {
    state.categories = _.map(state.categories, (i) => {
      if (i.Id === data.Id) i = data;
      return i;
    });
  },
  [c.DELETE_CATEGORY](state, id) {
    state.categories = _.reject(state.categories, ["Id", id]);
  },
  [c.GET_ARTICLES](state, data) {
    data = prepareArticles(data);
    state.articles = data;
  },
  [c.SEARCH_ARTICLES](state, data) {
    state.searchResults = data;
  },
  [c.LOAD_ARTICLE_CHILDS](state, { data, params }) {
    data = prepareArticles(data);

    function addField(i) {
      if (i.Id === params.PostId) {
        if (+i.SortAlph) {
          data = _.orderBy(data, ["Name"], ["asc"]);
        }
        i.child = data;
      } else if (i.child && i.child.length) {
        _.each(i.child, (c) => {
          addField(c);
        });
      }
    }

    state.articles = _.map(state.articles, (i) => {
      addField(i);
      return i;
    });
  },
  [c.EDIT_ARTICLE](state, data) {
    function changeArticle(i) {
      if (i.Id === data.Id) {
        data.child = i.child;
        i = data;
      } else if (i.child && i.child.length) {
        i.child = _.map(i.child, (c) => {
          c = changeArticle(c);
          return c;
        });
      }
      return i;
    }

    state.articles = _.map(state.articles, (i) => {
      i = changeArticle(i);
      return i;
    });
  },
  [c.CREATE_ARTICLE](state, data) {
    data.child = [];

    function pushPost(i) {
      if (i.Id === data.ParentId) {
        i.child.push(data);
        i.HaveChild = true;
      } else if (i.child && i.child.length) {
        _.each(i.child, (c) => {
          pushPost(c);
        });
      }
    }

    if (!data.ParentId) {
      state.articles.push(data);
    } else {
      state.articles = _.map(state.articles, (i) => {
        pushPost(i);
        return i;
      });
    }
  },
  [c.DELETE_ARTICLE](state, id) {
    function deletePost(arr) {
      if (_.find(arr, ["Id", id])) {
        return _.reject(arr, ["Id", id]);
      } else {
        arr = _.map(arr, (a) => {
          if (a.child && a.child.length) {
            a.child = deletePost(a.child);
          }
          return a;
        });
      }
      return arr;
    }
    state.articles = deletePost(state.articles);
  },
};
