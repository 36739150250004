import {
  GET_TASK_BY_FLASH_FILTER,
  GET_TASKS_BY_USER_FILTER,
  GET_TASKS,
  GET_TASKS_BY_STANDART_FILTER,
  SET_COMMON_MODULE_PAGE,
  SET_COMMON_MODULE_SELECTED_ITEMS,
  SILENT_EDIT_TASK,
  SET_TASK_CHILDS,
  REFRESH_SECTIONS,
  CHANGE_ORDER,
  SET_COMMON_MODULE_SUBTASK,
  SET_COMMON_MODULE_POPUP,
  EDIT_TASK_DEPENDENCY,
} from "../../../../../store/tasks/constants";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import common from "@/mixins/tasks/common";
import * as _ from "lodash";

export default {
  mixins: [common],
  data() {
    return {
      shiftSelectionState: false,
    };
  },
  computed: {
    ...mapState("tasks", ["tasks", "preloaders", "commonModuleSettings"]),
    ...mapGetters("tasks", ["group", "selectedFilter", "flashFilterData"]),
  },
  methods: {
    loadMore: function () {
      if (!this.selectedFilter) {
        this[SET_COMMON_MODULE_PAGE](this.commonModuleSettings.page + 1);
        this[GET_TASKS](this.buildQueryString());
      } else {
        if (this.selectedFilter === -1) {
          this[SET_COMMON_MODULE_PAGE](this.commonModuleSettings.page + 1);
          this[GET_TASK_BY_FLASH_FILTER]({
            data: { draftFilter: this.flashFilterData },
            pagination: this.commonModuleSettings.page,
          });
        } else if (this.selectedFilter.length > 5) {
          this[SET_COMMON_MODULE_PAGE](this.commonModuleSettings.page + 1);
          this[GET_TASKS_BY_USER_FILTER]({
            id: this.selectedFilter,
            params: this.buildQueryString(),
          });
        } else {
          this[SET_COMMON_MODULE_PAGE](this.commonModuleSettings.page + 1);
          this[GET_TASKS_BY_STANDART_FILTER]({
            type: this.selectedFilter,
            params: this.buildQueryString(),
          });
        }
      }
    },
    selectProjectRowItem(e, item) {
      const el = e.target;
      if (el.checked) {
        this.addItemToSelectionArray(item);
      } else {
        this.removeItemFromSelectionArray(item);
      }
    },
    selectItem(e, item, table) {
      const el = e.target;
      if (table === "projectTable") {
        this.selectProjectRowItem(e, item);
      } else {
        if (!this.shiftSelectionState) {
          if (document.querySelector(".selection-entry")) {
            document
              .querySelector(".selection-entry")
              .classList.remove("selection-entry");
          }
          el.closest(".table__row--col").classList.add("selection-entry");
        }
        if (
          el
            .closest(".table-row_draggable")
            .classList.contains("task-table-row--open")
        ) {
          if (el.classList.contains("checked-one")) {
            el.classList.remove("checked-one");
            // console.log('remove')
            el.closest(".table__row--col")
              .querySelectorAll(".table__row--col")
              .forEach((i) => {
                i.querySelectorAll(".checkbox__input").forEach((c) => {
                  c.classList.remove("checked-one");
                  this.addItemToSelectionArray(
                    this.findTask(c.getAttribute("task-id"))
                  );
                  c.checked = true;
                });
              });
            e.preventDefault();
          } else if (el.checked) {
            // console.log('add')
            this.addItemToSelectionArray(item);
            el.classList.add("checked-one");
          } else {
            // console.log('step 3')
            this.removeItemFromSelectionArray(item);
            el.closest(".table__row--col")
              .querySelectorAll(".table__row--col")
              .forEach((i) => {
                i.querySelectorAll(".checkbox__input").forEach((c) => {
                  c.classList.remove("checked-one");
                  this.removeItemFromSelectionArray(
                    this.findTask(c.getAttribute("task-id"))
                  );
                  c.checked = false;
                });
              });
          }
        } else {
          if (el.checked) {
            this.addItemToSelectionArray(item);
          } else {
            this.removeItemFromSelectionArray(item);
          }
        }
      }
    },
    shiftSelection(e) {
      const el = e.target.closest(".table__row--col");
      const rows = document
        .querySelector(".table__row-wrap--tasks")
        .querySelectorAll(".table__row--col");
      let firstEntry,
        lastEntry = false;
      this.shiftSelectionState = true;
      if (
        !el.classList.contains("selection-entry") &&
        !el.querySelector(".checkbox__input").checked
      ) {
        el.querySelector(".checkbox__input").click();
      }

      if (document.querySelector(".selection-entry")) {
        rows.forEach((row) => {
          if (el === row || row.classList.contains("selection-entry")) {
            lastEntry = !!firstEntry;
            firstEntry = true;
          }
          if (
            ((!firstEntry && !lastEntry) || (firstEntry && lastEntry)) &&
            el !== row &&
            !row.classList.contains("selection-entry")
          ) {
            if (
              row.querySelector(".checkbox__input") &&
              row.querySelector(".checkbox__input").checked
            ) {
              row.querySelector(".checkbox__input").click();
            }
          } else if (
            firstEntry &&
            !lastEntry &&
            !el.classList.contains("selection-entry")
          ) {
            if (
              row.querySelector(".checkbox__input") &&
              !row.querySelector(".checkbox__input").checked
            ) {
              row.querySelector(".checkbox__input").click();
            }
          }
        });
      } else {
        el.classList.add("selection-entry");
      }

      el.classList.add("shift-selection-entry");

      this.shiftSelectionState = false;
    },
    ctrlSelection(e) {
      const el = e.target.closest(".table__row--col");
      const rows = document
        .querySelector(".table__row-wrap--tasks")
        .querySelectorAll(".table__row--col");

      if (document.querySelector(".shift-selection-entry")) {
        rows.forEach((row) => {
          row.classList.remove("shift-selection-entry");
          if (
            row.querySelector(".checkbox__input") &&
            row.querySelector(".checkbox__input").checked
          ) {
            row.querySelector(".checkbox__input").click();
          }
        });
      }

      el.classList.add("сtrl-selection-entry");

      el.querySelector(".checkbox__input").click();
    },
    addItemToSelectionArray(item) {
      if (
        !this.commonModuleSettings.selectedItems.find((x) => x.Id === item.Id)
      ) {
        this[SET_COMMON_MODULE_SELECTED_ITEMS]([
          ...this.commonModuleSettings.selectedItems,
          item,
        ]);
      }
    },
    removeItemFromSelectionArray(item) {
      this[SET_COMMON_MODULE_SELECTED_ITEMS](
        _.filter(
          this.commonModuleSettings.selectedItems,
          (i) => i.Id !== item.Id
        )
      );
    },
    setTasksByDraggable(parentTask, tasks, section) {
      section = JSON.parse(JSON.stringify(section));
      section.Id = section.Name === "virtualSection" ? null : section.Id;
      // const cleanTasksArray = [];
      tasks = _.map(tasks, (i) => {
        const SectionId = section ? section.Id : null;
        const SectionName = section ? section.Name : null;
        const ParentId = parentTask ? parentTask.Id : null;
        let needSave = false;
        if (i.isDepended) {
          if (i.SectionId !== SectionId) {
            i.SectionId = SectionId;
            this[EDIT_TASK_DEPENDENCY]({
              Id: i.DependRecordId,
              SectionId,
            });
          }
        } else {
          if (i.SectionId !== SectionId || i.ParentId !== ParentId) {
            needSave = true;
          }
          i.SectionId = SectionId;
          i.SectionName = SectionName;
          i.ParentId = ParentId;
          if (needSave) {
            this[SILENT_EDIT_TASK]({
              id: i.Id,
              data: {
                SectionId: i.SectionId,
                SectionName: i.SectionName,
                ParentId: i.ParentId,
              },
            });
          }
        }

        return i;
      });
      const cleanTasksArray = tasks.filter((i) => !i.isDepended);
      if (cleanTasksArray.length) {
        this.changeOrder(cleanTasksArray);
      }

      // this[SET_TASK_CHILDS]({
      //   parentTask,
      //   tasks,
      //   section,
      // });
      // this[REFRESH_SECTIONS]();
    },
    changeOrder(items) {
      this[CHANGE_ORDER]({
        items,
        apiMethod: "api/tasks/v2/tasks/updateOrder",
      });
    },
    createSubTask(task) {
      this[SET_COMMON_MODULE_SUBTASK](JSON.parse(JSON.stringify(task)));
      this[SET_COMMON_MODULE_POPUP]({
        popup: "taskCreatePopupOpen",
        value: true,
      });
      document.querySelectorAll(".task-plus-label").forEach((i) => {
        i.classList.remove("task-plus-label--close");
      });
    },
    findTask(id) {
      let result = null;
      const recursiveFunc = (i) => {
        if (!result) {
          if (i.Id === id) return (result = i);
          else if (i.childs || i.depends) {
            const depends = i.depends || [];
            _.each([...i.childs, ...depends], (i) => {
              recursiveFunc(i);
            });
          }
        }
      };
      this.tasks.forEach((i) => {
        recursiveFunc(i);
      });
      return result;
    },
    ...mapActions("tasks", [
      GET_TASKS,
      GET_TASKS_BY_USER_FILTER,
      GET_TASK_BY_FLASH_FILTER,
      GET_TASKS_BY_STANDART_FILTER,
      CHANGE_ORDER,
      SILENT_EDIT_TASK,
      EDIT_TASK_DEPENDENCY,
    ]),
    ...mapMutations("tasks", [
      SET_COMMON_MODULE_PAGE,
      SET_COMMON_MODULE_SELECTED_ITEMS,
      SET_TASK_CHILDS,
      REFRESH_SECTIONS,
      SET_COMMON_MODULE_SUBTASK,
      SET_COMMON_MODULE_POPUP,
    ]),
  },
};
