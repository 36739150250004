import * as c from "./constants";
import Api from "@/api/sales";
import { showAlert } from "@/utils";
import { systemTypesDict } from "../../mixins/sales/SalesJSONS";
import * as _ from "lodash";

export default {
  [c.GET_SETTINGS](state, isFromSave = false) {
    state.dispatch(c.GET_EXAMPLES_OBJECTS);
    state.dispatch(c.GET_COMMERCIAL_SITE_URL);

    Api.getSettings()
      .then((res) => {
        if (res.data.status === "success") {
          if (Object.keys(res.data.settings).length > 4) {
            state.commit(c.GET_SETTINGS, res.data);
            if (isFromSave) {
              state.commit(c.RESET_IMAGES);
              state.dispatch(c.CREATE_DUMP_OF_SETTINGS);
            }
          }
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.SAVE_SETTINGS](state) {
    let prefix = "";

    switch (state.getters.config.system) {
      case "economy": {
        prefix = "filesEconomy_";
        break;
      }
      case "qr":
        prefix = "filesQr_";
        break;
    }
    state.commit(c.TOGGLE_PRELOADER, { type: "main", status: true });

    const formData = new FormData();

    const settings = JSON.stringify(state.getters.settings);
    formData.append("settings", settings);

    _.each(state.getters.images, (images, name) => {
      if (name === "objects") {
        _.each(images, (images, name) => {
          if (images.length) {
            _.each(images, (i) =>
              formData.append(
                prefix +
                  "objects_" +
                  name +
                  "[" +
                  prefix +
                  "objects_" +
                  name +
                  "_" +
                  i.id +
                  "]",
                i.file
              )
            );
          }
        });
      } else if (name === "icons") {
        _.each(images, (images, name) => {
          if (images.length) {
            _.each(images, (i) =>
              formData.append(
                prefix +
                  "icons_" +
                  name +
                  "[" +
                  prefix +
                  "icons_" +
                  name +
                  "_" +
                  i.id +
                  "]",
                i.file
              )
            );
          }
        });
      } else if (name === "devices") {
        _.each(images, (images, name) => {
          if (images.length) {
            _.each(images, (i) =>
              formData.append(
                prefix +
                  "devices_" +
                  name +
                  "[" +
                  prefix +
                  "devices_" +
                  name +
                  "_" +
                  i.id +
                  "]",
                i.file
              )
            );
          }
        });
      } else {
        if (images.length) {
          _.each(images, (i) =>
            formData.append(
              prefix + name + "[" + prefix + name + "_" + i.id + "]",
              i.file
            )
          );
        }
      }
    });
    const pdfFieldsArray = [
      "pdf_1",
      "pdf_2",
      "pdf_3",
      "pdf_tc",
      "pdf_bc",
      "pdf_zk",
      "pdf_hotels",
      "pdf_air",
      "pdf_sport",
      "pdf_parks",
      "pdf_others",
    ];
    let canSave = true;
    _.each(pdfFieldsArray, (pdfFile) => {
      const file = state.getters.settings[pdfFile].file;
      const img = state.getters.settings[pdfFile].img;
      if (file && file.name !== void 0) {
        if (!img.length && typeof img !== "object") {
          canSave = false;
          showAlert("error", "Не указана обложка для поля " + pdfFile);
          return false;
        }
        formData.append(prefix + pdfFile + "[file]", file);
      }
      if (img && img.name !== void 0) {
        if (!file.length && typeof file !== "object") {
          canSave = false;
          showAlert("error", "Не указан файл пдф для поля " + pdfFile);
          return false;
        }
        formData.append(prefix + pdfFile + "[img]", img);
      }
    });
    if (!canSave) {
      state.commit(c.TOGGLE_PRELOADER, { type: "main", status: false });
      return false;
    }

    Api.saveSettings(formData, state.getters.config.system)
      .then((res) => {
        showAlert(res.data.status, res.data.message);
        state.dispatch(c.GET_SETTINGS, true);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      })
      .finally(() => {
        state.commit(c.TOGGLE_PRELOADER, { type: "main", status: false });
      });
  },
  [c.GET_EXAMPLES_OBJECTS](state) {
    Api.getExamplesObjects()
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_EXAMPLES_OBJECTS, res.data);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.GET_COMMERCIAL_SITE_URL](state) {
    Api.getCommercialSiteUrl()
      .then((res) => {
        state.commit(
          c.GET_COMMERCIAL_SITE_URL,
          res.data.domain !== void 0 ? res.data.domain : ""
        );
      })
      .catch((err) => {
        state.commit(c.GET_COMMERCIAL_SITE_URL, "http://new.r-p-s.ru");
        showAlert("error", "Не удалось получить домен сайта", err.message);
      });
  },
  [c.RESET_OFFER](state) {
    state.commit(c.RESET_OFFER);
  },
  [c.SAVE_OFFER](state, { type = false, rateValue = false }) {
    // state.commit(c.TOGGLE_PRELOADER, { type: 'main', status: true })

    if (type) {
      if (type === "clear_copy") {
        state.commit(c.CREATE_CLEAR_COPY_OFFER);
        const rate = rateValue ? rateValue : state.getters.rateValue;
        state.dispatch(c.CONVERT_TO_RUB, {
          newValue: rate,
          oldValue: rate,
        });
      } else {
        state.commit(c.CREATE_COPY_OFFER);
      }
    }

    const offer = JSON.stringify(state.getters.offer);
    const formData = new FormData();

    const systemType = systemTypesDict[state.getters.offer.SystemType];
    const rateValueForFormData = rateValue
      ? rateValue
      : state.getters.offer.rateValue;

    formData.append("SystemType", systemType);
    formData.append("Price", state.getters.totalSumPrice);
    formData.append("OfferData", offer);
    formData.append("rateValue", rateValueForFormData);
    formData.append("ManagerName", state.getters.offer.ManagerName);
    formData.append("ManagerId", state.getters.offer.ManagerId);
    formData.append("Name", state.getters.offer.Name);
    formData.append("Status", state.getters.offer.Status);
    _.each(state.getters.offerImages, (images, name) => {
      if (images.length) {
        _.each(images, (i) =>
          formData.append(name + "[" + name + "_" + i.id + "]", i.file)
        );
      }
    });
    return new Promise((resolve, reject) => {
      Api.saveOffer(formData)
        .then((res) => {
          showAlert("success", "Предложение сохранено");
          state.commit(c.RESET_OFFER);
          // state.dispatch(c.GET_OFFERS)
          state.commit(c.SAVE_OFFER, res.data.model);
          // state.commit(c.ADD_OFFER, res.data)
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          reject(err);
        })
        .finally(() => {
          state.commit(c.TOGGLE_PRELOADER, { type: "main", status: false });
        });
    });
  },
  [c.GET_OFFERS](state, params = null) {
    Api.getOffers(params)
      .then((res) => {
        if (res.data.status === "success") {
          if (params && params.page > 0) {
            state.commit(c.ADD_OFFERS_TO_LIST, res.data.items);
          } else {
            state.commit(c.GET_OFFERS, res.data.items);
          }
        } else {
          showAlert("error", "Ошибка получения КП");
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.TOGGLE_PRELOADER](state, data) {
    state.commit(c.TOGGLE_PRELOADER, data);
  },
  [c.UPDATE_OFFER](state) {
    return new Promise((resolve, reject) => {
      state.commit(c.TOGGLE_PRELOADER, { type: "main", status: true });

      const offer = JSON.stringify(state.getters.offer);
      const formData = new FormData();

      const systemType = systemTypesDict[state.getters.offer.SystemType];

      formData.append("SystemType", systemType);
      formData.append("Price", state.getters.totalSumPrice);
      formData.append("OfferData", offer);
      formData.append("Status", state.getters.offer.Status);
      formData.append("rateValue", state.getters.offer.rateValue);
      formData.append("ManagerId", state.getters.offer.ManagerId);
      formData.append("UserId", state.getters.offer.ManagerId);
      formData.append("ManagerName", state.getters.offer.ManagerName);
      formData.append("Name", state.getters.offer.Name);
      formData.append("_method", "PATCH");

      _.each(state.getters.offerImages, (images, name) => {
        if (images.length) {
          _.each(images, (i) =>
            formData.append(name + "[" + name + "_" + i.id + "]", i.file)
          );
        }
      });

      Api.updateOffer(formData, state.getters.offer.Id)
        .then((res) => {
          showAlert("success", "Предложение обновлено");
          state.commit(c.UPDATE_OFFER, res.data.model);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          reject();
        })
        .finally(() => {
          state.commit(c.CHANGE_COMMON_SETTINGS, {
            field: "rendering",
            value: false,
          });
          state.commit(c.TOGGLE_PRELOADER, { type: "main", status: false });
        });
    });
  },
  [c.REMOVE_OFFER](state, id = false) {
    state.commit(c.TOGGLE_PRELOADER, { type: "main", status: true });

    id = id ? id : state.getters.offer.Id;

    Api.removeOffer(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.REMOVE_OFFER, id);
          state.commit(c.RESET_OFFER);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      })
      .finally(() => {
        state.commit(c.TOGGLE_PRELOADER, { type: "main", status: false });
      });
  },
  [c.CREATE_DUMP_OF_SETTINGS](state) {
    state.commit(c.CREATE_DUMP_OF_SETTINGS);
  },
  [c.RESTORE_SETTINGS_FROM_DUMP](state) {
    state.commit(c.RESET_IMAGES);
    state.commit(c.RESTORE_SETTINGS_FROM_DUMP);
  },
  [c.CHANGE_COMPARE_OBJECT](state, data) {
    state.commit(c.CHANGE_COMPARE_OBJECT, data);
  },
  [c.GET_RATE_VALUE](state) {
    Api.getRateValue()
      .then((res) => {
        state.commit(c.GET_RATE_VALUE, res.data.USD.Value);
      })
      .catch(() => {
        showAlert("error", "Ошибка", "Не удалось получить курс валюты");
      });
  },
  [c.CONVERT_TO_RUB](state, rateValue) {
    state.commit(c.CONVERT_TO_RUB, rateValue);
  },
  [c.CREATE_OFFER_LINK](state, offerPageState) {
    return new Promise((resolve, reject) => {
      Api.createOfferLink(state.getters.offer.Id, offerPageState)
        .then((res) => {
          if (res.data.status === "success") {
            if (offerPageState === 1) {
              const html = document.querySelector(".print-pdf-document")
                .innerHTML;

              let systemTypePrefix = "";

              if (
                state.getters.offer.System &&
                state.getters.offer.System !== "standart"
              ) {
                systemTypePrefix = state.getters.offer.System;
              }

              Api.buildOfferPage(state.getters.offer.Id, html, {
                ManagerName: state.getters.offer.ManagerName,
                ManagerPhone: state.getters.offer.ManagerPhone,
                ManagerEmail: state.getters.offer.ManagerEmail,
                ObjectName: state.getters.offer.Name,
                CompanyName: state.getters.offer.CompanyName,
                ObjectType: state.getters.offer.ObjectType,
                ClientName: state.getters.offer.ClientName,
                SystemTypePrefix: systemTypePrefix,
              })
                .then((res) => {
                  resolve(res);
                  showAlert(res.data.status, res.data.message);
                  state.commit(c.CREATE_OFFER_LINK, res.data.link);
                })
                .catch((err) => {
                  reject(err);
                  showAlert("error", "Ошибка", err.message);
                });
            } else {
              showAlert("success", "Ссылка удалена");
              state.commit(c.REMOVE_OFFER_LINK);
            }
          }
        })
        .finally(() => {
          // state.dispatch(c.GET_OFFERS)
        })
        .catch((err) => {
          reject(err);
          showAlert("error", "Ошибка", err.message);
        });
    });
  },
  [c.CHANGE_SYSTEM](state, type) {
    state.commit(c.CHANGE_SYSTEM, type);
    state.commit(c.RESET_IMAGES);
    state.dispatch(c.CREATE_DUMP_OF_SETTINGS);
  },
};
