/* eslint-disable quotes */
import { TOGGLE_PRELOADER } from "@/store/common/constants";
import Vue from "vue";

export const togglePreloader = (state, status) => {
  state.dispatch(TOGGLE_PRELOADER, status, { root: true });
};
export const showAlert = (type, title, text) => {
  Vue.prototype.$notify({
    group: "common",
    title,
    text,
    type,
  });
};
export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + // eslint-disable-line
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
export const makeSound = () => {
  var audio = new Audio("/static/sounds/noti.mp3");
  audio.play();
};

export function filesChange(fileList) {
  if (!fileList.length) return;
  const fileArray = [];
  Array.from(Array(fileList.length).keys()).map((x) => {
    fileArray.push({
      file: fileList[x],
      name: fileList[x].name,
      img: window.URL.createObjectURL(fileList[x]),
      id: Math.random().toString(36).substring(7),
    });
  });
  return fileArray;
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const numWord = (value, words) => {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
};

export const transliterate = (text, engToRus) => {
  var rus = "щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(
      / +/g
    ),
    eng = "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(
      / +/g
    );
  var x;
  for (x = 0; x < rus.length; x++) {
    text = text
      .split(engToRus ? eng[x] : rus[x])
      .join(engToRus ? rus[x] : eng[x]);
    text = text
      .split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase())
      .join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
  }
  return text;
};

export const iOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};
