<template>
  <div id="entry-wrap" class="scroll-style">
    <div
      class="pdf-cont"
      v-for="(page, index) in makeOptionsQuery('Entry', 'rack')"
      :key="'page-' + index"
    >
      <div class="print">
        <div class="print__cont">
          <div class="flex-wrap">
            <div class="col-auto">
              <div class="print__logo">
                <img :src="getimgStaticSrc('sales/print-logo.svg')" />
              </div>
            </div>
            <div class="col-auto ml-auto">
              <div class="print__header-title">Оборудование<br />и опции</div>
            </div>
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            <template v-if="offerObject.json.SystemType === 'Tokensdbl'">
              Модуль <br />
              въезда/выезда
            </template>
            <template v-else>
              <template
                v-if="['economy', 'qr'].includes(offerObject.json.System)"
                >Модуль <br />въезда</template
              >
              <template v-else>Модуль въезда</template>
            </template>
            <br />
            {{ systemName }}
            <i
              v-if="
                imageBlobCheck(
                  'devices',
                  systemTypeForPreview + '_entry_rack',
                  1
                )
              "
              :style="{
                backgroundImage: `url(${settingsObject.json.devices[systemTypeForPreview].entry_rack.blob})`,
              }"
              class="print__icon--entry-modules"
            ></i>
            <i
              v-else
              :style="{
                backgroundImage: `url(${getImageSrc(
                  offerObject.json.Modules.Entry.img_rack,
                  offerObject.json.System
                )})`,
              }"
              class="print__icon--entry-modules"
            ></i>
          </div>
          <div class="print-table">
            <div class="print-table__header">
              <div class="col print-table__th">наименование</div>
              <div class="print-table__th print-table__num">кол-во</div>
              <div class="print-table__th print-table__price">
                цена/ед. {{ getCurrencyAbbreviation }}
              </div>
              <div class="print-table__th print-table__sum">
                сумма, {{ getCurrencyAbbreviation }}.
              </div>
            </div>

            <template v-for="(item, index) in page">
              <div
                class="print-table__row"
                v-if="item.is_device"
                :key="index + 'xf'"
                :class="getSubRowClass(item)"
              >
                <div
                  class="col print-table__row-td print-table__row--device-name"
                >
                  <div class="print__checkbox print__checkbox--active">
                    <div
                      class="icon icon--checkbox"
                      :style="{
                        backgroundImage: `url(${getimgStaticSrc(
                          'sales/check_icon.png'
                        )})`,
                      }"
                    ></div>
                  </div>
                  <span>{{ item.name }}</span>
                </div>
                <div class="print-table__row-td print-table__num">
                  {{ item.count }}
                </div>
                <div class="print-table__row-td print-table__price">
                  {{ item.convertCost | moneyFormat }}
                </div>
                <div class="print-table__row-td print-table__sum">
                  {{ getOptionSumPrice(item) }}
                </div>
              </div>
              <div
                class="print-table__sub-row"
                v-else
                :class="getSubRowClass(item)"
                :key="index"
              >
                <div class="col print-table__sub-row-td print-table__name">
                  <div
                    class="print__checkbox"
                    :class="!item.count ? '' : 'print__checkbox--active'"
                  >
                    <div
                      class="icon icon--checkbox"
                      :style="{
                        backgroundImage: `url(${getimgStaticSrc(
                          'sales/check_icon.png'
                        )})`,
                      }"
                    ></div>
                  </div>
                  <a :href="'#id' + item.randomId">{{ item.name }}</a>
                </div>
                <div class="print-table__sub-row-td print-table__num">
                  {{ item.count }}
                </div>
                <div class="print-table__sub-row-td print-table__price">
                  {{ item.convertCost | moneyFormat }}
                </div>
                <div class="print-table__sub-row-td print-table__sum">
                  {{ getOptionSumPrice(item) }}
                </div>
              </div>
            </template>

            <div
              class="print-table__total"
              v-if="pageNums.EntryLastPage === +index"
            >
              <div class="col print-table__total-td">Итого:</div>
              <div class="print-table__total-td print-table__num">
                {{ getAmount("Entry", "count", "rack") }}
              </div>
              <div class="print-table__total-td print-table__price"></div>
              <div class="print-table__total-td print-table__sum">
                {{ getAmount("Entry", "cost", "rack") }}
              </div>
            </div>
          </div>
        </div>
        <div class="print__footer">{{ countPage("Entry", +index) }}</div>
      </div>
    </div>

    <div
      class="pdf-non-print-text"
      v-if="offerObject.json.passPages.entry_rack"
    >
      <span>Страница не будет добавлена в PDF</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as _ from "lodash";

export default {
  data() {
    return {
      col_1_is_full: false,
    };
  },
  created() {
    window.offerModuleTitles.Entry = 0;
    this.offerObject.openedPages.Entry = 1;
  },
  mixins: [SalesHelpers],
  computed: {
    specialOptions() {
      return _.filter(
        this.offerObject.json.Modules.Entry.rack,
        (item, index) => {
          let can = true;
          if (index !== 0) {
            can = false;
          }
          if (index <= this.MODULE_PAGE_LIMIT) {
            // console.log(index, this.MODULE_PAGE_LIMIT)
            this.col_1_is_full = false;
            can = true;
          } else {
            // console.log(index, 's')
            this.col_1_is_full = true;

            can = false;
          }
          return can && !item.is_device && !item.falseOption;
        }
      );
    },

    ...mapState("sales", ["offerObject", "pageNums"]),
    ...mapGetters("sales", ["getCurrencyAbbreviation"]),
  },
};
</script>
