<template>
  <div
    class="task-create-form__row task-create-form__row--files task-create-form__row--show-all"
  >
    <label class="task-create-form__row-left">
      <input
        type="file"
        hidden
        @change="filesChange($event.target.files)"
        multiple
      />
      <i class="task-form-icon task-form-icon--9"></i>
    </label>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title task-bubble-wrap">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Документы на подпись:</p>
          <div class="task-create-form__field-value">
            <SwitcherForHideField
              v-if="!noSave"
              @saveAfterEdit="
                (formData, callback, field) =>
                  $emit('saveAfterEdit', formData, callback, field)
              "
              :form="form"
              :field="'DocFiles'"
            />
          </div>
        </div>
      </div>
      <label
        class="files-dragzone"
        :class="{ 'files-dragzone--dragover': filesDragZoneShow }"
        @dragover.prevent="showDragZone()"
        @dragleave="hideDragZone"
        @drop.prevent="
          (e) => {
            filesChange(e.dataTransfer.files);
            hideDragZone();
          }
        "
      >
        <input
          type="file"
          hidden
          @change="filesChange($event.target.files)"
          multiple
        />
        <svg
          width="39"
          height="32"
          class="files-dragzone__icon"
          v-if="!files.length"
        >
          <use xlink:href="/static/images/tasks/sprite.svg#upload-icon"></use>
        </svg>
        <div class="task-popup-files">
          <div
            class="sup-chat-files__item"
            v-for="(item, index) in files"
            :key="index"
          >
            <img
              :src="item.file ? item.Link : getFileSrc(item.Link)"
              v-if="fileIsImage(item.file ? item.Name : item.Link)"
              @click.prevent="showImagePopup(item.Link)"
            />
            <a
              class="sup-chat-files__item-link"
              download
              v-else
              @click.prevent="
                downloadURI(getFileSrc(item.Link), item.Name, item.Id)
              "
              :href="getFileSrc(item.Link)"
            >
              <img
                :src="
                  getNonImageTypeFileIcon(item.file ? item.Name : item.Link)
                "
              />
              <p>{{ item.Name }}</p>
            </a>

            <div
              class="sup-chat-files__item-remove"
              @click.prevent="deleteFileFromTask(item.Id, index)"
            >
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import * as _ from "lodash";
import SwitcherForHideField from "@/modules/tasks/components/TaskCreateForm/Inputs/SwitcherForHideField";

export default {
  mixins: [common],
  props: ["form", "taskFilesListElement", "taskId", "noSave"],
  data() {
    return {
      filesDragZoneShow: false,
    };
  },
  computed: {
    files() {
      return _.filter(this.form.Files, (i) => i.SignDocs);
    },
  },
  methods: {
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.form.Files.push({
          file: fileList[x],
          Name: fileList[x].name,
          Link: window.URL.createObjectURL(fileList[x]),
          Id: Math.random().toString(36).substring(7),
          SignDocs: 1,
        });
      });

      const formData = new FormData();
      formData.append("SignDocs", 1);
      formData.append("TaskId", this.taskId);
      if (this.form.Files.length) {
        _.each(this.form.Files, (i) => {
          if (i.file) {
            formData.append("Files[]", i.file, i.file.name);
          }
        });
      }
      this.$emit("saveAfterEdit", formData, (files) => {
        this.form.Files = _.reject(this.form.Files, (i) => i.file);
        if (files) {
          this.form.Files = files;
        }
      });
    },
    deleteFileFromTask(Id, index) {
      if (Id) {
        const filesCopy = [...this.form.Files];
        this.form.Files = _.reject(this.form.Files, { Id });
        this.$emit("removeFile", Id, () => (this.form.Files = filesCopy));
      } else {
        this.form.Files.splice(index, 1);
      }
    },
    showDragZone() {
      this.filesDragZoneShow = true;
    },
    hideDragZone() {
      this.filesDragZoneShow = false;
    },
  },
  components: { SwitcherForHideField },
};
</script>
<style scoped>
.task-create-form__row-left {
  cursor: pointer;
}
.task-create-form__field-value {
  margin-left: auto;
  margin-right: -38px;
}
.task-create-form__name-wrap {
  margin-bottom: 14px;
}
</style>
