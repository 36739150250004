<template>
  <div :class="'page-' + module">
    <div class="actions-header">
      <!--v-if="isHeaderNeed"-->
      <div class="actions-header__col" v-if="module !== 'promo_landing'">
        <router-link
          v-if="!selectedItems.length"
          class="button button_green button_uppercase settings-devices-zone__add"
          :to="'/commercial_site/' + module + '/create'"
          >Добавить
        </router-link>
        <a
          v-else
          class="button button_red button_uppercase settings-devices-zone__add"
          @click="removeItems"
          href="#"
          >Удалить</a
        >
      </div>

      <div class="actions-header__col actions-header__col_push_right">
        <span class="icon-button">
          <span
            class="icon-button__container"
            @click="settingsWindowOpen = true"
          >
            <svg class="icon-button__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-settings"
              ></use>
            </svg>
          </span>
        </span>
      </div>
    </div>

    <!--  -->
    <div class="table" v-if="module === 'commonTranslations'">
      <div class="table__header">
        <div class="table__col table__col_width_360">
          <p class="table__header-text">Название</p>
          <div
            class="table__filter-button"
            @click="
              () => {
                order = order === 'asc' ? 'desc' : 'asc';
                column = 'Name';
              }
            "
            style="margin-left: 10px"
          >
            {{ order === "asc" ? "А-Я" : "Я-А" }}
          </div>
        </div>

        <div class="table__col table__col_width_200">
          <p class="table__header-text">Перевод</p>
        </div>
      </div>
      <div class="table__row-wrap" style="height: calc(100vh - 160px)">
        <template>
          <div
            class="table__row"
            v-for="(item, index) in filteredItems"
            :key="index + 'item'"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <p class="table__header-text">{{ index }}</p>
                </div>
              </div>
              <div class="table__col table__col_width_200">
                <input
                  class="input"
                  type="text"
                  v-model="item.TranslatedValue"
                  @blur="saveTranslate($event, item)"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="table-spinner" v-show="preloader">
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
      </div>
    </div>

    <div
      class="settings-devices-device__content"
      v-else-if="module === 'exportTranslations'"
    >
      <div class="setting-actions">
        <div class="setting-actions__heading">
          Экспорт \ Импорт языковых пакетов
        </div>
      </div>
      <div class="settings-devices-device__form">
        <fieldset class="fieldset">
          <div class="fieldset__content">
            <form
              :action="
                API_ROOT +
                'api/site/commonTranslations/exportCommonTranslations'
              "
              method="get"
              class="field__wrap"
            >
              <div class="field__title">Экспорт языковых пакетов:</div>
              <div class="field">
                <div
                  class="field__label field__label_align_right settings-devices-zone__label"
                >
                  Язык для экспорта:
                </div>
                <div class="field__content settings-devices-zone__field">
                  <div class="select">
                    <select
                      class="select__control"
                      id="export_lang"
                      name="lang"
                    >
                      <option value="ru">Русский</option>
                      <option value="en">English</option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="button button_green button_uppercase button_align_center field__btn"
              >
                Выгрузить
              </button>
            </form>
            <div class="field__wrap">
              <div class="field__title">Импорт языковых пакетов:</div>
              <div class="field">
                <div
                  class="field__label field__label_align_right settings-devices-zone__label"
                >
                  Язык для импорта:
                </div>
                <div class="field__content settings-devices-zone__field">
                  <div class="select">
                    <select
                      class="select__control"
                      ref="importLang"
                      name="lang"
                    >
                      <option value="ru">Русский</option>
                      <option value="en">English</option>
                    </select>
                    <div class="select__button">
                      <svg class="select__caret">
                        <use
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__file-upload">
                <input type="file" name="import" ref="importFile" />
              </div>
              <br />
              <button
                type="submit"
                @click="importLang"
                class="button button_green button_uppercase button_align_center field__btn"
              >
                Загрузить
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <!--    <DistrictsControlPage  v-else-if="module==='regions'"/>-->
    <div class="table" v-else>
      <div class="table__header">
        <div class="table__col table__col_width_360">
          <p class="table__header-text">Название</p>
          <button
            class="table-c__filter-btn"
            @click="makeSort('Name')"
            style="margin-right: 15px"
            :class="sorts.Name.active ? 'table-c__filter-btn--active' : ''"
          >
            {{ sorts.Name.asc ? "А-Я" : "Я-А" }}
          </button>
        </div>

        <div
          class="table__col table__col_width_200"
          v-if="['production'].includes(module)"
        >
          <p class="table__header-text">Категория</p>
        </div>

        <div
          class="table__col table__col--buttons table__col_width_200"
          v-if="['news', 'services', 'production'].includes(module)"
        >
          <p class="table__header-text">Дата создания</p>
          <button
            class="table-c__filter-btn"
            @click="makeSort('DateCreate')"
            :class="
              sorts.DateCreate.active ? 'table-c__filter-btn--active' : ''
            "
          >
            {{ sorts.DateCreate.asc ? "1-9" : "9-1" }}
          </button>
        </div>
        <div
          class="table__col table__col--buttons table__col_width_200"
          v-if="['examples'].includes(module)"
        >
          <p class="table__header-text">Дата создания</p>
          <button
            class="table-c__filter-btn"
            @click="makeSort('ObjectDate')"
            :class="
              sorts.ObjectDate.active ? 'table-c__filter-btn--active' : ''
            "
          >
            {{ sorts.ObjectDate.asc ? "1-9" : "9-1" }}
          </button>
        </div>
      </div>
      <div class="table__row-wrap" style="height: calc(100vh - 160px)">
        <draggable
          v-if="
            [
              'solutions',
              'services',
              'production',
              'productionCategories',
              'staticPages',
            ].includes(module)
          "
          v-model="items"
          @start="ordering = false"
          @end="changeSliderOrder()"
        >
          <div
            class="table__row"
            v-for="(item, index) in ordering ? filteredItems : items"
            @end="changeSliderOrder()"
            :key="index + 'drag-item'"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <label
                    class="checkbox checkbox_white table__checkbox table__checkbox-row"
                  >
                    <input
                      class="checkbox__input main_checkbox_input offer-checkbox"
                      type="checkbox"
                      name="row_checkbox_clients"
                      @click="selectItem($event, item.Id)"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </label>
                  <router-link
                    :to="'/commercial_site/' + module + '/' + item.Id"
                    class="table__text cm-link"
                  >
                    {{ getItemName(item) }}
                  </router-link>
                </div>
              </div>

              <div
                class="table__col table__col_width_200"
                v-if="item.CategoryId"
              >
                <div class="table__text-block">
                  <div class="table__header-text">
                    {{ getCategoryName(item.CategoryId) }}
                  </div>
                </div>
              </div>

              <div
                class="table__col table__col_width_200"
                v-if="item.ObjectDate"
              >
                <div class="table__text-block">
                  <div class="table__header-text">
                    {{ item.ObjectDate | getDate }}
                  </div>
                </div>
              </div>
              <div
                class="table__col table__col_width_200"
                v-if="item.DateCreate"
              >
                <div class="table__text-block">
                  <div class="table__header-text">
                    {{ item.DateCreate | getDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <template v-else>
          <div
            class="table__row"
            v-for="(item, index) in filteredItems"
            :key="index + 'item'"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <label
                    class="checkbox checkbox_white table__checkbox table__checkbox-row"
                  >
                    <input
                      class="checkbox__input main_checkbox_input offer-checkbox"
                      type="checkbox"
                      name="row_checkbox_clients"
                      @click="selectItem($event, item.Id)"
                    />
                    <span class="checkbox__box main_checkbox_span">
                      <svg class="checkbox__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-check"
                        ></use>
                      </svg>
                    </span>
                  </label>
                  <router-link
                    :to="'/commercial_site/' + module + '/' + item.Id"
                    class="table__text cm-link"
                  >
                    {{ getItemName(item) }}
                  </router-link>
                </div>
              </div>

              <div
                class="table__col table__col_width_200"
                v-if="item.DateCreate"
              >
                <div class="table__text-block">
                  <div class="table__header-text">
                    {{ item.DateCreate | getDate }}
                  </div>
                </div>
              </div>
              <div
                class="table__col table__col_width_200"
                v-if="item.ObjectDate"
              >
                <div class="table__text-block">
                  <div class="table__header-text">
                    {{ item.ObjectDate | getDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Еще не понял смысла этой конструкции, пусть лучше она будет в template -->
        <template v-if="module === 'promo_landing'">
          <div class="table__row">
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <router-link
                    :to="`/commercial_site/${module}/landing`"
                    class="table__text cm-link"
                    >Лендинг</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table__row">
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <router-link
                    :to="`/commercial_site/${module}/remote_landing`"
                    class="table__text cm-link"
                    >Лендинг на поддомене</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table__row">
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <router-link
                    :to="`/commercial_site/${module}/partner_landing`"
                    class="table__text cm-link"
                    >Лендинг для партнеров</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table__row">
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <router-link
                    :to="`/commercial_site/${module}/soft_landing`"
                    class="table__text cm-link"
                    >Лендинг ПО</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="table-spinner" v-show="preloader">
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
      </div>
    </div>

    <router-view
      :module="module"
      :items="items"
      :selectListOptions="selectListOptions"
      @getItems="getItems"
      @changeItems="changeItems"
    />

    <object-settings
      v-if="settingsWindowOpen"
      :module="module"
      @closeSettings="settingsWindowOpen = false"
    />
  </div>
</template>

<script>
import CommercialSiteTableItems from "@/mixins/site/CommercialSiteTableItems";
import ObjectSettings from "./ObjectSettings";
import Api from "@/api/commercial_site";
import Spinner from "vue-spinkit";
import { showAlert } from "../../utils";
import * as c from "../../store/commercial_site/constants";
import { mapState, mapActions, mapMutations } from "vuex";
import draggable from "vuedraggable";
import * as _ from "lodash";
import { API_ROOT } from "../../config";

export default {
  mixins: [CommercialSiteTableItems],
  props: ["module"],
  data() {
    return {
      items: null,
      selectedItems: [],
      order: "asc",
      settingsWindowOpen: false,
      preloader: false,
      sorts: {
        Name: {
          asc: true,
          active: true,
        },
        DateCreate: {
          asc: false,
          active: false,
        },
        ObjectDate: {
          asc: false,
          active: false,
        },
      },
      selectListOptions: [],
      API_ROOT,
      ordering: false,
      orderByName: true,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    $route(to, from) {
      if (to.name === "module" && from.name !== "module_item") {
        this.resetState();
        this.getItems();
      }
    },
  },
  computed: {
    isHeaderNeed() {
      // return true
      if (this.module === "blocks_of_company") return true;
      return (
        this.module !== "blocks" &&
        this.module.substr(0, 9) !== "blocks_of" &&
        !["commonTranslations", "exportTranslations"].includes(this.module)
      );
    },
    ...mapState("site", ["blocks"]),
  },

  methods: {
    resetState() {
      this.items = null;
      this.selectedItems = [];
      this.sorts = {
        Name: {
          asc: true,
          active: true,
        },
        DateCreate: {
          asc: false,
          active: false,
        },
        ObjectDate: {
          asc: false,
          active: false,
        },
      };
      this.ordering = false;
    },
    getItems(additionalData = null) {
      if (
        this.module !== "exportTranslations" &&
        this.module !== "promo_landing"
      ) {
        if (!additionalData || additionalData.manualPreloader === void 0) {
          this.preloader = true;
        }
        if (this.module.substr(0, 9) !== "blocks_of") {
          Api.get(this.module)
            .then((res) => {
              this.items = res.data;
              if (additionalData && additionalData.action !== void 0) {
                const createdElement = _.find(this.items, [
                  "Name",
                  additionalData.name,
                ]);
                switch (additionalData.action) {
                  case "reopen":
                    if (createdElement) {
                      this.$router.push(
                        "/commercial_site/" +
                          this.module +
                          "/" +
                          createdElement.Id
                      );
                      break;
                    } else {
                      this.$router.push("/commercial_site/" + this.module);
                    }
                }
              }
              if (this.module === "commonTranslations") {
                _.each(this.items, (lang) => {
                  _.map(lang, (i) => {
                    i.originalValue = i.TranslatedValue;
                    return i;
                  });
                });
              }
              if (this.module === "news") {
                this.items = _.orderBy(this.items, ["DateCreate"], ["desc"]);
              }
              if (this.module === "production") {
                Api.getProductionCategories()
                  .then((res) => {
                    this.selectListOptions = res.data;
                  })
                  .catch((err) => {
                    showAlert("error", "Ошибка", err.message);
                  });
                // .finally(_ => {
                //   this.$emit('setPreloader', false)
                // })
              }
              if (this.module !== "blocks") this[c.GET_BLOCKS]();
              else this[c.SET_BLOCKS](this.items);
            })
            .catch((err) => {
              showAlert("error", "Ошибка", err.message);
            })
            .finally(() => {
              if (
                !additionalData ||
                additionalData.manualPreloader === void 0
              ) {
                this.preloader = false;
              }
            });
        } else {
          Api.get("blocks")
            .then((res) => {
              this.items = res.data;
              this.items = _.filter(res.data, (i) => {
                return i.UniqueName.includes(this.module.substr(10));
              });
            })
            .catch((err) => {
              showAlert("error", "Ошибка", err.message);
            })
            .finally(() => (this.preloader = false));
        }
        if (this.module === "examples") {
          this[c.WORK_TYPES_ACTION]({ action: "get" });
          this[c.GET_CITIES](true);
        }
        if (this.module === "BlogPosts") {
          this[c.BLOG_CAT_ACTION]({ action: "get" });
        }
      }
    },
    changeItems(items) {
      this.items = items;
    },
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.selectedItems.includes(id)) {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    removeItems(e) {
      if (!confirm("Вы действительно хотите это сделать?")) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        document.querySelector("#app").classList.add("app--load");
        new Promise((resolve, reject) => {
          _.each(this.selectedItems, (id) => {
            Api.delete(this.module, id)
              .then(() => {
                if (this.selectedItems.slice(-1)[0] === id) {
                  resolve("success");
                }
              })
              .catch((err) => {
                showAlert("error", "Ошибка", err.message);
                reject(err);
              });
          });
        })
          .then(() => {
            this.getItems();
          })
          .finally(() => {
            document.querySelectorAll(".offer-checkbox").forEach((i) => {
              i.checked = false;
            });
            this.selectedItems = [];
            document.querySelector("#app").classList.remove("app--load");
          });
      }
    },
    getItemName(item) {
      return item.Name
        ? item.Name
        : item.Title
        ? item.Title
        : item.SlideTitle
        ? item.SlideTitle
        : item.CategoryName;
    },
    changeSliderOrder() {
      this.preloader = true;

      Api.updateEntityOrder(this.module, this.items)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    saveTranslate(e, item) {
      if (item.TranslatedValue !== item.originalValue) {
        this.preloader = true;
        const formData = new FormData();
        formData.append("_method", "PATCH");
        formData.append("TranslatedValue", item.TranslatedValue);

        Api.setCommonTranslation(item.Id, formData)
          .then(() => {
            showAlert("success", "Успешно");
            this.getItems();
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            this.preloader = false;
          });
      }
    },
    makeSort(type) {
      this.ordering = true;
      _.each(this.sorts, (object, name) => {
        if (name !== type) {
          object.asc = false;
          object.active = false;
        }
      });
      this.sorts[type].active = true;
      this.sorts[type].asc = !this.sorts[type].asc;

      // console.log([type], [this.sorts[type].asc])
      // this.items = _.orderBy(this.items, [type], [this.sorts[type].asc ? 'asc' : 'desc'])
    },
    getCategoryName(Id) {
      const option = _.find(this.selectListOptions, { Id });
      if (option) {
        return option.Name;
      }
    },
    importLang() {
      const file = this.$refs.importFile.files;
      if (file.length) {
        const formData = new FormData();
        formData.append("import", file[0], file[0].name);
        formData.append("lang", this.$refs.importLang.value);
        // console.log(formData)
        this[c.IMPORT_LANG](formData);
      }
    },
    ...mapActions("site", [
      c.GET_BLOCKS,
      c.WORK_TYPES_ACTION,
      c.GET_CITIES,
      c.IMPORT_LANG,
      c.BLOG_CAT_ACTION,
    ]),
    ...mapMutations("site", [c.SET_BLOCKS]),
  },
  components: { ObjectSettings, Spinner, draggable },
};
</script>

<style scoped></style>
