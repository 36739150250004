<template>
  <div>
    <svg width="16" height="16">
      <use
        :xlink:href="
          '/static/images/tasks/notification-settings-table-icons/sprite.svg#' +
          icon
        "
      ></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NotificationSettingsTableCheckInput",
  props: ["active"],
  computed: {
    icon() {
      return this.active ? "circle-checked-icon" : "circle-bordered-icon";
    },
  },
};
</script>

<style scoped></style>
