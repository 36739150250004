import { render, staticRenderFns } from "./NotificationSettingsList.vue?vue&type=template&id=3553c83c&scoped=true&"
import script from "./NotificationSettingsList.vue?vue&type=script&lang=js&"
export * from "./NotificationSettingsList.vue?vue&type=script&lang=js&"
import style0 from "./NotificationSettingsList.vue?vue&type=style&index=0&id=3553c83c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3553c83c",
  null
  
)

export default component.exports