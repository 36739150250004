import * as _ from "lodash";

export default {
  computed: {
    routerParams() {
      let parents = [];
      let parentId = this.item.ParentId;
      const self = this;
      function findParent(item) {
        if (item.Id === parentId) {
          parentId = item.ParentId;
          parents.push(item);
          findArray();
        } else if (item.child && item.child.length) {
          _.each(item.child, (c) => {
            findParent(c);
          });
        }
      }
      function findArray() {
        _.each(self.articles, (a) => {
          findParent(a);
        });
      }
      findArray();

      let params = { name: "wiki", params: { category: this.categorySlug } };

      parents = parents.reverse();

      _.each(parents, (parent, index) => {
        const postIndex = index + 1;
        params.params["post" + postIndex] = parent.Name;
      });
      const postIndex = parents.length + 1;
      params.params["post" + postIndex] = this.item.Name;

      // console.log(params)
      return params;
    },
  },
};
