<template>
  <div class="task-bubble task-bubble--tag" v-click-outside="close">
    <div class="task-bubble__search">
      <svg>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="/static/images/icon.svg#icon_icon-search"
        ></use>
      </svg>
      <input
        type="text"
        class="input"
        placeholder="Поиск"
        v-model="search"
        ref="input"
      />
      <div class="task-bubble__close" @click="close()"><i></i></div>
    </div>
    <div class="task-bubble__list" ref="list" v-if="filteredItems.length">
      <div
        class="task-bubble__item task-bubble__item--delete"
        v-if="selectedId"
        @click="choose(null)"
      >
        Открепить
      </div>

      <div
        class="task-bubble__item"
        v-for="(item, index) in filteredItems"
        @click="choose(item.Id)"
        :key="index"
        :item-id="item.Id"
      >
        <div
          class="task-table-person__circle"
          :style="{ background: item.Color }"
        ></div>
        {{ item.Name }}
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";
import { mapState } from "vuex";

export default {
  mixins: [common, Helpers],
  props: {
    items: null,
    selectedId: null,
  },
  data() {
    return {
      search: "",
      allowTimeForClose: false,
    };
  },
  destroyed() {
    document.onkeydown = this.settings.keydownFunction;
  },
  mounted() {
    this.$refs["input"].focus();
    setTimeout(() => {
      this.allowTimeForClose = true;
    }, 200);
  },
  computed: {
    filteredItems() {
      return _.filter(_.orderBy(this.items, ["Name"], ["asc"]), (i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.Name);
      });
    },
    ...mapState("tasks", ["settings"]),
  },
  methods: {
    choose(id) {
      this.$emit("choose", id);
      this.close();
    },
    close() {
      if (this.allowTimeForClose) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped></style>
