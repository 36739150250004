import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  login(authData) {
    return axios.post(`${API_ROOT}api/auth/login/index/`, authData);
  },
  getUser(userId) {
    return axios.get(`${API_ROOT}api/clients/UserModel/${userId}`);
  },
  getNotifications() {
    return axios.get(`${API_ROOT}api/support/Request/Count`);
  },
  getUserData(userId) {
    return axios.get(`${API_ROOT}api/clients/UserModel/${userId}`);
  },
  getUsersByRight(payload) {
    return axios.post(
      `${API_ROOT}api/settings/userRights/GetUsersWithRights`,
      payload
    );
  },
  checkGuest() {
    return axios.get(`${API_ROOT}api/login/CheckGuest`);
  },
};
