<template>
  <div class="task-files-header-filter">
    <div class="task-files-header-filter__field">
      <div class="task-files-header-filter__label">Тип:</div>
      <div
        class="task-files-header-filter__items task-files-header-filter__items--types"
      >
        <div
          class="task-files-header-filter__item task-files-header-filter__item--border"
          :class="{
            'task-files-header-filter__item--active': !filterTypes.length,
          }"
          @click="filterTypes = []"
        >
          все
        </div>
        <div
          class="task-files-header-filter__item task-files-header-filter__item--border"
          :class="getFilterTypeClassName(item)"
          v-for="(item, index) in filesTypes"
          :key="index"
          @click="filterByType(item)"
        >
          <img :src="getNonImageTypeFileIcon('.' + item)" />
        </div>
      </div>
    </div>

    <!--<div class="task-files-header-filter__field">-->
    <!--<div class="task-files-header-filter__label">Тэги:</div>-->
    <!--<div class="task-files-header-filter__items">-->
    <!--<div class="task-files-header-filter__item-->
    <!--task-files-header-filter__item&#45;&#45;active">-->
    <!--<span class="task-label task-label&#45;&#45;1">Проектирование</span>-->
    <!--</div>-->
    <!--<div class="task-files-header-filter__item">-->
    <!--<span class="task-label task-label&#45;&#45;2">Требует внимания</span>-->
    <!--</div>-->
    <!--<div class="task-files-header-filter__item">-->
    <!--<span class="task-label task-label&#45;&#45;3">Сдача</span>-->
    <!--</div>-->
    <!--<div class="task-files-header-filter__item">-->
    <!--<span class="task-label task-label&#45;&#45;4">ПНР</span>-->
    <!--</div>-->
    <!--<div class="task-files-header-filter__item">-->
    <!--<span class="task-label task-label&#45;&#45;5">СМР</span>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <div class="task-files-header-filter__field">
      <div class="task-files-header-filter__label">Загрузил:</div>
      <div class="task-files-header-filter__items">
        <div
          class="task-files-header-filter__item task-files-header-filter__item--border"
          :class="getFilterPersonClassName(item)"
          v-for="(item, index) in loadPersons"
          :key="index"
          @click="filterByPerson(item)"
        >
          <div class="task-table-person">
            <UserAvatar :userId="item" />
            {{ getPersonNameById(item) | shortName }}
          </div>
        </div>
      </div>
    </div>

    <div class="task-files-header-filter__btns">
      <div>
        <div class="task-files-header-filter__btns-1" @click="cancel">
          Отмена
        </div>
        <div class="task-files-header-filter__accept" @click="$emit('close')">
          Применить
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import common from "../../../../../mixins/tasks/common";
import * as _ from "lodash";
import UserAvatar from "../../CommonComponents/UserAvatar";

export default {
  mixins: [common],
  props: ["files"],
  data() {
    return {
      filterTypes: [],
      filterPersons: [],
    };
  },
  computed: {
    filesTypes() {
      const arr = [];
      _.each(this.selectedTask.files, (i) => {
        const ext = i.Filename.split(".").pop().toLowerCase();
        if (!arr.includes(ext)) {
          arr.push(ext);
        }
      });
      return arr;
    },
    loadPersons() {
      const arr = [];
      _.each(this.selectedTask.files, (i) => {
        const id = i.UserId;
        if (!arr.includes(id)) {
          arr.push(id);
        }
      });
      return arr;
    },
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    filterByType(ext) {
      if (!this.filterTypes.includes(ext)) {
        this.filterTypes.push(ext);
      } else {
        this.filterTypes = _.reject(this.filterTypes, (i) => i === ext);
      }
    },
    getFilterTypeClassName(ext) {
      if (this.filterTypes.includes(ext)) {
        return "task-files-header-filter__item--active";
      }
    },
    filterByPerson(id) {
      if (!this.filterPersons.includes(id)) {
        this.filterPersons.push(id);
      } else {
        this.filterPersons = _.reject(this.filterPersons, (i) => i === id);
      }
    },
    getFilterPersonClassName(id) {
      if (this.filterPersons.includes(id)) {
        return "task-files-header-filter__item--active";
      }
    },
    cancel() {
      this.filterTypes = [];
      this.filterPersons = [];
      this.$emit("close");
    },
  },
  components: {
    UserAvatar,
  },
};
</script>

<style scoped></style>
