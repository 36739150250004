<template>
  <div class="popup-wrapper">
    <div
      class="popup task-popup task-popup-parent event-popup event-popup_active"
      v-popup-inner-windows="{
        state:
          selectItemsInnerPopupOpen ||
          parentData.processesCreatePopupOpen ||
          templateForEdit,
        level: 0,
        forceOffset: true,
        sumLevel: 0,
      }"
    >
      <div
        class="popup__wrapper"
        @mousedown="closePopupByClickWrap($event)"
        v-if="group"
      >
        <div class="popup__content">
          <div class="popup__header">
            <h2
              class="popup__heading"
              @click="selectItemsInnerPopupOpen = !selectItemsInnerPopupOpen"
            >
              Настройка группы «{{ group.GroupName }}»
            </h2>
            <div class="popup__close" @click="closePopup()">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body">
            <div class="cm-popup">
              <div class="cm-popup__left">
                <div
                  class="cm-popup-nav__item"
                  @click="tab = 1"
                  :class="{ 'cm-popup-nav__item--active': tab === 1 }"
                >
                  Общая информация
                </div>
                <div
                  class="cm-popup-nav__item"
                  @click="tab = 2"
                  :class="{ 'cm-popup-nav__item--active': tab === 2 }"
                >
                  Участники<span
                    class="num"
                    v-if="group.Users && group.Users.length > 1"
                    >{{ group.Users.length - 1 }}</span
                  >
                </div>
                <div
                  class="cm-popup-nav__item"
                  @click="tab = 3"
                  v-if="checkUserTaskRight('change_subscribers')"
                  :class="{ 'cm-popup-nav__item--active': tab === 3 }"
                >
                  Шаблоны<span
                    class="num"
                    v-if="templates && templates.length"
                    >{{ templates.length }}</span
                  >
                </div>
                <div
                  class="cm-popup-nav__item"
                  @click="tab = 4"
                  v-if="checkUserTaskRight('create_proccesses_and_statuses')"
                  :class="{ 'cm-popup-nav__item--active': tab === 4 }"
                >
                  Процессы
                  <span class="num">{{ groupProcesses.length }}</span>
                </div>
                <div class="task-popup__nav-item task-bubble-wrap">
                  <div class="task-popup__nav-name">Ответственный:</div>
                  <div class="task-popup__nav-person">
                    <transition name="fade">
                      <PersonsListSearch
                        v-if="responsibleBubbleOpen"
                        :selectedPersonId="group.UserId"
                        :emptyPlaceholder="'Убрать ответственного'"
                        :className="'task-bubble--templates'"
                        @choose="setResponsibleId"
                        @close="onCloseResponsiblBubble()"
                      />
                    </transition>
                    <UserAvatar :userId="group.UserId" />
                    <span
                      @click="responsibleBubbleOpen = true"
                      class="task-table-person__name"
                    >
                      <template v-if="!group.UserId">Назначить</template>
                      <template v-else>{{
                        getPersonNameById(group.UserId) | shortName
                      }}</template>
                    </span>
                  </div>
                </div>
                <div class="task-popup__nav-item task-bubble-wrap">
                  <div class="task-popup__nav-name">Процесс:</div>
                  <div
                    class="task-popup__nav-info"
                    @click="processesBubbleOpen = true"
                  >
                    <div class="task-popup__nav-title">
                      <span class="task-popup__nav-item-left">
                        <svg width="12" height="12">
                          <use
                            xlink:href="/static/images/tasks/sprite.svg#process-icon"
                          ></use>
                        </svg>
                      </span>
                      <template v-if="group && group.MainProcessId">{{
                        getProcessName(group.MainProcessId)
                      }}</template>
                      <template v-else>Назначить процесс</template>
                    </div>
                    <transition name="fade">
                      <ProcessesListSearch
                        v-if="processesBubbleOpen"
                        :selectedId="group.MainProcessId"
                        :className="'task-bubble--templates'"
                        @choose="setGroupProcess"
                        @close="() => (processesBubbleOpen = false)"
                      />
                    </transition>
                  </div>
                </div>

                <div class="task-popup__nav-item task-bubble-wrap">
                  <div class="task-popup__nav-name">Процесс по умолчанию:</div>
                  <div
                    class="task-popup__nav-info"
                    @click="processesBubbleOpen2 = true"
                  >
                    <div class="task-popup__nav-title">
                      <span class="task-popup__nav-item-left">
                        <svg width="12" height="12">
                          <use
                            xlink:href="/static/images/tasks/sprite.svg#process-icon"
                          ></use>
                        </svg>
                      </span>
                      <template v-if="group && group.DefaultProcessId">{{
                        getProcessName(group.DefaultProcessId)
                      }}</template>
                      <template v-else>Назначить процесс</template>
                    </div>
                    <transition name="fade">
                      <ProcessesListSearch
                        v-if="processesBubbleOpen2"
                        :selectedId="group.DefaultProcessId"
                        :className="'task-bubble--templates'"
                        @choose="setDefaultGroupProcess"
                        @close="() => (processesBubbleOpen2 = false)"
                      />
                    </transition>
                  </div>
                </div>

                <div class="cm-popup__down-btn" @click="deleteGroup">
                  <div class="button button_grey button_uppercase">
                    Удалить группу
                  </div>
                </div>
              </div>
              <div class="cm-popup__right" v-if="tab === 1">
                <div class="task-create-form__row">
                  <div class="task-create-form__row-left">
                    <i class="task-form-icon task-form-icon--2"></i>
                  </div>
                  <div class="task-create-form__row-right">
                    <div
                      class="task-create-form__field task-create-form__field--relative"
                    >
                      <input
                        type="text"
                        class="input"
                        placeholder="Название группы"
                        :value="group.GroupName"
                        @blur="editGroupName($event.target.value)"
                      />
                      <div class="task-create-form__field-color-control">
                        <span
                          class="task-form-filter__bottom-color"
                          :style="{
                            background: '#' + group.Color.replace('#', ''),
                          }"
                          @click="showColorSwitch = true"
                        ></span>
                        <transition name="fade">
                          <sketch
                            :value="group.Color ? group.Color : ''"
                            @input="(event) => (group.Color = event.hex)"
                            v-if="showColorSwitch"
                            v-click-outside="closeColorSwitch"
                          />
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="task-create-form__row">
                  <div class="task-create-form__row-left">
                    <i class="task-form-icon task-form-icon--1"></i>
                  </div>
                  <div class="task-create-form__row-right">
                    <div class="task-create-form__label">
                      Шаблон задачи по умолчанию:
                    </div>
                    <div class="task-create-form__field">
                      <div class="select">
                        <select
                          class="select__control"
                          v-model="defaultTemplateId"
                          @change="editGroupDefaultTemplate()"
                        >
                          <option :value="null" disabled="">
                            Сервисный шаблон
                          </option>
                          <option
                            v-for="(item, index) in templates"
                            :value="item.Id"
                            :key="index"
                          >
                            {{ item.TemplateName }}
                          </option>
                        </select>
                        <div class="select__button">
                          <svg class="select__caret">
                            <use
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xlink:href="/static/images/icon.svg#icon_icon-caret"
                            ></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cm-popup__right" v-if="tab === 2">
                <members-settings
                  @selectItemsInnerPopupOpen="selectItemsInnerPopupOpen = true"
                />
              </div>
              <TemplatesTable
                v-if="tab === 3"
                @setTemplateForEdit="templateForEdit = $event"
              />
              <ProcessesSettings :settings="parentData" v-if="tab === 4" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="popupSlide">
      <addition-items
        v-if="users && selectItemsInnerPopupOpen"
        :items="usersToSelect"
        :name="'пользователей'"
        :type="'users'"
        @onClose="selectItemsInnerPopupOpen = false"
        @onAttach="addUserToGroup"
      />
    </transition>

    <transition name="popupSlide">
      <ProcessesCreateControl
        v-if="parentData.processesCreatePopupOpen"
        @onClosePopup="parentData.processesCreatePopupOpen = false"
      />
    </transition>

    <transition name="popupSlide">
      <TemplatesPopup
        :templateForEdit="templateForEdit"
        v-if="templateForEdit"
        @onClosePopup="templateForEdit = null"
      />
    </transition>
  </div>
</template>

<script>
import popup from "../../../../mixins/tasks/popup";
import { mapGetters, mapActions, mapState } from "vuex";
import * as c from "../../../../store/tasks/constants";
import TemplatesTable from "./TemplatesTable";
import TemplatesPopup from "@/modules/tasks/components/Templates";
import MembersSettings from "./MembersSettings";
import PersonsListSearch from "../CommonComponents/PersonsListSearch";
import common from "../../../../mixins/tasks/common";
import AdditionItems from "../../../clients/components/AdditionItems";
import * as _ from "lodash";
import UserAvatar from "../CommonComponents/UserAvatar";
import ProcessesSettings from "./ProcessesSettings";
import ProcessesCreateControl from "./ProcessesCreateControl";
import ProcessesListSearch from "../CommonComponents/ProcessesListSearch";
import { Sketch } from "vue-color";
import { Helpers } from "../../../../mixins/Helpers";
import Api from "@/api/tasks";

export default {
  mixins: [popup, common, Helpers],
  data() {
    return {
      tab: 1,
      defaultTemplateId: null,
      responsibleBubbleOpen: false,
      processesBubbleOpen: false,
      processesBubbleOpen2: false,
      selectItemsInnerPopupOpen: false,
      parentData: {
        processesCreatePopupOpen: false,
      },
      showColorSwitch: false,
      allUsers: [],
      templateForEdit: null,
    };
  },
  created() {
    if (this.group) {
      this.defaultTemplateId = this.group.DefaultTemplateId;
    }
    Api.getUsers().then((res) => {
      this.allUsers = res.data.users;
    });
    this[c.GET_TEMPLATES]();
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   if (this.responsibleBubbleOpen && !e.target.closest(".task-bubble-wrap")) {
    //     this.responsibleBubbleOpen = false;
    //   }
    // });
  },
  computed: {
    usersToSelect() {
      return _.filter(this.allUsers, (i) => {
        return !_.find(this.group.Users, ["UserId", i.Id]);
      });
    },
    ...mapState("tasks", [
      "users",
      "userData",
      "commonModuleSettings",
      "templates",
    ]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    editGroupName(name) {
      if (name !== this.group.GroupName) {
        const payload = {
          id: this.group.Id,
          data: {
            GroupName: name,
          },
        };
        this[c.EDIT_GROUP](payload);
      }
    },
    editGroupDefaultTemplate() {
      const payload = {
        id: this.group.Id,
        data: {
          DefaultTemplateId: this.defaultTemplateId,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    setResponsibleId(id) {
      const payload = {
        id: this.group.Id,
        data: {
          UserId: id,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    setGroupProcess(id) {
      const payload = {
        id: this.group.Id,
        data: {
          MainProcessId: id,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    setDefaultGroupProcess(id) {
      const payload = {
        id: this.group.Id,
        data: {
          DefaultProcessId: id,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    onCloseResponsiblBubble() {
      this.responsibleBubbleOpen = false;
    },
    deleteGroup() {
      this[c.DELETE_GROUP](this.group.Id).then(() => {
        this.close();
      });
    },
    close() {
      this.$emit("onClosePopup");
    },
    addUserToGroup(user) {
      this[c.ADD_USER_TO_GROUP]({
        GroupId: this.group.Id,
        UserId: user.itemId,
      });
      // .then(() => (this.selectItemsInnerPopupOpen = false));
    },
    closeColorSwitch() {
      this.showColorSwitch = false;
      const payload = {
        id: this.group.Id,
        data: {
          Color: this.group.Color,
        },
      };
      this[c.EDIT_GROUP](payload);
    },
    ...mapActions("tasks", [
      c.EDIT_GROUP,
      c.GET_TEMPLATES,
      c.DELETE_GROUP,
      c.ADD_USER_TO_GROUP,
    ]),
  },
  watch: {
    group(val) {
      this.defaultTemplateId = val.DefaultTemplateId;
    },
  },
  components: {
    TemplatesTable,
    MembersSettings,
    PersonsListSearch,
    AdditionItems,
    UserAvatar,
    ProcessesSettings,
    ProcessesCreateControl,
    ProcessesListSearch,
    Sketch,
    TemplatesPopup,
  },
};
</script>

<style scoped></style>
