<template>
  <div
    class="notification-settings-table__row notification-settings-table__row-child"
  >
    <div></div>
    <div class="notification-settings-table__title">
      <svg
        width="16"
        height="15"
        :style="{
          fill: '#' + group.GroupColor.replace('#', ''),
        }"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="/static/images/tasks/sprite.svg#folder-icon"
        ></use>
      </svg>
      <div class="notification-settings-table__title-text">
        {{ group.GroupName }}
      </div>
    </div>
    <NotificationSettingsTableCheckInput
      class="notification-settings-table__control-col"
      @click.native="set('NotificationType_1')"
      :active="Number(group.NotificationType_1)"
    />
    <NotificationSettingsTableCheckInput
      class="notification-settings-table__control-col"
      @click.native="set('NotificationType_2')"
      :active="Number(group.NotificationType_2)"
    />
    <NotificationSettingsTableCheckInput
      class="notification-settings-table__control-col"
      @click.native="set('NotificationType_3')"
      :active="Number(group.NotificationType_3)"
    />
    <NotificationSettingsTableCheckInput
      class="notification-settings-table__control-col"
      @click.native="set('NotificationType_4')"
      :active="Number(group.NotificationType_4)"
    />
  </div>
</template>

<script>
import NotificationSettingsTableCheckInput from "@/modules/tasks/components/ProfileSettingsPopup/NotificationSettingsTable/NotificationSettingsTableCheckInput";
import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  name: "NotificationSettingsTableRowChild",
  props: ["item"],
  computed: {
    group() {
      return this.item.GroupNotificationSetting;
    },
  },
  methods: {
    set(field) {
      this.group[field] = Number(this.group[field]) === 1 ? 0 : 1;
      axios.patch(
        `${API_ROOT}/api/tasks/v2/TasksNotificationsGroupsSettings/${this.group.NotificationId}`,
        {
          ...this.group,
        }
      );
    },
  },
  components: {
    NotificationSettingsTableCheckInput,
  },
};
</script>

<style scoped></style>
