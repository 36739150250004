<template>
  <div
    class="tm-sidebar"
    :class="{ 'tm-sidebar--hidden': !parentData.sidebarIsOpen }"
  >
    <div class="tm-sidebar__header">
      <div class="tm-sidebar-user" v-if="currentUser">
        <div class="tm-sidebar-user__avatar">
          <img :src="avatar" />
        </div>
        <div class="tm-sidebar-user__name">
          {{ currentUser.FullName }}
          <span @click="parentData.profileSettingsPopupOpen = true"
            >Настройки</span
          >
        </div>
      </div>
      <div class="tm-sidebar__btn" @click="parentData.sidebarIsOpen = false">
        <svg width="20" height="14">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/tasks/sprite.svg#sidebar-menu-icon"
          ></use>
        </svg>
      </div>
    </div>
    <div
      class="tm-sidebar__content"
      @scroll="$refs['sidebar-filters'].showCreateFilterPopup = false"
    >
      <RequestsSidebarBody />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API_ROOT } from "../../../../config";
import RequestsSidebarBody from "./RequestsSidebarBody";

export default {
  name: "Sidebar",
  components: { RequestsSidebarBody },
  props: ["parentData"],
  data() {
    return {
      API_ROOT,
    };
  },
  created() {},
  computed: {
    avatar() {
      if (this.currentUser && this.currentUser.LinkToAvatar) {
        return API_ROOT + this.currentUser.LinkToAvatar;
      }
      return "https://tanzolymp.com/images/default-non-user-no-photo-1.jpg";
    },
    ...mapGetters("tasks", ["currentUser"]),
  },
};
</script>

<style scoped></style>
