<template>
  <div class="pdf-preview" v-if="offerObject.json.SystemType">
    <div>
      <Print_1 />
      <Print_2 />
      <Print_3 />
      <Print_3_1 v-if="!offerObject.json.passPages.tasks_page" />
      <Print_4 v-if="!offerObject.json.passPages.schemes" />
      <Print_5 />
      <Print_6 v-if="!offerObject.json.passPages.entry_rack" />
      <!--      <Print_6_1 v-if="!offerObject.json.passPages.entry_barrier"/>-->
      <template v-if="offerObject.json.SystemType !== 'Tokensdbl'">
        <Print_7 v-if="!offerObject.json.passPages.exit_rack" />
      </template>
      <Print_7_1 v-if="!offerObject.json.passPages.exit_barrier" />
      <Print_8 v-if="!offerObject.json.passPages.payment" />
      <Print_9 v-if="!offerObject.json.passPages.additional" />
      <!--      <Print_9_1 v-if="Object.keys(offerObject.json.AdditionalPageTextVirtual).length"/>-->
      <Print_10 />
      <Print_11 v-if="print.full" />
      <Print_12 v-if="print.full" />
      <Print_13 v-if="print.full && !offerObject.json.passPages.ad_tenant" />
      <Print_14 v-if="print.full && !offerObject.json.passPages.ad_tenant" />
      <Print_15 v-if="print.full && !offerObject.json.passPages.service" />
      <Print_16 v-if="print.full && !offerObject.json.passPages.service" />
      <!--      <Print_17 v-if="print.full && !offerObject.json.passPages.option_desc"/>-->
      <Print_18 v-if="print.full" />
      <Print_19 v-if="print.full" />
      <Print_20 />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Print_1 from "./prints/print_1";
import Print_2 from "./prints/print_2";
import Print_3 from "./prints/print_3";
import Print_3_1 from "./prints/print_3-1";
import Print_4 from "./prints/print_4";
import Print_5 from "./prints/print_5";
import Print_6 from "./prints/print_6";
// import Print_6_1 from './prints/print_6-1'
import Print_7 from "./prints/print_7";
import Print_7_1 from "./prints/print_7-1";
import Print_8 from "./prints/print_8";
import Print_9 from "./prints/print_9";
import Print_10 from "./prints/print_10";
import Print_11 from "./prints/print_11";
import Print_12 from "./prints/print_12";
import Print_13 from "./prints/print_13";
import Print_14 from "./prints/print_14";
import Print_15 from "./prints/print_15";
import Print_16 from "./prints/print_16";
import Print_17 from "./prints/print_17";
import Print_18 from "./prints/print_18";
import Print_19 from "./prints/print_19";
import Print_9_1 from "./prints/print_9-1";
import Print_20 from "./prints/print_20";

export default {
  computed: {
    ...mapState("sales", ["offerObject", "print"]),
  },
  components: {
    Print_1,
    Print_2,
    Print_3,
    Print_3_1,
    Print_4,
    Print_5,
    Print_6,
    // Print_6_1,
    Print_7,
    Print_7_1,
    Print_8,
    Print_9,
    Print_10,
    Print_11,
    Print_12,
    Print_13,
    Print_14,
    Print_15,
    Print_16,
    // eslint-disable-next-line vue/no-unused-components
    Print_17,
    Print_18,
    Print_19,
    // eslint-disable-next-line vue/no-unused-components
    Print_9_1,
    Print_20,
  },
};
</script>
