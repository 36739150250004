<template>
  <portal to="users">
    <div
      class="task-popup__nav-person__bubble task-bubble fixed-bubble"
      ref="ported"
      :class="className"
      v-click-outside="close"
    >
      <!--        :style="{left, top}"-->
      <div class="task-bubble__search">
        <!--          <svg>-->
        <!--            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/images/icon.svg#icon_icon-search"></use>-->
        <!--          </svg>-->
        <input
          type="text"
          class="input"
          :placeholder="getShortNameOfSelectedPerson"
          v-model="search"
          ref="input"
        />
        <!--          <div class="task-bubble__close" @click="close()"><i></i></div>-->
      </div>
      <div class="task-bubble__list" ref="list">
        <div
          :item-id="null"
          @click="choose(null)"
          v-if="emptyPlaceholder"
          class="task-bubble__item task-bubble__item_red"
        >
          {{ emptyPlaceholder }}
        </div>
        <template v-if="filteredUsers.length">
          <div
            class="task-bubble__item"
            v-for="item in filteredUsers"
            @click="choose(item.Id, item)"
            :key="item.Id"
            :class="{ 'task-bubble__item--selected': item.selected }"
            @mouseover="item.selected = true"
            :item-id="item.Id"
            @mouseleave="item.selected = false"
          >
            <UserAvatar :userId="item" />
            {{ item.FullName | shortName }}

            <div
              class="task-bubble__item-bell-btn"
              v-if="arr"
              :class="{
                'task-bubble__item-bell-btn--active': checkSelected(item.Id),
              }"
            >
              <svg width="13" height="15">
                <use
                  xlink:href="/static/images/tasks/sprite.svg#bell-icon"
                ></use>
              </svg>
            </div>
          </div>
        </template>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapState } from "vuex";
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import * as _ from "lodash";
import UserAvatar from "./UserAvatar";
import ru from "convert-layout/ru";

export default {
  mixins: [common, Helpers],
  props: {
    className: {
      default: "",
    },
    emptyPlaceholder: {
      default: "",
    },
    selectedPersonId: null,
    outerSearch: {
      default: "",
    },
    arr: null,
    getItemIdField: {
      default: "Id",
    },
    excludeArray: null,
    outerUsers: null,
  },
  data() {
    return {
      search: "",
      items: [],
      top: "",
      left: "",
      preloader: false,
    };
  },
  created() {
    let users = this.users;

    if (this.outerUsers) {
      users = this.outerUsers;
    }

    // if (this.group) {
    //   users = users.filter((i) => {
    //     return this.group.Users.find((x) => x.UserId === i.Id);
    //   });
    // }
    this.items = JSON.parse(
      JSON.stringify(
        _.map(users, (i) => {
          i.selected = false;
          return i;
        })
      )
    );

    this.$nextTick().then(
      this.$nextTick(() => {
        const el = this.$refs["ported"];
        this.setPosition().then(() => {
          setTimeout(() => {
            el.style.transition = ".3s";
            el.classList.add("fixed-bubble--show");
            setTimeout(() => {
              el.style.transition = "0s";
            }, 300);
          });
        });
      })
    );
  },
  mounted() {
    document.onkeydown = (e) => {
      e = e || window.event;
      if ([13, 37, 39, 38, 40].includes(e.keyCode)) {
        e.preventDefault();
      }
      if (this.$refs["list"]) {
        const selectedItem = _.find(this.filteredUsers, ["selected", true]);
        let indexOfSelectedItem = false;
        if (selectedItem) {
          indexOfSelectedItem = this.filteredUsers
            .indexOf(selectedItem)
            .toString();
        }
        const prevItem = indexOfSelectedItem
          ? this.filteredUsers[+indexOfSelectedItem - 1]
          : this.filteredUsers[0];
        const nextItem = indexOfSelectedItem
          ? this.filteredUsers[+indexOfSelectedItem + 1]
          : this.filteredUsers[0];
        const offset = this.$refs["list"].closest(
          ".task-bubble--subscriber-in-textarea"
        )
          ? 0
          : 41;

        // console.log(this.$refs['list'].scrollTop)

        if (e.keyCode == "38") {
          // console.log('up arrow', indexOfSelectedItem)
          if (prevItem) {
            if (selectedItem) selectedItem.selected = false;
            // console.log('down up')
            const domElement = this.$refs["list"].querySelector(
              `[item-id="${prevItem.Id}"]`
            );
            this.$refs["list"].scrollTop = domElement.offsetTop - offset;
            prevItem.selected = true;
          }
        } else if (e.keyCode == "40") {
          if (nextItem) {
            if (selectedItem) selectedItem.selected = false;
            const domElement = this.$refs["list"].querySelector(
              `[item-id="${nextItem.Id}"]`
            );

            this.$refs["list"].scrollTop = domElement.offsetTop - offset;
            // console.log('down arrow')
            nextItem.selected = true;
          }
        } else if (e.keyCode == 13) {
          if (selectedItem) {
            this.choose(selectedItem.Id, selectedItem);
          }
        }
      }
    };
    setTimeout(() => {
      this.$refs["input"].focus();
    });
  },

  destroyed() {
    document.onkeydown = this.settings.keydownFunction;
  },
  computed: {
    getShortNameOfSelectedPerson() {
      if (this.selectedPersonId) {
        const personName = this.getPersonNameById(this.selectedPersonId);
        if (personName) {
          return this.$options.filters.shortName(personName);
        }
      }
      return "Иванов В. В.";
    },
    filteredUsers() {
      // console.log(this.outerSearch)
      let users = _.filter(
        _.orderBy(
          this.items.map((i) => {
            i.selected = false;
            return i;
          }),
          ["FullName"],
          ["asc"]
        ),
        (i) => {
          let search = this.outerSearch ? this.outerSearch : this.search;
          // const searchRus = transliterate(search, true);
          // const searchEn = transliterate(search);
          //

          const searchRus = ru.toEn(search).replace(
            /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, // eslint-disable-line
            ""
          ); // eslint-disable-line;
          const searchEn = ru.fromEn(search);

          const reRu = new RegExp(searchRus, "gi");
          const reEn = new RegExp(searchEn, "gi");

          const exc = this.excludeArray
            ? !this.excludeArray.includes(i.Id)
            : true;
          return (reRu.test(i.FullName) || reEn.test(i.FullName)) && exc;
        }
      );
      if (users[0]) users[0].selected = true;
      return users;
    },
    ...mapState("tasks", ["users", "settings"]),
  },
  methods: {
    choose(id, item) {
      if (!this.arr) {
        this.$emit("choose", id, item);
        this.close();
      } else {
        if (this.checkSelected(id)) {
          this.$emit("deleteItem", this.checkSelected(id)[this.getItemIdField]);
        } else {
          this.$emit("choose", id, item);
        }
      }
    },
    close() {
      this.$emit("close");
    },
    setPosition() {
      return new Promise((resolve) => {
        try {
          const el = this.$refs["ported"];
          // if (!el) this.$emit('close')
          const parent = this.$el.parentElement,
            bodyRect = document.body.getBoundingClientRect(),
            parentRect = parent.getBoundingClientRect();

          let left = parentRect.left - bodyRect.left;
          let top = parentRect.top - bodyRect.top;

          const componentWidth = el.clientWidth;
          const componentHeight = el.clientHeight;

          if (window.innerWidth - left < componentWidth) {
            left = parentRect.right - componentWidth;
          }

          if (window.innerHeight - top < componentHeight) {
            top = parentRect.top - componentHeight;
          } else {
            if (window.innerHeight - top - componentHeight < 150) {
              top = parentRect.top - componentHeight;
            }
          }
          el.style.top = top + "px";
          el.style.left = left + "px";

          resolve();
        } catch (e) {
          this.close();
        }
      });
    },
    checkSelected(Id) {
      return this.arr && _.find(this.arr, ["UserId", Id]);
    },
  },
  components: {
    UserAvatar,
  },
  watch: {
    filteredUsers(oldVal, items) {
      if (oldVal !== items) {
        setTimeout(() => {
          this.setPosition();
        });
      }
    },
  },
};
</script>

<style scoped></style>
