<template>
  <div
    class="task-checklists-block"
    :class="{
      'task-checklists-block--no-edit': !checkUserTaskRight(
        'add_edit_checklists_tasks'
      ),
    }"
  >
    <div class="task-checklists-block__header">
      <div class="task-create-form__row-left">
        <i class="task-form-icon task-form-icon--7"></i>
      </div>
      <div class="task-create-form__row-right">
        <div style="display: flex; width: 100%">
          <div class="task-create-form__label">Микрозадачи</div>

          <div
            class="t-gray-btn"
            @click="addNewChecklist"
            v-if="checkUserTaskRight('add_edit_checklists_tasks')"
          >
            Создать новый чек-лист
          </div>
          <div
            class="t-gray-btn"
            style="margin-left: 15px"
            @click="
              hideCompleted($event.target.closest('.task-checklists-block'))
            "
          >
            Скрыть выполненные
          </div>
        </div>
        <!--        <div class="task-create-form-check-list__add-item-->
        <!--           task-create-form-check-list__add-item&#45;&#45;for-checklist"-->
        <!--             @click="addNewChecklist"-->
        <!--             v-if="index === 0"-->
        <!--        >-->
        <!--          <span>+</span> Создать новый чек-лист-->
        <!--        </div>-->
      </div>
    </div>
    <div
      class="task-create-form__row task-checklists-block__item"
      v-for="(checklist, index) in selectedTask.checklists.filter((c, index) =>
        checkFirstChecklistCondition(index)
      )"
      :key="'checklist' + index"
    >
      <div class="task-create-form__row-left"></div>
      <div
        class="task-create-form__row-right task-create-form-check-list__wrap"
      >
        <div class="task-create-form__label-wrap" v-show="index !== 0">
          <div class="task-checklists-block__name">
            <div
              class="task-checklists-block__arrow"
              @click="
                collapse($event.target.closest('.task-checklists-block__item'))
              "
            >
              <img src="/static/images/tasks/icons/arrow.svg" alt="icon" />
            </div>
            <textarea-autosize
              class="task-create-form__label"
              ref="myTextarea"
              v-model="checklist.Name"
              rows="1"
              :min-height="20"
              :max-height="350"
              @keypress.13.native.prevent
              @blur.native="editChecklistName(checklist)"
              :disabled="!checkUserTaskRight('add_edit_checklists_tasks')"
            />
          </div>

          <div
            class="task-create-form-check-list__add-item task-create-form-check-list__add-item--for-checklist"
            @click="deleteChecklist(checklist.Id)"
            v-if="index !== 0"
          >
            Удалить
          </div>
        </div>

        <div class="task-create-form-check-list">
          <div class="task-create-form-check-list__shorted-icon">...</div>
          <draggable
            v-model="checklist.tasks"
            group="checklist"
            @start="$emit('setCheckListDragging', true)"
            @update="
              () => {
                changeItemsOrder(checklist);
                $emit('setCheckListDragging', false);
              }
            "
            @add="endDrag($event, checklist.Id)"
            @end="checkForRemoveItem($event)"
            :data-checklist-id="checklist.Id"
            class="task-create-form-check-list--draggable"
            :disabled="!checkUserTaskRight('add_edit_checklists_tasks')"
          >
            <div
              class="task-create-form-check-list__row task-create-form-check-list__row--checklist-items"
              :class="{
                'task-create-form-check-list__row--crossed-out':
                  item.Status === '2',
                'task-create-form-check-list__row--selected': checkItemForSelection(
                  item
                ),
              }"
              v-for="(item, index) in checklist.tasks"
              data-entry-selection-info="0"
              data-entry-shift-selection="0"
              :data-checklist-item-id="item.Id"
              :key="index"
            >
              <div class="task-create-form-check-list__row-drag-icon">
                <span>{{ index + 1 }}</span>
                <svg viewBox="0 0 24 24">
                  <path
                    d="M10,4c0,1.1-0.9,2-2,2S6,5.1,6,4s0.9-2,2-2S10,2.9,10,4z M16,2c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,2,16,2z M8,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.1,10,8,10z M16,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,10,16,10z M8,18 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.1,18,8,18z M16,18c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,18,16,18z"
                  ></path>
                </svg>
              </div>

              <!--<div class="task-create-form-check-list__check-status task-popup-icon__3"></div>-->
              <label
                class="checkbox checkbox_white table__checkbox js-index-checkbox"
              >
                <input
                  class="checkbox__input main_checkbox_input"
                  type="checkbox"
                  :disabled="!checkUserTaskRight('close_checklist_task')"
                  v-model="item.Status"
                  v-bind:true-value="'2'"
                  v-bind:false-value="'1'"
                  @change="saveItem(item)"
                  :class="{ 'checkbox__box--active': item.Status === '2' }"
                />
                <span class="checkbox__box main_checkbox_span">
                  <svg class="checkbox__icon">
                    <use
                      xlink:href="/static/images/icon.svg#icon_icon-check"
                    ></use>
                  </svg>
                </span>
              </label>
              <div class="task-create-form-check-list__name">
                <span
                  class="task-create-form-check-list__name-top"
                  @click.shift.capture.stop.prevent="
                    shiftSelection($event, item)
                  "
                  @click.meta.capture.stop.prevent="ctrlSelection($event, item)"
                  @click.ctrl.capture.stop.prevent="ctrlSelection($event, item)"
                  @click.exact="
                    selectSingleItem(
                      $event.target.closest(
                        '.task-create-form-check-list__row'
                      ),
                      item
                    )
                  "
                >
                  <textarea-autosize
                    :value="item.ItemName"
                    class="textarea-autosize-name"
                    rows="1"
                    :min-height="13"
                    :max-height="350"
                    @keyup.native="
                      watchFieldForRemove($event.target, item, checklist)
                    "
                    @keypress.13.native.prevent="
                      addCheckListItemByEnter($event, checklist)
                    "
                    @blur.native="saveItem(item)"
                    :disabled="!checkUserTaskRight('add_edit_checklists_tasks')"
                  />
                </span>
              </div>

              <div class="task-create-form-check-list__right">
                <div class="task-create-form-check-list__right-icons">
                  <img
                    src="/static/images/tasks/icons/alarm-icon.svg"
                    alt="icon"
                    v-if="haveDateNotification(item)"
                  />
                  <img
                    src="/static/images/tasks/icons/geo-icon.svg"
                    alt="icon"
                    v-if="haveGeoNotification(item)"
                  />
                </div>
                <div
                  class="task-create-form-check-list__dates"
                  :class="{
                    'task-create-form-check-list__dates--expired': checkExpiredTask(
                      item
                    ),
                  }"
                >
                  <span
                    class="task-create-form-check-list__dates--red"
                    v-if="checkExpiredTask(item)"
                    >{{ item.RealTimeFinish | formatLocalDate }}</span
                  >
                  <div class="task-create-form-check-list__date">
                    <date-input
                      :date="item.Deadline"
                      :timeValue="item.DeadlineTime"
                      :format="'DD MMMM YYYY в HH:mm'"
                      :disabled="
                        !checkUserTaskRight('change_checklists_tasks_dates') ||
                        item.Status === '2'
                      "
                      @setDate="
                        (date, callback) => setDate(date, item, callback)
                      "
                      :checklistItem="item"
                      :open="true"
                    />
                  </div>
                </div>

                <div
                  class="task-create-form-check-list__btn"
                  v-if="
                    item.ApproveRequestId &&
                    item.ApproveRequestId.UserId === userData.Id
                  "
                  @click="approveTask(item.ApproveRequestId.Id, item)"
                >
                  Принять
                </div>

                <div
                  class="task-create-form-check-list__person"
                  v-else-if="item.ApproveRequestId"
                >
                  <div
                    class="task-create-form-subtasks__person task-create-form-subtasks__person--add"
                    v-if="!item.ApproveRequestId.UserId"
                    @click="item.personsBubbleOpen = true"
                  >
                    +
                  </div>
                  <div
                    v-else
                    @click="item.personsBubbleOpen = true"
                    style="position: relative"
                  >
                    <UserAvatar
                      :userId="item.ApproveRequestId.UserId"
                      style="opacity: 0.4"
                      title="Пользователю отправлено уведомление"
                    />
                    <i class="task-checklist-item-time-icon"></i>
                  </div>
                  <transition name="fade">
                    <PersonsListSearch
                      v-if="
                        checkUserTaskRight('add_edit_checklists_tasks') &&
                        item.personsBubbleOpen
                      "
                      :className="'task-bubble--checklist-items'"
                      :selectedPersonId="item.ApproveRequestId.UserId"
                      @choose="
                        (id) => {
                          item.ApproveRequestId.UserId = id;
                          saveItem(item);
                        }
                      "
                      @close="item.personsBubbleOpen = false"
                      v-click-outside="closePersonBubble"
                    />
                  </transition>
                </div>
                <div class="task-create-form-check-list__person" v-else>
                  <div
                    class="task-create-form-subtasks__person task-create-form-subtasks__person--add"
                    v-if="!item.ResponsibleId"
                    @click="item.personsBubbleOpen = true"
                  >
                    +
                  </div>
                  <div v-else @click="item.personsBubbleOpen = true">
                    <UserAvatar :userId="item.ResponsibleId" />
                  </div>

                  <PersonsListSearch
                    v-if="
                      checkUserTaskRight('add_edit_checklists_tasks') &&
                      item.personsBubbleOpen
                    "
                    :className="'task-bubble--checklist-items'"
                    :selectedPersonId="item.ResponsibleId"
                    @choose="
                      (id) => {
                        item.ResponsibleId = id;
                        saveItem(item);
                      }
                    "
                    @close="item.personsBubbleOpen = false"
                    v-click-outside="closePersonBubble"
                  />
                </div>
              </div>
            </div>
          </draggable>

          <div
            class="task-create-form-check-list__row"
            v-if="checkUserTaskRight('add_edit_checklists_tasks')"
          >
            <div
              class="task-create-form-check-list__add-item"
              @click="addCheckListItem($event, checklist)"
            >
              <span>+</span>
              Добавить
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal to="checklist-grouped-actions">
      <grouped-checklist-actions
        :selectedItems="selectedItems"
        @resetSelectedItems="selectedItems = []"
        :parentData="parentData"
        :loadedTask="loadedTask"
      />
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import draggable from "vuedraggable";
import PersonsListSearch from "../CommonComponents/PersonsListSearch";
import common from "../../../../mixins/tasks/common";
import * as c from "../../../../store/tasks/constants";
import moment from "moment";
import { Helpers } from "../../../../mixins/Helpers";
import UserAvatar from "../CommonComponents/UserAvatar";
import * as _ from "lodash";
import GroupedChecklistActions from "./GroupedChecklistActions";

export default {
  mixins: [common, Helpers],
  props: ["parentData", "loadedTask"],
  data() {
    return {
      allowCreateChecklistItem: true,
      selectedItems: [],
      shiftSelectionState: false,
    };
  },
  computed: {
    ...mapState("tasks", ["selectedTask", "userData"]),
  },
  methods: {
    watchFieldForRemove(row, item) {
      const prevEl = row.closest(".task-create-form-check-list__row")
        .previousElementSibling;
      // console.log(item.ItemName, row.value)

      if (!item.ItemName && !row.value) {
        this[c.DELETE_CHECKLIST_ITEM](item.Id).then(() => {
          if (prevEl) {
            prevEl
              .querySelector(".task-create-form-check-list__name-top textarea")
              .focus();
          }
        });
      }
      item.ItemName = row.value;
    },
    addCheckListItem(e, checklist) {
      if (this.allowCreateChecklistItem) {
        this.allowCreateChecklistItem = false;
        if (!checklist.tasks) checklist.tasks = [];
        this[c.CREATE_CHECKLIST_ITEM]({
          ItemName: "Новая задача",
          Deadline: "",
          DeadlineTime: "",
          Position: checklist.tasks.length + 1,
          Status: "1",
          ResponsibleId: null,
          ChecklistId: checklist.Id,
          Notification: {
            NotificationType: null,
            NotificationDate: "",
            NotificationTime: "",
            NotificationLatitude: "",
            NotificationLongitude: "",
          },
          localId: Math.random().toString(36).substring(7),
        }).finally(() => (this.allowCreateChecklistItem = true));
        setTimeout(() => {
          const checklistWrap = e.target.closest(
            ".task-create-form-check-list"
          );
          checklistWrap
            .querySelectorAll(
              ".task-create-form-check-list__row--checklist-items"
            )
            [
              checklistWrap.querySelectorAll(
                ".task-create-form-check-list__row--checklist-items"
              ).length - 1
            ].querySelector(".task-create-form-check-list__name-top textarea")
            .focus();
        });
      }
    },
    addCheckListItemByEnter(e, checklist) {
      if (this.allowCreateChecklistItem) {
        this.allowCreateChecklistItem = false;
        if (!checklist.tasks) checklist.tasks = [];
        const prevEl = e.target.closest(".task-create-form-check-list__row"),
          checklistWrap = prevEl.closest(
            ".task-create-form-check-list--draggable"
          ),
          nodes = Array.prototype.slice.call(checklistWrap.children);

        this[c.CREATE_CHECKLIST_ITEM]({
          ItemName: "Новая задача",
          Deadline: "",
          DeadlineTime: "",
          Position: nodes.indexOf(prevEl) + 1,
          Status: "1",
          ResponsibleId: null,
          Notification: {
            NotificationType: null,
            NotificationDate: "",
            NotificationTime: "",
            NotificationLatitude: "",
            NotificationLongitude: "",
          },
          ChecklistId: checklist.Id,
          localId: Math.random().toString(36).substring(7),
        })
          .then(() => {
            this.changeItemsOrder(checklist);
          })
          .finally(() => (this.allowCreateChecklistItem = true));
        setTimeout(() => {
          prevEl.nextElementSibling
            .querySelector(".task-create-form-check-list__name-top textarea")
            .focus();
        });
      }
    },
    saveItem(item, orderChange = false) {
      if (!item.localId) {
        return new Promise((resolve) => {
          let can = false;
          if (orderChange) {
            can = true;
          }
          // if (force) can = true
          if (can) {
            if (item.Status === "2") {
              item.RealTimeFinish = moment().format("YYYY-MM-DD HH:mm:ss");
            }
            const payload = {
              id: item.Id,
              data: item,
            };
            if (payload.data.Deadline)
              payload.data.Deadline = moment(payload.data.Deadline).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            if (payload.data.RealTimeFinish)
              payload.data.RealTimeFinish = moment(
                payload.data.RealTimeFinish
              ).format("YYYY-MM-DD HH:mm:ss");

            this[c.EDIT_CHECKLIST_ITEM](payload).then(() => {
              resolve();
            });
          }
        });
      }
    },
    changeItemsOrder(checklist) {
      let count = 1;
      _.map(checklist.tasks, (i) => {
        i.Position = count;
        count++;
        return i;
      });
      _.each(checklist.tasks, (i) => {
        this.saveItem(i, true);
      });
    },
    endDrag(e, toChecklistId) {
      const itemId = e.item.getAttribute("data-checklist-item-id");
      const fromChecklistId = e.from.getAttribute("data-checklist-id");
      let foundItem = _.find(
        _.find(this.selectedTask.checklists, ["Id", toChecklistId]).tasks,
        ["Id", itemId]
      );
      if (!foundItem) {
        foundItem = _.find(
          _.find(this.selectedTask.checklists, ["Id", fromChecklistId]).tasks,
          ["Id", itemId]
        );
      }
      this.saveItem({ ...foundItem, ChecklistId: toChecklistId }, false);
    },
    editChecklistName(checklist) {
      if (!checklist.localId) {
        const payload = {
          id: checklist.Id,
          data: {
            Name: checklist.Name,
            TaskId: checklist.TaskId,
          },
        };
        this[c.EDIT_CHECKLIST](payload);
      }
    },
    addNewChecklist() {
      this[c.CREATE_CHECKLIST]({
        Name: "Новый чек-лист",
        TaskId: this.selectedTask.entityModel.Id,
        localId: Math.random().toString(36).substring(7),
      });
      // .then(() => {
      //   // scroll
      //   const newRow = document.querySelector('.task-checklists-block .task-create-form__row:last-of-type')
      //   document.querySelector('.cm-popup__right').scrollTop = newRow.offsetTop
      //   const nameInput = newRow.querySelector('.task-create-form__label')
      //   if (nameInput) nameInput.focus()
      // })
      setTimeout(() => {
        const newRow = document.querySelectorAll(
          ".task-checklists-block  .task-create-form__row"
        )[
          document.querySelectorAll(
            ".task-checklists-block  .task-create-form__row"
          ).length - 1
        ];
        document.querySelector(".cm-popup__right").scrollTop = newRow.offsetTop;
        const nameInput = newRow.querySelector(".task-create-form__label");
        if (nameInput) nameInput.focus();
      });
    },
    deleteChecklist(Id) {
      this[c.DELETE_CHECKLIST](Id);
    },
    checkExpiredTask(item) {
      if (item.RealTimeFinish && item.Deadline) {
        const finish = moment(item.Deadline);
        const realFinish = moment(item.RealTimeFinish);
        return realFinish > finish;
      }
    },
    closePersonBubble() {
      if (this.selectedTask && this.selectedTask.checklists.length) {
        _.each(this.selectedTask.checklists, (i) => {
          if (i.tasks && i.tasks.length) {
            _.map(i.tasks, (i) => {
              i.personsBubbleOpen = false;
              return i;
            });
          }
        });
      }
    },
    setDate(date, item, callback = false) {
      item.Deadline = date;
      item.DeadlineTime = moment(date).format("HH:mm:ss");
      this.saveItem(item).then(() => {
        if (callback) {
          callback();
        }
      });
    },
    toggleOpenStateCheckboxItem(el) {
      document
        .querySelectorAll(".task-create-form-check-list__row")
        .forEach((i) => {
          i.classList.remove("task-create-form-check-list__row--open");
        });
      el.classList.toggle("task-create-form-check-list__row--open");
    },
    approveTask(id, item) {
      this[c.APPROVE_CHECKLIST_ITEM]({ id, task: item });
    },
    ...mapActions("tasks", [
      c.EDIT_CHECKLIST_ITEM,
      c.CREATE_CHECKLIST_ITEM,
      c.DELETE_CHECKLIST_ITEM,
      c.EDIT_CHECKLIST,
      c.CREATE_CHECKLIST,
      c.DELETE_CHECKLIST,
      c.APPROVE_CHECKLIST_ITEM,
    ]),
    collapse(el) {
      el.classList.toggle("task-checklists-block__item--hidden");
    },
    hideCompleted(el) {
      el.classList.toggle("task-checklists-block--hide-completed");
    },
    haveDateNotification(item) {
      return _.find(item.Notifications, ["NotificationType", "1"]);
    },
    haveGeoNotification(item) {
      return _.find(item.Notifications, ["NotificationType", "2"]);
    },
    checkFirstChecklistCondition(index) {
      if (
        this.selectedTask.checklists.length > 1 &&
        index === 0 &&
        !this.selectedTask.checklists[0].tasks.length
      )
        return false;
      return true;
    },
    checkForRemoveItem(e) {
      var x = e.originalEvent.clientX,
        y = e.originalEvent.clientY,
        elementMouseIsOver = document.elementFromPoint(x, y);
      if (elementMouseIsOver.classList.contains("popup__wrapper")) {
        this[c.DELETE_CHECKLIST_ITEM](
          e.item.getAttribute("data-checklist-item-id")
        );
      }
    },
    selectSingleItem(el, item) {
      if (item.Id) {
        this.selectedItems = [];
        this.selectedItems.push(item);
        if (!this.shiftSelectionState) {
          if (document.querySelector('[data-entry-selection-info="1"]')) {
            document
              .querySelector('[data-entry-selection-info="1"]')
              .setAttribute("data-entry-selection-info", "0");
          }
          el.setAttribute("data-entry-selection-info", "1");
        }
      }
    },
    addItemToSelectList(item) {
      if (item.Id) {
        if (!_.find(this.selectedItems, ["Id", item.Id])) {
          this.selectedItems.push(item);
        }
      }
    },
    removeItemFromSelectList(item) {
      if (item.Id) {
        this.selectedItems = _.reject(this.selectedItems, ["Id", item.Id]);
      }
    },
    shiftSelection(e, item) {
      const el = e.target.closest(".task-create-form-check-list__row");
      const rows = el
        .closest(".task-create-form-check-list--draggable")
        .querySelectorAll(".task-create-form-check-list__row");
      let firstEntry,
        lastEntry = false;
      const checklistId = el
        .closest(".task-create-form-check-list--draggable")
        .getAttribute("data-checklist-id");
      this.shiftSelectionState = true;

      if (!el.getAttribute("data-entry-selection-info") !== "1") {
        this.addItemToSelectList(item);
      }

      if (document.querySelector('[data-entry-selection-info="1"]')) {
        rows.forEach((row) => {
          if (
            el === row ||
            row.getAttribute("data-entry-selection-info") === "1"
          ) {
            lastEntry = !!firstEntry;
            firstEntry = true;
          }
          if (
            ((!firstEntry && !lastEntry) || (firstEntry && lastEntry)) &&
            el !== row &&
            row.getAttribute("data-entry-selection-info") !== "1"
          ) {
            if (
              _.find(this.selectedItems, [
                "Id",
                row.getAttribute("data-checklist-item-id"),
              ])
            ) {
              const found = _.find(
                _.find(this.selectedTask.checklists, ["Id", checklistId]).tasks,
                ["Id", row.getAttribute("data-checklist-item-id")]
              );
              this.removeItemFromSelectList(found);
            }
          } else if (
            firstEntry &&
            !lastEntry &&
            el.getAttribute("data-entry-selection-info") !== "1"
          ) {
            if (
              !_.find(this.selectedItems, [
                "Id",
                row.getAttribute("data-checklist-item-id"),
              ])
            ) {
              const found = _.find(
                _.find(this.selectedTask.checklists, ["Id", checklistId]).tasks,
                ["Id", row.getAttribute("data-checklist-item-id")]
              );
              this.addItemToSelectList(found);
            }
          }
        });
      } else {
        el.setAttribute("data-entry-selection-info", "1");
      }
      el.setAttribute("data-entry-shift-selection", "1");
      this.shiftSelectionState = false;
    },
    checkItemForSelection(item) {
      return _.find(this.selectedItems, ["Id", item.Id]);
    },
    ctrlSelection(e, item) {
      const el = e.target.closest(".task-create-form-check-list__row");
      const rows = el
        .closest(".task-create-form-check-list--draggable")
        .querySelectorAll(".task-create-form-check-list__row");
      const checklistId = el
        .closest(".task-create-form-check-list--draggable")
        .getAttribute("data-checklist-id");

      if (document.querySelector('[data-entry-shift-selection="1"]')) {
        rows.forEach((row) => {
          row.setAttribute("data-entry-shift-selection", "0");
          const found = _.find(
            _.find(this.selectedTask.checklists, ["Id", checklistId]).tasks,
            ["Id", row.getAttribute("data-checklist-item-id")]
          );
          this.removeItemFromSelectList(found);
        });
      }
      if (document.querySelector('[data-entry-selection-info="1"]')) {
        document
          .querySelector('[data-entry-selection-info="1"]')
          .setAttribute("data-entry-selection-info", "0");
      }
      el.setAttribute("data-entry-selection-info", "1");
      this.addItemToSelectList(item);
    },
    ...mapMutations("tasks", [c.ORDER_CHECKLIST_ITEMS]),
  },
  components: {
    draggable,
    PersonsListSearch,
    UserAvatar,
    GroupedChecklistActions,
  },
};
</script>

<style scoped></style>
