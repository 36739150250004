<template>
  <div class="task-settings__list">
    <template>
      <div class="task-settings__list-item" @click="markAllNotificationsRead">
        <div class="task-settings__list-icon">
          <svg width="18" height="18" stroke="black">
            <use xlink:href="/static/images/tasks/sprite.svg#check-icon"></use>
          </svg>
        </div>
        Отметить всё прочитанным
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { MARK_ALL_NOTIFICATIONS_READ } from "@/store/tasks/constants";

export default {
  name: "NotificationSettingsList",
  props: [],
  data() {
    return {
      state: 1,
    };
  },
  methods: {
    markAllNotificationsRead() {
      this[MARK_ALL_NOTIFICATIONS_READ]({ HandlerType: 1 });
      this.$emit("closeSettings");
    },
    ...mapActions("tasks", [MARK_ALL_NOTIFICATIONS_READ]),
  },
};
</script>

<style scoped>
.ui-radio {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
}

.ui-radio__icon {
  width: 20px;
  height: 20px;
  border: 2px solid #e5e5e5;
  border-radius: 20px;
  position: relative;
  -webkit-transition: border-color 0.12s;
  transition: border-color 0.12s;
}

.ui-radio__icon,
.ui-radio__lable {
  display: inline-block;
  vertical-align: top;
}

.ui-radio__icon,
.ui-radio__lable {
  display: inline-block;
  vertical-align: top;
}

.ui-radio__lable {
  line-height: 20px;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-radio__icon:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 6px;
  background-color: #f9ad1a;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: opacity 60ms, -webkit-transform 0.22s;
  transition: opacity 60ms, -webkit-transform 0.22s;
  transition: opacity 60ms, transform 0.22s;
  transition: opacity 60ms, transform 0.22s, -webkit-transform 0.22s;
}

.ui-radio__field:checked + .ui-radio__icon,
.ui-radio__field:focus + .ui-radio__icon,
.ui-radio__field:hover + .ui-radio__icon {
  border-color: #f9ad1a;
}

.ui-radio__field:checked + .ui-radio__icon:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ui-radio__field {
  visibility: hidden;
}

.ui-button {
  padding-top: 0px !important;
  margin-top: 30px !important;
}

.ui-button:not(:disabled) {
  display: inline-block;
  line-height: 40px;
  width: 100%;
  max-width: 390px;
  background-color: #f9ae17;
  border: 2px solid #bf8511;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  -webkit-transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.12s;
  transition-duration: 0.12s;
}
</style>
