<template>
  <div>
    <span class="">База знаний</span> /
    <span v-if="sectionName"
      >{{ sectionName }}
      <BreadCrumbsItem :item="currentArticle" :articles="articles"
    /></span>
  </div>
</template>

<script>
import BreadCrumbsItem from "./BreadCrumbsItem";
import * as _ from "lodash";

export default {
  props: ["categories", "categorySlug", "currentArticle", "articles"],
  computed: {
    sectionName() {
      const found = _.find(this.categories, ["Slug", this.categorySlug]);
      if (found) return found.Name;
      return false;
    },
  },
  components: {
    BreadCrumbsItem,
  },
};
</script>

<style scoped></style>
