<template>
  <div class="popup task-popup event-popup event-popup_active">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Перенос задачи</h2>
          <div class="popup__close" @click="onClose">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__right">
              <div class="task-popup-header">
                <div class="actions-header__col actions-header__col_flexible">
                  <div
                    class="search-bar search-bar_white actions-header__search-bar"
                  >
                    <button class="search-bar__button">
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-search"
                        ></use>
                      </svg>
                    </button>
                    <input
                      type="search"
                      placeholder="Введите название задачи"
                      v-model="search"
                      @keyup="searchTasks"
                      class="search-bar__input"
                    />
                  </div>
                </div>
              </div>

              <div class="automation-template">
                <div
                  class="automation-template__row"
                  style="display: flex"
                  v-for="item in computedTasks"
                  :key="item.Id"
                >
                  <div class="automation-template__col">
                    <p class="table-c__name link" @click="choose(item)">
                      {{ item.TaskName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="simple-spinner" v-if="preloader">
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../../../api/tasks";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  GET_GROUPS,
  GET_TASKS,
  GET_USER_TASKS_LIST,
  TRANSFER_TASK_TO_OTHER_TASK,
} from "../../../../../store/tasks/constants";
import * as _ from "lodash";
import { queueFetch } from "@/utils/queneFetch";

export default {
  name: "TasksListToTransfer",
  props: ["loadedTask", "selectedItems"],
  data() {
    return {
      preloader: false,
      search: "",
      tasks: [],
      typeTime: null,
    };
  },
  created() {
    this.searchTasks();
  },
  computed: {
    computedTasks() {
      return _.filter(this.tasks, (i) => {
        if (!this.selectedItems) {
          return (
            i.Id !== this.selectedTask.entityModel.Id &&
            this.selectedTask.entityModel.ParentId !== i.Id
          );
        }
        return true;
      });
    },
    ...mapState("tasks", ["selectedTask"]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onClose();
      }
    },
    searchTasks() {
      const self = this;
      clearTimeout(this.typeTime);
      this.preloader = true;
      const groupId = self.selectedTask
        ? self.selectedTask.entityModel.GroupId
        : this.group.Id;

      this.typeTime = setTimeout(function () {
        Api.getTasks({
          "params[GroupId]": groupId,
          "params[TaskName]": self.search,
        })
          .then((res) => {
            self.tasks = _.filter(res.data, (i) => {
              if (self.selectedItems) {
                return !self.selectedItems.find((id) => id === i.Id);
              }
              return true;
            });
          })
          .finally(() => {
            self.preloader = false;
          });
      }, 300);
    },
    choose(taskToParent) {
      this.preloader = true;
      if (this.selectedItems) {
        this.$emit("unselectTasks");
        queueFetch(this.selectedItems, async (task) => {
          await this.asyncTransferTaskToOtherTask(
            taskToParent.Id,
            {},
            task.Id,
            true
          );
        }).then(() => {
          this[GET_USER_TASKS_LIST](true);
          this[GET_TASKS]();

          this.onClose();
        });
      } else {
        this.selectedTask.entityModel.ParentId = taskToParent.Id;
        this.loadedTask.entityModel.ParentId = taskToParent.Id;
        this.loadedTask.entityModel.parent = taskToParent;
        this.asyncTransferTaskToOtherTask(
          taskToParent.Id,
          this.selectedTask.entityModel,
          this.selectedTask.entityModel.Id,
          false
        );
        this.onClose();
      }
    },
    async asyncTransferTaskToOtherTask(parentId, task, taskId, batch) {
      const payload = {
        id: taskId,
        data: {
          ParentId: parentId,
        },
      };
      await this[TRANSFER_TASK_TO_OTHER_TASK]({
        payload,
        task,
        batch,
      });
      return true;
    },
    ...mapActions("tasks", [
      TRANSFER_TASK_TO_OTHER_TASK,
      GET_GROUPS,
      GET_TASKS,
      GET_USER_TASKS_LIST,
    ]),
  },
  components: {},
};
</script>

<style scoped></style>
