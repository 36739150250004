<template>
  <div class="task-create-form__row">
    <div class="task-create-form__row-left">
      <i
        class="task-form-icon task-form-icon--5"
        @click="collapsed = !collapsed"
        style="cursor: pointer"
      ></i>
    </div>
    <div class="task-create-form__row-right">
      <div
        class="task-create-form__field"
        v-if="checkUserTaskRight('add_edit_task_address')"
      >
        <div class="custom-select">
          <div
            class="task-popup__nav-person__bubble task-bubble"
            v-click-outside="close"
          >
            <div class="task-bubble__search">
              <svg>
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-search"
                ></use>
              </svg>
              <input
                type="text"
                class="input"
                @click="open = true"
                :class="loadedTask.entityModel.ObjectId ? 'input--filled' : ''"
                :placeholder="
                  loadedTask.entityModel.ObjectId
                    ? getObjectById(loadedTask.entityModel.ObjectId).Name
                    : 'Введите название объекта'
                "
                v-model="search"
              />
              <div class="task-bubble__close" @click="unselectObject()">
                <i></i>
              </div>
            </div>
            <div class="task-bubble__list" v-if="open">
              <div
                class="task-bubble__item"
                v-for="(item, index) in filteredObjects"
                @click="setAddress(item)"
                :key="index"
              >
                {{ item.Name }}
              </div>
            </div>
          </div>
        </div>
        <div class="task-create-form__btn" @click="gmapWindow = true">
          <i class="task-form-icon task-form-icon--12"></i>
        </div>
      </div>
      <template v-if="!collapsed">
        <input
          type="text"
          class="task-create-form__field-text"
          style="width: 100%"
          v-if="checkUserTaskRight('add_edit_task_address')"
          @blur="$emit('editTask')"
          v-model="loadedTask.entityModel.TaskAddress"
          @keyup="objectId = '0'"
        />
        <p class="task-create-form__field-text" v-else>
          {{ loadedTask.entityModel.TaskAddress }}
        </p>

        <div class="task-address-input-map" v-if="geo">
          <!--        <div-->
          <!--          class="task-address-input-map__collapse-btn"-->
          <!--          :class="{ 'task-address-input-map__collapse-btn&#45;&#45;active': collapsed }"-->
          <!--          @click="collapsed = !collapsed"-->
          <!--        >-->
          <!--          <svg width="12" height="7">-->
          <!--            <use-->
          <!--              xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"-->
          <!--            ></use>-->
          <!--          </svg>-->
          <!--        </div>-->
          <GmapMap :center="geo" :zoom="7" style="width: 100%; height: 300px">
            <GmapMarker
              ref="myMarker"
              :position="geo"
              :clickable="false"
              :draggable="false"
            />
          </GmapMap>
        </div>
      </template>
    </div>
    <portal to="address-map-portal">
      <v-dialog
        class="offer-gmap-dialog"
        v-model="gmapWindow"
        width="600px"
        v-if="gmapWindow"
      >
        <google-map
          @close="gmapWindow = false"
          @setAddressByMap="setAddressByMap"
          @setGeoByMap="setGeoByMap"
          :setPosition="geo"
        />
      </v-dialog>
    </portal>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { mapState } from "vuex";
import GoogleMap from "../../CommonComponents/GoogleMap";
import { Helpers } from "../../../../../mixins/Helpers";
import * as _ from "lodash";

export default {
  props: ["loadedTask"],
  mixins: [common, Helpers],
  data() {
    return {
      objectId: null,
      gmapWindow: false,
      open: false,
      search: "",
      collapsed: false,
    };
  },
  computed: {
    filteredObjects() {
      return _.orderBy(
        _.filter(this.objects, (i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i.Name);
        }),
        ["Name"],
        ["asc"]
      );
    },
    geo() {
      if (
        this.loadedTask.entityModel.TaskLatitude &&
        this.loadedTask.entityModel.TaskLongitude
      ) {
        return {
          lat: +this.loadedTask.entityModel.TaskLatitude,
          lng: +this.loadedTask.entityModel.TaskLongitude,
        };
      }
      return null;
    },
    ...mapState("tasks", ["objects"]),
  },
  methods: {
    setAddressByMap(val) {
      if (val) {
        this.objectId = null;
        this.loadedTask.entityModel.ObjectId = null;
        this.loadedTask.entityModel.TaskAddress = val;
        setTimeout(() => {
          this.$emit("editTask");
        });
      }
    },
    setAddress(item) {
      this.objectId = item.Id;
      this.close();
      this.loadedTask.entityModel.TaskAddress = this.getObjectAddressById(
        this.objectId
      );
      this.loadedTask.entityModel.TaskLatitude = item.Latitude;
      this.loadedTask.entityModel.TaskLongitude = item.Longitude;
      this.loadedTask.entityModel.ObjectId = item.Id;
      this.$emit("editTask");
    },
    close() {
      this.search = "";
      this.open = false;
    },
    unselectObject() {
      this.search = "";
      this.open = false;
      this.objectId = null;
      this.loadedTask.entityModel.ObjectId = null;
      this.loadedTask.entityModel.TaskAddress = "";
    },
    setGeoByMap(position) {
      this.loadedTask.entityModel.TaskLatitude = +position.lat.toFixed(8);
      this.loadedTask.entityModel.TaskLongitude = +position.lng.toFixed(8);
    },
  },
  components: {
    GoogleMap,
  },
};
</script>

<style scoped></style>
