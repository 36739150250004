<template>
  <router-link
    :to="routerParams"
    class="sidebar__item"
    :class="{
      'sidebar__item--with-child': item.HaveChild,
      'sidebar__item--open': open,
    }"
  >
    <span
      class="sidebar__item-icon"
      v-if="item.HaveChild"
      @click.prevent="openSubList"
    >
      <svg width="11" height="7" viewBox="0 0 11 7">
        <use xlink:href="#chevron"></use>
      </svg>
    </span>
    <span class="sidebar__item-name"
      >{{ item.Name }}
      <span class="sidebar__item-circle" v-if="+item.Moderation"></span
    ></span>
    <ul class="sidebar__list sidebar__list_is-sublist">
      <row
        v-for="child in item.child"
        :key="child.Id"
        :item="child"
        :articles="articles"
        :categorySlug="categorySlug"
        :currentArticle="currentArticle"
      />
    </ul>
  </router-link>
</template>

<script>
import Row from "./Row";
import { LOAD_ARTICLE_CHILDS } from "../../../store/wiki/constants";
import { mapActions } from "vuex";
import UrlGeneratorMixin from "../mixins/UrlGeneratorMixin";

export default {
  name: "Row",
  mixins: [UrlGeneratorMixin],
  props: ["item", "currentArticle", "categorySlug", "parent", "articles"],
  data() {
    return {
      open: false,
    };
  },
  computed: {},
  methods: {
    openSubList() {
      this[LOAD_ARTICLE_CHILDS]({ PostId: this.item.Id });
      this.open = !this.open;
    },
    ...mapActions("wiki", [LOAD_ARTICLE_CHILDS]),
  },
  components: {
    Row,
  },
};
</script>

<style scoped></style>
