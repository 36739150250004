import * as c from "./constants";
import Api from "@/api/clients";
import Vue from "vue";
import { showAlert } from "@/utils";
import * as _ from "lodash";

export default {
  // <-- COMMON |____
  // ||-------------------------------------------------
  [c.GET_OBJECTS](state) {
    togglePreloader(state, { preloader: "main", status: true });
    Api.getObjects()
      .then((res) => {
        state.commit(c.SET_OBJECTS, res.data);
        Vue.localStorage.set("objects", res.data);
        togglePreloader(state, { preloader: "main", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось загрузить объекты");
        togglePreloader(state, { preloader: "main", status: false });
        console.log(err);
      });
  },
  [c.SILENT_LOAD_OBJECTS](state) {
    togglePreloader(state, { preloader: "main", status: true });
    Api.getObjects().then((res) => {
      state.commit(c.SET_OBJECTS, res.data);
      togglePreloader(state, { preloader: "main", status: false });
      Vue.localStorage.set("objects", res.data);
    });
  },
  [c.SET_SEARCH_INPUT](state, value) {
    state.commit(c.SET_SEARCH_INPUT, value);
  },
  [c.SET_SORT](state, value) {
    state.commit(c.SET_SORT, value);
  },
  [c.SET_OBJECTS](state, objects) {
    state.commit(c.SET_OBJECTS, objects);
  },
  [c.SET_SELECTED_ITEMS](state, payload) {
    if (payload.removeAll) state.commit(c.REMOVE_SELECTED_ITEMS);
    else state.commit(c.SET_SELECTED_ITEMS, payload.item);
  },
  [c.GET_COMPANIES](state) {
    Api.getCompanies()
      .then((res) => {
        state.commit(c.SET_COMPANIES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось загрузить клиентов");
        console.log(err);
      });
  },
  // ||-----------------------------------------------/>
  // <-- COMPANY |____
  // ||-------------------------------------------------
  [c.SELECT_COMPANY](state, id) {
    state.commit(c.SELECT_COMPANY, id);
  },
  [c.UPDATE_COMPANY](state, payload) {
    togglePreloader(state, { preloader: "company", status: true });
    Api.updateCompany(state.getters.companyId, payload)
      .then((res) => {
        state.commit(c.UPDATE_COMPANY, res.data);
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        showAlert("success", "Успешно", "Компания обновлена");
        togglePreloader(state, { preloader: "company", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось обновить компанию");
        togglePreloader(state, { preloader: "company", status: false });
        console.log(err);
      });
  },
  [c.CREATE_COMPANY](state) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "main", status: true });
      Api.createCompany()
        .then((res) => {
          state.commit(c.CREATE_COMPANY, res.data);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          showAlert("success", "Успешно", "Новая компания создана");
          togglePreloader(state, { preloader: "main", status: false });
          resolve(res.data.Id);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось создать компанию");
          togglePreloader(state, { preloader: "main", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  [c.DELETE_COMPANY](state) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "company", status: true });
      Api.deleteCompany(state.getters.companyId)
        .then((res) => {
          state.commit(c.SET_COMPANIES, res.data);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          showAlert("success", "Успешно", "Компания удалена");
          togglePreloader(state, { preloader: "company", status: false });
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось удалить компанию");
          togglePreloader(state, { preloader: "company", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  // ||-----------------------------------------------/>
  // <-- OBJECTS |____
  // ||-------------------------------------------------
  [c.SELECT_OBJECT](state, object) {
    state.commit(c.SELECT_OBJECT, object);
  },
  [c.UPDATE_OBJECT](state, payload) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "object", status: true });
      Api.updateObject(state.getters.selectedObject.Id, payload)
        .then((res) => {
          state.commit(c.UPDATE_OBJECT, res.data);
          showAlert("success", "Успешно", "Объект обновлен");
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          resolve();
          togglePreloader(state, { preloader: "object", status: false });
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось обновить объект");
          togglePreloader(state, { preloader: "object", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  [c.UPDATE_LICENSE](state, date) {
    togglePreloader(state, { preloader: "object", status: true });
    Api.updateLicense(state.getters.selectedObject.Id, date)
      .then((res) => {
        state.commit(c.UPDATE_OBJECT, res.data);
        showAlert("success", "Успешно", "Дата лицензии обновлена");
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        togglePreloader(state, { preloader: "object", status: false });
        console.log(err);
      });
  },
  [c.CREATE_OBJECT](state) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "main", status: true });
      Api.createObject()
        .then((res) => {
          state.commit(c.CREATE_OBJECT, res.data);
          showAlert("success", "Успешно", "Новый объект создан");
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          togglePreloader(state, { preloader: "main", status: false });
          resolve(res.data.Id);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось создать объект");
          togglePreloader(state, { preloader: "main", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  [c.DELETE_OBJECT](state) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "object", status: true });
      Api.deleteObject(state.getters.selectedObject.Id)
        .then((res) => {
          Vue.localStorage.set("objects", res.data);
          state.commit(c.SET_OBJECTS_FROM_REMOVE, res.data);
          showAlert("success", "Успешно", "Объект удален");
          togglePreloader(state, { preloader: "object", status: false });
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось удалить объект");
          togglePreloader(state, { preloader: "object", status: false });
          console.log(err);
          reject(err);
        });
    });
  },

  [c.PATCH_OBJECT](state, data) {
    return new Promise((resolve, reject) => {
      Api.patchObject(data.Id, data.payload)
        .then((res) => {
          state.commit(c.UPDATE_OBJECT, res.data);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          showAlert("success", "Успешно");
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
          reject(err);
        });
    });
  },
  [c.GENERATE_LICENSE](state) {
    togglePreloader(state, { preloader: "object", status: true });
    Api.generateLicense(state.getters.selectedObject.Id)
      .then((res) => {
        state.commit(c.UPDATE_OBJECT, res.data);
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        showAlert("success", "Успешно");
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        togglePreloader(state, { preloader: "object", status: false });
      });
  },
  // ||-----------------------------------------------/>

  // <-- CONTACTS |____
  // ||-------------------------------------------------
  [c.UPDATE_CONTACT](state, { payload, preloader }) {
    togglePreloader(state, { preloader: "object", status: preloader });
    Api.updateContact(payload.Id, payload)
      .then((res) => {
        state.commit(c.UPDATE_CONTACT, res.data);
        if (preloader) {
          showAlert("success", "Успешно", "Контакт обновлен");
        }
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось обновить контакт");
        togglePreloader(state, { preloader: "object", status: false });
        console.log(err);
      });
  },
  [c.CREATE_CONTACT](state) {
    togglePreloader(state, { preloader: "object", status: true });
    Api.createContact(state.getters.selectedObject.Id)
      .then((res) => {
        state.commit(c.CREATE_CONTACT, res.data);
        showAlert("success", "Успешно", "Новый контакт создан");
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось создать контакт");
        togglePreloader(state, { preloader: "object", status: false });
        console.log(err);
      });
  },
  [c.DELETE_CONTACT](state, contactId) {
    togglePreloader(state, { preloader: "object", status: true });
    Api.deleteContact(contactId)
      .then(() => {
        state.commit(c.DELETE_CONTACT, contactId);
        showAlert("success", "Успешно", "Контакт удален");
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось удалить контакт");
        togglePreloader(state, { preloader: "object", status: false });
        console.log(err);
      });
  },

  [c.UPDATE_CONTACT_DISTB](state, payload) {
    payload.Phone = payload.Phone ? payload.Phone.replace(/[^0-9.]/g, "") : "";
    togglePreloader(state, { preloader: "distb", status: true });
    Api.updateDistributerContact(payload.Id, payload)
      .then((res) => {
        if (res.data.status !== void 0 && res.data.status === "error") {
          showAlert("error", "Ошибка", res.data.message);
        } else {
          state.commit(c.UPDATE_CONTACT_DISTB, res.data);
          showAlert("success", "Успешно", "Контакт обновлен");
        }
        togglePreloader(state, { preloader: "distb", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось обновить контакт");
        togglePreloader(state, { preloader: "distb", status: false });
        console.log(err);
      });
  },
  [c.CREATE_CONTACT_DISTB](state) {
    togglePreloader(state, { preloader: "distb", status: true });
    Api.createDistributerContact(state.getters.selectedDistb.Id)
      .then((res) => {
        state.commit(c.CREATE_CONTACT_DISTB, res.data);
        showAlert("success", "Успешно", "Новый контакт создан");
        togglePreloader(state, { preloader: "distb", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось создать контакт");
        togglePreloader(state, { preloader: "distb", status: false });
        console.log(err);
      });
  },
  [c.DELETE_CONTACT_DISTB](state, contactId) {
    togglePreloader(state, { preloader: "distb", status: true });
    Api.deleteDistributerContact(contactId)
      .then(() => {
        state.commit(c.DELETE_CONTACT_DISTB, contactId);
        showAlert("success", "Успешно", "Контакт удален");
        togglePreloader(state, { preloader: "distb", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось удалить контакт");
        togglePreloader(state, { preloader: "distb", status: false });
        console.log(err);
      });
  },
  // ||-----------------------------------------------/>

  // <--DISTB |____
  // ||-------------------------------------------------
  [c.GET_DISTB](state) {
    Api.getDistb()
      .then((res) => {
        state.commit(c.SET_DISTB, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось загрузить дистрибьюторов");
        console.log(err);
      });
  },
  [c.GET_SINGLE_DISTB](state) {
    if (state.getters.selectedDistb) {
      Api.getSingleDistb(state.getters.selectedDistb.Id)
        .then((res) => {
          state.commit(c.SELECT_DISTB, res.data);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось загрузить дистрибьютора");
          console.log(err);
        });
    }
  },
  [c.CREATE_DISTB](state) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "main", status: true });
      Api.createDistb()
        .then((res) => {
          state.commit(c.CREATE_DISTB, res.data);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          showAlert("success", "Успешно", "Дистрибьютор создан");
          togglePreloader(state, { preloader: "main", status: false });
          resolve(res.data.Id);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          togglePreloader(state, { preloader: "main", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  [c.SELECT_DISTB](state, distb) {
    state.commit(c.SELECT_DISTB, distb);
  },
  [c.UPDATE_DISTB](state, distb) {
    togglePreloader(state, { preloader: "distb", status: true });
    if (distb.Phone) {
      distb.Phone = distb.Phone.replace(/[^0-9.]/g, "");
    }
    Api.updateDistb(distb.Id, distb)
      .then((res) => {
        state.commit(c.UPDATE_DISTB, res.data);
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        showAlert("success", "Успешно", "Дистрибьютор обновлен");
        togglePreloader(state, { preloader: "distb", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось обновить дистрибьютора");
        togglePreloader(state, { preloader: "distb", status: false });
        console.log(err);
      });
  },
  [c.DELETE_DISTB](state, id) {
    return new Promise((resolve, reject) => {
      togglePreloader(state, { preloader: "distb", status: true });
      Api.deleteDistb(id)
        .then((res) => {
          state.commit(c.SET_DISTB, res.data);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          showAlert("success", "Успешно", "Дистрибьютор удален");
          togglePreloader(state, { preloader: "distb", status: false });
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось удалить дистрибьютора");
          togglePreloader(state, { preloader: "distb", status: false });
          console.log(err);
          reject(err);
        });
    });
  },
  // ||-----------------------------------------------/>
  // <--ATTACH |____
  // ||-------------------------------------------------
  [c.ATTACH_COMPANY_TO_DISTB](state, payload) {
    return new Promise((resolve, reject) => {
      Api.attachCompanyToDistb(payload)
        .then(() => {
          showAlert("success", "Успешно", "Компания добавлена");
          state.dispatch(c.GET_DISTB_COMPANIES);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          state.dispatch(c.GET_SINGLE_DISTB);
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "", err.response.data.message);
          // console.log(err.response.data);
          reject(err);
        });
    });
  },
  [c.ATTACH_OBJECT_TO_DISTB](state, payload) {
    return new Promise((resolve, reject) => {
      Api.attachObjectToDistb(payload)
        .then(() => {
          showAlert("success", "Успешно", "Объект добавлен");
          state.dispatch(c.GET_DISTB_OBJECTS);
          state.dispatch(c.SILENT_LOAD_OBJECTS);
          state.dispatch(c.GET_SINGLE_DISTB);
          resolve("success");
        })
        .catch((err) => {
          showAlert("error", "", err.response.data.message);
          console.log(err.response.data);
          reject(err);
        });
    });
  },
  [c.GET_DISTB_COMPANIES](state) {
    Api.getDistbCompanies()
      .then((res) => {
        state.commit(c.GET_DISTB_COMPANIES, res.data);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось загрузить компании дистрибьюторов"
        );
        console.log(err);
      });
  },
  [c.GET_DISTB_OBJECTS](state) {
    Api.getDistbObjects()
      .then((res) => {
        state.commit(c.GET_DISTB_OBJECTS, res.data);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось загрузить объекты дистрибьюторов"
        );
        console.log(err);
      });
  },
  [c.GET_DISTB_CONTACTS](state) {
    Api.getDistbContacts()
      .then((res) => {
        state.commit(c.GET_DISTB_CONTACTS, res.data);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось загрузить компании дистрибьюторов"
        );
        console.log(err);
      });
  },
  [c.CREATE_DISTB_CONTACT](state, payload) {
    Api.createDistbContact(payload)
      .then((res) => {
        state.commit(c.PUSH_DISTB_CONTACTS, res.data);
        state.dispatch(c.GET_SINGLE_DISTB);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось создать контактное лицо дистрибьютора"
        );
        console.log(err);
      });
  },
  [c.UPDATE_DISTB_CONTACT](state, payload) {
    payload.Phone = payload.Phone ? payload.Phone.replace(/[^0-9.]/g, "") : "";
    Api.patchDistbContact(payload)
      .then((res) => {
        state.commit(c.UPDATE_DISTB_CONTACT, res.data);
        state.dispatch(c.GET_SINGLE_DISTB);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось обновить контактное лицо дистрибьютора"
        );
        console.log(err);
      });
  },
  [c.REMOVE_ATTACH_COMPANY_TO_DISTB](state, id) {
    id = _.find(state.getters.distbsCompanies, ["ClientId", id]).Id;
    togglePreloader(state, { preloader: "distb", status: true });
    togglePreloader(state, { preloader: "company", status: true });

    Api.removeCompanyAttach(id)
      .then((res) => {
        state.commit(c.GET_DISTB_COMPANIES, res.data);
        state.dispatch(c.GET_SINGLE_DISTB);
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        togglePreloader(state, { preloader: "distb", status: false });
        togglePreloader(state, { preloader: "company", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        togglePreloader(state, { preloader: "distb", status: false });
        togglePreloader(state, { preloader: "company", status: false });
      });
  },
  [c.REMOVE_ATTACH_OBJECT_TO_DISTB](state, id) {
    id = _.find(state.getters.distbsObjects, ["ObjectId", id]).Id;
    togglePreloader(state, { preloader: "distb", status: true });
    togglePreloader(state, { preloader: "object", status: true });

    Api.removeObjectAttach(id)
      .then((res) => {
        state.commit(c.GET_DISTB_OBJECTS, res.data);
        state.dispatch(c.SILENT_LOAD_OBJECTS);
        state.dispatch(c.GET_SINGLE_DISTB);
        togglePreloader(state, { preloader: "distb", status: false });
        togglePreloader(state, { preloader: "object", status: false });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        togglePreloader(state, { preloader: "distb", status: false });
        togglePreloader(state, { preloader: "object", status: false });
      });
  },
  // ||-----------------------------------------------/>
};

const togglePreloader = (state, data) => {
  state.commit(c.TOGGLE_PRELOADER, data);
};
