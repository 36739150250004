<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_3_1 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <Print_3_1 />
      </magnific-popup-modal>
    </div>
    <div class="pdf-settings">
      <div class="virtual-tasks-text" ref="virtual-tasks-text">
        {{ offerObject.json.TasksPageTextVirtual }}
      </div>
      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Задачи:</div>
            <textarea
              class="form-row__input form-row__input--textarea"
              style="height: 300px"
              v-model="offerObject.json.TasksPageText"
              @keyup="textTasksChecked"
            >
            </textarea>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(3)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(4)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_3_1 from "../prints/print_3-1";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      center: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      position: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      place: null,
      gmapWindow: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    checkSelectClass(type) {
      if (this.offerObject.json.ObjectType === type) {
        return "select-d__dropdown-item--active";
      }
    },
    setType(type) {
      this.offerObject.json.ObjectType = type;
      this.changeOfferByObjectType(this.offerObject.json.ObjectType);
    },
    // <--google maps func |____
    // ||-------------------------------------------------
    drag(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    centerMark(location) {
      this.center = this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.setAddressByGeo();
    },
    setAddressByGeo() {
      this.$geocoder.send(this.position, (response) => {
        try {
          this.offerObject.json.City = _.find(response.results, (i) => {
            return (
              i.types.includes("administrative_area_level_2") &&
              i.types.includes("political")
            );
          }).address_components[0].long_name;
          this.offerObject.json.Address = _.find(response.results, (i) => {
            return i.types.includes("street_address");
          }).formatted_address;
        } catch (e) {
          e;
        }
        // console.log(response)
      });
    },
    changeTextLat(val) {
      this.position.lat = +val;
      this.center.lat = +val;
    },
    changeTextLng(val) {
      this.position.lng = +val;
      this.center.lng = +val;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.center = this.position = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.place = null;
        this.setAddressByGeo();
      }
    },

    // ||-----------------------------------------------/>
  },
  components: { Print_3_1 },
};
</script>
