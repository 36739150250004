<template>
  <div
    :class="
      user_form_preloader
        ? 'settings-users_block settings-users_config disable'
        : 'settings-users_block settings-users_config'
    "
  >
    <div class="settings-users_block__name">
      <h4>Настройка</h4>
    </div>

    <div class="settings-users_block__inner">
      <div class="settings-users_block__preloader" v-if="user_form_preloader">
        <i class="circle-preloader"></i>
      </div>
      <div
        class="settings-users_config__inner settings-users_config__inner--users"
        v-else
      >
        <form
          class="settings-users_config__form"
          @submit="submit"
          novalidate="true"
          :class="checkUserRight(rightUpdateUsers) ? '' : 'disable_inputs'"
        >
          <div class="settings-users_config__input-group">
            <div
              :class="
                errors['lastname']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="lastname">Фамилия:</label>
              <input id="lastname" type="text" v-model="lastname" />
              <span v-if="errors['lastname']" class="error_text">{{
                errors.lastname.message
              }}</span>
            </div>
            <div
              :class="
                errors['firstname']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="firstname">Имя:</label>
              <input id="firstname" type="text" v-model="firstname" />
              <span v-if="errors['firstname']" class="error_text">{{
                errors.firstname.message
              }}</span>
            </div>
            <div class="settings-users_config__input">
              <label for="middlename">Отчество:</label>
              <input id="middlename" type="text" v-model="middlename" />
            </div>
          </div>

          <div class="settings-users_config__input-group">
            <div class="settings-users_config__input">
              <label>Заявки на объект:</label>
              <input
                type="checkbox"
                class="settings-users_config__input--checkbox"
                v-model="ObjectRequestNotification"
                v-bind:true-value="'1'"
                v-bind:false-value="'0'"
              />
            </div>
          </div>
          <div class="settings-users_config__input-group">
            <div
              :class="
                errors['email']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="email">Почта:</label>
              <input id="email" type="email" v-model="email" />
              <span v-if="errors['email']" class="error_text">{{
                errors.email.message
              }}</span>
            </div>
            <div
              :class="
                errors['phone']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="phone">Телефон / Логин:</label>
              <input
                id="phone"
                type="tel"
                v-mask="'+7(###)###-##-##'"
                v-model="phone"
              />
              <span v-if="errors['phone']" class="error_text">{{
                errors.phone.message
              }}</span>
            </div>
          </div>
          <div class="settings-users_config__input-group">
            <div
              :class="
                errors['password']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="password">Пароль:</label>
              <input id="password" type="password" v-model="password" />
              <span v-if="errors['password']" class="error_text">{{
                errors.password.message
              }}</span>
            </div>
            <div
              :class="
                errors['confirm_password']
                  ? 'settings-users_config__input settings-users_config__input--error'
                  : 'settings-users_config__input'
              "
            >
              <label for="confirm_password">Пароль еще раз:</label>
              <input
                id="confirm_password"
                type="password"
                v-model="confirm_password"
              />
            </div>
          </div>

          <div class="settings-users_config__input-group">
            <div class="settings-users_config__input">
              <label for="role">Роль:</label>
              <select id="role" v-model="roleId">
                <option disabled :value="null">Не выбрана</option>
                <option
                  :key="index"
                  v-for="(role, index) in roles"
                  :value="role.Id"
                >
                  {{ role.Name }}
                </option>
              </select>
            </div>
          </div>
          <button
            class="hidden"
            type="submit"
            id="submit-form"
            value="Submit"
          ></button>
        </form>
      </div>
    </div>

    <div class="settings-users_block__btn-wrap">
      <label
        for="submit-form"
        class="button button_green button_uppercase button_align_center"
        v-disabled="{
          allow: checkUserRight(rightUpdateUsers),
          title: 'Запрещено настройками системы',
        }"
      >
        СОХРАНИТЬ
      </label>
      <label
        @click="$emit('deleteUser')"
        class="button button_green button_uppercase button_align_center"
        v-disabled="{
          allow: checkUserRight(rightDeleteUsers),
          title: 'Запрещено настройками системы',
        }"
      >
        Удалить
      </label>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import { UsersSettingsForm } from "@/mixins/UsersSettingsForm";
import { mask } from "vue-the-mask";
export default {
  mixins: [UsersSettingsForm, Helpers],
  props: ["user_form_preloader", "roles"],
  data() {
    return {
      moduleName: "settings",
      rightUpdateUsers: "02BAACBA-8B68-4E2F-B302-23D1C1448219",
      rightDeleteUsers: "36C06443-B366-4ABE-B35B-422CA84B1DC8",
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit("updateUser");
    },
  },
  directives: { mask },
};
</script>

<style scoped>
.settings-users_block__btn-wrap .button {
  height: 45px;
  line-height: 26px;
}
</style>
