<template>
  <div class="task-create-form__row">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--2"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__label">Описание</div>

      <vue-editor
        class="task-create-form__text task-editor task-editor-toolbar-dynamic"
        id="task-editor_task-detail"
        v-model="loadedTask.entityModel.TaskDescription"
        @blur="
          () => {
            checkUserTaskRight('edit_task_description') && $emit('editTask');
            blur();
          }
        "
        @focus="focus"
        :editor-options="{ bounds: '#task-editor_task-detail' }"
        :editorToolbar="
          checkUserTaskRight('edit_task_description')
            ? customToolbarWithLink
            : [[]]
        "
        ref="editor"
        @text-change="watchForSubscribe"
        :placeholder="
          checkUserTaskRight('edit_task_description')
            ? 'Введите сюда описание задачи...'
            : ''
        "
        @selection-change="watchEditorSelection"
        :disabled="!checkUserTaskRight('edit_task_description')"
        @paste.native="onPaste"
      ></vue-editor>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import textSubscriber from "../../../../../mixins/tasks/textSubscriber";
import { mapState } from "vuex";

export default {
  props: ["loadedTask"],
  mixins: [common, textSubscriber],
  mounted() {
    if (this.loadedTask.entityModel.TaskDescription.length) {
      this.$refs["editor"].$el.classList.add("task-editor--full");
    }
    this.$refs["editor"].$el
      .querySelectorAll(".ql-toolbar button")
      .forEach((i) => i.setAttribute("tabindex", "-1"));
    this.$refs["editor"].quill.clipboard.addMatcher(
      Node.TEXT_NODE,
      function (node, delta) {
        var regex = /https?:\/\/[^\s]+/g;
        if (typeof node.data !== "string") return;
        var matches = node.data.match(regex);

        if (matches && matches.length > 0) {
          var ops = [];
          var str = node.data;
          matches.forEach(function (match) {
            var split = str.split(match);
            var beforeLink = split.shift();
            ops.push({ insert: beforeLink });
            ops.push({ insert: match, attributes: { link: match } });
            str = split.join(match);
          });
          ops.push({ insert: str });
          delta.ops = ops;
        }

        return delta;
      }
    );

    // this.$refs["editor"].quill.clipboard.addMatcher(
    //   Node.ELEMENT_NODE,
    //   (node, delta) => {
    //     delta.ops = delta.ops.map((op) => {
    //       return {
    //         insert: op.insert,
    //       };
    //     });
    //     return delta;
    //   }
    // );
  },
  computed: {
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    focus() {
      this.$refs["editor"].$el.classList.add("task-editor--full");
    },
    blur() {
      if (!this.loadedTask.entityModel.TaskDescription) {
        this.$refs["editor"].$el.classList.remove("task-editor--full");
      }
    },
    onPaste(e) {
      const files = e.clipboardData.files;
      if (files.length) {
        this.$emit("filesChange", files);
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>
