<template>
  <div class="popup__inner-cont">
    <div @click="openModal()">
      <Print_17 />
    </div>
    <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
      <print_17 />
    </magnific-popup-modal>
    <div class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__center pdf-settings__cont--total pb-0 scroll-style"
      >
        <div class="pdf-settings__info-text">
          <div class="pdf-settings__info-text">
            Данные для этой страницы <br />
            редактировать нельзя.
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <label class="p-settings__total">
          <div class="p-settings__check">
            <div
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                name="row_checkbox_clients"
                @click.prevent="
                  offerObject.json.passPages.option_desc = !offerObject.json
                    .passPages.option_desc
                "
                :class="
                  offerObject.json.passPages.option_desc
                    ? 'checkbox__box--active'
                    : ''
                "
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </div>
          </div>
          <div class="col-auto">Не добавлять страницу в КП</div>
        </label>
        <div class="pdf-settings__next pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(16)">
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(18)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_17 from "../prints/print_17";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  components: { Print_17 },
};
</script>
