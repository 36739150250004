<template>
  <div id="entry-wrap" class="scroll-style">
    <div
      class="pdf-cont"
      v-for="(page, index) in objectPageLength"
      :key="index + 'page'"
    >
      <div class="print print--19">
        <div class="print__cont pb-0">
          <div class="print__logo">
            <img :src="getimgStaticSrc('sales/print-logo.svg')" />
          </div>
          <div
            class="print__title"
            :style="{
              backgroundImage: `url(${getimgStaticSrc(
                'sales/print-badge.svg'
              )})`,
            }"
          >
            примеры <br />объектов
          </div>
        </div>
        <div class="print__object">
          <ObjectItem
            v-for="(item, index) in objects.filter((item, index) =>
              limitObject(item, index, page)
            )"
            :key="index"
            :item="item"
            :commercialSiteUrl="commercialSiteUrl"
          />
        </div>
        <div class="print__footer">{{ countPage("Objects", page) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import ObjectItem from "./components/ObjectItem";

export default {
  mixins: [SalesHelpers],
  methods: {
    limitObject(item, index, page) {
      return (
        !item.disabled &&
        index + 1 > (page - 1) * 3 &&
        index + 1 <= (page - 1) * 3 + 3
      );
    },
  },
  computed: {
    ...mapState("sales", ["offerObject", "commercialSiteUrl"]),
  },
  components: { ObjectItem },
};
</script>
