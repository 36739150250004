<template>
  <div class="cm-popup__right">
    <div class="header task-header cm-header" v-if="selectedItems.length">
      <div class="header__col cm-group-actions">
        <div class="button-group">
          <div
            class="button button-close button_grey button_uppercase"
            @click="unselectItems()"
          >
            <img src="/static/images/sales/close.svg" alt="" />
          </div>
          <div class="button button_grey button_uppercase">
            {{ selectedItems.length }}
          </div>
        </div>
        <div
          class="button button_grey button_uppercase"
          style="margin-left: auto"
          @click="deleteItems()"
        >
          удалить
        </div>
      </div>
    </div>
    <div class="task-popup-header" v-else>
      <div class="actions-header__col">
        <span
          class="button tm-btn tm-btn--add"
          @click="parentData.automationCreatePopupOpen = true"
          >+</span
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          method="post"
          action="#"
          class="search-bar search-bar_white actions-header__search-bar"
        >
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Поиск автоматизации"
            v-model="search"
            class="search-bar__input"
          />
        </form>
      </div>
    </div>
    <div class="table table--subtasks">
      <div class="table__header">
        <div class="table__col table__col_width_30" style="border-right: none">
          <p class="table__header-text">№</p>
        </div>
        <div class="table__col table__col_flexible">
          <p class="table__header-text">События и действия</p>
          <div class="table__filter-button">А-Я</div>
        </div>
      </div>

      <div class="table__row-wrap table__row-wrap--tasks">
        <div
          class="table__row--col"
          v-if="automations.action && automations.action.length"
          style="margin-bottom: 54px"
        >
          <div class="table__row table-big-category__row">
            <div class="table__col table__col_width_30">
              <div class="table-big-category__btn">
                <svg width="12" height="10" fill="#242529">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#section-arrow"
                  ></use>
                </svg>
              </div>
            </div>
            <div class="table__col table__col_flexible">
              <div class="table-big-category__name">
                Действия с этой задачей по событиям в других задачах
              </div>
            </div>
          </div>
          <div class="table-big-category__inner">
            <AutomationItem
              v-for="(item, index) in automations.action"
              :key="item.Id"
              :actionType="true"
              :selectedTaskParentData="parentData"
              :item="item"
              :parentData="settings"
              :index="index"
              @deleteAutomation="deleteAutomation"
            />
          </div>
        </div>
        <div
          class="table__row--col"
          v-if="automations.event && Object.keys(automations.event).length"
        >
          <div class="table__row table-big-category__row">
            <div class="table__col table__col_width_30">
              <div class="table-big-category__btn">
                <svg width="12" height="10" fill="#242529">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#section-arrow"
                  ></use>
                </svg>
              </div>
            </div>
            <div class="table__col table__col_flexible">
              <div class="table-big-category__name">
                Действия с другими задачами по событиям в этой задаче
              </div>
            </div>
          </div>
          <div class="table-big-category__inner">
            <AutomationItem
              v-for="(item, key) in automations.event"
              :key="item.Id"
              :item="item"
              :parentData="settings"
              :selectedTaskParentData="parentData"
              :index="getEventIndex(key)"
              @deleteAutomation="deleteAutomation"
            />
          </div>
        </div>
      </div>
      <div class="simple-spinner" v-if="settings.preloader">
        <Spinner
          :noFadeIn="true"
          name="wave"
          color="#c9eeff"
          width="60"
          height="60"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AutomationItem from "./AutomationItem";
import {
  GET_AUTOMATION_ACTIONS,
  GET_AUTOMATION_ACTIONS_OBJECTS,
  GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS,
  GET_AUTOMATION_ACTIONS_OBJECTS_TYPES,
  GET_AUTOMATION_ACTIONS_OPTIONS,
  GET_AUTOMATION_OBJECTS_TYPES,
  GET_AUTOMATION_TEMPLATES,
  LOAD_TASK_AUTOMATIONS,
} from "../../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";

export default {
  props: ["parentData"],
  name: "Automation",
  data() {
    return {
      search: "",
      selectedItems: [],
      automations: {},
      settings: {
        preloader: true,
      },
    };
  },
  created() {
    this[GET_AUTOMATION_OBJECTS_TYPES]();
    this[GET_AUTOMATION_ACTIONS]();
    this[GET_AUTOMATION_ACTIONS_OPTIONS]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS_TYPES]();
    this[GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS]();
    this[GET_AUTOMATION_TEMPLATES]();
    this[LOAD_TASK_AUTOMATIONS](this.selectedTask.entityModel.Id)
      .then((data) => {
        this.automations = data;
      })
      .finally(() => (this.settings.preloader = false));
  },
  computed: {
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    deleteItems() {},
    unselectItems() {},
    deleteAutomation(Id) {
      _.each(["action", "event"], (i) => {
        if (i === "action") {
          this.automations[i] = _.reject(this.automations[i], { Id });
        } else {
          _.each(Object.keys(this.automations[i]), (k) => {
            _.each(Object.keys(this.automations[i][k]), (kk) => {
              this.automations[i][k][kk] = _.reject(
                this.automations[i][k][kk],
                { Id }
              );
            });
          });
        }
      });
    },
    getEventIndex(key) {
      return Object.keys(this.automations.event).indexOf(key);
    },
    ...mapActions("tasks", [
      LOAD_TASK_AUTOMATIONS,
      GET_AUTOMATION_OBJECTS_TYPES,
      GET_AUTOMATION_ACTIONS,
      GET_AUTOMATION_ACTIONS_OPTIONS,
      GET_AUTOMATION_ACTIONS_OBJECTS,
      GET_AUTOMATION_ACTIONS_OBJECTS_TYPES,
      GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS,
      GET_AUTOMATION_TEMPLATES,
    ]),
  },
  components: {
    AutomationItem,
  },
};
</script>

<style scoped></style>
