import { mapActions } from "vuex";
import * as c from "../store/common/constants";

export const UserSettingsValidate = {
  methods: {
    checkForm: function () {
      let can = false;
      const form = document.querySelector(
        ".settings-users_config .settings-users_block__inner"
      );
      this[c.CHANGE_USER_FORM_FIELD]({
        field_type: "errors",
        value: {},
      });
      if (!this.firstname) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: { firstname: { message: "Поле обязательно к заполнению" } },
        });
      } else if (this.firstname.length <= 2 || this.firstname.length > 20) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: {
            firstname: { message: "Имя должно быть от 3 до 20 символов" },
          },
        });
      }
      if (!this.lastname) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: { lastname: { message: "Поле обязательно к заполнению" } },
        });
      } else if (this.lastname.length <= 2 || this.lastname.length > 20) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: {
            lastname: { message: "Фамилия должна быть от 3 до 20 символов" },
          },
        });
      }
      if (!this.email) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: { email: { message: "Поле обязательно к заполнению" } },
        });
      } else if (!this.validEmail(this.email)) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: { email: { message: "Неверный адрес электронной почты" } },
        });
      }
      if (!this.phone) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: { phone: { message: "Поле обязательно к заполнению" } },
        });
      } else if (this.phone.length !== 16) {
        this[c.CHANGE_USER_FORM_FIELD]({
          field_type: "errors",
          value: {
            phone: { message: "Телефон должен быть в формате (495)1234567" },
          },
        });
      }
      // if (!this.password) {
      //   this[c.CHANGE_USER_FORM_FIELD]({
      //     field_type: 'errors',
      //     value: { password: { message: 'Поле обязательно к заполнению' } }
      //   })
      // } else if (this.password.length < 4) {
      //   this[c.CHANGE_USER_FORM_FIELD]({
      //     field_type: 'errors',
      //     value: { password: { message: 'Пароль должен быть от 4 символов' } }
      //   })
      // } else if (this.password !== this.confirm_password) {
      //   this[c.CHANGE_USER_FORM_FIELD]({
      //     field_type: 'errors',
      //     value: { password: { message: 'Пароли должны совпадать' } }
      //   })
      // }

      if (!Object.keys(this.errors).length) {
        can = true;
      }
      form.scrollTop = 0;
      return can;
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    ...mapActions("common", [c.CHANGE_USER_FORM_FIELD]),
  },
};
