<template>
  <div class="task-to-create__row">
    <div class="task-to-create__counter-label">
      <div class="task-to-create__counter-value">{{ index + 1 }}</div>
    </div>
    <div class="task-to-create__open-btn">+</div>
    <div class="task-to-create__name">
      Добавить еще задачу
      <Spinner
        class="task-to-create__preloader"
        v-if="preloader"
        :noFadeIn="true"
        name="line-scale-party"
        color="#f9ad1a"
        width="60"
        height="60"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddTaskToListButton",
  props: ["index", "preloader"],
};
</script>

<style>
.task-to-create__row {
  border-bottom: 1px solid #efefef;
  height: 64px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 16px 0 24px;
  cursor: pointer;
}
.task-to-create__open-btn {
  background: #f2f2f2;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}
.task-to-create__open-btn svg {
  transform: rotate(-90deg);
}
.task-to-create__name {
  font-size: 16px;
  color: #929294;
  position: relative;
}
.task-to-create__counter-label {
  margin-right: 27px;
  width: 16px;
  text-align: center;
  color: #b2b2b2;
}
.task-to-create__counter-value {
  font-size: 13px;
}
.task-to-create__preloader {
  right: -65px;
  position: absolute;
  top: -13px;
  transform: scale(0.6);
}
</style>
