<template>
  <div
    class="popup task-popup event-popup event-popup_active task-popup-parent popup task-popup-detail"
    @click.stop
  >
    <div class="popup__wrapper" @click="$emit('close')">
      <div class="popup__content" @click.stop>
        <div class="popup__header">
          <h2 class="popup__heading">Тэги</h2>
          <div class="popup__close" @click="$emit('close')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="table-vertical">
          <div
            class="table-vertical__col"
            v-for="item in commonSettings.tags"
            :key="item.Id"
          >
            <div class="table-vertical__cell">
              <span
                class="task-label"
                :style="`background-color: #${item.Color}`"
                >{{ item.TagName }}
              </span>
              <span class="tags-settings__buttons tags-settings__buttons-right _checkbox">
                <span
                  v-if="countChildrenSelected(item) > 0"
                  class="tags-settings__counter"
                  :style="`background: #${item.Color}`"
                >
                  {{ countChildrenSelected(item) }}
                </span>
                <input
                  type="checkbox"
                  :id="item.Id"
                  name="request-tags"
                  :value="item.Id"
                  :checked="reportTags.find((i) => i.Id === item.Id)"
                  @click.prevent
                  @click="onSelectedChange(item, $event)"
                />
              </span>
            </div>
            <div v-for="child in item.child" :key="child.id">
              <rps-analytics-tag-item
                :item="child"
                :color="`#${item.Color}`"
                :report-tags="reportTags"
                :depth="1"
                @addTag="(params) => $emit('addTag', params)"
                @removeTag="(params) => $emit('removeTag', params)"
              />
            </div>
          </div>
        </div>
        <div class="task-form-filter__bottom justify-content-end">
          <template>
            <div
              class="task-form-filter__bottom-btn-gray"
              @click="$emit('clearTags')"
            >
              Очистить
            </div>
            <div class="task-form-filter__bottom-btn" @click="$emit('close')">
              Сохранить
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RpsAnalyticsTagItem from "./RpsAnalyticsTagItem";
import { mapState } from "vuex";
import * as _ from "lodash";

export default {
  name: "RpsAnalyticsTags",
  components: { RpsAnalyticsTagItem },
  props: ["reportTags"],
  computed: {
    ...mapState("rpsSupport", ["commonSettings"]),
  },
  methods: {
    onSelectedChange(tag, event) {
      if (event.target.checked) this.$emit("addTag", tag);
      else this.$emit("removeTag", tag);
    },
    countChildrenSelected(item) {
      let result = 0;
      const reportTags = this.reportTags;
      checkChildren(item.child);

      function checkChildren(array) {
        _.each(array, (child) => {
          if (reportTags.find((i) => i.Id === child.Id)) result++;
          if (child.child && child.child.length) checkChildren(child.child);
        });
      }

      return result;
    },
  },
};
</script>

<style scoped></style>
