var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"automation-builder"},[(!_vm.hideFirstPart)?[(!_vm.noSeparateWord1)?_c('div',{staticClass:"automation-builder__separate-word"},[_vm._v("\n      Если\n    ")]):_vm._e(),_vm._v(" "),_c('AutomationInput',{ref:"ActionId",attrs:{"items":_vm.automatizationsActions,"value":_vm.form.ActionId,"placeholder":'событие',"disabled":!_vm.create},on:{"choose":_vm.chooseActionId}}),_vm._v(" "),_c('AutomationInput',{ref:"ObjectTypeId",attrs:{"items":_vm.filteredItems(
          _vm.automatizationsObjectsTypes,
          _vm.filteredAutomatizationsObjectsTypes
        ),"value":_vm.form.ObjectTypeId,"placeholder":'свойство задачи',"disabled":!_vm.create},on:{"choose":_vm.chooseObjectTypeId},nativeOn:{"click":function($event){_vm.checkField('ActionId')}}}),_vm._v(" "),(!_vm.hideTaskName && _vm.form.EventTaskId)?_c('div',{staticClass:"automation-builder-task-input"},[_vm._v("\n      задачи\n      "),_c('span',{staticClass:"automation-select-text automation-builder-task-input__name",on:{"click":function($event){_vm.selectedTaskParentData.subtaskIdForOpen = _vm.form.EventTaskId}}},[_vm._v("\n        "+_vm._s(_vm.form.TaskEventName))])]):_vm._e(),_vm._v(" "),(_vm.showConditionField('conditionTemplate'))?_c('AutomationInput',{attrs:{"items":_vm.preparedAutomatizationsActionsOptions(
          _vm.filteredAutomatizationsActionsOptions
        ),"value":_vm.form.ActionOptionId,"placeholder":'условие',"capitalizeWord":true,"disabled":!_vm.create},on:{"choose":function($event){_vm.setCondition(
          $event,
          'ActionOptionId',
          'conditionTemplate',
          _vm.filteredAutomatizationsActionsOptions
        ),
          _vm.save()}},nativeOn:{"click":function($event){_vm.checkField('ObjectTypeId'), _vm.checkField('ActionId')}}}):_vm._e(),_vm._v(" "),_c('AutomationBuilderParams',{attrs:{"template":_vm.conditionTemplate,"params":_vm.params,"type":'condition',"create":_vm.create,"attach":_vm.attach,"edit":_vm.edit,"filteredAutomatizationsObjectsTypes":_vm.filteredAutomatizationsObjectsTypes,"form":_vm.form},on:{"save":_vm.save}})]:_vm._e(),_vm._v(" "),(!_vm.hideFirstPart && !_vm.hideLastPart)?[_vm._v(",")]:_vm._e(),_vm._v(" "),(!_vm.hideLastPart)?[(!_vm.noSeparateWord2)?_c('div',{staticClass:"automation-builder__separate-word automation-builder__separate-word--2"},[_vm._v("\n      то\n    ")]):_vm._e(),_vm._v(" "),_c('AutomationInput',{ref:"ActionObjectType",attrs:{"items":_vm.automatizationsActionsObjectsTypes,"value":_vm.form.ActionObjectType,"placeholder":'действие',"disabled":!_vm.create},on:{"choose":_vm.chooseActionType}}),_vm._v(" "),_c('AutomationInput',{ref:"ActionObjectTypeId",attrs:{"items":_vm.filteredItems(
          _vm.automatizationsActionsObjects,
          _vm.filteredAutomatizationsActionsObjects
        ),"value":_vm.form.ActionObjectTypeId,"placeholder":'объект действия',"disabled":!_vm.create},on:{"choose":_vm.chooseActionObjectTypeId},nativeOn:{"click":function($event){_vm.checkField('ActionObjectType')}}}),_vm._v(" "),(_vm.showConditionField('actionTemplate'))?_c('AutomationInput',{attrs:{"items":_vm.preparedAutomatizationsActionsOptions(
          _vm.filteredAutomatizationsActionsTemplates
        ),"value":_vm.form.ActionObjectOptionId,"capitalizeWord":true,"placeholder":'параметр действия',"disabled":!_vm.create},on:{"choose":function($event){_vm.setCondition(
          $event,
          'ActionObjectOptionId',
          'actionTemplate',
          _vm.filteredAutomatizationsActionsTemplates
        ),
          _vm.save()}},nativeOn:{"click":function($event){_vm.checkField('ActionObjectTypeId'), _vm.checkField('ActionObjectType')}}}):_vm._e(),_vm._v(" "),_c('AutomationBuilderParams',{attrs:{"template":_vm.actionTemplate,"params":_vm.params,"type":'action',"edit":_vm.edit,"attach":_vm.attach,"create":_vm.create,"filteredAutomatizationsObjectsTypes":_vm.filteredAutomatizationsObjectsTypes,"form":_vm.form},on:{"save":_vm.save}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }