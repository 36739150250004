import { showAlert } from "../../utils";
import Api from "../../api/commercial_site";
import * as c from "../../store/commercial_site/constants";
import * as _ from "lodash";

export const editorToolBar = [
  [{ font: [] }],

  [{ header: [false, 1, 2, 3, 4, 5, 6] }],

  [{ size: ["small", false, "large", "huge"] }],

  ["bold", "italic", "underline", "strike"],

  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" },
  ],

  [{ header: 1 }, { header: 2 }],

  ["blockquote", "code-block"],

  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

  [{ script: "sub" }, { script: "super" }],

  [{ indent: "-1" }, { indent: "+1" }],

  [{ color: [] }, { background: [] }],

  ["link", "image", "video", "formula"],

  [{ direction: "rtl" }],
  ["clean"],
];

export const getTranslation = (state, moduleTranslateName, id) => {
  return new Promise((resolve, reject) => {
    id = id || state.data.Id;
    if (!moduleTranslateName) {
      switch (state.module) {
        case "news": {
          moduleTranslateName = "SiteNews";
          break;
        }
        case "benefits": {
          moduleTranslateName = "SiteBenefits";
          break;
        }
        case "sliders": {
          moduleTranslateName = "SiteSliders";
          break;
        }
        case "blocks": {
          moduleTranslateName = "SiteBlocks";
          break;
        }
        case "cities": {
          moduleTranslateName = "SiteCities";
          break;
        }
        case "examples": {
          moduleTranslateName = "SiteExamplesWorks";
          break;
        }
        case "facts": {
          moduleTranslateName = "SiteFacts";
          break;
        }
        case "production": {
          moduleTranslateName = "SiteProduction";
          break;
        }
        case "productionCategories": {
          moduleTranslateName = "SiteProductionCategories";
          break;
        }
        case "productionCharacteristics": {
          moduleTranslateName = "SiteProductionCharacteristics";
          break;
        }
        case "productionDocuments": {
          moduleTranslateName = "SiteProductionDocuments";
          break;
        }
        case "productionFunctional": {
          moduleTranslateName = "SiteProductionFunctional";
          break;
        }
        case "services": {
          moduleTranslateName = "SiteServices";
          break;
        }
        case "solutions": {
          moduleTranslateName = "SiteSolutions";
          break;
        }
        case "solutionsBenefits": {
          moduleTranslateName = "SiteSolutionsBenefits";
          break;
        }
        case "solutionsOpportunities": {
          moduleTranslateName = "SiteSolutionsOpportunities";
          break;
        }
        case "solutionsSoft": {
          moduleTranslateName = "SiteSolutionsSoft";
          break;
        }
        case "solutionsTasks": {
          moduleTranslateName = "SiteSolutionsTasks";
          break;
        }
        case "staticPages": {
          moduleTranslateName = "SiteStaticPages";
          break;
        }
        case "mainpageFooterFacts": {
          moduleTranslateName = "SiteMainPageFooterFacts";
          break;
        }
        case "BlogPosts": {
          moduleTranslateName = "SiteBlogPosts";
          break;
        }
        case "BlogCategories": {
          moduleTranslateName = "SiteBlogCategories";
          break;
        }
        default:
          moduleTranslateName = "SiteBlocks";
      }
    }
    Api.getTranslation(moduleTranslateName, id)
      .then((res) => {
        _.each(res.data, (ar, index) => {
          _.each(ar, (v, key) => {
            if (key === "Uri") {
              res.data[index].Uri.TranslatedValue = res.data[index].Uri
                .TranslatedValue
                ? res.data[index].Uri.TranslatedValue
                : state.fields.Uri;
            }
          });
        });
        // console.log(res.data)
        let needCommonTranslation = true;
        if (moduleTranslateName === "SiteWorkTypes") {
          state[c.CHANGE_WORK_TYPES_ARRAY]({ id, data: res.data });
          needCommonTranslation = false;
        }
        if (moduleTranslateName === "SiteBlocks") {
          state[c.CHANGE_BLOCKS_ARRAY]({ id, data: res.data });
          needCommonTranslation =
            res.data["en"] !== void 0 && res.data["en"].SeoTitle !== void 0;
        }
        if (moduleTranslateName === "SiteCities") {
          state[c.CHANGE_CITIES_ARRAY]({ id, data: res.data });
          needCommonTranslation = false;
        }

        if (
          needCommonTranslation &&
          moduleTranslateName !== "SiteSolutionsTasks"
        ) {
          // console.log(moduleTranslateName)
          state.translation = res.data;
          // console.log(111)
        }
        resolve({ id, data: res.data });
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        reject(err);
      })
      .finally(() => {
        state.$emit("setPreloader", false);
      });
  });
};

export const translatedFieldsRefs = [
  "Name",
  "Title",
  "Uri",
  "ShortText",
  "FullNews",
  "SeoTitle",
  "SeoDescription",
  "SeoKeywords",
  "SeoText",
  "PageTitle",
  "Value",
  "Address",
  "CallToActionText",
  "Description",
  "CityName",
  "WorkType",
  "WelcomeText",
  "WhatMean",
  "FunctionalOptions",
  "PromoText",
  "BaseFunctional",
  "OptionFunctional",
  "CharacteristicsName",
  "CharacteristicsValue",
  "productionDocumentsName",
  "SlideTitle",
  "InternalName",
  "MainPageName",
  "TaskName",
  "TaskText",
  "SolutionsOpportunitiesName",
  "OpportunitiesText",
  "RentTabTitle",
  "OwnerTabTitle",
  "GuestTabTitle",
  "Value8",
  "BaseFunctionalName",
  "OptionFunctionalName",
];

export const OBJECT_TYPES = [
  { Name: "Торгово-развлекательный центр", Id: 1 },
  { Name: "Бизнес-центр", Id: 2 },
  { Name: "Жилой комплекс", Id: 3 },
  { Name: "Отель", Id: 4 },
  { Name: "Спортивный комплекс", Id: 5 },
  { Name: "Аэропорт / Железнодорожный вокзал ", Id: 6 },
  { Name: "Парк", Id: 7 },
  { Name: "Паркинг", Id: 8 },
];
