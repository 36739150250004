<template>
  <div
    class="popup task-popup task-popup-inner event-popup event-popup_active popup-processes"
  >
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Шаблоны процессов</h2>
          <div class="popup__close" @click="onClose">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <transition name="fade">
            <div class="table-spinner" v-if="parentData.preloader">
              <Spinner
                :noFadeIn="true"
                name="ball-spin-fade-loader"
                color="#c7c7c7"
                width="60"
                height="60"
              />
            </div>
          </transition>
          <div class="cm-popup">
            <div class="cm-popup__right">
              <div class="task-popup-header">
                <div class="actions-header__col" v-if="selectedItems.length">
                  <span class="button tm-btn" @click="attach">Прикрепить</span>
                  <span
                    class="button tm-btn tm-btn--add tm-btn--remove"
                    style="margin-left: 5px"
                    @click="deleteProcesses"
                    >-</span
                  >
                </div>
                <div class="actions-header__col" v-else>
                  <span class="button tm-btn tm-btn--add" @click="createProcess"
                    >+</span
                  >
                </div>
                <div class="actions-header__col actions-header__col_flexible">
                  <div
                    class="search-bar search-bar_white actions-header__search-bar"
                  >
                    <button class="search-bar__button">
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-search"
                        ></use>
                      </svg>
                    </button>
                    <input
                      type="search"
                      placeholder="Поиск"
                      v-model="search"
                      class="search-bar__input"
                    />
                  </div>
                </div>
              </div>
              <div class="tm-table">
                <div class="tm-table__header tm-table__row">
                  <div class="tm-table__col">
                    <label
                      class="checkbox checkbox_white table__checkbox js-index-checkbox"
                    >
                      <input
                        class="checkbox__input main_checkbox_input"
                        type="checkbox"
                        name="row_checkbox_clients"
                        :class="{
                          'checkbox__box--active':
                            selectedItems.length === filteredProcesses.length,
                        }"
                        @click.prevent="
                          () => {
                            if (
                              selectedItems.length ===
                              filteredProcesses.filter(
                                (i) => !templateIsInGroup(i.Id)
                              ).length
                            ) {
                              selectedItems = [];
                            } else {
                              selectedItems = filteredProcesses
                                .filter((i) => !templateIsInGroup(i.Id))
                                .map((i) => i.Id);
                            }
                          }
                        "
                      />
                      <span class="checkbox__box main_checkbox_span">
                        <svg class="checkbox__icon">
                          <use
                            xlink:href="/static/images/icon.svg#icon_icon-check"
                          ></use>
                        </svg>
                      </span>
                    </label>
                    Процессы
                  </div>
                  <div class="tm-table__col">Статусы</div>
                </div>
                <div class="tm-table__body">
                  <div
                    class="tm-table__row"
                    v-for="item in filteredProcesses"
                    :key="item.Id"
                    :class="{
                      'tm-table__row--disabled': templateIsInGroup(item.Id),
                    }"
                  >
                    <div class="tm-table__col">
                      <label
                        class="checkbox checkbox_white table__checkbox js-index-checkbox"
                      >
                        <input
                          class="checkbox__input main_checkbox_input"
                          type="checkbox"
                          name="row_checkbox_clients"
                          :class="{
                            'checkbox__box--active': selectedItems.includes(
                              item.Id
                            ),
                          }"
                          @click.prevent="
                            !templateIsInGroup(item.Id) && selectItem(item.Id)
                          "
                        />
                        <span class="checkbox__box main_checkbox_span">
                          <svg class="checkbox__icon">
                            <use
                              xlink:href="/static/images/icon.svg#icon_icon-check"
                            ></use>
                          </svg>
                        </span>
                      </label>
                      <input
                        type="text"
                        class="tm-table-input-name"
                        :value="item.Name"
                        @change="updateProcess($event, item)"
                      />
                    </div>
                    <div class="tm-table__col">
                      <div class="tm-table__labels-list">
                        <draggable
                          draggable=".process-list-item__labels-item-wrap"
                          class="process-list-item__labels-list"
                          v-model="item.statuses"
                          @end="changeStatusesOrder(item.statuses)"
                          :class="{
                            'process-list-item__labels-list--empty': !item
                              .statuses.length,
                          }"
                        >
                          <ProcessLabelElement
                            v-for="status in item.statuses"
                            :key="status.Id"
                            :item="status"
                            :parentData="parentData"
                            :process="item"
                          />
                          <ProcessLabelElement
                            :create="true"
                            :process="item"
                            :parentData="parentData"
                          />
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../mixins/tasks/common";
import { Helpers } from "../../../../mixins/Helpers";
import ProcessLabelElement from "./components/ProcessLabelElement";
import draggable from "vuedraggable";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  ATTACH_PROCESS_TO_GROUP,
  CHANGE_STATUSES_ORDER,
  CREATE_PROCESS,
  DELETE_PROCESS,
  UPDATE_PROCESS,
  GET_PROCESSES,
} from "../../../../store/tasks/constants";
import * as _ from "lodash";

export default {
  name: "ProcessesCreateControl",
  mixins: [common, Helpers],
  data() {
    return {
      parentData: {
        preloader: false,
      },
      selectedItems: [],
      search: "",
    };
  },
  computed: {
    filteredProcesses() {
      return this.processes.filter((i) => {
        const re = new RegExp(this.search, "gi");
        return re.test(i.Name);
      });
    },
    ...mapGetters("tasks", ["group"]),
    ...mapState("tasks", ["processes", "groupProcesses"]),
  },
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onClose();
      }
    },
    createProcess() {
      this.parentData.preloader = true;
      this[CREATE_PROCESS]({
        Name: "Новый процесс",
      }).finally(() => {
        this.parentData.preloader = false;
      });
    },
    updateProcess(e, item) {
      if (e.target.value !== item.Name) {
        this.parentData.preloader = true;
        this[UPDATE_PROCESS]({ ...item, Name: e.target.value })
          .then(() => {
            this[GET_PROCESSES](this.group.Id);
          })
          .finally(() => {
            this.parentData.preloader = false;
          });
      }
    },
    selectItem(id) {
      if (!this.selectedItems.includes(id)) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    deleteProcesses() {
      this.selectedItems.forEach((id) => {
        this.parentData.preloader = true;
        this[DELETE_PROCESS](id)
          .then(() => {
            this[GET_PROCESSES](this.group.Id);
          })
          .finally(() => (this.parentData.preloader = false));
      });
      this.selectedItems = [];
    },
    attach() {
      this.selectedItems.forEach((id) => {
        this.parentData.preloader = true;
        this[ATTACH_PROCESS_TO_GROUP]({
          GroupId: this.group.Id,
          ProcessId: id,
        })
          .then(() => {
            this[GET_PROCESSES](this.group.Id);
          })
          .finally(() => (this.parentData.preloader = false));
      });
      this.selectedItems = [];
    },
    changeStatusesOrder(items) {
      this.parentData.preloader = true;
      this[CHANGE_STATUSES_ORDER](items)
        .then(() => {
          this[GET_PROCESSES](this.group.Id);
        })
        .finally(() => (this.parentData.preloader = false));
    },
    templateIsInGroup(Id) {
      return _.find(this.groupProcesses, { Id });
    },
    ...mapActions("tasks", [
      CREATE_PROCESS,
      UPDATE_PROCESS,
      DELETE_PROCESS,
      ATTACH_PROCESS_TO_GROUP,
      CHANGE_STATUSES_ORDER,
      GET_PROCESSES,
    ]),
  },
  components: {
    ProcessLabelElement,
    draggable,
  },
};
</script>

<style scoped></style>
