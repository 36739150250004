<template>
  <div class="print print--5">
    <div class="print__cont pb-0">
      <div class="print__logo">
        <img :src="getimgStaticSrc('sales/print-logo.svg')" />
      </div>
      <div
        class="print__title"
        :style="{
          backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
        }"
      >
        Оборудование <br />и опции
      </div>
      <div class="print__text print__text--m-large">
        <p>{{ offerObject.json.OptionsText }}</p>
      </div>
    </div>
    <div class="print__list-l">
      <div class="print__list-l-row">
        <div class="col-auto">
          <div
            class="print__list-l-name"
            v-if="offerObject.json.SystemType === 'Tokensdbl'"
          >
            Модули въезда\выезда
          </div>
          <div class="print__list-l-name" v-else>Модули въезда</div>
        </div>
        <div class="col-auto ml-auto">
          <div class="print__list-l-num">{{ getModuleCount("Entry") }}</div>
        </div>
      </div>
      <div
        class="print__list-l-row"
        v-if="offerObject.json.SystemType !== 'Tokensdbl'"
      >
        <div class="col-auto">
          <div class="print__list-l-name">Модули выезда</div>
        </div>
        <div class="col-auto ml-auto">
          <div class="print__list-l-num">{{ getModuleCount("Exit") }}</div>
        </div>
      </div>
      <div class="print__list-l-row">
        <div class="col-auto">
          <div class="print__list-l-name">устройства оплаты</div>
        </div>
        <div class="col-auto ml-auto">
          <div class="print__list-l-num">{{ getModuleCount("Payment") }}</div>
        </div>
      </div>
      <!--      <div class="print__list-l-row">-->
      <!--        <div class="col-auto">-->
      <!--          <div class="print__list-l-name">дополнительные устройства</div>-->
      <!--        </div>-->
      <!--        <div class="col-auto ml-auto">-->
      <!--          <div class="print__list-l-num">{{getModuleCount('Additional')}}</div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="print__footer">{{ pageNums.Schemes + 1 }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  methods: {
    getModuleCount(module) {
      if (
        !this.offerObject.openedPages[module] &&
        !this.windowStates.is_open_view
      )
        return 0;

      if (module === "Entry" || module === "Exit") {
        let sum = 0;
        _.each(this.offerObject.json.Modules[module]["rack"], (i) => {
          const value = i.countAsOneKit ? (i.count ? 1 : 0) : i.count;
          if (i.is_device) sum += value;
        });
        return sum;
      } else if (module === "Payment") {
        let cash_4Count = 0;

        if (this.offerObject.json.Modules[module]["cash_4"].length) {
          cash_4Count = this.offerObject.json.Modules[module]["cash_4"][0]
            .count;
        }
        return (
          this.offerObject.json.Modules[module]["cash_1"][0].count +
          (this.offerObject.json.System !== "economy"
            ? this.offerObject.json.Modules[module]["cash_2"][0].count
            : 0) +
          this.offerObject.json.Modules[module]["cash_3"][0].count +
          cash_4Count
        );
      } else {
        let count = 0;
        _.each(this.offerObject.json.Modules[module]["options"], (i) => {
          const value = i.countAsOneKit ? (i.count ? 1 : 0) : i.count;
          count += value;
        });
        return count;
      }
    },
  },
  computed: {
    ...mapState("sales", ["offerObject", "pageNums", "windowStates"]),
  },
};
</script>
