<template>
  <div
    class="section-tasks-draggable-zone"
    :class="{
      'section-tasks-draggable-zone--open': open,
    }"
    v-infinite-scroll="loadMore"
    infinite-scroll-distance="0"
    infinite-scroll-immediate-check="false"
    infinite-scroll-throttle-delay="1000"
  >
    <div class="table-section-row table__row--col">
      <div class="table__row table__row_main">
        <div class="table-section-row__arrow table__col table__col_width_35">
          <svg
            width="16"
            height="15"
            :style="{
              fill: group.GroupInfo.Color ? group.GroupInfo.Color : '#FFB72B',
            }"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#folder-icon"
            ></use>
          </svg>
        </div>
        <div class="table__col table__col_flexible table-section-row__name">
          <div class="table-section-row__name">
            <p>{{ group.GroupInfo.GroupName }}</p>
            <!--            <div class="task-deep-info"><i class="deep-icon"></i>???</div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="section-tasks-draggable-zone__list">
      <TableRow
        v-for="(item, index) in group.Tasks"
        :key="item.Id"
        :item="item"
        :index="index"
        :level="0"
        :data-id="item.Id"
        :parentData="{}"
        @selectItem="() => {}"
        @createSubTask="() => {}"
        @shiftSelection="() => {}"
        @ctrlSelection="() => {}"
      />
    </div>
  </div>
</template>

<script>
import TableRow from "@/modules/tasks/components/Tables/MainTable/TableRow";
import { mapActions, mapState } from "vuex";
import {
  LOAD_MORE_CUSTOM_TASKS_IN_GROUP,
  LOAD_MORE_STANDART_TASKS_IN_GROUP,
} from "@/store/tasks/constants";

export default {
  name: "GroupedTasksByGroupItem",
  props: ["group"],
  data() {
    return {
      open: true,
      busy: false,
    };
  },
  computed: {
    filled() {
      if (this.group.filled) return true;
      else {
        return this.busy;
      }
    },
    ...mapState("tasks", ["settings"]),
  },
  methods: {
    loadMore() {
      if (!this.group.filled && !this.busy) {
        this.busy = true;
        // this.settings.filterGroupTaskByGroupsLoading = true;
        if (this.selectedFilterIsUsersFilter > 5) {
          this[LOAD_MORE_CUSTOM_TASKS_IN_GROUP]({
            FilterId: this.selectedFilter,
            Skip: this.group.Tasks.length,
            GroupId: this.group.GroupInfo.Id,
          }).then(() => (this.busy = false));
        } else {
          this[LOAD_MORE_STANDART_TASKS_IN_GROUP]({
            FilterType: this.selectedFilter,
            Skip: this.group.Tasks.length,
            GroupId: this.group.GroupInfo.Id,
          }).then(() => (this.busy = false));
        }
      }
    },
    ...mapActions("tasks", [
      LOAD_MORE_STANDART_TASKS_IN_GROUP,
      LOAD_MORE_CUSTOM_TASKS_IN_GROUP,
    ]),
  },
  components: {
    TableRow,
  },
};
</script>

<style scoped></style>
