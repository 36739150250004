<template>
  <v-card>
    <v-card-title>
      <span class="headline">Указать местоположение на карте</span>
    </v-card-title>
    <div class="cm-gmap">
      <div class="field">
        <gmap-autocomplete @place_changed="setPlace" class="input">
        </gmap-autocomplete>
        <button
          class="button button_green button_uppercase button_align_center field__btn"
          @click="usePlace"
          style="margin-left: 10px"
        >
          Найти
        </button>
      </div>
      <GmapMap
        :center="center"
        :zoom="7"
        style="width: 100%; height: 300px"
        @click="centerMark"
        ref="gmap"
      >
        <GmapMarker
          ref="myMarker"
          :position="position"
          @click="center = position"
          :clickable="true"
          :draggable="true"
          @drag="drag"
        />
      </GmapMap>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" flat="flat" @click="close()">Готово</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as _ from "lodash";

export default {
  props: ["setPosition"],
  data() {
    return {
      center: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      position: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      place: null,
      city: "",
      address: "",
    };
  },
  created() {
    if (this.setPosition) {
      this.position = {
        ...this.setPosition,
      };
      this.center = {
        ...this.setPosition,
      };
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("setAddressByMap", this.address);
      this.$emit("setGeoByMap", this.position);
      this.center = {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      };
      this.position = {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      };
      this.place = null;
      this.address = "";
      this.city = "";
    },
    // <--google maps func |____
    // ||-------------------------------------------------
    drag(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.setAddressByGeo();
    },
    centerMark(location) {
      this.center = this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.setAddressByGeo();
    },
    setAddressByGeo() {
      this.$geocoder.send(this.position, (response) => {
        try {
          this.city = _.find(response.results, (i) => {
            return (
              i.types.includes("administrative_area_level_2") &&
              i.types.includes("political")
            );
          }).address_components[0].long_name;
          this.address = _.find(response.results, (i) => {
            return i.types.includes("street_address");
          }).formatted_address;
        } catch (e) {
          e;
        }
      });
    },
    changeTextLat(val) {
      this.position.lat = +val;
      this.center.lat = +val;
    },
    changeTextLng(val) {
      this.position.lng = +val;
      this.center.lng = +val;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.center = this.position = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.place = null;
        this.setAddressByGeo();
      }
    },

    // ||-----------------------------------------------/>
  },
};
</script>

<style scoped></style>
