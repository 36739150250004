import * as c from "../constants";
import Api from "@/api/support/client";
import { togglePreloader, showAlert } from "@/utils";
import { TOGGLE_POPUP } from "../../common/constants";
export default {
  [c.SET_COUNT](state, data) {
    state.commit(c.SET_COUNT, data);
  },
  [c.GET_COUNT](state) {
    const timerId = setInterval(() => {
      if (state.getters.countInterval) {
        Api.getClientCount()
          .then((res) => {
            state.dispatch(c.SET_COUNT, res.data);
            state.dispatch(c.GET_REQUESTS, {
              scope: state.getters.activeTab,
              start: false,
            });
          })
          .catch((err) => {
            console.log("Ошибка получения данных с сервера", err);
          });
      } else {
        clearInterval(timerId);
      }
    }, 120000);
  },
  [c.TOGGLE_COUNT_INTERVAL](state, data) {
    state.commit(c.TOGGLE_COUNT_INTERVAL, data);
  },
  [c.SET_ACTIVE_TAB](state, data) {
    state.commit(c.SET_ACTIVE_TAB, data);
  },
  [c.GET_REQUESTS](state, { scope, start }) {
    if (
      state.getters.requests[scope].length !== +state.getters.count[scope] ||
      start
    ) {
      togglePreloader(state, true);
    }
    Api.getRequests(scope)
      .then((res) => {
        state.commit(c.GET_REQUESTS, { scope, requests: res.data });
        togglePreloader(state, false);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось получить данные заявок с сервера"
        );
        console.log(err);
        togglePreloader(state, false);
      });
  },
  [c.SET_SEARCH_INPUT](state, data) {
    state.commit(c.SET_SEARCH_INPUT, data);
  },
  [c.GET_SUPPORT_DATA](state) {
    Api.getSupportData()
      .then((res) => {
        state.commit(c.GET_SUPPORT_DATA, res.data);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка",
          "Не удалось получить данные настроек с сервера"
        );
        console.log(err);
      });
  },
  [c.CREATE_NEW_CLIENT_REQUEST](state, data) {
    togglePreloader(state, true);
    Api.createNewClientRequest(state, data)
      .then((res) => {
        showAlert("success", "Успешно", "Заявка успешно создана");
        state.commit(c.SET_REQUESTS_WITH_CREATED, {
          scope: state.getters.activeTab,
          newRequest: res.data,
        });
        togglePreloader(state, false);
        state.dispatch(c.TOGGLE_REQUEST_STATUS);
        state.commit(c.SET_PROGRESS_BAR, null);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось создать новую заявку");
        console.log(err);
        togglePreloader(state, false);
        state.commit(c.SET_PROGRESS_BAR, null);
      });
  },
  [c.TOGGLE_REQUEST_STATUS](state) {
    state.commit(c.TOGGLE_REQUEST_STATUS);
  },
  [c.SET_SELECTED_REQUEST](state, { request, requestId }) {
    if (requestId) {
      togglePreloader(state, true);
      Api.getRequestMessages(requestId)
        .then((res) => {
          state.dispatch(
            TOGGLE_POPUP,
            { module: "client", popup: "requestWindow", value: true },
            { root: true }
          );
          state.commit(c.SET_SELECTED_REQUEST, request);
          state.dispatch(c.SET_REQUEST_MESSAGES, res.data.messages);
          state.commit(c.REMOVE_UNREAD_MARK, {
            scope: state.getters.activeTab,
            id: requestId,
          });
          state.dispatch(c.GET_REQUEST_MESSAGES);
          togglePreloader(state, false);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось получить данные заявки");
          console.log(err);
          togglePreloader(state, false);
        });
    } else {
      state.commit(c.SET_SELECTED_REQUEST, false);
    }
  },
  [c.SET_REQUEST_MESSAGES](state, data) {
    state.commit(c.SET_REQUEST_MESSAGES, data);
  },
  [c.UPDATE_REQUEST_MESSAGES](state, data) {
    state.commit(c.UPDATE_REQUEST_MESSAGES, data);
  },
  [c.CLOSE_REQUEST](state, id) {
    togglePreloader(state, true);
    Api.closeRequest(id)
      .then((res) => {
        showAlert("success", "Успешно", "Заявка закрыта");
        state.commit(c.SET_SELECTED_REQUEST, res.data);
        state.dispatch(c.GET_REQUESTS, {
          scope: state.getters.activeTab,
          start: true,
        });
        togglePreloader(state, false);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось закрыть заявку");
        console.log(err);
        togglePreloader(state, false);
      });
  },
  [c.OPEN_REQUEST](state, id) {
    togglePreloader(state, true);
    Api.openRequest(id)
      .then((res) => {
        showAlert("success", "Успешно", "Заявка открыта");
        state.commit(c.SET_SELECTED_REQUEST, res.data);
        state.dispatch(c.GET_REQUESTS, {
          scope: state.getters.activeTab,
          start: true,
        });
        togglePreloader(state, false);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", "Не удалось открыть заявку");
        console.log(err);
        togglePreloader(state, false);
      });
  },
  [c.GET_REQUEST_MESSAGES](state) {
    const oldRequest = state.getters.selectedRequest.Id;
    const timerId = setInterval(() => {
      if (state.getters.selectedRequest) {
        // console.log(state.getters.parentData.sendingMessage);
        if (!state.getters.parentData.sendingMessage) {
          Api.getRequestMessages(state.getters.selectedRequest.Id)
            .then((res) => {
              if (oldRequest === state.getters.selectedRequest.Id) {
                if (
                  !state.getters.parentData.sendingMessage &&
                  state.getters.messages.length !== res.data.messages.length
                ) {
                  state.commit(c.SET_REQUEST_MESSAGES, res.data.messages);
                  state.commit(c.SET_SELECTED_REQUEST, res.data);
                }
                state.commit(c.SET_REQUEST_STATUS, res.data);
              }
            })
            .catch((err) => {
              console.log("Ошибка принятия сообщений с сервера", err);
            });
        }
      } else {
        clearInterval(timerId);
      }
    }, 15000);
  },
  [c.SEND_MESSAGE](state, { requestId, message }) {
    return new Promise((resolve, reject) => {
      Api.sendMessage(state, { requestId, message })
        .then((res) => {
          state.commit(c.UPDATE_REQUEST_MESSAGES, res.data);
          state.commit(c.SET_PROGRESS_BAR, null);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", "Не удалось отправить сообщение");
          state.commit(c.SET_PROGRESS_BAR, null);
          console.log(err);
          reject(err);
        });
    });
  },
  [c.CHANGE_REQUEST_FILTERS](state, data) {
    state.commit(c.CHANGE_REQUEST_FILTERS, data);
  },
  [c.GET_USER_ROLES](state, userId) {
    Api.getUserRoles(userId)
      .then((res) => {
        state.commit(c.SET_USER_INFO, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [c.GET_PIN](state) {
    Api.getPin().then((res) => {
      state.commit(c.GET_PIN, res.data.pin);
    });
  },
};
