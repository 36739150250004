<template>
  <div class="table table--subtasks">
    <div class="table__header">
      <div class="table__col table__col_flexible">
        <p class="table__header-text">Задачи</p>
      </div>
      <div class="table__col table__col_width_160">
        <p class="table__header-text">Тэги</p>
      </div>
      <div class="table__col table__col_width_160">
        <p class="table__header-text">Ответственный</p>
      </div>
      <div class="table__col table__col_width_160">
        <p class="table__header-text">Сроки</p>
      </div>
    </div>
    <ul class="group-list">
      <li
        class="table__row table__row_main"
        v-for="(task, index) in subtasks"
        :key="task.Id"
      >
        <div class="table__col_label">
          <label
            class="checkbox checkbox_white table__checkbox js-index-checkbox task-row-count-checkbox"
          >
            <div class="task-row-count">{{ index + 1 }}</div>
          </label>
        </div>
        <div class="table__col table__col_name table__col_flexible">
          <div class="task-row-first">
            <div class="task-row-link" @click="openDetailTask($event, task)">
              <label class="table-c__name-label">
                <p class="table-c__name _fit-col">
                  {{ task.TaskName }}
                </p>
              </label>
            </div>
          </div>
        </div>
        <div class="table__col table__col_width_160 table__col--tags-wrap">
          <div class="task-labels">
            <span
              class="task-label"
              v-for="item in task.tags"
              :style="{ backgroundColor: item.Color }"
              :key="item.Id"
              @click="showTagsListBubble = true"
              >{{ item.TagName }}
            </span>
          </div>
        </div>
        <div class="table__col table__col_width_160">
          <template v-if="getPersonNameById(task.ResponsibleId)">
            <span class="_fit-col">
              {{ getPersonNameById(task.ResponsibleId) | shortName }}
            </span>
          </template>
          <template v-else>
            <span class="table-c__name _fit-col"
              >Не назначен</span
            >
          </template>
        </div>
        <div class="table__col table__col_width_160">
          <div class="table-row-date">
            <div class="task-table-date">
              <template v-if="task.FinishDate">
                {{ getFinishDate(task.FinishDate) }}
              </template>
              <template v-else>Без даты</template>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapActions } from "vuex";
import { GET_USER_TASKS_MODULE_RIGHTS } from "../../../../../store/tasks/constants";
import {
  SET_COMMON_LOCAL_STORAGE_SETTINGS,
  GET_TASKS,
} from "../../../../../store/tasks/constants";
import common from "../../../../../mixins/tasks/common";

export default {
  props: ["subtasks"],
  mixins: [common],
  created() {
    this[GET_USER_TASKS_MODULE_RIGHTS](this.$router);
  },
  methods: {
    ...mapMutations("tasks", [SET_COMMON_LOCAL_STORAGE_SETTINGS]),
    ...mapActions("tasks", [GET_TASKS, GET_USER_TASKS_MODULE_RIGHTS]),
    getFinishDate(data) {
      const date = moment(data);
      return date.locale("ru").format("DD.MM.YYYY");
    },
    openDetailTask(e, item) {
      this.changeCurrentTaskGroup(item.GroupId);

      if (!e.target.closest(".checkbox")) {
        const globalTask = item.GlobalTask === void 0 ? true : item.GlobalTask;
        let id = globalTask ? item.Id : item.TaskId;

        let routeData = this.$router.resolve("/tasks/" + id);
        window.open(routeData.href, "_blank");
      }
    },
    changeCurrentTaskGroup(groupId) {
      const data = {
        queryType: "group",
        queryId: groupId,
      };

      this[SET_COMMON_LOCAL_STORAGE_SETTINGS](data);
      this[GET_TASKS]();
    },
  },
};
</script>

<style scoped></style>
