import * as _ from "lodash";
import { CREATE_TASK_NO_MUTATION } from "../../../../store/tasks/constants";

export const emptyContact = {
  ObjectContactId: "",
  ManualContactFIO: "",
  ManualContactPhone: "",
  ManualContactPosition: "",
  ManualContactEmail: "",
  MainContact: "0",
};

export const taskCreateFormTemplate = {
  TemplateId: "",
  TemplateTaskName: "Новая задача",
  TemplateParentTaskId: null,
  TemplateTaskSettings: {
    TaskDescription: "",
    ResponsibleId: "",
    FinishDate: "",
    FinishTime: "",
    ObjectId: null,
    TaskAddress: "",
    TaskLatitude: null,
    TaskLongitude: null,
    Settings: {
      EnableComments: 1,
      FinishDateOnlyWithComment: 0,
      ResponsibleAutoAccept: 0,
      TaskAccess: 1,
    },
    Priority: 1,
    ProcessId: "",
    GroupId: "",
    ParentId: "",
    Checklists: [],
    Watchers: [],
    Subscribers: [],
    TaskContacts: [],
    Files: [],
    TagsIds: [],
    HiddenFields: {
      Subscribers: false,
      Watchers: false,
      Process: false,
      Tags: false,
      Files: false,
      Contacts: false,
      Address: false,
      DocFiles: false,
      Automatizations: false,
    },
  },
  subtasks: [],
};

export const hiddenFieldsNameArray = [
  "AddressInput",
  "ContactsInput",
  "WatchersInput",
  "SubscribersInput",
  "ProcessInput",
  "TagsInput",
  // "SubtasksInput",
];

const templateStringJSONFields = [
  "TaskName",
  "TaskDescription",
  "ResponsibleId",
  "FinishDate",
  "ObjectId",
  "TaskAddress",
  "ProcessId",
  "GroupId",
  "ParentId",
];
const templateArrayJSONFields = [
  "Files",
  "watchers",
  "subscribers",
  "contacts",
  "subtasks",
  "tags",
  "HiddenFields",
];

export function addFieldsToTemplateData(template) {
  _.each(templateStringJSONFields, (field) => {
    if (template[field] === void 0) {
      template[field] = "";
    }
  });
  _.each(templateArrayJSONFields, (field) => {
    if (template[field] === void 0) {
      template[field] = [];
    }
  });
}

async function delay(item, params, self) {
  await self[CREATE_TASK_NO_MUTATION]({
    ...item,
    ...params,
  }).then((createdItem) => {
    if (item.childs.length) {
      return saveItems(
        item.childs,
        { GroupId: createdItem.GroupId, ParentId: createdItem.Id },
        self
      );
    }
  });
}

async function delayedLog(item, params, self) {
  await delay(item, params, self);
}

async function saveItems(items, params, self) {
  for (const item of items) {
    await delayedLog(item, params, self);
  }
}

export async function createSubtasksQueue(items, params, self) {
  await saveItems(items, params, self);
}
