<template>
  <div class="automation-builder-params">
    <!--  инпут для статусов процесса   -->
    <AutomationProcessInput
      v-if="checkFieldForShow('FROM') && isProcessStatusInput"
      :preplaceholder="'на'"
      :placeholder="getInputPlaceholder('FROM')"
      :disabled="isDisabled('FROM')"
      :value="params[type].FROM"
      @choose="
        (id) => {
          params[type].FROM = id;
          $emit('save');
        }
      "
    />

    <AutomationInput
      :items="[
        { Name: 'Закрыто', id: 2, Id: 2 },
        { Name: 'Открыто', id: 1, Id: 1 },
      ]"
      v-else-if="checkFieldForShow('FROM')"
      :placeholder="getInputPlaceholder('FROM')"
      :preplaceholder="'с'"
      :value="params[type].FROM"
      :disabled="isDisabled('FROM')"
      :capitalizeWord="true"
      @choose="
        (id) => {
          params[type].FROM = id;
          $emit('save');
        }
      "
    />

    <AutomationInput
      :items="[
        { Name: 'Закрыто', id: 2, Id: 2 },
        { Name: 'Открыто', id: 1, Id: 1 },
      ]"
      v-if="checkFieldForShow('TO')"
      :placeholder="getInputPlaceholder('TO')"
      :preplaceholder="'на'"
      :value="params[type].TO"
      :disabled="isDisabled('TO')"
      :capitalizeWord="true"
      @choose="
        (id) => {
          params[type].TO = id;
          $emit('save');
        }
      "
    />
    <!--    <template v-if="checkFieldForShow('USER')">{{ params }}</template>-->
    <AutomationPersonInput
      v-if="checkFieldForShow('USER')"
      :preplaceholder="'на'"
      :placeholder="getInputPlaceholder('USER')"
      :disabled="isDisabled('USER')"
      :value="params[type].USER"
      @choose="
        (id) => {
          params[type].USER = id;
          $emit('save');
        }
      "
    />
    <!--                      <AutomationStatusInput-->
    <!--                        v-if="checkFieldForShow('conditionTemplate','FROM')"-->
    <!--                        :placeholder="'выбрать'"-->
    <!--                        :preplaceholder="'с'"-->
    <!--                        :value="form.FROM"-->
    <!--                        @choose="id => form.FROM = id"-->
    <!--                      />-->

    <!--                      <AutomationStatusInput-->
    <!--                        v-if="checkFieldForShow('conditionTemplate','TO')"-->
    <!--                        :placeholder="'выбрать'"-->
    <!--                        :preplaceholder="'на'"-->
    <!--                        :value="form.TO"-->
    <!--                        @choose="id => form.TO = id"-->
    <!--                      />-->
    <div class="automation-select-text" v-if="checkFieldForShow('COUNT_DAYS')">
      <div class="automation-select-text--no-selectable">
        на {{ getInputPlaceholder("COUNT_DAYS") }}
      </div>
      <input
        class="automation-select-text__input input"
        type="number"
        v-model="params[type].COUNT_DAYS"
        v-if="!isDisabled('COUNT_DAYS')"
        @blur="$emit('save')"
      />
      <p class="automation-select-text--no-selectable" v-else>
        {{ params[type].COUNT_DAYS }}
      </p>
      <div class="automation-select-text--no-selectable">дней</div>
    </div>
    <div
      class="automation-select-text"
      v-if="checkFieldForShow('COUNT_MONTHS')"
    >
      <div class="automation-select-text--no-selectable">
        на {{ getInputPlaceholder("COUNT_MONTHS") }}
      </div>
      <input
        class="automation-select-text__input input"
        type="number"
        v-model="params[type].COUNT_MONTHS"
        v-if="!isDisabled('COUNT_MONTHS')"
        @blur="$emit('save')"
      />
      <p class="automation-select-text--no-selectable" v-else>
        {{ params[type].COUNT_MONTHS }}
      </p>
      <div class="automation-select-text--no-selectable">месяцев</div>
    </div>
    <div class="automation-select-text" v-if="checkFieldForShow('COUNT_YEARS')">
      <div class="automation-select-text--no-selectable">
        на {{ getInputPlaceholder("COUNT_YEARS") }}
      </div>
      <input
        class="automation-select-text__input input"
        type="number"
        v-model="params[type].COUNT_YEARS"
        v-if="!isDisabled('COUNT_YEARS')"
        @blur="$emit('save')"
      />
      <p class="automation-select-text--no-selectable" v-else>
        {{ params[type].COUNT_YEARS }}
      </p>
      <div class="automation-select-text--no-selectable">лет</div>
    </div>

    <div class="automation-select-text" v-if="checkFieldForShow('DATE')">
      <div class="automation-select-text--no-selectable">на</div>
      <date-input
        :date="params[type].DATE"
        :format="'DD MMMM YYYY в HH:mm'"
        :placeholder="'Указать дату'"
        @setDate="(date) => (params[type].DATE = date)"
        :disabled="!edit"
        @blur="$emit('save')"
      />
    </div>
  </div>
</template>

<script>
import AutomationInput from "./components/AutomationInput";
import AutomationPersonInput from "./components/AutomationPersonInput";
import * as _ from "lodash";
import AutomationProcessInput from "@/modules/tasks/components/Automation/components/AutomationProcessInput";

export default {
  name: "AutomationBuilderParams",
  props: [
    "template",
    "params",
    "type",
    "create",
    "edit",
    "form",
    "filteredAutomatizationsObjectsTypes",
    "attach",
  ],
  computed: {
    isProcessStatusInput() {
      const objectTypeId = this.form?.ObjectTypeId;
      if (objectTypeId) {
        let processObjectId = null;
        _.each(this.filteredAutomatizationsObjectsTypes, (objectName, id) => {
          if (objectName.toLowerCase().trim() === "статус процесса") {
            processObjectId = id;
          }
        });
        return objectTypeId === processObjectId;
      }
      return false;
    },
  },
  methods: {
    checkFieldForShow(field) {
      return (
        this.template && this.template.Params && this.template.Params[field]
      );
    },
    checkNotFixedParam(field) {
      return (
        this.template &&
        this.template.Params &&
        ["@@PARAM@@", "@@VARIABLE@@"].includes(this.template.Params[field])
      );
    },
    checkFixedParam(field) {
      return (
        this.template &&
        this.template.Params &&
        this.template.Params[field] === "@@FIXED@@"
      );
    },
    isDisabled(field) {
      if (this.create) {
        if (this.checkNotFixedParam(field)) {
          return true;
        }
      } else {
        if (this.attach) {
          return this.checkFixedParam(field);
        } else {
          return !this.edit || this.checkNotFixedParam(field);
        }
      }
      // this.create && (!this.edit || this.checkFixedParam(field))
    },
    getInputPlaceholder(field) {
      if (this.isDisabled(field) && this.checkNotFixedParam(field))
        return "(парам)";
    },
  },
  components: {
    AutomationInput,
    AutomationPersonInput,
    AutomationProcessInput,
  },
};
</script>

<style scoped></style>
