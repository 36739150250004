<template>
  <div class="notification-settings-table__row-parent">
    <div class="notification-settings-table__row">
      <div
        class="notification-settings-table__arrow table-section-row__arrow"
        :class="{ 'notification-settings-table__arrow--open': open }"
        @click="open = !open"
      >
        <svg width="12" height="7">
          <use
            xlink:href="/static/images/tasks/sprite.svg#section-arrow-2"
          ></use>
        </svg>
      </div>
      <div class="notification-settings-table__title" @click="open = !open">
        <div
          class="notification-settings-table__title-icon"
          :style="{
            backgroundImage: `url(/img/tasks_icons/${item.TemplateInfo.SystemName}.svg)`,
          }"
        />
        <div class="notification-settings-table__title-text">
          {{ item.TemplateInfo.TemplateName }}
        </div>
      </div>
      <NotificationSettingsTableTemolateState
        class="notification-settings-table__control-col"
        @click.native="setBulk('NotificationType_1')"
        :items="item.Groups"
        field="NotificationType_1"
      />
      <NotificationSettingsTableTemolateState
        class="notification-settings-table__control-col"
        @click.native="setBulk('NotificationType_2')"
        :items="item.Groups"
        field="NotificationType_2"
      />
      <NotificationSettingsTableTemolateState
        class="notification-settings-table__control-col"
        @click.native="setBulk('NotificationType_3')"
        :items="item.Groups"
        field="NotificationType_3"
      />
      <NotificationSettingsTableTemolateState
        class="notification-settings-table__control-col"
        @click.native="setBulk('NotificationType_4')"
        :items="item.Groups"
        field="NotificationType_4"
      />
    </div>
    <div class="notification-settings-table__child-list" v-if="open">
      <NotificationSettingsTableRowChild
        v-for="(group, index) in item.Groups"
        :key="index"
        :item="group"
      />
    </div>
  </div>
</template>

<script>
import NotificationSettingsTableRowChild from "@/modules/tasks/components/ProfileSettingsPopup/NotificationSettingsTable/NotificationSettingsTableRowChild";
import NotificationSettingsTableTemolateState from "@/modules/tasks/components/ProfileSettingsPopup/NotificationSettingsTable/NotificationSettingsTableTemolateState";
import axios from "axios";
import { API_ROOT } from "@/config";

export default {
  name: "NotificationSettingsTableRowParent",
  props: ["item"],
  data() {
    return {
      open: false,
    };
  },
  methods: {
    setBulk(field) {
      let value = 0;
      const selectedItems = this.item.Groups.filter((i) =>
        Number(i.GroupNotificationSetting[field])
      );
      if (selectedItems.length !== this.item.Groups.length) {
        value = 1;
      }
      this.item.Groups = this.item.Groups.map((i) => {
        i.GroupNotificationSetting[field] = value;
        return i;
      });
      axios.post(
        `${API_ROOT}api/tasks/v2/TasksNotificationsGroupsSettings/updateStatusForTemplate`,
        {
          TemplateId: this.item.TemplateInfo.Id,
          NotificationType: field,
          Status: value,
        }
      );
    },
  },
  components: {
    NotificationSettingsTableRowChild,
    NotificationSettingsTableTemolateState,
  },
};
</script>

<style scoped></style>
