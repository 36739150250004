<template>
  <div class="request-window__sidebar-wrapper">
    <div class="cm-popup__left request-window__sidebar">
      <div
        class="cm-popup-nav__item"
        :class="activeTab === 'Request' ? 'cm-popup-nav__item--active' : ''"
        @click="$emit('changeTab', 'Request')"
      >
        Заявка #{{ selectedRequest.Id }}
      </div>
      <div
        class="cm-popup-nav__item"
        @click="$emit('changeTab', 'Tasks')"
        :class="activeTab === 'Tasks' ? 'cm-popup-nav__item--active' : ''"
      >
        Задачи в СУР
        <span class="num">{{ request.tasks ? request.tasks.length : "" }}</span>
      </div>
      <div
        class="cm-popup-nav__item"
        :class="activeTab === 'Files' ? 'cm-popup-nav__item--active' : ''"
        @click="$emit('changeTab', 'Files')"
      >
        Все файлы <span class="num">{{ files }}</span>
      </div>
      <div class="task-popup__nav-item task-popup__nav-item--datepicker">
        <div class="task-popup__nav-name">Следующий ответ:</div>
        <div
          class="task-popup__nav-info sup-request__deadline"
          :class="
            selectedRequest.AnswerDeadline &&
            selectedRequest.DeadLineMinutes <= 0
              ? 'red-color'
              : ''
          "
        >
          <i class="task-popup-icon__1"></i>
          <template v-if="selectedRequest.AnswerDeadline">
            {{ getDeadline }}
            <img
              v-if="selectedRequest.DeadLineMinutes <= 0"
              src="@/assets/images/fire.png"
              class="fire-icon"
              alt="fire icon"
            />
            <div class="sup-time" v-if="!isDistsObjectList">
              <span class="sup-time__item" @click="addTime(3600)">+1 час</span>
              <span class="sup-time__item" @click="addTime(86400)"
                >+1 день</span
              >
            </div>
          </template>
          <template v-else>Дата не назначена</template>
          <date-picker
            valueType="format"
            v-model="dateTime"
            :popup-class="'sup-datepicker'"
            confirm
            type="datetime"
            format="YYYY-MM-DD HH:mm"
            @confirm="addDate"
            lang="ru"
            :not-before="new Date()"
          ></date-picker>
        </div>
      </div>
      <div class="task-popup__nav-item task-popup__nav-item--datepicker">
        <div class="task-popup__nav-name">Ответственный:</div>
        <div class="task-popup__nav-info">
          <div class="tm-sidebar-user">
            <div class="tm-sidebar-user__avatar">
              <img v-if="getManagerAvatar" :src="getManagerAvatar" />
              <div v-else class="sup-message__avatar">
                {{ managerComputed | initials }}
              </div>
            </div>
            <div class="tm-sidebar-user__name">
              {{ managerComputed }}
            </div>
            <select
              v-if="checkUserRight(RESPONSE_CHANGE_RIGHT)"
              v-model="managerSelected"
              class="select-hidden"
              @change="changeManager(managerSelected)"
            >
              <option
                :value="item.Id"
                v-for="(item, index) in personsForManage"
                :key="index"
              >
                {{ item.FullName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="task-popup__nav-item task-popup__nav-item--datepicker">
        <div class="task-popup__nav-name">Статус:</div>
        <div class="sup-request__status" :style="`background: ${statusColor}`">
          {{ statusComputed }}
          <select
            v-model="statusSelected"
            class="select-hidden"
            @change="changeRequestStatus(statusSelected)"
          >
            <option :value="2">В работе</option>
            <option :value="1">Ожидает ответа оператора</option>
            <option :value="4">Ожидает ответа клиента</option>
          </select>
        </div>
      </div>
      <div class="task-popup__nav-item task-popup__nav-item--datepicker">
        <div class="task-popup__nav-name">Метки:</div>
        <div class="request-window__tags">
          <RequestTags
            :request="selectedRequest"
            @openInnerPopup="$emit('openInnerPopup')"
            @closeInnerPopup="$emit('closeInnerPopup')"
          />
        </div>
      </div>
      <div
        v-if="selectedRequest.StatusId !== '5'"
        class="task-popup__bottom-btn t-gray-btn"
        @click="changeRequestStatus(5)"
      >
        Закрыть заявку
      </div>
    </div>
    <div
      class="sup-chat-info sup-chat-info_comment"
      v-if="dateSettingReason.open"
      v-click-outside="resetDateReasonObject"
    >
      <p class="sup-chat-info__title">
        Срок до следующего ответа больше чем 1 день. <br />
        Напиши, пожалуйста, причину
      </p>
      <div class="sup-chat-info__item">
        <div class="req-save-name">
          <textarea
            class="textarea"
            placeholder="Введите текст комментария здесь..."
            v-model="dateSettingReason.text"
          />
        </div>
      </div>

      <div class="sup-chat-info__item">
        <span
          class="button cm__add-btn button_green button_uppercase settings-devices-zone__add"
          style="color: #fff"
          @click="setDateWithReasonComment"
          >Готово</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
import { API_ROOT } from "../../../../../config";
import Api from "../../../../../api/support/rps";
import * as c from "@/store/support/constants";
import { Helpers } from "@/mixins/Helpers";
import { showAlert } from "../../../../../utils";
import * as moment from "moment";
import RequestTags from "./RequestTags";

export default {
  name: "RequestWindowSidebar",
  components: { RequestTags },
  props: ["request", "activeTab", "files"],
  mixins: [Helpers],
  data() {
    return {
      RESPONSE_CHANGE_RIGHT: c.RESPONSE_CHANGE_RIGHT,
      managerSelected: "",
      statusSelected: null,
      dateTime: null,
      dateSettingReason: {
        open: false,
        text: "",
        value: "",
        type: "",
      },
    };
  },
  mounted() {
    this.managerSelected = this.request.Manager;
    this.statusSelected = this.selectedRequest.StatusId;
  },
  filters: {
    initials: function (name) {
      if (!name) return "";
      const nameArr = name.split(" ").filter((item) => item.length);
      if (nameArr.length === 3) {
        return `${nameArr[1][0]}${nameArr[2][0]}`;
      } else if (nameArr.length === 2) {
        return `${nameArr[0][0]}${nameArr[1][0]}`;
      } else {
        return nameArr[0][0];
      }
    },
  },
  computed: {
    ...mapState("rpsSupport", [
      "personsForManage",
      "isDistsObjectList",
      "selectedRequest",
      "commonSettings",
    ]),
    ...mapState("rpsSupport", ["preloaders"]),
    ...mapState("tasks", ["users"]),
    computedTags() {
      const self = this;
      function findTag(array, tag) {
        let found = null;
        array.forEach((i) => {
          if (!found) {
            if (i.Id === tag.TagId || i.Id === tag.Id) found = i;
            else if (i.child && i.child.length) {
              found = findTag(i.child, tag);
            }
          }
        });
        return found;
      }
      return _.map(this.selectedRequest.tags, (i) => {
        const foundTag = findTag(self.commonSettings.tags, i);
        return {
          ...foundTag,
          TagId: foundTag.Id,
          relationId: i.relationId ? i.relationId : i.Id,
        };
      });
    },
    getDeadline() {
      const date = new Date(this.request.AnswerDeadline * 1000);
      return date.toLocaleString("ru-RU");
    },
    managerComputed() {
      if (this.managerSelected)
        return this.getManagerName(this.managerSelected);
      else return this.getManagerName(this.request.Manager);
    },
    statusComputed() {
      if (this.statusSelected) {
        switch (this.statusSelected) {
          case 2:
            return "В работе";
          case 1:
            return "Ожидает ответа оператора";
          case 4:
            return "Ожидает ответа клиента";
          default:
            return this.selectedRequest.Status;
        }
      } else return this.selectedRequest.Status;
    },
    getManagerAvatar() {
      if (!this.personsForManage.length) {
        return false;
      } else {
        const foundUser = _.find(this.users, [
          "Id",
          this.managerSelected || this.request.Manager,
        ]);
        return foundUser && foundUser.LinkToAvatar
          ? API_ROOT + foundUser.LinkToAvatar
          : false;
      }
    },
    statusColor() {
      const status = this.statusSelected;

      if (status == 2) {
        return "#0c6";
      } else if (status == 1) {
        return "#f9ad1a";
      } else {
        return "#efefef";
      }
    },
  },
  methods: {
    ...mapActions("rpsSupport", [c.CHANGE_REQUEST_STATUS, c.ADD_TIME]),
    changeManager(managerId) {
      const formData = new FormData();
      formData.append("managerId", managerId);
      formData.append("requestId", this.selectedRequest.Id);

      this.preloaders.request = true;
      Api.changeManager(formData)
        .then((res) => {
          if (res.data.status === "error") this.managerSelected = "";
          showAlert(res.data.status, res.data.message);
        })
        .catch((err) => {
          this.managerSelected = "";
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.preloaders.request = false;
        });
    },
    getManagerName(id) {
      if (!this.personsForManage.length) {
        return "...";
      } else {
        const foundManager = _.find(this.personsForManage, ["Id", id]);
        return foundManager ? foundManager.FullName : "Не установлен";
      }
    },
    changeRequestStatus(status) {
      this.statusSelected = status;
      this[c.CHANGE_REQUEST_STATUS]({
        requestId: this.selectedRequest.Id,
        status,
      }).catch(() => {
        this.statusSelected = this.selectedRequest.StatusId;
      });
    },
    addTime(period, comment = null) {
      this.resetDateReasonObject();
      const requestId = this.selectedRequest.Id;
      const timeData = { requestId, time: null, period, comment };
      if (this.validateDate(period, "minutes") || comment) {
        this[c.ADD_TIME](timeData);
      }
    },
    addDate(date, comment = null) {
      this.resetDateReasonObject();
      const time = moment(date).unix();
      const requestId = this.selectedRequest.Id;
      const timeData = { requestId, time, period: null, comment };
      if (this.validateDate(date, "calendar") || comment) {
        this[c.ADD_TIME](timeData);
      }
    },
    validateDate(value, type) {
      let deadline, diff;
      const now = moment();

      if (type === "minutes") {
        const deadlineValue =
          moment
            .unix(this.selectedRequest.AnswerDeadline)
            .diff(now, "minutes") >= 0
            ? this.selectedRequest.AnswerDeadline
            : moment().unix();

        deadline = moment.unix(deadlineValue).add(value, "seconds");
        diff = deadline.diff(now, "minutes");
        console.log(diff);
      } else {
        deadline = moment(value);
        diff = deadline.diff(now, "minutes");
      }
      if (diff > 1435) {
        this.dateSettingReason.open = true;
        this.dateSettingReason.value = value;
        this.dateSettingReason.type = type;
        return false;
      }
      this.resetDateReasonObject();
      return true;
    },
    setDateWithReasonComment() {
      if (this.dateSettingReason.text) {
        if (this.dateSettingReason.type === "minutes") {
          this.addTime(
            this.dateSettingReason.value,
            this.dateSettingReason.text
          );
          this.resetDateReasonObject();
        } else {
          this.addDate(
            this.dateSettingReason.value,
            this.dateSettingReason.text
          );
          this.resetDateReasonObject();
        }
      }
    },
    resetDateReasonObject() {
      this.dateSettingReason = {
        open: false,
        type: "",
        text: "",
        value: "",
      };
    },
  },
};
</script>

<style scoped></style>
