<template>
  <div class="cm-popup__right task-files-list__wrapper">
    <files-header
      :gridView="gridView"
      @setGridView="(val) => (gridView = val)"
      :favoritesOnly="favoritesOnly"
      @makeFavoriteFilter="(val) => (favoritesOnly = val)"
      ref="files-header"
    />
    <div class="tasks-progress-bar" v-if="progressFilesUpload">
      <span :style="{ width: progressFilesUpload + '%' }"></span>
    </div>
    <div class="task-files-list" :class="{ 'task-files-list--grid': gridView }">
      <div
        class="task-files-list__item"
        v-for="(item, index) in taskFiles"
        :key="index"
      >
        <div
          class="task-files-list__img"
          v-if="fileIsImage(item.Filename)"
          @click="showGalleryImage(item.linkToFile, taskFiles)"
        >
          <img :src="getThumbnailSrc(item)" alt="" />
        </div>
        <div class="task-files-list__img task-files-list__img--file" v-else>
          <img :src="getNonImageTypeFileIcon(item.Filename)" />
        </div>

        <div class="task-files-list__info">
          <div class="task-files-list__left">
            <div
              class="task-files-list__name"
              v-if="fileIsImage(item.FileType)"
            >
              {{ item.Filename }}
            </div>
            <a
              class="task-files-list__name"
              :href="getFileSrc(item.linkToFile)"
              download
              @click.prevent="
                downloadURI(getFileSrc(item.linkToFile), item.Filename, item.Id)
              "
              v-else
              >{{ item.Filename }}</a
            >
            <div class="task-files-list__date">
              {{ item.TimeCreate | formatLocalDate }}
            </div>

            <!--TODO теги для файлов-->
            <!--<span class="task-files-list__label task-label task-label&#45;&#45;1">Проектирование</span>-->
          </div>
          <div class="task-files-list__right">
            <div class="task-files-list__person task-table-person">
              <UserAvatar :userId="item.UserId" />
              {{ getPersonNameById(item.UserId) | shortName }}
            </div>
            <div
              class="task-files-list__favorite-icon task-files-list__favorite-icon__1"
              @click="addFileToFavorite(item)"
              :class="{
                'task-files-list__favorite-icon__2': item.fileInFavoriteId,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FilesHeader from "./FilesHeader";
import * as c from "../../../../../store/tasks/constants";
import common from "../../../../../mixins/tasks/common";
import UserAvatar from "../../CommonComponents/UserAvatar";
import * as _ from "lodash";

export default {
  mixins: [common],
  data() {
    return {
      gridView: false,
      favoritesOnly: false,
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    taskFiles() {
      if (this.isMounted) {
        const filterTypes = this.$refs["files-header"].$refs["files-filter"]
          .filterTypes;
        const filterPersons = this.$refs["files-header"].$refs["files-filter"]
          .filterPersons;
        let search = this.$refs["files-header"].search;
        // eslint-disable-next-line no-useless-escape
        const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        search = search.replace(invalid, "");

        let arr = _.filter(this.selectedTask.files, (i) => {
          const re = new RegExp(search, "gi");
          return re.test(i.Filename);
        });

        arr = _.filter(arr, (i) => {
          if (this.favoritesOnly) {
            return i.fileInFavoriteId;
          } else {
            return true;
          }
        });
        if (filterTypes.length) {
          arr = _.filter(arr, (i) => {
            const ext = i.Filename.split(".").pop().toLowerCase();
            return filterTypes.includes(ext);
          });
        }
        if (filterPersons.length) {
          arr = _.filter(arr, (i) => {
            const id = i.UserId;
            return filterPersons.includes(id);
          });
        }
        return arr;
      }
      return false;
    },
    ...mapState("tasks", ["selectedTask", "progressFilesUpload", "userData"]),
  },
  methods: {
    addFileToFavorite(item) {
      const dataToRestore = item.fileInFavoriteId;
      if (!item.fileInFavoriteId) {
        item.fileInFavoriteId = "1";
        this[c.ADD_FILE_TO_FAVORITE]({
          FileId: item.Id,
          UserId: this.userData.Id,
        }).catch(() => (item.fileInFavoriteId = dataToRestore));
      } else {
        item.fileInFavoriteId = null;
        this[c.REMOVE_FILE_FROM_FAVORITE](dataToRestore).catch(
          () => (item.fileInFavoriteId = dataToRestore)
        );
      }
    },
    ...mapActions("tasks", [
      c.ADD_FILE_TO_FAVORITE,
      c.REMOVE_FILE_FROM_FAVORITE,
    ]),
  },
  components: {
    FilesHeader,
    UserAvatar,
  },
};
</script>

<style scoped></style>
