import ClientSupport from "./support/client/storage";
import RpsSupport from "./support/rps/storage";
import Common from "./common/storage";
import Clients from "./clients/storage";
import Sales from "./sales/storage";
import CommercialSite from "./commercial_site/storage";
import Tasks from "./tasks/storage";
import Reservation from "./sales_reservation/storage";
import Wiki from "./wiki/storage";

export default {
  modules: {
    clientSupport: ClientSupport,
    rpsSupport: RpsSupport,
    common: Common,
    clients: Clients,
    sales: Sales,
    site: CommercialSite,
    tasks: Tasks,
    reserve: Reservation,
    wiki: Wiki,
  },
};
