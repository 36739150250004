<template>
  <div class="pdf-cont">
    <div class="print print--20">
      <div class="print__cont">
        <div class="print__logo">
          <img :src="getimgStaticSrc('sales/print-logo.svg')" />
        </div>
        <div
          class="print__title"
          :style="{
            backgroundImage: `url(${getimgStaticSrc('sales/print-badge.svg')})`,
          }"
        >
          Спасибо, <br />что к нам обратились!
        </div>
        <div class="print__info-text">
          Если у вас остались вопросы, <br />
          ваш личный менеджер ответит на них.
        </div>
        <div class="print__contacts">
          <div class="print__contacts-item">
            <div class="print__contacts-label">Ваш личный менеджер:</div>
            <div class="print__contacts-text">
              {{ offerObject.json.ManagerName }}
            </div>
          </div>
          <div class="print__contacts-item">
            <div class="print__contacts-label">Контакты:</div>
            <div class="print__contacts-text">
              {{ offerObject.json.ManagerPhone }},
              {{ offerObject.json.ManagerEmail }}
            </div>
          </div>
          <div class="print__contacts-item">
            <div class="print__contacts-label">Контакты RPS:</div>
            <div class="print__contacts-text">
              8 (800) 551-06-22, info@r-p-s.ru <br />г. Москва, ул. Угрешская,
              д. 2, стр. 95, офис 1
            </div>
          </div>
        </div>
      </div>
      <div class="print__footer" v-if="print.full">
        {{ pageNums.Objects + 1 }}
      </div>
      <div class="print__footer" v-else>{{ pageNums.Tenant + 3 }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject", "print", "pageNums"]),
  },
};
</script>
