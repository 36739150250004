<template>
  <div class="popup-wrapper" style="z-index: 99">
    <div
      class="popup task-popup task-popup-parent task-popup-events event-popup event-popup_active"
      v-popup-inner-windows="{
        state: taskForNotificationsPopup && !task,
        level: 0,
        forceOffset: true,
        sumLevel: 0,
      }"
    >
      <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
        <div class="popup__content" style="width: 740px">
          <div class="popup__header">
            <div class="task-settings">
              <button
                class="task-settings__btn"
                aria-label="настройки"
                @click="showSettings = true"
              >
                <svg width="4" height="16">
                  <use
                    xlink:href="/static/images/tasks/sprite.svg#dots-icon"
                  ></use>
                </svg>
              </button>
              <NotificationSettingsList
                v-if="showSettings"
                v-click-outside="closeSettings"
                @closeSettings="closeSettings"
              />
            </div>
            <h2 class="popup__heading">
              События:
              <p v-if="task" style="margin-left: 5px">
                {{ task.taskInfo.TaskName }}
              </p>
              <template v-if="aviliableFilters.length">
                <span
                  class="b-filter"
                  @click="setFilter(null)"
                  :class="{ 'b-filter--active': !filter }"
                  >Все</span
                >
                <span
                  v-for="(item, index) in aviliableFilters"
                  :key="index"
                  class="b-filter"
                  @click="
                    item.Value !== 4
                      ? setFilter(item.Value)
                      : (groupSelectListOpen = true)
                  "
                  :class="{ 'b-filter--active': filter === item.Value }"
                >
                  <!--                   :style="{-->
                  <!--                    backgroundColor:-->
                  <!--                      item.Value === 4 &&-->
                  <!--                      groupForFilter &&-->
                  <!--                      groupForFilter.Color,-->
                  <!--                  }"-->
                  <span class="b-filter__span" v-if="item.Value === 3">@</span>

                  <template v-if="item.Value !== 4">{{ item.Name }}</template>
                  <template v-else>
                    {{
                      filter === 4 && groupForFilter
                        ? groupForFilter.Name
                        : "Группа"
                    }}
                  </template>
                  <template v-if="item.Value === 1"
                    >: {{ countNotifications.countNewNotifications }}</template
                  >
                  <template v-else-if="item.Value === 2"
                    >:
                    {{ countNotifications.countUnreadNotifications }}</template
                  >
                </span>
              </template>

              <div
                class="notifications-popup__group-list task-settings__list"
                v-if="groupSelectListOpen"
                v-click-outside="closeGroupSelectList"
              >
                <div
                  class="task-settings__list-item task-settings__list-item--search-input"
                >
                  <div class="task-settings__list-icon">
                    <svg width="15" height="15">
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/static/images/icon.svg#icon_icon-search"
                      ></use>
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Поиск"
                    ref="searchGroupInput"
                    v-model="searchGroupInputValue"
                  />
                </div>
                <div
                  class="task-settings__list-item task-settings__list-gray-item"
                  v-for="item in sortGroups"
                  :key="item.Id"
                  @click="setGroupFilter(item)"
                >
                  <div class="task-settings__list-icon">
                    <svg
                      width="16"
                      height="15"
                      :style="{ fill: item.Color ? item.Color : '#FFB72B' }"
                    >
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/static/images/tasks/sprite.svg#folder-icon"
                      ></use>
                    </svg>
                  </div>
                  <p>{{ item.Name }}</p>
                </div>
              </div>
            </h2>
            <div class="popup__close" @click="$emit('onClosePopup')">
              <svg class="popup__close-icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="static/images/icon.svg#icon_icon-close"
                ></use>
              </svg>
            </div>
          </div>
          <div class="popup__body">
            <div class="cm-popup">
              <div
                class="task-events"
                v-infinite-scroll="loadMore"
                infinite-scroll-disabled="task"
                infinite-scroll-distance="0"
                infinite-scroll-immediate-check="false"
                infinite-scroll-throttle-delay="1000"
              >
                <transition name="fade">
                  <div class="table-spinner" v-if="preloader">
                    <Spinner
                      :noFadeIn="true"
                      name="ball-grid-pulse"
                      color="#F9AD1A"
                      width="60"
                      height="60"
                    />
                  </div>
                </transition>
                <transition name="fade">
                  <div>
                    <div
                      class="task-events__wrap"
                      :class="{
                        'task-events__wrap_groped-mode':
                          viewType === viewTypes.GROUPED_MODE,
                      }"
                      :key="index"
                      v-for="(notifications, index) in groupedNotifications"
                    >
                      <div class="task-events__time-name" :key="index">
                        <span>{{ getGroupDateName(index) }}</span>
                      </div>
                      <div
                        class="task-events__item-wrap"
                        v-if="viewType === viewTypes.DEFAULT_MODE"
                      >
                        <notification-item
                          v-for="item in notifications"
                          :key="item.Id"
                          :item="item"
                          :countNotifications="countNotifications"
                        />
                      </div>
                      <div v-else>
                        <grouped-mode-notification-item
                          v-for="item in notifications"
                          :key="item.Id"
                          :item="item"
                          @setTaskForGetNotifications="
                            (notification) =>
                              (taskForNotificationsPopup = notification)
                          "
                        />
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="popupSlide">
      <notifications
        v-if="taskForNotificationsPopup"
        @onClosePopup="taskForNotificationsPopup = false"
        :task="taskForNotificationsPopup"
      />
    </transition>
  </div>
</template>

<script>
import NotificationItem from "./NotificationItem";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import * as _ from "lodash";
import Spinner from "vue-spinkit";
import {
  GET_COUNT_NOTIFICATIONS,
  GET_GROUPED_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FROM_ONE_TASK,
  GET_SPECIFIC_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_READ,
} from "../../../../store/tasks/constants";
import { Helpers } from "@/mixins/Helpers";
import GroupedModeNotificationItem from "@/modules/tasks/components/Notifications/GroupedModeNotificationItem";
import NotificationSettingsList from "@/modules/tasks/components/Notifications/NotificationSettingsList";
import axios from "axios";
import { API_ROOT } from "@/config";
import ru from "convert-layout/ru";

const DEFAULT_MODE = 1;
// eslint-disable-next-line no-unused-vars
const GROUPED_MODE = 2;

export default {
  name: "Notifications",
  mixins: [Helpers],
  props: ["task"],
  data() {
    return {
      countNotifications: {
        countUnreadNotifications: 0,
        countNewNotifications: 0,
      },
      localNotifications: [],
      localUnreadNotifications: [],
      groupedModeNotifications: [],
      filter: null,
      steps: ["all"],
      preloader: true,
      page: 0,
      pageForUserMarked: 0,
      groupSelectListOpen: false,
      groupForFilter: null,
      viewType: DEFAULT_MODE,
      viewTypes: {
        DEFAULT_MODE,
        GROUPED_MODE,
      },
      taskForNotificationsPopup: null,
      userMarkedNotifications: [],
      showSettings: false,
      aviliableFilters: [],
      searchGroupInputValue: "",
    };
  },
  created() {
    !this.task && this.getAviliableFilters();
    if (this.userData.NotificationsViewType === "2") {
      this.viewType = this.viewTypes.GROUPED_MODE;
    }
    if (this.task) {
      this.viewType = this.viewTypes.DEFAULT_MODE;
      this.fetchNotificationsFromOneTask();
    } else if (this.viewType === this.viewTypes.DEFAULT_MODE) {
      this.fetchNotification();
    } else {
      this.fetchGroupedNotifications();
    }
  },
  mounted() {},
  computed: {
    groupedNotifications() {
      let notificationsArray = this.localNotifications;
      let dateField = "DateCreate";
      if (this.viewType === this.viewTypes.GROUPED_MODE) {
        notificationsArray = this.groupedModeNotifications;
        dateField = "TimeCreate";
      }

      return _.groupBy(notificationsArray, (i) => {
        return moment(i[dateField]).startOf("day").format();
      });
    },
    sortGroups() {
      // if (this.viewType === this.viewTypes.GROUPED_MODE) {
      //   return _.find(this.aviliableFilters, ["Value", 4])?.Options;
      // }
      return _.orderBy(
        _.filter(_.find(this.aviliableFilters, ["Value", 4])?.Options, (i) => {
          const searchRus = ru.toEn(this.searchGroupInputValue).replace(
            /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, // eslint-disable-line
            ""
          ); // eslint-disable-line;
          const searchEn = ru.fromEn(this.searchGroupInputValue);

          const reRu = new RegExp(searchRus, "gi");
          const reEn = new RegExp(searchEn, "gi");

          return reRu.test(i.Name) || reEn.test(i.Name);
        }),
        ["Name"],
        ["asc"]
      );
    },
    ...mapState("tasks", ["allGroups", "users", "userData"]),
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.$emit("onClosePopup");
      }
    },
    getGroupDateName(date) {
      return moment(date).locale("ru").format("DD MMM YYYY");
    },
    setFilter(value) {
      this.filter = value;
      this.page = 0;
      document.querySelector(".task-events").scrollTop = 0;
      if (this.viewType === this.viewTypes.DEFAULT_MODE) {
        this.localNotifications = [];
        this.fetchNotification();
      } else {
        this.groupedModeNotifications = [];
        this.fetchGroupedNotifications();
      }
    },
    resetNewNotificationCountInItems() {
      this.localNotifications = _.map(this.localNotifications, (i) => {
        if (i.TemplatePriority === "1") {
          i.localNewNotification = false;
        }
        return i;
      });
      // }
    },
    fetchNotification() {
      this.preloader = true;
      const params = {
        Page: this.page,
      };
      if (this.filter) params.FilterId = this.filter;
      if (this.filter === 4) params.FilterValue = this.groupForFilter.Id;

      this[GET_NOTIFICATIONS](params)
        .then((data) => {
          this[GET_UNREAD_NOTIFICATIONS]().then((data) => {
            this.localUnreadNotifications = data;
          });
          const array = _.map(data, (i) => {
            i.localNewNotification = i.NewNotification || i.Status === "1";
            return i;
          });
          if (this.page) {
            this.localNotifications.push(...array);
          } else {
            this.localNotifications = array;
          }
          if (!this.page) {
            this[GET_COUNT_NOTIFICATIONS]().then((data) => {
              this.countNotifications = data;
              this[MARK_ALL_NOTIFICATIONS_READ]();
            });
          }
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    fetchGroupedNotifications() {
      this.preloader = true;
      const params = {
        Page: this.page,
      };
      if (this.filter) params.FilterId = this.filter;
      if (this.filter === 4) params.FilterValue = this.groupForFilter.Id;
      this[GET_GROUPED_NOTIFICATIONS](params)
        .then((data) => {
          const array = _.map(data, (i) => {
            i.localNewNotification = i.NewNotification || i.Status === "1";
            return i;
          });
          if (this.page) {
            this.groupedModeNotifications.push(...array);
          } else {
            this.groupedModeNotifications = array;
          }
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    fetchNotificationsFromOneTask() {
      this[GET_NOTIFICATIONS_FROM_ONE_TASK](this.task.TaskId)
        .then((data) => {
          this.localNotifications = data;
        })
        .finally(() => {
          this.preloader = false;
        });
    },
    loadMore() {
      if (this.viewType === this.viewTypes.DEFAULT_MODE) {
        if (this.filter === "mentions") {
          this.pageForUserMarked += 1;
          this.getUserMarkedNotifications(true);
        } else {
          this.page += 1;
          this.fetchNotification();
        }
      } else {
        this.page += 1;
        this.fetchGroupedNotifications();
      }
    },
    closeGroupSelectList() {
      this.groupSelectListOpen = false;
      this.searchGroupInputValue = "";
    },
    getUserMarkedNotifications(push = false) {
      this[GET_SPECIFIC_NOTIFICATIONS]({
        page: this.pageForUserMarked,
        params: { TaggedUser: "1" },
      }).then((data) => {
        const array = _.map(data, (i) => {
          i.localNewNotification = i.NewNotification || i.Status === "1";
          return i;
        });
        if (push) {
          this.userMarkedNotifications.push(...array);
        } else {
          this.userMarkedNotifications = array;
        }
      });
    },
    closeSettings() {
      this.showSettings = false;
    },
    getAviliableFilters() {
      axios
        .get(`${API_ROOT}api/tasks/v2/TasksNotifications/getAviliableFilters`)
        .then((res) => {
          this.aviliableFilters = res.data.params;
        });
    },
    setGroupFilter(item) {
      this.groupForFilter = item;
      this.groupSelectListOpen = false;
      document.querySelector(".task-events").scrollTop = 0;
      this.filter = 4;
      this.page = 0;
      if (this.viewType === this.viewTypes.DEFAULT_MODE) {
        this.localNotifications = [];
        this.fetchNotification();
      } else {
        this.groupedModeNotifications = [];
        this.fetchGroupedNotifications();
      }
    },
    ...mapActions("tasks", [
      MARK_ALL_NOTIFICATIONS_READ,
      GET_NOTIFICATIONS,
      GET_COUNT_NOTIFICATIONS,
      GET_UNREAD_NOTIFICATIONS,
      GET_GROUPED_NOTIFICATIONS,
      GET_NOTIFICATIONS_FROM_ONE_TASK,
      GET_SPECIFIC_NOTIFICATIONS,
    ]),
  },
  components: {
    NotificationItem,
    GroupedModeNotificationItem,
    Spinner,
    NotificationSettingsList,
  },
};
</script>

<style scoped></style>
