<template>
  <div
    class="task-comment-textarea"
    v-if="checkUserTaskRight('write_comments_to_task')"
    :class="{ 'task-comment-textarea--reply': replyComment }"
    ref="triggerZone"
    :style="{ width }"
  >
    <div class="task-comments-item__left-col">
      <div class="task-comments-item__avatar">
        <UserAvatar :user="userData" v-if="userData" />
      </div>
      <div
        class="task-comments-item__lock"
        @click="
          commentWillBeHide = !commentWillBeHide;
          usersWhoWillSeeHiddenComment = [];
        "
      >
        <svg width="16" height="18" v-if="!commentWillBeHide">
          <use
            xlink:href="/static/images/tasks/sprite.svg#lock-open-icon"
          ></use>
        </svg>
        <svg width="16" height="18" v-else>
          <use
            xlink:href="/static/images/tasks/sprite.svg#lock-close-icon"
          ></use>
        </svg>
      </div>
    </div>
    <div class="task-comment-textarea__text">
      <div class="task-comment-reply" v-if="showReplyBar">
        <div class="task-comment-reply__person">
          {{ replyComment.AuthorName | shortName }}
        </div>
        <p class="task-comment-reply__text" v-html="replyComment.Comment">}</p>
        <div
          class="task-comment-reply__close"
          @click="
            () => {
              $emit('removeReply');
              message = '';
            }
          "
        >
          <svg class="popup__close-icon">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/icon.svg#icon_icon-close"
            ></use>
          </svg>
        </div>
      </div>

      <div class="task-editor-wrapper">
        <vue-editor
          class="task-create-form__text task-editor control-comment-state"
          :class="{
            'control-comment-state--focus': isFocus || message || files.length,
          }"
          v-model="message"
          :editorToolbar="customToolbar"
          placeholder="Введите ваш комментарий здесь..."
          :editor-options="{ bounds: '#task-editor_task-comment' }"
          id="task-editor_task-comment"
          ref="editor"
          @text-change="watchForSubscribe"
          @selection-change="watchEditorSelection"
          @paste.native="onPaste"
          @blur="
            saveUnsendedMessageToLocalStorage(message);
            setFocusFalse();
          "
          @keydown.enter.meta.native="sendComment"
          @focus="isFocus = true"
        >
        </vue-editor>
        <div class="task-create-form__additional-toolbar">
          <label
            class="task-create-form__additional-toolbar-item"
            @click="isFocus = true"
          >
            <input
              type="file"
              hidden
              @change="filesChange($event.target.files)"
              multiple
            />
            <svg focusable="false" viewBox="0 0 32 32">
              <path
                d="M19,32c-3.9,0-7-3.1-7-7V10c0-2.2,1.8-4,4-4s4,1.8,4,4v9c0,0.6-0.4,1-1,1s-1-0.4-1-1v-9c0-1.1-0.9-2-2-2s-2,0.9-2,2v15c0,2.8,2.2,5,5,5s5-2.2,5-5V10c0-4.4-3.6-8-8-8s-8,3.6-8,8v5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5C6,4.5,10.5,0,16,0s10,4.5,10,10v15C26,28.9,22.9,32,19,32z"
              ></path>
            </svg>
          </label>
          <label
            class="task-create-form__additional-toolbar-item"
            @click="
              markUserByChar();
              isFocus = true;
            "
          >
            <svg focusable="false" viewBox="0 0 32 32">
              <path
                d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16c3.7,0,7.3-1.3,10.1-3.6c0.4-0.3,0.5-1,0.1-1.4c-0.3-0.4-1-0.5-1.4-0.1c-2.5,2-5.6,3.1-8.8,3.1C8.3,30,2,23.7,2,16S8.3,2,16,2s14,6.3,14,14v1.5c0,1.9-1.6,3.5-3.5,3.5S23,19.4,23,17.5V16c0-3.9-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7c2.3,0,4.4-1.1,5.7-2.9c0.9,1.7,2.8,2.9,4.8,2.9c3,0,5.5-2.5,5.5-5.5V16C32,7.2,24.8,0,16,0z M16,21c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S18.8,21,16,21z"
              ></path>
            </svg>
          </label>
        </div>
      </div>
      <div class="task-popup-files" v-if="files.length">
        <div
          class="sup-chat-files__item"
          v-for="(item, index) in files"
          :key="index"
        >
          <img
            :src="item.img"
            v-if="fileIsImage(item.name)"
            @click="showImagePopup(item.img)"
          />
          <a
            class="sup-chat-files__item-link"
            download
            v-else
            @click.prevent="
              downloadURI(getFileSrc(item.linkToFile), item.name, item.Id)
            "
            :href="getFileSrc(item.linkToFile)"
          >
            <img :src="getNonImageTypeFileIcon(item.name)" />
            <p>{{ item.name }}</p>
          </a>

          <div
            class="sup-chat-files__item-remove"
            @click="deleteFileFromTask(item.id)"
          >
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
      </div>

      <div class="task-comment-textarea__control">
        <div class="task-comment-subscribers" v-if="!commentWillBeHide">
          <div class="task-comment-subscribers__name">Подписчики:</div>

          <div class="task-popup__nav-persons-list">
            <div
              class="task-popup__nav-persons-list__item"
              v-for="(item, index) in loadedTask.subscriptions.filter(
                (item, index) =>
                  item &&
                  index <= 6 &&
                  item.UserId !== loadedTask.entityModel.ResponsibleId
              )"
              :key="index"
              :title="item.Fio | shortName"
            >
              <UserAvatar :user="item.User" />
              <div
                class="task-table-person__delete"
                v-if="checkUserTaskRight('change_subscribers')"
                @click="deleteSubscriber(item.Id)"
              >
                <svg focusable="false" viewBox="0 0 32 32">
                  <path
                    d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="task-create-form-subtasks__person">
              <div
                class="task-create-form-subtasks__person--add"
                v-if="
                  checkUserTaskRight('change_subscribers') &&
                  loadedTask.subscriptions.length <= 7
                "
                @click="addSubscriberBubbleOpen = true"
              >
                +
              </div>
              <div
                class="task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count"
                v-else-if="loadedTask.subscriptions.length > 7"
                @click="
                  () => {
                    if (checkUserTaskRight('change_subscribers'))
                      addSubscriberBubbleOpen = true;
                  }
                "
              >
                +{{ loadedTask.subscriptions.length - 7 }}
              </div>
              <PersonsListSearch
                :arr="loadedTask.subscriptions"
                v-if="addSubscriberBubbleOpen"
                :className="'task-bubble--subscriber'"
                :excludeArray="[loadedTask.entityModel.ResponsibleId]"
                :outerUsers="loadedTask.taskGroupUsers"
                @choose="addSubscriber"
                @deleteItem="deleteSubscriber"
                @close="addSubscriberBubbleOpen = false"
              />
            </div>
          </div>
        </div>
        <div class="task-comment-subscribers" v-else>
          <div class="task-comment-subscribers__name">Увидят только:</div>
          <div class="task-popup__nav-persons-list">
            <div
              class="task-popup__nav-persons-list__item"
              v-for="(item, index) in usersWhoWillSeeHiddenComment.filter(
                (item, index) =>
                  item &&
                  index <= 6 &&
                  item.UserId !== loadedTask.entityModel.ResponsibleId
              )"
              :key="index"
              :title="getPersonNameById(item.UserId) | shortName"
            >
              <UserAvatar :user="item.User" />
              <div
                class="task-table-person__delete"
                @click="deleteHiddenCommentUser(item.Id)"
              >
                <svg focusable="false" viewBox="0 0 32 32">
                  <path
                    d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="task-create-form-subtasks__person">
              <div
                class="task-create-form-subtasks__person--add"
                v-if="usersWhoWillSeeHiddenComment.length <= 7"
                @click="addHiddenCommentUserBubbleOpen = true"
              >
                +
              </div>
              <div
                class="task-create-form-subtasks__person--add task-create-form-subtasks__person--last-count"
                v-else-if="usersWhoWillSeeHiddenComment.length > 7"
                @click="addHiddenCommentUserBubbleOpen = true"
              >
                +{{ usersWhoWillSeeHiddenComment.length - 7 }}
              </div>
              <PersonsListSearch
                :arr="usersWhoWillSeeHiddenComment"
                v-if="addHiddenCommentUserBubbleOpen"
                :className="'task-bubble--subscriber'"
                :excludeArray="[loadedTask.entityModel.ResponsibleId]"
                :outerUsers="loadedTask.taskGroupUsers"
                @choose="addHiddenCommentUser"
                @deleteItem="deleteHiddenCommentUser"
                @close="addHiddenCommentUserBubbleOpen = false"
              />
            </div>
          </div>
        </div>
        <div
          class="btn-yellow task-comment-send-btn"
          :class="{
            'task-comment-send-btn--disabled':
              !isFocus && !files.length && !message,
          }"
          @click="sendComment"
        >
          Отправить
        </div>
      </div>
    </div>
    <dropzone ref="dropzone" @filesChange="filesChange" />
  </div>
</template>

<script>
import common from "@/mixins/tasks/common";
import { mapActions, mapMutations, mapState } from "vuex";
import textSubscriber from "@/mixins/tasks/textSubscriber";
import { SET_LOCAL_STORAGE_SETTINGS } from "@/store/tasks/constants";
import * as c from "@/store/tasks/constants";
import * as _ from "lodash";
import Dropzone from "@/modules/tasks/components/CommonComponents/Dropzone";
import UserAvatar from "@/modules/tasks/components/CommonComponents/UserAvatar";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";
import { prepareFetchedFiles } from "@/mixins/tasks/func";

export default {
  name: "CommentInput",
  mixins: [common, textSubscriber],
  props: [
    "replyComment",
    "loadedTask",
    "fromPopup",
    "popupReplyComment",
    "width",
  ],
  data() {
    return {
      message: "",
      files: [],
      addSubscriberBubbleOpen: false,
      isFocus: false,
      commentWillBeHide: false,
      usersWhoWillSeeHiddenComment: [],
      addHiddenCommentUserBubbleOpen: false,
    };
  },
  created() {
    if (!this.fromPopup) {
      const foundLocalSettings = _.find(this.localStorageSettings, {
        Id: this.loadedTask.entityModel.Id,
        Type: "task",
      });
      if (foundLocalSettings) {
        const settings = JSON.parse(foundLocalSettings.settings);
        this.message = settings.comment !== void 0 ? settings.comment : "";
      }
    }
  },
  mounted() {
    const dropzoneWrapper = this.$refs["dropzone"];
    this.$refs["triggerZone"].ondragover = function () {
      dropzoneWrapper.$el.classList.add("dropzone-hover");
      dropzoneWrapper.$el.style.display = "block";
      return false;
    };
    this.$refs["editor"].$el
      .querySelectorAll(".ql-toolbar button")
      .forEach((i) => i.setAttribute("tabindex", "-1"));
    this.$refs["editor"].quill.clipboard.addMatcher(
      Node.TEXT_NODE,
      function (node, delta) {
        var regex = /https?:\/\/[^\s]+/g;
        if (regex.exec(node.data) != null) {
          delta.ops = [{ insert: node.data, attributes: { link: node.data } }];
        }
        return delta;
      }
    );
    // this.$refs["editor"].quill.clipboard.addMatcher(
    //   Node.ELEMENT_NODE,
    //   (node, delta) => {
    //     delta.ops = delta.ops.map((op) => {
    //       return {
    //         insert: op.insert,
    //       };
    //     });
    //     return delta;
    //   }
    // );
  },
  computed: {
    showReplyBar() {
      if (this.fromPopup) {
        return this.popupReplyComment;
      }
      return this.replyComment;
    },
    ...mapState("tasks", [
      "selectedTask",
      "settings",
      "localStorageSettings",
      "userData",
    ]),
  },
  methods: {
    onPaste(e) {
      const files = e.clipboardData.files;
      if (files.length) {
        this.filesChange(files);
        e.preventDefault();
      }
    },
    filesChange(fileList) {
      this.$emit("hideDragZone");
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
    },
    saveUnsendedMessageToLocalStorage(comment) {
      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.loadedTask.entityModel.Id,
          Type: "task",
          settings: {
            comment,
          },
        },
        init: false,
      });
    },
    sendComment() {
      if (this.message.length || this.files.length) {
        const users = this.findSubscribersFromText(this.$refs["editor"].$el);
        // users.forEach((el) => {
        //   this[c.ADD_SUBSCRIBER]({
        //     UserId: el.UserId,
        //     TaskId: this.selectedTask.entityModel.Id,
        //   });
        // });
        const SkipNotifications = users.length ? false : !!this.files.length;

        this[c.CREATE_COMMENT]({
          TaskId: this.selectedTask.entityModel.Id,
          ParentId: this.replyComment ? this.replyComment.Id : null,
          CommentType: 1,
          Comment: this.message ? this.message : " ",
          Status: 1,
          UserId: this.userData.Id,
          SkipNotifications,
          Users: users,
          Visible: this.usersWhoWillSeeHiddenComment.length ? 2 : 1,
        }).then((newComment) => {
          if (this.fromPopup) {
            this.$emit("loadComments");
          }
          this.loadedTask.comments.push(newComment);
          this.setCommentsFiles(newComment.Id).finally(() => {
            setTimeout(() => {
              document.querySelector(
                ".cm-popup__right"
              ).scrollTop = document.querySelector(
                ".cm-popup__right"
              ).scrollHeight;
            });
          });
          if (this.usersWhoWillSeeHiddenComment.length) {
            this.usersWhoWillSeeHiddenComment.push({ Id: this.userData.Id });
            this[c.SET_COMMENT_VISIBLE]({
              CommentId: newComment.Id,
              UsersIds: this.usersWhoWillSeeHiddenComment.map((i) => i.Id),
            }).then((res) => {
              const createdComment = this.selectedTask.comments[
                this.selectedTask.comments.length - 1
              ];
              createdComment.VisibleUsersIds = res.data.rowsIds.map((i) => i);
            });
          }
          this.files = [];
          this.usersWhoWillSeeHiddenComment = [];
          this.commentWillBeHide = false;
        });
        this.message = "";
        this.$refs.editor.quill.setText("");
        // this.$refs.editor.quill.focus();
        // this.isFocus = true;
        this.$emit("removeReply");
        this.saveUnsendedMessageToLocalStorage("");
      }
    },
    setCommentsFiles(CommentId) {
      return new Promise((resolve) => {
        if (this.files.length) {
          const formData = new FormData();
          _.each(this.files, (i) => {
            formData.append("taskFiles[]", i.file);
          });
          formData.append("SkipNotifications", "false");
          formData.append("TaskId", this.selectedTask.entityModel.Id);
          formData.append("CommentId", CommentId);

          this.files = [];
          this[c.ADD_FILES_TO_TASK](formData).then((files) => {
            prepareFetchedFiles(files, this.loadedTask);
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    deleteFileFromTask(id) {
      this.files = _.reject(this.files, { id });
    },
    findSubscribersFromText(text) {
      let links = [];
      _.each(text.querySelectorAll(".ql-editor a"), (i) => {
        const userId = i.getAttribute("href");
        if (userId.includes("userId=")) {
          links.push({
            UserId: userId.split("=")[1],
            commentPart: i.closest("p").innerHTML,
          });
        }
      });
      return links;
    },
    addSubscriber(userId) {
      const watcher = this.loadedTask.watchers.find((i) => i.UserId === userId);
      if (watcher) {
        this.deleteWatcher(watcher.Id);
      }
      this[c.ADD_SUBSCRIBER]({
        UserId: userId,
        TaskId: this.loadedTask.entityModel.Id,
      }).then((userDataFromServer) => {
        this.loadedTask.subscriptions.push({
          ...this.getUser(
            userDataFromServer.UserId,
            this.loadedTask.taskGroupUsers
          ),
          Id: userDataFromServer.Id,
        });
      });
    },
    deleteSubscriber(Id) {
      this[c.DELETE_SUBSCRIBER](Id).then(
        () =>
          (this.loadedTask.subscriptions = _.reject(
            this.loadedTask.subscriptions,
            { Id }
          ))
      );
    },
    deleteWatcher(Id) {
      this[c.DELETE_WATCHER](Id).then(
        () =>
          (this.loadedTask.watchers = _.reject(this.loadedTask.watchers, {
            Id,
          }))
      );
    },
    deleteHiddenCommentUser(Id) {
      this.usersWhoWillSeeHiddenComment = _.reject(
        this.usersWhoWillSeeHiddenComment,
        { Id }
      );
    },
    addHiddenCommentUser(userId, user) {
      this.usersWhoWillSeeHiddenComment.push(user);
    },
    setFocusFalse() {
      if (!this.message && !this.files.length) {
        this.isFocus = false;
      }
    },
    markUserByChar() {
      const quill = this.$refs.editor.quill;
      const { index } = quill.getSelection(true) || {
        index: this.message.length,
      };
      quill.insertText(index, "@");
    },
    ...mapActions("tasks", [
      c.CREATE_COMMENT,
      c.ADD_FILES_TO_TASK,
      c.ADD_SUBSCRIBER,
      c.DELETE_SUBSCRIBER,
      c.SET_COMMENT_VISIBLE,
      c.DELETE_WATCHER,
    ]),
    ...mapMutations("tasks", [SET_LOCAL_STORAGE_SETTINGS]),
  },
  components: {
    Dropzone,
    UserAvatar,
    PersonsListSearch,
  },
};
</script>

<style scoped></style>
