<template>
  <div class="sup-container">
    <div class="table sup__table">
      <div class="table__header">
        <div class="table__col table__col_width_90">
          <p class="table__header-text">#</p>
        </div>
        <div
          class="table__col table__col_width_360"
          style="overflow: inherit"
          @mouseleave="filterWindows.messages = false"
        >
          <p class="table__header-text">Заявка</p>
          <div
            class="actions-header__col export-wrapper"
            style="margin-left: 10px"
          >
            <div
              class="table__filter-button reports__device-filter-button"
              @click="filterWindows.messages = !filterWindows.messages"
            >
              <span>{{
                requestFilters.messages === "Все"
                  ? "Все"
                  : "С новыми сообщениями"
              }}</span>
            </div>
            <div
              class="reports__device-type-list_block"
              style="z-index: 1"
              v-show="filterWindows.messages"
            >
              <ul>
                <li @click="changeFilter({ filter: 'messages', value: 'Все' })">
                  Все
                </li>
                <li
                  @click="
                    changeFilter({ filter: 'messages', value: 'new_messages' })
                  "
                >
                  С новыми сообщениями
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="table__col table__col_width_360">
          <p class="table__header-text">Последнее сообщение</p>
        </div>
        <div
          class="table__col table__col_width_260"
          @mouseleave="filterWindows.status = false"
        >
          <p class="table__header-text">Статус</p>
          <div
            class="actions-header__col export-wrapper"
            style="margin-left: 10px"
          >
            <div
              class="table__filter-button reports__device-filter-button"
              v-if="!activeTabs.closed"
              @click="filterWindows.status = !filterWindows.status"
            >
              <span>{{ requestFilters.status.name | truncateFilterName }}</span>
            </div>
            <div
              class="reports__device-type-list_block"
              v-show="filterWindows.status"
            >
              <ul>
                <li
                  @click="
                    changeFilter({
                      filter: 'status',
                      value: { name: 'Все', id: 0 },
                    })
                  "
                >
                  Все
                </li>
                <template v-if="supportData.StatusId">
                  <li
                    v-for="(name, id) in filteredStatuses"
                    :key="id"
                    @click="
                      changeFilter({ filter: 'status', value: { name, id } })
                    "
                  >
                    {{
                      name === "Ожидает ответа клиента"
                        ? "Ожидает вашего ответа"
                        : name
                    }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="table__col table__col_flexible">
          <p class="table__header-text">Последняя активность</p>
          <div
            class="table__filter-button table__filter-button_orange"
            style="margin-left: 10px"
            @click="
              changeFilter({
                filter: 'activity',
                value: getFilterValue('activity')[1],
              })
            "
          >
            {{ getFilterValue("activity")[0] }}
          </div>
        </div>
      </div>
      <div class="table__row-wrap">
        <template v-if="requests[activeTab].length">
          <div
            class="table__row"
            v-for="(item, index) in filteredRequests"
            :key="index"
            @click="selectRequest(item)"
          >
            <div class="table__row table__row_main">
              <div class="table__col table__col_width_90">
                <div class="table__text-block">
                  <p class="table__text">{{ item.Id }}</p>
                </div>
              </div>
              <div class="table__col table__col_width_360">
                <div class="table__text-block">
                  <p
                    class="table__text link"
                    :class="item.Unread ? 'bold' : ''"
                  >
                    {{ item.Subject }}
                  </p>
                  <span class="sup-label-table" v-if="item.Unread">{{
                    item.Unread
                  }}</span>
                </div>
              </div>
              <div class="table__col table__col_width_360">
                <p
                  class="table__text bold"
                  :class="item.Unread ? '' : 'grey-text'"
                >
                  {{
                    item.DistributorContactId ||
                    item.LastMessageSenderName === "Система"
                      ? item.distributorName || "RPS"
                      : item.OperatorId == null && item.UserId == null
                      ? "RPS"
                      : item.Object
                  }}: {{ item.LastMessage | truncateLastMessage }}
                </p>
              </div>
              <div class="table__col table__col_width_260">
                <div
                  class="sup-label-status"
                  :class="drawStatusColor(item.RealStatusId)"
                >
                  {{ getStatusMessage(item) }}
                  <template v-if="item.RealStatusId === '5'">
                    {{ item.Closed }}</template
                  >
                </div>
              </div>
              <div class="table__col table__col_flexible">
                <p class="table__text">
                  {{ item.LastMessageTime | formatDate }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <div class="table__row" v-else style="pointer-events: none">
          <div class="table__row table__row_main">
            <div class="table__col table__col_width_90">
              <div class="table__text-block">
                <p class="table__text"></p>
              </div>
            </div>
            <div class="table__col table__col_width_360">
              <div class="table__text-block">
                <p class="table__text">Данных нет</p>
              </div>
            </div>
          </div>
        </div>
        <div class="preloader-spinner" v-show="preloader">
          <Spinner name="wave" color="#c9eeff" width="60" height="60" />
        </div>
      </div>
    </div>

    <v-dialog
      v-model="isBlocked"
      max-width="440"
      class="client-dialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline">Внимание!</v-card-title>
        <v-card-text>
          Доступ в техническую поддержку ограничен. <br />
          Продлите договор на Техническое обслуживание. <br />
          Инфомарция по телефону:
          <a href="tel:88007079490">8 (800) 707-94-90</a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isBlockedSoon"
      max-width="530"
      class="client-dialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline">Внимание!</v-card-title>
        <v-card-text>
          С {{ blockDate }} доступ в техническую поддержку будет заблокирован.
          <br />
          Продлите договор на Техническое обслуживание. <br />
          Инфомарция по телефону:
          <a href="tel:88007079490">8 (800) 707-94-90</a>
        </v-card-text>
        <v-card-actions>
          <button
            class="btn"
            @click="isBlockedSoon = false"
            style="margin: auto"
          >
            Закрыть
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isBlockedInSomeDaysPopup"
      max-width="530"
      class="client-dialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline">Внимание!</v-card-title>
        <br />
        <v-card-text>
          Доступ к технической поддержке будет ограничен через
          <b>{{ daysLeftToBlock }} {{ numWord(daysLeftToBlock) }}</b
          >. <br /><br />

          Проверьте и продлите при необходимости срок действия имеющегося
          договора на Техническое (Сервисно) обслуживание.
          <br /><br />

          Для продления договора необходимо обратиться в коммерческую службу РПС
          Сервис, тел.
          <a class="link" href="tel:+74951355735">+7 495 135 57 35</a> (доб. 4),
          почта
          <a class="link" href="mailto:service@r-p-s.ru">service@r-p-s.ru</a>
        </v-card-text>
        <v-card-actions>
          <button
            class="btn"
            @click="isBlockedInSomeDaysPopup = false"
            style="margin: auto"
          >
            Закрыть
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <portal to="client-support-header">
      <div
        class="header-notification"
        v-if="daysLeftToBlock <= 5 && daysLeftToBlock > 0"
      >
        Внимание! Доступ к поддержке будет ограничен через
        {{ daysLeftToBlock }} {{ numWord(daysLeftToBlock) }}.
        <span
          class="header-notification__btn"
          @click="isBlockedInSomeDaysPopup = true"
        >
          Подробнее...</span
        >
      </div>
    </portal>

    <new-request :is_open="popups.client.newRequest" :type="'client'" />
    <request-window :is_open="popups.client.requestWindow" />

    <!--    <div-->
    <!--      class="notification-alert-block"-->
    <!--      :class="{ 'notification-alert-block&#45;&#45;close': !alertBlockOpen }"-->
    <!--    >-->
    <!--      <div class="notification-alert-block__title">-->
    <!--        Внимание! Технические работы-->
    <!--      </div>-->
    <!--      <div class="notification-alert-block__text">-->
    <!--        С 09.07.2021 по 12.07.2021 пройдут технические работы.-->
    <!--      </div>-->
    <!--      <div-->
    <!--        class="notification-alert-block__text notification-alert-block_hidden"-->
    <!--      >-->
    <!--        Могут возникать перебои с работой модуля «Поддержка».-->
    <!--      </div>-->
    <!--      <div-->
    <!--        class="notification-alert-block__btn notification-alert-block_hidden"-->
    <!--        @click="alertBlockOpen = false"-->
    <!--      >-->
    <!--        Все понятно-->
    <!--      </div>-->
    <!--    </div>-->

    <div
      class="notification-alert-block"
      :class="{ 'notification-alert-block--close': !alertBlockOpen }"
      style="width: 400px; text-align: center"
      v-if="alertBlockOpen"
    >
      <div class="notification-alert-block__title">
        Режим работы с 31 декабря по 9 января
      </div>
      <div class="notification-alert-block__text" style="margin-bottom: 10px">
        31 декабря и 1 января выходной. <br />
        Со 2 января по 9 января работаем с заявками <br />в режиме выходного
        дня.
      </div>
      <div
        class="notification-alert-block__text notification-alert-block_hidden"
        style="margin-bottom: 10px"
      >
        С наступающим новым годом! <br />🐯🎄🎉
      </div>
      <div
        class="notification-alert-block__btn notification-alert-block_hidden"
        @click="closeAlertBlock('new_year_notification')"
      >
        Хорошо
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState, mapGetters } from "vuex";
import { API_ROOT } from "@/config";
import { clientTestCookies } from "@/utils/credentials";
import * as c from "@/store/support/constants";
import * as commonTypes from "@/store/common/constants";
import NewRequest from "../components/client/NewRequest";
import RequestWindow from "../components/client/RequestWindow";
import ColorOfStatus from "@/mixins/colorOfStatus";
import Spinner from "vue-spinkit";
import moment from "moment";
import { getCookie, numWord } from "@/utils";
import * as _ from "lodash";

export default {
  mixins: [ColorOfStatus],
  data() {
    return {
      isBlocked: false,
      isBlockedSoon: false,
      isBlockedInSomeDaysPopup: false,
      daysLeftToBlock: null,
      blockDate: 0,
      filterWindows: {
        messages: false,
        status: false,
      },
      alertBlockOpen: false,
    };
  },
  created() {
    // const newYearNotification = this.$localStorage.get("new_year_notification");
    // if (!newYearNotification) this.alertBlockOpen = true;
  },
  computed: {
    filteredStatuses() {
      return _.filter(this.supportData.StatusId, (name, id) => +id !== 5);
    },
    ...mapState("clientSupport", [
      "requestFilters",
      "activeTabs",
      "requests",
      "supportData",
    ]),
    ...mapState("common", ["popups", "preloader"]),
    ...mapGetters("clientSupport", ["activeTab", "filteredRequests"]),
  },
  methods: {
    ...mapActions("clientSupport", [
      c.TOGGLE_COUNT_INTERVAL,
      c.GET_COUNT,
      c.SET_COUNT,
      c.GET_REQUESTS,
      c.GET_SUPPORT_DATA,
      c.SET_SELECTED_REQUEST,
      c.GET_USER_ROLES,
      c.GET_PIN,
    ]),
    ...mapActions("clientSupport", { changeFilter: c.CHANGE_REQUEST_FILTERS }),
    ...mapActions("common", [commonTypes.SET_CLIENT_AUTH]),

    selectRequest(request) {
      this[c.SET_SELECTED_REQUEST]({ request, requestId: request.Id });
    },
    getStatusMessage(item) {
      let message =
        item.RealStatusId === 5 ? "Закрыта " + item.Closed : item.Status;

      return message;
    },
    getFilterValue(filter) {
      if (filter === "activity") {
        const name = this.requestFilters[filter] === "desc" ? "1-9" : "9-1";
        const value = this.requestFilters[filter] === "desc" ? "asc" : "desc";
        return [name, value];
      }
    },
    numWord(num) {
      return numWord(num, ["день", "дня", "дней"]);
    },
    closeAlertBlock(notificationType) {
      this.$localStorage.set(notificationType, true);
      this.alertBlockOpen = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    // <--Check only client auth |____f
    // ||-------------------------------------------------
    document.querySelector("#app").classList.add("app--load");

    const stopPreloader = () => {
      document.querySelector("#app").classList.remove("app--load");
    };
    axios
      .get(`${API_ROOT}clientsupport/api/Request/Count`)
      .then((countRes) => {
        next((vm) => {
          vm[c.GET_USER_ROLES](getCookie("UserId"));
          axios
            .get(`${API_ROOT}clientsupport/api/ObjectSettings/getSettings`)
            .then((res) => {
              if (res.data && res.data.status === "success") {
                const now = moment();
                const date = moment(res.data.info.ServiceDateValidTo);
                // const daysLeftDate = moment(res.data.info.FreeServiceDate);
                vm.daysLeftToBlock = date.diff(now, "days");

                if (res.data.info.ServiceType == "5" || now < date) {
                  vm[c.TOGGLE_COUNT_INTERVAL](true);
                  vm[c.SET_COUNT](countRes.data);
                  vm[commonTypes.SET_CLIENT_AUTH](true);
                  vm[c.GET_COUNT](); // включаем интервал
                  vm[c.GET_SUPPORT_DATA](); // забираем настройки
                  vm[c.GET_REQUESTS]({ scope: vm.activeTab, start: true }); // забираем заявки
                  vm[c.GET_PIN]();
                } else {
                  vm.isBlocked = true;
                }
                if (res.data.info.ServiceType == "0") {
                  const now = moment();
                  const date = moment(res.data.info.FreeServiceDate);
                  if (now > date) {
                    vm.isBlocked = true;
                  } else {
                    vm.isBlockedSoon = true;
                    vm.blockDate = moment(res.data.info.FreeServiceDate)
                      .locale("ru")
                      .format("DD-MM-YYYY");
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch(() => {
        // TEST LOGIN FOR DEV
        if (process.env.NODE_ENV === "development") {
          clientTestCookies.split(";").forEach((i) => {
            document.cookie = i;
          });
          stopPreloader();
          return next();
        }
        alert("Вы не авторизованны");
        stopPreloader();
        next(false);
        window.location.href = "/";
      })
      .finally(() => {
        stopPreloader();
      });
    // ||-----------------------------------------------/>
  },
  beforeRouteLeave(to, from, next) {
    this[c.TOGGLE_COUNT_INTERVAL](false);
    this[commonTypes.SET_CLIENT_AUTH](false);
    next();
  },
  filters: {
    truncateLastMessage(message) {
      if (message) {
        if (message.length > 50) message = message.substr(0, 50) + "...";
        return message;
      }
    },
    formatDate(date) {
      return `${moment(date).format("DD.MM.YYYY")} в ${moment(date).format(
        "HH:mm"
      )}`;
    },
    truncateFilterName(name) {
      if (name.length > 5) name = name.substr(0, 3) + "...";
      return name;
    },
  },
  components: { NewRequest, RequestWindow, Spinner },
};
</script>

<style scoped lang="scss">
.client-dialog {
  .v-card {
    padding: 22px 0 40px;
  }
  .v-card__title {
    justify-content: center;
  }
  .v-card__text {
    text-align: center;
    font-size: 14px;
    line-height: 1.6;
  }
}
.notification-alert-block {
  position: fixed;
  right: 30px;
  bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 18px;
}
.notification-alert-block--close {
  padding: 11px 18px;

  .notification-alert-block__title {
    margin-bottom: 0;
  }
  .notification-alert-block_hidden {
    display: none;
  }
}
.notification-alert-block__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 13px;
}
.notification-alert-block__text {
  font-size: 13px;
  line-height: 24px;
  color: #000000;
}
.notification-alert-block__btn {
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.8125px;
  text-transform: uppercase;
  color: #202325;
  background: #f6f6f6;
  border: 1px solid #d2d3d3;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), inset 0px 2px 0px #ffffff;
  border-radius: 3px;
  height: 40px;
  line-height: 38px;
  margin-top: 15px;
  cursor: pointer;
}
</style>
