import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import * as c from "@/store/sales/constants";
import { API_ROOT } from "@/config";
import {
  copy,
  modulesArray,
  ObjectTypes,
  optionsArray,
  randomId,
} from "./SalesJSONS";
import * as axios from "axios";
import { showAlert } from "../../utils";
import * as _ from "lodash";
import MagnificPopupModal from "@/modules/common/popupImage/MagnificPopup";

export const SalesHelpers = {
  data() {
    return {
      MODULE_PAGE_LIMIT: 13,
      MODULE_PAGE_LIMIT_ADDITIONAL_DEVICES: 16,
      TOTAL_PAGE_LIMIT: 4,
    };
  },
  methods: {
    checkFileExt(fileName) {
      const imagesExts = ["jpeg", "jpg", "png", "bmp", "gif"];
      const ext = fileName.split(".").pop();
      return imagesExts.includes(ext.toLowerCase());
    },
    saveSettings() {
      this[c.SAVE_SETTINGS]();
    },
    saveDeviceImage(input, cat, module, id) {
      const fileList = input.files;
      let moduleName = module;
      let imagesArrayName = "img";

      if (module.includes("_rack")) {
        imagesArrayName = "img_rack";
        moduleName = module.replace("_rack", "");
      }
      if (module.includes("_barrier")) {
        imagesArrayName = "img_barrier";
        moduleName = module.replace("_barrier", "");
      }

      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        this.settingsObject.json.devices[cat][moduleName][
          imagesArrayName
        ] = this.settingsObject.json.devices[cat][moduleName][
          imagesArrayName
        ].map((i) => {
          if (i.id === id) {
            this.settingsObject.images.devices[cat + "_" + module].push({
              id,
              file: fileList[0],
            });
            this.settingsObject.blobImages.devices[cat + "_" + module].push({
              id,
              file: fileList[0],
            });
            const blob = window.URL.createObjectURL(fileList[0]);
            i.blob = blob;
            i.img = fileList[0].name;
          }
          return i;
        });

        input.value = null;
      }
    },
    actionDevice(action, cat, module, type, id, is_device = false) {
      if (action === "add") {
        const newItem = {
          id: this.settingsObject.json.devices[cat][module][type].length + 1,
          name: "",
          cost: "",
          convertCost: "",
          desc: "",
          randomId: randomId(),
          count: 1,
        };
        if (type === "rack" || type === "barrier") {
          newItem.is_device = is_device;
        }
        if (
          this.config.system === "economy" &&
          module === "additional" &&
          type === "options"
        ) {
          newItem.is_device = is_device;
        }
        this.settingsObject.json.devices[cat][module][type].push(newItem);
      } else if (action === "remove") {
        this.settingsObject.json.devices[cat][module][type] = _.reject(
          this.settingsObject.json.devices[cat][module][type],
          { id }
        );
        this.settingsObject.json.devices[cat][module][type] = _.each(
          this.settingsObject.json.devices[cat][module][type],
          (item, index) => {
            item.id = index + 1;
            return item;
          }
        );
      }
    },
    getimgStaticSrc(src) {
      return `${API_ROOT}/static/images/${src}`;
    },
    getImageSrc(img, system) {
      if (img) {
        if (system && system === "economy") {
          return `${API_ROOT}uploads/offers_economy/${img}`;
        } else if (system === "qr") {
          return `${API_ROOT}uploads/offers_qr/${img}`;
        } else {
          return `${API_ROOT}uploads/offers/${img}`;
        }
      }
    },
    getImageOfferSrc(img) {
      if (img) {
        return `${API_ROOT}uploads/com_offers_data/${img}`;
      }
    },
    imageBlobCheck(type, array, id) {
      if (type === "builder") {
        return _.find(this.offerObject.blobImages[array], { id });
      } else {
        if (type === "service") {
          return _.find(this.settingsObject.blobImages.service, { id });
        }
        if (type === "system") {
          return _.find(this.settingsObject.blobImages[array], { id });
        }
        return _.find(this.settingsObject.blobImages[type][array], { id });
      }
    },
    openForm(item) {
      item = item.closest(".tableInnerRow");
      item.classList.toggle("tableInnerRow--open");
    },
    getVirtualImageName(name, img, id) {
      if (img && typeof img !== "object") {
        return name + "_" + id + "." + img.split(".").pop();
      }
    },
    changeStep(step) {
      this.$emit("changeStep", step);
    },

    getAmount(module, sumType, option = false) {
      let amount = 0;
      _.each(optionsArray, (t) => {
        _.each(this.offerObject.json.Modules[module][t], (i) => {
          if (option && option !== t) return;
          if (this.offerObject.json.Modules[module][t] !== void 0) {
            if (sumType === "cost") {
              if (i.custom_sum !== void 0 && i.custom_sum) {
                amount += +i.custom_sum;
              } else {
                amount += +i.convertCost * i.count;
              }
            } else {
              const value = i.countAsOneKit
                ? +i[sumType]
                  ? 1
                  : 0
                : +i[sumType];

              amount += value;
            }
          }
        });
      });
      if (sumType === "cost") {
        return this.$options.filters.moneyFormat(amount);
      } else {
        return amount;
      }
    },
    getOptionSumPrice(item) {
      if (item.custom_sum !== void 0 && item.custom_sum) {
        return this.$options.filters.moneyFormat(item.custom_sum);
      } else {
        return this.$options.filters.moneyFormat(item.convertCost * item.count);
      }
    },
    getAllTotalOptions() {
      let amount = 0;
      if (this.offerObject.json.Total.Options) {
        _.each(this.offerObject.json.Total.Options.types, (i) => {
          if (i.disabled === void 0 ? true : !i.disabled) {
            amount++;
          }
        });
      }
      return amount || 1;
    },
    getAllModuleOptions(module) {
      let amount = -2;
      if (module === "Payment") {
        amount = -3;
      } else if (module === "Additional") {
        amount = -1;
      }
      _.each(optionsArray, (t) => {
        if (this.offerObject.json.Modules[module][t] !== void 0) {
          amount += this.offerObject.json.Modules[module][t].length;
        }
      });
      return amount;
    },
    checkModuleRowsLimit(module, limit, index, col) {
      if (module === "Entry" || module === "Exit") {
        if (col === "col_2") {
          // console.log(limit)
          limit =
            limit - (this.offerObject.json.Modules[module].rack.length - 1);
        }
        if (index <= limit) {
          // console.log(index, limit)
          this[col + "_is_full"] = false;
          return true;
        } else {
          // console.log(index, 's')
          this[col + "_is_full"] = true;

          return false;
        }
      } else if (module === "Payment") {
        if (col === "col_2") {
          limit =
            limit - (this.offerObject.json.Modules[module].cash_1.length - 1);
        } else if (col === "col_3") {
          limit =
            limit -
            (this.offerObject.json.Modules[module].cash_1.length -
              1 +
              ((this.offerObject.json.System !== "economy"
                ? this.offerObject.json.Modules[module].cash_2.length
                : 1) -
                1));
        }
        if (index <= limit) {
          this[col + "_is_full"] = false;
          return true;
        } else {
          this[col + "_is_full"] = true;
          return false;
        }
      } else {
        return index <= limit;
      }
    },
    getRowOffset(module, limit, index, pageNum, col) {
      if (module === "Payment") {
        let offset = (pageNum - 1) * limit;
        if (col === "col_2") {
          offset -= this.offerObject.json.Modules[module].cash_1.length - 1;
          if (pageNum === 2) {
            // console.log(index >= offset && index <= limit * pageNum - this.offerObject.json.Modules[module].cash_1.length + 1, 'num-', pageNum, '====', index, offset, index, limit * pageNum - this.offerObject.json.Modules[module].cash_1.length)
          }
          return (
            index > offset &&
            index <=
              limit * pageNum -
                this.offerObject.json.Modules[module].cash_1.length +
                1
          );
        } else if (col === "col_3") {
          offset -=
            this.offerObject.json.Modules[module].cash_1.length -
            1 +
            ((this.offerObject.json.System !== "economy"
              ? this.offerObject.json.Modules[module].cash_2.length
              : 1) -
              1);
          // console.log('sss', (this.offerObject.json.Modules[module].cash_1.length - 1) + (this.offerObject.json.Modules[module].cash_2.length - 1))

          if (pageNum === 2) {
            if (
              !(
                index > offset &&
                index <=
                  limit * pageNum -
                    (this.offerObject.json.Modules[module].cash_1.length -
                      1 +
                      ((this.offerObject.json.System !== "economy"
                        ? this.offerObject.json.Modules[module].cash_2.length
                        : 1) -
                        1))
              )
            ) {
              // console.log(index, index > offset && index <= limit * pageNum - ((this.offerObject.json.Modules[module].cash_1.length + 1) + (this.offerObject.json.Modules[module].cash_2.length + 1)))
              // console.log(index,limit * pageNum - ((this.offerObject.json.Modules[module].cash_1.length + 1) + (this.offerObject.json.Modules[module].cash_2.length + 1)), index <= limit * pageNum - ((this.offerObject.json.Modules[module].cash_1.length + 1) + (this.offerObject.json.Modules[module].cash_2.length + 1)))
              // console.log(limit * pageNum, ((this.offerObject.json.Modules[module].cash_1.length - 1) + (this.offerObject.json.Modules[module].cash_2.length - 1)))
            }
          }
          return (
            index > offset &&
            index <=
              limit * pageNum -
                (this.offerObject.json.Modules[module].cash_1.length -
                  1 +
                  ((this.offerObject.json.System !== "economy"
                    ? this.offerObject.json.Modules[module].cash_2.length
                    : 1) -
                    1))
          );
        }
        return index > offset && index <= limit * pageNum;
      } else {
        let offset = (pageNum - 1) * limit;
        if (col === "col_2") {
          offset -= this.offerObject.json.Modules[module].rack.length - 1;
          if (pageNum === 2) {
            // console.log(index >= offset && index <= limit * pageNum - this.offerObject.json.Modules[module].rack.length + 1, 'num-', pageNum, '====', index, offset, index, limit * pageNum - this.offerObject.json.Modules[module].rack.length)
            // console.log(index > offset, index, offset)
          }
          return (
            index > offset &&
            index <=
              limit * pageNum -
                this.offerObject.json.Modules[module].rack.length +
                1
          );
        }
        return index > offset && index <= limit * pageNum;
      }
    },
    checkCol1Limit(module, limit, pageNum, col) {
      if (module === "Payment") {
        let allOptions = this.offerObject.json.Modules[module].cash_1.length;
        if (col === "col_3") {
          allOptions =
            this.offerObject.json.Modules[module].cash_1.length +
            (this.offerObject.json.System !== "economy"
              ? this.offerObject.json.Modules[module].cash_2.length
              : 0);
        }
        return allOptions <= limit * pageNum;
      } else {
        const allOptions = this.offerObject.json.Modules[module].rack.length;
        return allOptions <= limit * pageNum;
      }
    },
    checkCol12TitleNeed(module, limit, pageNum) {
      if (module === "Payment") {
        const allOptions = this.offerObject.json.Modules[module].cash_1.length;
        if (allOptions <= limit * pageNum) {
          window.offerModuleTitles[module]++;
        }
        return (
          this.col_1_is_full &&
          allOptions <= limit * pageNum &&
          window.offerModuleTitles[module] === 1
        );
      } else {
        const allOptions = this.offerObject.json.Modules[module].rack.length;
        if (allOptions <= limit * pageNum) {
          window.offerModuleTitles[module]++;
        }
        // return this.col_1_is_full && allOptions <= limit * pageNum && window.offerModuleTitles[module] === 1
        return this.col_1_is_full && allOptions <= limit * pageNum;
      }
    },
    checkCol13TitleNeed(module, limit, pageNum) {
      const allOptions =
        this.offerObject.json.Modules[module].cash_1.length +
        (this.offerObject.json.System !== "economy"
          ? this.offerObject.json.Modules[module].cash_2.length
          : 0);
      if (allOptions <= limit * pageNum) {
        window.offerModuleTitles.Payment3++;
      }
      // console.log(allOptions)
      // console.log(limit * pageNum)
      return (
        (this.col_1_is_full || this.col_2_is_full) &&
        allOptions <= limit * pageNum &&
        window.offerModuleTitles.Payment3 === 1
      );
    },
    changeCount(option, method, module, event = false) {
      // const limit = isMainOption ? 1 : 0
      const limit = 0;

      if (method === "minus") {
        if (option.count > limit) {
          option.count--;
        }
      } else if (method === "input") {
        const value = +event.target.value;
        // if (value > 0) {
        option.count = value;
        // }
      } else {
        option.count++;
      }
      if (["Entry_2", "Exit_2"].includes(module)) {
        const mainDevices = _.filter(
          this.offerObject.json.Modules[module.replace(/[^A-Za-z]+/g, "")]
            .barrier,
          ["is_device", true]
        );
        let count = 0;
        _.each(mainDevices, (i) => {
          count += i.count;
        });
        this.offerObject.json.passPages[
          module.replace(/[^A-Za-z]+/g, "").toLowerCase() + "_barrier"
        ] = !count;
      }

      if (["Entry", "Exit"].includes(module)) {
        const mainDevices = _.filter(
          this.offerObject.json.Modules[module].rack,
          ["is_device", true]
        );
        let count = 0;
        _.each(mainDevices, (i) => {
          count += i.count;
        });
        this.offerObject.json.passPages[
          module.toLowerCase() + "_rack"
        ] = !count;
      }
      if (module === "Payment") {
        const mainDevices = [];
        mainDevices.push(this.offerObject.json.Modules.Payment.cash_1[0]);
        if (this.offerObject.json.System !== "economy") {
          mainDevices.push(this.offerObject.json.Modules.Payment.cash_2[0]);
          mainDevices.push(this.offerObject.json.Modules.Payment.cash_4[0]);
        }
        mainDevices.push(this.offerObject.json.Modules.Payment.cash_3[0]);

        let count = 0;
        _.each(mainDevices, (i) => {
          if (i) count += i.count;
        });
        this.offerObject.json.passPages.payment = !count;
      }

      if (["Additional"].includes(module)) {
        const mainDevices = this.offerObject.json.Modules.Additional.options;
        let count = 0;
        _.each(mainDevices, (i) => {
          count += i.count;
        });
        this.offerObject.json.passPages.additional = !count;
      }

      this.getServiceTypePrice();
    },
    getOptionPrice(module, option, index) {
      let foundOption = this.offerObject.json.Modules[module][option][index];
      foundOption = foundOption
        ? foundOption
        : _.find(this.offerObject.json.Modules[module][option], [
            "randomId",
            index,
          ]);
      if (foundOption) {
        const count = foundOption.count;
        const price = foundOption.convertCost;
        const sum = count ? price * count : price;
        return this.$options.filters.moneyFormat(sum);
      }
    },
    openOptionSettings(el) {
      const drop = el
        .closest(".p-settings__item")
        .querySelector(".p-settings__drop");
      document.querySelectorAll(".p-settings__drop").forEach((i) => {
        if (i !== drop) {
          i.classList.remove("p-settings__drop--open");
        }
      });
      if (
        el.classList[0] === "p-settings__cont" ||
        el.closest(".p-settings__name-wrap")
      ) {
        drop.classList.toggle("p-settings__drop--open");
        if (drop.querySelector(".form-row__input")) {
          drop.querySelector(".form-row__input").focus();
        }
      }
    },
    validateAllDevicePriceDiscont() {
      if (this.offerObject.json.totalDevicesPriceType === void 0) {
        this.offerObject.json.totalDevicesPriceType = "percent";
      }
      if (this.offerObject.json.totalDevicesPriceDiscont.toString()[0] === "0")
        this.offerObject.json.totalDevicesPriceDiscont = "";
      if (this.offerObject.json.totalDevicesPriceType === "percent") {
        this.offerObject.json.totalDevicesPriceDiscont =
          this.offerObject.json.totalDevicesPriceDiscont <= 0
            ? ""
            : this.offerObject.json.totalDevicesPriceDiscont > 100
            ? 100
            : this.offerObject.json.totalDevicesPriceDiscont;
      } else {
        this.offerObject.json.totalDevicesPriceDiscont =
          this.offerObject.json.totalDevicesPriceDiscont <= 0
            ? ""
            : this.offerObject.json.totalDevicesPriceDiscont;
      }
    },
    validateDiscont(e, item) {
      if (item.discont_type === void 0) {
        item.discont_type = "percent";
      }
      if (item.discont_number.toString()[0] === "0") item.discont_number = "";
      if (item.discont_type === "percent") {
        item.discont_number =
          item.discont_number <= 0
            ? ""
            : item.discont_number > 100
            ? 100
            : item.discont_number;
      } else {
        item.discont_number =
          item.discont_number <= 0 ? "" : item.discont_number;
      }
      this.setDiscont(item);
    },
    setDiscont(item) {
      item.initial_cost =
        item.initial_cost === void 0 ? item.convertCost : item.initial_cost;

      const price =
        item.single_cost === void 0 || !item.single_cost
          ? item.initial_cost
          : item.single_cost;
      if (item.discont_number !== void 0 && item.discont_number !== "") {
        if (item.discont_type === "percent") {
          const percentage = (+price * +item.discont_number) / 100;
          item.convertCost = price - percentage;
        } else {
          if (+item.discont_number <= +price) {
            item.convertCost = price - item.discont_number;
          } else {
            item.convertCost = 0;
          }
        }
      } else {
        item.convertCost = price;
      }
    },
    setSingleCost(item) {
      item.initial_cost =
        item.initial_cost === void 0 ? item.convertCost : item.initial_cost;

      item.single_cost =
        item.single_cost < 0 || item.single_cost.toString()[0] === "0"
          ? "0"
          : item.single_cost;
      if (!item.single_cost) {
        item.convertCost = item.initial_cost;
      } else {
        item.convertCost = item.single_cost;
      }
      item.discont_number = "";
    },
    setCustomSum(item) {
      item.custom_sum =
        item.custom_sum === void 0
          ? item.convertCost * item.count
          : item.custom_sum;
      // item.custom_sum = item.custom_sum <= 0 ?
    },
    changeDiscontType(item, type) {
      item.discont_type = type;
      const name = item.name;
      if (item.discont_number !== void 0) {
        item.discont_number =
          item.discont_number > 100 ? 100 : item.discont_number;
      }
      this.setDiscont(item);
      item.name = "";
      item.name = name;
    },
    cancelSetOptions(item) {
      if (item.initial_cost) {
        item.convertCost = item.initial_cost;
        item.discont_number = void 0;
        item.custom_sum = void 0;
        item.single_cost = void 0;
      }

      const name = item.name;
      item.name = "";
      item.name = name;
    },
    closeSettingsOption(el) {
      el.closest(".p-settings__item")
        .querySelector(".p-settings__drop")
        .classList.remove("p-settings__drop--open");
    },
    getObjectTypeName(type) {
      return ObjectTypes[type];
    },
    navCountPage(page) {
      if (["Additional"].includes(page)) {
        const pages = this.pageNums.PaymentLastPage;

        let pageCount = this.pageNums.Payment;

        if (this.offerObject.json.passPages.additional) {
          return "---";
        }
        this.pageNums.Additional = pageCount + pages;

        if (pages === 1) {
          return pageCount + 1;
        } else {
          return `${pageCount + 1} - ${pageCount + pages}`;
        }
      } else {
        if (page === "Schemes") {
          let pageCount = this.offerObject.json.passPages.tasks_page
            ? 3
            : this.pageNums.TasksAdditionalPage
            ? 5
            : 4;

          if (this.offerObject.json.passPages.schemes) {
            this.pageNums.Schemes = pageCount;

            return "---";
          }
          const pages = this.offerObject.json.Schemes.length
            ? this.offerObject.json.Schemes.length
            : 1;

          if (pages === 1) {
            this.pageNums.Schemes = pageCount + 1;
            return pageCount + 1;
          } else {
            this.pageNums.Schemes = pageCount + pages;
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Entry") {
          const pages = this.pageNums.EntryLastPage;

          let pageCount = this.pageNums.Schemes + 1;

          if (this.offerObject.json.passPages.entry_rack) {
            this.pageNums.Entry = pageCount;
            return "---";
          }
          this.pageNums.Entry = pageCount + pages;

          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Entry_2") {
          const pages = this.pageNums.Entry_2LastPage;

          let pageCount = this.pageNums.Entry;

          if (this.offerObject.json.passPages.entry_barrier) {
            this.pageNums.Entry_2 = pageCount;
            return "---";
          }

          this.pageNums.Entry_2 = pageCount + pages;
          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Exit") {
          const pages = this.pageNums.ExitLastPage;

          let pageCount = this.pageNums.Entry;
          if (this.offerObject.json.passPages.exit_rack) {
            this.pageNums.Exit = pageCount;
            return "---";
          }
          this.pageNums.Exit = pageCount + pages;
          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Exit_2") {
          let pages = this.pageNums.Exit_2LastPage;

          let pageCount = this.pageNums.Exit;
          if (this.offerObject.json.SystemType === "Tokensdbl") {
            pages = this.pageNums.Entry_2LastPage;
            pageCount = this.pageNums.Entry_2;
          }

          if (this.offerObject.json.passPages.exit_barrier) {
            this.pageNums.Exit_2 = pageCount;
            return "---";
          }
          this.pageNums.Exit_2 = pageCount + pages;

          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Payment") {
          const pages = this.pageNums.PaymentLastPage;

          let pageCount = this.pageNums.Exit_2;
          if (this.offerObject.json.passPages.payment) {
            this.pageNums.Payment = pageCount;
            return "---";
          }
          this.pageNums.Payment = pageCount + pages;
          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Total") {
          const pages = Math.ceil(
            this.getAllTotalOptions() / this.TOTAL_PAGE_LIMIT
          );

          let pageCount = this.pageNums.Additional;
          if (this.offerObject.json.passPages.additional) {
            pageCount = this.pageNums.Payment;
          }
          this.pageNums.Total = pageCount + pages;

          if (pages === 1) {
            return pageCount + 1;
          } else {
            return `${pageCount + 1} - ${pageCount + pages}`;
          }
        } else if (page === "Options") {
          if (!this.offerObject.json.passPages.option_desc) {
            if (this.pageNums.Options === 1) {
              this.pageNums.OptionsLastPage = this.pageNums.Service + 1;
              return this.pageNums.Service + 1;
            } else {
              this.pageNums.OptionsLastPage =
                this.pageNums.Service + this.pageNums.Options;
              return `${this.pageNums.Service + 1} - ${
                this.pageNums.OptionsLastPage
              }`;
            }
          } else {
            this.pageNums.OptionsLastPage = this.pageNums.Service;
            return "---";
          }
        } else if (page === "Objects") {
          if (this.objectPageLength === 1) {
            this.pageNums.Objects = this.pageNums.OptionsLastPage + 2;
            return this.pageNums.OptionsLastPage + 2;
          } else {
            this.pageNums.Objects =
              this.pageNums.OptionsLastPage + 1 + this.objectPageLength;

            return `${this.pageNums.OptionsLastPage + 2} - ${
              this.pageNums.OptionsLastPage + 1 + this.objectPageLength
            }`;
          }
        } else if (page === "Advantages") {
          if (!this.offerObject.json.passPages.ad_tenant) {
            this.pageNums.Advantages = this.pageNums.Total + 4;
            return this.pageNums.Advantages;
          } else {
            this.pageNums.Advantages = this.pageNums.Total + 2;
            return "---";
          }
        } else if (page === "ServiceDesc") {
          if (!this.offerObject.json.passPages.service) {
            return this.pageNums.Advantages + 1;
          } else {
            return "---";
          }
        } else if (page === "ServiceImg") {
          if (!this.offerObject.json.passPages.service) {
            this.pageNums.Service = this.pageNums.Advantages + 2;
            this.pageNums.OptionsLastPage = this.pageNums.Service;
            return this.pageNums.Service;
          } else {
            this.pageNums.Service = this.pageNums.Advantages;
            this.pageNums.OptionsLastPage = this.pageNums.Service;
            return "---";
          }
        }
      }
    },
    countPage(page, pageIndex) {
      let pageCount = this.offerObject.json.passPages.tasks_page
        ? 3
        : this.pageNums.TasksAdditionalPage
        ? 5
        : 4;
      if (page === "Schemes") {
        return pageCount + pageIndex;
      } else if (page === "Entry") {
        pageCount = this.pageNums.Schemes + 1;

        // if (this.offerObject.json.passPages.schemes) {
        //   pageCount = 4
        // }
        return pageCount + pageIndex;
      } else if (page === "Entry_2") {
        return this.pageNums.Entry + pageIndex;
      } else if (page === "Exit") {
        return this.pageNums.Entry + pageIndex;
      } else if (page === "Exit_2") {
        if (this.offerObject.json.SystemType === "Tokensdbl") {
          return this.pageNums.Entry_2 + pageIndex;
        } else {
          return this.pageNums.Exit + pageIndex;
        }
      } else if (page === "Payment") {
        return this.pageNums.Exit_2 + pageIndex;
      } else if (page === "Additional") {
        return this.pageNums.Payment + pageIndex;
      } else if (page === "Options") {
        return this.pageNums.Service + pageIndex;
      } else if (page === "Objects") {
        return this.pageNums.OptionsLastPage + 1 + pageIndex;
      } else if (page === "Total") {
        if (this.offerObject.json.passPages.additional) {
          return this.pageNums.Payment + pageIndex;
        }
        return this.pageNums.Additional + pageIndex;
      }
    },
    getSystemType(system) {
      switch (system) {
        case 0:
          return "Все";
        case 1:
          return "Жетоны";
        case 2:
          return "Билеты";
        case 3:
          return "Карты";
        case 4:
          return "Жетоны (двстр)";
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "Все";
        case 1:
          return "На проверку";
        case 2:
          return "Одобрено";
        case 3:
          return "Доработать";
        case 4:
          return "В процессе";
      }
    },
    getStatusClassName(status) {
      switch (status) {
        case 1:
          return "badge--warning";
        case 2:
          return "badge--success";
        case 3:
          return "badge--red";
      }
    },
    showPreview(page, systemType, objectType) {
      const type = systemType
        ? systemType[0].toUpperCase() + systemType.slice(1)
        : "Cards";

      this.offerObject.json.SystemType = type;
      this.offerObject.json.SystemImg = copy(
        this.settingsObject.json[type.toLowerCase() + "Img"]
      );

      _.each(modulesArray, (module) => {
        this.offerObject.json.Modules[module] = copy(
          this.settingsObject.json.devices[type.toLowerCase()][
            module.toLowerCase()
          ]
        );
        if (["Entry", "Exit"].includes(module)) {
          this.offerObject.json.Modules[
            module
          ].img_rack = this.settingsObject.json.devices[type.toLowerCase()][
            module.toLowerCase()
          ].img_rack[0].img;
          this.offerObject.json.Modules[
            module
          ].img_barrier = this.settingsObject.json.devices[type.toLowerCase()][
            module.toLowerCase()
          ].img_barrier[0].img;
        } else {
          this.offerObject.json.Modules[
            module
          ].img = this.settingsObject.json.devices[type.toLowerCase()][
            module.toLowerCase()
          ].img[0].img;
        }
      });
      this.offerObject.json.ObjectType = objectType ? objectType : "tc";
      this.offerObject.json.OptionsText = copy(
        this.settingsObject.json.options
      );
      this.offerObject.json.DoneWorks = copy(
        this.settingsObject.json.doneWorks
      );

      this.changeOfferByObjectType(this.offerObject.json.ObjectType);
      this.offerObject.json.Total.Options = copy(
        this.settingsObject.json.total
      );
      this.offerObject.json.Total.Delivery = copy(
        this.settingsObject.json.total.delivery[0].name
      );
      this.offerObject.json.Total.Payment = copy(
        this.settingsObject.json.total.payment[0].name
      );
      this.offerObject.json.ContentText = copy(
        this.settingsObject.json.content
      );
      this.offerObject.json.Service = copy(this.settingsObject.json.service);

      this[c.CONVERT_TO_RUB]({
        newValue: this.rateValue,
        oldValue: this.rateValue,
      });
      this.makeOptionsQuery("Entry", "rack");
      this.makeOptionsQuery("Entry_2", "barrier");
      this.makeOptionsQuery("Exit", "rack");
      this.makeOptionsQuery("Exit_2", "barrier");
      this.makeOptionsQuery("Payment", "cash_1");
      this.getServiceTypePrice();

      this.getLastTasksText;
      this.optionPages;
      this.objectPageLength;

      this.print.templatesPreview = true;
      this.print.pageForPreview = page;
    },
    changeOfferByObjectType(objectType) {
      this.offerObject.json.AdvantagesOwner = copy(
        this.settingsObject.json.advantages_owner[objectType]
      );
      this.offerObject.json.AdvantagesGuest = copy(
        this.settingsObject.json.advantages_guest[objectType]
      );
      this.offerObject.json.AdvantagesTenant = copy(
        this.settingsObject.json.advantages_tenant[objectType]
      );
      // this.offerObject.json.Objects = copy(this.settingsObject.json.objects[objectType])
      this.offerObject.json.Tasks = copy(
        this.settingsObject.json.tasks[objectType]
      );
      this.offerObject.json.ObjectTypeString = this.getObjectTypeName(
        this.offerObject.json.ObjectType
      );
    },
    getObjectsTypeByTab(tab) {
      switch (tab) {
        case 1:
          return "tc";
        case 2:
          return "bc";
        case 3:
          return "zk";
        case 4:
          return "hotels";
        case 5:
          return "sport";
        case 6:
          return "air";
        case 7:
          return "parks";
        case 8:
          return "others";
      }
    },
    checkTotalRowsLimit(pageNum, index) {
      const addNumber = pageNum === 1 ? 0 : 6;
      let offset = (pageNum - 1) * (this.TOTAL_PAGE_LIMIT + addNumber);

      const offsetCondition = offset ? index >= offset : true;
      let limitCondition =
        index < (this.TOTAL_PAGE_LIMIT + addNumber) * pageNum;
      if (
        pageNum === 1 &&
        this.getAllTotalOptions() / this.TOTAL_PAGE_LIMIT > 1
      ) {
        limitCondition = index < 10;
      }
      // console.log(offsetCondition, index, '>=', offset)
      // console.log(index , this.TOTAL_PAGE_LIMIT * (pag eNum - 1) -  this.getAllTotalOptions())
      // console.log(limitCondition, index , '<', this.TOTAL_PAGE_LIMIT * pageNum, item.name)
      return offsetCondition && limitCondition;
    },
    downloadPdf(is_full, downloadBtnsShow = false) {
      this[c.TOGGLE_PRELOADER]({ type: "main", status: true });
      if (downloadBtnsShow) {
        this[downloadBtnsShow] = false;
      }
      this.print.full = is_full;
      this.print.preview = true;
      this.print.rendering = true;
      setTimeout(() => {
        const html = `<!DOCTYPE html>
  <html>
  <head>
    <title>RPS — Найстройка / Устройства / Устройство</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1">
    <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&amp;display=swap&amp;subset=cyrillic-ext"
          rel="stylesheet">
    <link
      href="https://fonts.googleapis.com/css?family=Exo+2:100,200,300,400,500,600,700,800&amp;display=swap&amp;subset=cyrillic"
      rel="stylesheet">
<!--    <link rel="stylesheet" href="http://dev-mc.r-p-s.ru/static/styles/print-pdf.css">-->
    <link rel="stylesheet" href="${API_ROOT}/static/styles/print-pdf.css">
  </head>
  <body>
${document.querySelector(".print-pdf-document").innerHTML}
  </body>
  </html>`;
        // console.log(html)

        axios({
          url: `${API_ROOT}api/sales/ComOffers/RenderPdf`,
          method: "POST",
          responseType: "blob", // important
          data: {
            html,
          },
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Коммерческое предложение для " +
                this.offerObject.json.Name +
                ".pdf"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            this[c.TOGGLE_PRELOADER]({ type: "main", status: false });
            this.print.full = true;
            this.print.rendering = false;
          });
      }, 500);
    },
    compareOfferWithNewTemplate(oldData) {
      // console.log('compare')
      this[c.RESET_COMPARE]();
      oldData = copy(oldData);
      const newData = copy(this.settingsObjectStandart); // ???
      const offerObjectType = oldData.ObjectType;
      const offerSystemType = oldData.SystemType;
      // console.log(newData.content === oldData.ContentText)
      if (newData.content !== oldData.ContentText) {
        this.compareObject.ContentText = newData.content;
      }
      if (newData.options !== oldData.OptionsText) {
        this.compareObject.OptionsText = newData.options;
      }

      function createArrayField(vm, module, option = false) {
        if (vm.compareObject.Modules === void 0) vm.compareObject.Modules = {};
        if (vm.compareObject.Modules[module] === void 0)
          vm.compareObject.Modules[module] = {};
        if (option) {
          if (vm.compareObject.Modules[module][option] === void 0)
            vm.compareObject.Modules[module][option] = [];
          if (vm.compareObject.Modules[module].lengthDiffrent === void 0)
            vm.compareObject.Modules[module].lengthDiffrent = [];
        }
      }

      function comparer(otherArray) {
        return function (current) {
          if (otherArray) {
            return (
              otherArray.filter(function (other) {
                return other.randomId == current.randomId;
              }).length == 0
            );
          } else {
            return [];
          }
        };
      }

      _.each(modulesArray, (module) => {
        const newDataModule =
          newData.devices[offerSystemType.toLowerCase()][module.toLowerCase()];
        const oldDataModule = oldData.Modules[module];
        if (["Entry", "Exit"].includes(module)) {
          if (newDataModule.img_rack[0].img !== oldDataModule.img_rack) {
            createArrayField(this, module);
            this.compareObject.Modules[module].img_rack =
              newDataModule.img_rack;
          }

          if (newDataModule.img_barrier[0].img !== oldDataModule.img_barrier) {
            // createArrayField(this, module)
            // console.log(1111, newDataModule.img_barrier[0].img , oldDataModule.img_barrier)

            if (this.compareObject[module + "_2"] === void 0)
              this.compareObject[module + "_2"] = {};
            this.compareObject[module + "_2"].img_barrier =
              newDataModule.img_barrier[0].img;
          }
        } else {
          if (newDataModule.img[0].img !== oldDataModule.img) {
            createArrayField(this, module);
            this.compareObject.Modules[module].img = newDataModule.img;
          }
        }
        _.each(optionsArray, (option) => {
          const newDataOptionArray =
            newData.devices[offerSystemType.toLowerCase()][
              module.toLowerCase()
            ][option];
          if (newDataOptionArray !== void 0) {
            const oldDataOptionArray = oldData.Modules[module][option];

            _.each(newDataOptionArray, (newOption) => {
              const oldOption = _.find(oldDataOptionArray, {
                randomId: newOption.randomId,
              });
              if (oldOption) {
                let costCondition = oldOption.cost !== newOption.cost;
                // if (oldOption.initial_cost !== void 0) {
                //   // costCondition = oldOption.initial_cost !== newOption.cost
                //   newOption.initial_cost = 999
                // }
                if (
                  oldOption.name !== newOption.name ||
                  oldOption.desc !== newOption.desc ||
                  costCondition
                ) {
                  // console.log(oldOption.name !== newOption.name , oldOption.desc !== newOption.desc , costCondition)

                  if (costCondition) {
                    // i.convertCost = (+i.cost * rateValue).toFixed()
                    // newOption.initial_cost = 999
                    // oldOption.initial_cost = 999
                    // console.log(oldOption.cost, newOption.cost)
                    // console.log('ыщ', oldOption, newOption)
                    // console.log('\n\n\n')
                  }

                  // console.log(oldOption.name !== newOption.name)
                  // console.log(111, oldOption.name, newOption.name)

                  // console.log(oldOption.desc !== newOption.cost)
                  // console.log(oldOption.desc !== newOption.cost)

                  createArrayField(this, module, option);
                  this.compareObject.Modules[module][option].push(newOption);
                }
              }
            });

            const removedOptions = [];
            const addedOptions = [];
            const onlyInA = newDataOptionArray.filter(
              comparer(oldDataOptionArray)
            );
            const onlyInB = oldDataOptionArray.filter(
              comparer(newDataOptionArray)
            );
            const diffOptions = onlyInA.concat(onlyInB);

            if (diffOptions.length) {
              _.each(diffOptions, (i) => {
                if (_.find(newDataOptionArray, ["randomId", i.randomId])) {
                  addedOptions.push(i);
                } else {
                  removedOptions.push(i);
                }
              });
            }
            if (diffOptions.length) {
              createArrayField(this, module, option);
              this.compareObject.Modules[module].lengthDiffrent.push({
                [option]: {
                  removedOptions,
                  addedOptions,
                },
              });
            }
          }
        });
      });

      _.each(["types", "delivery", "payment"], (module) => {
        _.each(newData.total[module], (newOption) => {
          const oldOption = _.find(oldData.Total.Options[module], {
            randomId: newOption.randomId,
          });
          if (oldOption) {
            const condition =
              module === "types"
                ? oldOption.name !== newOption.name ||
                  oldOption.cost !== newOption.cost
                : oldOption.name !== newOption.name;
            if (condition) {
              if (this.compareObject.Total === void 0)
                this.compareObject.Total = {};
              if (this.compareObject.Total[module] === void 0)
                this.compareObject.Total[module] = {};
              if (this.compareObject.Total[module].Options === void 0)
                this.compareObject.Total[module].Options = [];
              this.compareObject.Total[module].Options.push(newOption);
            }
          }
        });
        const newDataOptionArray = newData.total[module];
        const oldDataOptionArray = oldData.Total.Options[module];
        const removedOptions = [];
        const addedOptions = [];
        const onlyInA = newDataOptionArray.filter(comparer(oldDataOptionArray));
        const onlyInB = oldDataOptionArray.filter(comparer(newDataOptionArray));
        const diffOptions = onlyInA.concat(onlyInB);

        if (diffOptions.length) {
          _.each(diffOptions, (i) => {
            if (_.find(newDataOptionArray, ["randomId", i.randomId])) {
              addedOptions.push(i);
            } else {
              removedOptions.push(i);
            }
          });
        }
        if (diffOptions.length) {
          if (this.compareObject.Total === void 0)
            this.compareObject.Total = {};
          if (this.compareObject.Total[module] === void 0)
            this.compareObject.Total[module] = {};
          if (this.compareObject.Total[module].lengthDiffrent === void 0)
            this.compareObject.Total[module].lengthDiffrent = [];
          this.compareObject.Total[module].lengthDiffrent.push({
            [module]: {
              removedOptions,
              addedOptions,
            },
          });
        }
      });

      if (newData.tasks[offerObjectType] !== oldData.Tasks) {
        this.compareObject.Tasks = newData.tasks[offerObjectType];
      }

      _.each(newData.advantages_owner[offerObjectType], (newOption) => {
        const oldOption = _.find(oldData.AdvantagesOwner, {
          randomId: newOption.randomId,
        });
        if (oldOption) {
          if (
            oldOption.name !== newOption.name ||
            oldOption.desc !== newOption.desc ||
            oldOption.img !== newOption.img
          ) {
            if (this.compareObject.AdvantagesOwner === void 0)
              this.compareObject.AdvantagesOwner = {};
            if (this.compareObject.AdvantagesOwner.Options === void 0)
              this.compareObject.AdvantagesOwner.Options = [];

            this.compareObject.AdvantagesOwner.Options.push(newOption);

            // console.log(oldOption.name, oldOption.desc)
          }
        }
      });
      const newDataOptionArray = newData.advantages_owner[offerObjectType];
      const oldDataOptionArray = oldData.AdvantagesOwner;
      const removedOptions = [];
      const addedOptions = [];
      const onlyInA = newDataOptionArray.filter(comparer(oldDataOptionArray));
      const onlyInB = oldDataOptionArray.filter(comparer(newDataOptionArray));
      const diffOptions = onlyInA.concat(onlyInB);

      if (diffOptions.length) {
        _.each(diffOptions, (i) => {
          if (_.find(newDataOptionArray, ["randomId", i.randomId])) {
            addedOptions.push(i);
          } else {
            removedOptions.push(i);
          }
        });
      }
      if (diffOptions.length) {
        if (this.compareObject.AdvantagesOwner === void 0)
          this.compareObject.AdvantagesOwner = {};
        if (this.compareObject.AdvantagesOwner.Options === void 0)
          this.compareObject.AdvantagesOwner.Options = [];
        if (this.compareObject.AdvantagesOwner.lengthDiffrent === void 0)
          this.compareObject.AdvantagesOwner.lengthDiffrent = [];
        this.compareObject.AdvantagesOwner.lengthDiffrent.push({
          AdvantagesOwner: {
            removedOptions,
            addedOptions,
          },
        });
      }

      if (
        newData.advantages_tenant[offerObjectType].advantages !==
          oldData.AdvantagesTenant.advantages ||
        newData.advantages_tenant[offerObjectType].desc !==
          oldData.AdvantagesTenant.desc
      ) {
        this.compareObject.AdvantagesTenant =
          newData.advantages_tenant[offerObjectType];
      }

      if (
        newData.advantages_guest[offerObjectType].advantages !==
          oldData.AdvantagesGuest.advantages ||
        newData.advantages_guest[offerObjectType].desc !==
          oldData.AdvantagesGuest.desc
      ) {
        // console.log(111, newData.advantages_guest[offerObjectType].advantages !== oldData.AdvantagesGuest.advantages )
        // console.log(111, newData.advantages_guest[offerObjectType].advantages,'\n\n', oldData.AdvantagesGuest.advantages)

        this.compareObject.AdvantagesGuest =
          newData.advantages_guest[offerObjectType];
      }

      if (
        newData.service.desc !== oldData.Service.desc ||
        newData.service.img !== oldData.Service.img
      ) {
        this.compareObject.Service = newData.service;
        this.compareObject.Service.diff = {
          img: newData.service.img !== oldData.Service.img,
          desc: newData.service.desc !== oldData.Service.desc,
        };
        // console.log(newData.service.desc, '\n\n\n', )
        // console.log(111, oldData.Service.desc)
      }

      if (newData.doneWorks !== oldData.DoneWorks) {
        this.compareObject.DoneWorks = newData.doneWorks;
      }

      // _.each(newData.objects[offerObjectType], newOption => {
      //   const oldOption = _.find(oldData.Objects, { randomId: newOption.randomId })
      //   if (oldOption) {
      //     if (oldOption.name !== newOption.name || oldOption.address !== newOption.address || oldOption.img !== newOption.img || oldOption.equipment !== newOption.equipment || oldOption.works !== newOption.works) {
      //       if (this.compareObject.Objects === void 0) this.compareObject.Objects = {}
      //       if (this.compareObject.Objects.Options === void 0) this.compareObject.Objects.Options = []
      //       this.compareObject.Objects.Options.push(newOption)
      //     }
      //   }
      // })
      //
      // const object_newDataOptionArray = newData.objects[offerObjectType]
      // const object_oldDataOptionArray = oldData.Objects
      // const object_removedOptions = []
      // const object_addedOptions = []
      // const object_onlyInA = object_newDataOptionArray ? object_newDataOptionArray.filter(comparer(object_oldDataOptionArray)) : []
      // const object_onlyInB = object_oldDataOptionArray ? object_oldDataOptionArray.filter(comparer(object_newDataOptionArray)) : []
      // const object_diffOptions = object_onlyInA.concat(object_onlyInB)
      //
      // if (object_diffOptions.length) {
      //   _.each(object_diffOptions, i => {
      //     if (_.find(object_newDataOptionArray, ['randomId', i.randomId])) {
      //       object_addedOptions.push(i)
      //     } else {
      //       object_removedOptions.push(i)
      //     }
      //   })
      // }
      // if (object_diffOptions.length) {
      //   if (this.compareObject.Objects === void 0) this.compareObject.Objects = {}
      //   if (this.compareObject.Objects.Options === void 0) this.compareObject.Objects.Options = []
      //   if (this.compareObject.Objects.lengthDiffrent === void 0) this.compareObject.Objects.lengthDiffrent = []
      //   this.compareObject.Objects.lengthDiffrent.push({
      //     'Objects': {
      //       removedOptions: object_removedOptions,
      //       addedOptions: object_addedOptions
      //     }
      //   })
      // }

      this[c.CHANGE_COMPARE_OBJECT](copy(this.compareObject));

      // console.log(this.compareObject, this.offerObject.json.Total.Options.types)
      // console.log(newData)
      // console.log(oldData)
    },
    countChangesOnPage(print) {
      let changes = 0;
      if (
        [
          "ContentText",
          "AdvantagesGuest",
          "AdvantagesTenant",
          "Tasks",
          "OptionsText",
          "DoneWorks",
          "ServiceImg",
          "ServiceDesc",
        ].includes(print)
      ) {
        return 1;
      } else {
        if (
          [
            "Entry",
            "Exit",
            "Entry_2",
            "Exit_2",
            "Payment",
            "Additional",
          ].includes(print)
        ) {
          // if (this.compareObject.Modules !== void 0 && (this.compareObject.Modules[print].img || this.compareObject.Modules[print].img_rack || (this.compareObject[print] && this.compareObject[print].img_barrier))) {
          //   console.log(123213)
          //   changes = 1
          // }
          // console.log(123213, print)

          if (
            this.compareObject[print] &&
            this.compareObject[print].img_barrier
          ) {
            changes = 1;
          }
        }
        changes +=
          this.getOptionsChangesArray(print).length +
          this.getChangedOptions(print, "removed").length +
          this.getChangedOptions(print, "added").length;
        return changes;
      }
    },
    getChangesNumericLabel(print) {
      if (
        this.print.changeWindow &&
        this.newTemplateChanges.length &&
        (this.newTemplateChanges.includes(print) ||
          this.newTemplateChanges.includes(print.replace(/[^A-Za-z]+/g, "")))
      ) {
        const count = this.countChangesOnPage(print);
        // console.log(print, count)
        if (count) {
          return `<div class="have-new-changes__label">${count}</div>`;
        }
      }
    },
    getOptionsChangesArray(module) {
      let arr = [];
      if (["Entry", "Exit"].includes(module)) {
        if (this.compareObject.Modules[module].rack !== void 0) {
          arr.push(...this.compareObject.Modules[module].rack);
        }
      } else if (["Entry_2", "Exit_2"].includes(module)) {
        if (
          this.compareObject.Modules &&
          this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")] &&
          this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
            .barrier !== void 0
        ) {
          arr.push(
            ...this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
              .barrier
          );
        }
      } else if (modulesArray.includes(module)) {
        _.each(optionsArray, (option) => {
          if (this.compareObject.Modules[module][option] !== void 0) {
            arr.push(...this.compareObject.Modules[module][option]);
          }
        });
      } else if (module === "Total") {
        _.each(["types", "delivery", "payment"], (option) => {
          if (
            this.compareObject.Total[option] !== void 0 &&
            this.compareObject.Total[option].Options !== void 0
          ) {
            arr.push(...this.compareObject.Total[option].Options);
          }
        });
      } else if (module === "AdvantagesOwner") {
        arr.push(...this.compareObject.AdvantagesOwner.Options);
      } else if (module === "Objects") {
        arr.push(...this.compareObject.Objects.Options);
      }
      return arr;
    },
    findOldOption(module, id) {
      let findedOption = false;
      if (modulesArray.includes(module)) {
        _.each(optionsArray, (option) => {
          if (this.offerObject.json.Modules[module][option] !== void 0) {
            // console.log(this.offerObject.json.Modules[module][option])
            let search = _.find(this.offerObject.json.Modules[module][option], [
              "randomId",
              id,
            ]);
            if (search) {
              findedOption = search;
              return false;
            }
          }
        });
      } else if (module === "Total") {
        _.each(["types", "delivery", "payment"], (option) => {
          if (this.offerObject.json.Total.Options[option] !== void 0) {
            let search = _.find(this.offerObject.json.Total.Options[option], [
              "randomId",
              id,
            ]);
            if (search) {
              findedOption = search;
              return false;
            }
          }
        });
      } else if (module === "AdvantagesOwner") {
        let search = _.find(this.offerObject.json.AdvantagesOwner, [
          "randomId",
          id,
        ]);
        if (search) {
          findedOption = search;
        }
      } else if (module === "Objects") {
        let search = _.find(this.offerObject.json.Objects, ["randomId", id]);
        if (search) {
          findedOption = search;
        }
      }
      // console.log('!!!', findedOption)
      return findedOption;
    },
    getChangedOptions(module, type) {
      let arr = [];
      if (
        this.compareObject.Modules !== void 0 &&
        this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")] &&
        modulesArray.includes(module.replace(/[^A-Za-z]+/g, "")) &&
        this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
          .lengthDiffrent !== void 0 &&
        this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
          .lengthDiffrent.length
      ) {
        if (["Entry", "Exit"].includes(module)) {
          if (
            this.compareObject.Modules[module].lengthDiffrent[0].rack !== void 0
          ) {
            arr = this.compareObject.Modules[module].lengthDiffrent[0].rack[
              type + "Options"
            ];
          }
        } else if (["Entry_2", "Exit_2"].includes(module)) {
          const index =
            this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
              .lengthDiffrent.length > 1
              ? 1
              : 0;
          if (
            this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
              .lengthDiffrent[index].barrier !== void 0
          ) {
            arr = this.compareObject.Modules[module.replace(/[^A-Za-z]+/g, "")]
              .lengthDiffrent[index].barrier[type + "Options"];
          }
        } else {
          _.each(optionsArray, (option) => {
            if (
              this.compareObject.Modules[module].lengthDiffrent[0][option] !==
              void 0
            ) {
              arr = this.compareObject.Modules[module].lengthDiffrent[0][
                option
              ][type + "Options"];
            }
          });
        }
      } else if (module === "Total") {
        _.each(["types", "delivery", "payment"], (module) => {
          if (
            this.compareObject.Total[module] !== void 0 &&
            this.compareObject.Total[module].lengthDiffrent !== void 0 &&
            this.compareObject.Total[module].lengthDiffrent.length
          ) {
            if (
              this.compareObject.Total[module].lengthDiffrent[0][module] !==
                void 0 &&
              this.compareObject.Total[module].lengthDiffrent[0][module][
                type + "Options"
              ].length
            ) {
              arr.push(
                ...this.compareObject.Total[module].lengthDiffrent[0][module][
                  type + "Options"
                ]
              );
            }
          }
        });
      } else if (module === "AdvantagesOwner") {
        if (
          this.compareObject.AdvantagesOwner.lengthDiffrent !== void 0 &&
          this.compareObject.AdvantagesOwner.lengthDiffrent.length &&
          this.compareObject.AdvantagesOwner.lengthDiffrent[0]
            .AdvantagesOwner !== void 0
        ) {
          if (
            this.compareObject.AdvantagesOwner.lengthDiffrent[0]
              .AdvantagesOwner[type + "Options"] !== void 0 &&
            this.compareObject.AdvantagesOwner.lengthDiffrent[0]
              .AdvantagesOwner[type + "Options"].length
          ) {
            arr.push(
              ...this.compareObject.AdvantagesOwner.lengthDiffrent[0]
                .AdvantagesOwner[type + "Options"]
            );
          }
        }
      } else if (module === "Objects") {
        if (
          this.compareObject.Objects.lengthDiffrent !== void 0 &&
          this.compareObject.Objects.lengthDiffrent.length &&
          this.compareObject.Objects.lengthDiffrent[0].Objects !== void 0
        ) {
          if (
            this.compareObject.Objects.lengthDiffrent[0].Objects[
              type + "Options"
            ] !== void 0 &&
            this.compareObject.Objects.lengthDiffrent[0].Objects[
              type + "Options"
            ].length
          ) {
            arr.push(
              ...this.compareObject.Objects.lengthDiffrent[0].Objects[
                type + "Options"
              ]
            );
          }
        }
      }
      return arr;
    },
    pageByModuleName(module) {
      switch (module) {
        case "ContentText":
          return 2;
        case "OptionsText":
          return 5;
        case "Entry":
          return 6;
        case "Exit":
          return 7;
        case "Entry_2":
          return 6.1;
        case "Exit_2":
          return 7.1;
        case "Payment":
          return 8;
        case "Additional":
          return 9;
        case "Total":
          return 10;
        case "Tasks":
          return 11;
        case "AdvantagesOwner":
          return 12;
        case "AdvantagesTenant":
          return 13;
        case "AdvantagesGuest":
          return 14;
        case "ServiceDesc":
          return 15;
        case "ServiceImg":
          return 16;
        case "DoneWorks":
          return 18;
        case "Objects":
          return 19;
      }
    },
    searchNextChangePage(module, initial = false) {
      const currentIndex = this.newTemplateChanges.indexOf(module);
      let nextPage = initial
        ? this.newTemplateChanges[currentIndex]
        : this.newTemplateChanges[currentIndex + 1];
      // console.log(nextPage)
      if (initial && ["Entry", "Exit"].includes(module)) {
        let changePath = true;
        _.each(this.compareObject.Modules[module], (value, key) => {
          if (key === "rack") changePath = false;
          if (value.length) {
            _.each(value, (i) => {
              if (typeof i === "object" && Object.keys(i)[0] === "rack") {
                changePath = false;
              }
            });
          }
        });
        if (changePath) {
          nextPage = nextPage + "_2";
        }
      }

      return this.pageByModuleName(nextPage);
    },
    getSubRowClass(item) {
      let className = !item.count ? "print-table__sub-row--disabled " : " ";
      if (item.falseOption) {
        className = className + " print-table--empty-row ";
      }
      className = className + this.getRowClassName(item);
      return className;
    },
    actionDeviceImages(action, id, array, cat, module) {
      if (action === "add") {
        const lastElementId = _.maxBy(array, "id").id;
        array.push({
          id: lastElementId + 1,
          img: "",
        });
      } else if (action === "remove") {
        let moduleName = module;
        let imagesArrayName = "img";

        if (module.includes("_rack")) {
          imagesArrayName = "img_rack";
          moduleName = module.replace("_rack", "");
        }
        if (module.includes("_barrier")) {
          imagesArrayName = "img_barrier";
          moduleName = module.replace("_barrier", "");
        }
        array = _.reject(array, { id });
        this.settingsObject.images.devices[cat + "_" + module] = _.reject(
          this.settingsObject.images.devices[cat + "_" + module],
          { id }
        );
        this.settingsObject.blobImages.devices[
          cat + "_" + module
        ] = _.reject(
          this.settingsObject.blobImages.devices[cat + "_" + module],
          { id }
        );
        this.settingsObject.json.devices[cat][moduleName][
          imagesArrayName
        ] = _.each(array, (item, index) => {
          item.id = index + 1;
          return item;
        });
      }
    },
    changeDeviceImageForOffer(img, module, imgName = "img") {
      this.offerObject.json.Modules[module][imgName] = img;
    },
    mainDevices(module, devices) {
      return _.filter(this.offerObject.json.Modules[module][devices], [
        "is_device",
        true,
      ]);
    },
    checkFalseOptions(module, device) {
      let found = false;
      _.each(this.offerObject.json.Modules[module][device], (i) => {
        if (i.falseOption) {
          found = true;
        }
      });
      // console.log(found, 'finding...')
      return found;
    },
    falseOptionsAction(action, module, device) {
      // console.log(this.checkFalseOptions(module, device))
      if (action === "add") {
        let can = true;
        _.each(this.offerObject.json.Modules[module][device], (i) => {
          if (i.falseOption) {
            can = false;
          }
        });
        if (can) {
          // console.log(this.getAllModuleOptions(module), this.MODULE_PAGE_LIMIT)
          let needOptions =
            this.getAllModuleOptions(module) - this.MODULE_PAGE_LIMIT;
          if (needOptions >= 1 && needOptions < 8) {
            const copyOffer = copy(
              this.offerObject.json.Modules[module][device]
            );
            needOptions = needOptions > 2 ? 0 : needOptions;
            for (let i = 0; i <= needOptions; i++) {
              // console.log(i)
              copyOffer.push({
                id: this.offerObject.json.Modules[module][device].length + 1,
                randomId: randomId(),
                name: "Пустая опция",
                cost: 0,
                convertCost: 0,
                desc: "",
                count: 0,
                falseOption: true,
              });
            }
            this.offerObject.json.Modules[module][device] = copyOffer;
          }
        }
      } else {
        this.offerObject.json.Modules[module][device] = _.filter(
          this.offerObject.json.Modules[module][device],
          (i) => {
            return i.falseOption === void 0;
          }
        );
      }
    },
    getRowClassName(item) {
      if (
        item.discont_number !== void 0 &&
        item.discont_number &&
        !this.print.rendering
      ) {
        return "row-with-discont";
      }
    },
    disableAllDeviceOptions(module, device) {
      if (this.offerObject.json.Modules[module][device][0].count) {
        this.offerObject.json.Modules[module][device] = _.map(
          this.offerObject.json.Modules[module][device],
          (i) => {
            i.count = 0;
            return i;
          }
        );
      } else {
        this.offerObject.json.Modules[module][device][0].count = 1;
      }

      if (module === "Payment") {
        const mainDevices = [];
        mainDevices.push(this.offerObject.json.Modules.Payment.cash_1[0]);
        if (this.offerObject.json.System !== "economy") {
          mainDevices.push(this.offerObject.json.Modules.Payment.cash_2[0]);
          mainDevices.push(this.offerObject.json.Modules.Payment.cash_4[0]);
        }
        mainDevices.push(this.offerObject.json.Modules.Payment.cash_3[0]);
        let count = 0;
        _.each(mainDevices, (i) => {
          count += i.count;
        });
        this.offerObject.json.passPages.payment = !count;
      }
    },
    makeOptionsQuery(module, option) {
      // const SMALL_SIZE = 15
      // const BIG_SIZE = 27
      // const RESET_NUM_SIZE = 275
      const SMALL_SIZE = 13;
      const BIG_SIZE = 25;
      const RESET_NUM_SIZE = 380;
      const TOTAL_BLOCK_HEIGHT = 30;
      const NEED_MODULE = module.replace(/[^A-Za-z]+/g, "");

      let pagesArray = {};
      let pagesCount = 1;
      let heightSum = 0;

      const makeArray = (foundBreak) => {
        let foundBreakNext = false;
        let lastElement = this.offerObject.json.Modules[NEED_MODULE][option][
          this.offerObject.json.Modules[NEED_MODULE][option].length - 1
        ];
        let filteredArr = _.filter(
          this.offerObject.json.Modules[NEED_MODULE][option],
          (i) => {
            if (i.is_device && !i.count) return false;
            return true;
          }
        );
        if (module === "Payment") {
          filteredArr = [];
          _.each(["cash_1", "cash_2", "cash_3", "cash_4"], (o) => {
            filteredArr.push(...this.offerObject.json.Modules.Payment[o]);
          });
          lastElement = filteredArr[filteredArr.length - 1];
        }
        let array = [];
        if (module === "Payment") {
          array = filteredArr;
        } else {
          array = _.orderBy(filteredArr, ["is_device"], ["desc"]);
        }

        _.each(array, (i) => {
          const itemHeight = i.is_device || i.id === 1 ? BIG_SIZE : SMALL_SIZE;

          if (lastElement.randomId === i.randomId)
            heightSum += TOTAL_BLOCK_HEIGHT;

          if (heightSum <= RESET_NUM_SIZE) {
            heightSum += itemHeight;
          }
          if (foundBreakNext) {
            heightSum = itemHeight;
            pagesCount++;
            foundBreakNext = false;
          } else if (heightSum > RESET_NUM_SIZE) {
            heightSum = itemHeight;
            pagesCount++;
          }
          if (foundBreak && i.randomId === foundBreak) foundBreakNext = true;

          if (pagesArray[pagesCount] === void 0) pagesArray[pagesCount] = [];
          // i.name = heightSum
          pagesArray[pagesCount].push(i);
        });
      };
      makeArray(false);

      // check line break
      if (pagesCount > 1) {
        let foundBreak = false;
        let afterBreak = false;
        let prevId;
        _.each(pagesArray, (arr) => {
          if (foundBreak) return;
          _.each(arr, (i) => {
            if (!i.is_device) {
              afterBreak = true;
            }
            if (i.is_device && afterBreak) {
              foundBreak = prevId;
              return;
            }
            prevId = i.randomId;
          });
        });
        if (foundBreak) {
          pagesArray = {};
          pagesCount = 1;
          heightSum = 0;
          makeArray(foundBreak);
        }
      }
      this.pageNums[module + "LastPage"] = this.lastPageNum = Object.keys(
        pagesArray
      ).length;

      return pagesArray;
    },
    getServiceTypePrice() {
      function getDevicePrice(options) {
        if (options === "rack") {
          return divicesSum < 70000 ? 3100 : 1500;
        } else {
          return divicesSum < 70000 ? 4000 : 1500;
        }
      }

      let divicesSum = 0;
      _.each(["Exit", "Entry"], (module) => {
        _.each(this.offerObject.json.Modules[module]["rack"], (i) => {
          if (i.is_device) {
            _.each(Array.from(Array(i.count).keys()), () => {
              divicesSum += getDevicePrice("rack");
            });
          }
        });
      });
      _.each(
        Array.from(
          Array(this.offerObject.json.Modules.Payment["cash_1"][0].count).keys()
        ),
        () => {
          divicesSum += getDevicePrice("payment");
        }
      );
      if (this.offerObject.json.System !== "economy") {
        _.each(
          Array.from(
            Array(
              this.offerObject.json.Modules.Payment["cash_2"][0].count
            ).keys()
          ),
          () => {
            divicesSum += getDevicePrice("payment");
          }
        );
      }

      if (divicesSum < 15500) divicesSum = 15500;
      // if (this.offerObject.json.serviceType.cost === 0)
      this.offerObject.json.serviceType.cost = divicesSum;
      return divicesSum;
    },
    textTasksChecked() {
      const self = this;
      clearTimeout(window.typeTime);

      window.typeTime = setTimeout(function () {
        let c = 1;
        let lines = self.offerObject.json.TasksPageText.split("\n");
        lines = _.map(lines, (line) => {
          if (c !== self.offerObject.json.TasksPageText.split("\n").length)
            line += "\n";
          c++;
          return line;
        });
        let localTasksAdditionalPage = self.pageNums.TasksAdditionalPage;
        self.offerObject.json.TasksPageTextVirtual = "";
        for (var i = 0; i < lines.length; i++) {
          (function (i) {
            setTimeout(function () {
              if (self.$refs["virtual-tasks-text"].offsetHeight < 520) {
                if (localTasksAdditionalPage) {
                  localTasksAdditionalPage = false;
                  console.log(
                    " self.pageNums.TasksAdditionalPage = false",
                    self.$refs["virtual-tasks-text"].offsetHeight
                  );
                }
              } else {
                if (!localTasksAdditionalPage) {
                  console.log(" self.pageNums.TasksAdditionalPage = true");
                  localTasksAdditionalPage = true;
                }

                if (
                  !self.offerObject.json.TasksPageTextVirtual.includes("|$$$|")
                ) {
                  self.offerObject.json.TasksPageTextVirtual += "|$$$|";
                }
              }
              self.offerObject.json.TasksPageTextVirtual += lines[i];
              if (document.getElementById("tasksAdditionalPageText")) {
                if (
                  document.getElementById("tasksAdditionalPageText")
                    .offsetHeight > 520
                ) {
                  document
                    .querySelector(".pdf-non-print-text--tasks")
                    .classList.remove("hidden");
                } else {
                  document
                    .querySelector(".pdf-non-print-text--tasks")
                    .classList.add("hidden");
                }
              }
              if (i === lines.length - 1) {
                console.log(111111);
                self.pageNums.TasksAdditionalPage = localTasksAdditionalPage;
              }
            }, 100);
          })(i);
        }
      }, 300);
      // }
    },
    openModal(refName = "modal") {
      this.$refs[refName].open();
    },
    ...mapMutations("sales", [c.RESET_COMPARE]),
    ...mapActions("sales", [
      c.SAVE_SETTINGS,
      c.TOGGLE_PRELOADER,
      c.CHANGE_COMPARE_OBJECT,
      c.CONVERT_TO_RUB,
    ]),
  },
  computed: {
    systemName() {
      if (!["economy", "qr"].includes(this.offerObject.json.System)) {
        if (this.offerObject.json.SystemType === "Cards") {
          return "на картах";
        } else if (this.offerObject.json.SystemType === "Tickets") {
          return "на билетах";
        } else if (this.offerObject.json.SystemType === "Tokens") {
          return "на жетонах";
        }
      }
    },
    optionPages() {
      let options = [];
      let pages = [];
      const MAX_PAGE_SIZE = 300;
      let pageHeight = MAX_PAGE_SIZE;

      _.each(modulesArray, (module) => {
        _.each(optionsArray, (option) => {
          if (this.offerObject.json.Modules[module][option] !== void 0) {
            _.each(this.offerObject.json.Modules[module][option], (i) => {
              const foundOption = _.find(options, { name: i.name });
              const optionForAdd = {
                randomId: i.randomId,
                anotherId: null,
                name: i.name,
                desc: i.desc,
                type: module,
              };
              if (i.desc && !foundOption) {
                options.push(optionForAdd);
              } else if (foundOption) {
                foundOption.anotherId = i.randomId;
              }
            });
          }
        });
      });
      options = _.groupBy(options, "type");

      let groupedOptions = { Entry: [] };

      _.each(options, (i, name) => {
        if (name === "Entry" || name === "Exit") {
          groupedOptions.Entry.push(...i);
        } else {
          groupedOptions[name] = i;
        }
      });

      _.each(groupedOptions, (moduleOptions, moduleName) => {
        let pageCounter = 0;
        // console.log(moduleName, pages)
        pages[moduleName] = [];
        pages[moduleName][pageCounter] = [];
        pageHeight = MAX_PAGE_SIZE;

        _.each(moduleOptions, (i) => {
          let height = 62;

          height += i.desc.length * 0.216;

          pageHeight -= height;
          // if (moduleName === 'Payment') {
          //   console.log(moduleName, pageHeight, height)
          // }
          pages[moduleName][pageCounter].push(i);
          if (pageHeight <= 0) {
            pageCounter++;
            pageHeight = MAX_PAGE_SIZE;
            // if (moduleName === 'Payment') {
            //   console.log(moduleName, '+ another page')
            // }
          }

          if (pages[moduleName][pageCounter] === void 0) {
            pages[moduleName][pageCounter] = [];
          }
        });
      });

      let totalLengthPage = 0;
      _.each(modulesArray, (module) => {
        if (pages[module]) {
          pages[module] = _.filter(pages[module], (i) => {
            return i.length;
          });

          totalLengthPage += pages[module].length;
          // console.log(totalLengthPage, module)
        }
      });
      // console.log('!!!', totalLengthPage, pages)
      this.pageNums.Options = totalLengthPage;

      return pages;
    },

    objects() {
      return _.filter(this.offerObject.json.Objects, (i) => !i.disabled);
    },
    objectPageLength() {
      const objectsLength = this.objects.length;
      // console.log(objectsLength)
      return objectsLength <= 3 ? 1 : Math.ceil(this.objects.length / 3);
    },
    objectType() {
      return this.offerObject.json.ObjectType;
    },
    systemTypeForPreview() {
      if (this.offerObject.json.SystemType) {
        return this.offerObject.json.SystemType.toLowerCase();
      }
    },
    filteredTypes() {
      return _.filter(this.offerObject.json.Total.Options.types, (item) => {
        return item.disabled === void 0 ? true : !item.disabled;
      });
    },
    newTemplateChanges() {
      // console.log(Object.keys(this.compareObject), Object.keys(this.compareObject).length)
      // console.log(this.compareObject)
      let arr = Object.keys(this.compareObject);
      if (arr.includes("Modules")) {
        arr.splice(
          arr.indexOf("Modules"),
          1,
          ...Object.keys(this.compareObject.Modules)
        );
      }
      if (arr.includes("Service")) {
        // console.log(this.compareObject.Service)
        let serviceArr = [];
        if (this.compareObject.Service.diff.desc)
          serviceArr.push("ServiceDesc");
        if (this.compareObject.Service.diff.img) serviceArr.push("ServiceImg");
        arr.splice(arr.indexOf("Service"), 1, ...serviceArr);
      }
      return arr;
    },
    totalPages() {
      // if (this.getAllTotalOptions() > 10) this.TOTAL_PAGE_LIMIT = 6
      // else this.TOTAL_PAGE_LIMIT = 4
      return Math.ceil(this.getAllTotalOptions() / this.TOTAL_PAGE_LIMIT);
    },
    getFirstTasksText() {
      // next sprint
      if (
        this.offerObject.json.TasksPageTextVirtual !== void 0 &&
        this.offerObject.json.TasksPageTextVirtual.includes("|$$$|")
      ) {
        return this.offerObject.json.TasksPageTextVirtual.split("|$$$|")[0];
      } else {
        return this.offerObject.json.TasksPageTextVirtual;
      }
    },
    getLastTasksText() {
      // next sprint
      if (
        this.offerObject.json.TasksPageTextVirtual !== void 0 &&
        this.offerObject.json.TasksPageTextVirtual.includes("|$$$|")
      ) {
        if (!this.pageNums.TasksAdditionalPage) {
          console.log("this.pageNums.TasksAdditionalPage = true");

          this.pageNums.TasksAdditionalPage = true;
        }

        return this.offerObject.json.TasksPageTextVirtual.split("|$$$|").pop();
      }
    },
    ...mapGetters("sales", ["totalDevicesPrice", "totalSumPrice"]),
    ...mapState("sales", [
      "settingsObject",
      "offerObject",
      "pageNums",
      "print",
      "compareObject",
      "rateValue",
      "config",
      "settingsObjectStandart",
    ]),
  },
  filters: {
    moneyFormat(val) {
      if (val && Number(val)) {
        return Number(val)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$& "); // 12,345.67
      }
      if (val === 0 && !val) {
        return 0;
      }
      return val;
    },
    short_name: function (value) {
      if (!value) return "";
      value = value.split(" ");
      let shortName = "";
      value.forEach((item, i) => {
        shortName = i === 0 ? `${item} ` : shortName + `${item[0]}.`;
      });
      return shortName;
    },
  },
  components: { MagnificPopupModal },
};
