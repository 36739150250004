<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_16 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_16 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('ServiceImg')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div class="new-changes-block">
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <img :src="getImageSrc(offerObject.json.Service.img)" alt="" />
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
            <img :src="getImageSrc(compareObject.Service.img)" alt="" />
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('ServiceImg'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div
        class="pdf-settings__cont pdf-settings__cont--total pb-0 scroll-style"
      >
        <div class="p-settings">
          <div
            class="p-settings__item p-settings__item--total"
            :class="setClass(item)"
            v-for="(item, index) in serviceTypes"
            :key="index"
          >
            <div class="p-settings__cont" @click="openOption($event.target)">
              <div class="p-settings__check">
                <label
                  class="checkbox checkbox_white table__checkbox js-index-checkbox"
                >
                  <input
                    class="checkbox__input main_checkbox_input"
                    type="checkbox"
                    name="row_checkbox_clients"
                    @click.prevent="disableOption(item)"
                    :class="
                      item.disabled === void 0
                        ? 'checkbox__box--active'
                        : item.disabled
                        ? ''
                        : 'checkbox__box--active'
                    "
                  />
                  <span class="checkbox__box main_checkbox_span">
                    <svg class="checkbox__icon">
                      <use
                        xlink:href="/static/images/icon.svg#icon_icon-check"
                      ></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="col-auto p-settings__name-wrap">
                <div class="p-settings__name">{{ item.name }}</div>
              </div>
              <div class="col-auto ml-auto">
                <div class="p-settings__text">
                  {{ item.cost | moneyFormat }}
                </div>
              </div>
            </div>
            <div class="p-settings__drop">
              <div class="p-settings__row">
                <div class="col">
                  <div class="p-settings__label">Цена</div>
                </div>
                <div class="p-settings__price-cont">
                  <div class="form-row">
                    <input
                      class="form-row__input"
                      type="number"
                      min="0"
                      v-model="item.cost"
                      :placeholder="item.cost"
                      @blur="disableOption(item)"
                    />
                  </div>
                </div>
              </div>

              <div class="p-settings__btn-group">
                <button
                  class="p-settings__btn p-settings__btn--green"
                  style="width: 100%"
                  @click="closeOption($event.target)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next">
        <label class="p-settings__total">
          <div class="p-settings__check">
            <div
              class="checkbox checkbox_white table__checkbox js-index-checkbox"
            >
              <input
                class="checkbox__input main_checkbox_input"
                type="checkbox"
                @click.prevent="
                  offerObject.json.passPages.service = !offerObject.json
                    .passPages.service
                "
                :class="
                  offerObject.json.passPages.service
                    ? 'checkbox__box--active'
                    : ''
                "
              />
              <span class="checkbox__box main_checkbox_span">
                <svg class="checkbox__icon">
                  <use
                    xlink:href="/static/images/icon.svg#icon_icon-check"
                  ></use>
                </svg>
              </span>
            </div>
          </div>
          <div class="col-auto">
            Не добавлять страницы Сервисное обслуживание
          </div>
        </label>
        <div class="pdf-settings__next pdf-settings__btns">
          <button class="btn-full btn-full__gray" @click="changeStep(15)">
            Назад
          </button>
          <button class="btn-full btn-full__green" @click="changeStep(18)">
            Далее
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_16 from "../prints/print_16";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      serviceTypes: [
        {
          type: 1,
          name: "Техподдержка",
          cost: 5000,
          disabled: false,
        },
        {
          type: 2,
          name: "Стандартное Т.О.",
          cost: 0,
          disabled: true,
        },
        {
          type: 3,
          name: "Эксплуатация 12 часов",
          cost: 0,
          disabled: true,
        },
        {
          type: 4,
          name: "Эксплуатация 24 часа",
          cost: 0,
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.serviceTypes = _.map(this.serviceTypes, (i) => {
      if (this.offerObject.json.serviceType.type === i.type) {
        i.disabled = false;
        i.cost = this.offerObject.json.serviceType.cost;
      } else {
        i.disabled = true;
      }

      return i;
    });
  },
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    setClass(item) {
      let className = item.disabled ? "p-settings__item--disabled" : "";
      return className;
    },
    disableOption(item) {
      this.serviceTypes = _.map(this.serviceTypes, (i) => {
        i.disabled = true;
        return i;
      });
      item.disabled = item.disabled === void 0 ? true : !item.disabled;
      this.offerObject.json.serviceType = {
        type: item.type,
        cost: item.cost,
        name: item.name,
      };
    },
    closeOption(el) {
      el.closest(".p-settings__item")
        .querySelector(".p-settings__drop")
        .classList.remove("p-settings__drop--open");
    },
    openOption(el) {
      const drop = el
        .closest(".p-settings__item")
        .querySelector(".p-settings__drop");
      document.querySelectorAll(".p-settings__drop").forEach((i) => {
        if (i !== drop) {
          i.classList.remove("p-settings__drop--open");
        }
      });
      if (
        el.classList[0] === "p-settings__cont" ||
        el.closest(".p-settings__name-wrap")
      ) {
        drop.classList.toggle("p-settings__drop--open");
        if (
          drop.querySelector(".form-row__input:not(.form-row__input--payment)")
        ) {
          drop
            .querySelector(".form-row__input:not(.form-row__input--payment)")
            .focus();
        }
      }
    },
  },
  components: { Print_16 },
};
</script>
