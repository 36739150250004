<template>
  <div
    class="task-settings__list context-popup-menu"
    ref="popper"
    v-show="isVisible"
    tabindex="-1"
    v-click-outside="close"
    @contextmenu.capture.prevent
  >
    <div
      class="task-settings__list-item"
      @click="
        close();
        $emit('openDetailTask', true);
      "
    >
      <div class="task-settings__list-icon">
        <svg width="18" height="18" stroke="#444444">
          <use xlink:href="/static/images/tasks/sprite.svg#new-page-icon"></use>
        </svg>
      </div>
      Открыть в новом окне
    </div>
    <div
      class="task-settings__list-item"
      @click="
        $emit('createSubTask');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="18" height="18" stroke="#444444">
          <use xlink:href="/static/images/tasks/sprite.svg#child-icon"></use>
        </svg>
      </div>
      Добавить подзадачу
    </div>
    <div
      class="task-settings__list-item"
      @click="
        $emit('copyTask');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="18" height="18" stroke="#444444">
          <use
            xlink:href="/static/images/tasks/sprite.svg#circle-angle-icon"
          ></use>
        </svg>
      </div>
      Дублировать
    </div>
    <div
      class="task-settings__list-item"
      @click="
        $emit('copyLink');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="18" height="18" stroke="#444444">
          <use
            xlink:href="/static/images/tasks/sprite.svg#copy-link-icon"
          ></use>
        </svg>
      </div>
      Копировать ссылку
    </div>
    <div
      v-if="item.Status === '2'"
      class="task-settings__list-item"
      @click="
        $emit('openTask');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="18" height="18" stroke="#444444">
          <use xlink:href="/static/images/tasks/sprite.svg#check-icon"></use>
        </svg>
      </div>
      Открыть
    </div>
    <div
      v-else
      class="task-settings__list-item"
      @click="
        $emit('closeTask');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="16" height="16" stroke="#444444">
          <use xlink:href="/static/images/tasks/sprite.svg#check-icon"></use>
        </svg>
      </div>
      Закрыть
    </div>
    <div
      class="task-settings__list-item"
      v-if="checkUserTaskRight('delete_task')"
      @click="
        $emit('deleteTask');
        close();
      "
    >
      <div class="task-settings__list-icon">
        <svg width="16" height="18" stroke="#444444">
          <use xlink:href="/static/images/tasks/sprite.svg#trash-icon"></use>
        </svg>
      </div>
      Удалить
    </div>
  </div>
</template>

<script>
import Popper from "popper.js";
import ClickOutside from "vue-click-outside";
import common from "@/mixins/tasks/common";
// @vue/component
export default {
  mixins: [common],
  props: {
    boundariesElement: null,
    item: {
      type: Object,
      default: null,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Popper,
  },
  data() {
    return {
      opened: false,
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    isVisible() {
      return this.opened;
    },
  },
  methods: {
    open(evt) {
      this.opened = true;

      if (this.popper) {
        this.popper.destroy();
      }
      console.log(this.referenceObject(evt).getBoundingClientRect());

      this.popper = new Popper(this.referenceObject(evt), this.$refs.popper, {
        placement: "right-start",
        modifiers: {
          preventOverflow: {
            boundariesElement: this.boundariesElement
              ? this.boundariesElement
              : document.querySelector("body"),
          },
        },
      });

      // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate();
      });
    },
    close() {
      this.opened = false;
    },
    referenceObject(evt) {
      const left = evt.clientX;
      const top = evt.clientY;
      const right = left + 1;
      const bottom = top + 1;
      const clientWidth = 1;
      const clientHeight = 1;

      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom,
        };
      }

      const obj = {
        getBoundingClientRect,
        clientWidth,
        clientHeight,
      };
      return obj;
    },
  },
  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
};
</script>
