import { render, staticRenderFns } from "./CitiesFields.vue?vue&type=template&id=284961ea&scoped=true&"
import script from "./CitiesFields.vue?vue&type=script&lang=js&"
export * from "./CitiesFields.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284961ea",
  null
  
)

export default component.exports