<template>
  <div class="layout__left">
    <div class="layout__scroll">
      <client-nav v-if="is_client" />
      <rps-nav v-else />
    </div>
  </div>
</template>

<script>
import ClientNav from "./components/client-nav";
import RpsNav from "./components/rps-nav";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("common", ["is_client"]),
  },
  components: { ClientNav, RpsNav },
};
</script>
