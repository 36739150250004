<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_3 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <Print_3 />
      </magnific-popup-modal>
    </div>
    <div class="pdf-settings">
      <div class="pdf-settings__cont scroll-style">
        <div class="pdf-settings__inner">
          <div class="form-row">
            <div class="form-row__label">Название:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.ObjectName"
            />
          </div>
          <!--<div class="form-row">-->
          <!--<div class="form-row__label">Город:</div>-->
          <!--<input class="form-row__input" type="text" v-model="offerObject.json.City">-->
          <!--</div>-->
          <div class="form-row form-row--geo">
            <div class="form-row__label">Адрес:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.Address"
            />
            <div class="select-d__arrow" @click="gmapWindow = true">
              <img src="/static/images/gps.svg" alt="icon" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__label">Шаблон:</div>
            <div class="select-d">
              <div class="select-d__inner">
                <template v-if="offerObject.json.ObjectType">{{
                  getObjectTypeName(this.offerObject.json.ObjectType)
                }}</template>
                <template v-else>Торгово-развлекательный центр</template>
                <div class="select-d__arrow">
                  <svg class="icon icon--arrow-down">
                    <use xlink:href="/static/images/icon.svg#arrow-down"></use>
                  </svg>
                </div>
              </div>
              <div class="select-d__dropdown">
                <div class="select-d__dropdown-list">
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('tc')"
                    :class="checkSelectClass('tc')"
                  >
                    Торгово-развлекательный центр
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('bc')"
                    :class="checkSelectClass('bc')"
                  >
                    Бизнес-центр
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('zk')"
                    :class="checkSelectClass('zk')"
                  >
                    Жилой комплекс
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('hotels')"
                    :class="checkSelectClass('hotels')"
                  >
                    Отель
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('sport')"
                    :class="checkSelectClass('sport')"
                  >
                    Спортивный комплекс
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('air')"
                    :class="checkSelectClass('air')"
                  >
                    Аэропорт / Железнодорожный вокзал
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('parks')"
                    :class="checkSelectClass('parks')"
                  >
                    Парк
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setType('others')"
                    :class="checkSelectClass('others')"
                  >
                    Паркинг
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__label">Валюта:</div>
            <div class="select-d">
              <div class="select-d__inner">
                {{ getCurrencyName }}
                <div class="select-d__arrow">
                  <svg class="icon icon--arrow-down">
                    <use xlink:href="/static/images/icon.svg#arrow-down"></use>
                  </svg>
                </div>
              </div>
              <div class="select-d__dropdown">
                <div class="select-d__dropdown-list">
                  <div
                    class="select-d__dropdown-item"
                    @click="setCurrency('RUB')"
                    :class="{
                      'select-d__dropdown-item--active':
                        offerObject.json.currency === 'RUB',
                    }"
                  >
                    Рубли
                  </div>
                  <div
                    class="select-d__dropdown-item"
                    @click="setCurrency('USD')"
                    :class="{
                      'select-d__dropdown-item--active':
                        offerObject.json.currency === 'USD',
                    }"
                  >
                    Доллары
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__label">Тип объекта:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.ObjectTypeString"
            />
          </div>
          <div class="form-row">
            <div class="form-row__label">Количество ММ:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.MMCount"
            />
          </div>
          <div class="form-row">
            <div class="form-row__label">Время работы парковки:</div>
            <input
              class="form-row__input"
              type="text"
              v-model="offerObject.json.ParkingTime"
            />
          </div>
          <div class="form-row">
            <label class="p-settings__total p-settings__total--task-page">
              <div class="p-settings__check">
                <div
                  class="checkbox checkbox_white table__checkbox js-index-checkbox"
                >
                  <input
                    class="checkbox__input main_checkbox_input"
                    type="checkbox"
                    @click.prevent="
                      offerObject.json.passPages.tasks_page = !offerObject.json
                        .passPages.tasks_page
                    "
                    :class="
                      !offerObject.json.passPages.tasks_page
                        ? 'checkbox__box--active'
                        : ''
                    "
                  />
                  <span class="checkbox__box main_checkbox_span">
                    <svg class="checkbox__icon">
                      <use
                        xlink:href="/static/images/icon.svg#icon_icon-check"
                      ></use>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="col-auto">Отдельная страница</div>
            </label>
            <div class="form-row__label">Задачи:</div>
            <textarea
              class="form-row__input form-row__input--textarea"
              v-if="offerObject.json.passPages.tasks_page"
              v-model="offerObject.json.ContentTasks"
            >
            </textarea>
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(2)">
          Назад
        </button>
        <button
          class="btn-full btn-full__green"
          @click="changeStep(offerObject.json.passPages.tasks_page ? 4 : 3.1)"
        >
          Далее
        </button>
      </div>
    </div>
    <v-dialog
      class="offer-gmap-dialog"
      v-model="gmapWindow"
      width="600px"
      v-if="gmapWindow"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Указать местоположение на карте</span>
        </v-card-title>
        <div class="cm-gmap">
          <div class="field">
            <gmap-autocomplete @place_changed="setPlace" class="input">
            </gmap-autocomplete>
            <button
              class="button button_green button_uppercase button_align_center field__btn"
              @click="usePlace"
              style="margin-left: 10px"
            >
              Найти
            </button>
          </div>
          <GmapMap
            :center="center"
            :zoom="7"
            style="width: 100%; height: 300px"
            @click="centerMark"
            ref="gmap"
          >
            <GmapMarker
              ref="myMarker"
              :position="position"
              @click="center = position"
              :clickable="true"
              :draggable="true"
              @drag="drag"
            />
          </GmapMap>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="gmapWindow = false"
            >Готово</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_3 from "../prints/print_3";
import * as c from "../../../../../store/sales/constants";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],
  data() {
    return {
      center: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      position: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      place: null,
      gmapWindow: false,
    };
  },
  mounted() {},
  computed: {
    getCurrencyName() {
      if (this.offerObject.json.currency !== void 0) {
        return this.offerObject.json.currency === "USD" ? "Доллары" : "Рубли";
      }
      return "Рубли";
    },
    ...mapState("sales", ["offerObject"]),
  },
  methods: {
    setCurrency(type) {
      if (this.offerObject.json.currency === void 0) {
        this.offerObject.json.currency = type;
        this.offerObject.json = JSON.parse(
          JSON.stringify(this.offerObject.json)
        );
      } else {
        this.offerObject.json.currency = type;
      }

      this[c.CONVERT_TO_RUB]({
        newValue: this.offerObject.json.rateValue,
        oldValue: this.offerObject.json.rateValue,
      });
      this.offerObject.json.Total.Options.types = _.map(
        this.offerObject.json.Total.Options.types,
        (i) => {
          // if (i.name === 'Пуско-наладочные работы') {
          //   i.convertCost = this.totalDevicesPrice * 7 / 100
          // }
          i.convertCost = Math.round(i.convertCost / 1000) * 1000;
          return i;
        }
      );
    },
    checkSelectClass(type) {
      if (this.offerObject.json.ObjectType === type) {
        return "select-d__dropdown-item--active";
      }
    },
    setType(type) {
      this.offerObject.json.ObjectType = type;
      this.changeOfferByObjectType(this.offerObject.json.ObjectType);
    },
    // <--google maps func |____
    // ||-------------------------------------------------
    drag(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    centerMark(location) {
      this.center = this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.setAddressByGeo();
    },
    setAddressByGeo() {
      this.$geocoder.send(this.position, (response) => {
        try {
          this.offerObject.json.City = _.find(response.results, (i) => {
            return (
              i.types.includes("administrative_area_level_2") &&
              i.types.includes("political")
            );
          }).address_components[0].long_name;
          this.offerObject.json.Address = _.find(response.results, (i) => {
            return i.types.includes("street_address");
          }).formatted_address;
        } catch (e) {
          e;
        }
        // console.log(response)
      });
    },
    changeTextLat(val) {
      this.position.lat = +val;
      this.center.lat = +val;
    },
    changeTextLng(val) {
      this.position.lng = +val;
      this.center.lng = +val;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.center = this.position = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.place = null;
        this.setAddressByGeo();
      }
    },
    ...mapActions("sales", [c.CONVERT_TO_RUB]),
    // ||-----------------------------------------------/>
  },
  components: { Print_3 },
};
</script>
