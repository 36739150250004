<template>
  <div class="cm-popup__right">
    <div class="header task-header cm-header" v-if="selectedItems.length">
      <div class="header__col cm-group-actions">
        <div class="button-group">
          <div
            class="button button-close button_grey button_uppercase"
            @click="unselectItems()"
          >
            <img src="/static/images/sales/close.svg" alt="" />
          </div>
          <div class="button button_grey button_uppercase">
            {{ selectedItems.length }}
          </div>
        </div>
        <div
          class="button button_grey button_uppercase"
          @click="attachAutomation"
        >
          Создать автоматизацию
        </div>
        <div
          class="button button_grey button_uppercase"
          v-if="checkUserTaskRight('close_task')"
          style="margin-left: auto"
          @click="closeTasks()"
        >
          Закрыть
        </div>
        <div class="button button_grey button_uppercase" @click="deleteTasks()">
          удалить
        </div>
        <div
          class="button button_grey button_uppercase"
          v-if="dependsTaskSelected"
          @click="deleteDependTasks()"
        >
          открепить
        </div>
      </div>
    </div>
    <div class="task-popup-header" v-else>
      <div
        class="actions-header__col"
        v-if="checkUserTaskRight('create_tasks_and_subtasks')"
        ref="portal-wrapper"
      >
        <span
          class="button tm-btn tm-btn--add"
          @click="openTaskCreateSubmenu = true"
          >+</span
        >
        <portal to="no-multiple-portal" v-if="openTaskCreateSubmenu">
          <transition name="fade">
            <div
              class="simple-select-list"
              style="max-width: 165px"
              v-if="openTaskCreateSubmenu"
              v-click-outside="closeTaskCreateSubmenu"
              v-portal="{ parent: $refs['portal-wrapper'] }"
            >
              <div
                class="simple-select-list__item"
                @click="createSection"
                v-if="checkUserTaskRight('add_section')"
              >
                Создать секцию
              </div>
              <div
                class="simple-select-list__item"
                @click="
                  () => {
                    parentData.taskCreatePopupOpen = true;
                    openTaskCreateSubmenu = false;
                  }
                "
              >
                Создать задачу
              </div>
            </div>
          </transition>
        </portal>
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          method="post"
          action="#"
          class="search-bar search-bar_white actions-header__search-bar"
        >
          <button type="submit" class="search-bar__button">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            type="search"
            placeholder="Введите название задачи"
            v-model="search"
            class="search-bar__input"
          />
          <!--          <div class="form-filter__btn form-filter__btn&#45;&#45;active">-->
          <!--            <span></span>-->
          <!--          </div>-->
        </form>
      </div>
      <!--      <div class="actions-header__col">-->
      <!--        <a href="#" class="icon-button task-checkbox-btn">-->
      <!--          <label class="checkbox checkbox_white table__checkbox js-index-checkbox">-->
      <!--            <input class="checkbox__input main_checkbox_input" type="checkbox" name="row_checkbox_clients"><span class="checkbox__box main_checkbox_span">-->
      <!--                          <svg class="checkbox__icon">-->
      <!--                            <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>-->
      <!--                          </svg></span>-->
      <!--          </label>-->
      <!--          Мои подзадачи-->
      <!--        </a>-->
      <!--      </div>-->
      <div class="actions-header__col" v-if="taskActiveProcess">
        <span
          class="tm-settings-btn tm-settings-btn-kanban"
          :class="{ 'tm-settings-btn-kanban--active': kanbanView }"
          @click="kanbanViewToggle"
        >
          <span class="icon-button__container">
            <svg width="16" height="16">
              <use
                xlink:href="/static/images/tasks/sprite.svg#kanban-icon"
              ></use>
            </svg>
          </span>
        </span>
      </div>
      <div class="actions-header__col">
        <a
          href="#"
          class="icon-button task-checkbox-btn task-checkbox-btn--hide-completed t-gray-btn"
          @click.prevent="hideCompletedTasks"
        >
          <label
            class="checkbox checkbox_white table__checkbox js-index-checkbox"
          >
            <input
              class="checkbox__input main_checkbox_input"
              type="checkbox"
              :class="{
                'checkbox__box--active': parentData.hideCompletedTasks,
              }"
            />
            <span class="checkbox__box main_checkbox_span">
              <svg class="checkbox__icon">
                <use xlink:href="/static/images/icon.svg#icon_icon-check"></use>
              </svg>
            </span>
          </label>
          Скрыть выполненные
        </a>
      </div>
      <div class="actions-header__col">
        <button
          class="kanban-attach-btn t-gray-btn"
          @click="
            checkUserTaskRight('attach_proccess_to_task') &&
              (processesBubbleOpen = true)
          "
        >
          <span class="kanban-attach-btn__icon">
            <svg width="16" height="16">
              <use
                xlink:href="/static/images/tasks/sprite.svg#attach-icon"
              ></use>
            </svg>
          </span>
          <template v-if="loadedTask.entityModel.ProcessId">{{
            getProcessName(loadedTask.entityModel.ProcessId)
          }}</template>
          <template v-else-if="checkUserTaskRight('attach_proccess_to_task')"
            >Назначить процесс</template
          >
          <template v-else>Процесс не назначен</template>
        </button>
        <transition name="fade">
          <ProcessesListSearch
            :taskId="loadedTask.entityModel.Id"
            v-if="processesBubbleOpen"
            :selectedId="loadedTask.entityModel.ProcessId"
            :className="'task-bubble--templates'"
            @choose="setGroupProcess"
            @close="() => (processesBubbleOpen = false)"
          />
        </transition>
      </div>
    </div>
    <Kanban
      v-if="subtasks.length && taskActiveProcess && kanbanView"
      :selectedTaskParentData="parentData"
      :process="taskActiveProcess"
      :tasks="subtasks"
      :createTaskFunction="() => (parentData.taskCreatePopupOpen = true)"
    />
    <div class="table table--subtasks" v-else>
      <div class="table__header">
        <!--        <div class="table__col table__col_width_50" style="border-right: none">-->
        <!--          <p class="table__header-text">№</p>-->
        <!--        </div>-->
        <div class="table__col table__col_flexible">
          <p class="table__header-text">Проект</p>
          <div
            class="table__filter-button"
            :class="sorts.TaskName.active ? 'table-c__filter-btn--active' : ''"
            @click="makeSort('TaskName')"
          >
            {{ sorts.TaskName.asc ? "А-Я" : "Я-А" }}
          </div>
        </div>
        <div
          class="table__col table__col_width_140"
          v-if="loadedTask.entityModel.ProcessId"
        >
          <p class="table__header-text">Статус</p>
          <div
            class="table__filter-button"
            :class="
              sorts.StatusName.active ? 'table-c__filter-btn--active' : ''
            "
            @click="makeSort('StatusName')"
          >
            {{ sorts.StatusName.asc ? "А-Я" : "Я-А" }}
          </div>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">Ответственный</p>
          <div
            class="table__filter-button"
            :class="
              sorts.ResponsibleName.active ? 'table-c__filter-btn--active' : ''
            "
            @click="makeSort('ResponsibleName')"
          >
            {{ sorts.ResponsibleName.asc ? "А-Я" : "Я-А" }}
          </div>
        </div>
        <div class="table__col table__col_width_160">
          <p class="table__header-text">Срок</p>
          <div
            class="table__filter-button"
            :class="
              sorts.FinishDate.active ? 'table-c__filter-btn--active' : ''
            "
            @click="makeSort('FinishDate')"
          >
            {{ sorts.FinishDate.asc ? "1-9" : "9-1" }}
          </div>
        </div>
      </div>

      <SubtasksList
        :parentData="parentData"
        :loadedTask="loadedTask"
        @openSubTask="openSubTask"
        @selectItem="selectItem"
        @loadSectionTasks="loadSectionTasks"
      />
    </div>
    <transition name="popupSlide">
      <AttachTasksGroupsList
        v-if="attachTasksPopupOpen"
        @onClosePopup="attachTasksPopupOpen = false"
        @onSubmitHandler="setTasksDependencies"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import common from "../../../../../mixins/tasks/common";
import * as c from "../../../../../store/tasks/constants";
import SubtasksList from "./SubtasksList";
import * as _ from "lodash";
import Kanban from "../../Kanban/Kanban";
import ProcessesListSearch from "../../CommonComponents/ProcessesListSearch";
import { Helpers } from "../../../../../mixins/Helpers";
import {
  DELETE_DEPENDENCIES_TASK,
  GET_DEPENDENCIES_TASKS,
  GET_SECTIONS,
  GET_SECTIONS_BY_ID,
  GET_TASKS,
  SET_LOCAL_STORAGE_SETTINGS,
} from "../../../../../store/tasks/constants";
import AttachTasksGroupsList from "@/modules/tasks/components/AttachTasks/AttachTasksGroupsList";
import { queueFetch } from "@/utils/queneFetch";
export default {
  mixins: [common, Helpers],
  props: ["loadedTask", "openInner", "parentData"],
  data() {
    return {
      selectedItems: [],
      search: "",
      kanbanView: false,
      processesBubbleOpen: false,
      sorts: {
        TaskName: {
          asc: false,
          active: false,
        },
        FinishDate: {
          asc: false,
          active: false,
        },
        Priority: {
          asc: false,
          active: false,
        },
        StatusName: {
          asc: false,
          active: false,
        },
        ResponsibleName: {
          asc: false,
          active: false,
        },
      },
      openTaskCreateSubmenu: false,
      attachTasksPopupOpen: false,
      dependenciesTasks: [],
    };
  },
  created() {
    this.loadSectionTasks();
    const foundLocalSettings = _.find(this.localStorageSettings, {
      Id: this.loadedTask.entityModel.Id,
      Type: "task",
    });
    if (foundLocalSettings) {
      const settings = JSON.parse(foundLocalSettings.settings);
      this.kanbanView =
        settings.kanbanView !== void 0 ? settings.kanbanView : this.kanbanView;
    }
  },
  computed: {
    subtasks() {
      // return this.loadedTask.entityModel.childs;
      let items = this.openInner
        ? this.loadedTask.entityModel.childs
        : this.loadedTask.entityModel.childs;

      items = _.map(items, (i) => {
        i.StatusName = "";
        i.ResponsibleName = this.getPersonNameById(i.ResponsibleId);
        if (i.StatusId) {
          const status = this.getStatus(i.StatusId);
          if (status) {
            i.StatusName = status.Name;
          }
        }
        return i;
      });

      let sortColumn = null;
      let sortAsc = "desc";

      _.each(this.sorts, (value, key) => {
        if (value.active) {
          sortColumn = key;
          sortAsc = value.asc ? "asc" : "desc";
        }
      });

      return _.orderBy(
        items.filter((i) => {
          const re = new RegExp(this.search, "gi");
          return re.test(i.TaskName);
        }),
        (i) => {
          return i[sortColumn];
          // return null;
        },
        [sortAsc]
      );
    },
    taskActiveProcess() {
      return _.find(this.groupProcesses, {
        Id: this.loadedTask.entityModel.ProcessId,
      });
    },
    dependsTaskSelected() {
      return this.selectedItems.find((x) => x.isDepended);
    },
    ...mapState("tasks", [
      "groupProcesses",
      "localStorageSettings",
      "selectedTask",
    ]),
    ...mapGetters("tasks", ["group"]),
  },
  methods: {
    openSubTask(id) {
      this.$emit("closePopup");
      setTimeout(() => {
        this.$router.push("/tasks/" + id);
      }, 300);
    },
    selectItem(el, item) {
      if (el.target.checked) {
        if (!this.selectedItems.find((x) => x.Id === item.Id)) {
          this.selectedItems.push(item);
        }
      } else {
        this.selectedItems = _.filter(
          this.selectedItems,
          (i) => i.Id !== item.Id
        );
      }
    },
    deleteTasks() {
      queueFetch(this.selectedItems, async (item) => {
        await this[c.DELETE_TASK]({ id: item.Id, batch: true }).then(() => {
          const recursiveDelete = (task) => {
            task.childs = _.reject(task.childs, ["Id", item.Id]);
            task.depends = _.reject(task.depends, ["Id", item.Id]);
            task.childs.map((i) => {
              recursiveDelete(i);
              return i;
            });
          };
          recursiveDelete(this.loadedTask);
          recursiveDelete(this.loadedTask.entityModel);
          this.loadedTask.entityModel = JSON.parse(
            JSON.stringify(this.loadedTask.entityModel)
          );
        });
      }).then(() => {
        this.unselectItems();
      });
    },
    deleteDependTasks() {
      queueFetch(this.selectedItems, async (item) => {
        if (item.isDepended) {
          await this[DELETE_DEPENDENCIES_TASK](item.DependRecordId).then(() => {
            const recursiveDelete = (task) => {
              task.depends = _.reject(task.depends, ["Id", item.Id]);
              if (task.childs) {
                task.childs.map((i) => {
                  recursiveDelete(i);
                  return i;
                });
              }
            };
            recursiveDelete(this.loadedTask);
            recursiveDelete(this.loadedTask.entityModel);
            this.loadedTask.entityModel = JSON.parse(
              JSON.stringify(this.loadedTask.entityModel)
            );
          });
        }
      }).then(() => {
        this.unselectItems();
      });
    },
    unselectItems() {
      this.selectedItems = [];
      document.querySelectorAll(".checkbox__input").forEach((i) => {
        i.checked = false;
      });
    },
    setGroupProcess(id) {
      this.loadedTask.entityModel.ProcessId = id;
      const payload = {
        id: this.loadedTask.entityModel.Id,
        data: {
          ProcessId: id,
        },
      };
      function recursiveFunc2(i) {
        i.StatusId = null;
        if (i.childs && i.childs.length) {
          i.childs = _.map(i.childs, (i) => {
            i.StatusId = null;
            recursiveFunc2(i);
            return i;
          });
        }
        return i;
      }
      if (!id) {
        this.loadedTask.entityModel.childs = _.map(
          this.loadedTask.entityModel.childs,
          (i) => {
            i = recursiveFunc2(i);
            return i;
          }
        );
        this.selectedTask.entityModel.childs = _.map(
          this.selectedTask.entityModel.childs,
          (i) => {
            i = recursiveFunc2(i);
            return i;
          }
        );
      }
      this[c.EDIT_TASK](payload);
    },
    attachAutomation() {
      const arr = [];
      const recursiveFunc = (i) => {
        if (this.selectedItems.find((x) => x.Id === i.Id)) {
          arr.push(i);
        }
        if (i.childs && i.childs.length) {
          _.each(i.childs, (c) => {
            recursiveFunc(c);
          });
        }
      };
      this.loadedTask.entityModel.childs.forEach((i) => {
        recursiveFunc(i);
      });
      this.parentData.automationAttachItems = arr;
      this.unselectItems();
    },
    kanbanViewToggle() {
      this.kanbanView = !this.kanbanView;

      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.loadedTask.entityModel.Id,
          Type: "task",
          settings: {
            kanbanView: this.kanbanView,
          },
        },
        init: false,
      });
    },
    makeSort(type) {
      _.each(this.sorts, (object, name) => {
        if (name !== type) {
          object.asc = false;
          object.active = false;
        }
      });
      this.sorts[type].active = true;
      this.sorts[type].asc = !this.sorts[type].asc;
      // this.$emit("loadSectionTasks");
    },
    getStatus(id) {
      let found = null;
      _.each(this.groupProcesses, (i) => {
        if (!found) {
          found = _.find(i.statuses, ["Id", id]);
        }
      });
      return found;
    },
    closeTaskCreateSubmenu() {
      this.openTaskCreateSubmenu = false;
    },
    createSection() {
      this.openTaskCreateSubmenu = false;

      this[c.CREATE_SUBTASK_SECTION]({
        Name: "Новая секция",
        GroupId: this.group.Id,
        ParentTaskId: this.loadedTask.entityModel.Id,
      }).then((res) => {
        res.tasks = [];
        this.parentData.sections.push(res);
        this[GET_TASKS]();
        this[GET_SECTIONS](this.group.Id);
      });
    },
    hideCompletedTasks() {
      this.parentData.hideCompletedTasks = !this.parentData.hideCompletedTasks;
      this[SET_LOCAL_STORAGE_SETTINGS]({
        data: {
          Id: this.loadedTask.entityModel.Id,
          Type: "task",
          settings: {
            hideCompletedTasks: this.parentData.hideCompletedTasks,
          },
        },
        init: false,
      });
      this.loadSectionTasks();
    },
    prepareTasksToSections() {
      const copySections = JSON.parse(JSON.stringify(this.parentData.sections));
      const allTasks = [
        ...this.loadedTask.entityModel.childs,
        ...this.loadedTask.depends.map((i) => {
          i.isDepended = true;
          i.SectionId = i.DependSectionModel ? i.DependSectionModel.Id : null;
          return i;
        }),
      ];

      this.parentData.sections = [];
      this.parentData.sections = _.map(copySections, (i) => {
        if (i.Name === "virtualSection") {
          i.tasks = _.filter(allTasks, ["SectionId", null]);
        } else {
          i.tasks = _.filter(allTasks, ["SectionId", i.Id]);
        }
        return i;
      });
    },
    // async loadDependsTaskSections() {
    //   const arr = [];
    //   const depends = this.loadedTask.depends;
    //   if (depends.length) {
    //     for (const i of depends) {
    //       let sections;
    //       if (i.ParentId && !arr.find((a) => a.ParentTaskId === i.ParentId)) {
    //         sections = await this[c.GET_PARENT_TASK_SECTIONS](i.ParentId);
    //       } else if (!arr.find((a) => a.GroupId === i.GroupId)) {
    //         sections = await this[GET_SECTIONS_BY_ID](i.GroupId);
    //       }
    //       if (sections) {
    //         arr.push(...sections.filter((s) => s.Id === i.SectionId));
    //       }
    //     }
    //     return arr;
    //   }
    //   return [];
    // },
    async loadSectionTasks() {
      this.parentData.gettingTasks = true;
      // const dependsTasksSections = await this.loadDependsTaskSections();
      let needReload = false;
      const self = this;

      function getSection(subtasks) {
        self.parentData.gettingTasks = true;
        self[c.GET_PARENT_TASK_SECTIONS](self.loadedTask.entityModel.Id)
          .then((res) => {
            needReload = res.needReload;
            if (res.needReload) {
              getSection(subtasks);
            } else {
              // const filteredSections = [];
              // [...res, ...dependsTasksSections].forEach((i) => {
              //   let can = true;
              //   if (
              //     i.Name === "virtualSection" &&
              //     filteredSections.find((x) => x.Name === i.Name)
              //   ) {
              //     can = false;
              //   }
              //   if (can) {
              //     filteredSections.push(i);
              //   }
              // });

              self.parentData.sections = res;

              self.prepareTasksToSections();
            }
          })
          .finally(() => {
            if (!needReload) {
              self.parentData.gettingTasks = false;
            }
          });
      }

      this[c.GET_CHILD_TASKS_DETAIL]({
        task: this.loadedTask.entityModel,
        params: {
          hideCompletedTasks: this.parentData.hideCompletedTasks,
        },
      })
        .then(() => {
          getSection();
        })
        .catch(() => (this.parentData.gettingTasks = false));
    },
    closeTasks() {
      queueFetch(this.selectedItems, async (item) => {
        const task = this.findTask(item.Id);
        task.Status = "2";
        const payload = {
          id: item.Id,
          data: {
            Status: "2",
          },
        };
        await this[c.EDIT_TASK](payload);
      }).then(() => {
        this.unselectItems();
      });
    },
    findTask(id) {
      let result = null;
      const recursiveFunc = (i) => {
        if (!result) {
          if (i.Id === id) return (result = i);
          else if (i.childs && i.childs.length) {
            _.each(i.childs, (i) => {
              recursiveFunc(i);
            });
          }
        }
      };
      this.loadedTask.entityModel.childs.forEach((i) => {
        recursiveFunc(i);
      });
      return result;
    },
    ...mapActions("tasks", [
      c.DELETE_TASK,
      c.EDIT_TASK,
      c.CREATE_SUBTASK_SECTION,
      GET_TASKS,
      GET_SECTIONS,
      c.GET_CHILD_TASKS_DETAIL,
      c.GET_PARENT_TASK_SECTIONS,
      GET_SECTIONS_BY_ID,
      GET_DEPENDENCIES_TASKS,
      DELETE_DEPENDENCIES_TASK,
    ]),
    ...mapMutations("tasks", [c.SET_LOCAL_STORAGE_SETTINGS]),
  },
  watch: {
    "loadedTask.entityModel.childs"() {
      this.prepareTasksToSections();
    },
    "loadedTask.depends"() {
      this.prepareTasksToSections();
    },
  },
  destroyed() {
    this.parentData.sections = [];
  },
  components: {
    SubtasksList,
    Kanban,
    ProcessesListSearch,
    AttachTasksGroupsList,
  },
};
</script>

<style scoped></style>
