<template>
  <div
    class="task-create-form__row task-create-form__row--files"
    :class="{ 'task-create-form__row--show-all': showAllFiles }"
  >
    <label class="task-create-form__row-left" style="cursor: pointer">
      <input
        type="file"
        multiple
        hidden
        :disabled="!checkUserTaskRight('add_edit_tasks_files')"
        @change="$emit('filesChange', $event.target.files, 'true')"
      />
      <i class="task-form-icon task-form-icon--9"></i>
    </label>
    <div class="task-create-form__row-right">
      <label class="task-create-form__label task-create-form__blue-title"
        >Документы для подписи
        <input
          type="file"
          multiple
          hidden
          :disabled="!checkUserTaskRight('add_edit_tasks_files')"
          @change="$emit('filesChange', $event.target.files, 'true')"
        />
      </label>
      <div class="task-popup-files">
        <div
          class="sup-chat-files__item"
          v-for="(item, index) in files"
          :key="index"
          :class="{ 'sup-chat-files__item--hidden': index > 6 }"
        >
          <img
            :src="getThumbnailSrc(item)"
            v-if="fileIsImage(item.PathOnDisk || item.Filename)"
            @click="showGalleryImage(item.linkToFile, files)"
          />
          <a
            class="sup-chat-files__item-link"
            download
            v-else
            @click.prevent="
              downloadURI(getFileSrc(item.linkToFile), item.Filename, item.Id)
            "
            :href="getFileSrc(item.linkToFile)"
          >
            <img
              :src="getNonImageTypeFileIcon(item.PathOnDisk || item.Filename)"
            />
            <p>{{ item.Filename }}</p>
          </a>

          <div
            class="sup-chat-files__item-remove"
            v-if="checkUserTaskRight('add_edit_tasks_files')"
            @click="deleteFileFromTask(item)"
          >
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div
          class="sup-chat-files__item sup-chat-files__item--more"
          v-if="files.length > 7 && !showAllFiles"
          @click="showAllFiles = true"
        >
          <span>еще {{ files.length - 7 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { mapState, mapActions } from "vuex";
import * as c from "../../../../../store/tasks/constants";
import { deleteFetchedFile } from "../../../../../mixins/tasks/func";

export default {
  mixins: [common],
  props: ["files", "loadedTask"],
  data() {
    return {
      showAllFiles: false,
    };
  },
  created() {},
  computed: {
    ...mapState("tasks", ["selectedTask"]),
  },
  methods: {
    deleteFileFromTask(file) {
      this[c.DELETE_FILES_FROM_TASK](file).then(() =>
        deleteFetchedFile(file, this.loadedTask)
      );
    },
    ...mapActions("tasks", [c.DELETE_FILES_FROM_TASK]),
  },
};
</script>

<style scoped></style>
