<template>
  <div class="sidebar-draggable-zone">
    <div class="task-form-filter__title" ref="portal-wrapper">
      Группы и папки
      <div
        class="tm-sidebar-control"
        :class="{ 'tm-sidebar-control--show-icons': showCreateFolderPopup }"
      >
        <div
          class="tm-sidebar-control__btn tm-sidebar-control__btn--add"
          v-if="checkUserTaskRight('create_groups')"
          @click="showCreateFolderPopup = !showCreateFolderPopup"
        >
          <svg width="24" height="16" v-if="!showCreateFolderPopup">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#add-dashed-icon"
            ></use>
          </svg>
          <svg width="24" height="16" v-if="showCreateFolderPopup">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="/static/images/tasks/sprite.svg#add-dashed-icon-close"
            ></use>
          </svg>
        </div>
      </div>
      <portal to="common">
        <transition name="fade">
          <CreateFolderAndGroupControl
            @close="showCreateFolderPopup = false"
            @createGroup="createGroup"
            v-if="showCreateFolderPopup"
            v-portal="{ parent: $refs['portal-wrapper'] }"
          />
        </transition>
      </portal>
    </div>
    <draggable
      :group="{ name: 'favorites', put: false }"
      class="task-form-filter__list"
      @end="changeOrder(groups)"
      :list="groups"
      :move="checkMove"
    >
      <SidebarGroupsItem
        v-for="item in groups"
        class="task-form-filter__item draggable"
        :class="{
          'task-form-filter__item--active': isActive(item.GroupId),
          'task-form-filter__item--selected': checkItemSelect(item),
        }"
        :key="item.Id"
        :data-item-type="1"
        :data-item-id="item.GroupId"
        :data-global-group="item.GlobalGroupName"
        :item="item"
        :headerControlElement="headerControlElement"
        @selectItem="(item) => $emit('selectItem', item, 'selectedGroupsArray')"
      />
    </draggable>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import draggable from "vuedraggable";
import * as c from "../../../../store/tasks/constants";
import { getRandomColor } from "../../../../utils";
import common from "../../../../mixins/tasks/common";
import sidebarMixins from "./sidebarMixins";
import { Helpers } from "@/mixins/Helpers";
import CreateFolderAndGroupControl from "@/modules/tasks/components/Sidebar/CreateFolderAndGroupControl";
import SidebarGroupsItem from "@/modules/tasks/components/Sidebar/SidebarGroupsItem";
import * as _ from "lodash";
import { SET_COMMON_MODULE_POPUP } from "../../../../store/tasks/constants";

export default {
  name: "SidebarGroups",
  mixins: [common, sidebarMixins, Helpers],
  props: ["sidebarData", "headerControlElement", "selectedArray"],
  data() {
    return {
      showCreateFolderPopup: false,
    };
  },
  computed: {
    ...mapState("tasks", [
      "groups",
      "settings",
      "favorites",
      "globalGroups",
      "userData",
    ]),
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    checkMove: function (evt) {
      if (evt.draggedContext.element.GlobalGroupName) {
        return !evt.to.classList.contains("task-form-filter__item-childs");
      }
      return true;
    },
    changeOrder(items) {
      // const groups = [];
      // const globalGroups = [];
      const arr = [];
      items.forEach((i) => {
        if (!i.GlobalGroupName) {
          // groups.push(i);
          arr.push({ GroupType: 1, GroupId: i.GroupId });
        } else {
          arr.push({ GroupType: 2, GroupId: i.GroupId });
          // globalGroups.push(i);
        }
      });

      const formData = new FormData();
      _.each(arr, (i, index) => {
        formData.append(`Ids[${index}][GroupId]`, i.GroupId);
        formData.append(`Ids[${index}][GroupType]`, i.GroupType);
      });
      this[c.CHANGE_GLOBAL_GROUP_ORDER](formData);
    },
    createGroup() {
      const payload = { GroupName: "Новая группа", Color: getRandomColor() };
      const UserId = this.userData.Id;
      this[c.CREATE_GROUP]({ payload, UserId }).then((res) => {
        if (res) {
          this.changeGroup(res.data.entityModel);
          if (this.checkUserTaskRight("edit_groups")) {
            this[SET_COMMON_MODULE_POPUP]({
              popup: "groupSettingsPopupOpen",
              value: true,
            });
          }
        }
      });
    },
    closeCreateFolderPopup() {
      this.showCreateFolderPopup = false;
    },
    checkItemSelect(item) {
      return !!this.selectedArray.find((i) => {
        return i.Id === item.Id;
      });
    },
    ...mapActions("tasks", [
      c.CHANGE_ORDER,
      c.CREATE_GROUP,
      c.CHANGE_GLOBAL_GROUP_ORDER,
    ]),
    ...mapMutations("tasks", [SET_COMMON_MODULE_POPUP]),
  },
  components: {
    draggable,
    CreateFolderAndGroupControl,
    SidebarGroupsItem,
  },
};
</script>

<style scoped></style>
