import { render, staticRenderFns } from "./RpsTagsSettings.vue?vue&type=template&id=cdc5bc20&scoped=true&"
import script from "./RpsTagsSettings.vue?vue&type=script&lang=js&"
export * from "./RpsTagsSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdc5bc20",
  null
  
)

export default component.exports