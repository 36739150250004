<template>
  <li>
    <router-link :to="routerParams">{{ item.Name }}</router-link>
  </li>
</template>

<script>
import UrlGeneratorMixin from "../mixins/UrlGeneratorMixin";

export default {
  props: ["item", "categorySlug", "articles"],
  mixins: [UrlGeneratorMixin],
};
</script>

<style scoped></style>
