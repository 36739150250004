<template>
  <div class="popup event-popup" :class="is_open ? 'event-popup_active' : ''">
    <div
      class="popup__wrapper popup__wrapper_co-admin popup__wrapper-ob"
      @click="hidePopupByClickWrap($event)"
    >
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Администрирование шаблонов</h2>
          <div class="popup__close" @click="onCloseSettings">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="tab === 1 ? 'cm-popup-nav__item--active' : ''"
                @click="() => (changeSytem('standart'), (tab = 1))"
              >
                Общие данные
              </div>
              <div
                class="cm-popup-nav__item"
                :class="tab === 1.1 ? 'cm-popup-nav__item--active' : ''"
                @click="tab = 1.1"
              >
                Данные о системе
              </div>
              <div
                class="cm-popup-nav__item"
                :class="tab === 2 ? 'cm-popup-nav__item--active' : ''"
                @click="tab = 2"
              >
                Системы и устройства
              </div>
            </div>
            <ul class="ob-system-tabs" v-if="tab !== 1">
              <li
                :class="{ active: config.system === 'standart' }"
                @click="changeSytem('standart')"
              >
                Standart
              </li>
              <!--              <li-->
              <!--                :class="{ active: config.system === 'economy' }"-->
              <!--                @click="changeSytem('economy')"-->
              <!--              >-->
              <!--                Economy-->
              <!--              </li>-->
              <li
                :class="{ active: config.system === 'qr' }"
                @click="changeSytem('qr')"
              >
                RPS QR
              </li>
            </ul>
            <div
              v-show="tab === 1"
              class="cm-popup__right"
              style="margin-top: 0"
            >
              <div class="table cm-table-2">
                <div class="table__row-wrap">
                  <covers-field />
                  <content-field />
                  <options-field />
                  <total-field />
                  <service-field />
                  <done-work-field />
                  <objects-field />
                </div>
              </div>
            </div>
            <div v-show="tab === 1.1" class="cm-popup__right">
              <div class="table cm-table-2">
                <div class="table__row-wrap">
                  <tasks-field />
                  <advantages-owner-field />
                  <advantages-tenant-field />
                  <advantages-guest-field />
                  <pdf-files-field />
                </div>
              </div>
            </div>
            <div v-show="tab === 2" class="cm-popup__right">
              <devices-tab />
            </div>
          </div>
        </div>
      </div>
    </div>
    <preview :is_open="print.templatesPreview" @close="closePreview"></preview>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as c from "@/store/sales/constants";
import CoversField from "./fields/CoversField";
import ContentField from "./fields/ContentField";
import DoneWorkField from "./fields/DoneWorkField";
import OptionsField from "./fields/OptionsField";
import TotalField from "./fields/TotalField";
import TasksField from "./fields/TasksField";
import AdvantagesOwnerField from "./fields/AdvantagesOwnerField";
import AdvantagesTenantField from "./fields/AdvantagesTenantField";
import AdvantagesGuestField from "./fields/AdvantagesGuestField";
import ServiceField from "./fields/ServiceField";
import ObjectsField from "./fields/ObjectsField";
import PdfFilesField from "./fields/PdfFilesField";
import DevicesTab from "./devicesTab";
import Preview from "./Preview";

export default {
  props: {
    is_open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    ...mapState("sales", ["settingsObject", "preloaders", "print", "config"]),
  },
  methods: {
    onCloseSettings() {
      this[c.RESTORE_SETTINGS_FROM_DUMP]();
      this[c.CHANGE_SYSTEM]("standart");
      this.$emit("onCloseSettings");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onCloseSettings();
      }
    },
    changeSytem(type) {
      this[c.CHANGE_SYSTEM](type);
    },
    closePreview() {
      this.print.templatesPreview = false;
      this.print.pageForPreview = null;
      this[c.RESET_OFFER]();
    },
    ...mapActions("sales", [
      c.GET_SETTINGS,
      c.RESET_OFFER,
      c.RESTORE_SETTINGS_FROM_DUMP,
      c.CHANGE_SYSTEM,
    ]),
  },
  components: {
    CoversField,
    ContentField,
    OptionsField,
    TotalField,
    TasksField,
    AdvantagesOwnerField,
    AdvantagesTenantField,
    AdvantagesGuestField,
    ServiceField,
    ObjectsField,
    DevicesTab,
    DoneWorkField,
    Preview,
    PdfFilesField,
  },
};
</script>
