<template>
  <div class="task-create-form__row task-create-form__row--small">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--6"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__blue-title">
        <div class="task-create-form__name-wrap">
          <p class="task-create-form__label-text">Контакты</p>
          <div class="task-create-form__field-value">
            <p @click="addContact">Добавить</p>
          </div>
        </div>
      </div>

      <div class="task-create-form-contact__wrapper">
        <div
          class="task-create-form-contact"
          v-for="(item, index) in form.TaskContacts"
          :key="index"
        >
          <div class="task-create-form__blue-title">
            <span
              class="task-popup-list__label label-button"
              style="cursor: pointer"
              :class="{ 'label-button--disabled': !item.MainContact }"
              @click="setMainContact(item)"
              >Основной</span
            >
          </div>
          <div class="task-create-form__field">
            <input
              type="text"
              class="input"
              placeholder="ФИО"
              v-model="item.ManualContactFIO"
              @change="$emit('saveAfterEdit', null, () => {}, 'TaskContacts')"
            />
            <input
              type="text"
              class="input"
              placeholder="Должность"
              v-model="item.ManualContactPosition"
              @change="$emit('saveAfterEdit', null, () => {}, 'TaskContacts')"
            />
            <div
              class="task-create-form__btn"
              @click="
                form.TaskContacts.splice(index, 1);
                $emit('saveAfterEdit', null, () => {}, 'TaskContacts');
              "
            >
              <i class="task-form-icon task-form-icon--13"></i>
            </div>
          </div>
          <div class="task-create-form__field">
            <input
              type="text"
              class="input"
              placeholder="Телефон"
              v-mask="'+7 ### ### ## ##'"
              v-model="item.ManualContactPhone"
              @change="$emit('saveAfterEdit', null, () => {}, 'TaskContacts')"
            />
            <input
              type="text"
              class="input"
              placeholder="Email"
              v-model="item.ManualContactEmail"
              @change="$emit('saveAfterEdit', null, () => {}, 'TaskContacts')"
            />
            <div
              class="task-create-form__btn task-create-form__btn--hidden"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <SwitcherForHideField
      v-if="!noSave"
      @saveAfterEdit="
        (formData, callback, field) =>
          $emit('saveAfterEdit', formData, callback, field)
      "
      :form="form"
      :field="'Contacts'"
    />
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { emptyContact } from "../templateData";
import * as _ from "lodash";

import SwitcherForHideField from "./SwitcherForHideField";
export default {
  mixins: [common],
  props: ["form", "noSave"],
  methods: {
    setMainContact(item) {
      _.map(this.form.TaskContacts, (i) => {
        i.MainContact = false;
        return i;
      });
      item.MainContact = true;
      this.$emit("saveAfterEdit", null, () => {}, "TaskContacts");
    },
    addContact() {
      this.form.TaskContacts.push(JSON.parse(JSON.stringify(emptyContact)));
      this.$emit("saveAfterEdit", null, () => {}, "TaskContacts");
    },
  },
  components: {
    SwitcherForHideField,
  },
};
</script>

<style scoped></style>
