import { mapState } from "vuex";

export default {
  data() {
    return {
      tab: 1,
      preloader: false,
    };
  },
  mounted() {
    // if (this.data || this.id === 'create') {
    setTimeout(() => {
      document
        .querySelector(".event-popup")
        .classList.add("event-popup_active");
    }, 100);
    // }
  },
  computed: {
    ...mapState("site", ["parentData"]),
  },
  methods: {
    hidePopupByClickWrap(event, is_setting = false) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup(is_setting);
      }
    },
    closePopup(is_setting = false) {
      document
        .querySelector(".event-popup")
        .classList.remove("event-popup_active");
      setTimeout(() => {
        if (is_setting) {
          this.$emit("closeSettings");
        } else {
          this.$router.push("/commercial_site/" + this.module);
        }
        this.parentData.language = "ru";
      }, 300);
    },
    getTabClassName(tab) {
      if (tab === this.tab) return "cm-popup-nav__item--active";
    },
    setPreloader(val) {
      this.preloader = val;
    },
  },
};
