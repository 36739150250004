<template>
  <div class="popup event-popup event-popup_active kb-popup">
    <div class="popup__wrapper" @mousedown="closePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading" v-if="item">{{ item.Name }}</h2>
          <div class="popup__close" @click="closePopup">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="kb-popup-form" v-if="item">
            <div
              class="kb-popup-form-status"
              v-if="+item.Status === 0 || +item.Status === 1"
            >
              {{
                +item.Status === 0 ? "Ожидает утверждения" : "Заявка отклонена"
              }}
            </div>

            <div class="kb-popup-form__inputs">
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Дата подачи заявки:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="item.Created | formatDate"
                    disabled
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Партнер:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="item.DistributorName"
                    disabled
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Название объекта:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="item.Name"
                    disabled
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Тип объекта:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="getObjectTypeName(item.Type)"
                    disabled
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Файл КП:
                </span>
                <span class="field__content">
                  <a :href="API_ROOT + item.Link" class="kb-link" download>{{
                    item.File
                  }}</a>
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Город:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="item.City"
                    disabled
                  />
                </span>
              </label>
              <label class="field">
                <span
                  class="field__label field__label_align_right clients-common-info__label"
                >
                  Адрес:
                </span>
                <span class="field__content">
                  <input
                    type="text"
                    class="input"
                    :value="item.Address"
                    disabled
                  />
                </span>
              </label>
            </div>
            <GmapMap
              :center="{ lat: +item.Lat, lng: +item.Lng }"
              v-if="item.Lat && item.Lng"
              :zoom="16"
              class="field__map kb-field__map"
            >
              <GmapMarker
                ref="myMarker"
                :position="{ lat: +item.Lat, lng: +item.Lng }"
                :clickable="false"
                :draggable="true"
              />
            </GmapMap>
            <div class="sup-form-btn__wrap" v-if="+item.Status === 0">
              <div
                class="sup-form-btn__btn sup-client-btn sup-client-btn-1"
                @click="setStatus(1)"
              >
                Отклонить
              </div>
              <div
                class="sup-form-btn__btn sup-client-btn sup-client-btn-2"
                @click="setStatus(3)"
              >
                Принять
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { showAlert } from "../../../../utils";
import moment from "moment";
import { getObjectTypeName } from "../../../../mixins/sales/reservation/reservationHelpers";
import { EDIT_REQUEST_OBJECT } from "../../../../store/sales_reservation/constants";
import axios from "axios";
import { API_ROOT } from "../../../../config";
import * as _ from "lodash";

export default {
  data() {
    return {
      item: null,
      API_ROOT,
    };
  },
  created() {
    this.item = _.find(this.requestsObjects, ["Id", this.$route.params.id]);
    if (!this.item) {
      showAlert("error", "", "Запись не найдена");
      this.$router.push("/sales/reservation/requests");
    }
  },
  computed: {
    ...mapState("reserve", ["requestsObjects"]),
  },
  methods: {
    closePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.closePopup();
      }
    },
    closePopup() {
      this.$router.push("/sales/reservation/requests");
    },
    getObjectTypeName(value) {
      return getObjectTypeName(value);
    },
    setStatus(value) {
      const formData = new FormData();
      formData.append("Status", value);
      formData.append("_method", "PATCH");
      axios
        .get(
          API_ROOT +
            "api/sales/RequestsObjects/sendApproveRequestEmail?RequestId=" +
            this.item.Id
        )
        .then(() => {
          this[EDIT_REQUEST_OBJECT]({
            id: this.item.Id,
            data: formData,
            onlyStatus: true,
          }).then(() => (this.item.Status = value));
        })
        .catch(() => {
          showAlert(
            "error",
            "",
            "не удалось отправить уведомление создателю заявки на почту"
          );
        });
    },
    ...mapActions("reserve", [EDIT_REQUEST_OBJECT]),
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style scoped></style>
