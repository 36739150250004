<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name"
              >Преимущества для собственника</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs">
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 1"
          >
            Тц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 2"
          >
            Бц
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 3"
          >
            ЖК
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 4"
          >
            Отели
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 5 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 5"
          >
            Спорт комплексы
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 6 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 6"
          >
            Аэропорты и ЖД
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 7 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 7"
          >
            Парки
          </div>
          <div
            class="cm-popup__tabs-item"
            :class="tab === 8 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 8"
          >
            Паркинг
          </div>
        </div>

        <template v-if="tab === 1">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner.tc"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'tc', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'tc', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'tc', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'tc')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 2">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner.bc"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'bc', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'bc', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'bc', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'bc')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 3">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner.zk"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'zk', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'zk', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'zk', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'zk')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 4">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner
                  .hotels"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'hotels', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'hotels', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'hotels', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'hotels')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 5">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner
                  .sport"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'sport', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'sport', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'sport', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'sport')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 6">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner
                  .air"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'air', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'air', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'air', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'air')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 7">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner
                  .parks"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'parks', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'parks', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'parks', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'parks')"
            >+ Добавить ещё</span
          >
        </template>

        <template v-if="tab === 8">
          <div class="table cm-table-1">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_140">
                  <div class="table__text-block">
                    <p class="table__text">Иконка</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text">Название</p>
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <p class="table__text">Текст</p>
                  </div>
                </div>
              </div>
              <div
                class="table__row table__row_main"
                v-for="(item, index) in settingsObject.json.advantages_owner
                  .others"
                :key="index"
              >
                <div class="table__col table__col_width_140">
                  <label class="table__text-block table__text-block--overflow">
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      class="input"
                      @change="filesChange($event.target, 'others', item.id)"
                    />
                    <img
                      class="co-input-icon"
                      v-if="imageBlobCheck('icons', 'others', item.id)"
                      :src="item.blob"
                    />
                    <img
                      class="co-input-icon"
                      v-else-if="item.img"
                      :src="getImageSrc(item.img, config.system)"
                    />
                    <span
                      class="co-input-icon co-input-icon--empty"
                      v-else
                    ></span>
                  </label>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.name" />
                  </div>
                </div>
                <div class="table__col table__col_flexible">
                  <div class="table__text-block">
                    <input type="text" class="input" v-model="item.desc" />
                  </div>
                </div>
                <div
                  class="table__col table__col-btn"
                  v-if="index > 2"
                  @click="actionOwner('remove', 'others', item.id)"
                >
                  <div class="table__text-block">
                    <span class="remove-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="little-down-text" @click="actionOwner('add', 'others')"
            >+ Добавить ещё</span
          >
        </template>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="showPreview(12, false, getObjectsTypeByTab(tab))"
          >Превью</span
        >
        <span @click="saveSettings">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import * as _ from "lodash";
import { randomId } from "../../../../../mixins/sales/SalesJSONS";
export default {
  mixins: [SalesHelpers],
  data() {
    return {
      tab: 1,
    };
  },

  methods: {
    actionOwner(action, type, id) {
      if (action === "add") {
        this.settingsObject.json.advantages_owner[type].push({
          id: this.settingsObject.json.advantages_owner[type].length + 1,
          name: "",
          img: "",
          desc: "Описание преимущества",
          randomId: randomId(),
        });
      } else if (action === "remove") {
        this.settingsObject.json.advantages_owner[type] = _.reject(
          this.settingsObject.json.advantages_owner[type],
          { id }
        );
        this.settingsObject.images.icons[type] = _.reject(
          this.settingsObject.images.icons[type],
          { id }
        );
        this.settingsObject.blobImages.icons[type] = _.reject(
          this.settingsObject.blobImages.icons[type],
          { id }
        );
        this.settingsObject.json.advantages_owner[type] = _.each(
          this.settingsObject.json.advantages_owner[type],
          (item, index) => {
            item.id = index + 1;
            return item;
          }
        );
      }
      // console.log(this.settingsObject.images)
    },
    filesChange(input, type, id) {
      const fileList = input.files;
      if (fileList.length && this.checkFileExt(fileList[0].name)) {
        this.settingsObject.json.advantages_owner[
          type
        ] = this.settingsObject.json.advantages_owner[type].map((i) => {
          if (i.id === id) {
            // console.log(type, this.settingsObject.images.icons);
            this.settingsObject.images.icons[type].push({
              id,
              file: fileList[0],
            });
            this.settingsObject.blobImages.icons[type].push({
              id,
              file: fileList[0],
            });
            i.img = fileList[0].name;
            const blob = window.URL.createObjectURL(fileList[0]);
            i.blob = blob;
          }
          return i;
        });
        input.value = null;
      }
      // console.log(this.settingsObject.images)
    },
  },
};
</script>
