<template>
  <div class="popup__inner-cont">
    <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
      <div class="pdf-cont" @click="openModal()">
        <Print_12 />
      </div>
      <magnific-popup-modal :config="{ closeOnBgClick: true }" ref="modal">
        <print_12 />
      </magnific-popup-modal>
    </div>
    <div
      v-if="print.changeWindow && getChangesNumericLabel('AdvantagesOwner')"
      class="pdf-settings"
    >
      <div class="pdf-settings__cont scroll-style">
        <div
          class="new-changes-block"
          :key="index"
          v-for="(item, index) in getOptionsChangesArray('AdvantagesOwner')"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <span style="display: none">{{
              (old = findOldOption("AdvantagesOwner", item.randomId))
            }}</span>
            <p class="icons">
              {{ old.name }}
              <span><img :src="getImageSrc(old.img)" alt="" /></span>
            </p>
            <br />
            <p>{{ old.desc }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p class="icons">
            {{ item.name }}
            <span><img :src="getImageSrc(item.img)" alt="" /></span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions(
            'AdvantagesOwner',
            'removed'
          )"
          :key="'1' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p class="icons">
              {{ item.name }}
              <span><img :src="getImageSrc(item.img)" alt="" /></span>
            </p>
            <br />
            <p>{{ item.desc }}</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p>Удалено</p>
        </div>
        <div
          class="new-changes-block"
          v-for="(item, index) in getChangedOptions('AdvantagesOwner', 'added')"
          :key="'k' + index"
        >
          <div class="new-changes-block__row">
            <b>До:</b> <br />
            <p>-</p>
          </div>
          <div class="new-changes-block__row new-changes-block__row--top">
            <b>После:</b> <br />
          </div>
          <p class="icons">
            {{ item.name }}
            <span><img :src="getImageSrc(item.img)" alt="" /></span>
          </p>
          <br />
          <p>{{ item.desc }}</p>
        </div>
      </div>
      <div class="pdf-settings__next">
        <button
          class="btn-full btn-full__green"
          @click="changeStep(searchNextChangePage('AdvantagesOwner'))"
        >
          Следующие изменения
        </button>
      </div>
    </div>
    <div v-else class="pdf-settings">
      <div class="pdf-settings__cont pdf-settings__center">
        <div class="pdf-settings__info-text">
          <div class="pdf-settings__info-text">
            Данные для этой страницы <br />
            редактировать нельзя.
          </div>
        </div>
      </div>
      <div class="pdf-settings__next pdf-settings__btns">
        <button class="btn-full btn-full__gray" @click="changeStep(11)">
          Назад
        </button>
        <button class="btn-full btn-full__green" @click="changeStep(13)">
          Далее
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import Print_12 from "../prints/print_12";

export default {
  mixins: [SalesHelpers],
  computed: {
    ...mapState("sales", ["offerObject"]),
  },
  components: { Print_12 },
};
</script>
