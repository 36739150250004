<template>
  <div class="control-bar">
    <div
      class="control-bar__btn"
      @click="responsibleBubbleOpen = !responsibleBubbleOpen"
    >
      Ответственый
      <transition name="fade">
        <PersonsListSearch
          :emptyPlaceholder="'Убрать ответственного'"
          v-if="responsibleBubbleOpen"
          :outerUsers="localUsersArray"
          @choose="setResponsibleId"
          @close="responsibleBubbleOpen = false"
        />
      </transition>
    </div>
    <div class="control-bar__btn">
      Срок
      <date-input @setDate="setDate" />
    </div>
    <div
      class="control-bar__btn"
      ref="portal-wrapper-tags"
      @click="tagsBubbleOpen = !tagsBubbleOpen"
    >
      <p>Тэги</p>
      <portal to="common">
        <transition name="fade">
          <TagsListSearch
            v-if="tagsBubbleOpen"
            :taskTags="[]"
            @choose="addTag"
            @close="tagsBubbleOpen = false"
            v-portal="{ parent: $refs['portal-wrapper-tags'] }"
          />
        </transition>
      </portal>
    </div>
    <div
      class="control-bar__btn"
      ref="portal-wrapper-settings"
      @click="settingsBubbleOpen = !settingsBubbleOpen"
    >
      <p>Настройки</p>
      <portal to="common">
        <transition name="fade">
          <SingleListSearch
            v-if="settingsBubbleOpen"
            @choose="setSettings"
            @close="settingsBubbleOpen = false"
            :items="settings"
            :selectedItemsArray="[]"
            :fieldNames="{
              idFieldName: 'Id',
              fieldName: 'Name',
            }"
            v-portal="{ parent: $refs['portal-wrapper-settings'] }"
          />
        </transition>
      </portal>
    </div>
    <div
      class="control-bar__btn"
      ref="portal-wrapper-subscribers"
      @click="subscribersBubbleOpen = !subscribersBubbleOpen"
    >
      <p>Подписчики</p>
      <transition name="fade">
        <PersonsListSearch
          :arr="
            selectedSubscribers.map((id) => {
              return { UserId: id };
            })
          "
          v-if="subscribersBubbleOpen"
          :className="'task-bubble--subscriber'"
          :getItemIdField="'UserId'"
          @choose="addSubscriber"
          @deleteItem="deleteSubscriber"
          @close="saveSubscriber"
        />
      </transition>
    </div>
    <div
      class="control-bar__btn control-bar__unselect-tasks-btn"
      @click="$emit('unselectTasks')"
    >
      Отменить выбор
    </div>
    <div
      class="control-bar__btn control-bar__btn_delete"
      @click="$emit('deleteSelectedTasks')"
    >
      Удалить
    </div>
  </div>
</template>

<script>
import common from "@/mixins/tasks/common";
import PersonsListSearch from "@/modules/tasks/components/CommonComponents/PersonsListSearch";
import moment from "moment";
import TagsListSearch from "@/modules/tasks/components/CommonComponents/TagsListSearch/TagsListSearch";
import { mapState } from "vuex";
import { Helpers } from "@/mixins/Helpers";
import saveMixin from "@/modules/tasks/components/TaskCreateForm/mixins/saveMixin";
import taskSettingsMixin from "@/modules/tasks/components/TaskCreateForm/mixins/taskSettingsMixin";
import SingleListSearch from "@/modules/tasks/components/CommonComponents/SingleListSearch/SingleListSearch";
import * as _ from "lodash";

export default {
  name: "ControlBarForSelectedTasks",
  mixins: [common, Helpers, saveMixin, taskSettingsMixin],
  props: [
    "localUsersArray",
    "selected",
    "tasks",
    "templateModel",
    "editedTemplateTasks",
  ],
  data() {
    return {
      responsibleBubbleOpen: false,
      tagsBubbleOpen: false,
      settingsBubbleOpen: false,
      subscribersBubbleOpen: false,
      selectedSubscribers: [],
    };
  },
  computed: {
    ...mapState("tasks", ["tags"]),
  },
  methods: {
    multipleEdit(callback, options) {
      var tasksCopy = [...this.tasks];
      this.selected.forEach((i) => {
        callback(i);
      });
      if (this.templateModel) {
        this.$emit("editMultipleTasks", options, tasksCopy);
      }
      if (this.editedTemplateTasks) {
        this.setMultipleTasksNewOptions(this.selected, options);
      }
      // this.$emit("unselectTasks");
    },
    setResponsibleId(id) {
      this.multipleEdit((i) => (i.TemplateTaskSettings.ResponsibleId = id), {
        ResponsibleId: id,
      });
    },
    setDate(date) {
      const time = date ? moment(date).format("HH:mm:ss") : null;
      this.multipleEdit(
        (i) => {
          i.TemplateTaskSettings.FinishDate = date;
          i.TemplateTaskSettings.FinishTime = time;
        },
        {
          FinishDate: date,
          FinishTime: time,
        }
      );
    },
    addTag(id) {
      this.multipleEdit(
        (i) => {
          if (
            i.TemplateTaskSettings.TagsIds &&
            !i.TemplateTaskSettings.TagsIds.includes(id)
          ) {
            i.TemplateTaskSettings.TagsIds.push(id);
          }
        },
        {
          TagsIds: [id],
        }
      );

      // this.$emit("unselectTasks");
    },
    setSettings(setting) {
      let value = true;
      if (setting.Id === "TaskAccess") {
        value = 2;
      }
      const options = { [setting.Id]: value };

      this.multipleEdit(
        (i) => {
          if (i.TemplateTaskSettings.Settings) {
            i.TemplateTaskSettings.Settings[setting.Id] = value;
          }
        },
        {
          Settings: options,
        }
      );
      this.settingsBubbleOpen = false;
      // this.$emit("unselectTasks");
    },
    addSubscriber(id) {
      if (!_.find(this.selectedSubscribers, id)) {
        this.selectedSubscribers.push(id);
      }
    },
    deleteSubscriber(id) {
      this.selectedSubscribers.splice(this.selectedSubscribers.indexOf(id), 1);
    },
    saveSubscriber() {
      if (this.selectedSubscribers.length) {
        this.multipleEdit(
          (i) => {
            i.TemplateTaskSettings.Subscribers = this.selectedSubscribers;
          },
          {
            Subscribers: this.selectedSubscribers,
          }
        );
      }
      this.subscribersBubbleOpen = false;
      this.selectedSubscribers = [];
      // this.$emit("unselectTasks");
    },
  },
  components: {
    PersonsListSearch,
    TagsListSearch,
    SingleListSearch,
  },
};
</script>

<style scoped>
.control-bar {
  display: flex;
  align-items: center;
  margin-left: 15px;
  flex: 1;
}
.control-bar__btn {
  margin-right: 15px;
  font-size: 13px;
  display: flex;
  color: #007fff;
  cursor: pointer;
  position: relative;
}
.control-bar__unselect-tasks-btn {
  margin-left: auto;
}
.control-bar__btn_delete {
  /*margin-left: auto;*/
  margin-right: 0;
}
</style>

<style>
.control-bar__btn .mx-datepicker {
  position: absolute;
  top: -10px;
  left: 0;
  width: 40px;
}
.control-bar__btn .mx-input-wrapper {
  opacity: 0;
}
</style>
