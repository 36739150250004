import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    countInterval: true,
    count: {
      active: 0,
      all: 0,
      closed: 0,
      new: 0,
      new_messages: 0,
      open: 0,
      wait_client: 0,
      dist_to_admin_open: 0,
      dist_to_admin_closed: 0,
      customer_to_dist_open: 0,
      customer_to_dist_closed: 0,
      responsible: 0,
      without_responsible: 0,
    },
    activeTabs: {
      open: true,
      closed: false,
      responsible: false,
      without_responsible: false,
      dist_to_admin_open: false,
      dist_to_admin_closed: false,
      customer_to_dist_open: false,
      customer_to_dist_closed: false,
    },
    requests: {
      closed: [],
      open: [],
      responsible: [],
      without_responsible: [],
      dist_to_admin_open: [],
      dist_to_admin_closed: [],
      customer_to_dist_open: [],
      customer_to_dist_closed: [],
    },
    selectedRequest: false,
    messages: [],
    searchInput: "",
    preloaders: {
      list: false,
      request: false,
    },
    messagesInterval: false,
    canMessageListUpdate: false,
    canRequestUpdate: true,
    canLoadRequestList: true,
    canLoadRequest: true,
    sort: "LastMessageTime",
    progressBar: null,
    personsForManage: [],
    page: 0,
    commonSettings: {
      selectedItems: [],
      managerForFilter: null,
      cancelRequest: false,
      cancelRequestExecuteFunction: () => {},
      tags: [],
      tagsForSearch: [],
      showWithTasks: false,
      objectForFilter: null,
      statusForFilter: null,
      tagsForFilter: [],
    },
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
