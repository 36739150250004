import axios from "axios";
import { API_ROOT } from "@/config";

const progressBarConfig = (state) => {
  return {
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total > 2000000) {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        state.commit("SET_PROGRESS_BAR", percentCompleted);
      }
    },
  };
};

export default {
  getClientCount() {
    return axios.get(`${API_ROOT}clientsupport/api/Request/Count`);
  },
  getRequests(scope) {
    return axios.get(`${API_ROOT}clientsupport/api/Request?scopes=${scope}`);
  },
  getSupportData() {
    return axios.get(`${API_ROOT}clientsupport/api/Request/Data`);
  },
  createNewClientRequest(state, data) {
    return axios.post(
      `${API_ROOT}clientsupport/api/Request`,
      data,
      progressBarConfig(state)
    );
  },
  getRequestMessages(requestId) {
    return axios.get(`${API_ROOT}clientsupport/api/Request/${requestId}`);
  },
  closeRequest(id) {
    return axios.patch(`${API_ROOT}clientsupport/api/Request/${id}`, {
      StatusId: 5,
    });
  },
  openRequest(id) {
    return axios.patch(`${API_ROOT}clientsupport/api/Request/${id}`, {
      StatusId: 2,
    });
  },
  sendMessage(state, { requestId, message }) {
    return axios.post(
      `${API_ROOT}clientsupport/api/Message/By/${requestId}`,
      message,
      progressBarConfig(state)
    );
  },
  getUserRoles(userId) {
    let url =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080/sand-proxy/"
        : API_ROOT;
    return axios.get(`${url}api2/appSettings/userRoles?userId=${userId}`);
  },
  getPin() {
    return axios.get(`${API_ROOT}clientsupport/api/Auth/pin`);
  },
};
