<template>
  <div class="sup-container sup-container--rps">
    <div class="sup-container_chat">
      <requests-list @windowOpen="onRequestWindowOpen" />
      <request-window
        v-if="requestWindowActive"
        @windowClose="onRequestWindowClose"
      />
      <transition name="popupSlide">
        <ProfileSettingsPopup
          v-if="parentData.profileSettingsPopupOpen"
          :isOpen="parentData.profileSettingsPopupOpen"
          @onClosePopup="onClosePopup('profileSettingsPopupOpen')"
        />
      </transition>
      <new-request
        :is_open="popups.rps.newRequest"
        :type="'rps'"
        :objects="objects"
      />
    </div>
    <vue-gallery-slideshow
      :images="gallery.images"
      :index="gallery.index"
      @close="gallery.index = null"
    ></vue-gallery-slideshow>
    <text-subscribe-bubble-select />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RequestsList from "../components/rps/RequestsList";
import RequestWindow from "../components/rps/RequestWindow";
import NewRequest from "../components/client/NewRequest";
import * as c from "@/store/support/constants";
import * as commonTypes from "@/store/common/constants";
import * as clientTypes from "@/store/clients/constants";
import { GET_SUPPORT_RIGHT } from "../../../store/common/constants";
import { Helpers } from "../../../mixins/Helpers";
import Api from "../../../api/support/rps";
import { GET_GROUPS, GET_OBJECTS } from "../../../store/tasks/constants";
import VueGallerySlideshow from "vue-gallery-slideshow";
import TextSubscribeBubbleSelect from "../../tasks/components/CommonComponents/TextSubscribeBubbleSelect";
import ProfileSettingsPopup from "../../tasks/components/ProfileSettingsPopup";

export default {
  data() {
    return {
      requestWindowActive: false,
    };
  },
  mixins: [Helpers],

  computed: {
    ...mapState("clientSupport", [
      "requestFilters",
      "activeTabs",
      "requests",
      "supportData",
    ]),
    ...mapGetters("clientSupport", ["activeTab", "filteredRequests"]),
    ...mapState("common", ["popups", "rightsLoaded"]),
    ...mapState("rpsSupport", ["commonSettings"]),
    ...mapState("clients", ["objects"]),
    ...mapState("tasks", ["gallery"]),
  },
  methods: {
    ...mapActions("rpsSupport", [
      c.TOGGLE_COUNT_INTERVAL,
      c.GET_COUNT,
      c.GET_REQUESTS,
      c.GET_MANAGERS_PEOPLE_LIST,
    ]),
    ...mapActions("common", [commonTypes.SET_GETTING_NOTIFICATIONS_STATUS]),
    ...mapActions("clientSupport", [c.GET_SUPPORT_DATA]),
    ...mapActions("clients", [
      clientTypes.GET_OBJECTS,
      clientTypes.SET_OBJECTS,
      clientTypes.SILENT_LOAD_OBJECTS,
    ]),
    ...mapActions("tasks", [GET_GROUPS]),
    ...mapActions("tasks", { getObjectsForTask: GET_OBJECTS }),
    ...mapActions("rpsSupport", [c.SET_SELECTED_REQUEST]),
    onClosePopup(popup) {
      this.parentData[popup] = false;
      this.parentData.subTask = null;
      this.parentData.sectionForTaskCreate = null;
    },
    onRequestWindowOpen() {
      this.requestWindowActive = true;
    },
    onRequestWindowClose() {
      this.requestWindowActive = false;
      this[c.SET_SELECTED_REQUEST](false);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const timerId = setInterval(() => {
        if (vm.rightsLoaded) {
          if (vm.checkUserRight(GET_SUPPORT_RIGHT)) {
            vm[c.TOGGLE_COUNT_INTERVAL](true);
            // this[c.GET_SUPPORT_DATA]()
            vm[c.GET_COUNT](true); // включаем интервал
            vm[c.GET_MANAGERS_PEOPLE_LIST](); // включаем интервал
            vm[c.GET_REQUESTS]({ scope: vm.activeTab, start: true }); // забираем заявки
            vm[commonTypes.SET_GETTING_NOTIFICATIONS_STATUS](false);
            vm[GET_GROUPS]();
            vm.getObjectsForTask();
            Api.getTagsList().then((res) => {
              if (res.data && res.data.length) {
                vm.commonSettings.tags = res.data;
              }
            });
            // загружаем объекты для заявки
            const objects = vm.$localStorage.get("objects");
            if (!objects) vm[clientTypes.GET_OBJECTS]();
            else {
              vm[clientTypes.SET_OBJECTS](objects);
              vm[clientTypes.SILENT_LOAD_OBJECTS]();
            }
          } else {
            vm.$router.push("/sales");
          }
          clearInterval(timerId);
        }
      }, 200);
    });
  },
  beforeRouteLeave(to, from, next) {
    this[c.TOGGLE_COUNT_INTERVAL](false);
    next();
  },
  components: {
    ProfileSettingsPopup,
    RequestsList,
    RequestWindow,
    NewRequest,
    VueGallerySlideshow,
    TextSubscribeBubbleSelect,
  },
  props: ["parentData"],
};
</script>
