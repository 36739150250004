import * as c from "./constants";
import Api from "../../api/wiki";
import { showAlert } from "../../utils";
export default {
  [c.GET_CATEGORIES](state, params = null) {
    return new Promise((resolve) => {
      Api.getCategories(params)
        .then((res) => {
          state.commit(c.GET_CATEGORIES, res.data);

          resolve(res.data);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        });
    });
  },
  [c.CREATE_CATEGORY](state, data) {
    return new Promise((resolve) => {
      Api.createCategory(data)
        .then((res) => {
          state.commit(c.CREATE_CATEGORY, res.data.model);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.EDIT_CATEGORY](state, { data, id }) {
    return new Promise((resolve) => {
      Api.editCategory(data, id)
        .then((res) => {
          state.commit(c.EDIT_CATEGORY, res.data.model);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.DELETE_CATEGORY](state, id) {
    return new Promise((resolve) => {
      Api.deleteCategory(id)
        .then(() => {
          state.commit(c.DELETE_CATEGORY, id);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.GET_ARTICLES](state, params) {
    return new Promise((resolve) => {
      Api.getArticles(params)
        .then((res) => {
          state.commit(c.GET_ARTICLES, res.data);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        });
    });
  },
  [c.SEARCH_ARTICLES](state, params) {
    Api.searchArticles(params)
      .then((res) => {
        state.commit(c.SEARCH_ARTICLES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.LOAD_ARTICLE_CHILDS](state, params) {
    return new Promise((resolve) => {
      Api.getChildArticles(params)
        .then((res) => {
          state.commit(c.LOAD_ARTICLE_CHILDS, { data: res.data.posts, params });
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.EDIT_ARTICLE](state, { data, id }) {
    return new Promise((resolve) => {
      Api.editArticle(data, id)
        .then((res) => {
          state.commit(c.EDIT_ARTICLE, res.data.model);
          resolve(res.data.model);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.DELETE_ARTICLE](state, id) {
    return new Promise((resolve) => {
      Api.deleteArticle(id)
        .then(() => {
          state.commit(c.DELETE_ARTICLE, id);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.CREATE_ARTICLE](state, data) {
    return new Promise((resolve) => {
      Api.createArticle(data)
        .then((res) => {
          state.commit(c.CREATE_ARTICLE, res.data.model);
          resolve(res.data.model);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
  [c.READ_ARTICLE](state, data) {
    return new Promise((resolve) => {
      Api.readSharedArticle(data)
        .then((res) => {
          if (res.data.status === "success") {
            resolve(res.data.post);
          } else {
            showAlert("error", "", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.response.data);
        });
    });
  },
};
