import * as c from "./constants";
import * as _ from "lodash";
export default {
  [c.GET_REQUESTS_OBJECTS](state, data) {
    state.requestsObjects = data.items;
    state.requestsCount = data.totalCount;
  },
  [c.ADD_REQUESTS_OBJECTS_TO_LIST](state, data) {
    state.requestsObjects.push(...data.items);
  },
  [c.GET_RESERVED_OBJECTS](state, data) {
    state.reservedObjects = data;
  },
  [c.ADD_RESERVED_OBJECTS_TO_LIST](state, data) {
    state.reservedObjects.push(...data);
  },
  [c.DELETE_RESERVED_OBJECT](state, Id) {
    state.reservedObjects = _.reject(state.reservedObjects, { Id });
  },
  [c.DELETE_REQUEST_OBJECT](state, Id) {
    state.requestsObjects = _.reject(state.requestsObjects, { Id });
    state.requestsCount--;
  },
  [c.EDIT_RESERVED_OBJECT](state, data) {
    state.reservedObjects = _.map(state.reservedObjects, (i) => {
      if (i.Id === data.Id) i = data;
      return i;
    });
  },
  [c.CREATE_RESERVED_OBJECT](state, data) {
    state.reservedObjects.push(data);
  },
};
