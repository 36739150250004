var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"print print--2"},[_c('div',{staticClass:"print__cont pb-0"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__text"},[_c('p',[_vm._v("\n        Дата: "+_vm._s(_vm._f("formatDate")(_vm.offerObject.json.Date))+" "),_c('br'),_vm._v("\n        Кому: "+_vm._s(_vm.offerObject.json.ClientName)),_c('br'),_vm._v("\n        Компания: "+_vm._s(_vm.offerObject.json.CompanyName)+"\n        ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.offerObject.json.ContentText))])])]),_vm._v(" "),_c('div',{staticClass:"print__list"},[_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("информация об объекте и задачи")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("3")])]),_vm._v(" "),(!this.offerObject.json.passPages.schemes)?_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Схема проездов")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm._f("shortNum")(_vm.navCountPage("Schemes")))+"\n      ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Оборудование и опции")]),_vm._v(" "),(!_vm.offerObject.json.passPages.schemes)?_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Schemes + 1)+"\n      ")]):_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("4")])]),_vm._v(" "),_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Итоговая смета и условия")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm._f("shortNum")(_vm.navCountPage("Total")))+"\n      ")])]),_vm._v(" "),(_vm.print.full)?_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Функциональные задачи системы")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Total + 1)+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.print.full)?_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Преимущества")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Total + 2)+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.print.full && !this.offerObject.json.passPages.service)?_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Сервисное обслуживание")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Total + 5)+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.print.full)?_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Выполненные работы")]),_vm._v(" "),_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.OptionsLastPage + 1)+"\n      ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"print__list-row"},[_c('div',{staticClass:"col-auto print__list-badge"},[_c('div',{staticClass:"print__content-badge",style:({
            backgroundImage: ("url(" + (_vm.getimgStaticSrc(
              'sales/print-badge.svg'
            )) + ")"),
          })})]),_vm._v(" "),_c('div',{staticClass:"col print__list-name"},[_vm._v("Контакты вашего менеджера")]),_vm._v(" "),(_vm.print.full)?_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Objects + 1)+"\n      ")]):_c('div',{staticClass:"co-auto ml-auto print__list-page"},[_vm._v("\n        "+_vm._s(_vm.pageNums.Tenant + 3)+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v("2")])])}
var staticRenderFns = []

export { render, staticRenderFns }