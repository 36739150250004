<template>
  <div
    class="popup task-popup event-popup event-popup_active task-popup-parent popup task-popup-detail"
  >
    <div class="popup__wrapper" @click="$emit('close')">
      <div class="popup__content popup__content-wide" @click.stop>
        <div class="popup__header">
          <h2 class="popup__heading">Аналитика</h2>
          <div class="popup__close" @click="$emit('close')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="analytics-table">
          <div class="analytics-table__row">
            <span class="analytics-table__header">Тэги</span>
            <span class="analytics-table__header">Период</span>
            <span class="analytics-table__header">Условие</span>
            <span class="analytics-table__header">Заявки</span>
          </div>
          <div class="analytics-table__row">
            <div v-if="selectedTags.length" class="analytics-table__item">
              <span
                class="task-label"
                v-for="item in selectedTags"
                :style="{ backgroundColor: '#' + item.Color }"
                :key="item.Id"
                @click="toggleTagsSelector"
                >{{ item.TagName }}
              </span>
            </div>
            <span
              v-else
              class="analytics-table__item btn-action"
              @click="toggleTagsSelector"
              >Добавить</span
            >
            <span
              v-if="selectedPeriod !== null"
              class="analytics-table__item analytics-table__datepicker"
            >
              {{ selectedPeriod[0] }} - {{ selectedPeriod[1] }}
              <date-picker
                valueType="format"
                v-model="selectedPeriod"
                :popup-class="'sup-datepicker'"
                confirm
                range
                type="date"
                format="DD-MM-YYYY"
                lang="ru"
              ></date-picker>
            </span>
            <span
              v-else
              class="analytics-table__item btn-action analytics-table__datepicker"
            >
              Указать период
              <date-picker
                valueType="format"
                v-model="selectedPeriod"
                :popup-class="'sup-datepicker'"
                confirm
                range
                type="date"
                format="YYYY-MM-DD"
                lang="ru"
              ></date-picker>
            </span>
            <span
              v-if="selectedDelimiter !== null"
              class="analytics-table__item"
            >
              {{ delimiterValue }}
              <select v-model="selectedDelimiter" class="select-hidden">
                <option :value="1">И</option>
                <option :value="2">ИЛИ</option>
              </select>
            </span>
            <span v-else class="analytics-table__item btn-action">
              Выбрать
              <select v-model="selectedDelimiter" class="select-hidden">
                <option :value="1">И</option>
                <option :value="2">ИЛИ</option>
              </select>
            </span>
            <div class="btn-create__wrapper">
              <a
                href="#"
                class="btn-create"
                :class="createDisabled ? 'btn-create-disabled' : ''"
                @click="createReport"
                >Создать отчёт</a
              >
            </div>
          </div>
          <div v-if="reports.length > 0 && commonSettings.tags.length > 0">
            <div
              v-for="report in reports"
              class="analytics-table__row"
              :key="report.Id"
            >
              <div
                class="analytics-table__item"
                v-if="checkTags(report.Params.TagsId)"
              >
                <span
                  class="task-label"
                  v-for="item in getReportTags(report.Params.TagsId)"
                  :style="{ backgroundColor: '#' + item.Color }"
                  :key="item.Id"
                >
                  {{ item.TagName }}
                  <span
                    v-if="report.Params.Delimiter === 2 && !report.VirtualId"
                    >: {{ getTagCounter(report, item.Id) }}</span
                  >
                </span>
                <span class="button-settings">
                  <svg width="5px" height="16px">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#three-dots"
                    ></use>
                  </svg>
                  <span class="tags-settings__dropdown-content">
                    <ul>
                      <li @click="handleDeleteReport(report)">
                        <span>Удалить</span>
                      </li>
                    </ul>
                  </span>
                </span>
              </div>
              <div v-else class="analytics-table__item">
                <span class="text-error">Ошибка: тэги не найдены</span>
                <span class="button-settings">
                  <svg width="5px" height="16px">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="/static/images/icon.svg#three-dots"
                    ></use>
                  </svg>
                  <span class="tags-settings__dropdown-content">
                    <ul>
                      <li @click="handleDeleteReport(report)">
                        <span>Удалить</span>
                      </li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="analytics-table__item">
                {{ report.Params.Period }}
              </div>
              <div class="analytics-table__item">
                {{ getDelimiterName(report.Params.Delimiter) }}
              </div>
              <div class="analytics-table__item analytics-table__counter">
                {{ getReportCounter(report) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <rps-analytics-tags
        v-show="tagsSelectorOpen"
        @close="toggleTagsSelector"
        @clearTags="resetTags"
        :report-tags="selectedTags"
        @addTag="addSelectedTag"
        @removeTag="removeSelectedTag"
      />
    </div>
  </div>
</template>

<script>
import RpsAnalyticsTags from "./RpsAnalyticsTags";
import * as _ from "lodash";
import { mapGetters, mapState } from "vuex";
import Api from "../../../../api/support/rps";
import { showAlert } from "../../../../utils";

export default {
  name: "RpsAnalyticsWindow",
  components: { RpsAnalyticsTags },
  computed: {
    ...mapState("rpsSupport", ["commonSettings"]),
    ...mapGetters("tasks", ["currentUser"]),
    delimiterValue() {
      if (this.selectedDelimiter === 1) return "И";
      else if (this.selectedDelimiter === 2) return "ИЛИ";
      return false;
    },
    createDisabled() {
      return !(
        this.selectedTags.length &&
        this.selectedPeriod !== null &&
        this.selectedDelimiter !== null
      );
    },
  },
  data() {
    return {
      tagsSelectorOpen: false,
      selectedTags: [],
      selectedPeriod: null,
      selectedDelimiter: null,
      reports: [],
      counter: [],
    };
  },
  mounted() {
    Api.getAnalyticsReports()
      .then((res) => {
        if (res.data && res.data.length) {
          this.reports = res.data;
        }
      })
      .catch(() => {
        showAlert("error", "Произошла ошибка при загрузке отчетов");
      });
    Api.getAnalyticsCounter()
      .then((counter) => {
        if (counter.data && counter.data.length) {
          this.counter = counter.data;
        }
      })
      .catch(() => {
        showAlert("error", "Произошла ошибка при обновлении счетчиков");
      });
  },
  methods: {
    checkTags(tagsId) {
      const tags = this.getReportTags(tagsId);
      return !(tags.includes(undefined) || tags.includes(null));
    },
    createReport() {
      if (
        this.selectedTags.length &&
        this.selectedPeriod !== null &&
        this.selectedDelimiter !== null
      ) {
        let data = {
          Params: {
            TagsId: _.map(this.selectedTags, (tag) => {
              return tag.Id;
            }),
            Period: `${this.selectedPeriod[0]} - ${this.selectedPeriod[1]}`,
            Delimiter: this.selectedDelimiter,
          },
        };
        const virtualId = Math.random().toString(36).substring(7);
        this.reports.unshift({
          ...data,
          VirtualId: virtualId,
        });
        Api.createAnalyticsReport(data)
          .then((res) => {
            if (res.status === 200) {
              this.resetAll();
              Api.getAnalyticsCounter()
                .then((counter) => {
                  if (counter.data && counter.data.length) {
                    this.counter = counter.data;
                    this.handleRemoveTemp(virtualId);
                    this.reports.unshift(res.data);
                  }
                })
                .catch(() => {
                  showAlert(
                    "error",
                    "Произошла ошибка при обновлении счетчиков"
                  );
                });
            } else showAlert("error", "Произошла ошибка при сохранении отчета");
          })
          .catch(() => {
            showAlert("error", "Произошла ошибка при сохранении отчета");
            this.handleRemoveTemp(virtualId);
          });
      }
    },
    getReportCounter(report) {
      const data = _.find(this.counter, (item) => item.FilterId === report.Id);
      if (data) return data.TotalCount;
      else return "...";
    },
    getTagCounter(report, tagId) {
      if (this.counter.length) {
        const data = _.find(
          this.counter,
          (item) => item.FilterId === report.Id
        );
        if (data !== undefined) {
          const counter = _.find(
            data.CountByTags,
            (item) => item.TagId === tagId
          );
          if (counter) return counter.count;
        }
      }
      return 0;
    },
    getDelimiterName(num) {
      if (num === 1) return "И";
      else if (num === 2) return "ИЛИ";
    },
    handleRemoveTemp(virtualId) {
      this.reports = _.filter(this.reports, (item) => {
        return item.VirtualId !== virtualId;
      });
    },
    handleDeleteReport(report) {
      this.reports = _.filter(this.reports, (item) => {
        return report.Id !== item.Id;
      });
      Api.deleteAnalyticsReport(report.Id).catch(() => {
        showAlert("error", "Произошла ошибка при удалении отчета");
        this.reports.push(report);
      });
    },
    toggleTagsSelector() {
      this.tagsSelectorOpen = !this.tagsSelectorOpen;
    },
    addSelectedTag(tag) {
      if (!_.find(this.selectedTags, (item) => item.Id === tag.Id))
        this.selectedTags.push(tag);
      if (tag.ParentTagId) {
        const parentFound = this.getTagById(tag.ParentTagId);
        if (parentFound) this.addSelectedTag(parentFound);
      }
    },
    getReportTags(ids) {
      let result = [];
      _.each(ids, (id) => {
        result.push(this.getTagById(id));
      });
      return result;
    },
    getTagById(id) {
      return findTag(this.commonSettings.tags);

      function findTag(array) {
        let searchTag = undefined;
        _.each(array, (i) => {
          if (i.Id === id) {
            searchTag = i;
            return false;
          } else if (i.child && i.child.length) {
            let tag = findTag(i.child);
            if (tag) {
              searchTag = tag;
              return false;
            }
          }
        });

        return searchTag;
      }
    },
    removeSelectedTag(tag) {
      this.selectedTags = _.filter(
        this.selectedTags,
        (item) => item.Id !== tag.Id
      );
      _.each(tag.child, (child) => this.removeSelectedTag(child));
    },
    resetTags() {
      this.selectedTags = [];
    },
    resetAll() {
      this.selectedTags = [];
      this.selectedPeriod = null;
      this.selectedDelimiter = null;
    },
  },
};
</script>

<style scoped></style>
