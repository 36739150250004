export default {
  methods: {
    shiftSelection(task) {
      const taskIndexInArray = this.tasks.indexOf(task);
      if (this.selected.length) {
        const lastSelectedTaskIndexInArray = this.tasks.indexOf(
          this.selected[this.selected.length - 1]
        );
        const firstIndex =
          lastSelectedTaskIndexInArray < taskIndexInArray
            ? lastSelectedTaskIndexInArray
            : taskIndexInArray;
        const lastIndex =
          lastSelectedTaskIndexInArray > taskIndexInArray
            ? lastSelectedTaskIndexInArray
            : taskIndexInArray;
        this.selected = this.tasks.slice(firstIndex, lastIndex + 1);
      }
    },
  },
};
