<template>
  <div class="popup event-popup">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading" v-if="selectedDistb">
            {{ selectedDistb.Name }}
          </h2>
          <div class="popup__close" @click="close">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>

        <div class="table-spinner" v-show="preloaders.distb || localPreloader">
          <Spinner
            :noFadeIn="true"
            style="margin-left: 190px"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>

        <div class="popup__body" v-if="selectedDistb">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(1)"
                @click="tab = 1"
              >
                Общая информация
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(2)"
                @click="tab = 2"
              >
                Рекивизиты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(3)"
                @click="tab = 3"
              >
                Клиенты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(4)"
                @click="tab = 4"
              >
                Объекты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(5)"
                @click="tab = 5"
              >
                Контакты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(6)"
                @click="tab = 6"
              >
                Тех. обслуживание
              </div>
              <div class="cm-popup__down-btn">
                <div
                  class="button button_grey button_uppercase"
                  @click="agreeToRemove = true"
                >
                  Удалить
                </div>
              </div>
            </div>
            <div v-show="tab === 1" class="cm-popup__right">
              <div class="cm-popup__top-title">Данные о дистрибьюторе</div>
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Наименование:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="selectedDistb.Name"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Контактное лицо:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="contact.ContactName"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Телефон:
                  </span>
                  <span class="field__content">
                    <input
                      type="tel"
                      v-mask="'+#(###)###-##-##'"
                      class="input"
                      v-model="selectedDistb.Phone"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Email:
                  </span>
                  <span class="field__content">
                    <input
                      type="email"
                      class="input"
                      v-model="selectedDistb.Email"
                    />
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateDistb"
                >
                  Сохранить
                </button>
              </div>
            </div>

            <div v-show="tab === 2" class="cm-popup__right">
              <div class="cm-popup__top-title">Реквизиты</div>
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    ИНН:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="selectedDistb.Inn"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    ОКПО:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="selectedDistb.Okpo"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Адрес юридический:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="selectedDistb.Address_juridical_text"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Адрес фактический:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="selectedDistb.Address_physical_text"
                    />
                  </span>
                </label>

                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateDistb"
                >
                  Сохранить
                </button>
              </div>
            </div>

            <div v-show="tab === 3" class="cm-popup__right">
              <div class="table cm-table-1">
                <div class="table__header">
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Клиент</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Контактное лицо</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Телефон</p>
                  </div>
                </div>
                <div
                  class="table__row-wrap"
                  v-if="selectedDistb.clients.length"
                >
                  <div
                    class="table__row table__row_main"
                    :key="index"
                    v-for="(item, index) in selectedDistb.clients"
                  >
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <a
                          :href="'/company/' + item.Id"
                          target="_blank"
                          class="table__text cm-link"
                          >{{ item.Name }}</a
                        >
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.ContactPerson }}</p>
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.ContactPhone }}</p>
                      </div>
                      <div
                        class="table__remove"
                        @click="removeCompanyAttach(item.Id)"
                      >
                        <img src="@/assets/images/remove.png" alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table__row table__row_main">
                  <div class="table__col table__col_width_260">
                    <div
                      class="table__text-block"
                      @click="clickAction"
                      style="cursor: pointer"
                    >
                      <p class="table__text green-font">+ ПРИВЯЗАТЬ</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="tab === 4" class="cm-popup__right">
              <div class="table cm-table-1">
                <div class="table__header">
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Объект</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Адрес</p>
                  </div>
                  <div class="table__col table__col_width_260">
                    <p class="table__header-text">Срок действия лицензии</p>
                  </div>
                </div>
                <div
                  class="table__row-wrap"
                  v-if="selectedDistb.objects.length"
                >
                  <div
                    class="table__row table__row_main"
                    :key="index"
                    v-for="(item, index) in selectedDistb.objects"
                  >
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <a
                          :href="'/object/' + item.Id"
                          target="_blank"
                          class="table__text cm-link"
                          >{{ item.Name }}</a
                        >
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.Address }}</p>
                      </div>
                    </div>
                    <div class="table__col table__col_width_260">
                      <div class="table__text-block">
                        <p class="table__text">{{ item.ExpireGet }}</p>
                      </div>
                      <div
                        class="table__remove"
                        @click="removeObjectAttach(item.Id)"
                      >
                        <img src="@/assets/images/remove.png" alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table__row table__row_main">
                  <div class="table__col table__col_width_260">
                    <div
                      class="table__text-block"
                      @click="clickAction1"
                      style="cursor: pointer"
                    >
                      <p class="table__text green-font">+ ПРИВЯЗАТЬ</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <distributor-contacts v-show="tab === 5" />
            <div v-show="tab === 6" class="cm-popup__right">
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Включить уникальный пин:
                  </span>
                  <span class="field__content">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        v-model="createPin"
                        v-bind:true-value="'1'"
                        v-bind:false-value="'0'"
                        id="createPin"
                      />
                      <label class="onoffswitch-label" for="createPin">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </span>
                </label>
                <div
                  class="field"
                  v-if="createPin === '1' && selectedDistb.Pin"
                >
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Уникальный пин:
                  </span>
                  <span class="field__content">
                    <span class="pin-info">{{ selectedDistb.Pin }}</span>
                  </span>
                </div>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Менять пин-код раз в месяц:
                  </span>
                  <span class="field__content">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        v-model="selectedDistb.UpdatePin"
                        v-bind:true-value="'1'"
                        v-bind:false-value="'0'"
                        id="UpdatePinDist"
                      />
                      <label class="onoffswitch-label" for="UpdatePinDist">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </span>
                </label>

                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updatePin"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="popupSlide">
      <addition-items
        v-if="companiesPopup"
        :items="companies"
        :name="'компаний'"
        :type="'Company'"
        :preloader="innerPopupPreloader"
        @onClose="onClose('companiesPopup')"
        @onAttach="onAttach"
      />
    </transition>

    <transition name="popupSlide">
      <addition-items
        v-if="objectsPopup"
        :items="objects.objects"
        :name="'объектов'"
        :type="'Object'"
        :preloader="innerPopupPreloader"
        @onClose="onClose('objectsPopup')"
        @onAttach="onAttach"
      />
    </transition>

    <v-dialog v-model="agreeToRemove" max-width="290">
      <v-card>
        <v-card-title class="headline">Предупреждение</v-card-title>
        <v-card-text> Уверены, что хотите удалить дистрибьютора ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            flat="flat"
            @click="agreeToRemove = false"
          >
            Нет
          </v-btn>
          <v-btn color="red darken-1" flat="flat" @click="clickAction2">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PopupsPage from "@/mixins/popupsPage";
import { mapState, mapActions } from "vuex";
import { API_ROOT } from "@/config";
import axios from "axios";
import { showAlert } from "@/utils";
import * as c from "@/store/clients/constants";
import Spinner from "vue-spinkit";
import AdditionItems from "./AdditionItems";
import DistributorContacts from "./DistributorContacts";
import { mask } from "vue-the-mask";

export default {
  mixins: [PopupsPage],
  data() {
    return {
      companiesPopup: false,
      objectsPopup: false,
      createPin: "0",
    };
  },
  created() {
    const distbId = this.$route.params.id;
    this.localPreloader = true;

    axios
      .get(`${API_ROOT}api/clients/Distributor/` + distbId)
      .then((res) => {
        this[c.SELECT_DISTB](res.data);
        (this.localPreloader = false),
          (this.createPin = this.selectedDistb.Pin ? "1" : "0");
      })
      .catch(() => {
        showAlert("error", "Ошибка", "Нет такого дистрибьютора");
        this.localPreloader = false;
        this.$router.push("/");
      });
  },
  computed: {
    ...mapState("clients", [
      "selectedDistb",
      "companies",
      "preloaders",
      "objects",
    ]),
    contact() {
      return this.selectedDistb.contacts && this.selectedDistb.contacts.length
        ? this.selectedDistb.contacts[0]
        : {
            ContactName: "",
            Email: "",
            Phone: "",
            New: true,
            DistributorId: this.selectedDistb.Id,
          };
    },
  },
  methods: {
    ...mapActions("clients", [
      c.SELECT_DISTB,
      c.UPDATE_DISTB,
      c.DELETE_DISTB,
      c.ATTACH_COMPANY_TO_DISTB,
      c.ATTACH_OBJECT_TO_DISTB,
      c.CREATE_DISTB_CONTACT,
      c.UPDATE_DISTB_CONTACT,
      c.REMOVE_ATTACH_COMPANY_TO_DISTB,
      c.REMOVE_ATTACH_OBJECT_TO_DISTB,
    ]),
    onClose(type) {
      this[type] = false;
      this.innerPopupOpen = false;
    },
    onAttach(data) {
      let payload;
      this.innerPopupPreloader = true;
      switch (data.type) {
        case "Company":
          payload = {
            DistributorId: this.selectedDistb.Id,
            ClientId: data.itemId,
          };

          this[c.ATTACH_COMPANY_TO_DISTB](payload).finally(() => {
            this.innerPopupPreloader = false;
            this.innerPopupOpen = false;
            this.companiesPopup = false;
          });
          break;

        case "Object":
          payload = {
            DistributorId: this.selectedDistb.Id,
            ObjectId: data.itemId,
          };
          this[c.ATTACH_OBJECT_TO_DISTB](payload).finally(() => {
            this.innerPopupPreloader = false;
            this.innerPopupOpen = false;
            this.objectsPopup = false;
          });
          break;
      }
    },
    updateDistb() {
      if (this.contact.New !== void 0) {
        this[c.CREATE_DISTB_CONTACT](this.contact);
      } else {
        this[c.UPDATE_DISTB_CONTACT](this.contact);
      }
      this[c.UPDATE_DISTB](this.selectedDistb);
    },
    deleteDistb() {
      this[c.DELETE_DISTB](this.selectedDistb.Id).then(() => {
        this.close();
      });
    },
    removeCompanyAttach(id) {
      this[c.REMOVE_ATTACH_COMPANY_TO_DISTB](id);
    },
    removeObjectAttach(id) {
      this[c.REMOVE_ATTACH_OBJECT_TO_DISTB](id);
    },
    updatePin() {
      this.localPreloader = true;
      const updatePin =
        this.selectedDistb.UpdatePin === void 0
          ? "0"
          : this.selectedDistb.UpdatePin;
      axios
        .get(API_ROOT + "api/clients/Distributor/savePin", {
          params: {
            DistributorId: this.selectedDistb.Id,
            createPin: this.createPin,
            updatePin,
          },
        })
        .then((res) => {
          this.selectedDistb.Pin = res.data.Pin;
          this.$notify({
            group: "common",
            title: res.data.status,
            text: res.data.message,
            type: res.data.status,
          });
        })
        .catch((err) => {
          this.$notify({
            group: "common",
            title: "Ошибка",
            text: err.response.data,
            type: "error",
          });
        })
        .finally(() => (this.localPreloader = false));
    },
    clickAction() {
      this.companiesPopup = true;
      this.innerPopupOpen = true;
    },
    clickAction1() {
      this.objectsPopup = true;
      this.innerPopupOpen = true;
    },
    clickAction2() {
      this.agreeToRemove = false;
      this.deleteDistb();
    },
  },
  components: { Spinner, AdditionItems, DistributorContacts },
  directives: { mask },
};
</script>

<style lang="scss" scoped>
.clients-common-info__label {
  width: 145px;
}
.field__content {
  position: relative;
}
.hidden_pin {
  position: absolute;
  left: -127px;
  bottom: -26px;
  font-size: 12px;
  color: #636363;
  span {
    background: #fff;
    padding: 3px 5px;
    text-align: center;
    font-size: 14px;
    color: #202325;
    margin-right: 30px;
  }
}
.pin-info {
  color: #202325;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}
</style>
