<template>
  <div class="popup event-popup">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading" v-if="selectedObject">
            {{ selectedObject.Name }}
          </h2>
          <div class="popup__close" @click="close">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>

          <div
            class="table-spinner"
            v-show="preloaders.object || localPreloader"
          >
            <Spinner
              :noFadeIn="true"
              style="margin-left: 190px"
              name="wave"
              color="#c9eeff"
              width="60"
              height="60"
            />
          </div>
        </div>
        <div class="popup__body" v-if="selectedObject && object">
          <div class="cm-popup">
            <div class="cm-popup__left">
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(1)"
                @click="tab = 1"
              >
                Общая информация
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(2)"
                @click="tab = 2"
              >
                Тех. обслуживание
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(3)"
                @click="tab = 3"
              >
                Контакты
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(4)"
                @click="tab = 4"
              >
                Лицензия
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(5)"
                @click="tab = 5"
              >
                Удаленный доступ
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(6)"
                @click="tab = 6"
              >
                Настройка суперлога
              </div>
              <div
                class="cm-popup-nav__item"
                :class="activeTabClass(7)"
                @click="tab = 7"
              >
                Зоны и Устройства
              </div>

              <div class="cm-popup-nav__client" v-if="getCompanyOfObject">
                КЛИЕНТ: <br />
                <a
                  target="_blank"
                  :href="'/company/' + getCompanyOfObject.Id"
                  class="link"
                  >{{ getCompanyOfObject.Name }}</a
                >
                <div
                  class="button button_grey button_uppercase"
                  @click="unattachCompany"
                >
                  Отвязать
                </div>
              </div>

              <div class="cm-popup-nav__client" v-if="getDistbOfObject">
                ДИСТРИБЬЮТОР: <br />
                <a
                  target="_blank"
                  :href="'/distb/' + getDistbOfObject.Id"
                  class="link"
                  >{{ getDistbOfObject.Name }}</a
                >
                <div
                  class="button button_grey button_uppercase"
                  @click="unattachDistb"
                >
                  Отвязать
                </div>
              </div>

              <div class="cm-popup__down-btn" @click="agreeToRemove = true">
                <div class="button button_grey button_uppercase">
                  Удалить объект
                </div>
              </div>
            </div>
            <div v-show="tab === 1" class="cm-popup__right">
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Наименование:
                  </span>
                  <span class="field__content">
                    <input type="text" class="input" v-model="object.Name" />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    E-mail:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="object.ContactEmail"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Город:
                  </span>
                  <span class="field__content">
                    <input type="text" class="input" v-model="object.City" />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Адрес:
                  </span>
                  <span class="field__content">
                    <input type="text" class="input" v-model="object.Address" />
                  </span>
                </label>
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Дата регистрации:
                  </span>
                  <span class="field__content">
                    <date-picker
                      input-class="input"
                      :format="'YYYY-MM-DD'"
                      lang="ru"
                      v-model="object.RegDate"
                      valueType="format"
                    ></date-picker>
                  </span>
                </label>
                <label class="field cm-gps-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Геолокация:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      class="input"
                      :value="position.lat"
                      @keyup="changeTextLat($event.target.value)"
                    />
                    <input
                      type="number"
                      class="input"
                      :value="position.lng"
                      @keyup="changeTextLng($event.target.value)"
                    />
                  </span>
                  <span class="field__content">
                    <div class="button button_grey" @click="gmapWindow = true">
                      <img src="/static/images/gps.svg" alt="icon" />
                      Указать на карте
                    </div>
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Комментарий:
                  </span>
                  <span class="field__content">
                    <input type="text" class="input" v-model="object.Comment" />
                  </span>
                </label>
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Гарантия до:
                  </span>
                  <span class="field__content">
                    <date-picker
                      input-class="input"
                      :format="'YYYY-MM-DD'"
                      lang="ru"
                      v-model="object.WarrantyDateTo"
                      valueType="format"
                    ></date-picker>
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Договор:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="object.WarrantyContact"
                    />
                  </span>
                </label>

                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateObject"
                >
                  Сохранить
                </button>
              </div>
            </div>
            <div v-show="tab === 2" class="cm-popup__right">
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Номер:
                  </span>
                  <span class="field__content">
                    <input
                      type="text"
                      class="input"
                      v-model="object.ServiceNumber"
                    />
                  </span>
                </label>
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Дата заключения:
                  </span>
                  <span class="field__content">
                    <date-picker
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      input-class="input"
                      lang="ru"
                      v-model="object.ServiceDateStart"
                    ></date-picker>
                  </span>
                </label>
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Действителен до:
                  </span>
                  <span class="field__content">
                    <date-picker
                      :format="'YYYY-MM-DD'"
                      valueType="format"
                      input-class="input"
                      lang="ru"
                      v-model="object.ServiceDateValidTo"
                    ></date-picker>
                  </span>
                </label>
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Тип:
                  </span>
                  <span class="field__content">
                    <div class="select">
                      <select
                        class="select__control"
                        v-model="object.ServiceType"
                      >
                        <option value="0">Ничего</option>
                        <option value="1">Техническая поддержка</option>
                        <option value="2">Техническое обслуживание</option>
                        <option value="3">Удалённая эксплуатация</option>
                        <option value="4">Эксплуатация</option>
                        <option value="5">ПостПНР</option>
                        <option value="6">ПНР</option>
                      </select>
                      <div class="select__button">
                        <svg class="select__caret">
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/static/images/icon.svg#icon_icon-caret"
                          ></use>
                        </svg>
                      </div>
                    </div>
                  </span>
                </label>
                <label
                  class="field cm-calendar-input"
                  v-if="object.ServiceType === '0'"
                >
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Бесплатное обслуживание до:
                  </span>
                  <span class="field__content">
                    <date-picker
                      valueType="format"
                      format="YYYY-MM-DD"
                      type="date"
                      input-class="input"
                      v-model="object.FreeServiceDate"
                      lang="ru"
                    ></date-picker>
                  </span>
                </label>
                <label class="field" v-if="getDistbOfObject">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    ТП осуществляется дистрибьютором:
                  </span>
                  <span class="field__content">
                    <label
                      class="checkbox checkbox_white table__checkbox js-index-checkbox"
                    >
                      <input
                        type="checkbox"
                        class="checkbox__input main_checkbox_input"
                        v-model="object.DistributorMaintance"
                        v-bind:true-value="'1'"
                        v-bind:false-value="'0'"
                      />
                      <span class="checkbox__box main_checkbox_span">
                        <svg class="checkbox__icon">
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/static/images/icon.svg#icon_icon-check"
                          ></use>
                        </svg>
                      </span>
                    </label>
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateObject"
                >
                  Сохранить
                </button>
              </div>
            </div>
            <contacts v-show="tab === 3" />
            <div v-show="tab === 4" class="cm-popup__right">
              <div
                class="cm-popup-form__wrapper cm-popup-form__wrapper--licence"
                v-if="object.PrivKey"
              >
                <label class="field cm-calendar-input">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Активна до:
                  </span>
                  <span class="field__content">
                    <date-picker
                      input-class="input"
                      valueType="format"
                      type="date"
                      :format="'YYYY-MM-DD'"
                      lang="ru"
                      v-model="object.ExpireSet"
                    ></date-picker>
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateLicense(object.ExpireSet)"
                  style="width: 249px; margin-left: 151px"
                >
                  Сохранить
                </button>
              </div>
              <div
                class="cm-popup-form__wrapper cm-popup-form__wrapper--licence"
                v-else
              >
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  style="width: 249px; margin-left: 151px"
                  @click="generateLicense"
                >
                  Сгенерировать PrivateKey
                </button>
              </div>
            </div>
            <div class="cm-popup__right" v-show="tab === 5">
              <div class="cm-popup__tabs">
                <div
                  class="cm-popup__tabs-item"
                  @click="innerTab = 1"
                  :class="innerTab === 1 ? 'cm-popup__tabs-item--active' : ''"
                >
                  ДАННЫЕ СЕРВЕРА
                </div>
                <div
                  class="cm-popup__tabs-item"
                  @click="innerTab = 2"
                  :class="innerTab === 2 ? 'cm-popup__tabs-item--active' : ''"
                >
                  ДАННЫЕ УСТРОЙСТВ
                </div>
              </div>
              <div v-show="innerTab === 1" class="cm-popup-blocks__wrapper">
                <div class="cm-popup-blocks__item">
                  <div class="cpb1-title">Общее</div>
                  <div class="cpb1-text">
                    <div class="cpb1-text__item">
                      <span>Версия Web:</span>
                      <span>{{ object.WebVer }}</span>
                    </div>
                    <div class="cpb1-text__item">
                      <span>Версия БД:</span>
                      <span>{{ object.DbVer }}</span>
                    </div>
                    <div class="cpb1-text__item">
                      <span>Доменное имя:</span>
                      <span
                        ><a
                          :href="'http://' + object.ServerDomain"
                          target="_blank"
                          class="link"
                          >{{ object.ServerDomain }}</a
                        ></span
                      >
                    </div>
                  </div>
                </div>

                <div class="cm-popup-blocks__item">
                  <div class="cpb2-form">
                    <div class="cpb2-form__item">
                      <span>DB3 сервер:</span>
                      <input
                        type="text"
                        class="input"
                        v-model="object.DB3Host"
                      />
                    </div>
                    <div class="cpb2-form__item">
                      <span>DB3 БД:</span>
                      <input
                        type="text"
                        class="input"
                        v-model="object.DB3Name"
                      />
                    </div>
                    <div class="cpb2-form__item">
                      <span>DB3 Логин:</span>
                      <input
                        type="text"
                        class="input"
                        v-model="object.DB3Login"
                      />
                    </div>
                    <div class="cpb2-form__item">
                      <span>DB3 Пароль:</span>
                      <input
                        type="text"
                        class="input"
                        v-model="object.DB3Password"
                      />
                    </div>
                  </div>
                  <div
                    class="cm-client-detail-form__btns cm-client-detail-form__btns-2"
                  >
                    <span @click="resetInputValue(1)">Отменить</span>
                    <span @click="updateObject">Сохранить</span>
                  </div>
                </div>

                <div class="cm-popup-blocks__item">
                  <div class="cpb3__thead">
                    <div class="cpb3__thead-row cpb3__col"></div>
                    <div class="cpb3__thead-row cpb3__col">БД доступ</div>
                    <div class="cpb3__thead-row cpb3__col">TeamViewer</div>
                    <div class="cpb3__thead-row cpb3__col">RDP</div>
                    <div class="cpb3__thead-row cpb3__col">WEB</div>
                  </div>
                  <div class="cpb3__tbody">
                    <div class="cpb3__tbody-item">
                      <div class="cpb3__tbody-item__col cpb3__col">Логин:</div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.DBLogin"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.TWId"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.RDPLogin"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.WEBLogin"
                        />
                      </div>
                    </div>
                    <div class="cpb3__tbody-item">
                      <div class="cpb3__tbody-item__col cpb3__col">Пароль:</div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.DBPassword"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.TWPassword"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.RDPPassword"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.WEBPassword"
                        />
                      </div>
                    </div>
                    <div class="cpb3__tbody-item">
                      <div class="cpb3__tbody-item__col cpb3__col"></div>
                      <div class="cpb3__tbody-item__col cpb3__col"></div>
                      <div class="cpb3__tbody-item__col cpb3__col"></div>
                      <div class="cpb3__tbody-item__col cpb3__col">
                        <input
                          type="text"
                          class="input"
                          v-model="object.RDPHost"
                        />
                      </div>
                      <div class="cpb3__tbody-item__col cpb3__col"></div>
                    </div>
                  </div>
                  <div
                    class="cm-client-detail-form__btns cm-client-detail-form__btns-2 cpb3__btns"
                  >
                    <span @click="resetInputValue(2)">Отменить</span>
                    <span @click="updateObject">Сохранить</span>
                  </div>
                </div>
              </div>
              <devices :objectId="object.Id" v-if="innerTab === 2" />
            </div>
            <super-log :objectId="object.Id" v-if="tab === 6" />
            <div v-show="tab === 7" class="cm-popup__right">
              <div class="cm-popup-form__wrapper">
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Стойка въезда:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      class="input"
                      v-model="object.CountEntranceDevices"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Стойка выезда:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      class="input"
                      v-model="object.CountExitDevices"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Стойка переезда:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      class="input"
                      v-model="object.CountChangeDevices"
                    />
                  </span>
                </label>
                <label class="field">
                  <span
                    class="field__label field__label_align_right clients-common-info__label"
                  >
                    Кассы:
                  </span>
                  <span class="field__content">
                    <input
                      type="number"
                      class="input"
                      v-model="object.CountCashDevices"
                    />
                  </span>
                </label>
                <button
                  type="button"
                  class="button button_green button_uppercase cm-popup-form__btn-submit"
                  @click="updateObject"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="gmapWindow" width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Указать местоположение на карте</span>
        </v-card-title>
        <div class="cm-gmap">
          <div class="field">
            <gmap-autocomplete @place_changed="setPlace" class="input">
            </gmap-autocomplete>
            <button
              class="button button_green button_uppercase button_align_center field__btn"
              @click="usePlace"
              style="margin-left: 10px"
            >
              Найти
            </button>
          </div>
          <GmapMap
            :center="center"
            :zoom="7"
            style="width: 100%; height: 300px"
            @click="centerMark"
          >
            <GmapMarker
              ref="myMarker"
              :position="position"
              @click="center = position"
              :clickable="true"
              :draggable="true"
              @drag="drag"
            />
          </GmapMap>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="gmapWindow = false"
            >Готово</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="agreeToRemove" max-width="290">
      <v-card>
        <v-card-title class="headline">Предупреждение</v-card-title>
        <v-card-text> Уверены, что хотите удалить объект ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            flat="flat"
            @click="agreeToRemove = false"
          >
            Нет
          </v-btn>
          <v-btn color="red darken-1" flat="flat" @click="clickAction">
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PopupsPage from "@/mixins/popupsPage";
import { mapState, mapActions, mapGetters } from "vuex";
import { showAlert } from "@/utils";
import moment from "moment";
import axios from "axios";
import * as c from "@/store/clients/constants";
import Spinner from "vue-spinkit";
// import DatePicker from 'vue2-datepicker'
import Contacts from "./Contacts";
import Devices from "./Devices";
import SuperLog from "./Superlog";
import { API_ROOT } from "../../../config";

export default {
  mixins: [PopupsPage],
  data() {
    return {
      tab: 1,
      innerTab: 1,
      agreeToRemove: false,
      center: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      position: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      object: null,
      initialObjectData: null,
      place: null,
      gmapWindow: false,
    };
  },
  created() {
    const objectId = this.$route.params.id;
    this.localPreloader = true;
    axios
      .get(`${API_ROOT}api/clients/Object/` + objectId)
      .then((res) => {
        const object = res.data;
        this.object = object;
        this.initialObjectData = { ...object };
        this[c.SELECT_OBJECT](this.object);

        this.position = this.center = {
          lat: +this.object.Latitude,
          lng: +this.object.Longitude,
        };
        this.localPreloader = false;
      })
      .catch(() => {
        showAlert("error", "Ошибка", "Нет такого объекта");
        this.localPreloader = false;
        this.$router.push("/");
      });
  },
  computed: {
    ...mapState("clients", ["objects", "selectedObject", "preloaders"]),
    ...mapGetters("clients", ["getCompanyOfObject", "getDistbOfObject"]),
  },
  methods: {
    ...mapActions("clients", [
      c.SELECT_OBJECT,
      c.UPDATE_OBJECT,
      c.DELETE_OBJECT,
      c.UPDATE_LICENSE,
      c.GENERATE_LICENSE,
      c.PATCH_OBJECT,
      c.REMOVE_ATTACH_OBJECT_TO_DISTB,
    ]),
    // <--google maps func |____
    // ||-------------------------------------------------
    drag(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    centerMark(location) {
      this.center = this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    changeTextLat(val) {
      this.position.lat = +val;
      this.center.lat = +val;
    },
    changeTextLng(val) {
      this.position.lng = +val;
      this.center.lng = +val;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.center = this.position = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.place = null;
      }
    },
    // ||-----------------------------------------------/>
    resetInputValue(blockNum) {
      switch (blockNum) {
        case 1:
          this.object.DB3Password = this.initialObjectData.DB3Password;
          this.object.DB3Login = this.initialObjectData.DB3Login;
          this.object.DB3Name = this.initialObjectData.DB3Name;
          this.object.DB3Host = this.initialObjectData.DB3Host;
          break;
        case 2:
          this.object.DBLogin = this.initialObjectData.DBLogin;
          this.object.TWId = this.initialObjectData.TWId;
          this.object.RDPLogin = this.initialObjectData.RDPLogin;
          this.object.WEBLogin = this.initialObjectData.WEBLogin;
          this.object.DBPassword = this.initialObjectData.DBPassword;
          this.object.TWPassword = this.initialObjectData.TWPassword;
          this.object.RDPPassword = this.initialObjectData.RDPPassword;
          this.object.WEBPassword = this.initialObjectData.WEBPassword;
          this.object.RDPHost = this.initialObjectData.RDPHost;
          break;
      }
    },
    updateObject() {
      this.object.Latitude = String(this.position.lat).substr(0, 9);
      this.object.Longitude = String(this.position.lng).substr(0, 9);
      this.object.RegDate = this.object.RegDate
        ? moment(this.object.RegDate).format("YYYY-MM-DD")
        : "";
      if (!moment(this.object.FreeServiceDate).isValid()) {
        this.object.FreeServiceDate = "";
      } else {
        this.object.FreeServiceDate = moment(
          this.object.FreeServiceDate
        ).format("YYYYMMDD 14:14:14");
      }
      this.object.ServiceDateStart = this.object.ServiceDateStart
        ? moment(this.object.ServiceDateStart).format("YYYY-MM-DD")
        : "";
      this.object.ServiceDateValidTo = this.object.ServiceDateValidTo
        ? moment(this.object.ServiceDateValidTo).format("YYYY-MM-DD")
        : "";

      this.initialObjectData = { ...this.object };
      this[c.UPDATE_OBJECT](this.object).then(() => {
        this.object.FreeServiceDate = this.object.FreeServiceDate
          ? moment(this.object.FreeServiceDate, "YYYYMMDD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "";
      });
    },
    updateLicense(date) {
      const formData = new FormData();
      formData.append("ExpireSet", moment(date).format("DD.MM.YYYY"));
      this[c.UPDATE_LICENSE](formData);
    },
    generateLicense() {
      this[c.GENERATE_LICENSE]();
    },
    deleteObject() {
      this[c.DELETE_OBJECT]().then(() => {
        this.close();
      });
    },
    unattachCompany() {
      this.localPreloader = true;
      const payload = {
        id: this.object.Id,
        payload: { CompanyID: null },
      };
      this[c.PATCH_OBJECT](payload).finally(() => {
        this.localPreloader = false;
      });
    },
    unattachDistb() {
      this[c.REMOVE_ATTACH_OBJECT_TO_DISTB](this.object.Id);
    },
    clickAction() {
      this.agreeToRemove = false;
      this.deleteObject();
    },
  },
  components: {
    Spinner,
    // DatePicker,
    Contacts,
    Devices,
    SuperLog,
  },
};
</script>

<style scoped>
.mx-datepicker {
  width: 100%;
}
</style>
