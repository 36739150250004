<template>
  <div class="popup-image" :class="is_open ? 'popup-image--open' : ''">
    <div class="popup-image__close-zone" @click="close()"></div>
    <div class="popup-image__image">
      <div class="popup__close" @click="close()">
        <svg class="popup__close-icon">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/static/images/icon.svg#icon_icon-close"
          ></use>
        </svg>
      </div>
      <print_2 v-if="print.pageForPreview === 2" />
      <print_18 v-if="print.pageForPreview === 18" />
      <div
        class="pdf-cont-scroll pdf-cont--ml-s scroll-style"
        v-if="print.pageForPreview === 10"
      >
        <print_10 />
      </div>
      <print_11 v-if="print.pageForPreview === 11" />
      <print_12 v-if="print.pageForPreview === 12" />
      <print_13 v-if="print.pageForPreview === 13" />
      <print_14 v-if="print.pageForPreview === 14" />
      <template v-if="print.pageForPreview === 15">
        <div class="popup-image__preview">
          <print_15 />
          <print_16 />
        </div>
      </template>
      <print_19 v-if="print.pageForPreview === 19" />
      <print_6 v-if="print.pageForPreview === 6" />
      <div class="pdf-cont-scroll pdf-cont--ml-s scroll-style">
        <print_7 v-if="print.pageForPreview === 7" />
        <print_8 v-if="print.pageForPreview === 8" />
        <print_9 v-if="print.pageForPreview === 9" />
        <print_5 v-if="print.pageForPreview === 5" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import print_2 from "../builder/prints/print_2";
import print_18 from "../builder/prints/print_18";
import print_10 from "../builder/prints/print_10";
import print_11 from "../builder/prints/print_11";
import print_12 from "../builder/prints/print_12";
import print_13 from "../builder/prints/print_13";
import print_14 from "../builder/prints/print_14";
import print_15 from "../builder/prints/print_15";
import print_16 from "../builder/prints/print_16";
import print_19 from "../builder/prints/print_19";
import print_6 from "../builder/prints/print_6";
import print_7 from "../builder/prints/print_7";
import print_8 from "../builder/prints/print_8";
import print_9 from "../builder/prints/print_9";
import print_5 from "../builder/prints/print_5";

export default {
  props: {
    is_open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("sales", ["print"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  components: {
    print_2,
    print_18,
    print_5,
    print_10,
    print_11,
    print_12,
    print_13,
    print_14,
    print_15,
    print_16,
    print_19,
    print_6,
    print_7,
    print_8,
    print_9,
  },
};
</script>

<style scoped lang="scss">
.print {
  background-color: #fff;
  transform: scale(0.9);
}
.popup-image {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.46);
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  /*transition: .3s;*/
  &__close-zone {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
  }
  &--open {
    z-index: 100;
    opacity: 1;
    pointer-events: all;
  }
  .popup__close-icon {
    fill: #fff;
  }
  .popup__close {
    border: 2px solid #fff;
    position: absolute;
    right: 50px;
    top: 50px;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: flex;
  }
  &__image {
    height: 80%;
    max-width: 80%;
    margin-top: -7%;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      z-index: 1;
    }
  }
}
</style>
