<template>
  <div class="simple-select-list" v-click-outside="close">
    <div
      class="simple-select-list__item"
      v-for="(item, index) in items"
      :key="index"
      @click="choose(item)"
    >
      {{ item.Name }}
    </div>
    <div
      class="simple-select-list__item simple-select-list__item--empty"
      v-if="!items.length"
    >
      Список пуст
    </div>
  </div>
</template>

<script>
import { Helpers } from "../../../../mixins/Helpers";

export default {
  name: "SimpleSelectList",
  mixins: [Helpers],
  props: ["items"],
  created() {
    setTimeout(() => {
      if (!this.items.length) {
        this.close();
      }
    }, 500);
  },
  methods: {
    choose(item) {
      this.$emit("choose", item.Id);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
