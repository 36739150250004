<template>
  <div>
    <div
      class="cm-popup__tabs cm-popup__tabs--no-shadows"
      v-if="config.system !== 'qr'"
    >
      <div
        class="cm-popup__tabs-item"
        :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
        @click="tab = 1"
      >
        КАРТЫ
      </div>
      <div
        class="cm-popup__tabs-item"
        :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
        @click="tab = 4"
      >
        БИЛЕТЫ
      </div>

      <template v-if="config.system !== 'economy'">
        <div
          class="cm-popup__tabs-item"
          :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
          @click="tab = 2"
        >
          ЖЕТОНЫ
        </div>
        <div
          class="cm-popup__tabs-item"
          :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
          @click="tab = 3"
        >
          Жетоны (двстр.)
        </div>
      </template>
    </div>

    <div class="table cm-table-2 cm-table--no-border" v-if="tab === 1">
      <div class="table__row-wrap">
        <system-type-image-field :cat="'cards'" />
        <entry-module-field :cat="'cards'" />
        <exit-module-field :cat="'cards'" />
        <payment-module-field :cat="'cards'" />
        <additional-module-field :cat="'cards'" />
      </div>
    </div>
    <div class="table cm-table-2 cm-table--no-border" v-if="tab === 2">
      <div class="table__row-wrap">
        <system-type-image-field :cat="'tokens'" />
        <entry-module-field :cat="'tokens'" />
        <exit-module-field :cat="'tokens'" />
        <payment-module-field :cat="'tokens'" />
        <additional-module-field :cat="'tokens'" />
      </div>
    </div>
    <div class="table cm-table-2 cm-table--no-border" v-if="tab === 3">
      <div class="table__row-wrap">
        <system-type-image-field :cat="'tokensdbl'" />
        <entry-module-field :cat="'tokensdbl'" />
        <exit-module-field :cat="'tokensdbl'" />
        <payment-module-field :cat="'tokensdbl'" />
        <additional-module-field :cat="'tokensdbl'" />
      </div>
    </div>
    <div class="table cm-table-2 cm-table--no-border" v-if="tab === 4">
      <div class="table__row-wrap">
        <system-type-image-field :cat="'tickets'" />
        <entry-module-field :cat="'tickets'" />
        <exit-module-field :cat="'tickets'" />
        <payment-module-field :cat="'tickets'" />
        <additional-module-field :cat="'tickets'" />
      </div>
    </div>
  </div>
</template>

<script>
import EntryModuleField from "./fields/EntryModuleField";
import ExitModuleField from "./fields/ExitModuleField";
import PaymentModuleField from "./fields/PaymentModuleField";
import AdditionalModuleField from "./fields/AdditionalModuleField";
import SystemTypeImageField from "./fields/SystemTypeImageField";
import { mapState } from "vuex";

export default {
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    ...mapState("sales", ["config"]),
  },
  components: {
    EntryModuleField,
    ExitModuleField,
    PaymentModuleField,
    AdditionalModuleField,
    SystemTypeImageField,
  },
  watch: {
    "config.system"() {
      this.tab = 1;
    },
  },
};
</script>
