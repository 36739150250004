<template>
  <div
    class="popup event-popup lk-popup lk-popup--profile"
    :class="is_open ? 'event-popup_active' : ''"
  >
    <div class="popup__wrapper">
      <div class="popup__content">
        <div class="dropzone"></div>
        <div class="popup__header">
          <h2 class="popup__heading">Новая заявка</h2>
          <div class="popup__close" @click="hideThisPopup(type, 'newRequest')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="input-group">
            <form class="sup-form" onsubmit="return false;">
              <div class="clients-common-info__content">
                <div class="sup-input-group">
                  <span class="sup-input-group__label">Название:</span>
                  <div class="sup-input-group__input">
                    <input
                      type="text"
                      class="input"
                      v-model="name"
                      maxlength="70"
                      placeholder="Коротко опишите проблему"
                    />
                  </div>
                </div>
                <div class="sup-input-group">
                  <span class="sup-input-group__label">Категория:</span>
                  <div class="sup-input-group__input sup-input-group__dropdown">
                    <input
                      type="text"
                      class="input"
                      disabled
                      required
                      :value="category.value"
                      placeholder="Укажите категорию"
                    />
                    <span
                      class="fake-input"
                      @click="isDropdownOpen = !isDropdownOpen"
                    ></span>
                    <div
                      class="sup-input-group__arrow-btn"
                      @click="isDropdownOpen = !isDropdownOpen"
                    >
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="sup-dropdown"
                      :class="isDropdownOpen ? 'sup-dropdown--open' : ''"
                    >
                      <template v-if="is_client">
                        <span
                          class="sup-dropdown__item"
                          :key="id"
                          :data-id="id"
                          v-for="(item, id) in supportData.CategoryId"
                          @click="
                            () => {
                              category.value = item;
                              category.id = id;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          {{ item }}
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="sup-dropdown__item"
                          @click="
                            () => {
                              category.value = 'Проблема с оборудованием';
                              category.id = 5;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          Проблема с оборудованием
                        </span>
                        <span
                          class="sup-dropdown__item"
                          @click="
                            () => {
                              category.value = 'Проблема с рабочей станцией';
                              category.id = 6;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          Проблема с рабочей станцией
                        </span>
                        <span
                          class="sup-dropdown__item"
                          @click="
                            () => {
                              category.value = 'Проблема с ЛК Арендатора';
                              category.id = 7;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          Проблема с ЛК Арендатора
                        </span>
                        <span
                          class="sup-dropdown__item"
                          @click="
                            () => {
                              category.value = 'Я не понимаю как это работает';
                              category.id = 8;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          Я не понимаю как это работает
                        </span>
                        <span
                          class="sup-dropdown__item"
                          @click="
                            () => {
                              category.value = 'Предложение о доработке';
                              category.id = 9;
                              isDropdownOpen = false;
                            }
                          "
                        >
                          Предложение о доработке
                        </span>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="sup-input-group" v-if="!is_client">
                  <span class="sup-input-group__label">Объект:</span>
                  <div class="sup-input-group__input sup-input-group__dropdown">
                    <input
                      type="text"
                      class="input"
                      id="objectField"
                      required
                      v-model="objectInput"
                      placeholder="Укажите объект"
                    />
                    <span
                      class="fake-input"
                      @click="isDropdownObjectsOpen = !isDropdownObjectsOpen"
                    ></span>
                    <div
                      class="sup-input-group__arrow-btn"
                      @click="isDropdownObjectsOpen = !isDropdownObjectsOpen"
                    >
                      <svg class="select__caret">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-caret"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="sup-dropdown"
                      :class="isDropdownObjectsOpen ? 'sup-dropdown--open' : ''"
                    >
                      <span
                        class="sup-dropdown__item"
                        :key="id"
                        :data-id="id"
                        v-for="(item, id) in filteredObjects"
                        @click="
                          () => {
                            object.value = item.Name;
                            object.id = item.Id;
                            isDropdownObjectsOpen = false;
                            objectInput = item.Name;
                          }
                        "
                      >
                        {{ item.Name }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="sup-input-group">
                  <span class="sup-input-group__label">Описание:</span>
                  <div class="sup-input-group__input">
                    <textarea
                      class="textarea"
                      required
                      placeholder="Опишите максимально подробно проблему"
                      v-model="desc"
                    ></textarea>
                  </div>
                </div>
                <div class="sup-input-group">
                  <span class="sup-input-group__label"
                    >Изображения и Файлы:</span
                  >
                  <div class="sup-file__wrap">
                    <template v-if="files.length">
                      <div
                        class="sup-file"
                        v-for="(item, index) in files"
                        :key="index"
                      >
                        <div class="sup-file__image">
                          <img :src="item.img" alt="" />
                        </div>
                        <span class="sup-file__name">{{ item.name }}</span>
                        <div
                          class="sup-file__remove"
                          @click="removeFile(item.id)"
                        >
                          <img src="@/assets/images/trash.svg" alt="icon" />
                        </div>
                      </div>
                    </template>
                    <div class="loadfile-indicator" v-if="progressBar">
                      <span :style="`width:${progressBar}%`"></span>
                    </div>
                  </div>

                  <label class="sup-file__add">
                    <input
                      type="file"
                      multiple
                      hidden
                      @change="filesChange($event.target.files)"
                    />
                    <span class="sup-file__add-icon">+</span>
                    <span class="sup-file__add-text">Добавить</span>
                  </label>
                </div>
              </div>
              <div class="sup-form-btn__wrap">
                <div
                  class="sup-form-btn__btn sup-client-btn sup-client-btn-1"
                  @click="hideThisPopup('client', 'newRequest')"
                >
                  Отмена
                </div>
                <div
                  @click="upload"
                  class="sup-form-btn__btn sup-client-btn sup-client-btn-2"
                >
                  Создать заявку
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/mixins/popup";
import { mapState, mapActions } from "vuex";
import * as _ from "lodash";
import * as c from "@/store/support/constants";
import { scrollTo } from "@/utils/scrollTo";
export default {
  props: {
    type: {
      default: "",
      type: String,
    },
    objects: null,
  },
  mixins: [Popup],
  data() {
    return {
      name: "",
      category: { value: "", id: "" },
      object: { value: "", id: "" },
      desc: "",
      files: [],
      isDropdownOpen: false,
      isDropdownObjectsOpen: false,
      objectInput: "",
    };
  },
  mounted() {
    const self = this;
    const dropzoneWrapper = document.querySelector(".dropzone");
    let isDragged = false;

    document.querySelector(".sup-form").ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      return false;
    };
    const objectInput = document.querySelector("#objectField");
    if (objectInput) {
      objectInput.addEventListener("keyup", () => {
        this.objectInput = objectInput.value;
      });
    }

    dropzoneWrapper.ondrop = function (event) {
      event.preventDefault();
      dropzoneWrapper.classList.remove("dropzone-hover");
      dropzoneWrapper.style.display = "none";
      self.filesChange(event.dataTransfer.files);
      isDragged = false;
    };
    dropzoneWrapper.ondragover = function () {
      dropzoneWrapper.classList.add("dropzone-hover");
      dropzoneWrapper.style.display = "block";
      isDragged = true;
      setTimeout(() => (isDragged = false), 400);
      return false;
    };

    dropzoneWrapper.ondragleave = function () {
      setTimeout(() => {
        if (!isDragged) {
          dropzoneWrapper.classList.remove("dropzone-hover");
          dropzoneWrapper.style.display = "none";
        }
      }, 400);
      return false;
    };
  },
  computed: {
    ...mapState("clientSupport", [
      "client",
      "isRequestUploaded",
      "supportData",
      "progressBar",
    ]),
    ...mapState("common", ["is_client"]),
    filteredObjects() {
      if (this.objects && this.objects.objects && this.objects.objects.length) {
        return this.objects.objects.filter((i) => {
          const re = new RegExp(this.objectInput, "gi");
          return re.test(i.Name);
        });
      }
      return false;
    },
  },
  methods: {
    ...mapActions("clientSupport", [
      c.CREATE_NEW_CLIENT_REQUEST,
      c.TOGGLE_REQUEST_STATUS,
    ]),
    filesChange(fileList) {
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.files.push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
      const requestWindow = document.querySelector(
        ".lk-popup--profile .clients-common-info__content"
      );
      setTimeout(() => {
        scrollTo(requestWindow, requestWindow.scrollHeight, 500);
      }, 100);
    },
    removeFile(id) {
      this.files = _.reject(this.files, { id });
    },
    upload() {
      const formData = new FormData();

      if (!this.name.length) {
        alert("Напишите название заявки");
        return;
      }
      if (!this.category.value.length) {
        alert("Выберите категорию");
        return;
      }
      if (!this.desc.length) {
        alert("Напишите описание проблемы");
        return;
      }

      if (!this.is_client) {
        if (!this.object.value.length) {
          alert("Выберите объект");
          return;
        }
        formData.append("ObjectId", this.object.id);
      }
      formData.append("Subject", this.name);
      formData.append("CategoryId", this.category.id);
      formData.append("Description", this.desc);

      _.each(this.files, (i, index) =>
        formData.append("files[" + index + "]", i.file, i.file.name)
      );

      this[c.CREATE_NEW_CLIENT_REQUEST](formData);
    },
  },
  watch: {
    isRequestUploaded(uploaded) {
      if (uploaded) {
        this.hideThisPopup(this.type, "newRequest");
      }
    },
    isDropdownObjectsOpen(val) {
      if (val) {
        document.querySelector("#objectField").focus();
      }
    },
  },
};
</script>
