<template>
  <div class="popup__cont popup__cont--scroll scroll-style">
    <div class="system-type">
      <h3 class="system-type__h3">УКАЖИТЕ ТИП СИСТЕМЫ</h3>
      <ul class="system-type__systems">
        <li
          :class="{ active: offerObject.json.System === 'standart' }"
          @click="changeSytem('standart')"
        >
          Standart
        </li>
        <!--        <li-->
        <!--          :class="{ active: offerObject.json.System === 'economy' }"-->
        <!--          @click="changeSytem('economy')"-->
        <!--        >-->
        <!--          Econom-->
        <!--        </li>-->
        <li
          :class="{ active: offerObject.json.System === 'qr' }"
          @click="changeSytem('qr')"
        >
          RPS QR
        </li>
      </ul>
      <div class="system-type__list" v-if="offerObject.json.System !== 'qr'">
        <template v-if="offerObject.json.System !== 'economy'">
          <button class="system-type__item" @click="setSystemType('Cards')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/cards.png" alt="" />
            </div>
            <div class="system-type__name">Карты</div>
          </button>
          <button class="system-type__item" @click="setSystemType('Tickets')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/tickets.png" alt="" />
            </div>
            <div class="system-type__name">Билеты</div>
          </button>
          <button class="system-type__item" @click="setSystemType('Tokens')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/coins.png" alt="" />
            </div>
            <div class="system-type__name">Жетоны</div>
          </button>
          <button class="system-type__item" @click="setSystemType('Tokensdbl')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/coins.png" alt="" />
            </div>
            <div class="system-type__name">Жетоны (двстр)</div>
          </button>
        </template>
        <template v-else>
          <button class="system-type__item" @click="setSystemType('Cards')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/economy-type.jpg" alt="" />
            </div>
            <div class="system-type__name">Карты</div>
          </button>
          <button class="system-type__item" @click="setSystemType('Tickets')">
            <div class="system-type__img">
              <img src="@/assets/images/sales/economy-type.jpg" alt="" />
            </div>
            <div class="system-type__name">Билеты</div>
          </button>
        </template>
      </div>
      <div class="system-type__list" v-else>
        <button class="system-type__item" @click="setSystemType('Cards')">
          <div class="system-type__img">
            <img src="@/assets/images/sales/qr-type.jpg" alt="" />
          </div>
          <div class="system-type__name">RPS QR</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import moment from "moment";
import { copy, modulesArray } from "../../../../../mixins/sales/SalesJSONS";
import * as c from "../../../../../store/sales/constants";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers],

  computed: {
    ...mapState("sales", [
      "offerObject",
      "settingsObject",
      "rateValue",
      "config",
      "settingsObjectStandart",
    ]),
    ...mapState("common", ["userData"]),
  },
  methods: {
    setSystemType(type) {
      if (this.settingsObject.json.fromServer) {
        this.offerObject.json.SystemType = type;
        this.offerObject.json.SystemImg = copy(
          this.settingsObject.json[type.toLowerCase() + "Img"]
        );
        _.each(modulesArray, (module) => {
          this.offerObject.json.Modules[module] = copy(
            this.settingsObject.json.devices[type.toLowerCase()][
              module.toLowerCase()
            ]
          );

          if (
            this.offerObject.json.System === "economy" &&
            module === "Payment"
          ) {
            this.offerObject.json.Modules.Payment.cash_2 = [];
          }
          if (["Entry", "Exit"].includes(module)) {
            this.offerObject.json.Modules[
              module
            ].img_rack = this.settingsObject.json.devices[type.toLowerCase()][
              module.toLowerCase()
            ].img_rack[0].img;
            this.offerObject.json.Modules[
              module
            ].img_barrier = this.settingsObject.json.devices[
              type.toLowerCase()
            ][module.toLowerCase()].img_barrier[0].img;
          } else {
            this.offerObject.json.Modules[
              module
            ].img = this.settingsObject.json.devices[type.toLowerCase()][
              module.toLowerCase()
            ].img[0].img;
          }
        });
        if (!this.offerObject.json.ObjectType) {
          this.offerObject.json.ObjectType = "tc";
        }
        this.offerObject.json.DoneWorks = copy(
          this.settingsObjectStandart.doneWorks
        );
        this.offerObject.json.ManagerName = copy(this.userData.FullName);
        this.offerObject.json.ManagerId = copy(this.userData.Id);
        this.offerObject.json.ManagerPhone = copy(this.userData.LoginName);
        this.offerObject.json.ManagerEmail = copy(this.userData.Email);
        this.offerObject.json.OptionsText = copy(
          this.settingsObjectStandart.options
        );
        this.offerObject.json.Total.Options = copy(
          this.settingsObjectStandart.total
        );
        this.offerObject.json.Total.Delivery = copy(
          this.settingsObjectStandart.total.delivery[0].name
        );
        this.offerObject.json.Total.Payment = copy(
          this.settingsObjectStandart.total.payment[0].name
        );
        this.offerObject.json.ContentText = copy(
          this.settingsObjectStandart.content
        );
        this.offerObject.json.Service = copy(
          this.settingsObjectStandart.service
        );
        this.offerObject.json.rateValue = this.rateValue;

        this.changeOfferByObjectType(this.offerObject.json.ObjectType);

        this.offerObject.json.Date = moment();

        this[c.CONVERT_TO_RUB]({
          newValue: this.rateValue,
          oldValue: this.rateValue,
        });

        this.offerObject.json.Total.Options.types = _.map(
          this.offerObject.json.Total.Options.types,
          (i) => {
            // if (i.name === 'Пуско-наладочные работы') {
            //   i.convertCost = this.totalDevicesPrice * 7 / 100
            // }
            // i.convertCost = Math.round(i.convertCost / 1000) * 1000
            i.convertCost = 0;
            return i;
          }
        );

        if (this.offerObject.json.System === "standart") {
          this.offerObject.json.Total.Guarantee = "36 месяцев";
        }

        this.makeOptionsQuery("Entry", "rack");
        // this.makeOptionsQuery('Entry_2', 'barrier')
        this.makeOptionsQuery("Exit", "rack");
        this.makeOptionsQuery("Exit_2", "barrier");
        this.makeOptionsQuery("Payment", "cash_1");
        this.getServiceTypePrice();

        this.getLastTasksText;
        this.optionPages;
        this.objectPageLength;
      }
    },
    changeSytem(type) {
      this.offerObject.json.System = type;
      this[c.CHANGE_SYSTEM](type);
    },
    ...mapActions("sales", [c.CONVERT_TO_RUB, c.CHANGE_SYSTEM]),
  },
};
</script>
