<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            ref="translated-field-Name"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            ref="translated-field-Uri"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Ссылка на видео youtube:
        </span>
        <span class="field__content">
          <input type="text" class="input" v-model="fields.VideoLink" />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          ID носителя:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.CarrierId">
              <option
                v-for="(item, index) in MIFARE_TYPES"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Тип объекта:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.ObjectType">
              <option
                v-for="(item, index) in OBJECT_TYPES"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Выбрать город:
        </span>
        <span class="field__content" v-if="parentData.language === 'ru'">
          <div class="select">
            <select class="select__control" v-model="fields.CityId">
              <option
                v-for="(item, index) in cities"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
        <span
          class="field__content"
          v-else-if="fields.CityId && getCity(fields.CityId)"
        >
          <translate-field
            :EntityColumnName="'Name'"
            ref="translated-field-CityName"
            :OriginalValue="getCity(fields.CityId).Name"
            :inputType="'input'"
            :module="'SiteCities'"
            @getTranslation="
              getTranslation('SiteCities', getCity(fields.CityId).Id)
            "
            :translation="getCity(fields.CityId).translation"
            :id="getCity(fields.CityId).Id"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Адрес:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Address"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Address'"
            ref="translated-field-Address"
            :OriginalValue="fields.Address"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Геолокация:
        </span>
        <span class="field__content field__content--geo">
          <input type="text" class="input" v-model="fields.Latitude" />
          <input type="text" class="input" v-model="fields.Longitude" />
          <div class="button button_grey" @click="gmapWindow = true">
            <img src="/static/images/gps.svg" alt="icon" />
            Указать на карте
          </div>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Выполненные работы:
        </span>
        <span class="field__content">
          <multiselect
            v-model="fields.WorksTypes"
            :options="workTypesIdsArray"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выбрать"
            :searchable="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ getMultiselectOptionName(props.option) }}
                </span>
              </div>
            </template>
            <template slot="tag" slot-scope="{ option }"
              ><span class="multiselect__tag"
                ><span>{{ getMultiselectOptionName(option) }}</span></span
              ></template
            >
          </multiselect>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Call to action:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.CallToActionText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'CallToActionText'"
            ref="translated-field-CallToActionText"
            :OriginalValue="fields.CallToActionText"
            :inputType="'textarea'"
            :module="'SiteExamplesWorks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.ImageLink">
            <img :src="getImgSrc(fields.ImageLink)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field cm-calendar-input">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Показывать на главной:
        </span>
        <span class="field__content">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              v-model="fields.ShowMainPage"
              v-bind:true-value="'1'"
              v-bind:false-value="'0'"
              id="UpdatePinDist"
            />
            <label class="onoffswitch-label" for="UpdatePinDist">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </span>
      </label>
      <label class="field" v-show="+fields.ShowMainPage">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Ссылка на страницу решения:
        </span>
        <span class="field__content">
          <div class="select">
            <select class="select__control" v-model="fields.SolutionId">
              <option
                v-for="(item, index) in solutionsListOptions"
                :key="index"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
            <div class="select__button">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
          </div>
        </span>
      </label>
      <div class="field" v-show="+fields.ShowMainPage">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Числа для главной:
        </span>
        <span class="field__content field__content--group">
          <span class="input-group">
            <input
              type="number"
              class="input"
              v-model="fields.WorksNumbers[0].NumberValue"
              v-if="parentData.language === 'ru'"
            />
            <input
              type="text"
              class="input"
              v-model="fields.WorksNumbers[0].NumberName"
              v-if="parentData.language === 'ru'"
            />
            <!--<translate-field :EntityColumnName="'Name'"-->
            <!--:OriginalValue="fields.Name"-->
            <!--:inputType="'input'"-->
            <!--:module="'SiteExamplesWorks'"-->
            <!--@getTranslation="getTranslation"-->
            <!--:translation="translation"-->
            <!--:id="id"-->
            <!--/>-->
          </span>
          <span class="input-group">
            <input
              type="number"
              class="input"
              v-model="fields.WorksNumbers[1].NumberValue"
              v-if="parentData.language === 'ru'"
            />
            <input
              type="text"
              class="input"
              v-model="fields.WorksNumbers[1].NumberName"
              v-if="parentData.language === 'ru'"
            />
          </span>
          <span class="input-group">
            <input
              type="number"
              class="input"
              v-model="fields.WorksNumbers[2].NumberValue"
              v-if="parentData.language === 'ru'"
            />
            <input
              type="text"
              class="input"
              v-model="fields.WorksNumbers[2].NumberName"
              v-if="parentData.language === 'ru'"
            />
          </span>
        </span>
      </div>
      <label class="field" v-show="+fields.ShowMainPage">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение на главной:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files, 'additionalFiles')"
          />

          <template v-if="additionalFiles.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in additionalFiles"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span
            class="commercial-site__images"
            v-else-if="fields.ImageLinkMainPage"
          >
            <img :src="getImgSrc(fields.ImageLinkMainPage)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Установленное оборудование:
        </span>
        <span class="field__content">
          <multiselect
            v-model="fields.WorksDevices"
            :options="devicesIdsArray"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выбрать"
            :searchable="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ getMultiselectOptionDeviceName(props.option) }}
                </span>
              </div>
            </template>
            <template slot="tag" slot-scope="{ option }"
              ><span class="multiselect__tag"
                ><span>{{ getMultiselectOptionDeviceName(option) }}</span></span
              ></template
            >
          </multiselect>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Дата:
        </span>
        <span class="field__content">
          <date-picker
            valueType="format"
            v-model="fields.ObjectDate"
            :format="'YYYY-MM-DD'"
            lang="ru"
          ></date-picker>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__text-editor" v-if="tab === 2">
      <div class="skedit" v-if="parentData.language === 'ru'">
        <textarea v-tinymce field="Description" v-model="fields.Description" />
      </div>
      <translate-field
        :EntityColumnName="'Description'"
        ref="translated-field-Description"
        :OriginalValue="fields.Description"
        :inputType="'editor'"
        :module="'SiteExamplesWorks'"
        @getTranslation="getTranslation"
        :translation="translation"
        :id="id"
      />
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>

    <div class="cm-popup-form__wrapper" v-if="tab === 3">
      <div class="field">
        <label class="">
          <input
            type="file"
            class="sup-chat__file"
            multiple
            hidden
            @change="filesSliderChange($event.target.files)"
          />
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            >добавить изображения
          </span>
        </label>
      </div>
      <draggable
        class="site-gallery"
        v-if="sliderFiles.length"
        v-model="sliderFiles"
        @end="changeSliderOrder()"
      >
        <div
          class="site-gallery__item"
          v-for="(item, index) in sliderFiles"
          :key="index"
        >
          <img :src="getImgSrc(item.Link, item.img)" alt="file image preview" />
          <div
            class="sup-chat-files__item-remove"
            @click="removeSliderFile(item.Id)"
          >
            <img src="/static/images/remove.svg" alt="icon" />
          </div>
        </div>
      </draggable>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save(false)"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            :OriginalValue="fields.SeoTitle"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            ref="translated-field-SeoTitle"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            :OriginalValue="fields.SeoDescription"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            ref="translated-field-SeoDescription"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            :OriginalValue="fields.SeoKeywords"
            :inputType="'input'"
            :module="'SiteExamplesWorks'"
            ref="translated-field-SeoKeywords"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteExamplesWorks'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>

    <v-dialog v-model="gmapWindow" width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Указать местоположение на карте</span>
        </v-card-title>
        <div class="cm-gmap">
          <div class="field">
            <gmap-autocomplete @place_changed="setPlace" class="input">
            </gmap-autocomplete>
            <button
              class="button button_green button_uppercase button_align_center field__btn"
              @click="usePlace"
              style="margin-left: 10px"
            >
              Найти
            </button>
          </div>
          <GmapMap
            :center="center"
            :zoom="7"
            style="width: 100%; height: 300px"
            @click="centerMark"
          >
            <GmapMarker
              ref="myMarker"
              :position="position"
              @click="center = position"
              :clickable="true"
              :draggable="true"
              @drag="drag"
            />
          </GmapMap>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="gmapWindow = false"
            >Готово</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import * as _ from "lodash";
import { mapState } from "vuex";
import Api from "../../../api/commercial_site";
import { showAlert } from "../../../utils";
import { OBJECT_TYPES } from "../../../mixins/site/CommercialSiteSettings";
export default {
  data() {
    return {
      OBJECT_TYPES,
      MIFARE_TYPES: [
        { Id: 1, Name: "Карты" },
        { Id: 2, Name: "Жетоны" },
        { Id: 3, Name: "Билеты" },
        { Id: 4, Name: "Жетоны (двстр)" },
      ],
      position: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      center: {
        lat: 55.754189180079,
        lng: 37.61709616498706,
      },
      gmapWindow: false,
      place: null,
      devices: [],
      solutionsListOptions: [],
    };
  },
  mounted() {
    if (this.fields.Longitude) {
      this.center = this.position = {
        lat: +this.fields.Latitude,
        lng: +this.fields.Longitude,
      };
    }
    Api.get("production")
      .then((res) => {
        this.devices = res.data;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
    Api.get("solutions")
      .then((res) => {
        this.solutionsListOptions = res.data;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  computed: {
    workTypesIdsArray() {
      return _.map(this.workTypeItems, (i) => i.Id);
    },
    devicesIdsArray() {
      const arr = [];
      _.each(this.devices, (i) => {
        if (i.Id !== this.id) arr.push(i.Id);
      });
      return arr;
    },
    ...mapState("site", ["workTypeItems"]),
  },
  methods: {
    getMultiselectOptionName(option) {
      return option !== void 0 && option.Name !== void 0
        ? option.Name
        : _.find(this.workTypeItems, ["Id", option]).Name;
    },
    getMultiselectOptionDeviceName(option) {
      // console.log(this.devices)
      return option.Name !== void 0
        ? option.Name
        : _.find(this.devices, ["Id", option])
        ? _.find(this.devices, ["Id", option]).Name
        : "...";
    },
    // <--google maps func |____
    // ||-------------------------------------------------
    drag(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.fields.Latitude = +location.latLng.lat();
      this.fields.Longitude = +location.latLng.lng();
    },
    centerMark(location) {
      this.center = this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.fields.Latitude = +location.latLng.lat();
      this.fields.Longitude = +location.latLng.lng();
    },
    changeTextLat(val) {
      this.position.lat = +val;
      this.fields.Latitude = +val;
      this.center.lat = +val;
    },
    changeTextLng(val) {
      this.position.lng = +val;
      this.fields.Longitude = +val;
      this.center.lng = +val;
    },
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.center = this.position = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.fields.Latitude = +this.place.geometry.location.lat();
        this.fields.Longitude = +this.place.geometry.location.lng();

        this.place = null;
      }
    },
    // ||-----------------------------------------------/>
    getCity(Id) {
      if (this.cities && this.cities.length) {
        return _.find(this.cities, { Id });
      }
    },
  },
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
