<template>
  <form class="profile-settings-form" @submit.prevent="save">
    <label
      class="profile-settings-form__image-field"
      @change="setAvatar($event.target.files)"
    >
      <input type="file" hidden />
      <span class="profile-settings-form__image">
        <img :src="avatar" alt="фото профиля" />
      </span>
      <p class="profile-settings-form__image-desc">Изменить аватар</p>
    </label>
    <div class="profile-settings-form__input">
      <input
        type="text"
        placeholder="Фамилия"
        v-model="userSettings.lastname"
        class="input"
      />
    </div>
    <div class="profile-settings-form__input">
      <input
        type="text"
        placeholder="Имя"
        v-model="userSettings.firstname"
        class="input"
      />
    </div>
    <div class="profile-settings-form__input">
      <input
        type="text"
        placeholder="Отчество"
        v-model="userSettings.middlename"
        value="Петрович"
        class="input"
      />
    </div>
    <br />
    <br />
    <div class="profile-settings-form__input">
      <input
        type="email"
        placeholder="Email"
        v-model="userSettings.email"
        class="input"
      />
    </div>
    <!--    <div class="profile-settings-form__input">-->
    <!--      <input-->
    <!--        type="tel"-->
    <!--        v-mask="'+7(###)###-##-##'"-->
    <!--        v-model="userSettings.phone"-->
    <!--        placeholder="Телефон"-->
    <!--        class="input"-->
    <!--      />-->
    <!--    </div>-->
    <br />
    <br />
    <div class="profile-settings-form__input">
      <div class="profile-settings-form__checkbox">
        разрешить/запретить отправку координат
        <label
          class="checkbox checkbox_white table__checkbox table__checkbox-row"
        >
          <input
            class="checkbox__input main_checkbox_input offer-checkbox"
            type="checkbox"
            name="row_checkbox_clients"
            v-bind:true-value="'1'"
            v-bind:false-value="'0'"
            v-model="userSettings.ObjectRequestNotification"
          />
          <span class="checkbox__box main_checkbox_span">
            <svg class="checkbox__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-check"
              ></use>
            </svg>
          </span>
        </label>
      </div>
    </div>
    <br />
    <button class="profile-settings-form__btn btn">Сохранить</button>
    <div class="simple-spinner" v-if="preloader">
      <Spinner
        :noFadeIn="true"
        name="wave"
        color="#c9eeff"
        width="60"
        height="60"
      />
    </div>
  </form>
</template>

<script>
import {
  SAVE_USER_AVATAR,
  UPDATE_USER_PROFILE,
} from "../../../../store/tasks/constants";
import { mapActions, mapState } from "vuex";
import { GET_USER_DATA } from "../../../../store/common/constants";
import { mask } from "vue-the-mask";
import { API_ROOT } from "../../../../config";
import { filesChange } from "../../../../utils";
import Spinner from "vue-spinkit";

export default {
  name: "ProfileSettings",
  data() {
    return {
      userSettings: {},
      preloader: false,
    };
  },
  created() {
    if (this.userData) {
      const fullName = this.userData.FullName.split(" ");
      this.userSettings.firstname = fullName[1];
      this.userSettings.lastname = fullName[0];
      this.userSettings.middlename = fullName[2] ? fullName[2] : "";
      this.userSettings.email = this.userData.Email;
      // this.userSettings.phone = this.userData.LoginName;
      this.userSettings.ObjectRequestNotification = this.userData.ObjectRequestNotification;
    }
  },
  computed: {
    avatar() {
      if (this.userData && this.userData.LinkToAvatar) {
        return API_ROOT + this.userData.LinkToAvatar;
      }
      return "https://tanzolymp.com/images/default-non-user-no-photo-1.jpg";
    },
    ...mapState("tasks", ["userData"]),
  },
  methods: {
    save() {
      this[UPDATE_USER_PROFILE]({
        id: this.userData.Id,
        data: {
          FullName: `${this.userSettings.lastname} ${
            this.userSettings.firstname
          } ${
            this.userSettings.middlename ? this.userSettings.middlename : ""
          }`,
          Email: this.userSettings.email,
          // LoginName: this.userSettings.phone,
          ObjectRequestNotification: this.userSettings
            .ObjectRequestNotification,
        },
      }).then(() => {
        this[GET_USER_DATA](this.userData.Id);
      });
    },
    setAvatar(files) {
      const images = filesChange(files);
      if (images.length) {
        this.preloader = true;
        const formData = new FormData();
        formData.append("avatar", images[0].file);
        this[SAVE_USER_AVATAR](formData).finally(
          () => (this.preloader = false)
        );
      }
    },
    ...mapActions("tasks", [UPDATE_USER_PROFILE, SAVE_USER_AVATAR]),
    ...mapActions("common", [GET_USER_DATA]),
  },
  directives: { mask },
  components: { Spinner },
};
</script>

<style scoped></style>
