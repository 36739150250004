<template>
  <div class="offer-window" :class="preloaders.main ? 'app--load' : ''">
    <router-view />
    <v-dialog
      max-width="290"
      v-if="print.rendering"
      v-model="print.rendering"
      class="print-pdf-popup"
      :persistent="true"
    >
      <v-card>
        <v-card-text> Подготовка PDF-файла, подождите... </v-card-text>
        <div>
          <Spinner
            :noFadeIn="true"
            name="cube-grid"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
      </v-card>
    </v-dialog>
    <div class="header">
      <div class="header__col">
        <div class="tabs header__tabs">
          <span class="tabs__tab active">
            <span class="tabs__link">Ком. Предложения</span>
          </span>
          <template v-if="checkUserRight(RESERVE_MODULE_RIGHT)">
            <router-link class="tabs__tab" to="/sales/reservation/objects">
              <span class="tabs__link">Забронированные объекты</span>
            </router-link>
            <router-link class="tabs__tab" to="/sales/reservation/requests">
              <span class="tabs__link"
                >Заявки<template v-if="requestsCount"
                  >: {{ requestsCount }}</template
                ></span
              >
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <div class="actions-header">
      <div class="actions-header__col">
        <a
          class="button button_green button_uppercase settings-devices-zone__add"
          @click="openBuilder"
          v-if="checkUserRight(CREATE_OFFER_RIGHT) && !selectedItems.length"
          href="#"
          >Создать КП</a
        >
        <a
          class="button button_red button_uppercase settings-devices-zone__add"
          @click="removeOffers"
          v-if="selectedItems.length"
          href="#"
          >Удалить</a
        >
      </div>
      <div class="actions-header__col actions-header__col_flexible">
        <form
          class="search-bar search-bar_white actions-header__search-bar"
          method="post"
          action="#"
        >
          <button class="search-bar__button" type="submit">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-search"
              ></use>
            </svg>
          </button>
          <input
            class="search-bar__input"
            type="search"
            placeholder="Название объекта, менеджер"
            v-model="searchInput"
            @keyup="search"
          />
        </form>
      </div>
      <div class="actions-header__col">
        <span
          class="button button_grey task-checkbox-btn"
          @click="loadPortfolio = true"
          style="padding: 11px 16px"
          >Скачать портфолио</span
        >
      </div>
      <div
        class="actions-header__col"
        v-if="checkUserRight(EDIT_OFFER_TEMPLATES_RIGHT)"
      >
        <a href="#" class="icon-button" @click="openTemplateTaskSettings">
          <span class="icon-button__container">
            <svg class="icon-button__icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-settings"
              ></use>
            </svg>
          </span>
        </a>
      </div>
    </div>

    <div class="cm-container">
      <table class="table-c">
        <tbody
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="0"
          infinite-scroll-immediate-check="false"
          infinite-scroll-throttle-delay="1000"
        >
          <tr>
            <th>
              <div class="table-c__th">
                Название КП
                <button
                  class="table-c__filter-btn"
                  @click="makeSort('Name')"
                  :class="
                    sorts.Name.active ? 'table-c__filter-btn--active' : ''
                  "
                >
                  {{ sorts.Name.asc ? "А-Я" : "Я-A" }}
                </button>
              </div>
            </th>

            <th
              @mouseleave="filtersTabs.systemType = false"
              style="min-width: 150px"
            >
              <div class="table-c__th">
                Система
                <button
                  class="table-c__filter-btn"
                  :class="
                    filters.systemType ? 'table-c__filter-btn--active' : ''
                  "
                  @click="filtersTabs.systemType = true"
                >
                  {{ getSystemType(filters.systemType) }}
                </button>
                <div
                  class="reports__device-type-list_block"
                  style="z-index: 1"
                  v-show="filtersTabs.systemType"
                >
                  <ul>
                    <li @click="changeFilter('systemType', 0)">Все</li>
                    <li @click="changeFilter('systemType', 1)">Жетоны</li>
                    <li @click="changeFilter('systemType', 4)">
                      Жетоны (двстр)
                    </li>
                    <li @click="changeFilter('systemType', 2)">Билеты</li>
                    <li @click="changeFilter('systemType', 3)">Карты</li>
                  </ul>
                </div>
              </div>
            </th>
            <th>
              <div class="table-c__th">Курс $</div>
            </th>
            <th>
              <div class="table-c__th">
                Сумма
                <button
                  class="table-c__filter-btn"
                  @click="makeSort('Price')"
                  :class="
                    sorts.Price.active ? 'table-c__filter-btn--active' : ''
                  "
                >
                  {{ sorts.Price.asc ? "1-9" : "9-1" }}
                </button>
              </div>
            </th>
            <th>Менеджер</th>
            <th width="241">
              <div class="table-c__th">
                Последнее обновление
                <button
                  class="table-c__filter-btn"
                  @click="makeSort('Updated')"
                  :class="
                    sorts.Updated.active ? 'table-c__filter-btn--active' : ''
                  "
                >
                  {{ sorts.Updated.asc ? "1-9" : "9-1" }}
                </button>
              </div>
            </th>
            <th width="173">
              <div class="table-c__th">
                Дата создания
                <button
                  class="table-c__filter-btn"
                  @click="makeSort('Created')"
                  :class="
                    sorts.Created.active ? 'table-c__filter-btn--active' : ''
                  "
                >
                  {{ sorts.Created.asc ? "1-9" : "9-1" }}
                </button>
              </div>
            </th>
          </tr>

          <tr
            v-for="(item, index) in filteredOffers.filter((item) =>
              checkOfferShowRight(item)
            )"
            :key="index + 'offer'"
          >
            <td>
              <label
                class="checkbox checkbox_white table__checkbox js-index-checkbox"
              >
                <input
                  class="checkbox__input main_checkbox_input offer-checkbox"
                  type="checkbox"
                  name="row_checkbox_clients"
                  @click="selectItem($event, item.Id)"
                />
                <span class="checkbox__box main_checkbox_span">
                  <svg class="checkbox__icon">
                    <use
                      xlink:href="/static/images/icon.svg#icon_icon-check"
                    ></use>
                  </svg>
                </span>
              </label>
              <a class="table-c__name" href="" @click.prevent="openOffer(item)">
                {{ item.Name ? item.Name : "Без названия" }}
              </a>
            </td>
            <!--<td><span class="badge" :class="getStatusClassName(+item.Status)"-->
            <!--&gt;-->
            <!--{{getStatus(+item.Status)}}-->
            <!--</span>-->
            <!--</td>-->

            <td>
              {{ getType(item) }}
            </td>
            <td>
              {{ item.rateValue ? Number(item.rateValue).toFixed(4) : "" }}
            </td>
            <td>{{ Number(item.Price).toFixed(2) }}</td>
            <td>
              {{ item.ManagerName | short_name }}
            </td>
            <td v-if="item.Updated">
              <div class="table-c__date">{{ item.Updated | formatDate }}</div>
              <div class="table-c__time">{{ item.Updated | getTime }}</div>
            </td>
            <td v-else>-</td>
            <td>
              <div class="table-c__date">{{ item.Created | formatDate }}</div>
              <div class="table-c__time">{{ item.Created | getTime }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="print-pdf-document">
      <pdf-to-download v-if="print.preview" />
    </div>
    <builder
      :is_open="windowStates.is_open_builder"
      @onCloseSettings="onCloseSettings('is_open_builder')"
    />
    <settings
      :is_open="windowStates.is_open_settings"
      @onCloseSettings="onCloseSettings('is_open_settings')"
    />
    <view-offer
      :is_open="windowStates.is_open_view"
      @onCloseSettings="onCloseSettings('is_open_view')"
    />

    <portfolio v-if="loadPortfolio" @close="loadPortfolio = false" />

    <portal-target
      name="modal"
      class="vue-portal-target--modal"
    ></portal-target>
  </div>
</template>

<script>
import Settings from "./components/settings/index";
import Builder from "./components/builder/index";
import ViewOffer from "./components/builder/View";
import * as c from "@/store/sales/constants";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import PdfToDownload from "./components/builder/PdfToDownload";
import Spinner from "vue-spinkit";
import {
  CREATE_OFFER_RIGHT,
  EDIT_OFFER_TEMPLATES_RIGHT,
  SALE_MODULE_RIGHT,
  SHOW_OTHER_OFFERS_RIGHT,
} from "../../store/sales/constants";
import { Helpers } from "../../mixins/Helpers";
import { copy } from "../../mixins/sales/SalesJSONS";
import { API_ROOT } from "../../config";
import axios from "axios";
import Portfolio from "./components/builder/Portfolio";
import {
  GET_REQUESTS_OBJECTS,
  RESERVE_MODULE_RIGHT,
} from "../../store/sales_reservation/constants";
import { GET_USERS } from "../../store/tasks/constants";
import { GET_CITIES } from "../../store/commercial_site/constants";
import * as _ from "lodash";

export default {
  mixins: [SalesHelpers, Helpers],
  data() {
    return {
      RESERVE_MODULE_RIGHT,
      searchInput: "",
      sorts: {
        Name: {
          asc: false,
          active: false,
        },
        Price: {
          asc: false,
          active: false,
        },
        Updated: {
          asc: false,
          active: false,
        },
        Created: {
          asc: false,
          active: false,
        },
      },
      filters: {
        status: 0,
        systemType: 0,
      },
      filtersTabs: {
        status: false,
        systemType: false,
      },
      selectedItems: [],
      CREATE_OFFER_RIGHT,
      EDIT_OFFER_TEMPLATES_RIGHT,
      loadPortfolio: false,
      page: 0,
    };
  },
  created() {
    this[c.GET_SETTINGS]();
    this[GET_REQUESTS_OBJECTS]();
    this[c.GET_OFFERS]();
    this[c.GET_RATE_VALUE]();
    this[GET_USERS]();
    this[GET_CITIES]();
    setTimeout(
      () => {
        if (!this.checkUserRight(SALE_MODULE_RIGHT)) {
          this.$router.push("/wiki");
        }
      },
      this.rightsLoaded ? 0 : 2000
    );
  },
  computed: {
    filteredOffers() {
      // let offers = this.offers.filter(i => {
      //   const re = new RegExp(this.searchInput, 'gi')
      //   let status = true, systemType = true
      //   if (this.filters.status) {
      //     status = +i.Status === this.filters.status
      //   }
      //   if (this.filters.systemType) {
      //     systemType = +i.SystemType === this.filters.systemType
      //   }
      //   return (re.test(i.ManagerName) || re.test(i.Name)) && status && systemType
      // })
      let offers = this.offers;
      let order = null,
        sort;
      _.each(this.sorts, (object, name) => {
        if (object.active) {
          order = name;
          sort = object.asc ? "asc" : "desc";
        }
      });
      if (order) {
        offers = _.orderBy(
          offers,
          (i) => {
            if (order === "Name") {
              return i.Name;
            } else {
              return i[order];
            }
          },
          [sort]
        );
      }

      return offers;

      // return this.offers
    },
    ...mapState("common", ["userData", "rightsLoaded"]),
    ...mapState("sales", [
      "offers",
      "offerObject",
      "preloaders",
      "windowStates",
      "print",
      "compareObject",
      "rateValue",
    ]),
    ...mapState("reserve", ["requestsCount"]),
  },
  methods: {
    onCloseSettings(type) {
      this.windowStates[type] = false;
    },
    openBuilder() {
      this[c.GET_SETTINGS]();
      this.windowStates.is_open_builder = true;
    },
    getName(item) {
      const city = item.City ? ", " + item.City : "";
      return item.Name ? item.Name + city : "Безымянное предложение";
    },
    getType(item) {
      const globalSystem =
        item.System === "economy"
          ? ", Econom"
          : item.System === "qr"
          ? ", RPS QR"
          : ", Standart";
      switch (item.SystemType) {
        case "3":
          return "Карты" + globalSystem;
        case "1":
          return "Жетоны" + globalSystem;
        case "2":
          return "Билеты" + globalSystem;
        case "4":
          return "Жетоны (двстр)" + globalSystem;
      }
    },
    openOffer(item) {
      axios.get(API_ROOT + "api/sales/ComOffers/" + item.Id).then((res) => {
        if (res.data.status === "success") {
          const offer = res.data.item;
          offer.OfferData = JSON.parse(offer.OfferData);

          this.windowStates.is_open_view = true;
          this.offerObject.json = copy(offer.OfferData);
          this.offerObject.OpenAccessByLink = offer.OpenAccessByLink;
          this.offerObject.LinkToOffer = offer.LinkToOffer;
          if (
            this.offerObject.json.Objects &&
            this.offerObject.json.Objects.length &&
            this.offerObject.json.Objects[0].randomId !== void 0
          ) {
            this.offerObject.json.Objects = [];
          }
          this.offerObject.json.Id = offer.Id;
          if (!this.offerObject.json.ManagerId) {
            this.offerObject.json.ManagerId = offer.UserId;
          }
          // manual change offer
          // this.offerObject.json.passPages.tasks_page = true
          if (!offer.OfferData.Modules.Payment.cash_4)
            offer.OfferData.Modules.Payment.cash_4 = [];
          if (!this.offerObject.json.Modules.Payment.cash_4)
            this.offerObject.json.Modules.Payment.cash_4 = [];

          this.compareOfferWithNewTemplate(offer.OfferData);
          const rateDiff = Math.abs(
            this.offerObject.json.rateValue - this.rateValue
          );
          const rateDiffPercentage = (
            (this.offerObject.json.rateValue / 100) *
            rateDiff
          ).toFixed();
          // console.log(rateDiffPercentage)
          if (
            this.offerObject.json.rateValue < this.rateValue &&
            +rateDiffPercentage >= 5
          ) {
            alert("Разница курса доллара свыше 5%. Данные были пересчитаны");
            this[c.CONVERT_TO_RUB]({
              newValue: this.rateValue,
              oldValue: this.rateValue,
            });
            this.offerObject.json.rateValue = this.rateValue;
            this.offerObject.json.Total.Options.types = _.map(
              this.offerObject.json.Total.Options.types,
              (i) => {
                // if (i.name === 'Пуско-наладочные работы') {
                //   i.convertCost = this.totalDevicesPrice * 7 / 100
                // }
                // i.convertCost = Math.round(i.convertCost / 1000) * 1000
                return i;
              }
            );
          }
          this.makeOptionsQuery("Entry", "rack");
          this.makeOptionsQuery("Entry_2", "barrier");
          this.makeOptionsQuery("Exit", "rack");
          this.makeOptionsQuery("Exit_2", "barrier");
          this.makeOptionsQuery("Payment", "cash_1");
          this.getServiceTypePrice();

          this.getLastTasksText;
          this.optionPages;
          this.objectPageLength;
        }
      });
    },
    makeSort(type) {
      _.each(this.sorts, (object, name) => {
        if (name !== type) {
          object.asc = false;
          object.active = false;
        }
      });
      this.sorts[type].active = true;
      this.sorts[type].asc = !this.sorts[type].asc;
    },
    changeFilter(filter, status) {
      this.filters[filter] = status;
      this.filtersTabs[filter] = false;
    },
    checkOfferShowRight(item) {
      if (!this.checkUserRight(SHOW_OTHER_OFFERS_RIGHT)) {
        return [item.UserId, item.ManagerId].includes(this.userData.Id);
      }
      return true;
    },
    selectItem(el, id) {
      if (el.target.checked) {
        if (!this.selectedItems.includes(id)) {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems = _.filter(this.selectedItems, (i) => i !== id);
      }
    },
    removeOffers(e) {
      if (!confirm("Вы действительно хотите удалить предложение?")) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        _.each(this.selectedItems, (id) => {
          this[c.REMOVE_OFFER](id);
        });
        document.querySelectorAll(".offer-checkbox").forEach((i) => {
          i.checked = false;
        });
        this.selectedItems = [];
      }
    },
    openTemplateTaskSettings() {
      this.windowStates.is_open_settings = true;
      this[c.CREATE_DUMP_OF_SETTINGS]();
      this[c.RESET_OFFER]();
    },
    loadMore: function () {
      this.page += 1;
      this[c.GET_OFFERS]({ page: this.page, search: this.searchInput });
    },
    search() {
      this.page = 0;
      this[c.GET_OFFERS]({ page: this.page, search: this.searchInput });
    },
    ...mapActions("sales", [
      c.GET_SETTINGS,
      c.GET_OFFERS,
      c.REMOVE_OFFER,
      c.RESET_OFFER,
      c.CREATE_DUMP_OF_SETTINGS,
      c.GET_RATE_VALUE,
      c.CONVERT_TO_RUB,
    ]),
    ...mapActions("tasks", [GET_USERS]),
    ...mapActions("reserve", [GET_REQUESTS_OBJECTS]),
    ...mapActions("site", [GET_CITIES]),
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(date) {
      return moment(date).format("HH:mm:ss");
    },
  },
  components: {
    Settings,
    Builder,
    ViewOffer,
    PdfToDownload,
    Spinner,
    Portfolio,
  },
};
</script>
