<template>
  <div
    class="automation-attach__row"
    :class="{
      'automation-attach__row--disabled': selectedAutomation.Id !== item.Id,
    }"
  >
    <AutomationBuilder
      class="automation-attach__row-name"
      :form="item"
      :edit="selectedAutomation.Id === item.Id"
      :attach="true"
      :saveOnBlur="true"
      @save="save"
      :parentData="{}"
    />
    <div
      class="automation-attach__row__label-button label-button"
      :class="{ 'label-button--disabled': selectedAutomation.Id !== item.Id }"
      @click="$emit('selectAutomation', item)"
    >
      <template v-if="selectedAutomation.Id === item.Id">Выбрана</template>
      <template v-else>Выбрать</template>
    </div>
  </div>
</template>

<script>
import { Helpers } from "../../../../../mixins/Helpers";
import AutomationBuilder from "../AutomationBuilder";

export default {
  name: "AutomationAttachControlItem",
  mixins: [Helpers],
  props: ["item", "selectedAutomation"],
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    save(form) {
      this.$emit("save", form);
    },
  },
  components: {
    AutomationBuilder,
  },
};
</script>

<style scoped></style>
