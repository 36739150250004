<template>
  <div
    class="popup task-popup event-popup event-popup_active task-popup-parent popup task-popup-detail"
  >
    <div class="popup__wrapper" @click="$emit('close')">
      <div class="popup__content popup__content-wide" @click.stop>
        <div class="popup__header">
          <h2 class="popup__heading">Настройки тэгов</h2>
          <div class="popup__close" @click="$emit('close')">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="table-vertical__wrapper">
          <draggable
            class="table-vertical"
            @end="handleMove(tags)"
            :group="{ name: 'g0' }"
            :list="tags"
            @change="handleParentChange"
            draggable=".draggable-item"
          >
            <div
              class="table-vertical__col draggable-item"
              v-for="item in tags"
              :key="item.Id"
            >
              <div
                v-if="item.Id !== null"
                class="table-vertical__cell _draggable"
              >
                <input
                  class="task-label"
                  :style="`background-color: #${item.Color}; width: calc(${item.TagName.length}ch + 5ch); max-width: 95%;`"
                  v-model="item.TagName"
                  @blur="handleNameChange(item)"
                  @keydown.enter.prevent
                />
                <span
                  class="tags-settings__buttons tags-settings__buttons-right tags-settings__buttons-hidden"
                >
                  <span class="tags-settings__dropdown" @click.stop>
                    <svg width="5px" height="16px">
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/static/images/icon.svg#three-dots"
                      ></use>
                    </svg>
                    <span class="tags-settings__dropdown-content">
                      <ul>
                        <li @click="handleDeleteItem(item.Id)">
                          <span>Удалить</span>
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </div>
              <div v-else class="table-vertical__cell">
                <span class="task-label">
                  {{ item.TagName }}
                </span>
              </div>
              <draggable
                @end="handleMove(item.child)"
                :list="item.child"
                :group="{ name: 'g1' }"
                @change="handleParentChange"
              >
                <div v-for="child in item.child" :key="child.id">
                  <rps-tag-item
                    :item="child"
                    :color="`#${item.Color}`"
                    :depth="1"
                    :tags="tags"
                    @handleParentChange="handleParentChange"
                    @removeTemp="handleItemRemoveTemp"
                    @deleteItem="handleDeleteItem"
                    @tagMove="handleMove"
                  />
                </div>
              </draggable>
              <div
                class="table-vertical__cell _add-tag"
                @click="handleAddItem(item)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                    :fill="`#${item.Color}`"
                  />
                </svg>
                <span
                  >Добавить тэг</span
                >
              </div>
            </div>
            <div class="table-vertical__col" slot="footer">
              <div class="table-vertical__cell _add-tag">
                <span class="tags-settings__new" @click="handleCreateNew"
                  >Добавить</span
                >
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RpsTagItem from "../tags/RpsTagItem";
import Api from "../../../../../api/support/rps";
import * as _ from "lodash";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import { showAlert } from "../../../../../utils";

export default {
  name: "RpsTagsSettings",
  components: { RpsTagItem, draggable },
  props: ["initialTags"],
  computed: {
    ...mapState("rpsSupport", ["commonSettings"]),
  },
  data() {
    return {
      tags: [],
    };
  },
  watch: {
    initialTags: function (newVal) {
      this.tags = newVal;
    },
  },
  methods: {
    handleParentChange(evt) {
      if (evt.removed) {
        let parent = null;
        const childToSearch = evt.removed.element.Id;
        for (let i = 0; i < this.tags.length; i++) {
          let search = findParent(this.tags[i], childToSearch);
          if (search) {
            parent = search;
            break;
          }
        }
        Api.editTag(childToSearch, {
          ParentTagId: parent.Id,
          Position: 0,
        })
          .then((res) => {
            if (res.status === 200) this.handleMove(parent.child);
            else showAlert("error", "Произошла ошибка");
          })
          .catch(() => showAlert("error", "Произошла ошибка"));
      }

      function findParent(tag, searchId) {
        let searchTag = undefined;
        _.each(tag.child, (i) => {
          if (i.Id === searchId) {
            searchTag = tag;
            return false;
          } else if (i.child && i.child.length) {
            let searchTagChild = findParent(i, searchId);
            if (searchTagChild) {
              searchTag = searchTagChild;
              return false;
            }
          }
        });
        return searchTag;
      }
    },
    handleMove(tags) {
      let newOrder = [];
      for (let i = 0; i < tags.length; i++) {
        newOrder.push(tags[i].Id);
      }
      Api.updateTagsOrder({ Ids: newOrder });
    },

    sortByPosition(array) {
      array = _.orderBy(array, "Position");
      _.each(array, (item) => {
        if (item.child && item.child.length) {
          item.child = this.sortByPosition(item.child);
        }
      });
      return array;
    },
    handleNameChange(item) {
      Api.editTag(item.Id, {
        TagName: item.TagName.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, ""),
      }).then((res) => {
        if (!res.data)
          showAlert("error", "Произошла ошибка при обновлении имени тега");
      });
    },
    handleDeleteItem(id) {
      const tagCopy = this.getTagById(id);
      let parentTag = null;
      if (tagCopy.ParentTagId) {
        parentTag = this.getTagById(tagCopy.ParentTagId);
      }
      this.handleItemRemove(id);

      Api.deleteTag(id).catch(() => {
        showAlert("error", "Произошла ошибка при удалении тэга");
        if (parentTag) {
          parentTag.child.push(tagCopy);
        } else this.tags.push(tagCopy);
      });
    },
    getTagById(id) {
      return findTag(this.tags);

      function findTag(array) {
        let searchTag = undefined;
        _.each(array, (i) => {
          if (i.Id === id) {
            searchTag = i;
            return false;
          } else if (i.child && i.child.length) {
            let tag = findTag(i.child);
            if (tag) {
              searchTag = tag;
              return false;
            }
          }
        });
        return searchTag;
      }
    },
    handleItemRemove(id) {
      let filtered = this.tags.filter((f) => f.Id === id);
      if (filtered && filtered.length) {
        this.tags = this.tags.filter((f) => f.Id !== id);
      } else {
        for (let i = 0; i < this.tags.length; i++) filterChildren(this.tags[i]);
      }

      function filterChildren(item) {
        if (item.child && item.child.length) {
          let filtered = item.child.filter((f) => f.Id === id);
          if (filtered && filtered.length > 0) {
            item.child = item.child.filter((f) => f.Id !== id);
          } else {
            for (let i = 0; i < item.child.length; i++) {
              filterChildren(item.child[i]);
            }
          }
        }
      }
    },
    handleItemRemoveTemp(id) {
      let filtered = this.tags.filter((f) => f.VirtualId === id);
      if (filtered && filtered.length) {
        this.tags = this.tags.filter((f) => f.VirtualId !== id);
      } else {
        for (let i = 0; i < this.tags.length; i++) filterChildren(this.tags[i]);
      }

      function filterChildren(item) {
        if (item.child && item.child.length) {
          let filtered = item.child.filter((f) => f.VirtualId === id);
          if (filtered && filtered.length > 0) {
            item.child = item.child.filter((f) => f.VirtualId !== id);
          } else {
            for (let i = 0; i < item.child.length; i++) {
              filterChildren(item.child[i]);
            }
          }
        }
      }
    },
    handleCreateNew() {
      const virtualId = Math.random().toString(36).substring(7);
      this.tags.push({
        TagName: "Новый тэг",
        VirtualId: virtualId,
        Position: this.tags.length + 1,
        Id: null,
      });
      Api.createTag({
        TagName: "Новый тэг",
      })
        .then((res) => {
          if (res.data.Id !== void 0) {
            const index = this.tags.findIndex(
              (item) => item.VirtualId === virtualId
            );
            this.tags.splice(index, 1, res.data);
          }
        })
        .catch(() => {
          const index = this.tags.findIndex(
            (item) => item.VirtualId === virtualId
          );
          this.tags.splice(index, 1);
        });
    },
    handleAddItem(item) {
      const virtualId = Math.random().toString(36).substring(7);
      item.child.push({
        TagName: "Новый тэг",
        VirtualId: virtualId,
        Position: item.child.length + 1,
        ParentTagId: item.Id,
        Id: null,
      });
      Api.createTag({
        TagName: "Новый тэг",
        ParentTagId: item.Id,
      })
        .then((res) => {
          if (res.data.Id !== void 0) {
            this.handleItemRemoveTemp(virtualId);
            item.child.push(res.data);
          }
        })
        .catch(() => {
          this.handleItemRemoveTemp(virtualId);
        });
    },
  },
};
</script>

<style scoped></style>
