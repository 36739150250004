<template>
  <div class="task-events__grouped-item">
    <div class="task-events__item-left">
      <div class="task-events__circle">
        <UserAvatar v-if="!item.SystemSender" :user="item.Sender" />
        <div class="task-events__circle" v-else>
          <div title="Системное сообщение" class="task-table-person__circle">
            <img
              src="/static/images/tasks/kitty.gif"
              alt=""
              v-if="item.SystemName === 'tasks_task_deadline'"
              style="object-position: left"
            />
            <img
              src="/static/images/tasks/icons/system-message-picture.png"
              alt="user avatar"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
    <div class="task-events__info">
      <div class="task-events__info-top">
        <span
          class="task-events__name"
          v-if="item.TaskId"
          @click="
            () => {
              item.Status = '3';
              item.localNewNotification = false;
              $emit('setTaskForGetNotifications', item);
            }
          "
          >{{ name }}</span
        >
        <div class="task-events__name" v-else>{{ name }}</div>
        <div class="task-events__info-top-right-side">
          <span class="task-events__info-label" v-if="item.localNewNotification"
            >Новое</span
          >
          <div class="task-events__item-time">{{ date }}</div>
        </div>
      </div>
      <div class="task-events__info-bottom">
        <div
          class="task-events__icon task-events__icon-1"
          :style="{
            backgroundImage: `url(/img/tasks_icons/${item.SystemName}.svg)`,
          }"
        />
        <div class="task-events__files" v-if="files.length">
          <div class="task-events__text">В задачу добавлены новые файлы:</div>

          <div class="task-popup-files" v-if="files.length">
            <div
              class="sup-chat-files__item"
              v-for="(item, index) in files"
              :key="index"
            >
              <span
                class="sup-chat-files__item-inner"
                v-if="fileIsImage(item.original)"
                @click="showImagePopup(item.original)"
              >
                <img :src="item.small" />
                <p>{{ item.filename }}</p>
              </span>

              <a
                class="sup-chat-files__item-inner"
                download
                target="_blank"
                :href="item.original"
                @click.prevent="
                  downloadURI(getFileSrc(item.item), item.original, item.Id)
                "
                v-else
              >
                <span class="sup-chat-files__item-link">
                  <img :src="getNonImageTypeFileIcon(item.original)" />
                </span>
                <p>{{ item.filename }}</p>
              </a>
            </div>
          </div>
        </div>
        <div
          v-else
          class="task-events__text"
          :class="item.SystemName"
          v-html="message"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import common from "../../../../mixins/tasks/common";
import UserAvatar from "../CommonComponents/UserAvatar";
import { GET_COUNT_NOTIFICATIONS } from "../../../../store/tasks/constants";
import { mapActions } from "vuex";

export default {
  props: ["item", "localCountNotifications"],
  mixins: [common],
  computed: {
    message() {
      const el = document.createElement("div");
      el.innerHTML = this.item.Message;
      const allLinks = el.querySelectorAll("a");
      allLinks.forEach((i) => {
        if (i.getAttribute("href").includes("userId=")) {
          i.classList.add("user-link");
        }
      });

      if (this.item.SystemName === "tasks_task_priority") {
        const oldPriority = el.querySelector(".old_priority").innerText;
        const newPriority = el.querySelector(".new_priority").innerText;
        el.querySelector(".old_priority").classList.add(
          this.getPriorityColor(oldPriority)
        );
        el.querySelector(".old_priority").classList.add("task-priority");
        el.querySelector(".new_priority").classList.add(
          this.getPriorityColor(newPriority)
        );
        el.querySelector(".new_priority").classList.add("task-priority");
      }

      return el.innerHTML;
    },
    name() {
      if (this.item.TaskId) {
        return this.item.taskInfo.TaskName;
      } else {
        return this.item.NotificationName;
      }
    },
    date() {
      // return this.$options.filters.formatReplyDate(this.item.DateCreate)
      return moment(this.item.DateCreate).format("HH:mm");
    },
    task() {
      return this.item.taskInfo;
    },
    files() {
      const files = this.item.Message.split("|||");
      if (files.length > 1) {
        files.shift();
        files.pop();
        try {
          const json = JSON.parse(files);
          return json;
        } catch (e) {
          return files;
        }
      }
      return [];
    },
  },
  methods: {
    ...mapActions("tasks", [GET_COUNT_NOTIFICATIONS]),
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UserAvatar,
  },
};
</script>

<style scoped></style>
