<template>
  <div class="task-create-form__row">
    <div class="task-create-form__row-left">
      <i class="task-form-icon task-form-icon--1"></i>
    </div>
    <div class="task-create-form__row-right">
      <div class="task-create-form__label">Шаблон задачи</div>
      <div class="task-create-form__field">
        <div class="select">
          <select class="select__control" v-model="template">
            <option :value="null">Сервисный шаблон</option>
            <option
              v-for="(item, index) in templates"
              :value="item"
              :key="index"
            >
              {{ item.TemplateName }}
            </option>
          </select>
          <div class="select__button">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["templateModel"],
  data() {
    return {};
  },
  computed: {
    template: {
      get() {
        return this.templateModel;
      },
      set(val) {
        this.$emit("settingTaskByTemplate", val);
      },
    },
    ...mapState("tasks", ["templates"]),
  },
};
</script>

<style scoped></style>
