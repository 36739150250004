<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div class="cm-popup-form__wrapper" v-if="tab === 1">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Name"
            @keyup="makeSlug"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'Name'"
            ref="translated-field-Name"
            :OriginalValue="fields.Name"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Слаг:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.Uri"
            v-if="parentData.language === 'ru'"
            @keyup="validateUrl()"
          />
          <translate-field
            :EntityColumnName="'Uri'"
            ref="translated-field-Uri"
            :OriginalValue="fields.Uri"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название для внутренней страницы:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.InternalName"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'InternalName'"
            ref="translated-field-InternalName"
            :OriginalValue="fields.InternalName"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Название в родительном падеже
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.MainPageName"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'MainPageName'"
            ref="translated-field-MainPageName"
            :OriginalValue="fields.MainPageName"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Верхний текст:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.WelcomeText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'WelcomeText'"
            ref="translated-field-WelcomeText"
            :OriginalValue="fields.WelcomeText"
            :inputType="'textarea'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Call to action:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.CallToActionText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'CallToActionText'"
            ref="translated-field-CallToActionText"
            :OriginalValue="fields.CallToActionText"
            :inputType="'textarea'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Загрузить PDF:
        </span>
        <span class="field__content file-input">
          <input
            type="file"
            class=""
            @change.prevent="filesChangePdf($event)"
          />
          <span v-if="fields.PdfLink" class="file-desc">{{
            fields.PdfLink
          }}</span>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Устройства для слайдера:
        </span>
        <span class="field__content">
          <multiselect
            v-model="fields.DevicesIds"
            :options="devicesIdsArray"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Выбрать"
            :searchable="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ getMultiselectOptionName(props.option) }}
                </span>
              </div>
            </template>
            <template slot="tag" slot-scope="{ option }"
              ><span class="multiselect__tag"
                ><span>{{ getMultiselectOptionName(option) }}</span></span
              ></template
            >
          </multiselect>
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение внутреннее:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="filesChange($event.target.files)"
          />

          <template v-if="files.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in files"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span class="commercial-site__images" v-else-if="fields.ImageLink">
            <img :src="getImgSrc(fields.ImageLink)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Изображение для списка:
        </span>
        <span class="field__content">
          <input
            type="file"
            accept="image/*"
            hidden
            class="input"
            @change="
              filesChange($event.target.files, 'additionalSolutionsFiles')
            "
          />

          <template v-if="additionalSolutionsFiles.length">
            <div
              class="commercial-site__images"
              v-for="(item, index) in additionalSolutionsFiles"
              :key="index"
            >
              <img v-if="checkFileExt(item.name)" :src="item.img" />
            </div>
          </template>
          <span
            class="commercial-site__images"
            v-else-if="fields.ImageForCategory"
          >
            <img :src="getImgSrc(fields.ImageForCategory)" />
          </span>
          <span class="add-file-btn" v-else>+</span>
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__text-editor" v-if="tab === 2">
      <div class="skedit" v-if="parentData.language === 'ru'">
        <textarea v-tinymce field="Description" v-model="fields.Description" />
      </div>
      <translate-field
        :EntityColumnName="'Description'"
        ref="translated-field-Description"
        :OriginalValue="fields.Description"
        :inputType="'editor'"
        :module="'SiteSolutions'"
        @getTranslation="getTranslation"
        :translation="translation"
        :id="id"
      />
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 4">
      <div class="field">
        <label class="">
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            @click="appendToTasksArray"
            >Добавить
          </span>
        </label>
      </div>
      <div class="inner-objects">
        <div
          class="inner-objects__item"
          v-for="(item, index) in fields.Tasks"
          :key="index"
        >
          <span class="remove-icon" @click="deleteTask(item)"></span>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название:
            </span>
            <span class="field__content">
              <input
                type="text"
                class="input"
                v-model="item.Name"
                v-if="parentData.language === 'ru'"
              />
              <translate-field
                :EntityColumnName="'Name'"
                ref="translated-field-TaskName"
                :OriginalValue="item.Name"
                :inputType="'input'"
                :module="'SiteSolutionsTasks'"
                @getTranslation="
                  getInnerObjectTranslation('solutionsTasks', item)
                "
                :translation="item.translation"
                :id="item.Id"
              />
            </span>
          </label>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Текст:
            </span>
            <span class="field__content">
              <textarea
                class="textarea"
                v-model="item.TaskText"
                v-if="parentData.language === 'ru'"
              >
></textarea
              >

              <translate-field
                :EntityColumnName="'TaskText'"
                ref="translated-field-TaskText"
                :OriginalValue="item.TaskText"
                :inputType="'textarea'"
                :module="'SiteSolutionsTasks'"
                @getTranslation="
                  getInnerObjectTranslation('solutionsTasks', item)
                "
                :translation="item.translation"
                :id="item.Id"
              />
            </span>
          </label>
        </div>
      </div>
      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save()"
      >
        Сохранить
      </button>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 5">
      <div class="field">
        <label class="">
          <span
            class="button button_green button_uppercase settings-devices-zone__add"
            @click="createAdditionalData('solutionsOpportunities')"
            >Добавить
          </span>
        </label>
      </div>
      <div class="inner-objects">
        <div
          class="inner-objects__item"
          v-for="(item, index) in additionalItems.solutionsOpportunities"
          :key="index"
        >
          <span
            class="remove-icon"
            @click="deleteAdditionalData('solutionsOpportunities', item)"
          ></span>
          <label
            class="field inner-objects__item-img"
            v-if="additionalFiles.solutionsOpportunities[item.Id]"
          >
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Изображение:
              <span
                class="add-file-btn"
                v-if="
                  !item.ImageLink &&
                  !additionalFiles.solutionsOpportunities[item.Id].length
                "
                >+</span
              >
            </span>
            <input
              type="file"
              @change="
                additionalFilesChange(
                  'solutionsOpportunities',
                  item,
                  $event.target.files
                )
              "
              hidden
            />
            <span class="field__content">
              <template
                v-if="additionalFiles.solutionsOpportunities[item.Id].length"
              >
                <img
                  :src="img.img"
                  v-for="(img, index) in additionalFiles.solutionsOpportunities[
                    item.Id
                  ].filter((i) => checkFileExt(i.name))"
                  :key="index"
                />
              </template>
              <img
                :src="getImgSrc(item.ImageLink)"
                v-else-if="item.ImageLink"
              />
            </span>
          </label>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Название:
            </span>
            <span class="field__content">
              <input
                type="text"
                class="input"
                :value="item.Name"
                @blur="
                  editFieldValue($event, 'solutionsOpportunities', item, 'Name')
                "
                v-if="parentData.language === 'ru'"
              />
              <translate-field
                :EntityColumnName="'Name'"
                ref="translated-field-SolutionsOpportunitiesName"
                :OriginalValue="item.Name"
                :inputType="'input'"
                :module="'SiteSolutionsOpportunities'"
                @getTranslation="
                  getInnerObjectTranslation('solutionsOpportunities', item)
                "
                :translation="item.translation"
                :id="item.Id"
              />
            </span>
          </label>
          <label class="field">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Текст:
            </span>
            <span class="field__content">
              <textarea
                class="textarea"
                :value="item.OpportunitiesText"
                v-if="parentData.language === 'ru'"
                @blur="
                  editFieldValue(
                    $event,
                    'solutionsOpportunities',
                    item,
                    'OpportunitiesText'
                  )
                "
              ></textarea>
              <translate-field
                :EntityColumnName="'OpportunitiesText'"
                ref="translated-field-OpportunitiesText"
                :OriginalValue="item.OpportunitiesText"
                :inputType="'textarea'"
                :module="'SiteSolutionsOpportunities'"
                @getTranslation="
                  getInnerObjectTranslation('solutionsOpportunities', item)
                "
                :translation="item.translation"
                :id="item.Id"
              />
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 6">
      <div class="header">
        <div class="header__col">
          <ul class="tabs header__tabs">
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 1 }"
              @click="innerTab = 1"
            >
              <a href="#" class="tabs__link">Для собственника</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 2 }"
              @click="innerTab = 2"
            >
              <a href="#" class="tabs__link">Для арендатора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 3 }"
              @click="innerTab = 3"
            >
              <a href="#" class="tabs__link">Для гостей:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab === 4 }"
              @click="innerTab = 4"
            >
              <a href="#" class="tabs__link">Названия табов:</a>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="innerTab === 1">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits', 1)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType('1')"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label
              class="field inner-objects__item-img"
              v-if="additionalFiles.solutionsBenefits[item.Id]"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span
                  class="add-file-btn"
                  v-if="
                    !item.ImageLink &&
                    !additionalFiles.solutionsBenefits[item.Id].length
                  "
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  additionalFilesChange(
                    'solutionsBenefits',
                    item,
                    $event.target.files
                  )
                "
                hidden
              />
              <span class="field__content">
                <template
                  v-if="additionalFiles.solutionsBenefits[item.Id].length"
                >
                  <img
                    :src="img.img"
                    v-for="(img, index) in additionalFiles.solutionsBenefits[
                      item.Id
                    ].filter((i) => checkFileExt(i.name))"
                    :key="index"
                  />
                </template>
                <img
                  :src="getImgSrc(item.ImageLink)"
                  v-else-if="item.ImageLink"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="
                    editFieldValue($event, 'solutionsBenefits', item, 'Name')
                  "
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsBenefitsName"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  :value="item.BenefitText"
                  v-if="parentData.language === 'ru'"
                  @blur="
                    editFieldValue(
                      $event,
                      'solutionsBenefits',
                      item,
                      'BenefitText'
                    )
                  "
                ></textarea>
                <translate-field
                  :EntityColumnName="'BenefitText'"
                  ref="translated-field-BenefitText"
                  :OriginalValue="item.BenefitText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 2">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits', 2)"
              >Добавить
            </span>
          </label>
        </div>
        <label class="field field-inside">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Текст:
          </span>
          <span class="field__content">
            <textarea
              class="textarea"
              v-model="fields.RentPromoText"
              v-if="parentData.language === 'ru'"
              @blur="save"
            ></textarea>
            <translate-field
              :EntityColumnName="'RentPromoText'"
              ref="translated-field-RentPromoText"
              :OriginalValue="fields.RentPromoText"
              :inputType="'textarea'"
              :module="'SiteSolutions'"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>

        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType('2')"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="
                    editFieldValue($event, 'solutionsBenefits', item, 'Name')
                  "
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsBenefitsName2"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 3">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsBenefits')"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getBenefitsByType('3')"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsBenefits', item)"
            ></span>
            <label
              class="field inner-objects__item-img"
              v-if="additionalFiles.solutionsBenefits[item.Id]"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span
                  class="add-file-btn"
                  v-if="
                    !item.ImageLink &&
                    !additionalFiles.solutionsBenefits[item.Id].length
                  "
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  additionalFilesChange(
                    'solutionsBenefits',
                    item,
                    $event.target.files
                  )
                "
                hidden
              />
              <span class="field__content">
                <template
                  v-if="additionalFiles.solutionsBenefits[item.Id].length"
                >
                  <img
                    :src="img.img"
                    v-for="(img, index) in additionalFiles.solutionsBenefits[
                      item.Id
                    ].filter((i) => checkFileExt(i.name))"
                    :key="index"
                  />
                </template>
                <img
                  :src="getImgSrc(item.ImageLink)"
                  v-else-if="item.ImageLink"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="
                    editFieldValue($event, 'solutionsBenefits', item, 'Name')
                  "
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsBenefitsName3"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  :value="item.BenefitText"
                  v-if="parentData.language === 'ru'"
                  @blur="
                    editFieldValue(
                      $event,
                      'solutionsBenefits',
                      item,
                      'BenefitText'
                    )
                  "
                ></textarea>
                <translate-field
                  :EntityColumnName="'BenefitText'"
                  ref="translated-field-BenefitText2"
                  :OriginalValue="item.BenefitText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsBenefits'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsBenefits', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab === 4">
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для арендодателей:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              v-model="fields.RentTabTitle"
              v-if="parentData.language === 'ru'"
            />
            <translate-field
              :EntityColumnName="'RentTabTitle'"
              ref="translated-field-RentTabTitle"
              :OriginalValue="fields.RentTabTitle"
              :inputType="'input'"
              :module="'SiteSolutions'"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для собственников:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              v-model="fields.OwnerTabTitle"
              v-if="parentData.language === 'ru'"
            />
            <translate-field
              :EntityColumnName="'OwnerTabTitle'"
              ref="translated-field-OwnerTabTitle"
              :OriginalValue="fields.OwnerTabTitle"
              :inputType="'input'"
              :module="'SiteSolutions'"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>
        <label class="field">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Для гостей:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              v-model="fields.GuestTabTitle"
              v-if="parentData.language === 'ru'"
            />
            <translate-field
              :EntityColumnName="'GuestTabTitle'"
              ref="translated-field-GuestTabTitle"
              :OriginalValue="fields.GuestTabTitle"
              :inputType="'input'"
              :module="'SiteSolutions'"
              @getTranslation="getTranslation"
              :translation="translation"
              :id="id"
            />
          </span>
        </label>
        <button
          class="button button_green button_uppercase cm-popup-form__btn-submit"
          @click="save(false)"
        >
          Сохранить
        </button>
      </template>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 7">
      <div class="header">
        <div class="header__col">
          <ul class="tabs header__tabs">
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 1 }"
              @click="innerTab2 = 1"
            >
              <a href="#" class="tabs__link">Рабочая станция оператора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 2 }"
              @click="innerTab2 = 2"
            >
              <a href="#" class="tabs__link">Личный кабинет арендатора:</a>
            </li>
            <li
              class="tabs__tab"
              :class="{ active: innerTab2 === 3 }"
              @click="innerTab2 = 3"
            >
              <a href="#" class="tabs__link">Мобильные приложения:</a>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="innerTab2 === 1">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 1)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(1)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label
              class="field inner-objects__item-img"
              v-if="additionalFiles.solutionsSoft[item.Id]"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span
                  class="add-file-btn"
                  v-if="
                    !item.ImageLink &&
                    !additionalFiles.solutionsSoft[item.Id].length
                  "
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  additionalFilesChange(
                    'solutionsSoft',
                    item,
                    $event.target.files
                  )
                "
                hidden
              />
              <span class="field__content">
                <template v-if="additionalFiles.solutionsSoft[item.Id].length">
                  <img
                    :src="img.img"
                    v-for="(img, index) in additionalFiles.solutionsSoft[
                      item.Id
                    ].filter((i) => checkFileExt(i.name))"
                    :key="index"
                  />
                </template>
                <img
                  :src="getImgSrc(item.ImageLink)"
                  v-else-if="item.ImageLink"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="editFieldValue($event, 'solutionsSoft', item, 'Name')"
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsSoftName2"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  :value="item.SoftText"
                  v-if="parentData.language === 'ru'"
                  @blur="
                    editFieldValue($event, 'solutionsSoft', item, 'SoftText')
                  "
                ></textarea>
                <translate-field
                  :EntityColumnName="'SoftText'"
                  ref="translated-field-SoftText"
                  :OriginalValue="item.SoftText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab2 === 2">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 2)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(2)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label
              class="field inner-objects__item-img"
              v-if="additionalFiles.solutionsSoft[item.Id]"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span
                  class="add-file-btn"
                  v-if="
                    !item.ImageLink &&
                    !additionalFiles.solutionsSoft[item.Id].length
                  "
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  additionalFilesChange(
                    'solutionsSoft',
                    item,
                    $event.target.files
                  )
                "
                hidden
              />
              <span class="field__content">
                <template v-if="additionalFiles.solutionsSoft[item.Id].length">
                  <img
                    :src="img.img"
                    v-for="(img, index) in additionalFiles.solutionsSoft[
                      item.Id
                    ].filter((i) => checkFileExt(i.name))"
                    :key="index"
                  />
                </template>
                <img
                  :src="getImgSrc(item.ImageLink)"
                  v-else-if="item.ImageLink"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="editFieldValue($event, 'solutionsSoft', item, 'Name')"
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsSoftName"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  :value="item.SoftText"
                  v-if="parentData.language === 'ru'"
                  @blur="
                    editFieldValue($event, 'solutionsSoft', item, 'SoftText')
                  "
                ></textarea>
                <translate-field
                  :EntityColumnName="'SoftText'"
                  ref="translated-field-SoftText"
                  :OriginalValue="item.SoftText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="innerTab2 === 3">
        <div class="field">
          <label class="">
            <span
              class="button button_green button_uppercase settings-devices-zone__add"
              @click="createAdditionalData('solutionsSoft', 3)"
              >Добавить
            </span>
          </label>
        </div>
        <div class="inner-objects">
          <div
            class="inner-objects__item"
            v-for="(item, index) in getSoftsByType(3)"
            :key="index"
          >
            <span
              class="remove-icon"
              @click="deleteAdditionalData('solutionsSoft', item)"
            ></span>
            <label
              class="field inner-objects__item-img"
              v-if="additionalFiles.solutionsSoft[item.Id]"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Изображение:
                <span
                  class="add-file-btn"
                  v-if="
                    !item.ImageLink &&
                    !additionalFiles.solutionsSoft[item.Id].length
                  "
                  >+</span
                >
              </span>
              <input
                type="file"
                @change="
                  additionalFilesChange(
                    'solutionsSoft',
                    item,
                    $event.target.files
                  )
                "
                hidden
              />
              <span class="field__content">
                <template v-if="additionalFiles.solutionsSoft[item.Id].length">
                  <img
                    :src="img.img"
                    v-for="(img, index) in additionalFiles.solutionsSoft[
                      item.Id
                    ].filter((i) => checkFileExt(i.name))"
                    :key="index"
                  />
                </template>
                <img
                  :src="getImgSrc(item.ImageLink)"
                  v-else-if="item.ImageLink"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Название:
              </span>
              <span class="field__content">
                <input
                  type="text"
                  class="input"
                  :value="item.Name"
                  @blur="editFieldValue($event, 'solutionsSoft', item, 'Name')"
                  v-if="parentData.language === 'ru'"
                />
                <translate-field
                  :EntityColumnName="'Name'"
                  ref="translated-field-SolutionsSoftName3"
                  :OriginalValue="item.Name"
                  :inputType="'input'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
            <label class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Текст:
              </span>
              <span class="field__content">
                <textarea
                  class="textarea"
                  :value="item.SoftText"
                  v-if="parentData.language === 'ru'"
                  @blur="
                    editFieldValue($event, 'solutionsSoft', item, 'SoftText')
                  "
                ></textarea>
                <translate-field
                  :EntityColumnName="'SoftText'"
                  ref="translated-field-SoftText"
                  :OriginalValue="item.SoftText"
                  :inputType="'textarea'"
                  :module="'SiteSolutionsSoft'"
                  @getTranslation="
                    getInnerObjectTranslation('solutionsSoft', item)
                  "
                  :translation="item.translation"
                  :id="item.Id"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            ref="translated-field-SeoTitle"
            :OriginalValue="fields.SeoTitle"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            ref="translated-field-SeoDescription"
            :OriginalValue="fields.SeoDescription"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            ref="translated-field-SeoKeywords"
            :OriginalValue="fields.SeoKeywords"
            :inputType="'input'"
            :module="'SiteSolutions'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteSolutions'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import Api from "../../../api/commercial_site";
import { showAlert } from "../../../utils";
import * as _ from "lodash";

export default {
  data() {
    return {
      innerTab: 1,
      innerTab2: 1,
      additionalItems: {
        solutionsOpportunities: [],
        solutionsSoft: [],
        solutionsBenefits: [],
      },
      additionalFiles: {
        solutionsOpportunities: {},
        solutionsSoft: {},
        solutionsBenefits: {},
      },
      devices: [],
    };
  },
  watch: {
    id() {
      ["solutionsOpportunities", "solutionsSoft", "solutionsBenefits"].forEach(
        (objectName) => {
          this.getAdditionalData(objectName);
        }
      );
    },
  },
  created() {
    if (this.id !== "create") {
      ["solutionsOpportunities", "solutionsSoft", "solutionsBenefits"].forEach(
        (objectName) => {
          this.getAdditionalData(objectName);
        }
      );
      if (this.fields.Tasks) {
        _.each(this.fields.Tasks, (i) => {
          this.getInnerObjectTranslation("solutionsTasks", i);
        });
      }
    }
    Api.get("production")
      .then((res) => {
        this.devices = res.data;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  computed: {
    initialData() {
      return {
        solutionsOpportunities: {
          Name: "",
          ImageLink: "",
          OpportunitiesText: "",
          SolutionId: this.id,
          initial: true,
        },
        solutionsSoft: {
          Name: "",
          ImageLink: "",
          SoftText: "",
          SolutionId: this.id,
          initial: true,
        },
        solutionsBenefits: {
          Name: "",
          ImageLink: "",
          BenefitText: "",
          SolutionId: this.id,
          initial: true,
          BenefitType: 2,
        },
      };
    },
    devicesIdsArray() {
      const arr = [];
      _.each(this.devices, (i) => {
        if (i.Id !== this.id) arr.push(i.Id);
      });
      return arr;
    },
    softs() {
      return _.groupBy(this.additionalItems.solutionsSoft, "SoftType");
    },
  },
  methods: {
    createAdditionalData(objectName, type = 3) {
      const data = JSON.parse(JSON.stringify(this.initialData[objectName]));
      if (objectName === "solutionsBenefits") {
        data.BenefitType = type;
      }
      if (objectName === "solutionsSoft") {
        data.SoftType = type;
      }
      this.setAdditionalData(objectName, data, "create");
    },
    setAdditionalData(objectName, data, method = "set") {
      this.$emit("setPreloader", true);
      const formData = new FormData();
      _.each(data, (value, key) => {
        if (key !== "ImageLink") {
          formData.append(key, value);
        }
      });

      if (!data.initial) {
        formData.append("_method", "PATCH");
        const files = this.additionalFiles[objectName][data.Id];

        if (files && files.length) {
          _.each(files, (i) => {
            formData.append("image", i.file, i.name);
          });
        }
      }

      const Id = method === "set" ? data.Id : this.id;
      Api[method + "AdditionalDataForSolutions"](formData, Id, objectName)
        .then((res) => {
          if (res.data.status === "success") {
            this.getAdditionalData(objectName);
          } else {
            showAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
    },
    getAdditionalData(objectName) {
      this.$emit("setPreloader", true);
      Api.getAdditionalDataForSolutions(objectName, this.id)
        .then((res) => {
          this.additionalItems[objectName] = _.map(res.data, (i) => {
            if (!i.DateCreate) i.DateCreate = "2014-11-07 17:23:43.000";
            return i;
          });
          _.each(res.data, (i) => {
            if (this.additionalFiles[objectName][i.Id] === void 0) {
              this.additionalFiles[objectName][i.Id] = [];
            } else {
              this.additionalFiles[objectName][i.Id] = [];
            }
          });
          this.additionalFiles = JSON.parse(
            JSON.stringify(this.additionalFiles)
          );
          this.additionalItems = JSON.parse(
            JSON.stringify(this.additionalItems)
          );

          _.each(this.additionalItems[objectName], (o, index) => {
            Api.getTranslation(
              "Site" +
                objectName.charAt(0).toUpperCase() +
                objectName.substring(1),
              o.Id
            )
              .then((res) => {
                this.additionalItems[objectName][index].translation = res.data;
              })
              .catch(() => {
                showAlert("error", "Ошибка получения переводов");
              });
          });
          this.additionalItems = JSON.parse(
            JSON.stringify(this.additionalItems)
          );
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
    },
    deleteAdditionalData(objectName, data) {
      this.$emit("setPreloader", true);

      Api.deleteAdditionalDataForSolutions(objectName, data.Id)
        .then((res) => {
          if (res.data.status === "success") {
            this.getAdditionalData(objectName);
          } else {
            showAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
        })
        .finally(() => {
          this.$emit("setPreloader", false);
        });
    },
    additionalFilesChange(objectName, object, fileList) {
      if (!fileList.length) return;
      this.additionalFiles[objectName][object.Id] = [];
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.additionalFiles[objectName][object.Id].push({
          file: fileList[x],
          name: fileList[x].name,
          img: window.URL.createObjectURL(fileList[x]),
          id: Math.random().toString(36).substring(7),
        });
      });
      this.setAdditionalData(objectName, object);
    },
    editFieldValue(event, objectName, item, fieldName) {
      const newFieldValue = event.target.value;
      if (newFieldValue !== item[fieldName]) {
        item[fieldName] = newFieldValue;
        this.setAdditionalData(objectName, item);
      }
    },
    appendToTasksArray() {
      if (!this.fields.Tasks) this.fields.Tasks = [];
      this.fields.Tasks.push({ Name: "", TasksText: "" });
      this.fields = JSON.parse(JSON.stringify(this.fields));
    },
    deleteTask(item) {
      this.fields.Tasks = _.reject(this.fields.Tasks, ["Name", item.Name]);
      this.fields = JSON.parse(JSON.stringify(this.fields));
    },
    getBenefitsByType(BenefitType) {
      return _.orderBy(
        _.filter(this.additionalItems.solutionsBenefits, { BenefitType }),
        ["DateCreate"],
        ["asc"]
      );
    },
    getSoftsByType(SoftType) {
      if (this.softs) {
        return _.orderBy(this.softs[SoftType], ["DateCreate"], ["asc"]);
      }
    },
    getMultiselectOptionName(option) {
      // console.log(this.devices)
      return option.Name !== void 0
        ? option.Name
        : _.find(this.devices, ["Id", option])
        ? _.find(this.devices, ["Id", option]).Name
        : "...";
    },
    getInnerObjectTranslation(objectName, item) {
      this.getTranslation(
        "Site" + objectName.charAt(0).toUpperCase() + objectName.substring(1),
        item.Id
      ).then((res) => {
        if (objectName === "solutionsTasks") {
          this.fields.Tasks = _.map(this.fields.Tasks, (o) => {
            if (o.Id === item.Id) {
              o.translation = res.data;
            }
            return o;
          });
          // console.log(11122, objectName, res)
        } else {
          this.additionalItems[objectName] = _.map(
            this.additionalItems[objectName],
            (o) => {
              if (o.Id === res.id) {
                o.translation = res.data;
              }
              return o;
            }
          );
        }
      });
    },
  },
  mixins: [CommercialSitePopupFields],
};
</script>

<style scoped></style>
