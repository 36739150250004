<template>
  <div class="cm-popup__right">
    <translation-tabs :module="module" :id="id" />
    <div
      class="cm-popup-form__wrapper cm-popup-form__text-editor"
      v-if="tab === 1"
    >
      <input type="text" class="input" v-model="fields.UniqueName" />
      <input
        type="text"
        class="input"
        v-model="fields.GroupId"
        placeholder="GroupId"
      />
      <template v-if="fields.UniqueName === 'page_contacts'">
        <label class="field" v-if="contactsPageAddress">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Адрес:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPageAddress.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPageAddress)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value"
              :OriginalValue="contactsPageAddress.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPageAddress.Id)
              "
              :translation="contactsPageAddress.translation"
              :id="contactsPageAddress.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPagePhone1">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Отдел продаж - телефон:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPagePhone1.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPagePhone1)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value2"
              :OriginalValue="contactsPagePhone1.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPagePhone1.Id)
              "
              :translation="contactsPagePhone1.translation"
              :id="contactsPagePhone1.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPageEmail1">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Отдел продаж - email:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPageEmail1.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPageEmail1)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value3"
              :OriginalValue="contactsPageEmail1.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPageEmail1.Id)
              "
              :translation="contactsPageEmail1.translation"
              :id="contactsPageEmail1.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPageDesc1">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Отдел продаж - часы работы:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPageDesc1.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPageDesc1)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value4"
              :OriginalValue="contactsPageDesc1.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPageDesc1.Id)
              "
              :translation="contactsPageDesc1.translation"
              :id="contactsPageDesc1.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPagePhone2">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Тех. поддержка - телефон:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPagePhone2.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPagePhone2)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value5"
              :OriginalValue="contactsPagePhone2.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPagePhone2.Id)
              "
              :translation="contactsPagePhone2.translation"
              :id="contactsPagePhone2.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPageEmail2">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Тех. поддержка - email:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPageEmail2.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPageEmail2)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value6"
              :OriginalValue="contactsPageEmail2.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPageEmail2.Id)
              "
              :translation="contactsPageEmail2.translation"
              :id="contactsPageEmail2.Id"
            />
          </span>
        </label>
        <label class="field" v-if="contactsPageDesc2">
          <span
            class="field__label field__label_align_right clients-common-info__label"
          >
            Тех. поддержка - часы работы:
          </span>
          <span class="field__content">
            <input
              type="text"
              class="input"
              :value="contactsPageDesc2.Value"
              v-if="parentData.language === 'ru'"
              @blur="saveBlockInfo($event, contactsPageDesc2)"
            />
            <translate-field
              :EntityColumnName="'Value'"
              ref="translated-field-Value7"
              :OriginalValue="contactsPageDesc2.Value"
              :inputType="'input'"
              :module="'SiteBlocks'"
              @getTranslation="
                getTranslation('SiteBlocks', contactsPageDesc2.Id)
              "
              :translation="contactsPageDesc2.translation"
              :id="contactsPageDesc2.Id"
            />
          </span>
        </label>
        <button
          class="button button_green button_uppercase cm-popup-form__btn-submit"
          v-if="parentData.language !== 'ru'"
          @click="saveTranslatedField"
        >
          Сохранить
        </button>
      </template>
      <template v-else>
        <textarea
          class="textarea"
          v-model="fields.Value"
          v-if="parentData.language === 'ru'"
        >
        </textarea>
        <translate-field
          :EntityColumnName="'Value'"
          ref="translated-field-Value8"
          :OriginalValue="fields.Value"
          :inputType="'textarea'"
          :module="'SiteBlocks'"
          @getTranslation="getTranslation"
          :translation="translation"
          :id="id"
        />
        <!--<div class="skedit" v-if="parentData.language==='ru'">-->
        <!--<textarea v-tinymce-->
        <!--field="Value"-->
        <!--v-model="fields.Value"-->
        <!--/>-->
        <!--</div>-->
        <!--<translate-field :EntityColumnName="'Value'"-->
        <!--:OriginalValue="fields.Value"-->
        <!--:inputType="'editor'"-->
        <!--:module="'SiteBlocks'"-->
        <!--@getTranslation="getTranslation"-->
        <!--:translation="translation"-->
        <!--:id="id"-->
        <!--/>-->
        <button
          class="button button_green button_uppercase cm-popup-form__btn-submit"
          @click="save"
        >
          Сохранить
        </button>
      </template>
    </div>
    <div class="cm-popup-form__wrapper" v-if="tab === 11">
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Заголовок в шапке:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.PageTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'PageTitle'"
            ref="translated-field-PageTitle"
            :OriginalValue="fields.PageTitle"
            :inputType="'input'"
            :module="'SiteBlocks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Title:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoTitle"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoTitle'"
            ref="translated-field-SeoTitle"
            :OriginalValue="fields.SeoTitle"
            :inputType="'input'"
            :module="'SiteBlocks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Description:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoDescription"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoDescription'"
            ref="translated-field-SeoDescription"
            :OriginalValue="fields.SeoDescription"
            :inputType="'input'"
            :module="'SiteBlocks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Keywords:
        </span>
        <span class="field__content">
          <input
            type="text"
            class="input"
            v-model="fields.SeoKeywords"
            v-if="parentData.language === 'ru'"
          />
          <translate-field
            :EntityColumnName="'SeoKeywords'"
            ref="translated-field-SeoKeywords"
            :OriginalValue="fields.SeoKeywords"
            :inputType="'input'"
            :module="'SiteBlocks'"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>
      <label class="field">
        <span
          class="field__label field__label_align_right clients-common-info__label"
        >
          Text:
        </span>
        <span class="field__content">
          <textarea
            class="textarea"
            v-model="fields.SeoText"
            v-if="parentData.language === 'ru'"
          >
          </textarea>
          <translate-field
            :EntityColumnName="'SeoText'"
            :OriginalValue="fields.SeoText"
            :inputType="'textarea'"
            :module="'SiteBlocks'"
            ref="translated-field-SeoText"
            @getTranslation="getTranslation"
            :translation="translation"
            :id="id"
          />
        </span>
      </label>

      <button
        class="button button_green button_uppercase cm-popup-form__btn-submit"
        @click="save"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import CommercialSitePopupFields from "../../../mixins/site/CommercialSitePopupFields";
import { mapState, mapActions, mapMutations } from "vuex";
import * as _ from "lodash";
import Api from "../../../api/commercial_site";
import * as c from "../../../store/commercial_site/constants";
import { showAlert } from "../../../utils";

export default {
  mixins: [CommercialSitePopupFields],
  created() {
    if (this.fields.UniqueName === "page_contacts") {
      [
        "contactsPageAddress",
        "contactsPagePhone1",
        "contactsPagePhone2",
        "contactsPageEmail1",
        "contactsPageEmail2",
        "contactsPageDesc1",
        "contactsPageDesc2",
      ].forEach((i) => {
        if (this[i]) {
          this.getTranslation("SiteBlocks", this[i].Id);
        }
      });
    }
  },
  computed: {
    contactsPageAddress() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__address"]);
      }
      return false;
    },
    contactsPagePhone1() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__phone_1"]);
      }
      return false;
    },
    contactsPagePhone2() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__phone_2"]);
      }
      return false;
    },
    contactsPageEmail1() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__email_1"]);
      }
      return false;
    },
    contactsPageEmail2() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__email_2"]);
      }
      return false;
    },
    contactsPageDesc1() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__desc_1"]);
      }
      return false;
    },
    contactsPageDesc2() {
      if (this.blocks.length) {
        return _.find(this.blocks, ["UniqueName", "contacts__desc_2"]);
      }
      return false;
    },
    ...mapState("site", ["blocks"]),
  },
  methods: {
    saveBlockInfo(e, block) {
      if (block.Value !== e.target.value) {
        document.querySelector("#app").classList.add("app--load");
        const formData = new FormData();

        formData.append("_method", "PATCH");
        formData.append("Id", block.Id);
        formData.append("Value", e.target.value);
        Api.edit("blocks", block.Id, formData)
          .then((res) => {
            showAlert(res.data.status, res.data.message);
            this[c.EDIT_ELEMENT_IN_BLOCKS_ARRAY]({
              id: block.Id,
              data: e.target.value,
              field: "Value",
            });
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          })
          .finally(() => {
            document.querySelector("#app").classList.remove("app--load");
          });
      }
    },
    saveTranslatedField() {
      [
        "translated-field-Value",
        "translated-field-Value2",
        "translated-field-Value3",
        "translated-field-Value4",
        "translated-field-Value5",
        "translated-field-Value6",
        "translated-field-Value7",
      ].forEach((i) => {
        this.$refs[i].save();
      });
    },
    ...mapMutations("site", [c.EDIT_ELEMENT_IN_BLOCKS_ARRAY]),
    ...mapActions("site", [c.GET_BLOCKS]),
  },
};
</script>

<style scoped></style>
