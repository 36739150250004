<template>
  <div
    class="sup-input-group__input multi_select_with_checkboxes multi_select_with_checkboxes--tags"
    v-click-outside="hideSelect"
  >
    <div
      class="multi_select_with_checkboxes__labels"
      v-if="tagsList"
      @click="addTag"
    >
      <span
        class="tags__item tags__item_active"
        v-for="(oneTag, index) in tagsList"
        :key="index"
      >
        <span
          class="tags__text"
          :style="`background-color: #${oneTag.Color}; border-color: #${oneTag.Color};`"
          >{{ oneTag.TagName }}
          <i @click.prevent="$emit('deleteTagFromList', oneTag)">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </i>
        </span>
      </span>
      <input
        type="text"
        placeholder="Введите название тега"
        v-model="newTagName"
        ref="newTagName"
        v-if="settingTagNameState || (!newTagName && !tagsList.length)"
      />
    </div>

    <div class="sup-dropdown__wrap" v-if="settingTagNameState">
      <div
        class="sup-dropdown"
        :class="settingTagNameState ? 'sup-dropdown--open' : ''"
      >
        <span
          class="sup-dropdown__item"
          v-for="(oneTag, index) in filteredTags"
          :key="index"
        >
          <label
            class="checkbox checkbox_white table__checkbox js-index-checkbox"
          >
            <input
              type="checkbox"
              v-model="oneTag.checked"
              name="row_checkbox_clients"
              class="checkbox__input main_checkbox_input"
              @change="setTagFromList(oneTag)"
            />
            <span class="checkbox__box main_checkbox_span">
              <svg class="checkbox__icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-check"
                ></use>
              </svg>
            </span>
            <span>
              {{ oneTag.TagName }}
            </span>
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import Api from "../../../../api/support/rps";
import { mapState } from "vuex";
import { Helpers } from "../../../../mixins/Helpers";
import { EDIT_TAGS_RIGHT } from "@/store/support/constants";

export default {
  mixins: [Helpers],
  props: {
    initialTags: {
      default: [],
    },
    tagsList: {
      default: [],
    },
    asFilter: {
      default: false,
    },
  },
  data() {
    return {
      settingTagNameState: false,
      newTagName: "",
      EDIT_TAGS_RIGHT,
    };
  },
  computed: {
    canCreateNewTag() {
      if (!this.newTagName || !this.checkUserRight(EDIT_TAGS_RIGHT))
        return false;
      return !_.find(this.filteredTags, ["TagName", this.newTagName]);
    },
    filteredTags() {
      function findTags(initial, children) {
        for (let i = 0; i < children.length; i++) {
          initial.push(children[i]);
          if (children[i].child && children[i].child.length)
            findTags(initial, children[i].child);
        }
      }
      if (this.commonSettings.tags.length) {
        const tags = [];
        findTags(tags, this.commonSettings.tags);
        let filteredTags = tags.filter((i) => {
          const re = new RegExp(this.newTagName, "gi");
          return re.test(i.TagName);
        });
        filteredTags = _.map(filteredTags, (i) => {
          let foundTag = _.find(this.initialTags.array, ["TagId", i.Id]);
          if (this.asFilter) {
            foundTag = _.find(this.initialTags.array, ["Id", i.Id]);
          }
          i.checked = !!foundTag;
          return i;
        });
        return filteredTags;
      } else {
        return [];
      }
    },
    ...mapState("rpsSupport", ["commonSettings", "selectedRequest"]),
  },
  methods: {
    addTag() {
      this.settingTagNameState = true;
      setTimeout(() => {
        this.$refs["newTagName"].focus();
      });
    },
    setTagFromList(oneTag) {
      this.$refs["newTagName"].focus();
      this.newTagName = "";
      this.$emit("setTagFromList", oneTag);
    },
    editTag(event, id) {
      Api.editTag(id, {
        TagName: event.target.value,
      }).then((res) => {
        _.map(this.commonSettings.tags, (i) => {
          if (i.Id === id) {
            i.TagName = res.data.TagName;
          }
        });
      });
    },
    cancelSettingTag() {
      this.settingTagNameState = false;
      this.newTagName = "";
    },
    hideSelect(e) {
      if (
        this.settingTagNameState &&
        !e.target.closest(".multi_select_with_checkboxes--tags")
      ) {
        this.cancelSettingTag();
      }
    },
  },
};
</script>

<style scoped></style>
