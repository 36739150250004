import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    requestsObjects: [],
    reservedObjects: [],
    requestsCount: 0,
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
