<template>
  <div
    class="cm-translation-tabs"
    v-if="id !== 'create' && !['promo_landing', 'cities'].includes(module)"
  >
    <span
      :class="{ active: parentData.language === 'ru' }"
      @click="parentData.language = 'ru'"
      >RU</span
    >
    <span
      :class="{ active: parentData.language === 'en' }"
      @click="parentData.language = 'en'"
      >EN</span
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["module", "id"],
  computed: {
    ...mapState("site", ["parentData"]),
  },
};
</script>
