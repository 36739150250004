var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-cont-scroll pdf-cont--ml-s scroll-style print--17"},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s((_vm.i = 0)))]),_vm._v(" "),_vm._l(([
      'Entry',
      'Exit',
      'Additional',
      'Payment' ]),function(module,moduleIndex){return _vm._l((_vm.optionPages[module].filter(
        function (page) { return page.length && _vm.optionPages[module]; }
      )),function(page,index){return _c('div',{key:'page' + index + moduleIndex,staticClass:"pdf-cont"},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.i++))]),_vm._v(" "),_c('div',{staticClass:"print"},[_c('div',{staticClass:"print__cont"},[_c('div',{staticClass:"print__logo"},[_c('img',{attrs:{"src":_vm.getimgStaticSrc('sales/print-logo.svg')}})]),_vm._v(" "),_c('div',{staticClass:"print__title",style:({
              backgroundImage: ("url(" + (_vm.getimgStaticSrc(
                'sales/print-badge.svg'
              )) + ")"),
            })},[_vm._v("\n            Описание опций "),_c('br'),_vm._v(_vm._s(_vm.getTitle(module))+"\n          ")]),_vm._v(" "),_vm._l((page),function(option,index){return _c('div',{key:index,staticClass:"print__info-s",attrs:{"id":'id' + option.randomId}},[_c('div',{staticClass:"print__info-s-title",attrs:{"id":'id' + option.anotherId}},[_vm._v("\n              "+_vm._s(option.name)+"\n            ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(option.desc))])])})],2),_vm._v(" "),_c('div',{staticClass:"print__footer"},[_vm._v(_vm._s(_vm.countPage("Options", _vm.i)))])])])})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }