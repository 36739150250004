import * as c from "./constants";
import Api from "@/api/commercial_site";
import { showAlert } from "../../utils";
import * as _ from "lodash";

export default {
  [c.GET_SETTINGS](state) {
    Api.getSettings()
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_SETTINGS, res.data.settings);
          // check for auto db update
          console.log(res.data.settings.SiteBlogPosts);

          if (res.data.settings && res.data.settings.SiteBlogPosts === void 0) {
            // alert('время изменений')
            Api.saveSettings(state.getters.settings).catch((err) => {
              showAlert(
                "error",
                "Ошибка автообновления настроек изображений",
                err.message
              );
            });
            let formData = new FormData();
            formData.append("UniqueName", "seo_section_blog_posts");
            Api.createBlock(formData).catch((err) => {
              showAlert("error", "Ошибка автообновления блоков", err.message);
            });
            formData = new FormData();
            formData.append("UniqueName", "welcome_section_blog_posts");
            Api.createBlock(formData).catch((err) => {
              showAlert("error", "Ошибка автообновления блоков", err.message);
            });
          }
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.SAVE_SETTINGS](state, data) {
    Api.saveSettings(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "", " Настройки успешно сохранены");
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.GET_BLOCKS](state) {
    Api.getBlocks()
      .then((res) => {
        state.commit(c.SET_BLOCKS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.GET_CITIES](state, withTranslate = false) {
    Api.getCities()
      .then((res) => {
        if (res.data.length) {
          if (withTranslate) {
            const data = res.data;
            _.map(data, (i) => {
              Api.getTranslation("SiteCities", i.Id)
                .then((res) => {
                  i.translation = res.data;
                })
                .catch(() => {
                  showAlert("error", "Ошибка получения переводов для городов");
                })
                .finally(() => {
                  return i;
                });
            });
          }
          state.commit(c.GET_CITIES, res.data);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.WORK_TYPES_ACTION](state, { action, data }) {
    switch (action) {
      case "get":
        Api.getWorkTypes()
          .then((res) => {
            if (res.data.length) {
              const data = res.data;
              _.map(data, (i) => {
                Api.getTranslation("SiteWorkTypes", i.Id)
                  .then((res) => {
                    i.translation = res.data;
                  })
                  .catch(() => {
                    showAlert(
                      "error",
                      "Ошибка получения переводов для типов работ"
                    );
                  })
                  .finally(() => {
                    return i;
                  });
              });
              state.commit(c.WORK_TYPES_ACTION, data);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "create":
        Api.createWorkType(data)
          .then((res) => {
            if (res.data.status === "success") {
              state.dispatch(c.WORK_TYPES_ACTION, { action: "get" });
            } else {
              showAlert("error", "Ошибка", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "edit":
        Api.editWorkTypes(data)
          .then(() => {
            state.dispatch(c.WORK_TYPES_ACTION, { action: "get" });
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "delete":
        Api.deleteWorkTypes(data)
          .then((res) => {
            if (res.data.status === "success") {
              state.dispatch(c.WORK_TYPES_ACTION, { action: "get" });
            } else {
              showAlert("error", "Ошибка", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
    }
  },
  [c.BLOG_CAT_ACTION](state, { action, data }) {
    switch (action) {
      case "get":
        Api.getBlogCategories()
          .then((res) => {
            if (res.data.length) {
              const data = res.data;
              _.map(data, (i) => {
                Api.getTranslation("SiteBlogCategories", i.Id)
                  .then((res) => {
                    i.translation = res.data;
                  })
                  .catch(() => {
                    showAlert(
                      "error",
                      "Ошибка получения переводов для типов работ"
                    );
                  })
                  .finally(() => {
                    return i;
                  });
              });
              state.commit(c.BLOG_CAT_ACTION, data);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "create":
        Api.createBlogCategory(data)
          .then((res) => {
            if (res.data.status === "success") {
              state.dispatch(c.BLOG_CAT_ACTION, { action: "get" });
            } else {
              showAlert("error", "Ошибка", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "edit":
        Api.editBlogCategories(data)
          .then(() => {
            state.dispatch(c.BLOG_CAT_ACTION, { action: "get" });
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
      case "delete":
        Api.deleteBlogCategories(data)
          .then((res) => {
            if (res.data.status === "success") {
              state.dispatch(c.BLOG_CAT_ACTION, { action: "get" });
            } else {
              showAlert("error", "Ошибка", res.data.message);
            }
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
          });
        break;
    }
  },
  [c.GET_BASE_URL](state) {
    Api.getBaseUrl()
      .then((res) => {
        state.commit(c.GET_BASE_URL, res.data.domain);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.IMPORT_LANG](state, data) {
    Api.importLang(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert(
            res.data.status,
            "Успешно",
            "Кол-во обновленных строк: " + res.data.totalRows
          );
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
      });
  },
  [c.GET_DISTRICTS](state) {
    Api.getDistricts().then((res) => {
      state.commit(c.GET_DISTRICTS, res.data);
    });
  },
};
