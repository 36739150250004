<template>
  <div class="task-bubble" v-click-outside="close">
    <div class="task-bubble__search">
      <svg>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="/static/images/icon.svg#icon_icon-search"
        ></use>
      </svg>
      <input
        type="text"
        class="input"
        :placeholder="fieldNames.searchInputName || 'Поиск'"
        v-model="search"
        ref="input"
      />
      <div class="task-bubble__close" @click="close()"><i></i></div>
    </div>
    <div class="task-bubble__list" ref="list">
      <GroupedListSearchItem
        v-for="(item, index) in parentItems"
        :key="index"
        :item="item"
        :parentItems="parentItems"
        :search="search"
        @choose="choose"
        :fieldNames="fieldNames"
        :selectedItemsArray="selectedItemsArray"
      />
    </div>
  </div>
</template>

<script>
import common from "../../../../../mixins/tasks/common";
import { Helpers } from "../../../../../mixins/Helpers";
import GroupedListSearchItem from "@/modules/tasks/components/CommonComponents/GroupedListSearch/GroupedListSearchItem";

export default {
  mixins: [common, Helpers],
  props: ["items", "fieldNames", "selectedItemsArray"],
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$refs["input"].focus();
  },
  computed: {
    parentItems() {
      return this.items.filter((i) => {
        const childArray = i[this.fieldNames.childListFieldName].filter(
          (child) => {
            const re = new RegExp(this.search, "gi");
            return re.test(child[this.fieldNames.childFieldName]);
          }
        );
        return childArray.length;
      });
    },
  },
  methods: {
    choose(item) {
      this.$emit("choose", item);
      if (this.selectedItemsArray === void 0) this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    GroupedListSearchItem,
  },
};
</script>

<style scoped></style>
