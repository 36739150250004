<template>
  <div>
    <div class="tableInnerRow">
      <div class="table__row table__row_main">
        <div
          class="table__col table__col_width_360"
          @click="openForm($event.target)"
        >
          <div class="cm-table-col-1">
            <div class="cm-table-col-1__arrow">
              <svg class="select__caret">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="/static/images/icon.svg#icon_icon-caret"
                ></use>
              </svg>
            </div>
            <div class="cm-table-col-1__text">
              <span class="cm-table-col-1__name">
                <template v-if="cat !== 'tokensdbl'"> Модуль въезда </template>
                <template v-else> Модуль въезда\выезда </template>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cm-client-detail-form" v-if="cat">
        <div
          class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single"
        >
          <div class="cm-popup__tabs">
            <div
              class="cm-popup__tabs-item"
              @click="tab = 1"
              :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
            >
              Фото
            </div>
            <div
              class="cm-popup__tabs-item"
              @click="tab = 2"
              :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
            >
              Опции
            </div>
          </div>

          <div class="field field--input-file device-images" v-if="tab === 1">
            <label
              class="field field-cover"
              v-for="(img, index) in settingsObject.json.devices[cat].entry
                .img_rack"
              :key="index"
            >
              <span
                class="field__label field__label_align_right clients-common-info__label"
              >
                Картинка {{ img.id }}:
              </span>
              <span class="field__content input-file">
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  class="input"
                  @change="
                    saveDeviceImage($event.target, cat, 'entry_rack', img.id)
                  "
                />
                <input type="text" class="input" :value="img.img" disabled />
                <div class="button button_grey button-file">
                  <img src="/static/images/gps.svg" />
                  Выбрать файл
                </div>
              </span>

              <div
                class="table__col table__col-btn"
                v-if="index > 0"
                @click.prevent="
                  actionDeviceImages(
                    'remove',
                    img.id,
                    settingsObject.json.devices[cat].entry.img_rack,
                    cat,
                    'entry_rack'
                  )
                "
              >
                <div class="table__text-block">
                  <span class="remove-icon"></span>
                </div>
              </div>
              <div class="device-img-preview">
                <img
                  class="input-file__img"
                  v-if="imageBlobCheck('devices', cat + '_entry_rack', img.id)"
                  :src="img.blob"
                  alt=""
                />
                <img
                  class="input-file__img"
                  v-else
                  :src="getImageSrc(img.img, config.system)"
                  alt=""
                />
              </div>
            </label>

            <div class="field">
              <span
                class="field__label field__label_align_right clients-common-info__label"
              ></span>
              <span class="field__content input-file">
                <span
                  class="little-down-text"
                  @click="
                    actionDeviceImages(
                      'add',
                      null,
                      settingsObject.json.devices[cat].entry.img_rack
                    )
                  "
                  >+ Добавить ещё</span
                >
              </span>
            </div>
          </div>

          <div v-if="tab === 2" class="table cm-table-1 cm-table--device">
            <div class="table__row-wrap сmp__row-wrap">
              <div class="table__row table__row_main table__row-head">
                <div class="table__col table__col_width_260">
                  <div class="table__text-block">
                    <p class="table__text">Наименование</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text" style="padding-left: 0">
                      Цена за ед. в $
                    </p>
                  </div>
                </div>
                <div class="table__col table__col_width_120">
                  <div class="table__text-block">
                    <p class="table__text" style="padding-left: 0">Кол-во</p>
                  </div>
                </div>
                <div class="table__col table__col_width_180">
                  <div class="table__text-block">
                    <p class="table__text" style="padding-left: 0">
                      Один комплект
                    </p>
                  </div>
                </div>
                <!--                <div class="table__col table__col_width_260">-->
                <!--                  <div class="table__text-block">-->
                <!--                    <p class="table__text">Описание опции</p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <span
                  class="little-down-text little-down-text--devices little-down-text--hidden"
                ></span>
              </div>

              <draggable
                v-model="settingsObject.json.devices[cat].entry.rack"
                class="flex-column"
                @end="changeOrder(cat, 'entry', 'rack')"
              >
                <div
                  class="table__row table__row_main"
                  v-for="(item, index) in settingsObject.json.devices[cat].entry
                    .rack"
                  :key="index"
                  :class="item.is_device ? 'table__row-device--main' : ''"
                >
                  <div class="table__col table__col_width_200">
                    <div class="table__text-block table__text-block--order">
                      <span
                        ><img
                          class="g-item__drag"
                          src="@/assets/images/drag.svg"
                          alt=""
                      /></span>
                      <input type="text" class="input" v-model="item.name" />
                    </div>
                  </div>
                  <div class="table__col table__col_width_180">
                    <div class="table__text-block">
                      <input type="number" class="input" v-model="item.cost" />
                    </div>
                  </div>
                  <div class="table__col table__col_width_120">
                    <div class="table__text-block">
                      <input
                        type="number"
                        class="input"
                        v-model.number="item.count"
                      />
                    </div>
                  </div>
                  <div class="table__col table__col_width_180">
                    <div class="table__text-block">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          :id="'myonoffswitch7' + index"
                          v-model="item.countAsOneKit"
                        />
                        <label
                          class="onoffswitch-label"
                          :for="'myonoffswitch7' + index"
                        >
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="table__col table__col_width_260">-->
                  <!--                    <div class="table__text-block">-->
                  <!--                      <input type="text" class="input" v-model="item.desc" v-if="index > 0">-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="table__col table__col-btn" v-if="index > 0">
                    <div
                      class="table__text-block"
                      @click="
                        actionDevice('remove', cat, 'entry', 'rack', item.id)
                      "
                    >
                      <span class="remove-icon"></span>
                    </div>
                  </div>
                  <span
                    class="little-down-text little-down-text--devices little-down-text--hidden"
                    v-else
                  ></span>
                </div>
              </draggable>
            </div>
            <div class="device-add-btns-wrap">
              <span
                class="little-down-text little-down-text--devices"
                @click="actionDevice('add', cat, 'entry', 'rack', null, true)"
                >+ Добавить устройство</span
              >
              <span
                class="little-down-text little-down-text--devices"
                @click="actionDevice('add', cat, 'entry', 'rack', null, false)"
                >+ Добавить опцию</span
              >
            </div>
          </div>
        </div>
        <div class="cm-client-detail-form__btns">
          <span @click="showPreview(6, cat, false)">Превью</span>
          <span @click="saveSettings">Сохранить</span>
        </div>
      </div>
    </div>
    <!--    <div class="tableInnerRow ">-->
    <!--      <div class="table__row table__row_main">-->
    <!--        <div class="table__col table__col_width_360" @click="openForm($event.target)">-->
    <!--          <div class="cm-table-col-1">-->
    <!--            <div class="cm-table-col-1__arrow">-->
    <!--              <svg class="select__caret">-->
    <!--                <use xmlns:xlink="http://www.w3.org/1999/xlink"-->
    <!--                     xlink:href="/static/images/icon.svg#icon_icon-caret"></use>-->
    <!--              </svg>-->
    <!--            </div>-->
    <!--            <div class="cm-table-col-1__text"><span-->
    <!--              class="cm-table-col-1__name">Шлагбаум модуля въезда</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="cm-client-detail-form" v-if="cat">-->

    <!--        <div class="cm-popup-form__wrapper cm-popup-form__wrapper&#45;&#45;tabs cm-popup-form__wrapper&#45;&#45;single">-->
    <!--          <div class="cm-popup__tabs">-->
    <!--            <div class="cm-popup__tabs-item" @click="tab=1" :class="tab===1 ? 'cm-popup__tabs-item&#45;&#45;active' : ''">Фото</div>-->
    <!--            <div class="cm-popup__tabs-item" @click="tab=2" :class="tab===2 ? 'cm-popup__tabs-item&#45;&#45;active' : ''">Опции</div>-->
    <!--          </div>-->

    <!--          <div class="field field&#45;&#45;input-file device-images" v-if="tab===1">-->
    <!--            <label class="field field-cover" v-for="(img, index) in settingsObject.json.devices[cat].entry.img_barrier" :key="index">-->
    <!--          <span class="field__label field__label_align_right clients-common-info__label">-->
    <!--            Картинка {{img.id}}:-->
    <!--          </span>-->
    <!--              <span class="field__content input-file">-->
    <!--              <input type="file" accept="image/*" hidden class="input" @change="saveDeviceImage($event.target, cat, 'entry_barrier', img.id)">-->
    <!--              <input type="text" class="input" :value="img.img" disabled>-->
    <!--              <div class="button button_grey button-file">-->
    <!--                  <img src="/static/images/gps.svg">-->
    <!--                  Выбрать файл-->
    <!--              </div>-->
    <!--          </span>-->

    <!--              <div class="table__col table__col-btn"-->
    <!--                   v-if="index > 0"-->
    <!--                   @click.prevent="actionDeviceImages('remove', img.id, settingsObject.json.devices[cat].entry.img_barrier, cat, 'entry_barrier')"-->
    <!--              >-->
    <!--                <div class="table__text-block">-->
    <!--                  <span class="remove-icon"></span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="device-img-preview">-->
    <!--                <img class="input-file__img" v-if="imageBlobCheck('devices', cat+'_entry_barrier', img.id)" :src="img.blob" alt="">-->
    <!--                <img class="input-file__img" v-else :src="getImageSrc(img.img, config.system)" alt="">-->
    <!--              </div>-->
    <!--            </label>-->

    <!--            <div class="field">-->
    <!--              <span class="field__label field__label_align_right clients-common-info__label"></span>-->
    <!--              <span class="field__content input-file">-->
    <!--              <span class="little-down-text" @click="actionDeviceImages('add', null, settingsObject.json.devices[cat].entry.img_barrier)">+ Добавить ещё</span>-->
    <!--          </span>-->
    <!--            </div>-->

    <!--          </div>-->

    <!--          <div v-if="tab===2" class="table cm-table-1 cm-table&#45;&#45;device">-->
    <!--            <div class="table__row-wrap сmp__row-wrap">-->
    <!--              <div class="table__row table__row_main table__row-head">-->
    <!--                <div class="table__col table__col_width_260">-->
    <!--                  <div class="table__text-block">-->
    <!--                    <p class="table__text">Наименование</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="table__col table__col_width_180">-->
    <!--                  <div class="table__text-block">-->
    <!--                    <p class="table__text" style="padding-left: 0;">Цена за ед. в $</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="table__col table__col_width_120">-->
    <!--                  <div class="table__text-block">-->
    <!--                    <p class="table__text" style="padding-left: 0;">Кол-во</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="table__col table__col_width_180">-->
    <!--                  <div class="table__text-block">-->
    <!--                    <p class="table__text" style="padding-left: 0;">Один комплект</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--&lt;!&ndash;                <div class="table__col table__col_width_260">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <div class="table__text-block">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <p class="table__text">Описание опции</p>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--                <span class="little-down-text little-down-text&#45;&#45;devices little-down-text&#45;&#45;hidden"></span>-->

    <!--              </div>-->

    <!--              <draggable v-model="settingsObject.json.devices[cat].entry.barrier"-->
    <!--                         class="flex-column"-->
    <!--                         @end="changeOrder(cat, 'entry', 'barrier')">-->
    <!--                <div class="table__row table__row_main"-->
    <!--                     v-for="(item, index) in settingsObject.json.devices[cat].entry.barrier"-->
    <!--                     :class="item.is_device ? 'table__row-device&#45;&#45;main':''"-->
    <!--                >-->
    <!--                  <div class="table__col table__col_width_200">-->
    <!--                    <div class="table__text-block table__text-block&#45;&#45;order">-->
    <!--                      <span><img class="g-item__drag" src="@/assets/images/drag.svg" alt=""></span>-->
    <!--                      <input type="text" class="input" v-model="item.name">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="table__col table__col_width_180">-->
    <!--                    <div class="table__text-block">-->
    <!--                      <input type="number" class="input" v-model="item.cost">-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="table__col table__col_width_120">-->
    <!--                    <div class="table__text-block">-->
    <!--                      <input type="number" class="input" v-model.number="item.count" >-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="table__col table__col_width_180">-->
    <!--                    <div class="table__text-block">-->
    <!--                      <div class="onoffswitch">-->
    <!--                        <input type="checkbox"-->
    <!--                               name="onoffswitch"-->
    <!--                               class="onoffswitch-checkbox"-->
    <!--                               :id="'myonoffswitch6'+index"-->
    <!--                               v-model="item.countAsOneKit"-->
    <!--                        >-->
    <!--                        <label class="onoffswitch-label" :for="'myonoffswitch6'+index">-->
    <!--                          <span class="onoffswitch-inner"></span>-->
    <!--                          <span class="onoffswitch-switch"></span>-->
    <!--                        </label>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--&lt;!&ndash;                  <div class="table__col table__col_width_260">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="table__text-block">&ndash;&gt;-->
    <!--&lt;!&ndash;                      <input type="text" class="input" v-model="item.desc" v-if="index > 0">&ndash;&gt;-->
    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--                  <div class="table__col table__col-btn"-->
    <!--                       v-if="index > 0"-->
    <!--                  >-->
    <!--                    <div class="table__text-block" @click="actionDevice('remove', cat, 'entry', 'barrier', item.id)">-->
    <!--                      <span class="remove-icon"></span>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <span class="little-down-text little-down-text&#45;&#45;devices little-down-text&#45;&#45;hidden" v-else></span>-->

    <!--                </div>-->
    <!--              </draggable>-->

    <!--            </div>-->

    <!--            <div class="device-add-btns-wrap">-->
    <!--            <span class="little-down-text little-down-text&#45;&#45;devices"-->
    <!--                  @click="actionDevice('add', cat, 'entry', 'barrier', null, true)">+ Добавить устройство</span>-->
    <!--              <span class="little-down-text little-down-text&#45;&#45;devices"-->
    <!--                    @click="actionDevice('add', cat, 'entry', 'barrier', null, false)">+ Добавить опцию</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="cm-client-detail-form__btns">-->
    <!--          <span @click="showPreview(6, cat, false)">Превью</span>-->
    <!--          <span @click="saveSettings">Сохранить</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import SalesDraggableMixin from "@/mixins/sales/SalesDraggableMixin";

export default {
  mixins: [SalesHelpers, SalesDraggableMixin],
  props: {
    cat: null,
  },
  data() {
    return {
      tab: 1,
    };
  },

  methods: {},
};
</script>
