<template>
  <div class="popup task-popup event-popup event-popup_active">
    <div class="popup__wrapper" @click="hidePopupByClickWrap($event)">
      <div class="popup__content">
        <div class="popup__header">
          <h2 class="popup__heading">Шаблоны автоматизаций</h2>
          <div class="popup__close" @click="onClose">
            <svg class="popup__close-icon">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-close"
              ></use>
            </svg>
          </div>
        </div>
        <div class="popup__body">
          <div class="cm-popup">
            <div class="cm-popup__right">
              <div class="task-popup-header">
                <div class="actions-header__col actions-header__col_flexible">
                  <div
                    class="search-bar search-bar_white actions-header__search-bar"
                  >
                    <button class="search-bar__button">
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/static/images/icon.svg#icon_icon-search"
                        ></use>
                      </svg>
                    </button>
                    <input
                      type="search"
                      placeholder="Свойство, событие, условие, действие, объект действия, параметр действия"
                      v-model="search"
                      class="search-bar__input"
                    />
                  </div>
                </div>
              </div>
              <div class="automation-template">
                <div
                  class="automation-template__row automation-template__row--create"
                >
                  <div class="automation-template__col">
                    <div class="automation-template__index">+</div>
                  </div>

                  <AutomationBuilder
                    class="automation-template__col"
                    :form="createTemplate"
                    :parentData="parentData"
                    ref="create-automation-builder"
                    :create="true"
                    :edit="true"
                  />

                  <div class="automation-template__col">
                    <div
                      class="automation-template__btn automation-template__btn--reset"
                      v-if="!allowCreate && filledOneValue"
                      @click="reset"
                    >
                      Сброс
                    </div>
                    <div
                      class="automation-template__btn"
                      v-else
                      :class="{
                        'automation-template__btn--disabled': !allowCreate,
                      }"
                      @click="allowCreate && create()"
                    >
                      Готово
                    </div>
                  </div>
                </div>
                <AutomationCreateControlItem
                  v-for="(item, index) in automatizations"
                  :item="item"
                  :index="index"
                  :parentData="parentData"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="simple-spinner" v-if="parentData.preloader">
          <Spinner
            :noFadeIn="true"
            name="wave"
            color="#c9eeff"
            width="60"
            height="60"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutomationCreateControlItem from "./AutomationCreateControlItem";
import { mapActions, mapState } from "vuex";
import { CREATE_AUTOMATION_TEMPLATE } from "../../../../../store/tasks/constants";
import AutomationBuilder from "../AutomationBuilder";
import { automationParamsTemplate } from "../template";
import * as _ from "lodash";

const fieldsArray = [
  "ActionObjectOptionId",
  "ActionObjectTypeId",
  "ActionObjectType",
  "ActionOptionId",
  "ObjectTypeId",
  "ActionId",
];

const templateData = {
  ActionId: null,
  ObjectTypeId: null,
  ActionOptionId: null,
  ActionObjectType: null,
  ActionObjectTypeId: null,
  ActionObjectOptionId: null,
};

export default {
  name: "AutomationCreateControl",
  data() {
    return {
      createTemplate: JSON.parse(JSON.stringify(templateData)),
      search: "",
      parentData: {
        preloader: false,
      },
    };
  },
  computed: {
    allowCreate() {
      let can = true;
      _.each(fieldsArray, (i) => {
        if (!this.createTemplate[i]) {
          can = false;
        }
      });
      return can;
    },
    filledOneValue() {
      let can = false;
      _.each(fieldsArray, (i) => {
        if (this.createTemplate[i]) {
          can = true;
        }
      });
      return can;
    },
    ...mapState("tasks", ["automatizations"]),
  },
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
    hidePopupByClickWrap(event) {
      if (event.target.classList.contains("popup__wrapper")) {
        this.onClose();
      }
    },
    create() {
      const builder = this.$refs["create-automation-builder"];
      this.parentData.preloader = true;
      this[CREATE_AUTOMATION_TEMPLATE](builder.paramsToSave())
        .then(() => {
          this.reset();
        })
        .finally(() => (this.parentData.preloader = false));
    },
    reset() {
      this.createTemplate = JSON.parse(JSON.stringify(templateData));
      const builder = this.$refs["create-automation-builder"];
      builder.conditionTemplate = null;
      builder.actionTemplate = null;
      builder.params = {
        condition: JSON.parse(JSON.stringify(automationParamsTemplate)),
        action: JSON.parse(JSON.stringify(automationParamsTemplate)),
      };
    },
    ...mapActions("tasks", [CREATE_AUTOMATION_TEMPLATE]),
  },
  components: {
    AutomationCreateControlItem,
    AutomationBuilder,
  },
};
</script>

<style scoped></style>
