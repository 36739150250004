import * as c from "./constants";
import { showAlert } from "@/utils";
import Api from "@/api/tasks";
import * as _ from "lodash";
import { getStandartFilterNameInfo } from "@/mixins/tasks/func";
import { sortsTemplate } from "@/modules/tasks/components/utils";

export default {
  [c.GET_GROUP_USERS](state, groupId) {
    Api.getGroupUsers(groupId)
      .then((res) => {
        state.commit(c.SET_USERS, res.data);
      })
      .catch((err) => {
        showAlert(
          "error",
          "Ошибка получения списка пользователей",
          err.message
        );
      });
  },
  [c.GET_OBJECTS](state) {
    if (!state.getters.objects.length) {
      Api.getObjects()
        .then((res) => {
          state.commit(c.GET_OBJECTS, res.data);
        })
        .catch((err) => {
          showAlert("error", "Ошибка получения списка объектов", err.message);
        });
    }
  },
  // roles
  [c.GET_ROLES](state) {
    Api.getRoles()
      .then((res) => {
        state.commit(c.GET_ROLES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.CREATE_ROLE](state, data) {
    Api.createRole(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Роль успешно создана");
          state.commit(c.CREATE_ROLE, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.EDIT_ROLE](state, { id, data }) {
    Api.editRole(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Роль успешно изменена");
          state.commit(c.EDIT_ROLE, { id, data: res.data.entityModel });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_ROLE](state, id) {
    Api.deleteRole(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Роль успешно удалена");
          state.commit(c.DELETE_ROLE, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.ADD_ROLE_FOR_USER](state, data) {
    Api.addRoleForUser(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Пользователь успешно привязан к роли");
          state.dispatch(c.GET_ROLES);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_ROLE_FOR_USER](state, id) {
    Api.deleteRoleForUser(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Пользователь успешно отвязан от роли");
          state.dispatch(c.GET_ROLES);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },

  // rights
  [c.GET_RIGHTS](state) {
    Api.getRights()
      .then((res) => {
        state.commit(c.GET_RIGHTS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.CREATE_RIGHT](state, data) {
    Api.createRight(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Право успешно создано");
          state.commit(c.CREATE_RIGHT, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.EDIT_RIGHT](state, { id, data }) {
    Api.editRight(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Право успешно изменено");
          state.commit(c.EDIT_RIGHT, { id, data: res.data.entityModel });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_RIGHT](state, id) {
    Api.deleteRight(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Право успешно удалено");
          state.commit(c.DELETE_RIGHT, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.ADD_RIGHT_FOR_ROLE](state, data) {
    Api.addRightForRole(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Право успешно привязано к роли");
          state.dispatch(c.GET_ROLES);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_RIGHT_FOR_ROLE](state, id) {
    Api.deleteRightForRole(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Право успешно отвязано от роли");
          state.dispatch(c.GET_ROLES);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },

  // templates
  [c.GET_TEMPLATES](state) {
    return new Promise((resolve) => {
      if (state.getters.group) {
        Api.getTemplates(state.getters.group.Id)
          .then((res) => {
            state.commit(c.GET_TEMPLATES, res.data);
            resolve();
          })
          .catch((err) => {
            showAlert("error", "Ошибка", err.message);
            console.log(err);
          })
          .finally(() => {});
      }
    });
  },
  async [c.GET_TEMPLATE_TASKS](state, id) {
    let result = null;
    await Api.getTemplateTasks(id)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_TEMPLATE_TASK_DETAIL](state, id) {
    let result = null;
    await Api.getTemplateTaskDetail(id)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_TEMPLATE_TASK_CHILD](state, id) {
    let result = null;
    await Api.getTemplateTaskChild(id)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data.items;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.EDIT_TEMPLATE_TASK](state, { id, data }) {
    let result = null;
    await Api.editTemplateTask(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.ADD_FILES_TO_TEMPLATE_TASK](state, data) {
    let result = null;
    await Api.addFilesToTemplateTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data.Files;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return result;
  },
  async [c.DELETE_FILES_FROM_TEMPLATE_TASK](state, data) {
    let result = null;
    await Api.deleteFilesFromTemplateTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        return Promise.reject(err);
      });
    return result;
  },
  async [c.UPLOAD_TMP_FILES](state, data) {
    let result = null;
    await Api.uploadTmpFile(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data.files;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return result;
  },
  async [c.CREATE_TEMPLATE_TASK](state, data) {
    let result = null;
    await Api.createTemplateTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data.entityModel;
        } else {
          return Promise.reject(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        showAlert("error", "Ошибка", err.message);
        return Promise.reject(err);
      });
    return result;
  },
  async [c.CREATE_TASKS_FROM_TEMPLATE](state, data) {
    let result = null;
    await Api.createTasksFromTemplate(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.dispatch(c.GET_USER_TASKS_LIST, true);
          state.dispatch(c.GET_TASKS);
          result = res.data;
        } else {
          return Promise.reject(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        showAlert("error", "Ошибка", err.message);
        return Promise.reject(err);
      });
    return result;
  },
  async [c.CREATE_MANY_ROOT_TASKS](state, data) {
    let result = null;
    await Api.createManyRootTasks(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
          state.dispatch(c.GET_USER_TASKS_LIST, true);
          state.dispatch(c.GET_TASKS);
        } else {
          return Promise.reject(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        showAlert("error", "Ошибка", err.message);
        return Promise.reject(err);
      });
    return result;
  },
  async [c.DELETE_TEMPLATE_TASK](state, id) {
    let result = null;
    await Api.deleteTemplateTask(id)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.DELETE_MULTIPLE_TEMPLATE_TASKS](state, data) {
    let result = null;
    await Api.deleteMultipleTemplateTasks(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        return Promise.reject(err);
      });
    return result;
  },
  async [c.EDIT_MULTIPLE_TEMPLATE_TASKS](state, data) {
    let result = null;
    await Api.editMultipleTemplateTasks(data)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
        return Promise.reject(err);
      });
    return result;
  },
  [c.CREATE_TEMPLATE](state, { payload, GroupId }) {
    return new Promise((resolve, reject) => {
      Api.createTemplate(payload)
        .then((res) => {
          if (res.data.status === "success") {
            showAlert("success", "Шаблон успешно создан");
            state.dispatch(c.ADD_TEMPLATE_TO_GROUP, {
              GroupId,
              TemplateId: res.data.entityModel.Id,
            });
            state.commit(c.CREATE_TEMPLATE, res.data.entityModel);
            resolve(res.data);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject({});
        })
        .finally(() => {});
    });
  },
  [c.EDIT_TEMPLATE](state, { id, data }) {
    return new Promise((resolve, reject) => {
      Api.editTemplate(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            showAlert("success", "Шаблон успешно изменен");
            state.commit(c.EDIT_TEMPLATE, { id, data: res.data.entityModel });
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject({});
        })
        .finally(() => {});
    });
  },
  [c.DELETE_TEMPLATE](state, id) {
    Api.deleteTemplate(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Шаблон успешно удален");
          state.commit(c.DELETE_TEMPLATE, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },

  // groups
  async [c.GET_GROUPS](state) {
    state.dispatch(c.GET_GLOBAL_GROUPS);
    await Api.getGroups()
      .then((res) => {
        state.commit(c.GET_GROUPS, res.data.groups);
        if (state.getters.group && !state.getters.users.length) {
          state.dispatch(c.GET_GROUP_USERS, state.getters.group.Id);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.UPDATE_CURRENT_GROUP](state) {
    Api.getGroupById(state.getters.group.Id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_COMMON_LOCAL_STORAGE_SETTINGS, {
            queryType: "group",
            queryId: res.data.group.Id,
            model: res.data.group,
          });
          state.commit(c.SET_CURRENT_GROUP, res.data.group);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.LOAD_FULL_INFO_OF_GROUP](state, id) {
    const foundGroup = _.find(state.getters.allGroups, ["Id", id]);

    if (Object.keys(foundGroup).length < 5) {
      await Api.getGroupById(id)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.LOAD_FULL_INFO_OF_GROUP, res.data.group);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    }
    return true;
  },
  async [c.CREATE_GROUP](state, { payload, UserId }) {
    return await Api.createGroup(payload)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Группа успешно создана");
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.dispatch(c.GET_GROUPS);
          state.dispatch(c.ADD_USER_TO_GROUP, {
            payload: {
              GroupId: res.data.entityModel.Id,
              UserId,
            },
          });
          state.dispatch(c.CREATE_SECTION, {
            Name: "virtualSection",
            GroupId: res.data.entityModel.Id,
          });
          state.commit(c.CREATE_GROUP, res.data.entityModel);
          return res;
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.EDIT_GROUP](state, { id, data }) {
    Api.editGroup(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Группа успешно изменена");
          state.commit(c.EDIT_GROUP, res.data.entityModel);
          state.commit(c.SET_COMMON_LOCAL_STORAGE_SETTINGS, {
            queryType: "group",
            queryId: res.data.entityModel.Id,
            model: res.data.entityModel,
          });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_GROUP](state, id) {
    return new Promise((resolve) => {
      Api.deleteGroup(id)
        .then((res) => {
          if (res.data.status === "success") {
            showAlert("success", "Группа успешно удалена");
            state.dispatch(c.GET_USER_TASKS_LIST);
            state.dispatch(c.GET_GROUPS);
            // state.commit(c.DELETE_GROUP, id);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_USER_TO_GROUP](state, payload) {
    return new Promise((resolve) => {
      Api.addUserToGroup(payload)
        .then((res) => {
          if (res.data.status === "success") {
            showAlert("success", "Пользователь успешно привязан к группе");
            state.dispatch(c.UPDATE_CURRENT_GROUP);
            state.dispatch(c.GET_USER_TASKS_LIST);
            resolve();
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_USER_FROM_GROUP](state, id) {
    Api.deleteUserToGroup(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Пользователь успешно отвязан от группы");
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.dispatch(c.UPDATE_CURRENT_GROUP);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.ADD_TEMPLATE_TO_GROUP](state, data) {
    Api.addTemplateToGroup(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Шаблон успешно привязан к группе");
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.dispatch(c.UPDATE_CURRENT_GROUP);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_TEMPLATE_FROM_GROUP](state, data) {
    Api.deleteTemplateToGroup(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Шаблон успешно отвязан от группы");
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.dispatch(c.UPDATE_CURRENT_GROUP);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },

  // tasks
  async [c.SET_CURRENT_GROUP](state, { group, forceUpdateCurrentGroup }) {
    if (Object.keys(group).length < 5 || forceUpdateCurrentGroup) {
      await Api.getGroupById(group.Id)
        .then((res) => {
          if (res.data.status === "success") {
            group = res.data.group;
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    }

    state.commit(c.SET_CURRENT_GROUP, group);
    state.commit(c.SET_COMMON_MODULE_VIEW_TYPE, false);
    state.dispatch(c.GET_GROUP_USERS, group.Id);
    state.dispatch(c.GET_PROCESSES, group.Id);

    state.commit(c.SET_COMMON_LOCAL_STORAGE_SETTINGS, {
      queryType: "group",
      queryId: group.Id,
      model: group,
    });
    const foundGroupLocalSettings = _.find(state.getters.localStorageSettings, [
      "Id",
      group.Id,
    ]);
    if (foundGroupLocalSettings) {
      const groupSettings = JSON.parse(foundGroupLocalSettings.settings);
      state.commit(c.SET_COMMON_MODULE_VIEW_TYPE, groupSettings.viewType);
      if (groupSettings.viewType !== "project") {
        state.dispatch(c.GET_TASKS);
      } else {
        state.commit(c.SET_COMMON_MODULE_SORTS, {
          ...JSON.parse(JSON.stringify(sortsTemplate)),
          Activity: {
            asc: false,
            active: true,
          },
        });
        state.dispatch(c.GET_TASKS, { orderBy: "Activity DESC" });
      }
    } else {
      state.dispatch(c.GET_TASKS);
    }
  },
  async [c.GET_TASKS](state, params = {}) {
    state.commit(c.TASKS_LOADING_PRELOADER_ENABLE);
    state.commit(c.SET_TABLE_FILTER, null);
    state.commit(c.SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK, 1);
    state.commit(c.SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY, null);
    state.commit(c.SET_TABLE_FILTER_CACHED, null);
    if (!params.page) {
      state.commit(c.SET_TASKS_TO_NULL);
      state.commit(c.SET_COMMON_MODULE_PAGE, 0);
    }
    if (state.getters.taskFilterParams) {
      if (!params.orderBy) params.orderBy = "Position";

      const status = {};

      if (state.getters.settings.hideCompletedTasks)
        status["params[Status]"] = 1;
      state.commit(c.DISABLE_FILTER_GROUP_TASKS_BY_GROUP);
      state.commit(c.SET_GROUPED_TASKS_BY_FILTER);
      await Api.getTasks({
        ...state.getters.taskFilterParams,
        ...params,
        ...status,
      })
        .then((res) => {
          if (params.page && params.page > 0) {
            state.commit(c.ADD_TASKS_TO_LIST, res.data);
            state.commit(c.ADD_TASKS_TO_SECTION, res.data);
          } else {
            state.commit(c.GET_TASKS, res.data);
            state.dispatch(c.GET_SECTIONS, state.getters.group.Id);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => state.commit(c.TASKS_LOADING_PRELOADER_DISABLE));
    }
    return true;
  },
  [c.GET_TASKS_BY_STANDART_FILTER](state, { type, params, reset }) {
    state.commit(c.TASKS_LOADING_PRELOADER_ENABLE);
    state.commit(c.SET_USERS, null);
    state.commit(c.SET_SECTIONS_TO_NULL);
    state.commit(c.SET_CURRENT_GROUP, null);
    state.commit(c.SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK, 1);
    state.commit(c.SET_TABLE_FILTER, type);
    state.commit(c.SET_TABLE_FILTER_NAME, getStandartFilterNameInfo(type)[0]);
    if (reset) {
      state.commit(c.SET_COMMON_MODULE_PAGE, 0);
      state.commit(c.SET_COMMON_MODULE_VIEW_TYPE, false);
    }
    state.commit(c.SET_COMMON_LOCAL_STORAGE_SETTINGS, {
      queryType: "standartFilter",
      queryId: type,
    });

    params = params ? params : {};
    if (!params.page) {
      state.commit(c.SET_TASKS_TO_NULL);
    }
    if (!params.orderBy) params.orderBy = "TaskName DESC";
    const status = {};
    if (state.getters.settings.hideCompletedTasks) status["params[Status]"] = 1;

    if (state.getters.settings.filterGroupTaskByGroups) {
      params.GroupTasks = 1;
      state.commit(c.SET_GROUPED_TASKS_BY_FILTER);
    }

    Api.getTasksByStandartFilter(type, {
      ...status,
      ...params,
      Page: params.page || 0,
      OrderBy: params.orderBy,
    })
      .then((res) => {
        if (res.data.status === "success") {
          if (params.page && params.page > 0) {
            state.commit(c.ADD_TASKS_TO_LIST, res.data.tasks);
          } else {
            state.commit(c.GET_TASKS, res.data.tasks);
          }
          if (state.getters.settings.filterGroupTaskByGroups) {
            state.commit(c.SET_GROUPED_TASKS_BY_FILTER, res.data.groupedTasks);
          }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.TASKS_LOADING_PRELOADER_DISABLE));
  },
  async [c.GET_TASKS_BY_USER_FILTER](state, { id, params, reset, filter }) {
    state.commit(c.TASKS_LOADING_PRELOADER_ENABLE);
    state.commit(c.SET_USERS, null);
    state.commit(c.SET_SECTIONS_TO_NULL);
    state.commit(c.SET_CURRENT_GROUP, null);
    state.commit(c.SET_TABLE_FILTER_COUNT_FOR_FLASH_FILTER_CHECK, 0);
    state.commit(c.SET_TABLE_FILTER, id);
    if (reset) {
      state.commit(c.SET_COMMON_MODULE_SHARED_FILTER_IN_QUERY, null);
      state.commit(c.SET_COMMON_MODULE_PAGE, 0);
      state.commit(c.SET_COMMON_MODULE_VIEW_TYPE, false);
    }
    const settingsData = {
      queryType: "userFilter",
      queryId: id,
    };
    if (filter) settingsData.model = filter;
    state.commit(c.SET_COMMON_LOCAL_STORAGE_SETTINGS, settingsData);

    let result = null;

    params = params ? params : {};
    if (!params.page) {
      state.commit(c.SET_TASKS_TO_NULL);
    }
    if (state.getters.settings.hideCompletedTasks) params["params[Status]"] = 1;

    if (state.getters.settings.filterGroupTaskByGroups) {
      params.GroupTasks = 1;
      state.commit(c.SET_GROUPED_TASKS_BY_FILTER);
    }

    await Api.getTasksByUserFilter(id, { ...params, Page: params.page || 0 })
      .then((res) => {
        if (res.data.status === "success") {
          if (params.page && params.page > 0) {
            state.commit(c.ADD_TASKS_TO_LIST, res.data.tasks);
          } else {
            state.commit(c.GET_TASKS, res.data.tasks);
          }
          if (state.getters.settings.filterGroupTaskByGroups) {
            state.commit(c.SET_GROUPED_TASKS_BY_FILTER, res.data.groupedTasks);
          }
        } else {
          showAlert(res.data.status, res.data.message);
        }
        result = res;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    state.commit(c.TASKS_LOADING_PRELOADER_DISABLE);
    return result;
  },
  [c.GET_TASK_BY_FLASH_FILTER](state, { data, pagination = 0 }) {
    state.commit(c.TASKS_LOADING_PRELOADER_ENABLE);
    state.commit(c.SET_USERS, null);
    state.commit(c.SET_SECTIONS_TO_NULL);
    if (!pagination) {
      state.commit(c.SET_TASKS_TO_NULL);
    }
    Api.getTasksByFlashFilter(data, pagination)
      .then((res) => {
        if (res.data.status === "success") {
          if (pagination && pagination > 0) {
            state.commit(c.ADD_TASKS_TO_LIST, res.data.tasks);
          } else {
            state.commit(c.GET_TASKS, res.data.tasks);
          }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.TASKS_LOADING_PRELOADER_DISABLE));
  },
  [c.SHOW_TASK](state, id) {
    return new Promise((resolve, reject) => {
      Api.showTask(id)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.SHOW_TASK, res.data);
            resolve();
          } else {
            showAlert("error", "Ошибка", res.data.message);
            reject({});
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject({});
        })
        .finally(() => {});
    });
  },
  [c.CREATE_TASK](state, { form, addCreatedTaskToChildArray }) {
    return new Promise((resolve, reject) => {
      Api.createTask(form)
        .then((res) => {
          if (res.data.status === "success") {
            if (addCreatedTaskToChildArray) {
              state.commit(c.CREATE_TASK, res.data.entity);
              state.commit(c.REFRESH_SECTIONS);
            }
            // state.dispatch(c.CREATE_SUBTASK_SECTION, {
            //   Name: "virtualSection",
            //   GroupId: state.getters.group.Id,
            //   ParentTaskId: res.data.entity.Id,
            // });
            showAlert("success", "Задача успешно создана");
            state.dispatch(c.GET_USER_TASKS_LIST, true);
            resolve(res.data.entity);
          } else {
            showAlert(res.data.status, res.data.message);
            reject({});
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject({});
        })
        .finally(() => {});
    });
  },
  [c.CREATE_TASK_NO_MUTATION](state, data) {
    return new Promise((resolve, reject) => {
      Api.createTask(data)
        .then((res) => {
          if (res.data.status === "success") {
            resolve(res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
            reject({});
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject({});
        });
    });
  },
  async [c.EDIT_TASK](state, { id, data }) {
    // if (!data.FinishDate) {
    //   data.FinishDate = "";
    // }

    await Api.editTask(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          // showAlert('success', 'Задача успешно изменена')
          state.commit(c.EDIT_TASK, { id, data: res.data.entityModel });
          state.commit(c.REFRESH_SECTIONS);
          return res;
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.SILENT_EDIT_TASK](state, { id, data }) {
    Api.editTask(id, data);
  },
  async [c.CHANGE_TASK_GROUP](state, data) {
    await Api.changeTaskGroup(data)
      .then((res) => {
        if (!res.data.status === "success") {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return true;
  },
  async [c.DELETE_TASK](state, { id, batch }) {
    await Api.deleteTask(id)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Задача успешно удалена");
          state.commit(c.DELETE_TASK, id);
          if (!batch) {
            state.dispatch(c.GET_USER_TASKS_LIST, true);
          }
          state.commit(c.REFRESH_SECTIONS);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
    return true;
  },
  async [c.GET_FILES_FOR_TASK](state, id) {
    let result = null;
    await Api.getFilesForTask(id).then((res) => {
      state.commit(c.GET_FILES_FOR_TASK, res.data.files);
      result = res.data.files;
    });
    return result;
  },
  async [c.GET_DEPENDENCIES_TASKS](state, id) {
    let result = null;
    await Api.getDependenciesTasks(id).then((res) => {
      result = res.data.tasks;
    });
    return result;
  },
  async [c.DELETE_DEPENDENCIES_TASK](state, id) {
    let result = null;
    await Api.deleteDependenciesTask(id).then((res) => {
      if (res.data.status === "success") {
        state.commit(c.DELETE_DEPENDENCIES_TASK, id);
      }
      result = res;
    });
    return result;
  },

  // checklists
  [c.CREATE_CHECKLIST](state, data) {
    return new Promise((resolve, reject) => {
      state.commit(c.CREATE_CHECKLIST_LOCAL, data);
      Api.createChecklist(data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.CREATE_CHECKLIST, {
              checklistEl: res.data.entityModel,
              localId: data.localId,
            });
            resolve(res.data.entityModel);
          } else {
            reject({});
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          reject({});
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.CREATE_CHECKLIST_NO_MUTATION](state, data) {
    return new Promise((resolve, reject) => {
      Api.createChecklist(data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            resolve(res.data.entityModel);
          } else {
            reject({});
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          reject({});
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.EDIT_CHECKLIST](state, { id, data }) {
    Api.editChecklist(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          // showAlert('success', 'Задача успешно изменена')
          // state.commit(c.EDIT_CHECKLIST, { id, data: res.data.entityModel })
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.DELETE_CHECKLIST](state, id) {
    Api.deleteChecklist(id)
      .then((res) => {
        if (res.data.status === "success") {
          // showAlert('success', 'Задача успешно удалена')
          state.commit(c.DELETE_CHECKLIST, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
  },
  [c.CREATE_CHECKLIST_ITEM](state, data) {
    return new Promise((resolve) => {
      state.commit(c.CREATE_CHECKLIST_ITEM_LOCAL, data);
      state.commit(c.ORDER_CHECKLIST_ITEMS);

      Api.createChecklistItem(data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.CREATE_CHECKLIST_ITEM, {
              taskEl: res.data.entityModel,
              localId: data.localId,
            });
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.CREATE_CHECKLIST_ITEM_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.createChecklistItem(data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.EDIT_CHECKLIST_ITEM](state, { id, data }) {
    return new Promise((resolve) => {
      Api.editChecklistItem(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Задача успешно изменена')
            state.commit(c.EDIT_CHECKLIST_ITEM, res.data.entityModel);
            resolve();
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_CHECKLIST_ITEM](state, id) {
    return new Promise((resolve) => {
      Api.deleteChecklistItem(id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Задача успешно удалена')
            state.commit(c.DELETE_CHECKLIST_ITEM, res.data.entityModel);
            state.commit(c.ORDER_CHECKLIST_ITEMS);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_FILES_TO_TASK](state, formData) {
    return new Promise((resolve) => {
      state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 10);
      Api.addFilesToTask(state, formData)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            // state.commit(c.ADD_FILES_TO_TASK, res.data.finalFiles);
            resolve(res.data.finalFiles);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {
          state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 99);
          setTimeout(() => {
            state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 0);
          }, 400);
        });
    });
  },
  [c.ADD_FILES_TO_TASK_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.addFilesToTask(state, data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_LOCAL_FILES_TO_TASK](state, data) {
    return new Promise((resolve) => {
      Api.addLocalFilesToTask(state, data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.ADD_DOC_FILES_TO_TASK](state, data) {
    return new Promise((resolve) => {
      state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 10);
      Api.addDocFilesToTask(state, data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            // state.commit(c.ADD_FILES_TO_TASK, res.data.finalFiles);
            resolve(res.data.finalFiles);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {
          state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 99);
          setTimeout(() => {
            state.commit(c.SET_PROGRESS_BAR_BY_FILES_UPLOAD, 0);
          }, 400);
        });
    });
  },
  [c.ADD_DOC_FILES_TO_TASK_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.addDocFilesToTask(state, data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_FILES_FROM_TASK](state, file) {
    return new Promise((resolve) => {
      Api.deleteFilesFromTask(file.Id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.DELETE_FILES_FROM_TASK, file);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_FILE_TO_FAVORITE](state, data) {
    return new Promise((resolve, reject) => {
      Api.addFileToFavorite(data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.ADD_FILE_TO_FAVORITE, res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject(err);
        });
    });
  },
  [c.REMOVE_FILE_FROM_FAVORITE](state, id) {
    return new Promise((resolve, reject) => {
      Api.removeFileFromFavorite(id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.REMOVE_FILE_FROM_FAVORITE, res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);

          reject(err);
        })
        .finally(() => {});
    });
  },

  // comments
  [c.CREATE_COMMENT](state, data) {
    return new Promise((resolve) => {
      Api.createComment(data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.CREATE_COMMENT, res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.EDIT_COMMENT](state, { id, data }) {
    return new Promise((resolve) => {
      Api.editComment(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.EDIT_COMMENT, { id, data: res.data.entityModel });
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_COMMENT](state, id) {
    return new Promise((resolve) => {
      Api.deleteComment(id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Задача успешно удалена')
            // state.commit(c.DELETE_COMMENT, id)
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },

  // contacts
  [c.CREATE_CONTACT](state, data) {
    return new Promise((resolve) => {
      Api.createContact(data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.CREATE_CONTACT, res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.CREATE_CONTACT_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.createContact(data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.EDIT_CONTACT](state, { id, data }) {
    return new Promise((resolve) => {
      Api.editContact(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.EDIT_CONTACT, { id, data: res.data.entityModel });
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.EDIT_CONTACT_QUIET](state, { id, data }) {
    Api.editContact(id, data).catch((err) => {
      showAlert("error", "Ошибка", err.message);
      console.log(err);
    });
  },
  [c.DELETE_CONTACT](state, id) {
    state.commit(c.DELETE_CONTACT, id);
    return new Promise((resolve) => {
      Api.deleteContact(id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Задача успешно удалена')
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_SUBSCRIBER](state, data) {
    if (
      state.getters.selectedTask &&
      _.find(state.getters.selectedTask.subscriptions, ["UserId", data.UserId])
    ) {
      return false;
    }
    return new Promise((resolve) => {
      Api.addSubscriber(data)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Чек лист для задачи успешно создан')
            state.commit(c.ADD_SUBSCRIBER, res.data.entityModel);
            resolve(res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.ADD_SUBSCRIBER_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.addSubscriber(data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_SUBSCRIBER](state, id) {
    return new Promise((resolve) => {
      Api.deleteSubscriber(id)
        .then((res) => {
          if (res.data.status === "success") {
            // showAlert('success', 'Задача успешно удалена')
            state.commit(c.DELETE_SUBSCRIBER, id);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },

  // tags
  [c.GET_TAGS](state, data) {
    Api.getTags(data)
      .then((res) => {
        state.commit(c.GET_TAGS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.CREATE_TAG](state, data) {
    Api.createTag(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_TAG, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.EDIT_TAG](state, { id, data }) {
    return new Promise((resolve) => {
      Api.editTag(id, data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.EDIT_TAG, { id, data: res.data.entityModel });
            resolve();
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.DELETE_TAG](state, id) {
    Api.deleteTag(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DELETE_TAG, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_TAG_GROUPS](state, data) {
    Api.getTagGroups(data)
      .then((res) => {
        state.commit(c.GET_TAG_GROUPS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.CREATE_TAGS_GROUP](state, data) {
    Api.createTagGroup(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_TAGS_GROUP, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.EDIT_TAGS_GROUP](state, { id, data }) {
    Api.patchTagGroup(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.EDIT_TAGS_GROUP, { id, data: res.data.entityModel });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.DELETE_TAGS_GROUP](state, id) {
    Api.deleteTagGroup(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DELETE_TAGS_GROUP, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.ADD_TAG_TO_TASK](state, { data }) {
    return Api.addTagToTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.ADD_TAG_TO_TASK, { data: res.data.entityModel });
          return Promise.resolve(res.data.entityModel);

          // if (task) {
          //   state.dispatch(c.GET_CHILD_TASKS_DETAIL, task)
          // }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.ADD_TAG_TO_TASK_NO_MUTATION](state, { data }) {
    Api.addTagToTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          // state.commit(c.ADD_TAG_TO_TASK, { data: res.data.entityModel })
          // if (task) {
          //   state.dispatch(c.GET_CHILD_TASKS_DETAIL, task)
          // }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.REMOVE_TAG_FROM_TASK](state, { Id }) {
    Api.removeTagFromTask({ Id })
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.REMOVE_TAG_FROM_TASK, res.data.entityModel);
          // if (task) {
          //   state.dispatch(c.GET_CHILD_TASKS_DETAIL, task)
          // }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.ADD_TAG_TO_GROUP](state, data) {
    return new Promise((resolve) => {
      Api.addTagToGroup(data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.ADD_TAG_TO_GROUP, res.data.entityModel);
            resolve();
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.REMOVE_TAG_FROM_GROUP](state, data) {
    return new Promise((resolve) => {
      Api.removeTagFromGroup(data)
        .then((res) => {
          if (res.data.status === "success") {
            resolve();
            state.commit(c.REMOVE_TAG_FROM_GROUP, data);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_NOTIFICATIONS](state, params) {
    return new Promise((resolve) => {
      Api.getNotifications(params)
        .then((res) => {
          if (res.data.status === "success") {
            // state.commit(c.GET_NOTIFICATIONS, res.data.notifications)
            resolve(res.data.notifications);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_GROUPED_NOTIFICATIONS](state, params) {
    return new Promise((resolve) => {
      Api.getGroupedNotifications(params)
        .then((res) => {
          if (res.data.status === "success") {
            resolve(res.data.notifications);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_NOTIFICATIONS_FROM_ONE_TASK](state, taskId) {
    return new Promise((resolve) => {
      Api.getNotificationsFromOneTask(taskId)
        .then((res) => {
          if (res.data.status === "success") {
            resolve(res.data.notifications);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_UNREAD_NOTIFICATIONS]() {
    return new Promise((resolve) => {
      Api.getUnreadNotifications()
        .then((res) => {
          if (res.data.status === "success") {
            // state.commit(c.GET_NOTIFICATIONS, res.data.notifications)
            resolve(res.data.notifications);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_SPECIFIC_NOTIFICATIONS](state, params) {
    return new Promise((resolve) => {
      Api.getNotifications(params)
        .then((res) => {
          if (res.data.status === "success") {
            resolve(res.data.notifications);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.APPROVE_CHECKLIST_ITEM](state, { id, task }) {
    Api.approveChecklistTask(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.APPROVE_CHECKLIST_ITEM, task);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.GET_CHILD_TASKS_DETAIL](state, { task, params }) {
    let Status = [1, 2];
    if (params !== void 0) {
      if (params.hideCompletedTasks) Status = 1;
    } else if (state.getters.settings.hideCompletedTasks) Status = 1;

    await Api.getChildTasksDetail({
      ParentId: task.Id,
      Status,
    })
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_CHILD_TASKS_DETAIL, {
            childs: res.data.childs,
            depends: res.data.depends,
            item: task,
          });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return true;
  },
  [c.CREATE_CHECKLIST_ITEM_NOTIFICATION](state, data) {
    Api.createChecklistItemNotification(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(
            c.CREATE_CHECKLIST_ITEM_NOTIFICATION,
            res.data.entityModel
          );
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.UPDATE_CHECKLIST_ITEM_NOTIFICATION](state, data) {
    Api.updateChecklistItemNotification(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(
            c.UPDATE_CHECKLIST_ITEM_NOTIFICATION,
            res.data.entityModel
          );
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.DELETE_CHECKLIST_ITEM_NOTIFICATION](state, id) {
    Api.deleteChecklistItemNotification(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(
            c.DELETE_CHECKLIST_ITEM_NOTIFICATION,
            res.data.entityModel
          );
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.ADD_WATCHER](state, data) {
    if (
      state.getters.selectedTask &&
      _.find(state.getters.selectedTask.watchers, ["UserId", data.UserId])
    ) {
      return false;
    }
    return new Promise((resolve) => {
      Api.addWatcher(data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.ADD_WATCHER, res.data.entityModel);
            resolve(res.data.entityModel);
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.ADD_WATCHER_NO_MUTATION](state, data) {
    return new Promise((resolve) => {
      Api.addWatcher(data)
        .then((res) => {
          if (res.data.status !== "success") {
            showAlert(res.data.status, res.data.message);
          }
          resolve(res.data.entityModel);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        })
        .finally(() => {});
    });
  },
  [c.DELETE_WATCHER](state, id) {
    return new Promise((resolve) => {
      Api.deleteWatcher(id)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.DELETE_WATCHER, id);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_STANDART_FILTERS](state) {
    return new Promise((resolve) => {
      Api.getStandartFilters()
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.GET_STANDART_FILTERS, res.data.filtersTypes);
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.GET_USER_FILTERS](state) {
    return new Promise((resolve) => {
      Api.getUserFilters()
        .then((res) => {
          state.commit(c.GET_USER_FILTERS, res.data);
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.CREATE_USER_FILTER](state, data) {
    return new Promise((resolve) => {
      Api.createUserFilter(data)
        .then((res) => {
          if (res.data.status === "success") {
            // state.commit(c.CREATE_USER_FILTER, res.data.entityModel);
            state.dispatch(c.GET_USER_TASKS_LIST);

            resolve(res.data.entityModel);
            showAlert("success", "", "Новый фильтр создан");
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.EDIT_USER_FILTER](state, data) {
    return new Promise((resolve) => {
      console.log(data);
      Api.editUserFilter(data.Id, data)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.EDIT_USER_FILTER, res.data.entityModel);
            state.dispatch(c.GET_USER_TASKS_LIST);
            showAlert("success", "", "Фильтр обновлен");
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.DELETE_USER_FILTER](state, id) {
    return new Promise((resolve) => {
      Api.deleteUserFilter(id)
        .then((res) => {
          if (res.data.status === "success") {
            state.commit(c.DELETE_USER_FILTER, res.data.entityModel);
            showAlert("success", "", "Фильтр удален");
          } else {
            showAlert(res.data.status, res.data.message);
          }
          resolve();
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.MARK_ALL_NOTIFICATIONS_READ](state, data) {
    Api.markAllNotificationsRead(data)
      .then(() => {
        state.dispatch(c.GET_COUNT_NOTIFICATIONS);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_COUNT_NOTIFICATIONS_BY_INTERVAL](state) {
    function getNotification() {
      Api.getCountUnreadNotifications()
        .then((res) => {
          state.commit(c.GET_COUNT_NOTIFICATIONS, res.data);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    }

    getNotification();
    setInterval(function () {
      getNotification();
    }, 25000);
  },
  [c.GET_COUNT_NOTIFICATIONS](state) {
    return new Promise((resolve) => {
      Api.getCountUnreadNotifications()
        .then((res) => {
          state.commit(c.GET_COUNT_NOTIFICATIONS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
    });
  },
  [c.APPROVE_TASK](state, id) {
    Api.approveTask(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.APPROVE_TASK);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_PROCESSES](state, id = "") {
    Api.getProcesses(id)
      .then((res) => {
        state.commit(id ? c.SET_GROUP_PROCESSES : c.GET_PROCESSES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CREATE_PROCESS](state, data) {
    await Api.createProcess(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_PROCESS, res.data.entityModel);
          state.dispatch(c.UPDATE_CURRENT_GROUP);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.ATTACH_PROCESS_TO_GROUP](state, data) {
    await Api.attachProcess(data)
      .then((res) => {
        if (res.data.status === "success") {
          // state.commit(c.ATTACH_PROCESS_TO_GROUP, res.data.entityModel)
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.DETACH_PROCESS_FROM_GROUP](state, data) {
    await Api.detachProcess(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.dispatch(c.UPDATE_CURRENT_GROUP);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.UPDATE_PROCESS](state, data) {
    await Api.updateProcess(data.Id, data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.UPDATE_PROCESS, data);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.DELETE_PROCESS](state, id) {
    await Api.deleteProcess(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DELETE_PROCESS, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CREATE_STATUS](state, data) {
    await Api.createStatus(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_STATUS, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.UPDATE_STATUS](state, data) {
    await Api.updateStatus(data.Id, data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.UPDATE_STATUS, data);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.DELETE_STATUS](state, id) {
    await Api.deleteStatus(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DELETE_STATUS, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CHANGE_STATUSES_ORDER](state, data) {
    await Api.changeStatusesOrder(data)
      .then((res) => {
        if (res.data.status === "success") {
          // state.commit(c.CHANGE_STATUSES_ORDER, res.data.entityModel)
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_USER_FAVORITES](state, data) {
    Api.getUserFavorites(data)
      .then((res) => {
        state.commit(c.GET_USER_FAVORITES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.ADD_TO_FAVORITES](state, data) {
    await Api.addToFavorites(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.commit(c.ADD_TO_FAVORITES, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.DELETE_FROM_FAVORITES](state, id) {
    await Api.removeFromFavorites(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.dispatch(c.GET_USER_TASKS_LIST);
          state.commit(c.DELETE_FROM_FAVORITES, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CHANGE_FAVORITES_ORDER](state, data) {
    await Api.changeFavoritesOrder(data)
      .then((res) => {
        if (res.data.status === "success") {
          // state.commit(c.CHANGE_STATUSES_ORDER, res.data.entityModel)
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CHANGE_ORDER](state, { items, apiMethod }) {
    if (items.length) {
      await Api.changeOrder(items, apiMethod)
        .then((res) => {
          if (res.data.status === "success") {
            // state.commit(c.CHANGE_ORDER, items);
            // state.commit(c.CHANGE_STATUSES_ORDER, res.data.entityModel)
          } else {
            showAlert(res.data.status, res.data.message);
          }
        })
        .catch((err) => {
          showAlert("error", "Ошибка", err.message);
          console.log(err);
        });
      return true;
    }
  },
  async [c.UPDATE_USER_PROFILE](state, { data, id }) {
    await Api.updateUserProfile(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert(res.data.status, "Профиль обновлен");
          state.commit(c.UPDATE_USER_PROFILE, { id, data });
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.UPDATE_USER_PASSWORD](state, data) {
    await Api.updateUserProfile(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert(res.data.status, "Пароль изменен");
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.SET_TASK_PROCESS_STATUS](state, data) {
    Api.editTask(data.taskId, {
      StatusId: data.statusId,
      Status: "1",
    });
    state.commit(c.SET_TASK_PROCESS_STATUS, data);
  },
  async [c.SAVE_USER_AVATAR](state, data) {
    await Api.saveUserAvatar(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.dispatch(c.GET_CURRENT_USER_SETTINGS);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.TRANSFER_TASK_TO_OTHER_GROUP](state, { data, batch }) {
    await state.dispatch(c.EDIT_TASK, data).then(() => {
      if (!batch) {
        showAlert("success", "Задача перенесена");
        state.dispatch(c.GET_USER_TASKS_LIST, true);
        state.dispatch(c.GET_TASKS);
        state.commit(c.TRANSFER_TASK_TO_OTHER_GROUP, data.data);
      }
    });
    return true;
  },
  async [c.TRANSFER_TASK_TO_OTHER_TASK](state, { payload, task, batch }) {
    await state.dispatch(c.EDIT_TASK, payload).then(() => {
      if (!batch) {
        showAlert("success", "Задача перенесена");
        state.commit(c.TRANSFER_TASK_TO_OTHER_TASK, task);
        state.dispatch(c.GET_USER_TASKS_LIST, true);
      }
    });
    return true;
  },
  async [c.COPY_TASK](state, data) {
    await Api.copyTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "Задача успешно скопироанна");
          state.commit(c.CREATE_TASK, res.data.entityModel);
          state.commit(c.REFRESH_SECTIONS);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return true;
  },
  [c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY](state, { idsArray, push }) {
    state.commit(c.SET_TASKS_NOTIFICATIONS_IDS_ARRAY, { idsArray, push });
    Api.getTasksNewNotificationsByArray({ tasksIds: idsArray })
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_TASKS_NEW_NOTIFICATIONS_BY_ARRAY, res.data.tasks);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_USER_TASKS_MODULE_RIGHTS](state, router) {
    Api.getUserRights()
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_USER_TASKS_MODULE_RIGHTS, res.data);
        } else {
          router.push("/");
          showAlert("error", "", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка получения прав", err.message);
      });
  },
  async [c.GET_CUSTOM_FILTER_PARAMS](state) {
    await Api.getCustomFilterParams()
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_CUSTOM_FILTER_PARAMS, res.data.params);
        } else {
          showAlert("error", "", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },

  // automation
  async [c.CREATE_AUTOMATION_TEMPLATE](state, data) {
    await Api.createAutomationTemplate(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.CREATE_AUTOMATION_TEMPLATE, res.data.entityModel);
        } else {
          showAlert("error", "", res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.GET_AUTOMATION_TEMPLATES](state) {
    await Api.getAutomationTemplates()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_TEMPLATES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return true;
  },
  [c.GET_AUTOMATION_OBJECTS_TYPES](state) {
    Api.getAutomationObjectsTypes()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_OBJECTS_TYPES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS](state) {
    Api.getAutomationActions()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_ACTIONS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS](state) {
    Api.getAutomationActionsObjects()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_ACTIONS_OBJECTS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS_OPTIONS](state) {
    Api.getAutomationActionsOptions()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_ACTIONS_OPTIONS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_TYPES](state) {
    Api.getAutomationActionsObjectsTypes()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_ACTIONS_OBJECTS_TYPES, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS](state) {
    Api.getAutomationActionsObjectsOptions()
      .then((res) => {
        state.commit(c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS, res.data);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_ACTIONS](state, params) {
    state.commit(c.SET_AUTOMATION_LOADING, true);

    return Api.getAutomationActionsOptionsFilterActions(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsActions",
            data: res.data.actions,
            ids: [params.ObjectTypeId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.SET_AUTOMATION_LOADING, false));
  },
  [c.GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_TEMPLATES](state, params) {
    state.commit(c.SET_AUTOMATION_LOADING, true);

    return Api.getAutomationActionsOptionsFilterTemplates(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsActionsOptions",
            data: res.data.templates,
            ids: [params.ObjectTypeId, params.ActionId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.SET_AUTOMATION_LOADING, false));
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_ACTIONS](state, params) {
    state.commit(c.SET_AUTOMATION_LOADING, true);

    return Api.getAutomationActionsObjectsOptionsFilterActions(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsActionsObjectsTypes",
            data: res.data.actions,
            ids: [params.ObjectTypeId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.SET_AUTOMATION_LOADING, false));
  },
  [c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_TEMPLATES](state, params) {
    state.commit(c.SET_AUTOMATION_LOADING, true);
    return Api.getAutomationActionsObjectsOptionsFilterTemplates(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsActionsTemplates",
            data: res.data.templates,
            ids: [params.ObjectTypeId, params.ActionId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.SET_AUTOMATION_LOADING, false));
  },
  [c.DELETE_AUTOMATION_TEMPLATE](state, id) {
    return Api.deleteAutomationTemplate(id)
      .then((res) => {
        state.commit(c.DELETE_AUTOMATION_TEMPLATE, id);
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.UPDATE_AUTOMATION_TEMPLATE](state, data) {
    return Api.updateAutomationTemplate(data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.UPDATE_AUTOMATION_TEMPLATE, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.ATTACH_AUTOMATION_TO_TASK](state, data) {
    return Api.attachAutomationToTask(data)
      .then((res) => {
        if (res.data.status === "success") {
          showAlert("success", "", "автоматизация создана");
          // state.commit(c.ATTACH_AUTOMATION_TO_TASK, res.data.entityModel)
        } else {
          showAlert(res.data.status, res.data.message);
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },

  [c.GET_AUTOMATION_ACTIONS_OPTIONS_FILTER_OBJECTS](state, params) {
    return Api.getAutomationActionsOptionsFilterObjects(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsObjectsTypes",
            data: res.data.objects,
            ids: [params.ActionId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },

  [c.GET_AUTOMATION_ACTIONS_OBJECTS_OPTIONS_FILTER_OBJECTS](state, params) {
    state.commit(c.SET_AUTOMATION_LOADING, true);

    return Api.getAutomationActionsObjectsOptionsFilterObjects(params)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.SET_AUTOMATION_SETTINGS_STORE, {
            field: "filteredAutomatizationsActionsObjects",
            data: res.data.objects,
            ids: [params.ActionId],
          });
        }
        return Promise.resolve(res);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => state.commit(c.SET_AUTOMATION_LOADING, false));
  },
  [c.LOAD_TASK_AUTOMATIONS](state, id) {
    return Api.getTaskAutomation(id)
      .then((res) => {
        return Promise.resolve(res.data.automatizations);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.UPDATE_TASK_AUTOMATION](state, data) {
    return Api.updateTaskAutomation(data)
      .then((res) => {
        if (res.data.status === "success") {
          return Promise.resolve(res.data.automatizations);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  [c.DELETE_TASK_AUTOMATION](state, id) {
    return Api.deleteTaskAutomation(id)
      .then((res) => {
        if (res.data.status === "success") {
          return Promise.resolve(res.data.automatizations);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },

  [c.DROP_CHILD_TASKS_STATUSES](state, id) {
    return Api.dropChildTasksStatuses(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.DROP_CHILD_TASKS_STATUSES, id);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.CREATE_SECTION](state, data) {
    await Api.createSection(data)
      .then((res) => {
        state.commit(c.CREATE_SECTION, res.data.entityModel);
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
    return true;
  },
  async [c.CREATE_SUBTASK_SECTION](state, data) {
    let result = null;
    await Api.createSection(data)
      .then((res) => {
        // state.commit(c.CREATE_SUBTASK_SECTION, res.data.entityModel);
        result = res.data.entityModel;
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return result;
  },
  async [c.EDIT_SECTION](state, { id, data }) {
    await Api.editSection(id, data)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.EDIT_SECTION, res.data.entityModel);
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return true;
  },
  async [c.DELETE_SECTION](state, { id, local }) {
    await Api.deleteSection(id)
      .then((res) => {
        if (res.data.status === "success") {
          // state.commit(c.DELETE_SECTION, id);
          if (!local) {
            state.commit(c.SET_SECTIONS_TO_NULL);
            state.dispatch(c.GET_TASKS);
          }
        } else {
          showAlert(res.data.status, res.data.message);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      })
      .finally(() => {});
    return true;
  },
  [c.GET_SECTIONS](state, GroupId) {
    state.commit(c.SET_SECTIONS_TO_NULL);
    Api.getSections(GroupId)
      .then((res) => {
        if (
          !res.data.items.find((i) => i.Name === "virtualSection") &&
          state.getters.group
        ) {
          state.dispatch(c.CREATE_SECTION, {
            Name: "virtualSection",
            GroupId: state.getters.group.Id,
          });
        }

        state.commit(c.GET_SECTIONS, res.data.items);
        if (document.querySelector(".table__row-wrap--tasks")) {
          document.querySelector(".table__row-wrap--tasks").scrollTop = 0;
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
  },
  async [c.GET_SECTIONS_BY_ID](state, GroupId) {
    let result = null;
    await Api.getSections(GroupId)
      .then((res) => {
        result = res.data.items;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_PARENT_TASK_SECTIONS](state, id) {
    let result = null;
    await Api.getParentTaskSections(id)
      .then(async (res) => {
        result = res.data.sections;
        if (
          !result.find((i) => i.Name === "virtualSection") &&
          state.getters.group
        ) {
          await state
            .dispatch(c.CREATE_SUBTASK_SECTION, {
              Name: "virtualSection",
              GroupId: state.getters.group.Id,
              ParentTaskId: id,
            })
            .then(() => {
              result.needReload = true;
            });
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка", err.message);
        console.log(err);
      });
    return result;
  },
  [c.GET_GLOBAL_GROUPS](state) {
    Api.getGlobalGroups()
      .then((res) => {
        state.commit(c.GET_GLOBAL_GROUPS, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async [c.CREATE_GLOBAL_GROUP](state, data) {
    let result = null;
    await Api.createGlobalGroup(data)
      .then((res) => {
        state.commit(c.CREATE_GLOBAL_GROUP, res.data.entityModel);
        state.dispatch(c.GET_USER_TASKS_LIST);
        result = res.data.entityModel;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.UPDATE_GLOBAL_GROUP](state, data) {
    let result = null;
    await Api.updateGlobalGroup(data.Id, data)
      .then((res) => {
        // state.commit(c.UPDATE_GLOBAL_GROUP, res.data.entityModel);
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.DELETE_GLOBAL_GROUP](state, id) {
    let result = null;
    state.commit(c.DELETE_GLOBAL_GROUP, id);

    await Api.deleteGlobalGroup(id)
      .then((res) => {
        state.dispatch(c.GET_USER_TASKS_LIST);
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_GLOBAL_GROUP_ELEMENTS](state, id) {
    let result = null;
    await Api.getGlobalGroupElements(id)
      .then((res) => {
        if (res.data.status === "success") {
          state.commit(c.GET_GLOBAL_GROUP_ELEMENTS, res.data.entityModel);
          result = res.data;
        } else {
          showAlert("error", "Ошибка", res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.ADD_ELEMENT_TO_GLOBAL_GROUP](state, data) {
    let result = null;
    await Api.addElementToGlobalGroup(data)
      .then(async (res) => {
        // state.commit(c.ADD_ELEMENT_TO_GLOBAL_GROUP, res.data);
        // await state.dispatch(c.GET_USER_TASKS_LIST);
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.DELETE_ELEMENT_FROM_GLOBAL_GROUP](state, id) {
    let result = null;
    await Api.removeElementFromGlobalGroup(id)
      .then((res) => {
        state.commit(c.DELETE_ELEMENT_FROM_GLOBAL_GROUP, res.data.entityModel);
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  [c.CHANGE_GLOBAL_GROUP_ORDER](state, data) {
    Api.changeGlobalGroupOrders(data)
      .then(() => {
        // state.commit(c.DELETE_ELEMENT_FROM_GLOBAL_GROUP, res.data.entityModel);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [c.CHANGE_GLOBAL_GROUP_FILTERS_ORDER](state, data) {
    Api.changeGlobalGroupFiltersOrders(data)
      .then(() => {
        // state.commit(c.DELETE_ELEMENT_FROM_GLOBAL_GROUP, res.data.entityModel);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async [c.GET_USER_TASKS_LIST](state) {
    let result;
    await Api.getUserTasksList()
      .then((res) => {
        state.commit(c.GET_USER_TASKS_LIST, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_COMMENTS_FOR_PARENT_COMMENT](state, id) {
    let result = null;
    await Api.getCommentsForParentComment(id)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  [c.CHANGE_TASKS_SECTIONS](state, data) {
    Api.changeTaskSections(data)
      .then(() => {
        state.dispatch(c.GET_USER_TASKS_LIST);
        state.dispatch(c.GET_TASKS);
        // state.commit(c.DELETE_ELEMENT_FROM_GLOBAL_GROUP, res.data.entityModel);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  [c.DOWNLOAD_FILE](state, id) {
    Api.downloadFile(id);
  },
  async [c.PIN_COMMENT](state, data) {
    let result = null;
    await Api.pinComment(data)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.UNPIN_COMMENT](state, id) {
    let result = null;
    await Api.unpinComment(id)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.MARK_COMMENT_AS_IMPORTANT](state, data) {
    let result = null;
    await Api.markCommentAsImportant(data)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.UNMARK_COMMENT_AS_IMPORTANT](state, id) {
    let result = null;
    await Api.unmarkCommentAsImportant(id)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.SET_COMMENT_VISIBLE](state, data) {
    let result = null;
    await Api.setCommentVisible(data)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.SET_COMMENT_UNVISIBLE](state, id) {
    let result = null;
    await Api.setCommentUnvisible(id)
      .then((res) => (result = res))
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_COMMENTS_FOR_TASK](state, { taskId, page, limit }) {
    let result = null;
    await Api.getCommentsForTask({ taskId, page, limit })
      .then(
        (res) =>
          (result = {
            comments: res.data.comments,
            totalCountComments: res.data.totalCountComments,
          })
      )
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.COPY_SHARED_FILTER](state, data) {
    let result = null;
    await Api.copySharedFilter(data)
      .then((res) => {
        result = res;
        state.dispatch(c.GET_USER_TASKS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.LOAD_MORE_STANDART_TASKS_IN_GROUP](state, data) {
    let result = null;
    await Api.loadMoreStandardTasksInGroup(data)
      .then((res) => {
        result = res;
        if (res.data.status === "success") {
          state.commit(c.LOAD_MORE_FILTER_TASKS_IN_GROUP, {
            groupId: data.GroupId,
            tasks: res.data.tasks,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.LOAD_MORE_CUSTOM_TASKS_IN_GROUP](state, data) {
    let result = null;
    await Api.loadMoreCustomTasksInGroup(data)
      .then((res) => {
        result = res;
        if (res.data.status === "success") {
          state.commit(c.LOAD_MORE_FILTER_TASKS_IN_GROUP, {
            groupId: data.GroupId,
            tasks: res.data.tasks,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  [c.GET_CURRENT_USER_SETTINGS](state) {
    Api.getCurrentUserSettigs().then((res) => {
      state.commit(c.GET_CURRENT_USER_SETTINGS, res.data);
    });
  },
  [c.EDIT_TASK_DEPENDENCY](state, data) {
    Api.editTaskDependency(data)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  },
  async [c.APPROVE_MANY_TASKS](state, data) {
    let result = null;
    await Api.approveManyRequests(data)
      .then((res) => {
        result = res;
        // if (res.data.status === "success") {
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_GROUP_BY_ID](state, id) {
    let result = null;
    await Api.getGroupById(id)
      .then((res) => {
        if (res.data.status === "success") {
          result = res.data.group;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  async [c.GET_NOTIFICATIONS_FOR_TASK](state, id) {
    let result = null;
    await Api.getNotificationsForTask(id)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
};
