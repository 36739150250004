<template>
  <div class="onoffswitch onoffswitch_for_hide_field" @click.prevent="set">
    <input
      type="checkbox"
      name="onoffswitch"
      class="onoffswitch-checkbox"
      :class="{
        'onoffswitch-checkbox--checked': !form.HiddenFields[field],
      }"
    />
    <label class="onoffswitch-label">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["form", "field"],
  name: "SwitcherForHideField",
  methods: {
    set() {
      this.form.HiddenFields[this.field] = !this.form.HiddenFields[this.field];
      this.$emit("saveAfterEdit");
    },
  },
};
</script>

<style scoped></style>
