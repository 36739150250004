<template>
  <div class="print print--1" :style="{ backgroundImage: `url(${bg})` }">
    <div class="print__small-logo">
      <img :src="getimgStaticSrc('sales/print-logo-small.svg')" />
    </div>
    <div class="print__welcome-title">
      Коммерческое предложение <br />
      по автоматизации парковки <br />
      для
      {{ offerObject.json.Name }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SalesHelpers } from "@/mixins/sales/SalesMixins";

export default {
  mixins: [SalesHelpers],
  computed: {
    bg() {
      if (this.offerObject.json.CoverManualSet) {
        if (this.print.rendering) {
          return this.getImageOfferSrc(this.offerObject.json.CoverManual.img);
        } else {
          return this.imageBlobCheck("builder", "covers_manual", 1)
            ? this.offerObject.json.CoverManual.blob
            : this.getImageOfferSrc(this.offerObject.json.CoverManual.img);
        }
      }
      return this.getImageSrc(this.offerObject.json.Cover);
    },
    ...mapState("sales", ["offerObject", "print"]),
  },
};
</script>
