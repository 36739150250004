export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_ARTICLES = "GET_ARTICLES";
export const LOAD_ARTICLE_CHILDS = "LOAD_ARTICLE_CHILDS";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const READ_ARTICLE = "READ_ARTICLE";
export const SEARCH_ARTICLES = "SEARCH_ARTICLES";

export const WIKI_MODULE_RIGHT = "01E7D845-8B92-4872-87DC-D00AB7F6A359";
export const WIKI_EDIT_RIGHT = "4F2C1E2D-0CD9-4434-9EF2-29551971729B";
