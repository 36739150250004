import { render, staticRenderFns } from "./DocFilesInput.vue?vue&type=template&id=3eeed2c2&scoped=true&"
import script from "./DocFilesInput.vue?vue&type=script&lang=js&"
export * from "./DocFilesInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eeed2c2",
  null
  
)

export default component.exports