import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { offerJson, settingsJson } from "@/mixins/sales/SalesJSONS";

export default {
  namespaced: true,
  state: {
    preloaders: {
      table: false,
      main: false,
    },
    settingsObjectStandart: null,
    settingsObjectEconomy: null,
    settingsObjectQr: null,
    settingsObject: {
      json: JSON.parse(JSON.stringify(settingsJson)),
      images: {
        covers: [],
        objects: {
          tc: [],
          bc: [],
          zk: [],
          hotels: [],
          sport: [],
          air: [],
          others: [],
          parks: [],
        },
        service: [],
        icons: {
          tc: [],
          bc: [],
          zk: [],
          hotels: [],
          sport: [],
          air: [],
          others: [],
          parks: [],
        },
        devices: {
          cards_entry_rack: [],
          cards_entry_barrier: [],
          cards_exit_rack: [],
          cards_exit_barrier: [],
          cards_payment: [],
          cards_additional: [],

          tickets_entry_rack: [],
          tickets_entry_barrier: [],
          tickets_exit_rack: [],
          tickets_exit_barrier: [],
          tickets_payment: [],
          tickets_additional: [],

          tokens_entry_rack: [],
          tokens_entry_barrier: [],
          tokens_exit_rack: [],
          tokens_exit_barrier: [],
          tokens_payment: [],
          tokens_additional: [],

          tokensdbl_entry_rack: [],
          tokensdbl_entry_barrier: [],
          tokensdbl_exit_rack: [],
          tokensdbl_exit_barrier: [],
          tokensdbl_payment: [],
          tokensdbl_additional: [],
        },
        cardsImg: [],
        tokensImg: [],
        ticketsImg: [],
        tockensdblImg: [],
      },
      blobImages: {
        covers: [],
        objects: {
          tc: [],
          bc: [],
          zk: [],
          hotels: [],
          sport: [],
          air: [],
          others: [],
          parks: [],
        },
        service: [],
        icons: {
          tc: [],
          bc: [],
          zk: [],
          hotels: [],
          sport: [],
          air: [],
          others: [],
          parks: [],
        },
        devices: {
          cards_entry_rack: [],
          cards_entry_barrier: [],
          cards_exit_rack: [],
          cards_exit_barrier: [],
          cards_payment: [],
          cards_additional: [],

          tickets_entry_rack: [],
          tickets_entry_barrier: [],
          tickets_exit_rack: [],
          tickets_exit_barrier: [],
          tickets_payment: [],
          tickets_additional: [],

          tokens_entry_rack: [],
          tokens_entry_barrier: [],
          tokens_exit_rack: [],
          tokens_exit_barrier: [],
          tokens_payment: [],
          tokens_additional: [],

          tokensdbl_entry_rack: [],
          tokensdbl_entry_barrier: [],
          tokensdbl_exit_rack: [],
          tokensdbl_exit_barrier: [],
          tokensdbl_payment: [],
          tokensdbl_additional: [],
        },
      },
    },
    offerObject: {
      json: JSON.parse(JSON.stringify(offerJson)),
      images: {
        schemes: [],
        covers_manual: [],
      },
      blobImages: {
        schemes: [],
        covers_manual: [],
      },
      openedPages: {
        Entry: 0,
        Exit: 0,
        Payment: 0,
        Additional: 0,
      },
      OpenAccessByLink: null,
      LinkToOffer: null,
    },
    pageNums: {
      Schemes: 4,
      Entry: 6,
      Entry_2: 6,
      Exit: 7,
      Exit_2: 7,
      Payment: 8,
      Additional: 9,
      Total: 10,
      Tasks: 11,
      Advantages: 14,
      Service: 15,
      Options: 17,
      Tenant: 18,
      OptionsLastPage: 19,
      EntryLastPage: 5,
      ExitLastPage: 5,
      PaymentLastPage: 5,
      Entry_2LastPage: 5,
      Exit_2LastPage: 5,
      AdditionalLastPage: 5,
      Objects: 20,
      TasksAdditionalPage: false,
      AdditionalTextPage: 11,
    },
    offers: [],
    windowStates: {
      is_open_settings: false,
      is_open_builder: false,
      is_open_view: false,
      is_edit_state: false,
    },
    print: {
      preview: false,
      full: true,
      templatesPreview: false,
      pageForPreview: null,
      rendering: false,
      changeWindow: false,
    },
    compareObject: {},
    settingsJsonDump: null,
    rateValue: 60,
    examplesObjects: [],
    commercialSiteUrl: "",
    config: {
      system: "standart", // economy // qr
    },
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
