<template>
  <div class="tableInnerRow">
    <div class="table__row table__row_main">
      <div
        class="table__col table__col_width_360"
        @click="openForm($event.target)"
      >
        <div class="cm-table-col-1">
          <div class="cm-table-col-1__arrow">
            <svg class="select__caret">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/static/images/icon.svg#icon_icon-caret"
              ></use>
            </svg>
          </div>
          <div class="cm-table-col-1__text">
            <span class="cm-table-col-1__name">Файлы пдф</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-client-detail-form">
      <div
        class="cm-popup-form__wrapper cm-popup-form__wrapper--tabs cm-popup-form__wrapper--single"
      >
        <div class="cm-popup__tabs">
          <div
            class="cm-popup__tabs-item"
            :class="tab === 9 ? 'cm-popup__tabs-item--active' : ''"
            @click="tab = 9"
          >
            Общие пдф
          </div>
          <div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 1 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 1"
            >
              Тц
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 2 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 2"
            >
              Бц
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 3 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 3"
            >
              ЖК
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 4 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 4"
            >
              Отели
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 5 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 5"
            >
              Спорт комплексы
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 6 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 6"
            >
              Аэропорты и ЖД
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 7 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 7"
            >
              Парки
            </div>
            <div
              class="cm-popup__tabs-item"
              :class="tab === 8 ? 'cm-popup__tabs-item--active' : ''"
              @click="tab = 8"
            >
              Паркинг
            </div>
          </div>
        </div>

        <template v-if="tab === 9">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 1 обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_1', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_1.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 1 файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_1', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_1.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <span class="separator"></span>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 2 обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_2', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_2.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 2 файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_2', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_2.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>

          <span class="separator"></span>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 3 обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_3', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_3.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Пдф 3 файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_3', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_3.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 1">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_tc', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_tc.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_tc', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_tc.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 2">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_bc', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_bc.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_bc', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_bc.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 3">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_zk', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_zk.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_zk', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_zk.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 4">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_hotels', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_hotels.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_hotels', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="
                  getVirtualImageName(settingsObject.json.pdf_hotels.file)
                "
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 5">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_sport', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_sport.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_sport', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_sport.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 6">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_air', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_air.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_air', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_air.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 7">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_parks', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_parks.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_parks', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_parks.file)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>

        <template v-if="tab === 8">
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Обложка:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="image/*"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_others', 'img')"
              />
              <input
                type="text"
                class="input"
                :value="getVirtualImageName(settingsObject.json.pdf_others.img)"
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
          <label class="field field-cover">
            <span
              class="field__label field__label_align_right clients-common-info__label"
            >
              Файл:
            </span>
            <span class="field__content input-file">
              <input
                type="file"
                accept="application/pdf"
                hidden
                class="input"
                @change="filesChange($event.target.files, 'pdf_others', 'file')"
              />
              <input
                type="text"
                class="input"
                :value="
                  getVirtualImageName(settingsObject.json.pdf_others.file)
                "
                disabled
              />
              <div class="button button_grey button-file">
                <img src="/static/images/gps.svg" />
                Выбрать файл
              </div>
            </span>
          </label>
        </template>
      </div>
      <div class="cm-client-detail-form__btns">
        <span @click="saveSettings" style="color: #0cce6b">Сохранить</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SalesHelpers } from "@/mixins/sales/SalesMixins";
import { showAlert } from "../../../../../utils";
export default {
  mixins: [SalesHelpers],
  data() {
    return {
      tab: 9,
    };
  },

  methods: {
    filesChange(fileList, pdfField, fileType) {
      const imagesExts = ["jpeg", "jpg", "png"];
      const ext = fileList[0].name.split(".").pop();

      if (fileType === "img") {
        if (!imagesExts.includes(ext)) {
          showAlert("error", "неправильный формат");
          return false;
        }
      } else {
        if (ext !== "pdf") {
          showAlert("error", "неправильный формат");
          return false;
        }
      }

      if (fileList.length) {
        this.settingsObject.json[pdfField][fileType] = fileList[0];
      }
    },
    getVirtualImageName(field) {
      if (field.name === void 0) {
        return field;
      } else {
        return field.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cm-client-detail-form .cm-popup-form__wrapper--single .cm-popup__tabs {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.separator {
  height: 1px;
  background: rgba(121, 123, 124, 0.2);
  width: 100%;
  display: block;
  margin: 40px 0;
}
</style>
